import { useEffect, useState, useMemo, useCallback } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";
import classnames from "classnames";
import has from "lodash/has";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { DatePicker, DateTimePicker, Dropdown, Input, Switch, UserMentions, flex, cssUtils } from "../../ui";
import { Calendar } from "../../ui/calendar";
import SchedulingSocialFields from "../../schedule/schedule-event-social-fields.component";
import ScheduleEvent from "../../schedule/schedule-event.component";
import { getSelected, getSchedulingTypes } from "../opportunity.selectors";
import { saveNewScheduling, fetchSchedulingTypes } from "../opportunity.actions";
import { selectors as coreSelectors } from "../../core";
import { actions as userActions, selectors as userSelectors } from "../../user";
import { NEW_SCHEDULING_FORM } from "../opportunity.constants";
import css from "./scheduling.scss";

const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !get( values, "opportunitySchedulingType.id" ) ) {
        set( errors, "opportunitySchedulingType.id", "common:validation.selection" );
    }
    if ( !values.startDate ) {
        errors.startDate = "common:validation.date";
    }
    if ( values.recurrent && values.recurrent.active ) {
        if ( !values.recurrent.type ) {
            set( errors, "recurrent.type", "common:validation.selection" );
        }
        if ( !values.recurrent.until ) {
            set( errors, "recurrent.until", "common:validation.required" );
        }
    }
    if ( !values.description ) {
        errors.description = "common:validation.required";
    }

    return errors;
};

const NewScheduling = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const types = useSelector( getSchedulingTypes );
    const events = useSelector( userSelectors.getEvents );
    const reloadEvents = useSelector( userSelectors.reloadEvents );
    const opportunity = useSelector( getSelected );
    const user = useSelector( coreSelectors.getUser );
    const users = useSelector( userSelectors.listAllPermittedActive );

    const [ done, setDone ] = useState( false );
    const [ selectedEvent, setSelectedEvent ] = useState( null );
    const [ notifyMinutesBefore, setNotifyMinutesBefore ] = useState( "0" );
    const [ notifyMinutesBeforeOther, setNotifyMinutesBeforeOther ] = useState( "" );
    const [ showUsers, setShowUsers ] = useState( false );
    const [ showNotify, setShowNotify ] = useState( true );

    const formik = useForm({
        form: NEW_SCHEDULING_FORM,
        enableReinitialize: true,
        initialValues: {
            opportunity: {
                id: opportunity?.id
            },
            recurrent: {
                active: false,
                type: null,
                until: null
            },
            description: "",
            notify: false,
            notifyAt: null,
            opportunitySchedulingType: {
                id: null
            },
            startDate: null,
            selectUser: false,
            user: null,
        },
        onSubmit: values => dispatch( saveNewScheduling( values ) ),
        validate
    });

    const setFieldValue = useMemo( () => formik.setFieldValue, [ formik.setFieldValue ] );

    const prevSubmitting = usePrevious( formik.submitting );
    const prevStartDate = usePrevious( formik.values.startDate );

    const userId = useMemo( () => {
        return get( formik.values.user, "id" );
    }, [ formik.values.user ] );

    const showSocialFields = useMemo( () => {
        return ( !userId && opportunity && opportunity.user.socialCalendar ) ||
        ( userId && !!users.find( u => u.id === userId ).socialCalendar );
    }, [ opportunity, userId, users ] );

    const onNavigateCalendar = useCallback( () => {
        if ( dayjs( formik.values.startDate ).isValid() ) {
            const newDate = dayjs( formik.values.startDate ).toDate();
            const newStartDate = new Date( newDate.getFullYear(), newDate.getMonth(), 1 );
            newStartDate.setHours( 0, 0, 0, 0 );
            const endDate = new Date( newDate.getFullYear(), newDate.getMonth() + 1, 0 );
            endDate.setHours( 23, 59, 59, 0 );
            const params = { startDate: newStartDate.toISOString(), endDate: endDate.toISOString() };
            if ( userId ) {
                params.usersId = [].concat( userId ).toString();
            }

            dispatch( userActions.fetchEvents( params ) );
        }
    }, [ dispatch, formik.values.startDate, userId ] );

    const handleChangeDate = useCallback( event => {
        const value = has( event, "target.value" ) ? event.target.value : event;

        if ( dayjs( value ).isValid() ) {
            const newDate = dayjs( value ).toDate();
            const now = new Date();
            if ( value !== null && newDate < now ) {
                setFieldValue( "status", "ACCOMPLISHED" );
                setNotifyMinutesBefore( "0" );
                setNotifyMinutesBeforeOther( "" );
                setFieldValue( "notify", false );
                setFieldValue( "notifyAt", null );
                setShowNotify( false );
            } else {
                setFieldValue( "status", "OPEN" );
                setShowNotify( true );
                if ( formik.values.notify ) {
                    if ( value ) {
                        const minutes = notifyMinutesBeforeOther ? notifyMinutesBeforeOther : notifyMinutesBefore;
                        const newNotifyAt = new Date( newDate );
                        if ( minutes !== "0" && minutes !== "-1" ) {
                            newNotifyAt.setMinutes( newNotifyAt.getMinutes() - Number.parseInt( value ) );
                        }
                        setFieldValue( "notifyAt", newNotifyAt );
                    } else {
                        setFieldValue( "notifyAt", null );
                    }
                }
            }
            setFieldValue( "endDate", value ? new Date( value ).toISOString() : value );
            if ( value && formik.values.recurrent && formik.values.recurrent.active && formik.values.recurrent.until ) {
                const dateDayjs = dayjs( value );
                const nextYear = dayjs( value ).add( 1, "year" );
                const until = dayjs( formik.values.recurrent.until );
                if ( dateDayjs.isAfter( until ) || nextYear.isBefore( until ) ) {
                    setFieldValue( "recurrent.until", null );
                }
            }
        }
    }, [
        formik.values.notify,
        notifyMinutesBefore,
        notifyMinutesBeforeOther,
        formik.values.recurrent,
        setFieldValue
    ] );

    const handleChangeUser = value => {
        setShowUsers( value );
        setFieldValue( "user", null );
    };

    const changeNotifyAt = useCallback( value => {
        if ( !value ) {
            setFieldValue( "notifyAt", null );
            return;
        }

        const newNotifyAt = formik.values.startDate ? new Date( formik.values.startDate ) : null;
        if ( newNotifyAt && value !== "0" && value !== "-1" ) {
            newNotifyAt.setMinutes( newNotifyAt.getMinutes() - Number.parseInt( value ) );
        }

        setFieldValue( "notifyAt", newNotifyAt );
    }, [ setFieldValue, formik.values.startDate ] );

    const handleChangeNotify = useCallback( value => {
        setNotifyMinutesBefore( value );
        changeNotifyAt( value );
    }, [ changeNotifyAt ] );

    const handleChangeNotifyOther = useCallback( value => {
        setNotifyMinutesBeforeOther( value );
        changeNotifyAt( value );
    }, [ changeNotifyAt ] );

    const toggleNotify = useCallback( value => {
        if ( !value ) {
            changeNotifyAt();
        } else {
            handleChangeNotify( "0" );
        }
    }, [ changeNotifyAt, handleChangeNotify ] );

    useEffect( () => {
        dispatch( fetchSchedulingTypes() );
        dispatch( userActions.fetchUsers() );
    }, [ dispatch ] );

    useEffect( () => {
        setDone( true );
    }, [] );

    useEffect( () => {
        if ( prevStartDate !== formik.values.startDate ) {
            handleChangeDate( formik.values.startDate );
        }
    }, [ prevStartDate, formik.values.startDate, handleChangeDate ] );
    useEffect( () => {
        onNavigateCalendar();
    }, [ onNavigateCalendar ] );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onNavigateCalendar();
        }
    }, [ prevSubmitting, formik.submitting, onNavigateCalendar ] );

    useEffect( () => {
        if ( reloadEvents ) {
            onNavigateCalendar();
            setSelectedEvent( null );
        }
    }, [ reloadEvents, onNavigateCalendar ] );

    useEffect( () => {
        if ( userId ) {
            setFieldValue( "social", null );
        }
    }, [ setFieldValue, userId ] );

    const minDate = new Date();
    minDate.setDate( minDate.getDate() - 1 );
    if ( !done ) {
        return <div/>;
    }

    return (
        <div className={ classnames( flex.container, flex.alignItemsStart ) }>
            <div
                className={ classnames(
                    css.calendar,
                    ( formik.values.notify || showSocialFields ) && css.calendarMedium,
                    formik.values.notify && showSocialFields && css.calendarLarge,
                ) }
            >
                {
                    userId &&
                            <CardHeader
                                className={ css.calendarUser }
                                subheader={
                                    t(
                                        "opportunity:schedule-of",
                                        { user: users.find( user => user.id === userId ).name }
                                    )
                                }
                            />
                }
                <Calendar
                    className={ css.calendar }
                    views={[ "day" ]}
                    defaultView="day"
                    events={ events }
                    date={ formik.values.startDate && new Date( formik.values.startDate ) }
                    onNavigate={ date => onNavigateCalendar( date ) }
                    eventPropGetter={ event => ({ style: { backgroundColor: event.color } }) }
                    onSelectEvent={ event => setSelectedEvent( event ) }
                    components={{
                        header: () => <div></div>
                    }}
                />
            </div>
            <ScheduleEvent
                event={ selectedEvent }
                onClose={ () => setSelectedEvent( null ) }
                user={ user }
            />
            <Card className={ css.newSchedulingContainer }>
                <Form formik={ formik } noValidate>
                    <CardHeader subheader={ t( "opportunity:new-scheduling" ) } />
                    <CardContent>
                        <Field
                            name="opportunitySchedulingType.id"
                            source={ ( types || [] ).map( type => ({
                                value: type.id,
                                label: type.name
                            }))}
                            label={ t( "opportunity:scheduling.type" ) }
                            component={ Dropdown }
                            required
                        />
                        <Field
                            name="startDate"
                            label={ t( "opportunity:scheduling.date" ) }
                            component={ DateTimePicker }
                            required
                        />
                        <Field
                            label={ t( "opportunity:scheduling.recurrent.label" ) }
                            name="recurrent.active"
                            type="checkbox"
                            component={ Switch }
                            fullWidth
                        />
                        <div className={ flex.container }>
                            {
                                formik.values.recurrent.active &&
                                        <>
                                            <Field
                                                name="recurrent.type"
                                                label={ t( "opportunity:scheduling.recurrent.type.label" ) }
                                                source={
                                                    [ "DAILY", "WEEKLY", "MONTHLY", "YEARLY" ].map( recurrentType => ({
                                                        label:
                                                            t( `schedule:form-event.recurrent.type.${recurrentType}` ),
                                                        value: recurrentType
                                                    }) )
                                                }
                                                component={ Dropdown }
                                                className={ flex.fill }
                                                fullWidth={ false }
                                                required
                                            />
                                            <Field
                                                name="recurrent.until"
                                                component={ DatePicker }
                                                label={ t( "opportunity:scheduling.recurrent.until" ) }
                                                className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                                minDate={ formik.values.startDate }
                                                maxDate={ dayjs( formik.values.startDate ).add( 1, "year" ).toDate() }
                                                fullWidth={ false }
                                                disabled={ !formik.values.startDate }
                                                asDate
                                                required
                                            />
                                        </>
                            }
                        </div>
                        <Field
                            name="description"
                            type="text"
                            label={ t( "opportunity:scheduling.description" ) }
                            component={ UserMentions }
                            singleLine
                            required
                        />
                        <Field
                            name="selectUser"
                            type="checkbox"
                            className={ cssUtils.marginTopSmall }
                            label={ t( "opportunity:scheduling.other-user" ) }
                            component={ Switch }
                            onChange={ handleChangeUser }
                            disabled={ isEmpty( users ) }
                        />
                        {
                            showUsers &&
                                    <Field
                                        name="user.id"
                                        source={ users.map( user => ({
                                            value: user.id,
                                            label: user.name
                                        }))}
                                        className={ showNotify && cssUtils.marginBottomSmall }
                                        label={ t( "opportunity:scheduling.user" ) }
                                        component={ Dropdown }
                                    />
                        }
                        <Field
                            name="notify"
                            type="checkbox"
                            label={ t( "opportunity:scheduling.notify-at" ) }
                            component={ Switch }
                            onChange={ toggleNotify }
                            disabled={ !showNotify }
                        />
                        {
                            formik.values.notify ?
                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            value={ notifyMinutesBefore }
                                            onChange={
                                                e => {
                                                    handleChangeNotify( e.target.value );
                                                }
                                            }
                                        >
                                            <FormControlLabel
                                                label={ t( "opportunity:scheduling.notify.at" ) }
                                                value="0"
                                                control={ <Radio/> }
                                            />
                                            <FormControlLabel
                                                label={ t( "opportunity:scheduling.notify.five-minutes" ) }
                                                value="5"
                                                control={ <Radio/> }
                                            />
                                            <FormControlLabel
                                                label={ t( "opportunity:scheduling.notify.thirty-minutes" ) }
                                                value="30"
                                                control={ <Radio/> }
                                            />
                                            <FormControlLabel
                                                label={ t( "opportunity:scheduling.notify.other" ) }
                                                value="-1"
                                                control={ <Radio/> }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {
                                        notifyMinutesBefore === "-1" ?
                                            <Input
                                                label={ t( "opportunity:scheduling.notify.minutes" ) }
                                                type="number"
                                                value={ notifyMinutesBeforeOther }
                                                onChange={
                                                    event => handleChangeNotifyOther( event.target.value )
                                                }
                                                required
                                            />
                                            : null
                                    }
                                </div>
                                : null
                        }
                        {
                            showSocialFields &&
                                <SchedulingSocialFields wrapperFields={ FieldArray } wrapperField={ Field } />
                        }
                    </CardContent>
                    <CardActions>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={ formik.submitting }
                        >
                            { t( "common:save" ) }
                        </Button>
                    </CardActions>
                </Form>
            </Card>
        </div>
    );
};

export default NewScheduling;