import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Component, useEffect } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Input, Dialog } from "../ui";
import { NEW_COMMENT_FORM } from "./customer.constants";
import { getSelected } from "./customer.selectors";
import { saveComment } from "./customer.actions";

const NewComment = ({ show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );

    const formik = useForm({
        form: NEW_COMMENT_FORM,
        enableReinitialize: true,
        initialValues: {
            customerId: customer?.id,
            comment: "",
        },
        onSubmit: values => dispatch( saveComment( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.comment ) {
                errors.comment = "common:validation.required";
            }
            return errors;
        },
    });

    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onClose();
        }
    }, [ onClose, prevSubmitting, formik.submitting ] );

    return (
        <Dialog
            open={ show }
            onClose={ onClose }
            fullWidth
        >
            <DialogTitle>
                { t( "customer:new-comment.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="comment"
                        type="text"
                        label={ t( "customer:new-comment.comment" ) }
                        component={ Input }
                        multiline
                        required
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export class _NewComment extends Component {

    render () {
        const { t, show, onClose, remoteSubmit, submitting, handleSubmit } = this.props;

        return (
            <Dialog
                open={ show }
                onClose={ onClose }
                fullWidth
            >
                <DialogTitle>
                    { t( "customer:new-comment.title" ) }
                </DialogTitle>
                <DialogContent>
                    <form role="form" onSubmit={ handleSubmit }>
                        <Field
                            name="comment"
                            type="text"
                            label={ t( "customer:new-comment.comment" ) }
                            component={ Input }
                            multiline
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ remoteSubmit } disabled={ submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default NewComment;