import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";
import get from "lodash/get";

import * as actions from "./home.actions";

const schedulingStatistics = createRequestReducer({
    begin: actions.REQUEST_SCHEDULING_STATISTICS,
    success: actions.RECEIVE_SCHEDULING_STATISTICS,
    failure: actions.ERROR_SCHEDULING_STATISTICS,
});

const schedules = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.FETCH_SCHEDULES:
            return Object.assign( {}, state, {
                currentPage: action.meta.params.page || 1
            });
        case actions.REQUEST_SCHEDULES:
            return Object.assign( {}, state, {
                loading: true,
                reload: false
            });
        case actions.RECEIVE_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                data: state.currentPage > 1 && action.payload ?
                    state.data.concat( get( action.payload, "schedules", [] ) ) :
                    get( action.payload, "schedules", [] ),
                totalPages: get( action.payload, "totalPages", 0 )
            });
        case actions.ERROR_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });
        default:
            return state;
    }
};

export const home = combineReducers({ schedulingStatistics, schedules });