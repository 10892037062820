import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import DistributionForm from "./distribution-form.component";
import { saveDistribution } from "./distribution.actions";
import { createEditDistributionForm } from "./distribution.utils";

const EditDistribution = ({ distribution, open, onClose }) => {
    const t = useTranslate();

    return (
        <Dialog open={ open } maxWidth="sm" fullWidth>
            <DialogTitle>
                { t( "common:edit" ) }
            </DialogTitle>
            <DistributionForm
                form={ createEditDistributionForm( distribution.id ) }
                distribution={ distribution }
                onSubmit={ saveDistribution }
                onClose={ onClose }
            />
        </Dialog>
    );
};

export default EditDistribution;