import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as sourceActions, selectors as sourceSelectors } from "../../../source";
import { flex } from "../../../core";
import ConfigHeader from "../../config-header.component";
import { saveSources } from "./source.actions";
import { SOURCE_FORM } from "./source.constants";
import SourceList from "./source-list.component";

const Source = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( sourceSelectors.isLoadingSources );
    const sources = useSelector( sourceSelectors.getSources );

    const [ showErrors, setShowErrors ] = useState( false );

    const closeErrors = () => setShowErrors( false );

    const formik = useForm({
        form: SOURCE_FORM,
        enableReinitialize: true,
        initialValues: {
            sources: sources || [],
        },
        onSubmit: values => dispatch( saveSources( values ) ),
        validate: values => {
            const errors = {};
            errors.sources = [];
            values.sources.forEach( ( source, index ) => {
                const error = {};

                if ( !source.name ) {
                    error.name = "common:validation.required";
                }

                if ( !isEmpty( error ) ) {
                    errors.sources[ index ] = error;
                }
            });
            if ( isEmpty( errors.sources ) ) {
                delete errors.sources;
            }
            return errors;
        }
    });

    const handleAdd = () => formik.setFieldValue( "sources", [ ...formik.values.sources, {}] );

    useEffect( () => {
        dispatch( sourceActions.fetchSources() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowErrors( true );
        }
    }, [ formik.error ] );

    return (
        <div>
            <Snackbar
                open={ showErrors }
                action={ <Button onClick={ closeErrors } color="secondary">{ "OK" }</Button> }
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ closeErrors }
            />
            <ConfigHeader
                title={ t( "config:sources.title" ) }
                disabled={ formik.submitting }
                onActionClick={ handleAdd}
                actionTitle={ t( "config:sources.add" ) }
                actionIcon={ AddIcon }
            />

            <Card id="sourceForm">
                <CardHeader
                    subheader={ t( "config:sources.call-to-action" ) }
                />
                {
                    loading ?
                        <LinearProgress/> :
                        <>
                            <CardContent>
                                <Form formik={ formik }>
                                    <FieldArray name="sources" component={ SourceList } />
                                </Form>
                            </CardContent>
                            <CardActions className={ flex.justifyContentEnd }>
                                <Button
                                    onClick={ formik.submitForm }
                                    color="primary"
                                    variant="contained"
                                    disabled={ formik.submitting }
                                >
                                    { t( "common:save" ) }
                                </Button>
                            </CardActions>
                        </>
                }
            </Card>
        </div>
    );
};

export default Source;