export const INVITE_USER = "x/config/user/INVITE_USER";
export const inviteUser = data => ({
    type: INVITE_USER,
    payload: data
});

export const RESEND_INVITATION = "x/config/user/RESEND_INVITATION";
export const resendInvitation = data => ({
    type: RESEND_INVITATION,
    payload: data
});

export const DELETE_INVITATION = "x/config/user/DELETE_INVITATION";
export const deleteInvitation = invitation => ({
    type: DELETE_INVITATION,
    meta: { id: invitation.id }
});

export const SAVE_USER = "x/config/user/SAVE_USER";
export const saveUser = ( user, form ) => ({
    type: SAVE_USER,
    meta: { form },
    payload: { user }
});

export const SAVE_USER_ACTIVE = "x/config/user/SAVE_USER_ACTIVE";
export const saveUserActive = user => ({
    type: SAVE_USER_ACTIVE,
    payload: { user }
});
