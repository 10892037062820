export default {
    "section-title-format": "$t(config:index.title) > { sublevel }",
    "title": "Configuración",
    "main": {
        "suggestions": {
            "title": "Sugerencias para ti",
            "inviteUsers": {
                "info": "Invita a las personas de tu equipo a usar el CRM",
                "buttonText": "Invitar usuarios"
            },
            "createCustomFields": {
                "info": "Crea campos personalizados según tu proceso de ventas",
                "buttonText": "Crear campos personalizados"
            }
        },
        "yourTeam": {
            "title": "Tu equipo",
            "invite": "Invitaciones, usuarios y equipos",
            "goals": "Metas",
            "commission": "Comisión"
        },
        "subscription": {
            "title": "Suscripción",
            "registrationData": "Datos de registro",
            "main": {
                "title": "Datos de registro"
            },
            "plan": "Plan, pagos y facturas"
        },
        "opportunities": {
            "title": "Ajuste de oportunidades",
            "funnel": "Embudo",
            "sources": "Fuentes",
            "lossReasons": "Motivos de pérdida de venta",
            "scheduleType": "Tipos de programación",
            "customFields": "Campos personalizados",
            "templates": "Plantillas, documentos y galería de imágenes",
            "products": "Productos y categorías de productos"
        },
        "advanced": {
            "title": "Avanzado",
            "holding": "Holding",
            "email": "Correo electrónico",
            "rules": "Reglas y horarios",
            "rule": "Reglas"
        }
    },
    "items": {
        "main": {
            "label": "General",
            "description": "Su plan"
        },
        "commission": {
            "label": "Comisiones"
        },
        "opportunity": {
            "label": "Oportunidades",
            "description": "Fases"
        },
        "form": {
            "label": "Formularios",
            "description": "Campos personalizados por módulo"
        },
        "product": {
            "label": "Productos",
            "description": "Sus productos y servicios"
        },
        "user": {
            "label": "Usuarios",
            "description": "Administrar e invitar usuarios"
        },
        "team": {
            "label": "Equipos",
            "description": "Sus equipos"
        },
        "goal": {
            "label": "Metas",
            "description": "Administrar los objetivos mensuales que los usuarios deben alcanzar"
        },
        "template": {
            "label": "Plantillas",
            "description": "Administre sus plantillas de envio de correo electrónico, SMS y WhatsApp"
        },
    },
    "subscription": {
        "title": "Inscripción",
        "call-to-action": "Cambiar los datos de registro",
        "name": "Nombre",
        "person-type": {
            "label": "Tipo de persona",
            "legal": "Jurídica",
            "natural": "Física"
        },
        "ssn": "CPF",
        "ein": "CNPJ",
        "save": "Guardar",
        "phone": "Teléfono",
        "address": {
            "invalid": "Código postal no válido",
            "zip-code": "Código postal",
            "street": "La carretera",
            "number": "Número",
            "complement": "Complemento",
            "district": "Vecindario",
            "city": "Ciudad",
            "state": "Estado",
            "country": "Padres"
        },
        "currency": "Moneda",
        "customer-portfolio": {
            "label": "Regla de cartera del cliente",
            "help":
                "Con esta opción habilitada, los vendedores solo podrán visualizar los datos de sus clientes y " +
                "sus oportunidades"
        },
        "block-non-unique-customer": {
            "label": "Bloquear el registro de clientes existentes",
            "help": "Con esta opción habilitada, no es posible registrar clientes con el mismo RFC"
        },
        "segment": {
            "label": "Segmento",
            "type": {
                "AESTHETIC": "Estética y Procedimientos",
                "AUTOMOTIVE": "Automotor",
                "EDUCATION": "Educación",
                "HEALTH": "Salud",
                "INSURANCE": "Seguros",
                "NAUTICAL": "Náutico",
                "OTHER": "Otro",
                "REALSTATE": "Bien inmueble",
                "RETAIL": "Venta por menor",
                "TELECOM": "Telecomunicaciones"
            },
            "brands": {
                "label": "Marcas",
                "selected": " seleccionada(s)"
            },
            "change": {
                "previous-automotive":
                    "Si retira su suscripción del segmento automotriz. Los campos de " +
                    "se eliminarán la marca y el modelo de las oportunidades"
            }
        },
        "select-brands": "Seleccione al menos una marca a continuación",
        "office-hours": {
            "title": "Horario de oficina",
            "weekdays": "Lunes a viernes",
            "morning": "De mañana",
            "afternoon": "De tarde",
            "start-time": "Hora de comienzo",
            "end-time": "Hora de finalización",
            "work-on-saturday": "Oficina el sábado",
            "work-on-sunday": "Oficina el domingo",
            "zone-id": "Uso horario",
            "my-location": "Usar la zona horaria"
        },
        "block-out-of-hours": {
            "label": "Bloquear el acceso fuera del horario de oficina",
            "help":
                "Bloquea el uso del sistema para usuarios que no son administradores, fuera del horario " +
                "de inicio o finalización. Se consideran 10 minutos de tolerancia."
        },
        "sample": {
            "sample-description": "Datos ficticios agregados automáticamente para la prueba",
            "sample-delete": "Eliminar datos ficticios",
            "confirm-delete": "Está seguro de que desea eliminar los datos ficticios?"
        }
    },
    "plans": {
        "title": "Plan, pagos y facturas",
        "quantity-with-end": "De {start} a {end} usuarios",
        "quantity-without-end": "Usuarios superiores a {start}",
        "coupon-expire": "Expira en {date}",
        "discount-unit": "Descuento de {discount} por usuario",
        "discount-percentage": "Descuento de {discount}%",
        "discount-total": "Descuento de {discount}%",
        "price": "por usuario por mes",
        "min-user-limit": "a partir de {minUserLimit} usuarios",
        "max-user-limit": "hasta {maxUserLimit} usuarios",
        "min-and-max-user-limit": "a partir de {minUserLimit} hasta {maxUserLimit} usuarios",
        "empty-address":
            "La dirección de su solicitud no está completa. Debes completar la dirección",
        "empty-document": "No se informó al RFC de su solicitud. Debes proporcionar un documento válido.",
        "access-register": "Registro de acceso",
        "frequency": "Mensual",
        "hire": "Contratar",
        "cancel": {
            "title": "Darse de baja",
            "confirm":
                "Se enviará un correo electrónico a nuestro servicio de atención al cliente solicitando " +
                "la cancelación. ¿Desea continuar con la cancelación?",
        },
        "storage": {
            "title": "Almacenamiento",
            "available": "Disponible",
            "customer-usage": "Clientes",
            "email-usage": "E-mails",
            "image-usage": "Imágenes",
            "opportunity-usage": "Oportunidades",
            "usage": "Uso de almacenamiento",
        },
        "success-updated": "Plan actualizado correctamente."
    },
    "billing": {
        "plan": "Plan",
        "user-limit": {
            "label": "Límite de usuarios",
            "help": "Defina aquí la cantidad de usuarios activos que usará su suscripción. {price} por usuario.",
            "incresead":
                "Cuando aumente su límite de usuarios, se le facturará proporcionalmente por los días de uso " +
                "de estos usuarios hasta la fecha de la próxima factura.",
            "warning":
                "Si hay más usuarios activos que el límite definido, los usuarios más nuevos se desactivarán al " +
                "pagar la próxima factura. El valor de estos usuarios se reducirá en la próxima factura"
        },
        "individual": "Pago proporcional de nuevos usuarios",
        "hired-users": "Usuarios contratados",
        "email-help": "Este correo electrónico se utilizará para notificaciones de pago y envío de facturas.",
        "frequency": {
            "label": "Frecuencia",
            "MONTHLY": "Mensual",
            "SEMIANNUAL": "Semi anual",
            "ANNUAL": "Anual"
        },
        "total": "Total: {price}",
        "total-frequency": "Total: {price} {frequency}",
        "type": {
            "label": "Forma de pago",
            "BANK_SLIP": "Boleto",
            "CREDIT_CARD": "Tarjeta de crédito",
            "PIX": "PIX"
        },
        "warning-new": "Al cambiar la frecuencia de facturación, se generará una nueva factura",
        "status": {
            "label": "Estado",
            "PENDING": "Pago pendiente",
            "AUTHORIZED": "Pago autorizado",
            "PROCESSING": "Procesando",
            "IN_DISPUTE": "En disputa",
            "RETURNED": "Valor devuelto",
            "DOWNED": "Tocho descargado",
            "REFUSED": "Tarjeta de credito denegada",
            "RELEASED": "Publicado",
            "IN_CANCELLATION": "En cancelación",
            "CHARGEBACK": "Transacción no reconocida por el titular de la tarjeta",
            "PRE_AUTHORIZED": "Pago preautorizado"
        },
        "print-bank-slip": "Imprimir boleto",
        "bank-slip-line": "Línea digital",
        "credit-card": {
            "description": "Terminado en {number}",
            "new": "Nueva tarjeta de crédito",
            "number": "Numero de tarjeta",
            "name": "Nombre del titular",
            "validity": "Validez",
            "cvc": "Código de seguridad"
        },
        "due-date": "Fecha de vencimiento",
        "price": "Valor",
        "paid-at": "Pago recibido en",
        "payment-date": "Día de paga",
        "amount-paid": "Valor pago",
        "payment-details": "Detalles del pago",
        "pix-copy": "Copiar y pegar código",
        "pix-info": "Se generará un código QR para el pago",
        "invoice": {
            "download-pdf": "Descargar NF-e en PDF",
            "download-xml": "Descargar NF-e en XML",
        },
    },
    "billings": {
        "next": "Próxima factura el {date}",
        "empty": "No hay facturas registradas"
    },
    "holding": {
        "title": "Tenencia",
        "help":
            "Utilizando el concepto de holding, se puede configurar aquí la comunicación entre registros. " +
            "Esta comunicación permite a los usuarios enviar información de oportunidades a la " +
            "registros que estén vinculados aquí.",
        "token": "Token",
        "validate-token": "Validar token",
        "invalid-token": "Token inválido",
        "funnels": {
            "title": "Embudos",
            "help": "Configure aquí la distribución de oportunidades que recibe de otras suscripciones",
            "add": "Crear configuración",
            "empty": "No se ha creado ninguna configuración",
            "empty-help":
                "Para que usted pueda recibir oportunidades para otros registros , " +
                "debe crear al menos una configuración"
        },
        "funnel": {
            "default-source": {
                "label": "Origen estándar",
                "help":
                    "Si la fuente de la oportunidad enviada no se encunetra en esta inscripción, " +
                    "usted utiliza esta fuente "
            },
            "delete": {
                "title": "Eliminar configuración",
                "confirm": "Está seguro que desea eliminar esta configuración?"
            },
            "edit": {
                "title": "Editar configuración"
            }
        },
        "linked-subscriptions": "Suscripciones vinculadas",
        "link-subscription-help": "Enlaza aquí las entradas a las que quieres tener acceso y enviar oportunidades",
        "link-subscription": "Suscripción de vínculo",
        "subscription-name": "Nombre de registro",
        "linked-subscription-creation": "Registro vinculado en { date } por { user }",
        "unlink-subscription": {
            "title": "Quitar vínculo",
            "confirm": "Está seguro que desea eliminar el enlace con esta inscripción?"
        }
    },
    "migrations": {
        "title": "Migraciones",
        "help":
            "Migre clientes y oportunidades de su antiguo sistema a LeadStation. Antes de ejecutar, " +
            "asegúrese de que todos los usuarios están registrados porque la " +
            "migración de um sistema solo puede ser ejecutada una única vez",
        "empty": "No se ha realizado  niguna migración",
        "new-migration": "Nueva migración",
        "system": {
            "label": "Sistema",
            "PIPEDRIVE": "Pipedrive"
        },
        "api-token": "Token de API",
        "default-source": "Origen",
        "default-source-help": "Definir qué origen se usará para las oportunidades creadas",
        "default-user": "Usuario estándar",
        "default-user-help":
            "Definir qué usuario será considerado si hay algún usuario del { system } que " +
            "no existe en LeadStation",
        "executing": "Ejecutando",
        "executed-by": "{ user } ejecutó esta migración en { date }",
        "finished-at": "Migración finalizada en { date }",
        "refresh": "Actualizar migraciones",
        "add": "Nueva migración"
    },
    "smtp-server-default": {
        "email": "E-mail",
        "call-to-action": "Cree aquí una preconfiguarción para facilitar la configuración de los usuarios",
        "title": "Servidor SMTP",
        "create": "Crear preconfiguración",
        "confirm-delete": "Está seguro que desea eliminar esta configuración previa?",
    },
    "funnels": {
        "title": "Embudos",
        "call-to-action": "Agregar, quitar y editar los embudos",
        "add": "Agregar embudo",
        "new": {
            "title": "Nuevo embudo",
            "name": "Nombre del embudo",
            "phase-name": "Nombre de la fase inicial"
        },
        "config-team-user": {
            "help": "Puede definir el equipo o los usuarios que pueden ver los datos en este embudo." +
            "Los usuarios administradores ignoran esta regla."
        },
        "name": "Embudo",
        "automation": {
            "title": "Automatización del embudo",
            "call-to-action":
                "Al marcar cómo obtiene o perdido una oportunidad de este embudo, usted puede definir que " +
                "el proceso continúa, generando una nueva oportunidad automáticamente en un nuevo embudo. " +
                "Tambiém puede definir cuál equipo/los usuarios que recibirán estas nuevas oportunidades, " +
                "si no se define ninguna, el se creará una oportunidad para el mismo usuario de la oportunidad ganada."
        },
        "delete": {
            "title": "Eliminar embudo",
            "confirm":
                "Enumere las fases de este embudo, con las fases de otros embudos para que las oportunidades que son " +
                "en este embudo, sean transferidas",
            "new-phase": "Nueva fase"
        },
        "product-categories": {
            "label": "Categorías de Producto",
            "help": "Puede definir qué categorías de productos se pueden agregar a las propuestas " +
                "de oportunidades de embudo"
        },
        "opportunitiesOrderBy": {
            "label": "Ordenar oportunidades por",
            "createdAt": "Fecha de creación",
            "updatedAt": "Fecha de actualización"
        },
        "opportunitiesOrderType": {
            "label": "Tipo de ordenación",
            "asc": "Ascendente",
            "desc": "Descendente"
        },
        "nextOpportunityStatus": {
            "label": "Estado",
            "sold": "Ganada",
            "lost": "Perdido"
        },
        "nextPhaseId": {
            "label": "Fase"
        }
    },
    "phases": {
        "title": "Fases",
        "call-to-action": "Agregar, eliminar y reorganizar las fases de negociación de su embudo",
        "add": "Agregar fase",
        "delete": {
            "title": "Eliminar fase",
            "comment": "Está seguro que desea eliminar esta fase? Esta acción es irreversible.",
            "forbidden": "No puede eliminar esta fase. Debe haber al menos, una fase en el embudo."
        },
        "item": {
            "name": "Nombre",
            "days": "Días de permanencia",
            "description": "Descripción"
        },
        "forward": {
            "title": "Tiempo de espera sin interacción",
            "call-to-action":
                "Al realizar esta configuración, necesitará que el usuario realice cualquier interacción, " +
                "en un momento determinado, en la oportunidad, cuando usted está en esta etapa . " +
                "Si no es así, la oportunidad será enviada a otro usuario.",
            "time": "Tiempo",
            "time-unit": {
                "label": "Unidad de tiempo",
                "DAY": "Día",
                "HOUR": "Hora",
                "MINUTE": "Minuto"
            },
            "clear-config": "Borrar configuración",
            "empty-users": "Debe seleccionar un equipo o al menos un usuario para reenviar oportunidades"
        },
        "cadence": {
            "title": "Tiempo de cadencia",
            "call-to-action":
            "Al realizar esta configuración, usted delimitará la hora en que se producirá la oportunidad " +
            "en esta fase. Al final del tempo, la oportunidad será reenviada a otra fase.",
            "phaseId": "Siguiente fase",
            "time": "Tiempo en días",
            "clear-config": "Borrar configuración"
        },
        "entry-schedules": {
            "title": "Automatización de programación",
            "call-to-action": "Configure aquí para crear cronogramas en la oportunidad al ingresar a la fase",
            "opportunity-scheduling-type": "Tipo de programa de oportunidad",
            "description": "Descripción de Horario",
            "time-type": {
                "label": "Tipo de ajuste de hora",
                "AT_THE_TIME": "En el momento",
                "AMOUNT_HOURS": "Hora(s)/minuto(s) por delante",
                "AMOUNT_DAYS": "Día (s) por delante",
                "EXACT": "Exactamente"
            },
            "amount-time": "Tiempo",
            "amount-days": "Día(s)",
            "exact-time": "Tiempo",
        },
        "trigger": {
            "title": "Disparadores",
            "help": "Disparadores que se ejecutarán al cambiar la fase",
            "template": "Plantilla",
            "automatic": {
                "label": "Automático",
                "help":
                    "El envio se realizará automáticamente después de que se cambie la fase. " +
                    "Puede configurar junto a cuántos minutos se deben esperar para que se realice el envío\n"
            },
            "send-customer": {
                "label": "Enviar para el cliente",
                "help":
                    "Utilice esta opción para enviar la plantilla al cliente. " +
                    "Si es una plantilla de correo electrónico, se enviará a todos los correos electrónico " +
                    "de los clientes, ahora si es una plantilla de SMS, se enviará a todos los celulares " +
                    "del cliente. Esta opcíon no se puede utilizar junto con los usuarios para enviar\n"
            },
            "users-id": "Usuarios a enviar",
            "minutes": {
                "label": "Enviar después",
                "placeholder": "minutos"
            },
            "add": "Agregar disparadores"
        },
        "automation-activity": {
            "title": "Automatización de actividades",
            "info": "La automatización de actividades bloqueará el cambio de fase del embudo si no se realizan. " +
                "Las actividades se considerarán realizadas cuando se registre un Correo electrónico, " +
                "WhatsApp, SMS o Llamadadesde la Oportunidad, o cuando un agendamiento se marque como realizado. " +
                "Los agendamientos también se considerarán como actividades a cumplir.",
            "registered-activity-opportunity": "Actividad registrada en la oportunidad",
            "registered-schedules-opportunity": "Agendamientos registrados en la oportunidad",
            "registered-schedules-opportunity-empty": "No hay agendamientos registrados en la oportunidad",
            "schedule": {
                "label": "Agendamiento",
                "add": "Agregar agendamiento",
                "status": {
                    "label": "Estado",
                    "OPEN": "Agendada",
                    "ACCOMPLISHED": "Realizada"
                }
            },
            "quantity": "Cantidad",
            "comment": "Comentario",
            "whatsapp": "WhatsApp",
            "call": "Llamada",
            "sms": "SMS"
        }

    },
    "opportunity-scheduling-types": {
        "title": "Clases de programación",
        "call-to-action": "Agregar y editar los tipos de programaciones de oportunidades que usa su suscripción.",
        "add": "Agregar tipo",
        "item": {
            "name": "Nombre",
            "phase": {
                "label": "Fase",
                "help":
                    "Cuando dicha programación se marca como realizada, la oportunidad " +
                    "en cuestión tendrá su fase cambiada a la seleccionada aquí. " +
                    "Este cambio de fase solo se produce si la oportunidad está en una fase anterior a la seleccionada"
            },
            "dashboard": {
                "label": "Ver en el panel",
                "help": "Define si el tipo de programación debe mostrarse en el gráfico en el módulo de panel"
            }
        },
        "delete": {
            "title": "Eliminar tipo de programación",
            "comment": "Está seguro que desea eliminar este tipo de programación?",
            "forbidden":
                "No puede eliminar este tipo de programación. Tiene que haber, al menos, " +
                "un tipo de programación en el sistema."
        },
    },
    "sources": {
        "title": "Orígenes",
        "call-to-action": "Agregar, editar y eliminar los orígenes de oportunidad que usa su suscripción.",
        "add": "Agregar origen",
        "item": {
            "placeholder": "Nombre del origen"
        },
        "delete": {
            "title": "Eliminar origen",
            "comment": "Está seguro que desea eliminar este origen?",
            "forbidden": "No puede eliminar este origen. Tiene que haber, al menos, un origen en el sistema."
        }
    },
    "loss-reasons": {
        "title": "Razones de venta perdidas",
        "call-to-action":
            "Agregar, editar y eliminar motivos de ventas perdidas que seusarán en las oportunidades " +
            "que utiliza la aplicación.",
        "add": "Agregar",
        "item": {
            "placeholder": "Nombre de la razón de venta perdida"
        },
        "delete": {
            "title": "Eliminar",
            "comment": "Está seguro de que desea eliminar esta razón de venta perdida?",
            "forbidden":
                "No se puede eliminar esta razón de venta perdida. Tiene que haber, al menos, " +
                "una razón de venta perdida en el sistema."
        }
    },
    "product-categories": {
        "header": "Produtos e categorias de produtos",
        "title": "Categorías",
        "call-to-action":
            "Agregar, editar y eliminar categorías utilizadas en productos"
            + "su aplicación.",
        "empty": "No hay categorías de productos",
        "add": "Nueva categoría",
        "item": {
            "placeholder": "Nombre de la categoría del producto"
        },
        "delete": {
            "title": "Borrar",
            "comment": "¿Está seguro de que desea eliminar esta categoría de producto?"
        }
    },
    "form": {
        "add-grouping": "Agregar agrupación...",
        "add-field": "Agregar campo...",
        "layout": {
            "title": "Diseño de la { form }",
            "comment":
                "Haga clic en los campos para editarlos, haga clic en el botón  \"Agregar agrupación...\" " +
                "Para crear una nueva agrupación \"Agregar campo...\" para crear uno nuevo."
        },
        "default-grouping-name": "Agrupación",
        "default-field-name": "Campo",
        "sorting": {
            "undo": "Deshacer",
            "save": "Guardar pedido"
        },
        "field-config": {
            "name": {
                "label": "Nombre de campo"
            },
            "type": {
                "label": "Tipo de campo"
            },
            "funnel": {
                "label": "Embudos"
            },
            "required": {
                "label": "Campo obligatorio"
            },
            "mainScreen": {
                "label": "Campo disponible en la lista principal de { module }",
                "help": "Muestra este campo en la lista de oportunidades al acceder al módulo de { module }"
            },
            "summary": {
                "label": "Campo disponible en el resumen de { module }",
                "help-customer":
                    "Muestra este campo en la tabla de resumen, este resumen se coloca en la lateral " +
                    "izquierda de la pantalla al acceder un cliente.",
                "help-opportunity":
                    "Muestra este campo en la tabla de resumen, este resumen se coloca en la lateral " +
                    "izquierda de la pantalla al acceder a una oportunidad."
            },
            "options": {
                "values": {
                    "label": "Opciones",
                    "placeholder": "Agregar una nueva opción"
                }
            },
            "system-field": "Campo fijo del sistema",
            "half-row": {
                "label": "Considerar el 50% de la línea",
                "help":
                    "Este campo tendrá la mitad del ancho de la línea. Si el campo anterir o " +
                    "por debajo de esto también considerar el 50% de la línea, ambos estarán en la misma línea"
            },
            "depends-on": {
                "label": "Condición a otro campo",
                "help":
                    "Condicionar la visualización de este campo a la selección de un valor de " +
                    "otro campo de tipo Seleccionar",
                "field": "Campo",
            },
            "confirm-update-message": "El(los) valor(es) \"{ values }\" está(án) en uso. Desea continuar?"
        },
        "header-config": {
            "name": {
                "label": "Nombre de la agrupación"
            }
        },
        "modules": {
            "COMPANY": "Empresas",
            "PERSON": "Personas",
            "OPPORTUNITY": "Oportunidades"
        },
        "field-types": {
            "text": "Texto",
            "date": "Fecha",
            "email": "Correo electrónico",
            "phone": "Teléfono",
            "number": "Número",
            "address": "Dirección",
            "select": "Seleccionar",
            "select-multiple": "Selección Múltiplo",
            "price": "Valor",
        },
        "delete-field": {
            "comment-field": "Está seguro que desea elimitar este campo?",
            "comment-grouping": "Está seguro que desea eliminar esta agrupación?"
        }
    },
    "products": {
        "header": "Productos y categorías de productos",
        "title": "Productos",
        "subtitle": "Sus productos y servicios",
        "search": "Busque aquí la descripción o el código del producto",
        "selected-importation": "Ver productos creados a través de: { name }",
        "empty": "No hay productos registrados",
        "delete": {
            "title": "Excluir productos",
            "success": "Producto excluido",
            "comment": "Está seguro que desea eliminar este producto?"
        },
        "add": {
            "single": "Nuevo producto",
            "import": "Importar productos"
        },
        "importation": {
            "title": "Importaciones",
            "subheader":
                "Importar productos usando una hoja de cálculo. " +
                "Si procesas productos con códigos ya registrados, se actualizarán",
            "call-to-action":
                "La hoja de cálculo puede contener cuatro columnas: " +
                "descripción, valor, código y nombre de categoría (en ese orden). Sólo se requiere descripción",
            "empty": "No se han creado importaciones",
            "helper-file": "Solo se acepatan archivos .csv, .xls y .xlsx",
            "file-exceed-lines": "La hoja de cálculo no puede tener más de 300 filas.",
            "finished-at": "Completado en",
            "processing": "Procesando",
            "delete":
                "¿Está seguro de que desea eliminar esta importación? " +
                "Todos los productos creados también serán eliminados.",
            "created-products": "Ver productos creados",
        },
        "download": "Exportar todos los productos a una hoja de cálculo (.csv)"
    },
    "product": {
        "code": "Código",
        "description": "Descripción",
        "price": "Precio",
        "measurementUnit": {
            "label": "Unidad de medida",
            "UNIT": "Unidad",
            "METER": "Metro",
            "HOUR": "Hora",
            "KILO": "Kilo",
            "LITER": "Litro"
        },
        "recurrent": "Recurrente",
        "with-recurrence": "Con recurrencia",
        "without-recurrence": "Sin recurrencia",
        "active": "Activo",
        "export-products": {
            "info-title": "Exportar Productos",
            "info-content": "No hay productos para exportar"
        },
        "category": "Categoria"
    },
    "users": {
        "title": "Usuarios",
        "active": "Activos",
        "invitations": "Invitaciones",
        "new-user": "Nuevo usuario",
        "pending-invite": "INVITACION PENDENTE",
        "search": {
            "placeholder": "Buscar usuarios por nombre"
        },
        "new": "Nuevo usuario",
        "limit": "La aplicación tiene un límite de { users } usuario(s) activo(s)"
    },
    "new-user": {
        "title": "Nuevo usuario",
        "name": "Nombre",
        "email": "Correo electrónico",
        "admin": "Administrador",
        "teams": {
            "label": "Equipos",
            "add": "Agregar equipo",
            "leader": "Responsable",
            "team": "Equipo"
        },
        "invitation-sent": "Se envió la invitación a { email }!"
    },
    "user": {
        "admin": "Administrador",
        "admin-at-least": "No es posible hacer este cambio. Deve haber, al menos, un administrador de usuarios.",
        "active": "Activo",
        "active-at-least":
            "No es posible realizar este cambio. Deve haber,al menos, un usuario administrador activo.",
        "two-factor-auth": {
            "title": "Autenticación de dos factores",
            "help": "Al iniciar sesión, se envía un correo electrónico al usuario con el código de verificación"
        },
    },
    "invitations": {
        "sent-at": "Enviado en { date }",
        "no-invites": "No hay invitaciones pendientes."
    },
    "resend": {
        "title": "Reenviar invitación",
        "confirm": "Está seguro que desea reenviar esta invitación?",
        "yes": "Sí, reenviar"
    },
    "delete-invite": {
        "title": "Eliminar invitación",
        "confirm": "Está seguro que desea eliminar esta invitación?",
        "yes": "Eliminar"
    },
    "team": {
        "name": "Nombre",
        "users": {
            "label": "Usuarios",
            "single": "Usuario",
            "leader": "Responsable",
            "add": "Agregar usuario"
        },
        "new": "Nuevo equipo",
        "opportunity-distribution-types": {
            "label": "Tipo de distribución de oportunidades",
            "weighted": "Ponderada",
            "proportional": "Proporcional",
            "add": "Agregar usuario",
            "weight": "Peso"
        },
        "weight": {
            "label": "Pesos",
            "validation": "La suma de los pesos debe ser igual a 10",
            "info":
                "Para una distribución ponderada de oportunidades, es necesario asignar a " +
                "cada usuario un peso individual en una escala del 1 al 10, indicando el grado " +
                "de relevancia de cada usuario en la recepción de oportunidades."
        }
    },
    "teams": {
        "title": "Equipos",
        "new": "Nuevo equipo",
        "no-teams": "No hay equipos registrados."
    },
    "new-team": {
        "title": "Nuevo equipo"
    },
    "goal": {
        "name": "Nombre",
        "type": {
            "label": "Tipo",
            "OPPORTUNITIES_CREATED": "Oportunidades creadas (la cantidad)",
            "OPPORTUNITIES_SOLD": "Oportunidades ganadas (la cantidad)",
            "OPPORTUNITY_SCHEDULING_ACCOMPLISHED": "Citas de oportunidad realizadas",
            "PRODUCTS_SOLD": "Productos vendidos",
            "SOLD_OPPORTUNITIES_PRICE": "Valor de las oportunidades obtenidas",
            "SOLD_OPPORTUNITIES_RECURRENT_PRICE": "Valor recurrente de las oportunidades ganadas",
            "AVERAGE_SERVICE_TIME": "Tiempo medio de servicio (minutos)",
            "CUSTOM_FIELD": "Campo personalizado"
        },
        "opportunity-scheduling-type": "Tipo de programa de oportunidad",
        "products": "Productos de oportunidad",
        "product-categories": "Categorías de producto",
        "target": "Objetivo",
        "help-target":
            "El objetivo debe representar el número que los usuarios deben alcanzar de acuerdo con el " +
            "con el tipo de objetivo dentro do mes",
        "help-users":
            "Puede establecer este objetivo para un equipo o usuarios específicos. " +
            "Si no se define un equipo o usuarios, este objetivo se considera para todos los usuarios. " +
            "Los responsables del equipo seleccionado, no serán considerados.",
        "help-type": "Suma los valores de los campos numéricos y de valor de las oportunidades vendidas en el período",
        "team": "Equipo",
        "users": "Usuarios",
        "all-users": "Todos los usuarios",
        "duration": {
            "label": "Duración",
            "help": "Si no se informa de ninguna fecha, siempre se considerará el objetivo"
        },
        "initial-date": "Fecha de inicio",
        "final-date": "Fecha final",
        "interval": {
            "label": "Intervalo",
            "help": "Intervalo de tiempo a tener en cuenta al calcular el progreso del objetivo",
            "MONTHLY": "Mensual",
            "QUARTERLY": "Trimestral",
            "SEMIANNUAL": "Semestral",
            "ANNUAL": "Anual"
        },
        "future": {
            "label": "Objetivo futuro",
            "help": "Objetivos que tienen una fecha de inicio superior a la fecha actual"
        },
        "past": {
            "label": "Objetivo pasado",
            "help": "Objetivos que tienen la fecha de finalización por debajo de la fecha actual"
        },
        "confirm-delete": "Está seguro de que desea eliminar este objetivo?",
        "collective": "Objetivo colectivo",
        "collective-helper": "El objetivo colectivo cuando esté activo considerará el " +
        "valor del objetivo para todos los miembros del equipo o usuarios seleccionados"
    },
    "new-goal": "Nuevo objetivo",
    "edit-goal": "Editar objetivo",
    "empty-goals": "No hay objetivos registrados",
    "template": {
        "name": "Nombre",
        "type": {
            "label": "Tipo",
            "EMAIL": "Correo electrónico",
            "SMS": "SMS",
            "WHATSAPP": "WhatsApp"
        },
        "share": "Compartir con otros usuarios",
        "share-help": "Otros usuarios pueden usar la plantilla para enviarla al cliente",
        "close": "Cerrar",
        "save": "Guardar"
    },
    "commissions": {
        "title": "Comissões",
        "call-to-action": "Defina as comissões dos usuários baseadas nas oportunidades ganhas",
        "empty": "Não há comissões cadastradas"
    },
    "commission": {
        "new": "Nueva comisión",
        "delete": {
            "title": "Excluir comisión",
            "confirm": "¿Está seguro de que desea eliminar esta comisión?",
        },
        "edit": "Editar comisión",
        "type": {
            "OPPORTUNITY": "Oportunidad",
            "PRODUCT": "Producto"
        },
        "opportunity-price": "Considere el valor de oportunidad",
        "opportunity-recurrent-price": "Considere el valor de oportunidad recurrente",
        "products": {
            "at-least": "Seleccione al menos una categoría o producto"
        },
        "price-type": {
            "label": "Tipo de valor",
            "UNIT": "Unitario",
            "PERCENTAGE": "Porcentaje"
        },
        "closing-day": {
            "label": "Día de cierre",
            "helper": "Día del mes en que se cerrará la comisión",
            "bigger": "No puede ser mayor a 30",
            "lower": "No puede ser menos de 1",
        },
        "goal": {
            "label": "Meta",
            "helper": "Meta a alcanzar para condicionar la aprobación de la comisión",
        },
        "users-helper":
            "Si no se selecciona ningún equipo o usuario, la comisión se considerará para todos los usuarios",
    },
    "templates": {
        "title": "Correo electrónico/SMS/WhatsApp",
        "call-to-action": "Administra tus plantillas de envio de Corrreo electrónico, SMS y WhatsApp",
        "add": "Agregar plantilla",
        "no-templates": "No hay plantillas registradas",
        "created-by": "Creado por { user } en { date } ",
        "updated-by": "- Cambiado por { user } en { date }",
        "type": {
            "EMAIL": "Correo electrónico",
            "SMS": "SMS",
            "WHATSAPP": "WhatsApp",
            "empty": "No hay plantillas de este tipo registradas"
        },
        "delete": {
            "confirm": "Está seguro que desea eliminar esta plantilla?",
            "yes": "Si",
            "no": "No"
        },
        "new": "Nueva plantilla",
        "edit": "Editar plantilla",
        "test": "Enviar prueba",
        "test-submitted": "Correo electrónico enviado correctamente",
        "email": "Correo electrónico",
        "email-test-description": "Por favor introduzca el correo electrónico que recibirá esta prueba a continuación"
    },
    "files": {
        "title": "Documentos",
        "call-to-action": "Registre aquí sus plantillas de documentos",
        "empty-models": "No existen modelos de documentos registrados",
        "model": {
            "name": "Nombre",
            "new": "Nueva plantilla de archivo",
            "config-header": "Configurar encabezado",
            "config-footer": "Configurar pie de página",
            "confirm-delete": "Está seguro que desea eliminar esta plantilla?",
            "header": "Encabezado",
            "header-height": "altura del encabezado",
            "header-margin": "Alinear el encabezado con el margen del documento",
            "footer": "Pie de página",
            "footer-height": "Altura del pie de página",
            "footer-margin": "Alinear el pie de página con el margen del documento",
            "section-list": "Secciones",
            "section": {
                "confirm-delete": "Está seguro que desea eliminar esta sección?",
                "edit": "Editar sección",
                "new": "Nueva sección",
                "name": "Nombre de la sección",
                "fields": "Campos",
                "form": "Forma",
                "overview": "General",
                "date-time": "Fecha y hora",
                "date": "Fecha",
                "time": "Horario",
                "company": "Empresa",
                "person": "Persona",
                "opportunity": "Oportunidad",
                "products-all": "Productos (todos)",
                "products-with-recurrence": "Productos con recurrencia",
                "products-without-recurrence": "Productos sin recurrencia",
                "proposal": "Propuesta",
                "proposal-sequence": "Secuencia",
                "proposal-sequence-help": "Número secuencial de la propuesta creada por oportunidad",
                "body": "Cuerpo",
                "in-words": "por extenso",
                "show-fields": "Mostrar campos",
                "hide-fields": "Ocultar campos"
            },
            "section-preview": {
                "title": "Visualizar sección",
                "description": "Para reemplazar los campos, puede seleccionar una empresa, persona u oportunidad"
            }
        }
    },
    "images": {
        "title": "Galería de imágenes",
        "subheader": "Aquí se enumeran las imágenes registradas que se pueden utilizar en las plantillas",
        "created-by": "Creado por { user } en { date }",
        "delete": {
            "confirm": "Está seguro que desea eliminar esta imagen?",
            "yes": "Sí",
            "no": "No"
        }
    },
    "kanban": {
        "title": "Kanban",
        "help": "Configura aquí los campos a mostrar en el kanban de tus oportunidades",
        "company-name": "Nombre de la empresa",
        "person-name": "Nome da pessoa",
        "phone-help":
            "Si hay una persona vinculada, intente obtener el primer número de teléfono de la persona, " +
            "si no, intente obtener el primer número de teléfono de la empresa",
        "email-help":
            "Si hay una persona vinculada, intente obtener el primer correo electrónico de la persona, " +
            "si no, intente obtener el primer correo electrónico de la empresa.",
        "other-fields": "Otros campos del formulario. Disponible para selección: {selected} de {max}",
        "field": "Campo",
    },
    "distribution": {
        "title": "Distribucións",
        "detail": "Añade, elimina y edita tus distribuciones. Estas configuraciones sustituyen las reglas de " +
            "distribución de las integraciones a las que están vinculadas, permitiendo un enfoque más colaborativo. " +
            "Puedes realizar distribuciones por equipo, siguiendo las reglas preexistentes del equipo, o por " +
            "usuarios, adoptando un modelo proporcional. La distribución actúa de manera independiente de las " +
            "integraciones, promoviendo una distribución más equitativa de las oportunidades. En otras palabras, " +
            "si una oportunidad llega a las integraciones X e Y, se distribuirá teniendo en cuenta el conjunto " +
            "de integraciones. Por ejemplo, si la oportunidad llega a la integración X y luego a la integración " +
            "Y, la distribución garantizará que las oportunidades se envíen a vendedores diferentes si la regla de " +
            "distribución es proporcional, realizando una asignación más justa y eficiente.",
        "empty-distributions": "No hay distribuciones registradas",
        "empty-users": "Debes seleccionar un equipo o al menos un usuario para distribuir las oportunidades",
        "add": "Añadir distribución",
        "new": "Nueva distribución",
        "delete-distribution": {
            "title": "Eliminar distribución",
            "detail": "Elige la regla de distribución que se aplicará a las integraciones actualmente asociadas a la " +
                "distribución que se está eliminando."
        }
    },
};