import { useState } from "react";
import { useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import AlarmIcon from "@mui/icons-material/Alarm";
import EditIcon from "@mui/icons-material/Edit";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotesIcon from "@mui/icons-material/Notes";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex, Dialog, Input } from "../../ui";
import GoogleIcon from "../../ui/icons/google.component";
import SchedulingDelete from "./scheduling-delete.component";
import SchedulingDemo from "./scheduling-demo.component";
import SchedulingSocialOptions from "./scheduling-social-options.component";
import css from "./scheduling.scss";
import { saveScheduling } from "../opportunity.actions";
import { isLate } from "../../core/date.utils";
import { SAVE_SCHEDULING } from "../opportunity.constants";
import isEmpty from "lodash/isEmpty";

const SchedulingItem = ({ last, scheduling }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const [ menuAnchorEl, setMenuAnchorEl ] = useState( null );
    const [ showConfirm, setShowConfirm ] = useState( false );
    const [ openDetails, setOpenDetails ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ newStatus, setNewStatus ] = useState( "OPEN" );

    const formik = useForm({
        form: SAVE_SCHEDULING,
        enableReinitialize: true,
        initialValues: {
            id: scheduling.id,
            status: "",
            notAccomplishedReason: null,
        },
        onSubmit: values => dispatch( saveScheduling({ ...values, form: SAVE_SCHEDULING }) ),
    });

    const openMenu = event => setMenuAnchorEl( event.currentTarget );
    const closeMenu = () => setMenuAnchorEl( null );

    const toggleDetails = () => {
        setOpenDetails( !openDetails );
        closeMenu();
    };

    const toggleDelete = () => {
        setOpenDelete( !openDelete );
        closeMenu();
    };

    const toggleEdit = () => {
        setOpenEdit( !openEdit );
        closeMenu();
    };

    const handleStatusChange = status => () => {
        formik.setFieldValue( "status", status );
        formik.submitForm();
    };

    const toggleConfirm = status => () => {
        setShowConfirm( !showConfirm );
        setNewStatus( scheduling.status === status ? "OPEN" : status );
        closeMenu();
    };

    const late = isLate( scheduling.startDate );
    const user = scheduling.user ? scheduling.user : scheduling.opportunity.user;

    return (
        <>
            <ListItem
                secondaryAction={
                    <IconButton onClick={ openMenu } size="large">
                        <MoreVertIcon />
                    </IconButton>
                }
                disablePadding
            >
                <ListItemButton dense onClick={ toggleDetails }>
                    <ListItemAvatar>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            badgeContent={
                                late ?
                                    <Tooltip title={ t( "opportunity:scheduling.late" ) }>
                                        <Avatar classes={{ root: css.avatarLate }}>
                                            <AlarmIcon/>
                                        </Avatar>
                                    </Tooltip> :
                                    0
                            }
                        >
                            <Tooltip title={ user.name }>
                                <Avatar
                                    src={ user.avatar }
                                >
                                    { user.avatar ? "" : user.name.charAt( 0 ) }
                                </Avatar>
                            </Tooltip>
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            scheduling.opportunitySchedulingType.name +
                            " - " + dateFormat( scheduling.startDate )
                        }
                        secondary={ scheduling.status !== "OPEN" &&
                            ( scheduling.status === "ACCOMPLISHED" ?
                                t( "opportunity:scheduling.accomplished" ) :
                                `${ t( "opportunity:scheduling.not-accomplished" ) }${
                                    isEmpty( scheduling.notAccomplishedReason ) ?
                                        "" :
                                        `, ${ scheduling.notAccomplishedReason }`
                                }`
                            )
                        }
                    />
                </ListItemButton>
            </ListItem>
            <Menu open={ !!menuAnchorEl } onClose={ closeMenu } anchorEl={ menuAnchorEl }>
                <MenuItem onClick={ toggleConfirm( "ACCOMPLISHED" ) }>
                    <ListItemIcon>
                        <EventAvailableIcon
                            className={
                                scheduling.status === "ACCOMPLISHED" ?
                                    css.statusAccomplished
                                    : scheduling.status !== "OPEN" ? css.statusDisabled : null
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary={ t( "opportunity:scheduling.accomplished" ) } />
                </MenuItem>
                <MenuItem onClick={ toggleConfirm( "NOT_ACCOMPLISHED" ) }>
                    <ListItemIcon>
                        <EventBusyIcon
                            className={
                                scheduling.status === "NOT_ACCOMPLISHED" ?
                                    css.statusNotAccomplished
                                    : scheduling.status !== "OPEN" ? css.statusDisabled : null
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary={ t( "opportunity:scheduling.not-accomplished" ) } />
                </MenuItem>
                <Divider />
                <MenuItem onClick={ toggleEdit }>
                    <ListItemIcon>
                        <EditIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:edit" ) } />
                </MenuItem>
                <MenuItem onClick={ toggleDelete }>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:exclude" )} />
                </MenuItem>
            </Menu>
            <Collapse className={ openDetails ? cssUtils.marginBottomSmall : null } in={ openDetails }>
                <ListItem className={ cssUtils.paddingLeftLarge } dense>
                    <ListItemIcon>
                        <NotesIcon/>
                    </ListItemIcon>
                    <ListItemText secondary={ scheduling.description }/>
                </ListItem>
                {
                    scheduling.social &&
                        <ListItem className={ cssUtils.paddingLeftLarge } dense>
                            <ListItemIcon>
                                <GoogleIcon/>
                            </ListItemIcon>
                            <ListItemText secondary={ t( "schedule:event.social.linked" ) }/>
                            <ListItemSecondaryAction>
                                <SchedulingSocialOptions scheduling={ scheduling }/>
                            </ListItemSecondaryAction>
                        </ListItem>
                }
            </Collapse>
            <Paper>
                <Collapse in={ openEdit } unmountOnExit>
                    <SchedulingDemo
                        className={ flex.fill }
                        scheduling={ scheduling }
                        onDeselect={ toggleEdit }
                    />
                </Collapse>
            </Paper>
            { !last && <Divider/> }

            <SchedulingDelete
                open={ openDelete }
                scheduling={ scheduling }
                onClose={ toggleDelete }
            />

            <Dialog disableScrollLock open={ showConfirm }>
                <DialogContent>
                    <Form formik={ formik }>
                        <DialogContentText>
                            {
                                newStatus === "ACCOMPLISHED" ?
                                    t( "opportunity:scheduling.confirm-accomplished" ) :
                                    newStatus === "NOT_ACCOMPLISHED" ?
                                        t( "opportunity:scheduling.confirm-not-accomplished" ) :
                                        newStatus === "OPEN" ?
                                            t( "opportunity:scheduling.reopen-scheduling" ) :
                                            null
                            }
                        </DialogContentText>
                        {
                            newStatus === "NOT_ACCOMPLISHED" &&
                                <Field
                                    name="notAccomplishedReason"
                                    type="text"
                                    label={ t( "opportunity:scheduling.not-accomplished-reason" ) }
                                    component={ Input }
                                />
                        }
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={ formik.submitting }
                        color="primary"
                        onClick={ toggleConfirm( newStatus ) }
                    >
                        { t( "common:no" ) }
                    </Button>
                    <Button
                        disabled={ formik.submitting }
                        color="primary"
                        onClick={ handleStatusChange( newStatus ) }
                    >
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SchedulingItem;