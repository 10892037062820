import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as smtpServerActions, selectors as smtpServerSelectors } from "../../../smtp-server";
import ConfigHeader from "../../config-header.component";
import SmtpServerDefaultForm from "./smtp-server-default-form.component";
import DeleteSmtpServerDefault from "./delete-smtp-server-default.component";

const SmtpServer = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const defaultSettings = useSelector( smtpServerSelectors.getDefaultSettings );
    const loading = useSelector( smtpServerSelectors.isLoadingDefaultSettings );

    const [ openForm, setOpenForm ] = useState( false );
    const toggleForm = () => setOpenForm( !openForm );

    useEffect( () => {
        dispatch( smtpServerActions.fetchSmtpServerDefault() );
    }, [ dispatch ] );

    return (
        <div>
            <ConfigHeader title={ t( "config:smtp-server-default.email" ) }/>
            <Card>
                <CardHeader
                    title="SMTP"
                    subheader={ t( "config:smtp-server-default.call-to-action" ) }
                />

                {
                    !loading && !defaultSettings &&
                    <ListItemButton component="div" onClick={ toggleForm }>
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText primary={ t( "config:smtp-server-default.create" ) }/>
                    </ListItemButton>
                }
                {
                    !loading && defaultSettings &&
                        <ListItem
                            component="div"
                            disablePadding
                            secondaryAction={ <DeleteSmtpServerDefault/> }
                        >
                            <ListItemButton onClick={ toggleForm }>
                                <ListItemText
                                    primary={ `${defaultSettings.host}:${defaultSettings.port}` }
                                    secondary={
                                        t( "user:smtp-server.security-type" ) + ": " +
                                        (
                                            defaultSettings.securityType === "NONE" ?
                                                t( "common:none" ) :
                                                defaultSettings.securityType
                                        ) +
                                        " - " +
                                        (
                                            defaultSettings.auth ?
                                                t( "user:smtp-server.auth" ) :
                                                t( "user:smtp-server.no-auth" )
                                        )
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                }

                <SmtpServerDefaultForm open={ openForm } onClose={ toggleForm }/>
            </Card>
        </div>
    );
};

export default SmtpServer;