import * as productActions from "./product.actions";

export const productConfig = ( state = {}, action ) => {
    switch ( action.type ) {
        case productActions.DELETE_PRODUCT:
            return {
                ...state,
                deleting: action.meta.id
            };

        case productActions.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                deleting: null
            };

        case productActions.DELETE_PRODUCT_ERROR:
            return {
                ...state,
                deleting: null,
                deleteError: action.error
            };

        case productActions.REQUEST_IMPORTATIONS:
            return {
                ...state,
                importations: {
                    data: [],
                    loading: true,
                }
            };
        case productActions.RECEIVE_IMPORTATIONS:
            return {
                ...state,
                importations: {
                    data: action.payload,
                    loading: false,
                }
            };
        case productActions.ERROR_IMPORTATIONS:
            return {
                ...state,
                importations: {
                    data: [],
                    loading: false,
                    error: action.payload
                }
            };
        case productActions.SET_IMPORTATION:
            return {
                ...state,
                importations: {
                    selected: action.payload
                }
            };

        default:
            return state;
    }
};