import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

import useTranslate from "../../../hooks/use-translate.hook";
import { saveUser } from "./user.actions";

const createFormName = user => ( "userTwoFactorAuth" + user.id );

const UserTwoFactorAuth = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formName = createFormName( user );

    const formik = useForm({
        form: formName,
        enableReinitialize: true,
        initialValues: {
            id: user.id,
            twoFactorAuth: !user.twoFactorAuth,
        },
        onSubmit: values => dispatch( saveUser( values, formName ) )
    });

    return (
        <Tooltip title={ t( "config:user.two-factor-auth.help" ) }>
            <FormControlLabel
                label={ t( "config:user.two-factor-auth.title" ) }
                control={
                    <Switch
                        checked={ user.twoFactorAuth }
                        disabled={ formik.submitting }
                        onChange={ formik.submitForm }
                        size="small"
                    />
                }
            />
        </Tooltip>
    );
};

export default UserTwoFactorAuth;