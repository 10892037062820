import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./funnel.actions";

const all = createRequestReducer({
    begin: actions.REQUEST_FUNNELS,
    success: actions.RECEIVE_FUNNELS,
    failure: actions.ERROR_FUNNELS
});

export const funnels = combineReducers({ all });