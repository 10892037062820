import { useMemo } from "react";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";

import useTranslate from "../hooks/use-translate.hook";
import Dropdown from "./dropdown.component";

const SelectCustomerType = props => {
    const t = useTranslate();

    const source = useMemo( () => [
        { value: "COMPANY", label: t( "customer:company.title" ), icon: <BusinessIcon/> },
        { value: "PERSON", label: t( "customer:person.title" ), icon: <PersonIcon/> }
    ], [ t ] );

    return (
        <Dropdown
            { ...props }
            label={ t( "customer:type" ) }
            source={ source }
        />
    );
};

export default SelectCustomerType;