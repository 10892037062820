import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { DialogInfo, Input, RadioButtons, flex, cssUtils } from "../../ui";
import TagsField from "../tags-field.component";
import { getUser } from "../../core/core.selectors";
import { updateCustomers } from "../customer.actions";
import { UPDATE_CUSTOMERS_FORM } from "../customer.constants";
import css from "./batch-options.scss";

const BulkUpdateForm = ({ customerType, ids, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( getUser );

    const validate = useCallback( values => {
        const errors = {};
        if ( !values ) {
            return errors;
        }

        if ( values.type === "DELETE" ) {
            if ( !values.confirmation ) {
                errors.confirmation = "common:validation.required";
            } else if ( values.confirmation !== t( "customer:batch-options.delete.confirmation.label" ) ) {
                errors.confirmation = "common:validation.required";
            }
        }

        return errors;
    }, [ t ] );

    const formik = useForm({
        form: UPDATE_CUSTOMERS_FORM,
        enableReinitialize: true,
        initialValues: {
            type: "UPDATE",
            customerType,
            ids,
            notes: "",
            tags: [],
            comment: "",
            confirmation: "",
        },
        onSubmit: values => dispatch( updateCustomers( values ) ),
        validate,
    });

    const prevSubmitting = usePrevious( formik.submitting );
    const [ informSubmitSuccess, setInformSubmitSuccess ] = useState( false );
    const closeSubmitSuccess = () => {
        setInformSubmitSuccess( false );
        onClose();
    };

    const disabledSubmit = useMemo( () => {
        if ( formik.values.type === "UPDATE" ) {
            return formik.submitting ||
                ( isEmpty( formik.values.tags ) && !formik.values.notes && !formik.values.comment );
        }
        return formik.submitting;
    }, [ formik.values, formik.submitting ] );

    useEffect( () => {
        if ( !prevSubmitting && formik.submitting ) {
            setInformSubmitSuccess( true );
        }
    }, [ prevSubmitting, formik.submitting, onClose ] );

    return (
        <>
            <Drawer
                variant="persistent"
                anchor="right"
                classes={{ paper: css.drawerPaper }}
                open
            >
                <div className={ css.container }>
                    <Typography variant="subtitle1" gutterBottom>
                        { t( "customer:batch-options.title" ) }
                    </Typography>

                    <Typography variant="caption" color="textSecondary" gutterBottom>
                        { t( "customer:batch-options.description" ) }
                    </Typography>
                    <Form formik={ formik }>
                        <div className={ cssUtils.marginTop }>
                            <Field
                                label={ t( "common:mode" ) }
                                name= "type"
                                component={ RadioButtons }
                                className={ classnames( cssUtils.marginTopSmall, cssUtils.marginBottom ) }
                                fullWidth
                                options={[
                                    {
                                        value: "UPDATE",
                                        label: t( "common:update" ),
                                        className: flex.fill
                                    }, {
                                        value: "DELETE",
                                        label: t( "common:exclude" ),
                                        className: flex.fill
                                    }
                                ]}
                            />
                            {
                                formik.values.type === "DELETE" &&
                                    <>
                                        {
                                            !user.admin &&
                                                <Typography
                                                    className={ cssUtils.marginBottomSmall }
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    { t( "customer:batch-options.delete.non-admin" ) }
                                                </Typography>
                                        }
                                        <Typography variant="body2">
                                            { t( "customer:batch-options.delete.confirmation.description" ) }
                                            { " " }
                                            <b>{ t( "customer:batch-options.delete.confirmation.label" ) }</b>
                                        </Typography>
                                        <Field
                                            name="confirmation"
                                            label={ t( "customer:batch-options.delete.confirmation.label" ) }
                                            component={ Input }
                                            required
                                        />
                                    </>
                            }

                            {
                                formik.values.type === "UPDATE" &&
                                    <>
                                        <Field
                                            name="tags"
                                            label={ t( "customer:tags.title" ) }
                                            className={ cssUtils.marginBottomSmall }
                                            component={ TagsField }
                                            helperText={ "As tags existentes nos clientes não serão removidas" }
                                        />
                                        <Field
                                            name="notes"
                                            label={ t( "customer:notes.title" ) }
                                            className={ cssUtils.marginBottomSmall }
                                            component={ Input }
                                            helperText="Texto para ser adicionado nas anotações do cliente"
                                            multiline
                                            rows="4"
                                            rowsMax="10"
                                        />
                                        <Field
                                            name="comment"
                                            label={ t( "customer:new-comment.title" ) }
                                            component={ Input }
                                            multiline
                                            rows="4"
                                            rowsMax="10"
                                        />
                                    </>
                            }

                            <div className={ classnames( flex.container, cssUtils.marginTopSmall ) }>
                                <Button className={ flex.fill } onClick={ onClose }>
                                    { t( "common:cancel" ) }
                                </Button>
                                <Button
                                    disabled={ disabledSubmit }
                                    className={ flex.fill }
                                    type="submit"
                                >
                                    { formik.values.type === "UPDATE" ? t( "common:update" ) : t( "common:exclude" ) }
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Drawer>

            <DialogInfo
                open={ informSubmitSuccess }
                onClose={ closeSubmitSuccess }
                message={ t( "customer:batch-options.success-content" ) }
                title={ t( "customer:batch-options.success-title" ) }
            />
        </>
    );
};

export default BulkUpdateForm;