import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import set from "lodash/set";

import useTranslate from "../../../hooks/use-translate.hook";
import { isEmail } from "../../../core/validator.utils";
import { Checkbox, Input } from "../../../ui";
import { actions as teamActions } from "../../../team";
import { CONFIG_FORM_NEW_USER } from "./user.constants";
import { inviteUser } from "./user.actions";
import UserTeamsField from "./user-teams-field.component";

const NewUser = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showError, setShowError ] = useState( false );

    const closeError = () => setShowError( false );

    const formik = useForm({
        form: CONFIG_FORM_NEW_USER,
        initialValues: {
            name: "",
            email: "",
            admin: false,
            config: {
                teams: [],
            },
        },
        onSubmit: values => dispatch( inviteUser( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.email ) {
                errors.email = "common:validation.required";
            } else if ( !isEmail( values.email ) ) {
                errors.email = "common:validation.email";
            }
            if ( values.config && values.config.teams ) {
                values.config.teams.forEach( ( team, index ) => {
                    if ( !team || !team.id ) {
                        set( errors, `config.teams[${index}].id`, "common:validation.selection" );
                    }
                });
            }
            return errors;
        }
    });

    useEffect( () => {
        dispatch( teamActions.fetchTeams() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    return (
        <>
            <Snackbar
                open={ showError }
                action={
                    <Button color="secondary" onClick={ closeError }>
                        { "OK" }
                    </Button>
                }
                message={ formik.error }
                onClose={ closeError }
            />

            <Dialog
                open={ open }
                onClose={ onClose }
            >
                <DialogTitle>{ t( "config:new-user.title" ) }</DialogTitle>
                <DialogContent>
                    <Form formik={ formik } noValidate>
                        <fieldset disabled={ formik.submitting }>
                            <Field
                                type="text"
                                name="name"
                                label={ t( "config:new-user.name" ) }
                                component={ Input }
                                autoComplete="off"
                                required
                                autoFocus />
                            <Field
                                type="email"
                                name="email"
                                label={ t( "config:new-user.email" ) }
                                component={ Input }
                                required
                                autoComplete="off" />

                            <Field type="checkbox" name="admin" component={ Checkbox }>
                                { t( "config:new-user.admin" ) }
                            </Field>

                            <FieldArray
                                name="config.teams"
                                component={ UserTeamsField }
                            />
                        </fieldset>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewUser;