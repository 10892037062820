// ---
// BILLINGS
// ---
export const FETCH_SUBSCRIPTION_BILLING = "x/config/subscription/plan/FETCH_SUBSCRIPTION_BILLING";
export const fetchSubscriptionBilling = () => ({
    type: FETCH_SUBSCRIPTION_BILLING
});

export const REQUEST_SUBSCRIPTION_BILLING = "x/config/subscription/plan/REQUEST_SUBSCRIPTION_BILLING";
export const requestSubscriptionBilling = () => ({
    type: REQUEST_SUBSCRIPTION_BILLING
});

export const RECEIVE_SUBSCRIPTION_BILLING = "x/config/subscription/plan/RECEIVE_SUBSCRIPTION_BILLING";
export const receiveSubscriptionBilling = data => ({
    type: RECEIVE_SUBSCRIPTION_BILLING,
    payload: data
});

export const ERROR_SUBSCRIPTION_BILLING = "x/config/subscription/plan/ERROR_SUBSCRIPTION_BILLING";
export const errorSubscriptionBilling = error => ({
    type: ERROR_SUBSCRIPTION_BILLING,
    payload: error
});

export const RECEIVE_SUBSCRIPTION_COUPON = "x/config/subscription/plan/RECEIVE_SUBSCRIPTION_COUPON";
export const receiveSubscriptionCoupon = data => ({
    type: RECEIVE_SUBSCRIPTION_COUPON,
    payload: data
});

export const SAVE_SUBSCRIPTION_BILLING = "x/config/subscription/plan/SAVE_SUBSCRIPTION_BILLING";
export const saveSubscriptionBilling = data => ({
    type: SAVE_SUBSCRIPTION_BILLING,
    payload: data
});

export const FETCH_BILLINGS = "x/config/subscription/plan/FETCH_BILLINGS";
export const fetchBillings = page => ({
    type: FETCH_BILLINGS,
    meta: { page }
});

export const REQUEST_BILLINGS = "x/config/subscription/plan/REQUEST_BILLINGS";
export const requestBillings = () => ({
    type: REQUEST_BILLINGS
});

export const RECEIVE_BILLINGS = "x/config/subscription/plan/RECEIVE_BILLINGS";
export const receiveBillings = data => ({
    type: RECEIVE_BILLINGS,
    payload: data
});

export const ERROR_BILLINGS = "x/config/subscription/plan/ERROR_BILLINGS";
export const errorBillings = error => ({
    type: ERROR_BILLINGS,
    payload: error
});

export const DELETE_SUBSCRIPTION_BILLING = "x/DELETE_SUBSCRIPTION_BILLING";
export const deleteSubscriptionBilling = () => ({
    type: DELETE_SUBSCRIPTION_BILLING
});

// ---
// Credit Card
// ---
export const FETCH_CREDIT_CARDS = "x/config/subscription/plan/FETCH_CREDIT_CARDS";
export const fetchCreditCards = () => ({
    type: FETCH_CREDIT_CARDS
});

export const REQUEST_CREDIT_CARDS = "x/config/subscription/plan/REQUEST_CREDIT_CARDS";
export const requestCreditCards = () => ({
    type: REQUEST_CREDIT_CARDS
});

export const RECEIVE_CREDIT_CARDS = "x/config/subscription/plan/RECEIVE_CREDIT_CARDS";
export const receiveCreditCards = creditCards => ({
    type: RECEIVE_CREDIT_CARDS,
    payload: creditCards
});

export const ERROR_CREDIT_CARDS = "x/config/subscription/plan/ERROR_CREDIT_CARDS";
export const errorCreditCards = error => ({
    type: ERROR_CREDIT_CARDS,
    payload: error
});

// ---
// Storage
// ---
export const FETCH_SUBSCRIPTION_STORAGE = "x/config/subscription/plan/FETCH_SUBSCRIPTION_STORAGE";
export const fetchSubscriptionStorage = () => ({
    type: FETCH_SUBSCRIPTION_STORAGE,
});

export const REQUEST_SUBSCRIPTION_STORAGE = "x/config/subscription/plan/REQUEST_SUBSCRIPTION_STORAGE";
export const requestSubscriptionStorage = () => ({
    type: REQUEST_SUBSCRIPTION_STORAGE,
});

export const RECEIVE_SUBSCRIPTION_STORAGE = "x/config/subscription/plan/RECEIVE_SUBSCRIPTION_STORAGE";
export const receiveSubscriptionStorage = storage => ({
    type: RECEIVE_SUBSCRIPTION_STORAGE,
    payload: storage,
});

export const ERROR_SUBSCRIPTION_STORAGE = "x/config/subscription/plan/ERROR_SUBSCRIPTION_STORAGE";
export const errorSubscriptionStorage = error => ({
    type: ERROR_SUBSCRIPTION_STORAGE,
    payload: error,
    error: true,
});