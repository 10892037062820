import { createRequestReducer } from "redux-request-state";
import { combineReducers } from "redux";

import * as actions from "./oauth.actions";

const client = createRequestReducer({
    begin: actions.REQUEST_CLIENT,
    success: actions.RECEIVE_CLIENT,
    failure: actions.ERROR_CLIENT
});

const authorization = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.SET_AUTHORIZATION_CODE:
            return { code: action.payload };
        default:
            return state;
    }
};

export const oauth = combineReducers({ client, authorization });