import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import { Field, FormikProvider } from "formik";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogForm, RadioButtons, cssUtils, flex } from "../../ui";
import { createExportation } from "../customer.actions";
import { EXPORTATION_FORM } from "../customer.constants";
import ExportationColumns from "./exportation-columns.component";

const ExportationForm = ({ type, initialColumns = [] }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const formik = useForm({
        enableReinitialize: true,
        form: EXPORTATION_FORM,
        initialValues: {
            type,
            columns: initialColumns,
            fileExtension: "csv",
        },
        onSubmit: values => dispatch( createExportation( values ) ),
    });

    return (
        <>
            <Tooltip title={ t( "common:export" )}>
                <IconButton onClick={ toggleOpen } size="large">
                    <CloudDownloadIcon/>
                </IconButton>
            </Tooltip>

            <DialogForm
                messages={{
                    submit: t( "common:execute" )
                }}
                open={ open }
                onClose={ toggleOpen }
                title={ t( "customer:exportation.title" ) }
                { ...formik }
            >
                <FormikProvider value={ formik }>
                    <DialogContentText>
                        { t( "customer:exportation.call-to-action" ) }
                    </DialogContentText>
                    <ExportationColumns type={ type }/>
                    <Field
                        label={ t( "customer:exportation.format" ) }
                        name= "fileExtension"
                        component={ RadioButtons }
                        className={ cssUtils.marginTopSmall }
                        fullWidth
                        options={ [ "csv", "xls", "xlsx" ]
                            .map( extension => ( { value: extension, label: extension, className: flex.fill } ) ) }
                    />
                </FormikProvider>
            </DialogForm>
        </>
    );
};

export default ExportationForm;