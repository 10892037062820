import { createSelector } from "reselect";

export const isNewFileModelShown = state => state.config.opportunity.templateConfig.fileModels.newFileModelShown;
export const selectedFileModel = state => state.config.opportunity.templateConfig.fileModels.selected;
export const isDeletingFileModel = createSelector(
    state => state.config.opportunity.templateConfig.fileModels,
    fileModels => fileModels.deleting
);
export const listFileModelSections = state => state.config.opportunity.templateConfig.fileModels.sections;
export const selectedFileModelSection = state => state.config.opportunity.templateConfig.fileModels.selectedIndex;
export const isSortingFileModelSections = state => state.config.opportunity.templateConfig.fileModels.sorting;
export const isDeletingFileModelSection = state => state.config.opportunity.templateConfig.fileModels.deletingSection;