import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import useTranslate from "../hooks/use-translate.hook";
import ConfigMain from "./config-main.component";
import ConfigUsers from "./users/users.component";
import ConfigSubscription from "./subscription/subscription.component";
import ConfigOpportunity from "./opportunity/opportunity.component";
import ConfigAdvanced from "./advanced/advanced.component";

const Config = ({ className }) => {
    const t = useTranslate();

    return (
        <div className={ className }>
            <Helmet title={ t( "config:title" ) } />
            <Routes>
                <Route index element={ <ConfigMain/> } />
                <Route path={ "users/*" } element={ <ConfigUsers/> }/>
                <Route path={ "subscription/*" } element={ <ConfigSubscription/> }/>
                <Route path={ "opportunity/*" } element={ <ConfigOpportunity/> }/>
                <Route path={ "advanced/*" } element={ <ConfigAdvanced/> }/>
            </Routes>
        </div>
    );
};

export default Config;