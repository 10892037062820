import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";

import useTranslate from "../../../hooks/use-translate.hook";
import { getUser } from "../../../core/core.selectors";
import { isEmail } from "../../../core/validator.utils";
import { Input, cssUtils } from "../../../ui";
import { TEMPLATE_TEST_FORM } from "./template.constants";
import { testTemplate } from "./template.actions";

const TemplateBodyTester = ({ body, show, onClose, }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( getUser );

    const [ showSuccessMessage, setShowSuccessMessage ] = useState( false );

    const closeSuccessMessage = () => setShowSuccessMessage( false );

    const formik = useForm({
        form: TEMPLATE_TEST_FORM,
        enableReinitialize: true,
        initialValues: {
            to: user.email,
            body,
        },
        onSubmit: values => dispatch( testTemplate( values ) ),
        onSubmitSuccess: () => {
            setShowSuccessMessage( true );
            onClose();
        },
        validate: values => {
            const errors = {};
            if ( !values.to ) {
                errors.to = "common:validation.required";
            } else if ( !isEmail( values.to ) ) {
                errors.to = "common:validation.email";
            }
            return errors;
        },
    });

    return (
        <>
            <Dialog open={ show } fullWidth>
                <DialogTitle>
                    { t( "config:templates.test" ) }
                </DialogTitle>
                <DialogContent className={ cssUtils.paddingTopSmall }>
                    <DialogContentText>{ t( "config:templates.email-test-description" ) }</DialogContentText>
                    <Form formik={ formik }>
                        <Field
                            name="to"
                            label={ t( "config:templates.email" ) }
                            component={ Input }
                            required
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:submit" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={ showSuccessMessage }
                action={
                    <Button color="secondary" onClick={ closeSuccessMessage }>
                        { "OK" }
                    </Button>
                }
                message= { t( "config:templates.test-submitted" ) }
                onClose={ closeSuccessMessage }
            />
        </>
    );
};

export default TemplateBodyTester;