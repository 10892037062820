import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import { useFieldValueFormat } from "../hooks/use-field-value-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import FieldsSummaryItem from "./fields-summary-item.component";

const FieldsSummary = ({ alignPopupLeft, fields, model }) => {
    const t = useTranslate();
    const fieldValueFormat = useFieldValueFormat( t );

    const [ openCopied, setOpenCopied ] = useState( false );

    const toggleCopied = () => setOpenCopied( !openCopied );

    return (
        <>
            {
                fields
                    .filter( field =>
                        ( field.type.match( "EMAIL|PHONE|ADDRESS" ) && !isEmpty( model.fields[ field.id ] ) ) ||
                        ( !field.type.match( "EMAIL|PHONE|ADDRESS" ) && model.fields[ field.id ] )
                    ).map( field => (
                        <FieldsSummaryItem
                            key={ field.id }
                            field={ field }
                            model={ model }
                            format={ fieldValueFormat }
                            alignPopupLeft={ alignPopupLeft }
                            toggleCopied={ toggleCopied }
                        />
                    ))
            }
            <Snackbar
                open={ openCopied }
                onClose={ toggleCopied }
                message={ t( "common:copied" ) }
                autoHideDuration={ 3000 }
                action={
                    <Button
                        onClick={ toggleCopied }
                        color="secondary"
                        size="small"
                    >
                        { "OK" }
                    </Button>
                }
            />
        </>
    );
};

export default FieldsSummary;