import { Field } from "formik";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Slider, cssUtils } from "../../../ui";
import { TOTAL_WEIGHTS_FOR_WEIGHTED_DISTRIBUTION } from "./team.constants";
import css from "./weighted-distribution-form.scss";

const WeightedDistributionForm = ({ className, name }) => {
    const t = useTranslate();

    return (
        <div className={ classnames( css.weightedDistributionContainer, className ) }>
            <Field
                name={ name }
                color="primary"
                component={ Slider }
                valueLabelDisplay="auto"
                marks
                max={ TOTAL_WEIGHTS_FOR_WEIGHTED_DISTRIBUTION }
            />
            <Typography variant="body1" className={ cssUtils.marginLeftSmall }>
                { t( "config:team.users.weight" ) }
            </Typography>
        </div>
    );
};

export default WeightedDistributionForm;