import { all } from "redux-saga/effects";

import { sagas as configTeam } from "./team";
import { sagas as configUser } from "./user";
import { sagas as configGoal } from "./goal";
import { sagas as configCommission } from "./commission";

export function *watchConfigUsers () {
    yield all([
        configTeam.watchConfigTeam(),
        configUser.watchConfigUser(),
        configGoal.watchConfigGoal(),
        configCommission.watchConfigCommission(),
    ]);
}