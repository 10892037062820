// ---------------------------------------------------------------------------------------------------------------------
// Team
// ---------------------------------------------------------------------------------------------------------------------

export const FETCH_TEAM = "x/FETCH_TEAM";
export const fetchTeam = id => ({
    type: FETCH_TEAM,
    meta: { id }
});

export const RECEIVE_TEAM = "x/RECEIVE_TEAM";
export const receiveTeam = data => ({
    type: RECEIVE_TEAM,
    payload: data
});

export const ERROR_TEAM = "x/ERROR_TEAM";
export const errorTeam = error => ({
    type: ERROR_TEAM,
    payload: error,
    error: true
});

// ---------------------------------------------------------------------------------------------------------------------
// Teams
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_TEAMS = "x/FETCH_TEAMS";
export const fetchTeams = () => ({
    type: FETCH_TEAMS
});

export const REQUEST_TEAMS = "x/REQUEST_TEAMS";
export const requestTeams = () => ({
    type: REQUEST_TEAMS
});

export const RECEIVE_TEAMS = "x/RECEIVE_TEAMS";
export const receiveTeams = teams => ({
    type: RECEIVE_TEAMS,
    payload: { teams }
});

export const ERROR_TEAMS = "x/ERROR_TEAMS";
export const errorTeams = error => ({
    type: ERROR_TEAMS,
    payload: error,
    error: true
});