import { MaskInput } from "../../../ui";

const CreditCardValidity = ({ ...props }) => (
    <MaskInput
        { ...props }
        useFormattedValue
        format="##/####"
    />
);

export default CreditCardValidity;