import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { Input, DialogInfo } from "../../../ui";
import { Table } from "../../../ui/table";
import { selectors as productSelectors, actions as productActions } from "../../../product";
import { selectors as configSelectors, actions as configActions, Products } from ".";
import { CONFIG_PRODUCT_FORM } from "./product.constants";
import { cssUtils, flex } from "../../../ui";
import { fetchCategories } from "../../../product/product.actions";
import DeleteProducts from "./delete-products.component";
import css from "./product.scss";

const ProductsTable = ({
    showNewProduct,
    onChangeNewProduct,
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const products = useSelector( productSelectors.getProducts );
    const totalProducts = useSelector( productSelectors.getTotalElementsAll );
    const totalPages = useSelector( productSelectors.getTotalPagesAll );
    const loading = useSelector( productSelectors.isLoadingProducts );
    const modifying = useSelector( isSubmitting( CONFIG_PRODUCT_FORM ) );
    const deleting = useSelector( configSelectors.deleting );
    const deleteError = useSelector( configSelectors.getDeleteError );
    const importation = useSelector( configSelectors.getSelectedImportation );

    const [ selected, setSelected ] = useState( [] );
    const [ selectedProduct, setSelectedProduct ] = useState( {} );
    const [ recurrent, setRecurrent ] = useState( importation?.recurrent || false );
    const [ description, setDescription ] = useState( "" );
    const [ showDialog, setShowDialog ] = useState( false );
    const [ showConfirmationDelete, setShowConfirmationDelete ] = useState( false );
    const [ productToDelete, setProductToDelete ] = useState( null );
    const [ showDeleteError, setShowDeleteError ] = useState( false );
    const [ showMultipleDelete, setShowMultipleDelete ] = useState( false );
    const [ showEmptyProductsDownload, setShowEmptyProductsDownload ] = useState( false );

    const page = useMemo( () => 0, [] );
    const orderBy = useMemo( () => "description", [] );
    const orderType = useMemo( () => "ASC", [] );

    const loadProducts = useCallback( () => (
        dispatch( productActions.fetchProducts({
            recurrent,
            description,
            orderBy,
            orderType,
            importationId: importation?.id
        }))
    ), [ dispatch, recurrent, description, orderBy, orderType, importation ] );

    const editProduct = selectedProduct => {
        setShowDialog( true );
        setSelectedProduct( selectedProduct );
    };

    const newProduct = () => {
        setShowDialog( true );
        setSelectedProduct( {} );
    };

    const closeDialog = useCallback( () => {
        setShowDialog( false );
        setSelectedProduct( {} );
        if ( showNewProduct ) {
            onChangeNewProduct();
        }
    }, [ onChangeNewProduct, showNewProduct ] );

    const toggleConfirmationDelete = product => {
        setShowConfirmationDelete( !showConfirmationDelete );
        setProductToDelete( product );
    };

    const handleDelete = () => {
        dispatch( configActions.deleteProduct( productToDelete ) );
        toggleConfirmationDelete();
    };

    const openDeleteError = () => setShowDeleteError( true );
    const closeDeleteError = () => {
        setShowDeleteError( false );
        setTimeout( () => dispatch( configActions.deleteProductError() ), 200 );
    };

    const toggleMultipleDelete = () => setShowMultipleDelete( !showMultipleDelete );
    const handleCloseMultipleDelete = reload => {
        setShowMultipleDelete( false );
        if ( reload ) {
            setSelected( [] );
            loadProducts();
        }
    };

    const handlePageChange = pageProps => {
        setSelected( [] );
        dispatch( productActions.fetchProducts({
            recurrent,
            description,
            ...pageProps
        }) );
    };

    const handleDownload = () => {
        if ( totalProducts > 0 ) {
            window.location.href = "/api/v1/products/download";
            return false;
        }
        setShowEmptyProductsDownload( true );
    };

    const handleSelect = values => {
        setSelected( values.filter( item => item.selected ).map( item => item.id ) );
    };

    const closeMessageDialog = () => setShowEmptyProductsDownload( false );

    const prevDeleteError = usePrevious( deleteError );
    const prevRecurrent = usePrevious( recurrent );
    const prevModifying = usePrevious( modifying );
    const prevDeleting = usePrevious( deleting );
    const prevShowNewProduct = usePrevious( showNewProduct );

    useEffect( () => {
        dispatch( fetchCategories() );

        return () => {
            dispatch( configActions.setImportation() );
        };
    }, [ dispatch ] );
    useEffect( () => {
        loadProducts();
    }, [ loadProducts ] );
    useEffect( () => {
        if ( deleteError && !isEqual( prevDeleteError, deleteError ) ) {
            openDeleteError();
        }
    }, [ deleteError, prevDeleteError ] );
    useEffect( () => {
        if ( ( prevModifying && !modifying ) || ( prevDeleting && !deleting ) ) {
            loadProducts();
        }
    }, [ deleting, loadProducts, modifying, prevDeleting, prevModifying, prevRecurrent, recurrent ] );
    useEffect( () => {
        if ( showNewProduct ) {
            newProduct();
        }
    }, [ showNewProduct ] );
    useEffect( () => {
        if ( prevShowNewProduct && !showNewProduct ) {
            closeDialog();
        }
    }, [ prevShowNewProduct, showNewProduct, closeDialog ] );


    const model = {
        code: t( "config:product.code" ),
        description: t( "config:product.description" ),
        price: {
            title: t( "config:product.price" ),
            format: price => priceFormat( price )
        },
        measurementUnit: {
            title: t( "config:product.measurementUnit.label" ),
            format: value => t( `config:product.measurementUnit.${value}` )
        },
        category: {
            title: t( "config:product.category.label" )
        },
        edit: {
            title: " ",
            size: "small"
        },
        delete: {
            title: " ",
            size: "small"
        }
    };
    const source = products?.map( product => ({
        selected: selected.includes( product.id ),
        ...product,
        category: product.category?.name,
        edit: (
            <IconButton onClick={ () => editProduct( product ) } size="large">
                <EditIcon/>
            </IconButton>
        ),
        delete: (
            <IconButton onClick={ () => toggleConfirmationDelete( product ) } size="large">
                <DeleteIcon/>
            </IconButton>
        )
    }));

    const hasSelected = !isEmpty( selected );

    return (
        <>
            <div className={ flex.alignItemsStart }>
                <Products show={ showDialog } product={ selectedProduct } onClose={ closeDialog } />
                <DeleteProducts
                    open={ showMultipleDelete }
                    onClose={ handleCloseMultipleDelete }
                    products={
                        selected
                            .map( productId => products.find( product => product.id === productId ) )
                            .filter( product => !!product )
                    }
                />

                <Dialog
                    open={ showConfirmationDelete }
                    onClose={ toggleConfirmationDelete }
                >
                    <DialogTitle>{ t( "common:exclude" ) }</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{ t( "config:products.delete.comment" ) }</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ toggleConfirmationDelete }>
                            { t( "common:no" ) }
                        </Button>
                        <Button color="primary" onClick={ handleDelete }>
                            { t( "common:yes" ) }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={ showDeleteError }
                    action={
                        <Button color="secondary" onClick={ closeDeleteError }>
                            { t( "common:ok" ) }
                        </Button>
                    }
                    message={ deleteError?.error }
                    autoHideDuration={ 5000 }
                    onClose={ closeDeleteError }
                />

                <Card className={ css.productList }>
                    <div className={ flex.container }>
                        <CardHeader
                            className={ flex.fill }
                            title={ t( "config:products.title" ) }
                            subheader={ t( "config:products.subtitle" ) }
                        />
                        <div className={ css.downloadContainer }>
                            <Tooltip title={ t( "common:refresh" ) }>
                                <IconButton disabled={ hasSelected } onClick={ loadProducts } size="large">
                                    <RefreshIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={ t("config:products.download" ) }>
                                <IconButton disabled={ hasSelected } onClick={ handleDownload } size="large">
                                    <CloudDownloadIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <Divider/>

                    <div className={ classnames( cssUtils.marginSmall, flex.container ) }>
                        <FormControl className={ flex.fill } margin="none">
                            <RadioGroup
                                className={ cssUtils.displayBlock }
                                value={ String( recurrent ) }
                                onChange={ e => setRecurrent( e.target.value === "true" ) }
                            >
                                <FormControlLabel
                                    disabled={ !!importation || hasSelected }
                                    label={ t( "config:product.without-recurrence" ) }
                                    value={ "false" }
                                    control={ <Radio/> }
                                />
                                <FormControlLabel
                                    disabled={ !!importation || hasSelected }
                                    className={ cssUtils.marginLeft }
                                    label={ t( "config:product.with-recurrence" ) }
                                    value={ "true" }
                                    control={ <Radio/> }
                                />
                            </RadioGroup>
                        </FormControl>
                        <div
                            className={ classnames( flex.container, flex.fill, flex.rowReverse ) }
                        >
                            {
                                hasSelected ?
                                    <Button onClick={ toggleMultipleDelete }>
                                        { t( "config:products.delete.title" ) }
                                    </Button> :
                                    importation ?
                                        <Typography>
                                            { t( "config:products.selected-importation", { name: importation.file } ) }
                                        </Typography> :
                                        <>
                                            <Tooltip title={ t( "common:search" ) }>
                                                <IconButton onClick={ loadProducts }>
                                                    <SearchIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Input
                                                className={ flex.fill }
                                                margin="none"
                                                placeholder={ t( "config:products.search" ) }
                                                onChange={ event => setDescription( event.target.value ) }
                                                fullWidth={ false }
                                                value={ description }
                                            />
                                        </>
                            }
                        </div>
                    </div>

                    <Table
                        model={ model }
                        source={ source }
                        isLoading={ loading }
                        emptyMessage={ t( "config:products.empty" ) }
                        totalPages={ totalPages }
                        totalElements={ totalProducts }
                        pageSize={ 20 }
                        onChangePage={ handlePageChange }
                        selectedPage={ page }
                        orderBy={ orderBy }
                        orderType={ orderType }
                        onSelect={ handleSelect }
                        allowOrder
                        selectable
                    />
                </Card>

            </div>
            <DialogInfo
                open={ showEmptyProductsDownload }
                message={ t( "config:product.export-products.info-content") }
                onClose={ closeMessageDialog }
                maxWidth="xs"
            />
        </>
    );
};

export default ProductsTable;