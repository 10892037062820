import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, Dropdown, flex } from "../../../ui";
import { selectors as userSelectors } from "../../../user";
import { TEAM_FORM, TOTAL_WEIGHTS_FOR_WEIGHTED_DISTRIBUTION } from "./team.constants";
import { isLoadingUsers, getUsers } from "./team.selectors";
import * as actions from "./team.actions";
import UsersField from "./users-field.component";

const Team = ({ team = {}, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const teamUsers = useSelector( getUsers( team.id ) );
    const loadingTeamUsers = useSelector( isLoadingUsers( team.id ) );
    const loadingAllUsers = useSelector( userSelectors.isLoadingAll );
    const loading = loadingTeamUsers || loadingAllUsers;

    const formik = useForm({
        form: TEAM_FORM,
        enableReinitialize: true,
        initialValues: {
            name: "",
            opportunityDistributionType: null,
            ...team,
            users: team.id && !loading ?
                ( teamUsers || [] )
                    .map( user => {
                        const userTeamsFiltered = user.teams.find( userTeam => userTeam.team.id === team.id );
                        return ({
                            userId: user.id,
                            leader: get( userTeamsFiltered, "leader" ),
                            weight: get( userTeamsFiltered, "weight" )
                        });
                    }) :
                [],
        },
        onSubmit: values => dispatch( actions.saveTeam( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.users || !values.users.length ) {
                errors.users = "common:validation.add-at-least-one-user";
            } else if ( values.opportunityDistributionType === "WEIGHTED" && !isEmpty( values.users ) ) {
                const totalWeights = values.users.reduce( ( sum, user ) => sum + ( user?.weight || 0 ), 0 );
                if ( totalWeights !== TOTAL_WEIGHTS_FOR_WEIGHTED_DISTRIBUTION ) {
                    errors.users = "config:team.weight.validation";
                }
            }
            if ( !values.opportunityDistributionType ) {
                errors.opportunityDistributionType = "common:validation.selection";
            }
            return errors;
        }
    });

    return (
        <>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        type="text"
                        name="name"
                        label={ t( "config:team.name" ) }
                        component={ Input }
                        required
                    />
                    <Field
                        name="opportunityDistributionType"
                        label={ t( "config:team.opportunity-distribution-types.label" ) }
                        component={ Dropdown }
                        source={[
                            {
                                value: "PROPORTIONAL",
                                label: t( "config:team.opportunity-distribution-types.proportional" )
                            },
                            { value: "WEIGHTED", label: t( "config:team.opportunity-distribution-types.weighted" ) }
                        ]}
                        required
                    />
                    <FieldArray
                        name="users"
                        component={ UsersField }
                    />
                    {
                        formik.error &&
                            <div className={ flex.fill }>
                                <Typography color="error" variant="caption">
                                    { formik.error }
                                </Typography>
                            </div>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </>
    );
};

export default Team;