import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog, UserMentions } from "../ui";
import { NEW_OPPORTUNITY_COMMENT_FORM } from "./opportunity.constants";
import { saveComment } from "./opportunity.actions";

const NewComment = ({ opportunityId, onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_OPPORTUNITY_COMMENT_FORM,
        enableReinitialize: true,
        initialValues: {
            opportunityId,
            comment: "",
        },
        onSubmit: values => dispatch( saveComment( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.comment ) {
                errors.comment = "common:validation.required";
            }
            return errors;
        },
    });

    return (
        <Dialog
            open={ show }
            onClose={ onClose }
            fullWidth
        >
            <DialogTitle>
                { t( "opportunity:new-comment.title" ) }
            </DialogTitle>
            <Form formik={ formik }>
                <DialogContent>
                    <Field
                        name="comment"
                        type="text"
                        label={ t( "opportunity:new-comment.comment" ) }
                        component={ UserMentions }
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" type="submit" disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default NewComment;