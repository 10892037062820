import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as smtpServerActions } from "../../../smtp-server";
import * as actions from "./smtp-server.actions";
import {
    SMTP_SERVER_DEFAULT_FORM,
    DELETE_SMTP_SERVER_DEFAULT_FORM,
} from "./smtp-server.constants";

export function *watchConfigAdvancedSmtpServer () {
    yield all([
        takeEvery( actions.SAVE_SMTP_SERVER_DEFAULT, saveSmtpServerDefault ),
        takeEvery( actions.DELETE_SMTP_SERVER_DEFAULT, deleteSmtpServerDefault ),
    ]);
}

export function *saveSmtpServerDefault ({ payload }) {
    yield put( startSubmit( SMTP_SERVER_DEFAULT_FORM ) );
    try {
        const response = yield call( apiv1.put, "/smtpserver/default", payload );
        yield put( smtpServerActions.receiveSmtpServerDefault( response.data ) );
        yield put( stopSubmit( SMTP_SERVER_DEFAULT_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SMTP_SERVER_DEFAULT_FORM, e.response.data ) );
    }
}

export function *deleteSmtpServerDefault () {
    yield put( startSubmit( DELETE_SMTP_SERVER_DEFAULT_FORM ) );
    try {
        yield call( apiv1.delete, "/smtpserver/default" );
        yield put( smtpServerActions.receiveSmtpServerDefault() );
        yield put( stopSubmit( DELETE_SMTP_SERVER_DEFAULT_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_SMTP_SERVER_DEFAULT_FORM, e.response.data ) );
    }
}