import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import classnames from "classnames";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { fetchSmtpServer } from "../smtp-server/smtp-server.actions";
import { cssUtils, flex } from "../ui";
import { GoogleAuthButton } from "../ui/social";
import GoogleIcon from "../ui/icons/google.component";

const GmailAuth = ({ setFieldValue, values }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const handleOpen = () => {
        const popup = window.open(
            history.createHref({
                ...location,
                pathname: `/gmail/login`
            })
        );
        const popupTick = setInterval( function () {
            if ( popup.closed ) {
                clearInterval( popupTick );
                dispatch( fetchSmtpServer() );
            }
        }, 500 );
    };

    const removeAuth = () => setFieldValue( "googleAccount", null );

    return (
        <>
            {
                values.googleAccount?.id ?
                    <div className={ flex.container }>
                        <Button
                            component="div"
                            className={ cssUtils.cursorUnset }
                            variant="outlined"
                            startIcon={ <GoogleIcon/> }
                            endIcon={
                                <IconButton onClick={ removeAuth } size="small"><CloseIcon/></IconButton>
                            }
                        >
                            { values.googleAccount.data.email }
                        </Button>

                        { values.googleAccount && !values.googleAccount.expired ?
                            <Alert
                                className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                severity="success"
                            >
                                { t( "user:gmail.connected" ) }
                            </Alert> :
                            <Alert
                                className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                severity="error"
                            >
                                { t( "user:gmail.expired" ) }
                            </Alert>
                        }
                    </div> :
                    <GoogleAuthButton onClick={ handleOpen }>
                        { t( "user:gmail.auth" ) }
                    </GoogleAuthButton>
            }
        </>
    );
};

export default GmailAuth;