import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

import useTranslate from "../hooks/use-translate.hook";
import { Input, cssUtils } from "../ui";
import { INVITATION_FORM } from "./";
import * as actions from "./invitation.actions";
import * as selectors from "./invitation.selectors";

const InviteError = ({ t }) => <p>{ t( "invitation:not-found" ) }</p>;

const AcceptInvite = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const invitation = useSelector( selectors.getInAcceptance );
    const errorInvite = useSelector( selectors.errorInAcceptance );

    const initialValues = useMemo( () => ({
        email: invitation?.email,
        name: invitation?.name,
        password: "",
        confirmPassword: "",
    }), [ invitation ] );
    const formik = useForm({
        enableReinitialize: true,
        form: INVITATION_FORM,
        initialValues,
        onSubmit: values => dispatch( actions.acceptInvitation( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.password ) {
                errors.password = "common:validation.required";
            } else if ( values.password.length < 6 ) {
                errors.password = "common:validation.password-length";
            }
            if ( !values.confirmPassword || !values.confirmPassword.length ) {
                errors.confirmPassword = "common:validation.required";
            }
            if ( values.password && values.confirmPassword && values.password !== values.confirmPassword ) {
                errors.confirmPassword = "common:validation.passwords-match";
            }
            return errors;
        },
    });

    useEffect( () => {
        dispatch( actions.fetchInvitation( params.id ) );
    }, [ dispatch, params.id ] );

    if ( !invitation && !errorInvite ) {
        return null;
    }

    if ( errorInvite ) {
        return <InviteError t={ t }/>;
    }

    return (
        <Form formik={ formik }>
            <p>{ t( "invitation:accept.call-to-action", { name: invitation.subscription.name }) }</p>

            <Field
                component={ Input }
                type="text"
                name="name"
                label={ t( "invitation:name" ) }
                placeholder={ t( "invitation:name" ) }
                icon={ <PersonIcon/> } />

            <Field
                component={ Input }
                type="email"
                name="email"
                label={ t( "invitation:email.label" ) }
                icon={ <EmailIcon/> }
                disabled />

            <Field
                component={ Input }
                type="password"
                name="password"
                label={ t( "invitation:password" ) }
                icon={ <LockIcon/> } />

            <Field
                type="password"
                name="confirmPassword"
                label={ t( "invitation:confirm-password" ) }
                component={ Input }
                className={ cssUtils.marginBottomSmall }
                icon={ <LockIcon/> }
                required />

            {
                formik.error &&
                    <Alert
                        severity="error"
                        className={ cssUtils.marginBottomSmall }
                    >
                        { formik.error }
                    </Alert>
            }

            <Button
                type="submit"
                color="primary"
                variant="contained"
                className={ cssUtils.fullWidth }
                disabled={ formik.submitting }>
                { t( "invitation:accept.button" ) }
            </Button>
        </Form>
    );
};

export default AcceptInvite;