import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Field } from "formik";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../../../source";
import { selectors as teamSelectors } from "../../../team";
import { selectors as userSelectors } from "../../../user";
import { Dropdown, DropdownMultiple, Input, SelectFunnel } from "../../../ui";

export const validate = values => {
    const errors = {};
    if ( !values.name ) {
        errors.name = "common:validation.required";
    }
    if ( !values.funnel || !values.funnel.id ) {
        set( errors, "funnel.id", "common:validation.selection" );
    }
    if ( !values.source || !values.source.id ) {
        set( errors, "source.id", "common:validation.selection" );
    }
    if ( ( !values.team || !values.team.id ) && isEmpty( values.usersId ) ) {
        set( errors, "team.id", "common:validation.selection" );
        errors.usersId = "common:validation.selection";
    }
    return errors;
};

const ApiKeyForm = ({ form }) => {
    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAllActive );

    const { setFieldValue } = form;

    useEffect( () => {
        if ( !isEmpty( form.values.usersId ) ) {
            setFieldValue( "team", null );
        }
    }, [ form.values.usersId, setFieldValue ] );
    useEffect( () => {
        if ( form.values.team?.id ) {
            setFieldValue( "usersId", [] );
        }
    }, [ form.values.team, setFieldValue ] );

    return (
        <>
            <Field
                name="name"
                label={ t( "marketplace:integrations.api-key.name" ) }
                component={ Input }
                required
            />

            <Field
                name="funnel.id"
                component={ SelectFunnel }
                required
            />

            <Field
                name="source.id"
                label={ t( "marketplace:integrations.api-key.source" ) }
                component={ Dropdown }
                source={ sources?.map( source => ({ value: source.id, label: source.name }) )}
                required
            />
            <Field
                name="team.id"
                label={ t( "marketplace:integrations.api-key.team" ) }
                component={ Dropdown }
                emptyValue
                source={
                    teams?.map( team => ({
                        value: team.id,
                        label: team.name,
                        helper: users
                            .filter(
                                user => !!user.teams.find( item => item.team.id.toString() === team.id.toString() )
                            )
                            .map( user => user.name )
                            .join( ", " )
                    }))
                }
            />
            <Field
                name="usersId"
                label={ t( "marketplace:integrations.api-key.users" ) }
                component={ DropdownMultiple }
                source={
                    users?.map( user => ({
                        label: user.name,
                        value: user.id
                    }))
                }
            />
        </>
    );
};

export default ApiKeyForm;