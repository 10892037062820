import { withForm } from "formik-redux";
import { useState, Fragment, useCallback, useEffect } from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import classnames from "classnames";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import FunnelsFilter from "../opportunity/list/funnels-filter.component";
import { selectors as opportunitySelectors } from "../opportunity";
import { selectors as userSelectors } from "../user";
import { selectors as sourceSelectors } from "../source";
import { flex, cssUtils, DropdownMultiple, Dropdown, DatePicker, UsersFilter } from "../ui";
import { DASHBOARD_FILTERS } from "./dashboard.constants";
import css from "./dashboard.scss";

const periodTypes = [
    "TODAY",
    "YESTERDAY",
    "THIS_WEEK",
    "LAST_WEEK",
    "THIS_MONTH",
    "LAST_MONTH",
    "THIS_QUARTER",
    "LAST_QUARTER",
    "THIS_YEAR",
    "LAST_YEAR",
    "CUSTOM"
];

const DashboardFilters = ({ handleSubmit, setFieldValue, values }) => {
    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );
    const users = useSelector( userSelectors.listAll );
    const funnelsId = useSelector( opportunitySelectors.getFunnelsId );

    const [ showUsers, setShowUsers ] = useState( false );
    const toggleShowUsers = () => setShowUsers( !showUsers );

    const onChangeTeam = useCallback( teamId => {
        if ( teamId ) {
            const teamUsers = users
                .filter( u => !!u.teams.find( item => item.team.id.toString() === teamId.toString() ) )
                .map( u => u.id );
            setFieldValue( "users", teamUsers );
        }

        setFieldValue( "team", teamId );
    }, [ users, setFieldValue ] );

    const onChangeUsers = useCallback( user => {
        setFieldValue( "users", user);
        setFieldValue( "team", null );
    }, [ setFieldValue ] );

    const handleChangeDateType = useCallback( () => {
        let newInitialDate = new Date();
        let newFinalDate = new Date();

        switch ( values.dateType ) {
            case "YESTERDAY":
                newInitialDate = dayjs().subtract( 1, "day" ).toDate();
                newFinalDate = dayjs().subtract( 1, "day" ).toDate();
                break;
            case "THIS_WEEK":
                newInitialDate = dayjs().startOf( "week" ).toDate();
                newFinalDate = dayjs().endOf( "week" ).toDate();
                break;
            case "LAST_WEEK":
                newInitialDate = dayjs().subtract( 1, "week" ).startOf( "week" ).toDate();
                newFinalDate = dayjs().subtract( 1, "week" ).endOf( "week" ).toDate();
                break;
            case "THIS_MONTH":
                newInitialDate = dayjs().startOf( "month" ).toDate();
                newFinalDate = dayjs().endOf( "month" ).toDate();
                break;
            case "LAST_MONTH":
                newInitialDate = dayjs().subtract( 1, "month" ).startOf( "month" ).toDate();
                newFinalDate = dayjs().subtract( 1, "month" ).endOf( "month" ).toDate();
                break;
            case "THIS_QUARTER":
                newInitialDate = dayjs().startOf( "quarter" ).toDate();
                newFinalDate = dayjs().endOf( "quarter" ).toDate();
                break;
            case "LAST_QUARTER":
                newInitialDate = dayjs().subtract( 1, "quarter" ).startOf( "quarter" ).toDate();
                newFinalDate = dayjs().subtract( 1, "quarter" ).endOf( "quarter" ).toDate();
                break;
            case "THIS_YEAR":
                newInitialDate = dayjs().startOf( "year" ).toDate();
                newFinalDate = dayjs().endOf( "year" ).toDate();
                break;
            case "LAST_YEAR":
                newInitialDate = dayjs().subtract( 1, "year" ).startOf( "year" ).toDate();
                newFinalDate = dayjs().subtract( 1, "year" ).endOf( "year" ).toDate();
                break;
            default:
                return;
        }
        newInitialDate = dayjs( newInitialDate ).startOf( "day" ).toDate();
        newFinalDate = dayjs( newFinalDate ).endOf( "day" ).toDate();

        setFieldValue( "initialDate", newInitialDate );
        setFieldValue( "finalDate", newFinalDate );
    }, [ setFieldValue, values.dateType ] );

    const handleChangeDate = useCallback( () => {
        setFieldValue( "dateType", "CUSTOM" );
    }, [ setFieldValue ] );

    const handleChangeUsers = useCallback( () => {
        if ( isEmpty( values.users ) ) {
            const initialUsers = ( users || [] )
                .filter( user => user.active ).map( user => user.id );

            setFieldValue( "users", initialUsers );
        }
        setShowUsers( false );
    }, [ setFieldValue, users, values ] );

    useEffect( () => {
        handleChangeDateType();
    }, [ handleChangeDateType ] );

    return (
        <Fragment>
            <form
                className={ classnames( flex.displayFlex, flex.row, cssUtils.fullWidth )}
                onSubmit={ handleSubmit }
            >
                <Field
                    name={ "funnelsId" }
                    fullWidth={ false }
                    className={ flex.fill }
                    whiteSpaceNormal={ false }
                    component={ FunnelsFilter }
                />
                <Field
                    name={ "sourcesId" }
                    label={ t( "dashboard:sources" ) }
                    source={
                        ( sources || [] )
                            .map( source => ({ label: source.name, value: source.id }))
                    }
                    className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                    whiteSpaceNormal={ false }
                    component={ DropdownMultiple }
                />
                <Field
                    name={ "dateType" }
                    label={ t( "dashboard:period.label" ) }
                    source={ periodTypes.map( value => ({
                        value,
                        label: t( "dashboard:period.values." + value )
                    }))}
                    className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                    fullWidth={ false }
                    component={ Dropdown }
                />
                <Field
                    name={ "initialDate"}
                    label={ t( "dashboard:initial-date" ) }
                    maxDate={ values.finalDate }
                    onAccept={ handleChangeDate }
                    className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                    component={ DatePicker }
                />
                <Field
                    name={ "finalDate" }
                    label={ t( "dashboard:final-date" ) }
                    minDate={ values.initialDate }
                    onAccept={ handleChangeDate }
                    className={ classnames(
                        flex.fill,
                        cssUtils.marginLeftSmall,
                        cssUtils.marginRightSmall
                    ) }
                    component={ DatePicker }
                />
                <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                    {
                        values.users.length > 0 ?
                            <Badge badgeContent={ values.users.length } color="secondary">
                                <Button
                                    onClick={ toggleShowUsers }
                                    startIcon={ <SupervisorAccountIcon/> }
                                >
                                    { t( "dashboard:users" ) }
                                </Button>
                            </Badge> :
                            <Button
                                onClick={ toggleShowUsers }
                                startIcon={ <SupervisorAccountIcon/> }
                            >
                                { t( "dashboard:users" ) }
                            </Button>
                    }
                </div>

                <Divider orientation="vertical" flexItem className={ cssUtils.marginLeftSmall }/>
                <div className={ classnames( flex.container, flex.alignItemsCenter, cssUtils.marginLeftSmall ) }>
                    <Button
                        type="submit"
                        disabled={ isEmpty( funnelsId ) }
                    >
                        { t( "report:filters.apply-filter" ) }
                    </Button>
                </div>
            </form>
            <Dialog open={ showUsers } fullWidth maxWidth="sm">
                <DialogTitle>{ t( "dashboard:select-teams-or-users" ) }</DialogTitle>
                <DialogContent>
                    <UsersFilter
                        disabled={ values.users.length === 0 }
                        disableFetch
                        ComponentRoot={ "div" }
                        className={ css.usersFilter }
                        fullWidth
                        input={{
                            team: {
                                value: values.team,
                                onChange: onChangeTeam
                            },
                            users: {
                                value: values.users,
                                onChange: onChangeUsers
                            }
                        }}
                        showInactive
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ handleChangeUsers }>{ "Ok" }</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default withForm({
    form: DASHBOARD_FILTERS,
    enableReinitialize: true,
    handleSubmit: ( values, { props } ) => props.onSubmit( values ),
    mapPropsToValues: ({ initialValues }) => initialValues,
})( DashboardFilters );
