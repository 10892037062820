import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import history from "../../../../history";
import { actions as invitationActions } from "../../../invitation";
import { selectors as userSelectors, actions as userActions } from "../../../user";
import { selectors as coreSelectors } from "../../../core";
import { actions as logoutActions } from "../../../logout";
import { CONFIG_FORM_NEW_USER } from "./user.constants";
import * as actions from "./user.actions";
import { createFormName as userAdminFormName } from "./user-admin.component";
import { createFormName as userActiveFormName } from "./user-active.component";
import { createInvitationFormName } from "./user.utils";

// ---------------------------------------------------------------------------------------------------------------------
// Geral
// ---------------------------------------------------------------------------------------------------------------------
export function *watchConfigUser () {
    yield all([
        takeEvery( actions.SAVE_USER, saveUser ),
        takeEvery( actions.INVITE_USER, inviteUser ),
        takeEvery( actions.RESEND_INVITATION, resendInvitation ),
        takeEvery( actions.DELETE_INVITATION, deleteInvitation ),
        takeEvery( actions.SAVE_USER_ACTIVE, saveActiveUser ),
    ]);
}

// ---------------------------------------------------------------------------------------------------------------------
// Convite de usuário
// ---------------------------------------------------------------------------------------------------------------------
export function *inviteUser ({ payload }) {
    yield put( startSubmit( CONFIG_FORM_NEW_USER ) );

    try {
        yield call( apiv1.post, "/invitations", payload );
        yield call([ history, history.push ], "/config/users" );
        yield put( invitationActions.fetchInvitations() );
        yield put( stopSubmit( CONFIG_FORM_NEW_USER ) );
    } catch ( e ) {
        yield put( stopSubmit( CONFIG_FORM_NEW_USER, e.response.data.error ) );
    }
}

export function *resendInvitation ({ payload }) {
    const { invitation } = payload;
    const formName = createInvitationFormName( invitation.id );

    yield put( startSubmit( formName ) );

    try {
        yield call( apiv1.post, "/invitations", invitation );
        yield put( invitationActions.fetchInvitations() );
        yield put( stopSubmit( formName ) );
    } catch ( e ) {
        yield put( stopSubmit( formName, e.response.data.error ) );
    }
}

export function *deleteInvitation ({ meta: { id } }) {
    const formName = createInvitationFormName( id );

    yield put( startSubmit( formName ) );

    try {
        yield call( apiv1.delete, "/invitations/" + id );
        yield put( invitationActions.fetchInvitations() );
        yield put( stopSubmit( formName ) );
    } catch ( e ) {
        yield put( stopSubmit( formName, e.response.data ) );
    }
}

export function *saveUser ({ meta, payload }) {
    const { user } = payload;
    const formName = meta.form || userAdminFormName( user );
    yield put( startSubmit( formName ) );

    try {
        const response = yield call( apiv1.patch, `/users/${user.id}`, user );

        const users = yield select( userSelectors.listAll );
        const newUsers = users.map( u => u.id === user.id ? response.data : u );
        yield put( userActions.receiveUsers( newUsers ) );

        const loggedUser = yield select( coreSelectors.getUser );
        if ( loggedUser.id === user.id ) {
            yield put( logoutActions.clearAuth() );
        }

        yield put( stopSubmit( formName ) );
    } catch ( e ) {
        yield put( stopSubmit( formName ), e );
    }
}

export function *saveActiveUser ({ payload: { user } }) {
    const formName = userActiveFormName( user );
    yield put( startSubmit( formName ) );

    try {
        let response;
        if ( user.active ) {
            response = yield call( apiv1.put, `/users/${user.id}/active` );
        } else {
            response = yield call( apiv1.put, `/users/${user.id}/inactive` );
        }
        const users = yield select( userSelectors.listAll );
        const newUsers = users.map( u => u.id === user.id ? response.data : u );
        yield put( userActions.receiveUsers( newUsers ) );
        yield put( stopSubmit( formName ) );

        const loggedUser = yield select( coreSelectors.getUser );
        if ( loggedUser.id === user.id ) {
            yield put( logoutActions.clearAuth() );
        }
    } catch ( e ) {
        yield put( stopSubmit( formName, e.response.data.error ) );
    }
}
