import { useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils } from "../../../ui";
import PhaseTriggersItem from "./phase-triggers-item.component";

const PhaseTriggers = ({
    name,
    form,
    push,
    remove,
}) => {
    const t = useTranslate();

    const handleAdd = useCallback( () => {
        push({ automatic: false, sendCustomer: false });
    }, [ push ] );

    const handleRemove = useCallback( index => () => {
        remove( index );
    }, [ remove ] );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    return (
        <div>
            <CardHeader
                className={ classnames( cssUtils.padding0, cssUtils.marginBottomSmall ) }
                title={ t( "config:phases.trigger.title" ) }
                subheader={ t( "config:phases.trigger.help" ) }
            />
            {
                values.map( ( phaseTrigger, index ) => (
                    <PhaseTriggersItem
                        key={ index }
                        name={ `${name}[${index}]` }
                        values={ phaseTrigger }
                        handleChange={ form.setFieldValue }
                        handleRemove={ handleRemove( index ) }
                    />
                ))
            }

            <Button size="small" onClick={ handleAdd }>
                { t( "config:phases.trigger.add" ) }
            </Button>
        </div>
    );
};

export default PhaseTriggers;