import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

const productsBase = state => state.products.all;
export const isLoadingProducts = requestSelectors.isLoading( productsBase );
export const getProducts = createSelector( state => state.products.all, all => all.data );
export const getTotalPagesAll = state => state.products.all.totalPages;
export const getTotalElementsAll = state => state.products.all.totalElements;
export const getActiveProducts = state => ( getProducts( state ) || [] ).filter( product => product.active );
export const getProductsError = requestSelectors.getError( productsBase );

const searchBase = state => state.products.search;
export const isLoadingSearch = createSelector( searchBase, search => search.loading );
export const getSearchCustomers = createSelector( searchBase, search => search.data );

export const listCategories = state => state.products.categories.data;
export const isLoadingCategories = state => state.products.categories.loading;