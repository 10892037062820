import { useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import get from "lodash/get";

import useTranslate from "../../hooks/use-translate.hook";
import ProposalProductsItem from "./proposal-products-item.component";

const ProposalProducts = ({ form, name, move, push, remove }) => {
    const t = useTranslate();

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    const handleAdd = () => push({ product: {}, quantity: null, discount: null, discountUnit: null });

    const handleDragEnd = result => move( parseInt( result.draggableId ), result.destination.index );

    const handleRemove = index => () => remove( index );

    return (
        <DragDropContext onDragEnd={ handleDragEnd }>
            <List>
                <Typography color="textSecondary" variant="body2">
                    { t( "opportunity:proposal.products.label" ) }
                </Typography>
                <Droppable droppableId="droppable">
                    {
                        provided => (
                            <div
                                { ...provided.droppableProps }
                                ref={ provided.innerRef }
                            >
                                {
                                    values.map( ( field, index ) =>
                                        <ProposalProductsItem
                                            key={ index }
                                            index={ index }
                                            form={ form }
                                            onRemove={ handleRemove( index ) }
                                        />
                                    )
                                }
                                { provided.placeholder }
                            </div>
                        )
                    }
                </Droppable>
                <ListItemButton dense component="div" onClick={ handleAdd }>
                    <ListItemIcon><AddIcon/></ListItemIcon>
                    <ListItemText
                        primary={ t( "opportunity:proposal.products.add" ) }
                    />
                </ListItemButton>
            </List>
        </DragDropContext>
    );
};

export default ProposalProducts;