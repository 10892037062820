import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";

import history from "../../../history";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link, cssUtils } from "../../ui";
import css from "../../ui/timeline/timeline.scss";
import OpportunityHolding from "./opportunity-holding.component";

const translateIntegrationFieldLabel = ( t, key ) => {
    switch ( key ) {
        case "adId":
            return t( "customer:timeline.integration-fields.adId" );
        case "name":
        case "Full name":
            return t( "customer:timeline.integration-fields.name" );
        case "phone":
        case "Phone number":
            return t( "customer:timeline.integration-fields.phone" );
        case "message":
            return t( "customer:timeline.integration-fields.message" );
        case "preAnalysisStatusNative":
            return t( "customer:timeline.integration-fields.preAnalysisStatusNative.label" );
        case "source":
            return t( "customer:timeline.integration-fields.source" );
        case "vehicleInformation":
            return t( "customer:timeline.integration-fields.vehicle-information" );
        case "simulationDetails":
            return t( "customer:timeline.integration-fields.simulation-details" );
        case "title":
            return t( "customer:timeline.integration-fields.itemTitle" );
        default:
            return t( `customer:timeline.integration-fields.${key}` ).defaultMessage( key );
    }
};

const translateIntegrationFieldValue = ( t, key, values ) => {
    if ( key === "preAnalysisStatusNative" && !!values[ key ] ) {
        return t( `customer:timeline.integration-fields.preAnalysisStatusNative.${values[ key ]}` )
            .defaultMessage( values[ key ] );
    }

    return typeof values[ key ] === "boolean" ?
        values[ key ] ? t( "common:yes" ) : t( "common:no" ) :
        toString( values[ key ] );
};

const translateIntegrationChannel = ( t, integrationChannel ) => {
    switch ( integrationChannel ) {
        case "FACEBOOK":
            return t( "opportunity:timeline.opportunity-created-facebook" );
        case "EXACT_SALES":
            return t( "opportunity:timeline.opportunity-created-exact-sales" );
        case "ICARROS":
            return t( "opportunity:timeline.opportunity-created-icarros" );
        case "LEADLOVERS":
            return t( "opportunity:timeline.opportunity-created-leadlovers" );
        case "MERCADO_LIBRE":
            return t( "opportunity:timeline.opportunity-created-mercado-libre" );
        case "MOBIAUTO":
            return t( "opportunity:timeline.opportunity-created-mobiauto" );
        case "MKT2EASY":
            return t( "opportunity:timeline.opportunity-created-mkt2easy" );
        case "RD_STATION":
            return t( "opportunity:timeline.opportunity-created-rd-station" );
        case "OLX":
            return t( "opportunity:timeline.opportunity-created-olx" );
        case "USADOSBR":
            return t( "opportunity:timeline.opportunity-created-usadosbr" );
        case "WEBMOTORS":
            return t( "opportunity:timeline.opportunity-created-webmotors" );
        default:
            return t( "opportunity:timeline.opportunity-created" );
    }
};

const CreationEvent = ({ opportunity, event }) => {
    const location = useLocation();

    const t = useTranslate();

    return (
        <TimelineItem classes={{ avatar: css.creationIcon }} icon={ <StarIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={
                    opportunity.integrationChannel ?
                        translateIntegrationChannel( t, opportunity.integrationChannel ) :
                        opportunity.leadId ?
                            event.user ?
                                t( "opportunity:timeline.opportunity-created-lead-user" ) :
                                t( "opportunity:timeline.opportunity-created-lead" ) :
                            event.importationId ?
                                t( "opportunity:timeline.opportunity-created-by-importation" ) :
                                event.user ?
                                    t( "opportunity:timeline.opportunity-created-user" ) :
                                    t( "opportunity:timeline.opportunity-created" )
                }
            />
            {
                opportunity.createdByHolding &&
                <>
                    <Typography color="textSecondary" variant="subtitle2">
                        { t( "opportunity:timeline.opportunity-created-by-holding" ) }
                    </Typography>
                    <OpportunityHolding opportunity={ opportunity } />
                </>

            }
            {
                opportunity.originId &&
                    <div>
                        <Typography color="textSecondary" variant="subtitle2">
                            { t( "opportunity:timeline.opportunity-created-funnel-automation" ) }
                        </Typography>
                        <Link href={ `/opportunities/${opportunity.originId}` }>
                            <Button>
                                { t( "common:click-here-access" ) }
                            </Button>
                        </Link>
                    </div>
            }
            {
                !isEmpty( opportunity.integrationFields ) &&
                    Object.keys( opportunity.integrationFields )
                        .filter( key => opportunity.integrationFields[ key ].constructor !== Object )
                        .map( ( key, index ) => (
                            <div className={ css.change } key={ index }>
                                <Typography variant="body2">
                                    { translateIntegrationFieldLabel( t, key ) }{ ": " }
                                </Typography>
                                <ul className={ css.emailChangeList }>
                                    <li>
                                        <Typography variant="body2">
                                            <mark className={ cssUtils.highlightNew }>
                                                {
                                                    translateIntegrationFieldValue(
                                                        t,
                                                        key,
                                                        opportunity.integrationFields
                                                    )
                                                }
                                            </mark>
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        ))
            }
            {
                opportunity.integrationChannel === "MERCADO_LIBRE" &&
                    <Button
                        size="small"
                        className={ cssUtils.marginTopSmall }
                        onClick={ () => {
                            window.open(
                                `https://questions.mercadolivre.com.br/seller?item=${opportunity.integrationFields.itemId}&amp;confirm=true&amp;_consistent=true`
                            );
                        }}
                    >
                        { t( "opportunity:mercado-libre.respond" ) }
                    </Button>
            }
            {
                opportunity.leadId &&
                    <Button
                        size="small"
                        onClick={ () => {
                            const width = 550;
                            const height = 550;
                            const left = ( screen.width / 2 ) - ( width / 2 );
                            const top = ( screen.height / 2 ) - ( height / 2 );
                            window.open(
                                history.createHref({
                                    ...location,
                                    pathname: `/leads/${opportunity.leadId}/body`
                                }),
                                "Lead",
                                `width=${width}, height=${height}, top=${top}, left=${left}`
                            );
                        }}
                    >
                        { t( "opportunity:mercado-libre.respond" ) }
                    </Button>
            }
            {
                opportunity.importationId &&
                    <Link href={ `/importations/${opportunity.importationId}/results` }>
                        <Button size="small">
                            { t( "customer:timeline.access-importation" ) }
                        </Button>
                    </Link>
            }
            {
                opportunity.migrationSystem &&
                    <Typography>
                        {
                            t( "opportunity:timeline.from-migration", {
                                system: t(
                                    `config:migrations.system.${opportunity.migrationSystem}`
                                )
                            })
                        }
                    </Typography>
            }
        </TimelineItem>
    );
};

export default CreationEvent;