import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as funnelSelectors } from "../../../funnel";
import { selectors as mailtopSelectors } from "../../../mailtop";
import { selectors as phaseSelectors } from "../../../phase";
import { selectors as sourceSelectors } from "../../../source";
import { Dropdown, Switch, UsersFilter } from "../../../ui";
import { addMailtopList } from "./mailtop.actions";
import { MAILTOP_ADD_LIST_FORM } from "./mailtop.constants";
import { events } from "./mailtop.utils";

const AddList = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnels = useSelector( funnelSelectors.listAll );
    const lists = useSelector( mailtopSelectors.getLists );
    const phases = useSelector( phaseSelectors.list );
    const sources = useSelector( sourceSelectors.getSources );

    const formik = useForm({
        form: MAILTOP_ADD_LIST_FORM,
        initialValues: {
            listId: "",
            funnel: { id: "" },
            event: "",
            phase: null,
            createOpportunity: true,
            sourceId: null,
            teamId: null,
            usersId: []
        },
        onSubmit: values => dispatch( addMailtopList( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.listId ) {
                errors.listId = "common:validation.selection";
            }
            if ( !values.funnel || !values.funnel.id ) {
                errors.funnel = {
                    id: "common:validation.selection"
                };
            }
            if ( !values.event ) {
                errors.event = "common:validation.selection";
            }
            if ( values.createOpportunity ) {
                if ( !values.sourceId ) {
                    errors.sourceId = "common:validation.selection";
                }

                if ( !values.teamId && isEmpty( values.usersId ) ) {
                    errors.usersId = "common:validation.dist-empty-users";
                }
            }
            return errors;
        }
    });

    const handleChange = name => value => formik.setFieldValue( name, value );

    const errorUsers = formik.errors && !!formik.errors.usersId && formik.touched?.usersId;

    const { setFieldValue } = formik;
    const prevFunnelId = usePrevious( formik.values.funnel.id );

    useEffect( () => {
        if ( prevFunnelId !== formik.values.funnel.id ) {
            setFieldValue( "phase", null );
        }
    }, [ formik.values.funnel.id, prevFunnelId, setFieldValue ] );
    useEffect( () => {
        if ( !formik.values.createOpportunity ) {
            setFieldValue( "sourceId", null );
            setFieldValue( "teamId", null );
            setFieldValue( "usersId", [] );
        }
    }, [ setFieldValue, formik.values.createOpportunity ] );

    return (
        <Dialog open={ open } maxWidth="sm" fullWidth>
            <DialogTitle>
                { t( "marketplace:integration.mailtop.lists.add" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    <Field
                        name="listId"
                        label={ t( "marketplace:automation.mailtop.lists.single" ) }
                        component={ Dropdown }
                        source={ lists?.map( list => ({ value: list.id, label: list.nome }) ) }
                        required
                    />
                    <Field
                        name="funnel.id"
                        label={ t( "opportunity:funnel" ) }
                        component={ Dropdown }
                        source={ funnels?.map( funnel => ({ value: funnel.id, label: funnel.name }) ) }
                        required
                    />
                    <Field
                        name="phase.id"
                        label={ t( "opportunity:phase" ) }
                        component={ Dropdown }
                        source={ phases?.filter( phase => phase.funnelId === formik.values.funnel.id )
                            .map( phase => ({
                                value: phase.id,
                                label: phase.name
                            }))
                        }
                        sort={ false }
                        disabled={ !formik.values.funnel.id }
                        emptyValue
                        clearParentValue
                    />
                    <Field
                        name="event"
                        label={ t( "marketplace:integration.mailtop.lists.event.label" ) }
                        component={ Dropdown }
                        source={ events.map( event => ({
                            value: event,
                            label: t( `marketplace:automation.mailtop.lists.event.${event}.label` ),
                            helper: t( `marketplace:automation.mailtop.lists.event.${event}.helper` )
                        }))}
                        sort={ false }
                        required
                    />
                    <hr/>
                    <Field
                        name="createOpportunity"
                        type="checkbox"
                        label={ t( "marketplace:automation.mailtop.lists.create-opportunity.label" ) }
                        component={ Switch }
                    />
                    <Typography color="textSecondary" variant="caption">
                        { t( "marketplace:automation.mailtop.lists.create-opportunity.help" ) }
                    </Typography>
                    {
                        formik.values.createOpportunity &&
                            <>
                                <Field
                                    name="sourceId"
                                    label={ t( "opportunity:source" ) }
                                    source={ sources?.map( source => ({
                                        value: source.id,
                                        label: source.name
                                    }))}
                                    component={ Dropdown }
                                    required
                                />
                                <UsersFilter
                                    ComponentRoot={ Fragment }
                                    input={{
                                        team: {
                                            value: formik.values.teamId,
                                            onChange: handleChange( "teamId" )
                                        },
                                        users: {
                                            value: formik.values.usersId,
                                            onChange: handleChange( "usersId" )
                                        }
                                    }}
                                    error={ errorUsers }
                                    fullWidth
                                />
                                {
                                    errorUsers &&
                                        <Typography color="error" variant="caption">
                                            { t( formik.errors.usersId ) }
                                        </Typography>
                                }
                            </>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:add" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddList;