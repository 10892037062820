import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import Table from "../../../ui/table";
import { fetchImportations } from "./product.actions";
import { getImportations, isLoadingImportations } from "./product.selectors";
import ImportationProducts from "./importation-products.component";
import ProductImportationOptions from "./product-importation-options.component";

const ProductImportations = ({ onSelectProducts, onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loading = useSelector( isLoadingImportations );
    const importations = useSelector( getImportations );

    const loadImportations = useCallback( () => {
        dispatch( fetchImportations() );
    }, [ dispatch ] );

    const model = {
        file: t( "common:file" ),
        measurementUnit: {
            title: t( "config:product.measurementUnit.label" ),
            format: measurementUnit => t( `config:product.measurementUnit.${measurementUnit}` )
        },
        recurrent: {
            title: t( "config:product.recurrent" ),
            format: recurrent => recurrent ? t( "common:yes" ) : t( "common:no" )
        },
        createdAt: {
            title: t( "common:creation-date" ),
            format: dateFormat
        },
        finishedAt: {
            title: t( "config:products.importation.finished-at" ),
            format: finishedAt => finishedAt ? dateFormat( finishedAt ) : t( "config:products.importation.processing" )
        },
        options: {
            title: " ",
            size: "small"
        }
    };
    const source = importations?.map( importation => ({
        ...importation,
        options: <ProductImportationOptions importation={ importation } onSelectProducts={ onSelectProducts } />
    }));

    useEffect( () => {
        loadImportations();
    }, [ loadImportations ] );

    return (
        <Card>
            <CardHeader
                title={ t( "config:products.importation.title" ) }
                subheader={ t( "config:products.importation.subheader" ) }
                action={
                    <IconButton onClick={ loadImportations }>
                        <RefreshIcon />
                    </IconButton>
                }
            />

            <Table
                emptyMessage={ t( "config:products.importation.empty" ) }
                isLoading={ loading }
                model={ model }
                source={ source }
            />

            <ImportationProducts onClose={ onClose } show={ show } />
        </Card>
    );
};

export default ProductImportations;