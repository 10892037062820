import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import classnames from "classnames";
import qs from "qs";

import history from "../../../history";
import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import { useFieldValueFormat } from "../../hooks/use-field-value-format.hook";
import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { DialogInfo, cssUtils, flex } from "../../ui";
import { Table } from "../../ui/table";
import { fetchOpportunities, setLossReason } from "../opportunity.actions";
import {
    listAll,
    getTotalPagesAll,
    getTotalElementsAll,
    isLoadingAll,
    getFunnelId,
    getPhaseId,
    getLossReasonId
} from "../opportunity.selectors";
import FunnelFilter from "./funnel-filter.component";
import PhaseFilter from "./phase-filter.component";
import LossReasonFilter from "./loss-reason-filter.component";
import {
    selectors as formSelectors,
    fieldTableTypes
} from "../../form";
import { selectors as coreSelectors } from "../../core";
import { timeDuration } from "../../core/formatter.utils";
import { getTemperatureProps } from "../opportunity.utils";
import ExportationForm from "./exportation-form.component";
import FiltersButton from "./filters-button.component";
import OpportunitiesBatchOptions from "./opportunities-batch-options.component";
import OpportunitiesFilters from "./opportunities-filters.component";
import OpenSchedulesIcon from "./open-schedules-icon.component";
import UserTableColumns from "./user-table-columns.component";
import css from "./table.scss";

const tableRowClassName = opportunity => {
    let isLate = false;
    if ( opportunity.status === "OPEN" && opportunity.phase.days ) {
        const phaseEntryDate = new Date( opportunity.phaseEntryDate );
        const maxPhaseDate = new Date();
        maxPhaseDate.setDate( maxPhaseDate.getDate() - opportunity.phase.days );

        isLate = phaseEntryDate < maxPhaseDate;
    }

    return isLate ? css.late : "";
};

const now = new Date();
const minutesToNow = date => {
    const diff = now.getTime() - date.getTime();
    return Math.round( diff / 60000 );
};

const OpportunitiesTable = ({ onOpenFilters, showOpportunitiesPhases }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );
    const fieldValueFormat = useFieldValueFormat( t );

    const opportunities = useSelector( listAll );
    const totalPages = useSelector( getTotalPagesAll );
    const totalElements = useSelector( getTotalElementsAll );
    const funnelId = useSelector( getFunnelId );
    const phaseId = useSelector( getPhaseId );
    const lossReasonId = useSelector( getLossReasonId );
    const fields = useSelector( formSelectors.list( "opportunity") );
    const companyFields = useSelector( formSelectors.list( "company") );
    const personFields = useSelector( formSelectors.list( "person") );
    const user = useSelector( coreSelectors.getUser );
    const preferences = useSelector( coreSelectors.getPreferences );
    const isLoading = useSelector( isLoadingAll );

    const queryString = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location ] );
    const selectedPage = useMemo( () => Number( queryString.page || 0 ), [ queryString.page ] );

    const [ selectable, setSelectable ] = useState( false );
    const [ showMenuColumns, setShowMenuColumns ] = useState( false );
    const [ allRowsSelected, setAllRowsSelected ] = useState( false );
    const [ allRowsSelectedType, setAllRowsSelectedType ] = useState( "none" );
    const [ opportunitiesSelected, setOpportunitiesSelected ] = useState( new Map() );
    const [ status, setStatus ] = useState( queryString.status || "OPEN" );
    const [ orderBy, setOrderBy ] = useState( queryString.orderBy );
    const [ orderType, setOrderType ] = useState( queryString.orderType || "ASC" );
    const [ showExportationForm, setShowExportationForm ] = useState( false );
    const [ showOpportunitiesBatch, setShowOpportunitiesBatch ] = useState( false );
    const [ showTransferSuccess, setShowTransferSuccess ] = useState( false );

    const prevPreferences = usePrevious( preferences );

    const loadOpportunities = useCallback( () => {
        if ( !funnelId || !orderBy ) {
            return;
        }
        dispatch( fetchOpportunities({
            status,
            funnelId,
            phaseId,
            lossReasonId,
            orderBy,
            orderType,
            page: selectedPage + 1,
            page_size: 50,
        }) );
    }, [ dispatch, funnelId, lossReasonId, orderBy, orderType, phaseId, selectedPage, status ] );

    const makeColName = index => "col" + index;

    const userColumns = useMemo( () => ( get( preferences, "opportunity.mainColumns" ) || [] ), [ preferences ] );

    const mainScreenFields = useMemo( () => {
        return fields
            .filter( field => field.options?.mainScreen )
            .map( field => ({
                ...field,
                checked: userColumns.includes( field.id.toString() ) || isEmpty( userColumns )
            }));
    }, [ fields, userColumns ] );

    const tableModel = useMemo( () => {
        if ( isEmpty( fields ) ) {
            return {};
        }

        const userMainScreenFields = mainScreenFields.filter( field => field.checked );

        let tableModel = {};
        if ( !isEmpty( userMainScreenFields ) ) {
            tableModel = userMainScreenFields.reduce(
                ( model, field, index ) => {
                    model[ makeColName( index ) ] = {
                        value: field.id,
                        title: field.name,
                        type: fieldTableTypes[ field.type ]
                    };
                    return model;
                },
                {}
            );
        }

        if ( isEmpty( userColumns ) || userColumns.includes( "company" ) ) {
            tableModel.company = {
                title: t( "opportunity:company" ),
                format: company => company?.name,
                info: company => ({
                    title: company && company.notes &&
                        <div className={ css.notes }>
                            <b>{ t( "customer:notes.title" ) }:</b> <br/>
                            { company.notes }
                        </div>,
                }),
            };
        }
        if ( isEmpty( userColumns ) || userColumns.includes( "person" ) ) {
            tableModel.person = {
                title: t( "opportunity:person" ),
                format: person => person?.name,
                info: person => ({
                    title: person && person.notes &&
                        <div className={ css.notes }>
                            <b>{ t( "customer:notes.title" ) }:</b> <br/>
                            { person.notes }
                        </div>,
                }),
            };
        }

        if ( userColumns.includes( "phase" ) || isEmpty( userColumns ) ) {
            tableModel.phase = {
                title: t( "opportunity:phase" ),
                format: phase => phase.name
            };
        }
        if ( userColumns.includes( "createdAt" ) || isEmpty( userColumns ) ) {
            tableModel.createdAt = {
                title: t( "opportunity:created-at" ),
                format: createdAt => createdAt ? dateTimeFormat( createdAt ) : ""
            };
        }
        if ( userColumns.includes( "nextScheduling" ) || isEmpty( userColumns ) ) {
            tableModel.nextScheduling = {
                title: t( "opportunity:next-scheduling" ),
                format: nextScheduling => nextScheduling ? dateTimeFormat( nextScheduling ) : ""
            };
        }

        if ( userColumns.includes( "lastInteractAt" ) || isEmpty( userColumns ) ) {
            tableModel.lastInteractAt = {
                title: t( "opportunity:last-interact-at" ),
                format: lastInteractAt => timeDuration( minutesToNow( new Date( lastInteractAt ) ) )
            };
        }

        tableModel.openSchedulesIcon = { title: " ", size: "small", disableOrder: true };
        tableModel.temperatureIcon = { title: " ", size: "small", disableOrder: true};
        return tableModel;
    }, [ dateTimeFormat, fields, mainScreenFields, userColumns, t ] );

    const tableSource = useMemo( () => {
        const userMainScreenFields = mainScreenFields.filter( field => field.checked );
        const checkAll = allRowsSelectedType === "all";

        let tableSource = [];
        if ( isEmpty( opportunities ) ) {
            return tableSource;
        }

        if ( !isEmpty( userMainScreenFields ) ) {
            tableSource = ( opportunities || [] ).map( opportunity => {
                return userMainScreenFields.reduce( ( temp, field, index ) => {
                    const col = makeColName( index );
                    temp[ col ] = fieldValueFormat( field, opportunity.fields[ field.id ] );
                    return temp;
                }, {
                    id: opportunity.id,
                    company: opportunity.company,
                    person: opportunity.person,
                    status: opportunity.status,
                    phase: opportunity.phase,
                    phaseEntryDate: opportunity.phaseEntryDate,
                    createdAt: opportunity.createdAt,
                    nextScheduling: opportunity.nextScheduling,
                    lastInteractAt: opportunity.lastInteractAt,
                    temperature: opportunity.temperature,
                    hasOpenScheduling: opportunity.hasOpenScheduling,
                });
            });
        } else {
            tableSource = opportunities;
        }

        return ( tableSource || [] ).map( ( item, index ) => ({
            ...item,
            selected: checkAll || opportunitiesSelected.has( item.id ),
            openSchedulesIcon: <OpenSchedulesIcon opportunity={ opportunities[ index ] } />,
            temperatureIcon: getTemperatureProps( opportunities[ index ].temperature )
        }));
    }, [ allRowsSelectedType, fieldValueFormat, mainScreenFields, opportunities, opportunitiesSelected ] );

    const toggleMenuColumns = () => setShowMenuColumns( !showMenuColumns );

    const toggleEnableTransferOpportunities = () => {
        const showSelectable = !selectable;

        if ( showSelectable ) {
            setSelectable( showSelectable );
        } else {
            closeTransferOpportunities();
        }
    };

    const toggleOpportunitiesBatch = () => {
        toggleEnableTransferOpportunities();
    };

    const closeTransferOpportunities = () => {
        toggleMenuTransferOpportunities( false );
        setSelectable( false );
        setAllRowsSelected( false );
        setOpportunitiesSelected( new Map() );
        setAllRowsSelectedType( "none" );
    };

    const toggleMenuTransferOpportunities = status => {
        setShowOpportunitiesBatch( status );
    };

    const handlePageChange = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const handleChangeStatus = status => {
        if ( status !== "OPEN") {
            closeTransferOpportunities();
        }
        history.push({
            ...location,
            search: qs.stringify({
                ...queryString,
                page: 0,
                status
            })
        });
        lossReasonId && dispatch( setLossReason( null ) );
        setStatus( status );
    };

    const handleSelect = useCallback( ( tableSource, all = false ) => {
        const selectedCurrentPageCount = tableSource.filter( item => item.selected ).length;
        const allRowsSelectedPage = tableSource.length > 0 && selectedCurrentPageCount === tableSource.length;
        const noneRowsSelectedPage = tableSource.length > 0 && selectedCurrentPageCount === 0;

        let newOpportunitiesSelected = new Map( opportunitiesSelected );

        if ( all ) {
            newOpportunitiesSelected = new Map();
        } else {
            tableSource.forEach( item => {
                if ( item.selected ) {
                    newOpportunitiesSelected.set( item.id, true );
                } else {
                    newOpportunitiesSelected.delete( item.id );
                }
            });
        }

        const menuStatus = newOpportunitiesSelected.size > 0 || all;

        setOpportunitiesSelected( newOpportunitiesSelected );
        setAllRowsSelected( allRowsSelectedPage );
        setAllRowsSelectedType( all ? "all" : noneRowsSelectedPage ? "none" : allRowsSelectedType );

        if ( showOpportunitiesBatch !== menuStatus ) {
            toggleMenuTransferOpportunities( menuStatus );
        }
    }, [ allRowsSelectedType, opportunitiesSelected, showOpportunitiesBatch ] );

    const toggleTransferSuccess = () => {
        const newStatus = !showTransferSuccess;
        setShowTransferSuccess( newStatus );
        if ( !newStatus ) {
            loadOpportunities();
        }
    };

    const toggleShowExportationForm = () => setShowExportationForm( !showExportationForm );

    useEffect( () => {
        loadOpportunities();
    }, [ loadOpportunities ] );
    useEffect( () => {
        if ( prevPreferences &&
            !isEqual( omit( prevPreferences, "mainColumns" ), omit( preferences, "mainColumns" ) )
        ) {
            loadOpportunities();
        }
    }, [ prevPreferences, preferences, loadOpportunities ] );
    useEffect( () => {
        if ( lossReasonId && status !== "LOST" ) {
            dispatch( setLossReason( null ) );
        }
    }, [ dispatch, lossReasonId, status ]);
    useEffect( () => {
        if ( isEmpty( tableModel ) ) {
            return;
        }
        if ( !orderBy ) {
            const orderByKey = Object.keys( tableModel )[ 0 ];
            setOrderBy( tableModel[ orderByKey ].value ? tableModel[ orderByKey ].value.toString() : orderByKey );
            setOrderType( "ASC" );
        }
    }, [ orderBy, tableModel ] );

    const ids = allRowsSelectedType === "all" ? [] : Array.from( opportunitiesSelected.keys() );

    const rowLink = index => opportunities && opportunities[ index ] ?
        `/opportunities/${opportunities[ index ].id}` :
        "";

    const formatTableField = useCallback( propertyKey => {
        let field;
        switch ( propertyKey ) {
            case "company":
                field = companyFields.find( field => field.systemField === "NAME" );
                return `company.fields.${field.id}`;
            case "person":
                field = personFields.find( field => field.systemField === "NAME" );
                return `person.fields.${field.id}`;
            case "phase":
                return "phase";
            case "createdAt":
                return "createdAt";
            case "nextScheduling":
                return "nextScheduling";
            default:
                return null;
        }
    }, [ companyFields, personFields ] );
    const exportationColumns = useMemo( () => {
        const exportationColumns = [];

        if ( isEmpty( fields ) || isEmpty( companyFields ) || isEmpty( personFields ) ) {
            return exportationColumns;
        }

        for ( const propertyKey in tableModel ) {
            if ( get( tableModel[ propertyKey ], "value" ) ) {
                exportationColumns.push( `fields.${ tableModel[ propertyKey ].value }` );
            } else {
                const field = formatTableField( propertyKey );
                field && exportationColumns.push( field );
            }
        }

        return exportationColumns;
    }, [ fields, companyFields, personFields, tableModel, formatTableField ] );

    return (
        <div>
            <DialogInfo
                open={ showTransferSuccess }
                title={ t( "opportunity:update-opportunities.info-title" ) }
                message={ t( "opportunity:update-opportunities.info-content") }
                onClose={ toggleTransferSuccess }
                maxWidth="xs"
            />

            <UserTableColumns open={ showMenuColumns } onClose={ toggleMenuColumns } />

            <OpportunitiesBatchOptions
                ids={ ids }
                open={ showOpportunitiesBatch }
                onClose={ closeTransferOpportunities }
                tableStatus={ status }
                allRowsSelectedType={ allRowsSelectedType }
                toggleTransferSuccess={ toggleTransferSuccess }
            />

            <div className={ showOpportunitiesBatch ? css.tableReduced : "" }>
                <div className={ classnames( flex.container, flex.alignItemsCenter, cssUtils.marginBottomSmall ) }>
                    <div className={ classnames( cssUtils.fullWidth, css.containerSelectedFilters ) }>
                        <OpportunitiesFilters status={ status } />
                    </div>

                    <div className={ css.filtersLeft }>
                        <FormControl id="opportunityStatus">
                            <RadioGroup
                                className={ css.containerStatus }
                                value={ status }
                                onChange={ e => handleChangeStatus( e.target.value ) }
                            >
                                <FormControlLabel
                                    className={ cssUtils.marginRight }
                                    label={ t( "opportunity:status.OPEN" ) }
                                    value="OPEN"
                                    control={ <Radio /> }
                                />
                                <FormControlLabel
                                    className={ cssUtils.marginRight }
                                    label={ t( "opportunity:status.SOLD" ) }
                                    value="SOLD"
                                    control={ <Radio /> }
                                />
                                <FormControlLabel
                                    className={ cssUtils.marginRightSmall }
                                    label={ t( "opportunity:status.LOST" ) }
                                    value="LOST"
                                    control={ <Radio /> }
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            status === "LOST" &&
                                <LossReasonFilter className={ css.filterOpportunityLoss }/>
                        }
                    </div>

                    <div className={ css.filtersRight }>

                        <FunnelFilter
                            className={ flex.fill }
                            fullWidth={ false }
                            size="small"
                        />
                        <PhaseFilter
                            className={ classnames( flex.fill, cssUtils.marginLeft, cssUtils.marginRight ) }
                            fullWidth={ false }
                            size="small"
                        />

                        <div>
                            { tableSource && tableSource.length > 0 ?
                                <Tooltip title={ t( "opportunity:opportunity-list.transfer-oportunities" ) }>
                                    <IconButton
                                        className={ cssUtils.floatRight }
                                        onClick={ toggleOpportunitiesBatch }
                                        size="large">
                                        <ShuffleIcon />
                                    </IconButton>
                                </Tooltip>
                                : null
                            }

                            <Tooltip title={ t( "opportunity:opportunity-list.show-cards" ) }>
                                <IconButton
                                    id="opportunitiesPhases"
                                    className={ cssUtils.floatRight }
                                    onClick={ showOpportunitiesPhases }
                                    size="large">
                                    <ViewCarouselIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={ t( "customer:customers-list.select-columns" ) }>
                                <IconButton
                                    className={ cssUtils.floatRight }
                                    onClick={ toggleMenuColumns }
                                    size="large">
                                    <ViewColumnIcon/>
                                </IconButton>
                            </Tooltip>

                            {
                                user.admin &&
                                    <>
                                        <Tooltip title={ t( "common:export" ) }>
                                            <IconButton onClick={ toggleShowExportationForm } size="large">
                                                <CloudDownloadIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <ExportationForm
                                            initialColumns={ exportationColumns }
                                            opportunityStatus={ status }
                                            open={ showExportationForm }
                                            onClose={ toggleShowExportationForm }
                                        />
                                    </>
                            }

                            <FiltersButton onClick={ onOpenFilters } preferences={ preferences } />
                        </div>
                    </div>
                </div>

                <Table
                    model={ tableModel }
                    source={ tableSource }
                    selectable={ selectable }
                    showSelectAllOptions
                    allRowsSelected={ allRowsSelected }
                    allRowsSelectedType={ allRowsSelectedType }
                    rowLink={ rowLink }
                    onSelect={ handleSelect }
                    rowClassName={ tableRowClassName }
                    isLoading={ isLoading }
                    emptyMessage={ t( "opportunity:opportunity-list.empty" ) }
                    totalPages={ totalPages }
                    totalElements={ totalElements }
                    pageSize={ 50 }
                    onChangePage={ handlePageChange }
                    saveOrderHistory
                    allowOrder
                    orderBy={ orderBy }
                    orderType={ orderType }
                    selectedPage={ selectedPage }
                />
            </div>
        </div>
    );
};

export default OpportunitiesTable;