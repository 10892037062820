export const SET_AUTH = "x/SET_AUTH";
export function setAuth ( logged, oauthGrant ) {
    return {
        type: SET_AUTH,
        payload: { logged, ...oauthGrant }
    };
}

export const LOGIN_SUBMIT = "x/LOGIN_SUBMIT";
export function loginSubmit ( credentials, nextUrl ) {
    return {
        type: LOGIN_SUBMIT,
        payload: { credentials, nextUrl }
    };
}

export const REQUEST_ACTIVATE = "x/REQUEST_SUBSCRIPTION_ACTIVATE";
export const requestActivate = email => ({
    type: REQUEST_ACTIVATE,
    payload: { email }
});

export const SEND_RESET_PASSWORD = "x/SEND_RESET_PASSWORD";
export const sendResetPassword = data => ({
    type: SEND_RESET_PASSWORD,
    payload: data
});

export const FETCH_PASSWORD_RESET_STATUS = "x/FETCH_PASSWORD_RESET_STATUS";
export const fetchPasswordResetStatus = id => ({
    type: FETCH_PASSWORD_RESET_STATUS,
    meta: { id }
});

export const SET_PASSWORD_RESET_STATUS = "x/SET_PASSWORD_RESET_STATUS";
export const setPasswordResetStatus = status => ({
    type: SET_PASSWORD_RESET_STATUS,
    payload: status
});

export const SAVE_PASSWORD_RESET = "x/SAVE_PASSWORD_RESET";
export const savePasswordReset = ({ id, ...data }) => ({
    type: SAVE_PASSWORD_RESET,
    meta: { id },
    payload: data
});

export const VERIFY_ACCESS_TOKEN = "x/VERITY_ACCESS_TOKEN";
export const verifyAccessToken = data => ({
    type: VERIFY_ACCESS_TOKEN,
    payload: data
});