import { PureComponent } from "react";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import classnames from "classnames";

import { Autocomplete, cssUtils, flex } from "../../ui";
import css from "./automation.scss";

class MappingFieldItem extends PureComponent {

    render () {
        const { field } = this.props;

        return (
            <div className={ classnames( flex.fill, flex.container, flex.alignItemsCenter ) }>
                <Typography className={ flex.fill } variant="subtitle1" color="textSecondary">
                    { field.name }
                    { field.options && field.options.required && " *" }
                </Typography>
                <ArrowForwardIcon
                    className={ classnames( cssUtils.marginLeftSmall, cssUtils.marginRightSmall ) }
                />
                <div className={ css.mappingFieldItem }>
                    <Field
                        name={ `mappingFields[${field.id}]` }
                        component={ Autocomplete }
                        allowCreate
                        source={ [] }
                        placeholder="Campo da requisição"
                        variant="outlined"
                    />
                </div>
            </div>
        );
    }

}

export default MappingFieldItem;