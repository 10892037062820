import InsertChartIcon from "@mui/icons-material/InsertChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import useTranslate from "../hooks/use-translate.hook";
import { isLoadingTimeline, getTotalPagesTimeline, reloadTimeline as isReloadTimeline } from "./customer.selectors";
import { receiveTimeline } from "./customer.actions";
import CustomerKPIs from "./customer-kpis.component";
import CustomerTimeline from "./customer-timeline.component";
import OpportunitiesPanel from "./opportunities-panel.component";
import { SectionTitle, cssUtils, flex } from "../ui";
import css from "./customer.scss";

const CustomerOverview = ({ customerId, getScrollParent }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const totalPagesTimeline = useSelector( getTotalPagesTimeline );
    const loadingTimeline = useSelector( isLoadingTimeline );
    const reloadTimeline = useSelector( isReloadTimeline );

    const [ pageTimeline, setPageTimeline ] = useState( 1 );

    const incrementPageTimeline = useCallback( () => {
        if ( loadingTimeline ) {
            return;
        }

        const page = pageTimeline + 1;
        setPageTimeline( page );
    }, [ loadingTimeline, pageTimeline ] );

    useEffect( () => {
        reloadTimeline && setPageTimeline( 1 );
    }, [ reloadTimeline ] );
    useEffect( () => {
        return () => {
            dispatch( receiveTimeline( null ) );
        };
    }, [ dispatch ] );

    return (
        <InfiniteScroll
            loadMore={ incrementPageTimeline }
            useWindow={ false }
            hasMore={ pageTimeline < totalPagesTimeline }
            className={ flex.container }
            initialLoad={ false }
            getScrollParent={ getScrollParent }
        >
            <div className={ css.containerContent }>
                <div className={ css.container }>
                    <div className={ css.content }>
                        <SectionTitle icon={ InsertChartIcon } name={ t( "customer:overview.stats" ) } />
                        <CustomerKPIs customerId={ customerId } />

                        <hr className={ cssUtils.marginVerticalLarge } />

                        <SectionTitle
                            icon={ AttachMoneyIcon }
                            name={ t( "customer:overview.open-opportunities" ) }
                        />
                        <OpportunitiesPanel customerId={ customerId } />
                        <hr className={ cssUtils.marginVerticalLarge } />
                        <CustomerTimeline customerId={ customerId } page={ pageTimeline } />
                    </div>
                </div>
            </div>
        </InfiniteScroll>

    );
};

export default CustomerOverview;