import { useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import useTranslate from "../../../hooks/use-translate.hook";
import usePrevious from "../../../hooks/use-previous.hook";
import { selectors as userSelectors } from "../../../user";
import { Checkbox, Dropdown, cssUtils, flex } from "../../../ui";
import WeightedDistributionForm from "./weighted-distribution-form.component";

const UsersField = ({ form, push, remove }) => {
    const t = useTranslate();

    const users = useSelector( userSelectors.listAll );

    const { setFieldValue, values } = form;

    const isDistributionWeighted = values.opportunityDistributionType === "WEIGHTED";
    const prevIsDistributionWeighted = usePrevious( isDistributionWeighted );

    const availableUsers = useMemo( () => {
        const chosenUsers = values.users
            .filter( field => field && field.userId )
            .map( field => field.userId );

        return users.filter( user => !chosenUsers.includes( user.id ) ).filter( user => user.active );
    }, [ values.users, users ] );

    const rows = useMemo( () => {
        return values.users.map( ( userSelected, index ) => {
            const sourceUsers = availableUsers.slice();
            if ( userSelected && userSelected.userId ) {
                sourceUsers.push( users.find( user => user.id === userSelected.userId ) );
            }
            return (
                <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                    <Field
                        name={ `users[${index}].userId` }
                        label={ t( "config:team.users.single" ) }
                        source={
                            sourceUsers.map( user => ({
                                label: user.name,
                                value: user.id
                            }))
                        }
                        className={ isDistributionWeighted ? flex.item50 : flex.fill }
                        required
                        component={ Dropdown }
                    />
                    <Field
                        name={ `users[${index}].leader` }
                        type="checkbox"
                        label={ t( "config:team.users.leader" ) }
                        className={ classnames( cssUtils.marginLeftSmall, cssUtils.marginBottom0 ) }
                        fullWidth={ false }
                        component={ Checkbox }
                    />
                    {
                        isDistributionWeighted &&
                            <WeightedDistributionForm
                                className={ classnames( flex.fill, cssUtils.marginBottom0 ) }
                                name={ `users[${index}].weight` }
                            />
                    }
                    <IconButton onClick={ () => remove( index ) } size="large">
                        <CancelIcon/>
                    </IconButton>
                </li>
            );
        });
    }, [ availableUsers, t, users, values.users, isDistributionWeighted, remove ] );

    const handleAdd = useCallback( () => {
        push({ userId: null, leader: false, weight: isDistributionWeighted ? 0 : null });
    }, [ isDistributionWeighted, push ] );

    useEffect( () => {
        if ( prevIsDistributionWeighted && !isDistributionWeighted ) {
            values.users.forEach( ( user, index ) => setFieldValue( `users[${index}].weight`, null ) );
        }
    }, [ prevIsDistributionWeighted, isDistributionWeighted, setFieldValue, values.users ] );

    if ( isEmpty( users ) ) {
        return null;
    }

    return (
        <div>
            <div className={ classnames( cssUtils.marginTop, flex.container ) }>
                <Typography variant="caption">{ t( "config:team.users.label" ) }</Typography>
                {
                    isDistributionWeighted &&
                        <Typography variant="subtitle1" color="textSecondary">
                            { t( "config:team.weight.info" ) }
                        </Typography>
                }
            </div>
            <List>{ rows }</List>
            {
                form.errors?.users &&
                    <Typography color="error" paragraph variant="caption">
                        { t( form.errors.users ) }
                    </Typography>
            }
            {
                rows.length !== users.length &&
                    <Button
                        className={ cssUtils.marginBottom }
                        size="small"
                        onClick={ handleAdd }
                        startIcon={ <PersonAddIcon/> }
                    >
                        { t( "config:team.users.add" ) }
                    </Button>
            }
        </div>
    );
};

export default UsersField;