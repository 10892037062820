import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TodayIcon from "@mui/icons-material/Today";
import classnames from "classnames";
import orderBy from "lodash/orderBy";

import { useDateFormat, useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils } from "../../../ui";
import { getSubscriptionBilling, getSubscriptionCoupon } from "./plan.selectors";
import CancelPlan from "./cancel-plan.component";
import css from "./plan.scss";

const PlanContainer = ({
    disabled,
    plan,
    onClick,
    openForm,
    selected
}) => {
    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const dateTimeFormat = useDateTimeFormat( t );

    const subscriptionBilling = useSelector( getSubscriptionBilling );
    const subscriptionCoupon = useSelector( getSubscriptionCoupon );

    const hired = subscriptionBilling && subscriptionBilling.planId === plan.id;

    const priceFormat = usePriceFormat( "BRL" );

    const handleClick = () => onClick( plan.id );

    const subscriptionCouponDescription = useMemo( () => {
        if ( !subscriptionCoupon ) {
            return "";
        }
        let discount;
        switch ( subscriptionCoupon.discountType ) {
            case "PERCENTAGE":
                discount = t( "config:plans.discount-percentage", { discount: subscriptionCoupon.discount } );
                break;
            case "TOTAL":
                discount = t( "config:plans.discount-total", { discount: priceFormat( subscriptionCoupon.discount ) } );
                break;
            default:
                discount = t( "config:plans.discount-unit", { discount: priceFormat( subscriptionCoupon.discount ) } );
                break;
        }

        const minUserLimit = subscriptionCoupon.minUserLimit &&
            t( "config:plans.min-user-limit", { minUserLimit: subscriptionCoupon.minUserLimit } );

        const maxUserLimit = subscriptionCoupon.maxUserLimit &&
            t( "config:plans.max-user-limit", { maxUserLimit: subscriptionCoupon.maxUserLimit } );

        const minAndMaxUserLimit = minUserLimit && maxUserLimit &&
            t( "config:plans.min-and-max-user-limit", {
                minUserLimit: subscriptionCoupon.minUserLimit,
                maxUserLimit: subscriptionCoupon.maxUserLimit
            } );

        const couponInformation = !maxUserLimit && !minUserLimit ?
            <span>
                { subscriptionCoupon.token + " - " + discount }
            </span> :
            <>
                { subscriptionCoupon.token }
                <span>
                    {
                        " - " + discount + " - " +
                        ( minAndMaxUserLimit ? minAndMaxUserLimit : minUserLimit ? minUserLimit : maxUserLimit )
                    }
                </span>
            </>;


        return couponInformation;
    }, [ t, priceFormat, subscriptionCoupon ]);

    const formatPlanPrice = useCallback( price => {
        const showDiscount = subscriptionCoupon && !subscriptionCoupon.minUserLimit && !subscriptionCoupon.maxUserLimit;

        if ( ( subscriptionCoupon && subscriptionCoupon.discountType !== "TOTAL" ) && showDiscount ) {
            let newPrice = subscriptionCoupon.discountType === "UNIT" ?
                price - subscriptionCoupon.discount :
                price - ( price * subscriptionCoupon.discount / 100 );
            newPrice = Math.round( ( newPrice + Number.EPSILON ) * 100 ) / 100;
            return `<del>${priceFormat(price)}</del> ${priceFormat( newPrice )} ${t( "config:plans.price" )}`;
        }
        return `${priceFormat( price )} ${t( "config:plans.price" )}`;
    }, [ t, priceFormat, subscriptionCoupon ]);

    return (
        <div className={ css.planContainer }>
            <Tooltip title={ selected ? "" : t( "common:select" ) } placement="top">
                <div className={ classnames( css.plan, selected && css.selectedPlan ) } onClick={ handleClick }>
                    <List disablePadding dense className={ css.planListInfo }>
                        {
                            subscriptionCoupon &&
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar className={ selected ? css.selectedAvatar : "" }>
                                            <LocalOfferIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ subscriptionCouponDescription }
                                        secondary={ subscriptionCoupon.expireAt && t( "config:plans.coupon-expire", {
                                            date: dateTimeFormat( subscriptionCoupon.expireAt )
                                        } ) }
                                    />
                                </ListItem>
                        }
                        {
                            orderBy( plan.ranges, [ "start" ] ).map( ( { start, end, price }, index ) => (
                                <ListItem key={ index } disableGutters>
                                    <ListItemAvatar>
                                        {
                                            index === 0 &&
                                                <Avatar className={ selected ? css.selectedAvatar : "" }>
                                                    <AttachMoneyIcon />
                                                </Avatar>
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            end ?
                                                t( "config:plans.quantity-with-end", { start, end } ) :
                                                t( "config:plans.quantity-without-end", { start } )
                                        }
                                        secondary={
                                            <span dangerouslySetInnerHTML={{ __html: formatPlanPrice( price ) }} />
                                        }
                                    />
                                </ListItem>
                            ))
                        }
                        {
                            hired &&
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar className={ selected ? css.selectedAvatar : "" }><TodayIcon/></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            t( `config:plans.frequency.${subscriptionBilling.billingFrequency}` )
                                        }
                                    />
                                </ListItem>
                        }
                    </List>
                </div>
            </Tooltip>
            <Button
                className={ css.planButton }
                color="primary"
                disabled={ !selected || disabled }
                variant="outlined"
                onClick={ openForm }
            >
                { selected && hired ? t( "common:edit" ) : t( "config:plans.hire" ) }
            </Button>
            { hired && <CancelPlan /> }
            {
                hired && subscriptionBilling.nextBillingAt &&
                    <Typography color="textSecondary" className={ cssUtils.marginTopSmall }>
                        { t( "config:billings.next", { date: dateFormat( subscriptionBilling.nextBillingAt ) } ) }
                    </Typography>
            }
        </div>
    );
};

export default PlanContainer;