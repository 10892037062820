import { Field } from "formik";
import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { getGoals } from "../../../goal/goal.selectors";
import { fetchProducts } from "../../../product/product.actions";
import {
    getActiveProducts,
    listCategories,
    isLoadingProducts,
} from "../../../product/product.selectors";
import { Autocomplete, Checkbox, Dropdown, Input, MaskInput, PriceInput, SelectFunnel, UsersFilter } from "../../../ui";

export const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }
    if ( !values.type ) {
        errors.type = "common:validation.selection";
    } else if ( values.type === "PRODUCT" ) {
        if ( !values.productCategoryId && isEmpty( values.products ) ) {
            errors.products = "config:commission.products.at-least";
        }
    }

    if ( !values.funnel || !values.funnel.id ) {
        errors.funnel = {
            id: "common:validation.selection"
        };
    }
    if ( !values.priceType ) {
        errors.priceType = "common:validation.selection";
    }
    if ( !values.price ) {
        errors.price = "common:validation.required";
    }
    if ( !values.closingDay ) {
        errors.closingDay = "common:validation.required";
    } else if ( values.closingDay > 30 ) {
        errors.closingDay = "config:commission.closing-day.bigger";
    } else if ( values.closingDay < 1 ) {
        errors.closingDay = "config:commission.closing-day.lower";
    }

    return errors;
};

const CommissionForm = ({
    setFieldValue,
    values,
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const goals = useSelector( getGoals );
    const products = useSelector( getActiveProducts );
    const productCategories = useSelector( listCategories );
    const loadingProducts = useSelector( isLoadingProducts );

    const prevProductCategoryId = usePrevious( values.productCategoryId );

    const handleChange = name => value => setFieldValue( name, value );

    const productsSource = useMemo( () => {
        const source = products
            .concat( values.products.filter( product => products?.filter( p => p.id !== product.id ) ) )
            .map( product => ({
                value: product,
                label: ( product.code ? product.code + " - " : "" ) + product.description,
                helper: product.recurrent ?
                    t( "config:product.with-recurrence" ) :
                    t( "config:product.without-recurrence" )
            }));

        return source;
    }, [ t, products, values.products ] );

    let timeoutProducts;
    const searchProduct = description => {
        clearTimeout( timeoutProducts );
        if ( description ) {
            timeoutProducts = setTimeout( () => {
                dispatch( fetchProducts({ description, categoryId: values.productCategoryId }) );
            }, 300 );
        }
    };

    useEffect( () => {
        if ( values.type === "OPPORTUNITY" ) {
            setFieldValue( "productCategoryId", null );
            setFieldValue( "products", [] );
            setFieldValue( "opportunityPrice", true );
        }
        if ( values.type === "PRODUCT" ) {
            setFieldValue( "opportunityPrice", null );
            setFieldValue( "opportunityRecurrentPrice", null );
        }
    }, [ setFieldValue, values.type ] );

    useEffect( () => {
        if ( values.productCategoryId &&
            prevProductCategoryId !== values.productCategoryId &&
            !isEmpty( values.products )
        ) {
            setFieldValue(
                "products",
                values.products.filter( product => product.category?.id === values.productCategoryId )
            );
        }
    }, [ setFieldValue, values.products, values.productCategoryId, prevProductCategoryId ] );


    return (
        <>
            <Field
                name="name"
                label={ t( "common:name" ) }
                component={ Input }
                required
            />
            <Field
                name="funnel.id"
                component={ SelectFunnel }
                required
            />
            <Field
                name="type"
                label={ t( "common:type" ) }
                source={ [ "OPPORTUNITY", "PRODUCT" ].map( type => ({
                    value: type,
                    label: t( `config:commission.type.${type}` )
                }) ) }
                component={ Dropdown }
                required
            />
            {
                values.type === "OPPORTUNITY" &&
                    <>
                        <Field
                            name="opportunityPrice"
                            type="checkbox"
                            label={ t( "config:commission.opportunity-price" ) }
                            component={ Checkbox }
                            disabled={ !values.opportunityRecurrentPrice }
                        />
                        <Field
                            name="opportunityRecurrentPrice"
                            type="checkbox"
                            label={ t( "config:commission.opportunity-recurrent-price" ) }
                            component={ Checkbox }
                            disabled={ !values.opportunityPrice }
                        />
                    </>
            }
            {
                values.type === "PRODUCT" &&
                    <>
                        <Field
                            name="productCategoryId"
                            label={ t( "config:product.category.label" ) }
                            component={ Dropdown }
                            source={ productCategories.map( category => ({
                                value: category.id, label: category.name
                            }))}
                            emptyValue
                        />
                        <Field
                            name="products"
                            source={ productsSource }
                            label={ t( "config:products.title" ) }
                            component={ Autocomplete }
                            multiple
                            loading={ loadingProducts }
                            onQueryChange={ searchProduct }
                            getOptionLabel={ option =>
                                option ? ( option.value ? option.value.description : option.description || "" ) : ""
                            }
                            filterOptions={ options => options }
                        />
                    </>
            }

            <hr />

            <Field
                name="priceType"
                label={ t( "config:commission.price-type.label" ) }
                component={ Dropdown }
                source={ [ "UNIT", "PERCENTAGE" ].map( type => ({
                    value: type,
                    label: t( `config:commission.price-type.${type}` )
                }))}
                required
            />
            {
                values.priceType === "PERCENTAGE" ?
                    <Field
                        name="price"
                        label={ t( "common:price" ) }
                        format="## %"
                        component={ MaskInput }
                        required
                    /> :
                    <Field
                        name="price"
                        label={ t( "common:price" ) }
                        component={ PriceInput }
                        required
                    />
            }

            <Field
                name="closingDay"
                type="number"
                label={ t( "config:commission.closing-day.label" ) }
                component={ Input }
                required
            />
            <Typography color="textSecondary" variant="caption">
                { t( "config:commission.closing-day.helper" ) }
            </Typography>

            <hr />

            <Field
                name="goalId"
                label={ t( "config:commission.goal.label" ) }
                helperText={ t( "config:commission.goal.helper" ) }
                component={ Dropdown }
                source={ goals.map( goal => ({ value: goal.id, label: goal.name } ) ) }
                emptyValue
            />

            <UsersFilter
                ComponentRoot={ Fragment }
                input={{
                    team: {
                        value: values.teamId,
                        onChange: handleChange( "teamId" )
                    },
                    users: {
                        value: values.usersId,
                        onChange: handleChange( "usersId" )
                    }
                }}
                fullWidth
            />
            <Typography color="textSecondary" variant="caption">
                { t( "config:commission.users-helper" ) }
            </Typography>
        </>
    );
};

export default CommissionForm;