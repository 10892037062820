import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./goal.actions";

const all = createRequestReducer({
    begin: actions.REQUEST_GOALS,
    success: actions.RECEIVE_GOALS,
    failure: actions.ERROR_GOALS
});

const progress = createRequestReducer({
    begin: actions.REQUEST_GOALS_PROGRESS,
    success: actions.RECEIVE_GOALS_PROGRESS,
    error: actions.ERROR_GOALS_PROGRESS,
});

export const goals = combineReducers({ all, progress });