import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";

import useTranslate from "../../../hooks/use-translate.hook";
import ConfigHeader from "../../config-header.component";
import * as teamsActions from "../../../team/team.actions";
import * as teamsSelectors from "../../../team/team.selectors";
import * as usersActions from "../../../user/user.actions";
import * as usersSelectors from "../../../user/user.selectors";
import NewDistribution from "./new-distribution.component";
import Distributions from "./distributions.component";

const Distribution = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loadingTeams = useSelector( teamsSelectors.isLoadingAll );
    const loadingUsers = useSelector( usersSelectors.isLoadingAll );
    const [ openNewDistribution, setOpenNewDistribution ] = useState( false );

    const loading = useMemo( () => loadingTeams || loadingUsers, [ loadingTeams, loadingUsers ] );

    useEffect( () => {
        dispatch( teamsActions.fetchTeams() );
        dispatch( usersActions.fetchUsers() );
    }, [ dispatch ] );

    const toggleNewDistribution = () => setOpenNewDistribution( !openNewDistribution );

    return (
        <div>
            <ConfigHeader
                title={ t( "config:distribution.title" ) }
                actionTitle={ t( "config:distribution.add" ) }
                actionIcon={ AddIcon }
                onActionClick={ toggleNewDistribution }
                disabled={ loading }
            />
            <Card>
                <CardHeader subheader={ t( "config:distribution.detail" ) } />
                {
                    loading ?
                        <LinearProgress/> :
                        <Distributions/>
                }
            </Card>

            <NewDistribution open={ openNewDistribution } onClose={ toggleNewDistribution }/>
        </div>
    );
};

export default Distribution;