import { useMemo } from "react";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import { getSubscription } from "../subscription/subscription.selectors";
import MaskInput from "./mask-input.component";

const PriceInput = ({
    defaultLabel,
    label,
    hidePrefix,
    disableDecimal,
    ...props
}) => {
    const subscription = useSelector( getSubscription );
    const currency = useMemo( () => getSymbolFromCurrency( subscription.currency ) + " ", [ subscription ] );
    const decimalSeparator = useMemo( () => (1.1).toLocaleString().substring( 1, 2 ), [] );

    return (
        <MaskInput
            { ...props }
            isNumericString
            label={ defaultLabel ? currency : label }
            allowNegative={ false }
            fixedDecimalScale
            decimalScale={ disableDecimal ? null : 2 }
            thousandSeparator={ decimalSeparator === "." ? "," : "." }
            decimalSeparator={ disableDecimal ? null : decimalSeparator }
            prefix={ hidePrefix ? "" : currency }
        />
    );
};

export default PriceInput;
