import classnames from "classnames";

import css from "./stepper.scss";
import { cssUtils } from "../../core";

const StepArrow = ({ index, selected, text, showTitle, ...props }) => (
    <li
        className={ classnames(
            css.step,
            cssUtils.cursorPointer,
            { [ css.stepDisabled ]: index > selected, [ css.stepActive ]: index === selected }
        )}
        title={ showTitle ? text : null }
        { ...props }
    >
        <div className={ classnames( css.line, { [ css.stepDisabled ]: index > selected } ) } />

        <div className={ classnames( cssUtils.textCenter, css.stepContent ) }>
            <span className={ css.label }>{ text }</span>
        </div>

        <div className={ classnames( css.line, { [ css.stepDisabled ]: index > selected } ) } />
        <div className={ classnames( css.arrowRight, { [ css.stepDisabled ]: index > selected } ) } />
    </li>
);

export default StepArrow;