import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, select, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as sourceActions, selectors as sourceSelectors } from "../../../source";
import * as actions from "./source.actions";
import { SOURCE_FORM } from "./source.constants";

export function *watchConfigOpportunitySource () {
    yield all([
        takeEvery( actions.SAVE_SOURCES, saveSources ),
        takeEvery( actions.DELETE_SOURCE, deleteSource )
    ]);
}

export function *saveSources ( action ) {
    const { sources } = action.payload;
    const patchedSources = [];

    try {
        yield put( startSubmit( SOURCE_FORM ) );

        for ( const source of sources ) {
            let response;
            if ( source.id ) {
                response = yield call( apiv1.patch, "/sources/" + source.id, source );
            } else {
                response = yield call( apiv1.post, "/sources", source );
            }

            patchedSources.push( response.data );
        }

        patchedSources.sort( ( o1, o2 ) => o1.name < o2.name ? -1 : o1.name > o2.name ? 1 : 0 );
        yield put( stopSubmit( SOURCE_FORM ) );
        yield put( sourceActions.receiveSources( patchedSources ) );
    } catch ( e ) {
        yield put( stopSubmit( SOURCE_FORM, e.response.data ) );
    }
}

export function *deleteSource ({ payload }) {
    yield put( startSubmit( SOURCE_FORM ) );
    const { source } = payload;

    const sources = yield select( sourceSelectors.getSources );

    try {
        yield call( apiv1.delete, "/sources/" + source.id );

        const filteredSources = sources.filter( item => item.id !== source.id );
        yield put( sourceActions.receiveSources( filteredSources ) );
        yield put( stopSubmit( SOURCE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SOURCE_FORM, e.response.data ) );
    }
}