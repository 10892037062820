export const SAVE_TEMPLATE = "x/config/opportunity/template/SAVE_TEMPLATE";
export const saveTemplate = data => ({
    type: SAVE_TEMPLATE,
    payload: data
});

export const DELETE_TEMPLATE = "x/config/opportunity/template/DELETE_TEMPLATE";
export const deleteTemplate = id => ({
    type: DELETE_TEMPLATE,
    meta: { id }
});

export const UPLOAD_IMAGE = "x/config/opportunity/template/UPLOAD_IMAGE";
export const uploadImage = data => ({
    type: UPLOAD_IMAGE,
    payload: data
});

export const DELETE_IMAGE = "x/config/opportunity/template/DELETE_IMAGE";
export const deleteImage = id => ({
    meta: { id },
    type: DELETE_IMAGE
});

export const TEST_TEMPLATE = "x/config/opportunity/template/TEST_TEMPLATE";
export const testTemplate = data => ({
    type: TEST_TEMPLATE,
    payload: data
});

// File
export const INIT_FILE_MODELS = "x/config/opportunity/template/INIT_FILE_MODELS";
export const initFileModels = () => ({
    type: INIT_FILE_MODELS
});

export const TOGGLE_NEW_FILE_MODEL = "x/config/opportunity/template/TOGGLE_NEW_FILE_MODEL";
export const toggleNewFileModel = () => ({
    type: TOGGLE_NEW_FILE_MODEL
});

export const CREATE_FILE_MODEL = "x/config/opportunity/template/CREATE_FILE_MODEL";
export const createFileModel = fileModel => ({
    type: CREATE_FILE_MODEL,
    payload: fileModel
});

export const OPEN_FILE_MODEL = "x/config/opportunity/template/OPEN_FILE_MODEL";
export const openFileModel = fileModel => ({
    type: OPEN_FILE_MODEL,
    payload: fileModel
});

export const CLOSE_FILE_MODEL = "x/config/opportunity/template/CLOSE_FILE_MODEL";
export const closeFileModel = () => ({
    type: CLOSE_FILE_MODEL
});

export const SAVE_FILE_MODEL = "x/config/opportunity/template/SAVE_FILE_MODEL";
export const saveFileModel = fileModel => ({
    type: SAVE_FILE_MODEL,
    payload: fileModel
});

export const DELETE_FILE_MODEL = "x/config/opportunity/template/DELETE_FILE_MODEL";
export const deleteFileModel = id => ({
    type: DELETE_FILE_MODEL,
    meta: { id }
});

export const SAVE_FILE_MODEL_SECTION = "x/config/opportunity/template/SAVE_FILE_MODEL_SECTION";
export const saveFileModelSection = data => ({
    type: SAVE_FILE_MODEL_SECTION,
    payload: data
});

export const NEW_FILE_MODEL_SECTION = "x/config/opportunity/form/NEW_FILE_MODEL_SECTION";
export const newFileModelSection = data => ({
    type: NEW_FILE_MODEL_SECTION,
    payload: data
});

export const DELETE_FILE_MODEL_SECTION = "x/config/opportunity/template/DELETE_FILE_MODEL_SECTION";
export const deleteFileModelSection = ( id, fileModelId ) => ({
    type: DELETE_FILE_MODEL_SECTION,
    meta: { id, fileModelId }
});

export const SELECT_FILE_MODEL_SECTION = "x/config/opportunity/template/SELECT_FILE_MODEL_SECTION";
export const selectFileModelSection = index => ({
    type: SELECT_FILE_MODEL_SECTION,
    payload: { index }
});

export const DESELECT_FILE_MODEL_SECTION = "x/config/opportunity/template/DESELECT_FILE_MODEL_SECTION";
export const deselectFileModelSection = () => ({
    type: DESELECT_FILE_MODEL_SECTION
});

export const SORT_FILE_MODEL_SECTION = "x/config/opportunity/template/SORT_FILE_MODEL_SECTION";
export const sortFileModelSection = ({ oldIndex, newIndex }) => ({
    type: SORT_FILE_MODEL_SECTION,
    payload: { oldIndex, newIndex }
});

export const CANCEL_SORTING_FILE_MODEL_SECTION = "x/config/opportunity/template/CANCEL_SORTING_FILE_MODEL_SECTION";
export const cancelSortingFileModelSection = () => ({
    type: CANCEL_SORTING_FILE_MODEL_SECTION
});

export const SAVE_SORTING_FILE_MODEL_SECTION = "x/config/opportunity/template/SAVE_SORTING_FILE_MODEL_SECTION";
export const saveSortingFileModelSection = () => ({
    type: SAVE_SORTING_FILE_MODEL_SECTION
});