import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classnames from "classnames";

import { cssUtils, flex } from "../ui";
import { fieldIcons } from "../form";

export const DRAG_SOURCE_TYPE = "mappginField";

const FormField = ({ field, onChange }) => {

    const [ , drag, preview ] = useDrag(() => ({
        type: DRAG_SOURCE_TYPE,
        item: { id: field.id, field },
        end: ( item, monitor ) => {
            if ( monitor.getDropResult() !== null ) {
                const { order } = monitor.getDropResult();
                const { field } = monitor.getItem();
                onChange({ order, field });
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }), [ field ]);

    useEffect(() => {
        preview( getEmptyImage(), { captureDraggingState: true });
    }, [ preview ] );

    const Icon = fieldIcons[ field.type ];

    return (
        <div
            ref={ drag }
            className={ classnames(
                cssUtils.marginLeftSmall,
                cssUtils.marginRightSmall,
                cssUtils.marginBottomSmall
            )}
        >
            <Card>
                <CardContent className={ classnames( flex.container, flex.alignItemsCenter ) }>
                    <Icon className={ cssUtils.marginRight }/>
                    <span>{ field.name + ( field.options && field.options.required ? " *" : "" ) }</span>
                </CardContent>
            </Card>
        </div>
    );
};

export default FormField;