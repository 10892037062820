import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { saveSignature } from "./user.actions";
import { USER_SIGNATURE_FORM } from "./user.constants";
import { InputFile, cssUtils, flex } from "../ui";
import css from "./user.scss";

const UserSignature = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirmRemove, setConfirmRemove ] = useState( false );

    const closeConfirmRemove = () => setConfirmRemove( false );
    const openConfirmRemove = () => setConfirmRemove( true );

    const formik = useForm({
        form: USER_SIGNATURE_FORM,
        initialValues: {
            file: null,
        },
        onSubmit: values => dispatch( saveSignature( values ) ),
        onSubmitSuccess: closeConfirmRemove,
    });

    const { submitForm, values } = formik;

    const removeSignature = () => {
        formik.setFieldValue( "file", null );
        formik.submitForm();
    };

    useEffect( () => {
        values.file && submitForm();
    }, [ values.file, submitForm ] );

    return (
        <div>
            <Dialog open={ confirmRemove }>
                <DialogContent>
                    <DialogContentText>{ t( "user:user.confirm-remove-signature" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ closeConfirmRemove }>{ t( "user:no" ) }</Button>
                    <Button color="primary" onClick={ removeSignature }>
                        { t( "user:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Card>
                <Form formik={ formik } encType="multipart/form-data">
                    {
                        user.signature &&
                            <CardContent className={ cssUtils.marginSmall }>
                                <img src={ user.signature } />
                            </CardContent>
                    }
                    <CardContent>
                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <div className={ flex.container }>
                                <Field
                                    name="file"
                                    component={ InputFile }
                                    accept="image/*"
                                />
                                <Typography
                                    className={ cssUtils.fullWidth }
                                    variant="caption"
                                >
                                    { t( "user:user.help-signature" ) }
                                </Typography>

                                <hr className={ cssUtils.fullWidth } />
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        {
                            user.signature &&
                                <Button
                                    disabled={ formik.submitting }
                                    variant="contained"
                                    className={ css.removeAvatar }
                                    onClick={ openConfirmRemove }
                                >
                                    { t( "user:remove" ) }
                                </Button>
                        }
                        <Button
                            type="submit"
                            color="primary"
                            disabled={ !formik.values.file || formik.submitting }
                        >
                            { t( "user:save" ) }
                        </Button>
                    </CardActions>
                </Form>
            </Card>
        </div>
    );
};

export default UserSignature;