import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { saveAvatar } from "./user.actions";
import { USER_AVATAR_FORM } from "./user.constants";
import { Dialog, InputFile, cssUtils, flex } from "../ui";
import css from "./user.scss";

const UserAvatar = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirmRemove, setConfirmRemove ] = useState( false );
    const [ showSubmitError, setShowSubmitError ] = useState( false );

    const closeSubmitError = () => setShowSubmitError( false );
    const closeConfirmRemove = () => setConfirmRemove( false );
    const openConfirmRemove = () => setConfirmRemove( true );

    const formik = useForm({
        form: USER_AVATAR_FORM,
        initialValues: {
            avatar: user.avatar,
        },
        onSubmit: values => dispatch( saveAvatar( values ) ),
        onSubmitSuccess: () => setConfirmRemove( false ),
    });

    const removeAvatar = () => {
        formik.setFieldValue( "avatar", null );
        formik.submitForm();
    };

    useEffect( () => {
        formik.error && setShowSubmitError( true );
    }, [ formik.error ] );

    return (
        <>
            <Dialog open={ confirmRemove }>
                <DialogContent>
                    <DialogContentText>{ t( "user:user.confirm-remove-avatar" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={ formik.submitting }
                        onClick={ closeConfirmRemove }
                    >
                        { t( "user:no" ) }
                    </Button>
                    <Button
                        color="primary"
                        disabled={ formik.submitting }
                        onClick={ removeAvatar }
                        className={ css.removeAvatar }
                    >
                        { t( "user:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={ showSubmitError }
                action={
                    <Button color="secondary" onClick={ closeSubmitError }>
                        { t( "common:ok" ) }
                    </Button>
                }
                message={ formik.error?.error }
                autoHideDuration={ 5000 }
                onClose={ closeSubmitError }
            />

            <Form formik={ formik } encType="multipart/form-data">
                <CardContent>
                    <div className={ classnames( flex.displayFlex, flex.alignItemsCenter ) }>
                        <Avatar
                            src={ user.avatar }
                            className={ classnames( css.avatar, cssUtils.marginRight ) }
                        >
                            { user.avatar ?
                                "" :
                                <span className={ css.avatarTitle }>
                                    { get( user, "name", "" ).charAt( 0 ).toUpperCase() }
                                </span>
                            }
                        </Avatar>

                        <div className={ flex.fill }>
                            <Field
                                name="avatar"
                                component={ InputFile }
                                accept="image/*"
                            />

                            <hr />

                            {
                                user.avatar &&
                                    <Button
                                        disabled={ formik.submitting }
                                        variant="contained"
                                        size="small"
                                        className={ css.removeAvatar }
                                        onClick={ openConfirmRemove }
                                    >
                                        { t( "user:remove" ) }
                                    </Button>
                            }
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={ !formik.values.avatar || formik.values.avatar === user.avatar || formik.submitting }
                    >
                        { t( "user:save" ) }
                    </Button>
                </CardActions>
            </Form>
        </>
    );
};

export default UserAvatar;