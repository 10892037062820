import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { actions as phaseActions, selectors as phaseSelectors } from "../../phase";
import { setPhase } from "../opportunity.actions";
import { Dropdown } from "../../ui";
import { getPhaseId, getFunnelId } from "../opportunity.selectors";

const PhaseFilter = ({ className, fullWidth, size }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );

    const loading = useSelector( phaseSelectors.isLoadingFunnelPhases( funnelId ) );
    const phases = useSelector( phaseSelectors.listWithFunnel( funnelId ) );
    const selected = useSelector( getPhaseId );

    const handleChange = useCallback( event => {
        dispatch( setPhase( event.target.value ) );
    }, [ dispatch ]);
    const source = useMemo( () => {
        return phases?.map( phase => ( { value: phase.id, label: phase.name } ) ) || [];
    }, [ phases ] );

    useEffect( () => {
        if ( selected && !phases.find( phase => phase.id === selected ) ) {
            dispatch( setPhase( null ) );
        }
    }, [ dispatch, selected, phases ] );
    useEffect( () => {
        funnelId && dispatch( phaseActions.fetchFunnelPhases( funnelId ) );
    }, [ dispatch, funnelId ] );

    return (
        <Dropdown
            label={ t( "opportunity:phase" ) }
            disabled={ loading || isEmpty( phases ) }
            value={ selected }
            onChange={ handleChange }
            source={ source }
            className={ className }
            fullWidth={ fullWidth }
            size={ size }
            emptyValue
        />
    );
};

export default PhaseFilter;
