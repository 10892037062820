import { combineReducers } from "redux";

import * as actions from "./holding.actions";

const holding = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_HODLING:
            return {
                loading: true
            };
        case actions.RECEIVE_HOLDING:
            return {
                loading: false,
                data: action.payload
            };
        case actions.ERROR_HOLDING:
            return {
                data: null,
                loading: false,
                error: action.payload
            };
        case actions.REQUEST_HOLDING_FUNNELS:
            return {
                ...state,
                loading: true
            };
        case actions.RECEIVE_HOLDING_FUNNELS:
            return {
                ...state,
                loading: false,
                funnels: action.payload
            };
        default:
            return state;
    }
};

export const holdingConfig = combineReducers({
    holding
});