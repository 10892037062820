import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import classnames from "classnames";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { Dialog, RadioButtons, cssUtils } from "../../ui";
import { changeOpenSchedulesStatus, fetchSchedules } from "../opportunity.actions";
import { OPEN_SCHEDULES_STATUS_FORM } from "../opportunity.constants";
import { getOpenSchedules, isLoadingSchedules } from "../opportunity.selectors";

const ChangeOpenSchedulesStatus = ({ open, opportunityId, onClose, updateAfter }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loading = useSelector( isLoadingSchedules );
    const schedules = useSelector( getOpenSchedules );

    const initialValues = useMemo( () => ({
        id: opportunityId,
        status: "OPEN",
        updateAfter,
    }), [ opportunityId, updateAfter ] );

    const formik = useForm({
        form: OPEN_SCHEDULES_STATUS_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( changeOpenSchedulesStatus( values ) ),
        onSubmitSuccess: onClose,
    });

    useEffect( () => {
        open && opportunityId && dispatch( fetchSchedules( opportunityId ) );
    }, [ dispatch, open, opportunityId ] );

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogContent>
                {
                    loading ?
                        <CircularProgress/> :
                        <DialogContentText>
                            { t( "opportunity:opportunity-list.open-schedules.title" ) }
                        </DialogContentText>
                }
                <List>
                    {
                        schedules.map( scheduling => (
                            <ListItem
                                key={ scheduling.id }
                                className={ classnames(
                                    cssUtils.paddingLeft0,
                                    cssUtils.paddingRight0
                                )}
                            >
                                <ListItemText
                                    primary={
                                        scheduling.opportunitySchedulingType.name +
                                        " - " + dateFormat( scheduling.startDate ) +
                                        ( scheduling.user ? " - " + scheduling.user.name : "" )
                                    }
                                    secondary={ scheduling.description }
                                />
                            </ListItem>
                        ))
                    }
                </List>
                <DialogContentText>
                    { t( "opportunity:opportunity-list.open-schedules.call-to-action" ) }
                </DialogContentText>
                <Form formik={ formik }>
                    <Field
                        name="status"
                        component={ RadioButtons }
                        row={ false }
                        options={[
                            {
                                value: "ACCOMPLISHED",
                                label: t( "opportunity:opportunity-list.open-schedules.accomplished" )
                            }, {
                                value: "NOT_ACCOMPLISHED",
                                label: t( "opportunity:opportunity-list.open-schedules.not-accomplished" )
                            }, {
                                value: "OPEN",
                                label: t( "opportunity:opportunity-list.open-schedules.open" )
                            }
                        ]}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:confirm" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeOpenSchedulesStatus;