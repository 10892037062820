const FlagIcon = ({ code, ...props }) => {
    return (
        <img
            src={ `https://flagcdn.com/24x18/${code}.png`}
            width="28"
            height="21"
            loading="lazy"
            { ...props }
        />
    );
};

export default FlagIcon;