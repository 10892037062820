import { createRequestReducer } from "redux-request-state";
import { combineReducers } from "redux";

import * as actions from "./plan.actions";

const all = createRequestReducer({
    begin: actions.REQUEST_PLANS,
    success: actions.RECEIVE_PLANS,
    failure: actions.ERROR_PLANS
});

export const plans = combineReducers({ all });