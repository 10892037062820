import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./funnel.actions";

export function *watchFunnel () {
    yield all([
        takeEvery( actions.FETCH_FUNNELS, fetchFunnels )
    ]);
}

export function *fetchFunnels () {
    yield put( actions.requestFunnels() );
    try {
        const response = yield call( apiv1.get, "/funnels" );
        yield put( actions.receiveFunnels( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFunnels( e.response.data ) );
    }
}