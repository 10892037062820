import { combineReducers } from "redux";

import * as creators from "./product.actions";

export function all ( state = {}, action ) {

    switch ( action.type ) {
        case creators.REQUEST_PRODUCTS:
            return Object.assign( {}, state, {
                loading: true
            });

        case creators.RECEIVE_PRODUCTS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload.products,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            });

        case creators.ERROR_PRODUCTS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
}

const search = ( state = {}, action ) => {
    switch ( action.type ) {
        case creators.REQUEST_SEARCH_PRODUCTS:
            return Object.assign( {}, state, {
                loading: true
            });

        case creators.RECEIVE_SEARCH_PRODUCTS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload
            });

        case creators.ERROR_SEARCH_PRODUCTS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
};

const categories = ( state = {}, action ) => {
    switch ( action.type ) {
        case creators.REQUEST_PRODUCT_CATEGORIES:
            return Object.assign( {}, state, {
                loading: true
            });

        case creators.RECEIVE_PRODUCT_CATEGORIES:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload
            });

        case creators.ERROR_PRODUCT_CATEGORIES:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
};

export const products = combineReducers({ all, search, categories });