export const CREATE_FUNNEL = "x/config/opportunity/CREATE_FUNNEL";
export const createFunnel = data => ({
    type: CREATE_FUNNEL,
    payload: data
});

export const SAVE_FUNNEL = "x/config/opportunity/SAVE_FUNNEL";
export const saveFunnel = data => ({
    type: SAVE_FUNNEL,
    payload: data
});

export const DELETE_FUNNEL = "x/config/opportunity/DELETE_FUNNEL";
export const deleteFunnel = ({ id, phases }) => ({
    type: DELETE_FUNNEL,
    meta: { id },
    payload: phases
});

export const DELETE_PHASE = "x/config/opportunity/DELETE_PHASE";
export const deletePhase = phase => ({
    type: DELETE_PHASE,
    payload: { phase }
});