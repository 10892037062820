import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import TimelineIcon from "@mui/icons-material/Timeline";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import useTranslate from "../hooks/use-translate.hook";
import { getTotalPagesTimeline, isLoadingTimeline, reloadTimeline as isReloadTimeline } from "./opportunity.selectors";
import { receiveTimeline } from "./opportunity.actions";
import OpportunityPhases from "./opportunity-phases.component";
import OpportunityTemperature from "./opportunity-temperature.component";
import { NewScheduling, OpenSchedules } from "./scheduling";
import OpportunityTimeline from "./opportunity-timeline.component";
import { SectionTitle, cssUtils } from "../ui";
import css from "./opportunity.scss";

const OpportunityOverview = ({ getScrollParent, opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const totalPagesTimeline = useSelector( getTotalPagesTimeline );
    const loadingTimeline = useSelector( isLoadingTimeline );
    const reloadTimeline = useSelector( isReloadTimeline );

    const [ pageTimeline, setPageTimeline ] = useState( 1 );

    const incrementPageTimeline = useCallback( () => {
        if ( loadingTimeline ) {
            return;
        }

        setPageTimeline( page => page + 1 );
    }, [ loadingTimeline ] );

    useEffect( () => {
        if ( reloadTimeline ) {
            setPageTimeline( 1 );
        }
    }, [ reloadTimeline ] );
    useEffect( () => {
        return () => {
            dispatch( receiveTimeline( null ) );
        };
    }, [ dispatch ]);

    return (
        <InfiniteScroll
            loadMore={ incrementPageTimeline }
            useWindow={ false }
            hasMore={ pageTimeline < totalPagesTimeline }
            className={ cssUtils.fullWidth }
            initialLoad={ false }
            getScrollParent={ getScrollParent }
        >
            <div className={ css.containerContent }>
                <div className={ css.container }>
                    <div className={ css.content }>
                        <SectionTitle
                            id="opportunityScheduling"
                            icon={ SpeakerNotesIcon }
                            name={ t( "opportunity:overview.schedules" ) }
                        />
                        <OpenSchedules opportunityId={ opportunityId }/>
                        <NewScheduling />

                        <hr className={ cssUtils.marginVerticalLarge } />

                        <div id="opportunityPhaseAndTemperature">
                            <SectionTitle icon={ TimelineIcon } name={ t( "opportunity:overview.phases" ) } />
                            <OpportunityPhases />

                            <hr className={ cssUtils.marginVerticalLarge } />

                            <SectionTitle
                                icon={ BrightnessMediumIcon }
                                name={ t( "opportunity:overview.temperature" ) }
                            />
                            <OpportunityTemperature />
                        </div>

                        <hr className={ cssUtils.marginVerticalLarge } />

                        <OpportunityTimeline
                            id="opportunityTimeline"
                            opportunityId={ opportunityId }
                            page={ pageTimeline }
                        />
                    </div>
                </div>
            </div>
        </InfiniteScroll>
    );
};

export default OpportunityOverview;