import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { isSubmitting } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/ErrorOutline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { useFieldValueFormat } from "../hooks/use-field-value-format.hook";
import { NEW_OPPORTUNITY_FORM, SEARCH_CUSTOMERS_FORM, selectors } from "./";
import * as actions from "./opportunity.actions";
import { Dialog, cssUtils, flex } from "../ui";
import { selectors as formSelectors } from "../form";
import css from "./opportunity.scss";
import NewPerson from "./new-opportunity/new-person.component";
import EditPerson from "./new-opportunity/edit-person.component";
import EditCompany from "./new-opportunity/edit-company.component";
import NewCompany from "./new-opportunity/new-company.component";
import OpportunityForm from "./new-opportunity/opportunity-form.component";
import SearchCustomers from "./new-opportunity/search-customers.component";

const NewOpportunity = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const fieldValueFormat = useFieldValueFormat( t );

    const companyFields = useSelector( formSelectors.list( "COMPANY" ) );
    const personFields = useSelector( formSelectors.list( "PERSON" ) );
    const show = useSelector( selectors.isNewOpportunityOpen );
    const persons = useSelector( selectors.getNewOpportunityPersons );
    const companies = useSelector( selectors.getNewOpportunityCompanies );
    const selectedCompany = useSelector( selectors.getNewOpportunityCompany );
    const selectedPerson = useSelector( selectors.getNewOpportunityPerson );
    const submittingCustomersSearch = useSelector( isSubmitting( SEARCH_CUSTOMERS_FORM ) );
    const submittingNewOpportunity = useSelector( isSubmitting( NEW_OPPORTUNITY_FORM ) );

    const [ step, setStep ] = useState( 0 );
    const [ newPersonFormOpen, setNewPersonFormOpen ] = useState( false );
    const [ newCompanyFormOpen, setNewCompanyFormOpen ] = useState( false );
    const [ editingCompanyFormOpen, setEditingCompanyFormOpen ] = useState( false );
    const [ editingPersonFormOpen, setEditingPersonFormOpen ] = useState( false );
    const [ showEmptyFilterSearchError, setShowEmptyFilterSearchError ] = useState( false );
    const [ showEmptySelectionCustomerError, setShowEmptySelectionCustomerError ] = useState( false );

    const searchCustomerForm = useRef();
    const newOpportunityForm = useRef();

    const hasCompanySelected = useMemo( () => !!selectedCompany, [ selectedCompany ] );
    const hasPersonSelected = useMemo( () => !!selectedPerson, [ selectedPerson ] );

    const selectPerson = customer => dispatch( actions.setNewOpportunityPerson( customer ) );
    const selectCompany = customer => dispatch( actions.setNewOpportunityCompany( customer ) );

    const toggleSelectPerson = customer => () => {
        const selected = isSelectedPerson( customer );
        selectPerson( selected ? null : customer );
        if ( !selected ) {
            if ( customer.company && isSelectedCompany( customer.company ) ) {
                selectCompany( null );
            }
        }
    };
    const toggleSelectCompany = customer => () => selectCompany( isSelectedCompany( customer ) ? null : customer );

    const toResultSearch = () => searchCustomerForm.current.requestSubmit();

    const submitSearchCustomers = values => {
        const emptyPerson = Object.keys( values.person )
            .filter( key => !isEmpty( values.person[ key ] ) )
            .length === 0;
        const emptyCompany = Object.keys( values.company )
            .filter( key => !isEmpty( values.company[ key ] ) )
            .length === 0;
        if ( emptyPerson && emptyCompany ) {
            setShowEmptyFilterSearchError( true );
        } else {
            setShowEmptyFilterSearchError( false );
            dispatch( actions.searchCustomers( values ) );
        }
    };

    const unselectCustomer = () => {
        setNewPersonFormOpen( false );
        setNewCompanyFormOpen( false );
        setEditingPersonFormOpen( false );
        setEditingCompanyFormOpen( false );
        selectPerson( null );
        selectPerson( null );
    };

    const handleSearchSuccess = () => setStep( 1 );

    const toPreviousStep = () => {
        if ( step === 1 ) {
            unselectCustomer();
        }
        setStep( step - 1 );
    };

    const toOpportunityForm = () => {
        if ( !hasPersonSelected && !hasCompanySelected ) {
            setShowEmptySelectionCustomerError( true );
        } else {
            setShowEmptySelectionCustomerError( false );
            setStep( 2 );
        }
    };

    const showNewCustomerCompanyForm = () => setNewCompanyFormOpen( true );
    const showNewCustomerPersonForm = () => setNewPersonFormOpen( true );

    const closeEditCustomerCompanyForm = () => setEditingCompanyFormOpen( false );
    const closeEditCustomerPersonForm = () => setEditingPersonFormOpen( false );

    const handleCloseCompanyForm = () => setNewCompanyFormOpen( false );
    const handleClosePersonForm = () => setNewPersonFormOpen( false );

    const startEditingCustomer = customer => () => {
        if ( customer.type === "PERSON" ) {
            setEditingPersonFormOpen( true );
            selectPerson( customer );
        } else {
            setEditingCompanyFormOpen( true );
            selectCompany( customer );
        }
    };

    const personValueBySystemField = systemField => {
        const field = personFields.find( field => field.systemField === systemField );
        return fieldValueFormat( field, selectedPerson.fields[ field.id ] );
    };

    const companyValueBySystemField = systemField => {
        const field = companyFields.find( field => field.systemField === systemField );
        return fieldValueFormat( field, selectedCompany.fields[ field.id ] );
    };

    const personValueByFieldType = type => {
        const field = personFields.find( field => field.type === type );
        return field ?
            fieldValueFormat( field, selectedPerson.fields[ field.id ] ) :
            "";
    };

    const companyValueByFieldType = type => {
        const field = companyFields.find( field => field.type === type );
        return field ?
            fieldValueFormat( field, selectedCompany.fields[ field.id ] ) :
            "";
    };

    const isSelectedCompany = customer => ( selectedCompany || {} ).id === customer.id;
    const isSelectedPerson = customer => ( selectedPerson || {} ).id === customer.id;

    const submitNewOpportunity = () => newOpportunityForm.current.requestSubmit();

    const handleClose = () => dispatch( actions.closeNewOpportunity() );

    const prevShow = usePrevious( show );

    useEffect( () => {
        if ( prevShow && !show ) {
            setStep( 0 );
            setNewPersonFormOpen( false );
            setNewCompanyFormOpen( false );
            setEditingCompanyFormOpen( false );
            setEditingPersonFormOpen( false );
            setShowEmptyFilterSearchError( false );
            setShowEmptySelectionCustomerError( false );
            dispatch( actions.setNewOpportunityCompany( null ) );
            dispatch( actions.setNewOpportunityPerson( null ) );
        }
    }, [ dispatch, prevShow, show ] );

    const searchPersons = step === 1 ?
        persons.map( person => {
            return (
                <ListItem
                    key={ person.id }
                    disablePadding
                    secondaryAction={
                        <IconButton onClick={ startEditingCustomer( person ) } size="large">
                            <EditIcon/>
                        </IconButton>
                    }
                >
                    <ListItemButton
                        onClick={ toggleSelectPerson( person ) }
                        selected={ isSelectedPerson( person ) }
                    >
                        <ListItemIcon>
                            {
                                isSelectedPerson( person ) ?
                                    <RadioButtonCheckedIcon /> :
                                    <RadioButtonUncheckedIcon />
                            }
                        </ListItemIcon>
                        <ListItemText primary={ person.name } />
                    </ListItemButton>
                </ListItem>
            );
        }) :
        [];

    const searchCompanies = step === 1 ?
        companies.map( company => {
            return (
                <ListItem
                    key={ company.id }
                    disablePadding
                    secondaryAction={
                        <IconButton onClick={ startEditingCustomer( company ) } size="large">
                            <EditIcon/>
                        </IconButton>
                    }
                >
                    <ListItemButton
                        onClick={ toggleSelectCompany( company ) }
                        selected={ isSelectedCompany( company ) }
                    >
                        <ListItemIcon>
                            {
                                isSelectedCompany( company ) ?
                                    <RadioButtonCheckedIcon /> :
                                    <RadioButtonUncheckedIcon />
                            }
                        </ListItemIcon>
                        <ListItemText primary={ company.name } />
                    </ListItemButton>
                </ListItem>
            );
        }) :
        [];

    const personCompany = step === 1 && selectedPerson && selectedPerson.company &&
        (
            <ListItem
                disablePadding
                secondaryAction={
                    <IconButton
                        onClick={ startEditingCustomer( selectedPerson.company ) }
                        size="large">
                        <EditIcon/>
                    </IconButton>
                }
            >
                <ListItemButton
                    onClick={ toggleSelectCompany( selectedPerson.company ) }
                    selected={ isSelectedCompany( selectedPerson.company ) }
                >
                    <ListItemIcon>
                        {
                            isSelectedCompany( selectedPerson.company ) ?
                                <RadioButtonCheckedIcon /> :
                                <RadioButtonUncheckedIcon />
                        }
                    </ListItemIcon>
                    <ListItemText
                        primary={ selectedPerson.company.name }
                        secondary={
                            t( "opportunity:new-opportunity.company-vinculed", { person: selectedPerson.name } )
                        }
                    />
                </ListItemButton>
            </ListItem>
        );

    return (
        <div className={ cssUtils.overflowHidden } >
            <Dialog
                className={ cssUtils.overflowHidden }
                open={ show || false }
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle className={ css.newOpportunityTitle }>
                    { t( "opportunity:new-opportunity.title" ) }
                </DialogTitle>

                <DialogContent classes={{ root: css.newOpportunityForm }}>
                    <>
                        <div className={ css.newOpportunityStepper } elevation={ 0 }>
                            <Stepper
                                activeStep={ step }
                            >
                                <Step>
                                    <StepLabel>
                                        { t( "opportunity:new-opportunity.search-customer" ) }
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        { t( "opportunity:new-opportunity.select-customer" ) }
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        { t( "opportunity:opportunity" ) }
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                        {
                            step === 0 &&
                                <SearchCustomers
                                    setRef={ searchCustomerForm }
                                    onSubmitSuccess={ handleSearchSuccess }
                                    onSubmit={ submitSearchCustomers }
                                />
                        }
                        {
                            step === 1 &&
                                <div className={ flex.container }>
                                    <div className={ classnames( flex.fill, css.searchCustomerContainer ) }>
                                        <span className={ classnames( cssUtils.fontBold ) }>
                                            { t( "customer:person.title" ) }
                                        </span>
                                        {
                                            ( !newPersonFormOpen && !editingPersonFormOpen ) &&
                                                <List disablePadding>
                                                    <Typography variant="body2">
                                                        { t( "opportunity:persons.select-new" ) }
                                                    </Typography>
                                                    <div className={ cssUtils.marginTopSmall }>
                                                        { searchPersons }
                                                        {
                                                            isEmpty( searchPersons ) &&
                                                                <ListItem>
                                                                    <ListItemIcon
                                                                        className={ classnames(
                                                                            cssUtils.paddingLeft0,
                                                                            css.iconCreation
                                                                        )}
                                                                    >
                                                                        <WarningIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            t( "common:customer-person-empty" )
                                                                        }
                                                                    />
                                                                </ListItem>
                                                        }

                                                    </div>
                                                    <ListItemButton onClick={ showNewCustomerPersonForm }>
                                                        <ListItemIcon
                                                            className={ classnames(
                                                                cssUtils.paddingLeft0,
                                                                css.iconCreation
                                                            )}
                                                        >
                                                            <AddIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={ t( "customer:person.create" ) }
                                                        />
                                                    </ListItemButton>
                                                </List>
                                        }

                                        {
                                            editingPersonFormOpen &&
                                                <EditPerson onClose={ closeEditCustomerPersonForm } />
                                        }
                                        {
                                            newPersonFormOpen &&
                                                <NewPerson onClose={ handleClosePersonForm }/>
                                        }
                                    </div>

                                    <Divider flexItem orientation="vertical"/>

                                    <div className={ classnames( flex.fill, css.searchCustomerContainer ) }>
                                        <span className={ classnames( cssUtils.fontBold ) }>
                                            { t( "customer:company.title" ) }
                                        </span>
                                        {
                                            ( !newCompanyFormOpen && !editingCompanyFormOpen ) &&
                                                <List disablePadding>
                                                    <Typography variant="body2">
                                                        { t( "opportunity:companies.select-new" ) }
                                                    </Typography>
                                                    <div
                                                        className={ classnames(
                                                            cssUtils.paddingBottom,
                                                            cssUtils.marginTopSmall
                                                        )}
                                                    >
                                                        { personCompany }
                                                        { personCompany && <Divider/> }
                                                        { searchCompanies }
                                                        {
                                                            isEmpty( searchCompanies ) &&
                                                                <ListItem>
                                                                    <ListItemIcon
                                                                        className={ classnames(
                                                                            cssUtils.paddingLeft0,
                                                                            css.iconCreation
                                                                        )}
                                                                    >
                                                                        <WarningIcon/>
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            t( "common:customer-company-empty" )
                                                                        }
                                                                    />
                                                                </ListItem>
                                                        }
                                                        <ListItemButton onClick={ showNewCustomerCompanyForm }>
                                                            <ListItemIcon
                                                                className={ classnames(
                                                                    cssUtils.paddingLeft0,
                                                                    css.iconCreation
                                                                )}
                                                            >
                                                                <AddIcon className={ cssUtils.paddingLeft0 } />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className={ cssUtils.paddingLeft0 }
                                                                primary={ t( "customer:company.create" ) }
                                                            />
                                                        </ListItemButton>
                                                    </div>
                                                </List>
                                        }

                                        <div className={ cssUtils.marginTopSmall }>
                                            {
                                                editingCompanyFormOpen &&
                                                    <EditCompany
                                                        onClose={ closeEditCustomerCompanyForm }
                                                    />
                                            }
                                            {
                                                newCompanyFormOpen &&
                                                    <NewCompany onClose={ handleCloseCompanyForm } />
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        {
                            step === 2 &&
                                <div className={ flex.container }>
                                    <div className={ flex.fill }>
                                        <div
                                            className={ classnames(
                                                cssUtils.marginTopSmall,
                                                css.selectedDataInfo
                                            )}
                                        >
                                            <Typography className={ cssUtils.fontBold }>
                                                { t( "opportunity:new-opportunity.selected-customer-data" ) }
                                            </Typography>
                                        </div>

                                        <div className={ flex.container }>
                                            <div className={ flex.fill }>
                                                <div className={ css.searchCustomerContainer }>
                                                    <Typography className={ cssUtils.marginTopSmall }>
                                                        { t( "opportunity:person-data" ) }
                                                    </Typography>
                                                </div>
                                            </div>

                                            <div className={ flex.fill }>
                                                <div className={ css.searchCustomerContainer }>
                                                    <Typography className={ cssUtils.marginTopSmall }>
                                                        { t( "opportunity:company-data" ) }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={ flex.container }>
                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasPersonSelected ?
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t( "common:name" ) }
                                                                secondary={ personValueBySystemField( "NAME" ) }
                                                            />
                                                        </ListItem> :
                                                        <div className={ cssUtils.marginTopSmall }>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <WarningIcon/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    secondary={ t( "common:customer-empty" ) }
                                                                />
                                                            </ListItem>
                                                        </div>
                                                }
                                            </List>

                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasCompanySelected ?
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t( "common:name" ) }
                                                                secondary={
                                                                    companyValueBySystemField( "NAME" )
                                                                }
                                                            />
                                                        </ListItem>
                                                        : <div className={ cssUtils.marginTopSmall }>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <WarningIcon/>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    secondary={ t( "common:customer-empty" ) }
                                                                />
                                                            </ListItem>
                                                        </div>
                                                }
                                            </List>
                                        </div>

                                        <div className={ flex.container }>
                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasPersonSelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t( "common:ssn" ) }
                                                                secondary={ personValueBySystemField( "SSN" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>

                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasCompanySelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t( "common:ein" ) }
                                                                secondary={ companyValueBySystemField( "EIN" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>
                                        </div>

                                        <div className={ flex.container }>
                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasPersonSelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:email") }
                                                                secondary={ personValueByFieldType( "EMAIL" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>

                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasCompanySelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:email") }
                                                                secondary={ companyValueByFieldType( "EMAIL" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>
                                        </div>

                                        <div className={ flex.container }>
                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasPersonSelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:phone") }
                                                                secondary={ personValueByFieldType( "PHONE" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>

                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasCompanySelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:phone") }
                                                                secondary={ companyValueByFieldType( "PHONE" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>
                                        </div>

                                        <div className={ flex.container }>
                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasPersonSelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:address") }
                                                                secondary={ personValueByFieldType( "ADDRESS" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>

                                            <List dense disablePadding className={ flex.fill }>
                                                {
                                                    hasCompanySelected &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ t("common:address") }
                                                                secondary={ companyValueByFieldType( "ADDRESS" ) }
                                                            />
                                                        </ListItem>
                                                }
                                            </List>
                                        </div>
                                    </div>
                                    <div className={ flex.fill }>
                                        <OpportunityForm
                                            setRef={ newOpportunityForm }
                                            company={ selectedCompany }
                                            person={ selectedPerson }
                                        />
                                    </div>
                                </div>
                        }
                    </>
                </DialogContent>

                <DialogActions>
                    {
                        step === 0 &&
                            <Fragment>
                                {
                                    showEmptyFilterSearchError &&
                                        <Typography color="error">
                                            { t( "opportunity:new-opportunity.customer-search-error" ) }
                                        </Typography>
                                }
                                <Button
                                    color="primary"
                                    onClick={ handleClose }
                                    disabled={ submittingCustomersSearch }
                                >
                                    { t( "common:cancel" ) }
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={ toResultSearch }
                                    className={ cssUtils.marginLeftSmall }
                                    disabled={ submittingCustomersSearch }
                                >
                                    { t( "common:next" ) }
                                </Button>
                            </Fragment>
                    }
                    {
                        step === 1 &&
                            <Fragment>
                                {
                                    showEmptySelectionCustomerError &&
                                        <Typography color="error">
                                            { t( "opportunity:new-opportunity.customer-error" ) }
                                        </Typography>
                                }
                                <Button
                                    color="primary"
                                    onClick={ toPreviousStep }
                                >
                                    { t( "common:back" ) }
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={ toOpportunityForm }
                                    variant="contained"
                                    disabled={
                                        newCompanyFormOpen ||
                                        newPersonFormOpen ||
                                        editingCompanyFormOpen ||
                                        editingPersonFormOpen
                                    }
                                >
                                    { t( "common:next" ) }
                                </Button>
                            </Fragment>
                    }
                    {
                        step === 2 &&
                            <Fragment>
                                <Button
                                    color="primary"
                                    onClick={ toPreviousStep }
                                    disabled={ submittingNewOpportunity }>
                                    { t( "common:back" ) }
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={ submitNewOpportunity }
                                    disabled={ submittingNewOpportunity }>
                                    { t( "common:save" ) }
                                </Button>
                            </Fragment>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewOpportunity;