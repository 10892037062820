export default {
    root: {
        display: "inline-grid",
        width: "100%"
    },

    dark: {
        "&multiLine": {
            input: {
                color: "#fff",
            },
        },
        "&singleLine": {
            input: {
                color: "#fff",
            },
        },
        suggestions: {
            list: {
                backgroundColor: "black",
            }
        }
    },

    "&multiLine": {
        control: {
            minHeight: 100,
        },
        highlighter: {
            padding: 4,
            border: "0",
        },
        input: {
            paddingTop: 8,
            outline: 0,
            border: 0,
            overflow: "hidden",
        },
        textarea: {
            outline: 0,
            border: 0,
            overflow: "hidden",
            borderStyle: "none",
            borderColor: "Transparent",
        }
    },

    "&singleLine": {
        display: "inline-block",
        width: "100%",

        highlighter: {
            padding: "1px 0 1px",
            border: "0",
        },
        input: {
            padding: "8.5px 0 8.5px",
            outline: 0,
            border: 0,
            overflow: "hidden",
        },
    },

    suggestions: {
        list: {
            backgroundColor: "white",
            border: "0",
        },
        item: {
            borderBottom: "0",
            "&focused": {
            },
        },
    }
};