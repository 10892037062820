import { all } from "redux-saga/effects";

import * as holdingSagas from "./holding/holding.sagas";
import * as kanbanSagas from "./kanban/kanban.sagas";
import * as smpServerSagas from "./smtp-server/smtp-server.sagas";
import * as migrationSagas from "./migration/migration.sagas";

export function *watchConfigAdvanced () {
    yield all([
        holdingSagas.watchConfigAdvancedHolding(),
        kanbanSagas.watchConfigKanban(),
        smpServerSagas.watchConfigAdvancedSmtpServer(),
        migrationSagas.watchConfigAdvancedMigration(),
    ]);
}