import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import history from "../../history";
import tracker from "../util/tracker.utils";
import usePrevious from "../hooks/use-previous.hook";
import { MenuLayout, NoMenuLayout, MobileLayout } from "../layout";
import { isLogged } from "./core.selectors";

const CoreComponent = () => {
    const logged = useSelector( isLogged );
    const [ mobile, setMobile ] = useState( "" );

    const location = useLocation();
    const prevLogged = usePrevious( logged );

    useEffect( () => {
        if ( ( !logged && location.pathname === "/" ) || ( prevLogged && !logged ) ) {
            history.push( "/login" );
        }

        if ( ( window.orientation > -1 ) && !mobile ) {
            let mobile = "";
            if ( /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream ) {
                mobile = "ios";
            } else if ( /(android)/i.test( navigator.userAgent ) ) {
                mobile = "android";
            }
            setMobile( mobile );
        }

    }, [ location.pathname, logged, prevLogged, mobile ] );

    useEffect( () => {
        tracker.init();
    }, [] );

    if ( mobile ) {
        return <MobileLayout mobile={ mobile } userLogged={ logged } />;
    }

    return logged ? <MenuLayout/> : <NoMenuLayout/>;
};

export default CoreComponent;