import { combineReducers } from "redux";

import * as actions from "./invitation.actions";

export function all ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_INVITATIONS:
            return {
                ...state,
                loading: true
            };

        case actions.RECEIVE_INVITATIONS:
            return {
                ...state,
                loading: false,
                data: action.payload.invitations
            };

        case actions.ERROR_INVITATIONS:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
}

export function inAcceptance ( state = {}, action ) {
    switch ( action.type ) {
        case actions.SET_INVITATION:
            return {
                data: action.payload
            };

        case actions.ERROR_INVITATION:
            return {
                error: action.payload
            };

        default:
            return state;
    }
}

export const invitations = combineReducers({ inAcceptance, all });