// ---------------------------------------------------------------------------------------------------------------------
// General
// ---------------------------------------------------------------------------------------------------------------------
export const INIT_FORM = "x/config/opportunity/form/INIT_FORM";
export const initForm = form => ({
    type: INIT_FORM,
    meta: { form }
});

// ---------------------------------------------------------------------------------------------------------------------
// Field manipulation
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_FIELD = "x/config/opportunity/form/SAVE_FIELD";
export const saveField = field => ({
    type: SAVE_FIELD,
    payload: { field }
});

export const NEW_FIELD = "x/config/opportunity/form/NEW_FIELD";
export const newField = data => ({
    type: NEW_FIELD,
    payload: data
});

export const SELECT_FIELD = "x/config/opportunity/form/SELECT_FIELD";
export const selectField = index => ({
    type: SELECT_FIELD,
    payload: { index }
});

export const DESELECT_FIELD = "x/config/opportunity/form/DESELECT_FIELD";
export const deselectField = () => ({
    type: DESELECT_FIELD
});

export const DELETE_FIELD = "x/config/opportunity/form/DELETE_FIELD";
export const deleteField = field => ({
    type: DELETE_FIELD,
    payload: field
});

// ---------------------------------------------------------------------------------------------------------------------
// Fields sorting
// ---------------------------------------------------------------------------------------------------------------------
export const SORT_FIELD = "x/config/opportunity/form/SORT_FIELD";
export const sortField = ({ oldIndex, newIndex }) => ({
    type: SORT_FIELD,
    payload: { oldIndex, newIndex }
});

export const CANCEL_SORTING = "x/config/opportunity/form/CANCEL_SORTING";
export const cancelSorting = () => ({
    type: CANCEL_SORTING
});

export const SAVE_SORTING = "x/config/opportunity/form/SAVE_SORTING";
export const saveSorting = () => ({
    type: SAVE_SORTING
});

// ---------------------------------------------------------------------------------------------------------------------
// Confirm Update Field
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_USED_VALUES = "x/config/opportunity/form/FETCH_USED_VALUES";
export const fetchUsedValues = field => ({
    type: FETCH_USED_VALUES,
    payload: { field }
});

export const REQUEST_USED_VALUES = "x/config/opportunity/REQUEST_USED_VALUES";
export const requestUsedValues = () => ({
    type: REQUEST_USED_VALUES
});

export const RECEIVE_USED_VALUES = "x/config/opportunity/form/RECEIVE_USED_VALUES";
export const receiveUsedValues = usedValues => ({
    type: RECEIVE_USED_VALUES,
    payload: { usedValues }
});

export const ERROR_USED_VALUES = "x/config/opportunity/ERROR_USED_VALUES";
export const errorUsedValues = error => ({
    type: ERROR_USED_VALUES,
    payload: error,
    error: true
});