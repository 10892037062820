import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PrintIcon from "@mui/icons-material/Print";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useReactToPrint } from "react-to-print";
import Highcharts from "highcharts";

import useTranslate from "../hooks/use-translate.hook";
import tracker from "../util/tracker.utils";
import { getSubscription } from "../subscription/subscription.selectors";
import { getUser } from "../core/core.selectors";
import { Fab } from "../ui";

const DashboardPrintChart = () => {
    const t = useTranslate();

    const subscription = useSelector( getSubscription );
    const user = useSelector( getUser );

    const [ showPrint, setShowPrint ] = useState( false );
    const [ chartsId, setChartsId ] = useState( [] );
    const [ kpisId, setKpisId ] = useState( [] );
    const [ isPrinting, setIsPrinting ] = useState( false );
    const printRef = useRef();

    const browser = useMemo( () => {
        const userAgent = navigator.userAgent;

        if ( userAgent.includes( "OPR" ) || userAgent.includes( "Opera" ) ) {
            return "opera";
        } else if ( userAgent.includes( "Edg" ) ) {
            return "edge";
        } else if ( userAgent.includes( "Firefox" ) ) {
            return "firefox";
        } else if ( userAgent.includes( "Chrome" ) ) {
            return "chrome";
        } else if ( userAgent.includes( "Safari" ) ) {
            return "safari";
        }

        return "unknown";
    }, [] );

    const kpis = useMemo( () => [
        { id: "conversionRateKpiChart", label: t( "dashboard:print-chart.conversion-rate" ) },
        { id: "opportunitiesCreatedTotalKpi", label: t( "dashboard:print-chart.opportunities-created-total" ) },
        { id: "opportunitiesPriceKpi", label: t( "dashboard:print-chart.opportunities-created-price" ) },
        { id: "schedulingKpi", label: t( "dashboard:print-chart.scheduling" ) },
        { id: "metricsKpi", label: t( "dashboard:print-chart.time-metrics" ) },
    ], [ t ] );

    const charts = useMemo( () => [
        { id: "opportunitiesBySource", label: t( "dashboard:print-chart.opportunities-by-source" ) },
        { id: "opportunitiesCreatedChart", label: t( "dashboard:print-chart.opportunities-created" ) },
        { id: "opportunitiesLostReasonsChart", label: t( "dashboard:print-chart.opportunities-lost-reasons" ) },
        { id: "temperatureChartChart", label: t( "dashboard:print-chart.temperature" ) },
        { id: "opportunitiesSalesForecastChart", label: t( "dashboard:print-chart.opportunities-sales-forecast" ) },
        { id: "opportunityCheckinChart", label: t( "dashboard:print-chart.opportunity-checkin" ) },
        { id: "schedulingTypeChart", label: t( "dashboard:print-chart.opportunity-scheduling-types" ) }
    ], [ t ] );

    const handleChangeKpis = useCallback( id => () => {
        const newKpisId = !isEmpty( kpisId ) && kpisId.includes( id ) ?
            kpisId.filter( kpiId => kpiId !== id ) :
            [ ...kpisId, id ];

        setKpisId( newKpisId );
    }, [ kpisId ] );

    const handleChangeCharts = useCallback( id => () => {
        const newChartsId = !isEmpty( chartsId ) && chartsId.includes( id ) ?
            chartsId.filter( chartId => chartId !== id ) :
            [ ...chartsId, id ];

        setChartsId( newChartsId );
    }, [ chartsId ] );

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: A4;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        `,
        documentTitle: t( "dashboard:print-chart.print-title" ),
        onBeforePrint: () => setIsPrinting( true ),
        onAfterPrint: () => {
            setIsPrinting( false );
            tracker.logAction( "Dashboard Print" );
        }
    });

    const toggleShowPrint = useCallback( () => setShowPrint( showPrint => !showPrint ), [] );

    const isSelectedKpi = useCallback( id => kpisId.includes( id ), [ kpisId ] );

    const isSelectedChart = useCallback( id => chartsId.includes( id ), [ chartsId ] );

    const filterChild = useCallback( element => {
        const conversionRateKpiChartId = "conversionRateKpiChart";
        const children = Array.from( element.children );
        children.forEach( child => {
            const isParent = child.parentNode === element;
            if ( child.id === conversionRateKpiChartId && !kpisId.includes( child.id ) && isParent ) {
                element.removeChild( child );
            } else if ( child.id !== "conversionRateKpiChart" ) {
                const charts = Array.from( child.children );
                charts.forEach( chart => {
                    if ( !kpisId.includes( chart.id ) && chart.parentNode === child ) {
                        child.removeChild( chart );
                    }
                });
            }
        });
    }, [ kpisId ] );

    const waitForHighchartsLoad = useCallback( async () => {
        return new Promise( resolve => {
            if ( Highcharts.Chart ) {
                resolve();
            } else {
                Highcharts.addEvent( Highcharts.Chart, "load", resolve );
            }
        } );
    }, [] );

    const handleGeneratePdf = useCallback( async () => {
        // Container
        const printContent = document.createElement( "div" );
        printContent.style.display = "flex";
        printContent.style.flexDirection = "column";
        printContent.style.width = "100%";

        // HEADER
        const headerElement = document.createElement( "header" );
        headerElement.style.display = "flex";
        headerElement.style.justifyContent = "center";
        headerElement.style.flexDirection = "column";
        headerElement.style.alignItems = "center";
        headerElement.style.width = "100%";

        const containerElement = document.createElement( "div" );
        containerElement.style.display = "flex";
        containerElement.style.justifyContent = "flex-end";
        containerElement.style.width = "100%";

        const logo = document.createElement("img");
        logo.src = "https://img.leadstation.com.br/logo/248x158-horizontal.png";
        logo.style.width = "117px";
        logo.style.height = "35px";
        logo.style.objectFit = "cover";
        logo.alt = "";

        const titleElement = document.createElement( "h3" );
        titleElement.style.marginTop = "0";
        titleElement.style.paddingTop = "0";
        titleElement.innerText = t( "dashboard:print-chart.print-title" );

        containerElement.appendChild( logo );
        headerElement.appendChild( containerElement );
        headerElement.appendChild( titleElement );

        printContent.appendChild( headerElement );

        // KPIs
        const kpisElement = document.getElementById( "opportunitiesKpis" );
        const kpisCopy = kpisElement.cloneNode( true );
        filterChild( kpisCopy );

        if ( kpisCopy.firstElementChild.id !== "conversionRateKpiChart" ) {
            kpisCopy.firstElementChild.style.gridColumn = "1 / -1";
        }
        printContent.appendChild( kpisCopy );

        await waitForHighchartsLoad();

        // Charts
        chartsId.forEach( chartId => {
            const chart = Highcharts.charts.find( chart => get( chart, "renderTo.id" ) === chartId );

            if ( chart ) {
                const svgElement = chart.getSVG();
                const tempDiv = document.createElement( "div" );
                tempDiv.style.width = "100%";
                tempDiv.innerHTML = svgElement;
                const svg = tempDiv.querySelector( "svg" );
                svg.setAttribute( "width", "100%" );
                svg.setAttribute( "height", "100%" );

                printContent.appendChild( svg );
            }
        });

        // FOOTER
        const footerElement = document.createElement( "footer" );
        footerElement.style.position = "fixed";
        footerElement.style.bottom = "0";
        footerElement.style.width = "100%";

        const footerTextElement = document.createElement( "p" );
        footerTextElement.style.margin = "0";
        footerTextElement.style.padding = "0";
        footerTextElement.style.fontSize = "0.75rem";
        footerTextElement.innerText = t( "dashboard:print-chart.emission", {
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            user: user.name,
            subscription: subscription.name,
        } );
        footerElement.appendChild( footerTextElement );
        printContent.appendChild( footerElement );

        printRef.current = printContent;
        handlePrint();
    }, [ chartsId, filterChild, t, subscription.name, user.name, waitForHighchartsLoad, handlePrint ] );

    useEffect( () => {
        const newChartsId = charts.map( chart => chart.id );
        const newKpisId = kpis.map( kpi => kpi.id );
        setKpisId( newKpisId );
        setChartsId( newChartsId );
    }, [ charts, kpis ] );

    return (
        <>
            <Fab position="bottomRight" onClick={ toggleShowPrint }>
                <PrintIcon/>
            </Fab>
            <Dialog open={ showPrint } onClose={ toggleShowPrint }>
                <DialogTitle>{ t( "common:print" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            t( `dashboard:print-chart.info`, { browserInfo: t( `dashboard:print-chart.${browser}`) } )
                        }
                    </DialogContentText>
                    <List disablePadding>
                        {
                            kpis.map( kpi => (
                                <ListItemButton
                                    key={ kpi.id }
                                    onClick={ handleChangeKpis( kpi.id ) }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={ isSelectedKpi( kpi.id ) } />
                                    </ListItemIcon>
                                    <ListItemText primary={ kpi.label }/>
                                </ListItemButton>
                            ))
                        }
                        {
                            charts.map( chart => (
                                <ListItemButton
                                    key={ chart.id }
                                    onClick={ handleChangeCharts( chart.id ) }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={ isSelectedChart( chart.id ) } />
                                    </ListItemIcon>
                                    <ListItemText primary={ chart.label }/>
                                </ListItemButton>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleShowPrint }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ handleGeneratePdf } disabled={ isPrinting }>
                        { t( "common:print" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DashboardPrintChart;