import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { app, apiv1 } from "../../api/sagas";
import { actions as loginActions } from "../login";
import { INVITATION_FORM, actions } from "./";
import * as selectors from "./invitation.selectors";

// ---------------------------------------------------------------------------------------------------------------------
// Invitations list
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchInvitations () {
    yield put( actions.requestInvitations() );

    try {
        const response = yield call( apiv1.get, "/invitations" );
        yield put( actions.receiveInvitations( response.data ) );
    } catch ( e ) {
        yield put( actions.errorInvitations( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Invitation in acceptance
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchInvitation ({ meta: { id } }) {
    const succeeded = yield select( selectors.getInAcceptance );
    const failed = yield select( selectors.errorInAcceptance );

    // Quick fail: em caso de sucesso ou em caso de erro
    // Só tenta obter o convite de novo se o processo nunca aconteceu antes
    if ( succeeded || failed ) {
        return;
    }

    try {
        const response = yield call( app.get, `/invitation/${id}/fetch` );
        yield put( actions.setInvitation( response.data ) );
    } catch ( e ) {
        yield put( actions.errorInvitation( e.response.data ) );
    }
}

export function *acceptInvitation ({ payload }) {
    const invitation = yield select( selectors.getInAcceptance );

    yield put( startSubmit( INVITATION_FORM ) );

    try {
        yield call( app.post, `/invitation/${invitation.id}`, payload );
        yield put( stopSubmit( INVITATION_FORM ) );
        yield put( loginActions.loginSubmit({
            email: invitation.email,
            password: payload.password
        }, "/home" ) );
    } catch ( e ) {
        yield put( stopSubmit( INVITATION_FORM, e.response.data.error ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Watcher saga
// ---------------------------------------------------------------------------------------------------------------------
export function *watchInvitationSubmit () {
    yield all([
        takeEvery( actions.FETCH_INVITATION, fetchInvitation ),
        takeEvery( actions.ACCEPT_INVITATION, acceptInvitation ),
        takeEvery( actions.FETCH_INVITATIONS, fetchInvitations )
    ]);
}