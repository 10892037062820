import axios from "axios";

export const downloadFile = ( url, filename ) => {
    axios.get( url, { responseType: "blob" } )
        .then(response => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL( response.data );
            link.download = filename;
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
        });
};