import { PureComponent } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import css from "./transfer-list.scss";

class TransferList extends PureComponent {

    state = {
        selectedToChange: [],
        selectedToUndo: [],
    };

    get value () {
        return this.props.field.value || [];
    }

    get availableOptions () {
        return this.props.source.filter( option => !this.value.includes( option.value ) );
    }

    handleAddChange = newValues => {
        const values = this.value.slice().concat( newValues );
        this.props.form.setFieldValue( this.props.field.name, values );
    };

    handleRemoveChange = oldValues => {
        const values = this.value.slice().filter( option => !oldValues.includes( option ) );
        this.props.form.setFieldValue( this.props.field.name, values );
    };

    handleCheckedRight = () => {
        this.handleAddChange( this.state.selectedToChange.slice() );
        this.setState({ selectedToChange: [] });
    };

    handleCheckedLeft = () => {
        this.handleRemoveChange( this.state.selectedToUndo.slice() );
        this.setState({ selectedToUndo: [] });
    };

    handleCheckLeft = value => () => {
        let selectedToChange = this.state.selectedToChange.slice();
        if ( selectedToChange.includes( value ) ) {
            selectedToChange = selectedToChange.filter( option => option !== value );
        } else {
            selectedToChange.push( value );
        }
        this.setState({ selectedToChange });
    };

    handleCheckRight = value => () => {
        let selectedToUndo = this.state.selectedToUndo.slice();
        if ( selectedToUndo.includes( value ) ) {
            selectedToUndo = selectedToUndo.filter( option => option !== value );
        } else {
            selectedToUndo.push( value );
        }
        this.setState({ selectedToUndo });
    };

    handleCheckAllLeft = () => {
        const selectedAll = this.state.selectedToChange.length !== this.availableOptions.length;
        if ( selectedAll ) {
            this.setState({ selectedToChange: this.availableOptions.map( item => item.value )});
        } else {
            this.setState({ selectedToChange: [] });
        }
    };

    handleCheckAllRight = () => {
        const selectedAll = this.state.selectedToUndo.length !== this.value.length;
        if ( selectedAll ) {
            this.setState({ selectedToUndo: this.value.slice() });
        } else {
            this.setState({ selectedToUndo: [] });
        }
    };

    findOption = value => this.props.source.find( option => option.value === value );

    renderItem = ( option, onClick, checked ) => (
        <ListItemButton
            key={ option.value }
            disableGutters
            onClick={ onClick( option.value ) }
        >
            <ListItemIcon>
                <Checkbox
                    checked={ checked }
                    tabIndex={ -1 }
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText primary={ option.label } secondary={ option.helper }/>
        </ListItemButton>
    );

    render () {
        const { titleLeft, titleRight } = this.props;
        const { selectedToChange, selectedToUndo } = this.state;
        const availableOptions = this.props.source.filter( option => !this.value.includes( option.value ) );

        return (
            <Grid container spacing={ 2 } justifyContent="center" alignItems="center">
                <Grid item lg>
                    <CardHeader
                        className={ css.header }
                        avatar={
                            <Checkbox
                                onClick={ this.handleCheckAllLeft }
                                checked={ selectedToChange.length === availableOptions.length }
                                indeterminate={
                                    selectedToChange.length > 0 && selectedToChange.length === availableOptions.length
                                }
                                disabled={ availableOptions.length === 0 }
                            />
                        }
                        title={ titleLeft }
                        subheader={ `${selectedToChange.length}/${availableOptions.length} selecionados` }
                        titleTypographyProps={{ variant: "body2" }}
                        subheaderTypographyProps={{ variant: "body2" }}
                    />
                    <Divider/>
                    <List dense className={ css.list } component="div" role="list">
                        {
                            availableOptions.map( option => this.renderItem(
                                option,
                                this.handleCheckLeft,
                                selectedToChange.includes( option.value )
                            ) )
                        }
                    </List>
                    <Divider/>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={ this.handleCheckedRight }
                            disabled={ selectedToChange.length === 0 }
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={ this.handleCheckedLeft }
                            disabled={ selectedToUndo.length === 0 }
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item lg>
                    <CardHeader
                        className={ css.header }
                        avatar={
                            <Checkbox
                                onClick={ this.handleCheckAllRight }
                                checked={ selectedToUndo.length === this.value.length }
                                indeterminate={
                                    selectedToUndo.length > 0 && selectedToUndo.length === this.value.length
                                }
                                disabled={ this.value.length === 0 }
                            />
                        }
                        title={ titleRight }
                        subheader={ `${selectedToUndo.length}/${this.value.length} selecionados` }
                        titleTypographyProps={{ variant: "body2" }}
                        subheaderTypographyProps={{ variant: "body2" }}
                    />
                    <Divider/>
                    <List dense className={ css.list } component="div" role="list">
                        {
                            this.value.map( option => this.renderItem(
                                this.findOption( option ),
                                this.handleCheckRight,
                                selectedToUndo.includes( option )
                            ) )
                        }
                    </List>
                    <Divider/>
                </Grid>
            </Grid>
        );
    }

}

export default TransferList;