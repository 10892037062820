import Dialog from "@mui/material/Dialog";

import useTranslate from "../../../hooks/use-translate.hook";
import GoalForm from "./goal-form.component";
import { NEW_GOAL_FORM } from "./goal.constants";

const NewGoal = ({ open, onClose }) => {
    const t = useTranslate();

    return (
        <Dialog open={ open }>
            <GoalForm formName={ NEW_GOAL_FORM } title={ t( "config:new-goal" ) } onClose={ onClose } />
        </Dialog>
    );
};

export default NewGoal;