import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { getThemeMode } from "../core/core.selectors";
import { selectors as subscriptionSelectors } from "../subscription";
import { flex, cssUtils, Link } from "../ui";
import css from "./config.scss";

const ConfigMain = () => {
    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const themeMode = useSelector( getThemeMode );

    const navigate = useNavigate();

    const suggestionItems = useMemo( () => [
        {
            id: "inviteUsers",
            info: t( "config:main.suggestions.inviteUsers.info" ),
            buttonText: t( "config:main.suggestions.inviteUsers.buttonText" ),
            icon: PersonAddIcon,
            className: themeMode === "dark" ? css.suggestionCardInviteUsersDark : css.suggestionCardInviteUsers,
            onClick: () => navigate( "/config/users" )
        },
        {
            id: "createCustomFields",
            info: t( "config:main.suggestions.createCustomFields.info" ),
            buttonText: t( "config:main.suggestions.createCustomFields.buttonText" ),
            icon: FormatAlignLeftIcon,
            className: themeMode === "dark" ? css.suggestionCardCreateCustomFieldsDark :
                css.suggestionCardCreateCustomFields,
            onClick: () => navigate( "/config/opportunity/forms" )
        }
    ], [ t, navigate, themeMode ] );

    const yourTeamRoutes = useMemo( () => [
        {
            id: "users",
            title: t( "config:main.yourTeam.invite" ),
            href: "/config/users"
        },
        {
            id: "goals",
            title: t( "config:main.yourTeam.goals" ),
            href: "/config/users/goals"
        },
        {
            id: "commission",
            title: t( "config:main.yourTeam.commission" ),
            href: "/config/users/commissions"
        },
    ], [ t ] );

    const subscriptionRoutes = useMemo( () => [
        {
            id: "subscription",
            title: t( "config:main.subscription.registrationData" ),
            href: "/config/subscription"
        },
        {
            id: "plans",
            title: t( "config:main.subscription.plan" ),
            href: "/config/subscription/plans",
            disabled: !!subscription.partnerId
        },
    ], [ t, subscription ] );

    const adjustmentOfOpportunitiesRoutes = useMemo( () => [
        {
            id: "opportunitiesFunnel",
            title: t( "config:main.opportunities.funnel" ),
            href: "/config/opportunity/funnels"
        },
        {
            id: "opportunitiesSources",
            title: t( "config:main.opportunities.sources" ),
            href: "/config/opportunity/sources"
        },
        {
            id: "opportunitiesLossReasons",
            title: t( "config:main.opportunities.lossReasons" ),
            href: "/config/opportunity/loss-reasons"
        },
        {
            id: "opportunitiesSchedulesType",
            title: t( "config:main.opportunities.scheduleType" ),
            href: "/config/opportunity/schedules-type"
        },
        {
            id: "opportunitiesCustomFields",
            title: t( "config:main.opportunities.customFields" ),
            href: "/config/opportunity/forms"
        },
        {
            id: "opportunitiesTemplates",
            title: t( "config:main.opportunities.templates" ),
            href: "/config/opportunity/templates"
        },
        {
            id: "opportunitiesProducts",
            title: t( "config:main.opportunities.products" ),
            href: "/config/opportunity/products"
        },
    ], [ t ] );

    const advancedRoutes = useMemo( () => [
        {
            id: "advancedHolding",
            title: t( "config:main.advanced.holding" ),
            href: "/config/advanced/holding",
        },
        {
            id: "advancedEmail",
            title: t( "config:main.advanced.email" ),
            href: "/config/advanced/smtp",
        },
        {
            id: "advancedRules",
            title: t( "config:main.advanced.rules" ),
            href: "/config/advanced/rules",
        },
        {
            id: "advancedMigrations",
            title: t( "config:migrations.title" ),
            href: "/config/advanced/migrations",
        },
        {
            id: "advancedKanban",
            title: t( "config:kanban.title" ),
            href: "/config/advanced/kanban",
        },
        {
            id: "advancedDistribution",
            title: t( "config:distribution.title" ),
            href: "/config/advanced/distribution",
        },
    ], [ t ] );

    const renderLink = useCallback( item => {
        return item.disabled ? null :
            <Link href={ item.href } key={ item.id }>
                <Typography className={ cssUtils.fontBold }>
                    { item.title }
                </Typography>
            </Link>;
    }, [] );

    return (
        <div className={ classnames( flex.container, flex.column, flex.rowGap, cssUtils.displayBlock ) }>
            <div className={ classnames( css.suggestionContainer, cssUtils.fontBold ) }>
                <Typography variant="h6" className={ cssUtils.fontBold }>
                    { t( "config:main.suggestions.title" ) }
                </Typography>
                <Grid container spacing={ 3 }>
                    {
                        suggestionItems.map( item => {
                            const Icon = item.icon;
                            return (
                                <Grid key={ item.id } item xs={ 12 } sm={ 6 } md={ 6 } lg={ 3 }>
                                    <Card className={ classnames( item.className ) }>
                                        <CardContent className={ css.suggestionCardContent }>
                                            <Typography variant="h6" className={ cssUtils.fontBold }>
                                                { item.info }
                                            </Typography>
                                            <div className={ css.suggestionCardContainerIcon }>
                                                <Icon className={ css.suggestionCardIcon }/>
                                            </div>
                                        </CardContent>
                                        <CardActions className={ css.suggestionCardActions }>
                                            <Button
                                                className={ css.suggestionCardButton }
                                                onClick={ item.onClick }
                                                variant="contained"
                                                fullWidth
                                            >
                                                { item.buttonText }
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        } )
                    }
                </Grid>
            </div>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                    <div
                        className={ classnames( flex.container, flex.column, flex.rowGapSmall, cssUtils.fontBold ) }
                    >
                        <Typography color="textSecondary" variant="subtitle1" className={ cssUtils.fontBold }>
                            { t( "config:main.yourTeam.title" ) }
                        </Typography>
                        {
                            yourTeamRoutes.map( item => ( renderLink( item ) ) )
                        }
                    </div>
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                    <div
                        className={ classnames( flex.container, flex.column, flex.rowGapSmall, cssUtils.fontBold ) }
                    >
                        <Typography color="textSecondary" variant="subtitle1" className={ cssUtils.fontBold }>
                            { t( "config:main.subscription.title" ) }
                        </Typography>
                        {
                            subscriptionRoutes.map( item => ( renderLink( item ) ) )
                        }
                    </div>
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                    <div className={ classnames( flex.container, flex.column, flex.rowGapSmall, cssUtils.fontBold ) }>
                        <Typography color="textSecondary" variant="subtitle1" className={ cssUtils.fontBold }>
                            { t( "config:main.opportunities.title" ) }
                        </Typography>
                        {
                            adjustmentOfOpportunitiesRoutes.map( item => ( renderLink( item ) ) )
                        }
                    </div>
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                    <div className={ classnames( flex.container, flex.column, flex.rowGapSmall, cssUtils.fontBold ) }>
                        <Typography color="textSecondary" variant="subtitle1" className={ cssUtils.fontBold }>
                            { t( "config:main.advanced.title" ) }
                        </Typography>
                        {
                            advancedRoutes.map( item => ( renderLink( item ) ) )
                        }
                    </div>
                </Grid>
            </Grid>
        </div>

    );
};

export default ConfigMain;