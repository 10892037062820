import { useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { Form } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { getModels } from "../../file/file.selectors";
import { Dropdown, Input } from "../../ui";
import ProposalProducts from "./proposal-products.component";

export const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( !isEmpty( values.products ) ) {
        errors.products = values.products
            .map( proposalProduct => {
                const error = {};

                if ( isEmpty( proposalProduct.product ) ) {
                    error.product = "common:validation.selection";
                }
                if ( !proposalProduct.quantity ) {
                    error.quantity = "common:validation.required";
                }

                return error;
            } )
            .filter( item => !isEmpty( item ) );
    }

    if ( isEmpty( errors.products ) ) {
        delete errors.products;
    }

    return errors;
};

export const ProposalForm = ({
    formik,
    open,
    onClose,
    title,
}) => {
    const t = useTranslate();

    const fileModels = useSelector( getModels );

    return (
        <Dialog open={ open } maxWidth="md" fullWidth>
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="name"
                        label={ t( "common:name" ) }
                        component={ Input }
                        required
                    />
                    <hr/>
                    <FieldArray
                        name="products"
                        component={ ProposalProducts }
                    />
                    <hr/>
                    <Field
                        name="fileModelId"
                        label={ t( "customer:files.model" ) }
                        component={ Dropdown }
                        source={ ( fileModels || [] ).map( fileModel => ({
                            label: fileModel.name,
                            value: fileModel.id
                        }))}
                        emptyValue
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};
