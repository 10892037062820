import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../hooks/use-translate.hook";
import { DeleteItem } from "../ui";
import { deleteImportation } from "./importation.actions";
import { createDeleteForm } from "./importation.utils";

const ImportationDelete = ({ importation }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: createDeleteForm( importation.id ),
        initialValues: {
            id: importation.id,
        },
        onSubmit: values => dispatch( deleteImportation( values ) ),
    });

    return (
        <DeleteItem
            message={ t( "importation:importation-results.delete-confirm" ) }
            handleSubmit={ formik.handleSubmit }
            submitting={ formik.submitting }
            submitForm={ formik.submitForm }
        />
    );
};

export default ImportationDelete;
