import { Field } from "formik";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, cssUtils, flex } from "../../../ui";

const TimeInterval = ({ title, name }) => {
    const t = useTranslate();

    return (
        <>
            <Typography color="textSecondary">{ title }</Typography>
            <div className={ flex.container }>
                <Field
                    name={ `${name}.start` }
                    type="time"
                    label={ t( "config:subscription.office-hours.start-time" ) }
                    InputLabelProps={{
                        shrink: true
                    }}
                    component={ Input }
                    className={ classnames( cssUtils.marginRightSmall, flex.fill ) }
                    fullWidth={ false }
                    required
                />
                <Field
                    name={ `${name}.end` }
                    type="time"
                    label={ t( "config:subscription.office-hours.end-time" ) }
                    InputLabelProps={{
                        shrink: true
                    }}
                    component={ Input }
                    className={ flex.fill }
                    fullWidth={ false }
                    required
                />
            </div>
        </>
    );
};

export default TimeInterval;