export default {
    "title": "Entrar",
    "email": "E-mail",
    "password": "Senha",
    "login": {
        "forgot-password": "Esqueceu a senha?",
        "action": "Entrar"
    },
    "request-activate": {
        "description": "Você deseja ativar sua conta? Clique no botão Solicitar abaixo que entraremos em contato!",
        "action": "Solicitar",
        "success": "Solicitação enviada com sucesso! Logo entraremos em contato"
    },
    "or": "ou",
    "facebook": "Entrar com o Facebook",
    "google": "Entrar com o Google",
    "signup": {
        "call-to-action": "Não tem uma conta? Registre-se agora!"
    },
    "error": {
        "credentials": "Verifique se seu e-mail e senha estão corretos."
    },
    "forgot-password": {
        "title":
            "Informe o e-mail da sua inscrição cadastrada. Você receberá um e-mail com " +
            "as informações para redefir a senha.",
        "email": "E-mail",
        "send": "Enviar",
        "back": "Voltar",
        "email-not-found": "E-mail não cadastrado",
        "email-sent": "Verifique sua caixa de entrada!"
    },
    "reset-password": {
        "error": "A solicitação para redefinir a senha expirou ou não existe",
        "title": "Para redefinir sua senha, preencha o campo abaixo",
        "new-password": "Nova senha",
        "confirm-password": "Confirme sua senha",
        "reset": "Redefinir",
        "go-login": "Ir para login"
    },
    "two-factor": {
        "call-to-action": "Digite abaixo o código de verificação enviado para o seu e-mail",
        "verification-code": "Código de verificação"
    }
};