import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";

import { InputFile } from "../../../ui";
import { NEW_IMAGE_FORM } from "./template.constants";
import { uploadImage } from "./template.actions";

const NewImage = () => {
    const dispatch = useDispatch();

    const [ showError, setShowError ] = useState( false );
    const closeError = () => setShowError( false );

    const formik = useForm({
        form: NEW_IMAGE_FORM,
        initialValues: {
            file: null,
        },
        onSubmit: values => dispatch( uploadImage( values ) ),
    });

    const { submitForm, values } = formik;

    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );
    useEffect( () => {
        if ( values.file ) {
            submitForm();
        }
    }, [ submitForm, values.file ] );

    return (
        <Form formik={ formik } encType="multipart/form-data">
            { formik.submitting && <LinearProgress/> }
            <Field
                name="file"
                component={ InputFile }
                accept="image/*"
                disabled={ formik.submitting }
            />
            <Snackbar
                open={ showError }
                action={ <Button onClick={ closeError } color="secondary">{ "OK" }</Button>}
                message={ formik.error?.error }
                onClose={ closeError }
            />
        </Form>
    );
};

export default NewImage;