import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import EventNoteIcon from "@mui/icons-material/EventNote";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";

const SchedulingCreationEvent = ({ event: { user, date, payload } }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    return (
        <TimelineItem icon={ <EventNoteIcon/> }>
            <TimelineSummary
                user={ user }
                description={ payload.userName ? t( "opportunity:timeline.opportunity-scheduling-creation_seller",
                    { user: payload.userName } ) : t( "opportunity:timeline.opportunity-scheduling-creation") }
                date={ date } />
            <Card>
                <CardHeader
                    title={ payload.typeName }
                    subheader={
                        t( "opportunity:scheduling.date" ) + " " + dateFormat( payload.date )
                    }
                />
                <CardContent>
                    <span>{ payload.description }</span>
                </CardContent>
            </Card>
        </TimelineItem>
    );
};

export default SchedulingCreationEvent;