import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import useTranslate from "../../../hooks/use-translate.hook";

const CommissionOptions = ({ commission, onOpenDelete, onOpenEdit }) => {
    const t = useTranslate();

    const [ anchorEl, setAnchorEl ] = useState( null );

    const openMenu = event => setAnchorEl( event.currentTarget );
    const closeMenu = () => setAnchorEl( null );

    const handleOpenDelete = () => {
        onOpenDelete( commission );
        closeMenu();
    };
    const handleOpenEdit = () => {
        onOpenEdit( commission );
        closeMenu();
    };

    return <>
        <IconButton onClick={ openMenu } size="large">
            <MoreVertIcon />
        </IconButton>
        <Menu
            anchorEl={ anchorEl }
            open={ !!anchorEl }
            onClose={ closeMenu }
        >
            <MenuItem onClick={ handleOpenEdit }>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={ t( "common:edit" ) } />
            </MenuItem>
            <MenuItem onClick={ handleOpenDelete }>
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={ t( "common:exclude" ) } />
            </MenuItem>
        </Menu>
    </>;
};

export default CommissionOptions;