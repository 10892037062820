import { useState, useEffect, useCallback, useMemo } from "react";
import config from "config";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../ui";
import { fetchForm } from "../../form/form.actions";
import { fetchSources } from "../../source/source.actions";
import { fetchAutomations } from "./automation.actions";
import { getAutomations, isLoadingAutomations } from "./automation.selectors";
import EditAutomation from "./edit-automation.component";
import DeleteAutomation from "./delete-automation.component";
import NewAutomation from "./new-automation.component";
import cssMarketplace from "../../marketplace/marketplace.scss";

const createUrl = automation => `${config.get( "client.core" )}/api/v1/automations/${automation.token}`;

const Automations = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const allAutomations = useSelector( getAutomations );
    const loading = useSelector( isLoadingAutomations );

    const [ showNewAutomation, setShowNewAutomation ] = useState( false );
    const location = useLocation();
    const navigate = useNavigate();

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const integrationChannel = useMemo( () => {
        const currentPath = location.pathname;
        const defaultPath = "/marketplace/automations/";

        switch ( currentPath ) {
            case `${ defaultPath }exactsales`:
                return "EXACT_SALES";
            case `${ defaultPath }leadlovers`:
                return "LEADLOVERS";
            case `${ defaultPath }mkt2easy`:
                return "MKT2EASY";
            case `${ defaultPath }rd-station`:
                return "RD_STATION";
            case `${ defaultPath }api`:
                return "OTHER";
            default:
                return "";
        }
    }, [ location.pathname ] );

    const automations = useMemo( () => {
        return ( allAutomations || []).filter( automation => automation.integrationChannel === integrationChannel );
    }, [ integrationChannel, allAutomations ] );

    const renderList = useCallback( () => {
        if ( isEmpty( automations ) ) {
            return (
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    { t( "marketplace:automation.empty" ) }
                </Typography>
            );
        }

        return automations.map( automation => (
            <Paper key={ automation.token } className={ cssUtils.fullWidth }>
                <List className={ cssUtils.marginBottom }>
                    <ListItem>
                        <ListItemText
                            primary={ automation.name }
                            secondary={
                                t( "marketplace:automation.name.label" )
                            }
                        />
                        <ListItemSecondaryAction className={ flex.container }>
                            <EditAutomation automation={ automation }/>
                            <DeleteAutomation automation={ automation }/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={ createUrl( automation ) }
                            secondary="URL"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={ automation.source.name }
                            secondary={
                                t( "marketplace:automation.source" )
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
        ));

    }, [ automations, t ] );

    const renderLogo = useCallback( () => {
        switch ( integrationChannel ) {
            case "EXACT_SALES":
                return <div className={ cssMarketplace.exactSalesIcon }/>;
            case "LEADLOVERS":
                return <div className={ cssMarketplace.leadLoversIcon }/>;
            case "MKT2EASY":
                return <div className={ cssMarketplace.mkt2EasyIcon }/>;
            case "RD_STATION":
                return <div className={ cssMarketplace.rdStationIcon }/>;
            case "OTHER":
                return <div className={ cssMarketplace.apiIcon }/>;
            default:
                return null;
        }
    }, [ integrationChannel ] );

    const toggleNewAutomation = useCallback( () => {
        setShowNewAutomation( newAutomation => !newAutomation );
    }, [] );

    const handleAdd = useCallback( () => {
        toggleNewAutomation();
    }, [ toggleNewAutomation ] );

    useEffect( () => {
        dispatch( fetchAutomations() );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "OPPORTUNITY" ) );
        dispatch( fetchSources() );
    }, [ dispatch ] );

    if ( !integrationChannel ) {
        return null;
    }

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            { renderLogo() }
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            <span>
                                { t( `marketplace:automation.${integrationChannel}.call-to-action` ) }
                                <a
                                    href={
                                        t( `marketplace:automation.${integrationChannel}.docs-link.value` )
                                    }
                                    target="_blank"
                                    className={ cssUtils.textLink }
                                >
                                    <b>
                                        {
                                            t( `marketplace:automation.${integrationChannel}.docs-link.label` )
                                        }
                                    </b>
                                </a>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader title={ t( `marketplace:automation.integration-channel.${integrationChannel}` ) }/>
                    <CardContent>
                        { loading ? <LinearProgress /> : renderList() }
                    </CardContent>
                    <CardActions className={ flex.justifyContentEnd }>
                        <Button color="secondary" onClick={ handleAdd }>
                            { t( "common:add" ) }
                        </Button>
                    </CardActions>
                </Card>
                <NewAutomation
                    open={ showNewAutomation }
                    integrationChannel={ integrationChannel }
                    onClose={ () => toggleNewAutomation() }
                />
            </div>
        </div>
    );
};

export default Automations;