export default {
    "title": "Captura Web",
    "lead": {
        "status": {
            "open": {
                "label": "Abrir",
                "help":
                    "Correos electrónicos entrantes y no convertidos automáticamente en oportunidades de venta. " +
                    "Haga clic en el cliente potencial, analice la plantilla recibida, " +
                    "cree un cliente y una oportunidad. " +
                    "Si no tiene información de contacto, descarte este cliente potencial."
            },
            "discarted": {
                "label": "Descartado",
                "help":
                    "Correos electrónicos descartados (sin generar oportunidad) debido a la " +
                    "falta de datos o datos duplicados."
            },
            "converted": {
                "label": "Convertido",
                "help": "Correos electrónicos convertidos en oportunidades de venta de forma automática o manual."
            }
        },
        "source": "Origen",
        "to": "Para",
        "from": "De",
        "subject": "Asunto",
        "body": "Cuerpo",
        "customer": "Cliente",
        "opportunity": "Oportunidad",
        "created-at": "Fecha de creación",
        "portal": {
            "label": "Portal",
        }
    },
    "lead-info": {
        "info": "Información de Lead",
        "body": "Cuerpo del correo electrónico",
        "open-body": "Abrir cuerpo del correo electrónico original",
        "change-status": {
            "open": "Reabrir",
            "discard": "Descartar",
            "confirm": "Está seguro de que desea cambiar el estado de este cliente potencial?",
            "yes": "Sí",
            "no": "No"
        }
    },
    "lead-form": {
        "discarted": "El lead está descartado. Para trabajar el lead, debe estar abierto.",
        "converted": "El cliente potencial se ha convertido en oportunidad.",
        "title":
            "Rellene la información y la oportunidad del cliente. A hacer clic en guardar, se le considerará " +
            "esta información para crear la oportunidad.",
        "customer": "Cliente",
        "opportunity": "Oportunidad",
        "search-customer": "Buscar al cliente por nombre, correo electrónico o teléfono completo",
        "clear-customer": "Selección clara del cliente",
        "save": "Guardar"
    },
    "leads": {
        "clear-filters": "Borrar filtros",
        "filter": "Filtrar",
        "empty": "No se han enviado clientes potenciales"
    }
};