export const FETCH_TEMPLATES = "x/FETCH_TEMPLATES";
export const fetchTemplates = () => ({
    type: FETCH_TEMPLATES
});

export const REQUEST_TEMPLATES = "x/REQUEST_TEMPLATES";
export const requestTemplates = () => ({
    type: REQUEST_TEMPLATES
});

export const RECEIVE_TEMPLATES = "x/RECEIVE_TEMPLATES";
export const receiveTemplates = sources => ({
    type: RECEIVE_TEMPLATES,
    payload: sources
});

export const ERROR_TEMPLATES = "x/ERROR_TEMPLATES";
export const errorTemplates = error => ({
    type: ERROR_TEMPLATES,
    error
});

export const FETCH_IMAGES = "x/FETCH_IMAGES";
export const fetchImages = () => ({
    type: FETCH_IMAGES
});

export const REQUEST_IMAGES = "x/REQUEST_IMAGES";
export const requestImages = () => ({
    type: REQUEST_IMAGES
});

export const RECEIVE_IMAGES = "x/RECEIVE_IMAGES";
export const receiveImages = sources => ({
    type: RECEIVE_IMAGES,
    payload: sources
});

export const ERROR_IMAGES = "x/ERROR_IMAGES";
export const errorImages = error => ({
    type: ERROR_IMAGES,
    error
});