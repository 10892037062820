import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import CoreComponent from "./core.component";
import { getThemeMode } from "./core.selectors";
import theme from "./theme";

const App = () => {
    const themeMode = useSelector( getThemeMode );

    return (
        <ThemeProvider theme={ theme( themeMode ) }>
            <CssBaseline />
            <Routes>
                <Route path="/*" element={ <CoreComponent/> }/>
            </Routes>
        </ThemeProvider>
    );
};

export default App;
