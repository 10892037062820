import SpeedDialAction from "@mui/material/SpeedDialAction";

const FloatingActionItem = ({ index, icon, tooltip, onClick }) => (
    <SpeedDialAction
        key={ index }
        icon={ icon }
        tooltipTitle={ tooltip }
        onClick={ onClick }
    />
);

export default FloatingActionItem;