import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

import useTranslate from "../hooks/use-translate.hook";
import { getSelected } from "./customer.selectors";
import TagsEditor from "./tags-editor.component";
import css from "./tags.scss";

const CustomerTags = ({ className }) => {
    const customer = useSelector( getSelected );
    const t = useTranslate();

    const [ tagsEditor, setTtagsEditor ] = useState( null );
    const closeTagsEditor = () => setTtagsEditor( null );
    const openTagsEditor = event => setTtagsEditor( event.currentTarget );

    return (
        <div className={ classnames( css.container, className ) }>
            { ( customer?.tags || [] ).map( ( tag, index ) => <Chip key={ index } label={ tag } /> ) }
            { !isEmpty( customer?.tags ) && (
                <Tooltip title={ t( "customer:tags.title" ) }>
                    <IconButton size="small" onClick={ openTagsEditor }>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            )}
            { isEmpty( customer?.tags ) && (
                <Button size="small" onClick={ openTagsEditor }>
                    { t( "customer:tags.add" ) }
                </Button>
            )}
            <Popover
                open={ !!tagsEditor }
                anchorEl={ tagsEditor }
                onClose={ closeTagsEditor }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}>
                <TagsEditor onClose={ closeTagsEditor } />
            </Popover>
        </div>
    );
};

export default CustomerTags;