import { useEffect } from "react";

const CloseTab = () => {

    useEffect(() => {
        window.close();
    }, [] );

    return <div/>;

};

export default CloseTab;