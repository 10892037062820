import { useCallback, useMemo } from "react";
import config from "config";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";

import useTranslate from "../../hooks/use-translate.hook";
import MarketplaceItem from "../marketplace-item.component";
import css from "../marketplace.scss";

const Hub = ({ className }) => {
    const navigate = useNavigate();

    const t = useTranslate();

    const hubs = useMemo( () => [
        {
            id: "zapier",
            title: t( "marketplace:hubs.zapier.title" ),
            className: css.zapierIcon,
            expired: !config.has( "client.zapier.clientId" )
        }
    ], [ t ] );

    const handleClickHub = useCallback( id => () => {
        const route = `hubs/${id}`;
        navigate( route );
    }, [ navigate ] );

    return (
        <div className={ className }>
            <CardHeader
                className={ css.sectionTitle }
                title={ t( "marketplace:hubs.title" ) }
                subheader={ t( "marketplace:hubs.subtitle" ) }
            />
            <Grid container spacing={ 2 }>
                {
                    hubs.map( hub => (
                        <Grid key={ hub.id } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                            <MarketplaceItem
                                expired={ hub.expired }
                                quantity={ hub.quantity }
                                onClick={ handleClickHub( hub.id ) }
                                title={ hub.title }
                            >
                                <div className={ hub.className }/>
                            </MarketplaceItem>
                        </Grid>
                    ) )
                }
            </Grid>
        </div>
    );
};

export default Hub;