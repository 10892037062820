import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import Avatar from "@mui/material/Avatar";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { DialogForm, Dropdown } from "../../../ui";
import {
    createFacebookLeadPage,
    fetchFacebookAccountPages,
    receiveFacebookAccountPages,
    receiveFacebookPageLeadgenForms,
    fetchFacebookPageLeadgenForms,
} from "./facebook-integration.actions";
import {
    getFacebookAuth,
    getFacebookAccountPages,
    getFacebookPageLeadgenForms,
} from "./facebook-integration.selectors";
import { NEW_FACEBOOK_LEAD_PAGE_FORM } from "./facebook-integration.constants";
import FacebookLeadPageForm from "./facebook-lead-page-form.component";

const NewFacebookLeadPage = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const facebookAccessToken = useSelector( getFacebookAuth );
    const pages = useSelector( getFacebookAccountPages );
    const forms = useSelector( getFacebookPageLeadgenForms );

    const formik = useForm({
        form: NEW_FACEBOOK_LEAD_PAGE_FORM,
        initialValues: {
            pageId: null,
            funnel: { id: null },
            source: { id: null },
            teamId: null,
            usersId: null,
        },
        onSubmit: values => dispatch( createFacebookLeadPage( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.pageId ) {
                errors.pageId = "common:validation.selection";
            }
            if ( !values.funnel || !values.funnel.id ) {
                errors.funnel = {
                    id: "common:validation.selection"
                };
            }
            if ( !values.source || !values.source.id ) {
                errors.source = {
                    id: "common:validation.selection"
                };
            }
            if ( !values.distribution?.id && !values.teamId && isEmpty( values.usersId ) ) {
                errors.usersId = "marketplace:automation.empty-users";
            }
            return errors;
        }
    });

    const { setFieldValue } = formik;

    const selectedPageId = useMemo( () => formik.values.pageId, [ formik.values.pageId ] );

    const prevSelectedPageId = usePrevious( selectedPageId );

    const fetchFacebookPages = useCallback( () => {
        dispatch( fetchFacebookAccountPages( {
            fields: [ "name", "picture", "access_token" ],
            accessToken: facebookAccessToken
        } ) );
    }, [ facebookAccessToken, dispatch ] );

    const clearPagesAndForms = useCallback( () => {
        dispatch( receiveFacebookAccountPages() );
        dispatch( receiveFacebookPageLeadgenForms() );
    }, [ dispatch ] );

    useEffect( () => {
        if ( !facebookAccessToken ) {
            return;
        }

        if ( open ) {
            fetchFacebookPages();
        } else {
            clearPagesAndForms();
        }
    }, [ open, facebookAccessToken, fetchFacebookPages, clearPagesAndForms ] );
    useEffect( () => {
        if ( formik.values.formId && forms ) {
            const formName = formik.values.formId ? forms.find( form => form.id === formik.values.formId ).name : null;
            setFieldValue( "formName", formName );
        }
    }, [ setFieldValue, forms, formik.values.formId ] );
    useEffect( () => {
        if ( selectedPageId && selectedPageId !== prevSelectedPageId ) {
            setFieldValue( "formId", null );
            setFieldValue( "formName", null );
            setFieldValue(
                "pageName",
                pages.find( page => page.id === selectedPageId ).name
            );
            const page = pages.find( pageItem => pageItem.id === selectedPageId );
            dispatch( fetchFacebookPageLeadgenForms( {
                pageId: selectedPageId,
                accessToken: page.access_token,
                fields: [ "name" ]
            } ) );

            setFieldValue( "accessToken", page.access_token );
        }
    }, [ selectedPageId, dispatch, setFieldValue, prevSelectedPageId, pages ] );

    return (
        <DialogForm
            handleSubmit={ formik.handleSubmit }
            onClose={ onClose }
            open={ open }
            resetForm={ formik.resetForm }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            title={ t( "marketplace:integrations.facebook.lead-pages.add" ) }
        >
            <FormikProvider value={ formik }>
                <Field
                    name="pageId"
                    label={ t( "marketplace:integrations.facebook.lead-pages.page" ) }
                    source={ pages?.map( page => ({
                        value: page.id,
                        label: page.name,
                        avatar: <Avatar src={ page.picture.data.url }/>
                    }))}
                    component={ Dropdown }
                    required
                />

                <Field
                    name="formId"
                    label={ t( "marketplace:integrations.facebook.lead-pages.form.label" ) }
                    helperText={ t( "marketplace:integrations.facebook.lead-pages.form.help" ) }
                    source={ forms?.map( form => ({
                        value: form.id,
                        label: form.name
                    }))}
                    disabled={ isEmpty( forms ) }
                    component={ Dropdown }
                    emptyValue
                />

                <FacebookLeadPageForm formik={ formik } />
            </FormikProvider>
        </DialogForm>
    );
};

export default NewFacebookLeadPage;