import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as subscriptionActions } from "../../../subscription";
import * as actions from "./lead-integration.actions";
import { LEAD_DISTRIBUTION_FORM, LEAD_SOURCE_FORM } from "./lead-integration.constants";
import * as selectors from "./lead-integration.selectors";

export function *watchIntegrationsLead () {
    yield all([
        takeEvery( actions.FETCH_LEAD_DISTRIBUTION, fetchLeadDistribution ),
        takeEvery( actions.SAVE_LEAD_DISTRIBUTION, saveLeadDistribution ),
        takeEvery( actions.FETCH_LEAD_SOURCES, fetchLeadSources ),
        takeEvery( actions.SAVE_LEAD_SOURCES, saveLeadSources ),
        takeEvery( actions.DELETE_LEAD_SOURCE, deleteLeadSource )
    ]);
}

export function *fetchLeadDistribution () {
    yield put( actions.requestLeadDistribution() );

    try {
        const response = yield call( apiv1.get, "/leads/distributions" );
        yield put( actions.receiveLeadDistribution( response.data ) );
    } catch ( e ) {
        yield put( actions.errorLeadDistribution( e.response.data ) );
    }
}

export function *saveLeadDistribution ({ payload }) {
    yield put( startSubmit( LEAD_DISTRIBUTION_FORM ) );

    try {
        const response = yield call( apiv1.put, "/leads/distributions", payload );
        yield put( actions.receiveLeadDistribution( response.data ) );
        yield put( subscriptionActions.fetchSubscription() );
        yield put( stopSubmit( LEAD_DISTRIBUTION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( LEAD_DISTRIBUTION_FORM, e.response.data ) );
    }
}

export function *fetchLeadSources () {
    yield put( actions.requestLeadSources() );

    try {
        const response = yield call( apiv1.get, "/leads/sources" );
        yield put( actions.receiveLeadSources( response.data ) );
    } catch ( e ) {
        yield put( actions.errorLeadSources( e.response.data ) );
    }
}

export function *saveLeadSources ( action ) {
    const { leadSources } = action.payload;
    const patched = [];

    try {
        yield put( startSubmit( LEAD_SOURCE_FORM ) );

        for ( const leadSource of leadSources ) {
            let response;
            if ( leadSource.id ) {
                response = yield call( apiv1.patch, "/leads/sources/" + leadSource.id, leadSource );
            } else {
                response = yield call( apiv1.post, "/leads/sources", leadSource );
            }
            patched.push( response.data );
        }

        yield put( stopSubmit( LEAD_SOURCE_FORM ) );
        yield put( actions.receiveLeadSources( patched ) );
    } catch ( e ) {
        yield put( stopSubmit( LEAD_SOURCE_FORM, e.response.data ) );
    }
}

export function *deleteLeadSource ({ meta: { id } }) {
    yield put( startSubmit( LEAD_SOURCE_FORM ) );

    const leadSources = yield select( selectors.getLeadSources );

    try {
        yield call( apiv1.delete, "/leads/sources/" + id );

        const newLeadSources = leadSources.filter( item => item.id !== id );
        yield put( actions.receiveLeadSources( newLeadSources ) );
        yield put( subscriptionActions.fetchSubscription() );
        yield put( stopSubmit( LEAD_SOURCE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( LEAD_SOURCE_FORM, e.response.data ) );
    }
}


