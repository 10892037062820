import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { actions as productActions, selectors as productSelectors } from "../product";
import { getFunnelId } from "../opportunity/opportunity.selectors";
import { listAll as listFunnels } from "../funnel/funnel.selectors";
import { DropdownMultiple } from "../ui";

const FilterProductCategories = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );
    const funnels = useSelector( listFunnels );
    const productCategories = useSelector( productSelectors.listCategories );

    const funnel = useMemo(
        () => funnelId && !isEmpty( funnels ) ? funnels.find( funnel => funnel.id === funnelId ) : null,
        [ funnelId, funnels ]
    );

    const availableProductCategories = useMemo(
        () => !isEmpty( funnel?.productCategories ) ?
            [
                { header: true, label: t( "opportunity:opportunity-product.funnel-categories" ) },
                ...funnel.productCategories.map( category => ( { value: category.id, label: category.name } ) ),
                { header: true, label: t( "opportunity:opportunity-product.others-categories" ) },
                ...( productCategories || [] )
                    .filter( pc => !funnel.productCategories.some( fpc => fpc.id === pc.id ) )
                    .map( category => ( { value: category.id, label: category.name } ) )
            ] :
            productCategories?.map( category => ( { value: category.id, label: category.name } ) ),
        [ t, funnel, productCategories ]
    );

    useEffect( () => {
        dispatch( productActions.fetchCategories() );
    }, [ dispatch ] );

    return (
        <Field
            name="opportunity.productCategories"
            label={ t( "opportunity:opportunity-product.category" ) }
            source={ availableProductCategories }
            component={ DropdownMultiple }
            emptyValue
            sort={ false }
        />
    );
};

export default FilterProductCategories;