import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import { DeleteItem } from "../../../ui";
import { deleteWebhook } from "./webhook.actions";
import { createDeleteWebhookForm } from "./webhook.utils";

const DeleteWebhook = ({ id }) => {
    const dispatch = useDispatch();

    const formik = useForm({
        form: createDeleteWebhookForm( id ),
        initialValues: {
            id,
        },
        onSubmit: values => dispatch( deleteWebhook( values.id ) ),
    });

    return (
        <DeleteItem
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            error={ formik.error }
        />
    );
};

export default DeleteWebhook;
