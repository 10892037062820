import { useMemo } from "react";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

import { CommonForm, ICarrosForm, MobiautoForm, UsadosbrForm, WebmotorsForm } from ".";

export const validateForm = ( values, props ) => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( values.type === "ICARROS" || values.type === "MOBIAUTO" ) {
        if ( !get( values, "credentials.dealerId" ) ) {
            set( errors, "credentials.dealerId", "common:validation.selection" );
        }
    }
    if ( values.type === "WEBMOTORS" ) {
        if ( !get( values, "credentials.ein" ) ) {
            set( errors, "credentials.ein", "common:validation.required" );
        } else if ( !props.isEinValid( values.credentials.ein ) ) {
            set( errors, "credentials.ein", "common:validation.ein" );
        }
    }
    if ( values.type === "ICARROS" ||
            values.type === "MOBIAUTO" ||
            values.type === "USADOSBR" ||
            values.type === "WEBMOTORS" ) {
        if ( !get( values, "credentials.username" ) ) {
            set( errors, "credentials.username", "common:validation.required" );
        }

        if ( !get( values, "credentials.password" ) ) {
            set( errors, "credentials.password", "common:validation.required" );
        }
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( !get( values, "funnel.id" ) ) {
        set( errors, "funnel.id", "common:validation.selection" );
    }

    if ( !get( values, "source.id" ) ) {
        set( errors, "source.id", "common:validation.selection" );
    }

    if ( !get( values, "distribution.id" ) && !values.teamId && isEmpty( values.usersId ) ) {
        errors.usersId = "common:validation.selection";
    }

    return errors;
};

const PortalForm = ({ form, portalType, }) => {
    const Component = useMemo( () => {
        switch ( portalType ) {
            case "ICARROS":
                return ICarrosForm;
            case "MOBIAUTO":
                return MobiautoForm;
            case "USADOSBR":
                return UsadosbrForm;
            case "WEBMOTORS":
                return WebmotorsForm;
            default:
                return CommonForm;
        }
    }, [ portalType ] );

    return <Component form={ form } />;
};

export default PortalForm;