import { useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import sortBy from "lodash/sortBy";

import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";
import { Table } from "../../ui/table";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";

const ProposalProductsEvent = ({ event: { user, payload: { name, current, previous }, date } }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const adaptProductsTable = useCallback( ( products = [] ) => {
        return sortBy(
            products.map( proposalProduct => ({
                ...proposalProduct,
                discount: new Intl
                    .NumberFormat( t( "common:full-locale" ), { maximumFractionDigits: 2 } )
                    .format( proposalProduct.discount || 0 ) + "%"
            })),
            [ "description" ]
        );
    }, [ t ] );

    const productsModel = {
        description: t( "opportunity:proposal-product.product" ),
        quantity: t( "opportunity:proposal-product.quantity" ),
        discount: t( "opportunity:proposal-product.discount" ),
        totalPrice: {
            title: t( "opportunity:proposal-product.total" ),
            format: priceFormat
        }
    };

    return (
        <TimelineItem icon={ <DescriptionIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.proposal-products.message", { name } ) }
                date={ date } />
            <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
                    <Typography color="textSecondary">
                        { t( "config:products.title" ) }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: cssUtils.padding0 }}>
                    <Table
                        model={ productsModel }
                        source={ adaptProductsTable( current ) }
                        shadow={ false }
                        emptyMessage={ t( "opportunity:timeline.proposal-products.empty" ) }
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
                    <Typography color="textSecondary">
                        { t( "opportunity:timeline.proposal-products.previous" ) }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: cssUtils.padding0 }}>
                    <Table
                        model={ productsModel }
                        source={ adaptProductsTable( previous ) }
                        shadow={ false }
                        emptyMessage={ t( "opportunity:timeline.proposal-products.empty" ) }
                    />
                </AccordionDetails>
            </Accordion>
        </TimelineItem>
    );
};

export default ProposalProductsEvent;