import { useEffect } from "react";
import { Form } from "formik-redux";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../ui";
import { Fields } from "../../form";
import css from "../opportunity.scss";

const CustomerForm = ({ fields, onClose, formik }) => {
    const t = useTranslate();

    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting && isEmpty( formik.error ) ) {
            onClose();
        }
    }, [ prevSubmitting, formik.error, formik.submitting, onClose ] );

    return (
        <>
            <Form formik={ formik } className={ cssUtils.marginTopSmall }>
                <Fields fields={ fields }/>
            </Form>

            <div className={ css.customerFormActions }>
                <ListItemButton
                    className={ flex.fill }
                    component="div"
                    disabled={ formik.submitting }
                    onClick={ onClose }
                >
                    <ListItemIcon>
                        <CancelIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:cancel" ) } />
                </ListItemButton>
                <Divider flexItem orientation="vertical" />
                <ListItemButton
                    className={ flex.fill }
                    component="div"
                    disabled={ formik.submitting }
                    onClick={ formik.submitForm }
                >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:save" ) } />
                </ListItemButton>
            </div>
            {
                formik.error && formik.error.error &&
                    <ListItem
                        dense
                        component="div"
                    >
                        <ListItemIcon>
                            <ErrorIcon color="error"/>
                        </ListItemIcon>
                        <ListItemText primary={ formik.error.error } primaryTypographyProps={{ color: "error" }}/>
                    </ListItem>
            }
        </>
    );
};

export default CustomerForm;