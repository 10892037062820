import { forwardRef } from "react";
import { Link as LinkRouter } from "react-router-dom";
import classnames from "classnames";

import css from "./link.scss";

const Link = forwardRef( ( { className, children, href, to }, ref ) => (
    <LinkRouter
        ref={ ref }
        className={ classnames( css.root, className ) }
        to={ href || to }
    >
        { children }
    </LinkRouter>
));

export default Link;