import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";

import useTranslate from "../hooks/use-translate.hook";
import { ColorPicker, Dropdown, cssUtils } from "../ui";
import { saveSocialCalendar } from "./user.actions";
import { SOCIAL_CALENDAR_FORM } from "./user.constants";
import {
    getSocialCalendar,
    getSocialAccountCalendars,
    isLoadingSocialAccountCalendars
} from "./user.selectors";

const SocialCalendarForm = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingSocialAccountCalendars );
    const accountCalendars = useSelector( getSocialAccountCalendars );
    const socialCalendar = useSelector( getSocialCalendar );

    const formik = useForm({
        form: SOCIAL_CALENDAR_FORM,
        enableReinitialize: true,
        initialValues: socialCalendar,
        onSubmit: values => dispatch( saveSocialCalendar( values ) ),
    });

    return (
        <Form formik={ formik } noValidate>
            <CardContent>
                <Field
                    name="calendarId"
                    label={ t( "user:schedule.title" ) }
                    disabled={ loading }
                    source={ accountCalendars?.map( calendar =>
                        ({ value: calendar.id, label: calendar.name })
                    )}
                    component={ Dropdown }
                    required
                />
                <Field
                    name="color"
                    type="text"
                    className={ cssUtils.marginTop }
                    label={ t( "user:schedule.color" ) }
                    defaultColor="#2196F3"
                    component={ ColorPicker }
                    required
                />
                { loading && <LinearProgress/> }
            </CardContent>
            <CardActions>
                <Button type="submit" color="primary" disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </CardActions>
        </Form>
    );
};

export default SocialCalendarForm;