import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, flex } from "../../../ui";
import { saveField } from "./form.actions";
import { createFormName } from "./form.utils";

const GroupingConfig = ({ className, field, onCancel }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: createFormName( field ),
        initialValues: {
            name: "",
            type: "GROUPING",
            ...field
        },
        onSubmit: values => dispatch( saveField( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            return errors;
        }
    });

    return (
        <Form formik={ formik } className={ "form-config-config " + className } noValidate>
            <Card>
                <CardContent>
                    <Field
                        name="name"
                        type="text"
                        label={ t( "config:form.header-config.name.label" ) }
                        component={ Input }
                        autoComplete="off"
                        required
                    />
                </CardContent>
                <CardActions className={ flex.justifyContentEnd }>
                    <Button onClick={ onCancel }>{ t( "common:cancel" ) }</Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={ formik.submitting }
                    >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Card>
        </Form>
    );
};

export default GroupingConfig;