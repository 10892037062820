import config from "config";
import * as amplitude from "@amplitude/analytics-browser";

const init = () => {
    if ( config.has( "client.amplitudeApiKey" ) ) {
        amplitude.init( config.get( "client.amplitudeApiKey" ), { defaultTracking: false } );
    }
};

const setUser = ( admin, email ) => {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set( "admin", admin );

    amplitude.setUserId( email );
    amplitude.identify( identifyEvent );
};

const pageChange = ( pathname, subpath = "" ) => {
    const formattedPageName = pathname.charAt( 1 ).toUpperCase() + pathname.slice( 2 ).toLowerCase();
    amplitude.logEvent( `[${formattedPageName}] ${subpath} Page Viewed`, {
        page: pathname,
        event_type: "page_view",
    });
};

const logAction = actionName => {
    const formattedEventName = actionName + " Action";

    amplitude.track( formattedEventName );
};

const tracker = {
    setUser,
    pageChange,
    logAction,
    init,
};

export default tracker;
