import { useEffect, useMemo, useRef } from "react";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { Checkbox, MaskInput, TemplateEditor } from "../../../ui";
import css from "./template.scss";

const FileModelHeader = ({ form, open, onClose }) => {
    const t = useTranslate();

    const editor = useRef( null );

    const headerHeight = useMemo( () => form.values.headerHeight, [ form.values.headerHeight ] );
    const headerMargin = useMemo( () => form.values.headerMargin, [ form.values.headerMargin ] );

    const handleCancel = () => {
        onClose();
        form.setFieldValue( "header", form.initialValues.header );
    };

    useEffect( () => {
        if ( headerHeight && editor?.current ) {
            editor.current.iframeElement.style.height = `${headerHeight}cm`;
        }
    }, [ headerHeight ] );
    useEffect( () => {
        if ( headerMargin && editor?.current ) {
            editor.current.editorContainer.style.width = headerMargin ? "21cm" : "23cm";
        }
    }, [ headerMargin ] );

    return (
        <Dialog open={ open } classes={{ paper: css.fileModelHeaderContainer }} disableEnforceFocus maxWidth="lg">
            <DialogTitle>
                { t( "config:files.model.header" ) }
            </DialogTitle>
            <DialogContent>
                <Field
                    name="headerMargin"
                    type="checkbox"
                    label={ t( "config:files.model.header-margin" ) }
                    component={ Checkbox }
                />
                <Field
                    name="headerHeight"
                    label={ t( "config:files.model.header-height" ) }
                    component={ MaskInput }
                    suffix=" cm"
                    required
                />
                <Field
                    name="header"
                    component={ TemplateEditor }
                    style={
                        `body { overflow: hidden; height: ${headerHeight}cm; }`
                    }
                    height={ headerHeight ? `calc(${headerHeight}cm + 86px + 25px + 32px)` : "0cm" }
                    width={ headerMargin ? "21cm" : "23cm" }
                    showVariables={ false }
                    setup={ config => {
                        config.on( "init", ed => {
                            ed.target.editorCommands.execCommand(
                                "fontName", false, "Roboto, Helvetica, Arial, sans-serif"
                            );
                            ed.target.editorCommands.execCommand( "fontSize", false, "12pt" );
                            ed.target.editorCommands.execCommand( "fontWeight", false, "400" );
                            editor.current = ed.target;
                        });
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleCancel }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ onClose }>
                    { t( "common:confirm" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileModelHeader;