import { Field } from "formik";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { listAll } from "../../../funnel/funnel.selectors";
import { getSources } from "../../../source/source.selectors";
import { Dropdown, UsersFilter, cssUtils } from "../../../ui";
import { getHoldingFunnels } from "./holding.selectors";

const HoldingFunnelForm = ({ errors = {}, touched = {}, setFieldValue, initialValues, values }) => {
    const t = useTranslate();

    const allFunnels = useSelector( listAll );
    const holdingFunnels = useSelector( getHoldingFunnels );
    const sources = useSelector( getSources );

    const funnels = useMemo( () => !!get( initialValues, "funnel.id" ) ?
        allFunnels :
        allFunnels.filter( funnel => !holdingFunnels.find( hFunnel => hFunnel.funnel.id === funnel.id ) )
    , [ allFunnels, holdingFunnels, initialValues ] );

    const errorUsers = !!errors.teamId && !!errors.usersId && touched.teamId;

    return (
        <>
            <Field
                name="funnel.id"
                label={ t( "opportunity:funnel" ) }
                component={ Dropdown }
                source={ funnels.map( funnel => ({ value: funnel.id, label: funnel.name })) }
                disabled={ !!get( initialValues, "funnel.id" ) }
                required
            />
            <Field
                name="defaultSourceId"
                label={ t( "config:holding.funnel.default-source.label" ) }
                component={ Dropdown }
                source={ sources.map( source => ({ value: source.id, label: source.name })) }
                required
            />
            <Typography variant="caption" color="textSecondary">
                { t( "config:holding.funnel.default-source.help" ) }
            </Typography>
            <UsersFilter
                ComponentRoot="div"
                fullWidth
                className={ classnames( cssUtils.displayBlock, cssUtils.padding0, cssUtils.marginBottom0 ) }
                error={ errorUsers }
                input={{
                    team: {
                        value: values.teamId,
                        onChange: value => setFieldValue( "teamId", value )
                    },
                    users: {
                        value: values.usersId,
                        onChange: value => setFieldValue( "usersId", value )
                    }
                }}
            />
            {
                errorUsers &&
                    <Typography variant="caption" color="error">
                        { t( "common:validation.dist-empty-users" ) }
                    </Typography>
            }
        </>
    );
};

export default HoldingFunnelForm;