import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import UserTwoFactorAuth from "./user-two-factor-auth.component";
import UserAdmin from "./user-admin.component";
import UserActive from "./user-active.component";

export const UserItem = ({ data }) => (
    <ListItem type="userItem">
        <ListItemAvatar>
            <Avatar src={ data.avatar }>{ data.avatar ? "" : data.name.charAt( 0 ).toUpperCase() }</Avatar>
        </ListItemAvatar>
        <ListItemText primary={ data.name } secondary={ data.email }/>
        <UserTwoFactorAuth key="twoFactorAuth" user={ data } />
        <UserAdmin key="admin" user={ data } />
        <UserActive key="active" user={ data } />
    </ListItem>
);

export default UserItem;