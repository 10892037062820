import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const create = initialState => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore( reducers, initialState, composeEnhancers( applyMiddleware( sagaMiddleware ) ) );
    store.sagaTask = sagaMiddleware.run( sagas );
    store.runSaga = sagaMiddleware.run;

    return store;
};
export default create( global.__serverState__ );