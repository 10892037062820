import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import { useState, useEffect, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as formActions } from "../../../form";
import { actions as sourceActions } from "../../../source";
import { fetchLeadPortals } from "../../../web-capture/web-capture.actions";
import { saveLeadSources, fetchLeadSources } from "./lead-integration.actions";
import { isLoadingLeadSources, getLeadSources } from "./lead-integration.selectors";
import { LEAD_SOURCE_FORM } from "./lead-integration.constants";
import LeadSourceList from "./lead-source-list.component";

const LeadSourceForm = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingLeadSources );
    const leadSources = useSelector( getLeadSources );

    const [ showErrors, setShowErrors ] = useState( false );

    const formik = useForm({
        form: LEAD_SOURCE_FORM,
        enableReinitialize: true,
        initialValues: {
            leadSources: leadSources || [],
        },
        onSubmit: values => dispatch( saveLeadSources( values ) ),
        validate: values => {
            const errors = {};
            values.leadSources.forEach( ( leadSource, index ) => {
                const error = {};

                if ( !leadSource.name ) {
                    error.name = "common:validation.required";
                }
                if ( !leadSource.funnelId ) {
                    error.funnelId = "common:validation.selection";
                }
                if ( !leadSource.sourceId ) {
                    error.sourceId = "common:validation.selection";
                }

                if ( !isEmpty( leadSource.defaultValues ) ) {
                    leadSource.defaultValues.forEach( ( defaultValue, i ) => {
                        const errorSecondary = {};
                        if ( !defaultValue.defaultValue ) {
                            errorSecondary.defaultValue = "common:validation.required";
                        }
                        if ( !defaultValue.fieldId ) {
                            errorSecondary.fieldId = "common:validation.required";
                        }
                        if ( !isEmpty( errorSecondary ) ) {
                            set( errors, `leadSources[${index}].defaultValues[${i}]`, errorSecondary );
                        }
                    });
                }

                if ( !isEmpty( error ) ) {
                    set( errors, `leadSources[${index}]`, error );
                }
            });

            return errors;
        }
    });

    const { setFieldValue, values } = formik;

    const toggleErrors = useCallback( () => setShowErrors( showErrors => !showErrors ), [] );

    const handleAdd = useCallback( () => {
        setFieldValue( "leadSources", [ ...values.leadSources, {}] );
    }, [ setFieldValue, values.leadSources ] );

    useEffect( () => {
        dispatch( fetchLeadPortals() );
        dispatch( fetchLeadSources() );
        dispatch( sourceActions.fetchSources() );
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    useEffect( () => {
        if ( !isEmpty( formik.error ) ) {
            setShowErrors( true );
        }
    }, [ formik.error, toggleErrors ] );

    return (
        <div>
            <Snackbar
                open={ showErrors }
                action={ <Button onClick={ toggleErrors } color="secondary">{ "OK" }</Button>}
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ toggleErrors }
            />

            <CardHeader
                title={ t( "marketplace:integrations.lead-source.title" ) }
                subheader={ t( "marketplace:integrations.lead-source.call-to-action" ) }
            />

            {
                loading ?
                    <LinearProgress/> :
                    <>
                        <CardContent>
                            <Form formik={ formik } noValidate>
                                <FieldArray
                                    name="leadSources"
                                    component={ LeadSourceList }
                                />
                            </Form>
                        </CardContent>

                        <CardActions>
                            <Button onClick={ handleAdd }>
                                { t( "marketplace:integrations.lead-source.add" ) }
                            </Button>
                            <Button
                                onClick={ formik.submitForm }
                                disabled={ formik.submitting }
                                variant="contained"
                                color="primary"
                            >
                                { t( "common:save" ) }
                            </Button>
                        </CardActions>
                    </>
            }
        </div>
    );
};


export default LeadSourceForm;