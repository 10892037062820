import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./phase.actions";

function all ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_PHASES:
            return {
                ...state,
                loading: true
            };

        case actions.RECEIVE_PHASES:
            return {
                ...state,
                loading: false,
                all: true,
            };

        case actions.ERROR_PHASES:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}

const funnel = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_FUNNEL_PHASES:
            return {
                ...state,
                [ action.meta.funnelId ]: {
                    ...state[ action.meta.funnelId ],
                    loading: true,
                },
            };
        case actions.RECEIVE_FUNNEL_PHASES:
            return {
                ...state,
                [ action.meta.funnelId ]: {
                    loading: false,
                    data: action.payload,
                },
            };
        case actions.RECEIVE_FUNNELS_PHASES:
            const funnelPhases = {};
            new Set( action.payload.map( phase => phase.funnelId ) )
                .forEach( funnelId => {
                    funnelPhases[ funnelId ] = {
                        data: action.payload.filter( phase => phase.funnelId === funnelId ),
                        loading: false,
                    };
                });
            return {
                ...state,
                ...funnelPhases
            };
        case actions.ERROR_FUNNEL_PHASES:
            return {
                ...state,
                [ action.meta.funnelId ]: {
                    loading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

const count = createRequestReducer({
    begin: actions.REQUEST_PHASES_COUNT,
    success: actions.RECEIVE_PHASES_COUNT,
    error: actions.ERROR_PHASES_COUNT,
});

export const phases = combineReducers({ all, funnel, count });