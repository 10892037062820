// ---------------------------------------------------------------------------------------------------------------------
// Users
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_USERS = "x/FETCH_USERS";
export const fetchUsers = () => ({
    type: FETCH_USERS
});

export const REQUEST_USERS = "x/REQUEST_USERS";
export const requestUsers = query => ({
    type: REQUEST_USERS,
    payload: { query }
});

export const RECEIVE_USERS = "x/RECEIVE_USERS";
export const receiveUsers = users => ({
    type: RECEIVE_USERS,
    payload: { users }
});

export const ERROR_USERS = "x/ERROR_USERS";
export const errorUsers = error => ({
    type: ERROR_USERS,
    payload: error
});

// ---------------------------------------------------------------------------------------------------------------------
// User
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_USER = "x/SAVE_USER";
export const saveUser = data => ({
    type: SAVE_USER,
    payload: data
});

export const SAVE_AVATAR = "x/SAVE_USER_AVATAR";
export const saveAvatar = data => ({
    type: SAVE_AVATAR,
    payload: data
});

export const SAVE_SIGNATURE = "x/SAVE_USER_SIGNATURE";
export const saveSignature = data => ({
    type: SAVE_SIGNATURE,
    payload: data
});

export const SAVE_USER_PASSWORD = "x/SAVE_USER_PASSWORD";
export const saveUserPassword = data => ({
    type: SAVE_USER_PASSWORD,
    payload: data
});

export const SAVE_USER_FILTERS = "x/SAVE_USER_FILTERS";
export const saveUserFilters = user => ({
    type: SAVE_USER_FILTERS,
    meta: { id: user.id },
    payload: user
});

export const RELOAD_USER_INFO = "x/RELOAD_USER_INFO";
export const reloadUserInfo = () => ({
    type: RELOAD_USER_INFO
});

// ---------------------------------------------------------------------------------------------------------------------
// Preference
// ---------------------------------------------------------------------------------------------------------------------

export const SAVE_USER_PREFERENCES = "x/SAVE_USER_PREFERENCES";
export const saveUserPreferences = ( preferences, form ) => ({
    type: SAVE_USER_PREFERENCES,
    meta: { form },
    payload: preferences
});

export const SAVE_THEME_MODE = "x/SAVE_USER_THEME_MODE";
export const saveThemeMode = data => ({
    type: SAVE_THEME_MODE,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Template
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_TEMPLATE = "x/SAVE_USER_TEMPLATE";
export const saveTemplate = data => ({
    type: SAVE_TEMPLATE,
    payload: data
});

export const UPLOAD_IMAGE = "x/UPLOAD_USER_IMAGE";
export const uploadImage = data => ({
    type: UPLOAD_IMAGE,
    payload: data
});

export const DELETE_IMAGE = "x/DELETE_USER_IMAGE";
export const deleteImage = ({ id }) => ({
    type: DELETE_IMAGE,
    meta: { id }
});

// ---------------------------------------------------------------------------------------------------------------------
// Smtp Server
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_SMTP_SERVER = "x/SAVE_SMTP_SERVER";
export const saveSmtpServer = data => ({
    type: SAVE_SMTP_SERVER,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Social Calendar
// ---------------------------------------------------------------------------------------------------------------------
export const FECTH_SOCIAL_CALENDAR = "x/FECTH_SOCIAL_CALENDAR";
export const fetchSocialCalendar = () => ({
    type: FECTH_SOCIAL_CALENDAR
});

export const REQUEST_SOCIAL_CALENDAR = "x/REQUEST_SOCIAL_CALENDAR";
export const requestSocialCalendar = () => ({
    type: REQUEST_SOCIAL_CALENDAR
});


export const RECEIVE_SOCIAL_CALENDAR = "x/RECEIVE_SOCIAL_CALENDAR";
export const receiveSocialCalendar = socialCalendar => ({
    type: RECEIVE_SOCIAL_CALENDAR,
    payload: socialCalendar
});

export const ERROR_SOCIAL_CALENDAR = "x/ERROR_SOCIAL_CALENDAR";
export const errorSocialCalendar = error => ({
    type: ERROR_SOCIAL_CALENDAR,
    payload: error,
    error: true
});

export const FETCH_SOCIAL_ACCOUNT_CALENDARS = "x/FETCH_SOCIAL_ACCOUNT_CALENDARS";
export const fetchSocialAccountCalendars = () => ({
    type: FETCH_SOCIAL_ACCOUNT_CALENDARS
});

export const REQUEST_SOCIAL_ACCOUNT_CALENDARS = "x/REQUEST_SOCIAL_ACCOUNT_CALENDARS";
export const requestSocialAccountCalenders = () => ({
    type: REQUEST_SOCIAL_ACCOUNT_CALENDARS
});

export const RECEIVE_SOCIAL_ACCOUNT_CALENDARS = "x/RECEIVE_SOCIAL_ACCOUNT_CALENDARS";
export const receiveSocialAccountCalendars = calendars => ({
    type: RECEIVE_SOCIAL_ACCOUNT_CALENDARS,
    payload: calendars
});

export const ERROR_SOCIAL_ACCOUNT_CALENDARS = "x/ERROR_SOCIAL_ACCOUNT_CALENDARS";
export const errorSocialAccountCalendars = error => ({
    type: ERROR_SOCIAL_ACCOUNT_CALENDARS,
    payload: error,
    error: true
});

export const SAVE_SOCIAL_CALENDAR = "x/SAVE_SOCIAL_CALENDAR";
export const saveSocialCalendar = socialCalendar => ({
    type: SAVE_SOCIAL_CALENDAR,
    payload: socialCalendar
});

export const DELETE_SOCIAL_CALENDAR = "x/DELETE_SOCIAL_CALENDAR";
export const deleteSocialCalendar = () => ({
    type: DELETE_SOCIAL_CALENDAR
});

// ---------------------------------------------------------------------------------------------------------------------
// Events
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_EVENTS = "x/FETCH_USER_EVENTS";
export const fetchEvents = params => ({
    type: FETCH_EVENTS,
    payload: { params }
});

export const REQUEST_EVENTS = "x/REQUEST_USER_EVENTS";
export const requestEvents = () => ({
    type: REQUEST_EVENTS
});

export const RECEIVE_EVENTS = "x/RECEIVE_USER_EVENTS";
export const receiveEvents = data => ({
    type: RECEIVE_EVENTS,
    payload: data
});

export const ERROR_EVENTS = "x/ERROR_USER_EVENTS";
export const errorEvents = error => ({
    type: ERROR_EVENTS,
    error: true,
    payload: error
});

export const RELOAD_EVENTS = "x/RELOAD_USER_EVENTS";
export const reloadEvents = () => ({
    type: RELOAD_EVENTS
});

export const SELECT_EVENT = "x/SELECT_USER_EVENT";
export const selectEvent = event => ({
    type: SELECT_EVENT,
    payload: event
});

// ---------------------------------------------------------------------------------------------------------------------
// Schedule
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_SCHEDULES = "x/FETCH_USER_SCHEDULES";
export const fetchSchedules = params => ({
    type: FETCH_SCHEDULES,
    meta: { params }
});

export const REQUEST_SCHEDULES = "x/REQUEST_USER_SCHEDULES";
export const requestSchedules = () => ({
    type: REQUEST_SCHEDULES
});

export const RECEIVE_SCHEDULES = "x/RECEIVE_USER_SCHEDULES";
export const receiveSchedules = data => ({
    type: RECEIVE_SCHEDULES,
    payload: data
});

export const ERROR_SCHEDULES = "x/ERROR_USER_SCHEDULES";
export const errorSchedules = error => ({
    type: ERROR_SCHEDULES,
    payload: error,
    error: true
});

export const FETCH_OTHERS_SCHEDULES = "x/FETCH_OTHERS_USER_SCHEDULES";
export const fetchOthersSchedules = params => ({
    type: FETCH_OTHERS_SCHEDULES,
    meta: { params }
});

export const REQUEST_OTHERS_SCHEDULES = "x/REQUEST_OTHERS_USER_SCHEDULES";
export const requestOthersSchedules = () => ({
    type: REQUEST_OTHERS_SCHEDULES
});

export const RECEIVE_OTHERS_SCHEDULES = "x/RECEIVE_OTHERS_USER_SCHEDULES";
export const receiveOthersSchedules = data => ({
    type: RECEIVE_OTHERS_SCHEDULES,
    payload: data
});

export const ERROR_OTHERS_SCHEDULES = "x/ERROR_OTHERS_USER_SCHEDULES";
export const errorOthersSchedules = error => ({
    type: ERROR_OTHERS_SCHEDULES,
    payload: error,
    error: true
});

// ---------------------------------------------------------------------------------------------------------------------
// Verification
// ---------------------------------------------------------------------------------------------------------------------
export const VERIFY_EMAIL = "x/VERIFY_USER_EMAIL";
export const verifyEmail = data => ({
    type: VERIFY_EMAIL,
    payload: data
});