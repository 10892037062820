import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../../hooks/use-translate.hook";
import { deleteApiKey } from "./api-key.actions";
import { createDeleteApiKeyForm } from "./api-key.utils";

const DeleteApiKey = ({ apiKey }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirm, setConfirm ] = useState( false );

    const toggleConfirm = () => setConfirm( !confirm );

    const formik = useForm({
        form: createDeleteApiKeyForm( apiKey.id ),
        initialValues: {
            id: apiKey.id
        },
        onSubmit: values => dispatch( deleteApiKey( values ) ),
        onSubmitSuccess: toggleConfirm,
    });

    return (
        <>
            <Dialog open={ confirm }>
                <DialogContent>
                    <DialogContentText>
                        { t( "marketplace:integrations.delete-api-key.confirm" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirm }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm }>
                        { t( "marketplace:integrations.delete-api-key.yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title={ t( "common:exclude" ) }>
                <IconButton disabled={ formik.submitting } onClick={ toggleConfirm } size="large">
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DeleteApiKey;