import classnames from "classnames";
import { Children, cloneElement } from "react";

import css from "./stepper.scss";

const StepperArrow = ({ className, selected = 0, children }) => (
    <ul className={ classnames( className, css.stepper ) }>
        { Children.map( children, ( child, index ) => cloneElement( child, {
            index,
            selected
        }))}
    </ul>
);

export default StepperArrow;