import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import useTranslate from "../hooks/use-translate.hook";
import { NEW_SCHEDULE_EVENT } from "./schedule.constants";
import { saveNewEvent } from "./schedule.actions";
import EventForm, { validate } from "./schedule-event-form.component";

const NewScheduleEvent = ({ show, onClose, startDate, endDate }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_SCHEDULE_EVENT,
        enableReinitialize: true,
        initialValues: {
            title: "",
            color: "#4caf50",
            startDate,
            endDate,
            status: "OPEN",
            recurrent: {
                active: false,
                type: null,
                until: null,
            },
            allDay: false,
            notify: false,
            notifyMinutes: 0,
            social: {
                data: {
                    attendees: []
                }
            }
        },
        onSubmit: values => dispatch( saveNewEvent( values ) ),
        validate,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    return (
        <Dialog
            open={ show }
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{ t( "schedule:new-event.title" ) }</DialogTitle>
            <DialogContent>
                <EventForm formik={ formik } />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewScheduleEvent;