import { all } from "redux-saga/effects";

import * as brand from "./feature/ui/brand/brand.sagas";
import * as commission from "./feature/commission/commission.sagas";
import * as customer from "./feature/customer/customer.sagas";
import * as config from "./feature/config/config.sagas";
import * as core from "./feature/core/core.sagas";
import * as file from "./feature/file/file.sagas";
import * as form from "./feature/form/form.sagas";
import * as funnel from "./feature/funnel/funnel.sagas";
import * as goal from "./feature/goal/goal.sagas";
import * as home from "./feature/home/home.sagas";
import * as kanban from "./feature/kanban/kanban.sagas";
import * as layout from "./feature/layout/layout.sagas";
import * as login from "./feature/login/login.sagas";
import * as lossReason from "./feature/loss-reason/loss-reason.sagas";
import * as marketplace from "./feature/marketplace/marketplace.sagas";
import * as signup from "./feature/signup/signup.sagas";
import * as importation from "./feature/importation/importation.sagas";
import * as invitation from "./feature/invitation/invitation.sagas";
import * as mailtop from "./feature/mailtop/mailtop.sagas";
import * as oauth from "./feature/oauth/oauth.sagas";
import * as opportunity from "./feature/opportunity/opportunity.sagas";
import * as phases from "./feature/phase/phase.sagas";
import * as plans from "./feature/plan/plan.sagas";
import * as product from "./feature/product/product.sagas";
import * as report from "./feature/report/report.sagas";
import * as source from "./feature/source/source.sagas";
import * as schedule from "./feature/schedule/schedule.sagas";
import * as smtpServer from "./feature/smtp-server/smtp-server.sagas";
import * as logout from "./feature/logout/logout.sagas";
import * as users from "./feature/user/user.sagas";
import * as teams from "./feature/team/team.sagas";
import * as templates from "./feature/template/template.sagas";
import * as subscription from "./feature/subscription/subscription.sagas";
import * as webCapture from "./feature/web-capture/web-capture.sagas";
import * as welcome from "./feature/welcome/welcome.sagas";
import * as distributions from "./feature/config/advanced/distribution/distribution.sagas";

export default function *() {
    return yield all([
        brand.watchBrands(),
        commission.watchCommission(),
        customer.watchCustomer(),
        config.watchConfig(),
        core.watchCore(),
        file.watchFiles(),
        form.watchForm(),
        funnel.watchFunnel(),
        goal.watchGoal(),
        home.watchHome(),
        kanban.watchKanban(),
        layout.watchLayout(),
        login.watchLoginSubmit(),
        lossReason.watchLossReason(),
        signup.watchSignupSubmit(),
        importation.watchImportation(),
        invitation.watchInvitationSubmit(),
        mailtop.watchMailtop(),
        oauth.watchOauth(),
        opportunity.watchOpportunity(),
        phases.watchPhase(),
        plans.watchPlan(),
        product.watchProduct(),
        report.watchReports(),
        source.watchSources(),
        schedule.watchSchedule(),
        smtpServer.watchSmtpServer(),
        logout.watchlogout(),
        users.watchUser(),
        teams.watchTeams(),
        templates.watchTemplates(),
        subscription.watchSubscription(),
        webCapture.watchLeads(),
        welcome.watchWelcome(),
        marketplace.watchMarketplace(),
        distributions.watchConfigDistribution(),
    ]);
}
