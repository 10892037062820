import MuiDialog from "@mui/material/Dialog";

const Dialog = ({ onClose, ...props }) => {
    const handleClose = ( event, reason ) => {
        if ( reason !== "backdropClick" ) {
            onClose();
        }
    };

    return <MuiDialog { ...props } onClose={ handleClose } />;
};

export default Dialog;
