import { ssnFormatter, einFormatter } from "locale-formatter";
import { useCallback, useMemo } from "react";

export const useEinFormat = t => {
    const locale = useMemo( () => t( "common:full-locale" ), [ t ] );
    const einFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return einFormatter( locale )( value );
    }, [ locale ] );

    return einFormat;
};

export const useSsnFormat = t => {
    const locale = useMemo( () => t( "common:full-locale" ), [ t ] );
    const ssnFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return ssnFormatter( locale )( value );
    }, [ locale ] );

    return ssnFormat;
};
