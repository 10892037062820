export const FETCH_COMMISSIONS = "x/FETCH_COMMISSIONS";
export const fetchCommissions = () => ({
    type: FETCH_COMMISSIONS
});

export const REQUEST_COMMISSIONS = "x/REQUEST_COMMISSIONS";
export const requestCommissions = () => ({
    type: REQUEST_COMMISSIONS
});

export const RECEIVE_COMMISSIONS = "x/RECEIVE_COMMISSIONS";
export const receiveCommissions = commissions => ({
    type: RECEIVE_COMMISSIONS,
    payload: commissions
});

export const ERROR_COMMISSIONS = "x/ERROR_COMMISSIONS";
export const errorCommissions = error => ({
    type: ERROR_COMMISSIONS,
    payload: error,
    error: true
});