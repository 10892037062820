import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { app, apiv1 } from "../../../../api/sagas";
import { actions as subscriptionActions } from "../../../subscription";
import * as actions from "./main.actions";
import { SUBSCRIPTION_MAIN_FORM } from "./main.constants";

export function *watchConfigSubscriptionMain () {
    yield all([
        takeEvery( actions.FETCH_SUBSCRIPTION_ADDRESS, fetchSubscriptionAddress ),
        takeEvery( actions.PATCH_SUBSCRIPTION, patchSubscription ),
    ]);
}

export function *patchSubscription ( action ) {
    yield put( startSubmit( SUBSCRIPTION_MAIN_FORM ) );

    try {
        const response = yield call( apiv1.patch, "/subscription", action.payload.subscription );
        yield put( subscriptionActions.receiveSubscription( response.data ) );
        yield put( stopSubmit( SUBSCRIPTION_MAIN_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SUBSCRIPTION_MAIN_FORM, e.response.data ) );
    }
}

function *fetchSubscriptionAddress ({ meta: { zipCode } }) {
    yield put( actions.requestSubscriptionAddress() );

    try {
        const response = yield call( app.get, `/adresses/${zipCode}` );
        yield put( actions.receiveSubscriptionAddress( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSubscriptionAddress({ zipCode, error: true }) );
    }
}