import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SmsIcon from "@mui/icons-material/Sms";
import WarningIcon from "@mui/icons-material/Warning";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { fetchTemplates, fetchImages } from "../template/template.actions";
import { EmptyState } from "../ui";
import WhatsAppIcon from "../ui/icons/whatsapp.component";
import { FloatingAction } from "../ui/floating-action";
import { fetchCustomer, startNewEmail } from "./customer.actions";
import { getSelected, getError, isNewEmailOpen } from "./customer.selectors";
import css from "./customer.scss";
import CustomerHead from "./customer-head.component";
import CustomerOverview from "./customer-overview.component";
import CustomerForm from "./customer-form.component";
import CustomerOpportunities from "./customer-opportunities.component";
import CustomerNotes from "./customer-notes.component";
import CustomerMap from "./customer-map.component";
import CustomerFiles from "./customer-files.component";
import CustomerDelete from "./customer-delete.component";
import NewEmail from "./new-email.component";
import NewSms from "./new-sms.component";
import NewPhoneCall from "./new-phone-call.component";
import NewWhatsApp from "./new-whatsapp.component";

const Customer = ({ className }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const t = useTranslate();

    const error = useSelector( getError );
    const customer = useSelector( getSelected );
    const newEmailOpen = useSelector( isNewEmailOpen );

    const [ tab, setTab ] = useState( "overview" );
    const [ showDialogNewSms, setShowDialogNewSms ] = useState( false );
    const [ showDialogNewPhoneCall, setShowDialogNewPhoneCall ] = useState( false );
    const [ showDialogNewWhatsApp, setShowDialogNewWhatsApp ] = useState( false );

    const root = useRef();

    const handleHeadEdit = () => setTab( "form" );

    const toggleDialogNewPhoneCall = () => setShowDialogNewPhoneCall( !showDialogNewPhoneCall );

    const toggleDialogNewSms = () => setShowDialogNewSms( !showDialogNewSms );

    const toggleDialogNewWhatsApp = () => setShowDialogNewWhatsApp( !showDialogNewWhatsApp );

    const handleOpenNewEmail = () => dispatch( startNewEmail() );

    const getScrollElement = useCallback( () => root.current, [] );

    useEffect( () => {
        dispatch( fetchTemplates() );
        dispatch( fetchImages() );
    }, [ dispatch ] );
    useEffect( () => {
        dispatch( fetchCustomer( params.id ) );
    }, [ dispatch, params ] );

    if ( !isEmpty( error ) && error.status === 404 ) {
        return <EmptyState icon={ WarningIcon } message={ t( "customer:not-found" ) }/>;
    }

    return (
        <div className={ classnames( className, css.subpanel ) } ref={ root }>
            <Helmet title={ customer?.name } />

            <aside className={ css.aside }>
                <CustomerHead onEdit={ handleHeadEdit }/>
            </aside>
            <div className={ css.main }>
                <Paper className={ css.tabsContainer }>
                    <Tabs
                        className={ css.tabs }
                        onChange={ ( event, value ) => setTab( value ) }
                        variant="fullWidth"
                        value={ tab }
                    >
                        <Tab value="overview" label={ t( "customer:overview.title" ) }/>
                        <Tab value="form" label={ t( "customer:form.title" ) }/>
                        <Tab value="opportunities" label={ t( "customer:opportunities.header" ) }/>
                        <Tab value="notes" label={ t( "customer:notes.title" ) }/>
                        <Tab value="map" label={ t( "customer:map.title" ) }/>
                        <Tab value="files" label={ t( "customer:files.title" ) }/>
                    </Tabs>
                    <CustomerDelete customerId={ params.id } className={ css.deleteButton }/>
                </Paper>
                <div className={ css.mainContainer }>
                    {
                        tab === "overview" &&
                            <Fragment>
                                <CustomerOverview customerId={ params.id } getScrollParent={ getScrollElement } />

                                <NewEmail show={ newEmailOpen } />
                                <NewPhoneCall
                                    show={ showDialogNewPhoneCall }
                                    onClose={ toggleDialogNewPhoneCall }
                                />
                                <NewWhatsApp
                                    show={ showDialogNewWhatsApp }
                                    onClose={ toggleDialogNewWhatsApp }
                                />
                                <NewSms show={ showDialogNewSms } onClose={ toggleDialogNewSms } />

                                <FloatingAction id="customerFAB" ariaLabel="customer">
                                    <SpeedDialAction
                                        icon={ <WhatsAppIcon className={ css.floatingIconWhatsapp }/> }
                                        onClick={ toggleDialogNewWhatsApp }
                                        tooltipTitle={ t( "customer:floating-actions.whatsapp" ) }
                                    />
                                    <SpeedDialAction
                                        icon={ <SmsIcon/> }
                                        onClick={ toggleDialogNewSms }
                                        tooltipTitle={ t( "customer:floating-actions.sms" ) }
                                    />
                                    <SpeedDialAction
                                        icon={ <PhoneIcon/> }
                                        onClick={ toggleDialogNewPhoneCall }
                                        tooltipTitle={ t( "customer:floating-actions.phone-call" ) }
                                    />
                                    <SpeedDialAction
                                        icon={ <EmailIcon/> }
                                        onClick={ handleOpenNewEmail }
                                        tooltipTitle={ t( "customer:floating-actions.email" ) }
                                    />
                                </FloatingAction>
                            </Fragment>
                    }
                    { tab === "form" &&
                        <CustomerForm customerId={ params.id } onEdit={ handleHeadEdit } />
                    }
                    {
                        tab === "opportunities" &&
                            <CustomerOpportunities customerId={ params.id } onEdit={ handleHeadEdit } />
                    }
                    { tab === "notes" && <CustomerNotes onEdit={ handleHeadEdit } /> }
                    { tab === "map" && <CustomerMap /> }
                    { tab === "files" && <CustomerFiles onEdit={ handleHeadEdit } /> }
                </div>
            </div>
        </div>
    );

};

export default Customer;