export default {
    "title": "Relatórios",
    "empty-results": "Não há resultados",
    "filters": {
        "initial-date": "Data inicial",
        "final-date": "Data final",
        "month": "Mês",
        "year": "Ano",
        "report": "Relatório",
        "team": "Equipe",
        "empty-team": "Não há equipes configuradas",
        "users": "Usuários",
        "columns": "Colunas",
        "field": "Agrupamento",
        "source": "Origem",
        "apply-filter": "Aplicar filtros"
    },
    "reports": {
        "OPPORTUNITIES_BY_SOURCE": "Oportunidades por origem",
        "ACCUMULATED_BY_USER": "Acumulado por usuário",
        "AVERAGE_SERVICE_TIME_BY_USER": "Tempo médio de atendimento por usuário",
        "AVERAGE_OPPORTUNITY_TIME_SOLD_BY_USER": "Tempo médio de oportunidade ganha por usuário",
        "AVERAGE_TICKET_BY_USER": "Ticket médio por usuário",
        "COMMISSION_RESULTS_BY_USER": "Resultados da comissão por usuário",
        "GOALS_BY_USER": "Metas por usuário",
        "SALES_FORECAST_BY_USER": "Previsão de faturamento por usuário",
        "OPPORTUNITY_SCHEDULES_BY_USER": "Agendamentos de oportunidade por usuário",
        "OPPORTUNITY_TRACKING_BY_USER": "Ranking de conversão por usuário",
        "OPPORTUNITIES_LOST_BY_REASON": "Oportunidades perdidas por motivo",
        "OPPORTUNITIES_SOLD_BY_FIELD": "Oportunidades ganhas",
        "CONVERSION_RATE_BY_USER": "Taxa de conversão por usuário",
        "PRODUCTS_SOLD_BY_FUNNEL": "Produtos vendidos por funil",
        "OPPORTUNITIES_CREATED_BY_USER": "Oportunidades criadas por usuário",
        "PROPOSALS_CREATED_BY_USER": "Propostas criadas por usuário",
        "OPPORTUNITIES_FORWARDED_BY_USER": "Encaminhamentos de oportunidades por usuário"
    },
    "opportunities-by-source": {
        "source": "Origem",
        "created": "Criadas",
        "sold": "Ganhas",
        "sold-rate": "Ganhas (Taxa de conversão)",
        "lost": "Perdidas",
        "total": "Total",
        "rate": "Taxa de conversão",
        "sold-value": "Valor ganho",
        "value": "Valor",
        "recurrent-value": "Valor recorrente",
        "help": "Seleciona as oportunidades pelas datas de criação e ganha"
    },
    "accumulated-by-user": {
        "user": "Usuário",
        "open": "Abertas",
        "sold": "Ganhas",
        "lost": "Perdidas",
        "total": "Total",
        "help": "Exibe as estatísticas de status das oportunidades"
    },
    "average-service-time-by-user": {
        "user": "Usuário",
        "total-opportunities": "Oportunidades",
        "total-opportunities-worked": "Oportunidades trabalhadas",
        "average-minutes": "Tempo médio de atendimento",
        "minutes": "{ minutes} minutos",
        "help": "Exibe o tempo médio para a primeira interação nas oportunidades incluídas no período selecionado"
    },
    "average-opportunity-time-sold-by-user": {
        "user": "Usuário",
        "total": "Oportunidades",
        "average-minutes": "Tempo médio para venda",
        "minutes": "{ minutes} minutos",
        "help": "Exibe o tempo médio para venda das oportunidades ganhas no período selecionado"
    },
    "average-ticket-by-user": {
        "user": "Usuário",
        "average-ticket": "Ticket médio",
        "sold-opportunities": "Oportunidades ganhas",
        "total": "Total",
        "help": "Exibe o ticket médio por usuário. " +
        "Para calcular o ticket médio, é realizado o cálculo: valor / quantidade de oportunidades ganhas"
    },
    "commission-results-by-user": {
        "help": "Exibe os resultados dos usuários da comissão",
        "commission": "Comissão",
        "user": "Usuário",
        "price": "Valor",
        "goal-reached": "Meta atingida",
        "goal-not-reached": "Meta não atingida",
        "no-goal": "Sem meta",
    },
    "goals-by-user": {
        "help": "Exibe o progresso dos usuários nas suas respectivas metas",
        "user": "Usuário",
        "goal": "Meta",
        "target": "Objetivo",
        "current": "Alcançado",
        "all": "Geral"
    },
    "sales-forecast-by-user": {
        "user": "Usuário",
        "open": "Em aberto",
        "open-recurrent": "Em aberto recorrente",
        "sold": "Vendido",
        "sold-recurrent": "Vendido recorrente",
        "total": "Total",
        "help":
            "Considera os dados das oportunidades ganhas pela data de conclusão e as " +
            "oportunidades abertas pela data previsão fechamento"
    },
    "opportunity-schedules-by-user": {
        "type": "Tipo de agendamento",
        "user": "Usuário",
        "total": "Total",
        "open": "Abertos",
        "accomplished": "Realizados",
        "not-accomplished": "Não realizados",
        "help": "Exibe as estatísticas de status dos agendamentos de oportunidade"
    },
    "opportunity-tracking-by-user": {
        "help": "Exibe o ranking de conversão de oportunidades oportunidades por usuário",
        "help-exit": "Pressione a tecla ESC para sair",
        "total": "Total",
        "open": "Em aberto",
        "not-worked": "Não trabalhadas",
        "schedules": "Agendadas",
        "done": "Finalizadas",
        "sold": "Ganhas",
        "price": "Valor",
        "recurrent-price": "Valor recorrente",
        "conversion-rate": "Taxa de conversão"
    },
    "opportunities-lost-by-reason": {
        "name": "Motivo venda perdida",
        "total": "Total",
        "percentage": "Percentual",
        "help": "Considera as oportunidades perdidas no período"
    },
    "opportunities-sold-by-field": {
        "value": "Valor",
        "total": "Total",
        "percentage": "Percentual",
        "help": "Agrupa os valores das oportunidades ganhas de acordo com o campo de tipo Select"
    },
    "conversion-rate-by-user": {
        "user": "Usuário",
        "total": "Total",
        "total-sold": "Ganhas",
        "rate": "Taxa de conversão",
        "help": "Taxa de conversão de oportunidades ganhas"
    },
    "products-sold-by-funnel": {
        "total": "Total",
        "product": "Produto",
        "quantity": "Quantidade",
        "unit-price": "Valor unitário",
        "total-price": "Valor total",
        "help": "Quantidade de produtos vendidos"
    },
    "opportunities-created-by-user": {
        "total": "Total",
        "user": "Usuário",
        "quantity": "Quantidade",
        "help": "Quantidade de oportunidades criadas por usuário"
    },
    "proposals-created-by-user": {
        "title": "Propostas criadas por usuário",
        "help": "Quantidade de propostas criadas por usuário",
        "empty": "Sem informações de propostas criadas",
        "open": "Em aberto",
        "accepted": "Aceita",
        "declined": "Recusada",
        "total": "Total",
        "user": "Usuário",
        "quantity": "Quantidade",
    },
    "opportunities-forwarded-by-user": {
        "total": "Total",
        "forward": "Encaminhamentos",
        "opportunities": "Oportunidades",
        "user": "Usuario",
        "help": "Exibe a quantidade de oportunidades que os usuários encaminharam para outros usuários"
    },
    "print-chart": {
        "emission": "Emissão { date } às { time } por { user } pela inscrição { subscription }"
    }
};