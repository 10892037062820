import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";

import useTranslate from "../../hooks/use-translate.hook";
import { listWithoutGroupings } from "../../form/form.selectors";
import { TransferList } from "../../ui";

const ExportationColumns = ({ type }) => {
    const t = useTranslate();

    const fields = useSelector( listWithoutGroupings( type ) );

    const columns = useMemo( () => fields.map( field => ({
        value: `fields.${field.id}`,
        label: field.name
    })), [ fields ] );

    return (
        <Field
            name="columns"
            source={ columns }
            component={ TransferList }
            titleLeft={ t( "customer:exportation.columns.available" ) }
            titleRight={ t( "customer:exportation.columns.selected" ) }
        />
    );
};

export default ExportationColumns;