export const FETCH_LOSS_REASONS = "x/FETCH_LOSS_REASONS";
export const fetchLossReasons = () => ({
    type: FETCH_LOSS_REASONS
});

export const REQUEST_LOSS_REASONS = "x/REQUEST_LOSS_REASONS";
export const requestLossReasons = () => ({
    type: REQUEST_LOSS_REASONS
});

export const RECEIVE_LOSS_REASONS = "x/RECEIVE_LOSS_REASONS";
export const receiveLossReasons = lossReasons => ({
    type: RECEIVE_LOSS_REASONS,
    payload: lossReasons
});

export const ERROR_LOSS_REASONS = "x/ERROR_LOSS_REASONS";
export const errorLossReasons = error => ({
    type: ERROR_LOSS_REASONS,
    error
});