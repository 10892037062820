import Typography from "@mui/material/Typography";

import { cssUtils } from "../../ui";

const FieldChange = ({ change }) => (
    <Typography className={ cssUtils.marginLeftSmall }>
        { change.previous && <mark className={ cssUtils.highlightOld }>{ change.previous }</mark> }
        { change.previous && "→ " }
        <mark className={ cssUtils.highlightNew }>{ change.current }</mark>
    </Typography>
);

export default FieldChange;