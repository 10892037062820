import { createRequestReducer } from "redux-request-state";
import { combineReducers } from "redux";

import * as automationsActions from "./automation.actions";

export const automations = createRequestReducer({
    begin: automationsActions.REQUEST_AUTOMATIONS,
    success: automationsActions.RECEIVE_AUTOMATIONS,
    failure: automationsActions.ERROR_AUTOMATIONS
});


export const automation = combineReducers({
    automations
});