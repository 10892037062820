import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as funnelActions, selectors as funnelSelectors } from "../../../funnel";
import { actions as phaseActions, selectors as phaseSelectors } from "../../../phase";
import { fetchSchedulingTypes } from "../../../opportunity/opportunity.actions";
import { isLoadingSchedulingTypes } from "../../../opportunity/opportunity.selectors";
import { actions as templateActions, selectors as templateSelectors } from "../../../template";
import { actions as teamActions, selectors as teamSelectors } from "../../../team";
import { actions as userActions, selectors as userSelectors } from "../../../user";
import { actions as productActions, selectors as productSelectors } from "../../../product";
import { cssUtils } from "../../../ui";
import ConfigHeader from "../../config-header.component";
import FunnelForm from "./funnel-form.component";
import NewFunnel from "./new-funnel.component";
import PhaseSteps from "./phases-steps.component";

const Funnel = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnels = useSelector( funnelSelectors.listAll );
    const loadingAll = useSelector( funnelSelectors.isLoadingAll );
    const loadingPhases = useSelector( phaseSelectors.isLoading );
    const loadingProducts = useSelector( productSelectors.isLoadingCategories );
    const loadingSchedulingTypes = useSelector( isLoadingSchedulingTypes );
    const loadingTemplates = useSelector( templateSelectors.isLoadingTemplates );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );

    const loading = loadingAll ||
        loadingPhases ||
        loadingProducts ||
        loadingSchedulingTypes ||
        loadingTemplates ||
        loadingTeams ||
        loadingUsers;

    const [ showNewFunnel, setShowNewFunnel ] = useState( false );

    const toggleNewFunnel = () => setShowNewFunnel( !showNewFunnel );

    useEffect( () => {
        dispatch( funnelActions.fetchFunnels() );
        dispatch( phaseActions.fetchPhases() );
        dispatch( fetchSchedulingTypes() );
        dispatch( templateActions.fetchTemplates() );
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );
        dispatch( productActions.fetchCategories() );
    }, [ dispatch ] );

    return (
        <>
            <ConfigHeader
                title={ t( "config:funnels.title" ) }
                actionTitle={ t( "config:funnels.add" ) }
                actionIcon={ AddIcon }
                onActionClick={ toggleNewFunnel }
            />
            <Card>
                <NewFunnel show={ showNewFunnel } onClose={ toggleNewFunnel }/>

                <CardHeader
                    subheader={ t( "config:funnels.call-to-action" ) }
                />
            </Card>
            {
                loading ?
                    <LinearProgress/> :
                    funnels?.map( funnel =>
                        <Accordion TransitionProps={{ unmountOnExit: true }} key={ funnel.id }>
                            <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
                                <div className={ cssUtils.fullWidth }>
                                    <Typography gutterBottom>{ funnel.name }</Typography>
                                    <PhaseSteps funnelId={ funnel.id }></PhaseSteps>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={ cssUtils.displayBlock }>
                                <FunnelForm funnel={ funnel } />
                            </AccordionDetails>
                        </Accordion>
                    )
            }
        </>
    );
};

export default Funnel;