import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";
import { selectors as subscriptionSelectors } from "../../../subscription";
import { actions as userActions, selectors as userSelectors } from "../../../user";
import { actions as invitationActions, selectors as invitationSelectors } from "../../../invitation";
import UsersList from "./users-list.component";

const ConfigUser = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const users = useSelector( userSelectors.listAll );
    const invitations = useSelector( invitationSelectors.listAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const loadingInvitations = useSelector( invitationSelectors.isLoadingAll );
    const loading = loadingUsers || loadingInvitations;

    useEffect( () => {
        dispatch( userActions.fetchUsers() );
        dispatch( invitationActions.fetchInvitations() );
    }, [ dispatch ] );

    return (
        <div className={ flex.alignItemsStart }>
            <Card>
                <CardHeader
                    classes={{ subheader: classnames( flex.container, flex.justifyContentSpaceBetween ) }}
                    title={ t( "config:items.user.label" ) }
                    subheader={[
                        <span key={ 0 }>{ t( "config:items.user.description" ) }</span>,
                        subscription.userLimit &&
                            <span key={ 1 }>{ t( "config:users.limit", { users: subscription.userLimit } ) }</span>
                    ]} />

                { loading ?
                    <LinearProgress/> :
                    <UsersList users={ users } invitations={ invitations } /> }
            </Card>
        </div>
    );
};

export default ConfigUser;