import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as funnelSelectors } from "../../funnel";
import { setFunnels } from "../opportunity.actions";
import { DropdownMultiple } from "../../ui";
import { getFunnelsId } from "../opportunity.selectors";

function FunnelsFilter ( props ) {
    const dispatch = useDispatch();
    const t = useTranslate();

    const loading = useSelector( funnelSelectors.isLoadingAll );
    const funnels = useSelector( funnelSelectors.listAll );
    const funnelsId = useSelector( getFunnelsId );

    const handleChange = useCallback( event => {
        dispatch( setFunnels( event.target.value ) );
    }, [ dispatch ] );
    const source = useMemo( () => {
        return funnels?.map( funnel => ({ value: funnel.id, label: funnel.name }) ) || [];
    }, [ funnels ] );

    useEffect( () => {
        if ( isEmpty( funnelsId ) && !isEmpty( funnels ) ) {
            dispatch( setFunnels( [ funnels[ 0 ].id ] ) );
        }
    }, [ dispatch, funnels, funnelsId ] );

    return (
        <DropdownMultiple
            label={ t( "opportunity:funnels" ) }
            source={ source }
            onChange={ handleChange }
            value={ funnelsId }
            { ...props }
            disabled={ loading }
        />
    );

}

export default FunnelsFilter;