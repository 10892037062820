import Typography from "@mui/material/Typography";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils, flex } from "../../ui";

const PhaseEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();
    return (
        <TimelineItem icon={ <SwapHorizIcon/> }>
            <TimelineSummary
                user={ user }
                description={
                    user ?
                        t( "opportunity:timeline.opportunity-phase-user" ) :
                        t( "opportunity:timeline.opportunity-phase" )
                }
                date={ date } />

            <div className={ flex.container } >
                <div className={ classnames( flex.container, flex.column ) }>
                    <mark className={ cssUtils.highlightOld }>
                        { payload.previous }
                    </mark>
                    <Typography variant="caption">
                        { payload.previousFunnel }
                    </Typography>
                </div>
                { "→ " }
                <div className={ classnames( flex.container, flex.column ) }>
                    <mark className={ cssUtils.highlightNew }>
                        { payload.current }
                    </mark>
                    <Typography variant="caption">
                        { payload.currentFunnel }
                    </Typography>
                </div>
            </div>
        </TimelineItem>
    );
};

export default PhaseEvent;