import { createRequestReducer } from "redux-request-state";
import { combineReducers } from "redux";

import * as actions from "./distribution.actions";

const all = createRequestReducer({
    begin: actions.REQUEST_DISTRIBUTIONS,
    success: actions.RECEIVE_DISTRIBUTIONS,
    failure: actions.ERROR_DISTRIBUTION
});

export const distributions = combineReducers({ all });