import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./loss-reason.actions";

export function *watchLossReason () {
    yield all([
        takeEvery( actions.FETCH_LOSS_REASONS, fetchLossReasons )
    ]);
}

export function *fetchLossReasons () {
    yield put( actions.requestLossReasons() );
    try {
        const response = yield call( apiv1.get, "/lossreasons" );
        yield put( actions.receiveLossReasons( response.data ) );
    } catch ( e ) {
        yield put( actions.errorLossReasons( e.response.data ) );
    }
}