export const FETCH_IMPORTATIONS = "x/FETCH_IMPORTATIONS";
export const fetchImportations = data => ({
    type: FETCH_IMPORTATIONS,
    payload: data
});

export const REQUEST_IMPORTATIONS = "x/REQUEST_IMPORTATIONS";
export const requestImportations = () => ({
    type: REQUEST_IMPORTATIONS
});

export const RECEIVE_IMPORTATIONS = "x/RECEIVE_IMPORTATIONS";
export const receiveImportations = data => ({
    type: RECEIVE_IMPORTATIONS,
    payload: data
});

export const ERROR_IMPORTATIONS = "x/ERROR_IMPORTATIONS";
export const errorImportations = error => ({
    type: ERROR_IMPORTATIONS,
    error: true,
    payload: error
});

export const SAVE_NEW_IMPORTATION = "x/SAVE_NEW_IMPORTATION";
export const saveNewImportation = data => ({
    type: SAVE_NEW_IMPORTATION,
    payload: data
});

export const DELETE_IMPORTATION = "x/DELETE_IMPORTATION";
export const deleteImportation = ({ id }) => ({
    type: DELETE_IMPORTATION,
    meta: { id }
});

export const FETCH_IMPORTATION = "x/FETCH_IMPORTATION";
export const fetchImportation = id => ({
    type: FETCH_IMPORTATION,
    meta: { id }
});

export const REQUEST_IMPORTATION = "x/REQUEST_IMPORTATION";
export const requestImportation = () => ({
    type: REQUEST_IMPORTATION
});

export const SET_IMPORTATION = "x/SET_IMPORTATION";
export const setImportation = importation => ({
    type: SET_IMPORTATION,
    payload: importation
});

export const ERROR_IMPORTATION = "x/ERROR_IMPORTATION";
export const errorImportation = error => ({
    type: ERROR_IMPORTATION,
    error: true,
    payload: error
});

export const FETCH_RESULTS = "x/FETCH_IMPORTATION_RESULTS";
export const fetchResults = id => ({
    meta: { id },
    type: FETCH_RESULTS
});

export const REQUEST_RESULTS = "x/REQUEST_IMPORTATION_RESULTS";
export const requestResults = () => ({
    type: REQUEST_RESULTS
});

export const RECEIVE_RESULTS = "x/RECEIVE_IMPORTATION_RESULTS";
export const receiveResults = results => ({
    type: RECEIVE_RESULTS,
    payload: results
});

export const ERROR_RESULTS = "x/ERROR_IMPORTATION_RESULTS";
export const errorResults = error => ({
    type: ERROR_RESULTS,
    payload: error,
    error: true
});