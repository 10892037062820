import { useMemo } from "react";

import { SelectField } from "./select-field.component";

const SelectUserField = props => {
    const formField = useMemo( () => {
        return {
            ...props.formField,
            options: {
                ...props.formField.options,
                values: ( props.formField.options.values || [] )
                    .filter( item => item.active || props.field.value === item.value )
            }
        };
    }, [ props.field.value, props.formField ] );

    return <SelectField { ...props } formField={ formField } />;
};

export default SelectUserField;