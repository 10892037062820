import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import { actions } from "./";

export function *fetchTeam ({ meta: { id } }) {
    try {
        const response = yield call( apiv1.get, `/teams/${id}` );
        yield put( actions.receiveTeam( response.data ) );
    } catch ( e ) {
        yield put( actions.errorTeam( e.response.data ) );
    }
}

export function *fetchTeams () {
    yield put( actions.requestTeams() );

    try {
        const response = yield call( apiv1.get, "/teams" );
        yield put( actions.receiveTeams( response.data ) );
    } catch ( e ) {
        yield put( actions.errorTeams( e.response.data ) );
    }
}

export function *watchTeams () {
    yield all([
        takeEvery( actions.FETCH_TEAMS, fetchTeams ),
        takeEvery( actions.FETCH_TEAM, fetchTeam )
    ]);
}