import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { actions as subscriptionActions, selectors as subscriptionSelectors } from "../subscription";
import { Dialog, Dropdown, cssUtils } from "../ui";
import { saveHolding } from "./opportunity.actions";
import { OPPORTUNITY_HOLDING_FORM } from "./opportunity.constants";
import css from "./opportunity.scss";

const OpportunityHolding = ({ opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const holdingFunnels = useSelector( subscriptionSelectors.getHoldingFunnels );
    const loading = useSelector( subscriptionSelectors.isLoadingHoldingFunnels );

    const holdings = useMemo( () => subscription.holdings, [ subscription ] );

    const [ open, setOpen ] = useState( false );
    const [ confirmSuccess, setConfirmSuccess ] = useState( false );

    const formik = useForm({
        form: OPPORTUNITY_HOLDING_FORM,
        initialValues: {
            opportunityId,
            holdingToken: "",
            funnelId: null,
            exclude: false,
        },
        onSubmit: values => dispatch( saveHolding( values ) ),
        onSubmitSuccess: () => {
            setOpen( false );
            setConfirmSuccess( true );
        },
        validate: values => {
            const errors = {};
            if ( !values.holdingToken ) {
                errors.holdingToken = "common:validation.selection";
            }
            if ( values.holdingToken && !values.funnelId ) {
                errors.funnelId = "common:validation.selection";
            }
            return errors;
        }
    });

    const handleClose = () => {
        setOpen( false );
        formik.resetForm();
    };
    const handleOpen = () => setOpen( true );

    const transfer = () => {
        formik.setFieldValue( "exclude", true );
        formik.submitForm();
    };

    const clone = () => {
        formik.setFieldValue( "exclude", false );
        formik.submitForm();
    };

    const toggleSuccess = () => setConfirmSuccess( !confirmSuccess );

    const prevLoading = usePrevious( loading );

    useEffect( () => {
        if ( prevLoading && !loading ) {
            formik.setFieldValue( "funnelId", null );
        }
    }, [ formik, loading, prevLoading ] );
    useEffect( () => {
        if ( formik.values.holdingToken ) {
            dispatch( subscriptionActions.fetchHoldingFunnels( formik.values.holdingToken ) );
        }
    }, [ dispatch, formik.values.holdingToken ] );

    if ( isEmpty( holdings ) ) {
        return null;
    }

    const noHoldingFunnels = !!formik.values.holdingToken && !loading && isEmpty( holdingFunnels );

    return (
        <>
            <Dialog open={ open } fullWidth maxWidth="sm">
                <DialogTitle>
                    { t( "opportunity:holding.title" ) }
                </DialogTitle>
                <DialogContent>
                    <Form formik={ formik }>
                        <DialogContentText>
                            { t( "opportunity:holding.help" ) }
                        </DialogContentText>
                        <Field
                            name="holdingToken"
                            label={ t( "opportunity:holding.token" ) }
                            source={ holdings.map( sHolding => ({
                                value: sHolding.token,
                                label: sHolding.name
                            }))}
                            component={ Dropdown }
                            required
                        />
                        {
                            noHoldingFunnels &&
                                <Typography color="error" variant="body2">
                                    { t( "opportunity:holding.no-funnels" ) }
                                </Typography>
                        }
                        <Field
                            name="funnelId"
                            label={ t( "opportunity:holding.funnel" ) }
                            source={ holdingFunnels?.map( holdingFunnel => ({
                                value: holdingFunnel.funnel.id,
                                label: holdingFunnel.funnel.name
                            }))}
                            component={ Dropdown }
                            disabled={ isEmpty( holdingFunnels ) }
                            required
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="secondary" onClick={ transfer } disabled={ formik.submitting }>
                        { t( "opportunity:holding.transfer" ) }
                    </Button>
                    <Button color="secondary" onClick={ clone } disabled={ formik.submitting }>
                        { t( "opportunity:holding.clone" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ confirmSuccess } fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        { t( "opportunity:holding.success" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleSuccess }>
                        { "OK" }
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                className={ classnames(
                    cssUtils.paddingTopSmall,
                    cssUtils.paddingBottomSmall,
                    cssUtils.textCenter ) }
            >
                <Button
                    color="primary"
                    className={ css.holdingButton }
                    variant="contained"
                    size="small"
                    onClick={ handleOpen }
                    startIcon={ <SendIcon/> }
                >
                    { t( "opportunity:holding.send" ) }
                </Button>
            </div>
            <Divider />
        </>
    );
};

export default OpportunityHolding;