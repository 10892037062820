import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { getFacebookAuth, isLoadingFacebookPage, getFacebookPage } from "./facebook-integration.selectors";
import { updateFacebookLeadPage, fetchFacebookPage } from "./facebook-integration.actions";
import { createRefreshFacebookLeadPageForm } from "./facebook-integration.utils";
import css from "./facebook-integration.scss";

const daysFromNow = date => parseInt( ( new Date( date ) - new Date() ) / ( 1000 * 60 * 60 * 24 ), 10 );

const RefreshFacebookLeadPage = ({ facebookLeadPage }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const form = useMemo( () => createRefreshFacebookLeadPageForm( facebookLeadPage.id ), [ facebookLeadPage.id ] );

    const submitting = useSelector( isSubmitting( form ) );
    const accessToken = useSelector( getFacebookAuth );
    const loading = useSelector( isLoadingFacebookPage );
    const facebookPage = useSelector( getFacebookPage );

    const refreshToken = useCallback( () => {
        dispatch( fetchFacebookPage({
            accessToken,
            pageId: facebookLeadPage.pageId,
            fields: [ "access_token" ]
        }));
    }, [ accessToken, facebookLeadPage, dispatch ] );

    const prevLoading = usePrevious( loading );

    useEffect( () => {
        const isPageToUpdate = facebookLeadPage.pageId.toString() === facebookPage?.id;
        if ( prevLoading && !loading && facebookPage && isPageToUpdate ) {
            const updatedFacebookPage = {
                ...facebookLeadPage,
                accessToken: facebookPage.access_token,
            };
            dispatch( updateFacebookLeadPage( form, facebookLeadPage.id )( updatedFacebookPage ) );
        }
    }, [ dispatch, form, facebookLeadPage, facebookPage, loading, prevLoading ] );

    return (
        <Tooltip
            title={
                new Date( facebookLeadPage.expireAt ) < new Date() ?
                    t( "marketplace:integrations.facebook.lead-pages.expired-token",
                        { date: dateFormat( facebookLeadPage.expireAt ) }
                    ) :
                    t( "marketplace:integrations.facebook.lead-pages.expire-token",
                        { date: dateFormat( facebookLeadPage.expireAt ) }
                    )
            }
        >
            <IconButton
                onClick={ refreshToken }
                disabled={ submitting || loading }
                size="large">
                <Badge
                    badgeContent={ "!" }
                    classes={{
                        colorError: new Date( facebookLeadPage.expireAt ) > new Date() &&
                                css.facebookTokenWarning
                    }}
                    color={ "error" }
                    invisible={ daysFromNow( facebookLeadPage.expireAt ) > 10 }
                >
                    <RefreshIcon/>
                </Badge>
            </IconButton>
        </Tooltip>
    );
};

export default RefreshFacebookLeadPage;