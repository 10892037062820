import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { fetchGoals } from "../../../goal/goal.actions";
import { isLoadingGoals } from "../../../goal/goal.selectors";
import { Table } from "../../../ui/table";
import { fetchCommissions } from "../../../commission/commission.actions";
import { getCommissions, isLoadingCommissions } from "../../../commission/commission.selectors";
import { fetchCategories } from "../../../product/product.actions";
import { isLoadingCategories } from "../../../product/product.selectors";
import CommissionOptions from "./commission-options.component";
import DeleteCommission from "./delete-commission.component";
import EditCommission from "./edit-commission.component";

const CommissionsTable = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const commissions = useSelector( getCommissions );
    const loadingCommissions = useSelector( isLoadingCommissions );
    const loadingGoals = useSelector( isLoadingGoals );
    const loadingCategories = useSelector( isLoadingCategories );
    const loading = loadingCommissions || loadingGoals || loadingCategories;

    const [ isOpenDelete, setIsOpenDelete ] = useState( false );
    const [ isOpenEdit, setIsOpenEdit ] = useState( false );
    const [ selected, setSelected ] = useState( {} );

    const onOpenDelete = commission => {
        setSelected( commission );
        setIsOpenDelete( true );
    };
    const onCloseDelete = () => setIsOpenDelete( false );
    const onOpenEdit = commission => {
        setSelected( commission );
        setIsOpenEdit( true );
    };
    const onCloseEdit = () => setIsOpenEdit( false );

    const model = {
        name: t( "common:name" ),
        type: {
            title: t( "common:type" ),
            format: type => t( `config:commission.type.${type}` )
        },
        funnel: {
            title: t( "opportunity:funnel" ),
            format: funnel => funnel.name
        },
        priceType: {
            title: t( "config:commission.price-type.label" ),
            format: type => t( `config:commission.price-type.${type}` )
        },
        price: t( "common:price" ),
        closingDay: t( "config:commission.closing-day.label" ),
        options: {
            title: " ",
            size: "small"
        }
    };
    const source = commissions?.map( commission => ({
        ...commission,
        price: commission.priceType === "UNIT" ? priceFormat( commission.price ) : `${commission.price} %`,
        options: (
            <CommissionOptions commission={ commission } onOpenDelete={ onOpenDelete } onOpenEdit={ onOpenEdit } />
        ),
    }));

    useEffect( () => {
        if ( !isOpenDelete ) {
            setSelected( {} );
        }
    }, [ isOpenDelete ] );
    useEffect( () => {
        if ( !isOpenEdit ) {
            setSelected( {} );
        }
    }, [ isOpenEdit ] );

    useEffect( () => {
        dispatch( fetchCategories() );
        dispatch( fetchCommissions() );
        dispatch( fetchGoals() );
    }, [ dispatch ] );

    return (
        <Card>
            <CardHeader
                subheader={ t( "config:commissions.call-to-action" ) }
            />
            <Table
                model={ model }
                source={ source }
                isLoading={ loading }
                emptyMessage={ t( "config:commissions.empty" ) }
            />

            <DeleteCommission
                open={ isOpenDelete }
                onClose={ onCloseDelete }
                commission={ selected }
            />
            <EditCommission
                open={ isOpenEdit }
                onClose={ onCloseEdit }
                commission={ selected }
            />
        </Card>
    );
};

export default CommissionsTable;