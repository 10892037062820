import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import FieldItem from "./field-item.component";
import * as actions from "./form.actions";
import * as selectors from "./form.selectors";

const FieldsList = () => {
    const dispatch = useDispatch();

    const selectedIndex = useSelector( selectors.selectedField );
    const fields = useSelector( selectors.listFields );

    const handleDragEnd = result => {
        if ( !result.destination ) {
            return;
        }
        dispatch( actions.sortField({ oldIndex: result.source.index, newIndex: result.destination.index } ) );
    };

    return (
        <DragDropContext onDragEnd={ handleDragEnd }>
            <Droppable droppableId="droppable">
                {
                    provided => (
                        <div
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }
                        >
                            {
                                ( fields || [] ).map( ( field, index ) => (
                                    <FieldItem
                                        key={ index }
                                        index={ index }
                                        field={ field }
                                        onSelect={ () => dispatch( actions.selectField( index ) ) }
                                        onDeselect={ () => dispatch( actions.deselectField() ) }
                                        selected={ selectedIndex === index }
                                    />
                                ))
                            }
                            { provided.placeholder }
                        </div>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};

export default FieldsList;