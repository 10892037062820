export default {
    "customers-list": {
        "title": "Clientes",
        "empty": "Ningún cliente disponible.",
        "create": "Crear cliente",
        "batch": "Actualizar clientes",
        "columns": "Columnas",
        "select-columns": "Seleccionar columnas",
        "filters": "Filtros"
    },
    "batch-options": {
        "title": "Actualización del cliente",
        "description": "Selecciona los clientes que te gustaría actualizar",
        "delete": {
            "non-admin": "Solo los clientes creados por usted serán excluidos",
            "confirmation": {
                "description": "Para confirmar tipo",
                "label": "eliminar clientes",
            }
        },
        "success-title": "Actualización iniciada",
        "success-content": "Recibirá una notificación cuando se complete la actualización."
    },
    "exportation": {
        "title": "Exportación de clientes",
        "call-to-action":
            "Seleccione debajo los campos que desea exportar y el formato de archivo deseado. " +
            "Se le notificará cuando se complete la exportación y el archivo estará disponible " +
            "por 24 horas.",
        "columns": {
            "available": "Columnas disponibles",
            "selected": "Columnas seleccionadas"
        },
        "format": "Formato del archivo"
    },
    "generate-opportunities": {
        "action": "Generar oportunidades",
        "title": "Generación de oportunidades",
        "description":
            "Se generarán oportunidades para los clientes listados, " +
            "un total de <strong>{ total }</strong>  de oportunidades. " +
            "Se le notificará cuando se complete la generación."
    },
    "new-customer": {
        "title": "Nuevo cliente"
    },
    "name": "Nombre",
    "created-at": "Incluido en",
    "overview": {
        "title": "Visión general",
        "stats": "Estadísticas",
        "timeline": "Línea de tiempo",
        "open-opportunities": "Oportunidades abiertas"
    },
    "confirm-delete-customer": "Está seguro que desea eliminar este cliente ?",
    "delete-customer": "Eliminar cliente",
    "opportunities-panel": {
        "empty": "No hay oportunidades abiertas"
    },
    "head": {
        "edit": "Editar",
        "person-type": {
            "NATURAL": "Persona física",
            "JURIDICAL": "Persona jurídica"
        },
        "follow": "Seguir",
        "following": "Siguiente",
        "unfollow": "Dejar de seguir",
        "confirm-remove-avatar": "Está seguro de que desea eliminar el avatar?",
        "change-avatar": "Cambiar avatar",
        "remove-avatar": "Eliminar avatar"
    },
    "floating-actions": {
        "whatsapp": "WhatsApp",
        "sms": "SMS",
        "phone-call": "Conexión",
        "email": "Correo electrónico"
    },
    "type": "Tipo de cliente",
    "company": {
        "new": "Nueva empresa",
        "create": "Crear empresa",
        "title": "Empresa",
        "add-title": "Vincular empresa",
        "confirm-delete": "Está seguro de que desea eliminar el vínculo de la empresa con esta persona?",
        "confirm-delete-from-opportunity":
            "Está seguro de que desea eliminar el enlace de la oportunidad con esta empresa?"
    },
    "person": {
        "new": "Nueva persona",
        "create": "Crear persona",
        "title": "Persona",
        "add-title": "Vincular persona",
        "list": "Pessoas",
        "company-persons": "Gente de la empresa",
        "confirm-delete": "Está seguro de que desea eliminar el vínculo de la empresa con esta persona?",
        "confirm-delete-from-opportunity":
            "Está seguro de que desea eliminar el vínculo de oportunidad con esta persona?"
    },
    "contacts": {
        "add-customer": "Agregar Cliente",
        "contacts-header": "Contactos de clientes",
        "contact-of-header": "Contacto de",
        "empty": "No hay contactos registrados",
        "empty-info": "No hay información de contacto registrada",
        "title-info": "Información de contacto",
        "confirm-delete": "El contacto { contact } se desvinculará del cliente { customer }. Estás seguro?",
        "type": "Tipo de contato",
        "customer": "Cliente",
        "empty-emails": "Este cliente no tiene un correo electrónico registrado"
    },
    "files": {
        "title": "Archivos",
        "confirm-delete": "Está seguro de que desea eliminar este archivo?",
        "name": "Nombre",
        "full-name": "Nombre de archivo",
        "size": "Tamaño",
        "opportunity": "Oportunidad",
        "uploaded-at": "Enviado en",
        "uploaded-by": "Enviado por",
        "empty": "No se han enviado archivos",
        "generate": "Generar documento",
        "model": "Plantilla de documento",
        "new-document": "Nuevo documento"
    },
    "timeline": {
        "types": {
            "CUSTOMER_CREATION": "Creación de clientes",
            "CUSTOMER_CHANGE": "Cambio de registro",
            "PHONE_CALL": "Conexiones realizadas",
            "EMAIL": "Correo electrónico",
            "SMS": "SMS enviados",
            "OPPORTUNITY": "Oportunidades",
            "WHATSAPP": "WhatsApp"
        },
        "events": {
            "empty": "No hay datos para mostrar"
        },
        "customer-changed": "ha cambiado este cliente",
        "customer-created": "Cliente creado a través de la aplicación de API",
        "customer-created-facebook": "Cliente creado a través de Facebook",
        "customer-created-exact-sales": "Cliente creado a través de Exact Sales",
        "customer-created-icarros": "Cliente creado a través de iCarros",
        "customer-created-leadlovers": "Cliente creado a través de Leadlovers",
        "customer-created-mercado-libre": "Cliente creado a través del Mercado Libre",
        "customer-created-mobiauto": "Cliente creado a través del Mobiauto",
        "customer-created-mkt2easy": "Cliente creado a través de MKT2Easy",
        "customer-created-rd-station": "Cliente creado a través de RD Station",
        "customer-created-olx": "Cliente creado a través de OLX",
        "customer-created-usadosbr": "Cliente creado a través de Usadosbr",
        "customer-created-webmotors": "Cliente creado a través de Motores Web",
        "customer-created-user": "creó este cliente",
        "customer-created-lead": "Cliente creado a través de un cliente potencial",
        "customer-created-lead-user": "creó este cliente a través de un cliente potencial",
        "customer-created-by-importation": "creó este cliente a través de una importación",
        "customer-created-by-holding": "Cliente creado por el proceso de retención (holding)",
        "contacts-changed": "se han cambiado los contactos de este cliente",
        "opportunity": {
            "SOLD": "definió cómo obtiene la oportunidad ",
            "LOST": "definido como desaprovechado la opotunidad "
        },
        "email-sent": "envió un correo electrónico",
        "email-sent-opportunity": "envió un correo electrónico en la oportunidad ",
        "email-response-user": "respondió un correo electrónico",
        "email-response-customer": "{ customer } respondió un correo electrónico",
        "email-read": "Correo electrónico leído",
        "email-not-read": "Correo electrónico no leído",
        "from-migration": "Cliente integrado a través de la migración del { system }",
        "phone-call": "realizó una llamada al teléfono { phone }",
        "phone-call-opportunity": "realizó una llamada al teléfono { phone } en la oportunidad ",
        "sms-sent": "envió un SMS al teléfono { phone }",
        "sms-sent-opportunity": "envió un SMS al teléfone { phone } en la oportunidad ",
        "whatsapp": "abrió la conversación de WhatsApp del teléfono { phone }",
        "whatsapp-opportunity": "abrió la conversación de WhatsApp del teléfono { phone }  en la oportunidad ",
        "customer-comment": "comentado en este cliente",
        "access-importation": "Importación de acceso",
        "integration-fields": {
            "name": "Nombre",
            "phone": "Teléfono",
            "message": "Mensage",
            "source": "Origen",
            "company": "Empresa",
            "publicUrl": "URL pública",
            "email": "Correo electrónico",
            "address": "Direccción",
            "state": "Estado",
            "city": "Ciudad",
            "personalPhone": "Teléfono particular",
            "mobilePhone": "Teléfono móvil",
            "job": "Empleo",
            "adId": "Identificador",
            "media": "Promedio",
            "brand": "Marca",
            "model": "Modelo",
            "version": "Versión",
            "modelYear": "Año de modelo",
            "fabricationYear": "Año de fabricación",
            "color": "Color",
            "km": "Kilómetros",
            "itemId": "Identificación del artículo",
            "itemTitle": "Descripción del artículo",
            "price": "Valor",
            "licensePlate": "Placa",
            "dealId": "ID",
            "proposalId": "Identificación de la propuesta",
            "trimName": "Modelo completo",
            "url": "URL",
            "score": "Score",
            "hasApprovedCredit": "Crédito aprobado",
            "hasReprovedCredit": "Crédito fallido",
            "preAnalysisBank": "Base de datos de preanálisis",
            "preAnalysisStatusNative": {
                "label": "Estado de preanálisis",
                "APPROVED": "Aprobado",
                "PRE_ANALYZED": "Preanálisis",
                "REJECTED": "Rechazado",
            },
            "leadType": "Tipo de plomo",
            "analysisDate": "Fecha de análisis",
            "analysisRefDate": "Período de análisis",
            "analysisMessage": "Mensaje de análisis",
            "vehicle-information": "Información del vehículo",
            "ein": "RFC",
            "ssn": "RFC",
            "company-name": "Nombre de empresa",
            "simulation-details": "Detalles de la simulación",
            "products": "Produtos",
            "site": "Site",
        }
    },
    "opportunities": {
        "header": "Oportunidades",
        "empty": "No hay oportunidades registradas",
    },
    "kpis": {
        "total-trading": "Total en negociación",
        "total-purchased": "Total vendido",
        "last-contact": "Último contacto allí",
        "last-purchase": "Última compra allí"
    },
    "form": {
        "title": "Registro"
    },
    "tags": {
        "title": "Editar tags",
        "label": "Digite",
        "add": "Agregar tags"
    },
    "notes": {
        "title": "Anotaciones"
    },
    "map": {
        "title": "Mapa",
        "empty": "No hay direcciones registradas",
        "not-valid": "No hay direcciones válidas registradas",
    },
    "new-email": {
        "title": "Enviar correo electrónico",
        "send": "Enviar",
        "respond": "Responder",
        "respond-all": "Responder para todos",
        "attachment-from-computer": "Adjuntar archivo desde el equipo",
        "attachment-size": "El tamaño máximo de un archivo adjunto es de 15mb",
        "attachment-from-customer": "Adjuntar archivo de cliente",
        "attachment-from-opportunity": "Adjuntar archivo de oportunidad",
        "attachment-proposal": "Adjuntar propuesta",
        "empty-proposals": "No hay propuestas aceptadas o abiertas"
    },
    "email": {
        "to": "Para",
        "from": "De",
        "cc": "Con copia",
        "subject": "Asunto",
        "body": "Cuerpo",
        "attachments": "Accesorios",
        "status": {
            "PENDING": "Entrega pendiente",
            "DELIVERED": "Entrega realizada",
            "TEMPORARY_FAILURE": "Falla temporal",
            "PERMANENT_FAILURE": "Falla permanente"
        },
        "show-full-body": "Ver contenido de correo electrónico",
        "show-previous": "Ver correo electrónico anterior"
    },
    "new-phone-call": {
        "title": "Enlace de registro",
        "phone": "Número",
        "description": "Descripción",
        "empty": "No hay teléfonos registrados"
    },
    "new-sms": {
        "blocked":
            "La integración de mensajes no está activa. Para la activación, es necesario tener acceso a la ficha " +
            "Integraciones en el módulo Configuración.",
        "title": "Enviar SMS",
        "send": "Enviar"
    },
    "sms": {
        "phone": "Teléfono",
        "message": "Mensage",
        "status": {
            "PENDING": "Pendiente",
            "UNDELIVERABLE": "No recibido",
            "DELIVERED": "Recibido",
            "EXPIRED": "Expirado",
            "REJECTED": "Rechazado"
        },
        "status-info": "Estatus del SMS enviado: { status }",
        "received-at": "Respuesta recibida en { date }"
    },
    "new-whatsapp": {
        "call-to-action":
            "Escribir un mensage inicial (opcional) y " +
            "haga clic en el teléfono que desea abrir la conversación en WhatsApp Web.",
        "message": "Mensage",
        "empty-phones": "No hay teléfonos celulares registrados"
    },
    "new-comment": {
        "title": "Nuevo comentario",
        "comment": "Comentario"
    },
    "edit": {
        "label": "Editar",
        "save": "Guardar edición",
        "cancel": "Cancelar edición",
        "error":
            "No se puede realizar el cambio, porque hay campos obligatorios en el formulario " +
            "de registros que están incompletos"
    },
    "not-found": "Cliente no encontrado"
};