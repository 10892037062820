export const FETCH_PORTALS = "x/marketplace/FETCH_PORTALS";
export const fetchPortals = () => ({
    type: FETCH_PORTALS
});

export const REQUEST_PORTALS = "x/marketplace/REQUEST_PORTALS";
export const requestPortals = () => ({
    type: REQUEST_PORTALS
});

export const RECEIVE_PORTALS = "x/marketplace/RECEIVE_PORTALS";
export const receivePortals = data => ({
    type: RECEIVE_PORTALS,
    payload: data
});

export const ERROR_PORTALS = "x/marketplace/ERROR_PORTALS";
export const errorPortals = error => ({
    type: ERROR_PORTALS,
    payload: error,
    error: true
});

export const CREATE_PORTAL = "x/marketplace/CREATE_PORTAL";
export const createPortal = data => ({
    type: CREATE_PORTAL,
    payload: data
});

export const SAVE_PORTAL = "x/marketplace/SAVE_PORTAL";
export const savePortal = portal => ({
    type: SAVE_PORTAL,
    meta: { token: portal.token },
    payload: portal
});

export const DELETE_PORTAL = "x/marketplace/DELETE_PORTAL";
export const deletePortal = ({ token }) => ({
    type: DELETE_PORTAL,
    meta: { token }
});

export const FETCH_MOBIAUTO_DEALERS = "x/marketplace/FETCH_PORTAL_MOBIAUTO_DEALERS";
export const fetchMobiautoDealers = credentials => ({
    type: FETCH_MOBIAUTO_DEALERS,
    meta: { credentials }
});

export const REQUEST_MOBIAUTO_DEALERS = "x/marketplace/REQUEST_PORTAL_MOBIAUTO_DEALERS";
export const requestMobiautoDealers = () => ({
    type: REQUEST_MOBIAUTO_DEALERS
});

export const RECEIVE_MOBIAUTO_DEALERS = "x/marketplace/RECEIVE_PORTAL_MOBIAUTO_DEALERS";
export const receiveMobiautoDealers = dealers => ({
    type: RECEIVE_MOBIAUTO_DEALERS,
    payload: dealers,
});

export const ERROR_MOBIAUTO_DEALERS = "x/marketplace/ERROR_PORTAL_MOBIAUTO_DEALERS";
export const errorMobiautoDealers = error => ({
    type: ERROR_MOBIAUTO_DEALERS,
    payload: error,
    error: true,
});

export const FETCH_ICARROS_DEALERS = "x/marketplace/FETCH_PORTAL_ICARROS_DEALERS";
export const fetchICarrosDealers = credentials => ({
    type: FETCH_ICARROS_DEALERS,
    meta: { credentials }
});

export const REQUEST_ICARROS_DEALERS = "x/marketplace/REQUEST_PORTAL_ICARROS_DEALERS";
export const requestICarrosDealers = () => ({
    type: REQUEST_ICARROS_DEALERS,
});

export const RECEIVE_ICARROS_DEALERS = "x/marketplace/RECEIVE_PORTAL_ICARROS_DEALERS";
export const receiveICarrosDealers = dealers => ({
    type: RECEIVE_ICARROS_DEALERS,
    payload: dealers,
});

export const ERROR_ICARROS_DEALERS = "x/marketplace/ERROR_PORTAL_ICARROS_DEALERS";
export const errorICarrosDealers = error => ({
    type: ERROR_ICARROS_DEALERS,
    payload: error
});
