import { useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import useTranslate from "../hooks/use-translate.hook";
import { editEvent } from "./schedule.actions";
import { EDIT_SCHEDULE_EVENT } from "./schedule.constants";
import css from "./schedule.scss";
import { Dialog, Input } from "../ui";

const SchedulingEventStatus = ({ event, refreshSchedules }) => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const [ showConfirm, setShowConfirm ] = useState( false );
    const [ newStatus, setNewStatus ] = useState( "OPEN" );

    const formik = useForm({
        form: EDIT_SCHEDULE_EVENT,
        enableReinitialize: true,
        initialValues: {
            id: event?.id,
            status: "",
            notAccomplishedReason: null,
        },
        onSubmit: values => dispatch( editEvent( values ) ),
        onSubmitSuccess: () => {
            if ( refreshSchedules ) {
                setShowConfirm( false );
                refreshSchedules();
            }
        }
    });

    const handleStatusChange = () => {
        formik.setFieldValue( "status", newStatus );
        formik.submitForm();
    };

    const toggleConfirm = status => () => {
        setShowConfirm( !showConfirm );
        setNewStatus( event?.status === status ? "OPEN" : status );
    };

    return (
        <>
            <Tooltip title={ t( "schedule:form-event.status.ACCOMPLISHED" ) }>
                <IconButton
                    onClick={ toggleConfirm( "ACCOMPLISHED" ) }
                    disabled={ formik.submitting }
                    className={
                        event.status === "ACCOMPLISHED" ?
                            css.statusAccomplished
                            : event.status !== "OPEN" ? css.statusDisabled : null
                    }
                    size="large">
                    <EventAvailableIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title={ t( "schedule:form-event.status.NOT_ACCOMPLISHED" ) }>
                <IconButton
                    onClick={ toggleConfirm( "NOT_ACCOMPLISHED" ) }
                    disabled={ formik.submitting }
                    className={
                        event.status === "NOT_ACCOMPLISHED" ?
                            css.statusNotAccomplished
                            : event.status !== "OPEN" ? css.statusDisabled : null
                    }
                    size="large">
                    <EventBusyIcon/>
                </IconButton>
            </Tooltip>
            <Dialog open={ showConfirm }>
                <DialogContent>
                    <Form formik={ formik }>
                        <DialogContentText>
                            {
                                newStatus === "ACCOMPLISHED" ?
                                    t( "schedule:form-event.confirm-accomplished" ) :
                                    newStatus === "NOT_ACCOMPLISHED" ?
                                        t( "schedule:form-event.confirm-not-accomplished" ) :
                                        newStatus === "OPEN" ?
                                            t( "schedule:form-event.reopen-scheduling" ) :
                                            null
                            }
                        </DialogContentText>
                        {
                            newStatus === "NOT_ACCOMPLISHED" &&
                                <Field
                                    name="notAccomplishedReason"
                                    type="text"
                                    label={ t( "schedule:form-event.not-accomplished-reason" ) }
                                    component={ Input }
                                />
                        }
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={ formik.submitting }
                        color="primary"
                        onClick={ toggleConfirm( newStatus ) }
                    >
                        { t( "common:no" ) }
                    </Button>
                    <Button
                        disabled={ formik.submitting }
                        color="primary"
                        onClick={ handleStatusChange }
                    >
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SchedulingEventStatus;