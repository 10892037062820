import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const OpportunitySchedulesByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState( [] );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };

    const chartData = useMemo( () => results && ({
        title: {
            text: " "
        },
        xAxis: {
            categories: results.map( item => item.type ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: t( "report:opportunity-schedules-by-user.open" ),
            data: results.map( item => item.userStats.map( userStat => userStat.open ) ),
            color: "#757575"
        }, {
            name: t( "report:opportunity-schedules-by-user.accomplished" ),
            data: results.map( item => item.userStats.map( userStat => userStat.accomplished ) ),
            color: "#4CAF50"
        }, {
            name: t( "report:opportunity-schedules-by-user.not-accomplished" ),
            data: results.map( item => item.userStats.map( userStat => userStat.notAccomplished ) ),
            color: "#F44336"
        }]
    }), [ t, results ] );

    const total = useMemo( () => {
        const total = !isEmpty( results ) &&
            usersId.map( userId => {
                let accomplished = 0;
                let notAccomplished = 0;
                let open = 0;
                let name = "";
                results
                    .map( result => result.userStats )
                    .forEach( result => {
                        result.filter( result => result.id === userId )
                            .forEach( result => {
                                name = result.name;
                                accomplished = accomplished + result.accomplished;
                                notAccomplished = notAccomplished + result.notAccomplished;
                                open = open + result.open;
                            } );
                    });
                return ({
                    name,
                    accomplished: accomplished + "",
                    notAccomplished: notAccomplished + "",
                    open: open + ""
                });
            });

        return total;
    }, [ results, usersId ] );

    const getTotalFooter = item => {
        const totalFooter = item.userStats.reduce( ( accumulator, index ) => {
            accumulator.name = t( "report:opportunity-schedules-by-user.total" );
            accumulator.open += index.open;
            accumulator.accomplished += index.accomplished;
            accumulator.notAccomplished += index.notAccomplished;
            return accumulator;
        }, {
            name: t( "report:opportunity-schedules-by-user.total" ),
            accomplished: 0,
            notAccomplished: 0,
            open: 0
        });
        return {
            ...totalFooter,
            open: totalFooter.open + "",
            accomplished: totalFooter.accomplished + "",
            notAccomplished: totalFooter.notAccomplished + "",
        };
    };

    const model = {
        name: t( "report:opportunity-schedules-by-user.user" ),
        open: t( "report:opportunity-schedules-by-user.open" ),
        accomplished: t( "report:opportunity-schedules-by-user.accomplished" ),
        notAccomplished: t( "report:opportunity-schedules-by-user.not-accomplished" )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITY_SCHEDULES_BY_USER" ) }
                subheader={ t( "report:opportunity-schedules-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    onChange={ handleChange }
                    report={ "OPPORTUNITY_SCHEDULES_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        chartData &&
                            <Chart
                                id="opportunitySchedulesByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <div className={ cssUtils.marginBottomSmall }>
                                    <Table
                                        id="opportunitySchedulesByUserTotalTable"
                                        allowExport
                                        model={ model }
                                        source={ total }
                                        showTitle
                                        title={ t( "report:opportunity-schedules-by-user.total" ) }
                                    />
                                </div>
                                { results.map( ( item, index ) => {
                                    return (
                                        <Table
                                            id={ `opportunitySchedulesByUser${index}Table` }
                                            key={ index }
                                            allowExport
                                            model={ model }
                                            source={
                                                item.userStats.map( stat => ({
                                                    ...stat,
                                                    open: stat.open + "",
                                                    accomplished: stat.accomplished + "",
                                                    notAccomplished: stat.notAccomplished + ""
                                                }))
                                            }
                                            footer={ getTotalFooter( item ) }
                                            showTitle
                                            title={ item.type }
                                        />
                                    );
                                })}
                            </div>
                }
            </div>
        </div>
    );
};

export default OpportunitySchedulesByUser;