import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import SmsIcon from "@mui/icons-material/Sms";
import MessageIcon from "@mui/icons-material/Message";
import ReplyIcon from "@mui/icons-material/Reply";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { phoneFormatInternational } from "../../core/formatter.utils";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "../../ui/timeline/timeline.scss";

const SmsEvent = ({ event }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    const phone = phoneFormatInternational( event.payload.phone );

    return (
        <TimelineItem icon={ <SmsIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={ t( "customer:timeline.sms-sent", { phone } ) }
            />
            <div className={ css.smsContainer }>
                <Chip
                    className={ css.smsSentContainer }
                    avatar={
                        <Tooltip
                            title={
                                t(
                                    "customer:sms.status-info",
                                    { status: t( "customer:sms.status", { status: event.payload.status })}
                                )
                            }
                        >
                            <Avatar classes={{ root: css.smsSentIcon }}><MessageIcon/></Avatar>
                        </Tooltip>
                    }
                    label={ event.payload.message }
                />
                {
                    event.payload.responses.map( ( response, index ) => (
                        <Chip
                            key={ index }
                            className={ css.smsResponseContainer }
                            avatar={
                                <Tooltip
                                    title={
                                        t(
                                            "customer:sms.received-at",
                                            {
                                                date: dateFormat( response.receivedAt ),
                                                interpolation: { escapeValue: false }
                                            }
                                        )
                                    }
                                >
                                    <Avatar classes={{ root: css.smsSentIcon }}><ReplyIcon/></Avatar>
                                </Tooltip>
                            }
                            label={ response.message }
                        />
                    ))
                }
            </div>
        </TimelineItem>
    );
};

export default SmsEvent;