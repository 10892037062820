import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./smtp-server.actions";

const defaultSettings = createRequestReducer({
    begin: actions.REQUEST_SMTP_SERVER_DEFAULT,
    success: actions.RECEIVE_SMTP_SERVER_DEFAULT,
    failure: actions.ERROR_SMTP_SERVER_DEFAULT
});

const settings = createRequestReducer({
    begin: actions.REQUEST_SMTP_SERVER,
    success: actions.RECEIVE_SMTP_SERVER,
    failure: actions.ERROR_SMTP_SERVER
});

export const smtpServer = combineReducers({ defaultSettings, settings });