import { useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUSwitch from "@mui/material/Switch";
import omit from "lodash/omit";

export const Switch = ({
    field,
    label,
    children,
    className,
    fullWidth = true,
    margin = "none",
    onChange,
    ...props
}) => {

    const handleChange = useCallback( e => {
        field?.onChange( e );
        onChange && onChange( e.target.checked );
    }, [ field, onChange ] );

    return (
        <FormControl fullWidth={ fullWidth } margin={ margin }>
            <FormControlLabel
                label={ label || children }
                className={ className }
                control={
                    <MUSwitch
                        { ...field }
                        { ...( omit( props, "meta" ) ) }
                        onChange={ handleChange }
                        onBlur={ () => {} }
                    />
                }
            />
        </FormControl>
    );
};

export default Switch;