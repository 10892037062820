import { useEffect, useState, useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import get from "lodash/get";
import set from "lodash/set";
import isEqual from "lodash/isEqual";
import has from "lodash/has";
import omit from "lodash/omit";
import dayjs from "dayjs";
import classnames from "classnames";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import SchedulingSocialFields from "../../schedule/schedule-event-social-fields.component";
import {
    Dropdown,
    Input,
    DatePicker,
    DateTimePicker,
    RadioButtons,
    Switch,
    UserMentions,
    cssUtils,
    flex
} from "../../ui";
import { createSchedulingFormName } from "../opportunity.utils";
import { saveScheduling } from "../opportunity.actions";
import { getSchedulingTypes } from "../opportunity.selectors";
import { selectors as userSelectors } from "../../user";

const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !get( values, "opportunitySchedulingType.id" ) ) {
        set( errors, "opportunitySchedulingType.id", "common:validation.selection" );
    }
    if ( !values.startDate ) {
        errors.startDate = "common:validation.date";
    }
    if ( values.recurrent && values.recurrent.active ) {
        if ( !values.recurrent.type ) {
            set( errors, "recurrent.type", "common:validation.selection" );
        }
        if ( !values.recurrent.until ) {
            set( errors, "recurrent.until", "common:validation.required" );
        }
    }
    if ( !values.description ) {
        errors.description = "common:validation.required";
    }

    return errors;
};

const Scheduling = ({ scheduling, onDeselect }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const types = useSelector( getSchedulingTypes );
    const users = useSelector( userSelectors.listAll );

    const formik = useForm({
        enableReinitialize: true,
        form: createSchedulingFormName( scheduling.id ),
        initialValues: {
            notify: false,
            notifyAt: null,
            ...scheduling,
            recurrent: {
                active: has( scheduling, "recurrent.id" ),
                ...( get( scheduling, "recurrent" ) || {} ),
                updateMode: has( scheduling, "recurrent.id" ) && "SINGLE"
            },
            selectUser: !!scheduling.user,
        },
        onSubmit: values => dispatch( saveScheduling( values ) ),
        onSubmitSuccess: onDeselect,
        validate,
    });

    const [ notifyMinutesBefore, setNotifyMinutesBefore ] = useState( "0" );
    const [ isMounted, setIsMounted ] = useState( false );
    const [ notifyMinutesBeforeOther, setNotifyMinutesBeforeOther ] = useState( "" );
    const [ showNotify, setShowNotify ] = useState( true );
    const [ disabled, setDisabled ] = useState( false );

    const recurrent = useMemo( () => formik.values.recurrent, [ formik.values.recurrent ] );
    const userId = useMemo( () => scheduling.user?.id, [ scheduling.user ] );

    const prevSelectUser = usePrevious( formik.values.selectUser );
    const prevRecurrent = usePrevious( recurrent );

    const setFieldValue = useMemo( () => formik.setFieldValue, [ formik.setFieldValue ] );

    const handleChangeDate = useCallback( event => {
        const value = has( event, "target.value" ) ? event.target.value : event;

        if ( dayjs( value ).isValid ) {
            const newDate = dayjs( value ).toDate();
            const now = new Date();
            if ( value !== null && newDate < now ) {
                setFieldValue( "status", "ACCOMPLISHED" );
                setNotifyMinutesBefore( "0" );
                setNotifyMinutesBeforeOther( "" );
                setFieldValue( "notify", false );
                setFieldValue( "notifyAt", null );
                setShowNotify( false );
            } else {
                setFieldValue( "status", "OPEN" );
                setShowNotify( true );
                if ( formik.values.notify ) {
                    if ( value ) {
                        const minutes = notifyMinutesBeforeOther ? notifyMinutesBeforeOther : notifyMinutesBefore;
                        const newNotifyAt = new Date( newDate );
                        if ( minutes !== "0" && minutes !== "-1" ) {
                            newNotifyAt.setMinutes( newNotifyAt.getMinutes() - Number.parseInt( value ) );
                        }
                        setFieldValue( "notifyAt", newNotifyAt );
                    } else {
                        setFieldValue( "notifyAt", null );
                    }
                }
            }
            setFieldValue( "endDate", value ? new Date( value ).toISOString() : value );
            if ( value && recurrent && recurrent.active && recurrent.until ) {
                const dateDayjs = dayjs( value );
                const nextYear = dayjs( value ).add( 1, "year" );
                const until = dayjs( recurrent.until );
                if ( dateDayjs.isAfter( until ) || nextYear.isBefore( until ) ) {
                    setFieldValue( "recurrent.until", null );
                }
            }
        }
    }, [
        formik.values.notify,
        notifyMinutesBefore,
        notifyMinutesBeforeOther,
        recurrent,
        setFieldValue
    ] );

    const changeNotifyAt = useCallback( value => {
        if ( !value ) {
            setFieldValue( "notifyAt", null );
            return;
        }

        const newNotifyAt = formik.values.startDate ? new Date( formik.values.startDate ) : null;
        if ( newNotifyAt && value !== "0" && value !== "-1" ) {
            newNotifyAt.setMinutes( newNotifyAt.getMinutes() - Number.parseInt( value ) );
        }

        setFieldValue( "notifyAt", newNotifyAt );
    }, [ setFieldValue, formik.values.startDate ] );

    const handleChangeNotify = useCallback( value => {
        setNotifyMinutesBefore( value );
        changeNotifyAt( value );
    }, [ changeNotifyAt ] );

    const toggleNotify = useCallback( value => {
        if ( !value ) {
            changeNotifyAt();
        } else {
            handleChangeNotify( "0" );
        }
    }, [ changeNotifyAt, handleChangeNotify ] );

    const handleChangeNotifyOther = useCallback( value => {
        setNotifyMinutesBeforeOther( value );
        changeNotifyAt( value );
    }, [ changeNotifyAt ] );

    const setFieldValues = useCallback( () => {
        const newShowNotify = new Date() < new Date( scheduling.startDate );
        let newNotify = false;
        let newNotifyMinutesBefore = "0";
        let newNotifyMinutesBeforeOther = "";

        if ( newShowNotify && scheduling.notifyAt ) {
            newNotify = true;
            newNotifyMinutesBefore = Math.round(
                ( new Date( scheduling.startDate ).getTime() - new Date( scheduling.notifyAt ).getTime() ) / 60000
            ).toString();

            if ( ![ "0", "5", "30" ].includes( newNotifyMinutesBefore ) ) {
                newNotifyMinutesBeforeOther = newNotifyMinutesBefore;
                newNotifyMinutesBefore = "-1";
            }
        }

        const newDisabled = scheduling.user && !users.find( user => user.id === scheduling.user.id );
        setFieldValue( "notify", newNotify );
        setNotifyMinutesBefore( newNotifyMinutesBefore );
        setNotifyMinutesBeforeOther( newNotifyMinutesBeforeOther );
        setDisabled( newDisabled );
        setIsMounted( true );
    }, [ scheduling, users, setFieldValue ] );

    const recurrentHasChanged = !isEqual(
        scheduling.recurrent,
        omit( recurrent, [ "active", "updateMode" ] )
    );

    const recurrentUpdateOptions = useMemo( () => {
        return has( scheduling, "id" ) && has( scheduling, "recurrent.id" ) &&
        [
            {
                value: "SINGLE",
                label: t( "opportunity:scheduling.recurrent.update.single" ),
                disabled: recurrentHasChanged || !recurrent.active,
                className: flex.fill
            }, {
                value: "FUTURE",
                label: t( "opportunity:scheduling.recurrent.update.future" ),
                className: flex.fill
            }, {
                value: "ALL",
                label: t( "opportunity:scheduling.recurrent.update.all" ),
                disabled: recurrentHasChanged,
                className: flex.fill
            }
        ];
    }, [ recurrent.active, recurrentHasChanged, scheduling, t ] );

    const showUpdateMode = useMemo( () => {
        return scheduling.id && scheduling.recurrent && scheduling.recurrent.id;
    }, [ scheduling.id, scheduling.recurrent ]);

    const showSocialFields = useMemo( () => {
        return ( userId && !!users.find( u => u.id === userId ).socialCalendar );
    }, [ userId, users ] );

    useEffect( () => {
        if ( !isMounted ) {
            setFieldValues();
        }
    }, [ isMounted, setFieldValues ] );

    useEffect( () => {
        if ( prevSelectUser && !formik.values.selectUser ) {
            setFieldValue( "user", null );
        }
    }, [ formik.values.selectUser, setFieldValue, prevSelectUser ] );

    useEffect( () => {
        // observa o ativar/desativar recorrencia
        if ( prevRecurrent && prevRecurrent.active && !recurrent.active ) {
            setFieldValue( "recurrent", ({ ...scheduling.recurrent, active: false }) );
        } else if ( prevRecurrent && !prevRecurrent.active && recurrent.active ) {
            setFieldValue(
                "recurrent",
                ({ ...get( scheduling, "recurrent", {} ), active: true, updateMode: "SINGLE" })
            );
        } else if ( !isEqual( omit( prevRecurrent, [ "updateMode" ] ), omit( recurrent, [ "updateMode" ] )) &&
            has( prevRecurrent, "until" ) && get( recurrent, "updateMode" ) !== "FUTURE"
        ) {
            setFieldValue( "recurrent.updateMode", "FUTURE" );
        }
    }, [ recurrent, setFieldValue, prevRecurrent, scheduling ] );

    return (
        <Card>
            <Form formik={ formik } noValidate >
                <CardContent>
                    <Field
                        name="opportunitySchedulingType.id"
                        source={ types.map( type => ({
                            value: type.id,
                            label: type.name
                        }))}
                        label={ t( "opportunity:scheduling.type" ) }
                        component={ Dropdown }
                        disabled={ disabled }
                        required
                    />
                    <Field
                        name="startDate"
                        label={ t( "opportunity:scheduling.date" ) }
                        onAccept={ handleChangeDate }
                        onChange={ handleChangeDate }
                        component={ DateTimePicker }
                        disabled={ disabled }
                        required
                    />
                    <Field
                        label={ t( "opportunity:scheduling.recurrent.label" ) }
                        name="recurrent.active"
                        type="checkbox"
                        component={ Switch }
                        fullWidth
                    />
                    <div className={ flex.container }>
                        {
                            recurrent.active &&
                                    <>
                                        <Field
                                            name="recurrent.type"
                                            label={ t( "opportunity:scheduling.recurrent.type.label" ) }
                                            source={
                                                [ "DAILY", "WEEKLY", "MONTHLY", "YEARLY" ].map( recurrentType => ({
                                                    label: t( `schedule:form-event.recurrent.type.${recurrentType}` ),
                                                    value: recurrentType
                                                }) )
                                            }
                                            component={ Dropdown }
                                            className={ flex.fill }
                                            fullWidth={ false }
                                            required
                                        />
                                        <Field
                                            name="recurrent.until"
                                            component={ DatePicker }
                                            label={ t( "opportunity:scheduling.recurrent.until" ) }
                                            className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                            minDate={ formik.values.startDate }
                                            maxDate={ dayjs( formik.values.startDate ).add( 1, "year" ).toDate() }
                                            fullWidth={ false }
                                            disabled={ !formik.values.startDate }
                                            asDate
                                            required
                                        />
                                    </>
                        }
                    </div>
                    <Field
                        name="description"
                        type="text"
                        label={ t( "opportunity:scheduling.description" ) }
                        component={ UserMentions }
                        disabled={ disabled }
                        singleLine
                        required
                    />
                    {
                        scheduling.status === "NOT_ACCOMPLISHED" &&
                            <Field
                                name="notAccomplishedReason"
                                type="text"
                                label={ t( "opportunity:scheduling.not-accomplished-reason" ) }
                                component={ Input }
                                disabled={ disabled }
                                singleLine
                            />
                    }
                    <Field
                        name="selectUser"
                        type="checkbox"
                        className={ cssUtils.marginTopSmall }
                        label={ t( "opportunity:scheduling.other-user" ) }
                        component={ Switch }
                        disabled={ disabled }
                    />
                    {
                        formik.values.selectUser && !disabled &&
                                <Field
                                    name="user.id"
                                    source={ users.filter( user => user.active || user.id === userId )
                                        .map( user => ({
                                            value: user.id,
                                            label: user.name
                                        }))}
                                    label={ t( "opportunity:scheduling.user" ) }
                                    component={ Dropdown }
                                />
                    }
                    <Field
                        name="notify"
                        label={ t( "opportunity:scheduling.notify-at" ) }
                        checked={ formik.values.notify }
                        onChange={ toggleNotify }
                        component={ Switch }
                        disabled={ disabled || !showNotify }
                    />
                    {
                        formik.values.notify && !disabled ?
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        value={ notifyMinutesBefore }
                                        onChange={ e => handleChangeNotify( e.target.value ) }
                                    >
                                        <FormControlLabel
                                            label={ t( "opportunity:scheduling.notify.at" ) }
                                            value="0"
                                            control={ <Radio/> }
                                        />
                                        <FormControlLabel
                                            label={ t( "opportunity:scheduling.notify.five-minutes" ) }
                                            value="5"
                                            control={ <Radio/> }
                                        />
                                        <FormControlLabel
                                            label={ t( "opportunity:scheduling.notify.thirty-minutes" ) }
                                            value="30"
                                            control={ <Radio/> }
                                        />
                                        <FormControlLabel
                                            label={ t( "opportunity:scheduling.notify.other" ) }
                                            value="-1"
                                            control={ <Radio/> }
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {
                                    notifyMinutesBefore === "-1" ?
                                        <Input
                                            label={ t( "opportunity:scheduling.notify.minutes" ) }
                                            type="number"
                                            value={ notifyMinutesBeforeOther }
                                            onChange={
                                                event => handleChangeNotifyOther( event.target.value )
                                            }
                                            required
                                        />
                                        : null
                                }
                            </div>
                            : null
                    }
                    {
                        showSocialFields &&
                            <SchedulingSocialFields wrapperFields={ FieldArray } wrapperField={ Field } />
                    }
                    {
                        showUpdateMode &&
                                <Field
                                    label={ t( "common:mode" ) }
                                    name="recurrent.updateMode"
                                    component={ RadioButtons }
                                    className={ classnames( cssUtils.marginTopSmall, cssUtils.marginBottom ) }
                                    fullWidth
                                    options={ recurrentUpdateOptions }
                                />
                    }
                </CardContent>
                <CardActions>
                    <Button
                        disabled={ formik.submitting }
                        onClick={ onDeselect }
                    >
                        { t( "common:cancel" ) }
                    </Button>
                    { " " }
                    <Button
                        type="submit"
                        disabled={ formik.submitting }
                        color="primary"
                    >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Form>
        </Card>
    );
};

export default Scheduling;