import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import set from "lodash/set";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../../../source";
import { selectors as userSelectors } from "../../../user";
import { Dropdown, Input } from "../../../ui";
import { createMigration } from "./migration.actions";
import { NEW_MIGRATION_FORM } from "./migration.constants";

const NewMigration = ({ open, onClose, system }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );
    const users = useSelector( userSelectors.listAll );

    const formik = useForm({
        enableReinitialize: true,
        form: NEW_MIGRATION_FORM,
        initialValues: {
            system,
            auth: {},
            defaultSource: {},
            defaultUser: {},
        },
        onSubmit: values => dispatch( createMigration( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( values.system === "PIPEDRIVE" ) {
                if ( !values.auth || !values.auth.token ) {
                    set( errors, "auth.token", "common:validation.required" );
                }
            }
            if ( !values.defaultSource || !values.defaultSource.id ) {
                set( errors, "defaultSource.id", "common:validation.selection" );
            }
            if ( !values.defaultUser || !values.defaultUser.id ) {
                set( errors, "defaultUser.id", "common:validation.selection" );
            }
            return errors;
        },
    });

    return (
        <Dialog open={ open }>
            <DialogTitle>
                { t( "config:migrations.new-migration" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    <Field
                        name="system"
                        label={ t( "config:migrations.system.label" ) }
                        component={ Dropdown }
                        source={
                            [ system ].map( system =>
                                ({
                                    value: system,
                                    label: t( `config:migrations.system.${system}` )
                                })
                            )
                        }
                        disabled
                    />
                    {
                        system === "PIPEDRIVE" &&
                            <Field
                                name="auth.token"
                                label={ t( "config:migrations.api-token" ) }
                                component={ Input }
                                error={ !!formik.error }
                                required
                            />
                    }
                    { formik.error && <Typography variant="caption" color="error">{ formik.error }</Typography> }

                    <Field
                        name="defaultSource.id"
                        label={ t( "config:migrations.default-source" ) }
                        component={ Dropdown }
                        source={ sources?.map( source => ({ value: source.id, label: source.name }))}
                        required
                    />
                    <Typography variant="caption" color="textSecondary" gutterBottom>
                        { t( "config:migrations.default-source-help" ) }
                    </Typography>
                    <Field
                        name="defaultUser.id"
                        label={ t( "config:migrations.default-user" ) }
                        component={ Dropdown }
                        source={ users.map( user => ({ value: user.id, label: user.name }))}
                        required
                    />
                    <Typography variant="caption" color="textSecondary" paragraph>
                        {
                            t( "config:migrations.default-user-help", {
                                system: t( `config:migrations.system.${system}` )
                            })
                        }
                    </Typography>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button disabled={ formik.submitting } color="primary" onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button disabled={ formik.submitting } color="primary" onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewMigration;