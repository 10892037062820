import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import getSymbolFromCurrency from "currency-symbol-map";

import useTranslate from "../../../hooks/use-translate.hook";
import {
    Autocomplete,
    Checkbox,
    DatePicker,
    Dropdown,
    DropdownMultiple,
    Input,
    Helper,
    MaskInput,
    cssUtils,
    flex,
    SelectFunnel
} from "../../../ui";
import { actions as productActions, selectors as productSelectors } from "../../../product";
import { getSubscription } from "../../../subscription/subscription.selectors";
import { selectors as userSelectors } from "../../../user";
import { selectors as teamSelectors } from "../../../team";
import { getSchedulingTypes } from "../../../opportunity/opportunity.selectors";
import { listCategories } from "../../../product/product.selectors";
import { selectors as formSelectors } from "../../../form";
import { saveGoal } from "./goal.actions";
import { intervals } from "./goal.utils";

const types = [
    "OPPORTUNITIES_CREATED",
    "OPPORTUNITIES_SOLD",
    "OPPORTUNITY_SCHEDULING_ACCOMPLISHED",
    "PRODUCTS_SOLD",
    "SOLD_OPPORTUNITIES_PRICE",
    "SOLD_OPPORTUNITIES_RECURRENT_PRICE",
    "AVERAGE_SERVICE_TIME",
    "CUSTOM_FIELD"
];

const GoalForm = ({ formName, goal, onClose, title }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const products = useSelector( productSelectors.getProducts );
    const users = useSelector( userSelectors.listAll );
    const teams = useSelector( teamSelectors.listAll );
    const opportunitySchedulingTypes = useSelector( getSchedulingTypes );
    const opportunityFields = useSelector( formSelectors.listNumberAndPrice( "OPPORTUNITY" ) );
    const productCategories = useSelector( listCategories );
    const subscription = useSelector( getSubscription );

    const formik = useForm({
        enableReinitialize: true,
        form: formName,
        initialValues: {
            name: "",
            type: null,
            target: null,
            products: [],
            interval: "MONTHLY",
            opportunitySchedulingTypeId: null,
            collective: false,
            ...goal,
            fieldId: goal?.field?.id,
            usersId: goal && goal.users && goal.users.map( user => user.id )
        },
        onSubmit: values => dispatch( saveGoal({
            form: formName,
            goal: {
                ...values,
                field: values.fieldId ? { id: values.fieldId } : null,
                funnel: values.funnel && values.funnel.id ? values.funnel : null,
                users: values.usersId && values.usersId.map( userId => ({ id: userId }))
            }
        })),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.type ) {
                errors.type = "common:validation.selection";
            } else {
                if ( values.type === "PRODUCTS_SOLD" && isEmpty( values.products ) ) {
                    errors.products = "common:validation.selection";
                }
                if ( values.type === "OPPORTUNITY_SCHEDULING_ACCOMPLISHED" && !values.opportunitySchedulingTypeId ) {
                    errors.opportunitySchedulingTypeId = "common:validation.selection";
                }
                if ( values.type === "CUSTOM_FIELD" && !values.fieldId ) {
                    errors.fieldId = "common:validation.selection";
                }
            }
            if ( !values.target ) {
                errors.target = "common:validation.required";
            }

            return errors;
        }
    });

    const timeoutSearchProducts = useRef();

    const searchProducts = description => {
        clearTimeout( timeoutSearchProducts.current );
        if ( description ) {
            timeoutSearchProducts.current = setTimeout( () => {
                dispatch( productActions.fetchProducts({ description }) );
            }, 300 );
        }
    };

    const { setFieldValue, values } = formik;

    const productsSource = useMemo(
        () => ( values.products || [] ).concat( products || [] ),
        [ values.products, products ]
    );

    useEffect( () => {
        if ( !isEmpty( values.usersId ) ) {
            setFieldValue( "team", null );
        }
    }, [ setFieldValue, values.usersId ] );
    useEffect( () => {
        if ( !isEmpty( values.team ) ) {
            setFieldValue( "usersId", null );
        }
    }, [ setFieldValue, values.team ] );
    useEffect( () => {
        if ( !isEmpty( values.productCategory ) ) {
            setFieldValue( "products", [] );
        }
    }, [ setFieldValue, values.productCategory ] );

    const priceFormat = formik.values.type?.match( "SOLD_OPPORTUNITIES_PRICE|SOLD_OPPORTUNITIES_RECURRENT_PRICE" );

    return (
        <>
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="name"
                        label={ t( "config:goal.name" ) }
                        component={ Input }
                        required
                    />
                    <Field
                        name="type"
                        label={ t( "config:goal.type.label" ) }
                        source={ types.map( type => ({ value: type, label: t( `config:goal.type.${type}` ) })) }
                        component={ Dropdown }
                        required
                        helperText={ formik.values.type === "CUSTOM_FIELD" ? t( "config:goal.help-type" ) : null }
                    />
                    {
                        formik.values.type === "CUSTOM_FIELD" &&
                            <Field
                                name="fieldId"
                                label={ t( "config:goal.type.CUSTOM_FIELD" ) }
                                source={ opportunityFields.map( field => ({ value: field.id, label: field.name })) }
                                component={ Dropdown }
                                required
                            />
                    }
                    {
                        formik.values.type === "OPPORTUNITY_SCHEDULING_ACCOMPLISHED" &&
                            <Field
                                name="opportunitySchedulingTypeId"
                                source={ opportunitySchedulingTypes.map( type => ({
                                    value: type.id,
                                    label: type.name
                                }))}
                                label={ t( "config:goal.opportunity-scheduling-type" ) }
                                component={ Dropdown }
                                required
                            />
                    }
                    {
                        formik.values.type !== "PRODUCTS_SOLD" &&
                            <Field
                                name="funnel"
                                component={ SelectFunnel }
                                getFunnelValue={ funnel => funnel }
                                emptyValue
                            />
                    }

                    <Field
                        name="productCategory"
                        label={ t( "config:goal.product-categories" ) }
                        source={ productCategories.map( category => ({ value: category, label: category.name })) }
                        component={ Dropdown }
                        emptyValue
                    />
                    <Field
                        name="products"
                        label={ t( "config:goal.products" ) }
                        source={ productsSource.map( product => ({ value: product, label: product.description })) }
                        onQueryChange={ searchProducts }
                        getOptionLabel={ option =>
                            option ? ( option.value ? option.value.description : option.description || "" ) : ""
                        }
                        renderTags={ ( value, getTagProps ) =>
                            value.map(( option, index ) => (
                                <Chip
                                    label={ option.description }
                                    size="small"
                                    { ...getTagProps({ index }) }
                                />
                            ))
                        }
                        required={ formik.values.type === "PRODUCTS_SOLD" }
                        component={ Autocomplete }
                    />
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            name="target"
                            label={ t( "config:goal.target" ) }
                            component={ MaskInput }
                            step="1"
                            className={ flex.fill }
                            fullWidth={ false }
                            decimalSeparator={ null }
                            isNumericString
                            prefix={ priceFormat ? `${getSymbolFromCurrency( subscription.currency )} ` : "" }
                            required
                        />
                        <Helper message={ t( "config:goal.help-target" ) }/>
                    </div>
                    <Typography variant="body2" className={ cssUtils.marginTop }>
                        { t( "config:goal.help-users" ) }
                    </Typography>
                    <Field
                        name="team.id"
                        label={ t( "config:goal.team" ) }
                        source={ teams.map( team => ({ value: team.id, label: team.name })) }
                        component={ Dropdown }
                        emptyValue
                    />
                    <Field
                        name="usersId"
                        label={ t( "config:goal.users" ) }
                        source={ users.map( user => ({ value: user.id, label: user.name })) }
                        component={ DropdownMultiple }
                    />
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            type="checkbox"
                            name="collective"
                            component={ Checkbox }
                            label={ t( "config:goal.collective" ) }
                            fullWidth={ false }
                        />
                        <Helper message={ t("config:goal.collective-helper") }/>
                    </div>
                    <hr/>
                    <Field
                        name="interval"
                        label={ t( "config:goal.interval.label" ) }
                        helperText={ t( "config:goal.interval.help" ) }
                        component={ Dropdown }
                        source={ intervals.map( interval => ({
                            value: interval,
                            label: t( `config:goal.interval.${interval}` )
                        }))}
                        required
                    />
                    <Typography
                        className={ classnames( cssUtils.marginTop, cssUtils.marginBottom0 ) }
                        color="textSecondary"
                    >
                        { t( "config:goal.duration.label" ) }
                    </Typography>
                    <div className={ flex.container }>
                        <Field
                            name="initialDate"
                            label={ t( "config:goal.initial-date" ) }
                            component={ DatePicker }
                            className={ classnames( cssUtils.marginRightSmall, flex.fill ) }
                            fullWidth={ false }
                            asDate
                        />
                        <Field
                            name="finalDate"
                            label={ t( "config:goal.final-date" ) }
                            component={ DatePicker }
                            className={ flex.fill }
                            fullWidth={ false }
                            asDate
                        />
                    </div>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        { t( "config:goal.duration.help" ) }
                    </Typography>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </>
    );
};

export default GoalForm;