export default {
    "title": "Dashboard",
    "select-teams-or-users": "Seleccionar equipos y/o usuarios",
    "team": "Equipo",
    "empty-team": "No hay equipos configurados",
    "users": "Usuarios",
    "period": {
        "label": "Período",
        "values": {
            "TODAY": "Hoy",
            "YESTERDAY": "Ayer",
            "THIS_WEEK": "Esta semana",
            "LAST_WEEK": "Semana pasada",
            "THIS_MONTH": "Este mes",
            "LAST_MONTH": "Mes pasado",
            "THIS_QUARTER": "Este trimestre",
            "LAST_QUARTER": "Trimestre pasado",
            "THIS_YEAR": "Este año",
            "LAST_YEAR": "Año pasado",
            "CUSTOM": "Personalizado"
        }
    },
    "initial-date": "Período inicial",
    "final-date": "Período final",
    "help": "Ayuda",
    "opportunities-created-chart": {
        "title": "Oportunidades creadas",
        "title-dialog": "Oportunidades creadas de { initialDate } hasta { finalDate }",
        "empty": "No hay información sobre las oportunidades creadas",
        "accumulated": "Acumulado",
        "help": "Considera las oportunidades por la fecha de creación y el usuario que está co la oportunidad.",
        "help-accumulated": "En la opción acumulado, verá la suma de los datos de los usuarios seleccionados"
    },
    "opportunities-kpis": {
        "conversion": {
            "rate": "Tasa de conversión",
            "of": "De",
            "finished": "oportunidades finalizadas",
            "help":
                "Tasa de conversión basada en oportunidades ganadas frente a oportunidades finalizadas " +
                "(ganado + perdido)"
        },
        "created-total": {
            "title": "Oportunidades creadas",
            "help": "Total de oportunidades creadas, en el período, para lo(s) usuario(s) seleccionado(s)"
        },
        "created-price": {
            "title": "Valor de las oportunidades creadas",
            "help": "Valor total de las oportunidades creadas, en el período, para lo(s) usuario(s) seleccionado(s)",
            "total": "Total"
        },
        "sold-total": {
            "title": "Oportunidades ganadas",
            "help": "Total de oportunidades ganadas, en el período, del(los) usuario(s) seleccionado(s)"
        },
        "sold-price": {
            "title": "Valor de las oportunidades ganadas",
            "help": "Valor total de las oportunidades ganadas, en el período, para lo(s) usuario(s) seleccionado(s)",
            "total": "Total"
        },
        "lost-total": {
            "title": "Oportunidades perdidas",
            "help": "Total de oportunidades perdidas, en el período, de los usuarios seleccionados"
        },
        "lost-price": {
            "title": "Valor de las oportunidades perdidas",
            "help": "Valor total de las oportunidades perdidas, en el período, del(los) usuário(s) seleccionado(s)",
            "total": "Total"
        },
        "schedules-open": {
            "title": "Horarios abiertos",
            "help": "Abrir programaciones con fecha en el período"
        },
        "schedules-accomplished": {
            "title": "Horarios realizados",
            "help": "Horarios realizados con fecha en el período"
        },
        "schedules-not-accomplished": {
            "title": "Horarios no hechos",
            "help": "Horarios no realizados con fecha en el período"
        },
        "first-interact": {
            "title": "Tiempo primera interacción",
            "help": "Tiempo promedio entre la creación de la oportunidad y la primera interacción"
        },
        "first-contact": {
            "title": "Tiempo primer intento de contacto",
            "help": "Tiempo promedio entre la creación de oportunidades y el primer intento de contacto"
        },
        "closing-time": {
            "title": "Tiempo de cierre de la oportunidad",
            "help": "Tiempo promedio entre la creación de la oportunidad y el cierre de la oportunidad"
        }
    },
    "loss-reasons-chart": {
        "title": "Razón de venta perdida",
        "title-dialog": "Oportunidades perdidas por { lossReason }",
        "empty": "No hay información de razón de venta perdida",
        "opportunities": "Oportunidades",
        "help": "Considera las oportunidades perdidas por su razón de ventas perdidas"
    },
    "opportunities-sales-forecast-chart": {
        "title": "Previsión de cierre",
        "empty": "Sin información de previsión de cierre",
        "open": "Abierto",
        "open-recurrent": "Abierto recurrente",
        "sold": "Vendido",
        "sold-recurrent": "Vendido recurrente",
        "help-open":
            "Aberto: considera las oportunidades abiertas con la fecha de previsión " +
            "de cierre en el período seleccionado",
        "help-sold":
            "Vendido: considera las oportunidades ganadas con la fecha de finalización en el período seleccionado"
    },
    "opportunities-temperature-chart": {
        "title": "Temperaturas",
        "title-dialog": "Oportunidades con temperatura { temperature }",
        "empty": "Sin información de temperatura",
        "cold": "Frío",
        "warm": "Tibio",
        "hot": "Caliente",
        "opportunities": "Oportunidades",
        "help":
            "Considera las oportunidades por la fecha de creación, que están abiertas y que " +
            "tienen una temperatura seleccionada"
    },
    "opportunity-checkin-chart": {
        "title": "Check-in en oportunidades",
        "help": "",
        "list-title": "Check-ins realizados por { user }",
        "open": "Abrir en Google Maps"
    },
    "opportunity-scheduling-types-chart": {
        "title": "Tipos de programaciones de oportunidades",
        "empty": "Sin información de tipo de programación",
        "open": "Abrir",
        "accomplished": "Realizado",
        "not-accomplished": "No realizado",
        "help": "Considera las programaciones de oportunidades según la fecha programada"
    },
    "opportunities-by-source": {
        "title": "Oportunidades por origen",
        "empty": "Sin oportunidades",
        "source": "Origen",
        "created": "Creadas",
        "sold": "Ganadas",
        "lost": "Perdidas",
        "help": "Considera oportunidades por fechas de creación, ventas ganadas y perdidas según su origen",
        "title-dialog": "Oportunidades de origen por { source }"
    },
    "print-chart": {
        "title": "KPIS y Dashboards",
        "conversion-rate": "Tasa de conversión (KPI)",
        "opportunities-created-total": "Oportunidades (KPI)",
        "opportunities-value-total": "Valores de Oportunidad (KPI)",
        "scheduling": "Horarios (KPI)",
        "time-metrics": "Métricas de tiempo (KPI)",
        "opportunities-by-source": "Oportunidades por origen (Dashboard)",
        "opportunities-created": "Oportunidades creadas (Dashboard)",
        "opportunities-lost-reasons": "Razón de venta perdida (Dashboard)",
        "temperature": "Temperatura (Dashboard)",
        "opportunities-sales-forecast": "Previsión de cierre (Dashboard)",
        "opportunity-checkin": "Check-in en oportunidades (Dashboard)",
        "opportunity-scheduling-types": "Tipos de programaciones de oportunidades (Dashboard)",
        "emission": "Emisión { date } a las { time } por { user } para la Inscripción { subscription }",
        "print-title": "Dashboards LeadStation",
        "info": "Nota: en las opciones de impresión, asegúrate de que la opción: \"{ browserInfo }\" " +
        "esté marcada para ver correctamente los gráficos.",
        "edge": "Elementos gráficos de fondo",
        "chrome": "Imprimir imágenes de fondo",
        "firefox": "Imprimir fondo",
        "safari": "Imprimir fondos",
        "unknown": "Imprimir imágenes de fondo",
        "opera": "Elementos gráficos de fondo"
    }
};