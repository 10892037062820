import { combineReducers } from "redux";

import * as fileActions from "../../../file/file.actions";
import * as actions from "./template.actions";

export const reorderSections = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
};

function fileModels ( state = {}, action ) {
    switch ( action.type ) {
        case actions.INIT_FILE_MODELS:
            return {
                newFileModelShown: false
            };

        case actions.TOGGLE_NEW_FILE_MODEL:
            return {
                newFileModelShown: !state.newFileModelShown
            };

        case actions.OPEN_FILE_MODEL:
            return {
                selected: action.payload
            };

        case actions.CLOSE_FILE_MODEL:
            return {
                selected: null
            };

        case actions.DELETE_FILE_MODEL:
            return {
                ...state,
                deleting: true
            };

        case fileActions.RECEIVE_MODEL_SECTIONS:
            return !state.selected ? state : {
                ...state,
                selectedIndex: null,
                sorting: false,
                unsortedSections: undefined,
                sections: action.payload
            };

        case actions.NEW_FILE_MODEL_SECTION:
            let selectedIndex = state.sections.length;
            const section = action.payload;

            if ( selectedIndex > 0 ) {
                const lastSection = state.sections[ selectedIndex - 1 ];

                section.prevFileModelSectionId = lastSection.id ? lastSection.id : lastSection.prevFileModelSectionId;

                if ( !lastSection.id ) {
                    state.sections.pop();
                    selectedIndex = selectedIndex - 1;
                }

            } else {
                section.prevFileModelSectionId = null;
            }

            return {
                ...state,
                selectedIndex: selectedIndex,
                sections: state.sections.concat( section )
            };

        case actions.SELECT_FILE_MODEL_SECTION:
            const sameIndex = action.payload.index === state.selectedIndex;
            const deselectedState = fileModels( state, { type: actions.DESELECT_FILE_MODEL_SECTION });
            return Object.assign( {}, sameIndex ? state : deselectedState, {
                selectedIndex: action.payload.index
            });

        case actions.DESELECT_FILE_MODEL_SECTION:
            const current = state.sections[ state.selectedIndex ];
            return {
                ...state,
                selectedIndex: null,
                sections: !current || current.id ? state.sections : state.sections.slice( 0, -1 )
            };

        case actions.SORT_FILE_MODEL_SECTION:
            const { oldIndex, newIndex } = action.payload;
            const unsortedSections = state.unsortedSections || state.sections;
            return oldIndex === newIndex ? state : {
                ...state,
                unsortedSections,
                sorting: true,
                sections: reorderSections( state.sections, oldIndex, newIndex )
            };

        case actions.CANCEL_SORTING_FILE_MODEL_SECTION:
            return {
                ...state,
                unsortedSections: undefined,
                sections: state.unsortedSections,
                sorting: false
            };

        case actions.DELETE_FILE_MODEL_SECTION:
            return {
                ...state,
                deletingSection: true
            };

        default:
            return state;
    }
}

export const templateConfig = combineReducers({ fileModels });