import { useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils } from "../ui";
import css from "./input-file.scss";

const InputFile = ({ accept, field, form, id, required, className, onChange, ...props }) => {
    const t = useTranslate();

    const [ invalidFormat, setInvalidFormat ] = useState( false );

    const inputFile = useRef();

    const toggleInvalidFormat = () => setInvalidFormat( !invalidFormat );

    const handleChange = event => {
        const file = event.target.files[ 0 ];

        if ( !accept ) {
            field && form.setFieldValue( field.name, file );
            onChange && onChange( file );
            return;
        }
        const fileFormat = file.name.substring( file.name.lastIndexOf( "." ) );

        const fileAccepted = accept.split( "," ).find( format => {
            const searchFormat = fileFormat.search( format.replace( "*", "" ) ) >= 0;
            const searchType = file.type.search( format.replace( "*", "" ) ) >= 0;
            return searchType || searchFormat;
        });

        if ( fileAccepted ) {
            field && form.setFieldValue( field.name, file );
            onChange && onChange( file );
        } else {
            toggleInvalidFormat();
            field && form.setFieldValue( field.name, null );
            onChange && onChange( null );
        }
    };

    const value = useMemo( () => field.value, [ field ] );

    const validationMsg = useMemo( () => {
        if ( form ) {
            const fieldMeta = form.getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, form ] );

    return (
        <FormControl
            id={ id }
            error={ !!validationMsg }
            required={ required }
            className={ className }
        >
            <div className={ classnames( css.container ) } >
                <Button
                    size="small"
                    variant="contained"
                    component="label"
                    className={ css.fileInput }
                >
                    { t( "ui:input-file.label" ) }
                    <input
                        hidden
                        { ...props }
                        type="file"
                        onChange={ handleChange }
                        ref={ inputFile }
                    />
                </Button>
                <Typography className={ classnames( css.fileName, cssUtils.marginLeftSmall ) }>
                    { value?.name || t( "ui:input-file.no-file" ) }
                </Typography>
            </div>
            { validationMsg && <FormHelperText>{ t( validationMsg ) }</FormHelperText> }
            <Snackbar
                open={ invalidFormat }
                action={
                    <Button color="secondary" onClick={ toggleInvalidFormat }>
                        { t( "common:ok" ) }
                    </Button>
                }
                message={ t( "ui:input-file.file-error" ) }
                autoHideDuration={ 5000 }
                onClose={ toggleInvalidFormat }
            />
        </FormControl>
    );
};

export default InputFile;