import { Routes, Route } from "react-router-dom";

import Holding from "./holding/holding.component";
import Kanban from "./kanban/kanban.component";
import SmptServer from "./smtp-server/smtp-server.component";
import Rules from "./rules/rules.component";
import Migration from "./migration/migration.component";
import Distribution from "./distribution/distribution.component";

const Advanced = () => {
    return (
        <Routes>
            <Route path={ "holding" } element={ <Holding/> } />
            <Route path={ "smtp" } element={ <SmptServer/> } />
            <Route path={ "rules" } element={ <Rules/> } />
            <Route path={ "migrations" } element={ <Migration/> } />
            <Route path={ "kanban" } element={ <Kanban/> } />
            <Route path={ "distribution" } element={ <Distribution/> } />
        </Routes>
    );
};

export default Advanced;