import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as portalActions from "./portal-integration.actions";

export const portals = createRequestReducer({
    begin: portalActions.REQUEST_PORTALS,
    success: portalActions.RECEIVE_PORTALS,
    failure: portalActions.ERROR_PORTALS
});

const icarrosDealers = ( state = {}, action ) => {
    switch ( action.type ) {
        case portalActions.REQUEST_ICARROS_DEALERS:
            return {
                loading: true,
            };
        case portalActions.RECEIVE_ICARROS_DEALERS:
            return {
                loading: false,
                data: action.payload,
            };
        case portalActions.ERROR_ICARROS_DEALERS:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

const mobiautoDealers = (state = {}, action) => {
    switch ( action.type ) {
        case portalActions.REQUEST_MOBIAUTO_DEALERS:
            return {
                loading: true,
            };
        case portalActions.RECEIVE_MOBIAUTO_DEALERS:
            return {
                loading: false,
                data: action.payload,
            };
        case portalActions.ERROR_MOBIAUTO_DEALERS:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const portalIntegration = combineReducers({
    portals,
    icarrosDealers,
    mobiautoDealers,
});