export default {
    "locales": {
        "pt-BR": "Português",
        "es-MX": "Español"
    },
    "language": "Idioma",
    "change-locale": "Cambia el idioma",
    "full-locale": "es-MX",
    "country": "MX",
    "locale": "pt",
    "currency": "MXN",
    "currency-symbol": "$",
    "date": "DD/MM/YYYY",
    "full-date": "DD/MM/YYYY HH:mm",
    "format-hour": "HH:mm",
    "day-month": "DD/MM",
    "yes": "Sí",
    "no": "No",
    "access": "Accesar",
    "add": "Agregar",
    "remove": "Eliminar",
    "ok": "Ok",
    "duplicate": "Duplicar",
    "edit": "Editar",
    "enter": "Entrar",
    "confirm": "Confirmar",
    "confirmation": "Confirmación",
    "execute": "Ejecutar",
    "save": "Guardar",
    "submit": "Enviar",
    "cancel": "Cancelar",
    "clean": "Limpar",
    "close": "Cerrar",
    "exclude": "Excluir",
    "expand": "Expandir",
    "export": "Exportar",
    "copy": "Copiar",
    "copied": "Copiado",
    "click-here-to-copy": "Haga aquí clic para copiar",
    "print": "Impresión",
    "help": "Ayuda",
    "file": "Archivo",
    "optional": "Opcional",
    "definitions": "Definiciones",
    "previous": "Anterior",
    "next": "Siguiente",
    "back": "Cancelar",
    "skip": "Saltar",
    "refresh": "Actualizar",
    "search": "Buscar",
    "select": "Seleccione",
    "table": "Tabela",
    "visualize": "Visualizar",
    "minimize": "Minimizar",
    "order": "Alterar orden",
    "download": "Hacer o descargar",
    "delete-confirm": "Está seguro que desea eliminarlo? Esta acción es irreversible!",
    "click-here-access": "Haga clic aquí para accesar",
    "empty-email": "Ningún e-mail registrado",
    "empty-phone": "Ningún número telefónico registrado",
    "open-this-window": "Abrir esta ventana",
    "open-new-window": "Abrir en una nueva ventana",
    "creation-user": "Usuario de creación",
    "creation-date": "Fecha de creación",
    "change-user": "Cambiar usuario",
    "change-date": "Cambiar fecha",
    "name": "Nombre",
    "username": "Nombre de usuario",
    "email": "E-mail",
    "password": "Contraseña",
    "ein": "RFC",
    "ssn": "RFC",
    "phone": "Teléfono",
    "address": "Dirección",
    "customers": "Clientes",
    "customer-empty": "Sin selección de datos",
    "customer-person-empty": "Nenhuma pessoa encontrada",
    "customer-company-empty": "Nenhuma empresa encontrada",
    "none": "Ninguno",
    "mode": "Modo",
    "update": "Cambio",
    "from": "De",
    "until": "Até",
    "price": "Valor",
    "total": "Total",
    "type": "Tipo",
    "error": {
        "unknown": "Ops, aquí se ha producido un error desconocido! Inténtelo de nuevo más tarde!",
        "refresh": "Ops, aquí se ha producido un error desconocido! Actualice la página e intente nuevamente"
    },
    "validation": {
        "accept-terms": "Debe aceptar los términos de uso.",
        "required-fields": "Debe llenar todos los campos obligatorios",
        "required": "Llena este campo.",
        "required-file": "Seleccione un archivo.",
        "selection": "Seleccione un valor.",
        "select-option": "Seleccione al menos un valor.",
        "add-at-least-one-user": "Agregue al menos un usuario.",
        "address": "Seleccione una dirección válida.",
        "email": "Introduzca un correo electrónico válido.",
        "email-taken": "Este correo electrónico ya está siendo usado por otro usuário.",
        "dist-empty-users": "Debe seleccionar un equipo o al menos un usuario para distribuir las oportunidades.",
        "phone-number": "Introduzca un número de teléfono válido con DDD.",
        "currency": "Introducir una cantidad de dinero válida.",
        "exceeded": "Número de carácteres excedido",
        "date": "Introduzca una fecha válida.",
        "password-length": "La contraseña debe contener al menos 6 caracteres",
        "passwords-match": "Las contraseñas no coinciden.",
        "lower-start": "Horario inferior al de inicio.",
        "higher-end": "Horario superior al del final.",
        "lower-start-period": "Horario inferior al de la mañana.",
        "higher-end-period": "Horario superior al de la tarde.",
        "ssn": "Introduzca un RFC válido.",
        "ein": "Introduzca un RFC válido.",
        "user-limit": "Cantidad de usuarios deseados no válidos",
        "credit-card": {
            "number": "Numero de tarjeta invalido.",
            "month-out-range": "El mes debe estar entre 01 y 12.",
            "year-out-range": "El año no debe estar en el pasado.",
            "date-out-range": "La fecha no puede ser pasada.",
            "cvc": "Código de seguridad es inválido."
        },
        "plan-limit-out-of-range": "El número de usuarios está fuera del límite del plan.",
        "loss-reason": "Introduzca un motivo de la pérdida."
    },
    "highcharts": {
        "print-chart": "Imprimir gráfico",
        "menu-chart": "Opciones de gráfico",
        "download-jpeg": "Descargar imagen JPEG",
        "download-png": "Descargar imagen PNG",
        "download-pdf": "Descargar documento PDF",
        "download-csv": "Descargar CSV",
        "download-xls": "Descargar XLS",
        "view-full-screen": "Ver en pantalla completa",
    }
};