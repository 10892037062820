import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, select, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as funnelActions } from "../../../funnel";
import { actions as phaseActions, selectors as phaseSelectors } from "../../../phase";
import * as actions from "./funnel.actions";
import { NEW_FUNNEL_FORM } from "./funnel.constants";
import { createFormFunnel, createDeleteFormFunnel } from "./funnel.utils";

export function *watchConfigOpportunityFunnel () {
    yield all([
        takeEvery( actions.CREATE_FUNNEL, createFunnel ),
        takeEvery( actions.SAVE_FUNNEL, saveFunnel ),
        takeEvery( actions.DELETE_FUNNEL, deleteFunnel ),
        takeEvery( actions.DELETE_PHASE, deletePhase )
    ]);
}

export function *createFunnel ({ payload }) {
    yield put( startSubmit( NEW_FUNNEL_FORM ) );
    const { phase, ...funnel } = payload;
    try {
        const response = yield call( apiv1.post, "/funnels", funnel );
        phase.funnelId = response.data.id;
        yield call( apiv1.post, "/phases", phase );
        yield put( funnelActions.fetchFunnels( true ) );
        yield put( phaseActions.fetchPhases( true ) );
        yield put( stopSubmit( NEW_FUNNEL_FORM ) );
        yield put( reset( NEW_FUNNEL_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_FUNNEL_FORM, e.response.data ) );
    }
}

export function *saveFunnel ({ payload }) {
    const { phases, ...funnel } = payload;
    const form = createFormFunnel( funnel.id );
    yield put( startSubmit( form ) );
    try {
        yield call( apiv1.patch, `/funnels/${funnel.id}`, funnel );

        const patchedPhases = [];
        for ( const [ index, phase ] of phases.entries() ) {
            let response;
            if ( phase.id ) {
                response = yield call( apiv1.patch, "/phases/" + phase.id, {
                    ...phase,
                    previousPhaseId: index > 0 ? patchedPhases[ index - 1 ].id : null
                });
            } else {
                response = yield call( apiv1.post, "/phases", {
                    ...phase,
                    previousPhaseId: index > 0 ? patchedPhases[ index - 1 ].id : null
                });
            }

            patchedPhases.push( response.data );
        }
        yield put( funnelActions.fetchFunnels( true ) );
        yield put( phaseActions.fetchPhases( true ) );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteFunnel ({ meta: { id }, payload }) {
    const form = createDeleteFormFunnel( id );
    yield put( startSubmit( form ) );
    try {
        yield call( apiv1.put, `/funnels/${id}/transferopportunities`, payload );
        yield call( apiv1.delete, `/funnels/${id}` );
        yield put( funnelActions.fetchFunnels( true ) );
        yield put( phaseActions.fetchPhases( true ) );
        yield put( stopSubmit( form ) );
        yield put( reset( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}

export function *deletePhase ({ payload }) {
    const { phase } = payload;
    if ( !phase || !phase.id ) {
        return;
    }

    const phases = yield select( phaseSelectors.list );

    try {
        yield call( apiv1.delete, "/phases/" + phase.id );

        const filteredPhases = phases.filter( item => item.id !== phase.id );
        yield put( phaseActions.receivePhases( filteredPhases ) );
    } catch ( e ) {
        // lint with no errors
    }
}