import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import dayjs from "dayjs";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import usePrevious from "../hooks/use-previous.hook";
import { actions as opportunityActions, selectors as opportunitySelectors } from "../opportunity";
import { selectors as coreSelectors } from "../core";
import { actions as funnelActions, selectors as funnelSelectors } from "../funnel";
import { actions as sourceActions, selectors as sourceSelectors } from "../source";
import { actions as teamActions, selectors as teamSelectors } from "../team";
import { actions as userActions, selectors as userSelectors } from "../user";
import ExportationForm from "../opportunity/list/exportation-form.component";
import OpportunitiesDialog from "../opportunity/opportunities-dialog.component";
import OpportunitiesKpis from "./opportunities-kpis.component";
import OpportunitiesCreatedChart from "./opportunities-created-chart.component";
import OpportunitiesSalesForecastChart from "./opportunities-sales-forecast-chart.component";
import OpportunitiesTemperatureChart from "./opportunities-temperature-chart.component";
import OpportunityCheckinChart from "./opportunity-checkin-chart.component";
import OpportunitySchedulingTypesChart from "./opportunity-scheduling-types-chart.component";
import OpportunitiesBySource from "./opportunities-by-source.component";
import LossReasonsChart from "./loss-reasons-chart.component";
import DashboardFilters from "./dashboard-filters.component";
import DashboardPrintChart from "./dashboard-print-chart";
import { flex, cssUtils } from "../ui";
import css from "./dashboard.scss";

const Dashboard = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const allUsers = useSelector( userSelectors.listAllPermitted );
    const activeUsers = useMemo(
        () => ( allUsers || [] ).filter( user => user.active ).map( user => user.id ),
        [ allUsers ]
    );
    const loadingSchedulingTypes = useSelector( opportunitySelectors.isLoadingSchedulingTypes );
    const user = useSelector( coreSelectors.getUser );
    const loadingUser = !user;
    const loadingFunnels = useSelector( funnelSelectors.isLoadingAll );
    const loadingSources = useSelector( sourceSelectors.isLoadingSources );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const loadingFilters = loadingSchedulingTypes ||
        loadingUser ||
        loadingFunnels ||
        loadingSources ||
        loadingTeams ||
        loadingUsers;
    const loading = useSelector( opportunitySelectors.isLoadingAllStatistics );
    const opportunitiesStatistics = useSelector( opportunitySelectors.getAllStatistics );
    const funnelsId = useSelector( opportunitySelectors.getFunnelsId );

    const prevFunnelsId = usePrevious( funnelsId );
    const prevLoadingFilters = usePrevious( loadingFilters );

    const [ filters, setFilters ] = useState({
        dateType: "THIS_MONTH",
        funnelsId: funnelsId,
        initialDate: dayjs().startOf( "month" ).toISOString(),
        finalDate: dayjs().endOf( "month" ).toISOString(),
        sourcesId: [],
        team: null,
        users: [],
    });
    const [ opportunitiesDialogSelectedFunnel, setOpportunitiesDialogSelectedFunnel ] = useState( null );
    const [ dialogOpportunities, setDialogOpportunities ] = useState( "" );
    const [ opportunitiesParams, setOpportunitiesParams ] = useState( null );
    const [ openOpportunitiesDialog, setOpenOpportunitiesDialog ] = useState( false );
    const [ showDialogExport, setShowDialogExport ] = useState( false );

    const searchInfo = useCallback( () => {
        const { funnelsId, initialDate, finalDate, sourcesId, users } = filters;
        if ( isEmpty( funnelsId ) ) {
            return;
        }
        dispatch( opportunityActions.fetchOpportunitiesStatistics({
            funnelsId: funnelsId?.toString(),
            sourcesId: sourcesId?.toString(),
            initialDate,
            finalDate,
            users: users.toString()
        }));
    }, [ dispatch, filters ] );

    const loadOpportunities = useCallback( ( title, params ) => {
        setOpportunitiesParams({ ...params, sourcesId: ( params?.sourcesId || filters.sourcesId?.toString() ) });
        setDialogOpportunities( title );
        setOpenOpportunitiesDialog( true );
    }, [ filters.sourcesId ] );

    const closeOpportunities = () => setOpenOpportunitiesDialog( false );

    const handleSubmitFilters = values => setFilters({ ...values });

    const handleExportOpportunities = () => {
        setOpenOpportunitiesDialog( false );
        setShowDialogExport( true );
    };

    const toggleShowDialogExport = () => setShowDialogExport( !showDialogExport );

    const filtersExportation = useMemo( () => {
        const { initialDate, finalDate, sourcesId, users: usersId } = filters;

        const status = get( opportunitiesParams, "status" );
        let dateParams;

        if ( !status ) {
            dateParams = {
                initialCreatedAt: initialDate,
                finalCreatedAt: finalDate,
            };
        } else if ( status === "SOLD" ) {
            dateParams = {
                initialSoldAt: initialDate,
                finalSoldAt: finalDate,
            };
        } else if ( status === "LOST" ) {
            dateParams = {
                initialLostAt: initialDate,
                finalLostAt: finalDate,
            };
        }

        return {
            sourcesId,
            usersId,
            funnelId: opportunitiesDialogSelectedFunnel,
            status,
            ...dateParams
        };
    }, [ filters, opportunitiesParams, opportunitiesDialogSelectedFunnel ] );

    useEffect( () => {
        dispatch( funnelActions.fetchFunnels() );
        dispatch( userActions.fetchUsers() );
        dispatch( sourceActions.fetchSources() );
        dispatch( teamActions.fetchTeams() );
        dispatch( opportunityActions.fetchSchedulingTypes() );
    }, [ dispatch ] );
    useEffect( () => {
        searchInfo();
    }, [ searchInfo ] );
    useEffect( () => {
        if ( isEmpty( prevFunnelsId ) && !isEmpty( funnelsId ) && isEmpty( opportunitiesStatistics ) ) {
            setFilters({ ...filters, funnelsId });
        }
    }, [ prevFunnelsId, funnelsId, opportunitiesStatistics, filters ] );
    useEffect( () => {
        if ( prevLoadingFilters && !loadingFilters && isEmpty( filters.users ) ) {
            setFilters({ ...filters, users: activeUsers });
        }
    }, [ activeUsers, prevLoadingFilters, loadingFilters, filters ] );
    useEffect( () => {
        return () => {
            dispatch( opportunityActions.receiveOpportunitiesStatistics() );
        };
    }, [ dispatch ] );

    return (
        <div className={ classnames( className, css.container ) } >
            <Helmet title={ t( "dashboard:title" ) } />

            <OpportunitiesDialog
                open={ openOpportunitiesDialog }
                onClose= { closeOpportunities }
                title={ dialogOpportunities }
                params={ opportunitiesParams }
                funnelsId={ filters.funnelsId }
                onActionClick={ handleExportOpportunities }
                onChangeFunnel={ setOpportunitiesDialogSelectedFunnel }
                showExportButton
            />
            <ExportationForm
                open={ showDialogExport }
                onClose={ toggleShowDialogExport }
                filters={ filtersExportation }
            />
            <Card id="filters" className={ css.containerFilters }>
                {
                    loadingFilters ?
                        <LinearProgress /> :
                        <CardContent
                            className={ classnames( css.contentFilters, flex.container, flex.alignItemsCenter ) }
                        >
                            <DashboardFilters onSubmit={ handleSubmitFilters } initialValues={ filters } />
                        </CardContent>
                }
            </Card>

            {
                loading &&
                    <div className={ classnames( cssUtils.textCenter, cssUtils.paddingSmall ) }>
                        <CircularProgress/>
                    </div>
            }
            {
                !loading && opportunitiesStatistics &&
                    <>
                        <div id="kpis" className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunitiesKpis
                                className={ flex.fill }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                users={ filters.users }
                                loadOpportunities={ loadOpportunities }
                                statistics={ opportunitiesStatistics }
                            />
                        </div>
                        <div className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunitiesBySource
                                className={ flex.fill }
                                statistics={ opportunitiesStatistics }
                                filterSources={ filters.sourcesId }
                                onClickItem={ loadOpportunities }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                            />
                        </div>
                        <div className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunitiesCreatedChart
                                className={ flex.fill }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                statistics={ opportunitiesStatistics }
                                onClickItem={ loadOpportunities }
                                users={ filters.users }
                            />
                            <LossReasonsChart
                                className={ classnames( flex.item30, cssUtils.marginLeftSmall ) }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                users={ filters.users }
                                onClickItem={ loadOpportunities }
                                statistics={ opportunitiesStatistics }
                            />
                        </div>
                        <div className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunitiesTemperatureChart
                                className={ flex.item20 }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                statistics={ opportunitiesStatistics }
                                onClickItem={ loadOpportunities }
                                users={ filters.users }
                            />
                            <OpportunitiesSalesForecastChart
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                statistics={ opportunitiesStatistics }
                                onClickItem={ loadOpportunities }
                                users={ filters.users }
                            />
                        </div>
                        <div className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunityCheckinChart
                                className={ flex.fill }
                                initialDate={ filters.initialDate }
                                finalDate={ filters.finalDate }
                                statistics={ opportunitiesStatistics }
                                users={ filters.users }
                            />
                        </div>
                        <div className={ classnames( cssUtils.marginTopSmall, flex.container ) }>
                            <OpportunitySchedulingTypesChart
                                className={ flex.fill }
                                statistics={ opportunitiesStatistics }
                            />
                        </div>
                    </>
            }
            <DashboardPrintChart/>
        </div>
    );
};

export default Dashboard;
