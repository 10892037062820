import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../form";
import { AutomationForm, validateForm } from ".";
import { saveAutomation } from "./automation.actions";
import { createEditAutomationForm } from "./automation.utils";

const EditAutomation = ({ automation }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const personFields = useSelector( formSelectors.list( "PERSON" ) );
    const companyFields = useSelector( formSelectors.list( "COMPANY" ) );
    const opportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );

    const [ show, setShow ] = useState( false );

    const toggleShow = () => setShow( !show );

    const integrationChannel = automation.integrationChannel;

    const customerFields = useMemo( () => {
        return integrationChannel === "EXACT_SALES" ? companyFields : personFields;
    }, [ companyFields, integrationChannel, personFields ] );

    const formik = useForm({
        form: createEditAutomationForm( automation.token ),
        enableReinitialize: true,
        initialValues: {
            ...automation,
            mappingFields: {
                ...customerFields.reduce( ( model, field ) => {
                    model[ field.id ] = automation.mappingFields[ field.id ] || [];
                    return model;
                }, {} ),
                ...opportunityFields.reduce( ( model, field ) => {
                    model[ field.id ] = automation.mappingFields[ field.id ] || [];
                    return model;
                }, {} )
            },
        },
        onSubmit: values => dispatch( saveAutomation( values ) ),
        onSubmitSuccess: () => setShow( false ),
        validate: values => validateForm( values, { customerFields, integrationChannel } ),
    });

    const translateIntegrationChannel = integrationChannel &&
        t( `marketplace:automation.integration-channel.${integrationChannel}` );

    return (
        <>
            <Dialog open={ show } fullWidth maxWidth="md">
                <DialogTitle>
                    { t( "marketplace:automation.edit.title" ) }
                    { integrationChannel && ( " - " + translateIntegrationChannel ) }
                </DialogTitle>
                <DialogContent>
                    <AutomationForm formik={ formik } integrationChannel={ integrationChannel } />
                </DialogContent>
                <DialogActions>
                    { formik.error && <Typography color="error">{ formik.error }</Typography> }
                    <Button color="primary" onClick={ toggleShow } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={ toggleShow } size="large">
                <EditIcon/>
            </IconButton>
        </>
    );
};

export default EditAutomation;