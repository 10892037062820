import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";

const DateChange = ({ change }) => {
    const t = useTranslate();

    return (
        <Typography>
            {
                change.previous
                &&
                <mark className={ cssUtils.highlightOld }>
                    { dayjs( change.previous).format( t( "common:date" ) ) }
                </mark>
            }
            { change.previous && "→ " }
            <mark className={ cssUtils.highlightNew }>{ dayjs( change.current).format( t( "common:date" ) ) }</mark>
        </Typography>
    );
};

export default DateChange;