import { useDispatch } from "react-redux";
import { Form, useForm } from "formik-redux";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import useTranslate from "../../../hooks/use-translate.hook";
import { saveApiKey } from "./api-key.actions";
import { createEditApiKeyForm } from "./api-key.utils";
import ApiKeyForm, { validate } from "./api-key-form.component";

const EditApiKey = ({ apiKey }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ open, setOpen ] = useState( false );

    const toggleOpen = () => setOpen( !open );

    const formik = useForm({
        form: createEditApiKeyForm( apiKey.id ),
        initialValues: apiKey,
        onSubmit: values => dispatch( saveApiKey( values ) ),
        onSubmitSuccess: toggleOpen,
        validate,
    });

    return (
        <>
            <Dialog open={ open } fullWidth>
                <DialogTitle>
                    { t( "marketplace:integrations.new-api-key.title" ) }
                </DialogTitle>
                <DialogContent>
                    <Form formik={ formik }>
                        <ApiKeyForm form={ formik } />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleOpen } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={ toggleOpen } size="large">
                <EditIcon/>
            </IconButton>
        </>
    );
};

export default EditApiKey;