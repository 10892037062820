import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors, validateFields } from "../../form";
import { cssUtils, Dialog, Input } from "../../ui";
import { getPersons as getCompanyPersons } from "../../customer/customer.selectors";
import CustomerWizard from "../../customer/customer-wizard.component";
import customerCss from "../../customer/person/person.scss";
import { savePerson } from "../opportunity.actions";
import { ADD_OTHER_PERSON_FORM } from "../opportunity.constants";
import { getPersons, getSelected } from "../opportunity.selectors";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const AddPerson = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "PERSON" ) );
    const persons = useSelector( getPersons );
    const opportunity = useSelector( getSelected );
    const companyPersons = useSelector( getCompanyPersons );

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const initialValues = useMemo( () => ({
        opportunityId: opportunity.id,
        person: {
            id: "",
            type: "PERSON",
            fields: fieldsToInitialValues( fields )
        },
        role: ""
    }), [ fields, opportunity.id ] );

    const formik = useForm({
        form: ADD_OTHER_PERSON_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( savePerson( values ) ),
        onSubmitSuccess: () => setOpen( false ),
        validate: values => {
            const errors = {};

            const person = get( values, "person", {} );
            if ( person.new ) {
                errors.person = validateFields( person, { t, fields } );
            } else if ( !person.id ) {
                errors.person = {
                    id: "common:validation.selection"
                };
            }

            if ( errors.person && isEmpty( errors.person ) ) {
                delete errors.person;
            }

            if ( !values.role ) {
                errors.role = "common:validation.required";
            }

            return errors;
        }
    });

    const handleResetForm = () => formik.setFieldValue( "person.fields", initialValues.person.fields );

    const suggestions = useMemo( () => ( companyPersons || [] )
        .filter( person => !opportunity || !opportunity.person || person.id !== opportunity.person.id )
        .filter( person => !persons?.find( opportunityPerson => opportunityPerson.person.id === person.id ) )
    , [ companyPersons, opportunity, persons ] );

    return (
        <>
            <Tooltip title={ t( "opportunity:persons.add" ) }>
                <IconButton onClick={ toggleOpen } size="large">
                    <AddIcon/>
                </IconButton>
            </Tooltip>

            <Dialog open={ open } fullWidth maxWidth="sm">
                <DialogTitle className={ customerCss.titleAddPerson }>
                    { t( "opportunity:persons.add" ) }
                </DialogTitle>
                <DialogContent className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRight0 )}>
                    <Form formik={ formik }>
                        <CustomerWizard
                            name="person"
                            type="PERSON"
                            resetForm={ handleResetForm }
                            setFieldValue={ formik.setFieldValue }
                            suggestions={ suggestions }
                            suggestionsTitle={ t( "customer:person.company-persons" ) }
                        />
                        <div className={ classnames( cssUtils.marginLeft, cssUtils.marginRight )}>
                            <Divider
                                className={ classnames( cssUtils.marginBottomSmall, cssUtils.marginTopSmall )}
                            />
                            <Field
                                name="role"
                                label={ t( "opportunity:persons.role" ) }
                                component={ Input }
                                required
                            />
                        </div>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={ formik.submitting } onClick={ toggleOpen }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button
                        color="primary"
                        disabled={ formik.submitting }
                        onClick={ formik.submitForm }
                    >
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddPerson;