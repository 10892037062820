import { useDispatch, useSelector } from "react-redux";
import throttle from "lodash/throttle";

import { Autocomplete } from "../ui";
import { fetchTags } from "./customer.actions";
import { isLoadingTags, getTags } from "./customer.selectors";

const TagsField = ({ field, ...props }) => {
    const dispatch = useDispatch();

    const loading = useSelector( isLoadingTags );
    const tags = useSelector( getTags );

    const searchTags = tag => {
        if ( !tag || loading ) {
            return;
        }
        dispatch( fetchTags( tag ) );
    };
    const search = throttle( searchTags, 200, {
        leading: false
    });

    const handleChange = event => {
        const value = event.target.value;
        field.onChange({
            ...event,
            target: { ...event.target, value: value ? value.map( tag => tag.toLowerCase() ) : value }
        });
    };

    return (
        <Autocomplete
            allowCreate
            source={ tags }
            onQueryChange={ search }
            keepFocusOnChange
            field={{ ...field, onChange: handleChange }}
            { ...props }
        />
    );
};

export default TagsField;