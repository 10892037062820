import { startSubmit as startSubmitFormik, stopSubmit as stopSubmitFormik } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as mailtopActions } from "../../../mailtop";
import * as actions from "./mailtop.actions";
import {
    MAILTOP_ADD_LIST_FORM,
    MAILTOP_DELETE_LIST_FORM,
    MAILTOP_EDIT_LIST_FORM,
} from "./mailtop.constants";

export function *watchAutomationMailtop () {
    yield all([
        takeEvery( actions.SAVE_MAILTOP_TOKEN, saveMailtopToken ),
        takeEvery( actions.ADD_MAILTOP_LIST, addMailtopList ),
        takeEvery( actions.DELETE_MAILTOP_LIST, deleteMailtopList ),
        takeEvery( actions.EDIT_MAILTOP_LIST, editMailtopList ),
        takeEvery( actions.EXECUTE_MAILTOP_LIST, executeMailtopList )
    ]);
}

export function *saveMailtopToken ({ meta: { form }, payload }) {
    yield put( startSubmitFormik( form ) );
    try {
        yield call( apiv1.put, "/mailtop/integration", payload );
        yield put( mailtopActions.fetchToken() );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data.error ) );
    }
}

export function *addMailtopList ({ payload }) {
    yield put( startSubmitFormik( MAILTOP_ADD_LIST_FORM ) );
    try {
        yield call( apiv1.post, "/mailtop/integration/lists", payload );
        yield put( mailtopActions.fetchToken() );
        yield put( stopSubmitFormik( MAILTOP_ADD_LIST_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( MAILTOP_ADD_LIST_FORM, e.response.data.error ) );
    }
}

export function *editMailtopList ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( MAILTOP_EDIT_LIST_FORM ) );
    try {
        yield call( apiv1.patch, `/mailtop/integration/lists/${id}`, payload );
        yield put( mailtopActions.fetchToken() );
        yield put( stopSubmitFormik( MAILTOP_EDIT_LIST_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( MAILTOP_EDIT_LIST_FORM, e.response.data.error ) );
    }
}

export function *deleteMailtopList ({ meta: { id } }) {
    yield put( startSubmitFormik( MAILTOP_DELETE_LIST_FORM ) );
    try {
        yield call( apiv1.delete, `/mailtop/integration/lists/${id}` );
        yield put( mailtopActions.fetchToken() );
        yield put( stopSubmitFormik( MAILTOP_DELETE_LIST_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( MAILTOP_DELETE_LIST_FORM, e.response.data.error ) );
    }
}

export function *executeMailtopList ({ meta: { id } }) {
    try {
        yield call( apiv1.post, `/mailtop/integration/lists/${id}` );
        yield put( mailtopActions.fetchToken() );
    } catch ( e ) {
        // ata
    }
}