export const FETCH_GOALS = "x/FETCH_GOALS";
export const fetchGoals = () => ({
    type: FETCH_GOALS
});

export const REQUEST_GOALS = "x/REQUEST_GOALS";
export const requestGoals = () => ({
    type: REQUEST_GOALS
});

export const RECEIVE_GOALS = "x/RECEIVE_GOALS";
export const receiveGoals = goals => ({
    type: RECEIVE_GOALS,
    payload: goals
});

export const ERROR_GOALS = "x/ERROR_GOALS";
export const errorGoals = error => ({
    type: ERROR_GOALS,
    payload: error,
    error: true
});

export const FETCH_GOALS_PROGRESS = "x/FETCH_GOALS_PROGRESS";
export const fetchGoalsProgress = params => ({
    type: FETCH_GOALS_PROGRESS,
    meta: { params },
});

export const REQUEST_GOALS_PROGRESS = "x/REQUEST_GOALS_PROGRESS";
export const requestGoalProgress = () => ({
    type: REQUEST_GOALS_PROGRESS,
});

export const RECEIVE_GOALS_PROGRESS = "x/RECEIVE_GOALS_PROGRESS";
export const receiveGoalsProgress = data => ({
    type: RECEIVE_GOALS_PROGRESS,
    payload: data,
});

export const ERROR_GOALS_PROGRESS = "x/ERROR_GOALS_PROGRESS";
export const errorGoalsProgress = error => ({
    type: ERROR_GOALS_PROGRESS,
    payload: error,
    error: true,
});