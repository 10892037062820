import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SmsIcon from "@mui/icons-material/Sms";
import WarningIcon from "@mui/icons-material/Warning";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { fetchForm } from "../form/form.actions";
import { fetchTemplates, fetchImages } from "../template/template.actions";
import { cssUtils, EmptyState } from "../ui";
import WhatsAppIcon from "../ui/icons/whatsapp.component";
import { FloatingAction } from "../ui/floating-action";
import { fetchOpportunity } from "./opportunity.actions";
import { getSelected, getError, isLoadingSelected } from "./opportunity.selectors";
import css from "./opportunity.scss";
import OpportunityHead from "./opportunity-head.component";
import OpportunityCustomerHeader from "./opportunity-customer-head.component";
import OpportunityForm from "./opportunity-form.component";
import OpportunityOverview from "./opportunity-overview.component";
import OpportunitySchedules from "./opportunity-schedules.component";
import OpportunityProposals from "./opportunity-proposals.component";
import OpportunityFiles from "./opportunity-files.component";
import OpportunityDelete from "./opportunity-delete.component";
import {
    CustomerForm,
    NewEmail,
    NewPhoneCall,
    NewSms,
    NewWhatsApp,
    actions as customerActions,
    selectors as customerSelectors
} from "../customer";

const Opportunity = ({ className }) => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const loading = useSelector( isLoadingSelected );
    const error = useSelector( getError );
    const opportunity = useSelector( getSelected );
    const isNewEmailOpen = useSelector( customerSelectors.isNewEmailOpen );

    const root = useRef();

    const [ tab, setTab ] = useState( "overview" );
    const [ showDialogNewPhoneCall, setShowDialogNewPhoneCall ] = useState( false );
    const [ showDialogNewSms, setShowDialogNewSms ] = useState( false );
    const [ showDialogNewWhatsApp, setShowDialogNewWhatsApp ] = useState( false );

    const toggleDialogNewPhoneCall = () => setShowDialogNewPhoneCall( !showDialogNewPhoneCall );
    const toggleDialogNewSms = () => setShowDialogNewSms( !showDialogNewSms );
    const toggleDialogNewWhatsApp = () => setShowDialogNewWhatsApp( !showDialogNewWhatsApp );

    const handleEdit = () => setTab( "form" );
    const handleEditCompany = () => {
        dispatch( customerActions.setCustomer( opportunity.company ) );
        setTab( "company" );
    };
    const handleEditPerson = () => {
        dispatch( customerActions.setCustomer( opportunity.person ) );
        setTab( "person" );
    };

    const startNewEmail = () => dispatch( customerActions.startNewEmail() );

    const getScrollElement = useCallback( () => root.current, [] );

    useEffect( () => {
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchTemplates() );
        dispatch( fetchImages() );
    }, [ dispatch ]);
    useEffect( () => {
        dispatch( fetchOpportunity( params.id ) );
    }, [ dispatch, params.id ] );

    if ( !isEmpty( error ) && error.status !== 404 && error.status !== 422 ) {
        throw new Error( "Opportunity error" );
    }

    if ( !isEmpty( error ) && error.status === 404 ) {
        return <EmptyState icon={ WarningIcon } message={ t( "opportunity:not-found" ) }/>;
    }

    if ( loading || isEmpty( opportunity ) ) {
        return <div className={ cssUtils.margin }><CircularProgress/></div>;
    }

    return (
        <div className={ classnames( className, css.subpanel ) } ref={ root }>
            <Helmet title={ opportunity.title }/>

            <Paper className={ css.tabsContainer }>
                <Tabs
                    id="tabs"
                    className={ css.tabs }
                    onChange={ ( event, value ) => {
                        switch ( value ) {
                            case "company":
                                handleEditCompany();
                                break;
                            case "person":
                                handleEditPerson();
                                break;
                            default:
                                setTab( value );
                                break;
                        }
                    } }
                    variant="fullWidth"
                    value={ tab }
                >
                    <Tab value="overview" label={ t( "opportunity:tabs.overview" ) }/>
                    <Tab value="form" label={ t( "opportunity:tabs.form" ) }/>
                    { !isEmpty( opportunity.company ) &&
                        <Tab value="company" label={ t( "opportunity:tabs.company" ) }/>
                    }
                    { !isEmpty( opportunity.person ) &&
                        <Tab value="person" label={ t( "opportunity:tabs.person" ) }/>
                    }
                    <Tab value="proposals" label={ t( "opportunity:tabs.proposals" ) }/>
                    <Tab value="schedules" label={ t( "opportunity:tabs.schedules" ) }/>
                    <Tab value="files" label={ t( "opportunity:tabs.files" ) }/>
                </Tabs>
                <OpportunityDelete opportunityId={ params.id } className={ css.deleteButton }/>
            </Paper>

            <div className={ css.main }>
                <aside className={ css.aside }>
                    <OpportunityHead onEdit={ handleEdit }/>
                </aside>

                <div className={ css.mainContainer }>
                    {
                        tab === "overview" &&
                            <OpportunityOverview
                                opportunityId={ params.id }
                                getScrollParent={ getScrollElement }
                            />
                    }
                    { tab === "form" &&
                            <OpportunityForm
                                onEdit={ handleEdit }
                            />
                    }
                    { ( tab === "person" || tab === "company" ) && <CustomerForm opportunityKey={ tab }/> }
                    { tab === "proposals" && <OpportunityProposals opportunityId={ params.id }/> }
                    { tab === "schedules" &&
                            <OpportunitySchedules
                                opportunityId={ params.id }
                                onEdit={ handleEdit }
                            />
                    }
                    { tab === "files" &&
                            <OpportunityFiles
                                opportunityId={ params.id }
                                onEdit={ handleEdit }
                            />
                    }
                </div>

                <aside className={ css.aside }>
                    <OpportunityCustomerHeader
                        opportunity={ opportunity }
                        onEditCompany={ handleEditCompany }
                        onEditPerson={ handleEditPerson }
                    />
                </aside>
                {
                    tab === "overview" &&
                        <>
                            <NewEmail show={ isNewEmailOpen }/>
                            <NewPhoneCall
                                show={ showDialogNewPhoneCall }
                                onClose={ toggleDialogNewPhoneCall }
                            />
                            <NewWhatsApp
                                show={ showDialogNewWhatsApp }
                                onClose={ toggleDialogNewWhatsApp }
                            />
                            <NewSms show={ showDialogNewSms } onClose={ toggleDialogNewSms } />

                            <FloatingAction ariaLabel="opportunity">
                                <SpeedDialAction
                                    icon={ <WhatsAppIcon className={ css.floatingIconWhatsapp }/> }
                                    onClick={ toggleDialogNewWhatsApp }
                                    tooltipTitle={ t( "opportunity:floating-actions.whatsapp" ) }
                                />
                                <SpeedDialAction
                                    icon={ <SmsIcon/> }
                                    onClick={ toggleDialogNewSms }
                                    tooltipTitle={ t( "opportunity:floating-actions.sms" ) }
                                />
                                <SpeedDialAction
                                    icon={ <PhoneIcon/> }
                                    onClick={ toggleDialogNewPhoneCall }
                                    tooltipTitle={ t( "opportunity:floating-actions.phone-call" ) }
                                />
                                <SpeedDialAction
                                    icon={ <EmailIcon/> }
                                    onClick={ startNewEmail }
                                    tooltipTitle={ t( "opportunity:floating-actions.email" ) }
                                />
                            </FloatingAction>
                        </>
                }
            </div>
        </div>
    );
};

export default Opportunity;