import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../api/sagas";
import * as actions from "./automation.actions";
import { NEW_AUTOMATION_FORM } from "./automation.constants";
import { createEditAutomationForm, createDeleteAutomationForm } from "./automation.utils";
import { watchAutomationMailtop } from "./mailtop/mailtop.sagas";

export function *watchIntegrationsAutomation () {
    yield all([
        takeEvery( actions.FETCH_AUTOMATIONS, fetchAutomations ),
        takeEvery( actions.CREATE_AUTOMATION, createAutomation ),
        takeEvery( actions.SAVE_AUTOMATION, saveAutomation ),
        takeEvery( actions.DELETE_AUTOMATION, deleteAutomation ),
        watchAutomationMailtop()
    ]);
}

export function *fetchAutomations () {
    yield put( actions.requestAutomations() );

    try {
        const response = yield call( apiv1.get, "/automations" );
        yield put( actions.receiveAutomations( response.data ) );
    } catch ( e ) {
        yield put( actions.errorAutomations( e.response.data ) );
    }
}

export function *createAutomation ({ payload }) {
    yield put( startSubmit( NEW_AUTOMATION_FORM ) );

    try {
        yield call( apiv1.post, "/automations", payload );
        yield put( stopSubmit( NEW_AUTOMATION_FORM ) );
        yield put( actions.fetchAutomations() );
    } catch ( e ) {
        yield put( stopSubmit( NEW_AUTOMATION_FORM, e.response.data.error ) );
    }
}

export function *saveAutomation ({ meta: { token }, payload }) {
    const form = createEditAutomationForm( token );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.patch, `/automations/${token}`, payload );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchAutomations() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}

export function *deleteAutomation ({ meta: { token } }) {
    const form = createDeleteAutomationForm( token );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, `/automations/${token}` );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchAutomations() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}
