export const isLate = value => {
    const now = new Date();
    const date = new Date( value );
    if ( now.getDate() === date.getDate()
        && now.getMonth() === date.getMonth()
        && now.getFullYear() === date.getFullYear() ) {

        const time = date.getHours().toString().concat( date.getMinutes() ).concat( date.getSeconds() );
        // Se for o mesmo dia e o horario estiver zerado, nao esta atrasado
        if ( time === "000" ) {
            return false;
        }
    }

    return date < now;
};