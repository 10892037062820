import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import set from "lodash/set";

import useTranslate from "../../../hooks/use-translate.hook";
import ConfigHeader from "../../config-header.component";
import { Switch, flex, cssUtils } from "../../../ui";
import { fetchForm } from "../../../form/form.actions";
import { fetchKanban } from "../../../kanban/kanban.actions";
import { getKanban, isLoadingKanban } from "../../../kanban/kanban.selectors";
import { saveKanban } from "./kanban.actions";
import { KANBAN_FORM } from "./kanban.constants";
import KanbanFields from "./kanban-fields.component";
import OpportunityPreview from "./opportunity-preview.component";

const Kanban = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const kanban = useSelector( getKanban );
    const loading = useSelector( isLoadingKanban );

    const formik = useForm({
        form: KANBAN_FORM,
        enableReinitialize: true,
        initialValues: {
            fields: [],
            ...kanban,
        },
        onSubmit: values => dispatch( saveKanban( values ) ),
        validate: values => {
            const errors = {};
            if ( values.fields?.length > 0 ) {
                values.fields.forEach( ( field, index ) => {
                    if ( !field.fieldId ) {
                        set( errors, `fields[${index}].fieldId`, "common:validation.required" );
                    }
                });
            }
            return errors;
        },
    });

    useEffect( () => {
        dispatch( fetchKanban() );
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    if ( loading || !kanban ) {
        return <CircularProgress />;
    }

    return (
        <div>
            <ConfigHeader title={ t( "config:kanban.title" ) } />
            <Card>
                <Form className={ cssUtils.padding } formik={ formik }>
                    <Typography gutterBottom>{ t( "config:kanban.help" ) }</Typography>
                    <div className={ flex.container }>
                        <div className={ flex.fill }>
                            <Field
                                name="company"
                                type="checkbox"
                                label={ t( "config:kanban.company-name" ) }
                                component={ Switch }
                                disabled={ !formik.values.person }
                            />
                            <Field
                                name="person"
                                type="checkbox"
                                label={ t( "config:kanban.person-name" ) }
                                component={ Switch }
                                disabled={ !formik.values.company }
                            />
                            <Field
                                name="phone"
                                type="checkbox"
                                label={ t( "common:phone" ) }
                                component={ Switch }
                            />
                            <Typography color="textSecondary" variant="caption">
                                { t( "config:kanban.phone-help" ) }
                            </Typography>
                            <Field
                                name="email"
                                type="checkbox"
                                label={ t( "common:email" ) }
                                component={ Switch }
                            />
                            <Typography color="textSecondary" variant="caption">
                                { t( "config:kanban.email-help" ) }
                            </Typography>
                            <Field
                                name="price"
                                type="checkbox"
                                label={ t( "opportunity:price.label" ) }
                                component={ Switch }
                            />
                            <Field
                                name="recurrentPrice"
                                type="checkbox"
                                label={ t( "opportunity:recurrent-price" ) }
                                component={ Switch }
                            />
                            <Field
                                name="source"
                                type="checkbox"
                                label={ t( "opportunity:source" ) }
                                component={ Switch }
                            />
                            <Field
                                name="user"
                                type="checkbox"
                                label={ t( "opportunity:user" ) }
                                component={ Switch }
                            />
                            <Field
                                name="nextScheduling"
                                type="checkbox"
                                label={ t( "opportunity:next-scheduling" ) }
                                component={ Switch }
                            />
                        </div>
                        <div className={ flex.fill }>
                            <OpportunityPreview kanban={ formik.values } />
                        </div>
                    </div>
                    <hr />
                    <FieldArray name="fields" component={ KanbanFields } />
                </Form>
                <CardActions className={ flex.rowReverse }>
                    <Button
                        disabled={ formik.submitting }
                        onClick={ formik.submitForm }
                        variant="contained"
                    >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default Kanban;