import { MaskInput } from "../ui";

/* eslint-disable no-unused-vars */
const SsnField = ({ formField, currentPosition, ...props }) => (
    <MaskInput
        { ...props }
        format="###.###.###-##"
    />
);

export default SsnField;