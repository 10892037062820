import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirm, Input, flex } from "../../../ui";
import { selectors as mailtopSelectors } from "../../../mailtop";
import { MAILTOP_AUTH_TOKEN_FORM } from "./mailtop.constants";
import { saveMailtopToken } from "./mailtop.actions";

const AuthToken = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );

    const [ confirmDelete, setConfirmDelete ] = useState( false );

    const auth = useSelector( mailtopSelectors.getAuth );

    const toggleDelete = () => setConfirmDelete( !confirmDelete );

    const formik = useForm({
        form: MAILTOP_AUTH_TOKEN_FORM,
        enableReinitialize: true,
        initialValues: auth || {},
        onSubmit: values => dispatch( saveMailtopToken( values ) ),
        onSubmitSuccess: () => setConfirmDelete( false ),
    });

    const handleDelete = () => dispatch( saveMailtopToken({ ...auth, token: "" }) );

    return (
        <>
            <Form formik={ formik }>
                <CardHeader
                    subheader={ t( "marketplace:automation.mailtop.auth.title" ) }
                    action={
                        <Button
                            component="a"
                            color="primary"
                            size="small"
                            target="_blank"
                            href="https://cloud.mailtop.app/profile/api"
                        >
                            { t( "marketplace:automation.mailtop.access" ) }
                        </Button>
                    }
                />
                <CardContent>
                    <Field
                        name="token"
                        label={ t( "marketplace:automation.mailtop.auth.token" ) }
                        component={ Input }
                    />
                    { formik.error && <Typography color="error">{ formik.error }</Typography> }
                    {
                        auth && auth.createdBy &&
                            <Typography color="textSecondary" variant="body2">
                                {
                                    t(
                                        "marketplace:automation.mailtop.auth.created",
                                        { createdBy: auth.createdBy.name, date: dateTimeFormat( auth.createdAt ) }
                                    )
                                }
                            </Typography>
                    }
                </CardContent>
                <CardActions className={ flex.justifyContentEnd }>
                    {
                        auth && auth.id &&
                            <Button color="secondary" disabled={ formik.submitting } onClick={ toggleDelete }>
                                { t( "common:exclude" ) }
                            </Button>
                    }
                    <Button color="primary" type="submit" disabled={ formik.submitting } variant="contained">
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Form>

            <DialogConfirm
                disabled={ formik.submitting }
                message={ t( "marketplace:automation.mailtop.auth.confirm-delete" ) }
                open={ confirmDelete }
                onCancel={ toggleDelete }
                onConfirm={ handleDelete }
            />
        </>
    );
};

export default AuthToken;