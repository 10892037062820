import { useState, useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";
import CommissionsTable from "./commissions-table.component";
import ConfigHeader from "../../config-header.component";
import NewCommission from "./new-commission.component";

const Commission = () => {
    const t = useTranslate();
    const [ showNewCommission, setShowNewCommission ] = useState( false );

    const toggleNewCommission = useCallback( () => {
        setShowNewCommission( showNewCommission => !showNewCommission );
    }, [] );

    return (
        <>
            <ConfigHeader
                title={ t( "config:commissions.title" ) }
                actionTitle={ t( "config:commission.new" ) }
                actionIcon={ AddIcon }
                onActionClick={ toggleNewCommission }
            />
            <NewCommission open={ showNewCommission } onClose={ toggleNewCommission }/>
            <div className={ flex.alignItemsStart }>
                <CommissionsTable />
            </div>
        </>
    );
};

export default Commission;