import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import config from "config";
import isEmpty from "lodash/isEmpty";
import isInteger from "lodash/isInteger";
import omit from "lodash/omit";

import useTranslate from "../hooks/use-translate.hook";
import { getThemeMode } from "../core/core.selectors";
import { cssUtils, flex } from "./";
import { selectors as templateSelectors } from "../template";
import { variables } from "./template.utils";
import TemplateSelector from "./template-selector.component";
import css from "./template.scss";

const TemplateEditorEmail = ({
    customer,
    opportunity,
    field,
    form,
    hasRuler,
    setup,
    showVariables = true,
    style,
    height = "450",
    width = "100%",
    templates,
    ...props
}) => {
    const t = useTranslate();

    const themeMode = useSelector( getThemeMode );
    const images = useSelector( templateSelectors.getImages );

    const editor = useRef();
    const [ editorReady, setEditorReady ] = useState( false );
    const [ showImages, setShowImages ] = useState( false );

    const rulerObserver = useRef();

    const toggleImages = () => setShowImages( !showImages );
    const selectImage = image => {
        const value = field.value;
        const newValue = value.concat( `<img src="${image.url}" />` );
        if ( field ) {
            field.onChange({ target: { name: field.name, value: newValue } });
        }
        toggleImages();
    };
    const renderImages = myGallery => {
        const galleryImages = images.filter( image => myGallery === ( !!image.userId ) );

        if ( isEmpty( galleryImages ) ) {
            return (
                <Typography
                    className={ cssUtils.marginLeft }
                    color="textSecondary"
                >
                    { t( "ui:template.empty-images" ) }
                </Typography>
            );
        }

        return galleryImages.map( image => (
            <ImageListItem
                key={ image.id }
                onClick={ () => selectImage( image ) }
                className={ cssUtils.cursorPointer }
            >
                <img
                    src={ image.url }
                    alt={ image.name }
                    className={ classnames( cssUtils.fullWidth, cssUtils.autoHeight ) }
                />
                <ImageListItemBar title={ image.name }/>
            </ImageListItem>
        ));
    };

    const getInnerDoc = () => {
        const iframes = document.getElementsByTagName( "iframe" );

        if ( isEmpty( iframes ) ) {
            return null;
        }

        let iframe;
        for ( let i = 0; i < iframes.length; i++ ) {
            if ( iframes[ i ].id === "templateEditor_ifr" ) {
                iframe = iframes[ i ];
                break;
            }
        }

        return iframe.contentDocument.documentElement || iframe.contentWindow.document;
    };

    const setRuler = useCallback( entry => {
        const innerDoc = getInnerDoc();
        const innerBodyHeight = Math.round( entry[ 0 ].borderBoxSize[ 0 ].blockSize );
        const rulerId = "templateEditor_ruler";

        const elements = innerDoc.getElementsByTagName( "div" );

        let rulerElement = elements[ 0 ]?.id === rulerId ?
            elements[ 0 ] :
            elements[ 1 ]?.id === rulerId ? elements[ 1 ] : null;

        if ( rulerElement ) {
            rulerElement.remove();
        }
        rulerElement = document.createElement( "div" );
        const rulerStyle = (`
            width: 5%;
            position: absolute;
            left: 0.5%;
            overflow: hidden;
            border-left: 3px solid #757575;
        `);
        rulerElement.id = rulerId;
        rulerElement.style.cssText = rulerStyle;
        rulerElement.appendChild( document.createElement( "ul" ) );
        innerDoc.insertBefore( rulerElement, innerDoc.firstChild );
        const createUlElement = innerDoc.getElementsByTagName( "div" )[ 0 ].getElementsByTagName( "ul" )[ 0 ];
        createUlElement.innerHTML = `
            <style>
                .ruler-x {
                    margin: 0;
                    padding: 0;
                    gap: calc(0.5cm - 2.5px);
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                }

                .ruler-x li {
                    display: flex;
                    margin: 0;
                    color: #757575;
                    border-top: 3px solid #757575;
                    width: 70%;
                    justify-content: flex-end;
                    font-size: 10px;
                    line-height: 18px;
                    white-space: pre;
                }
            </style>
        `;
        createUlElement.className = "ruler-x";


        rulerElement.style.maxHeight = innerBodyHeight + "px";

        const getUlElement = rulerElement.getElementsByTagName( "ul" )[ 0 ];

        let documentHeight = 0;
        if ( getUlElement.offsetHeight < innerBodyHeight ) {
            while ( getUlElement.offsetHeight < innerBodyHeight ) {
                getUlElement.appendChild( document.createElement( "li" ) );

                if ( isInteger( documentHeight ) ) {
                    getUlElement.lastChild.innerText = documentHeight + " cm";
                    documentHeight === 30 && ( getUlElement.lastChild.style.marginTop = "-0.5cm" );
                } else if ( documentHeight === 29.5 ) {
                    getUlElement.lastChild.style.width = "35%";
                    getUlElement.lastChild.style.marginTop = "-0.5cm";

                    getUlElement.appendChild( document.createElement( "li" ) );
                    getUlElement.lastChild.appendChild( document.createTextNode( t( "ui:template.end-of-page" ) ) );

                    getUlElement.lastChild.style.width = "auto";
                    getUlElement.lastChild.style.lineHeight = "12px";
                    getUlElement.lastChild.style.justifyContent = "flex-start";
                    getUlElement.lastChild.style.marginTop = "-0.3cm";
                } else {
                    getUlElement.lastChild.style.width = "35%";
                    getUlElement.lastChild.style.marginTop = "-0.5cm";
                }

                documentHeight += 0.5;
            }
        } else {
            while ( getUlElement.offsetHeight > innerBodyHeight ) {
                getUlElement.lastElementChild.remove();
                documentHeight -= 0.5;
            }
        }
    }, [ t ] );

    const handleSetup = ed => {
        editor.current = ed;
        setup && setup( ed );
    };

    const handleFocusIn = () => !editorReady && setEditorReady( true );

    const handleEditorChange = value => form.setFieldValue( field.name, value );

    const validationMsg = useMemo( () => {
        const fieldMeta = form.getFieldMeta( field.name );
        return fieldMeta.touched && fieldMeta.error;
    }, [ field, form ] );

    const contentStyle = useMemo( () => {
        return `p { margin:0; } ${style ? style : ""}`;
    }, [ style ] );

    const showTemplates = useMemo(
        () => props.showTemplates && !isEmpty( templates ),
        [ props.showTemplates, templates ]
    );

    useEffect( () => {
        if ( !editorReady || !hasRuler ) {
            return;
        }

        const innerDoc = getInnerDoc();
        if ( isEmpty( innerDoc ) ) {
            return;
        }

        rulerObserver.current = new ResizeObserver( setRuler );

        const body = innerDoc.getElementsByTagName( "body" )[ 0 ];
        rulerObserver.current?.observe( body );

        return () => rulerObserver.current?.unobserve( body );
    }, [ editorReady, hasRuler, setRuler ] );

    const language = t( "common:locale" ) + "_" + t( "common:country" );

    let toolbar = "undo redo | bold italic underline | forecolor backcolor | " +
        "fontfamily fontsize blocks | " +
        "outdent indent |  numlist bullist | " +
        "table | " +
        "alignleft aligncenter alignright alignjustify | code | " ;
    if ( showTemplates ) {
        toolbar += "usetemplate | ";
    }
    toolbar += "addimage link | ";

    const initProps = {};
    if ( themeMode === "dark" ) {
        initProps.skin = "oxide-dark";
        initProps.content_css = "dark";
    }

    return (
        <>
            <Dialog
                fullWidth
                open={ showImages }
                onClose={ toggleImages }
            >
                {
                    isEmpty( images ) ?
                        <DialogContent>
                            <DialogContentText>{ t( "ui:template.empty-images" ) }</DialogContentText>
                        </DialogContent> :
                        <ImageList cols={ 2 } rowHeight="auto" className={ cssUtils.margin0 }>
                            <ImageListItem key="subheader-general" cols={ 2 }>
                                <ListSubheader component="div">
                                    { t( "ui:template.general-gallery" ) }
                                </ListSubheader>
                            </ImageListItem>
                            { renderImages( false ) }
                            <ImageListItem key="subheader-my" cols={ 2 }>
                                <ListSubheader component="div">
                                    { t( "ui:template.my-gallery" ) }
                                </ListSubheader>
                            </ImageListItem>
                            { renderImages( true ) }
                        </ImageList>
                }
                <DialogActions>
                    <Button color="primary" onClick={ toggleImages }>
                        { t( "common:close" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                showVariables &&
                    <>
                        <CardHeader
                            title={ t( "ui:template.variables.title" ) }
                            subheader={ t( "ui:template.variables.help" ) }
                        />
                        <div className={ flex.container }>
                            {
                                variables.map( item => (
                                    <Button
                                        key={ item.value }
                                        variant="button"
                                        onClick={ () => editor.current.insertContent( item.value ) }
                                        className={ cssUtils.cursorPointer }
                                    >
                                        { t( item.label ) }
                                    </Button>
                                ) )
                            }
                        </div>
                    </>
            }

            {
                showTemplates &&
                    <TemplateSelector
                        customer={ customer }
                        opportunity={ opportunity }
                        templates={ templates }
                        onChange={ handleEditorChange }
                    />
            }

            <div
                className={ classnames(
                    css.root,
                    validationMsg && css.editorEmpty
                )}
            >
                <Editor
                    apiKey={ config.get( "client.tinymceApiKey" ) }
                    init={{
                        menubar: false,
                        branding: false,
                        plugins: "link code table",
                        font_size_formats: "8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt",
                        toolbar,
                        language,
                        language_url: `https://olli-suutari.github.io/tinyMCE-4-translations/${language}.js`,
                        content_style: contentStyle,
                        toolbar_mode: "wrap",
                        setup: editor => {
                            editor.ui.registry.addButton( "addimage", {
                                icon: "image",
                                tooltip: t( "ui:template.add-image" ),
                                onAction: () => toggleImages()
                            });

                            editor.on( "init", function ( ed ) {
                                ed.target.editorCommands.execCommand(
                                    "fontName", false, "verdana, geneva"
                                );
                                ed.target.editorCommands.execCommand( "fontSize", false, "11pt" );
                                ed.target.editorCommands.execCommand( "fontWeight", false, "400" );
                            });

                            handleSetup( editor );
                        },
                        width,
                        height,
                        ...initProps,
                    }}
                    onEditorChange={ handleEditorChange }
                    { ...omit( field, [ "onChange" ] ) }
                    { ...props }
                    onFocusIn={ handleFocusIn }
                />
            </div>
            {
                validationMsg &&
                    <Typography
                        color="error"
                        variant="caption"
                    >
                        { t( validationMsg ) }
                    </Typography>
            }
        </>
    );
};

export default TemplateEditorEmail;