import { createSelector } from "reselect";

export const deleting = state => state.config.opportunity.productConfig.deleting;
export const getDeleteError = state => state.config.opportunity.productConfig.deleteError;

export const getImportations = state => state.config.opportunity.productConfig.importations?.data;
export const isLoadingImportations = state => state.config.opportunity.productConfig.importations?.loading;
export const getSelectedImportation = createSelector(
    state => state.config.opportunity.productConfig.importations,
    importations => importations?.selected
);