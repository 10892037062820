export const FETCH_LEADS = "x/FETCH_LEADS";
export const fetchLeads = params => ({
    type: FETCH_LEADS,
    payload: params
});

export const REQUEST_LEADS = "x/REQUEST_LEADS";
export const requestLeads = () => ({
    type: REQUEST_LEADS
});

export const RECEIVE_LEADS = "x/RECEIVE_LEADS";
export const receiveLeads = data => ({
    type: RECEIVE_LEADS,
    payload: data
});

export const ERROR_LEADS = "x/ERROR_LEADS";
export const errorLeads = error => ({
    type: ERROR_LEADS,
    error: true,
    payload: error
});

export const FETCH_LEAD = "x/FETCH_LEAD";
export const fetchLead = id => ({
    type: FETCH_LEAD,
    meta: { id }
});

export const REQUEST_LEAD = "x/REQUEST_LEAD";
export const requestLead = () => ({
    type: REQUEST_LEAD
});

export const SET_LEAD = "x/SET_LEAD";
export const setLead = data => ({
    type: SET_LEAD,
    payload: data
});

export const ERROR_LEAD = "x/ERROR_LEAD";
export const errorLead = error => ({
    type: ERROR_LEAD,
    error: true,
    payload: error
});

export const SAVE_LEAD_FORM = "x/SAVE_LEAD_FORM";
export const saveLeadForm = data => ({
    type: SAVE_LEAD_FORM,
    payload: data
});

export const CHANGE_LEAD_STATUS = "x/CHANGE_LEAD_STATUS";
export const changeLeadStatus = ({ id, status }) => ({
    type: CHANGE_LEAD_STATUS,
    meta: { id },
    payload: status
});

export const FETCH_LEAD_PORTALS = "x/FETCH_LEAD_PORTALS";
export const fetchLeadPortals = () => ({
    type: FETCH_LEAD_PORTALS
});

export const REQUEST_LEAD_PORTALS = "x/REQUEST_LEAD_PORTALS";
export const requestLeadPortals = () => ({
    type: REQUEST_LEAD_PORTALS
});

export const RECEIVE_LEAD_PORTALS = "x/RECEIVE_LEAD_PORTALS";
export const receiveLeadPortals = leadPortals => ({
    type: RECEIVE_LEAD_PORTALS,
    payload: leadPortals
});

export const ERROR_LEAD_PORTALS = "x/ERROR_LEAD_PORTALS";
export const errorLeadPortals = error => ({
    type: ERROR_LEAD_PORTALS,
    error: true,
    payload: error
});