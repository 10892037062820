import { Route, Routes } from "react-router-dom";

import Users from "./user/users.component";
import Commission from "./commission/commission.component";
import Goal from "./goal/goal.component";

const UsersConfig = () => {
    return (
        <Routes>
            <Route index path="/*" element={ <Users/>} />
            <Route path="commissions" element={ <Commission />} />
            <Route path="goals" element={ <Goal />} />
        </Routes>
    );
};

export default UsersConfig ;