export const TOGGLE_MENU = "x/TOGGLE_MENU";
export const toggleMenu = () => ({
    type: TOGGLE_MENU
});

export const SET_TOP_BAR_TITLE = "x/SET_TOP_BAR_TITLE";
export const setTopBarTitle = title => ({
    type: SET_TOP_BAR_TITLE,
    payload: title
});

export const SET_ACCESS_BLOCKED = "x/SET_ACCESS_BLOCKED";
export const setAccessBlocked = blocked => ({
    type: SET_ACCESS_BLOCKED,
    payload: blocked
});

export const FETCH_TOP_BAR_ITEMS = "x/FETCH_TOP_BAR_ITEMS";
export const fetchTopBarItems = search => ({
    type: FETCH_TOP_BAR_ITEMS,
    payload: { search }
});

export const REQUEST_TOP_BAR_ITEMS = "x/REQUEST_TOP_BAR_ITEMS";
export function requestTopBarItems () {
    return {
        type: REQUEST_TOP_BAR_ITEMS
    };
}

export const RECEIVE_TOP_BAR_ITEMS = "x/RECEIVE_TOP_BAR_ITEMS";
export function receiveTopBarItems ( data ) {
    return {
        type: RECEIVE_TOP_BAR_ITEMS,
        payload: data
    };
}

export const ERROR_TOP_BAR_ITEMS = "x/ERROR_TOP_BAR_ITEMS";
export function errorTopBarItems ( error ) {
    return {
        type: ERROR_TOP_BAR_ITEMS,
        error
    };
}

export const FETCH_NOTIFICATIONS_UNREAD = "x/FETCH_NOTIFICATIONS_UNREAD";
export const fetchNotificationsUnread = () => ({
    type: FETCH_NOTIFICATIONS_UNREAD
});

export const RECEIVE_NOTIFICATIONS_UNREAD = "x/RECEIVE_NOTIFICATIONS_UNREAD";
export const receiveNotificationsUnread = unread => ({
    type: RECEIVE_NOTIFICATIONS_UNREAD,
    payload: unread
});

export const FETCH_NOTIFICATIONS = "x/FETCH_NOTIFICATIONS";
export const fetchNotifications = page => ({
    type: FETCH_NOTIFICATIONS,
    payload: { page }
});

export const REQUEST_NOTIFICATIONS = "x/REQUEST_NOTIFICATIONS";
export const requestNotifications = () => ({
    type: REQUEST_NOTIFICATIONS
});

export const RECEIVE_NOTIFICATIONS = "x/RECEIVE_NOTIFICATIONS";
export const receiveNotifications = notifications => ({
    type: RECEIVE_NOTIFICATIONS,
    payload: notifications
});

export const ERROR_NOTIFICATIONS = "x/ERROR_NOTIFICATIONS";
export const errorNotifications = error => ({
    type: ERROR_NOTIFICATIONS,
    error: true,
    payload: error
});

export const READ_NOTIFICATION = "x/READ_NOTIFICATION";
export const readNotification = notification => ({
    type: READ_NOTIFICATION,
    payload: { notification }
});

export const READ_ALL_NOTIFICATIONS = "x/READ_ALL_NOTIFICATIONS";
export const readAllNotifications = () => ({
    type: READ_ALL_NOTIFICATIONS,
});


export const FETCH_HOLDINGS = "x/FETCH_USER_HOLDINGS";
export const fetchHoldings = () => ({
    type: FETCH_HOLDINGS
});

export const REQUEST_HOLDINS = "x/REQUEST_USER_HOLDINGS";
export const requestHoldings = () => ({
    type: REQUEST_HOLDINS
});

export const RECEIVE_HOLDINGS = "x/RECEIVE_USER_HOLDINGS";
export const receiveHoldings = holdings => ({
    type: RECEIVE_HOLDINGS,
    payload: holdings
});

export const ERROR_HOLDINGS = "x/ERROR_USER_HOLDINGS";
export const errorHoldings = error => ({
    type: ERROR_HOLDINGS,
    payload: error,
    error: true
});

export const LOGIN_HOLDING = "x/LOGIN_HOLDING_SUBSCRIPTION";
export const loginHolding = holding => ({
    type: LOGIN_HOLDING,
    payload: holding
});

export const LOGOUT_HOLDING = "x/LOGOUT_HOLDING_SUBSCRIPTION";
export const logoutHolding = () => ({
    type: LOGOUT_HOLDING
});

export const FETCH_USER_SUBSCRIPTION = "x/FETCH_USER_SUBSCRIPTION";
export const fetchUserSubscription = () => ({
    type: FETCH_USER_SUBSCRIPTION
});

export const REQUEST_USER_SUBSCRIPTION = "x/REQUEST_USER_SUBSCRIPTION";
export const requestUserSubscription = () => ({
    type: REQUEST_USER_SUBSCRIPTION
});

export const RECEIVE_USER_SUBSCRIPTION = "x/RECEIVE_USER_SUBSCRIPTION";
export const receiveUserSubscription = subscription => ({
    type: RECEIVE_USER_SUBSCRIPTION,
    payload: subscription
});

export const ERROR_USER_SUBSCRIPTION = "x/ERROR_USER_SUBSCRIPTION";
export const errorUserSubscription = error => ({
    type: ERROR_USER_SUBSCRIPTION,
    payload: error,
    error: true
});

export const SEND_USER_VERIFICATION = "x/SEND_USER_VERIFICATION";
export const sendUserVerification = () => ({
    type: SEND_USER_VERIFICATION,
});