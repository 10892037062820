import Typography from "@mui/material/Typography";
import differenceBy from "lodash/differenceBy";

import useTranslate from "../../hooks/use-translate.hook";
import { phoneFormatInternational } from "../../core/formatter.utils";
import { cssUtils } from "../../ui";
import css from "./timeline.scss";

const mapChangedItem = ( from, to, { label, className, t }) => {
    const phoneKey = phone => phone.number + phone.type;
    const items = differenceBy( from, to, phoneKey );

    return !items.length ? null : items.map( ( phone, index ) => (
        <li key={ index }>
            <Typography>
                <span className={ css.phoneChangeLabel }>{ t( label ) + " " }</span>
                <mark className={ className }>
                    { phoneFormatInternational( phone.number ) }
                </mark>
            </Typography>
        </li>
    ));
};

const PhoneChangeList = ({ change }) => {
    const t = useTranslate();

    const previous = mapChangedItem( change.previous, change.current, {
        t,
        label: "timeline:change.removed",
        className: cssUtils.highlightOld
    });
    const current = mapChangedItem( change.current, change.previous, {
        t,
        label: "timeline:change.added",
        className: cssUtils.highlightNew
    });

    return <ul className={ css.phoneChangeList }>{ previous }{ current }</ul>;
};

export default PhoneChangeList;