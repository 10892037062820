import { combineReducers } from "redux";

import { users } from "./users/users.reducers";
import { subscription } from "./subscription/subscription.reducers";
import { opportunity } from "./opportunity/opportunity.reducers";
import { advanced } from "./advanced/advanced.reducers";

export const config = combineReducers({
    users,
    subscription,
    opportunity,
    advanced
});