import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { selectors as formSelectors } from "../form";
import * as formActions from "../form/form.actions";
import { fetchLead, setLead } from "./web-capture.actions";
import { isLoadingLead, getLead } from "./web-capture.selectors";
import { cssUtils, flex } from "../ui";
import LeadInfo from "./lead-info.component";
import LeadForm from "./lead-form.component";

const Lead = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const loadingLead = useSelector( isLoadingLead );
    const loadingPersonForm = useSelector( formSelectors.isLoading( "PERSON" ) );
    const loadingCompanyForm = useSelector( formSelectors.isLoading( "OPPORTUNITY" ) );
    const lead = useSelector( getLead );

    const loading = loadingLead || loadingPersonForm || loadingCompanyForm || !lead;

    useEffect( () => {
        dispatch( fetchLead( params.id ) );
    }, [ dispatch, params.id ] );
    useEffect( () => {
        dispatch( formActions.fetchForm( "PERSON" ) );
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );

        return () => {
            dispatch( setLead() );
        };
    }, [ dispatch ] );

    if ( loading ) {
        return (
            <div className={ classnames( cssUtils.textCenter, cssUtils.paddingSmall ) }>
                <CircularProgress/>
            </div>
        );
    }

    return (
        <div className={ classnames( cssUtils.fullHeight, cssUtils.padding, flex.container ) }>
            <Helmet title={ t( "web-capture:title" ) }/>

            <LeadInfo
                className={
                    classnames( cssUtils.marginRightSmall, flex.fill, cssUtils.boxShadow )
                }
            />
            <LeadForm
                className={
                    classnames( cssUtils.marginLeftSmall, flex.fill )
                }
            />
        </div>
    );
};

export default Lead;