import { useCallback } from "react";
import isEmpty from "lodash/isEmpty";

import { phoneFormat } from "../core/formatter.utils";
import { useDateFormat } from "./use-date-format.hook";
import { useEinFormat, useSsnFormat } from "./use-document-format.hook";
import usePriceFormat from "./use-price-format.hook";

export const useFieldValueFormat = t => {
    const dateFormat = useDateFormat( t );
    const einFormat = useEinFormat( t );
    const ssnFormat = useSsnFormat( t );
    const priceFormat = usePriceFormat();

    const fieldFormat = useCallback( ( field, value ) => {
        if ( value === null || value === undefined ) {
            return value;
        }

        switch ( field.systemField ) {
            case "EIN":
                return einFormat( value );
            case "SSN":
                return ssnFormat( value );
            case "BRAND":
            case "MODEL":
                return value;
            default:
                break;
        }

        switch ( field.type ) {
            case "ADDRESS":
                if ( Array.isArray( value ) ) {
                    return value.map( address => address.name ).join( " | " );
                }
                return value.name;
            case "DATE":
                return dateFormat( value );
            case "SELECT":
                const itemValue = field.options.values
                    .find( item => item.value ? item.value === value : item === value );
                return itemValue ? ( itemValue.label ? itemValue.label : itemValue ) : "" ;
            case "EMAIL":
                if ( Array.isArray( value ) ) {
                    return value.map( email => email ).join( ", " );
                }
                return value;
            case "PHONE":
                if ( Array.isArray( value ) ) {
                    return value.map( phone => phoneFormat( phone ) ).join( ", " );
                }
                return phoneFormat( value );
            case "PRICE":
                return priceFormat( value );
            case "SELECT_MULTIPLE":
                if ( Array.isArray( value ) ) {
                    return value.map( item => item ).join( ", " );
                }
                return value;
            default:
                return value;
        }
    }, [ dateFormat, einFormat, ssnFormat, priceFormat ] );

    return fieldFormat;
};

export const useFieldFilterFormat = t => {
    const dateFormat = useDateFormat( t );

    const filterFormat = useCallback( ( field, operator, value ) => {
        const getValue = value => {
            if ( field.systemField === "MODEL" ) {
                return value.model;
            }
            switch ( field.type ) {
                case "DATE":
                    return dateFormat( value );
                case "SELECT":
                    if ( isEmpty( field.options.values ) || !field.options.values[ 0 ].value ) {
                        return value;
                    }

                    const item = field.options.values.find( item => item.value.toString() === value );
                    return item ? item.label : value;
                default:
                    return value;
            }
        };

        switch ( operator ) {
            case "BETWEEN":
                return field.name + ": " + getValue( value.initial ) + " - " + getValue( value.final );
            case "LIKE":
            case "NOT_LIKE":
                return field.name + ": %" + getValue( value ) + "%";
            case "EQUAL":
            case "NOT_EQUAL":
                return field.name + ": " + getValue( value );
            default:
                return field.name + ": " + t( "form:user-filters.operator." + operator );
        }
    }, [ t, dateFormat ] );

    return filterFormat;
};
