import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import MUITableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import css from "./table.scss";
import { cssUtils, flex } from "../";

const TableHead = ({
    model = {},
    onSelectAll,
    allRowsSelected,
    selectable,
    allowOrder,
    orderBy,
    orderType,
    onChangeOrder
}) => {
    const t = useTranslate();

    return (
        <MUITableHead>
            <TableRow>
                {
                    selectable ?
                        <TableCell className={ classnames(css.selectable, css.checkbox) }>
                            {
                                onSelectAll ?
                                    <Tooltip title={ t("ui:table.select-all-tooltip") }>
                                        <Checkbox checked={ allRowsSelected } onChange={ onSelectAll } />
                                    </Tooltip>
                                    : ""
                            }
                        </TableCell>
                        : null
                }
                {

                    Object.keys( model ).map( key => {
                        const allowOrderColumn = allowOrder && !model[ key ].disableOrder;
                        return (
                            <TableCell
                                key={ key }
                                onClick={ () => allowOrderColumn && onChangeOrder( model[ key ].value || key ) }
                                className={ classnames(
                                    allowOrderColumn ? cssUtils.cursorPointer : "",
                                    { [ css.small ]: model[ key ].size === "small" }
                                )}
                            >
                                <div className={ classnames( flex.container, flex.alignItemsCenter ) } >
                                    {
                                        allowOrderColumn ?
                                            orderBy === ( model[ key ].value ?
                                                model[ key ].value.toString()
                                                : key ) ?
                                                orderType === "ASC" ?
                                                    <ArrowDownwardIcon className={ css.arrowOrder }/> :
                                                    <ArrowUpwardIcon className={ css.arrowOrder }/>
                                                : ""
                                            : ""
                                    }
                                    { model[ key ].title || model[ key ] }
                                </div>
                            </TableCell>
                        );
                    })
                }
            </TableRow>
        </MUITableHead>
    );
};

export default TableHead;