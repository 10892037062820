export const SAVE_MAILTOP_TOKEN = "x/marketplace/SAVE_MAILTOP_TOKEN";
export const saveMailtopToken = form => data => ({
    type: SAVE_MAILTOP_TOKEN,
    meta: { form },
    payload: data
});

export const ADD_MAILTOP_LIST = "x/marketplace/ADD_MAILTOP_LIST";
export const addMailtopList = list => ({
    type: ADD_MAILTOP_LIST,
    payload: list
});

export const DELETE_MAILTOP_LIST = "x/marketplace/DELETE_MAILTOP_LIST";
export const deleteMailtopList = ({ id }) => ({
    type: DELETE_MAILTOP_LIST,
    meta: { id }
});

export const EDIT_MAILTOP_LIST = "x/marketplace/EDIT_MAILTOP_LIST";
export const editMailtopList = ({ id, ...data }) => ({
    type: EDIT_MAILTOP_LIST,
    meta: { id },
    payload: data
});

export const EXECUTE_MAILTOP_LIST = "x/marketplace/EXECUTE_MAILTOP_LIST";
export const executeMailtopList = id => ({
    type: EXECUTE_MAILTOP_LIST,
    meta: { id }
});