import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import * as XLSX from "xlsx";

import useTranslate from "../../../hooks/use-translate.hook";
import { Checkbox, Dropdown, InputFile, cssUtils } from "../../../ui";
import { importProducts } from "./product.actions";
import { IMPORTATION_PRODUCTS_FORM } from "./product.constants";

const ImportationProducts = ({ onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const readFile = file => new Promise( resolve => {
        if ( !file ) {
            resolve();
        }
        const extension = file.name.substring( file.name.lastIndexOf( "." ) + 1 );

        const reader = new FileReader();
        reader.onload = event => {
            if ( !event.target.result ) {
                return;
            }

            const result = event.target.result;
            const wb = XLSX.read( result, { type: "binary" } );
            const wsname = wb.SheetNames[ 0 ];
            const ws = wb.Sheets[ wsname ];
            const lines = XLSX.utils.sheet_to_row_object_array(
                ws,
                { header: 1, defval: "", raw: false, blankrows: false }
            );

            resolve( lines );
        };

        if ( extension === "csv" ) {
            reader.readAsText( file );
        } else {
            reader.readAsBinaryString( file );
        }
    } );

    const formik = useForm({
        form: IMPORTATION_PRODUCTS_FORM,
        initialValues: {
            recurrent: false,
            measurementUnit: null,
            file: null,
        },
        onSubmit: values => dispatch( importProducts( values ) ),
        onSubmitSuccess: onClose,
        validate: async values => {
            return readFile( values.file ).then( fileLines => {
                const errors = {};
                if ( !values.measurementUnit ) {
                    errors.measurementUnit = "common:validation.selection";
                }
                if ( !fileLines ) {
                    errors.file = "common:validation.selection";
                } else if ( fileLines.length > 300 ) {
                    errors.file = "config:products.importation.file-exceed-lines";
                }
                return errors;
            });
        }
    });

    return (
        <Dialog open={ show }>
            <DialogTitle>{ t( "config:products.add.import" ) }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <DialogContentText className={ cssUtils.marginBottom }>
                        { t( "config:products.importation.call-to-action" ) }
                    </DialogContentText>
                    <Field
                        name="measurementUnit"
                        label={ t( "config:product.measurementUnit.label" ) }
                        component={ Dropdown }
                        source={
                            [ "UNIT", "METER", "HOUR", "KILO", "LITER" ].map( value => (
                                { value, label: t( `config:product.measurementUnit.${value}` )}
                            ))
                        }
                        required
                    />
                    <Field
                        name="recurrent"
                        type="checkbox"
                        component={ Checkbox }
                        className={ cssUtils.marginBottom }
                    >
                        { t( "config:product.recurrent" ) }
                    </Field>
                    <Field
                        name="file"
                        accept=".csv,.xls,.xlsx"
                        component={ InputFile }
                        required
                    />
                    <Typography variant="subtitle2">
                        { t( "config:products.importation.helper-file" ) }
                    </Typography>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button disabled={ formik.submitting } color="primary" onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button disabled={ formik.submitting } color="primary" onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportationProducts;