import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import { DeleteItem } from "../../../ui";
import { deletePortal } from "./portal-integration.actions";
import { createDeletePortalForm } from "./portal-integration.utils";

const DeletePortal = ({ portal }) => {
    const dispatch = useDispatch();

    const formik = useForm({
        form: createDeletePortalForm( portal.token ),
        initialValues: {
            token: portal.token,
        },
        onSubmit: values => dispatch( deletePortal( values ) ),
    });

    return (
        <DeleteItem
            error={ formik.error }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeletePortal;