import { startSubmit, stopSubmit } from "formik-redux";
import { call, put, takeEvery } from "redux-saga/effects";

import history from "../../history";
import { SIGNUP_FORM, createSubmit, actions } from "./";
import { createSubmit as createSubmitLogin, actions as loginActions } from "../login";
import tracker from "../util/tracker.utils";

export function *signupSubmit ( action ) {
    yield put( startSubmit( SIGNUP_FORM ) );
    try {
        yield call( createSubmit, action.payload );

        yield call( createSubmitLogin, action.payload.user );
        yield put( loginActions.setAuth( true ) );

        tracker.logAction( "Sign Up" );
        yield put( stopSubmit( SIGNUP_FORM ) );
        yield call( [ history, history.push ], "/welcome?first=true" );
    } catch ( e ) {
        yield put( stopSubmit( SIGNUP_FORM, e ) );
    }
}

export function *watchSignupSubmit () {
    yield takeEvery( actions.SIGNUP_SUBMIT, signupSubmit );
}