import { useMemo, useState } from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";

import useTranslate from "../../hooks/use-translate.hook";

const SchedulingSocialOptions = ({ scheduling }) => {
    const t = useTranslate();

    const [ menuAnchorEl, setMenuAnchorEl ] = useState();

    const openMenu = event => setMenuAnchorEl( event.currentTarget );
    const closeMenu = () => setMenuAnchorEl( null );

    const openSocialLink = () => {
        window.open( scheduling.social.data.htmlLink, "_blank" );
        closeMenu();
    };

    const openSocialConference = () => {
        window.open(
            `https://meet.google.com/${scheduling.social.data.conferenceId}`,
            "_blank"
        );
        closeMenu();
    };

    const copySocialConferenceLink = async () => {
        await navigator.clipboard.writeText( `https://meet.google.com/${scheduling.social.data.conferenceId}` );
        closeMenu();
    };

    const hasConference = useMemo(
        () => scheduling.social.calendar.account.provider === "GOOGLE" && scheduling.social.data.conferenceId,
        [ scheduling ] );

    return (
        <>
            <IconButton onClick={ openMenu } size="large">
                <MoreVertIcon/>
            </IconButton>
            <Menu open={ !!menuAnchorEl } onClose={ closeMenu } anchorEl={ menuAnchorEl }>
                <MenuItem onClick={ openSocialLink }>
                    <ListItemIcon>
                        <OpenInNewIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "schedule:event.social.open" ) }/>
                </MenuItem>
                {
                    hasConference &&
                        <>
                            <Divider/>
                            <MenuItem onClick={ openSocialConference }>
                                <ListItemIcon>
                                    <VoiceChatIcon/>
                                </ListItemIcon>
                                <ListItemText primary={ t( "schedule:event.social.google-meet.enter" ) }/>
                            </MenuItem>
                            <MenuItem onClick={ copySocialConferenceLink }>
                                <ListItemIcon>
                                    <FileCopyIcon/>
                                </ListItemIcon>
                                <ListItemText primary={ t( "schedule:event.social.google-meet.copy" ) }/>
                            </MenuItem>
                        </>
                }
            </Menu>
        </>
    );
};

export default SchedulingSocialOptions;