import omit from "lodash/omit";

import { Dropdown } from "../ui";

export const SelectField = (({ formField, disabled, ...props }) => {

    return (
        <Dropdown
            { ...omit( props, "currentPosition" ) }
            disabled={ disabled }
            source={ formField.options.values.map( option => ({
                value: option.value ? option.value : option,
                label: option.label ? option.label : option,
                icon: option.icon
            }))}
            emptyValue
        />
    );
});
