import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./report.actions";

export function *watchReports () {
    yield all([
        takeEvery( actions.FETCH_RESULTS, fetchResults )
    ]);
}

export function *fetchResults ({ payload }) {
    const { report, ...params } = payload;
    yield put( actions.requestResults() );
    try {
        const response = yield call( apiv1.get, `/reports/${report}`, { params } );
        yield put( actions.receiveResults( response.data ) );
    } catch ( e ) {
        yield put( actions.errorResults( e.response.data ) );
    }
}