import { useCallback, useEffect, useState } from "react";
import usePrevious from "../hooks/use-previous.hook";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex } from ".";
import css from "./preview-file.scss";

const PreviewFile = ({ open, url, onClose, contentType, filename }) => {
    const t = useTranslate();

    const prevOpen = usePrevious( open );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ data, setData ] = useState( null );
    const [ src, setSrc ] = useState( "" );

    const fetchFile = useCallback( async () => {
        setIsLoading( true );
        await axios.get( url, { responseType: "blob" } )
            .then( response => {
                setData( response.data ) ;
                openFile( response.data );
                setIsLoading( false );
            });
    }, [ url, openFile ]);

    const saveFile = () => {
        const link = document.createElement( "a" );
        link.href = window.URL.createObjectURL( data );
        link.download = filename;
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    };

    const openFile = useCallback( data => {
        switch ( contentType ) {
            case "pdf":
                const file = new Blob([ data ], { type: "application/pdf" } );
                setSrc( URL.createObjectURL( file ) );
                break;
            case "image":
                const reader = new FileReader();
                reader.readAsDataURL( data );
                reader.onloadend = function () {
                    setSrc( reader.result );
                };
                break;
        }
    }, [ contentType ]);

    const closePreview = () => {
        onClose();
        setData( null );
        setSrc( "" );
    };

    useEffect( () => {
        if ( open && !prevOpen ) {
            fetchFile();
        }
    }, [ prevOpen, open, fetchFile ]);

    return (
        <Dialog
            open={ open }
            disableEnforceFocus
            onClose={ onClose }
            fullWidth
            fullScreen
        >
            <AppBar classes={{ root: css.appBar }}>
                <Toolbar>
                    <Tooltip title={ t( "common:close" ) }>
                        <IconButton color="inherit" onClick={ closePreview } size="large">
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography
                        variant="h6"
                        color="inherit"
                        className={ classnames( flex.fill, cssUtils.marginLeft ) }
                    >
                        { filename }
                    </Typography>

                    <Button
                        color="inherit"
                        onClick={ saveFile }
                    >
                        { t( "common:download" ) }
                    </Button>
                </Toolbar>
                { isLoading && <LinearProgress /> }
            </AppBar>
            {
                contentType === "pdf" ?
                    <iframe className={ css.iframePdf } src={ src } /> :
                    <img className={ css.img } src={ src } />
            }

        </Dialog>
    );
};

export default PreviewFile;