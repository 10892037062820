import { combineReducers } from "redux";

import * as actions from "./team.actions";

export function all ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_TEAMS:
            return {
                ...state,
                loading: true
            };

        case actions.RECEIVE_TEAMS:
            return {
                ...state,
                loading: false,
                data: action.payload.teams
            };

        case actions.ERROR_TEAMS:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
}

export function selected ( state = {}, action) {
    switch ( action.type ) {
        case actions.RECEIVE_TEAM:
            return {
                data: action.payload
            };

        case actions.ERROR_TEAM:
            return {
                error: action.payload
            };

        default:
            return state;
    }
}

export const teams = combineReducers({ all, selected });