export const SAVE_GOAL = "x/SAVE_GOAL";
export const saveGoal = ({ form, goal }) => ({
    type: SAVE_GOAL,
    meta: { form },
    payload: goal
});

export const DELETE_GOAL = "x/DELETE_GOAL";
export const deleteGoal = id => ({
    type: DELETE_GOAL,
    meta: { id }
});