// ---------------------------------------------------------------------------------------------------------------------
// Opportunity list
// ---------------------------------------------------------------------------------------------------------------------

export const FETCH_OPPORTUNITIES = "x/FETCH_OPPORTUNITIES";
export function fetchOpportunities ( data ) {
    return {
        type: FETCH_OPPORTUNITIES,
        payload: data
    };
}

export const REQUEST_OPPORTUNITIES = "x/REQUEST_OPPORTUNITIES";
export function requestOpportunities () {
    return {
        type: REQUEST_OPPORTUNITIES
    };
}

export const RECEIVE_OPPORTUNITIES = "x/RECEIVE_OPPORTUNITIES";
export function receiveOpportunities ( data ) {
    return {
        type: RECEIVE_OPPORTUNITIES,
        payload: data
    };
}

export const ERROR_OPPORTUNITIES = "x/ERROR_OPPORTUNITIES";
export function errorOpportunties ( error ) {
    return {
        type: ERROR_OPPORTUNITIES,
        error
    };
}

export const FETCH_OPPORTUNITIES_COUNT = "x/FETCH_OPPORTUNITIES_COUNT";
export const fetchOpportunitiesCount = params => ({
    type: FETCH_OPPORTUNITIES_COUNT,
    payload: params,
});

export const REQUEST_OPPORTUNITIES_COUNT = "x/REQUEST_OPPORTUNITIES_COUNT";
export const requestOpportunitiesCount = () => ({
    type: REQUEST_OPPORTUNITIES_COUNT,
});

export const RECEIVE_OPPORTUNITIES_COUNT = "x/RECEIVE_OPPORTUNITIES_COUNT";
export const receiveOpportunitiesCount = data => ({
    type: RECEIVE_OPPORTUNITIES_COUNT,
    payload: data,
});

export const ERROR_OPPORTUNITIES_COUNT = "x/ERROR_OPPORTUNITIES_COUNT";
export const errorOpportunitiesCount = error => ({
    type: ERROR_OPPORTUNITIES_COUNT,
    payload: error,
    error: true,
});

export const FETCH_OPPORTUNITIES_STATISTICS = "x/FETCH_OPPORTUNITIES_STATISTICS";
export const fetchOpportunitiesStatistics = params => ({
    type: FETCH_OPPORTUNITIES_STATISTICS,
    payload: params
});

export const REQUEST_OPPORTUNITIES_STATISTICS = "x/REQUEST_OPPORTUNITIES_STATISTICS";
export const requestOpportunitiesStatistics = () => ({
    type: REQUEST_OPPORTUNITIES_STATISTICS
});

export const RECEIVE_OPPORTUNITIES_STATISTICS = "x/RECEIVE_OPPORTUNITIES_STATISTICS";
export const receiveOpportunitiesStatistics = data => ({
    type: RECEIVE_OPPORTUNITIES_STATISTICS,
    payload: data
});

export const ERROR_OPPORTUNITIES_STATISTICS = "x/ERROR_OPPORTUNITIES_STATISTICS";
export const errorOpportunitiesStatistics = error => ({
    type: ERROR_OPPORTUNITIES_STATISTICS,
    error: true,
    payload: error
});

export const SET_FUNNEL = "x/SET_OPPORTUNITIES_FUNNEL";
export const setFunnel = id => ({
    type: SET_FUNNEL,
    payload: id
});

export const SET_FUNNELS = "x/SET_OPPORTUNITIES_FUNNELS";
export const setFunnels = funnelsId => ({
    type: SET_FUNNELS,
    payload: funnelsId
});

export const SET_PHASE = "x/SET_OPPORTUNITIES_PHASE";
export const setPhase = id => ({
    type: SET_PHASE,
    payload: id
});

export const SET_LOSS_REASON = "x/SET_LOSS_REASON";
export const setLossReason = id => ({
    type: SET_LOSS_REASON,
    payload: id
});

export const SET_SOURCE = "x/SET_OPPORTUNITIES_SOURCE";
export const setSource = id => ({
    type: SET_SOURCE,
    payload: id
});

export const TRANSFER_OPPORTUNITIES = "x/TRANSFER_OPPORTUNITIES";
export const transferOpportunities = data => ({
    type: TRANSFER_OPPORTUNITIES,
    payload: data
});

export const FETCH_PHASE_OPPORTUNITIES = "x/FETCH_PHASE_OPPORTUNITIES";
export function fetchPhaseOpportunities ( phaseId, params ) {
    return {
        type: FETCH_PHASE_OPPORTUNITIES,
        meta: { phaseId },
        payload: { params }
    };
}

export const REQUEST_PHASE_OPPORTUNITIES = "x/REQUEST_PHASE_OPPORTUNITIES";
export function requestPhaseOpportunities ( phaseId, page ) {
    return {
        type: REQUEST_PHASE_OPPORTUNITIES,
        meta: { phaseId, page }
    };
}

export const RECEIVE_PHASE_OPPORTUNITIES = "x/RECEIVE_PHASE_OPPORTUNITIES";
export function receivePhaseOpportunities ( phaseId, data ) {
    return {
        type: RECEIVE_PHASE_OPPORTUNITIES,
        meta: { phaseId },
        payload: data
    };
}

export const ERROR_PHASE_OPPORTUNITIES = "x/ERROR_PHASE_OPPORTUNITIES";
export function errorPhaseOpportunities ( phaseId, error ) {
    return {
        type: ERROR_PHASE_OPPORTUNITIES,
        meta: { phaseId },
        error
    };
}

export const RESET_PHASE_OPPORTUNITIES = "x/RESET_PHASE_OPPORTUNITIES";
export const resetPhaseOpportunities = () => ({
    type: RESET_PHASE_OPPORTUNITIES,
});

export const RELOAD_PHASE_OPPORTUNITIES = "x/RELOAD_PHASE_OPPORTUNITIES";
export function reloadPhaseOpportunities ( phaseId ) {
    return {
        type: RELOAD_PHASE_OPPORTUNITIES,
        meta: { phaseId }
    };
}

export const FETCH_FUNNEL_LATEST_UPDATED_AT = "x/FETCH_FUNNEL_LATEST_UPDATED_AT";
export const fetchFunnelLatestUpdatedAt = () => ({
    type: FETCH_FUNNEL_LATEST_UPDATED_AT
});

export const SET_FUNNEL_LATEST_UPDATED_AT = "x/SET_FUNNEL_LATEST_UPDATED_AT";
export const setFunnelLatestUpdatedAt = latestUpdatedAt => ({
    type: SET_FUNNEL_LATEST_UPDATED_AT,
    payload: latestUpdatedAt
});

export const SAVE_PHASE_OPPORTUNITY = "x/SAVE_PHASE_OPPORTUNITY";
export function savePhaseOpportunity ( phaseId, opportunity ) {
    return {
        type: SAVE_PHASE_OPPORTUNITY,
        meta: { phaseId },
        payload: { opportunity }
    };
}

export const CHANGE_OPEN_SCHEDULES_STATUS = "x/CHANGE_OPEN_OPPORTUNITY_SCHEDULES_STATUS";
export const changeOpenSchedulesStatus = ({ id, status, updateAfter }) => ({
    type: CHANGE_OPEN_SCHEDULES_STATUS,
    meta: { id },
    payload: { status, updateAfter }
});

export const FETCH_SEARCH_OPPORTUNITIES = "x/FETCH_SEARCH_OPPORTUNITIES";
export function fetchSearchOpportunities ( data ) {
    return {
        type: FETCH_SEARCH_OPPORTUNITIES,
        payload: data
    };
}

export const REQUEST_SEARCH_OPPORTUNITIES = "x/REQUEST_SEARCH_OPPORTUNITIES";
export function requestSearchOpportunities () {
    return {
        type: REQUEST_SEARCH_OPPORTUNITIES
    };
}

export const RECEIVE_SEARCH_OPPORTUNITIES = "x/RECEIVE_SEARCH_OPPORTUNITIES";
export function receiveSearchOpportunities ( data ) {
    return {
        type: RECEIVE_SEARCH_OPPORTUNITIES,
        payload: data
    };
}

export const ERROR_SEARCH_OPPORTUNITIES = "x/ERROR_SEARCH_OPPORTUNITIES";
export function errorSearchOpportunities ( error ) {
    return {
        type: ERROR_SEARCH_OPPORTUNITIES,
        error
    };
}

export const CREATE_EXPORTATION = "x/CREATE_OPPORTUNITY_EXPORTATION";
export const createExportation = data => ({
    type: CREATE_EXPORTATION,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Single opportunity
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_OPPORTUNITY = "x/FETCH_OPPORTUNITY";
export const fetchOpportunity = id => ({
    type: FETCH_OPPORTUNITY,
    meta: { id }
});

export const REQUEST_OPPORTUNITY = "x/REQUEST_OPPORTUNITY";
export const requestOpportunity = () => ({
    type: REQUEST_OPPORTUNITY
});

export const SET_OPPORTUNITY = "x/SET_OPPORTUNITY";
export const setOpportunity = opportunity => ({
    type: SET_OPPORTUNITY,
    payload: opportunity
});

export const ERROR_OPPORTUNITY = "x/ERROR_OPPORTUNITY";
export const errorOpportunity = error => ({
    type: ERROR_OPPORTUNITY,
    error: true,
    payload: error
});

export const SAVE_OPPORTUNITY = "x/SAVE_OPPORTUNITY";
export const saveOpportunity = opportunity => ({
    type: SAVE_OPPORTUNITY,
    meta: { id: opportunity.id },
    payload: opportunity
});

export const PATCH_OPPORTUNITY = "x/PATCH_OPPORTUNITY";
export const patchOpportunity = ({ form, formikForm, ...opportunity }) => ({
    type: PATCH_OPPORTUNITY,
    meta: {
        id: opportunity.id,
        form,
        formikForm
    },
    payload: opportunity
});

export const EXECUTE_PHASE_TRIGGERS = "x/EXECUTE_OPPORTUNITY_PHASE_TRIGGERS";
export const executePhaseTriggers = ({ opportunityId, ...data }) => ({
    type: EXECUTE_PHASE_TRIGGERS,
    meta: { id: opportunityId },
    payload: data
});

export const SAVE_COMMENT = "x/SAVE_OPPORTUNITY_COMMENT";
export const saveComment = comment => ({
    type: SAVE_COMMENT,
    meta: { id: comment.opportunityId },
    payload: comment
});

export const FETCH_TIMELINE = "x/FETCH_OPPORTUNITY_TIMELINE";
export const fetchTimeline = ({ id, ...params }) => ({
    type: FETCH_TIMELINE,
    meta: { id },
    payload: params
});

export const REQUEST_TIMELINE = "x/REQUEST_OPPORTUNITY_TIMELINE";
export const requestTimeline = () => ({
    type: REQUEST_TIMELINE
});

export const RECEIVE_TIMELINE = "x/RECEIVE_OPPORTUNITY_TIMELINE";
export const receiveTimeline = data => ({
    type: RECEIVE_TIMELINE,
    payload: data
});

export const ERROR_TIMELINE = "x/ERROR_OPPORTUNITY_TIMELINE";
export const errorTimeline = error => ({
    type: ERROR_TIMELINE,
    error: true,
    payload: error
});

export const RELOAD_TIMELINE = "x/RELOAD_OPPORTUNITY_TIMELINE";
export const reloadTimeline = () => ({
    type: RELOAD_TIMELINE
});

export const DELETE_OPPORTUNITY = "x/DELETE_OPPORTUNITY";
export const deleteOpportunity = ({ id }) => ({
    type: DELETE_OPPORTUNITY,
    meta: { id },
});

export const SAVE_FILE = "x/SAVE_OPPORTUNITY_FILE";
export const saveFile = ({ opportunityId, ...data }) => ({
    type: SAVE_FILE,
    meta: { id: opportunityId },
    payload: data
});

export const FETCH_FILES = "x/FETCH_OPPORTUNITY_FILES";
export const fetchFiles = id => ({
    type: FETCH_FILES,
    meta: { id }
});

export const REQUEST_FILES = "x/REQUEST_OPPORTUNITY_FILES";
export const requestFiles = () => ({
    type: REQUEST_FILES
});

export const RECEIVE_FILES = "x/RECEIVE_OPPORTUNITY_FILES";
export const receiveFiles = data => ({
    type: RECEIVE_FILES,
    payload: data
});

export const ERROR_FILES = "x/ERROR_OPPORTUNITY_FILES";
export const errorFiles = error => ({
    type: ERROR_FILES,
    error: true,
    payload: error
});

export const DELETE_FILE = "x/DELETE_OPPORTUNITY_FILE";
export const deleteFile = ({ id, ...file }) => ({
    type: DELETE_FILE,
    meta: { id },
    payload: file
});

export const SAVE_HOLDING = "x/SAVE_OPPORTUNITY_HOLDING";
export const saveHolding = ({ opportunityId, ...data }) => ({
    type: SAVE_HOLDING,
    meta: { id: opportunityId },
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// PERSONS
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_PERSONS = "x/FETCH_OPPORTUNITY_PERSONS";
export const fetchPersons = id => ({
    type: FETCH_PERSONS,
    meta: { id }
});

export const REQUEST_PERSONS = "x/REQUEST_OPPORTUNITY_PERSONS";
export const requestPersons = () => ({
    type: REQUEST_PERSONS
});

export const RECEIVE_PERSONS = "x/RECEIVE_OPPORTUNITY_PERSONS";
export const receivePersons = contacts => ({
    type: RECEIVE_PERSONS,
    payload: contacts
});

export const ERROR_PERSONS = "x/ERROR_OPPORTUNITY_PERSONS";
export const errorPersons = error => ({
    type: ERROR_PERSONS,
    error: true,
    payload: error
});

export const SAVE_PERSON = "x/SAVE_OPPORTUNITY_PERSON";
export const savePerson = data => ({
    type: SAVE_PERSON,
    meta: { id: data.opportunityId },
    payload: data
});

export const DELETE_PERSON = "x/DELETE_OPPORTUNITY_PERSON";
export const deletePerson = data => ({
    type: DELETE_PERSON,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Scheduling
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_NEW_SCHEDULING = "x/SAVE_NEW_OPPORTUNITY_SCHEDULING";
export const saveNewScheduling = action => ({
    type: SAVE_NEW_SCHEDULING,
    payload: action
});

export const SAVE_SCHEDULING = "x/SAVE_OPPORTUNITY_SCHEDULING";
export const saveScheduling = action => ({
    type: SAVE_SCHEDULING,
    meta: { id: action.id },
    payload: action
});

export const FETCH_SCHEDULES = "x/FETCH_OPPORTUNITY_SCHEDULES";
export const fetchSchedules = opportunityId => ({
    type: FETCH_SCHEDULES,
    meta: { id: opportunityId }
});

export const REQUEST_SCHEDULES = "x/REQUEST_OPPORTUNITY_SCHEDULES";
export const requestSchedules = () => ({
    type: REQUEST_SCHEDULES
});

export const RECEIVE_SCHEDULES = "x/RECEIVE_OPPORTUNITY_SCHEDULES";
export const receiveSchedules = actions => ({
    type: RECEIVE_SCHEDULES,
    payload: actions
});

export const ERROR_SCHEDULES = "x/ERROR_OPPORTUNITY_SCHEDULES";
export const errorSchedules = error => ({
    type: ERROR_SCHEDULES,
    error: true,
    payload: error
});

export const DELETE_SCHEDULING = "x/DELETE_OPPORTUNITY_SCHEDULING";
export const deleteScheduling = ({ id, ...data }) => ({
    type: DELETE_SCHEDULING,
    meta: { id },
    payload: data,
});

export const FETCH_SCHEDULING_TYPES = "x/FETCH_OPPORTUNITY_SCHEDULING_TYPES";
export const fetchSchedulingTypes = () => ({
    type: FETCH_SCHEDULING_TYPES
});

export const REQUEST_SCHEDULING_TYPES = "x/REQUEST_OPPORTUNITY_SCHEDULING_TYPES";
export const requestSchedulingTypes = () => ({
    type: REQUEST_SCHEDULING_TYPES
});

export const RECEIVE_SCHEDULING_TYPES = "x/RECEIVE_OPPORTUNITY_SCHEDULING_TYPES";
export const receiveSchedulingTypes = types => ({
    type: RECEIVE_SCHEDULING_TYPES,
    payload: types
});

export const ERROR_SCHEDULING_TYPES = "x/ERROR_OPPORTUNITY_SCHEDULING_TYPES";
export const errorSchedulingTypes = error => ({
    type: ERROR_SCHEDULING_TYPES,
    error: true,
    payload: error
});

// ---------------------------------------------------------------------------------------------------------------------
// Proposal
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_PROPOSALS = "x/FETCH_OPPORTUNITY_PROPOSALS";
export const fetchProposals = id => ({
    type: FETCH_PROPOSALS,
    meta: { id }
});

export const REQUEST_PROPOSALS = "x/REQUEST_OPPORTUNITY_PROPOSALS";
export const requestProposals = () => ({
    type: REQUEST_PROPOSALS
});

export const RECEIVE_PROPOSALS = "x/RECEIVE_OPPORTUNITY_PROPOSALS";
export const receiveProposals = proposals => ({
    type: RECEIVE_PROPOSALS,
    payload: proposals
});

export const ERROR_PROPOSALS = "x/ERROR_OPPORTUNITY_PROPOSALS";
export const errorProposals = error => ({
    type: ERROR_PROPOSALS,
    payload: error,
    error: true
});

export const SAVE_PROPOSAL = "x/SAVE_OPPORTUNITY_PROPOSAL";
export const saveProposal = form => proposal => ({
    type: SAVE_PROPOSAL,
    meta: { form, id: proposal.id },
    payload: proposal
});

export const DELETE_PROPOSAL = "x/DELETE_OPPORTUNITY_PROPOSAL";
export const deleteProposal = ({ id, opportunityId }) => ({
    type: DELETE_PROPOSAL,
    meta: { id, opportunityId }
});

// ---------------------------------------------------------------------------------------------------------------------
// Opportunity sms
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_NEW_SMS = "x/SAVE_NEW_OPPORTUNITY_SMS";
export const saveNewSms = sms => ({
    type: SAVE_NEW_SMS,
    payload: sms
});

// ---------------------------------------------------------------------------------------------------------------------
// New customer
// ---------------------------------------------------------------------------------------------------------------------
export const START_NEW_OPPORTUNITY = "x/START_NEW_OPPORTUNITY";
export function startNewOpportunity () {
    return {
        type: START_NEW_OPPORTUNITY,
    };
}

export const CLOSE_NEW_OPPORTUNITY = "x/CLOSE_NEW_OPPORTUNITY";
export function closeNewOpportunity () {
    return {
        type: CLOSE_NEW_OPPORTUNITY
    };
}

export const SAVE_NEW_OPPORTUNITY = "x/SAVE_NEW_OPPORTUNITY";
export function saveNewOpportunity ( opportunity, { form } = {} ) {
    return {
        type: SAVE_NEW_OPPORTUNITY,
        meta: { form },
        payload: opportunity
    };
}

export const PUSH_NEW_OPPORTUNITY = "x/PUSH_NEW_OPPORTUNITY";
export function pushNewOpportunity ( opportunity ) {
    return {
        type: PUSH_NEW_OPPORTUNITY,
        payload: { opportunity }
    };
}

export const SEARCH_CUSTOMERS = "x/SEARCH_OPPORTUNITY_CUSTOMERS";
export const searchCustomers = data => ({
    type: SEARCH_CUSTOMERS,
    payload: data
});

export const SET_CUSTOMERS_SEARCH = "x/SET_OPPORTUNITY_CUSTOMERS_SEARCH";
export const setCustomersSearch = data => ({
    type: SET_CUSTOMERS_SEARCH,
    payload: data
});

export const SET_CUSTOMERS_SEARCH_VALUES = "x/SET_CUSTOMERS_SEARCH_VALUES";
export const setCustomersSearchValues = data => ({
    type: SET_CUSTOMERS_SEARCH_VALUES,
    payload: data
});

export const SAVE_NEW_OPPORTUNITY_COMPANY = "x/SAVE_NEW_OPPORTUNITY_COMPANY";
export const saveNewOpportunityCompany = company => ({
    type: SAVE_NEW_OPPORTUNITY_COMPANY,
    payload: company
});

export const SAVE_NEW_OPPORTUNITY_PERSON = "x/SAVE_NEW_OPPORTUNITY_PERSON";
export const saveNewOpportunityPerson = person => ({
    type: SAVE_NEW_OPPORTUNITY_PERSON,
    payload: person
});

export const SET_NEW_OPPORTUNITY_COMPANY = "x/SET_NEW_OPPORTUNITY_COMPANY";
export const setNewOpportunityCompany = company => ({
    type: SET_NEW_OPPORTUNITY_COMPANY,
    payload: company
});

export const SET_NEW_OPPORTUNITY_PERSON = "x/SET_NEW_OPPORTUNITY_PERSON";
export const setNewOpportunityPerson = person => ({
    type: SET_NEW_OPPORTUNITY_PERSON,
    payload: person
});

export const FETCH_OPPORTUNITY_HOLDING = "x/FETCH_OPPORTUNITY_HOLDING";
export function fetchOpportunityHolding ( id ) {
    return {
        type: FETCH_OPPORTUNITY_HOLDING,
        meta: { id }
    };
};

export const REQUEST_OPPORTUNITY_HOLDING = "x/REQUEST_OPPORTUNITY_HOLDING";
export function requestOpportunityHolding () {
    return {
        type: REQUEST_OPPORTUNITY_HOLDING
    };
}

export const RECEIVE_OPPORTUNITY_HOLDING = "x/RECEIVE_OPPORTUNITY_HOLDING";
export function receiveOpportunityHolding ( data ) {
    return {
        type: RECEIVE_OPPORTUNITY_HOLDING,
        payload: data
    };
}

export const ERROR_OPPORTUNITY_HOLDING = "x/ERROR_OPPORTUNITY_HOLDING";
export function errorOpportuntyHolding ( error ) {
    return {
        type: ERROR_OPPORTUNITY_HOLDING,
        error
    };
}