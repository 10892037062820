import CommentIcon from "@mui/icons-material/Comment";
import Typography from "@mui/material/Typography";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "./timeline.scss";

const CommentEvent = ({ event }) => {
    const t = useTranslate();

    return (
        <TimelineItem icon={ <CommentIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={ t( "customer:timeline.customer-comment" ) }
            />
            <Typography className={ css.commentText } dangerouslySetInnerHTML={{ __html: event.payload.comment }}/>
        </TimelineItem>
    );
};

export default CommentEvent;