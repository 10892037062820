import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { editCommission } from "./commission.actions";
import { EDIT_COMMISSION_FORM } from "./commission.constants";
import CommissionForm, { validate } from "./commission-form.component";

const EditCommission = ({ commission, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: EDIT_COMMISSION_FORM,
        initialValues: {
            goalId: null,
            products: [],
            teamId: null,
            usersId: [],
            ...commission,
        },
        onSubmit: values => dispatch( editCommission( values ) ),
        onSubmitSuccess: onClose,
        validate,
    });

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:commission.edit" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    <CommissionForm setFieldValue={ formik.setFieldValue } values={ formik.values } />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCommission;