import config from "config";
import { useCallback, useEffect, useMemo, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import qs from "qs";

import { useEinFormat } from "../../../hooks/use-document-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { flex, cssUtils } from "../../../ui";
import { fetchSources } from "../../../source/source.actions";
import cssMarketplace from "../../marketplace.scss";
import DeletePortal from "./delete-portal.component";
import EditPortal from "./edit-portal.component";
import NewPortal from "./new-portal.component";
import { fetchPortals } from "./portal-integration.actions";
import { getPortals, isLoadingPortals } from "./portal-integration.selectors";
import { fetchDistributions } from "../../../config/advanced/distribution/distribution.actions";

const createUrl = portal => `${config.get( "client.core" )}/api/v1/portals/${portal.token}`;

const PortalsIntegration = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const einFormat = useEinFormat( t );

    const allPortals = useSelector( getPortals );
    const loading = useSelector( isLoadingPortals );

    const [ showNewPortal, setShowNewPortal ] = useState( false );
    const navigate = useNavigate();
    const location = useLocation();

    const portalType = useMemo( () => {
        const currentPath = location.pathname;
        const defaultPath = "/marketplace/integrations/portals/";

        switch ( currentPath ) {
            case `${ defaultPath }icarros`:
                return "ICARROS";
            case `${ defaultPath }webmotors`:
                return "WEBMOTORS";
            case `${ defaultPath }olx`:
                return "OLX";
            case `${ defaultPath }mercadolibre`:
                return "MERCADO_LIBRE";
            case `${ defaultPath }mobiauto`:
                return "MOBIAUTO";
            case `${ defaultPath }usadosbr`:
                return "USADOSBR";
            default:
                return "";
        }

    }, [ location ] );

    const portals = useMemo( () => {
        if ( !allPortals ) {
            return [];
        }

        return allPortals.filter( portal => portal.type === portalType );
    }, [ portalType, allPortals ] );

    const toggleNewPortal = useCallback( () => setShowNewPortal( showNewPortal => !showNewPortal ), [] );

    const renderLogo = useCallback( () => {
        switch ( portalType ) {
            case "ICARROS":
                return <div className={ cssMarketplace.icarrosIcon }/>;
            case "WEBMOTORS":
                return <div className={ cssMarketplace.webmotorsIcon }/>;
            case "OLX":
                return <div className={ cssMarketplace.olxIcon }/>;
            case "MERCADO_LIBRE":
                return <div className={ cssMarketplace.mercadoLibreIcon }/>;
            case "MOBIAUTO":
                return <div className={ cssMarketplace.mobiautoIcon } />;
            case "USADOSBR":
                return <div className={ cssMarketplace.usadosbrIcon } />;
            default:
                return null;
        }
    }, [ portalType ] );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "../../", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const handleAdd = useCallback( () => {
        if ( portalType === "MERCADO_LIBRE" ) {
            window.location.href = "/mercadolibre/auth";
        } else {
            toggleNewPortal();
        }
    }, [ portalType, toggleNewPortal ] );

    useEffect( () => {
        dispatch( fetchDistributions() );
        dispatch( fetchPortals() );
        dispatch( fetchSources() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( portalType === "MERCADO_LIBRE" ) {
            const queryProps = qs.parse( location.search, { ignoreQueryPrefix: true } );
            if ( !!queryProps.code ) {
                setShowNewPortal( true );
            }
        }
    }, [ location, portalType ] );

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            { renderLogo() }
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            { t( `marketplace:integrations.portals.${portalType}.call-to-action` ) }
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader
                        title={ t( `marketplace:integrations.portals.${portalType}.title` ) }
                        subheader={ t( `marketplace:integrations.portals.call-to-action` ) }
                    />
                    <CardContent>
                        {
                            loading ?
                                <LinearProgress/> :
                                isEmpty( portals ) ?
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        { t( "marketplace:integrations.portals.empty" ) }
                                    </Typography> :
                                    portals.map( ( portal, index ) => (
                                        <Fragment key={ index }>
                                            <List>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={ portal.name }
                                                        secondary={
                                                            t( "marketplace:integrations.portals.name.label" )
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Chip
                                                            color={ portal.credentials?.expired ? "error" : "success" }
                                                            label={
                                                                portal.credentials?.expired ?
                                                                    t( "marketplace:integrations.portals.expired" ) :
                                                                    t( "marketplace:integrations.portals.active" )
                                                            }
                                                        />
                                                        <EditPortal portal={ portal }/>
                                                        <DeletePortal portal={ portal }/>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {
                                                    portalType === "MERCADO_LIBRE" &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ portal.credentials.username }
                                                                secondary={ t( "common:username" ) }
                                                            />
                                                        </ListItem>
                                                }
                                                {
                                                    portalType === "ICARROS" &&
                                                    portalType === "MOBIAUTO" &&
                                                    portalType === "USADOSBR" &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ portal.credentials.username }
                                                                secondary={ t( "common:username" ) }
                                                            />
                                                        </ListItem>
                                                }
                                                {
                                                    portalType === "OLX" &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ createUrl( portal ) }
                                                                secondary="URL"
                                                            />
                                                        </ListItem>
                                                }
                                                {
                                                    portalType === "WEBMOTORS" &&
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={ einFormat( portal.credentials.ein ) }
                                                                secondary={ t( "common:ein" ) }
                                                            />
                                                        </ListItem>
                                                }
                                                <ListItem>
                                                    <ListItemText
                                                        primary={ portal.source.name }
                                                        secondary={
                                                            t( "marketplace:integrations.portals.source" )
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Fragment>
                                    )
                                    )}
                    </CardContent>
                    <CardActions className={ flex.justifyContentEnd }>
                        <Button onClick={ handleAdd }>
                            { t( "common:add" ) }
                        </Button>
                    </CardActions>
                </Card>
                <NewPortal
                    portalType={ showNewPortal ? portalType : "" }
                    onClose={ () => toggleNewPortal() }
                />

            </div>
        </div>
    );
};

export default PortalsIntegration;
