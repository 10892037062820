import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import DialogContentText from "@mui/material/DialogContentText";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogForm } from "../../../ui";
import { updateFacebookLeadPage } from "./facebook-integration.actions";
import { createEditFacebookLeadPageForm } from "./facebook-integration.utils";
import FacebookLeadPageForm from "./facebook-lead-page-form.component";

const EditFacebookLeadPage = ({ facebookLeadPage, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const form = createEditFacebookLeadPageForm( facebookLeadPage.id );

    const formik = useForm({
        form,
        enableReinitialize: true,
        initialValues: facebookLeadPage,
        onSubmit: values => dispatch( updateFacebookLeadPage( form, facebookLeadPage.id )( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.funnel || !values.funnel.id ) {
                errors.funnel = {
                    id: "common:validation.selection"
                };
            }
            if ( !values.source || !values.source.id ) {
                errors.source = {
                    id: "common:validation.selection"
                };
            }
            if ( !values.distribution?.id && !values.teamId && isEmpty( values.usersId ) ) {
                errors.usersId = "marketplace:automation.empty-users";
            }
            return errors;
        }
    });

    return (
        <DialogForm
            handleSubmit={ formik.handleSubmit }
            onClose={ onClose }
            open={ open }
            resetForm={ formik.resetForm }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            title={ t( "marketplace:integrations.facebook.lead-pages.edit" ) }
        >
            <DialogContentText color="textSecondary">
                { `${t( "marketplace:integrations.facebook.lead-pages.page")}: ${facebookLeadPage.pageName}. ` }
                {
                    facebookLeadPage.formName &&
                            `${
                                t( "marketplace:integrations.facebook.lead-pages.form.label")}:
                                ${facebookLeadPage.formName}.`
                }
            </DialogContentText>
            <FacebookLeadPageForm formik={ formik } />
        </DialogForm>
    );
};

export default EditFacebookLeadPage;