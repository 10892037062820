import { useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import ReorderIcon from "@mui/icons-material/Reorder";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LockIcon from "@mui/icons-material/Lock";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";
import { fieldIcons as icons } from "../../../form";
import { deleteField } from "./form.actions";
import FieldDemo from "./field-demo.component";

const FieldItem = ({ field, selected, onSelect, onDeselect, index }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showConfirmationDelete, setShowConfirmationDelete ] = useState( false );

    const toggleConfirmationDelete = () => setShowConfirmationDelete( !showConfirmationDelete );

    const Icon = icons[ field.type ];

    return (
        <div>
            <Dialog
                open={ showConfirmationDelete }
                onClose={ toggleConfirmationDelete }
            >
                <DialogContent>
                    <DialogContentText>
                        { field.type === "GROUPING" ?
                            t( "config:form.delete-field.comment-grouping" ) :
                            t( "config:form.delete-field.comment-field" )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmationDelete }>
                        { t( "common:no" ) }
                    </Button>
                    <Button
                        color="primary"
                        onClick={ () => {
                            dispatch( deleteField( field ) );
                            toggleConfirmationDelete();
                        }}
                    >
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                field.id &&
                    <Draggable key={field.id} draggableId={ field.id.toString() } index={ index }>
                        { provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={flex.container}
                            >
                                <div className={flex.fill}>
                                    <ListItem
                                        disablePadding
                                        secondaryAction={
                                            <>
                                                {
                                                    field.systemField &&
                                                        <Tooltip title={ t( "config:form.field-config.system-field" ) }>
                                                            <IconButton datatype="lockSystemField" size="large">
                                                                <LockIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                {
                                                    !field.systemField &&
                                                        <Tooltip title={ t( "common:exclude" ) }>
                                                            <IconButton
                                                                onClick={ toggleConfirmationDelete }
                                                                size="large"
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                <Tooltip
                                                    title={
                                                        selected ?
                                                            t( "common:minimize" ) :
                                                            t( "common:expand" ) }
                                                >
                                                    <IconButton
                                                        onClick={ selected ? onDeselect : onSelect }
                                                        size="large"
                                                    >
                                                        { selected ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={ t( "common:order" ) } >
                                                    <IconButton { ...provided.dragHandleProps } size="large">
                                                        <ReorderIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemButton
                                            onClick={ selected ? null : onSelect }
                                        >
                                            { Icon && <ListItemIcon><Icon/></ListItemIcon> }
                                            <ListItemText
                                                primary={ field.name +
                                                    ( field.options && field.options.required ? " *" : "" ) }
                                            />
                                        </ListItemButton>
                                    </ListItem>

                                </div>
                            </div>
                        )}
                    </Draggable>
            }
            <Paper>
                <Collapse in={ selected } unmountOnExit>
                    <FieldDemo
                        className={ flex.fill }
                        onDeselect={ onDeselect }
                        field={ field }
                    />
                </Collapse>
            </Paper>
        </div>
    );
};

export default FieldItem;