import { useCallback, useEffect, useRef } from "react";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "./timeline.scss";

const CheckinEvent = ({ event: { payload, user, date } }) => {
    const t = useTranslate();

    const map = useRef();

    const init = useCallback( () => {
        const bound = new google.maps.LatLngBounds();
        bound.extend( new google.maps.LatLng( payload.latitude, payload.longitude ) );
        const map = new google.maps.Map( this.map, {
            zoom: 15,
            center: bound.getCenter(),
            clickableIcons: false,
            mapTypeControl: false,
            draggable: false,
            draggableCursor: "pointer",
            fullscreenControl: false
        });

        new google.maps.Marker({
            clickable: false,
            position: { lat: payload.latitude, lng: payload.longitude },
            map: map
        });
    }, [ payload ] );

    const openMap = () => {
        window.open( `https://www.google.com/maps?q=${payload.latitude},${payload.longitude}`, "_blank" );
    };

    useEffect( () => {
        try {
            init();
        } catch ( e ) {
            // Nao carregou o google maps ainda entao tenta de novo
            setTimeout( init, 500 );
        }
    }, [ init ] );

    return (
        <TimelineItem icon={ <PersonPinCircleIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.opportunity-checkin-user" ) }
                date={ date }
            />
            <div className={ css.checkinMap } onClick={ openMap } ref={ map } />
        </TimelineItem>
    );
};

export default CheckinEvent;