import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../hooks/use-translate.hook";
import { RadioButtons } from "../../ui";
import { deleteScheduling } from "../opportunity.actions";
import { createSchedulingDeleteForm } from "../opportunity.utils";

const SchedulingDelete = ({ open, onClose, scheduling }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: createSchedulingDeleteForm( scheduling.id ),
        initialValues: {
            id: scheduling.id,
            recurrent: scheduling.recurrent,
            startDate: scheduling.startDate,
            mode: "SINGLE",
        },
        onSubmit: values => dispatch( deleteScheduling( values ) ),
    });

    return (
        <Dialog open={ open }>
            <DialogTitle>{ t( "common:exclude" ) }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    {
                        scheduling.recurrent ?
                            <Field
                                name="mode"
                                label={ t( "common:mode" ) }
                                row={ false }
                                options={
                                    [
                                        {
                                            value: "SINGLE",
                                            label: t( "schedule:form-event.recurrent.update.single" ),
                                        }, {
                                            value: "FUTURE",
                                            label: t( "schedule:form-event.recurrent.update.future" ),
                                        }, {
                                            value: "ALL",
                                            label: t( "schedule:form-event.recurrent.update.all" ),
                                        }
                                    ]
                                }
                                component={ RadioButtons }
                            /> :
                            <DialogContentText>{ t( "schedule:event.delete.confirm" ) }</DialogContentText>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:no" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:yes" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SchedulingDelete;