import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import DistributionForm from "./distribution-form.component";
import { createDistribution } from "./distribution.actions";
import { NEW_DISTRIBUTION_FORM } from "./distribution.constants";

const NewDistribution = ({ open, onClose }) => {
    const t = useTranslate();

    return (
        <Dialog open={ open } maxWidth="sm" fullWidth>
            <DialogTitle>
                { t( "config:distribution.new" ) }
            </DialogTitle>
            <DistributionForm
                form={ NEW_DISTRIBUTION_FORM }
                onSubmit={ createDistribution }
                onClose={ onClose }
            />
        </Dialog>
    );
};

export default NewDistribution;