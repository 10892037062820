import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import history from "../../history";
import tracker from "../util/tracker.utils";
import { apiv1 } from "../../api/sagas";
import * as actions from "./importation.actions";
import { NEW_IMPORTATION_FORM } from "./importation.constants";
import { createDeleteForm } from "./importation.utils";

export function *watchImportation () {
    yield all([
        takeEvery( actions.FETCH_IMPORTATIONS, fetchImportations ),
        takeEvery( actions.SAVE_NEW_IMPORTATION, saveNewImportation ),
        takeEvery( actions.DELETE_IMPORTATION, deleteImportation ),
        takeEvery( actions.FETCH_IMPORTATION, fetchImportation ),
        takeEvery( actions.FETCH_RESULTS, fetchResults )
    ]);
}

export function *fetchImportations ({ payload }) {
    yield put( actions.requestImportations() );
    try {
        const response = yield call( apiv1.get, "/importations", { params: payload } );
        const importations = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveImportations({ importations, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorImportations( e.response.data ) );
    }
}

export function *saveNewImportation ({ payload }) {
    yield put( startSubmit( NEW_IMPORTATION_FORM ) );
    try {
        const formData = new FormData();
        Object.keys( payload ).forEach( key => {
            if ( key !== "file" && typeof payload[ key ] === "object" ) {
                formData.append( key, JSON.stringify( payload[ key ] ) );
            } else {
                formData.append( key, payload[ key ] );
            }
        });
        yield call( apiv1.post, "/importations", formData );
        tracker.logAction( "Importation created" );
        yield put( stopSubmit( NEW_IMPORTATION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_IMPORTATION_FORM, e.response.data ) );
    }
}

export function *deleteImportation ({ meta: { id } }) {
    const formName = createDeleteForm( id );
    yield put( startSubmit( formName ) );

    try {
        yield call( apiv1.delete, `/importations/${id}` );
        yield put( actions.fetchImportations() );
        yield put( stopSubmit( formName ) );
        yield call( [ history, history.push ], "/importations" );
    } catch ( e ) {
        yield put( stopSubmit( formName, e.response.data.error ) );
    }
}

export function *fetchImportation ({ meta: { id } }) {
    yield put( actions.requestImportation() );

    try {
        const response = yield call( apiv1.get, `/importations/${id}` );
        yield put( actions.setImportation( response.data ) );
    } catch ( e ) {
        yield put( actions.errorImportation( e.response.data ) );
    }
}

export function *fetchResults ({ meta: { id }}) {
    yield put( actions.requestResults() );

    try {
        const response = yield call( apiv1.get, `/importations/${id}/results` );
        yield put( actions.receiveResults( response.data ) );
    } catch ( e ) {
        yield put( actions.errorResults( e.response.data ) );
    }
}