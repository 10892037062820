export default {
    "opportunity-created": "Oportunidad creada",
    "opportunity-created-user": "creo esta oportunidad",
    "change": {
        "address": "{ name } ({ type })",
        "added": "añadido",
        "removed": "quitado"
    },
    "opportunity": {
        "closing-date": "Fecha de cierre",
        "price": "Valor"
    }
};