import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import get from "lodash/get";
import { useForm } from "formik-redux";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { deleteProducts } from "./product.actions";
import { DELETE_PRODUCTS_FORM } from "./product.constants";

const DeleteProducts = ({ open, onClose, products }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ isSubmitted, setIsSubmitted ] = useState( false );

    const formik = useForm({
        form: DELETE_PRODUCTS_FORM,
        initialValues: {
            productIds: open ? products.map( product => product.id ) : [],
        },
        enableReinitialize: true,
        onSubmit: values => dispatch( deleteProducts( values ) ),
    });
    const prevSubmitting = usePrevious( formik.submitting );

    const handleClose = () => onClose( isSubmitted );

    useEffect( () => {
        if ( open ) {
            setIsSubmitted( false );
        }
    }, [ open ] );
    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            setIsSubmitted( true );
        }
    }, [ prevSubmitting, formik.submitting, onClose ] );

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:products.delete.title" ) }
            </DialogTitle>
            <DialogContent>
                <List>
                    {
                        products.map( product => (
                            <ListItem key={ product.id } disableGutters>
                                <ListItemIcon>
                                    {
                                        isSubmitted ?
                                            formik.error && get( formik.error, product.id ) ?
                                                <ErrorIcon color="error" /> :
                                                <CheckCircleIcon color="success" /> :
                                            <RadioButtonCheckedIcon />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={ `${product.code ? `${product.code} - ` : "" }${product.description}` }
                                    secondary={
                                        isSubmitted ?
                                            formik.error && get( formik.error, product.id ) ?
                                                formik.error[ product.id ].error :
                                                t( "config:products.delete.success" ) :
                                            ""
                                    }
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button disabled={ formik.submitting } onClick={ handleClose }>
                    { isSubmitted ? t( "common:close" ) : t( "common:cancel" ) }
                </Button>
                <Button disabled={ formik.submitting || isSubmitted } onClick={ formik.submitForm }>
                    { t( "common:exclude" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteProducts;