import differenceBy from "lodash/differenceBy";
import Typography from "@mui/material/Typography";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";
import css from "./timeline.scss";

const mapChangedItem = ( from, to, { label, className, t }) => {
    const addressKey = address => address.name + address.type;
    const items = differenceBy( from, to, addressKey );
    return !items.length ? null : items.map( ( address, index ) => (
        <li key={ index }>
            <Typography>
                <span className={ css.addressChangeLabel }>{ t( label ) + " " }</span>
                <mark className={ className }>
                    {
                        t(
                            "timeline:change.address",
                            { name: address.name, type: t( `form:address.types.${address.type}` ) }
                        )
                    }
                </mark>
            </Typography>
        </li>
    ));
};

const AddressChangeList = ({ change }) => {
    const t = useTranslate();

    const previous = mapChangedItem( change.previous, change.current, {
        t,
        label: "timeline:change.removed",
        className: cssUtils.highlightOld
    });
    const current = mapChangedItem( change.current, change.previous, {
        t,
        label: "timeline:change.added",
        className: cssUtils.highlightNew
    });

    return <ul className={ css.addressChangeList }>{ previous }{ current }</ul>;
};

export default AddressChangeList;