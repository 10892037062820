import { isEmail } from "../core/validator.utils";

export function createValidator () {
    return values => {
        const errors = {};

        if ( !values.email || !values.email.length ) {
            errors.email = "common:validation.required";
        } else if ( !isEmail( values.email ) ) {
            errors.email = "common:validation.email";
        }

        return errors;
    };
}