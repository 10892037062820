import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import throttle from "lodash/throttle";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { fetchImportations } from "../importation/importation.actions";
import { getImportations, isLoadingImportations } from "../importation/importation.selectors";
import { Autocomplete } from "../ui";

const FilterImportation = ({ name, form }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const searchedImportations = useSelector( getImportations );
    const loading = useSelector( isLoadingImportations );

    const [ importations, setImportations ] = useState(
        get( form.initialValues, name ) ? [ get( form.initialValues, name ) ] : []
    );

    const search = throttle(
        name => dispatch( fetchImportations({ name }) ),
        200,
        { leading: false }
    );

    const source = useMemo(
        () => importations
            .reduce( ( option, importation ) => {
                option[ importation.id ] = importation.name;
                return option;
            }, {} ),
        [ importations ]
    );

    const { setFieldValue } = form;

    const selected = useMemo( () => get( form.values, `${name}.id` ), [ form.values, name ] );

    const prevSearchedImportations = usePrevious( searchedImportations );
    const prevSelected = usePrevious( selected );

    useEffect( () => {
        if ( !isEqual( prevSearchedImportations, searchedImportations ) ) {
            setImportations( searchedImportations );
        }
    }, [ searchedImportations, prevSearchedImportations ] );
    useEffect( () => {
        if ( !isEqual( prevSelected, selected ) && !selected ) {
            setFieldValue( name, null );
        }
    }, [ name, prevSelected, selected, setFieldValue ]);

    return (
        <Field
            name={ `${name}.id` }
            label={ t( "form:user-filters.importation.label" ) }
            source={ source }
            variant="outlined"
            component={ Autocomplete }
            getOptionLabel={ option =>
                isObject( option ) ?
                    ( source[ option.value ] || "" ) :
                    option ? ( source[ option ] || "" ) : ""
            }
            loading={ loading }
            multiple={ false }
            onQueryChange={ search }
        />
    );
};

export default FilterImportation;