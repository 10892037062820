export default {
    "customers-list": {
        "title": "Clientes",
        "empty": "Nenhum cliente disponível.",
        "create": "Criar cliente",
        "batch": "Atualizar clientes",
        "columns": "Colunas",
        "select-columns": "Selecionar colunas",
        "filters": "Filtros"
    },
    "batch-options": {
        "title": "Atualização de clientes",
        "description": "Selecione os clientes que você gostaria de atualizar",
        "delete": {
            "non-admin": "Serão excluídos somente os clientes criados por você",
            "confirmation": {
                "description": "Para confirmar digite",
                "label": "excluir clientes",
            }
        },
        "success-title": "Atualização iniciada",
        "success-content": "Você receberá uma notificação quando a atualização estiver concluída"
    },
    "exportation": {
        "title": "Exportação de clientes",
        "call-to-action":
            "Selecione abaixo os campos que você deseja exportar e o formato de arquivo desejado. " +
            "Você será notificado quando a exportação estiver concluída e o arquivo ficará disponível " +
            "por 24 horas.",
        "columns": {
            "available": "Colunas disponíveis",
            "selected": "Colunas selecionadas"
        },
        "format": "Formato do arquivo"
    },
    "generate-opportunities": {
        "action": "Gerar oportunidades",
        "title": "Geração de oportunidades",
        "description":
            "Serão geradas oportunidades para os clientes listados, " +
            "um total de <strong>{ total }</strong> de oportunidades. " +
            "Você será notificado quando a geração " + "for concluída.",
    },
    "new-customer": {
        "title": "Novo cliente"
    },
    "name": "Nome",
    "created-at": "Incluído em",
    "overview": {
        "title": "Visão geral",
        "stats": "Estatísticas",
        "timeline": "Linha do tempo",
        "open-opportunities": "Oportunidades abertas"
    },
    "confirm-delete-customer": "Você tem certeza que deseja excluir esse cliente?",
    "delete-customer": "Excluir cliente",
    "opportunities-panel": {
        "empty": "Não há oportunidades abertas"
    },
    "head": {
        "edit": "Editar",
        "person-type": {
            "NATURAL": "Pessoa física",
            "JURIDICAL": "Pessoa jurídica"
        },
        "follow": "Seguir",
        "following": "Seguindo",
        "unfollow": "Deixar de seguir",
        "confirm-remove-avatar": "Você tem certeza que deseja remover o avatar?",
        "change-avatar": "Alterar avatar",
        "remove-avatar": "Remover avatar"
    },
    "floating-actions": {
        "whatsapp": "WhatsApp",
        "sms": "SMS",
        "phone-call": "Ligação",
        "email": "E-mail"
    },
    "type": "Tipo de cliente",
    "company": {
        "new": "Nova empresa",
        "create": "Criar empresa",
        "title": "Empresa",
        "add-title": "Vincular empresa",
        "confirm-delete": "Você tem certeza que deseja remover o vínculo da empresa com esta pessoa?",
        "confirm-delete-from-opportunity":
            "Você tem certeza que deseja remover o vínculo da oportunidade com esta empresa?"
    },
    "person": {
        "new": "Nova pessoa",
        "create": "Criar pessoa",
        "title": "Pessoa",
        "add-title": "Vincular pessoa",
        "list": "Pessoas",
        "company-persons": "Pessoas da empresa",
        "confirm-delete": "Você tem certeza que deseja remover o vínculo da empresa com esta pessoa?",
        "confirm-delete-from-opportunity":
            "Você tem certeza que deseja remover o vínculo da oportunidade com esta pessoa?"
    },
    "contacts": {
        "add-customer": "Adicionar Cliente",
        "contacts-header": "Contatos do cliente",
        "contact-of-header": "Contato de",
        "empty": "Não há contatos cadastrados",
        "empty-info": "Não há informações para contato cadastradas",
        "title-info": "Informações para contato",
        "confirm-delete": "O contato { contact } será desvinculado do cliente { customer }. Você tem certeza?",
        "type": "Tipo de contato",
        "customer": "Cliente",
        "empty-emails": "Este cliente não possui e-mail(s) cadastrado(s)"
    },
    "files": {
        "title": "Arquivos",
        "confirm-delete": "Você tem certeza que deseja deletar este arquivo?",
        "name": "Nome",
        "full-name": "Nome do arquivo",
        "size": "Tamanho",
        "opportunity": "Oportunidade",
        "uploaded-at": "Enviado em",
        "uploaded-by": "Enviado por",
        "empty": "Não há arquivos enviados",
        "generate": "Gerar documento",
        "model": "Modelo de documento",
        "new-document": "Novo documento"
    },
    "timeline": {
        "types": {
            "CUSTOMER_CREATION": "Criação do cliente",
            "CUSTOMER_CHANGE": "Alteração do cadastro",
            "PHONE_CALL": "Ligações efetuadas",
            "EMAIL": "E-mail",
            "SMS": "SMS enviado",
            "OPPORTUNITY": "Oportunidades",
            "WHATSAPP": "WhatsApp"
        },
        "events": {
            "empty": "Não há dados para exibir"
        },
        "customer-changed": "alterou este cliente",
        "customer-created": "Cliente criado via integração API",
        "customer-created-facebook": "Cliente criado via Facebook",
        "customer-created-exact-sales": "Cliente criado via Exact Sales",
        "customer-created-icarros": "Cliente criado via iCarros",
        "customer-created-leadlovers": "Cliente criado via Leadlovers",
        "customer-created-mercado-libre": "Cliente criado via Mercado Livre",
        "customer-created-mobiauto": "Cliente criado via Mobiauto",
        "customer-created-mkt2easy": "Cliente criado via MKT2Easy",
        "customer-created-rd-station": "Cliente criado via RD Station",
        "customer-created-olx": "Cliente criado via OLX",
        "customer-created-usadosbr": "Cliente criado via Usadosbr",
        "customer-created-webmotors": "Cliente criado via Webmotors",
        "customer-created-user": "criou este cliente",
        "customer-created-lead": "Cliente criado através de um lead",
        "customer-created-lead-user": "criou este cliente através de um lead",
        "customer-created-by-importation": "criou este cliente através de uma importação",
        "customer-created-by-holding": "Cliente criado pelo processo de holding",
        "contacts-changed": "alterou os contatos deste cliente",
        "opportunity": {
            "SOLD": "definiu como ganha a oportunidade ",
            "LOST": "definiu como perdida a oportunidade "
        },
        "email-sent": "enviou um e-mail",
        "email-sent-opportunity": "enviou um e-mail na oportunidade ",
        "email-response-user": "respondeu um e-mail",
        "email-response-customer": "{ customer } respondeu um e-mail",
        "email-read": "E-mail lido",
        "email-not-read": "E-mail não lido",
        "from-migration": "Cliente integrado via migração do { system }",
        "phone-call": "efetuou uma ligação para o telefone { phone }",
        "phone-call-opportunity": "efetuou uma ligação para o telefone { phone } na oportunidade ",
        "sms-sent": "enviou um SMS para o telefone { phone }",
        "sms-sent-opportunity": "enviou um SMS para o telefone { phone } na oportunidade ",
        "whatsapp": "abriu a conversa do WhatsApp do telefone { phone }",
        "whatsapp-opportunity": "abriu a conversa do WhatsApp do telefone { phone }  na oportunidade ",
        "customer-comment": "comentou neste cliente",
        "access-importation": "Accessar importação",
        "integration-fields": {
            "name": "Nome",
            "phone": "Telefone",
            "message": "Mensagem",
            "source": "Origem",
            "company": "Empresa",
            "publicUrl": "URL pública",
            "email": "E-mail",
            "address": "Endereço",
            "state": "Estado",
            "city": "Cidade",
            "personalPhone": "Telefone residencial",
            "mobilePhone": "Telefone celular",
            "job": "Emprego",
            "adId": "Identificador",
            "media": "Midia",
            "brand": "Marca",
            "model": "Modelo",
            "version": "Versão",
            "modelYear": "Ano do modelo",
            "fabricationYear": "Ano de fabricação",
            "color": "Cor",
            "km": "KM",
            "itemId": "Identificação do item",
            "itemTitle": "Descrição do item",
            "price": "Valor",
            "licensePlate": "Placa",
            "dealId": "ID",
            "proposalId": "ID da proposta",
            "trimName": "Modelo completo",
            "url": "URL",
            "score": "Score",
            "hasApprovedCredit": "Crédito aprovado",
            "hasReprovedCredit": "Crédito reprovado",
            "preAnalysisBank": "Banco de pre-análise",
            "preAnalysisStatusNative": {
                "label": "Status pré-análise",
                "APPROVED": "Aprovado",
                "PRE_ANALYZED": "Pré-analisado",
                "REJECTED": "Rejeitado",
            },
            "leadType": "Tipo de lead",
            "analysisDate": "Data de análise",
            "analysisRefDate": "Período de análise",
            "analysisMessage": "Mensagem de análise",
            "vehicle-information": "Informações do veiculo",
            "ein": "CNPJ",
            "ssn": "CPF",
            "company-name": "Nome da empresa",
            "simulation-details": "Detalhes da simulação",
            "products": "Produtos",
            "site": "Site",
        }
    },
    "opportunities": {
        "header": "Oportunidades",
        "empty": "Não há oportunidades cadastradas",
    },
    "kpis": {
        "total-trading": "Total em negociação",
        "total-purchased": "Total vendido",
        "last-contact": "Último contato há",
        "last-purchase": "Última compra há"
    },
    "form": {
        "title": "Cadastro"
    },
    "tags": {
        "title": "Editar tags",
        "label": "Digite",
        "add": "Adicionar tags"
    },
    "notes": {
        "title": "Anotações"
    },
    "map": {
        "title": "Mapa",
        "empty": "Não há endereços cadastrados",
        "not-valid": "Não há endereços válidos cadastrados",
    },
    "new-email": {
        "title": "Enviar e-mail",
        "send": "Enviar",
        "respond": "Responder",
        "respond-all": "Responder para todos",
        "attachment-from-computer": "Anexar arquivo do seu computador",
        "attachment-size": "O tamanho máximo de um anexo é de 15mb",
        "attachment-from-customer": "Anexar arquivo do cliente",
        "attachment-from-opportunity": "Anexar arquivo da oportunidade",
        "attachment-proposal": "Anexar proposta",
        "empty-proposals": "Não há propostas aceitas ou em aberto"
    },
    "email": {
        "to": "Para",
        "from": "De",
        "cc": "Cc",
        "subject": "Assunto",
        "body": "Corpo",
        "attachments": "Anexos",
        "status": {
            "PENDING": "Entrega pendente",
            "DELIVERED": "Entrega realizada",
            "TEMPORARY_FAILURE": "Falha temporária",
            "PERMANENT_FAILURE": "Falha permanente"
        },
        "show-full-body": "Visualizar conteúdo do e-mail",
        "show-previous": "Visualizar e-mail anterior"
    },
    "new-phone-call": {
        "title": "Registrar ligação",
        "phone": "Número",
        "description": "Descrição",
        "empty": "Não há telefones cadastrados"
    },
    "new-sms": {
        "blocked":
            "A integração via SMS não está ativa. Para ativação, é necessário acessar a aba " +
            "Integrações no módulo de Configurações.",
        "title": "Enviar SMS",
        "send": "Enviar"
    },
    "sms": {
        "phone": "Telefone",
        "message": "Mensagem",
        "status": {
            "PENDING": "Pendente",
            "UNDELIVERABLE": "Não recebido",
            "DELIVERED": "Recebido",
            "EXPIRED": "Expirado",
            "REJECTED": "Rejeitado"
        },
        "status-info": "Status do SMS enviado: { status }",
        "received-at": "Resposta recebida em { date }"
    },
    "new-whatsapp": {
        "call-to-action":
            "Escreva uma mensagem inicial (opcional) e " +
            "clique no telefone que deseja abrir a conversa no WhatsApp Web.",
        "message": "Mensagem",
        "empty-phones": "Não há celulares cadastrados"
    },
    "new-comment": {
        "title": "Novo comentário",
        "comment": "Comentário"
    },
    "edit": {
        "label": "Editar",
        "save": "Salvar edição",
        "cancel": "Cancelar edição",
        "error":
            "Não é possível realizar a alteração, pois há campos obrigatórios no formulário " +
            "de cadastro que estão incompletos"
    },
    "not-found": "Cliente não encontrado"
};