export const FETCH_LEAD_DISTRIBUTION = "x/marketplace/FETCH_LEAD_DISTRIBUTION";
export const fetchLeadDistribution = () => ({
    type: FETCH_LEAD_DISTRIBUTION
});

export const REQUEST_LEAD_DISTRIBUTION = "x/marketplace/REQUEST_LEAD_DISTRIBUTION";
export const requestLeadDistribution = () => ({
    type: REQUEST_LEAD_DISTRIBUTION
});

export const RECEIVE_LEAD_DISTRIBUTION = "x/marketplace/RECEIVE_LEAD_DISTRIBUTION";
export const receiveLeadDistribution = data => ({
    type: RECEIVE_LEAD_DISTRIBUTION,
    payload: data
});

export const ERROR_LEAD_DISTRIBUTION = "x/marketplace/ERROR_LEAD_DISTRIBUTION";
export const errorLeadDistribution = error => ({
    type: ERROR_LEAD_DISTRIBUTION,
    error: true,
    payload: error
});

export const SAVE_LEAD_DISTRIBUTION = "x/marketplace/SAVE_LEAD_DISTRIBUTION";
export const saveLeadDistribution = data => ({
    type: SAVE_LEAD_DISTRIBUTION,
    payload: data
});

export const FETCH_LEAD_SOURCES = "x/marketplace/FETCH_LEAD_SOURCES";
export const fetchLeadSources = () => ({
    type: FETCH_LEAD_SOURCES
});

export const REQUEST_LEAD_SOURCES = "x/marketplace/REQUEST_LEAD_SOURCES";
export const requestLeadSources = () => ({
    type: REQUEST_LEAD_SOURCES
});

export const RECEIVE_LEAD_SOURCES = "x/marketplace/RECEIVE_LEAD_SOURCES";
export const receiveLeadSources = data => ({
    type: RECEIVE_LEAD_SOURCES,
    payload: data
});

export const ERROR_LEAD_SOURCES = "x/marketplace/ERROR_LEAD_SOURCES";
export const errorLeadSources = error => ({
    type: ERROR_LEAD_SOURCES,
    error: true,
    payload: error
});

export const SAVE_LEAD_SOURCES = "x/marketplace/SAVE_LEAD_SOURCES";
export const saveLeadSources = data => ({
    type: SAVE_LEAD_SOURCES,
    payload: data
});

export const DELETE_LEAD_SOURCE = "x/marketplace/DELETE_LEAD_SOURCE";
export const deleteLeadSource = id => ({
    type: DELETE_LEAD_SOURCE,
    meta: { id }
});
