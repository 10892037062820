import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const ConversionRateByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState();
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo( () => (
        order( ( results || [] ), [ orderBy ], [ orderType.toLowerCase() ] )
    ), [ results, orderBy, orderType ] );

    const chartData = useMemo( () => ({
        chart: {
            type: "column",
            style: {
                fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
            }
        },
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: t( "report:conversion-rate-by-user.rate" )
            }
        },
        tooltip: {
            formatter: function () {
                return this.x + ": " + ( +( Math.round( this.y + "e+2" ) + "e-2" ) ) + "%";
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: t( "report:conversion-rate-by-user.rate" ),
            data: orderedResults.map( item => item.rate )
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => orderedResults.map( item => ({
        ...item,
        total: item.total + "",
        totalSold: item.totalSold + "",
        rate: `${( +( Math.round( item.rate + "e+2" ) + "e-2" ) )}%`
    })), [ orderedResults ] );

    const model = {
        name: t( "report:conversion-rate-by-user.user" ),
        total: t( "report:conversion-rate-by-user.total" ),
        totalSold: t( "report:conversion-rate-by-user.total-sold" ),
        rate: t( "report:conversion-rate-by-user.rate" )
    };

    const totalFooter = orderedResults.reduce( ( accumulator, item ) => {
        accumulator.total += item.total;
        accumulator.totalSold += item.totalSold;
        return {
            ...accumulator,
            rate: accumulator.total === 0 ? 0 : accumulator.totalSold * 100 / accumulator.total
        };
    }, {
        name: t( "report:conversion-rate-by-user.total" ),
        total: 0,
        totalSold: 0,
        rate: 0
    });

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.CONVERSION_RATE_BY_USER" ) }
                subheader={ t( "report:conversion-rate-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    report={ "CONVERSION_RATE_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    onChange={ handleChange }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="conversionRateByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="conversionRateByUserTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    footer={{
                                        ...totalFooter,
                                        total: totalFooter.total + "",
                                        totalSold: totalFooter.totalSold + "",
                                        rate: ( +( Math.round( totalFooter.rate + "e+2" ) + "e-2" ) ) + "%"
                                    }}
                                    title={ t( "report:reports.CONVERSION_RATE_BY_USER" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default ConversionRateByUser;