import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "formik-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { deleteInvitation } from "./user.actions";
import { createInvitationFormName } from "./user.utils";

const DeleteInvite = ({ invitation }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirm, setConfirm ] = useState( false );

    const toggleConfirm = () => setConfirm( !confirm );

    const formik = useForm({
        form: createInvitationFormName( invitation.id ),
        initialValues: invitation,
        onSubmit: values => dispatch( deleteInvitation( values ) ),
        onSubmitSuccess: () => setConfirm( false ),
    });

    return (
        <div>
            <Tooltip title={ t( "common:exclude" ) }>
                <IconButton disabled={ formik.submitting } onClick={ toggleConfirm } size="large">
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={ confirm }
                onClose={ toggleConfirm }>
                <DialogTitle>
                    { t( "config:delete-invite.title" ) }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t( "config:delete-invite.confirm" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={ toggleConfirm }
                    >
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } >{ t( "config:delete-invite.yes" ) }</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteInvite;