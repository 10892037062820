import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback, useMemo } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../core";
import { fetchSchedulingStatistics } from "./home.actions";
import { getSchedulingStatistics, isLoadingSchedulingStatistics } from "./home.selectors";
import { fetchOpportunitiesCount } from "../opportunity/opportunity.actions";
import { getOpportunitiesCount, isLoadingOpportunitiesCount } from "../opportunity/opportunity.selectors";
import OpportunitiesDialog from "../opportunity/opportunities-dialog.component";
import SchedulesDialog from "./schedules-dialog.component";
import css from "./home-kpis.scss";

const now = dayjs().toDate();
const startOfDay = dayjs().startOf( "day" ).toDate();
const endOfDay = dayjs().endOf( "day" ).toDate();

const HomeKpis = ({ id, className, funnelId, usersId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( coreSelectors.getUser );
    const opportunitiesCreated = useSelector( getOpportunitiesCount );
    const schedulingStatistics = useSelector( getSchedulingStatistics );
    const loadingSchedulingStatistics = useSelector( isLoadingSchedulingStatistics );
    const loadingOpportunitiesCount = useSelector( isLoadingOpportunitiesCount );
    const loading = loadingSchedulingStatistics || loadingOpportunitiesCount;

    const [ opportunitiesDialogTitle, setOpportunitiesDialogTitle ] = useState( "" );
    const [ openOpportunitiesDialog, setOpenOpportunitiesDialog ] = useState( false );
    const [ opportunitiesParams, setOpportunitiesParams ] = useState( null );
    const [ statisticsType, setStatisticsType ] = useState( "" );
    const [ showScheduleDialog, setShowScheduleDialog ] = useState( false );
    const [ initialUsersId, setInitialUsersId ] = useState([]);

    const handleChangeOpportunitiesParams = ( title, params ) => {
        const newOpportunitiesParams = { ...params, funnelId };
        setOpportunitiesParams( newOpportunitiesParams );
        setOpportunitiesDialogTitle( title );
        setOpenOpportunitiesDialog( true );
    };

    const closeOpportunities = () => setOpenOpportunitiesDialog( false );

    const handleClickOpportunitiesEmptyOpenSchedules = () => {
        handleChangeOpportunitiesParams( t( "home:kpis.opportunities-empty-open-schedules.title" ), {
            status: "OPEN",
            hasOpenScheduling: false,
            users: usersId.toString()
        });
    };

    const handleClickNewOpportunities = () => {
        const initialCreatedAt = dayjs().startOf( "day" ).toISOString();
        const finalCreatedAt = dayjs().endOf( "day" ).toISOString();

        handleChangeOpportunitiesParams( t( "home:kpis.new-opportunities.title" ), {
            initialCreatedAt,
            finalCreatedAt,
            users: usersId.toString()
        });
    };

    const handleClickCard = type => () => {
        setStatisticsType( type );
        setShowScheduleDialog( true );
    };

    const toggleShowScheduleDialog = () => {
        setShowScheduleDialog( showScheduleDialog => !showScheduleDialog );
        setStatisticsType( "" );
    };

    const refreshSchedulingStatistics = useCallback( () => {
        dispatch( fetchSchedulingStatistics({ usersId: usersId.toString() }) );
    }, [ dispatch, usersId ] );

    const getParamsByStatisticsType = useCallback( type => {
        switch ( type ) {
            case "TODAY":
                return {
                    title: t( "home:kpis.open-schedules.title" ),
                    startDate: startOfDay,
                    endDate: endOfDay,
                    usersId: user.admin ? [ user.id ] : usersId,
                };
            case "TODAY_OTHER_USERS":
                return {
                    title: t( "home:kpis.open-schedules-other-users.title" ),
                    startDate: startOfDay,
                    endDate: endOfDay,
                    usersId: ( isEmpty( usersId ) ? initialUsersId : usersId ).filter( userId => userId !== user.id ),
                    isOtherUsers: true
                };
            case "LATE":
                return {
                    title: t( "home:kpis.late-schedules.title" ),
                    startDate: now,
                    usersId: user.admin ? [ user.id ] : usersId,
                    started: true
                };
            case "LATE_OTHER_USERS":
                return {
                    title: t( "home:kpis.late-schedules-other-users.title" ),
                    startDate: now,
                    usersId: ( isEmpty( usersId ) ? initialUsersId : usersId ).filter( userId => userId !== user.id ),
                    isOtherUsers: true,
                    started: true
                };
            default:
                return {};
        }
    }, [ user, usersId, t, initialUsersId ] );

    const schedulesDialogProps = useMemo( () => {
        const defaultProps = {
            open: true,
            onClose: toggleShowScheduleDialog,
            refreshSchedulingStatistics,
        };

        return {
            ...defaultProps,
            ...getParamsByStatisticsType( statisticsType )
        };
    }, [ statisticsType, refreshSchedulingStatistics, getParamsByStatisticsType ]);

    useEffect( () => {
        dispatch( fetchSchedulingStatistics({ usersId: usersId.toString() }) );
        dispatch( fetchOpportunitiesCount({
            usersId: usersId.toString(),
            initialCreatedAt: dayjs().startOf( "day" ).toISOString(),
            finalCreatedAt: dayjs().endOf( "day" ).toISOString(),
            ignoreUserPreferences: true,
        }) );
    }, [ dispatch, usersId ] );

    useEffect( () => {
        if ( isEmpty( initialUsersId ) && !isEmpty( usersId ) ) {
            setInitialUsersId( usersId );
        }
    }, [ usersId, initialUsersId ] );

    return (
        <div id={ id } className={ className }>
            {
                !user || !user.admin ?
                    <Tooltip title={ t( "home:kpis.open-schedules.help" ) }>
                        <Card className={ css.kpiContainer } square>
                            <CardActionArea
                                onClick={ handleClickCard( "TODAY" ) }
                                className={ classnames( css.kpi, css.kpiOpenSchedules ) }
                                disabled={ !schedulingStatistics || !schedulingStatistics.today }
                            >
                                <Typography variant="body2">
                                    { t( "home:kpis.open-schedules.title" ) }
                                </Typography>
                                <Typography variant="h4">
                                    { loading || !schedulingStatistics ? "-" : schedulingStatistics.today }
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Tooltip> :
                    <div className={ css.kpiContainerAdmin }>
                        <Tooltip title={ t( "home:kpis.open-schedules.help" ) }>
                            <Card className={ css.kpiContainer } square>
                                <CardActionArea
                                    onClick={ handleClickCard( "TODAY" ) }
                                    className={ classnames( css.kpi, css.kpiOpenSchedules ) }
                                    disabled={ !schedulingStatistics || !schedulingStatistics.today }
                                >
                                    <Typography variant="body2">
                                        { t( "home:kpis.open-schedules.title" ) }
                                    </Typography>
                                    <Typography variant="h4">
                                        { loading || !schedulingStatistics ? "-" : schedulingStatistics.today }
                                    </Typography>
                                </CardActionArea>
                            </Card>
                        </Tooltip>
                        <Tooltip title={ t( "home:kpis.open-schedules-other-users.help" ) }>
                            <Card className={ css.kpiContainer } square>
                                <CardActionArea
                                    onClick={ handleClickCard( "TODAY_OTHER_USERS" ) }
                                    className={ classnames( css.kpi, css.kpiOpenSchedulesOtherUsers ) }
                                    disabled={ !schedulingStatistics || !schedulingStatistics.todayOtherUsers }
                                >
                                    <Typography variant="body2">
                                        { t( "home:kpis.open-schedules-other-users.title" ) }
                                    </Typography>
                                    <Typography variant="h4">
                                        {
                                            loading || !schedulingStatistics ?
                                                "-" :
                                                schedulingStatistics.todayOtherUsers
                                        }
                                    </Typography>
                                </CardActionArea>
                            </Card>
                        </Tooltip>
                    </div>
            }
            {
                !user || !user.admin ?
                    <Tooltip title={ t( "home:kpis.late-schedules.help" ) }>
                        <Card className={ css.kpiContainer } square>
                            <CardActionArea
                                onClick={ handleClickCard( "LATE" ) }
                                className={ classnames( css.kpi, css.kpiLateSchedules ) }
                                disabled={ !schedulingStatistics || !schedulingStatistics.late }
                            >
                                <Typography variant="body2">
                                    { t( "home:kpis.late-schedules.title" ) }
                                </Typography>
                                <Typography variant="h4">
                                    { loading || !schedulingStatistics ? "-" : schedulingStatistics.late }
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Tooltip> :
                    <div className={ css.kpiContainerAdmin }>
                        <Tooltip title={ t( "home:kpis.late-schedules.help" ) }>
                            <Card className={ css.kpiContainer } square>
                                <CardActionArea
                                    onClick={ handleClickCard( "LATE" ) }
                                    className={ classnames( css.kpi, css.kpiLateSchedules ) }
                                    disabled={ !schedulingStatistics || !schedulingStatistics.late }
                                >
                                    <Typography variant="body2">
                                        { t( "home:kpis.late-schedules.title" ) }
                                    </Typography>
                                    <Typography variant="h4">
                                        { loading || !schedulingStatistics ? "-" : schedulingStatistics.late }
                                    </Typography>
                                </CardActionArea>
                            </Card>
                        </Tooltip>
                        <Tooltip title={ t( "home:kpis.late-schedules-other-users.help" ) }>
                            <Card className={ css.kpiContainer } square>
                                <CardActionArea
                                    onClick={ handleClickCard( "LATE_OTHER_USERS" )}
                                    className={ classnames( css.kpi, css.kpiLateSchedulesOtherUsers ) }
                                    disabled={ !schedulingStatistics || !schedulingStatistics.lateOtherUsers }
                                >
                                    <Typography variant="body2">
                                        { t( "home:kpis.late-schedules-other-users.title" ) }
                                    </Typography>
                                    <Typography variant="h4">
                                        {
                                            loading || !schedulingStatistics ?
                                                "-" :
                                                schedulingStatistics.lateOtherUsers
                                        }
                                    </Typography>
                                </CardActionArea>
                            </Card>
                        </Tooltip>
                    </div>
            }
            <Card className={ css.kpiContainer } square>
                <Tooltip title={ t( "home:kpis.opportunities-empty-open-schedules.help" ) }>
                    <CardActionArea
                        className={ classnames( css.kpi, css.kpiOpportunitiesEmptyOpenSchedules ) }
                        onClick={ handleClickOpportunitiesEmptyOpenSchedules }
                    >
                        <Typography variant={ user.admin ? "body2" : "body1" }>
                            { t( "home:kpis.opportunities-empty-open-schedules.title" ) }
                        </Typography>
                        <Typography variant="h4">
                            { loading || !schedulingStatistics ? "-" : schedulingStatistics.openOpportunitiesEmpty }
                        </Typography>
                    </CardActionArea>
                </Tooltip>
            </Card>
            <Card className={ css.kpiContainer } square>
                <Tooltip title={ t( "home:kpis.new-opportunities.help" ) }>
                    <CardActionArea
                        className={ classnames( css.kpi, css.kpiNewOpportunities ) }
                        onClick={ handleClickNewOpportunities }
                    >
                        <Typography variant={ user.admin ? "body2" : "body1" }>
                            { t( "home:kpis.new-opportunities.title" ) }
                        </Typography>
                        <Typography variant="h4">
                            { loading || opportunitiesCreated === undefined ? "-" : opportunitiesCreated }
                        </Typography>
                    </CardActionArea>
                </Tooltip>
            </Card>

            <OpportunitiesDialog
                open={ openOpportunitiesDialog }
                onClose= { closeOpportunities }
                title={ opportunitiesDialogTitle }
                params={ opportunitiesParams }
            />
            {
                showScheduleDialog && <SchedulesDialog { ...schedulesDialogProps }/>
            }
        </div>

    );
};

export default HomeKpis;