import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { flex } from "../../ui";
import css from "./phases.scss";

const OpportunityItemCustomer = ({ name, icon }) => {

    return (
        <div
            className={
                classnames(
                    flex.container,
                    flex.alignItemsCenter,
                    css.opportunityInfo,
                )
            }
        >
            { icon }
            <Typography
                variant="caption"
                className={ flex.fill }
            >
                { name }
            </Typography>
        </div>
    );
};

export default OpportunityItemCustomer;