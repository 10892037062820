// ---------------------------------------------------------------------------------------------------------------------
// Invitation in acceptance
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_INVITATION = "x/FETCH_INVITATION";
export const fetchInvitation = id => ({
    type: FETCH_INVITATION,
    meta: { id }
});

export const SET_INVITATION = "x/SET_INVITATION";
export const setInvitation = invitation => ({
    type: SET_INVITATION,
    payload: invitation
});

export const ERROR_INVITATION = "x/ERROR_INVITATION";
export const errorInvitation = error => ({
    type: ERROR_INVITATION,
    error: true,
    payload: error
});

export const ACCEPT_INVITATION = "x/ACCEPT_INVITATION";
export const acceptInvitation = data => ({
    type: ACCEPT_INVITATION,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Invitations list
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_INVITATIONS = "x/FETCH_INVITATIONS";
export const fetchInvitations = () => ({
    type: FETCH_INVITATIONS
});

export const REQUEST_INVITATIONS = "x/REQUEST_INVITATIONS";
export const requestInvitations = () => ({
    type: REQUEST_INVITATIONS
});

export const RECEIVE_INVITATIONS = "x/RECEIVE_INVITATIONS";
export const receiveInvitations = invitations => ({
    type: RECEIVE_INVITATIONS,
    payload: { invitations }
});

export const ERROR_INVITATIONS = "x/ERROR_INVITATIONS";
export const errorInvitations = error => ({
    type: ERROR_INVITATIONS,
    payload: error,
    error: true
});