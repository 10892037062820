import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import classnames from "classnames";
import get from "lodash/get";

import history from "../../history";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { fetchImportation, fetchResults, setImportation } from "./importation.actions";
import { getSelected, getResults, isLoadingSelected, isLoadingResults } from "./importation.selectors";
import { cssUtils, flex, Fab } from "../ui";
import ImportationDelete from "./importation-delete.component";
import css from "./importation.scss";

const ImportationResults = ({ className }) => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const importation = useSelector( getSelected );
    const results = useSelector( getResults );
    const loadingSelected = useSelector( isLoadingSelected );
    const loadingResults = useSelector( isLoadingResults );
    const loading = useMemo( () => loadingSelected || loadingResults, [ loadingSelected, loadingResults ] );

    const prevLoading = usePrevious( loading );

    useEffect( () => {
        dispatch( fetchImportation( params.id ) );
        dispatch( fetchResults( params.id ) );
    }, [ dispatch, params.id ] );
    useEffect( () => {
        if ( prevLoading && !loading && !importation.finishedAt ) {
            setTimeout( () => {
                dispatch( fetchImportation( params.id ) );
                dispatch( fetchResults( params.id ) );
            }, 2000 );
        }
    }, [ dispatch, importation.finishedAt, loading, params.id, prevLoading ] );
    useEffect( () => {
        return () => {
            dispatch( setImportation( null ) );
        };
    }, [ dispatch ] );

    if ( loading || !importation || !results ) {
        return <CircularProgress/>;
    }

    return (
        <div className={ className }>
            <Helmet title={ t( "importation:title" ) } />
            <Card>
                <CardHeader
                    title={ importation.name }
                    subheader={ importation.file }
                    action={ importation.finishedAt && <ImportationDelete importation={ importation } /> }
                />
            </Card>

            <Card className={ cssUtils.marginTopSmall }>
                {
                    importation.finishedAt ?
                        <CardContent className={ cssUtils.textCenter }>
                            <CheckIcon className={ css.successIcon }/>
                            <Typography variant="h5" className={ css.successMessage }>
                                { t(
                                    "importation:importation-results.success-message",
                                    {
                                        date: dateFormat( importation.finishedAt ),
                                        interpolation: { escapeValue: false }
                                    }
                                )}
                            </Typography>
                        </CardContent> :
                        <Fragment>
                            <CardHeader
                                subheader={ t( "importation:importation-results.executing" ) }
                            />
                            <LinearProgress/>
                        </Fragment>

                }
            </Card>

            <Card className={ cssUtils.marginTopSmall }>
                <div className={ flex.container }>
                    <div className={ classnames( css.result, css.customersCreated ) }>
                        <Typography>{ t( "importation:importation-results.customers-created" ) }</Typography>
                        <Typography variant="h5">
                            { ( get( results, "customersCreated", [] ) || [] ).length }
                        </Typography>
                    </div>
                    <div className={ classnames( css.result, css.customersUpdated ) }>
                        <Typography>{ t( "importation:importation-results.customers-updated" ) }</Typography>
                        <Typography variant="h5">
                            { ( get( results, "customersUpdated", [] ) || [] ).length }
                        </Typography>
                    </div>
                    <Tooltip title={ t( "importation:importation-results.customers-skipped-download" ) }>
                        <a
                            href={
                                "/api/v1/importations/" + importation.id +
                                "/results/download?type=CUSTOMER"
                            }
                            className={ classnames( css.result, css.customersSkipped ) }
                        >
                            <Typography>{ t( "importation:importation-results.customers-skipped" ) }</Typography>
                            <Typography variant="h5">
                                { ( get( results, "skipped.customers", [] ) || [] ).length }
                            </Typography>
                            <CloudDownloadIcon className={ css.resultDownload }/>
                        </a>
                    </Tooltip>
                    <div className={ classnames( css.result, css.opportunitiesCreated ) }>
                        <Typography>{ t( "importation:importation-results.opportunities-created" ) }</Typography>
                        <Typography variant="h5">
                            { ( get( results, "opportunitiesCreated", [] ) || [] ).length }
                        </Typography>
                    </div>
                    <Tooltip title={ t( "importation:importation-results.opportunities-skipped-download" ) }>
                        <a
                            href={ `/api/v1/importations/${importation.id}/results/download?type=OPPORTUNITY` }
                            className={ classnames( css.result, css.opportunitiesSkipped ) }
                        >
                            <Typography>
                                { t( "importation:importation-results.opportunities-skipped" ) }
                            </Typography>
                            <Typography variant="h5">
                                { ( get( results, "skipped.opportunities", [] ) || [] ).length }
                            </Typography>
                            <CloudDownloadIcon className={ css.resultDownload }/>
                        </a>
                    </Tooltip>
                </div>
            </Card>

            <Fab
                onClick={ () => history.push( "/importations/new" ) }
                position="bottomRight"
            >
                <AddIcon/>
            </Fab>
        </div>
    );
};

export default ImportationResults;