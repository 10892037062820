import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteSmptServerDefault } from "./smtp-server.actions";
import { DELETE_SMTP_SERVER_DEFAULT_FORM } from "./smtp-server.constants";

const DeleteSmtpServerDefault = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ open, setOpen ] = useState( false );

    const formik = useForm({
        form: DELETE_SMTP_SERVER_DEFAULT_FORM,
        initialValues: {},
        onSubmit: () => dispatch( deleteSmptServerDefault() ),
    });

    const handleClose = () => setOpen( false );
    const handleOpen = () => setOpen( true );

    return (
        <>
            <IconButton onClick={ handleOpen } size="large">
                <DeleteIcon/>
            </IconButton>

            <DialogConfirmForm
                open={ open }
                handleSubmit={ formik.handleSubmit }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
                message={ t( "config:smtp-server-default.confirm-delete" ) }
                onClose={ handleClose }
            />
        </>
    );
};

export default DeleteSmtpServerDefault;