import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import css from "./helper.scss";

const Helper = ({ message }) => (
    <Tooltip title={ message }>
        <InfoIcon className={ css.icon }/>
    </Tooltip>
);

export default Helper;