import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv2 } from "../../../api/sagas";
import * as actions from "./brand.actions";

export function *watchBrands () {
    yield all([
        takeEvery( actions.FETCH_BRANDS, fetchBrands ),
    ]);
}

export function *fetchBrands ({ meta: { brandType } }) {
    yield put( actions.requestBrands( brandType ) );
    try {
        const response = yield call( apiv2.get, `/brands/${brandType}` );
        yield put( actions.receiveBrands( brandType, response.data ) );
    } catch ( e ) {
        yield put( actions.errorBrands( brandType, e.response.data ) );
    }
}