import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { app, apiv1 } from "../../api/sagas";
import history from "../../history";
import { loadInfo } from "../core/core.actions";
import { fetchToken } from "../mailtop/mailtop.actions";
import tracker from "../util/tracker.utils";
import {
    LOGIN_FORM,
    FORGOT_PASSWORD_FORM,
    RESET_PASSWORD_FORM,
    TWO_FACTOR_VERIFICATION_FORM,
    createSubmit,
    actions
} from "./";

export function *loginSubmit ( action ) {
    yield put( startSubmit( LOGIN_FORM ) );
    try {
        const oauthGrant = yield call( createSubmit, action.payload.credentials );
        let nextUrl;
        if ( !oauthGrant.verified ) {
            nextUrl = `/login/verify/${oauthGrant.accessToken}`;
        } else {
            const preferences = yield call( app.get, "/users/me/preferences" );
            yield put( loadInfo() );
            yield put( actions.setAuth( true ) );
            yield put( fetchToken() );
            nextUrl = action.payload.nextUrl && action.payload.nextUrl !== "/" ?
                action.payload.nextUrl :
                preferences.data.indexRoute;
        }
        tracker.logAction( "Login" );
        yield put( stopSubmit( LOGIN_FORM ) );
        yield call( [ history, history.push ], nextUrl );
    } catch ( e ) {
        yield put( stopSubmit( LOGIN_FORM, e ) );
    }
}

function *requestActivate ({ payload }) {
    yield put( startSubmit( LOGIN_FORM ) );
    try {
        yield call( app.post, "/subscription/active", payload );
        yield put( stopSubmit( LOGIN_FORM ) );
        yield put( reset( LOGIN_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( LOGIN_FORM, e.response.data ) );
    }
}

function *verifyAccessToken ({ payload }) {
    yield put( startSubmit( TWO_FACTOR_VERIFICATION_FORM ) );
    try {
        yield call( app.post, "/login/verify", payload );
        const preferences = yield call( apiv1.get, "/users/me/preferences" );
        yield put( loadInfo() );
        yield put( actions.setAuth( true ) );
        yield put( stopSubmit( TWO_FACTOR_VERIFICATION_FORM ) );
        yield put( fetchToken() );
        const nextUrl = `/${preferences.data.indexRoute}`;
        yield call( [ history, history.push ], nextUrl );
    } catch ( e ) {
        yield put( stopSubmit( TWO_FACTOR_VERIFICATION_FORM, e.response.data.error ) );
    }
}

export function *sendResetPassword ({ payload: { email } }) {
    yield put( startSubmit( FORGOT_PASSWORD_FORM ) );
    try {
        yield call( app.post, `/users/${email}/resetpassword/` );
        yield put( stopSubmit( FORGOT_PASSWORD_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( FORGOT_PASSWORD_FORM, e.response.data ) );
    }
}

export function *fetchPasswordResetStatus ({ meta: { id } }) {
    try {
        const response = yield call( app.get, `/passwordreset/${id}` );
        yield put( actions.setPasswordResetStatus( response.status ) );
    } catch ( e ) {
        yield put( actions.setPasswordResetStatus( e.response.status ) );
    }
}

export function *savePasswordReset ({ meta: { id }, payload }) {
    yield put( startSubmit( RESET_PASSWORD_FORM ) );
    try {
        yield call( app.post, `/users/resetpassword/${id}`, payload );
        yield put( stopSubmit( RESET_PASSWORD_FORM ) );
        yield call( [ history, history.push ], "/login" );
    } catch ( e ) {
        yield put( stopSubmit( RESET_PASSWORD_FORM, e.response.data ) );
    }
}

export function *watchLoginSubmit () {
    yield all([
        takeEvery( actions.LOGIN_SUBMIT, loginSubmit ),
        takeEvery( actions.REQUEST_ACTIVATE, requestActivate ),
        takeEvery( actions.VERIFY_ACCESS_TOKEN, verifyAccessToken ),
        takeEvery( actions.SEND_RESET_PASSWORD, sendResetPassword ),
        takeEvery( actions.FETCH_PASSWORD_RESET_STATUS, fetchPasswordResetStatus ),
        takeEvery( actions.SAVE_PASSWORD_RESET, savePasswordReset )
    ]);
}