import { useCallback } from "react";
import { useSelector } from "react-redux";

import usePriceFormat from "../hooks/use-price-format.hook";
import { list as listFields } from "../form/form.selectors";

export const variables = [
    { value: "%%CUSTOMERNAME%%", label: "ui:template.customer-name.label" },
    { value: "%%BRANDMODEL%%", label: "ui:template.brand-model.label" },
    { value: "%%PRICE%%", label: "opportunity:price.label" },
    { value: "%%RECURRENTPRICE%%", label: "opportunity:recurrent-price" },
];

export function useVariablesFormat () {
    const priceFormat = usePriceFormat();

    const opportunityFields = useSelector( listFields( "OPPORTUNITY" ) );

    const formatter = useCallback( ( customer, opportunity, text ) => {
        text = text.replace( /%%CUSTOMERNAME%%/g, customer?.name || "" );
        text = text.replace( /%%PRICE%%/g, priceFormat( opportunity?.price ) );
        text = text.replace( /%%RECURRENTPRICE%%/g, priceFormat( opportunity?.recurrentPrice ) );

        let brandModel = "";
        const brandField = opportunityFields.find( field => field.systemField === "BRAND" );
        if ( brandField ) {
            brandModel += opportunity?.fields[ brandField.id ] || "";

            if ( brandModel ) {
                const modelField = opportunityFields.find( field => field.systemField === "MODEL" );
                brandModel += " " + ( opportunity?.fields[ modelField.id ] || "" );
            }
        }
        text = text.replace( /%%BRANDMODEL%%/g, brandModel );
        return text;
    }, [ priceFormat, opportunityFields ] );

    return formatter;
}
