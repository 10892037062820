import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, cssUtils, flex } from "../.";
import { fetchBrands } from "./brand.actions";
import { getBrands } from "./brand.selectors";
import css from "./brand.scss";

const BrandsSelector = ({ brandTypes = [], onChange, selectedBrands = [] }) => {
    const dispatch = useDispatch();

    const allBrands = useSelector( getBrands );

    const brands = useMemo( () => {
        return brandTypes.map( brandType => allBrands[ brandType ]?.data ).filter( data => !!data ).flat();
    }, [ brandTypes, allBrands ] );

    const handleChange = useCallback( brand => event => {
        onChange( brand, event.target.checked );
    }, [ onChange ] );

    useEffect( () => {
        if ( brandTypes ) {
            brandTypes.forEach( brandType => dispatch( fetchBrands( brandType ) ) );
        }
    }, [ dispatch, brandTypes ] );

    return (
        <div className={ flex.container }>
            {
                brands.map( ( brand, index ) => (
                    <div key={ index } className={ css.brandItem }>
                        <Checkbox
                            label={ brand }
                            className={ cssUtils.paddingNone }
                            fullWidth={ false }
                            onChange={ handleChange( brand )}
                            checked={ selectedBrands.includes( brand ) }
                        />
                    </div>
                ))
            }
        </div>
    );
};

export default BrandsSelector;