export const FETCH_FACEBOOK_LEAD_DISTRIBUTION = "x/marketplace/FETCH_FACEBOOK_LEAD_DISTRIBUTION";
export const fetchFacebookLeadDistribution = () => ({
    type: FETCH_FACEBOOK_LEAD_DISTRIBUTION
});

export const REQUEST_FACEBOOK_LEAD_DISTRIBUTION = "x/marketplace/REQUEST_FACEBOOK_LEAD_DISTRIBUTION";
export const requestFacebookLeadDistribution = () => ({
    type: REQUEST_FACEBOOK_LEAD_DISTRIBUTION
});

export const RECEIVE_FACEBOOK_LEAD_DISTRIBUTION = "x/marketplace/RECEIVE_FACEBOOK_LEAD_DISTRIBUTION";
export const receiveFacebookLeadDistribution = data => ({
    type: RECEIVE_FACEBOOK_LEAD_DISTRIBUTION,
    payload: data
});

export const ERROR_FACEBOOK_LEAD_DISTRIBUTION = "x/marketplace/ERROR_FACEBOOK_LEAD_DISTRIBUTION";
export const errorFacebookLeadDistribution = error => ({
    type: ERROR_FACEBOOK_LEAD_DISTRIBUTION,
    error: true,
    payload: error
});

export const SAVE_FACEBOOK_LEAD_DISTRIBUTION = "x/marketplace/SAVE_FACEBOOK_LEAD_DISTRIBUTION";
export const saveFacebookLeadDistribution = data => ({
    type: SAVE_FACEBOOK_LEAD_DISTRIBUTION,
    payload: data
});

export const FETCH_FACEBOOK_LEAD_PAGES = "x/marketplace/FETCH_FACEBOOK_LEAD_PAGES";
export const fetchFacebookLeadPages = () => ({
    type: FETCH_FACEBOOK_LEAD_PAGES
});

export const REQUEST_FACEBOOK_LEAD_PAGES = "x/marketplace/REQUEST_FACEBOOK_LEAD_PAGES";
export const requestFacebookLeadPages = () => ({
    type: REQUEST_FACEBOOK_LEAD_PAGES
});

export const RECEIVE_FACEBOOK_LEAD_PAGES = "x/marketplace/RECEIVE_FACEBOOK_LEAD_PAGES";
export const receiveFacebookLeadPages = data => ({
    type: RECEIVE_FACEBOOK_LEAD_PAGES,
    payload: data
});

export const ERROR_FACEBOOK_LEAD_PAGES = "x/marketplace/ERROR_FACEBOOK_LEAD_PAGES";
export const errorFacebookLeadPages = error => ({
    type: ERROR_FACEBOOK_LEAD_PAGES,
    error: true,
    payload: error
});

export const CREATE_FACEBOOK_LEAD_PAGE = "x/marketplace/CREATE_FACEBOOK_LEAD_PAGE";
export const createFacebookLeadPage = data => ({
    type: CREATE_FACEBOOK_LEAD_PAGE,
    payload: data
});

export const UPDATE_FACEBOOK_LEAD_PAGE = "x/marketplace/UPDATE_FACEBOOK_LEAD_PAGE";
export const updateFacebookLeadPage = ( form, id ) => data => ({
    type: UPDATE_FACEBOOK_LEAD_PAGE,
    meta: { form, id },
    payload: data
});

export const SAVE_FACEBOOK_LEAD_PAGES = "x/marketplace/SAVE_FACEBOOK_LEAD_PAGES";
export const saveFacebookLeadPages = data => ({
    type: SAVE_FACEBOOK_LEAD_PAGES,
    payload: data
});

export const EDIT_FACEBOOK_LEAD_PAGE = "x/marketplace/EDIT_FACEBOOK_LEAD_PAGE";
export const editFacebookLeadPage = ({ id, ...data }) => ({
    type: EDIT_FACEBOOK_LEAD_PAGE,
    meta: { id },
    payload: data
});

export const DELETE_FACEBOOK_LEAD_PAGE = "x/marketplace/DELETE_FACEBOOK_LEAD_PAGE";
export const deleteFacebookLeadPage = id => ({
    type: DELETE_FACEBOOK_LEAD_PAGE,
    meta: { id }
});

// FACEBOOK USER
export const SET_FACEBOOK_TOKEN = "x/marketplace/SET_FACEBOOK_TOKEN";
export const setFacebookToken = data => ({
    type: SET_FACEBOOK_TOKEN,
    payload: data
});

export const REQUEST_FACEBOOK_TOKEN = "x/marketplace/REQUEST_FACEBOOK_TOKEN";
export const requestFacebookToken = () => ({
    type: REQUEST_FACEBOOK_TOKEN
});

export const ERROR_FACEBOOK_TOKEN = "x/marketplace/ERROR_FACEBOOK_TOKEN";
export const errorFacebookToken = error => ({
    type: ERROR_FACEBOOK_TOKEN,
    payload: error,
    error: true
});

export const FETCH_FACEBOOK_TOKEN = "x/marketplace/FETCH_FACEBOOK_TOKEN";
export const fetchFacebookToken = data => ({
    type: FETCH_FACEBOOK_TOKEN,
    payload: data
});

export const REQUEST_FACEBOOK_ACCOUNT_PAGES = "x/marketplace/REQUEST_FACEBOOK_ACCOUNT_PAGES";
export const requestFacebookAccountPages = () => ({
    type: REQUEST_FACEBOOK_ACCOUNT_PAGES
});

export const RECEIVE_FACEBOOK_ACCOUNT_PAGES = "x/marketplace/RECEIVE_FACEBOOK_ACCOUNT_PAGES";
export const receiveFacebookAccountPages = data => ({
    type: RECEIVE_FACEBOOK_ACCOUNT_PAGES,
    payload: data
});

export const ERROR_FACEBOOK_ACCOUNT_PAGES = "x/marketplace/ERROR_FACEBOOK_ACCOUNT_PAGES";
export const errorFacebookAccountPages = error => ({
    type: ERROR_FACEBOOK_ACCOUNT_PAGES,
    payload: error,
    error: true
});

export const FETCH_FACEBOOK_ACCOUNT_PAGES = "x/marketplace/FETCH_FACEBOOK_ACCOUNT_PAGES";
export const fetchFacebookAccountPages = data => ({
    type: FETCH_FACEBOOK_ACCOUNT_PAGES,
    payload: data
});

export const REQUEST_FACEBOOK_PAGE_LEADGEN_FORMS = "x/marketplace/REQUEST_FACEBOOK_PAGE_LEADGEN_FORMS";
export const requestFacebookPageLeadgenForms = () => ({
    type: REQUEST_FACEBOOK_PAGE_LEADGEN_FORMS
});

export const RECEIVE_FACEBOOK_PAGE_LEADGEN_FORMS = "x/marketplace/RECEIVE_FACEBOOK_PAGE_LEADGEN_FORMS";
export const receiveFacebookPageLeadgenForms = data => ({
    type: RECEIVE_FACEBOOK_PAGE_LEADGEN_FORMS,
    payload: data
});

export const ERROR_FACEBOOK_PAGE_LEADGEN_FORMS = "x/marketplace/ERROR_FACEBOOK_PAGE_LEADGEN_FORMS";
export const errorFacebookPageLeadgenForms = error => ({
    type: ERROR_FACEBOOK_PAGE_LEADGEN_FORMS,
    payload: error,
    error: true
});

export const FETCH_FACEBOOK_PAGE_LEADGEN_FORMS = "x/marketplace/FETCH_FACEBOOK_PAGE_LEADGEN_FORMS";
export const fetchFacebookPageLeadgenForms = data => ({
    type: FETCH_FACEBOOK_PAGE_LEADGEN_FORMS,
    payload: data
});

export const FETCH_FACEBOOK_PAGE = "x/marketplace/FETCH_FACEBOOK_PAGE";
export const fetchFacebookPage = data => ({
    type: FETCH_FACEBOOK_PAGE,
    payload: data
});

export const REQUEST_FACEBOOK_PAGE = "x/marketplace/REQUEST_FACEBOOK_PAGE";
export const requestFacebookPage = () => ({
    type: REQUEST_FACEBOOK_PAGE
});

export const RECEIVE_FACEBOOK_PAGE = "x/marketplace/RECEIVE_FACEBOOK_PAGE";
export const receiveFacebookPage = data => ({
    type: RECEIVE_FACEBOOK_PAGE,
    payload: data
});

export const ERROR_FACEBOOK_PAGE = "x/marketplace/ERROR_FACEBOOK_PAGE";
export const errorFacebookPage = error => ({
    type: ERROR_FACEBOOK_PAGE,
    payload: error,
    error: true
});
