import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./importation.actions";

export const all = ( state = {}, action ) => {

    switch ( action.type ) {
        case actions.REQUEST_IMPORTATIONS:
            return Object.assign( {}, state, {
                loading: true
            });

        case actions.RECEIVE_IMPORTATIONS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload.importations,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            });

        case actions.ERROR_IMPORTATIONS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
};

export const selected = createRequestReducer({
    begin: actions.REQUEST_IMPORTATION,
    success: actions.SET_IMPORTATION,
    failure: actions.ERROR_IMPORTATION
});

export const results = createRequestReducer({
    begin: actions.REQUEST_RESULTS,
    success: actions.RECEIVE_RESULTS,
    failure: actions.ERROR_RESULTS
});

export const importations = combineReducers({ all, selected, results });