import { useState } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirm } from "../../../ui";
import { deleteGoal } from "./goal.actions";

function GoalItemActions ({ goal, onSelect }) {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ buttonMenu, setButtonMenu ] = useState();
    const [ isDeleteOpen, setIsDeleteOpen ] = useState( false );

    const openMenu = event => setButtonMenu( event.currentTarget );
    const closeMenu = () => setButtonMenu( null );
    const handleOpenDelete = () => {
        closeMenu();
        setIsDeleteOpen( true );
    };
    const handleCloseDelete = () => setIsDeleteOpen( false );

    const handleDelete = () => {
        dispatch( deleteGoal( goal.id ) );
        handleCloseDelete();
    };

    const handleSelect = () => {
        onSelect( goal );
        closeMenu();
    };

    return <>
        <IconButton onClick={ openMenu } color="inherit" size="large">
            <MoreVertIcon />
        </IconButton>
        <Menu
            open={ !!buttonMenu }
            onClose={ closeMenu }
            anchorEl={ buttonMenu }
        >
            <MenuItem onClick={ handleSelect }>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={ t("common:edit") } />
            </MenuItem>
            <MenuItem onClick={ handleOpenDelete }>
                <ListItemIcon>
                    <DeleteIcon/>
                </ListItemIcon>
                <ListItemText primary={ t( "common:exclude" ) } />
            </MenuItem>
        </Menu>

        <DialogConfirm
            open={ isDeleteOpen }
            message={ t( "config:goal.confirm-delete" ) }
            onCancel={ handleCloseDelete }
            onConfirm={ handleDelete }
        />
    </>;

}

export default GoalItemActions;