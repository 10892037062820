import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListSubHeader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Snackbar from "@mui/material/Snackbar";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AttachMoney from "@mui/icons-material/AttachMoney";
import classnames from "classnames";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../core";
import { actions as sourceActions } from "../source";
import { selectors as formSelectors, fieldIcons } from "../form";
import { fetchForm } from "../form/form.actions";
import { actions as userActions, selectors as userSelectors } from "../user";
import { saveNewImportation } from "./importation.actions";
import { NEW_IMPORTATION_FORM } from "./importation.constants";
import {
    CountryFlags,
    Input,
    cssUtils,
    flex
} from "../ui";
import CustomerConfig from "./customer-config.component";
import OpportunityConfig from "./opportunity-config.component";
import FileConfig from "./file-config.component";
import MappingFile from "./mapping-file.component";

const validate = values => {
    const errors = {};

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( !values.person && !values.company ) {
        errors.person = "importation:new-importation.select-customer";
        errors.company = "importation:new-importation.select-customer";
    }

    if ( values.createOpportunity ) {
        if ( !get( values, "funnel.id" ) ) {
            set( errors, "funnel.id", "common:validation.selection" );
        }
        if ( !values.sourceId ) {
            errors.sourceId = "common:validation.required";
        }
    }

    return errors;
};

const adaptMapping = ( mapping, formType ) => mapping
    .map( ( item, index ) => ({ ...item, order: index }) )
    .filter( item => !!item.field )
    .filter( item => item.field.form === formType )
    .map( item => ({ ...item, fieldId: item.field.id }) )
    .map( item => omit( item, [ "field" ] ) );

const NewImportation = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( coreSelectors.getUser );
    const companyFields = useSelector( formSelectors.listWithoutGroupings( "COMPANY" ) );
    const personFields = useSelector( formSelectors.listWithoutGroupings( "PERSON" ) );
    const opportunityFields = useSelector( formSelectors.listWithoutGroupings( "OPPORTUNITY" ) );
    const users = useSelector( userSelectors.listAll );

    const [ step, setStep ] = useState( 0 );
    const [ showMappingDialog, setShowMappingDialog ] = useState( false );
    const [ addressField, setAddressField ] = useState( "" );
    const [ addressType, setAddressType ] = useState( null );
    const [ dateField, setDateField ] = useState( "" );
    const [ dateFormat, setDateFormat ] = useState( "" );
    const [ phoneField, setPhoneField ] = useState( "" );
    const [ phoneCountry, setPhoneCountry ] = useState( t( "common:country" ) );
    const [ confirm, setConfirm ] = useState( false );
    const [ showError, setShowError ] = useState( false );
    const [ informSuccess, setInformSuccess ] = useState( false );

    const formik = useForm({
        form: NEW_IMPORTATION_FORM,
        initialValues: {
            person: true,
            company: false,
            mergeData: true,
            clearData: true,
            firstRowHeader: true,
            mapping: [],
            tags: [],
            file: null,
            createOpportunity: false,
            funnel: null,
            sourceId: null,
        },
        onSubmit: values => dispatch( saveNewImportation( omit(
            {
                ...values,
                mappingPerson: adaptMapping( values.mapping, "PERSON" ),
                mappingCompany: adaptMapping( values.mapping, "COMPANY" ),
                mappingOpportunity: adaptMapping( values.mapping, "OPPORTUNITY" ),
            },
            [ "mapping" ]
        ) ) ),
        onSubmitSuccess: () => {
            setConfirm( false );
            setInformSuccess( true );
        },
        validate,
    });

    const { setFieldValue, values } = formik;

    const toFinalStep = () => {
        if ( isEmpty( formik.errors ) ) {
            setStep( 1 );
            return;
        }
        formik.setFieldTouched( "name" );
        formik.setFieldTouched( "person" );
        formik.setFieldTouched( "company" );
        formik.setFieldTouched( "funnel.id" );
        formik.setFieldTouched( "sourceId" );
    };

    const closeInfoSuccess = () => history.push( "/importations" );

    const setMappingField = ( order, field, append ) => {
        const mappingIndex = formik.values.mapping.findIndex( item => item.fieldId === field.id );

        if ( mappingIndex > -1 ) {
            const mapping = [ ...formik.values.mapping ];
            mapping[ mappingIndex ].field = null;
            mapping[ order ] = {
                field,
                ...append
            };
            formik.setFieldValue( "mapping", mapping );
        } else {
            formik.setFieldValue(
                `mapping[${order}]`,
                {
                    ...formik.values.mapping[ order ],
                    field,
                    ...append,
                }
            );
        }

        setShowMappingDialog( false );
    };

    const handleClickMappingField = order => handleChangeMappingField({ ...formik.values.mapping[ order ], order });

    const handleChangeMappingField = ({ order, field, showMappingDialog = true }) => {
        switch ( field.type ) {
            case "ADDRESS":
                setAddressField({ order, field });
                setShowMappingDialog( showMappingDialog );
                break;
            case "DATE":
                setDateField({ order, field });
                setShowMappingDialog( showMappingDialog );
                break;
            case "PHONE":
                setPhoneField({ order, field });
                setShowMappingDialog( showMappingDialog );
                break;
            default:
                setMappingField( order, field );
                break;
        }
    };

    const handleChangePhoneCountry = event => {
        const value = typeof event === "object" ? event?.target?.value : event;
        setPhoneCountry( value );
    };

    const deleteMappingField = useCallback( order => {
        const item = values.mapping[ order ];
        setFieldValue(
            `mapping[${order}]`,
            {
                line: item.line,
                header: item.header,
            }
        );
    }, [ setFieldValue, values.mapping ] );

    const confirmDateField = () => {
        const { field, order } = dateField;
        setDateField( null );
        setDateFormat( null );
        setMappingField( order, field, { format: dateFormat } );
    };

    const confirmAddressField = () => {
        const { field, order } = addressField;
        setAddressField( null );
        setAddressType( null );
        setMappingField( order, field, { type: addressType } );
    };

    const confirmPhoneField = () => {
        const { field, order } = phoneField;
        setPhoneField( null );
        setPhoneCountry( t( "common:country" ) );
        setMappingField( order, field, { country: phoneCountry } );
    };

    const openConfirm = () => {
        if ( !!formik.values.fileName ) {
            setConfirm( true );
        }
    };
    const closeConfirm = () => setConfirm( false );

    const toggleError = () => setShowError( !showError );

    const save = () => {
        setShowError( false );
        formik.submitForm();
    };

    const sourceUsers = useMemo( () => ( users || [] ).map( user => ({
        label: user.name,
        value: user.id
    })), [ users ] );

    const mappingPersonFields = useMemo(
        () => ( personFields || [] )
            .filter( field => !values.mapping.find( item => item.field?.id === field.id ) ||
                field.type.match( "ADDRESS|EMAIL|PHONE" )
            )
            .map( field => ({
                ...field,
                form: "PERSON",
            })),
        [ values.mapping, personFields ]
    );
    const mappingCompanyFields = useMemo(
        () => ( companyFields || [] )
            .filter( field => !values.mapping.find( item => item.field?.id === field.id ) ||
                field.type.match( "ADDRESS|EMAIL|PHONE" )
            )
            .map( field => ({
                ...field,
                form: "COMPANY",
            })),
        [ values.mapping, companyFields ]
    );
    const mappingOpportunityFields = useMemo(
        () => ( opportunityFields || [] )
            .filter( field => !field.systemField || !field.systemField.match( "SOURCE|TITLE|USER" ) )
            .filter( field => !values.mapping.find( item => item.field?.id === field.id ) ||
                field.type.match( "ADDRESS|EMAIL|PHONE" )
            )
            .map( field => ({
                ...field,
                form: "OPPORTUNITY",
            })),
        [ values.mapping, opportunityFields ]
    );

    useEffect( () => {
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchForm( "OPPORTUNITY" ) );
        dispatch( sourceActions.fetchSources() );
        dispatch( userActions.fetchUsers() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    return (
        <div className={ className }>
            <Helmet title={ t( "importation:title" ) } />

            <Snackbar
                open={ showError }
                action={ <Button onClick={ toggleError } color="secondary">{ "OK" }</Button>}
                message={ get( formik.error, "error" ) }
                autoHideDuration={ 5000 }
                onClose={ toggleError }
            />

            <Dialog open={ !!dateField && showMappingDialog }>
                <DialogContent>
                    <FormControl>
                        <FormLabel>{ t( "importation:new-importation.select-date-format" ) }</FormLabel>
                        <RadioGroup
                            onChange={ event => setDateFormat( event.target.value ) }
                            value={ dateFormat }
                        >
                            <FormControlLabel value="dd/MM/yyyy" label="dd/MM/yyyy" control={ <Radio/> }/>
                            <FormControlLabel value="MM/dd/yyyy" label="MM/dd/yyyy" control={ <Radio/> }/>
                            <FormControlLabel
                                value="dd/MM/yyyy HH:mm"
                                label="dd/MM/yyyy HH:mm"
                                control={ <Radio/> }
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={ !dateFormat } onClick={ confirmDateField }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ !!addressField && showMappingDialog }>
                <DialogContent>
                    <FormControl>
                        <FormLabel>{ t( "importation:new-importation.select-address-type" ) }</FormLabel>
                        <RadioGroup
                            onChange={ event => setAddressType( event.target.value ) }
                            value={ addressType }
                        >
                            <FormControlLabel
                                value="COMMERCIAL"
                                label={ t( "form:address.types.COMMERCIAL" ) }
                                control={ <Radio/> }
                            />
                            <FormControlLabel
                                value="RESIDENTIAL"
                                label={ t( "form:address.types.RESIDENTIAL" ) }
                                control={ <Radio/> }
                            />
                            <FormControlLabel
                                value="OTHER"
                                label={ t( "form:address.types.OTHER" ) }
                                control={ <Radio/> }
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ confirmAddressField } disabled={ !addressType }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ !!phoneField && showMappingDialog }>
                <DialogContent>
                    <CountryFlags
                        label={ "" }
                        field={{
                            onChange: handleChangePhoneCountry,
                            onFocus: () => {},
                            onBlur: () => {},
                            value: phoneCountry
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={ !phoneCountry }
                        onClick={ confirmPhoneField }
                    >
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ confirm }>
                <DialogContent>
                    <DialogContentText>
                        {
                            t( "importation:new-importation.confirm.title", {
                                file: formik.values.fileName,
                                lines: formik.values.fileLength
                            } )
                        }
                    </DialogContentText>
                    {
                        !isEmpty( formik.values.mappingOpportunity ) ?
                            <DialogContentText>
                                { t(
                                    "importation:new-importation.confirm.opportunity-users",
                                    {
                                        users: isEmpty( formik.values.usersId ) ?
                                            user.name :
                                            formik.values.usersId
                                                .map( userId =>
                                                    sourceUsers.find( option => option.value === userId )
                                                )
                                                .map( user => user.label )
                                                .join( ", " )
                                    }
                                ) }
                            </DialogContentText> :
                            null
                    }
                    <List>
                        <ListSubHeader className={ cssUtils.padding0 }>
                            { t( "importation:new-importation.confirm.used-columns" ) }
                        </ListSubHeader>
                        {
                            formik.values.mapping
                                .filter( item => !!item.field )
                                .map( ( item, index ) => {
                                    const Icon = fieldIcons[ item.field.type ];
                                    return (
                                        <ListItem key={ index }>
                                            <ListItemIcon>
                                                { item.field.form === "PERSON" && <PersonIcon/> }
                                                { item.field.form === "COMPANY" && <BusinessIcon/> }
                                                { item.field.form === "OPPORTUNITY" && <AttachMoney/> }
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    item.header ?
                                                        item.header :
                                                        item.line ?
                                                            item.line :
                                                            t( "importation:new-importation.empty-value" )
                                                }
                                                secondary={ item.field.name }
                                            />
                                            <ListItemIcon><Icon/></ListItemIcon>
                                        </ListItem>
                                    );
                                })
                        }
                    </List>
                    <List>
                        <ListSubHeader className={ cssUtils.padding0 }>
                            { t( "importation:new-importation.confirm.not-used-columns" ) }
                        </ListSubHeader>
                        {
                            formik.values.mapping
                                .filter( item => !item.field )
                                .map( ( item, index ) => (
                                    <ListItem key={ index }>
                                        <ListItemText
                                            primary={
                                                item.header ?
                                                    item.header :
                                                    item.line ?
                                                        item.line :
                                                        t( "importation:new-importation.empty-value" )
                                            }
                                            secondary={
                                                item.header ?
                                                    item.line ?
                                                        item.line : t( "importation:new-importation.empty-value" )
                                                    : null
                                            }
                                        />
                                    </ListItem>
                                ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ closeConfirm } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ save } disabled={ formik.submitting }>
                        { t( "importation:new-importation.save-and-execute" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ informSuccess } fullWidth maxWidth="sm">
                <DialogContent>
                    <DialogContentText>
                        { t( "importation:new-importation.init-success" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ closeInfoSuccess }>
                        { "OK" }
                    </Button>
                </DialogActions>
            </Dialog>

            <Form formik={ formik } noValidate encType="multipart/form-data" >
                <Card>
                    <CardContent>
                        {
                            step === 0 &&
                                <Fragment>
                                    <Field
                                        name="name"
                                        label={ t( "importation:importation.name" ) }
                                        type="text"
                                        component={ Input }
                                        required
                                    />

                                    <CustomerConfig form={ formik } />

                                    <OpportunityConfig
                                        createOpportunity={ values.createOpportunity }
                                        setFieldValue={ formik.setFieldValue }
                                    />

                                </Fragment>
                        }

                        {
                            step === 1 &&
                                <FileConfig
                                    form={ formik }
                                    personFields={ mappingPersonFields }
                                    companyFields={ mappingCompanyFields }
                                    opportunityFields={ mappingOpportunityFields }
                                />
                        }
                    </CardContent>
                </Card>

                {
                    step === 1 && !isEmpty( values.mapping ) &&
                        <MappingFile
                            form={ formik }
                            personFields={ mappingPersonFields }
                            companyFields={ mappingCompanyFields }
                            opportunityFields={ mappingOpportunityFields }
                            onClickMappingField={ handleClickMappingField }
                            deleteMappingField={ deleteMappingField }
                            onChangeMappingField={ handleChangeMappingField }
                        />
                }
                <Card className={ cssUtils.marginTop }>
                    <CardActions>
                        <Stepper
                            activeStep={ step }
                            className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRightSmall, flex.fill ) }
                        >
                            <Step><StepLabel>{ t( "common:definitions" ) }</StepLabel></Step>
                            <Step><StepLabel>{ t( "common:file" ) }</StepLabel></Step>
                            <Step/>
                        </Stepper>
                        <div className={ cssUtils.paddingRightSmall }>
                            {
                                step === 0 &&
                                    <Fragment>
                                        <Button
                                            color="primary"
                                            onClick={ () => history.push( "/importations" ) }
                                        >
                                            { t( "common:cancel" ) }
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={ toFinalStep }
                                            className={ cssUtils.marginLeftSmall }
                                        >
                                            { t( "common:next" ) }
                                        </Button>
                                    </Fragment>
                            }
                            {
                                step === 1 &&
                                    <Fragment>
                                        <Button
                                            color="primary"
                                            disabled={ formik.submitting }
                                            onClick={ () => setStep( 0 ) }
                                        >
                                            { t( "common:previous" ) }
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={ openConfirm }
                                            variant="contained"
                                        >
                                            { t( "common:save" ) }
                                        </Button>
                                    </Fragment>
                            }
                        </div>
                    </CardActions>
                </Card>
            </Form>
        </div>
    );
};

export default NewImportation;