import { useForm } from "formik-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { EmptyState, DialogInfo } from "../ui";
import { sendUserVerification } from "./layout.actions";
import { SEND_USER_VERIFICATION_FORM } from "./layout.constants";

const UserUnverified = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const { submitForm, submitting } = useForm({
        form: SEND_USER_VERIFICATION_FORM,
        initialValues: {},
        onSubmit: values => dispatch( sendUserVerification( values ) ),
    });

    const prevSubmitting = usePrevious( submitting );
    const [ isOpenSucceeded, setIsOpenSucceeded ] = useState( false );

    const handleCloseSucceeded = () => setIsOpenSucceeded( false );

    useEffect( () => {
        if ( prevSubmitting && !submitting ) {
            setIsOpenSucceeded( true );
        }
    }, [ prevSubmitting, submitting ] );

    return (
        <>
            <EmptyState
                icon={ VerifiedUserIcon }
                message={ t( "layout:user-verification.description" ) }
            >
                <Button onClick={ submitForm } disabled={ submitting }>
                    { t( "layout:user-verification.resend" ) }
                </Button>
            </EmptyState>

            <DialogInfo
                message={ t( "layout:user-verification.resend-success" ) }
                onClose={ handleCloseSucceeded }
                open={ isOpenSucceeded }
            />
        </>
    );
};

export default UserUnverified;