import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteCommission } from "./commission.actions";
import { DELETE_COMMISSION_FORM } from "./commission.constants";

const DeleteCommission = ({ commission, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: DELETE_COMMISSION_FORM,
        initialValues: {
            id: commission.id
        },
        onSubmit: values => dispatch( deleteCommission( values ) ),
    });

    return (
        <DialogConfirmForm
            open={ open }
            onClose={ onClose }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            title={ t( "config:commission.delete.title" ) }
            message={ t( "config:commission.delete.confirm" ) }
        />
    );
};

export default DeleteCommission;