import { all } from "redux-saga/effects";

import * as funnelSagas from "./funnel/funnel.sagas";
import * as sourceSagas from "./source/source.sagas";
import * as lossReasonSagas from "./loss-reason/loss-reason.sagas";
import * as schedulesTypeSagas from "./schedules-type/schedules-type.sagas";
import * as formSagas from "./form/form.sagas";
import * as templateSagas from "./template/template.sagas";
import * as productSagas from "./product/product.sagas";

export function *watchConfigOpportunity () {
    yield all([
        funnelSagas.watchConfigOpportunityFunnel(),
        sourceSagas.watchConfigOpportunitySource(),
        lossReasonSagas.watchConfigOpportunityLossReason(),
        schedulesTypeSagas.watchConfigOpportunitySchedulesType(),
        formSagas.watchConfigOpportunityForm(),
        templateSagas.watchConfigOpportunityTemplate(),
        productSagas.watchConfigOpportunityProduct(),
    ]);
}