import classnames from "classnames";
import Typography from "@mui/material/Typography";

import css from "./section-title.scss";

export const SectionTitle = ({ id, className, icon, name }) => {
    const Icon = icon;
    return (
        <Typography id={ id } className={ classnames( className, css.container ) }>
            <Icon className={ css.icon } />
            { name }
        </Typography>
    );
};

export default SectionTitle;