import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const OpportunitiesCreatedByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState();
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "user" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo( () => (
        order( ( results || [] ), [ orderBy ], [ orderType.toLowerCase() ] )
    ), [ results, orderBy, orderType ] );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.user ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: t( "report:most-sold-products.total" ),
            data: orderedResults.map( item => item.quantity ) || 0,
            color: "#4CAF50"
        }]
    }), [ t, orderedResults ] );

    const footer = useMemo( () => orderedResults.reduce(( acc, item ) => {
        acc.user = t( "report:opportunities-created-by-user.total" );
        acc.quantity += item.quantity;
        return acc;
    }, {
        product: t( "report:opportunities-created-by-user.user" ),
        quantity: 0,
    }), [ t, orderedResults ] );

    const model = {
        user: t( "report:opportunities-created-by-user.user" ),
        quantity: t( "report:opportunities-created-by-user.quantity" )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITIES_CREATED_BY_USER" ) }
                subheader={ t( "report:opportunities-created-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "users" ]}
                    required={[ "funnels", "users" ]}
                    onChange={ handleChange }
                    report={ "OPPORTUNITIES_CREATED_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        chartData &&
                            <Chart
                                id="opportunitiesCreatedByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="opportunitiesCreatedByUserTable"
                                    allowExport
                                    model={ model }
                                    source={ orderedResults }
                                    footer={ footer }
                                    title={ t( "report:reports.OPPORTUNITIES_CREATED_BY_USER" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default OpportunitiesCreatedByUser;