import { PureComponent } from "react";
import { Field } from "formik";
import { Form } from "formik-redux";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Checkbox, Dropdown, Input, TemplateEditor, cssUtils, flex } from "../ui";
import css from "./user.scss";

const TemplateForm = ({ formik }) => {
    const t = useTranslate();

    return (
        <Form formik={ formik }>
            <Field
                name="name"
                label={ t( "config:template.name" ) }
                component={ Input }
                className={ cssUtils.marginTopSmall }
                required
            />
            <Field
                name="type"
                label={ t( "config:template.type.label" ) }
                source={
                    [ "EMAIL", "SMS", "WHATSAPP" ]
                        .map( value => ({ value, label: t( `config:template.type.${value}` ) }))
                }
                component={ Dropdown }
                className={ cssUtils.marginBottomSmall }
                required
            />
            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Field
                    name="share"
                    type="checkbox"
                    label={ t( "config:template.share" ) }
                    fullWidth={ false }
                    component={ Checkbox }
                />
                <Tooltip
                    title={ t( "config:template.share-help" ) }
                >
                    <HelpIcon className={ css.helpIcon }/>
                </Tooltip>
            </div>
            <Field
                name="body"
                type="text"
                component={ TemplateEditor }
                templateType={ formik.values.type }
            />
        </Form>
    );
};

export class _Template extends PureComponent {

    render () {
        const { t, handleSubmit, type } = this.props;

        return (
            <form onSubmit={ handleSubmit }>
                <Field
                    name="name"
                    label={ t( "config:template.name" ) }
                    component={ Input }
                    className={ cssUtils.marginTopSmall }
                    required
                />
                <Field
                    name="type"
                    label={ t( "config:template.type.label" ) }
                    source={
                        [ "EMAIL", "SMS", "WHATSAPP" ]
                            .map( value => ({ value, label: t( `config:template.type.${value}` ) }))
                    }
                    component={ Dropdown }
                    className={ cssUtils.marginBottomSmall }
                    required
                />
                <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                    <Field
                        name="share"
                        type="checkbox"
                        label={ t( "config:template.share" ) }
                        fullWidth={ false }
                        component={ Checkbox }
                    />
                    <Tooltip
                        title={ t( "config:template.share-help" ) }
                    >
                        <HelpIcon className={ css.helpIcon }/>
                    </Tooltip>
                </div>
                <Field
                    name="body"
                    type="text"
                    component={ TemplateEditor }
                    templateType={ type }
                />
            </form>
        );
    }

}

export default TemplateForm;