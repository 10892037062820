export default {
    "title": "Registrar-se",
    "company": "Nome da empresa",
    "phone": "Telefone",
    "name": "Seu nome",
    "email": "E-mail",
    "password": "Senha",
    "confirm-password": "Confirme sua senha",
    "coupon": "Cupom",
    "segment": {
        "label": "Segmento",
        "type": {
            "AESTHETIC": {
                "label": "Estética e Procedimentos"
            },
            "AUTOMOTIVE": {
                "label": "Automotivo",
                "help": "Selecione o tipo de veículo que será comercializado pela sua inscrição."
            },
            "EDUCATION": {
                "label": "Educação"
            },
            "HEALTH": {
                "label": "Saúde"
            },
            "INSURANCE": {
                "label": "Seguros"
            },
            "NAUTICAL": {
                "label": "Náutico"
            },
            "OTHER": {
                "label": "Outro"
            },
            "REALSTATE": {
                "label": "Imobiliário"
            },
            "RETAIL": {
                "label": "Varejo"
            },
            "TELECOM": {
                "label": "Telecom"
            }
        }
    },
    "accept-terms": {
        "1": "Concordo com os ",
        "2": "Termos de uso",
        "3": " e ",
        "4": "Política de privacidade",
    },
    "signup": {
        "action": "Registrar"
    },
    "login": {
        "call-to-action": "Já possui uma conta por aqui?",
        "action": "Entrar"
    },
    "person-type": {
        "label": "Tipo de pessoa",
        "legal": "Jurídica",
        "natural": "Física"
    },
    "ssn": "CPF",
    "ein": "CNPJ",
    "user-limit": "Quantos usuários deseja?",
};