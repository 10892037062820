import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteSubscriptionBilling } from "./plan.actions";
import { DELETE_SUBSCRIPTION_BILLING_FORM } from "./plan.constants";
import css from "./plan.scss";

const CancelPlan = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ isOpen, setIsOpen ] = useState( false );
    const toggleOpen = () => setIsOpen( !isOpen );

    const formik = useForm({
        form: DELETE_SUBSCRIPTION_BILLING_FORM,
        initialValues: {},
        onSubmit: () => dispatch( deleteSubscriptionBilling() ),
    });

    return (
        <>
            <Button className={ css.cancelButton } variant="outlined" onClick={ toggleOpen }>
                { t( "config:plans.cancel.title" ) }
            </Button>
            <DialogConfirmForm
                open={ isOpen }
                title={ t( "config:plans.cancel.title" ) }
                message={ t( "config:plans.cancel.confirm" ) }
                onClose={ toggleOpen }
                handleSubmit={ formik.handleSubmit }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
            />
        </>
    );
};

export default CancelPlan;