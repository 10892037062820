import { useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import KeyBoardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyBoardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../";
import css from "./calendar.scss";

const CalendarToolbar = ({ label, onNavigate, onViewChange, rightButtons, view, views }) => {
    const t = useTranslate();

    const selectedView = useMemo( () => view || "month", [ view ] );
    const handleNavigate = useCallback( direction => () => onNavigate( direction ), [ onNavigate ] );
    const handleViewChange = useCallback( view => () => onViewChange( view ), [ onViewChange ] );

    return (
        <div className={ css.toolbar }>
            <div id="navigationCalendar">
                {
                    views.length > 1 &&
                        <Button
                            onClick={ handleNavigate( "TODAY" ) }
                            variant="contained"
                            className={ cssUtils.marginRight }
                        >
                            { t( "ui:calendar.today" ) }
                        </Button>
                }
                <Tooltip title={ t( "ui:calendar.previous" ) }>
                    <IconButton onClick={ handleNavigate( "PREV" ) } size="large">
                        <KeyBoardArrowLeftIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={ t( "ui:calendar.next" ) }>
                    <IconButton onClick={ handleNavigate( "NEXT" ) } size="large">
                        <KeyBoardArrowRightIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <Typography className={ classnames( flex.fill, cssUtils.textCenter ) }>{ label }</Typography>

            {
                views.length > 1 &&
                    <div id="periodsCalendar">
                        <Button
                            onClick={ handleViewChange( "month" ) }
                            variant={ selectedView === "month" ? "contained" : "text" }
                        >
                            { t( "ui:calendar.month" ) }
                        </Button>
                        <Button
                            onClick={ handleViewChange( "week" ) }
                            variant={ selectedView === "week" ? "contained" : "text" }
                        >
                            { t( "ui:calendar.week" ) }
                        </Button>
                        <Button
                            onClick={ handleViewChange( "day" ) }
                            variant={ selectedView === "day" ? "contained" : "text" }
                        >
                            { t( "ui:calendar.day" ) }
                        </Button>
                    </div>
            }
            { rightButtons && rightButtons }
        </div>
    );
};

export default CalendarToolbar;