import { useEffect, useMemo, useState } from "react";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";
import isObject from "lodash/isObject";
import omit from "lodash/omit";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import * as keycodes from "../util/keycodes.constants";
import css from "./autocomplete.scss";

const createOption = value => ({ value, label: value });

const Autocomplete = ({
    allowCreate,
    autoFocus,
    source = [],
    form,
    fullWidth = true,
    label,
    helperText,
    multiple = true,
    placeholder,
    useSourceKeyValue,
    field,
    onQueryChange,
    splitKeyCodes = [],
    required,
    variant,
    classes,
    ...props
}) => {
    const t = useTranslate();

    const [ inputValue, setInputValue ] = useState( "" );

    const options = useMemo( () => {
        if ( Array.isArray( source ) ) {
            return source.map( item => ({
                label: item.label || item,
                value: item.value || item,
                helper: item.helper,
                icon: item.icon
            }));
        } else if ( source ) {
            return Object.keys( source )
                .map( key => ({
                    label: source[ key ].text || source[ key ].label || source[ key ],
                    helper: source[ key ].helper,
                    value: useSourceKeyValue ?
                        ( source[ key ].text || source[ key ].label || source[ key ] )
                        : key,
                    icon: source[ key ].icon
                }) );
        }

        return [];
    }, [ source, useSourceKeyValue ] );

    const value = useMemo( () => field.value || "", [ field ] );

    const fieldMeta = useMemo( () => {
        if ( field && form ) {
            return form.getFieldMeta( field.name );
        }
        return null;
    }, [ field, form ] );

    const validationMsg = useMemo( () => fieldMeta?.touched && fieldMeta?.error, [ fieldMeta ] );

    const prevValue = usePrevious( value );

    const handleSelect = item => {
        let value;
        if ( multiple ) {
            let values = field ? field.value : [];

            if ( !values.find( selected => selected.toString() === item.value.toString() ) ) {
                values = [ ...values, item.value ];
            }

            value = values;
        } else {
            value = field ? field.value : null;
        }

        setInputValue( "" );

        field && field.onChange({ target: { name: field.name, value } });
    };

    const handleKeyDownChange = event => {
        event.preventDefault();
        if ( allowCreate && inputValue ) {
            const value = field ? Array.isArray( field.value ) ? field.value : [] : [];
            if ( value.includes( inputValue ) ) {
                return;
            }
            handleSelect( createOption( inputValue ) );
        }
    };

    const handleKeyDown = event => {
        switch ( event.key ) {
            case keycodes.ENTER:
                handleKeyDownChange( event );
                break;
            case keycodes.TAB:
                if ( inputValue ) {
                    handleKeyDownChange( event );
                }
                break;
            default:
                if ( splitKeyCodes.includes( event.key ) ) {
                    handleKeyDownChange( event );
                }
                break;
        }
    };

    const handleInputChange = event => {
        onQueryChange && onQueryChange( event.target.value );
        setInputValue( event.target.value );

        if ( allowCreate && !multiple ) {
            field && field.onChange({ target: {
                name: field.name,
                value: event.target.value
            }});
        }
    };

    const handleInputBlur = event => {
        const newValue = event.target.value.trim();
        if ( event.target.value !== newValue ) {
            setInputValue( newValue );
        }
        if ( newValue && allowCreate ) {
            const value = field ?
                Array.isArray( field.value ) ? field.value : [] : [];
            if ( value.includes( newValue ) ) {
                return;
            }
            handleSelect( createOption( newValue ) );
        }
    };

    const handleChange = ( event, newValue ) => {
        if ( newValue && newValue.inputValue ) {
            field && field.onChange({ target: { name: field.name, value: newValue.inputValue } });
            return;
        }

        if ( Array.isArray( newValue ) ) {
            field && field.onChange({ target: {
                name: field.name,
                value: Array.from( new Set( newValue.map( option => option.value || option ) ) )
            }});
        } else {
            field && field.onChange({ target: {
                name: field.name,
                value: isObject( newValue ) ? newValue.value : newValue
            }});
        }
    };

    useEffect( () => {
        if ( prevValue && !value && inputValue ) {
            setInputValue( "" );
        }
    }, [ prevValue, value, inputValue ] );

    const filterOptions = options => {
        const filterValue = inputValue || ( !multiple && value );
        if ( !filterValue ) {
            return options;
        }

        return options.filter( option => option.label.toUpperCase().match( filterValue.toUpperCase() ) );
    };

    return (
        <MUIAutocomplete
            multiple={ multiple }
            options={ options }
            classes={{ ...classes, listbox: css.listbox, popper: css.popper }}
            fullWidth={ fullWidth }
            renderInput={ params => (
                <TextField
                    { ...params }
                    autoFocus={ autoFocus }
                    onBlur={ handleInputBlur }
                    onChange={ handleInputChange }
                    onKeyDown={ allowCreate && multiple ? handleKeyDown : undefined }
                    label={ label }
                    placeholder={ placeholder }
                    error={ !!validationMsg || ( fieldMeta?.touched && fieldMeta?.error ) }
                    helperText={ ( validationMsg && t( validationMsg ) ) || helperText }
                    required={ required }
                    value={ inputValue }
                    variant={ variant }
                />
            )}
            renderTags={ ( value, getTagProps ) =>
                value.map(( option, index ) => (
                    <Chip
                        key={ option.value }
                        label={
                            ( options.find( op => op.value === option ) || { label: option } ).label
                        }
                        size="small"
                        { ...getTagProps({ index }) }
                    />
                ))
            }
            renderOption={ ( props, option ) => (
                <ListItem
                    { ...omit( props, [ "key", "className" ] ) }
                    key={ option.value }
                    disableGutters
                    disablePadding
                >
                    <ListItemButton>
                        { option.icon && <ListItemIcon>{ option.icon }</ListItemIcon> }
                        <ListItemText
                            primary={ option.label }
                            secondary={ option.helper }
                        />
                    </ListItemButton>
                </ListItem>
            )}
            filterOptions={ filterOptions }
            freeSolo={ allowCreate }
            { ...field }
            onChange={ handleChange }
            loadingText={ t( "ui:autocomplete.loading" ) }
            noOptionsText={
                onQueryChange ?
                    inputValue.length < 3 ?
                        t( "ui:autocomplete.warning" ) :
                        t( "ui:autocomplete.empty" ) :
                    ""
            }
            { ...props }
            onBlur={ () => {} }
            value={ value }
        />
    );
};

export default Autocomplete;