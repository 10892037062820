import { useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchAutomations } from "./automation.actions";
import { getAutomations, isLoadingAutomations } from "./automation.selectors";
import MarketplaceItem from "../marketplace-item.component";
import css from "../marketplace.scss";

const Automations = ({ className }) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const t = useTranslate();

    const allAutomations = useSelector( getAutomations );
    const loading = useSelector( isLoadingAutomations );

    const handleClickAutomation = useCallback( path => () => {
        const route = `automations/${path}`;
        navigate( route );
    }, [ navigate ] );

    const filterAutomations = useCallback( integrationChannel => (
        ( allAutomations || [] ).filter( automation => automation.integrationChannel === integrationChannel )
    ), [ allAutomations ] );

    const automations = useMemo( () => [
        {
            id: "EXACT_SALES",
            path: "exactsales",
            title: t( "marketplace:automation.integration-channel.EXACT_SALES" ),
            expired: filterAutomations( "EXACT_SALES" ).find( automation => !automation.active ),
            quantity: filterAutomations( "EXACT_SALES" ).length,
            className: css.exactSalesIcon,
        },
        {
            id: "MAILTOP",
            path: "mailtop",
            title: t( "marketplace:automation.integration-channel.MAILTOP" ),
            expired: filterAutomations( "MAILTOP" ).find( automation => !automation.active ),
            quantity: filterAutomations( "MAILTOP" ).length,
            className: css.mailtopIcon,
        },
        {
            id: "LEADLOVERS",
            path: "leadlovers",
            title: t( "marketplace:automation.integration-channel.LEADLOVERS" ),
            expired: filterAutomations( "LEADLOVERS" ).find( automation => !automation.active ),
            quantity: filterAutomations( "LEADLOVERS" ).length,
            className: css.leadLoversIcon,
        },
        {
            id: "MKT2EASY",
            path: "mkt2easy",
            title: t( "marketplace:automation.integration-channel.MKT2EASY" ),
            expired: filterAutomations( "MKT2EASY" ).find( automation => !automation.active ),
            quantity: filterAutomations( "MKT2EASY" ).length,
            className: css.mkt2EasyIcon,
        },
        {
            id: "RD_STATION",
            path: "rd-station",
            title: t( "marketplace:automation.integration-channel.RD_STATION" ),
            expired: filterAutomations( "RD_STATION" ).find( automation => !automation.active ),
            quantity: filterAutomations( "RD_STATION" ).length,
            className: css.rdStationIcon,
        }, {
            id: "OTHER",
            path: "api",
            title: t( "marketplace:automation.integration-channel.OTHER" ),
            expired: filterAutomations( "OTHER" ).find( automation => !automation.active ),
            quantity: filterAutomations( "OTHER" ).length,
            className: css.apiIcon,
        }
    ], [ t, filterAutomations ] );

    useEffect( () => {
        dispatch( fetchAutomations() );
    }, [ dispatch ] );

    return (
        <div className={ className }>
            <CardHeader
                className={ css.sectionTitle }
                title={ t( "marketplace:automation.title" ) }
                subheader={ t( "marketplace:automation.subtitle" ) }
            />
            <Grid container spacing={ 2 }>
                {
                    automations.map( automation => (
                        <Grid key={ automation.id } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                            <MarketplaceItem
                                onClick={ handleClickAutomation( automation.path ) }
                                loading={ loading }
                                expired={ automation.expired }
                                quantity={ automation.quantity }
                                title={ automation.title }
                            >
                                <div className={ automation.className }/>
                            </MarketplaceItem>
                        </Grid>
                    ) )
                }
            </Grid>
        </div>
    );
};

export default Automations;