import { startSubmit as startSubmitFormik, stopSubmit as stopSubmitFormik } from "formik-redux";
import { all, put, select, call, takeEvery } from "redux-saga/effects";
import isEqual from "lodash/isEqual";

import { apiv1, app } from "../../api/sagas";
import { actions as smtpServerActions, selectors as smtpServerSelectors } from "../smtp-server";
import { actions as templateActions } from "../template";
import * as actions from "./user.actions";
import {
    USER_FORM,
    USER_AVATAR_FORM,
    USER_SIGNATURE_FORM,
    USER_PASSWORD_FORM,
    USER_FILTERS,
    USER_TEMPLATE,
    USER_THEME_MODE_FORM,
    ADD_IMAGE_FORM,
    SMTP_SERVER_FORM,
    SOCIAL_CALENDAR_FORM,
    DELETE_SOCIAL_CALENDAR_FORM,
    VERIFY_EMAIL_FORM,
} from "./user.constants";
import { createDeleteImagemForm } from "./user.utils";
import { userLogout } from "../logout/logout.actions";
import { setInfo, setPreferences, setThemeMode } from "../core/core.actions";
import { getPreferences, getUser } from "../core/core.selectors";

// ---------------------------------------------------------------------------------------------------------------------
// Geral
// ---------------------------------------------------------------------------------------------------------------------
export function *watchUser () {
    yield all([
        takeEvery( actions.FETCH_USERS, fetchUsers ),
        takeEvery( actions.SAVE_USER_PREFERENCES, saveUserPreferences ),
        takeEvery( actions.SAVE_THEME_MODE, saveThemeMode ),
        takeEvery( actions.SAVE_USER, patchUser ),
        takeEvery( actions.SAVE_AVATAR, saveAvatar ),
        takeEvery( actions.SAVE_SIGNATURE, saveSignature ),
        takeEvery( actions.SAVE_USER_PASSWORD, patchPassword ),
        takeEvery( actions.SAVE_USER_FILTERS, saveFilters ),
        takeEvery( actions.RELOAD_USER_INFO, reloadUserInfo ),
        takeEvery( actions.FETCH_EVENTS, fetchEvents ),
        takeEvery( actions.FETCH_SCHEDULES, fetchSchedules ),
        takeEvery( actions.FETCH_OTHERS_SCHEDULES, fetchOthersSchedules ),
        takeEvery( actions.SAVE_TEMPLATE, saveTemplate ),
        takeEvery( actions.UPLOAD_IMAGE, uploadImage ),
        takeEvery( actions.DELETE_IMAGE, deleteImage ),
        takeEvery( actions.SAVE_SMTP_SERVER, saveSmtpServer ),
        takeEvery( actions.FECTH_SOCIAL_CALENDAR, fetchSocialCalendar ),
        takeEvery( actions.FETCH_SOCIAL_ACCOUNT_CALENDARS, fetchSocialAccountCalendars ),
        takeEvery( actions.SAVE_SOCIAL_CALENDAR, saveSocialCalendar ),
        takeEvery( actions.DELETE_SOCIAL_CALENDAR, deleteSocialCalendar ),
        takeEvery( actions.VERIFY_EMAIL, verifyEmail ),
    ]);
}

// ---------------------------------------------------------------------------------------------------------------------
// Users list
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchUsers () {
    yield put( actions.requestUsers() );

    try {
        const response = yield call( apiv1.get, "/users" );
        yield put( actions.receiveUsers( response.data ) );
    } catch ( e ) {
        yield put( actions.errorUsers( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// User
// ---------------------------------------------------------------------------------------------------------------------

export function *patchUser ({ payload }) {

    yield put( startSubmitFormik( USER_FORM ) );
    try {
        const previousUser = yield select( getUser );
        const preferencesPrev = yield select( getPreferences );

        if ( payload.preferences ) {
            const preferences = {
                ...preferencesPrev,
                indexRoute: payload.preferences.indexRoute
            };
            yield put( actions.saveUserPreferences( preferences ) );
        }

        const response = yield call( apiv1.patch, "/users/me", payload );
        if ( !isEqual( payload.email, previousUser.email ) ) {
            yield put( userLogout() );
        } else {
            yield put( setInfo( response.data ) );
        }
        yield put( stopSubmitFormik( USER_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_FORM, e.response.data ) );
    }
}

export function *saveAvatar ({ payload }) {

    yield put( startSubmitFormik( USER_AVATAR_FORM ) );
    try {
        const { avatar } = payload;
        let response;
        if ( avatar ) {
            const formData = new FormData();
            formData.append( "avatar", avatar );
            response = yield call( apiv1.put, "/users/me/avatar", formData );
        } else {
            response = yield call( apiv1.delete, "/users/me/avatar" );
        }
        yield put( setInfo( response.data ) );
        yield put( stopSubmitFormik( USER_AVATAR_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_AVATAR_FORM, e.response.data ) );
    }
}

export function *saveSignature ({ payload }) {

    yield put( startSubmitFormik( USER_SIGNATURE_FORM ) );
    try {
        const { file } = payload;
        let response;
        if ( file ) {
            const params = {
                fileName: file.name
            };
            const formData = new FormData();
            formData.append( "signature", file );
            response = yield call( apiv1.put, "/users/me/signature", formData, { params } );
        } else {
            response = yield call( apiv1.delete, "/users/me/signature" );
        }
        yield put( setInfo( response.data ) );
        yield put( stopSubmitFormik( USER_SIGNATURE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_SIGNATURE_FORM ), e );
    }
}

export function *patchPassword ({ payload }) {
    yield put( startSubmitFormik( USER_PASSWORD_FORM ) );
    try {
        yield call( apiv1.patch, "/users/me/password", payload );
        yield put( stopSubmitFormik( USER_PASSWORD_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_PASSWORD_FORM, e.response?.data ));
    }
}

export function *saveFilters ({ payload: { formName, ...payload } }) {
    yield put( startSubmitFormik( USER_FILTERS ) );
    try {
        const preferences = yield select( getPreferences );
        const body = {
            ...preferences,
            [ formName ]: payload[ formName ]
        };

        const response = yield call( apiv1.put, `/users/me/preferences`, body );

        yield put( setPreferences( response.data ) );
        yield put( stopSubmitFormik( USER_FILTERS ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_FILTERS, e.response.data ));
    }
}

export function *reloadUserInfo () {
    try {
        const response = yield call( apiv1.get, "/users/me" );
        yield put( setInfo( response.data ) );
    } catch ( e ) {
        // lala
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Preference
// ---------------------------------------------------------------------------------------------------------------------
export function *saveUserPreferences ({ meta: { form }, payload }) {
    try {
        if ( form ) {
            yield put( startSubmitFormik( form ) );
        }
        const response = yield call( apiv1.put, `/users/me/preferences`, payload );
        yield put( setPreferences( response.data ) );
        if ( form ) {
            yield put( stopSubmitFormik( form ) );
        }
    } catch ( e ) {
        if ( form ) {
            yield put( stopSubmitFormik( form, e ) );
        }
    }
}

function *saveThemeMode ({ payload }) {
    yield put( startSubmitFormik( USER_THEME_MODE_FORM ) );

    try {
        const response = yield call( app.put, `/users/me/preferences`, payload );
        yield put( setPreferences( response.data ) );
        yield put( setThemeMode( response.data.theme.toLowerCase() ) );
        yield put( stopSubmitFormik( USER_THEME_MODE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_THEME_MODE_FORM, e ) );
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Events
// ---------------------------------------------------------------------------------------------------------------------
function *fetchEvents ({ payload: { params } }) {
    yield put( actions.requestEvents() );
    try {
        if ( params.weeks ) {
            const responses = yield all( params.weeks.map( week => (
                call(
                    apiv1.get, "/schedules",
                    { params: { ...week, usersId: params.usersId, social: params.social } }
                )
            )));
            yield put( actions.receiveEvents( [].concat.apply( [], responses.map( response => response.data ) ) ) );
        } else {
            const response = yield call( apiv1.get, "/schedules", { params } );
            yield put( actions.receiveEvents( response.data ) );
        }
    } catch ( e ) {
        yield put( actions.errorEvents( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Schedules
// ---------------------------------------------------------------------------------------------------------------------
function *fetchSchedules ({ meta: { params } }) {
    yield put( actions.requestSchedules() );
    try {
        const user = yield select( getUser );
        const response = yield call(
            apiv1.get,
            "/schedules",
            { params: { ...params, usersId: [ user.id ].toString() } }
        );
        const schedules = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];

        yield put( actions.receiveSchedules({ schedules, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorSchedules( e.response.data ) );
    }
}

function *fetchOthersSchedules ({ meta: { params } }) {
    yield put( actions.requestOthersSchedules() );
    try {
        const response = yield call( apiv1.get, "/schedules", { params } );
        const schedules = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];

        yield put( actions.receiveOthersSchedules({ schedules, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorOthersSchedules( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Template
// ---------------------------------------------------------------------------------------------------------------------
export function *saveTemplate ({ payload }) {
    yield put( startSubmitFormik( USER_TEMPLATE ) );
    try {
        if ( payload.id ) {
            yield call( apiv1.patch, `/templates/${payload.id}`, payload );
        } else {
            yield call( apiv1.post, "/templates/me", payload );
        }
        yield put( templateActions.fetchTemplates() );
        yield put( stopSubmitFormik( USER_TEMPLATE ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( USER_TEMPLATE, e.response.data ) );
    }
}

export function *uploadImage ({ payload }) {
    yield put( startSubmitFormik( ADD_IMAGE_FORM ) );
    try {
        const formData = new FormData();
        Object.keys( payload ).forEach( key => formData.append( key, payload[ key ] ) );
        yield call( apiv1.post, "/images/me", formData );
        yield put( templateActions.fetchImages() );
        yield put( stopSubmitFormik( ADD_IMAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( ADD_IMAGE_FORM, e.response.data ) );
    }
}

export function *deleteImage ({ meta: { id } }) {
    const form = createDeleteImagemForm( id );
    yield put( startSubmitFormik( form ) );
    try {
        yield call( apiv1.delete, `/images/${id}` );
        yield put( templateActions.fetchImages() );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Smtp Server
// ---------------------------------------------------------------------------------------------------------------------
export function *saveSmtpServer ({ payload }) {
    yield put( startSubmitFormik( SMTP_SERVER_FORM ) );
    try {
        if ( payload.type === "CUSTOM" ) {
            const response = yield call( apiv1.put, "/smtpserver", payload );
            yield put( smtpServerActions.receiveSmtpServer( response.data ) );
        } else {
            const settings = yield select( smtpServerSelectors.getSettings );
            if ( !!settings ) {
                yield call( apiv1.delete, "/smtpserver" );
                yield put( smtpServerActions.receiveSmtpServer( null ) );
            }
        }
        yield put( stopSubmitFormik( SMTP_SERVER_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SMTP_SERVER_FORM, e.response.data ) );
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Social Calendar
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchSocialCalendar () {
    yield put( actions.requestSocialCalendar() );

    try {
        const response = yield call( apiv1.get, "/socialcalendar" );
        yield put( actions.receiveSocialCalendar( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSocialCalendar( e.response.data ) );
    }
}

export function *fetchSocialAccountCalendars () {
    yield put( actions.requestSocialAccountCalenders() );

    try {
        const response = yield call( apiv1.get, "/socialcalendar/calendars" );
        yield put( actions.receiveSocialAccountCalendars( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSocialAccountCalendars( e.response.data ) );
    }
}

export function *saveSocialCalendar ({ payload }) {
    yield put( startSubmitFormik( SOCIAL_CALENDAR_FORM ) );
    try {
        const response = yield call( apiv1.patch, "/socialcalendar", payload );
        yield put( actions.receiveSocialCalendar( response.data ) );
        yield put( stopSubmitFormik( SOCIAL_CALENDAR_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SOCIAL_CALENDAR_FORM, e.response.data ) );
    }
}
export function *deleteSocialCalendar () {
    yield put( startSubmitFormik( DELETE_SOCIAL_CALENDAR_FORM ) );
    try {
        yield call( apiv1.delete, "/socialcalendar" );
        yield put( actions.receiveSocialCalendar( null ) );
        yield put( stopSubmitFormik( DELETE_SOCIAL_CALENDAR_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( DELETE_SOCIAL_CALENDAR_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Verify
// ---------------------------------------------------------------------------------------------------------------------
export function *verifyEmail ({ payload }) {
    yield put( startSubmitFormik( VERIFY_EMAIL_FORM ) );
    try {
        yield call( apiv1.post, `/users/me/verify/${payload}` );
        const response = yield call( apiv1.get, "/users/me" );
        yield put( setInfo( response.data ) );
        yield put( stopSubmitFormik( VERIFY_EMAIL_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( VERIFY_EMAIL_FORM, e.response.data ) );
    }
}