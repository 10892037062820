import { Fragment, useEffect, useState, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../hooks/use-translate.hook";
import { DialogInfo, DialogConfirmForm } from "./";

const DeleteItem = ({
    error,
    message,
    tooltip,
    handleSubmit,
    buttonSize,
    submitForm,
    submitting
}) => {
    const t = useTranslate();

    const [ confirm, setConfirm ] = useState( false );
    const [ openError, setOpenError ] = useState( false );
    const toggleConfirm = () => setConfirm( !confirm );
    const toggleError = useCallback(() => setOpenError( !openError ), [ openError ] );

    useEffect( () => {
        error && !openError && toggleError();
    }, [ error, openError, toggleError ] );

    return (
        <Fragment>
            <DialogConfirmForm
                open={ confirm }
                handleSubmit={ handleSubmit }
                message={ message || t( "common:delete-confirm" ) }
                onClose={ toggleConfirm }
                submitForm={ submitForm }
                submitting={ submitting }
            />
            <DialogInfo
                open={ openError }
                maxWidth="xs"
                message={ error || "" }
                onClose={ toggleError }
            />
            <Tooltip title={ tooltip || t( "common:exclude" ) }>
                <IconButton size={ buttonSize } disabled={ submitting } onClick={ toggleConfirm }>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Fragment>
    );
};

export default DeleteItem;