import classnames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Field, FieldArray } from "formik";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CheckIcon from "@mui/icons-material/Check";
import ContactsIcon from "@mui/icons-material/Contacts";
import SignpostIcon from "@mui/icons-material/Signpost";
import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import reject from "lodash/reject";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Draggable } from "react-beautiful-dnd";
import get from "lodash/get";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { flex, cssUtils } from "../../../core";
import { Input, Dropdown } from "../../../ui";
import UserDistributionInput from "./user-distribution-input.component";
import PhaseEntrySchedules from "./phase-entry-schedules.component";
import PhaseSelector from "./phase-selector.component";
import PhaseTriggers from "./phase-triggers.component";
import PhaseAutomation from "./phase-automation.component";
import css from "./funnel.scss";

const PhaseItem = ({ form, index, onDelete }) => {
    const t = useTranslate();

    const [ showTriggers, setShowTriggers ] = useState( false );
    const [ showHelp, setShowHelp ] = useState( false );
    const [ showForward, setShowForward ] = useState( false );
    const [ showCadence, setShowCadence ] = useState( false );
    const [ showEntrySchedules, setSHowEntrySchedules ] = useState( false );
    const [ forwardUsersEmpty, setForwardUsersEmpty ] = useState( false );
    const [ showActivityAutomation, setShowActivityAutomation ] = useState( false );

    const name = useMemo( () => `phases[${index}]`, [ index ] );

    const { setFieldValue } = form;

    const toggleTriggers = () => setShowTriggers( !showTriggers );
    const closeTriggers = () => {
        if ( !form.errors.phases || isEmpty( reject( form.errors.phases[ index ]?.triggers, isEmpty ) ) ) {
            setShowTriggers( false );
        } else {
            form.errors.phases[ index ].triggers.forEach( ( defaultValue, i ) => {
                form.setFieldTouched( `phases[${ index }].triggers[${ i }].template.id` );
            });

        }
    };

    const toggleHelp = () => setShowHelp( !showHelp );

    const openCadence = () => setShowCadence( true );
    const closeCadence = () => {
        const phase = form.values.phases[ index ];
        if ( !phase.cadencePhaseId ^ !phase.cadenceTime ) {
            form.setFieldTouched( `${name}.cadencePhaseId` );
            form.setFieldTouched( `${name}.cadenceTime` );
        } else {
            setShowCadence( false );
        }
    };
    const clearCadence = () => {
        form.setFieldValue( `${name}.cadencePhaseId`, null );
        form.setFieldValue( `${name}.cadenceTime`, null );
    };

    const openForward = () => setShowForward( true );
    const closeForward = () => {
        const phase = form.values.phases[ index ];
        if ( !phase.forwardAfter ) {
            form.setFieldValue( `${name}.forwardAfterType`, null );
            form.setFieldValue( `${name}.forwardToTeamId`, null );
            form.setFieldValue( `${name}.forwardToUsersId`, null );
            setShowForward( false );
            setForwardUsersEmpty( false );
        } else {
            const emptyUsers = !phase.forwardToTeamId && isEmpty( phase.forwardToUsersId );
            const emptyType = !phase.forwardAfterType;
            if ( emptyUsers || emptyType ) {
                form.setFieldTouched( `${name}.forwardAfterType` );
                setForwardUsersEmpty( emptyUsers );
            } else {
                setShowForward( false );
                setForwardUsersEmpty( false );
            }
        }
    };
    const clearForward = () => {
        form.setFieldValue( `${name}.forwardAfter`, null );
        form.setFieldValue( `${name}.forwardAfterType`, null );
        form.setFieldValue( `${name}.forwardToTeamId`, null );
        form.setFieldValue( `${name}.forwardToUsersId`, null );
    };

    const clearForwardTeam = useCallback(
        () => setFieldValue( `${name}.forwardToTeamId`, null ),
        [ name, setFieldValue ]
    );
    const clearForwardUsers = useCallback(
        () => setFieldValue( `${name}.forwardToUsersId`, null ),
        [ name, setFieldValue ]
    );

    const openEntrySchedules = () => setSHowEntrySchedules( true );
    const closeEntrySchedules = () => {
        if ( !form.errors.phases || isEmpty( reject( form.errors.phases[ index ]?.entrySchedules, isEmpty ) ) ) {
            setSHowEntrySchedules( false );
        } else {
            form.errors.phases[ index ].entrySchedules.forEach( ( defaultValue, i ) => {
                form.setFieldTouched( `phases[${ index }].entrySchedules[${ i }].opportunitySchedulingTypeId` );
                form.setFieldTouched( `phases[${ index }].entrySchedules[${ i }].description` );
                form.setFieldTouched( `phases[${ index }].entrySchedules[${ i }].timeType` );
                form.setFieldTouched( `phases[${ index }].entrySchedules[${ i }].amountTime` );
            });

        }
    };

    const openActivityAutomation = () => setShowActivityAutomation( true );
    const closeActivityAutomation = () => {
        form.setFieldValue( `${name}.phaseSchedulingStatus`, [] );
        form.setFieldValue( `${name}.phaseEmailSent`, null );
        form.setFieldValue( `${name}.phaseWhatsappSent`, null );
        form.setFieldValue( `${name}.phasePhoneCallCreated`, null );
        form.setFieldValue( `${name}.phaseSmsSent`, null );
        form.setFieldValue( `${name}.phaseCommentCreated`, null );

        form.setFieldValue( `${name}.showPhaseEmailSent`, false );
        form.setFieldValue( `${name}.showPhaseWhatsappSent`, false );
        form.setFieldValue( `${name}.showPhasePhoneCallCreated`, false );
        form.setFieldValue( `${name}.showPhaseSmsSent`, false );
        form.setFieldValue( `${name}.showPhaseCommentCreated`, false );

        setShowActivityAutomation( false );
    };

    const handleValidatePhaseAutomationFields = () => {
        const isPhaseSchedulingStatusValid = !form.errors.phases ||
            isEmpty( reject( form.errors.phases[ index ]?.phaseSchedulingStatus, isEmpty ) );
        const isPhaseEmailSentInvalid = form.errors.phases && !!get( form.errors.phases[ index ], "phaseEmailSent" );
        const isPhaseWhatsappSentInvalid = form.errors.phases &&
            !!get( form.errors.phases[ index ], "phaseWhatsappSent" );
        const isPhasePhoneCallCreatedInvalid = form.errors.phases &&
            !!get( form.errors.phases[ index ], "phasePhoneCallCreated" );
        const isPhaseSmsSentInvalid = form.errors.phases && !!get( form.errors.phases[ index ], "phaseSmsSent" );
        const isPhaseCommentCreatedInvalid = form.errors.phases &&
            !!get( form.errors.phases[ index ], "phaseCommentCreated" );

        const isFieldsValid = isPhaseSchedulingStatusValid && !isPhaseEmailSentInvalid && !isPhaseWhatsappSentInvalid &&
            !isPhasePhoneCallCreatedInvalid && !isPhaseSmsSentInvalid && !isPhaseCommentCreatedInvalid;

        if ( isFieldsValid ) {
            setShowActivityAutomation( false );
        } else {
            if ( !isPhaseSchedulingStatusValid ) {
                form.errors.phases[ index ].phaseSchedulingStatus.forEach( ( defaultValue, i ) => {
                    form.setFieldTouched(
                        `phases[${ index }].phaseSchedulingStatus[${ i }].opportunitySchedulingTypeId`
                    );
                    form.setFieldTouched( `phases[${ index }].phaseSchedulingStatus[${ i }].schedulingStatus` );
                });
            }

            if ( isPhaseEmailSentInvalid ) {
                form.setFieldTouched( `phases[${ index }].phaseEmailSent` );
            }

            if ( isPhaseWhatsappSentInvalid ) {
                form.setFieldTouched( `phases[${ index }].phaseWhatsappSent` );
            }

            if ( isPhasePhoneCallCreatedInvalid ) {
                form.setFieldTouched( `phases[${ index }].phasePhoneCallCreated` );
            }

            if ( isPhaseSmsSentInvalid ) {
                form.setFieldTouched( `phases[${ index }].phaseSmsSent` );
            }

            if ( isPhaseCommentCreatedInvalid ) {
                form.setFieldTouched( `phases[${ index }].phaseCommentCreated` );
            }
        }
    };

    const totalActiveAutomations = useMemo( () => {
        const phase = form.values.phases[ index ];

        const phasesContact = [
            phase.phaseEmailSent,
            phase.phaseWhatsappSent,
            phase.phasePhoneCallCreated,
            phase.phaseSmsSent,
            phase.phaseCommentCreated,
        ];

        return phasesContact.reduce( ( acc, value ) => acc + ( value ? 1 : 0 ), 0 ) +
            isEmpty( phase.phaseSchedulingStatus ) ? 0 : phase.phaseSchedulingStatus.length;
    }, [ form.values.phases, index ] );

    const className = classnames( flex.container, flex.alignItemsCenter );
    const phase = useMemo( () => form.values.phases[ index ], [ form.values.phases, index ] );

    const prevForwardToTeamId = usePrevious( phase.forwardToTeamId );
    const prevForwardToUsersId = usePrevious( phase.forwardToUsersId );

    useEffect( () => {
        if ( prevForwardToTeamId !== phase.forwardToTeamId ) {
            if ( phase.forwardToTeamId && !isEmpty( phase.forwardToUsersId ) ) {
                clearForwardUsers();
            }
        }
    }, [ clearForwardUsers, phase.forwardToTeamId, phase.forwardToUsersId, prevForwardToTeamId ] );
    useEffect( () => {
        if ( !isEqual( prevForwardToUsersId, phase.forwardToUsersId ) ) {
            if ( phase.forwardToTeamId && !isEmpty( phase.forwardToUsersId ) ) {
                clearForwardTeam();
            }
        }
    }, [ clearForwardTeam, phase.forwardToTeamId, phase.forwardToUsersId, prevForwardToUsersId ] );

    return (
        <Draggable key={ index } draggableId={ index.toString() } index={ index }>
            {
                provided => (
                    <div
                        ref={ provided.innerRef }
                        { ...provided.draggableProps }
                        className={ flex.container }
                    >
                        <div className={ flex.fill }>
                            <div className={ className }>
                                <Dialog
                                    open={ showTriggers }
                                    onClose={ closeTriggers }
                                    maxWidth="md"
                                    fullWidth
                                >
                                    <DialogContent>
                                        <FieldArray
                                            name={ `${name}.triggers` }
                                            component={ PhaseTriggers }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" onClick={ closeTriggers }>{ "OK" }</Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={ showHelp }
                                    maxWidth="sm"
                                    fullWidth
                                >
                                    <DialogContent>
                                        <Field
                                            type="text"
                                            name={ `${name}.description` }
                                            label={ t( "config:phases.item.description" ) }
                                            component={ Input }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" onClick={ toggleHelp }>{ "OK" }</Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={ showCadence }
                                    onClose={ closeCadence }
                                    maxWidth="sm"
                                    fullWidth
                                >
                                    <DialogTitle>
                                        { t( "config:phases.cadence.title" ) }
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            { t( "config:phases.cadence.call-to-action" ) }
                                        </DialogContentText>
                                        <div className={ flex.container }>
                                            <Field
                                                name={ `${name}.cadencePhaseId` }
                                                label={ t( "config:phases.cadence.phaseId" ) }
                                                className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                                required={ !!phase.cadenceTime }
                                                component={ PhaseSelector }
                                                exclude={ [ phase.id ] }
                                            />
                                            <Field
                                                name={ `${name}.cadenceTime` }
                                                type="number"
                                                required={ !!phase.cadencePhaseId }
                                                label={ t( "config:phases.cadence.time" ) }
                                                component={ Input }
                                                className={ flex.item30 }
                                                fullWidth={ false }
                                            />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" onClick={ clearCadence }>
                                            { t( "config:phases.cadence.clear-config" ) }
                                        </Button>
                                        <Button color="primary" onClick={ closeCadence }>{ "OK" }</Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={ showForward }
                                    maxWidth="sm"
                                    fullWidth
                                >
                                    <DialogTitle>
                                        { t( "config:phases.forward.title" ) }
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            { t( "config:phases.forward.call-to-action" ) }
                                        </DialogContentText>
                                        <div className={ flex.container }>
                                            <Field
                                                name={ `${name}.forwardAfter` }
                                                type="number"
                                                label={ t( "config:phases.forward.time" ) }
                                                className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                                component={ Input }
                                            />
                                            <Field
                                                name={ `${name}.forwardAfterType` }
                                                label={ t( "config:phases.forward.time-unit.label" ) }
                                                source={ [ "DAY", "HOUR", "MINUTE" ].map( value => ({
                                                    value,
                                                    label: t( `config:phases.forward.time-unit.${value}` )
                                                }))}
                                                className={ flex.item30 }
                                                required={ !!phase.forwardAfter }
                                                component={ Dropdown }
                                            />
                                        </div>
                                        <div
                                            className={ classnames(
                                                flex.fill,
                                                flex.container,
                                                cssUtils.margin0,
                                                cssUtils.padding0
                                            )}
                                        >
                                            <UserDistributionInput
                                                error={ forwardUsersEmpty }
                                                fullWidth={ false }
                                                teamName={ `${name}.forwardToTeamId` }
                                                usersName={ `${name}.forwardToUsersId` }
                                            />
                                        </div>
                                        {
                                            forwardUsersEmpty &&
                                                <Typography color="error" variant="caption">
                                                    { t( "config:phases.forward.empty-users" ) }
                                                </Typography>
                                        }
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" onClick={ clearForward }>
                                            { t( "config:phases.forward.clear-config" ) }
                                        </Button>
                                        <Button color="primary" onClick={ closeForward }>{ "OK" }</Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={ showEntrySchedules }
                                    maxWidth="sm"
                                    fullWidth
                                >
                                    <DialogTitle>
                                        { t( "config:phases.entry-schedules.title" ) }
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            { t( "config:phases.entry-schedules.call-to-action" ) }
                                        </DialogContentText>
                                        <FieldArray
                                            name={ `${name}.entrySchedules` }
                                            phaseName={ name }
                                            component={ PhaseEntrySchedules }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" onClick={ closeEntrySchedules }>
                                            { t( "common:ok" ) }
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <PhaseAutomation
                                    name={ name }
                                    onClose={ closeActivityAutomation }
                                    open={ showActivityAutomation }
                                    onChange={ form.setFieldValue }
                                    onSave={ handleValidatePhaseAutomationFields }
                                    values={ phase }
                                />
                                <Field name={ `${name}.forwardToTeamId` } type="hidden" component="input"/>
                                <Field name={ `${name}.forwardToUsersId` } type="hidden" component="input"/>

                                <Field
                                    type="text"
                                    name={ `${name}.name` }
                                    label={ t( "config:phases.item.name" ) }
                                    className={ flex.fill }
                                    fullWidth={ false }
                                    component={ Input }
                                    size="small"
                                    required
                                />
                                <Field
                                    type="number"
                                    name={ `${name}.days` }
                                    label={ t( "config:phases.item.days" ) }
                                    className={ cssUtils.marginLeft }
                                    fullWidth={ false }
                                    component={ Input }
                                    size="small"
                                />
                                <Tooltip
                                    title={ t( "config:phases.automation-activity.title" ) } >
                                    <IconButton onClick={ openActivityAutomation } size="large">
                                        {
                                            totalActiveAutomations > 0 ?
                                                <Badge badgeContent={ totalActiveAutomations } color="primary">
                                                    <SignpostIcon/>
                                                </Badge> :
                                                <SignpostIcon/>
                                        }
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "config:phases.trigger.title" ) } placement="bottom">
                                    <IconButton onClick={ toggleTriggers } size="large">
                                        {
                                            phase.triggers && phase.triggers.length > 0 ?
                                                <Badge badgeContent={ phase.triggers.length } color="primary">
                                                    <ContactsIcon/>
                                                </Badge> :
                                                <ContactsIcon/>
                                        }
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "config:phases.cadence.title" ) }>
                                    <IconButton onClick={ openCadence } size="large">
                                        <Badge
                                            badgeContent={
                                                <Avatar className={ css.checkIcon }>
                                                    <CheckIcon/>
                                                </Avatar>
                                            }
                                            invisible={ !phase.cadencePhaseId }
                                        >
                                            <UpdateIcon/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "config:phases.forward.title" ) }>
                                    <IconButton onClick={ openForward } size="large">
                                        <Badge
                                            badgeContent={
                                                <Avatar className={ css.checkIcon }>
                                                    <CheckIcon/>
                                                </Avatar>
                                            }
                                            invisible={ !phase.forwardAfter }
                                        >
                                            <AvTimerIcon/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "config:phases.entry-schedules.title" ) }>
                                    <IconButton onClick={ openEntrySchedules } size="large">
                                        <Badge
                                            badgeContent={ ( phase.entrySchedules || [] ).length }
                                            color="primary"
                                            invisible={ isEmpty( phase.entrySchedules ) }
                                        >
                                            <EventNoteIcon />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "config:phases.item.description" ) }>
                                    <IconButton onClick={ toggleHelp } size="large">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "common:exclude" ) }>
                                    <IconButton onClick={ onDelete } datatype="delete" size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "common:order" ) } >
                                    <IconButton { ...provided.dragHandleProps } size="large"><ReorderIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>

                    </div>
                )}
        </Draggable>
    );
};

export const SortablePhaseItem = PhaseItem;