import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Snackbar from "@mui/material/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import NotesIcon from "@mui/icons-material/Notes";
import PersonIcon from "@mui/icons-material/Person";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../../form";
import { Input, TemplateEditor, cssUtils, flex } from "../../../ui";
import { saveFileModelSection } from "./template.actions";
import { createFileModelSectionForm } from "./template.utils";
import FileModelSectionPreview from "./file-model-section-preview.component";
import css from "./template.scss";

const getVariable = ( label, value ) => (
    "<var contenteditable=\"false\" " +
    "style=\"background: #EF7D36 !important; padding: 2px; border-radius: 4px\" " +
    "data-attribute=\"" + value + "\">" +
    "<span contenteditable=\"true\">" +
    label +
    "</span>" +
    "</var>&nbsp;"
);

const listCompanyFields = ( t, companyFields ) => {
    return companyFields.map( field => ({
        key: `company.fields.${field.id}`,
        name: field.name,
        label: `${t( "customer:company.title" )}: ${field.name}`,
        type: field.type
    }));
};

const listPersonFields = ( t, personFields ) => {
    return personFields.map( field => ({
        key: `person.fields.${field.id}`,
        name: field.name,
        label: `${t( "customer:person.title" )}: ${field.name}`,
        type: field.type
    }));
};

const listOpportunityFields = ( t, opportunityFields ) => {
    return opportunityFields.map( field => ({
        key: `opportunity.fields.${field.id}`,
        name: field.name,
        label: `${t( "opportunity:opportunity" )}: ${field.name}`,
        type: field.type
    }));
};

const MIN_FULL_LAYOUT_WIDTH = 1368;

const FileModelSection = ({ fileModel, fileModelSection, onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const companyFields = useSelector( formSelectors.listWithoutGroupings( "COMPANY" ) );
    const personFields = useSelector( formSelectors.listWithoutGroupings( "PERSON" ) );
    const opportunityFields = useSelector( formSelectors.listWithoutGroupings( "OPPORTUNITY" ) );

    const [ openRequiredFields, setOpenRequiredFields ] = useState( false );
    const [ openPreview, setOpenPreview ] = useState( false );
    const [ openCompanyFields, setOpenCompanyFields ] = useState( false );
    const [ openPersonFields, setOpenPersonFields ] = useState( false );
    const [ openOpportunityFields, setOpenOpportunityFields ] = useState( false );
    const [ openSubscriptionFields, setOpenSubscriptionFields ] = useState( false );
    const [ openUserFields, setOpenUserFields ] = useState( false );
    const [ openCommonFields, setOpenCommonFields ] = useState( false );
    const [ showFields, setShowFields ] = useState( window.innerWidth > MIN_FULL_LAYOUT_WIDTH );
    const [ horizontalScroll, setHorizontalScroll ] = useState( window.innerWidth <= MIN_FULL_LAYOUT_WIDTH );

    const editor = useRef();

    const { header, footer, headerHeight, headerMargin, footerHeight, footerMargin } = fileModel;

    const formik = useForm({
        enableReinitialize: true,
        form: createFileModelSectionForm( fileModelSection ),
        initialValues: {
            name: "",
            body: "",
            ...fileModelSection,
        },
        onSubmit: values => dispatch( saveFileModelSection( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.body || values.body === "<p></p>" ) {
                errors.body = "common:validation.required";
            }
            return errors;
        }
    });

    const watchPageResize = () => {
        if ( window.innerWidth <= MIN_FULL_LAYOUT_WIDTH ) {
            setHorizontalScroll( true );
            setShowFields( false );
        } else if ( window.innerWidth > MIN_FULL_LAYOUT_WIDTH ) {
            setHorizontalScroll( false );
            setShowFields( true );
        }
    };

    const handleOpenRequiredFields = () => setOpenRequiredFields( true );
    const handleCloseRequiredFields = () => setOpenRequiredFields( false );

    const togglePreview = () => setOpenPreview( !openPreview );

    const toggleShowFields = () => setShowFields( !showFields );

    const handleFieldClick = field => () => editor.current.insertContent( getVariable( field.label, field.key ) );

    const handlePhaseClick = () => {
        editor.current.insertContent( getVariable(
            `${t( "opportunity:opportunity" )}: ${t( "opportunity:phase" )}`,
            "opportunity.phase"
        ));
    };

    const handlePriceInWordsClick = () => {
        editor.current.insertContent( getVariable(
            `${t( "opportunity:price.label" )} ${t( "config:files.model.section.in-words" )}`,
            "opportunity.priceInWords"
        ));
    };

    const handleRecurrentPriceInWordsClick = () => {
        editor.current.insertContent( getVariable(
            `${t( "opportunity:recurrent-price" )} ${t( "config:files.model.section.in-words" )}`,
            "opportunity.recurrentPriceInWords"
        ));
    };

    const handleProductsClick = tableKey => () => {
        const tableTitle = tableKey === "products" ?
            t( "config:products.title" ) :
            tableKey === "productsWithRecurrence" ?
                t( "config:files.model.section.products-with-recurrence" ) :
                t( "config:files.model.section.products-without-recurrence" );
        const labelProduct = t( "opportunity:proposal-product.product" );
        const labelProducts = t( "config:products.title" );
        const labelDescription = t( "config:product.description" );
        const labelQuantity = t( "opportunity:proposal-product.quantity" );
        const labelUnitPrice = t( "opportunity:proposal-product.unit-price" );
        const labelDiscount = t( "opportunity:proposal-product.discount" );
        const labelTotalPrice = t( "opportunity:proposal-product.total" );

        const table = `
            <table
                style="font-family: verdana, geneva, sans-serif; border-collapse: collapse; width: 100%;"
                border="1"
                cellpadding="5"
                cellspacing="0"
            >
                <thead>
                    <tr>
                        <th colspan="5" style="text-align: left; font-size: 8pt">${tableTitle}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="page-break-inside:avoid">
                        <th style="width: 35%; text-align: left; font-size: 8pt">${labelDescription}</th>
                        <th style="width: 15%; text-align: left; font-size: 8pt">${labelQuantity}</th>
                        <th style="width: 20%; text-align: left; font-size: 8pt">${labelUnitPrice}</th>
                        <th style="width: 12%; text-align: left; font-size: 8pt">${labelDiscount}</th>
                        <th style="width: 18%; text-align: right; font-size: 8pt">${labelTotalPrice}</th>
                    </tr>
                    <tr data-attribute=${tableKey} style="page-break-inside:avoid">
                        <td style="font-size: 8pt">
                            ${getVariable( labelProduct + ": " + labelDescription, "product.description" )}
                        </td>
                        <td style="font-size: 8pt">
                            ${getVariable( labelProduct + ": " + labelQuantity, "product.quantity" )}
                        </td>
                        <td style="font-size: 8pt">
                            ${getVariable( labelProduct + ": " + labelUnitPrice, "product.unitPrice" )}
                        </td>
                        <td style="font-size: 8pt">
                            ${getVariable( labelProduct + ": " + labelDiscount, "product.discount" )}
                        </td>
                        <td style="font-size: 8pt; text-align: right">
                            ${getVariable( labelProduct + ": " + labelTotalPrice, "product.total" )}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align:right; font-size: 8pt">
                            <strong>${labelTotalPrice}&nbsp; &nbsp;</strong>
                        </td>
                        <td style="text-align:right; font-size: 8pt">
                            ${getVariable( labelProducts + ": " + labelTotalPrice, "products.total" )}
                        </td>
                    </tr>
                </tbody>
            </table>
        `;

        editor.current.insertContent( table );
    };

    const handleProposalClick = ( key, label ) => () => {
        editor.current.insertContent( getVariable(
            `${t( "config:files.model.section.proposal" )}: ${label}`,
            `proposal.${key}`
        ));
    };

    const handleSubscriptionClick = ( key, label ) => () => {
        editor.current.insertContent( getVariable(
            `${t( "config:subscription.title" )}: ${label}`,
            key
        ));
    };

    const handleUserClick = ( key, label ) => () => {
        editor.current.insertContent( getVariable(
            `${t( "config:team.users.single" )}: ${label}`,
            key
        ));
    };

    const handleCommonClick = ( key, label ) => () => editor.current.insertContent( getVariable( label, key ));

    const setHeaderAndFooter = () => {
        if ( !header && !footer ) {
            return;
        }

        let iframe;
        const iframes = document.getElementsByTagName( "iframe" );
        for ( let i = 0; i < iframes.length; i++ ) {
            if ( iframes[ i ].id === "templateEditor_ifr" ) {
                iframe = iframes[ i ];
            }
        }
        const innerDoc = iframe.contentDocument.documentElement || iframe.contentWindow.document;
        if ( header ) {
            const headerStyle = (
                `width: ${ headerMargin ? "21cm" : "23cm" }; ` +
                `height: ${headerHeight}cm; background: white; margin: 1px auto 0 auto; overflow-wrap: break-word;` +
                "position: absolute; left: 0; right: 0;"
            );

            const headerElement = document.createElement( "div" );
            headerElement.style.cssText = headerStyle;
            headerElement.innerHTML = header;
            innerDoc.insertBefore( headerElement, innerDoc.firstChild );
        }
        if ( footer ) {
            const footerStyle = (
                `width: ${footerMargin ? "21cm" : "23cm"}; ` +
                `height: ${footerHeight}cm; background: white; margin: 0 auto 1px auto; overflow-wrap: break-word;` +
                "position: absolute; bottom: 1.8em; left: 0; right: 0;"
            );

            const footerElement = document.createElement( "div" );
            footerElement.style.cssText = footerStyle;
            footerElement.innerHTML = footer;
            innerDoc.appendChild( footerElement );
        }
    };

    useEffect( () => {
        window.addEventListener( "resize", watchPageResize );

        return () => {
            window.removeEventListener( "resize", watchPageResize );
        };
    }, [] );

    return (
        <Dialog open={ open } maxWidth="md" disableEnforceFocus fullScreen>
            <AppBar classes={{ root: css.newTemplateBar }}>
                <Toolbar>
                    <IconButton color="inherit" onClick={ onClose } disabled={ formik.submitting } size="large">
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={ classnames( flex.fill, cssUtils.marginLeft ) }
                    >
                        { fileModelSection.id ?
                            t( "config:files.model.section.edit" ) :
                            t( "config:files.model.section.new" )
                        }
                    </Typography>
                    <Button
                        color="inherit"
                        onClick={ formik.isValid ? togglePreview : handleOpenRequiredFields }
                        disabled={ formik.submitting }
                    >
                        { t( "common:visualize" ) }
                    </Button>

                    <Button
                        color="inherit"
                        onClick={ formik.submitForm }
                        disabled={ formik.submitting }
                    >
                        { t( "common:save" ) }
                    </Button>
                </Toolbar>
            </AppBar>

            <Snackbar
                open={ openRequiredFields }
                message={ t( "common:validation.required-fields" ) }
                action={
                    <Button color="secondary" onClick={ handleCloseRequiredFields }>
                        { "OK" }
                    </Button>
                }
            />

            <FileModelSectionPreview
                fileModelSection={ fileModelSection }
                onClose={ togglePreview }
                open={ openPreview }
            />

            <DialogContent>
                <Form
                    formik={ formik }
                    className={ classnames( cssUtils.fullHeight, flex.displayFlex, flex.column )}
                >
                    <Field
                        name="name"
                        label={ t( "config:files.model.section.name" ) }
                        component={ Input }
                        required
                    />
                    <div
                        className={ classnames(
                            cssUtils.marginTop, cssUtils.fullHeight, flex.displayFlex, cssUtils.overflowHidden
                        )}
                    >
                        <div
                            className={ classnames(
                                cssUtils.overflowAuto,
                                flex.item25,
                                !showFields && cssUtils.displayNone
                            ) }
                        >
                            <List
                                subheader={
                                    <ListSubheader disableSticky className={ cssUtils.paddingLeft0}>
                                        { t( "config:files.model.section.fields" ) }
                                    </ListSubheader>
                                }
                            >
                                <ListItemButton
                                    onClick={ () => setOpenCompanyFields( !openCompanyFields ) }
                                    selected={ openCompanyFields }
                                >
                                    <ListItemIcon>
                                        <BusinessIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t( "config:files.model.section.company" ) }/>
                                    { openCompanyFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openCompanyFields }>
                                    <List component="div" dense disablePadding>
                                        {
                                            listCompanyFields( t, companyFields ).map( field => (
                                                <ListItemButton
                                                    key={ field.id }
                                                    className={ cssUtils.paddingLeftLarge }
                                                    onClick={ handleFieldClick( field ) }
                                                >
                                                    <ListItemText primary={ field.name }/>
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                                <Divider/>
                                <ListItemButton
                                    onClick={ () => setOpenPersonFields( !openPersonFields ) }
                                    selected={ openPersonFields }
                                >
                                    <ListItemIcon>
                                        <PersonIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "config:files.model.section.person" ) }/>
                                    { openPersonFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openPersonFields }>
                                    <List component="div" dense disablePadding>
                                        {
                                            listPersonFields( t, personFields ).map( field => (
                                                <ListItemButton
                                                    key={ field.id }
                                                    className={ cssUtils.paddingLeftLarge }
                                                    onClick={ handleFieldClick( field ) }
                                                >
                                                    <ListItemText primary={ field.name }/>
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                                <Divider/>
                                <ListItemButton
                                    onClick={ () => setOpenOpportunityFields( !openOpportunityFields ) }
                                    selected={ openOpportunityFields }
                                >
                                    <ListItemIcon>
                                        <AttachMoneyIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "config:files.model.section.opportunity" ) }/>
                                    { openOpportunityFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openOpportunityFields }>
                                    <List component="div" dense disablePadding>
                                        <ListSubheader disableSticky>
                                            { t( "config:files.model.section.form" ) }
                                        </ListSubheader>
                                        {
                                            listOpportunityFields( t, opportunityFields ).map( field => (
                                                <ListItemButton
                                                    key={ field.id }
                                                    className={ cssUtils.paddingLeftLarge }
                                                    onClick={ handleFieldClick( field ) }
                                                >
                                                    <ListItemText primary={ field.name }/>
                                                </ListItemButton>
                                            ))
                                        }
                                        <ListSubheader disableSticky>
                                            { t( "config:files.model.section.overview" ) }
                                        </ListSubheader>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handlePhaseClick }
                                        >
                                            <ListItemText
                                                primary={ t( "opportunity:phase" ) }
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handlePriceInWordsClick }
                                        >
                                            <ListItemText
                                                primary={ `${t( "opportunity:price.label" )}
                                                ${t( "config:files.model.section.in-words" )}` }
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handleRecurrentPriceInWordsClick }
                                        >
                                            <ListItemText
                                                primary={ `${t( "opportunity:recurrent-price" )}
                                                ${t( "config:files.model.section.in-words" )}` }
                                            />
                                        </ListItemButton>
                                        <Fragment>
                                            <ListSubheader disableSticky>
                                                { `${t( "config:products.title" )} (${t( "common:table" )})` }
                                            </ListSubheader>
                                            <ListItemButton
                                                className={ cssUtils.paddingLeftLarge }
                                                onClick={ handleProductsClick( "products" ) }
                                            >
                                                <ListItemText
                                                    primary={ t( "config:files.model.section.products-all" ) }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                className={ cssUtils.paddingLeftLarge }
                                                onClick={ handleProductsClick( "productsWithRecurrence" ) }
                                            >
                                                <ListItemText
                                                    primary={
                                                        t( "config:files.model.section.products-with-recurrence" )
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                className={ cssUtils.paddingLeftLarge }
                                                onClick={ handleProductsClick( "productsWithoutRecurrence" ) }
                                            >
                                                <ListItemText
                                                    primary={ t(
                                                        "config:files.model.section.products-without-recurrence"
                                                    )}
                                                />
                                            </ListItemButton>
                                        </Fragment>
                                        <Fragment>
                                            <ListSubheader disableSticky>
                                                { t( "config:files.model.section.proposal" ) }
                                            </ListSubheader>
                                            <ListItemButton
                                                className={ cssUtils.paddingLeftLarge }
                                                onClick={ handleProposalClick( "name", t( "common:name" ) ) }
                                            >
                                                <ListItemText
                                                    primary={ t( "common:name" ) }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                className={ cssUtils.paddingLeftLarge }
                                                onClick={ handleProposalClick(
                                                    "sequence",
                                                    t( "config:files.model.section.proposal-sequence" )
                                                ) }
                                            >
                                                <ListItemText
                                                    primary={ t( "config:files.model.section.proposal-sequence" ) }
                                                    secondary={
                                                        t( "config:files.model.section.proposal-sequence-help" )
                                                    }
                                                />
                                            </ListItemButton>
                                        </Fragment>
                                    </List>
                                </Collapse>
                                <Divider/>
                                <ListItemButton
                                    onClick={ () => setOpenSubscriptionFields( !openSubscriptionFields ) }
                                    selected={ openSubscriptionFields }
                                >
                                    <ListItemIcon>
                                        <AccountBalanceIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "config:subscription.title" ) }/>
                                    { openSubscriptionFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openSubscriptionFields }>
                                    <List component="div" dense disablePadding>
                                        {
                                            [
                                                {
                                                    key: "subscription.name",
                                                    label: t( "config:subscription.name" )
                                                },
                                                { key: "subscription.ein", label: t( "config:subscription.ein" ) },
                                                { key: "subscription.ssn", label: t( "config:subscription.ssn" ) },
                                                {
                                                    key: "subscription.phone",
                                                    label: t( "config:subscription.phone" )
                                                }
                                            ].map( item => (
                                                <ListItemButton
                                                    className={ cssUtils.paddingLeftLarge }
                                                    onClick={ handleSubscriptionClick( item.key, item.label ) }
                                                >
                                                    <ListItemText primary={ item.label } />
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                                <Divider/>
                                <ListItemButton
                                    onClick={ () => setOpenUserFields( !openUserFields ) }
                                    selected={ openUserFields }
                                >
                                    <ListItemIcon>
                                        <AccountCircleIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "config:team.users.single" ) }/>
                                    { openUserFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openUserFields }>
                                    <List component="div" dense disablePadding>
                                        {
                                            [
                                                { key: "user.name", label: t( "user:user.name" ) },
                                                { key: "user.email", label: t( "user:user.email" ) },
                                                { key: "user.phone", label: t( "config:subscription.phone" ) }
                                            ].map( item => (
                                                <ListItemButton
                                                    className={ cssUtils.paddingLeftLarge }
                                                    onClick={ handleUserClick( item.key, item.label ) }
                                                >
                                                    <ListItemText primary={ item.label } />
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                                <ListItemButton
                                    onClick={ () => setOpenCommonFields( !openCommonFields ) }
                                    selected={ openCommonFields }
                                >
                                    <ListItemIcon>
                                        <NotesIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "config:files.model.section.overview" ) }/>
                                    { openCommonFields ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                </ListItemButton>
                                <Collapse in={ openCommonFields }>
                                    <List component="div" dense disablePadding>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handleCommonClick(
                                                "common.dateTime",
                                                t( "config:files.model.section.date-time" )
                                            )}
                                        >
                                            <ListItemText primary={ t( "config:files.model.section.date-time" ) }/>
                                        </ListItemButton>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handleCommonClick(
                                                "common.date",
                                                t( "config:files.model.section.date" )
                                            )}
                                        >
                                            <ListItemText primary={ t( "config:files.model.section.date" ) }/>
                                        </ListItemButton>
                                        <ListItemButton
                                            className={ cssUtils.paddingLeftLarge }
                                            onClick={ handleCommonClick(
                                                "common.time",
                                                t( "config:files.model.section.time" )
                                            )}
                                        >
                                            <ListItemText primary={ t( "config:files.model.section.time" ) }/>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </List>
                        </div>
                        {
                            horizontalScroll &&
                                <Tooltip
                                    title={
                                        showFields ?
                                            t( "config:files.model.section.hide-fields" ) :
                                            t( "config:files.model.section.show-fields" )
                                    }
                                    placement="right"
                                >
                                    <ButtonBase
                                        onClick={ toggleShowFields }
                                        className={ classnames( cssUtils.marginRightSmall, cssUtils.marginTop ) }
                                    >
                                        { showFields ? <KeyboardArrowLeft /> : <KeyboardArrowRight /> }
                                    </ButtonBase>
                                </Tooltip>
                        }
                        <div className={ classnames( flex.fill )}>
                            <Field
                                id="templateEditor"
                                name="body"
                                label={ t( "config:files.model.section.body" ) }
                                templateType="EMAIL"
                                component={ TemplateEditor }
                                showVariables={ false }
                                style={
                                    // html
                                    "html {" +
                                    "position: relative;" +
                                    "padding: calc( 2 * 0.6em * 1.5 );" +
                                    "background: #e0e0e0;}" +
                                    // body
                                    "body { width: 21cm;" +
                                    `min-height: calc(${29.7 - headerHeight - footerHeight}cm - 2px);` +
                                    `padding: ${headerHeight}cm 2cm ${footerHeight}cm 2cm;` +
                                    "border: 1px hsl( 0,0%,82.7% ) solid;" +
                                    "border-radius: var(--ck-border-radius);" +
                                    "background: white;" +
                                    "box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );" +
                                    "color: black;" +
                                    "margin: 0 auto;" +
                                    "overflow: auto;" +
                                    "vertical-align: baseline; }"
                                }
                                setup={ ed => {
                                    editor.current = ed;
                                    setTimeout( setHeaderAndFooter, 600 );
                                }}
                                required
                                hasRuler
                            />
                        </div>
                    </div>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default FileModelSection;