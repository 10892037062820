import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { validateFields, selectors as formSelectors } from "../../form";
import CustomerForm from "./customer-form.component";
import { saveNewOpportunityPerson } from "../opportunity.actions";
import { SAVE_PERSON_FORM } from "../opportunity.constants";
import { getNewOpportunityPerson } from "../opportunity.selectors";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const EditPerson = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getNewOpportunityPerson );
    const fields = useSelector( formSelectors.list( customer.type ) );

    const initialValues = useMemo( () => ({
        id: customer.id,
        fields: {
            ...fieldsToInitialValues( fields ),
            ...customer.fields
        }
    }), [ fields, customer ] );

    const formik = useForm({
        form: SAVE_PERSON_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewOpportunityPerson( values ) ),
        validate: values => {
            let errors = {};
            if ( !values ) {
                return errors;
            }
            errors = validateFields( values, { t, fields } );
            return errors;
        },
    });

    return <CustomerForm fields={ fields } formik={ formik } onClose={ onClose } />;
};

export default EditPerson;
