import { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../.";

const BrandTypes = ({ field, helperText, form: { getFieldMeta, setFieldValue } }) => {
    const t = useTranslate();

    const value = useMemo(
        () => field && Array.isArray( field.value ) ? field.value : [],
        [ field ]
    );

    const handleChange = selectedValue => () => {
        const newValue = value.includes( selectedValue ) ?
            value.filter( item => item !== selectedValue ) :
            value.concat( selectedValue );
        if ( field ) {
            setFieldValue( field.name, newValue );
        }
    };

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, getFieldMeta ] );

    return (
        <FormControl className={ cssUtils.marginBottomSmall } error={ !!validationMsg } fullWidth>
            <FormGroup row>
                <FormControlLabel
                    className={ flex.fill }
                    control={
                        <Checkbox
                            className={ classnames( cssUtils.paddingBottom0, cssUtils.paddingTop0 ) }
                            checked={ value.includes( "CAR" ) }
                            onChange={ handleChange( "CAR" ) }
                        />
                    }
                    label={ t( "ui:brands.type.CAR" ) }
                />
                <FormControlLabel
                    className={ flex.fill }
                    control={
                        <Checkbox
                            className={ classnames( cssUtils.paddingBottom0, cssUtils.paddingTop0 ) }
                            checked={ value.includes( "MOTORCYCLE" ) }
                            onChange={ handleChange( "MOTORCYCLE" ) }
                        />
                    }
                    label={ t( "ui:brands.type.MOTORCYCLE" ) }
                />
                <FormControlLabel
                    className={ flex.fill }
                    control={
                        <Checkbox
                            className={ classnames( cssUtils.paddingBottom0, cssUtils.paddingTop0 ) }
                            checked={ value.includes( "TRUCK" ) }
                            onChange={ handleChange( "TRUCK" ) }
                        />
                    }
                    label={ t( "ui:brands.type.TRUCK" ) }
                />
            </FormGroup>
            { helperText && <FormHelperText>{ helperText }</FormHelperText> }
            { validationMsg && <FormHelperText color="error">{ t( validationMsg ) }</FormHelperText> }
        </FormControl>
    );
};

export default BrandTypes;