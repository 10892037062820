export const PATCH_SUBSCRIPTION = "x/config/subscription/main/PATCH_SUBSCRIPTION";
export function patchSubscription ( subscription ) {
    return {
        type: PATCH_SUBSCRIPTION,
        payload: { subscription }
    };
}

// ---
// Address
// --
export const FETCH_SUBSCRIPTION_ADDRESS = "x/config/subscription/main/FETCH_SUBSCRIPTION_ADDRESS";
export const fetchSubscriptionAddress = zipCode => ({
    type: FETCH_SUBSCRIPTION_ADDRESS,
    meta: { zipCode },
});

export const REQUEST_SUBSCRIPTION_ADDRESS = "x/config/subscription/main/REQUEST_SUBSCRIPTION_ADDRESS";
export const requestSubscriptionAddress = () => ({
    type: REQUEST_SUBSCRIPTION_ADDRESS,
});

export const RECEIVE_SUBSCRIPTION_ADDRESS = "x/config/subscription/main/RECEIVE_SUBSCRIPTION_ADDRESS";
export const receiveSubscriptionAddress = address => ({
    type: RECEIVE_SUBSCRIPTION_ADDRESS,
    payload: address,
});

export const ERROR_SUBSCRIPTION_ADDRESS = "x/config/subscription/main/ERROR_SUBSCRIPTION_ADDRESS";
export const errorSubscriptionAddress = error => ({
    type: ERROR_SUBSCRIPTION_ADDRESS,
    payload: error,
    error: true,
});