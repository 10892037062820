import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListSubHeader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import qs from "qs";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex, cssUtils } from "../../../ui";
import { fetchUsers } from "../../../user/user.actions";
import { fetchSources } from "../../../source/source.actions";
import { fetchTeams } from "../../../team/team.actions";
import FacebookIcon from "../../../ui/icons/facebook.component";
import NewFacebookLeadPage from "./new-facebook-lead-page.component";
import FacebookLeadPageItem from "./facebook-lead-page-item.component";
import { fetchFacebookLeadPages, fetchFacebookToken } from "./facebook-integration.actions";
import { getFacebookLeadPages, getFacebookAuth, isLoadingFacebookAuth } from "./facebook-integration.selectors";
import css from "./facebook-integration.scss";
import cssMarketplace from "../../marketplace.scss";
import { fetchDistributions } from "../../../config/advanced/distribution/distribution.actions";

const FacebookIntegration = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const t = useTranslate();

    const pages = useSelector( getFacebookLeadPages );
    const facebookAccessToken = useSelector( getFacebookAuth );
    const isLoadingAccessToken = useSelector( isLoadingFacebookAuth );

    const [ add, setAdd ] = useState( false );

    const handleAdd = useCallback( () => {
        window.location.href = "/facebook/auth?state=newPage";
    }, [] );

    const openAdd = useCallback( () => setAdd( true ), [] );

    const closeAdd = useCallback( () => setAdd( false ), [] );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    useEffect( () => {
        dispatch( fetchDistributions() );
        dispatch( fetchFacebookLeadPages() );
        dispatch( fetchSources() );
        dispatch( fetchTeams() );
        dispatch( fetchUsers() );
    }, [ dispatch ] );

    useEffect( () => {
        const queryProps = qs.parse( location.search, { ignoreQueryPrefix: true } );
        if ( !!queryProps.code ) {
            const data = {
                code: queryProps.code
            };

            dispatch( fetchFacebookToken( data ) );
        }
    }, [ dispatch, location.search ] );

    useEffect( () => {
        const queryProps = qs.parse( location.search, { ignoreQueryPrefix: true } );
        if ( !isLoadingAccessToken && facebookAccessToken && queryProps.state === "newPage" ) {
            openAdd();
        }
    }, [ isLoadingAccessToken, facebookAccessToken, location.search, openAdd ] );

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ css.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            <div className={ cssMarketplace.facebookIcon }/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            { t( "marketplace:integrations.facebook.info" ) }
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader
                        title={ t( "marketplace:integrations.facebook.lead-pages.title" ) }
                        subheader={ t( "marketplace:integrations.facebook.lead-pages.call-to-action" ) }
                    />
                    <CardContent>
                        <Button
                            className={ css.facebookButton }
                            onClick={ handleAdd }
                            disabled={ isLoadingAccessToken }
                        >
                            <FacebookIcon className={ css.facebookButtonIcon }/>
                            { t( "marketplace:integrations.facebook.lead-pages.add" ) }
                        </Button>
                    </CardContent>
                    {
                        !isEmpty( pages ) &&
                            <List
                                subheader={
                                    <ListSubHeader>
                                        { t( "marketplace:integrations.facebook.lead-pages.your-pages" ) }
                                    </ListSubHeader>
                                }
                            >
                                {
                                    pages.map( page => (
                                        <FacebookLeadPageItem
                                            key={ page.id }
                                            facebookLeadPage={ page }
                                        />
                                    ))
                                }
                            </List>
                    }
                </Card>
                <NewFacebookLeadPage
                    open={ add }
                    onClose={ closeAdd }
                />
            </div>
        </div>
    );
};

export default FacebookIntegration;