import { combineReducers } from "redux";

import { formConfig } from "./form/form.reducers";
import { templateConfig } from "./template/template.reducers";
import { productConfig } from "./product/product.reducers";

export const opportunity = combineReducers({
    formConfig,
    templateConfig,
    productConfig
});