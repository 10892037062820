import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import classnames from "classnames";
import order from "lodash/orderBy";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { fetchCommissions } from "../commission/commission.actions";
import { getCommissions, isLoadingCommissions } from "../commission/commission.selectors";
import { Chart, Dropdown, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { fetchResults } from "./report.actions";
import { isLoadingResults, getResults } from "./report.selectors";

const CommissionResultsByCommission = ({ month, year, }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const commissions = useSelector( getCommissions );
    const loadingResults = useSelector( isLoadingResults );
    const loadingCommissions = useSelector( isLoadingCommissions );
    const results = useSelector( getResults );
    const loading = loadingResults || loadingCommissions;

    const [ commission, setCommission ] = useState( null );
    const [ orderBy, setOrderBy ] = useState( "user" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChangeCommissionId = event => {
        const value = commissions.find( commission => commission.id === event.target.value );
        setCommission( value );
    };
    const handleChangePage = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const model = useMemo( () => {
        const model = {
            user: t( "report:commission-results-by-user.user" ),
            price: {
                title: t( "report:commission-results-by-user.price" ),
                format: priceFormat,
            },
        };
        if ( commission?.goalId ) {
            model.goalReached = {
                title: t( "report:commission-results-by-user.goal-reached" ),
                format: goalReached => {
                    if ( goalReached === "" ) {
                        return "";
                    }
                    return goalReached ? t( "common:yes" ) : t( "common:no" );
                },
            };
        }

        return model;
    }, [ t, priceFormat, commission ] );
    const source = useMemo( () => {
        return order(
            ( results || [] ).map( item => ({
                ...item,
                user: item.user.name,
            })),
            [ orderBy ],
            [ orderType.toLowerCase() ],
        );
    }, [ results, orderBy, orderType ] );
    const footer = results && {
        user: t( "common:total" ),
        price: results.reduce( ( model, item ) => model + item.price, 0 ),
        goalReached: "",
    };

    const refreshResults = useCallback( () => {
        if ( commission ) {
            dispatch( fetchResults({
                report: "COMMISSION_RESULTS_BY_USER",
                commissionId: commission.id,
                month,
                year,
            }) );
        }
    }, [ dispatch, commission, month, year ] );

    const chartData = useMemo( () => {
        if ( !loading && results ) {
            return {
                chart: {
                    type: "column",
                    style: {
                        fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
                    }
                },
                title: {
                    text: " "
                },
                xAxis: {
                    categories: source.map( item => item.user ),
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ""
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                tooltip: {
                    formatter: function () {
                        return this.series.name + ": " + priceFormat( this.y );
                    }
                },
                series: [{
                    name: t( "report:commission-results-by-user.price" ),
                    data: source.map( item => ({
                        color: item.goalReached ? "#43a047" : "#c62828",
                        y: item.price,
                    })),
                }]
            };
        }

        return null;
    }, [ t, priceFormat, loading, results, source ] );

    useEffect( () => {
        refreshResults();
    }, [ refreshResults ] );

    useEffect( () => {
        dispatch( fetchCommissions() );
    }, [ dispatch ] );

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.COMMISSION_RESULTS_BY_USER" ) }
                subheader={ t( "report:commission-results-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.padding, cssUtils.marginBottomSmall
                )}
            >
                <Dropdown
                    label={ t( "report:commission-results-by-user.commission" ) }
                    value={ commission?.id }
                    onChange={ handleChangeCommissionId }
                    source={ commissions?.map( commission => ({ value: commission.id, label: commission.name } ) )}
                    disabled={ loading }
                    fullWidth={ false }
                    className={ flex.fill }
                    required
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper className={ flex.fill }>
                    {
                        chartData &&
                            <Chart
                                id="commissionResultsByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="commissionResultsByUserTable"
                                    allowExport
                                    allowOrder
                                    onChangePage={ handleChangePage }
                                    model={ model }
                                    footer={ footer }
                                    source={ source }
                                    title={ t( "report:reports.COMMISSION_RESULTS_BY_USER" ) }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default CommissionResultsByCommission;