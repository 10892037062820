import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import classnames from "classnames";
import set from "lodash/set";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { Checkbox, flex } from "../../../ui";
import { SUBSCRIPTION_MAIN_FORM } from "../../subscription/main/main.constants";
import * as actions from "../../subscription/main/main.actions";
import ConfigHeader from "../../config-header.component";
import { selectors as subscriptionSelectors } from "../../../subscription";
import OfficeHours from "./office-hours.component";
import { isEndBeforeStart } from "./rules.utils";
import css from "./rules.scss";

const validate = values => {
    const errors = {};
    // segunda a sexta
    if ( get( values, "officeHours.startAt.start" ) && get( values, "officeHours.startAt.end" ) ) {
        const endBeforeStart = isEndBeforeStart(
            get( values, "officeHours.startAt.start" ),
            get( values, "officeHours.startAt.end" )
        );
        if ( endBeforeStart ) {
            set( errors, "officeHours.startAt.start", "common:validation.higher-end" );
            set( errors, "officeHours.startAt.end", "common:validation.lower-start" );
        }
    }
    if ( !get( values, "officeHours.startAt.start" ) ) {
        set( errors, "officeHours.startAt.start", "common:validation.required" );
    }
    if ( !get( values, "officeHours.startAt.end" ) ) {
        set( errors, "officeHours.startAt.end", "common:validation.required" );
    }
    if ( get( values, "officeHours.endAt.start" ) && get( values, "officeHours.startAt.end" ) ) {
        const endBeforeStart = isEndBeforeStart(
            get( values, "officeHours.startAt.end" ),
            get( values, "officeHours.endAt.start" )
        );
        if ( endBeforeStart ) {
            set( errors, "officeHours.endAt.start", "common:validation.lower-start-period" );
            set( errors, "officeHours.startAt.end", "common:validation.higher-end-period" );
        }
    }
    if ( get( values, "officeHours.endAt.start" ) && get( values, "officeHours.endAt.end" ) ) {
        const endBeforeStart = isEndBeforeStart(
            get( values, "officeHours.endAt.start" ),
            get( values, "officeHours.endAt.end" )
        );
        if ( endBeforeStart ) {
            set( errors, "officeHours.endAt.start", "common:validation.higher-end" );
            set( errors, "officeHours.endAt.end", "common:validation.lower-start" );
        }
    }
    if ( !get( values, "officeHours.endAt.start" ) ) {
        set( errors, "officeHours.endAt.start", "common:validation.required" );
    }
    if ( !get( values, "officeHours.endAt.end" ) ) {
        set( errors, "officeHours.endAt.end", "common:validation.required" );
    }

    //sabado
    if ( values.officeHours && values.officeHours.workOnSaturday ) {
        if ( get( values, "officeHours.saturdayStartAt.start" ) && get( values, "officeHours.saturdayStartAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.saturdayStartAt.start" ),
                get( values, "officeHours.saturdayStartAt.end" )
            );
            if ( endBeforeStart ) {
                set( errors, "officeHours.saturdayStartAt.start", "common:validation.higher-end" );
                set( errors, "officeHours.saturdayStartAt.end", "common:validation.lower-start" );
            }
        }
        if ( !get( values, "officeHours.saturdayStartAt.start" ) ) {
            set( errors, "officeHours.saturdayStartAt.start", "common:validation.required" );
        }
        if ( !get( values, "officeHours.saturdayStartAt.end" ) ) {
            set( errors, "officeHours.saturdayStartAt.end", "common:validation.required" );
        }
        if ( get( values, "officeHours.saturdayEndAt.start" ) && get( values, "officeHours.saturdayStartAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.saturdayStartAt.end" ),
                get( values, "officeHours.saturdayEndAt.start" )
            );
            if ( endBeforeStart ) {
                set( errors, "officeHours.saturdayEndAt.start", "common:validation.lower-start-period" );
                set( errors, "officeHours.saturdayStartAt.end", "common:validation.higher-end-period" );
            }
        }
        if ( get( values, "officeHours.saturdayEndAt.start" ) && get( values, "officeHours.saturdayEndAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.saturdayEndAt.start" ),
                get( values, "officeHours.saturdayEndAt.end" )
            );
            if ( endBeforeStart ) {
                set( errors, "officeHours.saturdayEndAt.start", "common:validation.higher-end" );
                set( errors, "officeHours.saturdayEndAt.end", "common:validation.lower-start" );
            }
        }
        if ( !get( values, "officeHours.saturdayEndAt.start" ) ) {
            set( errors, "officeHours.saturdayEndAt.start", "common:validation.required" );
        }
        if ( !get( values, "officeHours.saturdayEndAt.end" ) ) {
            set( errors, "officeHours.saturdayEndAt.end", "common:validation.required" );
        }

    }

    //domingo
    if ( values.officeHours && values.officeHours.workOnSunday ) {
        if ( get( values, "officeHours.sundayStartAt.start" ) && get( values, "officeHours.sundayStartAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.sundayStartAt.start" ),
                get( values, "officeHours.sundayStartAt.end" )
            );
            if ( endBeforeStart ) {
                set( errors, "officeHours.sundayStartAt.start", "common:validation.higher-end" );
                set( errors, "officeHours.sundayStartAt.end", "common:validation.lower-start" );
            }
        }
        if ( !get( values, "officeHours.sundayStartAt.start" ) ) {
            set( errors, "officeHours.sundayStartAt.start", "common:validation.required" );
        }
        if ( !get( values, "officeHours.sundayStartAt.end" ) ) {
            set( errors, "officeHours.sundayStartAt.end", "common:validation.required" );
        }
        if ( get( values, "officeHours.sundayEndAt.start" ) && get( values, "officeHours.sundayStartAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.sundayEndAt.start" ),
                get( values, "officeHours.sundayStartAt.end" )
            );
            if ( !endBeforeStart ) {
                set( errors, "officeHours.sundayEndAt.start", "common:validation.lower-start-period" );
                set( errors, "officeHours.sundayStartAt.end", "common:validation.higher-end-period" );
            }
        }
        if ( get( values, "officeHours.sundayEndAt.start" ) && get( values, "officeHours.sundayEndAt.end" ) ) {
            const endBeforeStart = isEndBeforeStart(
                get( values, "officeHours.sundayEndAt.start" ),
                get( values, "officeHours.sundayEndAt.end" )
            );
            if ( endBeforeStart ) {
                set( errors, "officeHours.sundayEndAt.start", "common:validation.higher-end" );
                set( errors, "officeHours.sundayEndAt.end", "common:validation.lower-start" );
            }
        }
        if ( !get( values, "officeHours.sundayEndAt.start" ) ) {
            set( errors, "officeHours.sundayEndAt.start", "common:validation.required" );
        }
        if ( !get( values, "officeHours.sundayEndAt.end" ) ) {
            set( errors, "officeHours.sundayEndAt.end", "common:validation.required" );
        }

    }

    return errors;
};
const Rules = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );

    const formik = useForm({
        form: SUBSCRIPTION_MAIN_FORM,
        enableReinitialize: true,
        initialValues: {
            customerPortfolio: false,
            blockNonUniqueCustomer: false,
            blockOutOfHours: false,
            ...subscription,
        },
        onSubmit: values => dispatch( actions.patchSubscription( values ) ),
        validate,
    });

    return (
        <div>
            <ConfigHeader title={ t( "config:main.advanced.rules" ) }/>
            <Form formik={ formik }>
                <Card>
                    <CardContent>
                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                name="customerPortfolio"
                                label={ t( "config:subscription.customer-portfolio.label" ) }
                                type="checkbox"
                                fullWidth={ false }
                                component={ Checkbox }
                            />
                            <Tooltip title={ t( "config:subscription.customer-portfolio.help" ) }>
                                <HelpIcon className={ css.helpIcon }/>
                            </Tooltip>
                        </div>
                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                name="blockNonUniqueCustomer"
                                label={ t( "config:subscription.block-non-unique-customer.label" ) }
                                type="checkbox"
                                fullWidth={ false }
                                component={ Checkbox }
                            />
                            <Tooltip title={ t( "config:subscription.block-non-unique-customer.help" ) }>
                                <HelpIcon className={ css.helpIcon }/>
                            </Tooltip>
                        </div>
                        <hr/>
                        <Typography
                            color="textSecondary"
                            variant="subtitle1"
                            paragraph
                        >
                            { t( "config:subscription.office-hours.title" ) }
                        </Typography>
                        <OfficeHours setFieldValue={ formik.setFieldValue } values={ formik.values }/>
                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                name="blockOutOfHours"
                                label={ t( "config:subscription.block-out-of-hours.label" ) }
                                type="checkbox"
                                fullWidth={ false }
                                component={ Checkbox }
                            />
                            <Tooltip title={ t( "config:subscription.block-out-of-hours.help" ) }>
                                <HelpIcon className={ css.helpIcon }/>
                            </Tooltip>
                        </div>
                    </CardContent>
                    <CardActions className={ flex.justifyContentEnd }>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={ formik.submitting }
                        >
                            { t( "config:subscription.save" ) }
                        </Button>
                        {
                            formik.error &&
                                <Typography color="error">
                                    { formik.error.error }
                                </Typography>
                        }
                    </CardActions>
                </Card>
            </Form>
        </div>
    );
};

export default Rules;