import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import LoopIcon from "@mui/icons-material/Loop";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { actions as sourceActions } from "../../../source";
import { actions as userActions } from "../../../user";
import ConfigHeader from "../../config-header.component";
import { fetchMigrations } from "./migration.actions";
import { getMigrations, isLoadingMigrations } from "./migration.selectors";
import NewMigration from "./new-migration.component";

const Migrations = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );

    const loading = useSelector( isLoadingMigrations );
    const migrations = useSelector( getMigrations );

    const [ system, setSystem ] = useState( "" );
    const [ showNewMigration, setShowNewMigration ] = useState( false );

    const toggleNewMigration = () => setShowNewMigration( !showNewMigration );

    const handleChangeSystem = system => {
        toggleNewMigration();
        setSystem( system );
    };

    const loadMigrations = () => dispatch( fetchMigrations() );

    useEffect( () => {
        dispatch( fetchMigrations() );
        dispatch( sourceActions.fetchSources() );
        dispatch( userActions.fetchUsers() );
    }, [ dispatch ] );


    const iconMigration = migration => {
        if ( migration.finishedAt ) {
            return (
                <Tooltip
                    title={ t( "config:migrations.finished-at", {
                        date: dateTimeFormat( migration.finishedAt )
                    })}
                >
                    <DoneIcon/>
                </Tooltip>
            );
        }
        return (
            <Tooltip title={ t( "config:migrations.executing" ) }>
                <LoopIcon/>
            </Tooltip>
        );
    };

    const renderItem = system => {
        const migration = migrations.find( m => m.system === system );
        return (
            <Paper>
                <CardHeader
                    title={ t( "config:migrations.system.PIPEDRIVE" ) }
                />
                {
                    migration &&
                        <List>
                            <ListItem key={ migration.id }>
                                <ListItemIcon>
                                    { iconMigration( migration ) }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        t( "config:migrations.api-token" ) +
                                        ": " +
                                        migration.auth.token
                                    }
                                    secondary={
                                        t( "config:migrations.executed-by", {
                                            user: migration.executedBy.name,
                                            date: dateTimeFormat( migration.executedAt )
                                        })
                                    }
                                />
                            </ListItem>
                        </List>
                }
            </Paper>
        );
    };

    return (
        <>
            <ConfigHeader
                title={ t( "config:migrations.title" ) }
                onActionClick={ () => handleChangeSystem( "PIPEDRIVE" ) }
                actionTitle={ t( "config:migrations.add" ) }
                actionIcon={ AddIcon }
            />
            <Paper>
                <CardHeader
                    title={ t( "config:migrations.title" ) }
                    subheader={ t( "config:migrations.help" ) }
                    action={
                        <Tooltip title={ t( "config:migrations.refresh" ) }>
                            <IconButton onClick={ loadMigrations } size="large">
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                />

                { ( loading || !migrations ) && <LinearProgress/> }

                { migrations && renderItem( "PIPEDRIVE" ) }

            </Paper>

            <NewMigration open={ showNewMigration } system={ system } onClose={ () => handleChangeSystem() }/>
        </>
    );
};

export default Migrations;