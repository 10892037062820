export default {
    "client-not-found": "No se encontró el cliente de OAuth",
    "logged-as": "Has iniciado sesión como",
    "call-to-action": "{ client } solicita acceso a:",
    "scopes": {
        "read-all": "Leer datos no administrativos",
        "save-customer": "Crea y cambia clientes",
        "create-opportunity": "Crear oportunidades",
        "update-opportunity": "Oportunidades de cambio",
        "save-scheduling": "Crear y cambiar horarios",
        "webhooks": "Webhooks"
    },
    "authorize": "Autorizar",
};