import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";

import useTranslate from "../../hooks/use-translate.hook";
import { phoneFormatInternational } from "../../core/formatter.utils";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link } from "../../ui";

const PhoneCallEvent = ({ event: { user, date, payload } }) => {
    const t = useTranslate();

    return (
        <TimelineItem icon={ <PhoneIcon/> }>
            <TimelineSummary
                user={ user }
                description={
                    payload.opportunityId ?
                        <span>
                            { t( "customer:timeline.phone-call-opportunity",
                                { phone: phoneFormatInternational( payload.phone ) }
                            )}
                            <Link href={ "/opportunities/" + payload.opportunityId }>
                                <a> { payload.opportunityTitle } </a>
                            </Link>
                        </span> :
                        <span>
                            { t( "customer:timeline.phone-call",
                                { phone: phoneFormatInternational( payload.phone ) }
                            )}
                        </span>
                }
                date={ date }
            />
            { payload.description && <Typography>{ payload.description }</Typography> }
        </TimelineItem>
    );
};

export default PhoneCallEvent;