import { FastField, FieldArray } from "formik";

import {
    AddressField,
    TextField,
    EinField,
    EmailField,
    NumberField,
    PhoneField,
    SelectField,
    SelectUserField,
    SelectMultipleField,
    SsnField,
    BrandField,
    ModelField,
    PriceField,
    DateField
} from "./";

const componentMap = {
    ADDRESS: { component: AddressField, wrapper: FieldArray, array: true },
    TEXT: { component: TextField, wrapper: FastField },
    DATE: { component: DateField, wrapper: FastField },
    EMAIL: { component: EmailField, wrapper: FieldArray, array: true },
    PHONE: { component: PhoneField, wrapper: FieldArray, array: true },
    NUMBER: { component: NumberField, wrapper: FastField },
    PRICE: { component: PriceField, wrapper: FastField },
    SELECT: { component: SelectField, wrapper: FastField },
    SELECT_MULTIPLE: { component: SelectMultipleField, wrapper: FastField }
};

const systemFieldComponentMap = {
    BRAND: { component: BrandField, wrapper: FastField },
    EIN: { component: EinField, wrapper: FastField },
    MODEL: { component: ModelField, wrapper: FastField },
    SSN: { component: SsnField, wrapper: FastField },
    USER: { component: SelectUserField, wrapper: FastField }
};

const CustomField = ({ field, ...props }) => {
    const systemFieldComponent = field.systemField ? systemFieldComponentMap[ field.systemField ] : null;
    const fieldCfg = systemFieldComponent ? systemFieldComponent : componentMap[ field.type ];

    if ( !fieldCfg ) {
        return null;
    }

    if ( fieldCfg.array ) {
        return (
            <fieldCfg.wrapper
                name={ props.name }
                render={ componentProps =>
                    <fieldCfg.component
                        { ...componentProps }
                        formField={ field }
                        component={ fieldCfg.component }
                        label={ field.name }
                        required={ field.options && field.options.required }
                    />
                }
            />
        );
    }

    return (
        <fieldCfg.wrapper
            { ...props }
            formField={ field }
            component={ fieldCfg.component }
            label={ field.name }
            required={ field.options && field.options.required }
        />
    );
};

export default CustomField;