import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import { DeleteItem } from "../../ui";
import { deleteAutomation } from "./automation.actions";
import { createDeleteAutomationForm } from "./automation.utils";

const DeleteAutomation = ({ automation }) => {
    const dispatch = useDispatch();

    const formik = useForm({
        form: createDeleteAutomationForm( automation.token ),
        initialValues: { token: automation.token },
        onSubmit: values => dispatch( deleteAutomation( values ) ),
    });

    return (
        <DeleteItem
            error={ formik.error }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteAutomation;