import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as phaseSelectors } from "../../../phase";
import { Dialog, cssUtils, flex } from "../../../ui";
import { deleteFunnel } from "./funnel.actions";
import { createDeleteFormFunnel } from "./funnel.utils";
import PhaseSelector from "./phase-selector.component";

const DeleteFunnel = ({ funnel, onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const phases = useSelector( phaseSelectors.listWithFunnel( funnel.id ) );

    const formik = useForm({
        form: createDeleteFormFunnel( funnel.id ),
        initialValues: {
            id: funnel.id,
            phases: {}
        },
        onSubmit: values => dispatch( deleteFunnel( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            errors.phases = {};
            phases.forEach( phase => {
                if ( !values.phases || !values.phases[ phase.id ] ) {
                    errors.phases[ phase.id ] = "common:validation.selection";
                }
            });
            if ( Object.keys( errors.phases ).length === 0 ) {
                delete errors.phases;
            }
            return errors;
        }
    });

    const clearErrors = () => formik.resetForm();

    return (
        <Fragment>
            <Dialog open={ show } maxWidth="sm" fullWidth>
                <DialogTitle>
                    { t( "config:funnels.delete.title" ) }
                </DialogTitle>
                <DialogContent>
                    <Form formik={ formik }>
                        <DialogContentText>
                            { t( "config:funnels.delete.confirm" ) }
                        </DialogContentText>
                        {
                            phases.map( phase => (
                                <div
                                    key={ phase.id }
                                    className={ classnames( flex.fill, flex.container, flex.alignItemsCenter ) }
                                >
                                    <Typography className={ flex.fill } variant="subtitle1" color="textSecondary">
                                        { phase.name }
                                    </Typography>
                                    <ArrowForwardIcon
                                        className={
                                            classnames( cssUtils.marginLeftSmall, cssUtils.marginRightSmall )
                                        }
                                    />
                                    <Field
                                        name={ `phases[${phase.id}]` }
                                        className={ flex.fill }
                                        component={ PhaseSelector }
                                        funnel={ funnel }
                                        label={ t( "config:funnels.delete.new-phase" ) }
                                        required
                                    />
                                </div>
                            ))
                        }
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:exclude" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={ !!formik.error }
                message={ formik.error }
                action={ <Button onClick={ clearErrors } color="error">{ "OK" }</Button> }
                onClose={ clearErrors }
                autoHideDurationtimeout={ 5000 }
            />
        </Fragment>
    );
};

export default DeleteFunnel;