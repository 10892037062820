import { useForm } from "formik-redux";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import classnames from "classnames";
import qs from "qs";

import useTranslate from "../hooks/use-translate.hook";
import { getUser } from "../core/core.selectors";
import { createAuthorizationCode, fetchClient } from "./oauth.actions";
import { OAUTH_FORM } from "./oauth.constants";
import { isLoadingClient, getClient, getClientError, getAuthorizationCode } from "./oauth.selectors";
import layoutCss from "../layout/layout.scss";
import css from "./oauth.scss";

const OAuthClient = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const loading = useSelector( isLoadingClient );
    const client = useSelector( getClient );
    const clientError = useSelector( getClientError );
    const user = useSelector( getUser );
    const code = useSelector( getAuthorizationCode );

    const queryProps = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location.search ] );

    const formik = useForm({
        form: OAUTH_FORM,
        initialValues: {
            clientId: queryProps.clientId,
            redirectUri: queryProps.redirectUri,
        },
        onSubmit: values => dispatch( createAuthorizationCode( values ) ),
    });

    useEffect( () => {
        if ( queryProps.clientId ) {
            dispatch( fetchClient( queryProps.clientId ) );
        }
    }, [ dispatch, queryProps.clientId ] );
    useEffect( () => {
        if ( code ) {
            let redirectUri = queryProps.redirectUri;
            if ( redirectUri.slice( -1 ) === "/" ) {
                redirectUri = redirectUri.slice( 0, -1 );
            }

            window.open( `${redirectUri}?code=${code}&state=${queryProps.state}`, "_self" );
        }
    }, [ code, queryProps.redirectUri, queryProps.state ] );

    return (
        <div className={ classnames( layoutCss.layout, layoutCss.marketingImage ) }>
            <Helmet title={ `${t( "oauth:authorize" )} - LeadStation` } />

            <div className={ layoutCss.content }>
                <Card className={ css.container }>
                    { loading && <LinearProgress /> }
                    <CardContent className={ css.header }>
                        <img src="https://img.leadstation.com.br/logo/248x158-horizontal.png"/>
                    </CardContent>
                    {
                        !loading && !client && !clientError &&
                            <CardContent>
                                <Typography align="center" color="error">
                                    { t( "oauth:client-not-found" ) }
                                </Typography>
                            </CardContent>
                    }
                    {
                        !loading && !client && clientError &&
                            <CardContent>
                                <Typography align="center" color="error">
                                    { clientError }
                                </Typography>
                            </CardContent>
                    }
                    {
                        formik.error &&
                            <CardContent>
                                <Typography align="center" color="error">
                                    { formik.error }
                                </Typography>
                            </CardContent>
                    }
                    {
                        !loading && client &&
                            <>
                                <CardContent className={ css.header }>
                                    <Typography align="center" color="textSecondary" variant="subtitle1">
                                        { t( "oauth:logged-as" ) }
                                    </Typography>
                                </CardContent>
                                <CardHeader
                                    avatar={
                                        <Avatar className={ css.avatar } src={ user.avatar }>
                                            { user.avatar ? "" : user.name.toUpperCase().charAt( 0 ) }
                                        </Avatar>
                                    }
                                    title={ user.name }
                                    titleTypographyProps={{
                                        variant: "h6"
                                    }}
                                    subheader={ user.email }
                                    subheaderTypographyProps={{
                                        variant: "subtitle1"
                                    }}
                                />
                                <CardContent>
                                    <Typography align="center" variant="subtitle2">
                                        { t( "oauth:call-to-action", { client: client.name } ) }
                                    </Typography>
                                    <List>
                                        { client.scopes.map( ( scope, index ) => (
                                            <ListItem key={ index } disableGutters>
                                                <ListItemIcon>
                                                    <CheckBoxIcon/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ t( `oauth:scopes.${scope}`) }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <CardActions className={ css.actions }>
                                    <Button
                                        color="primary"
                                        disabled={ formik.submitting }
                                        onClick={ formik.submitForm }
                                        variant="contained"
                                    >
                                        { t( "oauth:authorize" ) }
                                    </Button>
                                </CardActions>
                            </>
                    }
                </Card>
            </div>
        </div>
    );
};

export default OAuthClient;