import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslate from "../hooks/use-translate.hook";
import { actions as funnelActions, selectors as funnelSelectors } from "../funnel";
import { Dropdown } from ".";

const SelectFunnel = ({ getFunnelValue, ...props }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( funnelSelectors.isLoadingAll );
    const funnels = useSelector( funnelSelectors.listAll );

    const source = useMemo( () => {
        const funnelValue = getFunnelValue ? getFunnelValue : funnel => funnel.id;

        return funnels ? funnels.map( funnel => ({ value: funnelValue( funnel ), label: funnel.name }) ) : [];
    }, [ funnels, getFunnelValue ] );

    useEffect( () => {
        if ( !loading && !funnels ) {
            dispatch( funnelActions.fetchFunnels() );
        }
    }, [ dispatch, funnels, loading ] );

    return (
        <Dropdown
            label={ t( "opportunity:funnel" ) }
            disabled={ loading }
            source={ source }
            { ...props }
        />
    );
};

export default SelectFunnel;