import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { saveProposal } from "../opportunity.actions";
import { NEW_PROPOSAL_FORM } from "../opportunity.constants";
import { ProposalForm, validate } from "./proposal-form.component";

const AddProposal = ({ open, onClose, opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_PROPOSAL_FORM,
        initialValues: {
            opportunityId,
            name: "",
            products: [],
            fileModelId: null,
        },
        onSubmit: values => dispatch( saveProposal( NEW_PROPOSAL_FORM )( values ) ),
        onSubmitSuccess: onClose,
        validate,
    });

    return (
        <ProposalForm
            formik={ formik }
            open={ open }
            onClose={ onClose }
            title={ t( "opportunity:proposals.add" ) }
        />
    );
};

export default AddProposal;