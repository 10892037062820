import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { selectors as formSelectors } from "../form";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const OpportunitiesSoldByField = ({ initialDate, finalDate }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const fields = useSelector( formSelectors.listSelectFields( "OPPORTUNITY" ) );
    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ fieldId, setFieldId ] = useState( "" );
    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "value" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo(
        () => order( results || [], [ orderBy ], [ orderType.toLowerCase() ] ),
        [ results, orderBy, orderType ]
    );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.value ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: t( "report:opportunities-lost-by-reason.total" ),
            data: orderedResults.map( item => item.total ),
            color: "#4CAF50"
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => {
        const totalOpportunities = orderedResults.reduce( ( model, item ) => model + item.total, 0 );

        return orderedResults.map( item => ({
            ...item,
            price: priceFormat( item.price ) + "",
            recurrentPrice: priceFormat( item.recurrentPrice ) + "",
            total: item.total + "",
            percentage: item.total > 0 ?
                ( +( Math.round( ( ( 100 * item.total ) / totalOpportunities ) + "e+2" ) + "e-2" ) ) + "%" :
                "0%"
        }));
    }, [ priceFormat, orderedResults ] );

    useEffect( () => {
        const userField = fields.find( field => field.systemField === "USER" );
        setFieldId( userField?.id );
    }, [ fields ] );

    const model = {
        value: fieldId ? fields.find( field => field.id === fieldId ).name : "",
        percentage: t( "report:opportunities-sold-by-field.percentage" ),
        price: t( "opportunity:price.label" ),
        recurrentPrice: t( "opportunity:recurrent-price" ),
        total: t( "report:opportunities-sold-by-field.total" ),
    };

    const footer = {
        price: priceFormat( orderedResults.reduce(( acc, item ) => acc + item.price, 0 ) ),
        recurrentPrice: priceFormat( orderedResults.reduce(( acc, item ) => acc + item.recurrentPrice, 0 ) ),
        total: orderedResults.reduce(( acc, item ) => acc + item.total, 0 )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITIES_SOLD_BY_FIELD" ) }
                subheader={ t( "report:opportunities-sold-by-field.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "users", "field" ]}
                    required={[ "field", "funnels" ]}
                    onChange={ handleChange }
                    report={ "OPPORTUNITIES_SOLD_BY_FIELD" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                    fieldId={ fieldId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="opportunitiesSoldByFieldChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                        <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                            <Table
                                id="opportunitiesSoldByFieldTable"
                                allowExport
                                model={ model }
                                source={ source }
                                footer={ footer }
                                title={ t( "report:reports.OPPORTUNITIES_SOLD_BY_FIELD" ) }
                                allowOrder
                                onChangePage={ changeOrder }
                                orderBy={ orderBy }
                                orderType={ orderType }
                            />
                        </div>
                }
            </div>
        </div>
    );
};

export default OpportunitiesSoldByField;