export const FETCH_BRANDS = "x/FETCH_BRANDS";
export const fetchBrands = brandType => ({
    type: FETCH_BRANDS,
    meta: { brandType }
});

export const REQUEST_BRANDS = "x/REQUEST_BRANDS";
export const requestBrands = brandType => ({
    type: REQUEST_BRANDS,
    meta: { brandType }
});

export const RECEIVE_BRANDS = "x/RECEIVE_BRANDS";
export const receiveBrands = ( brandType, data ) => ({
    type: RECEIVE_BRANDS,
    meta: { brandType },
    payload: data
});

export const ERROR_BRANDS = "x/ERROR_BRANDS";
export const errorBrands = ( brandType, error ) => ({
    type: ERROR_BRANDS,
    meta: { brandType },
    error: true,
    payload: error
});