import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { getSchedulingTypes } from "../../../opportunity/opportunity.selectors";
import { Dropdown, flex } from "../../../ui";
import { cssUtils } from "../../../ui";

const SCHEDULE_SITUATIONS = [
    "OPEN",
    "ACCOMPLISHED",
];

const PhaseAutomationSchedulesRegisteredList = ({ form, name, push, remove }) => {
    const t = useTranslate();

    const schedulingTypes = useSelector( getSchedulingTypes );

    const schedulingStatusSituationSource = useMemo( () => {
        return SCHEDULE_SITUATIONS.map( timeType => ({
            label: t( `config:phases.automation-activity.schedule.status.${timeType}` ),
            value: timeType,
        } ) );
    }, [ t ] );

    const handleAdd = useCallback( () => {
        push({});
    }, [ push ] );

    const handleRemove = useCallback( index => () => {
        remove( index );
    }, [ remove ] );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    return (
        <>
            {
                isEmpty( values ) ?
                    <Typography
                        className={ cssUtils.marginTopSmall }
                        variant="subtitle1"
                        color="textSecondary"
                    >
                        { t("config:phases.automation-activity.registered-schedules-opportunity-empty" ) }
                    </Typography> :
                    <List>
                        {
                            values.map( ( item, index ) => (
                                <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                    <Field
                                        name={ `${name}[${index}].opportunitySchedulingTypeId` }
                                        component={ Dropdown }
                                        label={ t( "config:phases.automation-activity.schedule.label" ) }
                                        source={
                                            schedulingTypes.map( schedulingType => ({
                                                value: schedulingType.id,
                                                label: schedulingType.name,
                                            } ) )
                                        }
                                        required
                                        className={ flex.fill }
                                    />
                                    <Field
                                        name={ `${name}[${index}]schedulingStatus` }
                                        component={ Dropdown }
                                        label={ t( "config:phases.automation-activity.schedule.status.label" ) }
                                        source={ schedulingStatusSituationSource }
                                        required
                                        className={ classnames( cssUtils.marginLeftSmall, flex.item25 ) }
                                        fullWidth={ false }
                                    />
                                    <IconButton onClick={ handleRemove( index ) } size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </li>
                            ))
                        }
                    </List>
            }
            <Button size="small" onClick={ handleAdd } className={ cssUtils.marginTopSmall }>
                { t( "config:phases.automation-activity.schedule.add" ) }
            </Button>
        </>
    );
};

export default PhaseAutomationSchedulesRegisteredList;