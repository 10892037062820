import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";

import useTranslate from "../../../hooks/use-translate.hook";
import { createEinValidation } from "../../../core/validator.utils";
import PortalForm, { validateForm } from "./portal-form.component";
import { savePortal } from "./portal-integration.actions";
import { createEditPortalForm } from "./portal-integration.utils";

const EditPortal = ({ portal }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const isEinValid = createEinValidation({ t });

    const [ show, setShow ] = useState( false );

    const toggleOpen = () => setShow( !show );

    const formik = useForm({
        enableReinitialize: true,
        form: createEditPortalForm( portal.token ),
        initialValues: portal,
        onSubmit: values => dispatch( savePortal( values ) ),
        onSubmitSuccess: () => setShow( false ),
        validate: values => validateForm( values, { isEinValid }),
    });

    const translateIntegrationChannel = portal &&
        t( `marketplace:integrations.portals.type.${portal.type}` );

    return (
        <>
            <Dialog open={ show } fullWidth maxWidth="md">
                <DialogTitle>
                    { t( "marketplace:integrations.portals.edit.title" ) }
                    { portal.type && ( " - " + translateIntegrationChannel ) }
                </DialogTitle>
                <DialogContent>
                    <Form formik={ formik }>
                        <PortalForm
                            form={ formik }
                            portalType={ portal.type }
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    { formik.error && <Typography color="error">{ formik.error }</Typography> }
                    <Button color="primary" onClick={ toggleOpen } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton onClick={ toggleOpen } size="large">
                <EditIcon/>
            </IconButton>
        </>
    );
};

export default EditPortal;