import { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import { createDateDistanceInWords } from "../core/formatter.utils";
import { isLoadingStatistics, getStatistics } from "./customer.selectors";
import { fetchStatistics } from "./customer.actions";
import { flex } from "../ui";
import css from "./customer-kpis.scss";

const CustomerKPIs = ({ className, customerId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingStatistics );
    const statistics = useSelector( getStatistics );

    useEffect( () => {
        dispatch( fetchStatistics( customerId ) );
    }, [ dispatch, customerId ] );

    if ( loading || !statistics ) {
        return <LinearProgress/>;
    }

    return (
        <div className={ classnames( flex.container, flex.columnGapSmall, className ) }>
            <Card className={ flex.fill }>
                <div className={ css.kpiContainer }>
                    <div className={ classnames( css.kpi, css.totalTrading ) }>
                        <Typography variant="subtitle1">{ t( "customer:kpis.total-trading" ) }</Typography>
                        <Typography variant="h6">
                            <Tooltip title={ t( "opportunity:price.label" ) }>
                                <span>
                                    { priceFormat( statistics.openOpportunitiesPrice ) }
                                </span>
                            </Tooltip>
                            { " / " }
                            <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                <span>
                                    { priceFormat( statistics.openOpportunitiesRecurrentPrice ) }
                                </span>
                            </Tooltip>
                        </Typography>
                        <Typography variant="subtitle2">
                            { t( "common:total" ) }
                            { ": " }
                            { priceFormat(
                                statistics.openOpportunitiesPrice + statistics.openOpportunitiesRecurrentPrice
                            ) }
                        </Typography>
                    </div>
                    <div className={ classnames( css.kpi, css.totalPurchased ) }>
                        <Typography variant="subtitle1">{ t( "customer:kpis.total-purchased" ) }</Typography>
                        <Typography variant="h6">
                            <Tooltip title={ t( "opportunity:price.label" ) }>
                                <span>
                                    { priceFormat( statistics.soldOpportunitiesPrice ) }
                                </span>
                            </Tooltip>
                            { " / " }
                            <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                <span>
                                    { priceFormat( statistics.soldOpportunitiesRecurrentPrice ) }
                                </span>
                            </Tooltip>
                        </Typography>
                        <Typography variant="subtitle2">
                            { t( "common:total" ) }
                            { ": " }
                            { priceFormat(
                                statistics.soldOpportunitiesPrice + statistics.soldOpportunitiesRecurrentPrice
                            ) }
                        </Typography>
                    </div>
                </div>
            </Card>

            <Card className={ flex.fill }>
                <div className={ css.kpiContainer }>
                    <div className={ classnames( css.kpi, css.lastContacted ) }>
                        <Typography variant="subtitle1">{ t( "customer:kpis.last-contact" ) }</Typography>
                        <Typography variant="h6">
                            {
                                statistics.lastContactAt ?
                                    createDateDistanceInWords( statistics.lastContactAt ) :
                                    "-"
                            }
                        </Typography>
                    </div>
                    <div className={ classnames( css.kpi, css.lastPurchase ) }>
                        <Typography variant="subtitle1">{ t( "customer:kpis.last-purchase" ) }</Typography>
                        <Typography variant="h6">
                            {
                                statistics.lastOpportunitySoldAt ?
                                    createDateDistanceInWords( statistics.lastOpportunitySoldAt ) :
                                    "-"
                            }
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export class _CustomerKPIs extends Component {

    constructor ( props ) {
        super( props );

        this.props.fetchStatistics( this.props.customerId );
    }

    shouldComponentUpdate ( nextProps ) {
        return nextProps.statistics !== this.props.statistics || this.props.customerId !== nextProps.customerId;
    }

    componentDidUpdate ( prevProps ) {
        if ( this.props.customerId && prevProps.customerId !== this.props.customerId ) {
            this.props.fetchStatistics( this.props.customerId );
        }
    }

    render () {
        const { t, className, priceFormat, loading, statistics } = this.props;
        if ( loading || !statistics ) {
            return <LinearProgress/>;
        }

        return (
            <div className={ classnames( flex.container, flex.columnGapSmall, className ) }>
                <Card className={ flex.fill }>
                    <div className={ css.kpiContainer }>
                        <div className={ classnames( css.kpi, css.totalTrading ) }>
                            <Typography variant="subtitle1">{ t( "customer:kpis.total-trading" ) }</Typography>
                            <Typography variant="h6">
                                <Tooltip title={ t( "opportunity:price.label" ) }>
                                    <span>
                                        { priceFormat( statistics.openOpportunitiesPrice ) }
                                    </span>
                                </Tooltip>
                                { " / " }
                                <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                    <span>
                                        { priceFormat( statistics.openOpportunitiesRecurrentPrice ) }
                                    </span>
                                </Tooltip>
                            </Typography>
                            <Typography variant="subtitle2">
                                { t( "common:total" ) }
                                { ": " }
                                { priceFormat(
                                    statistics.openOpportunitiesPrice + statistics.openOpportunitiesRecurrentPrice
                                ) }
                            </Typography>
                        </div>
                        <div className={ classnames( css.kpi, css.totalPurchased ) }>
                            <Typography variant="subtitle1">{ t( "customer:kpis.total-purchased" ) }</Typography>
                            <Typography variant="h6">
                                <Tooltip title={ t( "opportunity:price.label" ) }>
                                    <span>
                                        { priceFormat( statistics.soldOpportunitiesPrice ) }
                                    </span>
                                </Tooltip>
                                { " / " }
                                <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                    <span>
                                        { priceFormat( statistics.soldOpportunitiesRecurrentPrice ) }
                                    </span>
                                </Tooltip>
                            </Typography>
                            <Typography variant="subtitle2">
                                { t( "common:total" ) }
                                { ": " }
                                { priceFormat(
                                    statistics.soldOpportunitiesPrice + statistics.soldOpportunitiesRecurrentPrice
                                ) }
                            </Typography>
                        </div>
                    </div>
                </Card>

                <Card className={ flex.fill }>
                    <div className={ css.kpiContainer }>
                        <div className={ classnames( css.kpi, css.lastContacted ) }>
                            <Typography variant="subtitle1">{ t( "customer:kpis.last-contact" ) }</Typography>
                            <Typography variant="h6">
                                {
                                    statistics.lastContactAt ?
                                        createDateDistanceInWords( statistics.lastContactAt ) :
                                        "-"
                                }
                            </Typography>
                        </div>
                        <div className={ classnames( css.kpi, css.lastPurchase ) }>
                            <Typography variant="subtitle1">{ t( "customer:kpis.last-purchase" ) }</Typography>
                            <Typography variant="h6">
                                {
                                    statistics.lastOpportunitySoldAt ?
                                        createDateDistanceInWords( statistics.lastOpportunitySoldAt ) :
                                        "-"
                                }
                            </Typography>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

}

export default CustomerKPIs;