import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1} from "../../../../api/sagas";
import * as actions from "./migration.actions";
import { NEW_MIGRATION_FORM } from "./migration.constants";

export function *watchConfigAdvancedMigration () {
    yield all([
        takeEvery( actions.FETCH_MIGRATIONS, fetchMigrations ),
        takeEvery( actions.CREATE_MIGRATION, createMigration ),
    ]);
}

export function *fetchMigrations () {
    yield put( actions.requestMigrations() );

    try {
        const response = yield call( apiv1.get, "/migrations" );
        yield put( actions.receiveMigrations( response.data ) );
    } catch ( e ) {
        yield put( actions.errorMigrations( e.response.data ) );
    }
}

export function *createMigration ({ payload }) {
    yield put( startSubmit( NEW_MIGRATION_FORM ) );

    try {
        yield call( apiv1.post, `/migrations/${payload.system}`, payload );
        yield put( stopSubmit( NEW_MIGRATION_FORM ) );
        yield put( actions.fetchMigrations() );
    } catch ( e ) {
        yield put( stopSubmit( NEW_MIGRATION_FORM, e.response.data.error ) );
    }
}