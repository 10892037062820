export const FETCH_CLIENT = "x/FETCH_OAUTH_CLIENT";
export const fetchClient = id => ({
    type: FETCH_CLIENT,
    meta: { id }
});

export const REQUEST_CLIENT = "x/REQUEST_OAUTH_CLIENT";
export const requestClient = () => ({
    type: REQUEST_CLIENT
});

export const RECEIVE_CLIENT = "x/RECEIVE_OAUTH_CLIENT";
export const receiveClient = client => ({
    type: RECEIVE_CLIENT,
    payload: client
});

export const ERROR_CLIENT = "x/ERROR_OAUTH_CLIENT";
export const errorClient = error => ({
    type: ERROR_CLIENT,
    payload: error,
    error: true
});

export const CREATE_AUTHORIZATION_CODE = "x/CREATE_OAUTH_AUTHORIZATION_CODE";
export const createAuthorizationCode = data => ({
    type: CREATE_AUTHORIZATION_CODE,
    payload: data
});

export const SET_AUTHORIZATION_CODE = "x/SET_OAUTH_AUTHORIZATION_CODE";
export const setAuthorizationCode = code => ({
    type: SET_AUTHORIZATION_CODE,
    payload: code
});