import { combineReducers } from "redux";

import { facebookIntegration } from "./facebook/facebook-integration.reducers";
import { leadIntegration } from "./lead/lead-integration.reducers";
import { portalIntegration } from "./portal/portal-integration.reducers";
import { apiKeyIntegration } from "./api-key/api-key.reducers";

export const integrations = combineReducers({
    facebookIntegration,
    leadIntegration,
    portalIntegration,
    apiKeyIntegration
});