export default {
    "dialog": {
        "greeting": "Olá, { user }",
        "text":
            "Seja bem-vindo ao LeadStation.<br />" +
            "Para facilitar sua jornada pelo nosso CRM, temos uma opção de adicionar automaticamente dados fictícios " +
            "nos cadastros de Clientes (Empresa/Pessoa), Produtos, Oportunidades, Agendamentos e Fluxo/Fases de " +
            "funil.<br />" +
            "Quando terminar seus testes, você pode remover todos os dados de uma só vez. Para isso é só acessar " +
            "Configurações na aba Geral em Inscrições e clicar em Remover Dados Fictícios.",
        "add-samples": "Adicionar dados fictícios no LeadStation para testes"
    },
    "title": "Olá, {userName}!",
    "intro":
        "Seja bem vindo ao LeadStation!\nGuiaremos você nos primeiros passos com o sistema.\n" +
        "Clique em uma das opções indicadas abaixo e vamos começar.",
    "add-users": {
        "title": "Convide usuários",
        "description": "Envie convites para seus colegas de trabalho."
    },
    "add-teams": {
        "title": "Cadastre suas equipes",
        "description": "Configure suas equipes."
    }
};