import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSubscription } from "react-stomp-hooks";
import config from "config";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import LaunchIcon from "@mui/icons-material/Launch";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { actions as logoutActions } from "../logout";
import { getUser } from "../core/core.selectors";
import Notifications from "./notifications.component";
import {
    toggleMenu,
    readNotification,
    receiveNotificationsUnread,
    setTopBarTitle
} from "./layout.actions";
import { getNotificationHref, useNotificationDescription } from "./layout.utils";
import css from "./top-navbar.scss";
import {
    isAccessBlocked,
    topBarTitle,
    getNotificationsUnread
} from "./layout.selectors";
import SearchAppBar from "./search-app-bar.component";
import SelectHoldingSubscription from "./select-holding-subscription.component";
import history from "../../history";

const TopNavbar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const notificationDescription = useNotificationDescription( t );

    const accessBlocked = useSelector( isAccessBlocked );
    const user = useSelector( getUser ) || {};
    const title = useSelector( topBarTitle );
    const notificationsUnread = useSelector( getNotificationsUnread );

    const [ isSearching, setIsSearching ] = useState( false );
    const [ userConfigs, setUserConfigs ] = useState();
    const [ notifications, setNotifications ] = useState();

    const downloadExportationFile = notification => {
        const link = document.createElement( "a" );
        link.href = getNotificationHref( notification );
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    };
    const handleLogout = () => dispatch( logoutActions.userLogout() );
    const handleToggleMenu = () => dispatch( toggleMenu() );
    const handleClickNotification = useCallback( ( notification, key ) => () => {
        if ( notification.type === "EXPORTATION_CREATED" ) {
            downloadExportationFile( notification );
        } else {
            history.push( getNotificationHref( notification ) );
        }
        dispatch( readNotification( notification ) );
        closeSnackbar( key );
    }, [ dispatch ] );

    const openDocs = () => {
        const win = window.open( "https://docs.leadstation.com.br", "_blank" );
        win.focus();
    };
    const toggleSearching = () => setIsSearching( !isSearching );
    const showUserConfigs = event => setUserConfigs( event.currentTarget );
    const closeUserConfigs = () => setUserConfigs( null );
    const showNotifications = event => setNotifications( event.currentTarget );
    const closeNotifications = () => setNotifications( null );

    const createNotification = useCallback( data => {
        enqueueSnackbar( notificationDescription( data ), {
            action: key => (
                <Fragment>
                    <IconButton
                        color="inherit"
                        onClick={ handleClickNotification( data, key ) }
                        size="large">
                        <LaunchIcon/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        onClick={ () => closeSnackbar( key ) }
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                </Fragment>
            ),
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            autoHideDuration: null,
            variant: "info"
        });
        new Audio( config.get( "client.audioNotificationsUrl" ) ).play();
    }, [ handleClickNotification, notificationDescription ] );

    useSubscription( `/users/${user.id}/notifications`, data => {
        const message = JSON.parse( data.body );
        const { notification, unread } = message;
        createNotification( notification );

        dispatch( receiveNotificationsUnread( unread ) );
    });

    useEffect( () => {
        dispatch( setTopBarTitle( t( "layout:modules." + location.pathname.split( "/" )[ 1 ] ) ) );
    }, [ t, dispatch, location ] );

    if ( isSearching ) {
        return <SearchAppBar onClose={ toggleSearching } />;
    }

    return (
        <AppBar id="appBar" position="absolute" classes={{ root: css.appBar }}>
            <Toolbar>
                <IconButton color="inherit" onClick={ handleToggleMenu } size="large">
                    <MenuIcon/>
                </IconButton>
                <Avatar className={ css.logo } src="https://img.leadstation.com.br/logo/24x24.png"/>
                <Typography variant="h6" color="inherit" className={ css.appBarTitle }>
                    { title || "LeadStation" }
                </Typography>
                <SelectHoldingSubscription user={ user } />
                {
                    !accessBlocked &&
                        <Tooltip title={ t( "layout:top-navbar.items.search" ) }>
                            <IconButton
                                id="searchButton"
                                color="inherit"
                                onClick={ toggleSearching }
                                size="large">
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                }

                <Tooltip title={ t( "layout:top-navbar.items.open-docs" ) }>
                    <IconButton color="inherit" onClick={ openDocs } size="large">
                        <HelpIcon />
                    </IconButton>
                </Tooltip>
                {
                    notificationsUnread ?
                        <Tooltip title={ t( "layout:top-navbar.items.notifications" ) }>
                            <IconButton
                                id="notificationsButton"
                                color="inherit"
                                onClick={ showNotifications }
                                size="large">
                                <Badge badgeContent={ notificationsUnread } color="error">
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>
                        </Tooltip> :
                        <Tooltip title={ t( "layout:top-navbar.items.notifications" ) }>
                            <IconButton
                                id="notificationsButton"
                                color="inherit"
                                onClick={ showNotifications }
                                size="large">
                                <NotificationsIcon/>
                            </IconButton>
                        </Tooltip>
                }
                <Popover
                    open={ !!notifications }
                    anchorEl={ notifications }
                    onClose={ closeNotifications }
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    className={ css.notifications }
                    PaperProps={{ className: css.notificationsModal }}
                >
                    <Notifications onClose={ closeNotifications } />
                </Popover>

                <IconButton
                    id="meButton"
                    color="inherit"
                    onClick={ showUserConfigs }
                    size="large">
                    <Avatar src={ user.avatar }>
                        { user.avatar ? null : get( user, "name", "", ).charAt( 0 ).toUpperCase() }
                    </Avatar>
                </IconButton>
                <Menu
                    open={ !!userConfigs }
                    onClose={ closeUserConfigs }
                    anchorEl={ userConfigs }
                >
                    <MenuItem
                        onClick={ () => {
                            closeUserConfigs();
                            history.push( "/profile" );
                        }}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon/>
                        </ListItemIcon>
                        <ListItemText primary={ t( "layout:top-navbar.items.profile" ) }/>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={ handleLogout }>
                        <ListItemIcon>
                            <ExitToAppIcon/>
                        </ListItemIcon>
                        <ListItemText primary={ t( "layout:top-navbar.items.logout" ) }/>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default TopNavbar;