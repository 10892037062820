import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link } from "../../ui";

const CustomerEvent = ({ event: { user, payload, date, type } }) => {
    const t = useTranslate();

    const removed = !isEmpty( payload.previous );
    const role = get( payload.previous, "role" ) || get( payload.current, "role" );
    return (
        <TimelineItem icon={ type === "PERSON" ? <PersonIcon/> : <BusinessIcon/> }>
            <TimelineSummary
                user={ user }
                description={ removed ?
                    <span>
                        { t( `opportunity:timeline.customer.${type}.removed` ) }
                        <Link to={ `/customers/${payload.previous.id}` }>
                            { payload.previous.name }
                        </Link>
                        { t( "opportunity:timeline.customer.at-opportunity" ) }
                    </span>
                    :
                    <span>
                        { t( `opportunity:timeline.customer.${type}.added` ) }
                        <Link to={ `/customers/${payload.current.id}` }>
                            { payload.current.name }
                        </Link>
                        { t( "opportunity:timeline.customer.at-opportunity" ) }
                    </span>
                }
                date={ date } />
            {
                role &&
                    <Typography variant="body2">
                        { `${t( "opportunity:persons.role" )}: ${role}` }
                    </Typography>
            }
        </TimelineItem>
    );
};

export default CustomerEvent;