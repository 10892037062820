import { useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex } from "./";
import css from "./template.scss";
import TemplateMentions from "./template-mentions.component";
import TemplateSelector from "./template-selector.component";
import { variables } from "./template.utils";

const TemplateEditorSms = ({
    field,
    form,
    templateType = "",
    templates,
    customer,
    opportunity,
    showVariables = true,
    ...props
}) => {
    const t = useTranslate();

    const { setFieldValue } = form;

    const handleChange = useCallback( value => {
        setFieldValue( field.name, value );
    }, [ field.name, setFieldValue ] );

    const handleChangeVariable = useCallback( ( item, value ) => {
        const newValue = value + `#(${item.value})`;
        handleChange( newValue );
    }, [ handleChange ] );

    const showTemplates = props.showTemplates && !isEmpty( templates );

    const value = useMemo( () => field.value, [ field.value ] );
    const data = useMemo(
        () => variables.map( variable => ({ id: variable.value, display: t( variable.label ) } )),
        [ t ]
    );

    return (
        <>
            {
                showTemplates &&
                    <TemplateSelector
                        customer={ customer }
                        opportunity={ opportunity }
                        templates={ templates }
                        onChange={ handleChange }
                    />
            }
            {
                showVariables &&
                    <>
                        <CardHeader
                            title={ t( "ui:template.variables.title" ) }
                            subheader={ t( "ui:template.variables.help" ) }
                        />
                        <div className={ flex.container }>
                            {
                                variables.map( item => (
                                    <Button
                                        key={ item.value }
                                        variant="button"
                                        onClick={ () => handleChangeVariable( item, value ) }
                                        className={ cssUtils.cursorPointer }
                                    >
                                        { t( item.label ) }
                                    </Button>
                                ) )
                            }
                        </div>
                    </>
            }
            <TemplateMentions
                className={ cssUtils.marginTopSmall }
                data={ data }
                field={ field }
                form={ form }
                label={ t( "ui:template.body" ) }
                required
                { ...props }
                maxLength={ templateType !== "SMS" && 4096 }
                variant="outlined"
            />
            {
                templateType === "SMS" &&
                    <Typography className={ css.smsSize } color="textSecondary" variant="body2">
                        {
                            t( "ui:template.sms-size",
                                {
                                    characters: value.length,
                                    quantity: Math.ceil( value.length / 160 )
                                }
                            )
                        }
                    </Typography>
            }
        </>
    );
};

export default TemplateEditorSms;