export default {
    "title": "Agenda",
    "users": "Usuarios",
    "new-event": {
        "title": "Nueva programación"
    },
    "form-event": {
        "start-date": "Fecha de inicio",
        "start-time": "Hora de inicio",
        "end-date": "Fecha de finalización",
        "end-time": "Hora de finalización",
        "all-day": "Día completo",
        "title": "Título",
        "recurrent": {
            "label": "Recurrente",
            "type": {
                "label": "Reaparición",
                "DAILY": "Diariamente",
                "WEEKLY": "Semanalmente",
                "MONTHLY": "Mensual",
                "YEARLY": "Anual"
            },
            "until": "Hasta hasta",
            "update": {
                "single": "Este horario",
                "future": "Este horario y el siguiente",
                "all": "Todas las citas"
            }
        },
        "notify": "Notificar",
        "minutes": "Minutos",
        "description": "Descripción",
        "color": "Color",
        "customer": "Cliente",
        "social": {
            "attendees": "Invitadoss",
            "add-attendee": "Agregar invitado",
            "google-data": "Datos de Google Calendar",
        },
        "status": {
            "ACCOMPLISHED": "Realizado",
            "NOT_ACCOMPLISHED": "No realizado",
            "OPEN": "Abierto"
        },
        "confirm-accomplished": "Marcar la programación como realizada?",
        "confirm-not-accomplished": "Marcar la programación como no realizada?",
        "reopen-scheduling": "Marcar la programación como abierta?",
        "not-accomplished-reason": "Motivo"
    },
    "event": {
        "delete": {
            "confirm": "Está seguro de que desea eliminar este evento?"
        },
        "edit": "Editar",
        "user": "Usuario: ",
        "salesman": "Vendedor: ",
        "social": {
            "linked": "Programación vinculada a Google Calendar",
            "open": "Abrir en Google Calendar",
            "google-meet": {
                "enter": "Iniciar sesión en Google Meet",
                "copy": "Copiar vínculo de conferencia"
            }
        },
        "not-accomplished-reason": "Motivo del no realizada"
    },
    "filters": {
        "social": {
            "label": "Calendario de Google",
            "help": "Horarios creados directamente en Google Calendar"
        },
        "opportunity-scheduling-types": "Con oportunidad",
        "with-user-schedules": "Sin oportunidad"
    }
};