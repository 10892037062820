import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";

import useTranslate from "../hooks/use-translate.hook";
import { Link } from "../ui";
import { isLoadingSelected, getPersons, isLoadingPersons } from "./opportunity.selectors";
import { fetchPersons } from "./opportunity.actions";
import CustomerSummary from "../customer/customer-summary.component";
import { AddPerson, DeletePerson } from "./persons";
import css from "./opportunity.scss";

const OpportunityPersons = ({ opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loadingSelected = useSelector( isLoadingSelected );
    const loadingPersons = useSelector( isLoadingPersons );
    const opportunityPersons = useSelector( getPersons );

    const [ selected, setSelected ] = useState( "" );

    const openDetails = personId => () => setSelected( personId );
    const closeDetails = () => setSelected( "" );

    const loading = loadingSelected || loadingPersons;

    useEffect( () => {
        dispatch( fetchPersons( opportunityId ) );
    }, [ dispatch, opportunityId ] );

    if ( loading ) {
        return <LinearProgress/>;
    }

    return (
        <Card id="opportunityContacts">
            <CardHeader
                action={ <AddPerson/> }
                className={ css.personsTitle }
                title={ t( "opportunity:persons.header" ) }
                titleTypographyProps={{ variant: "body2" }}
            />
            <List disablePadding>
                {
                    ( opportunityPersons || [] ).map( ( opportunityPerson, index ) => (
                        <Fragment key={ index }>
                            <ListItem
                                disablePadding
                                secondaryAction={ <DeletePerson opportunityPerson={ opportunityPerson }/> }
                            >
                                <ListItemButton
                                    selected={ selected === opportunityPerson.person.id }
                                    onClick={
                                        selected === opportunityPerson.person.id ?
                                            closeDetails :
                                            openDetails( opportunityPerson.person.id )
                                    }
                                >
                                    <ListItemText
                                        primary={ opportunityPerson.person.name }
                                        secondary={ opportunityPerson.role }
                                    />
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={ selected === opportunityPerson.person.id } unmountOnExit>
                                <List component="div" dense disablePadding>
                                    <CustomerSummary
                                        alignPopupLeft
                                        customer={ opportunityPerson.person }
                                    />
                                    <Link href={ `/customers/${opportunityPerson.person.id}` }>
                                        <Button
                                            className={ css.personAccessButton }
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            { t( "common:access" ) }
                                        </Button>
                                    </Link>
                                </List>
                            </Collapse>
                        </Fragment>
                    ))
                }
            </List>
        </Card>
    );
};

export default OpportunityPersons;