import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting, getSubmitError } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import MailIcon from "@mui/icons-material/Mail";
import SmsIcon from "@mui/icons-material/Sms";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import group from "lodash/groupBy";
import order from "lodash/orderBy";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { EmptyState } from "../../../ui";
import WhatsAppIcon from "../../../ui/icons/whatsapp.component";
import { actions as templateActions, selectors as templateSelectors } from "../../../template";
import Templates from "./templates.component";
import { DELETE_TEMPLATE_FORM } from "./template.constants";
import { deleteTemplate, initFileModels } from "./template.actions";

const TemplateList = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loading = useSelector( templateSelectors.isLoadingTemplates );
    const allTemplates = useSelector( templateSelectors.getSystemTemplates );
    const deleting = useSelector( isSubmitting( DELETE_TEMPLATE_FORM ) );
    const deleteError = useSelector( getSubmitError( DELETE_TEMPLATE_FORM ) );

    const [ selectedTemplate, setSelectedTemplate ] = useState( null );
    const [ showDialogTemplate, setShowDialogTemplate ] = useState( false );
    const [ confirmDelete, setConfirmDelete ] = useState();
    const [ showDeleteError, setShowDeleteError ] = useState( false );
    const [ showBodyTester, setShowBodyTester ] = useState( false );

    const templates = useMemo( () => group( allTemplates, "type" ), [ allTemplates ] );

    const toggleDialogTemplate = () => setShowDialogTemplate( !showDialogTemplate );

    const selectTemplate = template => {
        setSelectedTemplate( template );
        if ( template ) {
            toggleDialogTemplate();
        }
    };

    const toDelete = template => setConfirmDelete( template );

    const handleDelete = () => dispatch( deleteTemplate( confirmDelete.id ) );

    const toggleDeleteError = () => setShowDeleteError( !showDeleteError );

    const toggleTestSubmission = () => setShowBodyTester( !showBodyTester );

    const renderTemplateIcon = template => {
        switch ( template.type ) {
            case "EMAIL":
                return <MailIcon/>;
            case "SMS":
                return <SmsIcon/>;
            case "WHATSAPP":
                return <WhatsAppIcon/>;
            default:
                return "";
        }
    };

    const renderTemplates = templates => {
        return ( order( templates, [ "name" ], [ "asc" ] ) ).map( template => (
            <ListItem
                key={ template.id }
                disablePadding
                secondaryAction={
                    <Tooltip title={ t( "common:exclude" ) }>
                        <IconButton onClick={ () => toDelete( template ) } size="large">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                }
            >
                <ListItemButton onClick={ () => selectTemplate( template ) }>
                    <ListItemIcon>
                        { renderTemplateIcon( template ) }
                    </ListItemIcon>
                    <ListItemText
                        primary={ template.name }
                        secondary={
                            t(
                                "config:templates.created-by",
                                {
                                    user: template.createdBy.name,
                                    date: dateFormat( template.createdAt ),
                                }
                            ) +
                            ( template.createdAt !== template.updatedAt ?
                                t(
                                    "config:templates.updated-by",
                                    {
                                        user: template.updatedBy.name,
                                        date: dateFormat( template.updatedAt ),
                                    }
                                ) : ""
                            )
                        }
                    />
                </ListItemButton>
            </ListItem>
        ));
    };

    const prevTemplates = usePrevious( templates );
    const prevDeleting = usePrevious( deleting );

    useEffect( () => {
        dispatch( initFileModels() );
        dispatch( templateActions.fetchTemplates() );
        dispatch( templateActions.fetchImages() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( !isEqual( templates, prevTemplates ) ) {
            setShowDialogTemplate( false );
        }
    }, [ templates, prevTemplates ] );
    useEffect( () => {
        if ( !deleting && prevDeleting ) {
            setConfirmDelete();
            if ( !isEmpty( deleteError ) ) {
                setShowDeleteError( true );
            }
        }
    }, [ deleting, prevDeleting, deleteError ] );

    return (
        <>
            <Card>
                <CardHeader
                    title={ t( "config:items.template.label" ) }
                    subheader={ t( "config:items.template.description" ) }
                    action={
                        <Button
                            color="primary"
                            onClick={ () => {
                                toggleDialogTemplate();
                                selectTemplate();
                            }}
                        >
                            { t( "config:templates.add" ) }
                        </Button>
                    }
                />
                { loading && <LinearProgress /> }
                {
                    !loading && isEmpty( templates ) ?
                        <CardContent>
                            <EmptyState
                                icon={ LayersClearIcon }
                                message={ t( "config:templates.no-templates" ) }
                            />
                        </CardContent> :
                        <List>
                            <ListSubheader> { t( "config:templates.type.EMAIL" ) } </ListSubheader>
                            {
                                templates.EMAIL ?
                                    renderTemplates( templates.EMAIL ) :
                                    <ListItem>
                                        <ListItemText
                                            secondary={ t( "config:templates.type.empty" ) }
                                        />
                                    </ListItem>
                            }
                            <ListSubheader> { t( "config:templates.type.SMS" ) } </ListSubheader>
                            {
                                templates.SMS ?
                                    renderTemplates( templates.SMS ) :
                                    <ListItem>
                                        <ListItemText
                                            secondary={ t( "config:templates.type.empty" ) }
                                        />
                                    </ListItem>
                            }
                            <ListSubheader> { t( "config:templates.type.WHATSAPP" ) } </ListSubheader>
                            {
                                templates.WHATSAPP ?
                                    renderTemplates( templates.WHATSAPP ) :
                                    <ListItem>
                                        <ListItemText
                                            secondary={ t( "config:templates.type.empty" ) }
                                        />
                                    </ListItem>
                            }
                        </List>
                }
            </Card>

            {
                showDialogTemplate &&
                    <Templates
                        template={ selectedTemplate }
                        onClose={ toggleDialogTemplate }
                        onClickTest={ toggleTestSubmission }
                        showBodyTester={ showBodyTester }
                    />
            }
            <Dialog open={ !!confirmDelete }>
                <DialogContent>
                    <DialogContentText>
                        { t( "config:templates.delete.confirm" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ () => toDelete() } disabled={ deleting }>
                        { t( "config:templates.delete.no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete } disabled={ deleting }>
                        { t( "config:templates.delete.yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={ showDeleteError }
                action={
                    <Button color="secondary" onClick={ toggleDeleteError }>
                        { "OK" }
                    </Button>
                }
                message={ deleteError?.error }
                onClose={ toggleDeleteError }
            />

        </>
    );
};

export default TemplateList;