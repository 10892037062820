import { useCallback, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils } from "./";
import css from "./dropdown.scss";

const Dropdown = ({
    innerRef,
    id,
    field = {},
    form: { getFieldMeta, setFieldValue } = {},
    onChange,
    label,
    helperText,
    source = [],
    required,
    className,
    error: propError,
    fullWidth = true,
    clearParentValue,
    emptyValue = false,
    margin,
    size,
    sort = true,
    displayEmpty,
    disabled,
    loading = false,
    variant,
    ...props
}) => {
    const t = useTranslate();

    const [ isOpen, setIsOpen ] = useState( false );
    const toggleOpen = () => setIsOpen( !isOpen );

    const handleClearValue = useCallback( () => {
        if ( setFieldValue ) {
            const fieldName = clearParentValue ? field.name.split(".")[ 0 ] : field.name;
            setFieldValue( fieldName, null );
        } else if ( onChange ) {
            onChange({ target: { value: null } });
        }
    }, [ clearParentValue, field.name, setFieldValue, onChange ] );

    const value = useMemo( () => {
        return field.value || props.value || "";
    }, [ field.value, props.value ] );

    const hasValue = useMemo( () => !!value, [ value ] );

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, getFieldMeta ] );

    const endAdornment = useMemo( () => {
        if ( loading ) {
            return (
                <InputAdornment position="end" className={ cssUtils.marginRight }>
                    <CircularProgress size={ 22 } className={ css.loading } />
                </InputAdornment>
            );
        } else if ( !disabled && emptyValue && hasValue ) {
            return (
                <InputAdornment position="end" className={ cssUtils.marginRight }>
                    <IconButton onClick={ handleClearValue } size="small">
                        <CancelIcon/>
                    </IconButton>
                </InputAdornment>
            );
        }

        return null;
    }, [ disabled, emptyValue, handleClearValue, hasValue, loading ] );

    const renderValue = selected => {
        if ( !selected || isEmpty( source ) ) {
            return "";
        }

        const option = source.find( o => isEqual( o.value, selected ) );
        return props.renderValue ? props.renderValue( option ) : option ? option.label : "";
    };

    return (
        <FormControl
            ref={ innerRef }
            id={ id }
            margin={ margin }
            error={ propError || !!validationMsg }
            required={ required }
            className={ className }
            fullWidth={ fullWidth }
            size={ size }
            variant={ variant }
        >
            { label && <InputLabel>{ label }</InputLabel> }
            <Select
                onChange={ onChange }
                { ...props }
                { ...field }
                disabled={ disabled }
                label={ label }
                value={ value }
                onOpen={ toggleOpen }
                onClose={ toggleOpen }
                renderValue={ renderValue }
                displayEmpty={ displayEmpty }
                endAdornment={ endAdornment }
            >
                {
                    ( sort ? sortBy( source, [ "label" ] ) : source ).map( ( item, index ) => (
                        <MenuItem key={ index } value={ item.value }>
                            { item.avatar && <ListItemAvatar>{ item.avatar }</ListItemAvatar>}
                            { item.icon && <ListItemIcon>{ item.icon }</ListItemIcon> }
                            <ListItemText
                                className={ classnames( cssUtils.marginBottom0, cssUtils.marginTop0 ) }
                                primary={ item.label }
                                secondary={ open && item.helper }
                            />
                        </MenuItem>
                    ))
                }
            </Select>
            { helperText && <FormHelperText>{ helperText }</FormHelperText> }
            { validationMsg && <FormHelperText>{ t( validationMsg ) }</FormHelperText> }
        </FormControl>
    );
};

export default Dropdown;