import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, select, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as opportunityActions, selectors as opportunitySelectors } from "../../../opportunity";
import * as actions from "./schedules-type.actions";
import { SCHEDULING_TYPES_FORM } from "./schedules-type.constants";

export function *watchConfigOpportunitySchedulesType () {
    yield all([
        takeEvery( actions.SAVE_SCHEDULING_TYPES, saveSchedulingTypes ),
        takeEvery( actions.DELETE_SCHEDULING_TYPE, deleteSchedulingType )
    ]);
}

export function *saveSchedulingTypes ( action ) {
    const { schedulingTypes } = action.payload;
    const patchedSchedulingTypes = [];

    try {
        yield put( startSubmit( SCHEDULING_TYPES_FORM ) );

        for ( const schedulingType of schedulingTypes ) {
            let response;
            if ( schedulingType.id ) {
                response = yield call(
                    apiv1.patch, "/opportunities/schedules/types/" + schedulingType.id,
                    schedulingType
                );
            } else {
                response = yield call( apiv1.post, "/opportunities/schedules/types", schedulingType );
            }

            patchedSchedulingTypes.push( response.data );
        }

        yield put( stopSubmit( SCHEDULING_TYPES_FORM ) );
        yield put( opportunityActions.receiveSchedulingTypes( patchedSchedulingTypes ) );
    } catch ( e ) {
        yield put( stopSubmit( SCHEDULING_TYPES_FORM, e.response.data ) );
    }
}

export function *deleteSchedulingType ({ payload }) {
    yield put( startSubmit( SCHEDULING_TYPES_FORM ) );
    const { schedulingType } = payload;

    const schedulingTypes = yield select( opportunitySelectors.getSchedulingTypes );

    try {
        yield call( apiv1.delete, "/opportunities/schedules/types/" + schedulingType.id );

        const filteredSchedulingTypes = schedulingTypes.filter( item => item.id !== schedulingType.id );
        yield put( opportunityActions.receiveSchedulingTypes( filteredSchedulingTypes ) );
        yield put( stopSubmit( SCHEDULING_TYPES_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SCHEDULING_TYPES_FORM, e.response.data ) );
    }
}