import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import SearchIcon from "@mui/icons-material/Search";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { MaskInput } from "../ui";
import { createEinValidation } from "../core/validator.utils";
import { actions as customerActions, selectors as customerSelector } from "../customer";
import { selectors as formSelectors } from "../form";

const EinField = ({ field, form, ...props }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const isEinValid = useCallback( value => createEinValidation({ t })( value ), [ t ] );

    const companyFields = useSelector( formSelectors.list( "COMPANY" ) );
    const loading = useSelector( customerSelector.isLoadingCompanyData );
    const companyData = useSelector( customerSelector.getCompanyData );
    const error = useSelector( customerSelector.getCompanyDataError );

    const [ showError, setShowError ] = useState( false );

    const setFieldValue = useMemo( () => form.setFieldValue, [ form.setFieldValue ] );

    const closeError = () => setShowError( false );

    const getNewValues = useCallback( companyData => {
        const newValues = companyFields
            .map( field => {
                if ( field.systemField === "NAME" && companyData.name ) {
                    return { id: field.id, value: companyData.name };
                } else if ( field.type === "EMAIL" ) {
                    return { id: field.id, value: companyData.emails };
                } else if ( field.type === "PHONE" ) {
                    return { id: field.id, value: companyData.phones };
                } else if ( field.type === "ADDRESS" && companyData.address ) {
                    return {
                        id: field.id,
                        value: [{ type: "COMMERCIAL", name: companyData.address }]
                    };
                }
                return { id: field.id, value: null };
            })
            .reduce( ( model, field ) => (
                field.value ?
                    ({ ...model, [ field.id ]: field.value }) :
                    model
            ), {} );

        return newValues;
    }, [ companyFields ] );

    const fetchCompanyData = () => {
        if ( field.value && isEinValid( field.value ) ) {
            dispatch( customerActions.fetchCompanyData( field.value.replace( /\D/g, "" ) ) );
        } else {
            setShowError( true );
        }
    };

    const prevCompanyData = usePrevious( companyData );

    useEffect( () => {
        if ( !isEmpty( companyData ) && !isEqual( companyData, prevCompanyData ) ) {
            const newValues = getNewValues( companyData );

            if ( !isEmpty( form.values.company ) ) {
                setFieldValue( "company", {
                    ...form.values.company,
                    fields: {
                        ...form.values.company.fields,
                        ...newValues,
                    }
                } );
            } else {
                setFieldValue( "fields", {
                    ...form.values.fields,
                    ...newValues
                } );
            }
        }
    }, [ getNewValues, form.values, companyData, prevCompanyData, setFieldValue ] );
    useEffect( () => {
        error && setShowError( true );
    }, [ error ] );

    useEffect( () => {
        return () => {
            dispatch( customerActions.receiveCompanyData() );
        };
    }, [ dispatch ] );

    return (
        <>
            <MaskInput
                { ...omit( props, [ "formField" ] ) }
                field={ field }
                form={ form }
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title={ t( "form:company-data.fetch" ) }>
                                <IconButton
                                    onClick={ fetchCompanyData }
                                    onMouseDown={ () => {} }
                                    disabled={ loading }
                                    size="large">
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                }}
                format="##.###.###/####-##"
            />
            <Snackbar
                open={ showError }
                message= { t( "form:company-data.unavailable" ) }
                action={ <Button onClick={ closeError } color="secondary">{ "OK" }</Button> }
                autoHideDuration={ 5000 }
                onClose={ closeError }
            />
        </>
    );
};

export default EinField;