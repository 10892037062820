import { useState, useCallback, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Highcharts from "highcharts";

import useTranslate from "../hooks/use-translate.hook";
import tracker from "../util/tracker.utils";
import { getSubscription } from "../subscription/subscription.selectors";
import { getUser } from "../core/core.selectors";
import { Fab } from "../ui";
import { getResults } from "./report.selectors";

const ReportsPrint = ({ report }) => {
    const t = useTranslate();
    const subscription = useSelector( getSubscription );
    const user = useSelector( getUser );
    const results = useSelector( getResults );
    const hasResults = !isEmpty( results );

    const [ isPrinting, setIsPrinting ] = useState( false );
    const printRef = useRef();

    const formattedReportNameToTrack = useMemo( () => {
        const words = report.split( "_" ).map( word => word.toLowerCase() );
        const formattedWords = words.map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) );
        return formattedWords.join(" ");
    }, [ report ] );

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: A4;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        `,
        documentTitle: t( `report:reports.${report}` ),
        onBeforePrint: () => setIsPrinting( true ),
        onAfterPrint: () => {
            setIsPrinting( false );
            tracker.pageChange( `Report ${formattedReportNameToTrack} Printed` );
        }
    });

    const reportName = useMemo( () => {
        const name = report.toLowerCase().split( "_" );
        const camelCase = name.map( ( word, index ) => {
            return index !== 0 ? word.charAt( 0 ).toUpperCase() + word.slice( 1 ) : word;
        }).join( "" );

        return camelCase;
    }, [ report ] );

    const waitForHighchartsLoad = useCallback( async () => {
        return new Promise( resolve => {
            if ( Highcharts.Chart ) {
                resolve();
            } else {
                Highcharts.addEvent( Highcharts.Chart, "load", resolve );
            }
        } );
    }, [] );

    const handleGeneratePdf = useCallback( async () => {
        // Container
        const printContent = document.createElement( "div" );
        printContent.style.display = "flex";
        printContent.style.flexDirection = "column";
        printContent.style.width = "100%";

        // HEADER
        const headerElement = document.createElement( "header" );
        headerElement.style.display = "flex";
        headerElement.style.justifyContent = "center";
        headerElement.style.flexDirection = "column";
        headerElement.style.alignItems = "center";
        headerElement.style.width = "100%";

        const containerElement = document.createElement( "div" );
        containerElement.style.display = "flex";
        containerElement.style.justifyContent = "flex-end";
        containerElement.style.width = "100%";

        const logo = document.createElement("img");
        logo.src = "https://img.leadstation.com.br/logo/248x158-horizontal.png";
        logo.style.width = "117px";
        logo.style.height = "35px";
        logo.style.objectFit = "cover";
        logo.alt = "";

        const titleElement = document.createElement( "h3" );
        titleElement.style.marginTop = "0";
        titleElement.style.paddingTop = "0";
        titleElement.innerText = t( `report:reports.${report}` );

        containerElement.appendChild( logo );
        headerElement.appendChild( containerElement );
        headerElement.appendChild( titleElement );

        printContent.appendChild( headerElement );

        // REPORTS
        const containerReport = document.createElement( "div" );
        containerReport.style.display = "flex";
        containerReport.style.flexDirection = "column";
        containerReport.style.gap = "1rem";

        // TABLES
        const tables = document.querySelectorAll(`[id^="${reportName}"]`);
        if ( tables ) {
            Array.from( tables )
                .filter( table => !table.id.includes( "Chart" ) && table.id.includes( "Table" ) )
                .map( table => {
                    const tableCopy = table.cloneNode( true );
                    containerReport.appendChild( tableCopy );
                } );
        }

        // CHARTS
        await waitForHighchartsLoad();
        const charts = Highcharts.charts.filter( chart => get( chart, "renderTo.id" )?.startsWith( reportName ) );
        charts.forEach( chart => {
            const svgElement = chart.getSVG();
            const tempDiv = document.createElement( "div" );
            tempDiv.style.width = "100%";
            tempDiv.innerHTML = svgElement;
            const svg = tempDiv.querySelector( "svg" );
            svg.setAttribute( "width", "100%" );
            svg.setAttribute( "height", "100%" );

            containerReport.appendChild( svg );
        });

        printContent.appendChild( containerReport );

        // FOOTER
        const footerElement = document.createElement( "footer" );
        footerElement.style.position = "fixed";
        footerElement.style.bottom = "0";
        footerElement.style.width = "100%";

        const footerTextElement = document.createElement( "p" );
        footerTextElement.style.margin = "0";
        footerTextElement.style.padding = "0";
        footerTextElement.style.fontSize = "0.75rem";
        footerTextElement.innerText = t( "report:print-chart.emission", {
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            user: user.name,
            subscription: subscription.name,
        } );
        footerElement.appendChild( footerTextElement );
        printContent.appendChild( footerElement );

        printRef.current = printContent;
        handlePrint();
    }, [ t, subscription.name, user.name, handlePrint, reportName, waitForHighchartsLoad, report ] );

    return (
        <Fab disabled={ !hasResults || isPrinting } position="bottomRight" onClick={ handleGeneratePdf }>
            <PrintIcon/>
        </Fab>
    );
};

export default ReportsPrint;