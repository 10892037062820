import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import history from "../../history";
import { GoogleAuthButton } from "../ui/social";
import { fetchSocialCalendar } from "./user.actions";

const GoogleCalendarAuth = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const open = () => {
        const popup = window.open(
            history.createHref({
                ...location,
                pathname: `/googlecalendar/login`
            })
        );
        const popupTick = setInterval( function () {
            if ( popup.closed ) {
                clearInterval( popupTick );
                dispatch( fetchSocialCalendar() );
            }
        }, 500 );
    };

    return <GoogleAuthButton onClick={ open }/>;
};

export default GoogleCalendarAuth;