import Typography from "@mui/material/Typography";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import OpacityIcon from "@mui/icons-material/Opacity";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "../../ui/timeline/timeline.scss";
import { cssUtils } from "../../ui";

const icons = {
    COLD: AcUnitIcon,
    WARM: OpacityIcon,
    HOT: WhatshotIcon
};

const TemperatureEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();
    const Icon = payload.current ? icons[ payload.current ] : BrightnessMediumIcon;
    return (
        <TimelineItem icon={ <Icon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.opportunity-temperature" ) }
                date={ date } />

            <div className={ css.change } >
                <Typography>
                    {
                        payload.previous ?
                            <mark className={ cssUtils.highlightOld }>
                                { t( "opportunity:temperatures." + payload.previous ) }
                            </mark>
                            : ""
                    }
                    {
                        payload.previous && payload.current ?
                            "→ "
                            : ""
                    }
                    {
                        payload.current ?
                            <mark className={ cssUtils.highlightNew }>
                                { t( "opportunity:temperatures." + payload.current ) }
                            </mark>
                            : ""
                    }
                </Typography>
            </div>
        </TimelineItem>
    );
};

export default TemperatureEvent;