import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { Table } from "../../../ui/table";
import { selectors as teamSelectors } from "../../../team";
import { selectors as userSelectors } from "../../../user";
import { fetchDistributions } from "./distribution.actions";
import { listAll, isLoadingAll } from "./distribution.selectors";
import DistributionOptions from "./distribution-options.component";

const Distributions = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const distributions = useSelector( listAll );
    const loading = useSelector( isLoadingAll );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAll );

    const tableModel = {
        name: t( "common:name" ),
        team: t( "ui:users-filter.team" ),
        users: t( "ui:users-filter.users" ),
        options: {
            title: " ",
            size: "small"
        },
    };

    const tableSource = useMemo(
        () => ( distributions || [] ).map( distribution => ({
            name: distribution.name,
            team: distribution.teamId ? teams?.find( team => team.id === distribution.teamId ).name : "",
            users: !isEmpty( distribution.usersId ) ?
                distribution.usersId.map( userId => users?.find( user => user.id === userId ).name ).join( ", " ) :
                [],
            options: <DistributionOptions distribution={ distribution } />
        })),
        [ distributions, teams, users ]
    );

    useEffect( () => {
        dispatch( fetchDistributions() );
    }, [ dispatch ] );

    return (
        <Table
            model={ tableModel }
            source={ tableSource }
            emptyMessage={ t( "config:distribution.empty-distributions" ) }
            isLoading={ loading }
        />
    );

};

export default Distributions;