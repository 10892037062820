import { memo } from "react";

import css from "./timeline.scss";

const Timeline = ({ children }) => (
    <ul className={ css.list }>
        { children }
    </ul>
);

export default memo( Timeline );