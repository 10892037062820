export const CREATE_COMMISSION = "x/CREATE_COMMISSION";
export const createCommission = data => ({
    type: CREATE_COMMISSION,
    payload: data
});

export const DELETE_COMMISSION = "x/DELETE_COMMISSION";
export const deleteCommission = ({ id }) => ({
    type: DELETE_COMMISSION,
    meta: { id }
});

export const EDIT_COMMISSION = "x/EDIT_COMMISSION";
export const editCommission = ({ id, ...commission }) => ({
    type: EDIT_COMMISSION,
    meta: { id },
    payload: commission
});