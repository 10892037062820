import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import libphonenumber from "libphonenumber-js";
import isEmpty from "lodash/isEmpty";
import { ssnFormatter, einFormatter } from "locale-formatter";

const CRYPT_PRIVATE_KEY = "ProjetoX";

dayjs.extend( relativeTime );

export const createDateTimeFormatter = ({ t }) => value => {
    if ( !value ) {
        return "";
    }
    return dayjs( value).format( t( "common:full-date" ) );
};

export const createDateFormatter = ({ t }) => value => {
    if ( !value ) {
        return "";
    }

    return dayjs( value).format( t( "common:date" ) );
};

export const createTimeFormatter = ({ t }) => value => {
    if ( !value ) {
        return "";
    }

    return dayjs( value).format( t( "common:format-hour" ) );
};

export const createDayMonthFormatter = ({ t }) => value => {
    if ( !value ) {
        return "";
    }

    return dayjs( value).format( t( "common:day-month" ) );
};

export const createDateDistanceInWords = date => {
    if ( !date ) {
        return date;
    }

    return dayjs( date ).fromNow( true );
};

export const timeDuration = minutes => {
    if ( !minutes || minutes < 1 ) {
        return "0m";
    }

    const hours = minutes / 60;
    if ( hours < 1 ) {
        return Math.round( minutes ) + "m";
    }

    let rhours = Math.floor( hours );
    const rminutes = Math.round( ( hours - rhours ) * 60 );

    const days = rhours / 24;

    if ( days < 1 ) {
        return rhours + "h " + rminutes + "m";
    }

    const rdays = Math.floor( days );
    rhours = Math.round( ( days - rdays ) * 24 );

    let duration = rdays + "d ";
    if ( rhours > 0 ) {
        duration += rhours + "h ";
    }
    if ( rminutes > 0 ) {
        duration += rminutes + "m";
    }

    return duration;
};

export const phoneFormat = phone => {
    if ( !phone || !phone.number ) {
        return "";
    }
    if ( !phone.country ) {
        return phone.number;
    }

    try {
        const phoneNumber = libphonenumber( phone.number, phone.country );

        return phoneNumber.isValid() ?
            phoneNumber.formatInternational() :
            phone.number;
    } catch ( e ) {
        return phone.number;
    }
};

export const phoneFormatInternational = phone => {
    try {
        const phoneNumber = libphonenumber( "+" + phone );

        return phoneNumber.isValid() ?
            phoneNumber.formatInternational() :
            phone;
    } catch ( e ) {
        return phone;
    }
};

export function phoneFormatNational ( number, country ) {
    if ( !number ) {
        return "";
    }
    try {
        const phoneNumber = libphonenumber( number, country );

        return phoneNumber.isValid() ?
            phoneNumber.formatNational() :
            number;
    } catch ( e ) {
        return number;
    }

};

export const createFieldValueFormatter = ({ t, subscription }) => ( field, value ) => {
    if ( value === null || value === undefined ) {
        return value;
    }

    switch ( field.systemField ) {
        case "EIN":
            return createEinFormatter({ t })( value );
        case "SSN":
            return createSsnFormatter({ t })( value );
        case "BRAND":
        case "MODEL":
            return value;
        default:
            break;
    }

    switch ( field.type ) {
        case "ADDRESS":
            if ( Array.isArray( value ) ) {
                return value.map( address => address.name ).join( " | " );
            }
            return value.name;
        case "DATE":
            return createDateFormatter({ t })( value );
        case "SELECT":
            const itemValue = field.options.values.find( item => item.value ? item.value === value : item === value );
            return itemValue ? ( itemValue.label ? itemValue.label : itemValue ) : "" ;
        case "EMAIL":
            if ( Array.isArray( value ) ) {
                return value.map( email => email ).join( ", " );
            }
            return value;
        case "PHONE":
            if ( Array.isArray( value ) ) {
                return value.map( phone => phoneFormat( phone ) ).join( ", " );
            }
            return phoneFormat( value );
        case "PRICE":
            return createPriceFormatter({ subscription })( value );
        case "SELECT_MULTIPLE":
            if ( Array.isArray( value ) ) {
                return value.map( item => item ).join( ", " );
            }
            return value;
        default:
            return value;
    }
};

export const createFieldFilterValueFormatter = ({ t }) => ( field, operator, value ) => {
    const dateFormat = createDateFormatter({ t });
    const getValue = value => {
        if ( field.systemField === "MODEL" ) {
            return value.model;
        }
        switch ( field.type ) {
            case "DATE":
                return dateFormat( value );
            case "SELECT":
                if ( isEmpty( field.options.values ) || !field.options.values[ 0 ].value ) {
                    return value;
                }

                const item = field.options.values.find( item => item.value.toString() === value );
                return item ? item.label : value;
            default:
                return value;
        }
    };

    switch ( operator ) {
        case "BETWEEN":
            return field.name + ": " + getValue( value.initial ) + " - " + getValue( value.final );
        case "LIKE":
        case "NOT_LIKE":
            return field.name + ": %" + getValue( value ) + "%";
        case "EQUAL":
        case "NOT_EQUAL":
            return field.name + ": " + getValue( value );
        default:
            return field.name + ": " + t( "form:user-filters.operator." + operator );
    }
};

export const createPriceFormatter = ({ subscription }) => price => {
    try {
        return new Intl
            .NumberFormat(
                navigator.language || navigator.userLanguage,
                { style: "currency", currency: subscription.currency }
            )
            .format( price || 0 )
            .replace( /\u00a0/g, "" )
            .replace( /^(\D+)/, "$1 " );
    } catch ( e ) {
        return "";
    }
};

export const formatBytes = bytes => {
    if ( bytes === 0 ) {
        return "0 Bytes";
    }

    const k = 1024;
    const decimals = 2;
    const sizes = [ "Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB" ];
    const i = Math.floor( Math.log( bytes ) / Math.log( k ) );
    return parseFloat(( bytes / Math.pow( k, i ) ).toFixed( decimals ) ) + " " + sizes[ i ];
};

export const createSsnFormatter = ({ t }) => ssn => ssnFormatter( t( "common:full-locale" ) )( ssn );

export const createEinFormatter = ({ t }) => ein => einFormatter( t( "common:full-locale" ) )( ein );

export const encryptText = text => {
    if ( !text ) {
        return text;
    }

    const key = CRYPT_PRIVATE_KEY.substring( 0, 8 );
    const iv = CRYPT_PRIVATE_KEY.substring( 8, 8 );

    const keyHex = CryptoJS.enc.Utf8.parse( key );
    const ivHex = CryptoJS.enc.Utf8.parse( iv );

    const encrypted = CryptoJS.DES.encrypt( text, keyHex, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: ivHex,
    });

    return encrypted.toString();
};

export const decryptText = text => {
    if ( !text ) {
        return text;
    }

    const key = CRYPT_PRIVATE_KEY.substring( 0, 8 );
    const iv = CRYPT_PRIVATE_KEY.substring( 8, 8 );

    const keyHex = CryptoJS.enc.Utf8.parse( key );
    const ivHex = CryptoJS.enc.Utf8.parse( iv );

    const decrypted = CryptoJS.DES.decrypt( text, keyHex, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: ivHex,
    });

    return decrypted.toString( CryptoJS.enc.Utf8 );
};