import { useEffect, useMemo } from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { DatePicker, Dropdown, DropdownMultiple, Input, cssUtils, flex } from "../ui";
import { BrandField, ModelField } from "../form";
import { selectors } from "./";
import { getFunnelId } from "../opportunity/opportunity.selectors";

const operators = [
    "EQUAL",
    "NOT_EQUAL",
    "LIKE",
    "NOT_LIKE",
    "NULL",
    "NOT_NULL",
    "BETWEEN"
];

const FilterFieldItem = ({ name, formType, filter, onDelete, setFieldValue }) => {
    const t = useTranslate();

    const fields = useSelector( selectors.listFilters( formType ) );
    const funnelId = useSelector( getFunnelId );

    const operator = useMemo( () => filter.operator, [ filter.operator ] );
    const fieldId = useMemo( () => filter.fieldId, [ filter.fieldId ] );

    const availableFields = useMemo( () => {
        if ( !funnelId || formType !== "opportunity" ) {
            return fields;
        }

        return fields.filter( field =>
            isEmpty( field?.funnelsId ) ||
            field?.funnelsId?.includes( funnelId )
        );
    }, [ formType, fields, funnelId ] );
    const field = useMemo( () => ( fields.find( field => field.id === fieldId ) || {} ), [ fields, fieldId ] );

    const prevOperator = usePrevious( operator );
    const prevFieldId = usePrevious( fieldId );

    useEffect( () => {
        if ( prevFieldId && fieldId !== prevFieldId ) {
            setFieldValue( `${name}.operator`, null );
        }
    }, [ name, setFieldValue, fieldId, prevFieldId ] );
    useEffect( () => {
        if ( prevOperator && operator !== prevOperator ) {
            setFieldValue( `${name}.value`, null );
        }
    }, [ name, setFieldValue, operator, prevOperator ] );

    const getValueComponent = () => {
        if ( operator === "NULL" || operator === "NOT_NULL" ) {
            return null;
        }

        const props = {
            name: `${name}.value`,
            label: t( "form:user-filters.value.label" ),
            required: true
        };

        if ( !fieldId ) {
            return (
                <Field
                    component={ Input }
                    className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                    disabled
                    { ...props }
                />
            );
        }

        if ( field.systemField === "MODEL" ) {
            const brand = fields.find( field => field.systemField === "BRAND" );
            return [
                <Field
                    required
                    label={ t( "form:user-filters.field.brand" ) }
                    component={ BrandField }
                    formField={ brand }
                    className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                    key="1"
                    name={ `${name}.value.brand` }
                />,
                <Field
                    { ...props }
                    component={ ModelField }
                    formField={ field }
                    className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                    key="2"
                    name={ `${name}.value.model` }
                />
            ];
        }
        switch ( field.type ) {
            case "PHONE":
                return (
                    <Field
                        type="number"
                        component={ Input }
                        className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                        { ...props }
                    />
                );
            case "NUMBER":
            case "PRICE":
                const numberFieldProps = {
                    type: "number",
                    component: Input,
                };
                if ( operator === "BETWEEN" ) {
                    const fields = [];
                    fields.push(
                        <Field
                            { ...props }
                            { ...numberFieldProps }
                            className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                            key="1"
                            name={ `${name}.value.initial` }
                        />
                    );
                    fields.push(
                        <Field
                            { ...props }
                            { ...numberFieldProps }
                            className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                            key="2"
                            name={ `${name}.value.final` }
                        />
                    );

                    return fields;
                }

                return (
                    <Field
                        { ...props }
                        { ...numberFieldProps }
                        className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                    />
                );
            case "SELECT":
                if ( isEmpty( get( field, "options.values" ) ) ) {
                    break;
                }
                return (
                    <Field
                        component={ Dropdown }
                        source={ field.options.values.map( option => ({
                            value: option.value ? option.value.toString() : option,
                            label: option.label ? option.label : option
                        }))}
                        className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                        { ...props }
                    />
                );
            case "DATE":
                const dateFieldProps = {
                    component: DatePicker,
                    fullWidth: false,
                    className: classnames( flex.item30, cssUtils.marginRightSmall ),
                };
                if ( operator === "BETWEEN" ) {
                    const fields = [];
                    fields.push(
                        <Field
                            { ...dateFieldProps }
                            { ...props }
                            className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                            key="1"
                            name={ `${name}.value.initial` }
                        />
                    );
                    fields.push(
                        <Field
                            { ...dateFieldProps }
                            { ...props }
                            className={ classnames( flex.item15, cssUtils.marginRightSmall ) }
                            key="2"
                            name={ `${name}.value.final` }
                        />
                    );

                    return fields;
                }

                return <Field { ...dateFieldProps } { ...props } />;
            case "SELECT_MULTIPLE":
                if ( isEmpty( get( field, "options.values" ) ) ) {
                    break;
                }
                return (
                    <Field
                        component={ DropdownMultiple }
                        source={ field.options.values.map( option => ({
                            value: option.value ? option.value : option,
                            label: option.label ? option.label : option,
                        }))}
                        className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                        { ...props }
                    />
                );
            default:
                break;
        }

        return (
            <Field
                type="text"
                component={ Input }
                className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                { ...props }
            />
        );
    };

    return (
        <li className={ flex.container }>
            <Field
                name={ `${name}.fieldId` }
                label={ t( "form:user-filters.field.label" ) }
                source={ availableFields.map( field => ({ value: field.id, label: field.name })) }
                className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                component={ Dropdown }
                required
            />
            <Field
                name={ `${name}.operator` }
                label={ t( "form:user-filters.operator.label" ) }
                disabled={ !fieldId }
                source={ operators
                    .filter( operator => {
                        switch ( operator ) {
                            case "BETWEEN":
                                return field.type === "DATE" || field.type === "NUMBER" || field.type === "PRICE";
                            case "LIKE":
                                return field.type !== "SELECT_MULTIPLE";
                            case "NOT_LIKE":
                                return field.type !== "SELECT_MULTIPLE";
                            default:
                                return true;
                        }
                    })
                    .map( operator => ({
                        value: operator,
                        label: t( "form:user-filters.operator." + operator )
                    }))
                }
                className={ classnames( flex.item30, cssUtils.marginRightSmall ) }
                component={ Dropdown }
                sort={ false }
                required
            />
            { getValueComponent() }
            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Tooltip title={ t( "common:exclude" ) }>
                    <IconButton onClick={ onDelete } size="large"><DeleteIcon/></IconButton>
                </Tooltip>
            </div>
        </li>
    );
};

export default FilterFieldItem;