import Button from "@mui/material/Button";

import useTranslate from "../../hooks/use-translate.hook";
import GoogleIcon from "../icons/google.component";

const GoogleAuthButton = props => {
    const t = useTranslate();

    return (
        <Button variant="outlined" startIcon={ <GoogleIcon/> } { ...props }>
            { t( "ui:social.google-auth" ) }
        </Button>
    );
};

export default GoogleAuthButton;