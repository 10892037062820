import { useDispatch } from "react-redux";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import useTranslate from "../../../hooks/use-translate.hook";
import * as actions from "./form.actions";

const formTypes = [
    "COMPANY",
    "PERSON",
    "OPPORTUNITY"
];

const ModuleSelection = ({ form }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const handleChange = value => () => dispatch( actions.initForm( value ) );

    return (
        <List id="selectModule">
            {
                formTypes.map( item => (
                    <ListItemButton
                        key={ `selectModule${ item }` }
                        onClick={ handleChange( item ) }
                        selected={ form === item }
                    >
                        <ListItemText primary={ t( "config:form.modules." + item ) } />
                    </ListItemButton>
                ) )
            }
        </List>
    );
};

export default ModuleSelection;