import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { Input, Link, cssUtils } from "../ui";
import { verifyAccessToken } from "./login.actions";
import { TWO_FACTOR_VERIFICATION_FORM } from "./login.constants";

const TwoFactorVerification = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const formik = useForm({
        form: TWO_FACTOR_VERIFICATION_FORM,
        initialValues: {
            accessToken: get( params, "accessToken" ),
            code: "",
        },
        onSubmit: values => dispatch( verifyAccessToken( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.code ) {
                errors.code = "common:validation.required";
            }
            return errors;
        },
    });

    return (
        <>
            <Helmet title={ t( "login:title" ) } />
            <Typography>
                { t( "login:two-factor.call-to-action" ) }
            </Typography>
            <Form formik={ formik } noValidate>
                <Field
                    name="code"
                    label={ t( "login:two-factor.verification-code" ) }
                    component={ Input }
                    maxLength={ 6 }
                    required
                />
                { formik.error && <Typography color="error">{ formik.error }</Typography> }
                <Button
                    type="submit"
                    className={ cssUtils.marginTop }
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={ formik.submitting }
                >
                    { t( "login:login.action" ) }
                </Button>

                <hr />

                <Link href="/login" className={ cssUtils.fullWidth }>
                    <Button color="secondary" variant="contained" className={ cssUtils.fullWidth }>
                        { t( "common:back" ) }
                    </Button>
                </Link>
            </Form>
        </>
    );
};

export default TwoFactorVerification;