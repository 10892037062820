import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { actions as productActions, selectors as productSelectors } from "../product";
import { getFunnelId } from "../opportunity/opportunity.selectors";
import { listAll as listFunnels } from "../funnel/funnel.selectors";
import { Autocomplete } from "../ui";

const FilterProducts = ({ initialValues }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );
    const funnels = useSelector( listFunnels );
    const products = useSelector( productSelectors.getSearchCustomers );
    const loading = useSelector( productSelectors.isLoadingSearch );

    const timeoutProducts = useRef();

    const funnel = useMemo(
        () => funnelId && !isEmpty( funnels ) ? funnels.find( funnel => funnel.id === funnelId ) : null,
        [ funnelId, funnels ]
    );
    const helperText = useMemo(
        () => funnel && !isEmpty( funnel.productCategories ) &&
            t( "opportunity:funnel-product-categories", {
                productCategories: funnel.productCategories
                    .map( productCategory => productCategory.name )
                    .join( ", " )
            }),
        [ t, funnel ]
    );

    const searchProducts = description => {
        const categoriesId = funnel.productCategories ?
            funnel.productCategories.map( productCategory => productCategory.id ) :
            [];
        clearTimeout( timeoutProducts.current );
        if ( description ) {
            timeoutProducts.current = setTimeout( () => {
                dispatch( productActions.fetchSearchProducts({ description, categoriesId: categoriesId.toString() }) );
            }, 300 );
        }
    };

    const source = useMemo( () => {
        return ( products || [] ).map( product => ({ value: product.id, label: product.description }));
    }, [ products ] );

    useEffect( () => {
        if ( !isEmpty( initialValues.opportunity?.products ) ) {
            dispatch( productActions.fetchSearchProducts({ ids: initialValues.opportunity.products.toString() }) );
        }
    }, [ dispatch, initialValues.opportunity?.products ] );

    return (
        <Field
            name="opportunity.products"
            source={ source }
            label={ t( "opportunity:proposal-product.linked-products" ) }
            helperText={ helperText }
            component={ Autocomplete }
            loading={ loading }
            onQueryChange={ searchProducts }
            variant="outlined"
        />
    );
};

export default FilterProducts;