import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../hooks/use-translate.hook";

const DialogConfirm = ({ open, onConfirm, onCancel, message, messages = [], disabled, title }) => {
    const t = useTranslate();

    return (
        <Dialog open={ open } maxWidth="xs" fullWidth>
            { title && <DialogTitle>{ title }</DialogTitle> }
            <DialogContent>
                { messages.map( ( m, index ) => <DialogContentText key={ index }>{ m }</DialogContentText> ) }
                { message && <DialogContentText>{ message }</DialogContentText> }
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ disabled } onClick={ onCancel }>{ t( "common:no" ) }</Button>
                <Button color="primary" disabled={ disabled } onClick={ onConfirm }>{ t( "common:yes" ) }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogConfirm;