import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils } from "../../../ui";
import { SortablePhaseItem } from "./phase-item.component";
import { deletePhase } from "./funnel.actions";

const PhasesList = ({ form, move, push, remove }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ index, setIndex ] = useState();
    const [ activeDeleteDialog, setActiveDeleteDialog ] = useState( false );
    const [ activeDeleteForbiddenDialog, setActiveDeleteForbiddenDialog ] = useState( false );

    const toggleDeleteForbiddenDialog = () => setActiveDeleteForbiddenDialog( !activeDeleteForbiddenDialog );

    const toggleDeleteDialog = index => () => {
        if ( form.values.phases.filter( field => field.id ).length === 1 ) {
            toggleDeleteForbiddenDialog();
            return;
        }

        setIndex( index );
        setActiveDeleteDialog( !activeDeleteDialog );
    };

    const onDeletePhase = () => {
        dispatch( deletePhase( form.values.phases[ index ] ) );
        remove( index );
        toggleDeleteDialog()();
    };

    const onDragEnd = result => {
        if ( !result.destination ) {
            return;
        }
        move( result.source.index, result.destination.index );
    };

    const getItems = () => form.values.phases.map( ( phase, index ) => (
        <SortablePhaseItem
            key={ index }
            onDelete={ toggleDeleteDialog( index ) }
            index={ index }
            form={ form }
        />
    ));

    return (
        <>
            <div id="phaseForm" className={ cssUtils.marginTopSmall }>
                <Dialog
                    open={ activeDeleteDialog }
                    onClose={ toggleDeleteDialog() }
                >
                    <DialogTitle>{ t( "config:phases.delete.title" ) }</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            { t( "config:phases.delete.comment" ) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ toggleDeleteDialog() } >
                            { t( "common:no" ) }
                        </Button>
                        <Button color="primary" onClick={ onDeletePhase } >
                            { t( "common:yes" ) }
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={ activeDeleteForbiddenDialog }
                    onClose={ toggleDeleteForbiddenDialog }
                >
                    <DialogTitle>{ t( "config:phases.delete.title" ) }</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{ t( "config:phases.delete.forbidden" ) }</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ toggleDeleteForbiddenDialog }>
                            { t( "common:ok" ) }
                        </Button>
                    </DialogActions>
                </Dialog>
                <DragDropContext onDragEnd={ onDragEnd }>
                    <Droppable droppableId="droppable">
                        { provided => (
                            <div
                                { ...provided.droppableProps }
                                ref={ provided.innerRef }
                            >
                                { getItems() }
                                { provided.placeholder }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Button
                    className={ cssUtils.marginTopSmall }
                    color="primary"
                    onClick={ () => push({ funnelId: form.values.id }) }
                >
                    { t( "config:phases.add" ) }
                </Button>
            </div>
        </>
    );
};

export default PhasesList;