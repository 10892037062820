import { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WebhookIcon from "@mui/icons-material/Webhook";
import { useNavigate } from "react-router";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as funnelActions, selectors as funnelSelectors } from "../../../funnel";
import { actions as sourceActions, selectors as sourceSelectors } from "../../../source";
import { Table } from "../../../ui/table";
import { cssUtils, flex } from "../../../ui";
import cssMarketplace from "../../marketplace.scss";
import { fetchWebhooks } from "./webhook.actions";
import { getWebhooks, isLoadingWebhooks } from "./webhook.selectors";
import DeleteWebhook from "./delete-webhook.component";
import NewWebhook from "./new-webhook.component";

const Webhook = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const navigate = useNavigate();

    const webhooks = useSelector( getWebhooks );
    const funnels = useSelector( funnelSelectors.listAll );
    const sources = useSelector( sourceSelectors.getSources );
    const loadingFunnels = useSelector( funnelSelectors.isLoadingAll );
    const loadingSources = useSelector( sourceSelectors.isLoadingSources );
    const loadingWebhooks = useSelector( isLoadingWebhooks );

    const loading = loadingFunnels || loadingSources || loadingWebhooks;

    const [ openNew, setOpenNew ] = useState( false );

    const toggleNew = useCallback( () => setOpenNew( openNew => !openNew ), [] );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const model = useMemo( () => ({
        url: {
            title: t( "marketplace:additional-resources.webhooks.url" ),
            format: url => (
                <Tooltip placement="bottom-start" title={ url }>
                    <span>
                        { url }
                    </span>
                </Tooltip>
            )
        },
        customerType: {
            title: t( "customer:type" ),
            format: customerType => customerType && t( `customer:${customerType.toLowerCase()}.title` )
        },
        funnelId: {
            title: t( "opportunity:funnel" ),
            format: funnelId => funnelId && funnels?.find( funnel => funnel.id === funnelId )?.name
        },
        sourceId: {
            title: t( "opportunity:source" ),
            format: sourceId => sourceId && sources?.find( source => source.id === sourceId )?.name
        },
        event: {
            title: t( "marketplace:additional-resources.webhooks.event.label" ),
            format: event => t( `marketplace:additional-resources.webhooks.event.${event}` ),
        },
        oauthClient: {
            title: t( "marketplace:additional-resources.webhooks.oauth-client" ),
            format: oauthClient => oauthClient && oauthClient.name
        },
        id: {
            title: " ",
            size: "small",
            format: id => <DeleteWebhook id={ id } />
        }
    }), [ t, funnels, sources ] );

    useEffect( () => {
        dispatch( funnelActions.fetchFunnels() );
        dispatch( sourceActions.fetchSources() );
        dispatch( fetchWebhooks() );
    }, [ dispatch ] );

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            <WebhookIcon style={{ fontSize: 50 }}/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            <span>
                                { t( "marketplace:additional-resources.webhooks.call-to-action" ) }
                                <a
                                    href={
                                        t( "marketplace:additional-resources.webhooks.docs-link.value" )
                                    }
                                    target="_blank"
                                    className={ cssUtils.textLink }
                                >
                                    <b>
                                        {
                                            t( "marketplace:additional-resources.webhooks.docs-link.label" )
                                        }
                                    </b>
                                </a>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader
                        title={ t( "marketplace:additional-resources.webhooks.title" ) }
                        subheader={ t( "marketplace:additional-resources.webhooks.subtitle" ) }
                    />
                    <Table
                        emptyMessage={ t( "marketplace:additional-resources.webhooks.empty" ) }
                        model={ model }
                        source={ webhooks }
                        isLoading={ loading }
                        onAdd={ toggleNew }
                        shadow={ false }
                    />
                    <NewWebhook open={ openNew } onClose={ toggleNew } />
                </Card>
            </div>
        </div>
    );
};

export default Webhook;
