import { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubmitError, reset } from "formik-redux";
import classnames from "classnames";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ListIcon from "@mui/icons-material/List";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { DragDropContext } from "react-beautiful-dnd";

import usePrevious from "../../hooks/use-previous.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../ui";
import PhaseTriggers from "../phase-triggers.component";
import PhaseContainer from "./phase-container.component";
import {
    isReloadingPhaseOpportunities,
    getFunnelId,
    getFunnelLatestUpdatedAt,
    getPhaseOpportunitiesPrice,
    getPhaseOpportunitiesTotal,
    getPhaseOpportunitiesRecurrentPrice
} from "../opportunity.selectors";
import {
    fetchFunnelLatestUpdatedAt,
    setFunnelLatestUpdatedAt,
    savePhaseOpportunity,
    reloadPhaseOpportunities,
    resetPhaseOpportunities,
} from "../opportunity.actions";
import { selectors as funnelSelectors } from "../../funnel";
import { selectors as coreSelectors } from "../../core";
import { fetchKanban } from "../../kanban/kanban.actions";
import { isLoadingKanban } from "../../kanban/kanban.selectors";
import { actions as phaseActions, selectors as phaseSelectors } from "../../phase";
import { listWithoutGroupings } from "../../form/form.selectors";
import { getKanban } from "../../kanban/kanban.selectors";
import { OPPORTUNITY_STATUS_FORM } from "../opportunity.constants";
import OpportunityStatusDialog from "../opportunity-status-dialog.component";
import ChangeOpenSchedulesStatus from "./change-open-schedules-status.component";
import ExportationForm from "./exportation-form.component";
import FiltersButton from "./filters-button.component";
import FunnelFilter from "./funnel-filter.component";
import LivePhases from "./live-phases.component";
import OpportunitiesFilters from "./opportunities-filters.component";
import css from "./phases.scss";
import tableCss from "./table.scss";

const OpportunitiesPhases = ({ onOpenFilters, showOpportunitiesTable }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const loadingKanban = useSelector( isLoadingKanban );
    const funnelId = useSelector( getFunnelId );
    const funnels = useSelector( funnelSelectors.listAll );
    const funnelLatestUpdatedAt = useSelector( getFunnelLatestUpdatedAt );
    const reloadingOpportunities = useSelector( isReloadingPhaseOpportunities );
    const phases = useSelector( phaseSelectors.listWithFunnel( funnelId ) );
    const opportunitiesPrice = useSelector( getPhaseOpportunitiesPrice );
    const opportunitiesRecurrentPrice = useSelector( getPhaseOpportunitiesRecurrentPrice );
    const opportunitiesTotal = useSelector( getPhaseOpportunitiesTotal );
    const user = useSelector( coreSelectors.getUser );
    const preferences = useSelector( coreSelectors.getPreferences );
    const phaseChangeError = useSelector( getSubmitError( OPPORTUNITY_STATUS_FORM ) );
    const companyFields = useSelector( listWithoutGroupings( "COMPANY" ) );
    const personFields = useSelector( listWithoutGroupings( "PERSON" ) );
    const opportunityFields = useSelector( listWithoutGroupings( "OPPORTUNITY" ) );
    const kanban = useSelector( getKanban );

    const prevPreferences = usePrevious( preferences );
    const prevReloadingOpportunities = usePrevious( reloadingOpportunities );

    const [ toChangeStatus, setToChangeStatus ] = useState();
    const [ showOpenSchedules, setShowOpenSchedules ] = useState();
    const [ lastReloadAt, setLastReloadAt ] = useState( new Date().toISOString() );
    const [ showExportationForm, setShowExportationForm ] = useState( false );
    const [ confirmTriggers, setConfirmTriggers ] = useState();

    const funnel = funnels?.find( funnel => funnel.id === funnelId );
    const opportunitiesOrderBy = get( funnel, "opportunitiesOrderBy" );
    const opportunitiesOrderType = get( funnel, "opportunitiesOrderType" );

    const isLive = useMemo( () => {
        return get( preferences, "opportunity.liveKanban", true );
    }, [ preferences ] );

    const reloadOpportunities = useCallback( () => {
        phases.forEach( phase => dispatch( reloadPhaseOpportunities( phase.id ) ) );
    }, [ dispatch, phases ] );

    const changeStatus = opportunity => {
        setToChangeStatus( opportunity );
    };
    const closeChangeStatus = () => changeStatus();

    const saveStatus = useCallback( ({ phase, ...opportunity }) => {
        dispatch( savePhaseOpportunity( phase.id, opportunity ) );
    }, [ dispatch ] );

    const refreshStatus = () => {
        if ( toChangeStatus.nextScheduling ) {
            setShowOpenSchedules( toChangeStatus.id );
        }
        changeStatus();
    };

    const closeOpenSchedules = () => setShowOpenSchedules( null );

    const handleDragEnd = result => {
        const { destination, source, draggableId } = result;
        const phaseFrom = phases.find( phase => phase.id === Number( source.droppableId ) );
        const phaseTo = phases.find( phase => phase.id === Number( destination.droppableId ) );

        if ( phaseFrom.id === phaseTo.id ) {
            return;
        }

        const opportunity = {
            id: draggableId,
            phase: phaseTo,
            phaseEntryDate: new Date().toISOString()
        };

        dispatch( savePhaseOpportunity(
            phaseFrom.id,
            { id: opportunity.id, phase: opportunity.phase, phaseEntryDate: opportunity.phaseEntryDate }
        ) );

        const triggers = opportunity.phase.triggers;
        if ( !isEmpty( triggers ) && !isEmpty( triggers.filter( trigger => !trigger.automatic ) ) ) {
            setConfirmTriggers( opportunity );
        }
    };

    const closeConfirmTriggers = () => setConfirmTriggers();

    const toggleShowExportationForm = () => setShowExportationForm( !showExportationForm );

    const clearPhaseChangeError = () => {
        reloadOpportunities();
        dispatch( reset( OPPORTUNITY_STATUS_FORM ) );
    };

    const formatKanbanField = field => {
        switch (field.formType) {
            case "PERSON":
                return `person.fields.${field.fieldId}`;
            case "COMPANY":
                return `company.fields.${field.fieldId}`;
            default:
                return `fields.${field.fieldId}`;
        }
    };
    const exportationColumns = useMemo( () => {
        const exportationColumns = [];

        if ( !kanban || isEmpty( opportunityFields ) || isEmpty( companyFields ) || isEmpty( personFields ) ) {
            return exportationColumns;
        }

        const titleField = opportunityFields.find( field => field.systemField === "TITLE" );
        exportationColumns.push( `fields.${titleField.id}` );

        if ( kanban.company ) {
            const field = companyFields.find( field => field.systemField === "NAME" );
            field && exportationColumns.push( `company.fields.${field.id}` );
        }
        if ( kanban.person ) {
            const field = personFields.find( field => field.systemField === "NAME" );
            field && exportationColumns.push( `person.fields.${field.id}` );
        }
        if ( kanban.email ) {
            const companyField = companyFields.find( field => field.type === "EMAIL" );
            companyField && exportationColumns.push( `company.fields.${companyField.id}` );
            const personField = personFields.find( field => field.type === "EMAIL" );
            personField && exportationColumns.push( `person.fields.${personField.id}` );
        }
        if ( kanban.phone ) {
            const companyField = companyFields.find( field => field.type === "PHONE" );
            companyField && exportationColumns.push( `company.fields.${companyField.id}` );
            const personField = personFields.find( field => field.type === "PHONE" );
            personField && exportationColumns.push( `person.fields.${personField.id}` );
        }
        if ( kanban.price ) {
            const field = opportunityFields.find( field => field.systemField === "PRICE" );
            field && exportationColumns.push( `fields.${field.id}` );
        }
        if ( kanban.recurrentPrice ) {
            const field = opportunityFields.find( field => field.systemField === "RECURRENT_PRICE" );
            field && exportationColumns.push( `fields.${field.id}` );
        }
        if ( kanban.source ) {
            const field = opportunityFields.find( field => field.systemField === "SOURCE" );
            field && exportationColumns.push( `fields.${field.id}` );
        }
        if ( kanban.nextScheduling ) {
            exportationColumns.push( "nextScheduling" );
        }
        if ( kanban.user ) {
            const field = opportunityFields.find( field => field.systemField === "USER" );
            field && exportationColumns.push( `fields.${field.id}` );
        }
        if ( !isEmpty( kanban.fields ) ) {
            exportationColumns.push( ...kanban.fields.map( formatKanbanField ) );
        }

        return exportationColumns;
    }, [ companyFields, kanban, opportunityFields, personFields ] );

    const prevFunnelId = usePrevious( funnelId );

    useEffect( () => {
        dispatch( fetchKanban() );
        dispatch( setFunnelLatestUpdatedAt() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( funnelId ) {
            dispatch( phaseActions.fetchFunnelPhases( funnelId ) );
            setLastReloadAt( new Date().toISOString() );
        }
    }, [ dispatch, funnelId ] );
    useEffect( () => {
        if ( prevReloadingOpportunities && !reloadingOpportunities ) {
            dispatch( phaseActions.fetchFunnelPhases( funnelId ) );
            setLastReloadAt( new Date().toISOString() );
        }
    }, [ dispatch, funnelId, prevReloadingOpportunities, reloadingOpportunities ] );
    useEffect( () => {
        const interval = isLive && setInterval( () => dispatch( fetchFunnelLatestUpdatedAt() ), 30000 );
        return () => clearInterval( interval );
    }, [ dispatch, isLive ] );
    useEffect( () => {
        if ( prevPreferences && !isEqual( prevPreferences, preferences ) ) {
            reloadOpportunities();
        }
    }, [ prevPreferences, preferences, reloadOpportunities ] );
    useEffect( () => {
        if ( prevFunnelId && prevFunnelId !== funnelId ) {
            dispatch( resetPhaseOpportunities() );
        }
    }, [ dispatch, prevFunnelId, funnelId ] );
    useEffect( () => {
        return () => {
            dispatch( resetPhaseOpportunities() );
        };
    }, [ dispatch ] );

    return (
        <div className={ css.main }>

            <PhaseTriggers
                show={ !!confirmTriggers }
                onClose={ closeConfirmTriggers }
                opportunity={ confirmTriggers }
            />

            <OpportunityStatusDialog
                onClose={ closeChangeStatus }
                onSave={ saveStatus }
                open={ !!toChangeStatus }
                opportunity={ toChangeStatus || {} }
                refreshStatus={ refreshStatus }
                status={ toChangeStatus ? toChangeStatus.status : "" }
            />

            <ChangeOpenSchedulesStatus
                open={ !!showOpenSchedules }
                opportunityId={ showOpenSchedules }
                onClose={ closeOpenSchedules }
            />

            <div className={ classnames( flex.container, flex.alignItemsCenter, cssUtils.fullWidth ) }>
                <div className={ flex.fill }>
                    <div className={ classnames( flex.container, flex.column ) }>
                        <div className={ tableCss.containerSelectedFilters }>
                            <OpportunitiesFilters status="OPEN" />
                        </div>
                        <Typography variant="subtitle1">
                            {
                                t( "opportunity:opportunity-list.phases-title", {
                                    price: priceFormat( opportunitiesPrice ),
                                    recurrentPrice: priceFormat( opportunitiesRecurrentPrice ),
                                    total: opportunitiesTotal
                                })
                            }
                        </Typography>
                    </div>
                </div>
                <div className={ classnames( flex.item30, flex.container, flex.alignItemsCenter ) }>
                    <LivePhases />
                    <FunnelFilter
                        className={ flex.fill }
                        fullWidth={ false }
                        size="small"
                    />
                    <Tooltip title={ t( "opportunity:opportunity-list.show-table" ) }>
                        <IconButton
                            id="opportunitiesTable"
                            className={ cssUtils.floatRight }
                            onClick={ showOpportunitiesTable }
                            size="large">
                            <ListIcon/>
                        </IconButton>
                    </Tooltip>
                    {
                        user.admin &&
                            <>
                                <Tooltip title={ t( "common:export" )}>
                                    <IconButton onClick={ toggleShowExportationForm } size="large">
                                        <CloudDownloadIcon/>
                                    </IconButton>
                                </Tooltip>
                                <ExportationForm
                                    initialColumns={ exportationColumns }
                                    open={ showExportationForm }
                                    onClose={ toggleShowExportationForm }/>
                            </>
                    }
                    <FiltersButton onClick={ onOpenFilters } preferences={ preferences } />
                </div>
            </div>
            { loadingKanban && <LinearProgress className={ cssUtils.fullWidth } /> }
            <DragDropContext onDragEnd={ handleDragEnd }>
                <div className={ css.phases }>
                    {
                        phases?.map( ( phase, index ) => (
                            <PhaseContainer
                                key={ phase.id }
                                className={ classnames( flex.fill, css.phaseCard ) }
                                index={ index }
                                phase={ phase }
                                saveStatus={ changeStatus }
                                orderBy={ opportunitiesOrderBy }
                                orderType={ opportunitiesOrderType }
                            />
                        ))
                    }
                    {
                        !phaseChangeError && funnelLatestUpdatedAt > lastReloadAt &&
                            <Snackbar
                                open
                                onClick={ reloadOpportunities }
                                className={ cssUtils.cursorPointer }
                            >
                                <Alert severity="warning">
                                    { t( "opportunity:opportunity-list.refresh-warning" ) }
                                </Alert>
                            </Snackbar>
                    }
                    {
                        !!phaseChangeError &&
                            <Snackbar
                                open
                                className={ cssUtils.cursorPointer }
                                autoHideDuration={ 5000 }
                            >
                                <Alert severity="error" onClick={ clearPhaseChangeError }>
                                    {
                                        phaseChangeError + " " + t(
                                            "opportunity:opportunity-list.click-to-refresh"
                                        )
                                    }
                                </Alert>
                            </Snackbar>
                    }
                </div>
            </DragDropContext>
        </div>
    );
};

export default OpportunitiesPhases;