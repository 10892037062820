import { useCallback } from "react";
import config from "config";
import classnames from "classnames";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";

import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../../ui";
import cssMarketplace from "../../marketplace.scss";

const Zapier = ({ className }) => {
    const navigate = useNavigate();

    const t = useTranslate();

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            <div className={ cssMarketplace.zapierIcon }></div>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            <span>
                                { t( "marketplace:hubs.zapier.call-to-action" ) }
                                <a
                                    href={
                                        t( "marketplace:hubs.zapier.docs-link.value" )
                                    }
                                    target="_blank"
                                    className={ cssUtils.textLink }
                                >
                                    <b>
                                        {
                                            t( "marketplace:hubs.zapier.docs-link.label" )
                                        }
                                    </b>
                                </a>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>

                <>
                    <Helmet>
                        <script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
                        <link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
                    </Helmet>
                    <Card className={ flex.fill }>
                        <CardHeader title={ t( "marketplace:hubs.zapier.title" ) }/>
                        <Divider />
                        <CardContent>
                            {
                                config.has( "client.zapier.clientId" ) &&
                                <zapier-full-experience
                                    client-id={ config.get( "client.zapier.clientId" ) }
                                    theme="light"
                                    intro-copy-display="hide"
                                    app-search-bar-display="show"
                                    app-limit={ 6 }
                                    zap-style="row"
                                    zap-call-to-action-display="show"
                                    zap-create-from-scratch-display="show"
                                />
                            }
                        </CardContent>
                    </Card>
                </>
            </div>
        </div>
    );
};


export default Zapier;