import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { formatBytes } from "../../core/formatter.utils";
import { cssUtils } from "../../ui";

const EmailAttachments = ({ attachments }) => {
    const t = useTranslate();

    if ( isEmpty( attachments ) ) {
        return null;
    }

    return (
        <List
            subheader={ <ListSubheader>{ t( "customer:email.attachments" ) }</ListSubheader> }
            className={ cssUtils.fullWidth }
            dense
        >
            {
                attachments.map( attachment => (
                    <ListItem key={ attachment.id }>
                        <ListItemText
                            primary={ attachment.name }
                            secondary={ formatBytes( attachment.size ) }
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={ t( "common:download" ) }>
                                <IconButton size="small" href={ `/api/v1/emails/attachments/${attachment.id}` }>
                                    <CloudDownloadIcon/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))
            }
        </List>
    );
};

export default EmailAttachments;