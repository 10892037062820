import { Fragment, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButtton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, Switch, cssUtils } from "../ui";
import css from "./dashboard.scss";

const OpportunitiesCreatedChart = ({
    className,
    initialDate,
    finalDate,
    statistics: { created: { data } },
    onClickItem,
    users,
}) => {
    const t = useTranslate();

    const [ accumulated, setAccumulated ] = useState( false );
    const [ showHelp, setShowHelp ] = useState( false );
    const toggleHelp = () => setShowHelp( !showHelp );

    const format = useMemo( () => {
        const differentMonths = dayjs( finalDate ).diff( dayjs( initialDate ), "month" ) > 0;
        if ( differentMonths ) {
            return "MMM/YY";
        }
        return "D/MMM";
    }, [ finalDate, initialDate ] );
    const categories = useMemo( () => {
        const maxDate = dayjs( finalDate );
        let minDate = dayjs( initialDate );

        const categories = [];
        const differentMonths = maxDate.diff( minDate, "month" ) > 0;
        if ( differentMonths ) {
            while ( maxDate > minDate || minDate.format( "M" ) === maxDate.format( "M" ) ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "month" );
            }
        } else {
            while ( maxDate.diff( minDate, "days", true ) >= 0 ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "day" );
            }
        }

        return categories;
    }, [ format, finalDate, initialDate ] );

    const series = useMemo( () => {
        let items = [];
        data.forEach( item => items = items.concat( item.dates ) );

        if ( accumulated ) {
            return [{
                name: t( "dashboard:opportunities-created-chart.accumulated" ),
                data: categories.map(
                    category => items.filter( item => dayjs( item ).format( format ) === category ).length
                )
            }];
        }
        return data.map( creation => ({
            userId: creation.userId,
            name: creation.userName,
            data: categories.map(
                category => creation.dates.filter( item => dayjs( item ).format( format ) === category ).length
            )
        }));
    }, [ format, data, accumulated, t, categories ] );

    const chartOptions = useMemo( () => {
        const differentMonths = dayjs( finalDate ).diff( dayjs( initialDate ), "month" ) > 0;
        return {
            title: {
                text: t( "dashboard:opportunities-created-chart.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunities-created-chart.title" );
                    }
                },
            },
            yAxis: {
                title: {
                    text: ""
                }
            },
            xAxis: {
                categories
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                if ( !this.y ) {
                                    return;
                                }

                                const iDate = dayjs( this.category, format )
                                    .year( dayjs( initialDate ).year() )
                                    .startOf( "day" )
                                    .toDate();
                                let fDate = dayjs( iDate ).endOf( "day" ).toDate();
                                if ( differentMonths ) {
                                    fDate = dayjs( fDate ).endOf( "month" ).toDate();
                                }
                                const usersId = this.series.userOptions.userId ?
                                    [].concat( this.series.userOptions.userId ).toString()
                                    : users.toString();

                                onClickItem(
                                    t(
                                        "dashboard:opportunities-created-chart.title-dialog",
                                        {
                                            initialDate: dayjs( iDate ).format( "D/MMMM" ),
                                            finalDate: dayjs( fDate ).format( "D/MMMM" ),
                                            interpolation: { escapeValue: false }
                                        }
                                    ),
                                    {
                                        initialCreatedAt: iDate.toISOString(),
                                        finalCreatedAt: fDate.toISOString(),
                                        users: usersId
                                    }
                                );
                            }
                        }
                    }
                }
            },
            series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom"
                        }
                    }
                }]
            }
        };
    }, [ finalDate, initialDate, t, categories, series, format, users, onClickItem ] );

    const showAccumulated = !isEmpty( users ) && users.length > 1;

    return (
        <Card id="opportunitiesCreatedChart" className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunities-created-chart.help" ) }
                        {
                            showAccumulated &&
                                <Fragment>
                                    <br/>
                                    { t( "dashboard:opportunities-created-chart.help-accumulated" ) }
                                </Fragment>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="opportunitiesCreatedChart"
                data={ chartOptions }
                noDataMessage={ t( "dashboard:opportunities-created-chart.empty" ) }
            />

            {
                showAccumulated &&
                    <div className={ cssUtils.marginLeft }>
                        <Switch
                            label={ t( "dashboard:opportunities-created-chart.accumulated" ) }
                            input={{ onChange: setAccumulated }}
                            checked={ accumulated }
                            className={ cssUtils.cursorPointer }
                        />
                    </div>
            }

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButtton id="helpButton" onClick={ toggleHelp } className={ css.iconHelpChart } size="large">
                    <InfoIcon/>
                </IconButtton>
            </Tooltip>
        </Card>
    );
};

export default OpportunitiesCreatedChart;