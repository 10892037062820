import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils } from "../../ui";
import css from "../../ui/timeline/timeline.scss";

const getFieldTranslate = ( t, key ) => {
    switch ( key ) {
        case "name":
            return t( "common:name" );
        default:
            "";
    }
};

const ProposalChangeEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();
    return (
        <TimelineItem icon={ <DescriptionIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.proposal-change", { name: payload.name } ) }
                date={ date } />

            {
                Object.keys( payload.previous ).map( key => (
                    <div key={ key } className={ css.change } >
                        <Typography>
                            { getFieldTranslate( t, key ) }{ ": " }
                            <Typography component="span" className={ cssUtils.marginLeftSmall }>
                                <mark className={ cssUtils.highlightOld }>
                                    { payload.previous[ key ] }
                                </mark>
                                {"→ "}
                                <mark className={ cssUtils.highlightNew }>
                                    { (key, payload.current[ key ]) }
                                </mark>
                            </Typography>
                        </Typography>
                    </div>
                ))
            }
            {
                Object.keys( payload.current ).filter( key => !payload.previous[ key ] ).map( key => (
                    <div key={ key } className={ css.change } >
                        <Typography>
                            { getFieldTranslate( t, key ) + ": " }
                            <Typography component="span" className={ cssUtils.marginLeftSmall }>
                                <mark className={ cssUtils.highlightNew }>
                                    { payload.current[ key ] }
                                </mark>
                            </Typography>
                        </Typography>
                    </div>
                ))
            }

        </TimelineItem>
    );
};

export default ProposalChangeEvent;