import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import isEmpty from "lodash/isEmpty";
import AddIcon from "@mui/icons-material/Add";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import qs from "qs";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { fetchImportations, setImportation } from "./importation.actions";
import { getImportations, getTotalElementsAll, getTotalPagesAll, isLoadingImportations } from "./importation.selectors";
import { EmptyState, Fab } from "../ui";
import { Table } from "../ui/table";
import { useDateFormat } from "../hooks/use-date-format.hook";

export const onClickNew = () => history.push( "/importations/new" );

const Importation = ( { className } ) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation();
    const dateFormat = useDateFormat( t );

    const importations = useSelector( getImportations );
    const totalPages = useSelector( getTotalPagesAll );
    const totalElements = useSelector( getTotalElementsAll );
    const loading = useSelector( isLoadingImportations );

    const queryString = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location ] );

    const selectedPage = useMemo( () => Number( queryString.page || 0 ), [ queryString.page ] );
    const [ orderBy, setOrderBy ] = useState( queryString.orderBy || "createdAt" );
    const [ orderType, setOrderType ] = useState( queryString.orderType || "DESC" );
    const model = useMemo(() => {
        return {
            name: t( "importation:importation.name" ),
            createdAt: {
                title: t( "importation:importation.created-at" ),
                format: date => dateFormat( date )
            },
            createdBy: {
                title: t( "importation:importation.created-by" ),
                format: user => user.name
            },
            file: {
                title: t( "importation:importation.file" )
            },
            finishedAt: {
                title: t( "importation:importation.finished-at" ),
                format: date => dateFormat( date )
            }
        };
    }, [ dateFormat, t ]);

    const handlePageChange = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const onRowClick = index => {
        const importation = importations[ index ];
        dispatch( setImportation( importation ) );
        history.push( `/importations/${importation.id}/results` );
    };

    const loadImportations = useCallback( () => {
        dispatch( fetchImportations( { page: selectedPage, orderBy, orderType } ) );
    }, [ dispatch, orderBy, orderType, selectedPage ]);

    useEffect( () => {
        loadImportations();
    }, [ loadImportations ]);

    return (
        <div className={ className }>
            <Helmet title={ t( "importation:title" ) } />
            {
                isEmpty( importations ) && !loading ?
                    <div>
                        <EmptyState icon={ CloudOffIcon } message={ t( "importation:importations.empty" ) }/>
                    </div> :
                    <Table
                        model={ model }
                        source={ importations }
                        onRowClick={ onRowClick }
                        isLoading={ loading }
                        pageSize={ 20 }
                        totalPages={ totalPages }
                        totalElements={ totalElements }
                        saveOrderHistory
                        onChangePage={ handlePageChange }
                        allowOrder
                        selectedPage={ selectedPage }
                        orderBy={ orderBy }
                        orderType={ orderType }
                    />
            }

            <Fab id="addButton" position="bottomRight" onClick={ onClickNew }>
                <AddIcon/>
            </Fab>
        </div>
    );

};

export default Importation;