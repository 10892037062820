import { createSelector } from "reselect";
import get from "lodash/get";

export const getSelected = createSelector(
    state => state.reports.selected,
    selected => get( selected, "name" )
);
export const isLoadingResults = state => get( state.reports, "selected.results.loading" );
export const getResults = createSelector(
    state => state.reports.selected,
    selected => get( selected, "results.data" )
);