import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import FormField from "./form-field.component";
import FormFieldDragLayer from "./form-field-drag-layer.component";
import MappingField from "./mapping-field.component";
import { cssUtils, flex } from "../ui";
import css from "./importation.scss";

const MappingFile = ({
    form,
    personFields,
    companyFields,
    opportunityFields,
    onClickMappingField,
    deleteMappingField,
    onChangeMappingField,
}) => {
    const t = useTranslate();

    const [ widthFormField, setWidthFormField ] = useState( 0 );
    const headerFormFields = useRef( null );
    useEffect(() => {
        setWidthFormField( headerFormFields.current ? headerFormFields.current.offsetWidth : 0 );
    }, [] );

    return (
        <DndProvider backend={ HTML5Backend }>
            <FormFieldDragLayer width={ widthFormField } />
            <div className={ css.mainContainerMapping }>
                <Card className={ css.containerMappingFields }>
                    <CardHeader subheader={ t( "importation:new-importation.file-columns" ) }/>
                    <hr className={ css.titleDivider } />

                    {
                        form.values.mapping.map( ( mapping, index ) => (
                            <MappingField
                                key={ index }
                                onClick={ onClickMappingField }
                                onDelete={ deleteMappingField }
                                order={ index }
                                { ...mapping }
                            />
                        ))
                    }
                </Card>
                <Card
                    className={ classnames(
                        cssUtils.marginTop, cssUtils.marginLeft, flex.fill, cssUtils.overflowAuto )
                    }
                >
                    <CardHeader
                        ref={ headerFormFields }
                        subheader={ t( "importation:new-importation.form-fields" ) }
                    />
                    <hr className={ css.titleDivider } />

                    { form.values.person && <>
                        <CardHeader subheader={ t( "importation:new-importation.customer-person" ) }/>
                        {
                            personFields.map( field => (
                                <FormField
                                    key={ field.id }
                                    field={ field }
                                    onChange={ onChangeMappingField }
                                />
                            ))
                        }

                    </>}

                    { form.values.company && <>
                        <CardHeader subheader={ t( "importation:new-importation.customer-company" ) }/>
                        {
                            companyFields.map( field => (
                                <FormField
                                    key={ field.id }
                                    field={ field }
                                    onChange={ onChangeMappingField }
                                />
                            ))
                        }

                    </>}

                    {
                        form.values.createOpportunity &&
                            <>
                                <CardHeader
                                    subheader={ t( "importation:new-importation.opportunities" ) }
                                />
                                {
                                    opportunityFields.map( field => (
                                        <FormField
                                            key={ field.id }
                                            field={ field }
                                            onChange={ onChangeMappingField }
                                        />
                                    ))
                                }
                            </>
                    }
                </Card>
            </div>
        </DndProvider>
    );
};

export default MappingFile;
