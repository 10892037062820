import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import useTranslate from "../../hooks/use-translate.hook";
import { useDateFormat } from "../../hooks/use-date-format.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import history from "../../../history";
import { cssUtils, flex } from "../../ui";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "./timeline.scss";

const OpportunityEvent = ({ event: { user, date, payload: { opportunity } } }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();
    const dateFormat = useDateFormat( t );

    const handleClick = () => history.push( "/opportunities/" + opportunity.id );

    return (
        <TimelineItem
            classes={{ avatar: css.opportunityIcon }}
            icon={ <AttachMoneyIcon/> }
        >
            <TimelineSummary
                user={ user }
                description={
                    user ?
                        t( "timeline:opportunity-created-user" ) :
                        t( "timeline:opportunity-created" )
                }
                date={ date } />

            <Card
                className={ cssUtils.cursorPointer }
                onClick={ handleClick }
            >
                <List>
                    <ListItem>
                        <ListItemText primary={ opportunity.title } />
                    </ListItem>
                    <div className={ flex.container }>
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "opportunity:funnel" ) }
                                secondary={ opportunity.funnelName }
                            />
                        </ListItem>
                        <Divider flexItem orientation="vertical" />
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "opportunity:source" ) }
                                secondary={ opportunity.sourceName }
                            />
                        </ListItem>
                    </div>
                    <div className={ flex.container }>
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "opportunity:price.label" ) }
                                secondary={ priceFormat( opportunity.price ) }
                            />
                        </ListItem>
                        <Divider flexItem orientation="vertical" />
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "opportunity:recurrent-price" ) }
                                secondary={ priceFormat( opportunity.recurrentPrice ) }
                            />
                        </ListItem>
                    </div>
                    <div className={ flex.container }>
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "timeline:opportunity.closing-date" ) }
                                secondary={ opportunity.closingAt ? dateFormat( opportunity.closingAt ) : " - " }
                            />
                        </ListItem>
                        <Divider flexItem orientation="vertical" />
                        <ListItem className={ css.opportunityListItem } dense>
                            <ListItemText
                                primary={ t( "opportunity:user" ) }
                                secondary={ opportunity.userName }
                            />
                        </ListItem>
                    </div>
                </List>
            </Card>
        </TimelineItem>
    );
};

export default OpportunityEvent;