import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../core";
import { fetchCategories } from "../../../product/product.actions";
import { saveProductCategories } from "./product.actions";
import { isLoadingCategories, listCategories } from "../../../product/product.selectors";
import { PRODUCT_CATEGORY_FORM } from "./product.constants";
import ProductCategoriesList from "./product-categories-list.component";

const ProductCategoryForm = ({ newCategory, onChangeNewCategory }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingCategories );
    const productCategories = useSelector( listCategories );

    const [ showErrors, setShowErrors ] = useState( false );

    const closeErrors = () => setShowErrors( true );

    const formik = useForm({
        enableReinitialize: true,
        form: PRODUCT_CATEGORY_FORM,
        initialValues: {
            productCategories,
        },
        onSubmit: values => dispatch( saveProductCategories( values ) ),
        validate: values => {
            const errors = {
                productCategories: [],
            };
            values.productCategories.forEach( ( category, index ) => {
                const error = {};

                if ( !category.name ) {
                    error.name = "common:validation.required";
                }

                if ( !isEmpty( error ) ) {
                    errors.productCategories[ index ] = error;
                }
            });
            if ( isEmpty( errors.productCategories ) ) {
                delete errors.productCategories;
            }
            return errors;
        },
    });

    const prevNewCategory = usePrevious( newCategory );
    const { setFieldValue, values } = formik;

    useEffect( () => {
        dispatch( fetchCategories() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowErrors( true );
        }
    }, [ formik.error ] );
    useEffect( () => {
        if ( newCategory && !prevNewCategory ) {
            setFieldValue( "productCategories", [ ...values.productCategories, {}] );
            onChangeNewCategory();
        }
    }, [ newCategory, prevNewCategory, setFieldValue, values.productCategories, onChangeNewCategory ] );

    return (
        <div>
            <Snackbar
                open={ showErrors }
                action={ <Button onClick={ closeErrors } color="secondary">{ "OK" }</Button>}
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ closeErrors }
            />

            <Card id="productCategoryForm">
                <CardHeader
                    title={ t( "config:product-categories.title" ) }
                    subheader={ t( "config:product-categories.call-to-action" ) }
                />
                {
                    loading ?
                        <LinearProgress/> :
                        <>
                            <CardContent>
                                <Form formik={ formik }>
                                    <FieldArray name="productCategories" component={ ProductCategoriesList } />
                                </Form>
                            </CardContent>
                            <CardActions className={ flex.justifyContentEnd }>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={ formik.submitForm }
                                    disabled={ formik.submitting }
                                >
                                    { t( "common:save" ) }
                                </Button>
                            </CardActions>
                        </>
                }
            </Card>
        </div>
    );
};

export default ProductCategoryForm;