import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CommentIcon from "@mui/icons-material/Comment";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Timeline } from "../ui/timeline";
import { Dialog, SectionTitle, cssUtils, flex } from "../ui";
import {
    ChangeEvent,
    CheckinEvent,
    CreationEvent,
    PhaseEvent,
    StatusEvent,
    TemperatureEvent,
    CommentEvent,
    CustomerEvent,
    SchedulingCreationEvent,
    SchedulingChangeEvent,
    SchedulingStatusEvent,
    ProposalCreatedEvent,
    ProposalChangeEvent,
    ProposalProductsEvent,
    ProposalStatusEvent,
    SmsEvent
} from "./timeline";
import { EmailSentEvent, EmailResponseEvent, PhoneCallEvent, WhatsAppEvent } from "../customer/timeline";
import NewComment from "./new-comment.component";
import * as selectors from "./opportunity.selectors";
import { fetchTimeline, reloadTimeline } from "./opportunity.actions";
import css from "./opportunity.scss";

const eventComponentMappings = {
    OPPORTUNITY_CREATION: CreationEvent,
    OPPORTUNITY_CHANGE: ChangeEvent,
    OPPORTUNITY_CHECKIN: CheckinEvent,
    OPPORTUNITY_PHASE: PhaseEvent,
    OPPORTUNITY_STATUS: StatusEvent,
    OPPORTUNITY_TEMPERATURE: TemperatureEvent,
    OPPORTUNITY_COMMENT: CommentEvent,
    OPPORTUNITY_SCHEDULING_CREATION: SchedulingCreationEvent,
    OPPORTUNITY_SCHEDULING_CHANGE: SchedulingChangeEvent,
    OPPORTUNITY_SCHEDULING_STATUS: SchedulingStatusEvent,
    PROPOSAL_CREATED: ProposalCreatedEvent,
    PROPOSAL_CHANGE: ProposalChangeEvent,
    PROPOSAL_PRODUCTS: ProposalProductsEvent,
    PROPOSAL_STATUS: ProposalStatusEvent,
    COMPANY: CustomerEvent,
    PERSON: CustomerEvent,
    EMAIL_SENT: EmailSentEvent,
    EMAIL_RESPONSE: EmailResponseEvent,
    PHONE_CALL: PhoneCallEvent,
    SMS: SmsEvent,
    WHATSAPP: WhatsAppEvent
};

const filters = [
    { value: "OPPORTUNITY_CHANGE" },
    { value: "OPPORTUNITY_CHECKIN" },
    { value: "OPPORTUNITY_PHASE" },
    { value: "OPPORTUNITY_STATUS" },
    { value: "OPPORTUNITY_TEMPERATURE" },
    { value: "OPPORTUNITY_COMMENT" },
    {
        label: "OPPORTUNITY_SCHEDULING",
        value: [ "OPPORTUNITY_SCHEDULING_CREATION", "OPPORTUNITY_SCHEDULING_CHANGE", "OPPORTUNITY_SCHEDULING_STATUS" ]
    },
    {
        label: "PROPOSALS",
        value: [ "PROPOSAL_CREATED", "PROPOSAL_CHANGE", "PROPOSAL_PRODUCTS", "PROPOSAL_STATUS" ]
    },
    { value: "PERSON" },
    { value: "COMPANY" },
    { label: "EMAIL", value: [ "EMAIL_SENT", "EMAIL_RESPONSE" ] },
    { value: "PHONE_CALL" },
    { value: "SMS" },
    { value: "WHATSAPP" }
];

const OpportunityTimeline = ({ id, opportunityId, page }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunity = useSelector( selectors.getSelected );
    const loading = useSelector( selectors.isLoadingTimeline );
    const events = useSelector( selectors.getTimelineEvents );
    const reload = useSelector( selectors.reloadTimeline );

    const [ showCommentDialog, setShowCommentDialog ] = useState( false );
    const [ showTypes, setShowTypes ] = useState( false );
    const [ newTypes, setNewTypes ] = useState( filters.map( filter => ({ ...filter, checked: false }) ) );
    const [ types, setTypes ] = useState( filters.map( filter => ({ ...filter, checked: false }) ) );

    const hasTypes = useMemo( () => types.some( filter => filter.checked ), [ types ] );

    const toggleCommentDialog = () => setShowCommentDialog( !showCommentDialog );

    const toggleDialogTypes = () => setShowTypes( !showTypes );

    const saveTypes = () => {
        setTypes( newTypes );
        toggleDialogTypes();
    };

    const cancelTypes = () => {
        setNewTypes( types.slice() );
        toggleDialogTypes();
    };

    const toggleType = index => () => {
        const types = newTypes.slice();
        setNewTypes( types.map( ( type, i ) => index === i ? { ...type, checked: !type.checked } : { ...type } ) );
    };

    const loadTimeline = useCallback( () => {
        const selectedTypes = types.filter( type => type.checked ).map( type => type.value );
        dispatch( fetchTimeline({ id: opportunityId, types: selectedTypes, page }) );
    }, [ dispatch, opportunityId, page, types ] );
    const handleReloadTimeline = () => dispatch( reloadTimeline() );

    useEffect( () => {
        loadTimeline();
    }, [ loadTimeline ] );
    useEffect( () => {
        if ( reload ) {
            loadTimeline();
        }
    }, [ reload, loadTimeline ] );

    return (
        <div id={ id }>
            <NewComment
                opportunityId={ opportunityId }
                show={ showCommentDialog }
                onClose={ toggleCommentDialog }
            />

            <Dialog open={ showTypes } fullWidth>
                <DialogTitle>{ "Filtros" }</DialogTitle>
                <DialogContent>
                    <div className={ classnames( flex.container, flex.column )}>
                        {
                            newTypes.map( ( type, index ) => (
                                <FormControlLabel
                                    key={ index }
                                    control={
                                        <Checkbox
                                            checked={ type.checked }
                                            onChange={ toggleType( index ) }
                                        />
                                    }
                                    label={
                                        t( "opportunity:timeline.types." +
                                        ( type.label ? type.label : type.value ) )
                                    }
                                />
                            ))
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ cancelTypes }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ saveTypes }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <SectionTitle
                    className={ flex.fill }
                    icon={ AccessTimeIcon }
                    name={ t( "opportunity:overview.timeline" ) }
                />
                <Button
                    id="opportunityTimelineComment"
                    className={ cssUtils.marginBottom }
                    onClick={ toggleCommentDialog }
                    endIcon={ <CommentIcon/> }
                >
                    { t( "opportunity:new-comment.title" ) }
                </Button>
                <IconButton
                    className={ classnames( hasTypes ? css.hasTimelineTypes : "", cssUtils.marginBottom ) }
                    onClick={ toggleDialogTypes }
                    size="large">
                    <FilterListIcon/>
                </IconButton>
                <IconButton
                    className={ cssUtils.marginBottom }
                    onClick={ handleReloadTimeline }
                    size="large">
                    <RefreshIcon/>
                </IconButton>
            </div>
            {
                !isEmpty( events ) && opportunity ?
                    <Timeline>
                        { ( events || [] ).map( ( event, index ) => {
                            const Component = eventComponentMappings[ event.type ];
                            return <Component key={ index } opportunity={ opportunity } event={ event } />;
                        })}
                    </Timeline> :
                    null
            }

            {
                loading ?
                    <LinearProgress/> :
                    null
            }
        </div>
    );

};

export default OpportunityTimeline;