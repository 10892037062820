import { Routes, Route } from "react-router-dom";

import Funnel from "./funnel/funnel.component";
import Source from "./source/source.component";
import LossReason from "./loss-reason/loss-reason.component";
import SchedulesType from "./schedules-type/schedules-type.component";
import Form from "./form/form.component";
import Template from "./template/template.component";
import Product from "./product/product.component";

const Opportunity = () => {
    return (
        <Routes>
            <Route path={ "funnels" } element={ <Funnel/> } />
            <Route path={ "sources" } element={ <Source/> }/>
            <Route path={ "loss-reasons" } element={ <LossReason/> }/>
            <Route path={ "schedules-type" } element={ <SchedulesType/> }/>
            <Route path={ "forms" } element={ <Form/> } />
            <Route path={ "templates" } element={ <Template/> } />
            <Route path={ "products" } element={ <Product/> } />
        </Routes>
    );
};

export default Opportunity;