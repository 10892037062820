import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input } from "../../../ui";
import { createFileModel, toggleNewFileModel } from "./template.actions";
import { NEW_FILE_MODEL_FORM } from "./template.constants";
import { isNewFileModelShown } from "./template.selectors";

const NewFileModel = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const show = useSelector( isNewFileModelShown );

    const handleClose = () => dispatch( toggleNewFileModel() );

    const formik = useForm({
        form: NEW_FILE_MODEL_FORM,
        initialValues: {
            name: "",
            headerHeight: 3,
            footerHeight: 2,
        },
        onSubmit: values => dispatch( createFileModel( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            return errors;
        },
    });

    return (
        <Dialog open={ show } fullWidth maxWidth="sm">
            <DialogTitle>{ t( "config:files.model.new" ) }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="name"
                        label={ t( "config:files.model.name" ) }
                        component={ Input }
                        required
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewFileModel;