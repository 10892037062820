import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../core";
import { actions as teamActions, selectors as teamSelectors } from "../team";
import { actions as userActions, selectors as userSelectors } from "../user";
import { Dropdown, DropdownMultiple, cssUtils, flex } from "./";
import css from "./users-filter.scss";

const UsersFilter = ({
    defaultActive,
    ComponentRoot,
    className,
    fullWidth,
    margin,
    variant,
    input,
    error,
    required = false,
    showInactive = false,
    whiteSpaceNormal,
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( coreSelectors.getUser );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAllPermitted );

    const loading = loadingTeams || loadingUsers;

    const handleChangeTeam = event => {
        if ( event.target.value ) {
            const teamUsers = users
                .filter( u => !!u.teams.find( item => item.team.id.toString() === event.target.value.toString() ) )
                .map( u => u.id );
            input.users.onChange( teamUsers );
        } else {
            input.users.onChange( [] );
        }

        input.team.onChange( event.target.value );
    };

    const handleChangeUsers = event => {
        input.team.onChange( null );
        input.users.onChange( event && event.target ? event.target.value : [] );
    };

    const setUsers = useMemo( () => input.users.onChange, [ input.users.onChange ] );
    const usersValue = useMemo( () => input.users.value, [ input.users.value ] );

    const prevUsersValue = usePrevious( usersValue );

    useEffect( () => {
        if ( !loading && !teams ) {
            dispatch( teamActions.fetchTeams() );
            dispatch( userActions.fetchUsers() );
        }
    }, [ dispatch, loading, teams ] );
    useEffect( () => {
        if ( defaultActive && isEmpty( prevUsersValue ) && isEmpty( usersValue ) && !isEmpty( users ) ) {
            setUsers( users.filter( user => user.active ).map( user => user.id ) );
        }
    }, [ defaultActive, prevUsersValue, usersValue, setUsers, users ] );

    const userTeams = useMemo( () => get( user, "teams" ), [ user ] );
    const sourceTeams = useMemo( () => {
        if ( !teams ) {
            return [];
        }
        return get( user, "admin" ) ?
            teams.map( team => ({ value: team.id, label: team.name }) ) :
            sortBy(
                userTeams.map( userTeam =>
                    ({ value: userTeam.team.id, label: userTeam.team.name })
                ),
                [ "label" ] );
    }, [ user, userTeams, teams ] );

    const Component = ComponentRoot ? ComponentRoot : Paper;
    const rootProps = Component !== Fragment ? { className: classnames( css.root, className ) } : {};

    const inactiveUsers = users.filter( user => !user.active ).map( user => ({ label: user.name, value: user.id }));
    const activeUsers = users.filter( user => user.active ).map( user => ({ label: user.name, value: user.id }));
    const sourceUsers = showInactive && !isEmpty( inactiveUsers ) ?
        [
            { header: true, label: t( "ui:users-filter.active" ) },
            ...activeUsers,
            { header: true, label: t( "ui:users-filter.inactive" ) },
            ...inactiveUsers,
        ] :
        activeUsers;

    return (
        <Component { ...rootProps }>
            <Dropdown
                label={ t( "ui:users-filter.team" ) }
                fullWidth={ fullWidth }
                margin={ margin }
                className={ flex.fill }
                error={ error }
                value={ input.team.value }
                onChange={ handleChangeTeam }
                source={ sourceTeams }
                disabled={ user.admin ? isEmpty( teams ) : isEmpty( userTeams ) }
                helperText={ isEmpty( teams ) && t( "ui:users-filter.empty-teams" ) }
                required={ required }
                variant={ variant }
                emptyValue
            />
            <DropdownMultiple
                emptyValue
                margin={ margin }
                variant={ variant }
                className={ classnames( !fullWidth && cssUtils.marginLeftSmall, flex.fill ) }
                error={ error }
                required={ required }
                label={ t( "ui:users-filter.users" ) }
                source={ sourceUsers }
                sort={ false }
                value={ input.users.value }
                onChange={ handleChangeUsers }
                whiteSpaceNormal={ whiteSpaceNormal }
            />
        </Component>
    );
};

export default UsersFilter;