import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import isEqual from "lodash/isEqual";
import has from "lodash/has";
import omit from "lodash/omit";
import dayjs from "dayjs";

import { apiv1 } from "../../api/sagas";
import tracker from "../util/tracker.utils";
import { NEW_SCHEDULE_EVENT, DELETE_SCHEDULE_EVENT, EDIT_SCHEDULE_EVENT } from "./schedule.constants";
import * as actions from "./schedule.actions";
import { actions as userActions, selectors as userSelectors } from "../user";

export function *watchSchedule () {
    yield all([
        takeEvery( actions.SAVE_NEW_EVENT, saveNewEvent ),
        takeEvery( actions.DELETE_EVENT, deleteEvent ),
        takeEvery( actions.EDIT_EVENT, editEvent )
    ]);
}

export function *saveNewEvent ({ payload }) {
    yield put( startSubmit( NEW_SCHEDULE_EVENT ) );

    try {
        const scheduling = { ...payload };
        if ( !scheduling.recurrent.active ) {
            scheduling.recurrent = null;
        }
        yield call( apiv1.post, "/schedules", scheduling );
        tracker.logAction( "New Schedule" );
        yield put( stopSubmit( NEW_SCHEDULE_EVENT ) );
        yield put( reset( NEW_SCHEDULE_EVENT ) );
        yield put( userActions.reloadEvents() );
    } catch ( e ) {
        yield put( stopSubmit( NEW_SCHEDULE_EVENT, e.response.data ) );
    }
}

export function *deleteEvent ({ payload }) {
    yield put( startSubmit( DELETE_SCHEDULE_EVENT ) );

    try {
        const { id, mode, recurrent } = payload;
        switch ( mode ) {
            case "ALL":
                yield call( apiv1.delete, `/schedules/recurrent/${recurrent.id}` );
                break;
            case "FUTURE":
                const schedulingRecurrent = {
                    id: recurrent.id,
                    until: dayjs( payload.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                };
                yield call(
                    apiv1.patch,
                    `/schedules/recurrent/${schedulingRecurrent.id}`,
                    schedulingRecurrent
                );
                break;
            default:
                yield call( apiv1.delete, `/schedules/${id}` );
                break;
        }
        yield put( stopSubmit( DELETE_SCHEDULE_EVENT ) );
        yield put( userActions.reloadEvents() );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_SCHEDULE_EVENT, e.response.data ) );
    }
}

export function *editEvent ({ payload }) {
    yield put( startSubmit( EDIT_SCHEDULE_EVENT ) );

    try {
        const event = yield select( userSelectors.getEventSelected );
        const scheduling = { ...payload };

        let response;
        if ( has( scheduling, "recurrent.updateMode" ) ) {
            if ( scheduling.recurrent.active && has( event, "recurrent.id" ) ) {
                // se ta ativo e tinha recorrencia
                if ( !isEqual( omit( scheduling.recurrent, [ "active", "updateMode" ] ), event.recurrent ) ) {
                    // se alterou a recorrencia
                    if ( scheduling.recurrent.updateMode === "ALL" ) {
                        // exclui todos os agendamentos da recorrencia e cria um novo
                        yield call( apiv1.delete, `/schedules/recurrent/${event.recurrent.id}` );
                        scheduling.recurrent.id = null;
                        response = yield call( apiv1.post, "/schedules", scheduling );
                    } else {
                        const schedulingRecurrent = {
                            ...event.recurrent,
                            until: dayjs( scheduling.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                        };
                        // altera a recorrencia e o agendamento
                        yield call(
                            apiv1.patch,
                            `/schedules/recurrent/${schedulingRecurrent.id}`,
                            schedulingRecurrent
                        );
                        scheduling.recurrent.id = null;
                        response = yield call( apiv1.post, "/schedules", scheduling );
                    }
                } else {
                    switch ( scheduling.recurrent.updateMode ) {
                        case "ALL":
                            response = yield call(
                                apiv1.patch,
                                `/schedules/${scheduling.id}/recurrent/all`,
                                scheduling
                            );
                            break;
                        case "FUTURE":
                            response = yield call( apiv1.patch, `/schedules/${scheduling.id}/recurrent`, scheduling );
                            break;
                        default:
                            response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
                            break;
                    }
                }
            } else if ( has( event, "recurrent.id" ) ) {
                // se nao ta ativo mas tinha recorrencia
                if ( scheduling.recurrent.updateMode === "ALL" ) {
                    yield call( apiv1.delete, `/schedules/recurrent/${event.recurrent.id}` );

                    scheduling.recurrent = null;
                    response = yield call( apiv1.post, "/schedules", scheduling );
                } else {
                    const schedulingRecurrent = {
                        ...event.recurrent,
                        until: dayjs( scheduling.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                    };
                    yield call( apiv1.patch, `/schedules/recurrent/${schedulingRecurrent.id}`, schedulingRecurrent );

                    scheduling.recurrent = null;
                    response = yield call( apiv1.post, "/schedules", scheduling );
                }
            } else {
                if ( !scheduling.recurrent.active ) {
                    scheduling.recurrent = null;
                }
                response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
            }
        } else {
            if ( scheduling.recurrent && !scheduling.recurrent.active ) {
                scheduling.recurrent = null;
            }
            response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
        }
        yield put( userActions.selectEvent( userSelectors.adaptEvent( response.data ) ) );
        yield put( stopSubmit( EDIT_SCHEDULE_EVENT ) );
        yield put( userActions.reloadEvents() );
    } catch ( e ) {
        yield put( stopSubmit( EDIT_SCHEDULE_EVENT, e.response.data ) );
    }
}