import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const schedulingOpenColor = "#757575";
const schedulingAccomplishedColor = "#4CAF50";
const schedulingNotAccomplishedColor = "#F44336";

const ProposalsGeneratedByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState();
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo( () => (
        order( ( results || [] ), [ orderBy ], [ orderType.toLowerCase() ] )
    ), [ results, orderBy, orderType ] );

    const chartData = useMemo( () => ({
        title: {
            text: t( "report:proposals-created-by-user.title" )
        },
        exporting: {
            csv: {
                columnHeaderFormatter: function ( item ) {
                    if ( item?.name ) {
                        return item.name;
                    }
                    return t( "report:proposals-created-by-user.title" );
                }
            },
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: t( "report:proposals-created-by-user.open" ),
            data: orderedResults.map( item => item.open ),
            color: schedulingOpenColor
        }, {
            name: t( "report:proposals-created-by-user.accepted" ),
            data: orderedResults.map( item => item.accepted ),
            color: schedulingAccomplishedColor
        }, {
            name: t( "report:proposals-created-by-user.declined" ),
            data: orderedResults.map( item => item.declined ),
            color: schedulingNotAccomplishedColor
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => orderedResults.map( item => ({
        name: item.name + "",
        open: item.open + "",
        accepted: item.accepted + "",
        declined: item.declined + ""
    })), [ orderedResults ] );

    const footer = useMemo( () => ( results || [] ).reduce(( acc, item ) => {
        acc.name = t( "report:proposals-created-by-user.total" );
        acc.open += item.open;
        acc.accepted += item.accepted;
        acc.declined += item.declined;
        return acc;
    }, {
        name: t( "report:proposals-created-by-user.total" ),
        open: 0,
        accepted: 0,
        declined: 0
    }), [ t, results ] );

    const model = {
        name: t( "report:proposals-created-by-user.user" ),
        open: t( "report:proposals-created-by-user.open" ),
        accepted: t( "report:proposals-created-by-user.accepted" ),
        declined: t( "report:proposals-created-by-user.declined" ),
    };

    return (
        <div className={ cssUtils.marginTopSmall }>

            <CardHeader
                title={ t( "report:reports.PROPOSALS_CREATED_BY_USER" ) }
                subheader={ t( "report:proposals-created-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "users" ]}
                    required={[ "users", "funnels" ]}
                    onChange={ handleChange }
                    report={ "PROPOSALS_CREATED_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="proposalsCreatedByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    !loading && results &&
                        <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                            <Table
                                id="proposalsCreatedByUserTable"
                                allowExport
                                model={ model }
                                source={ source }
                                footer={ footer }
                                title={ t( "report:reports.PROPOSALS_CREATED_BY_USER" ) }
                                allowOrder
                                onChangePage={ changeOrder }
                                orderBy={ orderBy }
                                orderType={ orderType }
                            />
                        </div>
                }
            </div>
        </div>
    );
};

export default ProposalsGeneratedByUser;