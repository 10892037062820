import { useEffect, useState } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";

import { InputFile } from "../ui";
import { uploadImage } from "./user.actions";
import { ADD_IMAGE_FORM } from "./user.constants";

const AddImage = () => {
    const dispatch = useDispatch();

    const [ showError, setShowError ] = useState( false );
    const toggleError = () => setShowError( !showError );

    const formik = useForm({
        form: ADD_IMAGE_FORM,
        initialValues: {
            file: null,
        },
        onSubmit: values => dispatch( uploadImage( values ) ),
        onSubmitSuccess: () => setShowError( false ),
    });

    const { submitForm, values } = formik;

    useEffect( () => {
        formik.error && setShowError( true );
    }, [ formik.error ] );
    useEffect( () => {
        values.file && submitForm();
    }, [ values.file, submitForm ] );

    return (
        <Form formik={ formik } encType="multipart/form-data">
            { formik.submitting && <LinearProgress/> }
            <Field
                name="file"
                component={ InputFile }
                accept="image/*"
                disabled={ formik.submitting }
            />
            <Snackbar
                open={ showError }
                action={ <Button onClick={ toggleError } color="secondary">{ "OK" }</Button>}
                message={ formik.error?.error }
                onClose={ toggleError }
            />
        </Form>
    );
};

export default AddImage;