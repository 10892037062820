import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Dropdown } from "../ui";
import { isLoadingBrandModels, getSelectedBrandModels, getSelectedBrand } from "./form.selectors";

const Select = forwardRef( ( props, ref ) => <Dropdown { ...props } innerRef={ ref } /> );

const ModelField = ({ field, form, ...props }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingBrandModels );
    const brand = useSelector( getSelectedBrand );
    const models = useSelector( getSelectedBrandModels );

    const { setFieldValue } = form;
    const prevBrand = usePrevious( brand );

    useEffect( () => {
        if ( brand !== prevBrand && !!prevBrand ) {
            setFieldValue( field.name, null );
        }
    }, [ brand, prevBrand, field.name, setFieldValue ] );

    if ( isEmpty( models ) ) {
        return (
            <Tooltip title={ t( "form:model.empty-brand" ) }>
                <Select
                    { ...omit( props, [ "currentPosition", "formField" ] ) }
                    loading={ loading }
                    source={ [] }
                    disabled
                    emptyValue
                />
            </Tooltip>
        );
    }

    return (
        <Dropdown
            { ...omit( props, [ "currentPosition", "formField" ] ) }
            field={ field }
            form={ form }
            loading={ loading }
            source={ models.map( model => ({ label: model, value: model }) ) }
            emptyValue
        />
    );
};

export default ModelField;