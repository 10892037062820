import { Fragment, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import useTranslate from "../hooks/use-translate.hook";
import { Autocomplete, cssUtils, flex } from "../ui";
import { selectors as formSelectors, Fields } from "../form";
import { phoneFormat } from "../core/formatter.utils";
import { fetchSearchCustomers } from "./customer.actions";
import { isLoadingSearch, getSearchCustomers } from "./customer.selectors";

const CustomerWizard = ({ suggestions, suggestionsTitle, type, name, resetForm, setFieldValue }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( type ) );
    const emailFields = useSelector( formSelectors.listEmailFields( type ) );
    const phoneFields = useSelector( formSelectors.listPhoneFields( type ) );
    const customers = useSelector( getSearchCustomers );
    const searching = useSelector( isLoadingSearch );

    const [ showCustomerForm, setShowCustomerForm ] = useState( false );
    const [ suggestionSelected, setSuggestionSelected ] = useState( "" );

    const searchCustomer = query => {
        if ( !query || query.length < 3 ) {
            return;
        }
        setSuggestionSelected( "" );
        dispatch( fetchSearchCustomers({ query, type }) );
    };

    const getCustomerInformations = useCallback( customer => {
        const informations = [];

        emailFields.filter( field => !isEmpty( customer.fields[ field.id ] ) )
            .forEach( field => {
                const value = customer.fields[ field.id ];
                value.forEach( email => informations.push( email ) );
            });

        phoneFields.filter( field => !isEmpty( customer.fields[ field.id ] ) )
            .forEach( field => {
                const value = customer.fields[ field.id ];
                value.map( value => phoneFormat( value ) )
                    .forEach( phone => informations.push( phone ) );
            });

        return informations.join(", ");
    }, [ emailFields, phoneFields ] );

    const toggleCustomerForm = () => {
        const show = !showCustomerForm;
        setFieldValue( `${name}.new`, show );
        setFieldValue( name, { fields: {}, new: show } );
        if ( show ) {
            setFieldValue( `${name}.type`, type );
        } else {
            setFieldValue( `${name}.type`, null );
        }
        setShowCustomerForm( show );
        !show && resetForm();
    };

    const handleChange = customer => {
        setFieldValue( name, customer );
        setSuggestionSelected( customer );
    };

    const sourceCustomers = useMemo( () => {
        let source;
        if ( suggestionSelected ) {
            source = [].concat( suggestionSelected );
        } else {
            source = ( customers || [] ).slice();
        }
        if ( source.length > 5 ) {
            source = source.slice( 0, 5 );
        }

        return source.reduce( ( model, customer ) => ({
            ...model,
            [ customer.id ]: {
                value: customer.id,
                label: customer.name,
                helper: getCustomerInformations( customer )
            }
        }), {} );
    }, [ customers, suggestionSelected, getCustomerInformations ] );

    return (
        <Fragment>
            {
                !showCustomerForm &&
                    <Fragment>
                        {
                            !isEmpty( suggestions ) &&
                                <List subheader={ <ListSubheader>{ suggestionsTitle }</ListSubheader>} >
                                    {
                                        suggestions.map( customer => (
                                            <ListItemButton
                                                key={ customer.id }
                                                onClick={ () => handleChange( customer ) }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar src={ customer.avatar }>
                                                        {
                                                            !customer.avatar &&
                                                                customer.name.charAt( 0 ).toUpperCase()
                                                        }
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={ customer.name }
                                                    secondary={ getCustomerInformations( customer ) }
                                                />
                                            </ListItemButton>
                                        ))
                                    }
                                </List>
                        }
                        <div
                            className={ classnames(
                                cssUtils.marginLeft,
                                cssUtils.marginRight,
                                flex.container,
                                flex.alignItemsCenter
                            )}
                        >
                            <SearchIcon className={ cssUtils.marginRightSmall }/>
                            <Field
                                name={ `${name}.id` }
                                component={ Autocomplete }
                                className={ flex.fill }
                                multiple={ false }
                                placeholder={ t( "opportunity:search-customer" ) }
                                source={ sourceCustomers }
                                onQueryChange={ searchCustomer }
                                getOptionLabel={ option =>
                                    isObject( option ) ?
                                        sourceCustomers[ option.value ].label :
                                        option ? sourceCustomers[ option ].label : ""
                                }
                                filterOptions={ options => options }
                                loading={ searching }
                                autoFocus
                            />
                        </div>
                        <Button
                            className={ cssUtils.marginLeft }
                            onClick={ toggleCustomerForm }
                            color="primary"
                        >
                            { t( "opportunity:new-opportunity.new-customer" ) }
                        </Button>
                    </Fragment>
            }

            {
                showCustomerForm &&
                    <div
                        className={ classnames(
                            cssUtils.marginLeftSmall,
                            cssUtils.marginRightSmall
                        )}
                    >
                        <div
                            className={ classnames(
                                cssUtils.marginLeftSmall,
                                cssUtils.marginRightSmall,
                                flex.container,
                                flex.alignItemsCenter
                            )}
                        >
                            <Button
                                onClick={ toggleCustomerForm }
                                color="primary"
                                startIcon={ <KeyboardBackspaceIcon/> }
                            >
                                { t( "opportunity:new-opportunity.search" ) }
                            </Button>
                        </div>
                        <Fields name={ name } fields={ fields } />
                    </div>
            }
        </Fragment>
    );
};

export default CustomerWizard;