import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./goal.actions";

export function *watchGoal () {
    yield all([
        takeEvery( actions.FETCH_GOALS, fetchGoals ),
        takeEvery( actions.FETCH_GOALS_PROGRESS, fetchGoalsProgress ),
    ]);
}

function *fetchGoals () {
    yield put( actions.requestGoals() );
    try {
        const response = yield call( apiv1.get, "/goals" );
        yield put( actions.receiveGoals( response.data ) );
    } catch ( e ) {
        yield put( actions.errorGoals( e.response.data ) );
    }
}

function *fetchGoalsProgress ({ meta: { params } }) {
    yield put( actions.requestGoalProgress() );
    try {
        const response = yield call( apiv1.get, "/goals/progress", { params } );
        yield put( actions.receiveGoalsProgress( response.data ) );
    } catch ( e ) {
        yield put( actions.errorGoalsProgress( e.response.data ) );
    }
}