import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog } from "../ui";
import { DELETE_CUSTOMER_FORM } from "./customer.constants";
import { deleteCustomer } from "./customer.actions";

const CustomerDelete = ({ className, customerId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirmDelete, setConfirmDelete ] = useState( false );
    const [ showError, setShowError ] = useState( false );
    const [ menu, setMenu ] = useState( null );

    const { error, submitForm, submitting } = useForm({
        form: DELETE_CUSTOMER_FORM,
        enableReinitialize: true,
        initialValues: {
            id: customerId,
        },
        onSubmit: values => dispatch( deleteCustomer( values ) ),
    });

    const toggleConfirmDelete = () => {
        setConfirmDelete( !confirmDelete );
        setMenu( null );
    };
    const toggleError = () => setShowError( !showError );

    const openMenu = e => setMenu( e.currentTarget );
    const closeMenu = () => setMenu( null );

    useEffect( () => {
        if ( error ) {
            setShowError( true );
            setConfirmDelete( false );
        }
    }, [ error ] );

    return (
        <>
            <Dialog
                open={ confirmDelete }
                onClose={ toggleConfirmDelete }
            >
                <DialogContent>
                    <DialogContentText>{ t( "customer:confirm-delete-customer" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmDelete } disabled={ submitting }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ submitForm } disabled={ submitting }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showError }
                onClose={ toggleError }
            >
                <DialogContent>
                    <DialogContentText>{ error }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleError }>
                        { t( "common:ok" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <IconButton onClick={ openMenu } size="small" className={ className }>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                open={ !!menu }
                onClose={ closeMenu }
                anchorEl={ menu }
            >
                <MenuItem onClick={ toggleConfirmDelete }>
                    <ListItemIcon><DeleteIcon/></ListItemIcon>
                    <ListItemText primary={ t( "customer:delete-customer" ) }/>
                </MenuItem>
            </Menu>
        </>
    );
};

export default CustomerDelete;