import { Field } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import classnames from "classnames";
import throttle from "lodash/throttle";

import useTranslate from "../hooks/use-translate.hook";
import { actions as customerActions, selectors as customerSelectors } from "../customer";
import { Autocomplete, Checkbox, flex } from "../ui";
import css from "./importation.scss";

const CustomerConfig = ({ form }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customerTags = useSelector( customerSelectors.getTags );

    const searchTags = throttle( value => {
        if ( !value ) {
            return;
        }
        dispatch( customerActions.fetchTags( value ) );
    }, 200 );

    const { setFieldValue } = form;

    useEffect( () => {
        setFieldValue( "clearData", form.values.mergeData );
    }, [ setFieldValue, form.values.mergeData ] );

    return (
        <>
            <Field
                name="company"
                type="checkbox"
                label={ t( "importation:new-importation.customer-company" ) }
                component={ Checkbox }
            />

            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Field
                    name="person"
                    type="checkbox"
                    label={ t( "importation:new-importation.customer-person" ) }
                    component={ Checkbox }
                    fullWidth={ false }
                />
                {
                    form.values.person && form.values.company &&
                        <Tooltip title={ t( "importation:new-importation.customer-help" ) }>
                            <HelpIcon className={ css.helpIcon }/>
                        </Tooltip>
                }
            </div>

            {
                !form.values.person && !form.values.company &&
                    <Typography color="error" variant="caption">
                        {
                            t( "importation:new-importation.select-customer" )
                        }
                    </Typography>
            }

            <Field
                name="mergeData"
                type="checkbox"
                label={ t( "importation:importation.merge-data" ) }
                component={ Checkbox }
            />

            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Field
                    name="clearData"
                    type="checkbox"
                    label={ t( "importation:importation.clear-data" ) }
                    component={ Checkbox }
                    fullWidth={ false }
                    disabled={ !form.values.mergeData }
                />
                <Tooltip title={ t( "importation:importation.clear-data-help" ) }>
                    <HelpIcon className={ css.helpIcon }/>
                </Tooltip>
            </div>

            <div id="tags">
                <Field
                    name="tags"
                    label={ t( "importation:importation.tags" ) }
                    component={ Autocomplete }
                    allowCreate
                    source={ form.values.tags.concat( customerTags ) }
                    onQueryChange={ searchTags }
                />
            </div>
        </>
    );
};

export default CustomerConfig;