import { Field } from "formik";

import useTranslate from "../../hooks/use-translate.hook";
import { Input } from "../../ui";

const SearchCustomerFields = ({ name, customerType }) => {
    const t = useTranslate();

    return (
        <>
            <Field
                name={ `${name}.name` }
                label={ t("common:name") }
                component={ Input }
            />
            <Field
                name={ `${name}.document` }
                label={ customerType === "PERSON" ? t("common:ssn") : t("common:ein") }
                component={ Input }
            />
            <Field
                name={ `${name}.emails` }
                label={ t("common:email") }
                component={ Input }
            />
            <Field
                name={ `${name}.phones` }
                label={ t("common:phone") }
                component={ Input }
            />
        </>
    );
};

export default SearchCustomerFields;