import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { Link } from "../../ui";
import CustomerSummary from "../customer-summary.component";
import customerCss from "../customer.scss";
import AddCompany from "./add-company.component";
import DeleteCompany from "./delete-company.component";
import css from "./company.scss";

const CustomerCompany = ({ alignPopupLeft, customer }) => {
    const t = useTranslate();

    return (
        <Card>
            <CardHeader
                className={ classnames( css.companyHeader, customerCss.headerCompany )}
                classes={{
                    content: customerCss.headerContent
                }}
                title={ customer.company && customer.company.name }
                subheaderTypographyProps={{ variant: customer.company ? "body2" : "body1" }}
                titleTypographyProps={{ variant: "subtitle1" }}
                subheader={ t( "customer:company.title" ) }
                action={
                    customer.company ?
                        <DeleteCompany customer={ customer }/> :
                        <AddCompany customer={ customer }/>
                }
            />

            {
                customer.company &&
                    <>
                        <CustomerSummary
                            customer={ customer.company }
                            alignPopupLeft={ alignPopupLeft }
                        />
                        <Link href={ `/customers/${customer.company.id}` }>
                            <Button className={ css.accessButton } variant="contained" color="primary" size="small">
                                { t( "common:access" ) }
                            </Button>
                        </Link>
                    </>
            }
        </Card>
    );
};

export default CustomerCompany;