import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import history from "../../../../history";
import { apiv1 } from "../../../../api/sagas";
import { actions as teamActions } from "../../../team";
import { createDeleteTeamForm } from "../user/user.utils";
import { TEAM_FORM } from "./team.constants";
import * as actions from "./team.actions";

export function *saveTeam ({ payload }) {
    const team = payload;
    yield put( startSubmit( TEAM_FORM ) );

    try {
        let response;
        if ( team.id ) {
            response = yield call( apiv1.patch, "/teams/" + team.id, team );
        } else {
            response = yield call( apiv1.post, `/teams`, team );
        }

        yield put( teamActions.fetchTeams() );
        yield put( actions.fetchUsers( response.data.id ) );
        yield put( stopSubmit( TEAM_FORM ) );
        yield call( [ history, history.push ], "/config/users/team" );
    } catch ( e ) {
        yield put( stopSubmit( TEAM_FORM, e.response.data ) );
    }
}

export function *fetchUsers ({ meta: { id } }) {
    yield put( actions.requestUsers( id ) );

    try {
        const response = yield call( apiv1.get, `/teams/${id}/users` );
        yield put( actions.receiveUsers( id, response.data ) );
    } catch ( e ) {
        yield put( actions.errorUsers( id, e.response.data ) );
    }
}

export function *deleteTeam ({ meta: { id } }) {
    const form = createDeleteTeamForm( id );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, `/teams/${id}` );
        yield put( teamActions.fetchTeams() );
        yield put( stopSubmit( form ) );

    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *watchConfigTeam () {
    yield all([
        takeEvery( actions.FETCH_USERS, fetchUsers ),
        takeEvery( actions.SAVE_TEAM, saveTeam ),
        takeEvery( actions.DELETE_TEAM, deleteTeam )
    ]);
}