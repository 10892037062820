import { useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import set from "lodash/set";

import useTranslate from "../hooks/use-translate.hook";

const Input = ({
    className,
    icon,
    autoComplete = "off",
    error: propError,
    maxLength,
    field,
    form: { getFieldMeta } = {},
    onBlur,
    onChange,
    type = "text",
    ...props
}) => {
    const t = useTranslate();

    const formatText = useCallback( e => {
        let newEvent = Object.assign( e );
        if ( e.target.value && !type.match( "password|number" ) ) {
            const newValue = e.target.value.trim();

            if ( newValue !== e.target.value ) {
                newEvent = set( newEvent, "target.value", newValue );
                if ( field ) {
                    field.onChange( newEvent );
                } else {
                    onChange( newEvent );
                }
            }
        }
        e.preventDefault();
        onBlur && onBlur( newEvent );
        field && field.onBlur( newEvent );
    }, [ field, onBlur, onChange, type ] );

    const value = useMemo( () => {
        return ( field ? field.value : props.value ) || "";
    }, [ field, props.value ] );

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field, getFieldMeta ] );

    const inputProps = useMemo( () => {
        const inputProps = {};
        if ( icon ) {
            inputProps.startAdornment = <InputAdornment position="start">{ icon }</InputAdornment>;
        }
        return inputProps;
    }, [ icon ] );

    const helperText = useMemo( () => {
        if ( maxLength ) {
            return t( "ui:input.length", {
                size: "".concat( value || "" ).length,
                total: maxLength
            });
        }
        return validationMsg && t( validationMsg );
    }, [ t, value, maxLength, validationMsg ] );

    return (
        <TextField
            className={ className }
            autoComplete={ autoComplete }
            fullWidth
            error={ !!validationMsg || propError }
            helperText={ helperText }
            InputProps={ { inputProps: { maxLength }, ...inputProps } }
            onChange={ onChange }
            { ...field }
            onBlur={ formatText }
            { ...props }
            type={ type }
            value={ value }
        />
    );
};

export default Input;