export const FETCH_FORM = "x/FETCH_FORM";
export const fetchForm = form => ({
    type: FETCH_FORM,
    meta: { form }
});

export const REQUEST_FORM = "x/REQUEST_FORM";
export const requestForm = form => ({
    type: REQUEST_FORM,
    meta: { form }
});

export const RECEIVE_FORM = "x/RECEIVE_FORM";
export const receiveForm = ( form, fields ) => ({
    type: RECEIVE_FORM,
    meta: { form },
    payload: fields
});

export const ERROR_FORM = "x/ERROR_FORM";
export const errorForm = ( form, error ) => ({
    type: ERROR_FORM,
    meta: { form },
    payload: error,
    error: true
});

export const FETCH_FORM_FILTERS = "x/FETCH_FORM_FILTERS";
export const fetchFormFilters = form => ({
    type: FETCH_FORM_FILTERS,
    meta: { form }
});

export const REQUEST_FORM_FILTERS = "x/REQUEST_FORM_FILTERS";
export const requestFormFilters = form => ({
    type: REQUEST_FORM_FILTERS,
    meta: { form }
});

export const RECEIVE_FORM_FILTERS = "x/RECEIVE_FORM_FILTERS";
export const receiveFormFilters = ( form, fields ) => ({
    type: RECEIVE_FORM_FILTERS,
    meta: { form },
    payload: fields
});

export const ERROR_FORM_FILTERS = "x/ERROR_FORM_FILTERS";
export const errorFormFilters = ( form, error ) => ({
    type: ERROR_FORM_FILTERS,
    meta: { form },
    payload: error,
    error: true
});

export const SET_BRAND = "x/SET_FIELD_BRAND";
export const setBrand = value => ({
    type: SET_BRAND,
    payload: value
});

export const REQUEST_BRAND_MODELS = "x/REQUEST_FIELD_BRAND_MODELS";
export const requestBrandModels = () => ({
    type: REQUEST_BRAND_MODELS
});

export const SET_BRAND_MODELS = "x/SET_FIELD_BRAND_MODELS";
export const setBrandModels = models => ({
    type: SET_BRAND_MODELS,
    payload: models
});