import { useForm } from "formik-redux";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import get from "lodash/get";
import has from "lodash/has";

import useTranslate from "../hooks/use-translate.hook";
import { EDIT_SCHEDULE_EVENT } from "./schedule.constants";
import { editEvent } from "./schedule.actions";
import EventForm, { validate } from "./schedule-event-form.component";
import { Dialog } from "../ui";

const getNotifyMinutes = event => {
    if ( !event ) {
        return 0;
    }
    const { notifyAt, startDate } = event;

    if ( notifyAt ) {
        return Math.round( ( new Date( startDate ).getTime() - new Date( notifyAt ).getTime() ) / 60000 ).toString();
    }
    return 0;
};

const ScheduleEventEdit = ({ event, show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const initialValues = useMemo( () => {
        const recurrent = {
            active: has( event, "recurrent.id" ),
            type: null,
            until: null,
            ...( get( event, "recurrent" ) || {} )
        };
        if ( recurrent.active ) {
            recurrent.updateMode = "SINGLE";
        }
        return ({
            ...event,
            recurrent,
            notify: !!get( event, "notifyAt" ),
            notifyMinutes: getNotifyMinutes( event )
        });
    }, [ event ] );

    const formik = useForm({
        form: EDIT_SCHEDULE_EVENT,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( editEvent( values ) ),
        validate,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    if ( !show ) {
        return null;
    }

    return (
        <Dialog
            open
            onClose={ handleClose }
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <EventForm event={ event } formik={ formik } />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleEventEdit;