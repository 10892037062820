import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectors as templateSelectors } from "../template";
import TemplateEditorEmail from "./template-editor-email.component";
import TemplateEmailSms from "./template-editor-sms.component";

const TemplateEditor = ({ templateType = "", ...props }) => {
    const systemTemplates = useSelector( templateSelectors.getSystemTemplates );
    const myTemplates = useSelector( templateSelectors.getMyTemplates );
    const sharedTemplates = useSelector( templateSelectors.getSharedTemplates );

    const templates = useMemo( () => [
        ...systemTemplates
            .filter( template => template.type === templateType )
            .map( template => ({ ...template, system: true }) ),
        ...myTemplates
            .filter( template => template.type === templateType )
            .map( template => ({ ...template, my: true })),
        ...sharedTemplates
            .filter( template => template.type === templateType )
            .map( template => ({ ...template, shared: true })),
    ], [ templateType, systemTemplates, myTemplates, sharedTemplates ] );

    const Component = useMemo( () => {
        return templateType === "SMS" || templateType === "WHATSAPP" ? TemplateEmailSms : TemplateEditorEmail;
    }, [ templateType ] );

    return <Component templateType={ templateType } templates={ templates } { ...props } />;
};

export default TemplateEditor;