import { useSelector } from "react-redux";

import { selectors } from "../../../phase";
import { StepArrow, StepperArrow } from "../../../ui/stepper-arrow";
import { cssUtils } from "../../../ui";

const PhaseSteps = ({ funnelId }) => {
    const phases = useSelector( selectors.listWithFunnel( funnelId ) );

    return (
        <StepperArrow selected={ -1 } className={ cssUtils.margin0 }>
            { phases?.map( phase =>
                <StepArrow
                    key={ phase.id }
                    text={ phase.name }
                    showTitle
                />
            )}
        </StepperArrow>
    );
};

export default PhaseSteps;