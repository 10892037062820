import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { createDeleteTeamForm } from "../user/user.utils";
import { deleteTeam } from "./team.actions";

const DeleteTeam = ({ team, showDeleteError }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openConfirm, setOpenConfirm ] = useState( false );

    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const formik = useForm({
        form: createDeleteTeamForm( team.id ),
        initialValues: {
            id: team.id,
        },
        onSubmit: values => dispatch( deleteTeam( values ) ),
    });

    useEffect( () => {
        if ( formik.error ) {
            showDeleteError( formik.error );
        }
    }, [ formik.error, showDeleteError ] );

    return (
        <>
            <IconButton onClick={ toggleConfirm } size="large">
                <DeleteIcon/>
            </IconButton>
            <DialogConfirmForm
                open={ openConfirm }
                handleSubmit={ formik.handleSubmit }
                message={ t( "common:delete-confirm" ) }
                onClose={ toggleConfirm }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
            />
        </>
    );
};

export default DeleteTeam;
