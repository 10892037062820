export const FETCH_HOLDING = "x/config/advanced/holding/FETCH_HOLDING";
export const fetchHolding = () => ({
    type: FETCH_HOLDING
});

export const REQUEST_HODLING = "x/config/advanced/holding/REQUEST_HOLDING";
export const requestHolding = () => ({
    type: REQUEST_HODLING
});

export const RECEIVE_HOLDING = "x/config/advanced/holding/RECEIVE_HOLDING";
export const receiveHolding = holding => ({
    type: RECEIVE_HOLDING,
    payload: holding
});

export const ERROR_HOLDING = "x/config/advanced/holding/ERROR_HOLDING";
export const errorHolding = error => ({
    type: ERROR_HOLDING,
    payload: error,
    error: true
});

export const FETCH_HOLDING_FUNNELS = "x/config/advanced/holding/FETCH_HOLDING_FUNNELS";
export const fetchHoldingFunnels = token => ({
    type: FETCH_HOLDING_FUNNELS,
    meta: { token }
});

export const REQUEST_HOLDING_FUNNELS = "x/config/advanced/holding/REQUEST_HOLDING/FUNNELS";
export const requestHoldingFunnels = () => ({
    type: REQUEST_HOLDING_FUNNELS
});

export const RECEIVE_HOLDING_FUNNELS = "x/config/advanced/holding/RECEIVE_HOLDING_FUNNELS";
export const receiveHoldingFunnels = data => ({
    type: RECEIVE_HOLDING_FUNNELS,
    payload: data
});

export const ERROR_HOLDING_FUNNELS = "x/config/advanced/holding/ERROR_HOLDING_FUNNELS";
export const errorHoldingFunnels = error => ({
    type: ERROR_HOLDING_FUNNELS,
    payload: error,
    error: true
});

export const CREATE_HOLDING_FUNNEL = "x/config/advanced/holding/CREATE_HOLDING_FUNNEL";
export const createHoldingFunnel = data => ({
    type: CREATE_HOLDING_FUNNEL,
    payload: data
});

export const DELETE_HOLDING_FUNNEL = "x/config/advanced/holding/DELETE_HOLDING_FUNNEL";
export const deleteHoldingFunnel = funnelId => ({
    type: DELETE_HOLDING_FUNNEL,
    meta: { funnelId }
});

export const SAVE_HOLDING_FUNNEL = "x/config/advanced/holding/SAVE_HOLDING_FUNNEL";
export const saveHoldingFunnel = ({ funnel, ...data }) => ({
    type: SAVE_HOLDING_FUNNEL,
    meta: { funnelId: funnel.id },
    payload: data
});

export const ADD_SUBSCRIPTION_HOLDING = "x/config/advanced/holding/ADD_SUBSCRIPTION_HOLDING";
export const addSubscriptionHolding = data => ({
    type: ADD_SUBSCRIPTION_HOLDING,
    payload: data
});

export const DELETE_SUBSCRIPTION_HOLDING = "x/config/advanced/holding/DELETE_SUBSCRIPTION_HOLDING";
export const deleteSubscriptionHolding = token => ({
    type: DELETE_SUBSCRIPTION_HOLDING,
    meta: { token }
});