import { combineReducers } from "redux";

import * as apiKeyActions from "./api-key.actions";

export const apiKeys = ( state = {}, action ) => {
    switch ( action.type ) {
        case apiKeyActions.REQUEST_API_KEYS:
            return Object.assign( {}, state, {
                loading: true,
                data: undefined
            });
        case apiKeyActions.RECEIVE_API_KEYS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload
            });
        case apiKeyActions.ERROR_API_KEYS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload
            });
        default:
            return state;
    }
};

export const apiKeyIntegration = combineReducers({
    apiKeys
});