import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1, app } from "../../../../api/sagas";
import * as actions from "./facebook-integration.actions";
import { createDeleteFacebookLeadPageForm } from "./facebook-integration.utils";
import {
    FACEBOOK_LEAD_DISTRIBUTION_FORM,
    FACEBOOK_LEAD_PAGE_FORM,
    NEW_FACEBOOK_LEAD_PAGE_FORM,
    EDIT_FACEBOOK_LEAD_PAGE_FORM
} from "./facebook-integration.constants";

export function *watchIntegrationsFacebook () {
    yield all([
        takeEvery( actions.FETCH_FACEBOOK_LEAD_DISTRIBUTION, fetchFacebookLeadDistribution ),
        takeEvery( actions.SAVE_FACEBOOK_LEAD_DISTRIBUTION, saveFacebookLeadDistribution ),
        takeEvery( actions.FETCH_FACEBOOK_LEAD_PAGES, fetchFacebookLeadPages ),
        takeEvery( actions.CREATE_FACEBOOK_LEAD_PAGE, createFacebookLeadPage ),
        takeEvery( actions.UPDATE_FACEBOOK_LEAD_PAGE, updateFacebookLeadPage ),
        takeEvery( actions.SAVE_FACEBOOK_LEAD_PAGES, saveFacebookLeadPages ),
        takeEvery( actions.EDIT_FACEBOOK_LEAD_PAGE, editFacebookLeadPage ),
        takeEvery( actions.DELETE_FACEBOOK_LEAD_PAGE, deleteFacebookLeadPage ),
        takeEvery( actions.FETCH_FACEBOOK_TOKEN, fetchFacebookToken ),
        takeEvery( actions.FETCH_FACEBOOK_ACCOUNT_PAGES, fetchFacebookAccountPages ),
        takeEvery( actions.FETCH_FACEBOOK_PAGE_LEADGEN_FORMS, fetchFacebookPageLeadgenForms ),
        takeEvery( actions.FETCH_FACEBOOK_PAGE, fetchFacebookPage )
    ]);
}

export function *fetchFacebookLeadDistribution () {
    yield put( actions.requestFacebookLeadDistribution() );

    try {
        const response = yield call( apiv1.get, "/facebook/leads/distribution" );
        yield put( actions.receiveFacebookLeadDistribution( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFacebookLeadDistribution( e.response.data ) );
    }
}

export function *saveFacebookLeadDistribution ({ payload }) {
    yield put( startSubmit( FACEBOOK_LEAD_DISTRIBUTION_FORM ) );

    try {
        const response = yield call( apiv1.put, "/facebook/leads/distribution", payload );
        yield put( actions.receiveFacebookLeadDistribution( response.data ) );
        yield put( stopSubmit( FACEBOOK_LEAD_DISTRIBUTION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( FACEBOOK_LEAD_DISTRIBUTION_FORM, e.response.data ) );
    }
}

export function *fetchFacebookLeadPages () {
    yield put( actions.requestFacebookLeadPages() );

    try {
        const response = yield call( apiv1.get, "/facebook/leads/pages" );
        yield put( actions.receiveFacebookLeadPages( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFacebookLeadPages( e.response.data ) );
    }
}

export function *saveFacebookLeadPages ({ payload }) {
    yield put( startSubmit( FACEBOOK_LEAD_PAGE_FORM ) );

    try {
        /* eslint-disable no-unused-vars */
        for ( const [ index, page ] of payload.entries() ) {
            const params = { accessToken: page.access_token };
            yield call( apiv1.post, "/facebook/leads/pages", page, { params } );
        }
        yield put( actions.fetchFacebookLeadPages() );
        yield put( stopSubmit( FACEBOOK_LEAD_PAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( FACEBOOK_LEAD_PAGE_FORM, e.response.data ) );
    }
}

export function *createFacebookLeadPage ({ payload }) {
    yield put( startSubmit( NEW_FACEBOOK_LEAD_PAGE_FORM ) );

    try {
        yield call( apiv1.post, "/facebook/leads/pages", payload );
        yield put( actions.fetchFacebookLeadPages() );
        yield put( stopSubmit( NEW_FACEBOOK_LEAD_PAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_FACEBOOK_LEAD_PAGE_FORM, e.response.data ) );
    }
}

export function *updateFacebookLeadPage ({ meta: { form, id }, payload }) {
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.patch, `/facebook/leads/pages/${id}`, payload );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchFacebookLeadPages() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *editFacebookLeadPage ({ meta: { id }, payload }) {
    yield put( startSubmit( EDIT_FACEBOOK_LEAD_PAGE_FORM ) );

    try {
        yield call( apiv1.patch, `/facebook/leads/pages/${id}`, payload );
        yield put( actions.fetchFacebookLeadPages() );
        yield put( stopSubmit( EDIT_FACEBOOK_LEAD_PAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( EDIT_FACEBOOK_LEAD_PAGE_FORM, e.response.data ) );
    }
}

export function *deleteFacebookLeadPage ({ meta: { id } }) {
    const form = createDeleteFacebookLeadPageForm( id );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, `/facebook/leads/pages/${id}` );
        yield put( actions.fetchFacebookLeadPages() );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

function *fetchFacebookToken ({ payload }) {
    yield put( actions.requestFacebookToken() );

    try {
        const response = yield call( app.post, "/facebook/token", payload );
        const data = {
            shortLivedToken: response.data.access_token,
        };

        const responseLongLived = yield call( app.post, "/facebook/token/long-lived", data );
        yield put( actions.setFacebookToken( responseLongLived.data.access_token ) );
    } catch ( e ) {
        yield put( actions.errorFacebookToken( e.response.data ) );
    }
}

function *verifyFacebookAccessToken ( accessToken ) {
    const headers = { Authorization: `Bearer ${accessToken}` };

    try {
        const response = yield call( app.get, "/facebook/token/verify", { headers } );
        const { isValid } = response.data;
        if ( !isValid ) {
            window.location.href = "/facebook/auth";
        }
    } catch ( e ) {
        window.location.href = "/facebook/auth";
    }
}

function *fetchFacebookAccountPages ({ payload }) {
    const { accessToken, fields } = payload;
    yield put( actions.requestFacebookAccountPages() );

    const headers = { Authorization: `Bearer ${accessToken}` };

    yield call( verifyFacebookAccessToken, accessToken );

    const params = {
        fields: fields.join( "," ),
    };

    try {
        const response = yield call( app.get, "/facebook/me/pages", { headers, params } );
        yield put( actions.receiveFacebookAccountPages( response.data.data ) );
    } catch ( e ) {
        yield put( actions.errorFacebookAccountPages( e.response.data ) );
    }
}

function *fetchFacebookPageLeadgenForms ({ payload: { accessToken, pageId, fields } }) {
    yield put( actions.requestFacebookPageLeadgenForms() );

    const headers = { Authorization: `Bearer ${accessToken}` };

    const params = {
        fields: fields.join( "," ),
    };

    try {
        const response = yield call( app.get, `/facebook/me/pages/${pageId}/leadgen_forms`, { headers, params } );
        yield put( actions.receiveFacebookPageLeadgenForms( response.data.data ) );
    } catch ( e ) {
        yield put( actions.errorFacebookPageLeadgenForms( e.response.data ) );
    }
}


function *fetchFacebookPage ({ payload: { accessToken, pageId, fields } }) {
    yield put( actions.requestFacebookPage() );

    const headers = { Authorization: `Bearer ${accessToken}` };

    const params = {
        fields: fields.join( "," ),
    };

    yield call( verifyFacebookAccessToken, accessToken );

    try {
        const response = yield call( app.get, `/facebook/me/pages/${pageId}`, { params, headers } );
        yield put( actions.receiveFacebookPage( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFacebookPage( e.response.data ) );
    }
}