import * as actions from "./form.actions";

export function fields ( state = {}, action ) {
    switch ( action.type ) {
        case actions.SET_BRAND:
            return {
                ...state,
                brand: action.payload,
                brandModels: []
            };
        case actions.REQUEST_BRAND_MODELS:
            return {
                ...state,
                brandModels: {
                    loading: true
                }
            };
        case actions.SET_BRAND_MODELS:
            return {
                ...state,
                brandModels: {
                    loading: false,
                    data: action.payload
                }
            };
        case actions.REQUEST_FORM:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: true,
                    data: state[ action.meta.form.toUpperCase() ] &&
                        state[ action.meta.form.toUpperCase() ].data
                }
            };

        case actions.RECEIVE_FORM:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: false,
                    data: action.payload
                }
            };

        case actions.ERROR_FORM:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: false,
                    error: action.payload
                }
            };

        default:
            return state;
    }
}

export function filters ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_FORM_FILTERS:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: true
                }
            };

        case actions.RECEIVE_FORM_FILTERS:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: false,
                    data: action.payload
                }
            };

        case actions.ERROR_FORM_FILTERS:
            return {
                ...state,
                [ action.meta.form.toUpperCase() ]: {
                    loading: false,
                    error: action.payload
                }
            };

        default:
            return state;
    }
}