import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirm } from "../../ui";
import { patchOpportunity } from "../opportunity.actions";
import { DELETE_COMPANY_FORM } from "../opportunity.constants";

const DeleteCompany = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openConfirm, setOpenConfirm ] = useState( false );

    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const initialValues = useMemo( () => ({
        id: opportunity.id,
        company: null
    }), [ opportunity ] );

    const formik = useForm({
        form: DELETE_COMPANY_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( patchOpportunity({ ...values, formikForm: DELETE_COMPANY_FORM }) ),
    });

    return (
        <>
            <IconButton color="inherit" onClick={ toggleConfirm } size="large">
                <DeleteIcon/>
            </IconButton>
            <form onSubmit={ formik.handleSubmit }>
                <DialogConfirm
                    open={ openConfirm }
                    disabled={ formik.submitting }
                    message={ t( "customer:company.confirm-delete-from-opportunity" ) }
                    onCancel={ toggleConfirm }
                    onConfirm={ formik.submitForm }
                />
            </form>
        </>
    );
};

export default DeleteCompany;