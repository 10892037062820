import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import get from "lodash/get";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import classnames from "classnames";
import qs from "qs";

import { useDateFormat } from "../hooks/use-date-format.hook";
import { useFieldFilterFormat, useFieldValueFormat } from "../hooks/use-field-value-format.hook";
import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import history from "../../history";
import { fetchForm } from "../form/form.actions";
import { selectors as formSelectors, fieldTableTypes, FiltersFields } from "../form";
import { cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { getSubscription } from "../subscription/subscription.selectors";
import { saveUserPreferences } from "../user/user.actions";
import { selectors as coreSelectors } from "../core";
import BatchOptions from "./list/batch-options.component";
import ExportationForm from "./list/exportation-form.component";
import GenerateOpportunities from "./list/generate-opportunities.component";
import UserTableColumns from "./list/user-table-columns.component";
import NewCustomer from "./new-customer.component";
import { isLoadingAll, listAll, getAllType, getTotalPagesAll, getTotalElementsAll } from "./customer.selectors";
import { fetchCustomersList, setCustomerListType } from "./customer.actions";
import css from "./table.scss";

// ---------------------------------------------------------------------------------------------------------------------
// Utils
// ---------------------------------------------------------------------------------------------------------------------
export const hasNoCustomers = ({ customers }) => isEmpty( customers );

export const mapStateToProps = state => {
    const type = getAllType( state ) || "";

    return ({
        // Se você algum dia descobrir qual o problema de dependencia circular envolvido com form.selectors,
        // por favor, crie o selector for desta função,
        fields: type ? formSelectors.list( type )( state ) : [],
        mainScreenFields: type ? formSelectors.listUserMainScreenFields( type )( state ) : [],
        subscription: getSubscription( state ),
        user: coreSelectors.getUser( state ),
        preferences: coreSelectors.getPreferences( state ),
        customers: listAll( state ),
        type,
        totalPages: getTotalPagesAll( state ),
        totalElements: getTotalElementsAll( state ),
        loading: isLoadingAll( state ),
        loadingForm: formSelectors.isLoading( "COMPANY" )( state ) || formSelectors.isLoading( "PERSON" )( state )
    });
};

// ---------------------------------------------------------------------------------------------------------------------
// Component
// ---------------------------------------------------------------------------------------------------------------------
const CustomersTable = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const fieldFilterFormat = useFieldFilterFormat( t );
    const fieldValueFormat = useFieldValueFormat( t );

    const queryString = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location ] );

    const [ selectable, setSelectable ] = useState( false );
    const [ allRowsSelected, setAllRowsSelected ] = useState( false );
    const [ allRowsSelectedType, setAllRowsSelectedType ] = useState( "none" );
    const [ customersSelected, setCustomersSelected ] = useState( new Map() );
    const [ showBatchOptions, setShowBatchOptions ] = useState( false );
    const [ showMenuColumns, setShowMenuColumns ] = useState( false );
    const [ showMenuFilters, setShowMenuFilters ] = useState( false );
    const [ orderBy, setOrderBy ] = useState( queryString.orderBy );
    const [ orderType, setOrderType ] = useState( queryString.orderType || "ASC" );

    const customerType = useSelector( getAllType );
    const type = useMemo( () => queryString.type || customerType, [ queryString.type, customerType ] );
    const selectedPage = useMemo( () => Number( queryString.page || 0 ), [ queryString.page ] );

    const fields = useSelector( formSelectors.listMainScreenFields( type ) );
    const user = useSelector( coreSelectors.getUser );
    const preferences = useSelector( coreSelectors.getPreferences );
    const customers = useSelector( listAll );
    const totalPages = useSelector( getTotalPagesAll );
    const totalElements = useSelector( getTotalElementsAll );
    const loading = useSelector( isLoadingAll );
    const loadingForm = useSelector( formSelectors.isLoading( type ) );

    const pageSize = useMemo( () => queryString.pageSize || 10, [ queryString.pageSize ] );

    const userColumns = useMemo(
        () => ( get( preferences, `${type.toLowerCase()}.mainColumns` ) || [] ),
        [ preferences, type ]
    );

    const mainScreenFields = useMemo( () => {
        return fields
            .filter( field => field.options?.mainScreen )
            .map( field => ({
                ...field,
                checked: userColumns.includes( field.id.toString() ) || isEmpty( userColumns )
            }));
    }, [ fields, userColumns ] );

    const makeColName = index => "col" + index;

    const tableModel = useMemo( () => {
        if ( isEmpty( fields ) ) {
            return {};
        }

        const userMainScreenFields = mainScreenFields.filter( field => field.checked );

        if ( !isEmpty( userMainScreenFields ) ) {
            return userMainScreenFields.reduce(
                ( model, field, index ) => {
                    model[ makeColName( index ) ] = {
                        value: field.id,
                        title: field.name,
                        type: fieldTableTypes[ field.type ]
                    };
                    return model;
                },
                {}
            );
        }

        return {
            title: t( "customer:name" ),
            customerType: type
        };
    }, [ t, fields, mainScreenFields, type ] );
    const tableSource = useMemo( () => {
        const userMainScreenFields = mainScreenFields.filter( field => field.checked );

        if ( isEmpty( customers ) ) {
            return [];
        }
        const checkAll = allRowsSelectedType === "all";

        if ( !isEmpty( userMainScreenFields ) ) {
            return ( customers || [] ).map( customer => {
                return userMainScreenFields.reduce( ( temp, field, index ) => {
                    const col = makeColName( index );
                    temp[ col ] = fieldValueFormat( field, customer.fields[ field.id ] );
                    return temp;
                }, {
                    id: customer.id,
                    name: customer.name,
                    selected: checkAll || customersSelected.has( customer.id ),
                });
            });
        }
        return customers;
    }, [ allRowsSelectedType, customers, customersSelected, fieldValueFormat, mainScreenFields ] );

    const handlePageChange = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const loadCustomers = useCallback( () => {
        if ( !orderBy ) {
            return;
        }
        dispatch( fetchCustomersList({
            type,
            orderBy,
            orderType,
            page: selectedPage + 1
        }) );
    }, [ dispatch, orderBy, orderType, selectedPage, type ] );

    const toggleSelectable = () => {
        if ( !selectable ) {
            setSelectable( true );
        } else {
            closeBatchOptions();
        }
    };

    const closeBatchOptions = () => {
        setShowBatchOptions( false );
        setSelectable( false );
        setAllRowsSelected( false );
        setCustomersSelected( new Map() );
        setAllRowsSelectedType( "none" );
    };

    const toggleBatchOptions = () => setShowBatchOptions( !showBatchOptions );

    const toggleMenuColumns = () => setShowMenuColumns( !showMenuColumns );

    const toggleMenuFilters = () => setShowMenuFilters( !showMenuFilters );

    const handleSelect = ( tableSource, all = false ) => {
        const selectedCurrentPageCount = tableSource.filter( item => item.selected ).length;
        const allRowsSelectedPage = tableSource.length > 0 && selectedCurrentPageCount === tableSource.length;
        const noneRowsSelectedPage = tableSource.length > 0 && selectedCurrentPageCount === 0;

        let newCustomersSelected = new Map( customersSelected );

        if ( all ) {
            newCustomersSelected = new Map();
        } else {
            tableSource.forEach( item => {
                if ( item.selected ) {
                    newCustomersSelected.set( item.id, true );
                } else {
                    newCustomersSelected.delete( item.id );
                }
            });
        }

        const menuStatus = newCustomersSelected.size > 0 || all;

        setCustomersSelected( newCustomersSelected );
        setAllRowsSelected( allRowsSelectedPage );
        setAllRowsSelectedType( all ? "all" : noneRowsSelectedPage ? "none" : allRowsSelectedType );

        if ( showBatchOptions !== menuStatus ) {
            toggleBatchOptions();
        }
    };

    const deleteFilter = fieldId => {
        const filters = preferences[ type.toLowerCase() ].filters;
        const newFilters = filters.filter( filter => filter.fieldId !== fieldId );
        dispatch( saveUserPreferences( { ...set( preferences, `${type.toLowerCase()}.filters`, newFilters ) }) );
    };

    const deleteTag = tag => {
        const tags = get( preferences, `${type.toLowerCase()}.tags` )
            .filter( item => item !== tag );
        dispatch( saveUserPreferences( { ...set( preferences, `${type.toLowerCase()}.tags`, tags ) }) );
    };

    const deleteImportation = () => {
        dispatch( saveUserPreferences( { ...omit( preferences, `${type.toLowerCase()}.importation` ) } ) );
    };

    const deleteCreatedAt = () => {
        dispatch( saveUserPreferences( { ...omit( preferences, `${type.toLowerCase()}.createdAt` ) } ) );
    };

    const handleTypeChange = event => {
        const type = event.target.value;
        showBatchOptions && closeBatchOptions();
        dispatch( setCustomerListType( type ) );
        setOrderBy( "" );
        history.push({
            ...location,
            search: qs.stringify({ type, page: 0 })
        });
    };

    const prevPreferences = usePrevious( preferences );

    useEffect( () => {
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
    }, [ dispatch ] );
    useEffect( () => {
        loadCustomers();
    }, [ loadCustomers ] );
    useEffect( () => {
        if ( prevPreferences &&
            !isEqual(
                omit( prevPreferences[ type.toLowerCase() ], "mainColumns" ),
                omit( preferences[ type.toLowerCase() ], "mainColumns" )
            )
        ) {
            loadCustomers();
        }
    }, [ prevPreferences, preferences, type, loadCustomers ] );
    useEffect( () => {
        if ( isEmpty( tableModel ) ) {
            return;
        }
        if ( !orderBy ) {
            const orderByKey = Object.keys( tableModel )[ 0 ];
            setOrderBy( tableModel[ orderByKey ].value ? tableModel[ orderByKey ].value.toString() : orderByKey );
            setOrderType( "ASC" );
        }
    }, [ orderBy, tableModel ] );

    const filters = get( preferences, `${type.toLowerCase()}.filters`, [] );
    const tags = get( preferences, `${type.toLowerCase()}.tags`, [] );
    const importation = get( preferences, `${type.toLowerCase()}.importation.name` );
    const createdAt = get( preferences, `${type.toLowerCase()}.createdAt` );
    const hasFilters = !isEmpty( filters ) || !isEmpty( tags ) || !!importation || !isEmpty( createdAt );
    const rowLink = index => customers && customers[ index ] ?
        `/customers/${customers[ index ].id}` :
        "";
    const ids = allRowsSelectedType === "all" ? [] : Array.from( customersSelected.keys() );

    const exportationColumns = useMemo( () => {
        if ( isEmpty( fields ) ) {
            return [];
        }

        const userMainScreenFields = mainScreenFields.filter( field => field.checked );
        if ( !isEmpty( userMainScreenFields ) ) {
            return userMainScreenFields.map( field => `fields.${ field.id }` );
        }

        const field = fields.find( field => field.systemField === "NAME" );
        return [ `fields.${ field.id }` ];
    }, [ fields, mainScreenFields ] );

    return (
        <div>
            {
                showBatchOptions &&
                    <BatchOptions
                        customerType={ type }
                        onClose={ closeBatchOptions }
                        ids={ ids }
                    />
            }

            <UserTableColumns customerType={ type } open={ showMenuColumns } onClose={ toggleMenuColumns } />

            <FiltersFields
                show={ showMenuFilters }
                formName={ type.toLowerCase() }
                onClose={ toggleMenuFilters }
            />

            <div
                className={ classnames(
                    cssUtils.marginBottomSmall,
                    flex.container,
                    flex.alignItemsCenter,
                    showBatchOptions ? css.tableReduced : ""
                ) }
            >
                <div className={ flex.fill }>
                    <div className={ classnames( flex.container, flex.column ) }>
                        <div>
                            {
                                tags.map( ( tag, index ) => (
                                    <Chip
                                        key={ index }
                                        onDelete={ () => deleteTag( tag ) }
                                        label={ tag }
                                    />
                                ))
                            }
                            {
                                !isEmpty( createdAt ) ?
                                    <Chip
                                        onDelete={ deleteCreatedAt }
                                        label={
                                            t( "form:user-filters.created-at.label" ) +
                                            ": " +
                                            ( createdAt.initial && createdAt.final ?
                                                dateFormat( createdAt.initial ) +
                                                " - " +
                                                dateFormat( createdAt.final )
                                                :
                                                createdAt.initial ?
                                                    t( "form:user-filters.created-at.from" ) +
                                                    dateFormat( createdAt.initial ) :
                                                    t( "form:user-filters.created-at.to" ) +
                                                    dateFormat( createdAt.final )
                                            )
                                        }
                                    /> :
                                    null
                            }
                            {
                                !isEmpty( importation ) &&
                                    <Chip
                                        onDelete={ deleteImportation }
                                        label={
                                            t( "form:user-filters.importation.label" ) +
                                            ": " +
                                            importation
                                        }
                                    />
                            }
                            {
                                filters.map( ( filter, index ) => {
                                    const field = fields.find( field => field.id === filter.fieldId ) || {};

                                    return (
                                        <Chip
                                            key={ index }
                                            onDelete={ () => deleteFilter( field.id ) }
                                            label={ fieldFilterFormat( field, filter.operator, filter.value ) }
                                        />
                                    );
                                })
                            }
                        </div>
                        <FormControl>
                            <RadioGroup
                                className={ cssUtils.displayBlock }
                                value={ type }
                                onChange={ handleTypeChange }
                            >
                                <FormControlLabel
                                    label={ t( "customer:company.title" ) }
                                    value="COMPANY"
                                    control={ <Radio /> }
                                />
                                <FormControlLabel
                                    className={ cssUtils.marginLeft }
                                    label={ t( "customer:person.title" ) }
                                    value="PERSON"
                                    control={ <Radio /> }
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className={ flex.container }>
                    <Tooltip title={ t( "customer:customers-list.batch" ) }>
                        <IconButton onClick={ toggleSelectable } size="large">
                            <ShuffleIcon />
                        </IconButton>
                    </Tooltip>
                    <GenerateOpportunities customerType={ type } totalCustomers={ totalElements } />
                    { user.admin && <ExportationForm type={ type } initialColumns={ exportationColumns } /> }
                    <Tooltip title={ t( "customer:customers-list.filters" ) }>
                        <IconButton
                            id="filterButton"
                            onClick={ toggleMenuFilters }
                            className={ classnames( cssUtils.floatRight, hasFilters ? css.iconFilters : "" ) }
                            size="large">
                            <FilterListIcon/>
                        </IconButton>
                    </Tooltip>
                    { mainScreenFields.length > 0 ?
                        <Tooltip title={ t( "customer:customers-list.select-columns" ) }>
                            <IconButton
                                id="filterButton"
                                className={ cssUtils.floatRight }
                                onClick={ toggleMenuColumns }
                                size="large">
                                <ViewColumnIcon/>
                            </IconButton>
                        </Tooltip>
                        : null
                    }
                </div>
            </div>
            <Table
                model={ tableModel }
                source={ tableSource }
                rowLink={ rowLink }
                isLoading={ loading || loadingForm }
                emptyMessage={ t( "customer:customers-list.empty" ) }
                pageSize={ pageSize }
                rowsPerPageOptions={ [ 10, 30, 50 ] }
                totalPages={ totalPages }
                totalElements={ totalElements }
                onChangePage={ handlePageChange }
                saveOrderHistory
                allowOrder
                orderBy={ orderBy }
                orderType={ orderType }
                selectedPage={ selectedPage }
                selectable={ selectable }
                showSelectAllOptions
                allRowsSelected={ allRowsSelected }
                allRowsSelectedType={ allRowsSelectedType }
                onSelect={ handleSelect }
            />

            <NewCustomer type={ type } />
        </div>
    );
};

export default CustomersTable;