import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";

import useTranslate from "../hooks/use-translate.hook";
import { EmptyState } from "../ui";

const RouteNotFound = () => {
    const t = useTranslate();
    return (
        <EmptyState icon={ DesktopAccessDisabledIcon } message={ t( "layout:page-not-found" ) }/>
    );
};

export default RouteNotFound;