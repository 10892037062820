import { useMemo, useCallback } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/es-mx";

import useTranslate from "../hooks/use-translate.hook";

const DatePicker = ({
    field,
    form: { getFieldMeta, setFieldValue } = {},
    className,
    disabled,
    label,
    startOfDay,
    endOfDay,
    asDate = false,
    fullWidth = true,
    maxDate,
    minDate,
    onAccept,
    onChange,
    ...props
}) => {
    const t = useTranslate();

    const name = useMemo( () => field?.name, [ field ] );
    const value = useMemo( () => field?.value || props.value, [ field, props.value ] );

    const handleChange = useCallback( newValue => {
        if ( newValue ) {
            if ( startOfDay ) {
                newValue = newValue.startOf( "day" );
            } else if ( endOfDay ) {
                newValue = newValue.endOf( "day" );
            } else if ( value ) {
                const previousValue = dayjs( value );
                newValue.set( "hour", previousValue.get( "hour" ) );
                newValue.set( "minute", previousValue.get( "minute" ) );
                newValue.set( "second", previousValue.get( "second" ) );
                newValue.set( "millisecond", previousValue.get( "millisecond" ) );
            }
            const isoDate = newValue.isValid() ?
                asDate ? newValue.format( "YYYY-MM-DD" ) : newValue.toDate().toISOString() :
                "";
            setFieldValue && setFieldValue( name, isoDate );
            onChange && onChange( isoDate );
        } else {
            setFieldValue && setFieldValue( name, newValue );
            onChange && onChange( newValue );
        }
    }, [ asDate, startOfDay, endOfDay, name, onChange, setFieldValue, value ] );

    const validationMsg = useMemo( () => {
        if ( name && getFieldMeta ) {
            const fieldMeta = getFieldMeta( name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ name, getFieldMeta ] );

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale={ t( "common:full-locale" ).toLowerCase() }
        >
            <DesktopDatePicker
                className={ className }
                disabled={ disabled }
                label={ label }
                maxDate={ maxDate }
                minDate={ minDate }
                renderInput={ params => (
                    <TextField
                        error={ !!validationMsg }
                        fullWidth={ fullWidth }
                        helperText={ validationMsg && t( validationMsg ) }
                        { ...props }
                        { ...params }
                    />
                )}
                value={ value || null }
                onChange={ handleChange }
                onAccept={ onAccept }
            />
        </LocalizationProvider>
    );
};

export default DatePicker;