import { Field } from "formik";
import { useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useTranslate from "../hooks/use-translate.hook";
import { Checkbox, TemplateEditor, flex } from "../ui";

const PhaseTrigger = ({ form }) => {
    const t = useTranslate();

    const values = useMemo( () => form.values.triggers, [ form.values.triggers ] );

    const renderItem = ( trigger, index ) => (
        <div key={ index }>
            <Accordion>
                <AccordionSummary
                    expandIcon={ <ExpandMoreIcon/> }
                    classes={{ content: flex.alignItemsCenter }}
                >
                    <div>
                        <Field
                            name={ `triggers[${index}].checked` }
                            component={ Checkbox }
                        />
                    </div>
                    <Typography>
                        {
                            t(
                                "opportunity:phases.template.description",
                                {
                                    name: trigger.template.name,
                                    type: t( `opportunity:phases.template.type.${trigger.template.type}` )
                                }
                            )
                        }
                        {
                            trigger.sendCustomer ?
                                t( "opportunity:phases.template.send-customer" ) :
                                t(
                                    "opportunity:phases.template.send-users",
                                    { users: trigger.users.map( user => user.name ).join( ", " ) }
                                )
                        }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Field
                        name={ `triggers[${index}].body` }
                        templateType={ trigger.template.type }
                        component={ TemplateEditor }
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );

    return values.map( renderItem );
};

export default PhaseTrigger;