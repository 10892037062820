import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export default ({ field, label, options, row = true, fullWidth, className, disabled }) => (
    <FormControl className={ className } disabled={ disabled } fullWidth={ fullWidth }>
        { label && <FormLabel>{ label }</FormLabel> }
        <RadioGroup { ...field } row={ row }>
            {
                options.map( ( option, index ) => (
                    <FormControlLabel key={ index } control={ <Radio color="primary" /> } { ...option }/>
                ))
            }
        </RadioGroup>
    </FormControl>
);