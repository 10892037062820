import Dialog from "@mui/material/Dialog";

import useTranslate from "../../../hooks/use-translate.hook";
import GoalForm from "./goal-form.component";
import { EDIT_GOAL_FORM } from "./goal.constants";

const EditGoal = ({ goal, onClose }) => {
    const t = useTranslate();

    return (
        <Dialog open={ !!goal }>
            <GoalForm
                formName={ EDIT_GOAL_FORM }
                goal={ goal }
                title={ t( "config:new-goal" ) }
                onClose={ onClose }
            />
        </Dialog>
    );
};

export default EditGoal;