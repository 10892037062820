import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils } from "../../ui";
import css from "../opportunity.scss";

const icons = {
    OPEN: ReplayIcon,
    ACCEPTED: ThumbUpIcon,
    DECLINED: ThumbDownIcon
};

const ProposalStatusEvent = ({ event: { user, payload: { name, current, previous }, date } }) => {
    const t = useTranslate();

    const Icon = icons[ current ];

    return (
        <TimelineItem
            classes={{ avatar: current === "ACCEPTED" ? css.sold : current === "DECLINED" ? css.lost : null }}
            icon={ <Icon/> }
        >
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.proposal-status", { name } ) }
                date={ date } />

            <Typography>
                <mark className={ cssUtils.highlightOld }>
                    { t( "opportunity:proposal.status." + previous ) }
                </mark>
                { "→ " }
                <mark className={ cssUtils.highlightNew }>
                    { t( "opportunity:proposal.status." + current ) }
                </mark>
            </Typography>
        </TimelineItem>
    );
};

export default ProposalStatusEvent;