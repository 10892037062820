export default {
    "email-verification": {
        "success": "¡E-mail verificado con éxito!",
        "error": "No se pudo verificar el correo electrónico usando este token",
        "back": "Volver a la página de inicio",
        "loading": "Revisando su correo electrónico, por favor espere"
    },
    "user": {
        "name": "Nombre",
        "email": "Correo electrónico",
        "profile": "Mis datos",
        "change-email-help":
            "Después de guardar el cambio de correo electrónico, se le redirigirá a la página de inicio de sesión " +
            "y se requerirá la verificación de la nueva dirección",
        "index-route": {
            "label": "Página principal",
            "help": "En este campo se define qué módulo se mostrará al iniciar sesión o acceder al sistema",
            "home": "Por hoy",
            "opportunities": "Oportunidades"
        },
        "avatar": "Avatar",
        "confirm-remove-avatar": "Está seguro de que desea eliminar el avatar?",
        "signature": "Firma de correo electrónico",
        "change-password": {
            "title": "Cambiar la contraseña",
            "old-password": "Contraseña actual",
            "new-password": "Nueva contraseña",
            "rewrite-new-password": "Confirme su nueva contraseña",
            "changed-password": "Contraseña cambiada",
            "success": "Contraseña cambiada con éxito",
            "help":
                "Después del cambio será redirigido a la página de inicio de sesión, " +
                "donde puede iniciar sesión con su nueva contraseña."
        },
        "confirm-remove-signature": "Está seguro de que desea eliminar la firma?",
        "help-signature": "Solo se aceptan archivos de imagen."
    },
    "smtp-server": {
        "call-to-action": "Configure su método de envío de correo electrónico aquí",
        "type": {
            "label": "Modo de envío",
            "LS": "Servidor LeadStation",
            "CUSTOM": "Otro servidor"
        },
        "pre-settings": {
            "label": "Usar preconfiguración",
            "subscription": "Configuración de inscripción"
        },
        "host": "Anfitrión",
        "security-type": "Tipo de seguridad",
        "port": "Puerta",
        "auth": "Con autenticación",
        "no-auth": "Sin autenticación",
        "username": {
            "label": "Usuario",
            "help": "Por lo general, es el mismo que el correo electónico"
        },
        "amazon-help": "Para utilizar este SMTP, debe crear credenciales de acceso en su cuenta de Amazon. ",
        "amazon-help-docs": "Haga clic aquí para acceder a la documentación.",
        "gmail-help":
            "Gmail le pedirá \"Revisar el intento de inicio de sesión bloqueado\" a través de un correo electrónico.",
        "submit": {
            "success": "Configuración guardada con éxito!",
            "auth-error":
                "No se pudo autenticar. Asegúrese de que los datos sean correctos, su bandeja de entrada e " +
                "intente nuevamente.",
            "unknown-error": "No se pudo guardar la configuración. Compruebe los datos e intente nuevamente."
        }
    },
    "theme": {
        "label": "Tema",
        "light": "Ligero",
        "dark": "Oscuro"
    },
    "gmail": {
        "auth": "Autenticarse con Google",
        "connected": "Cuenta conectada",
        "expired": "Cuenta vencida"
    },
    "schedule": {
        "title":  "Agenda",
        "integration": {
            "title": "Integración de la agenda",
            "call-to-action":
                "Configure una cuenta aquí para integrar su calendario con LeadStation. " +
                "A partir de esta configuración, " +
                "podrá ver en LeadStation los eventos de su calendario personal y los horarios creados " +
                "por nuestra plataforma estará vinculada a su agenda."
        },
        "color": "Color para mostrar las programaciones en LeadStation Calendario"
    },
    "register": "Registro",
    "system": "Sistema",
    "save": "Guardar",
    "remove": "Eliminar",
    "yes": "Sí",
    "no": "No",
    "tabs": {
        "person": "Información personal",
        "email": "Envío de correo electrónico",
        "templates": "Plantillas",
        "schedule": "Calendario"
    },
    "images": {
        "title": "Mi galería",
        "call-to-action": "Aquí se enumeran las imágenes registradas que se pueden utilizar en sus plantillas",
        "confirm-delete": "Está seguro de que desea eliminar esta imagem de su galería?"
    },
    "templates": {
        "title": "Mis plantillas",
        "call-to-action": "Puede configurar sus plantillas aquí y compartirlas con otros usuarios"
    }
};