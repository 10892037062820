import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const OptionsButton = ({ options }) => {
    const [ buttonMenu, setButtonMenu ] = useState();

    const openMenu = event => setButtonMenu( event.currentTarget );
    const closeMenu = () => setButtonMenu( null );

    const handleMenuClick = option => () => {
        option.onClick();
        closeMenu();
    };

    return (
        <>
            <IconButton onClick={ openMenu } color="inherit" size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={ !!buttonMenu }
                onClose={ closeMenu }
                anchorEl={ buttonMenu }
            >
                {
                    options.map( ( option, index ) => (
                        <MenuItem key={ index } disabled={ option.disabled } onClick={ handleMenuClick( option ) }>
                            <ListItemIcon>
                                { option.icon }
                            </ListItemIcon>
                            <ListItemText primary={ option.text } />
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

export default OptionsButton;