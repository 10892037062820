import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { getSelected, isLoadingSelected } from "./customer.selectors";
import { selectors as opportunitySelectors } from "../opportunity";
import { openWhatsApp } from "./customer.actions";
import { selectors as formSelectors } from "../form";
import { selectors as templateSelectors } from "../template";
import { phoneFormat } from "../core/formatter.utils";
import { EmptyState, TemplateEditor } from "../ui";

const NewWhatsApp = ({ onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunity = useSelector( opportunitySelectors.getSelected );
    const selected = useSelector( getSelected );
    const customer = ( opportunity && ( opportunity.person || opportunity.company ) ) || selected;
    const fields = useSelector( formSelectors.listPhoneFields( customer?.type ) );
    const loadingCompanyFields = useSelector( formSelectors.isLoading( "COMPANY" ) );
    const loadingPersonFields = useSelector( formSelectors.isLoading( "PERSON" ) );
    const loadingSelected = useSelector( isLoadingSelected );
    const loadingOpportunity = useSelector( opportunitySelectors.isLoadingSelected );
    const loadingImages = useSelector( templateSelectors.isLoadingImages );
    const selectedCustomer = useMemo(
        () => ( opportunity && ( opportunity.person || opportunity.company ) ) || customer,
        [ opportunity, customer ]
    );
    const loading = loadingCompanyFields ||
        loadingPersonFields ||
        loadingSelected ||
        loadingOpportunity ||
        loadingImages;

    const [ message, setMessage ] = useState( "" );

    const handleClick = phone => () => {
        let number = phone.number;
        if ( phone.country === "BR" ) {
            // Para telefones brasileiros, necessario deixar o 9 do celular para os
            // ddd 11 entre 28
            const areaCode = parseInt( phone.number.substring( 2, 4 ) );
            const startsWithNine = phone.number.length === 13 && phone.number.charAt( 4 ) === "9";
            const areaCodeWithNine = areaCode >= 11 && areaCode <= 28;
            if ( startsWithNine && !areaCodeWithNine ) {
                number = phone.number.substring( 0, 4 ) + phone.number.substring( 5 );
            }
        }

        let url = `https://api.whatsapp.com/send?phone=${number}`;
        if ( message ) {
            url += `&text=${message}`;
        }
        window.open( url, "_blank" );
        dispatch( openWhatsApp( number ) );
        onClose();
    };

    const handleChange = ( name, value ) => setMessage( value );

    useEffect( () => {
        if ( !show ) {
            setMessage( "" );
        }
    }, [ show ] );

    if ( loading || !selectedCustomer ) {
        return null;
    }

    const phones = [];
    fields.forEach( field => {
        const value = selectedCustomer.fields[ field.id ];
        if ( value ) {
            value.forEach( phone => phones.push( phone ) );
        }
    });

    return (
        <Dialog open={ show } fullWidth>
            <DialogTitle>{ "WhatsApp" }</DialogTitle>
            <DialogContent>
                {
                    isEmpty( phones ) ?
                        <EmptyState
                            icon={ PhonelinkEraseIcon }
                            message={ t( "customer:new-whatsapp.empty-phones" ) }
                        /> :
                        <div>
                            <DialogContentText>{ t( "customer:new-whatsapp.call-to-action" ) }</DialogContentText>
                            <TemplateEditor
                                label={ t( "customer:new-whatsapp.message" ) }
                                field={{ name: "message", value: message }}
                                form={{ setFieldValue: handleChange }}
                                templateType="WHATSAPP"
                                customer={ selectedCustomer }
                                opportunity={ opportunity }
                                required={ false }
                                showVariables={ false }
                                showTemplates
                                multiline
                            />
                            <List>
                                {
                                    phones.map( ( phone, index ) => (
                                        <ListItemButton key={ index } onClick={ handleClick( phone ) }>
                                            <ListItemIcon><SendIcon/></ListItemIcon>
                                            <ListItemText primary={ phoneFormat( phone ) } />
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewWhatsApp;