import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteHoldingFunnel } from "./holding.actions";
import { DELETE_HOLDING_FUNNEL_FORM } from "./holding.constants";

const DeleteHoldingFunnel = ({ funnelId, onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: DELETE_HOLDING_FUNNEL_FORM,
        initialValues: {
            funnelId
        },
        onSubmit: values => dispatch( deleteHoldingFunnel( values.funnelId ) ),
    });

    return (
        <DialogConfirmForm
            open={ open }
            onClose={ onClose }
            title={ t( "config:holding.funnel.delete.title" ) }
            message={ t( "config:holding.funnel.delete.confirm" ) }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteHoldingFunnel;