export const SAVE_TEAM = "x/config/team/SAVE_TEAM";
export const saveTeam = data => ({
    type: SAVE_TEAM,
    payload: data
});

export const FETCH_USERS = "x/config/team/FETCH_USERS";
export const fetchUsers = id => ({
    type: FETCH_USERS,
    meta: { id }
});

export const REQUEST_USERS = "x/config/team/REQUEST_USERS";
export const requestUsers = id => ({
    type: REQUEST_USERS,
    meta: { id },
});

export const RECEIVE_USERS = "x/config/team/RECEIVE_USERS";
export const receiveUsers = ( id, users ) => ({
    type: RECEIVE_USERS,
    meta: { id },
    payload: users
});

export const ERROR_USERS = "x/config/team/ERROR_USERS";
export const errorUsers = ( id, error ) => ({
    type: ERROR_USERS,
    meta: { id },
    error
});

export const DELETE_TEAM = "x/config/team/DELETE_TEAM";
export const deleteTeam = ( { id } ) => ({
    type: DELETE_TEAM,
    meta: { id }
});