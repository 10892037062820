import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { Dropdown, Input, TemplateEditor, cssUtils, flex } from "../../../ui";
import { variables } from "../../../ui/template.utils";
import { saveTemplate } from "./template.actions";
import { TEMPLATE_FORM } from "./template.constants";
import TemplateBodyTester from "./template-body-tester.component";
import css from "./template.scss";

const Templates = ({ template, onClose, onClickTest, showBodyTester }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const initialValues = useMemo( () => {
        if ( !template ) {
            return {
                name: "",
                type: null,
                body: "",
            };
        }
        let body = template.body;
        if ( template.type !== "EMAIL" ) {
            variables.forEach( variable => {
                body = body.replace( variable.value, `#(${variable.value})` );
            });
        }

        return {
            ...template,
            body,
        };
    }, [ template ] );

    const formik = useForm({
        form: TEMPLATE_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            let body = values.body;
            if ( values.type !== "EMAIL" ) {
                variables.forEach( variable => {
                    body = body.replace( `#(${variable.value})`, variable.value );
                });
            }

            dispatch( saveTemplate({ ...values, body }) );
        },
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.type ) {
                errors.type = "common:validation.selection";
            }
            if ( !values.body || values.body === "<p></p>" ) {
                errors.body = "common:validation.required";
            }
            return errors;
        }
    });
    const { setFieldValue, values } = formik;

    const prevType = usePrevious( formik.values.type );

    useEffect( () => {
        if ( !isEmpty( values.body ) && values.type && prevType && values.type !== prevType ) {
            setFieldValue( "body", "" );
        }
    }, [ setFieldValue, values.body, values.type, prevType ] );


    return (
        <>
            <Dialog
                open
                disableEnforceFocus
                fullWidth
                fullScreen
            >
                <AppBar position="absolute" classes={{ root: css.newTemplateBar }}>
                    <Toolbar>
                        <Tooltip title={ t( "config:template.close" ) }>
                            <IconButton color="inherit" onClick={ onClose } size="large">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={ classnames( flex.fill, cssUtils.marginLeft ) }
                        >
                            {
                                values.id && values.id ?
                                    t( "config:templates.edit" ) :
                                    t( "config:templates.new" )
                            }
                        </Typography>
                        {
                            values.type === "EMAIL" && values.body && values.body !== "<p></p>" &&
                                <Button
                                    color="inherit"
                                    disabled={ showBodyTester }
                                    onClick={ onClickTest }
                                >
                                    { t( "config:templates.test" ) }
                                </Button>
                        }
                        <Button color="inherit" onClick={ formik.submitForm }>
                            { t( "config:template.save" ) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Form formik={ formik }>
                        <Field
                            name="name"
                            label={ t( "config:template.name" ) }
                            component={ Input }
                            className={ cssUtils.marginTopSmall }
                            required
                        />
                        <Field
                            name="type"
                            label={ t( "config:template.type.label" ) }
                            source={
                                [ "EMAIL", "SMS", "WHATSAPP" ]
                                    .map( value => ({ value, label: t( `config:template.type.${value}` ) }))
                            }
                            component={ Dropdown }
                            className={ cssUtils.marginBottomSmall }
                            required
                        />
                        <Field
                            name="body"
                            type="text"
                            component={ TemplateEditor }
                            templateType={ values.type }
                            required
                        />
                    </Form>
                </DialogContent>
            </Dialog>

            <TemplateBodyTester
                show={ showBodyTester }
                body={ values.body }
                onClose={ onClickTest }
            />
        </>
    );
};

export default Templates;