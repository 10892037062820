import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import get from "lodash/get";
import set from "lodash/set";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { CustomField } from "../form";
import { createEinValidation, createSsnValidation, isEmail, isPhoneValid } from "../core/validator.utils";
import css from "./fields.scss";

// ---------------------------------------------------------------------------------------------------------------------
// Utils
// ---------------------------------------------------------------------------------------------------------------------

export const validateFields = ( values, props ) => {
    const errors = {};

    if ( !values ) {
        return errors;
    }

    errors.fields = {};

    props.fields.forEach( field => {

        const name = `fields.${field.id}`;
        const value = get( values, name );

        if ( get( field, "options.required" ) && ( Array.isArray( value ) ? isEmpty( value ) : !value ) &&
        isFieldVisible( field, values ) ) {
            set( errors, name, "common:validation.required" );
        }

        if ( field.systemField && value ) {
            switch ( field.systemField ) {
                case "EIN":
                    const einValidation = createEinValidation( props );
                    if ( !einValidation( value ) ) {
                        set( errors, name, "common:validation.ein" );
                    }
                    break;
                case "SSN":
                    const ssnValidation = createSsnValidation( props );
                    if ( !ssnValidation( value ) ) {
                        set( errors, name, "common:validation.ssn" );
                    }
                    break;
                default:
                    break;
            }
        }

        switch ( field.type ) {
            case "ADDRESS":
                ( value || [] ).forEach( ( address, index ) => {
                    if ( !address || !address.name ) {
                        set( errors, name + `[${index}].name`, "common:validation.address" );
                    }

                    if ( !address || !address.type ) {
                        set( errors, name + `[${index}].type`, "common:validation.selection" );
                    }
                });
                break;
            case "EMAIL":
                ( value || [] ).forEach( ( email, index ) => {
                    if ( !email || !isEmail( email ) ) {
                        set( errors, name + `[${index}]`, "common:validation.email" );
                    }
                });
                break;
            case "PHONE":
                ( value || [] ).forEach( ( phone, index ) => {
                    if ( !phone.number ) {
                        set( errors, name + `[${index}]`, "common:validation.required" );
                    } else {
                        if ( !isPhoneValid( phone ) ) {
                            set( errors, name + `[${index}]`, "common:validation.phone-number" );
                        }
                    }
                });
                break;
            default:
                break;
        }
    });

    if ( isEmpty( errors.fields ) ) {
        delete errors.fields;
    }

    return errors;
};


const isFieldVisible = ( field, values ) => {
    if ( !field.dependsOn ) {
        return true;
    }

    const value = get( values, `fields.${field.dependsOn.dependsOnFieldId}` );
    if ( !value ) {
        return false;
    }

    return field.dependsOn.options.includes( value.toString() );
};

// ---------------------------------------------------------------------------------------------------------------------
// Component
// ---------------------------------------------------------------------------------------------------------------------
export const Fields = ({ name, fields, disabledAll, disabled = [], className, ...props }) => {
    const [ currentPosition, setCurrentPosition ] = useState();

    const formik = useFormikContext();

    useEffect( () => {
        let positionWatcher;
        if ( navigator.geolocation ) {
            positionWatcher = navigator.geolocation.watchPosition( position => {
                setCurrentPosition( position );
                navigator.geolocation.clearWatch( positionWatcher );
            } );
        }

        return () => {
            navigator.geolocation.clearWatch( positionWatcher );
        };
    }, [] );

    const fieldName = fieldId => name ? `${name}.fields[${fieldId}]` : `fields[${fieldId}]`;

    const filterDependsOn = field => isFieldVisible( field, name ? formik.values[ name ] : formik.values );

    const fieldsComponents = fields
        .filter( filterDependsOn )
        .map( ( field, index ) => (
            field.type === "GROUPING" ?
                <Typography
                    key={ field.id }
                    variant="subtitle1"
                    className={ classnames( css.grouping, index > 1 && css.divider, className ) }
                >
                    { field.name }
                </Typography> :
                <CustomField
                    key={ field.id }
                    name={ fieldName( field.id ) }
                    field={ field }
                    currentPosition={ currentPosition }
                    disabled={ disabledAll || disabled.includes( field.id ) }
                    className={
                        classnames( field.options && field.options.halfRow ? css.halfRow : css.field, className )
                    }
                    { ...props }
                />
        ));
    return fieldsComponents;
};
