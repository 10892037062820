export const SAVE_SOURCES = "x/config/opportunity/SAVE_SOURCE";
export const saveSources = ({ sources }) => ({
    type: SAVE_SOURCES,
    payload: { sources }
});

export const DELETE_SOURCE = "x/config/opportunity/DELETE_SOURCE";
export const deleteSource = source => ({
    type: DELETE_SOURCE,
    payload: { source }
});