import { combineReducers } from "redux";

import * as actions from "./team.actions";

const users = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_USERS:
            return {
                ...state,
                [ action.meta.id ]: {
                    loading: true
                }
            };
        case actions.RECEIVE_USERS:
            return {
                ...state,
                [ action.meta.id ]: {
                    loading: false,
                    data: action.payload
                }
            };
        case actions.ERROR_USERS:
            return {
                ...state,
                [ action.meta.id ]: {
                    loading: false,
                    error: action.error
                }
            };
        default:
            return state;
    }
};

export const teamConfig = combineReducers({ users });