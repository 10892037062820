import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../api/sagas";
import * as actions from "./integration.actions";
import { actions as subscriptionActions } from "../../subscription";
import { watchIntegrationsFacebook } from "./facebook/facebook-integrations.sagas";
import { watchIntegrationsLead } from "./lead/lead-integration.sagas";
import { watchIntegrationsPortal } from "./portal/portal-integration.sagas";
import { watchIntegrationsApiKey } from "./api-key/api-key.sagas";

export function *watchIntegrations () {
    yield all([
        takeEvery( actions.SAVE_INTEGRATION, saveIntegration ),
        watchIntegrationsFacebook(),
        watchIntegrationsLead(),
        watchIntegrationsPortal(),
        watchIntegrationsApiKey()
    ]);
}

export function *saveIntegration ({ payload }) {
    const form = "INTEGRATION_" + payload.integration;
    yield put( startSubmit( form ) );

    try {
        const response = yield call( apiv1.put, `/subscription/integrations/${payload.integration}`, payload );
        yield put( subscriptionActions.receiveSubscription( response.data ) );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}