import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import { DeleteItem } from "../ui";
import { deleteSocialCalendar } from "./user.actions";
import { DELETE_SOCIAL_CALENDAR_FORM } from "./user.constants";

const DeleteSocialCalendar = () => {
    const dispatch = useDispatch();

    const formik = useForm({
        form: DELETE_SOCIAL_CALENDAR_FORM,
        initialValues: {},
        onSubmit: () => dispatch( deleteSocialCalendar() ),
    });

    return (
        <DeleteItem
            error={ formik.error }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteSocialCalendar;