import { useForm, Form } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import useTranslate from "../hooks/use-translate.hook";
import { NEW_CUSTOMER_FORM, selectors } from "./";
import { closeNewCustomer, saveNewCustomer } from "./customer.actions";
import { Fields, validateFields } from "../form";
import * as formSelectors from "../form/form.selectors";
import css from "./customer.scss";

// ---------------------------------------------------------------------------------------------------------------------
// Utils
// ---------------------------------------------------------------------------------------------------------------------
const listCustomerFields = type => formSelectors.list( type );
const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const validate = ( values, props ) => {
    const errors = validateFields( values, props );
    return errors;
};

// ---------------------------------------------------------------------------------------------------------------------
// Component
// ---------------------------------------------------------------------------------------------------------------------
const NewCustomer = ({ type }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const show = useSelector( selectors.isNewCustomerOpen );
    const fields = useSelector( listCustomerFields( type ) );

    const formik = useForm({
        enableReinitialize: true,
        form: NEW_CUSTOMER_FORM,
        initialValues: {
            type,
            fields: fieldsToInitialValues( fields ),
        },
        onSubmit: values => dispatch( saveNewCustomer( values ) ),
        validate: values => validate( values, ({ t, fields }) ),
    });

    const handleClose = () => {
        dispatch( closeNewCustomer() );
        formik.resetForm();
    };

    if ( !show ) {
        return null;
    }

    return (
        <Dialog open fullWidth maxWidth="md">
            <DialogTitle>
                { type === "PERSON" ? t( "customer:person.new" ) : t( "customer:company.new" ) }
            </DialogTitle>
            <DialogContent classes={{ root: css.newCustomerForm }}>
                <Form formik={ formik }>
                    <Fields fields={ fields }/>
                </Form>
            </DialogContent>
            <DialogActions>
                {
                    formik.error &&
                        <Typography color="error" variant="body1">
                            { formik.error }
                        </Typography>
                }
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:close" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewCustomer;