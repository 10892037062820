import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

import { cssUtils, flex } from "../ui";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as funnelSelectors } from "../funnel";
import { getFunnelId } from "../opportunity/opportunity.selectors";
import { actions as phaseActions, selectors as phaseSelectors } from "../phase";
import FunnelFilter from "../opportunity/list/funnel-filter.component";
import Chart from "../ui/chart.component";
import css from "./home.scss";

const PhasesFunnel = ({
    usersId,
    id,
    className,
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );
    const loadingFunnels = useSelector( funnelSelectors.isLoadingAll );
    const loadingFunnelPhases = useSelector( phaseSelectors.isLoadingFunnelPhases( funnelId ) );
    const loadingPhasesCount = useSelector( phaseSelectors.isLoadingPhasesCount );
    const loading = loadingFunnels || loadingFunnelPhases || loadingPhasesCount;
    const funnels = useSelector( funnelSelectors.listAll );
    const phasesCount = useSelector( phaseSelectors.getPhasesCount );

    const series = useMemo( () => {
        const empty = ( phasesCount || [] ).filter( item => item.total > 0 ).length === 0;
        if ( !empty ) {
            return [{
                name: t( "home:phases-funnel.opportunities" ),
                data: phasesCount.map( phase => [ phase.name, phase.total ] )
            }];
        }

        return null;
    }, [ t, phasesCount ] );
    const funnel = useMemo( () => {
        return funnelId && funnels.find( funnel => funnel.id === funnelId );
    }, [ funnelId, funnels ] );
    const data = useMemo( () => {
        return {
            chart: {
                height: 330,
            },
            title: {
                text: funnel ?
                    t( "home:phases-funnel.title", { funnel: funnel.name } ) :
                    " "
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b> ({point.y:,.0f})",
                        softConnector: true
                    },
                    center: [ "35%", "50%" ],
                    neckWidth: "25%",
                    neckHeight: "25%",
                    width: "60%"
                }
            },
            legend: {
                enabled: true
            },
            series
        };
    }, [ t, series, funnel ] );

    useEffect( () => {
        funnelId && dispatch( phaseActions.fetchPhasesCount( funnelId, { usersId: usersId.toString() }) );
    }, [ funnelId, dispatch, usersId ] );

    return (
        <Card id={ id } className={ className }>
            <div className={ cssUtils.marginSmall }>
                <FunnelFilter />
            </div>
            {
                loading ?
                    <div
                        className={ css.phasesSkeleton }
                    >
                        <Skeleton className={ flex.fill } sx={{ width: "100%" }} />
                        <Skeleton className={ flex.fill } sx={{ width: "75%" }} />
                        <Skeleton className={ flex.fill } sx={{ width: "50%" }} />
                        <Skeleton className={ flex.fill } sx={{ width: "25%" }} />
                        <Skeleton className={ flex.fill } sx={{ width: "25%" }} />
                    </div> :
                    <Chart
                        noDataMessage={ t( "home:phases-funnel.empty" ) }
                        data={ data }
                        type="funnel"
                    />
            }
        </Card>
    );
};

export default PhasesFunnel;