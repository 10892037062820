import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./plan.actions";

export function *watchPlan () {
    yield all([
        takeEvery( actions.FETCH_PLANS, fetchPlans ),
    ]);
}

function *fetchPlans () {
    yield put( actions.requestPlans() );
    try {
        const response = yield call( apiv1.get, "/plans" );
        yield put( actions.receivePlans( response.data ) );
    } catch ( e ) {
        yield put( actions.errorPlans( e.response.data.error ) );
    }
}
