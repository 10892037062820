import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import { getTemperatureProps } from "../opportunity/opportunity.utils";
import { EmptyState, Fab } from "../ui";
import { Table } from "../ui/table";
import { fetchOpportunities, openNewOpportunity } from "./customer.actions";
import { isLoadingOpportunities, listOpportunities } from "./customer.selectors";
import { listAll } from "../funnel/funnel.selectors";
import NewOpportunity from "./new-opportunity.component";
import css from "./customer.scss";

const CustomerOpportunities = ({ customerId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingOpportunities );
    const opportunities = useSelector( listOpportunities );
    const funnels = useSelector( listAll );

    const tableModel = useMemo( () => ({
        title: t( "opportunity:title" ),
        funnel: t( "opportunity:funnel" ),
        phase: t( "opportunity:phase" ),
        price: {
            title: t( "opportunity:price.label" ),
            format: priceFormat
        },
        recurrentPrice: {
            title: t( "opportunity:recurrent-price" ),
            format: priceFormat
        },
        nextScheduling: {
            title: t( "opportunity:next-scheduling" ),
            format: dateFormat
        },
        user: t( "opportunity:user" ),
        temperature: {
            title: " ",
            size: "small"
        },
        status: {
            title: " ",
            size: "small"
        },
    }), [ t, dateFormat, priceFormat ] );
    const tableSource = useMemo( () => opportunities.map( opportunity => ({
        title: opportunity.title,
        funnel: funnels.find( funnel => funnel.id === opportunity.funnelId ).name,
        phase: opportunity.phase.name,
        temperature: getTemperatureProps( opportunity.temperature ),
        price: opportunity.price,
        recurrentPrice: opportunity.recurrentPrice,
        nextScheduling: opportunity.nextScheduling,
        user: opportunity.user.name,
        status: (
            <AttachMoneyIcon
                className={
                    opportunity.status === "SOLD" ? css.sold : opportunity.status === "LOST" ? css.lost : ""
                }
            />
        )
    })), [ opportunities, funnels ] );

    const handleNewOpportunityClick = () => dispatch( openNewOpportunity() );

    useEffect( () => {
        dispatch( fetchOpportunities( customerId ) );
    }, [ dispatch, customerId ] );

    const rowLink = index => `/opportunities/${opportunities[ index ].id}`;

    return (
        <div className={ css.containerContent }>
            <div className={ css.content }>
                {
                    !loading && isEmpty( opportunities ) ?
                        <EmptyState icon={ MoneyOffIcon } message={ t( "customer:opportunities.empty" ) } /> :
                        <Table
                            model={ tableModel }
                            source={ tableSource }
                            rowLink={ rowLink }
                        />
                }
                <Fab
                    id="addButton"
                    position="bottomRight"
                    onClick={ handleNewOpportunityClick }
                >
                    <AddIcon/>
                </Fab>
            </div>

            <NewOpportunity/>
        </div>
    );
};

export default CustomerOpportunities;
