import { combineReducers } from "redux";

import { brands } from "./feature/ui/brand/brand.reducers";
import { commissions } from "./feature/commission/commission.reducers";
import { customers } from "./feature/customer/customer.reducers";
import { config } from "./feature/config/config.reducers";
import { info, routing, formik, theme } from "./feature/core/core.reducers";
import { files } from "./feature/file/file.reducers";
import { fields, filters } from "./feature/form/form.reducers";
import { funnels } from "./feature/funnel/funnel.reducers";
import { goals } from "./feature/goal/goal.reducers";
import { home } from "./feature/home/home.reducers";
import { kanban } from "./feature/kanban/kanban.reducers";
import { importations } from "./feature/importation/importation.reducers";
import { invitations } from "./feature/invitation/invitation.reducers";
import { layout } from "./feature/layout/layout.reducers";
import { lossReasons } from "./feature/loss-reason/loss-reason.reducers";
import { auth, passwordReset } from "./feature/login/login.reducers";
import { mailtop } from "./feature/mailtop/mailtop.reducers";
import { oauth } from "./feature/oauth/oauth.reducers";
import { opportunities } from "./feature/opportunity/opportunity.reducers";
import { phases } from "./feature/phase/phase.reducers";
import { plans } from "./feature/plan/plan.reducers";
import { products } from "./feature/product/product.reducers";
import { reports } from "./feature/report/report.reducers";
import { sources } from "./feature/source/source.reducers";
import { smtpServer } from "./feature/smtp-server/smtp-server.reducers";
import { users } from "./feature/user/user.reducers";
import { teams } from "./feature/team/team.reducers";
import { templates } from "./feature/template/template.reducers";
import { subscription } from "./feature/subscription/subscription.reducers";
import { webCapture } from "./feature/web-capture/web-capture.reducers";
import { marketplace } from "./feature/marketplace/marketplace.reducers";
import { distributions } from "./feature/config/advanced/distribution/distribution.reducers";

import { USER_LOGOUT } from "./feature/logout/logout.actions";

const appReducer = combineReducers({
    auth,
    brands,
    commissions,
    config,
    customers,
    fields,
    files,
    filters,
    formik,
    funnels,
    goals,
    home,
    kanban,
    importations,
    invitations,
    routing,
    info,
    layout,
    lossReasons,
    mailtop,
    oauth,
    opportunities,
    passwordReset,
    phases,
    plans,
    products,
    reports,
    sources,
    smtpServer,
    users,
    teams,
    templates,
    theme,
    subscription,
    webCapture,
    marketplace,
    distributions
});

export default ( state, action ) =>
    appReducer( action.type === USER_LOGOUT ? {} : state, action );
