export default {
    "field": {
        "select": "Selecione",
        "ssn": "CPF",
        "ein": "CNPJ"
    },
    "address": {
        "add": "Adicionar endereço",
        "address": "Endereço",
        "type": "Tipo",
        "open-map": "Abrir endereço no Google Maps",
        "types": {
            "RESIDENTIAL": "Residencial",
            "COMMERCIAL": "Comercial",
            "OTHER": "Outro"
        }
    },
    "email": {
        "add": "Adicionar e-mail"
    },
    "model": {
        "empty-brand": "Você deve selecionar uma marca primeiro"
    },
    "phone": {
        "add": "Adicionar telefone",
        "number": "Número",
    },
    "company-data": {
        "fetch": "Completar dados através do CNPJ",
        "unavailable": "Serviço indisponível no momento. Tente novamente mais tarde"
    },
    "user-salesman": "Vendedor",
    "add-customer": "Adicionar cliente",
    "customer": "Cliente",
    "user-filters": {
        "title": "Filtros",
        "other-fields": "Outros campos",
        "person-fields": "Campos da pessoa",
        "company-fields": "Campos da empresa",
        "operator": {
            "label": "Comparador",
            "EQUAL": "Igual",
            "NOT_EQUAL": "Diferente",
            "LIKE": "Contém",
            "NOT_LIKE": "Não contém",
            "NULL": "Sem informação",
            "NOT_NULL": "Com informação",
            "BETWEEN": "Entre"
        },
        "field": {
            "label": "Campo",
            "brand": "Marca"
        },
        "value": {
            "label": "Valor"
        },
        "customer": {
            "tags": "Tags"
        },
        "opportunity": {
            "empty-team": "Não há equipes configuradas",
            "team": "Equipe",
            "users": "Usuários",
            "products": "Produtos",
            "temperature": "Temperatura",
            "company-tags": "Tags da empresa",
            "person-tags": "Tags da pessoa",
            "has-open-scheduling": "Com agendamento em aberto",
            "has-open-scheduling-all": "Todos",
            "has-open-scheduling-no": "Não",
            "has-open-scheduling-yes": "Sim",
            "has-not-open-scheduling": "Sem agendamento aberto"
        },
        "scheduling-date": {
            "label": "Data de agendamento em aberto",
            "from": " a partir de ",
            "to": " até ",
            "initial": "Data inicial",
            "final": "Data final"
        },
        "importation": {
            "label": "Importação",
            "help": "Importação utilizada para criar os registros"
        },
        "created-at": {
            "label": "Data de criação",
            "from": " a partir de ",
            "to": " até ",
            "initial": "Data inicial",
            "final": "Data final"
        },
        "sold-at": {
            "label": "Data de venda ganha",
            "from": " a partir de ",
            "to": " até ",
            "initial": "Data inicial",
            "final": "Data final"
        }
    }
};