import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import AlarmIcon from "@mui/icons-material/Alarm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex } from "../ui";
import css from "./opportunity.scss";
import { isLoadingSelected, getSelected } from "./opportunity.selectors";
import {
    selectors as formSelectors,
    actions as formActions,
    FieldsSummary
} from "../form";
import { timeDuration } from "../core/formatter.utils";
import { getThemeMode } from "../core/core.selectors";
import OpportunityHolding from "./opportunity-holding.component";
import OpportunityStatus from "./opportunity-status.component";

const now = new Date();
const minutesToNow = date => {
    const diff = now.getTime() - date.getTime();
    return Math.round( diff / 60000 );
};

const OpportunityHead = ({ onEdit }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const themeMode = useSelector( getThemeMode );
    const loading = useSelector( isLoadingSelected );
    const opportunity = useSelector( getSelected );
    const fields = useSelector( formSelectors.listSummaryFields( "OPPORTUNITY" ) );

    useEffect( () => {
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    const renderFields = ( fields, opportunity ) => {
        if ( !opportunity.fields ) {
            return null;
        }

        return (
            <FieldsSummary
                fields={ fields }
                model={ opportunity }
            />
        );
    };

    const renderOptions = () => {
        if ( opportunity.leadId ) {
            const openLeadBody = () => {
                const width = 550;
                const height = 550;
                const left = ( screen.width / 2 ) - ( width / 2 );
                const top = ( screen.height / 2 ) - ( height / 2 );
                window.open(
                    history.createHref({ pathname: `/leads/${opportunity.leadId}/body` }),
                    "Lead",
                    `width=${width}, height=${height}, top=${top}, left=${left}`
                );
            };

            return (
                <Tooltip title={ t( "opportunity:lead.open-body" ) }>
                    <IconButton className={ css.headerOptions } onClick={ openLeadBody } size="large">
                        <LaunchIcon/>
                    </IconButton>
                </Tooltip>
            );
        }

        return null;
    };

    return (
        loading || !opportunity ?
            <div className={ cssUtils.textCenter }><CircularProgress/></div> :
            <Card id="opportunityHead" className={ css.opportunityHead }>
                <CardHeader
                    avatar={
                        <Avatar className={ themeMode === "dark" ? css.avatarDark : css.avatar }>
                            <AttachMoneyIcon/>
                        </Avatar>
                    }
                    className={ themeMode === "dark" ? css.avatarContainerDark : css.avatarContainer }
                    title={ opportunity.title }
                    subheader={ t( "opportunity:head.phase", { phase: opportunity.phase.name } ) }
                    classes={{
                        content: css.headerContent,
                        subheader: css.avatarSubheader
                    }}
                    action={ renderOptions() }
                />

                <OpportunityHolding opportunityId={ opportunity.id }/>

                <OpportunityStatus opportunity={ opportunity } />

                <List dense disablePadding>
                    <Divider className={ classnames( cssUtils.marginTopSmall, cssUtils.marginBottomSmall )}/>
                    <ListItem>
                        <ListItemIcon>
                            <AlarmIcon/>
                        </ListItemIcon>
                        <ListItemText
                            secondary={
                                t( "opportunity:last-interact-at" ) +
                                " " +
                                timeDuration( minutesToNow( new Date( opportunity.lastInteractAt ) ) )
                            }
                        />
                    </ListItem>
                </List>

                <List dense>
                    <Divider />
                    { renderFields( fields, opportunity ) }
                </List>

                <CardActions className={ flex.justifyContentSpaceBetween }>
                    <Button size="small" onClick={ onEdit } startIcon={ <EditIcon/> }>
                        { t( "opportunity:head.edit" ) }
                    </Button>
                </CardActions>
            </Card>
    );
};

export default OpportunityHead;