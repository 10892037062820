import { selectors as requestSelectors } from "redux-request-state";

export const isMenuShown = state => state.layout.all.menuShown;
export const topBarTitle = state => state.layout.all.topBarTitle;
export const isAccessBlocked = state => state.layout.all.accessBlocked;

const topBarItemsBase = state => state.layout.topBarItems;
export const topBarItems = requestSelectors.getData( topBarItemsBase );
export const isLoadingTopBarItems = requestSelectors.isLoading( topBarItemsBase );

export const getNotifications = state => state.layout.notifications.data;
export const getNotificationsPages = state => state.layout.notifications.totalPages;
export const isLoadingNotifications = state => state.layout.notifications.loading;
export const getNotificationsUnread = state => state.layout.notifications.unread;

export const getHoldings = state => state.layout.holdings.data;
export const isLoadingHoldings = state => state.layout.holdings.loading;

const userSubscriptionBase = state => state.layout.userSubscription;
export const getUserSubscription = requestSelectors.getData( userSubscriptionBase );
export const isLoadingUserSubscription = requestSelectors.isLoading( userSubscriptionBase );