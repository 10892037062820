import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import useTranslate from "../../../hooks/use-translate.hook";
import { formatBytes } from "../../../core/formatter.utils";
import { Chart } from "../../../ui";
import { fetchSubscriptionStorage } from "./plan.actions";
import { getSubscriptionStorage, isLoadingSubscriptionStorage } from "./plan.selectors";

const Storage = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingSubscriptionStorage );
    const storage = useSelector( getSubscriptionStorage );

    const data = useMemo( () => {
        if ( !storage ) {
            return {};
        }
        const available = storage.limit -
            storage.customerUsage - storage.emailUsage - storage.imageUsage - storage.opportunityUsage;
        const series = [{
            name: t( "config:plans.storage.available" ),
            y: available,
        }, {
            name: t( "config:plans.storage.customer-usage" ),
            y: storage.customerUsage,
        }, {
            name: t( "config:plans.storage.email-usage" ),
            y: storage.emailUsage,
        }, {
            name: t( "config:plans.storage.image-usage" ),
            y: storage.imageUsage,
        }, {
            name: t( "config:plans.storage.opportunity-usage" ),
            y: storage.opportunityUsage,
        }];

        return {
            title: {
                text: t( "config:plans.storage.title" )
            },
            exporting: false,
            tooltip: {
                formatter: function () {
                    return `${this.point.name}: ${formatBytes( this.point.y )} - ${this.point.percentage.toFixed(2)}%`;
                },
            },
            plotOptions: {
                pie: {
                    cursor: "pointer",
                    showInLegend: false,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return `${this.point.name}:
                                ${formatBytes( this.point.y )} - ${this.point.percentage.toFixed(2)}%`;
                        }
                    },
                },
            },
            series: [{
                name: t( "config:plans.storage.usage" ),
                colorByPoint: true,
                innerSize: "50%",
                data: series
            }],
        };
    }, [ t, storage ] );

    useEffect( () => {
        dispatch( fetchSubscriptionStorage() );
    }, [ dispatch ] );

    if ( loading ) {
        return <CircularProgress />;
    }

    return (
        <Chart type="pie" data={ data } />
    );
};

export default Storage;