import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import ProposalDetails from "./proposal-details.component";
import ProposalStatus from "./proposal-status.component";
import DeleteProposal from "./delete-proposal.component";
import DuplicateProposal from "./duplicate-proposal.component";
import EditProposal from "./edit-proposal.component";
import css from "../opportunity.scss";

const ProposalItem = ({ proposal }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const [ menuElement, setMenuElement ] = useState( null );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openDuplicate, setOpenDuplicate ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ selected, setSelected ] = useState( false );

    const handleMenuOpen = event => setMenuElement( event.currentTarget );
    const handleMenuClose = () => setMenuElement( null );

    const toggleDelete = () => {
        setOpenDelete( !openDelete );
        handleMenuClose();
    };

    const toggleDuplicate = () => {
        setOpenDuplicate( !openDuplicate );
        handleMenuClose();
    };

    const toggleEdit = () => {
        setOpenEdit( !openEdit );
        handleMenuClose();
    };

    const toggleSelected = () => setSelected( !selected );

    const renderStatusIcon = status => {
        switch ( status ) {
            case "ACCEPTED":
                return <ThumbUpIcon/>;
            case "DECLINED":
                return <ThumbDownIcon/>;
            default:
                return <ThumbsUpDown/>;
        }
    };

    return (
        <>
            <DeleteProposal open={ openDelete } onClose={ toggleDelete } proposal={ proposal }/>
            <DuplicateProposal open={ openDuplicate } onClose={ toggleDuplicate } proposal={ proposal }/>
            <EditProposal open={ openEdit } onClose={ toggleEdit } proposal={ proposal }/>

            <ListItem
                disablePadding
                secondaryAction={
                    <IconButton onClick={ handleMenuOpen } size="large">
                        <MoreVertIcon/>
                    </IconButton>
                }
            >
                <ListItemButton
                    onClick={ toggleSelected }
                    selected={ selected }
                >
                    <ListItemAvatar>
                        <Tooltip title={ t( `opportunity:proposal.status.${proposal.status}` ) }>
                            <Avatar
                                className={
                                    proposal.status === "ACCEPTED" ?
                                        css.sold :
                                        proposal.status === "DECLINED" ? css.lost : null
                                }>
                                { renderStatusIcon( proposal.status ) }
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                        primary={ proposal.name }
                        secondary={
                            t( "opportunity:proposal.created-at", { date: dateFormat( proposal.createdAt ) } )
                        }
                    />
                </ListItemButton>
            </ListItem>
            <Collapse unmountOnExit in={ selected }>
                <CardContent>
                    <ProposalDetails proposal={ proposal }/>
                </CardContent>
            </Collapse>
            <Menu
                anchorEl={ menuElement }
                onClose={ handleMenuClose }
                open={ !!menuElement }
            >
                <MenuItem onClick={ toggleEdit }>
                    <ListItemIcon>
                        <EditIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:edit" ) }/>
                </MenuItem>
                <MenuItem onClick={ toggleDuplicate }>
                    <ListItemIcon>
                        <FileCopyIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:duplicate" ) }/>
                </MenuItem>
                <MenuItem onClick={ toggleDelete }>
                    <ListItemIcon>
                        <DeleteIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "common:exclude" ) }/>
                </MenuItem>
                <Divider/>
                {
                    [ "OPEN", "ACCEPTED", "DECLINED" ]
                        .filter( status => proposal.status !== status )
                        .map( status => (
                            <ProposalStatus
                                key={ status }
                                icon={ status !== "OPEN" ? renderStatusIcon( status ) : <ReplayIcon/> }
                                onClose={ handleMenuClose }
                                proposal={ proposal }
                                status={ status }
                            />
                        ))
                }
            </Menu>
        </>
    );
};

export default ProposalItem;