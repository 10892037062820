import { useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import useTranslate from "../../hooks/use-translate.hook";
import { Link } from "../../ui";
import CustomerSummary from "../customer-summary.component";
import Delete from "./delete-person.component";
import css from "./person.scss";

const CustomerPersonItem = ({ alignPopupLeft, customer, opportunity }) => {
    const t = useTranslate();

    const [ openDetails, setOpenDetails ] = useState( false );
    const toggleDetails = () => setOpenDetails( !openDetails );

    return (
        <>
            <ListItem
                disablePadding
                secondaryAction={ <Delete customer={ customer } opportunity={ opportunity }/> }
            >
                <ListItemButton selected={ openDetails } onClick={ toggleDetails }>
                    <ListItemText primary={ customer.name }/>
                </ListItemButton>
            </ListItem>
            <Collapse in={ openDetails } unmountOnExit>
                <List component="div" disablePadding>
                    <CustomerSummary
                        alignPopupLeft={ alignPopupLeft }
                        customer={ customer }
                    />
                    <Link href={ `/customers/${customer.id}` }>
                        <Button className={ css.accessButton } variant="contained" color="primary" size="small">
                            { t( "common:access" ) }
                        </Button>
                    </Link>
                </List>
            </Collapse>
        </>
    );
};

export default CustomerPersonItem;