import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import get from "lodash/get";
import sum from "lodash/sum";

import useTranslate from "../hooks/use-translate.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import tracker from "../util/tracker.utils";
import { timeDuration } from "../core/formatter.utils";
import { flex } from "../ui";
import css from "./opportunities-kpis.scss";

function roundNumber ( num ) {
    return +( Math.round( num + "e+2" ) + "e-2" );
}

const OpportunitiesKpis = ({ className, initialDate, finalDate, statistics, users, loadOpportunities }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const handleClick = status => () => {
        if ( !status ) {
            loadOpportunities( t( "dashboard:opportunities-kpis.created-total.title" ), {
                initialCreatedAt: initialDate,
                finalCreatedAt: finalDate,
                status,
                users: users.toString()
            });
        } else {
            const title = status === "SOLD" ?
                t( "dashboard:opportunities-kpis.sold-total.title" ) :
                t( "dashboard:opportunities-kpis.lost-total.title" );
            const params = {
                status,
                users: users.toString()
            };
            if ( status === "SOLD" ) {
                params.initialSoldAt = initialDate;
                params.finalSoldAt = finalDate;
            } else {
                params.initialLostAt = initialDate;
                params.finalLostAt = finalDate;
            }
            loadOpportunities( title, params );
        }

        tracker.logAction( "Dashboard Kips " + !status ? "CREATED" : status );
    };

    const getValueTotal = type => {
        const recurrentPrice = get( statistics, `${type}.recurrentPrice`, 0 );
        const price = get( statistics, `${type}.price`, 0 );

        return ( recurrentPrice + price );
    };

    const renderAverageTime = type => {
        const duration = timeDuration( get( statistics, `averageTime.${type}`, 0 ) );

        return (
            <div className={ css.kpiActions }>
                {
                    duration.split( " " ).map( ( item, index ) => (
                        <Typography variant="h4" key={ index }>
                            { item.slice( 0, -1 ) }
                            <span>
                                { item.slice( -1 ) }
                            </span>
                        </Typography>
                    ))
                }
            </div>
        );
    };

    const totalFinished = sum([ get( statistics, "sold.total", 0 ), get( statistics, "lost.total", 0 ) ]);
    let rate = 0;
    if ( totalFinished > 0 && get( statistics, "sold.total", 0 ) > 0 ) {
        rate = roundNumber( ( 100 * get( statistics, "sold.total", 0 ) ) / totalFinished );
    }

    return (
        <div className={ classnames( className, flex.container, css.containerPrint ) } id="opportunitiesKpis">
            <Tooltip title={ t( "dashboard:opportunities-kpis.conversion.help" ) }>
                <div className={ classnames( css.kpi, css.conversionContainer ) } id="conversionRateKpiChart">
                    <Typography>{ t( "dashboard:opportunities-kpis.conversion.rate" ) }</Typography>
                    <div className={ css.conversionPercentage }>
                        <Typography variant="h2">{ rate }</Typography>
                        <Typography variant="h5">{ "%" }</Typography>
                    </div>
                    <Typography>{ t( "dashboard:opportunities-kpis.conversion.of" ) }</Typography>
                    <Typography variant="h2">
                        { totalFinished }
                    </Typography>
                    <Typography>{ t( "dashboard:opportunities-kpis.conversion.finished" ) }</Typography>
                </div>
            </Tooltip>
            <div className={ css.kpisContainer }>
                <div className={ css.kpiContainer } id="opportunitiesCreatedTotalKpi">
                    <Tooltip title={ t( "dashboard:opportunities-kpis.created-total.help" ) }>
                        <Card square className={ classnames( css.kpi, css.kpiOpportunitiesOpen ) }>
                            <CardActionArea onClick={ handleClick( "" ) }>
                                <Typography>{ t( "dashboard:opportunities-kpis.created-total.title" ) }</Typography>
                                <Typography variant="h4">
                                    { get( statistics, "created.total", 0 ) }
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.sold-total.help" ) }>
                        <Card square className={ classnames( css.kpi, css.kpiOpportunitiesSold ) }>
                            <CardActionArea onClick={ handleClick( "SOLD" ) }>
                                <Typography>{ t( "dashboard:opportunities-kpis.sold-total.title" ) }</Typography>
                                <Typography variant="h4">
                                    { get( statistics, "sold.total", 0 ) }
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.lost-total.help" ) }>
                        <Card square className={ classnames( css.kpi, css.kpiOpportunitiesLost ) }>
                            <CardActionArea onClick={ handleClick( "LOST" ) }>
                                <Typography>{ t( "dashboard:opportunities-kpis.lost-total.title" ) }</Typography>
                                <Typography variant="h4">
                                    { get( statistics, "lost.total", 0 ) }
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Tooltip>
                </div>

                <div className={ css.kpiContainer } id="opportunitiesPriceKpi">
                    <Tooltip title={ t( "dashboard:opportunities-kpis.created-price.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitiesOpenPrice ) }>
                            <Typography>{ t( "dashboard:opportunities-kpis.created-price.title" ) }</Typography>
                            <Typography variant="h5">
                                { priceFormat( get( statistics, "created.price", 0 ) ) }
                                { " / " }
                                { priceFormat( get( statistics, "created.recurrentPrice", 0 ) ) }
                            </Typography>
                            <Typography variant="h6">
                                {
                                    t( "dashboard:opportunities-kpis.created-price.total" ) + ": " +
                                    priceFormat( getValueTotal( "created" ) )
                                }
                            </Typography>
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.sold-price.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitiesSoldPrice ) }>
                            <Typography>{ t( "dashboard:opportunities-kpis.sold-price.title" ) }</Typography>
                            <Typography variant="h5">
                                { priceFormat( get( statistics, "sold.price", 0 ) ) }
                                { " / " }
                                { priceFormat( get( statistics, "sold.recurrentPrice", 0 ) ) }
                            </Typography>
                            <Typography variant="h6">
                                {
                                    t( "dashboard:opportunities-kpis.sold-price.total" ) + ": " +
                                    priceFormat( getValueTotal( "sold" ) )
                                }
                            </Typography>
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.lost-price.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitiesLostPrice ) }>
                            <Typography>{ t( "dashboard:opportunities-kpis.lost-price.title" ) }</Typography>
                            <Typography variant="h5">
                                { priceFormat( get( statistics, "lost.price", 0 ) ) }
                                { " / " }
                                { priceFormat( get( statistics, "lost.recurrentPrice", 0 ) ) }
                            </Typography>
                            <Typography variant="h6">
                                {
                                    t( "dashboard:opportunities-kpis.lost-price.total" ) + ": " +
                                    priceFormat( getValueTotal( "lost" ) )
                                }
                            </Typography>
                        </div>
                    </Tooltip>
                </div>

                <div className={ css.kpiContainer } id="schedulingKpi">
                    <Tooltip title={ t( "dashboard:opportunities-kpis.schedules-open.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitySchedulesOpen ) }>
                            <Typography>{ t( "dashboard:opportunities-kpis.schedules-open.title" ) }</Typography>
                            <Typography variant="h4">
                                {
                                    get( statistics, "schedules", [] )
                                        .reduce( ( model, scheduling ) => model + scheduling.open, 0 )
                                }
                            </Typography>
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.schedules-accomplished.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitySchedulesAccomplished ) }>
                            <Typography>
                                { t( "dashboard:opportunities-kpis.schedules-accomplished.title" ) }
                            </Typography>
                            <Typography variant="h4">
                                {
                                    get( statistics, "schedules", [] )
                                        .reduce( ( model, scheduling ) => model + scheduling.accomplished, 0 )
                                }
                            </Typography>
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.schedules-not-accomplished.title" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunitySchedulesNotAccomplished ) }>
                            <Typography>
                                { t( "dashboard:opportunities-kpis.schedules-not-accomplished.title" ) }
                            </Typography>
                            <Typography variant="h4">
                                {
                                    get( statistics, "schedules", [] )
                                        .reduce( ( model, scheduling ) => model + scheduling.notAccomplished, 0 )
                                }
                            </Typography>
                        </div>
                    </Tooltip>
                </div>

                <div className={ css.kpiContainer } id="metricsKpi">
                    <Tooltip title={ t( "dashboard:opportunities-kpis.first-interact.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunityFirstInteract ) }>
                            <Typography>
                                { t( "dashboard:opportunities-kpis.first-interact.title" ) }
                            </Typography>
                            { renderAverageTime( "firstInteract" ) }
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.first-contact.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunityFirstContact ) }>
                            <Typography>
                                { t( "dashboard:opportunities-kpis.first-contact.title" ) }
                            </Typography>
                            { renderAverageTime( "firstContact" ) }
                        </div>
                    </Tooltip>

                    <Tooltip title={ t( "dashboard:opportunities-kpis.closing-time.help" ) }>
                        <div className={ classnames( css.kpi, css.kpiOpportunityClosingTime ) }>
                            <Typography>
                                { t( "dashboard:opportunities-kpis.closing-time.title" ) }
                            </Typography>
                            { renderAverageTime( "closingTime" ) }
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default OpportunitiesKpis;