import { useEffect, useState, useCallback, useMemo } from "react";
import config from "config";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Route, Routes, matchPath, Navigate } from "react-router-dom";
import { StompSessionProvider } from "react-stomp-hooks";
import RefreshIcon from "@mui/icons-material/Refresh";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import offlineExport from "highcharts/modules/offline-exporting";
import noData from "highcharts/modules/no-data-to-display";
import dayjs from "dayjs";
import get from "lodash/get";
import intl from "react-intl-universal";
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import classnames from "classnames";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import tracker from "../util/tracker.utils";
import { loadInfo } from "../core/core.actions";
import { getLocale, getPreferences, getThemeMode, getUser, isLoadingInfo } from "../core/core.selectors";
import { locales } from "../core/core.utils";
import { getSubscription } from "../subscription/subscription.selectors";
import { Config } from "../config";
import { CustomerList, Customer } from "../customer";
import { Dashboard } from "../dashboard";
import { Home } from "../home";
import { Importations, NewImportation, ImportationResults } from "../importation";
import { Marketplace } from "../marketplace";
import { OAuthAuthorize } from "../oauth";
import { NewOpportunity, OpportunityList, Opportunity } from "../opportunity";
import { Reports } from "../report";
import { Schedule } from "../schedule";
import { EmailVerification, UserForm } from "../user";
import { WebCapture, Lead, LeadBody } from "../web-capture";
import { Welcome } from "../welcome";
import { EmptyState } from "../ui";
import { CloseTab, Menu, TopNavbar, RouteNotFound } from "./";
import Chat from "./chat.component";
import LoadingView from "./loading-view.component";
import SubscriptionExpiration from "./subscription-expiration.component";
import UserUnverified from "./user-unverified.component";
import { setAccessBlocked, fetchNotificationsUnread } from "./layout.actions";
import { isAccessBlocked, getNotificationsUnread } from "./layout.selectors";
import css from "./layout.scss";

const GRACE_MINUTES = 10;
const isOutOfHours = ( startAt, endAt ) => {
    const now = dayjs();

    const startTime = startAt.start.split( ":" );
    const endTime = endAt.end.split( ":" );
    const startOfDay = dayjs()
        .hour( startTime[ 0 ] )
        .minute( startTime[ 1 ] )
        .subtract( GRACE_MINUTES, "minutes" );
    const endOfDay = dayjs()
        .hour( endTime[ 0 ] )
        .minute( endTime[ 1 ] )
        .add( GRACE_MINUTES, "minutes" );

    return now.isBefore( startOfDay ) || now.isAfter( endOfDay );
};

const renderChild = Component => <Component className={ css.subpanel }/>;

// ---------------------------------------------------------------------------------------------------------------------
// Component
// ---------------------------------------------------------------------------------------------------------------------

const MenuLayout = () => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const [ error ] = useErrorBoundary();

    const locale = useSelector( getLocale );
    const themeMode = useSelector( getThemeMode );
    const subscription = useSelector( getSubscription );
    const user = useSelector( getUser );
    const preferences = useSelector( getPreferences );
    const loadingInfo = useSelector( isLoadingInfo );
    const accessBlocked = useSelector( isAccessBlocked );
    const notificationsUnread = useSelector( getNotificationsUnread );

    const [ localesLoaded, setLocalesLoaded ] = useState( false );
    const [ isMounted, setIsMounted ] = useState( false );
    const prevLoadingInfo = usePrevious( loadingInfo );
    const location = useLocation();

    const checkAccessOutOfHours = useCallback( () => {
        let blockAccess = false;
        if ( !user.admin && subscription.blockOutOfHours ) {
            if ( dayjs().day() === 0 ) {
                if ( subscription.officeHours.workOnSunday ) {
                    blockAccess = isOutOfHours(
                        subscription.officeHours.sundayStartAt,
                        subscription.officeHours.sundayEndAt,
                    );
                }
            } else if ( dayjs().day() === 6 ) {
                if ( subscription.officeHours.workOnSaturday ) {
                    blockAccess = isOutOfHours(
                        subscription.officeHours.saturdayStartAt,
                        subscription.officeHours.saturdayEndAt,
                    );
                }
            } else {
                blockAccess = isOutOfHours(
                    subscription.officeHours.startAt,
                    subscription.officeHours.endAt,
                );
            }
        }

        dispatch( setAccessBlocked( blockAccess ) );
    }, [ dispatch, user, subscription ] );

    const titleTemplate = useMemo( () => {
        return `${notificationsUnread ? `(${notificationsUnread})` : ""} %s - LeadStation`;
    }, [ notificationsUnread ] );

    const trackedPages = useMemo( () => [
        "/welcome",
        "/home",
        "/schedule",
        "/dashboard",
        "/customers",
        "/opportunities",
        "/importations",
        "/leads",
        "/reports",
        "/marketplace",
        "/config"
    ], [] );


    const getSubPathAmplitude = useCallback( ( name, pathname ) => {
        const separateSubpath = pathname.split( "/" ).filter( item => item !== name && item !== "" );
        const formattedSubpath = separateSubpath.map( item => {
            return item.charAt( 0 ).toUpperCase() + item.slice( 1 );
        });

        return formattedSubpath.join( " " );
    }, [] );

    useEffect( () => {
        dispatch( loadInfo() );
    }, [ dispatch ] );
    useEffect( () => {
        exporting( Highcharts );
        exportData( Highcharts );
        offlineExport( Highcharts );
        noData( Highcharts );
    }, [] );
    useEffect( () => {
        dispatch( fetchNotificationsUnread() );
    }, [ dispatch ] );
    useEffect( () => {
        const newLocale = locale && isMounted;
        const loggedUser = locale && user && isMounted;
        if ( newLocale || loggedUser ) {
            intl.init({
                currentLocale: locale,
                fallbackLocale: "pt-BR",
                locales
            }).then( () => {
                Highcharts.setOptions({
                    credits: {
                        enabled: false
                    },
                    lang: {
                        viewFullscreen: t( "common:highcharts.view-full-screen" ),
                        printChart: t( "common:highcharts.print-chart" ),
                        contextButtonTitle: t( "common:highcharts.menu-chart" ),
                        downloadJPEG: t( "common:highcharts.download-jpeg" ),
                        downloadPNG: t( "common:highcharts.download-png" ),
                        downloadPDF: t( "common:highcharts.download-pdf" ),
                        downloadCSV: t( "common:highcharts.download-csv" ),
                        downloadXLS: t( "common:highcharts.download-xls" )
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    "viewFullscreen", "printChart", "downloadJPEG",
                                    "downloadPNG", "downloadPDF", "downloadCSV", "downloadXLS"
                                ]
                            }
                        }
                    }
                });
                dayjs.locale( t( "common:full-locale" ) );
                setLocalesLoaded( true );
            });
        }
        setIsMounted( true );
    }, [ isMounted, locale, t, user ]);
    useEffect( () => {
        if ( prevLoadingInfo && !loadingInfo ) {
            checkAccessOutOfHours();
        }

        const intervalCheckAccess = setInterval( checkAccessOutOfHours, 60000 );
        return () => clearInterval( intervalCheckAccess );
    }, [ loadingInfo, prevLoadingInfo, checkAccessOutOfHours, ] );

    useEffect( () => {
        user && tracker.setUser( user.admin, user.email );
    }, [ user ] );

    useEffect( () => {
        if ( trackedPages.includes( location.pathname ) ) {
            tracker.pageChange( location.pathname );
        } else if ( location.pathname.startsWith( "/config" ) ) {
            const subpath = getSubPathAmplitude( "config", location.pathname );
            tracker.pageChange( "/config", subpath );
        } else if ( location.pathname.startsWith( "/marketplace") ) {
            const subpath = getSubPathAmplitude( "marketplace", location.pathname );
            tracker.pageChange( "/marketplace", subpath );
        } else if ( location.pathname.match(
            /^\/opportunities\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/ )
        ) {
            tracker.pageChange( "/opportunity" );
        }
    }, [ trackedPages, location.pathname, getSubPathAmplitude ] );

    if ( !localesLoaded || loadingInfo || !user ) {
        return <LoadingView />;
    }

    if ( matchPath( { path: "leads/:id/body", caseSensitive: false, end: true }, location.pathname ) ) {
        return <Routes> <Route path="leads/:id/body" element={ <LeadBody /> }/> </Routes>;
    } else if ( matchPath( { path: "/close", caseSensitive: false, end: true }, location.pathname ) ) {
        return <Routes>  <Route path="close" element={ <CloseTab/> }/> </Routes>;
    } else if ( matchPath( { path: "/oauth", caseSensitive: false }, location.pathname ) ) {
        return <Routes> <Route path="oauth" element={ <OAuthAuthorize/> }/>  </Routes>;
    }
    const emailVerificationRoute = matchPath( {
        path: "emails/verify/:token",
        caseSensitive: false,
        end: true
    }, location.pathname );
    const userUnverified = !user.verified;

    return (
        <div className={ css.layout }>
            <Helmet defaultTitle="LeadStation" titleTemplate={ titleTemplate } />
            <Helmet script={[{ src: config.get( "client.google" ) }]} />

            <div className={ css.panel }>
                { process.env.NODE_ENV === "production" && <Chat /> }
                <StompSessionProvider url={ config.get( "client.websocketNotifications" ) }>
                    <TopNavbar />
                </StompSessionProvider>
                <div className={ classnames( css.layout, themeMode === "dark" && css.layoutDark ) }>
                    {
                        accessBlocked &&
                            <EmptyState icon={ TimerOffIcon } message={ t( "layout:blocked-out-of-hours" ) }/>
                    }
                    {
                        error &&
                            <EmptyState icon={ RefreshIcon } message={ t( "common:error.refresh" ) }/>
                    }
                    {
                        userUnverified && !emailVerificationRoute &&
                            <UserUnverified />
                    }
                    {
                        emailVerificationRoute &&
                            <Routes>
                                <Route path="emails/verify/:token" element={ <EmailVerification/> } />
                            </Routes>
                    }
                    {
                        !error && !accessBlocked && !emailVerificationRoute && !userUnverified &&
                            [
                                <Menu key="menu"/>,
                                <div key="children" className={ css.panel }>
                                    {
                                        subscription?.expireAt &&
                                            <SubscriptionExpiration subscription={ subscription } user={ user } />
                                    }
                                    <Routes>

                                        { /* Customer */ }
                                        <Route path="customers" element={ renderChild( CustomerList ) }/>
                                        <Route path="customers/:id" element={ renderChild( Customer ) }/>

                                        { /* Dashboard */ }
                                        <Route path="dashboard" element={ renderChild( Dashboard ) }/>
                                        { /* Home */ }
                                        <Route path="home" element={ renderChild( Home ) }
                                        />
                                        { /* Importation */ }
                                        <Route
                                            path="importations"
                                            element={ renderChild( Importations ) }
                                        />
                                        <Route
                                            path="importations/new"
                                            element={ renderChild( NewImportation ) }
                                        />
                                        <Route
                                            path="importations/:id/results"
                                            element={ renderChild( ImportationResults ) }
                                        />

                                        {/* Opportunity */}

                                        <Route
                                            path="opportunities"
                                            element={ renderChild( OpportunityList ) }
                                        />
                                        <Route
                                            path="opportunities/:id/*"
                                            element={ renderChild( Opportunity ) }
                                        />

                                        {/* Report */}
                                        <Route path="reports" element={ renderChild( Reports ) }/>

                                        {/* Schedule */}
                                        <Route path="schedule" element={ renderChild( Schedule ) }/>

                                        {/* User */}
                                        <Route path="profile" element={ renderChild( UserForm ) }/>

                                        { /* Web Capture */ }
                                        <Route path="leads" element={ renderChild( WebCapture ) }/>
                                        <Route path="leads/:id/*" element={ renderChild( Lead ) }/>

                                        { /* Only Admins */ }
                                        { /* Welcome */ }
                                        { user.admin &&
                                            <Route path="welcome" element={ renderChild( Welcome ) }/>
                                        }
                                        {/* Configs */}
                                        { user.admin && <Route path="config/*" element={ renderChild( Config ) }/> }

                                        {/* Marketplace */}
                                        {
                                            user.admin &&
                                                <Route path="marketplace/*" element={ renderChild( Marketplace ) } />
                                        }
                                        <Route
                                            path={ "/" }
                                            element={
                                                <Navigate replace to={ get( preferences, "indexRoute", "home" ) } /> }
                                        />

                                        <Route path="/*" element={ <RouteNotFound/> }/>

                                    </Routes>

                                    <NewOpportunity/>
                                </div>
                            ]
                    }
                </div>
            </div>
        </div>
    );
};

// ---------------------------------------------------------------------------------------------------------------------
// Container
// ---------------------------------------------------------------------------------------------------------------------
const ErrorContext = withErrorBoundary( MenuLayout );
export default ErrorContext;