import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { fetchCreditCards } from "./plan.actions";
import { getCreditCards, isLoadingCreditCards } from "./plan.selectors";
import NewCreditCard from "./new-credit-card.component";

const CreditCards = ({ setFieldValue, values }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingCreditCards );
    const creditCards = useSelector( getCreditCards );

    useEffect( () => {
        dispatch( fetchCreditCards() );
    }, [ dispatch ] );

    const isSelected = id => id === get( values.creditCard, "id", "" );
    const handleChange = id => () => setFieldValue( "creditCard.id", id );

    return (
        <List>
            { loading && <LinearProgress /> }
            {
                creditCards && creditCards.map( creditCard => (
                    <ListItemButton
                        key={ creditCard.id }
                        dense
                        onClick={ handleChange( creditCard.id ) }
                        selected={ isSelected( creditCard.id ) }
                    >
                        <ListItemIcon>
                            <Radio
                                checked={ isSelected( creditCard.id ) }
                                value={ creditCard.id }
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={ t( "config:billing.credit-card.description", { number: creditCard.number } ) }
                            secondary={ `${creditCard.name} - ${creditCard.validity}` }
                        />
                    </ListItemButton>
                ))
            }
            <ListItemButton dense onClick={ handleChange( null ) } selected={ isSelected( null ) }>
                <ListItemIcon>
                    <Radio
                        checked={ isSelected( null ) }
                        value={ null }
                    />
                </ListItemIcon>
                <ListItemText
                    primary={ t( "config:billing.credit-card.new" ) }
                />
            </ListItemButton>
            { !get( values.creditCard, "id" ) && <NewCreditCard /> }
        </List>
    );
};

export default CreditCards;