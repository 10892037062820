import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import {
    actions as smtpServerActions,
    selectors as smtpServerSelectors,
    preDefaultSettings,
    securityTypes
} from "../smtp-server";
import { Checkbox, DialogInfo, Dropdown, Input, cssUtils, flex } from "../ui";
import { saveSmtpServer } from "./user.actions";
import { SMTP_SERVER_FORM } from "./user.constants";
import GmailAuth from "./gmail-auth.component";

const LINK_AMAZON_HELP = "https://docs.aws.amazon.com/pt_br/ses/latest/DeveloperGuide/smtp-credentials.html";

const SmtpServer = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const defaultSettings = useSelector( smtpServerSelectors.getDefaultSettings );
    const settings = useSelector( smtpServerSelectors.getSettings );
    const loadingSettings = useSelector( smtpServerSelectors.isLoadingSettings );
    const loadingDefaultSettings = useSelector( smtpServerSelectors.isLoadingDefaultSettings );
    const loading = loadingSettings || loadingDefaultSettings;

    const [ preSettings, setPreSettings ] = useState( "" );
    const [ openSubmitted, setOpenSubmitted ] = useState( false );

    const closeSubmitted = () => setOpenSubmitted( false );

    const formik = useForm({
        enableReinitialize: true,
        form: SMTP_SERVER_FORM,
        initialValues: {
            host: null,
            securityType: null,
            port: null,
            username: null,
            password: null,
            email: null,
            ...settings,
            type: settings ? "CUSTOM" : "LS"
        },
        onSubmit: values => dispatch( saveSmtpServer( values ) ),
        validate: values => {
            const errors = {};
            if ( !values || values.type === "LS" ) {
                return errors;
            }
            if ( !values.host ) {
                errors.host = "common:validation.required";
            }
            if ( !values.securityType ) {
                errors.securityType = "common:validation.selection";
            }
            if ( !values.port ) {
                errors.port = "common:validation.required";
            }
            if ( !values.username ) {
                errors.username = "common:validation.required";
            }
            if ( !values.password ) {
                errors.password = "common:validation.required";
            }
            if ( !values.email ) {
                errors.email = "common:validation.required";
            }
            return errors;
        }
    });

    const { setFieldValue } = formik;

    const sourcePreSettings = useMemo( () => {
        const options = [
            { value: "GMAIL", label: "Google (Gmail)" },
            { value: "OUTLOOK", label: "Outlook.com, Hotmail" },
            { value: "OFFICE", label: "Office 365" },
            { value: "MS_EXCHANGE", label: "MS Exchange" },
            { value: "AMAZON", label: "Amazon Workmail" },
            { value: "ZOHO", label: "Zoho Mails" },
            { value: "ICLOUD", label: "iCloud" },
            { value: "AOL", label: "Aol" },
            { value: "YAHOO", label: "Yahoo!" },
            { value: "KINGHOST", label: "Kingost" },
        ];

        if ( defaultSettings ) {
            options.unshift({ value: "SUBSCRIPTION", label: t( "user:smtp-server.pre-settings.subscription" ) });
        }

        return options;
    }, [ t, defaultSettings ] );

    const submitMessage = useMemo( () => {
        if ( !openSubmitted ) {
            return "";
        }
        if ( !formik.error ) {
            return t( "user:smtp-server.submit.success" );
        }
        return formik.error.status === 422 ?
            t( "user:smtp-server.submit.auth-error" ) :
            t( "user:smtp-server.submit.unknown-error" );
    }, [ t, openSubmitted, formik.error ] );

    const prevPreSettings = usePrevious( preSettings );
    const prevSelectedType = usePrevious( formik.values.type );
    const prevLoading = usePrevious( loading );

    useEffect( () => {
        dispatch( smtpServerActions.fetchSmtpServer() );
        dispatch( smtpServerActions.fetchSmtpServerDefault() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( preSettings && prevPreSettings !== preSettings ) {
            let settings;
            if ( preSettings === "SUBSCRIPTION" ) {
                settings = defaultSettings;
            } else {
                settings = preDefaultSettings[ preSettings ];
            }
            setFieldValue( "host", settings.host );
            setFieldValue( "port", settings.port );
            setFieldValue( "securityType", settings.securityType );
            setFieldValue( "auth", settings.auth );
        }
    }, [ defaultSettings, setFieldValue, prevPreSettings, preSettings ] );
    useEffect( () => {
        if ( formik.values.type !== prevSelectedType ) {
            setPreSettings( "" );
        }
    }, [ formik.values.type, prevSelectedType ] );
    useEffect( () => {
        if ( prevLoading && !loading ) {
            setPreSettings( "" );
        }
    }, [ loading, prevLoading ] );

    return (
        <Card>
            <CardHeader
                title="SMTP"
                subheader={ t( "user:smtp-server.call-to-action" ) }
            />

            <Form formik={ formik } noValidate>
                <CardContent>
                    { loading && <LinearProgress/> }
                    <div className={ flex.container }>
                        <Field
                            name="type"
                            label={ t( "user:smtp-server.type.label" ) }
                            component={ Dropdown }
                            className={ flex.fill }
                            source={[
                                { value: "LS", label: t( "user:smtp-server.type.LS" ) },
                                { value: "CUSTOM", label: t( "user:smtp-server.type.CUSTOM" ) }
                            ]}
                            fullWidth={ false }
                            sort={ false }
                        />
                        {
                            formik.values.type === "CUSTOM" &&
                                <div className={ classnames( cssUtils.marginLeft, flex.fill ) }>
                                    <Dropdown
                                        label={ t( "user:smtp-server.pre-settings.label" ) }
                                        sort={ false }
                                        source={ sourcePreSettings }
                                        onChange={ event => setPreSettings( event.target.value ) }
                                        disabled={ !!formik.values.googleAccount && !!formik.values.googleAccount.id }
                                        value={ preSettings }
                                    />
                                    {
                                        preSettings === "GMAIL" &&
                                            <Typography color="textSecondary">
                                                { t( "user:smtp-server.gmail-help" ) }
                                            </Typography>
                                    }
                                    {
                                        preSettings === "AMAZON" &&
                                            <Typography color="textSecondary">
                                                { t( "user:smtp-server.amazon-help" ) }
                                                <a href={ LINK_AMAZON_HELP } target="_blank">
                                                    { t( "user:smtp-server.amazon-help-docs" ) }
                                                </a>
                                            </Typography>
                                    }
                                </div>
                        }
                    </div>
                    <hr/>
                    {
                        formik.values.type === "CUSTOM" &&
                            <GmailAuth
                                setFieldValue={ formik.setFieldValue }
                                values={ formik.values }
                            />
                    }
                    {
                        formik.values.type === "CUSTOM" && !formik.values.googleAccount &&
                            <>
                                <hr/>
                                <div className={ flex.container }>
                                    <Field
                                        name="host"
                                        label={ t( "user:smtp-server.host" ) }
                                        component={ Input }
                                        className={ classnames( cssUtils.marginRight, flex.fill ) }
                                        required
                                    />
                                    <Field
                                        name="securityType"
                                        label={ t( "user:smtp-server.security-type" ) }
                                        component={ Dropdown }
                                        className={ classnames( cssUtils.marginRight, flex.item20 ) }
                                        fullWidth={ false }
                                        source={ securityTypes.map( securityType => ({
                                            value: securityType,
                                            label: securityType === "NONE" ?
                                                t( "common:none" ) :
                                                securityType
                                        }))}
                                        required
                                    />
                                    <Field
                                        name="port"
                                        label={ t( "user:smtp-server.port" ) }
                                        type="number"
                                        className={ classnames( cssUtils.marginRight, flex.item10 ) }
                                        component={ Input }
                                        required
                                    />
                                    <Field
                                        name="auth"
                                        label={ t( "user:smtp-server.auth" ) }
                                        fullWidth={ false }
                                        type="checkbox"
                                        className={ flex.item20 }
                                        component={ Checkbox }
                                        required
                                    />
                                </div>
                                <div className={ flex.container }>
                                    <div className={ classnames( cssUtils.marginRight, flex.fill ) }>
                                        <Field
                                            name="username"
                                            label={ t( "user:smtp-server.username.label" ) }
                                            component={ Input }
                                            required
                                        />
                                        <Typography color="textSecondary" variant="caption">
                                            { t( "user:smtp-server.username.help" ) }
                                        </Typography>
                                    </div>
                                    <Field
                                        name="password"
                                        type="password"
                                        label={ t( "common:password" ) }
                                        className={ classnames( cssUtils.marginRight, flex.fill ) }
                                        component={ Input }
                                        required
                                    />
                                    <Field
                                        name="email"
                                        label={ t( "common:email" ) }
                                        className={ flex.fill }
                                        component={ Input }
                                        required
                                    />
                                </div>
                            </>
                    }
                </CardContent>
                <CardActions>
                    <Button color="primary" type="submit" disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Form>

            <DialogInfo
                open={ openSubmitted }
                message={ submitMessage }
                onClose={ closeSubmitted }
                maxWidth="xs"
            />
            { formik.submitting && <LinearProgress/> }
        </Card>
    );
};

export default SmtpServer;