export const LOAD_INFO = "x/LOAD_INFO";
export function loadInfo () {
    return {
        type: LOAD_INFO
    };
}

export const SET_PREFERENCES = "x/SET_PREFERENCES";
export function setPreferences ( preferences ) {
    return {
        type: SET_PREFERENCES,
        payload: preferences
    };
}

export const SET_THEME_MODE = "x/SET_THEME_MODE";
export const setThemeMode = mode => ({
    type: SET_THEME_MODE,
    payload: mode,
});

export const REQUEST_INFO = "x/REQUEST_INFO";
export const requestInfo = () => ({
    type: REQUEST_INFO
});

export const SET_INFO = "x/SET_INFO";
export function setInfo ( user, loggedHolding ) {
    return {
        type: SET_INFO,
        payload: { user, locale: user && user.locale, loggedHolding }
    };
}

export const SET_LOCALE = "x/SET_LOCALE";
export const setLocale = locale => ({
    type: SET_LOCALE,
    payload: locale
});