import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";

import history from "../../../history";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link, cssUtils } from "../../ui";
import css from "../../ui/timeline/timeline.scss";

const translateIntegrationFields = ( t, key ) => {
    switch ( key ) {
        case "adId":
            return t( "customer:timeline.integration-fields.adId" );
        case "name":
        case "Full name":
            return t( "customer:timeline.integration-fields.name" );
        case "phone":
        case "Phone number":
            return t( "customer:timeline.integration-fields.phone" );
        case "message":
            return t( "customer:timeline.integration-fields.message" );
        case "source":
            return t( "customer:timeline.integration-fields.source" );
        case "companyName":
            return t( "customer:timeline.integration-fields.company-name" );
        default:
            return t( `customer:timeline.integration-fields.${key}` ).defaultMessage( key );
    }
};

const translateIntegrationChannel = ( t, integrationChannel ) => {
    switch ( integrationChannel ) {
        case "FACEBOOK":
            return t( "customer:timeline.customer-created-facebook" );
        case "EXACT_SALES":
            return t( "customer:timeline.customer-created-exact-sales" );
        case "ICARROS":
            return t( "customer:timeline.customer-created-icarros" );
        case "LEADLOVERS":
            return t( "customer:timeline.customer-created-leadlovers" );
        case "MERCADO_LIBRE":
            return t( "customer:timeline.customer-created-mercado-libre" );
        case "MOBIAUTO":
            return t( "customer:timeline.customer-created-mobiauto" );
        case "MKT2EASY":
            return t( "customer:timeline.customer-created-mkt2easy" );
        case "RD_STATION":
            return t( "customer:timeline.customer-created-rd-station" );
        case "OLX":
            return t( "customer:timeline.customer-created-olx" );
        case "USADOSBR":
            return t( "customer:timeline.customer-created-usadosbr" );
        case "WEBMOTORS":
            return t( "customer:timeline.customer-created-webmotors" );
        default:
            return t( "customer:timeline.customer-created" );
    }
};

const CreationEvent = ({ customer, event }) => {
    const location = useLocation();
    const t = useTranslate();

    return (
        <TimelineItem classes={{ avatar: css.creationIcon }} icon={ <StarIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={
                    customer.integrationChannel ?
                        translateIntegrationChannel( t, customer.integrationChannel ) :
                        customer.leadId ?
                            event.user ?
                                t( "customer:timeline.customer-created-lead-user" ) :
                                t( "customer:timeline.customer-created-lead" ) :
                            customer.importationId ?
                                t( "customer:timeline.customer-created-by-importation" ) :
                                event.user ?
                                    t( "customer:timeline.customer-created-user" ) :
                                    t( "customer:timeline.customer-created" )
                }
            />
            {
                customer.createdByHolding &&
                    <Typography color="textSecondary" variant="subtitle2">
                        { t( "customer:timeline.customer-created-by-holding" ) }
                    </Typography>
            }
            {
                !isEmpty( customer.integrationFields ) &&
                    Object.keys( customer.integrationFields )
                        .filter( key => customer.integrationFields[ key ].constructor !== Object )
                        .map( ( key, index ) => (
                            <div className={ css.change } key={ index }>
                                <Typography variant="body2">
                                    { translateIntegrationFields( t, key ) }{ ": " }
                                </Typography>
                                <ul className={ css.emailChangeList }>
                                    <li>
                                        <Typography variant="body2">
                                            <mark className={ cssUtils.highlightNew }>
                                                {
                                                    typeof customer.integrationFields[ key ] === "boolean" ?
                                                        customer.integrationFields[ key ] ?
                                                            t( "common:yes" ) :
                                                            t( "common:no" ) :
                                                        toString( customer.integrationFields[ key ] )
                                                }
                                            </mark>
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        ))
            }
            {
                customer.leadId &&
                    <Button
                        size="small"
                        onClick={ () => {
                            const width = 550;
                            const height = 550;
                            const left = ( screen.width / 2 ) - ( width / 2 );
                            const top = ( screen.height / 2 ) - ( height / 2 );
                            window.open(
                                history.createHref({
                                    ...location,
                                    pathname: `/leads/${customer.leadId}/body`
                                }),
                                "Lead",
                                `width=${width}, height=${height}, top=${top}, left=${left}`
                            );
                        }}
                    >
                        { t( "opportunity:lead.open-body" ) }
                    </Button>
            }
            {
                customer.importationId &&
                    <Link href={ `/importations/${customer.importationId}/results` }>
                        <Button size="small">
                            { t( "customer:timeline.access-importation" ) }
                        </Button>
                    </Link>
            }
            {
                customer.migrationSystem &&
                    <Typography>
                        {
                            t( "customer:timeline.from-migration", {
                                system: t(
                                    `config:migrations.system.${customer.migrationSystem}`
                                )
                            })
                        }
                    </Typography>
            }
        </TimelineItem>
    );
};

export default CreationEvent;