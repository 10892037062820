import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import config from "config";
import intl from "react-intl-universal";

import useTranslate from "../hooks/use-translate.hook";
import tracker from "../util/tracker.utils";
import { setLocale } from "../core/core.actions";
import { getLocale } from "../core/core.selectors";
import { locales, supportedLanguages } from "../core/core.utils";
import { AcceptInvite } from "../invitation";
import { Login, ForgotPassword, ResetPassword, TwoFactorVerification } from "../login";
import { Signup } from "../signup";
import LoadingView from "./loading-view.component";
import SelectLocale from "./select-locale.component";
import cssUtils from "../core/css-utils.scss";
import css from "./layout.scss";

const closestLanguage = language => {
    if ( supportedLanguages.includes( language ) ) {
        return language;
    }

    return supportedLanguages
        .find( locale => language.startsWith( locale.split( "-" )[ 0 ] ) );
};

const NoMenuLayout = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const locale = useSelector( getLocale );
    const [ ready, setReady ] = useState( false );

    const poweredByICarros = config.has( "client.poweredBy" ) && config.get( "client.poweredBy" ) === "icarros";


    useEffect( () => {
        if ( location.pathname === "/login" ) {
            tracker.pageChange( location.pathname );
        }
    }, [ location.pathname ] );

    useEffect( () => {
        const language = navigator.language || navigator.userLanguage;
        const locale = closestLanguage( language ) || "pt-BR";
        dispatch( setLocale( locale ) );
    }, [ dispatch ] );

    useEffect( () => {
        if ( locale ) {
            setReady( false );
            intl.init({
                currentLocale: locale,
                fallbackLocale: "pt-BR",
                locales,
            }).then( () => setReady( true ) );
        }
    }, [ locale ] );

    if ( !ready ) {
        return <LoadingView />;
    }

    return (
        <div className={ classnames( css.rootNoMenu, css.layout ) }>
            <Helmet defaultTitle="LeadStation" titleTemplate="%s - LeadStation" />
            <div className={ classnames( cssUtils.overflowAuto, css.contentContainer ) }>
                <Card
                    className={ classnames( cssUtils.autoHeight, css.content, css.rootNoMenu ) }
                    square
                >
                    <CardContent className={ css.content }>
                        <div className={
                            classnames(
                                css.noMenuLogoDimension,
                                poweredByICarros && css.noMenuLogoCarros,
                                poweredByICarros !== undefined && !poweredByICarros && css.noMenuLogo
                            )
                        }
                        />
                        <Routes>
                            <Route path="signup" element={ <Signup /> } />
                            <Route path="login/*" element={ <Login /> } />
                            <Route
                                path="login/verify/:accessToken"
                                element={ <TwoFactorVerification /> }
                            />
                            <Route path="forgotpassword" element={ <ForgotPassword /> } />
                            <Route path="resetpassword/:id" element={ <ResetPassword /> } />
                            <Route path="invitation/:id" element={ <AcceptInvite /> } />
                            <Route index element={ <Navigate replace to={"/login"}/> } />
                            <Route path="/*"
                                element={
                                    <Navigate
                                        replace
                                        to={`/login?next=${location.pathname}&${location.search}`}
                                    />
                                }
                            />
                        </Routes>
                        <div className={ css.localeContainer }>
                            <SelectLocale />
                        </div>
                    </CardContent>
                    {
                        poweredByICarros &&
                            <div className={ css.leadStationPoweredMessage }
                            >
                                <Typography align="center">
                                    { t( "layout:leadstation-powered-by.icarros" ) }
                                </Typography>
                            </div>
                    }
                </Card>
            </div>
            <div className={ css.marketingContainer }>
                <div className={ css.marketingImage }>
                    {
                        location.pathname === "/signup" &&
                                <img
                                    className={ css.marketingRegisterDays }
                                    src={ `https://img.leadstation.com.br/marketing/register-days-${locale}-v3.png` }
                                />
                    }
                </div>
            </div>
        </div>
    );

};

export default NoMenuLayout;