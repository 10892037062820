import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { useDateFormat } from "../hooks/use-date-format.hook";
import css from "./layout.scss";

const SubscriptionExpiration = ({ subscription, user }) => {
    const t = useTranslate();
    const dateFormat = useDateFormat( t );

    const handleHireClick = () => history.push( "/config/subscription/plans" );

    return (
        <Alert
            className={ css.alertExpire }
            elevation={ 1 }
            severity="warning"
            action={ user.admin && !subscription.partnerId &&
                <Button
                    color="inherit"
                    onClick={ handleHireClick }
                    size="small"
                >
                    { t( "layout:top-navbar.hire" ) }
                </Button>
            }
        >
            {
                t( "layout:top-navbar.expire-at", {
                    date: dateFormat( subscription.expireAt )
                } )
            }
        </Alert>
    );
};

export default SubscriptionExpiration;
