import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubHeader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CommentIcon from "@mui/icons-material/Comment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EmailIcon from "@mui/icons-material/Email";
import EmailReadIcon from "@mui/icons-material/Drafts";
import EventIcon from "@mui/icons-material/Event";
import GroupIcon from "@mui/icons-material/Group";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import SmsIcon from "@mui/icons-material/Sms";
import AccessExpiredIcon from "@mui/icons-material/HourglassDisabled";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { isLoadingNotifications, getNotifications, getNotificationsPages } from "./layout.selectors";
import { fetchNotifications, readNotification, readAllNotifications } from "./layout.actions";
import { getNotificationHref, useNotificationDescription } from "./layout.utils";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import { Dialog, EmptyState, cssUtils } from "../ui";
import FacebookIcon from "../ui/icons/facebook.component";
import css from "./top-navbar.scss";

const icons = {
    CUSTOMER_CHANGE: GroupIcon,
    CUSTOMER_OPPORTUNITY_CREATION: AttachMoneyIcon,
    CUSTOMER_OPPORTUNITY_STATUS: AttachMoneyIcon,
    CUSTOMER_OPPORTUNITY_CHANGE: AttachMoneyIcon,
    CUSTOMERS_UPDATED: GroupIcon,
    EMAIL: EmailIcon,
    EMAIL_READ: EmailReadIcon,
    EXPORTATION_CREATED: CloudDownloadIcon,
    FACEBOOK_PAGE_EXPIRED: FacebookIcon,
    FACEBOOK_PAGE_EXPIRING: FacebookIcon,
    IMPORTATION_FINISHED: ImportContactsIcon,
    MAILTOP_OPPORTUNITY_COMMENT: CommentIcon,
    OPPORTUNITY_CREATION: AttachMoneyIcon,
    OPPORTUNITY_CHANGE_USER: AttachMoneyIcon,
    OPPORTUNITY_COMMENT: CommentIcon,
    OPPORTUNITY_COMMENT_MENTION: CommentIcon,
    OPPORTUNITIES_GENERATED: DoneAllIcon,
    OPPORTUNITIES_UPDATED: DoneAllIcon,
    SCHEDULING: EventIcon,
    SCHEDULING_CREATION: EventIcon,
    SCHEDULING_CHANGE_USER: EventIcon,
    SCHEDULING_ACCOMPLISHED: EventIcon,
    SCHEDULING_NOT_ACCOMPLISHED: EventIcon,
    SCHEDULING_MENTION: EventIcon,
    SMS_RECEIVED: SmsIcon,
    PORTAL_EXPIRED: AccessExpiredIcon,
};

const Notifications = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );
    const notificationDescription = useNotificationDescription( t );

    const isLoading = useSelector( isLoadingNotifications );
    const notifications = useSelector( getNotifications );
    const totalPages = useSelector( getNotificationsPages );
    const [ page, setPage ] = useState( 1 );
    const [ confirmReadAll, setConfirmReadAll ] = useState( false );

    const incrementPage = () => {
        if ( isLoading ) {
            return;
        }
        setPage( page + 1 );
    };

    const toggleConfirmReadAll = () => setConfirmReadAll( confirmReadAll => !confirmReadAll );

    const readAll = () => {
        toggleConfirmReadAll();
        dispatch( readAllNotifications() );
        onClose();
    };

    const handleClick = notification => () => {
        if ( !notification.read ) {
            dispatch( readNotification( notification ) );
        }
        if ( notification.type === "EXPORTATION_CREATED" ) {
            const link = document.createElement( "a" );
            link.href = getNotificationHref( notification );
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
        } else {
            history.push( getNotificationHref( notification ) );
        }
        onClose();
    };

    useEffect( () => {
        dispatch( fetchNotifications( page ) );
    }, [ dispatch, page ] );

    if ( notifications && isEmpty( notifications ) && !isLoading ) {
        return (
            <div className={ css.notificationsEmpty }>
                <EmptyState icon={ NotificationsOffIcon } message={ t( "layout:notifications.empty" ) } />
            </div>
        );
    }

    return (
        <Fragment>
            <List className={ classnames( cssUtils.fullHeight, cssUtils.paddingBottomNone ) } dense>
                <ListSubHeader className={ css.notificationsListSubHeader }>
                    { t( "layout:notifications.title" ) }
                    <Button
                        className={ css.notificationsReadAllButton }
                        size="small"
                        color="primary"
                        onClick={ toggleConfirmReadAll }
                    >
                        { t( "layout:notifications.read-all.button" ) }
                    </Button>
                </ListSubHeader>
                <div className={ css.notificationsContainer }>
                    <InfiniteScroll
                        hasMore={ ( page < totalPages ) || ( !totalPages ) }
                        loadMore={ incrementPage }
                        useWindow={ false }
                        initialLoad={ false }
                        className={ cssUtils.fullHeight }
                    >
                        {
                            ( notifications || [] )
                                .filter( notification => !!icons[ notification.type ] )
                                .map( ( notification, index ) => {
                                    const Icon = icons[ notification.type ];

                                    return (
                                        <Fragment key={ index }>
                                            <Divider/>
                                            <ListItemButton
                                                selected={ !notification.read }
                                                onClick={ handleClick( notification ) }
                                            >
                                                <ListItemIcon>{ <Icon/> }</ListItemIcon>
                                                <ListItemText
                                                    primary={ notificationDescription( notification ) }
                                                    secondary={ dateTimeFormat( notification.date ) }
                                                />
                                            </ListItemButton>
                                        </Fragment>
                                    );
                                })
                        }
                    </InfiniteScroll>
                </div>
            </List>
            {
                isLoading ?
                    <div className={ css.loading }><CircularProgress /></div>
                    : ""
            }

            <Dialog open={ confirmReadAll }>
                <DialogContent>
                    <DialogContentText>
                        { t( "layout:notifications.read-all.confirmation-message" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ toggleConfirmReadAll } color="primary">
                        { t( "common:no" ) }
                    </Button>
                    <Button onClick={ readAll } color="primary" autoFocus>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

};

export default Notifications;