import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../form";
import { AutomationForm, validateForm } from ".";
import { createAutomation } from "./automation.actions";
import { NEW_AUTOMATION_FORM } from "./automation.constants";

const NewAutomation = ({ integrationChannel, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );
    const personFields = useSelector( formSelectors.list( "PERSON" ) );
    const companyFields = useSelector( formSelectors.list( "COMPANY" ) );
    const customerFields = useMemo( () => {
        return integrationChannel === "EXACT_SALES" ? companyFields : personFields;
    }, [ companyFields, integrationChannel, personFields ] );

    const defaultRequestNames = useCallback( integrationChannel => field => {
        switch ( integrationChannel ) {
            case "LEADLOVERS":
                if ( field.systemField ) {
                    switch ( field.systemField ) {
                        case "NAME":
                            return [ "Nome" ];
                        default:
                            break;
                    }
                }

                switch ( field.type ) {
                    case "ADDRESS":
                        return [ "Cidade", "Estado" ];
                    case "EMAIL":
                        return [ "Email" ];
                    case "PHONE":
                        return [ "Telefone" ];
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        return [];
    }, [] );

    const getDefaultRequestName = defaultRequestNames( integrationChannel );

    const formik = useForm({
        form: NEW_AUTOMATION_FORM,
        enableReinitialize: true,
        initialValues: {
            accessToken: null,
            name: "",
            funnel: {
                id: null,
            },
            source: {
                id: null,
            },
            teamId: null,
            usersId: [],
            integrationChannel,
            mappingFields: {
                ...customerFields.reduce( ( model, field ) => {
                    model[ field.id ] = getDefaultRequestName( field );
                    return model;
                }, {} ),
                ...opportunityFields.reduce( ( model, field ) => {
                    model[ field.id ] = getDefaultRequestName( field );
                    return model;
                }, {} )
            },
        },
        onSubmit: values => dispatch( createAutomation( values ) ),
        onSubmitSuccess: onClose,
        validate: values => validateForm( values, { customerFields, integrationChannel } )
    });

    const translateIntegrationChannel = useMemo( () => {
        return integrationChannel && t( `marketplace:automation.integration-channel.${integrationChannel}` );
    }, [ integrationChannel, t ] );

    return (
        <Dialog open={ open } fullWidth maxWidth="md">
            <DialogTitle>
                { t( "marketplace:automation.new.title" ) }
                { integrationChannel && ( " - " + translateIntegrationChannel ) }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        integrationChannel === "EXACT_SALES" ?
                            t( "marketplace:automation.new.exact-sales-help" ) :
                            t( "marketplace:automation.new.help", {
                                integrationChannel: translateIntegrationChannel
                            })

                    }
                </DialogContentText>
                <AutomationForm formik={ formik } integrationChannel={ integrationChannel } />
            </DialogContent>
            <DialogActions>
                { formik.error && <Typography color="error">{ formik.error }</Typography> }
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewAutomation;