import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import GridOffIcon from "@mui/icons-material/GridOff";
import isEmpty from "lodash/isEmpty";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs from "dayjs";

import useTranslate from "../../../hooks/use-translate.hook";
import { EmptyState } from "../../../ui";
import { actions as formActions } from "../../../form";
import { actions as goalActions, selectors as goalSelectors } from "../../../goal";
import { fetchSchedulingTypes } from "../../../opportunity/opportunity.actions";
import { actions as productActions } from "../../../product";
import { actions as teamActions } from "../../../team";
import { actions as userActions } from "../../../user";
import ConfigHeader from "../../config-header.component";
import { DELETE_GOAL_FORM } from "./goal.constants";
import EditGoal from "./edit-goal.component";
import NewGoal from "./new-goal.component";
import GoalsList from "./goals-list.component";
import { intervals } from "./goal.utils";

dayjs.extend( isSameOrAfter );
dayjs.extend( isSameOrBefore );

const ConfigGoal = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( goalSelectors.isLoadingGoals );
    const deleting = useSelector( isSubmitting( DELETE_GOAL_FORM ) );
    const goals = useSelector( goalSelectors.getGoals );

    const [ showNewGoal, setShowNewGoal ] = useState( false );
    const [ selectedGoal, setSelectedGoal ] = useState();

    const toggleNewGoal = () => setShowNewGoal( !showNewGoal );

    const isGoalValid = goal => {
        return ( !goal.initialDate || dayjs( goal.initialDate ).isSameOrBefore( dayjs(), "day" ) ) &&
            ( !goal.finalDate || dayjs( goal.finalDate ).isSameOrAfter( dayjs(), "day" ) );
    };

    const isPastGoal = goal => goal.finalDate && dayjs().isAfter( dayjs( goal.finalDate ), "day" );

    const isFutureGoal = goal => goal.initialDate && dayjs().isBefore( dayjs( goal.initialDate ), "day" );

    useEffect( () => {
        dispatch( goalActions.fetchGoals() );
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );
        dispatch( productActions.fetchCategories() );
        dispatch( fetchSchedulingTypes() );
    }, [ dispatch ] );

    return (
        <>
            <ConfigHeader
                title={ t( "config:items.goal.label" ) }
                actionTitle={ t( "config:new-goal" ) }
                actionIcon={ AddIcon }
                onActionClick={ toggleNewGoal }
            />
            <Paper>
                <CardHeader
                    subheader={ t( "config:items.goal.description" ) }
                />
                {
                    loading || deleting ?
                        <LinearProgress/> :
                        isEmpty( goals ) ?
                            <CardContent>
                                <EmptyState icon={ GridOffIcon } message={ t( "config:empty-goals" ) }/>
                            </CardContent> :
                            <>
                                {
                                    intervals.map( ( interval, index ) => (
                                        <Fragment key={ index }>
                                            <GoalsList
                                                goals={ goals
                                                    .filter( goal => goal.interval === interval )
                                                    .filter( isGoalValid )
                                                }
                                                onSelect={ setSelectedGoal }
                                                title={ t( `config:goal.interval.${interval}` ) }
                                            />
                                            <Divider/>
                                        </Fragment>
                                    ))
                                }
                                <GoalsList
                                    goals={
                                        goals
                                            .filter( goal => !isGoalValid( goal ) )
                                            .filter( isFutureGoal )
                                    }
                                    onSelect={ setSelectedGoal }
                                    title={ t( "config:goal.future.label" ) }
                                    subheader={ t( "config:goal.future.help" ) }
                                    bottomShadow={ false }
                                    showInterval
                                />
                                <Divider/>
                                <GoalsList
                                    goals={
                                        goals
                                            .filter( goal => !isGoalValid( goal ) )
                                            .filter( isPastGoal )
                                    }
                                    onSelect={ setSelectedGoal }
                                    title={ t( "config:goal.past.label" ) }
                                    subheader={ t( "config:goal.past.label" ) }
                                    bottomShadow={ false }
                                    showInterval
                                />
                            </>
                }
            </Paper>

            <NewGoal open={ showNewGoal } onClose={ toggleNewGoal }/>
            <EditGoal goal={ selectedGoal } onClose={ () => setSelectedGoal() }/>
        </>
    );
};

export default ConfigGoal;