import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";
import css from "./table.scss";

const FiltersButton = ({ preferences, onClick }) => {
    const t = useTranslate();

    const filters = get( preferences, "opportunity.filters" ) || [];
    const schedulingDate = get( preferences, "opportunity.schedulingDate" );
    const importation = get( preferences, "opportunity.importation.name" );
    const createdAt = get( preferences, "opportunity.createdAt" );
    const soldAt = get( preferences, "opportunity.soldAt" );
    const companyTags = get( preferences, "opportunity.companyTags" ) || [];
    const personTags = get( preferences, "opportunity.personTags" ) || [];
    const companyFilters = get( preferences, "opportunity.customerFilters" ) || [];
    const personFilters = get( preferences, "opportunity.customerFilters" ) || [];
    const productCategories = get( preferences, "opportunity.productCategories" );
    const hasFilters = !isEmpty( filters ) ||
        !isEmpty( get( preferences, "opportunity.users" ) ) ||
        !isEmpty( get( preferences, "opportunity.company.id" ) ) ||
        !isEmpty( get( preferences, "opportunity.person.id" ) ) ||
        !isEmpty( get( preferences, "opportunity.products" ) ) ||
        !isEmpty( companyTags ) ||
        !isEmpty( personTags ) ||
        !isEmpty( schedulingDate ) ||
        !!importation ||
        !isEmpty( createdAt ) ||
        !isEmpty( soldAt ) ||
        !isEmpty( companyFilters ) ||
        !isEmpty( personFilters ) ||
        !isEmpty( productCategories );

    return (
        <Tooltip title={ t( "customer:customers-list.filters" ) }>
            <IconButton
                id="filterButton"
                onClick={ onClick }
                className={ classnames(
                    cssUtils.floatRight, hasFilters ? css.iconFilters : ""
                )}
                size="large"
            >
                <FilterListIcon/>
            </IconButton>
        </Tooltip>
    );
};

export default FiltersButton;