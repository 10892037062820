export const FETCH_MIGRATIONS = "x/config/advanced/migration/FETCH_MIGRATIONS";
export const fetchMigrations = () => ({
    type: FETCH_MIGRATIONS
});

export const REQUEST_MIGRATIONS = "x/config/advanced/migration/REQUEST_MIGRATIONS";
export const requestMigrations = () => ({
    type: REQUEST_MIGRATIONS
});

export const RECEIVE_MIGRATIONS = "x/config/advanced/migration/RECEIVE_MIGRATIONS";
export const receiveMigrations = data => ({
    type: RECEIVE_MIGRATIONS,
    payload: data
});

export const ERROR_MIGRATIONS = "x/config/advanced/migration/ERROR_MIGRATIONS";
export const errorMigrations = error => ({
    type: ERROR_MIGRATIONS,
    payload: error,
    error: true
});

export const CREATE_MIGRATION = "x/config/advanced/migration/CREATE_MIGRATION";
export const createMigration = data => ({
    type: CREATE_MIGRATION,
    payload: data
});
