export default {
    "title": "Importações",
    "importations": {
        "title": "Importações",
        "empty": "Não há importações cadastradas",
        "empty-file": "Importação não executada",
        "download": "Fazer o download do arquivo utilizado",
        "copy": "Criar nova importação a partir deste modelo de configuração a partir de um novo arquivo"
    },
    "importation": {
        "name": "Nome",
        "tags": "Tags",
        "users": "Vendedores",
        "users-help":
            "Vendedores que receberão proporcionalmente as oportunidades criadas. " +
            "Se não for selecionado, pelo menos, um vendedor, as oportunidades serão criadas para você.",
        "merge-data": "Mesclar dados",
        "clear-data": "Limpar dados",
        "clear-data-help": "Se o valor de um campo vier em branco e no cliente houver valor, o mesmo será \"limpo\".",
        "warning-validation-errors": "É necessário preencher todos os campos obrigatórios para continuar",
        "first-row-header": "Considerar primeira linha do arquivo como cabeçalho",
        "files-accepted": "Somente arquivos .csv, .xls e .xlsx são aceitos",
        "created-at": "Criada em",
        "created-by": "Criada por",
        "file": "Arquivo",
        "finished-at": "Finalizada em",
    },
    "new-importation": {
        "init-success":
            "A importação foi colocada na fila de execução com sucesso. " +
            "Você será notificado quando ela for finalizada.",
        "save-and-execute": "Salvar e executar",
        "different-file":
            "O arquivo selecionado não possui a mesma quantidade de colunas que o arquivo utilizado " +
            "na importação selecionada para copiar.",
        "file-exceed-size": "O arquivo não pode exceder 2 MB",
        "file-exceed-lines": "A planilha não pode possuir mais de 1000 linhas",
        "mapping-invalid": "Este campo precisa de algumas definições. Clique no botão ao lado para editá-lo",
        "select-date-format": "Selecione o formato das datas",
        "select-address-type": "Selecione o tipo de endereço",
        "select-phone-country": "Selecione o país do telefone",
        "select-phone-type": "Selecione o tipo de telefone",
        "file-required": "É necessário selecionar um arquivo",
        "file-columns": "Colunas do arquivo",
        "form-fields": "Campos de formulário. Arraste estes campos para as colunas do arquivo ao lado",
        "customers": "Clientes",
        "customer-person": "Pessoa",
        "customer-company": "Empresa",
        "customer-help": "As pessoas serão vinculadas as empresas do arquivo",
        "select-customer": "Selecione ao menos um tipo de cliente",
        "opportunities": "Oportunidades",
        "empty-value": "(Sem valor)",
        "drag-here-field": "Arraste aqui o campo",
        "confirm": {
            "title": "Você confirma a importação do arquivo { file } com { lines } linha(s)?",
            "opportunity-users":
                "As oportunidades criadas serão distribuidas proporcionalmente para o(s) usuario(s) { users }.",
            "used-columns": "Colunas que serão importadas",
            "not-used-columns": "Colunas que não serão importadas"
        }
    },
    "importation-results": {
        "executing": "Executando importação",
        "success-message": "Importação finalizada em { date }",
        "title": "Resultados",
        "customers-created": "Clientes criados",
        "customers-updated": "Clientes atualizados",
        "customers-skipped": "Clientes ignorados",
        "customers-skipped-download": "Clique aqui para fazer o download do arquivo com as linhas ignoradas",
        "opportunities-created": "Oportunidades criadas",
        "opportunities-skipped": "Oportunidades ignoradas",
        "opportunities-skipped-download": "Clique aqui para fazer o download do arquivo com as linhas ignoradas",
        "delete-confirm":
            "Você tem certeza que deseja excluir esta importação? Todos os clientes e oportunidades criados " +
            "por esta importação, serão excluídos também. Os dados criados serão excluídos assincronamente."
    }
};