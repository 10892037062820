import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import set from "lodash/set";
import dayjs from "dayjs";

import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import usePrevious from "../../../hooks/use-previous.hook";
import { listAll } from "../../../plan/plan.selectors";
import { getSubscription } from "../../../subscription/subscription.selectors";
import { fetchUsers } from "../../../user/user.actions";
import { isLoadingAll } from "../../../user/user.selectors";
import { cssUtils, Input, RadioButtons } from "../../../ui";
import { saveSubscriptionBilling } from "./plan.actions";
import { PLAN_FORM } from "./plan.constants";
import { getSubscriptionBilling, getSubscriptionCoupon, isLoadingSubscriptionBilling } from "./plan.selectors";
import CreditCards from "./credit-cards.component";
import css from "./plan.scss";

const MONTHS = {
    MONTHLY: 1,
    SEMIANNUAL: 6,
    ANNUAL: 12
};

const PlanForm = ({ open, onClose, planId, }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const plans = useSelector( listAll );
    const loadingSubscriptionBilling = useSelector( isLoadingSubscriptionBilling );
    const loadingAll = useSelector( isLoadingAll );
    const loading = loadingAll || loadingSubscriptionBilling;
    const subscription = useSelector( getSubscription );
    const subscriptionBilling = useSelector( getSubscriptionBilling );
    const subscriptionCoupon = useSelector( getSubscriptionCoupon );

    const formik = useForm({
        enableReinitialize: true,
        form: PLAN_FORM,
        initialValues: {
            planId,
            userLimit: subscription.userLimit,
            billingEmail: "",
            billingFrequency: "MONTHLY",
            billingType: "BANK_SLIP",
            creditCard: {
                id: null,
                number: "",
                name: "",
                validity: "",
                cvc: ""
            },
            ...( subscriptionBilling || {} ),
        },
        onSubmit: values => dispatch( saveSubscriptionBilling( values ) ),
        validate: values => {
            const now = new Date();
            const errors = {};
            if ( !values.userLimit ) {
                errors.userLimit = "common:validation.required";
            }
            if ( !values.billingEmail ) {
                errors.billingEmail = "common:validation.required";
            }
            if ( values.billingType === "CREDIT_CARD" && !values.creditCard.id ) {
                if ( !values.creditCard.name ) {
                    set( errors, "creditCard.name", "common:validation.required" );
                }
                if ( !values.creditCard.validity ) {
                    set( errors, "creditCard.validity", "common:validation.required" );
                } else {
                    const rawValidity = values.creditCard.validity.replace("/", "");
                    const month = rawValidity.slice( 0, 2 );
                    const year = rawValidity.slice( 2 );
                    if ( parseInt( year ) < now.getFullYear() ) {
                        set( errors, "creditCard.validity", "common:validation.credit-card.year-out-range" );
                    }
                    if ( parseInt( year ) === now.getFullYear() && parseInt( month ) < now.getMonth() + 1 ) {
                        set( errors, "creditCard.validity", "common:validation.credit-card.date-out-range" );
                    }
                }
            }
            return errors;
        }
    });
    const { error, resetForm, setFieldValue, submitting, values } = formik;

    const prevSubmitting = usePrevious( submitting );

    const priceFormat = usePriceFormat( "BRL" );

    const handleClose = useCallback( () => {
        onClose();
        resetForm();
    }, [ onClose, resetForm ] );

    const increseadUserLimit = useMemo( () => {
        return subscriptionBilling && values.userLimit > subscription.userLimit;
    }, [ subscription, subscriptionBilling, values ] );

    const unitPrice = useMemo( () => {
        const { userLimit, planId } = values;
        if ( !userLimit || !planId ) {
            return 0;
        }
        const { minUserLimit, maxUserLimit } = subscriptionCoupon;
        const isCouponValid = ( !maxUserLimit || userLimit <= maxUserLimit ) &&
            ( !minUserLimit || userLimit >= minUserLimit );

        const plan = plans.find( plan => plan.id === planId );
        let price = plan.ranges.filter( range => range.start <= userLimit )
            .find( range => range.end >= userLimit || !range.end )
            .price;

        if ( ( subscriptionCoupon && subscriptionCoupon.discountType !== "TOTAL" ) && isCouponValid ) {
            const discount = subscriptionCoupon.discount;
            price = subscriptionCoupon.discountType === "UNIT" ?
                price - discount :
                Math.round( ( ( price - ( price * discount / 100 ) ) + Number.EPSILON ) * 100 ) / 100 ;
        }
        return price;
    }, [ values, subscriptionCoupon, plans ] );

    const total = useMemo( () => {
        const { billingFrequency, userLimit } = values;
        if ( !userLimit ) {
            return 0;
        }
        const payingUsers = increseadUserLimit ? userLimit - subscription.userLimit : userLimit;
        let price;
        const months = MONTHS[ billingFrequency ];
        switch ( billingFrequency ) {
            case "SEMIANNUAL":
                price = unitPrice * payingUsers * months;
                price = price - ( price * 10 / 100 );
                break;
            case "ANNUAL":
                price = unitPrice * payingUsers * months;
                price = price - ( price * 20 / 100 );
                break;
            default:
                price = unitPrice * payingUsers;
                break;
        }
        if ( increseadUserLimit ) {
            const daysUntilNextBilling = dayjs( subscriptionBilling.nextBillingAt )
                .startOf( "day" )
                .diff( dayjs().startOf( "day" ), "days" );
            const paidDays = dayjs( subscriptionBilling.nextBillingAt ).diff(
                dayjs( subscriptionBilling.nextBillingAt ).subtract( months, "months" ),
                "days"
            );

            price = ( price * daysUntilNextBilling ) / paidDays;
            price = Math.round( ( price + Number.EPSILON ) * 100 ) / 100;
        } else if ( subscriptionCoupon && subscriptionCoupon.discountType === "TOTAL" ) {
            price = price - subscriptionCoupon.discount;
        }
        return priceFormat( price );
    }, [ subscription, subscriptionBilling, subscriptionCoupon, unitPrice, values, increseadUserLimit, priceFormat ] );

    useEffect( () => {
        fetchUsers();
    }, [] );

    useEffect( () => {
        if ( prevSubmitting && !submitting && !error ) {
            handleClose();
        }
    }, [ prevSubmitting, submitting, error, handleClose ] );

    useEffect( () => {
        setFieldValue( "planId", planId );
    }, [ planId, setFieldValue ] );

    useEffect( () => {
        if ( increseadUserLimit && values.billingFrequency !== subscriptionBilling.billingFrequency ) {
            setFieldValue( "billingFrequency", subscriptionBilling.billingFrequency );
        }
    }, [ values.billingFrequency, increseadUserLimit, setFieldValue, subscriptionBilling ] );

    if ( loading ) {
        return null;
    }

    const decreseadUserLimit = subscriptionBilling && values.userLimit < subscriptionBilling.userLimit;

    const showWarningNew = subscriptionBilling && values.billingFrequency !== subscriptionBilling.billingFrequency;

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:plans.hire" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    <Field
                        name="userLimit"
                        type="number"
                        label={ t( "config:billing.user-limit.label" ) }
                        component={ Input }
                        required
                    />
                    <Typography color="textSecondary" variant="caption">
                        { t( "config:billing.user-limit.help", {
                            price: priceFormat( unitPrice ) }
                        ) }
                    </Typography>
                    {
                        increseadUserLimit &&
                            <Typography component="p" variant="caption">
                                { t( "config:billing.user-limit.incresead" ) }
                            </Typography>
                    }
                    {
                        decreseadUserLimit &&
                            <Typography color="error" component="p" variant="caption">
                                { t( "config:billing.user-limit.warning" ) }
                            </Typography>
                    }
                    <Field
                        name="billingEmail"
                        type="email"
                        label={ t( "common:email" ) }
                        component={ Input }
                        required
                    />
                    <Typography color="textSecondary" variant="caption">
                        { t( "config:billing.email-help" ) }
                    </Typography>
                    <Field
                        name="billingFrequency"
                        className={ cssUtils.marginTop }
                        component={ RadioButtons }
                        label={ t( "config:plans.frequency.label" ) }
                        options={
                            [ "MONTHLY", "SEMIANNUAL", "ANNUAL" ].map( frequency => ({
                                value: frequency,
                                label: t( `config:plans.frequency.${frequency}` )
                            }))
                        }
                        disabled={ increseadUserLimit }
                        fullWidth
                    />
                    <Typography variant="subtitle2">
                        { increseadUserLimit ?
                            t( "config:billing.total", { price: total }) :
                            t( "config:billing.total-frequency", {
                                price: total,
                                frequency: t( `config:billing.frequency.${values.billingFrequency}` )
                            })
                        }
                    </Typography>
                    {
                        showWarningNew &&
                            <Typography color="error" component="p" variant="caption">
                                { t( "config:billing.warning-new" ) }
                            </Typography>
                    }
                    <Field
                        name="billingType"
                        className={ cssUtils.marginTop }
                        component={ RadioButtons }
                        label={ t( "config:billing.type.label" ) }
                        options={[
                            { value: "BANK_SLIP", label: t( "config:billing.type.BANK_SLIP" ) },
                            { value: "CREDIT_CARD", label: t( "config:billing.type.CREDIT_CARD" ) },
                            { value: "PIX", label: t( "config:billing.type.PIX" ) },
                        ]}
                        fullWidth
                    />

                    { values.billingType === "BANK_SLIP" && <div className={ css.barCode } /> }
                    {
                        values.billingType === "PIX" &&
                            <Typography color="textSecondary" variant="caption">
                                { t("config:billing.pix-info") }
                            </Typography>
                    }
                    {
                        values.billingType === "CREDIT_CARD" &&
                            <CreditCards setFieldValue={ setFieldValue } values={ values } />
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                {
                    error && error.error &&
                        <Typography color="error">
                            { error.error }
                        </Typography>
                }
                <Button
                    color="primary"
                    disabled={ submitting }
                    onClick={ handleClose }
                >
                    { t( "common:cancel" ) }
                </Button>
                <Button
                    color="primary"
                    disabled={ submitting }
                    onClick={ formik.submitForm }
                >
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlanForm;