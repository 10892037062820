import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CircularProgres from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, cssUtils, flex } from "../../../ui";
import { addSubscriptionHolding } from "./holding.actions";
import { ADD_SUBSCRIPTION_HOLDING_FORM } from "./holding.constants";

const AddSubscriptionHolding = ({ onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ step, setStep ] = useState( 0 );
    const [ loadingToken, setLoadingToken ] = useState( false );

    const formik = useForm({
        form: ADD_SUBSCRIPTION_HOLDING_FORM,
        initialValues: { token: "", name: "" },
        onSubmit: values => dispatch( addSubscriptionHolding( values ) ),
        onSubmitSuccess: onClose,
        validateOnChange: false,
        validateOnBlur: true
    });

    const validateToken = async () => {
        const token = formik.values.token;
        if ( !token ) {
            formik.setFieldTouched( "token", true, false );
            formik.setFieldError( "token", "common:validation.required" );
            return Promise.resolve();
        }
        setLoadingToken( true );
        return fetch( `/api/v1/holding/${token}` )
            .then( response => response.json() )
            .then( response => {
                if ( response.name ) {
                    formik.setFieldValue( "name", response.name );
                    formik.setFieldError( "token" );
                    setStep( 1 );
                } else {
                    formik.setFieldError( "token", "config:holding.invalid-token" );
                }

            })
            .finally( () => setLoadingToken( false ) );
    };

    const validateName = async name => {
        if ( !name ) {
            formik.setFieldError( "name", "common:validation.required" );
        } else {
            formik.setFieldError( "name" );
        }
    };

    const prepareSubmit = async () => {
        await validateName( formik.values.name );
        formik.setFieldTouched( "name", true, false );

        if ( isEmpty( formik.errors ) ) {
            formik.submitForm();
        }
    };

    useEffect( () => {
        if ( !open && step > 0 ) {
            setStep( 0 );
        }
    }, [ open, step ] );

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:holding.link-subscription" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Stepper activeStep={ step } orientation="vertical">
                        <Step>
                            <StepLabel onClick={ () => setStep( 0 ) }>
                                { t( "config:holding.token" ) }
                            </StepLabel>
                            <StepContent>
                                <Field
                                    name="token"
                                    label={ t( "config:holding.token" ) }
                                    component={ Input }
                                    InputProps={{
                                        endAdornment: loadingToken && (
                                            <InputAdornment
                                                className={ cssUtils.marginRightSmall }
                                                position="end"
                                            >
                                                <CircularProgres size={ 20 }/>
                                            </InputAdornment>
                                        )
                                    }}
                                    validate={ () => get( formik.errors, "token" ) }
                                    required
                                />
                                <div
                                    className={ classnames(
                                        cssUtils.marginTopSmall,
                                        flex.container,
                                        flex.justifyContentEnd
                                    ) }
                                >
                                    <Button color="primary" variant="contained" onClick={ validateToken }>
                                        { t( "config:holding.validate-token" ) }
                                    </Button>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                { t( "config:holding.subscription-name" ) }
                            </StepLabel>
                            <StepContent>
                                <Field
                                    name="name"
                                    label={ t( "config:holding.subscription-name" ) }
                                    component={ Input }
                                    validate={ () => get( formik.errors, "name" ) }
                                    required
                                />
                            </StepContent>
                        </Step>
                    </Stepper>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                {
                    step === 1 &&
                        <Button color="primary" disabled={ formik.submitting } onClick={ prepareSubmit }>
                            { t( "common:save" ) }
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default AddSubscriptionHolding;