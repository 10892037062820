import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as smtpServerSelectors, securityTypes } from "../../../smtp-server";
import { Checkbox, Dropdown, Input } from "../../../ui";
import { saveSmtpServerDefault } from "./smtp-server.actions";
import { SMTP_SERVER_DEFAULT_FORM } from "./smtp-server.constants";

const SmtpServerDefault = ({ onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const defaultSettings = useSelector( smtpServerSelectors.getDefaultSettings );

    const formik = useForm({
        enableReinitialize: true,
        form: SMTP_SERVER_DEFAULT_FORM,
        initialValues: {
            host: get( defaultSettings, "host", "" ),
            port: get( defaultSettings, "port", null ),
            securityType: get( defaultSettings, "securityType", "" ),
            auth: get( defaultSettings, "auth", false ),
        },
        onSubmit: values => dispatch( saveSmtpServerDefault( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.host ) {
                errors.host = "common:validation.required";
            }
            if ( !values.port ) {
                errors.port = "common:validation.required";
            }
            if ( !values.securityType ) {
                errors.securityType = "common:validation.selection";
            }
            return errors;
        },
    });

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:smtp-server-default.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="host"
                        label={ t( "user:smtp-server.host" ) }
                        component={ Input }
                        required
                    />
                    <Field
                        name="port"
                        label={ t( "user:smtp-server.port" ) }
                        type="number"
                        component={ Input }
                        required
                    />
                    <Field
                        name="securityType"
                        label={ t( "user:smtp-server.security-type" ) }
                        component={ Dropdown }
                        source={ securityTypes.map( securityType => ({
                            value: securityType,
                            label: securityType === "NONE" ?
                                t( "common:none" ) :
                                securityType
                        }))}
                        required
                    />
                    <Field
                        name="auth"
                        label={ t( "user:smtp-server.auth" ) }
                        component={ Checkbox }
                        required
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SmtpServerDefault;