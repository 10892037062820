import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import set from "lodash/set";
import classnames from "classnames";
import Chip from "@mui/material/Chip";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { Autocomplete, Dropdown, DropdownMultiple, Input, Checkbox, DialogConfirm, Switch, flex } from "../../../ui";
import { selectors as funnelSelectors } from "../../../funnel";
import { listSelectFields } from "../../../form/form.selectors";
import { saveField, fetchUsedValues, receiveUsedValues } from "./form.actions";
import { createFormName } from "./form.utils";
import * as selectors from "./form.selectors";
import css from "./form.scss";

const nonRequiredSystemFields = [ "EIN", "SSN", "BRAND", "MODEL" ];

const validate = values => {
    const errors = {};

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( values.type?.match( "SELECT|SELECT_MULTIPLE" ) && isEmpty( values.options.values ) ) {
        errors.options = {
            values: "common:validation.required"
        };
    }

    if ( !!values.dependsOn.active ) {
        if ( !values.dependsOn.dependsOnFieldId ) {
            set( errors, "dependsOn.dependsOnFieldId", "common:validation.selection" );
        }
        if ( isEmpty( values.dependsOn.options ) ) {
            set( errors, "dependsOn.options", "common:validation.selection" );
        }
    }

    return errors;
};

// ---------------------------------------------------------------------------------------------------------------------
// Component
// ---------------------------------------------------------------------------------------------------------------------
const formTypesForHalfRow = [ "TEXT", "DATE", "NUMBER", "SELECT", "PRICE", "SELECT_MULTIPLE" ];

const FieldConfig = ({ field, onCancel }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formName = useSelector( selectors.selectedForm );
    const funnels = useSelector( funnelSelectors.listAll );
    const usedValues = useSelector( selectors.usedValues );
    const loading = useSelector( selectors.isLoading );
    const allSelectFields = useSelector( listSelectFields( formName ) );

    const selectFields = useMemo(
        () => allSelectFields
            .filter( f => f.id !== field.id )
            .filter( f => f.systemField !== "MODEL" ),
        [ field, allSelectFields ]
    );
    const usedValuesLoaded = useMemo( () => !!usedValues, [ usedValues ] );

    const [ showWarning, setShowWarning ] = useState( false );

    const toggleShowWarning = () => setShowWarning( !showWarning );

    const disabled = useMemo( () => !!field.systemField, [ field.systemField ] );
    const requiredOptionDisabled = useMemo(
        () => !!field.systemField && !nonRequiredSystemFields.includes( field.systemField ),
        [ field.systemField ]
    );

    const formik = useForm({
        enableReinitialize: true,
        form: createFormName( field ),
        initialValues: {
            name: "",
            type: null,
            ...field,
            options: {
                required: false,
                halfRow: false,
                mainScreen: false,
                summary: false,
                ...field.options,
            },
            dependsOn: {
                ...( field.dependsOn || {} ),
                active: !isEmpty( field.dependsOn ),
            }
        },
        onSubmit: values => {
            const fieldValues = { ...values };
            if ( !fieldValues?.dependsOn?.active ) {
                fieldValues.dependsOn = null;
            }
            dispatch( saveField( fieldValues ) );
        },
        validate,
    });

    const { setFieldValue, values: liveField } = formik;

    const usedValuesDeleted = useMemo(
        () => !field.id || isEmpty( usedValues ) || !liveField?.type.match( "SELECT|SELECT_MULTIPLE" ) ?
            [] :
            usedValues.filter( value => !liveField.options.values.includes( value ) ),
        [ field, liveField, usedValues ]
    );
    const loadUsedValues = useCallback( () => {
        if ( !!field.id && field.type.match( "SELECT|SELECT_MULTIPLE" ) && !usedValuesLoaded ) {
            dispatch( fetchUsedValues( field ) );
        }
    }, [ dispatch, field, usedValuesLoaded ] );

    const handleClose = () => {
        toggleShowWarning();
        formik.resetForm();
    };

    const prevLiveField = usePrevious( liveField );

    useEffect( () => {
        dispatch( receiveUsedValues( null ) );
    }, [ dispatch ] );
    useEffect( () => {
        if ( prevLiveField?.dependsOn.dependsOnFieldId &&
            liveField?.dependsOn.dependsOnFieldId &&
            prevLiveField?.dependsOn.dependsOnFieldId !== liveField?.dependsOn.dependsOnFieldId
        ) {
            setFieldValue( "dependsOn.options", null );
        }
        if ( prevLiveField?.dependsOn.active && !liveField?.dependsOn.active ) {
            setFieldValue( "dependsOn.dependsOnFieldId", null );
            setFieldValue( "dependsOn.options", null );
        }
    }, [ liveField, prevLiveField, setFieldValue ] );
    useEffect( () => {
        loadUsedValues();
    }, [ loadUsedValues ] );

    return (
        <Form formik={ formik } noValidate>
            <Card>
                <CardContent>
                    { liveField.systemField ?
                        <Chip label={ t( "config:form.field-config.system-field" ) }/> :
                        null
                    }
                    <DialogConfirm
                        open={ usedValuesLoaded && showWarning && !isEmpty( usedValuesDeleted ) }
                        message={ t(
                            "config:form.field-config.confirm-update-message",
                            { values: usedValuesDeleted.join( ", " ) }
                        ) }
                        onCancel={ handleClose }
                        onConfirm={ formik.submitForm }
                    />

                    <Field
                        name="name"
                        type="text"
                        label={ t( "config:form.field-config.name.label" ) }
                        component={ Input }
                        autoComplete="off"
                        disabled={ disabled }
                        required
                    />

                    <Field
                        name="type"
                        label={ t( "config:form.field-config.type.label" ) }
                        component={ Dropdown }
                        disabled={ disabled || !!liveField.id }
                        source={[
                            { value: "TEXT", label: t( "config:form.field-types.text" ) },
                            { value: "DATE", label: t( "config:form.field-types.date" ) },
                            { value: "EMAIL", label: t( "config:form.field-types.email" ) },
                            { value: "PHONE", label: t( "config:form.field-types.phone" ) },
                            { value: "NUMBER", label: t( "config:form.field-types.number" ) },
                            { value: "ADDRESS", label: t( "config:form.field-types.address" ) },
                            { value: "SELECT", label: t( "config:form.field-types.select" ) },
                            { value: "SELECT_MULTIPLE", label: t( "config:form.field-types.select-multiple" ) },
                            { value: "PRICE", label: t( "config:form.field-types.price" ) }
                        ]}
                    />

                    { formName === "OPPORTUNITY" && !liveField.systemField &&
                        <Field
                            name="field.funnelsId"
                            label={ t( "config:form.field-config.funnel.label" ) }
                            component={ DropdownMultiple }
                            disabled={ disabled }
                            source={
                                funnels?.map( funnel => ( { label: funnel.name, value: funnel.id } ) )
                            }
                        />
                    }
                    {
                        ( liveField.type === "SELECT" || liveField.type === "SELECT_MULTIPLE" ) && !disabled &&
                            <Field
                                name="options.values"
                                label={ t( "config:form.field-config.options.values.label" ) }
                                source={ [] }
                                component={ Autocomplete }
                                allowCreate
                                required
                            />
                    }
                    <Field
                        name="options.required"
                        type="checkbox"
                        component={ Checkbox }
                        disabled={ requiredOptionDisabled }
                    >
                        { t( "config:form.field-config.required.label" ) }
                    </Field>

                    {
                        formTypesForHalfRow.includes( liveField.type ) &&
                            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                <Field
                                    name="options.halfRow"
                                    type="checkbox"
                                    component={ Checkbox }
                                    fullWidth={ false }
                                >
                                    { t( "config:form.field-config.half-row.label" ) }
                                </Field>
                                <Tooltip
                                    title={ t( "config:form.field-config.half-row.help" ) }
                                >
                                    <HelpIcon className={ css.helpIcon }/>
                                </Tooltip>
                            </div>
                    }

                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            name="options.mainScreen"
                            type="checkbox"
                            component={ Checkbox }
                            fullWidth={ false }
                        >
                            {
                                t(
                                    "config:form.field-config.mainScreen.label",
                                    { module: t( "config:form.modules." + formName ) }
                                )
                            }
                        </Field>
                        <Tooltip
                            title={
                                t(
                                    "config:form.field-config.mainScreen.help",
                                    { module: t( "config:form.modules." + formName ) }
                                )
                            }
                        >
                            <HelpIcon className={ css.helpIcon }/>
                        </Tooltip>
                    </div>

                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            name="options.summary"
                            type="checkbox"
                            component={ Checkbox }
                            fullWidth={ false }
                        >
                            {
                                t(
                                    "config:form.field-config.summary.label",
                                    { module: t( "config:form.modules." + formName ) }
                                )
                            }
                        </Field>
                        <Tooltip
                            title={
                                t(
                                    formName === "OPPORTUNITY" ?
                                        "config:form.field-config.summary.help-opportunity" :
                                        "config:form.field-config.summary.help-customer"
                                )
                            }
                        >
                            <HelpIcon className={ css.helpIcon }/>
                        </Tooltip>
                    </div>

                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            name="dependsOn.active"
                            type="checkbox"
                            label={ t( "config:form.field-config.depends-on.label" ) }
                            component={ Switch }
                            disabled={ !!liveField.systemField }
                            fullWidth={ false }
                        />
                        <Tooltip title={ t( "config:form.field-config.depends-on.help" ) }>
                            <HelpIcon className={ css.helpIcon }/>
                        </Tooltip>
                    </div>
                    {
                        liveField.dependsOn?.active &&
                            <div
                                className={ classnames(
                                    flex.container,
                                    flex.columnGapSmall,
                                    flex.alignItemsStart,
                                ) }
                            >
                                <Field
                                    name="dependsOn.dependsOnFieldId"
                                    label={ t( "config:form.field-config.depends-on.field" ) }
                                    component={ Dropdown }
                                    source={ selectFields.map( field => ({
                                        label: field.name,
                                        value: field.id,
                                    })) }
                                    fullWidth={ false }
                                    className={ flex.fill }
                                    required
                                />
                                <Field
                                    name="dependsOn.options"
                                    label={ t( "config:form.field-config.options.values.label" ) }
                                    component={ DropdownMultiple }
                                    source={ liveField.dependsOn.dependsOnFieldId ?
                                        selectFields
                                            .find( field => field.id === liveField.dependsOn.dependsOnFieldId )
                                            .options
                                            .values
                                            .map( value => ({
                                                label: isObject( value ) ? value.label : value,
                                                value: isObject( value ) ? value.value.toString() : value,
                                            })) :
                                        []
                                    }
                                    disabled={ !liveField.dependsOn.dependsOnFieldId }
                                    fullWidth={ false }
                                    className={ flex.fill }
                                    required
                                />
                            </div>
                    }
                </CardContent>
                <CardActions className={ flex.justifyContentEnd }>
                    <Button
                        disabled={ formik.submitting || loading }
                        onClick={ onCancel }
                    >
                        { t( "common:cancel" ) }
                    </Button>
                    <Button
                        onClick={
                            usedValuesLoaded && !isEmpty( usedValuesDeleted ) ? toggleShowWarning : formik.submitForm
                        }
                        color="primary"
                        disabled={ formik.submitting || loading }
                    >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Card>
        </Form>
    );
};

export default FieldConfig;