import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import AlarmIcon from "@mui/icons-material/Alarm";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { isLate } from "../../core/date.utils";
import css from "../opportunity.scss";

const OpenSchedulesIcon = ({ opportunity }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    if ( !opportunity.nextScheduling && opportunity.status === "OPEN" ) {
        return (
            <Tooltip title={ t( "opportunity:phases.opportunity-open-scheduling.empty" ) }>
                <WarningIcon className={ css.iconEmptySchedules }/>
            </Tooltip>
        );
    } else if ( opportunity.nextScheduling ) {
        const hasLateScheduling = isLate( opportunity.nextScheduling );

        if ( hasLateScheduling ) {
            return (
                <Tooltip title={ dateFormat( opportunity.nextScheduling ) }>
                    <AlarmIcon className={ css.iconLateScheduling }/>
                </Tooltip>
            );
        }

        return (
            <Tooltip title={ dateFormat( opportunity.nextScheduling ) }>
                <AlarmIcon className={ css.iconScheduling }/>
            </Tooltip>
        );

    }

    return null;
};

export default OpenSchedulesIcon;