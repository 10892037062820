import "./main.scss";
import "core-js/stable";
import "regenerator-runtime/runtime";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { Router as CreateRouter } from "react-router-dom";
import React, { useState, useLayoutEffect } from "react";
import { createRoot } from "react-dom/client";
import { syncHistoryWithStore } from "react-router-redux";
import { CacheProvider } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import NotificationsIcon from "@mui/icons-material/Notifications";

import notistackCss from "./notistack.scss";
import { App } from "../feature/core";
import createCache from "../feature/core/cache";
import history from "../history";
import store from "../store";
global.React = React;

syncHistoryWithStore( history, store );

dayjs.extend( localeData );

const Router = ({ basename, children, history }) => {
    const [ state, setState ] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect( () => history.listen( setState ), [ history ] );

    return (
        <CreateRouter
            basename={ basename }
            children={ children }
            location={ state.location }
            navigationType={ state.action }
            navigator={ history }
        />
    );
};

const cache = createCache();

function Main () {

    return (
        <Provider store={ store }>
            <Router history={ history }>
                <CacheProvider value={ cache }>
                    <SnackbarProvider
                        classes={{
                            root: notistackCss.root
                        }}
                        iconVariant={{
                            info: <NotificationsIcon className={ notistackCss.variantIcon }/>,
                            success: <CheckIcon className={ notistackCss.variantIcon }/>,
                        }}
                        maxSnack={ 10 }
                    >
                        <App />
                    </SnackbarProvider>
                </CacheProvider>
            </Router>
        </Provider>
    );
}

const root = createRoot( document.querySelector( "main" ) );
root.render( <Main /> );