import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState, useMemo } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApiIcon from "@mui/icons-material/Api";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../../form";
import * as formActions from "../../../form/form.actions";
import { actions as sourceActions } from "../../../source";
import { actions as teamActions } from "../../../team";
import { actions as userActions, selectors as userSelectors } from "../../../user";
import { fetchApiKeys } from "./api-key.actions";
import { getApiKeys, isLoadingApiKeys } from "./api-key.selectors";
import { cssUtils, flex } from "../../../ui";
import { Table } from "../../../ui/table";
import NewApiKey from "./new-api-key.component";
import DeleteApiKey from "./delete-api-key.component";
import EditApiKey from "./edit-api-key.component";
import css from "./api-keys.scss";
import cssMarketplace from "../../marketplace.scss";

const filterFields = ( fields = [] ) => fields
    .filter( field => field.type.match( "TEXT|DATE|NUMBER|SELECT|PRICE" ) && !field.systemField );

const getObjectApiExample = ( t, fields ) => {
    const entityFields = {
        ...fields.reduce(
            ( model, field ) => {
                let value;
                switch ( field.type ) {
                    case "DATE":
                        value = t( "marketplace:integrations.api-keys.date-field-value" );
                        break;
                    case "NUMBER":
                    case "PRICE":
                        value = 0;
                        break;
                    case "SELECT":
                        value = field.options.values.join( "|" );
                        break;
                    default:
                        value = "";
                        break;
                }

                model[ field.id + "(" + field.name + ")" ] = value;
                return model;
            },
            {}
        )
    };

    return JSON.stringify( entityFields, undefined, 2 );
};

const ApiKeys = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const navigate = useNavigate();

    const apiKeys = useSelector( getApiKeys );
    const loadingApiKeys = useSelector( isLoadingApiKeys );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const users = useSelector( userSelectors.listAll );
    const customerFields = useSelector( formSelectors.list( "PERSON" ) );
    const opportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );

    const [ showHelpBodyApi, setShowHelpBodyApi ] = useState( false );
    const [ showNewApiKey, setShowNewApiKey ] = useState( false );

    const toggleHelpBodyApi = useCallback( () => setShowHelpBodyApi( showHelpBodyApi => !showHelpBodyApi ), [] );

    const toggleNewApiKey = useCallback( () => setShowNewApiKey( showNewApiKey => !showNewApiKey ), [] );

    const copyKey = useCallback( async key => {
        await navigator.clipboard.writeText( key );
    }, [] );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const model = useMemo( () => {
        return {
            name: t( "marketplace:integrations.api-key.name" ),
            key: {
                title: t( "marketplace:integrations.api-key.key" ),
                format: key => (
                    <Tooltip title={ t( "marketplace:integrations.api-key.copy-key", { key } ) }>
                        <IconButton onClick={ () => copyKey( key ) } size="large">
                            <FileCopyIcon/>
                        </IconButton>
                    </Tooltip>
                )
            },
            funnel: {
                title: t( "opportunity:funnel" ),
                format: funnel => funnel.name
            },
            source: {
                title: t( "marketplace:integrations.api-key.source" ),
                format: source => source.name
            },
            team: {
                title: t( "marketplace:integrations.api-key.team" ),
                format: team => team.name
            },
            usersId: {
                title: t( "marketplace:integrations.api-key.users" ),
                format: usersId => usersId && users && users.length &&
                    usersId.map( userId => users.find( user => user.id === userId ).name ).join( ", " )
            },
            edit: {
                title: " ",
                size: "small"
            },
            delete: {
                title: " ",
                size: "small"
            }
        };
    }, [ t, users, copyKey ] );

    const source = useMemo( () => apiKeys && apiKeys
        .map( apiKey => ({
            ...apiKey,
            edit: <EditApiKey apiKey={ apiKey }/>,
            delete: <DeleteApiKey apiKey={ apiKey }/>
        })), [ apiKeys ] );

    useEffect( () => {
        dispatch( fetchApiKeys() );
        dispatch( sourceActions.fetchSources() );
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );
        dispatch( formActions.fetchForm( "PERSON" ) );
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    const loading = loadingApiKeys || loadingUsers;

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Dialog open={ showHelpBodyApi } fullWidth>
                    <DialogTitle>
                        { t( "marketplace:integrations.api-keys.fields-body-api" ) }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            { t( "marketplace:integrations.api-keys.help-fields-body-api" ) }
                        </DialogContentText>
                        <Typography variant="subtitle2">
                            { t( "marketplace:integrations.api-keys.customer-fields" ) }
                        </Typography>
                        <pre>
                            { getObjectApiExample( t, filterFields( customerFields ) ) }
                        </pre>
                        <Typography variant="subtitle2">
                            { t( "marketplace:integrations.api-keys.opportunity-fields" ) }
                        </Typography>
                        <pre>
                            { getObjectApiExample( t, filterFields( opportunityFields ) ) }
                        </pre>
                        <Typography variant="caption">
                            { t( "marketplace:integrations.api-keys.observation-fields" ) }
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ toggleHelpBodyApi }>{ t( "common:ok" ) }</Button>
                    </DialogActions>
                </Dialog>

                <NewApiKey show={ showNewApiKey } onClose={ toggleNewApiKey }/>

                <Card className={
                    classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainerApiKeys )
                }
                >
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            <ApiIcon style={{ fontSize: 50 }}/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <span
                            className={ css.apiKeyDocLink }
                            dangerouslySetInnerHTML={{
                                __html: t( "marketplace:integrations.api-keys.help" )
                            }}
                        />
                        <Button
                            className={ classnames( cssUtils.floatRight, cssUtils.marginTop ) }
                            onClick={ toggleHelpBodyApi }
                        >
                            { t( "marketplace:integrations.api-keys.fields-body-api" ) }
                        </Button>
                    </CardContent>
                </Card>
                <Table
                    className={ flex.fill }
                    model={ model }
                    source={ source }
                    emptyMessage={ t( "marketplace:integrations.api-keys.empty" ) }
                    onAdd={ toggleNewApiKey }
                    isLoading={ loading }
                />
            </div>
        </div>
    );
};

export default ApiKeys;