export default {
    "title": "Captação Web",
    "lead": {
        "status": {
            "open": {
                "label": "Aberto",
                "help":
                    "E-mails recebidos e não convertidos automaticamente em oportunidades de venda. " +
                    "Clique no lead, analise o template recebido, crie um cliente e uma oportunidade. " +
                    "Caso não tenha dados de contato, descarte este lead."
            },
            "discarted": {
                "label": "Descartado",
                "help": "E-mails descartados (sem gerar oportunidade) por falta de dados ou dados duplicados."
            },
            "converted": {
                "label": "Convertido",
                "help": "E-mails convertidos em oportunidades de venda automaticamente ou manualmente."
            }
        },
        "source": "Origem",
        "to": "Para",
        "from": "De",
        "subject": "Assunto",
        "body": "Corpo",
        "customer": "Cliente",
        "opportunity": "Oportunidade",
        "created-at": "Data de criação",
        "portal": {
            "label": "Portal",
        }
    },
    "lead-info": {
        "info": "Informações do Lead",
        "body": "Corpo do e-mail",
        "open-body": "Abrir corpo do e-mail original",
        "change-status": {
            "open": "Reabrir",
            "discard": "Descartar",
            "confirm": "Você tem certeza que deseja alterar o status deste lead?",
            "yes": "Sim",
            "no": "Não"
        }
    },
    "lead-form": {
        "discarted": "O lead está descartado. Para trabalhar o lead, é necessário que ele esteja em aberto.",
        "converted": "O lead foi convertido para oportunidade.",
        "title":
            "Preencha as informações do cliente e oportunidade. Ao clicar em salvar, serão consideradas " +
            "estas informações para criar a oportunidade.",
        "customer": "Cliente",
        "opportunity": "Oportunidade",
        "search-customer": "Pesquise o cliente pelo nome, e-mail ou telefone completo",
        "clear-customer": "Limpar seleção de cliente",
        "save": "Salvar"
    },
    "leads": {
        "clear-filters": "Limpar filtros",
        "filter": "Filtrar",
        "empty": "Não há leads enviados"
    }
};