import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import useTranslate from "../../../hooks/use-translate.hook";
import { OptionsButton } from "../../../ui";
import EditDistribution from "./edit-distribution.component";
import DeleteDistribution from "./delete-distribution.component";

const DistributionOptions = ({ distribution }) => {
    const t = useTranslate();

    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );

    const toggleEdit = () => setOpenEdit( !openEdit );
    const toggleDelete = () => setOpenDelete( !openDelete );

    return (
        <>
            <OptionsButton
                options={[
                    {
                        icon: <EditIcon />,
                        text: t( "common:edit" ),
                        onClick: toggleEdit
                    },
                    {
                        icon: <DeleteIcon />,
                        text: t( "common:exclude" ),
                        onClick: toggleDelete
                    },
                ]}
            />

            <EditDistribution distribution={ distribution } open={ openEdit } onClose={ toggleEdit } />
            <DeleteDistribution distribution={ distribution } open={ openDelete } onClose={ toggleDelete } />
        </>
    );
};

export default DistributionOptions;