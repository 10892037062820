import { combineReducers } from "redux";

import { automation } from "./automation/automation.reducers";
import { integrations } from "./integration/integration.reducers";
import { product } from "./product/product.reducers";
import { additionalResources } from "./additional-resources/additional-resources.reducers";

export const marketplace = combineReducers({
    automation,
    integrations,
    product,
    additionalResources
});