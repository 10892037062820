import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";

import useTranslate from "../../hooks/use-translate.hook";
import { listWithoutGroupings } from "../../form/form.selectors";
import { TransferList } from "../../ui";

const ExportationColumns = ({ opportunityStatus = "OPEN" }) => {
    const t = useTranslate();

    const companyFields = useSelector( listWithoutGroupings( "COMPANY" ) );
    const personFields = useSelector( listWithoutGroupings( "PERSON" ) );
    const opportunityFields = useSelector( listWithoutGroupings( "OPPORTUNITY" ) );

    const columns = useMemo( () => {
        const companyColumns = companyFields.map( field => ({
            value: `company.fields.${field.id}`,
            label: field.name,
            helper: t( "opportunity:company" )
        }));
        const personColumns = personFields.map( field => ({
            value: `person.fields.${field.id}`,
            label: field.name,
            helper: t( "opportunity:person" )
        }));
        const opportunityColumns = opportunityFields.map( field => ({
            value: `fields.${field.id}`,
            label: field.name,
            helper: t( "opportunity:opportunity" )
        })).concat(
            [
                {
                    value: "funnel",
                    label: t( "opportunity:funnel" ),
                    helper: t( "opportunity:opportunity" ),
                },
                {
                    value: "phase",
                    label: t( "opportunity:phase" ),
                    helper: t( "opportunity:opportunity" ),
                }, {
                    value: "createdAt",
                    label: t( "common:creation-date" ),
                    helper: t( "opportunity:opportunity" ),
                }, {
                    value: "dateLastInteractAt",
                    label: t( "opportunity:date-last-interact-at" ),
                    helper: t( "opportunity:opportunity" ),
                }, {
                    value: "nextScheduling",
                    label: t( "opportunity:next-scheduling" ),
                    helper: t( "opportunity:opportunity" ),
                },
                {
                    value: "status",
                    label: t( "opportunity:status.label" ),
                    helper: t( "opportunity:opportunity" ),
                }
            ]
        );
        if ( opportunityStatus === "LOST" ) {
            opportunityColumns.push({
                value: "lossReason",
                label: t( "opportunity:loss-reason" ),
                helper: t( "opportunity:opportunity" )
            });
        }

        return opportunityColumns.concat( personColumns ).concat( companyColumns );
    }, [ t, companyFields, personFields, opportunityFields, opportunityStatus ]);

    return (
        <Field
            name="columns"
            source={ columns }
            component={ TransferList }
            titleLeft={ t( "opportunity:exportation.columns.available" ) }
            titleRight={ t( "opportunity:exportation.columns.selected" ) }
        />
    );
};

export default ExportationColumns;