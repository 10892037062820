import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router";
import get from "lodash/get";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as subscriptionSelectors } from "../../subscription";
import MarketplaceItem from "../marketplace-item.component";
import css from "../marketplace.scss";

const Products = ({ className }) => {
    const navigate = useNavigate();
    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );

    const integratedProducts = useMemo( () => {
        const integratedSms = get( subscription, "integrations", [] )
            .filter( item => item.integration === "SMS" && item.active );
        return [
            {
                id: "sms",
                title: t( "marketplace:products.sms.title" ),
                expired: false,
                quantity: integratedSms.length,
            }
        ];
    }, [ t, subscription ] );

    const renderProductIcon = useCallback( product => {
        switch ( product.id ) {
            case "sms":
                return <SmsIcon style={{ fontSize: 50 }}/>;
            default:
                return <div className={ product.className }/>;
        }
    }, [] );

    const handleClickIntegratedProduct = useCallback( id => () => {
        const route = `integrations/products/${id}`;
        navigate( route );
    }, [ navigate ] );

    return (
        <div className={ className }>
            <CardHeader
                className={ css.sectionTitle }
                title={ t( "marketplace:products.title" ) }
                subheader={ t( "marketplace:products.subtitle" ) }
            />
            <Grid container spacing={ 2 }>
                {
                    integratedProducts.map( product => (
                        <Grid key={ product.id } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                            <MarketplaceItem
                                onClick={ handleClickIntegratedProduct( product.id ) }
                                expired={ product.expired }
                                quantity={ product.quantity }
                                title={ product.title }
                            >
                                { renderProductIcon( product ) }
                            </MarketplaceItem>
                        </Grid>
                    ) )
                }
            </Grid>
        </div>
    );
};

export default Products;