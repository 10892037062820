export const FETCH_MODELS = "x/FECTH_FILE_MODELS";
export const fetchModels = () => ({
    type: FETCH_MODELS
});

export const REQUEST_MODELS = "x/REQUEST_FILE_MODELS";
export const requestModels = () => ({
    type: REQUEST_MODELS
});

export const RECEIVE_MODELS = "x/RECEIVE_FILE_MODELS";
export const receiveModels = models => ({
    type: RECEIVE_MODELS,
    payload: models
});

export const ERROR_MODELS = "x/ERROR_FILE_MODELS";
export const errorModels = error => ({
    type: ERROR_MODELS,
    payload: error,
    error: true
});

export const FETCH_MODEL_SECTIONS = "x/FETCH_FILE_MODEL_SECTIONS";
export const fetchModelSections = id => ({
    type: FETCH_MODEL_SECTIONS,
    meta: { id }
});

export const REQUEST_MODEL_SECTIONS = "x/REQUEST_FILE_MODEL_SECTIONS";
export const requestModelSections = id => ({
    type: REQUEST_MODEL_SECTIONS,
    meta: { id }
});

export const RECEIVE_MODEL_SECTIONS = "x/RECEIVE_FILE_MODEL_SECTIONS";
export const receiveModelSections = ( id, sections ) => ({
    type: RECEIVE_MODEL_SECTIONS,
    meta: { id },
    payload: sections
});

export const CREATE_FILE_FROM_MODEL = "x/CREATE_FILE_FROM_MODEL";
export const createFileFromModel = data => ({
    type: CREATE_FILE_FROM_MODEL,
    payload: data
});