import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import AddIcon from "@mui/icons-material/Add";

import useTranslate from "../../../hooks/use-translate.hook";
import * as actions from "./form.actions";
import { isSorting } from "./form.selectors";

const FormAction = ({ className, submitting }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const sorting = useSelector( isSorting );

    const handleAdd = field => () => dispatch( actions.newField( field ) );
    const handleSortCancel = () => dispatch( actions.cancelSorting() );

    return (
        <footer className={ className }>
            <CardActions>
                { sorting ?
                    null :
                    <div>
                        <Button
                            id="addGrouping"
                            onClick={ handleAdd({
                                type: "GROUPING",
                                name: t( "config:form.default-grouping-name" )
                            })}
                            startIcon={ <AddIcon/> }
                        >
                            { t( "config:form.add-grouping" ) }
                        </Button>
                        <Button
                            onClick={ handleAdd({
                                type: "TEXT",
                                name: t( "config:form.default-field-name" )
                            })}
                            startIcon={ <AddIcon/> }
                        >
                            { t( "config:form.add-field" ) }
                        </Button>
                    </div>
                }

                { !sorting ?
                    null :
                    <Button onClick={ handleSortCancel } disabled={ submitting }>
                        { t( "config:form.sorting.undo" ) }
                    </Button> }
                { !sorting ?
                    null :
                    <Button
                        type="submit"
                        color="primary"
                        disabled={ submitting }
                    >
                        { t( "config:form.sorting.save" ) }
                    </Button> }
            </CardActions>
        </footer>
    );
};

export default FormAction;