import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MUCheckbox from "@mui/material/Checkbox";

import useTranslate from "../hooks/use-translate.hook";

const Checkbox = ({
    field,
    form,
    meta,
    label,
    children,
    fullWidth = true,
    className,
    margin = "none",
    ...props
}) => {
    const t = useTranslate();

    let validationMsg;
    let fieldMeta = {};
    if ( field && form ) {
        fieldMeta = form.getFieldMeta( field.name );
        validationMsg = fieldMeta.touched && fieldMeta.error;
    } else if ( meta ) {
        validationMsg = meta && meta.touched && ( meta.error || meta.warning );
    }

    return (
        <FormControl
            fullWidth={ fullWidth }
            className={ className }
            error={ !!validationMsg }
            margin={ margin }
        >
            <FormControlLabel
                control={
                    <MUCheckbox
                        { ...field }
                        { ...props }
                    />
                }
                label={ label || children }
            />
            { validationMsg && <FormHelperText>{ t( validationMsg ) }</FormHelperText> }
        </FormControl>
    );
};

export default Checkbox;