import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { app, apiv1 } from "../../../../api/sagas";
import * as actions from "./portal-integration.actions";
import { NEW_PORTAL_FORM } from "./portal-integration.constants";
import { createEditPortalForm, createDeletePortalForm } from "./portal-integration.utils";

export function *watchIntegrationsPortal () {
    yield all([
        takeEvery( actions.FETCH_PORTALS, fetchPortals ),
        takeEvery( actions.CREATE_PORTAL, createPortal ),
        takeEvery( actions.SAVE_PORTAL, savePortal ),
        takeEvery( actions.DELETE_PORTAL, deletePortal ),
        takeEvery( actions.FETCH_ICARROS_DEALERS, fetchICarrosDealers ),
        takeEvery( actions.FETCH_MOBIAUTO_DEALERS, fetchMobiautoDealers ),
    ]);
}

export function *fetchPortals () {
    yield put( actions.requestPortals() );

    try {
        const response = yield call( apiv1.get, "/portals" );
        yield put( actions.receivePortals( response.data ) );
    } catch ( e ) {
        yield put( actions.errorPortals( e.response.data ) );
    }
}

export function *createPortal ({ payload }) {
    yield put( startSubmit( NEW_PORTAL_FORM ) );

    try {
        yield call( apiv1.post, "/portals", payload );
        yield put( stopSubmit( NEW_PORTAL_FORM ) );
        yield put( actions.fetchPortals() );
    } catch ( e ) {
        yield put( stopSubmit( NEW_PORTAL_FORM, e.response.data.error ) );
    }
}

export function *savePortal ({ meta: { token }, payload }) {
    const form = createEditPortalForm( token );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.patch, `/portals/${token}`, payload );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchPortals() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}

export function *deletePortal ({ meta: { token } }) {
    const form = createDeletePortalForm( token );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, `/portals/${token}` );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchPortals() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}

function *fetchICarrosDealers ({ meta: { credentials }}) {
    yield put( actions.requestICarrosDealers() );
    try {
        const token = yield call( app.post, "/icarros/token", credentials );
        const headers = {
            Authorization: `Bearer ${token.data.access_token}`,
        };
        const response = yield call( app.get, "/icarros/dealers", { headers });
        yield put( actions.receiveICarrosDealers( response.data ) );
    } catch ( e ) {
        yield put( actions.errorICarrosDealers( e.response.data ) );
    }
}

function *fetchMobiautoDealers ({ meta: { credentials }}) {
    yield put( actions.requestMobiautoDealers() );
    try {
        const token = yield call( app.post, "/mobiauto/token", credentials );
        const headers = {
            Authorization: `Bearer ${token.data.access_token}`,
        };
        const response = yield call( app.get, "/mobiauto/dealers", { headers });
        yield put( actions.receiveMobiautoDealers( response.data ) );
    } catch ( e ) {
        yield put( actions.errorMobiautoDealers( e.response.data ) );
    }
}