import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import throttle from "lodash/debounce";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { isEmail } from "../../../core/validator.utils";
import { cssUtils, Dropdown, Input } from "../../../ui";
import { fetchICarrosDealers } from "./portal-integration.actions";
import { getICarrosDealers, isLoadingICarrosDealers, getICarrosDealersError } from "./portal-integration.selectors";
import CommonForm from "./common-form.component";

const ICarrosForm = ({ form }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const dealers = useSelector( getICarrosDealers ) || [];
    const isLoadingDealers = useSelector( isLoadingICarrosDealers );
    const errorDealers = useSelector( getICarrosDealersError );

    const username = useMemo( () => {
        const value = form.values.credentials?.username;
        return isEmail( value ) ? value : "";
    }, [ form.values.credentials ] );
    const password = useMemo( () => {
        return form.values.credentials?.password;
    }, [ form.values.credentials ] );

    const fetchDealers = useRef( throttle(
        credentials => dispatch( fetchICarrosDealers( credentials ) ), 500
    ) );
    useEffect( () => {
        username && password && fetchDealers.current({ username, password });
    }, [ dispatch, username, password ] );

    return (
        <>
            <Box
                border={ 1 }
                borderRadius={ 1 }
                className={ classnames( cssUtils.paddingSmall, cssUtils.marginBottomSmall ) }
            >
                <Typography variant="subtitle2">
                    { t( "marketplace:integrations.portals.icarros-header" ) }
                </Typography>
                <Field
                    name="credentials.username"
                    label={ t( "common:username" ) }
                    component={ Input }
                    required
                />
                <Field
                    type="password"
                    name="credentials.password"
                    label={ t( "common:password" ) }
                    component={ Input }
                    required
                />
                {
                    errorDealers &&
                        <Typography color="error">{
                            t( "marketplace:integrations.portals.credentials-error" ) }
                        </Typography>
                }
                <Field
                    name="credentials.dealerId"
                    label={ t( "marketplace:integrations.portals.dealer" ) }
                    component={ Dropdown }
                    source={ dealers.map( dealer => ({
                        value: dealer.id,
                        label: dealer.name,
                    })) }
                    required
                />
                { isLoadingDealers && <LinearProgress /> }
            </Box>
            <CommonForm form={ form } />
        </>
    );
};

export default ICarrosForm;