import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirm } from "../../ui";
import { patchCustomer } from "../customer.actions";
import { DELETE_COMPANY_FORM } from "../customer.constants";

const DeleteCompany = ({ customer }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openConfirm, setOpenConfirm ] = useState( false );
    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const { submitForm, submitting } = useForm({
        initialValues: {
            id: customer.id,
            company: null
        },
        onSubmit: values => dispatch( patchCustomer({ ...values, form: DELETE_COMPANY_FORM }) ),
    });

    return (
        <>
            <IconButton color="inherit" onClick={ toggleConfirm } size="large">
                <DeleteIcon/>
            </IconButton>
            <DialogConfirm
                open={ openConfirm }
                disabled={ submitting }
                message={ t( "customer:company.confirm-delete" ) }
                onCancel={ toggleConfirm }
                onConfirm={ submitForm }
            />
        </>
    );
};

export default DeleteCompany;