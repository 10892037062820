import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import useTranslate from "../hooks/use-translate.hook";
import { isEmail } from "../core/validator.utils";
import css from "./email.scss";
import { getRejections, isLoadingRejections } from "../mailtop/mailtop.selectors";
import { fetchRejections } from "../mailtop/mailtop.actions";

const EmailFieldStatus = ({ index, email }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const rejections = useSelector( getRejections( index ) );
    const loading = useSelector( isLoadingRejections( index ) );

    const isRejectedEmail = useMemo( () => rejections?.find( item => item.email === email ), [ email, rejections ] );
    const emailValid = useMemo( () => isEmail( email ), [ email ] );

    useEffect( () => {
        if ( email && isEmail( email ) ) {
            dispatch( fetchRejections( index, email ) );
        }
    }, [ dispatch, email, index ] );

    return (
        <>
            { loading && <div><CircularProgress className={ css.loadingSearch } /></div> }
            {
                !isRejectedEmail && !loading && emailValid &&
                    <Tooltip title={ t( "marketplace:automation.mailtop.email-available" ) }>
                        <CheckIcon className={ css.avaliable }/>
                    </Tooltip>

            }
            {
                isRejectedEmail &&
                    <Tooltip title={ t( "marketplace:automation.mailtop.email-rejected" ) }>
                        <WarningIcon className={ css.unavaliable }/>
                    </Tooltip>
            }
        </>
    );
};

export default EmailFieldStatus;
