import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

import { getUser } from "../core/core.selectors";

export const isLoadingTemplates = state => state.templates.all.loading;
export const getTemplates = createSelector(
    state => state.templates.all,
    all => all.data
);
export const getTemplatesByType = state => types =>
    getTemplates( state ).filter( template => types.includes( template.type ) );
export const getSystemTemplates = createSelector(
    state => state.templates.all,
    all => ( all.data || [] ).filter( template => !template.user )
);
export const getMyTemplates = state => ( getTemplates( state ) || [] )
    .filter( template => template.user && template.user.id === getUser( state ).id );
export const getSharedTemplates = state => ( getTemplates( state ) || [] )
    .filter( template => template.user && template.user.id !== getUser( state ).id );

const imagesBase = state => state.templates.images;
export const isLoadingImages = requestSelectors.isLoading( imagesBase );
export const getImages = requestSelectors.getData( imagesBase );
export const getSystemImages = state => ( getImages( state ) || [] ).filter( image => !image.userId );
export const getMyImages = state => ( getImages( state ) || [] ).filter( image => !!image.userId );