import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { flex } from "../../ui";
import css from "./phases.scss";

const OpportunityItemEmail = ({ opportunity, companyField, personField }) => {
    const t = useTranslate();

    const value = useMemo( () => {
        if ( opportunity.person && personField && !isEmpty( opportunity.person.fields[ personField.id ] ) ) {
            return opportunity.person.fields[ personField.id ][ 0 ];
        }
        if ( !companyField || isEmpty( opportunity.company?.fields[ companyField.id ] ) ) {
            return t( "common:empty-email" );
        }
        return opportunity.company.fields[ companyField.id ][ 0 ];
    }, [ t, opportunity, companyField, personField ] );

    return (
        <div
            className={
                classnames(
                    flex.container,
                    flex.alignItemsCenter,
                    css.opportunityInfo,
                    css.opportunityPriceTemperatureRow
                )
            }
        >
            <Typography
                variant="caption"
                className={ flex.fill }
                noWrap
            >
                { value }
            </Typography>
        </div>
    );
};

export default OpportunityItemEmail;