import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./web-capture.actions";

export function leads ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_LEADS:
            return Object.assign( {}, state, {
                loading: true
            });

        case actions.RECEIVE_LEADS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload.leads,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            });

        case actions.ERROR_LEADS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export const lead = createRequestReducer({
    begin: actions.REQUEST_LEAD,
    success: actions.SET_LEAD,
    failure: actions.ERROR_LEAD
});

const leadPortals = createRequestReducer({
    begin: actions.REQUEST_LEAD_PORTALS,
    success: actions.RECEIVE_LEAD_PORTALS,
    failure: actions.ERROR_LEAD_PORTALS
});


export const webCapture = combineReducers({ leads, lead, leadPortals });