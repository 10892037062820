import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getSubscription } from "../subscription/subscription.selectors";

export default function usePriceFormat ( currency ) {
    const subscription = useSelector( getSubscription );

    const priceFormatter = useCallback( price => {
        const value = price ? Number( price ) : 0;
        return value.toLocaleString( ( navigator.language || navigator.userLanguage ), {
            style: "currency",
            currency: currency ? currency : subscription.currency,
        });
    }, [ currency, subscription ] );

    return priceFormatter;
}