import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import isEmpty from "lodash/isEmpty";

import history from "../../../../history";
import useTranslate from "../../../hooks/use-translate.hook";
import { listAll } from "../../../plan/plan.selectors";
import { getSubscription } from "../../../subscription/subscription.selectors";
import { cssUtils } from "../../../ui";
import { getSubscriptionBilling } from "./plan.selectors";
import { subscriptionHasAddress } from "./plan.utils";
import PlanContainer from "./plan-container.component";
import PlanForm from "./plan-form.component";
import Storage from "./storage.component";
import css from "./plan.scss";

const PlanSelector = () => {
    const t = useTranslate();

    const plans = useSelector( listAll );
    const subscription = useSelector( getSubscription );
    const subscriptionBilling = useSelector( getSubscriptionBilling );

    const [ selected, setSelected ] = useState( null );
    const [ isOpenForm, setIsOpenForm ] = useState( false );

    useEffect( () => {
        if ( !selected ) {
            if ( !isEmpty( subscriptionBilling ) ) {
                setSelected( subscriptionBilling.planId );
            } else if ( !isEmpty( plans ) ) {
                setSelected( plans[ 0 ].id );
            }
        }
    }, [ subscriptionBilling, plans, selected ] );

    const handleOpenForm = () => setIsOpenForm( true );
    const handleCloseForm = () => {
        setIsOpenForm( false );
        setSelected( subscriptionBilling?.planId );
    };
    const openSubscriptionRegister = () => history.push( "/config/subscription" );

    const hasAddress = subscriptionHasAddress( subscription );
    const hasDocument = !!subscription.ein || !!subscription.ssn;

    return (
        <>
            {
                !hasAddress &&
                    <Alert
                        action={
                            <Button
                                color="primary"
                                onClick={ openSubscriptionRegister }
                                size="small"
                            >
                                { t( "config:plans.access-register" ) }
                            </Button>
                        }
                        className={ cssUtils.marginBottom }
                        severity="error"
                    >
                        { t( "config:plans.empty-address" ) }
                    </Alert>
            }
            {
                !hasDocument &&
                    <Alert
                        action={
                            <Button
                                color="primary"
                                onClick={ openSubscriptionRegister }
                                size="small"
                            >
                                { t( "config:plans.access-register" ) }
                            </Button>
                        }
                        className={ cssUtils.marginBottom }
                        severity="error"
                    >
                        { t( "config:plans.empty-document" ) }
                    </Alert>
            }
            <Grid container className={ css.listContainer }>
                {
                    plans?.map( plan => ({ ...plan, selected: plan.id === selected })).map( plan => (
                        <Fragment key={ plan.id }>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 } lg={ 4 }>
                                <PlanContainer
                                    plan={ plan }
                                    onClick={ setSelected }
                                    openForm={ handleOpenForm }
                                    selected={ plan.selected }
                                    hired={ plan.id === subscriptionBilling?.planId }
                                    disabled={ !hasAddress || !hasDocument }
                                />
                            </Grid>
                            { plan.selected && <Grid item xs={ 6 } sm={ 6 } md={ 6 } lg={ 4 }><Storage /></Grid> }
                        </Fragment>
                    ))
                }
            </Grid>
            <PlanForm
                open={ isOpenForm }
                planId={ selected }
                onClose={ handleCloseForm }
            />
        </>
    );
};

export default PlanSelector;