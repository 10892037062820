import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import { useForm } from "formik-redux";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../../form";
import { fetchFunnels } from "../../../funnel/funnel.actions";
import { flex } from "../../../ui";
import ConfigHeader from "../../config-header.component";
import { CONFIG_FORM } from "./form.constants";
import ModuleSelection from "./module-selection.component";
import FieldsList from "./fields-list.component";
import FormAction from "./form-action.component";
import * as formConfigSelectors from "./form.selectors";
import * as formConfigActions from "./form.actions";

const Form = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const form = useSelector( formConfigSelectors.selectedForm );
    const loading = useSelector( formSelectors.isLoading( form ) );

    const [ showSubmitError, setShowSubmitError ] = useState( false );
    const toggleSubmitError = () => setShowSubmitError( !showSubmitError );

    const formik = useForm({
        form: CONFIG_FORM,
        initialValues: {},
        onSubmit: values => dispatch( formConfigActions.saveSorting( values ) ),
    });

    useEffect( () => {
        dispatch( formConfigActions.initForm( "COMPANY" ) );
        dispatch( fetchFunnels() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( !isEmpty( formik.error ) ) {
            setShowSubmitError( true );
        }
    }, [ formik.error ] );

    return (
        <>
            <Snackbar
                open={ showSubmitError }
                action={
                    <Button color="secondary" onClick={ toggleSubmitError }>
                        { t( "common:ok" ) }
                    </Button>
                }
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ toggleSubmitError }
            />
            <ConfigHeader title={ t( "config:main.opportunities.customFields" ) }/>
            <div className={ classnames( flex.alignItemsStart, flex.container ) }>
                <div className={ flex.item25 }>
                    <ModuleSelection className="mb" form={ form }/>
                </div>
                <div className={ flex.item75 }>
                    <Card id="configForm">
                        <CardHeader
                            title={ t( "config:form.layout.title", { form: t( "config:form.modules." + form ) } ) }
                            subheader={ t( "config:form.layout.comment" ) }
                        />
                        {
                            loading ?
                                <LinearProgress /> :
                                <FieldsList />
                        }
                        {
                            !loading && (
                                <CardActions>
                                    <form onSubmit={ formik.handleSubmit }>
                                        <FormAction submitting={ formik.submitting } />
                                    </form>
                                </CardActions>
                            )
                        }
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Form;