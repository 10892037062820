import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslate from "../hooks/use-translate.hook";
import { actions as phaseActions, selectors as phaseSelectors } from "../phase";
import { Dropdown, SelectFunnel } from ".";

const PhasesFilter = ({ input }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const phases = useSelector( phaseSelectors.listWithFunnel( input.funnel.value ) );

    const updatePhases = useCallback( event => {
        input.phase.onChange( null );
        input.funnel.onChange( event.target.value );
    }, [ input ] );

    useEffect( () => {
        dispatch( phaseActions.fetchPhases() );
    }, [ dispatch ] );

    return (
        <>
            <SelectFunnel
                onChange={ updatePhases }
                value={ input.funnel.value || "" }
            />
            { phases &&
                <Dropdown
                    label={ t( "ui:phases-filter.phase" ) }
                    disabled={ !input.funnel.value }
                    source={ phases.map( phase => ({ value: phase.id, label: phase.name })) }
                    onChange={ event => input.phase.onChange( event.target.value ) }
                    value={ input.phase.value || "" }
                />
            }
        </>
    );
};

export default PhasesFilter;