import { Fragment, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useTranslate from "../hooks/use-translate.hook";
import { setLocale } from "../core/core.actions";
import { getLocale } from "../core/core.selectors";
import { supportedLanguages } from "../core/core.utils";
import { FlagIcon, cssUtils } from "../ui";

const SelectLocale = () => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const locale = useSelector( getLocale );

    const [ buttonEl, setButtonEl ] = useState( null );

    const handleOpen = event => setButtonEl( event.currentTarget );

    const handleClose = () => setButtonEl( null );

    const handleChange = locale => () => {
        dispatch( setLocale( locale ) );
        handleClose();
    };

    return (
        <Fragment>
            <Tooltip title={ t( "common:change-locale" ) }>
                <Button
                    color="inherit"
                    className={ cssUtils.marginHorizontalSmall }
                    onClick={ handleOpen }
                >
                    <FlagIcon
                        code={ locale.split( "-" )[ 1 ].toLowerCase() }
                        className={ cssUtils.marginRightSmall }
                    />
                    { t( `common:locales.${locale}` ) }
                    <ExpandMoreIcon className={ cssUtils.marginLeftSmall } />
                </Button>
            </Tooltip>
            <Popover
                open={ !!buttonEl }
                anchorEl={ buttonEl }
                onClose={ handleClose }
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <List disablePadding>
                    {
                        supportedLanguages.map( language => (
                            <ListItemButton
                                key={ language }
                                onClick={ handleChange( language ) }
                                selected={ language === locale }
                            >
                                <ListItemIcon>
                                    <FlagIcon code={ language.split( "-" )[ 1 ].toLowerCase() } />
                                </ListItemIcon>
                                <ListItemText primary={ t( `common:locales.${language}` ) }/>
                            </ListItemButton>
                        ))
                    }
                </List>
            </Popover>
        </Fragment>
    );

};

export default memo( SelectLocale );