export const FETCH_API_KEYS = "x/marketplace/FETCH_API_KEYS";
export const fetchApiKeys = () => ({
    type: FETCH_API_KEYS
});

export const REQUEST_API_KEYS = "x/marketplace/REQUEST_API_KEYS";
export const requestApiKeys = () => ({
    type: REQUEST_API_KEYS
});

export const RECEIVE_API_KEYS = "x/marketplace/RECEIVE_API_KEYS";
export const receiveApiKeys = data => ({
    type: RECEIVE_API_KEYS,
    payload: data
});

export const ERROR_API_KEYS = "x/marketplace/ERROR_API_KEYS";
export const errorApiKeys = error => ({
    type: ERROR_API_KEYS,
    error: true,
    payload: error
});

export const SAVE_NEW_API_KEY = "x/marketplace/SAVE_NEW_API_KEY";
export const saveNewApiKey = data => ({
    type: SAVE_NEW_API_KEY,
    payload: data
});

export const DELETE_API_KEY = "x/marketplace/DELETE_API_KEY";
export const deleteApiKey = ({ id }) => ({
    type: DELETE_API_KEY,
    meta: { id }
});

export const SAVE_API_KEY = "x/marketplace/SAVE_API_KEY";
export const saveApiKey = data => ({
    type: SAVE_API_KEY,
    meta: { id: data.id },
    payload: data
});