import { combineReducers } from "redux";
import get from "lodash/get";

import * as actions from "./report.actions";

export const selected = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.SET_REPORT:
            return Object.assign( {}, state, {
                name: action.payload,
                results: null
            });
        case actions.REQUEST_RESULTS:
            return Object.assign( {}, state, {
                results: {
                    loading: true,
                    data: get( state, "results.data" )
                }
            });
        case actions.RECEIVE_RESULTS:
            return Object.assign( {}, state, {
                results: {
                    loading: false,
                    data: action.payload
                }
            });
        case actions.ERROR_RESULTS:
            return Object.assign( {}, state, {
                results: {
                    loading: false,
                    error: action.payload
                }
            });
        default:
            return state;
    }
};

export const reports = combineReducers({ selected });