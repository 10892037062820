import omit from "lodash/omit";

import { DropdownMultiple } from "../ui";

export const SelectMultipleField = (({ formField, disabled, ...props }) => {

    return (
        <DropdownMultiple
            { ...omit( props, "currentPosition" ) }
            disabled={ disabled }
            source={ formField.options.values.map( option => ({
                value: option.value ? option.value : option,
                label: option.label ? option.label : option,
            }))}
            emptyValue
        />
    );
});
