import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../hooks/use-translate.hook";
import { fetchFollowing, saveFollowing } from "./customer.actions";
import { isFollowing, getSelected } from "./customer.selectors";
import { FOLLOWING_FORM } from "./customer.constants";
import css from "./customer.scss";

const CustomerFollowing = ({ customerId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );
    const following = useSelector( isFollowing );

    const [ mouseOver, setMouseOver ] = useState( false );

    const handleMouseOver = () => setMouseOver( true );
    const handleMouseLeave = () => setMouseOver( false );

    const { submitForm, submitting } = useForm({
        enableReinitialize: true,
        form: FOLLOWING_FORM,
        initialValues: {
            id: customer?.id,
            following,
        },
        onSubmit: values => {
            handleMouseLeave();
            dispatch( saveFollowing( values ) );
        },
    });

    useEffect( () => {
        dispatch( fetchFollowing( customerId ) );
    }, [ dispatch, customerId ] );

    return (
        <Button
            onMouseOver={ handleMouseOver }
            onMouseLeave={ handleMouseLeave }
            disabled={ submitting }
            onClick={ submitForm }
            size="small"
            className={
                mouseOver ?
                    following ? css.unfollowButton : css.followButton :
                    following ? css.followingButton : css.followButton
            }
            variant="contained"
        >
            {
                mouseOver ?
                    following ? t( "customer:head.unfollow" ) : t( "customer:head.follow" ) :
                    following ? t( "customer:head.following" ) : t( "customer:head.follow" )
            }
        </Button>
    );
};

export default CustomerFollowing;