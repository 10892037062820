import Tooltip from "@mui/material/Tooltip";
import EventIcon from "@mui/icons-material/Event";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import dayjs from "dayjs";

import useTranslate from "../hooks/use-translate.hook";
import css from "./schedule.scss";

const eventStatusIcon = {
    ACCOMPLISHED: EventAvailableIcon,
    NOT_ACCOMPLISHED: EventBusyIcon,
    OPEN: EventIcon
};

const CalendarEvent = ({ event, title, isAllDay }) => {
    const t = useTranslate();

    const Icon = event.status ? eventStatusIcon[ event.status ] : eventStatusIcon.OPEN;
    const statusDescription = event.status ? t( `schedule:form-event.status.${event.status}` ) : "";

    return (
        <div className={ css.calendarEventItem }>
            {
                isAllDay ?
                    <>
                        <Tooltip title={ statusDescription }>
                            <Icon className={ css.calendarEventIcon } color="inherit"/>
                        </Tooltip>
                        <span className={ css.calendarEventLabel } title={ title }>{ title }</span>
                    </> :
                    <>
                        <Tooltip title={ statusDescription }>
                            <Icon className={ css.calendarEventIcon } style={{ color: event.color }}/>
                        </Tooltip>
                        <span className={ css.calendarEventLabel } title={ title }>
                            { dayjs( event.start ).format( "HH:mm" ) + " " + title }
                        </span>
                    </>
            }
        </div>
    );
};

export default CalendarEvent;