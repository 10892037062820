import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CommentIcon from "@mui/icons-material/Comment";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog, SectionTitle, cssUtils, flex } from "../ui";
import { Timeline, OpportunityEvent } from "../ui/timeline";
import {
    ChangeEvent,
    CreationEvent,
    ContactsEvent,
    CustomerCommentEvent,
    OpportunityStatusEvent,
    EmailSentEvent,
    EmailResponseEvent,
    PhoneCallEvent,
    SmsEvent,
    WhatsAppEvent
} from "./timeline";
import NewComment from "../customer/new-comment.component";
import * as selectors from "./customer.selectors";
import { fetchTimeline, reloadTimeline } from "./customer.actions";
import css from "./customer.scss";

const eventComponentMappings = {
    CUSTOMER_CREATION: CreationEvent,
    CUSTOMER_CHANGE: ChangeEvent,
    CUSTOMER_CONTACTS: ContactsEvent,
    CUSTOMER_COMMENT: CustomerCommentEvent,
    EMAIL_SENT: EmailSentEvent,
    EMAIL_RESPONSE: EmailResponseEvent,
    PHONE_CALL: PhoneCallEvent,
    SMS: SmsEvent,
    WHATSAPP: WhatsAppEvent,
    OPPORTUNITY: OpportunityEvent,
    OPPORTUNITY_STATUS: OpportunityStatusEvent
};

const filters = [
    { value: "CUSTOMER_CHANGE" },
    { label: "EMAIL", value: [ "EMAIL_SENT", "EMAIL_RESPONSE" ] },
    { value: "PHONE_CALL" },
    { value: "SMS" },
    { value: "WHATSAPP" },
    { label: "OPPORTUNITY", value: [ "OPPORTUNITY", "OPPORTUNITY_STATUS" ] }
];

const CustomerTimeline = ({ customerId, page }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( selectors.getSelected );
    const loading = useSelector( selectors.isLoadingTimeline );
    const events = useSelector( selectors.getTimelineEvents );
    const reload = useSelector( selectors.reloadTimeline );

    const [ showCommentDialog, setShowCommentDialog ] = useState( false );
    const [ showTypes, setShowTypes ] = useState( false );
    const [ types, setTypes ] = useState( filters.map( filter => ({ ...filter, checked: false }) ) );
    const [ newTypes, setNewTypes ] = useState( filters.map( filter => ({ ...filter, checked: false }) ) );
    const [ hasTypes, setHasTypes ] = useState( false );

    const toggleCommentDialog = () => setShowCommentDialog( !showCommentDialog );

    const toggleDialogTypes = () => setShowTypes( !showTypes );

    const saveTypes = () => {
        const selectedTypes = newTypes.filter( type => type.checked ).map( type => type.value );
        const hasTypes = selectedTypes.length > 0;

        setTypes( newTypes );
        setHasTypes( hasTypes );
        toggleDialogTypes();
    };

    const cancelTypes = () => {
        setNewTypes( types );
        toggleDialogTypes();
    };

    const toggleType = index => {
        const selectedTypes = newTypes.map( ( type, i ) => index === i ?
            { ...type, checked: !type.checked } :
            { ...type }
        );
        setNewTypes( selectedTypes );
    };

    const loadTimeline = useCallback( () => {
        const selectedTypes = types.filter( type => type.checked ).map( type => type.value );
        dispatch( fetchTimeline({ id: customerId, types: selectedTypes, page }) );
    }, [ dispatch, customerId, page, types ] );
    const handleReloadTimeline = () => dispatch( reloadTimeline() );

    useEffect( () => {
        loadTimeline();
    }, [ loadTimeline ] );
    useEffect( () => {
        if ( reload ) {
            loadTimeline();
        }
    }, [ reload, loadTimeline ] );

    return (
        <div className={ cssUtils.marginBottomLarge }>
            <NewComment
                show={ showCommentDialog }
                onClose={ toggleCommentDialog }
            />

            <Dialog open={ showTypes } fullWidth>
                <DialogTitle>{ "Filtros" }</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {
                            newTypes.map( ( type, index ) => (
                                <FormControlLabel
                                    key={ index }
                                    control={
                                        <Checkbox
                                            checked={ type.checked }
                                            onChange={ () => toggleType( index ) }
                                        />
                                    }
                                    label={
                                        t( "customer:timeline.types." + ( type.label ? type.label : type.value ) )
                                    }
                                />
                            ))
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ cancelTypes }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ saveTypes }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                id="customerSectionTimeline"
                className={ classnames( flex.container, flex.alignItemsCenter ) }
            >
                <SectionTitle
                    className={ flex.fill }
                    icon={ AccessTimeIcon }
                    name={ t( "customer:overview.timeline" ) }
                />
                <Button
                    className={ cssUtils.marginBottom }
                    onClick={ toggleCommentDialog }
                    endIcon={ <CommentIcon/> }
                >
                    { t( "customer:new-comment.title" ) }
                </Button>
                <IconButton
                    className={ classnames( hasTypes ? css.hasTimelineTypes : "", cssUtils.marginBottom ) }
                    onClick={ toggleDialogTypes }
                    size="large">
                    <FilterListIcon/>
                </IconButton>
                <IconButton
                    className={ cssUtils.marginBottom }
                    onClick={ handleReloadTimeline }
                    size="large">
                    <RefreshIcon/>
                </IconButton>
            </div>

            {
                !isEmpty( events ) && customer ?
                    <Timeline>
                        { ( events || [] ).map( ( event, index ) => {
                            const Component = eventComponentMappings[ event.type ];
                            return <Component key={ index } customer={ customer } event={ event }/>;
                        })}
                    </Timeline> :
                    null
            }

            { loading && <LinearProgress/> }
        </div>
    );
};

export default CustomerTimeline;