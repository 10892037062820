import { Field, FieldArray } from "formik";
import { Form } from "formik-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import dayjs from "dayjs";
import set from "lodash/set";
import get from "lodash/get";
import has from "lodash/has";
import omit from "lodash/omit";

import useTranslate from "../hooks/use-translate.hook";
import usePrevious from "../hooks/use-previous.hook";
import { getUser } from "../core/core.selectors";
import { isEmail } from "../core/validator.utils";
import {
    Input,
    ColorPicker,
    DatePicker,
    DateTimePicker,
    Dropdown,
    RadioButtons,
    SelectCustomer,
    Switch,
    cssUtils,
    flex
} from "../ui";
import ScheduleEventSocialFields from "./schedule-event-social-fields.component";

export const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !values.startDate ) {
        errors.startDate = "common:validation.required";
    }
    if ( !values.endDate ) {
        errors.endDate = "common:validation.required";
    }
    if ( !values.title ) {
        errors.title = "common:validation.required";
    }
    if ( values.recurrent && values.recurrent.active ) {
        if ( !values.recurrent.type ) {
            set( errors, "recurrent.type", "common:validation.selection" );
        }
        if ( !values.recurrent.until ) {
            set( errors, "recurrent.until", "common:validation.required" );
        }
    }
    if ( values.notify && !values.notifyMinutes ) {
        errors.notifyMinutes = "common:validation.required";
    }

    if ( values.social && values.social.data && !isEmpty( values.social.data.attendees ) ) {
        for ( let i = 0; i < values.social.data.attendees.length; i++ ) {
            const attendee = values.social.data.attendees[ i ];
            if ( !attendee.email ) {
                set( errors, `social.data.attendees[${i}].email`, "common:validation.required" );
            } else if ( !isEmail( attendee.email ) ) {
                set( errors, `social.data.attendees[${i}].email`, "common:validation.email" );
            }
        }
    }

    return errors;
};

const getEventUser = event => event.user ? event.user : event.opportunity.user;

const ScheduleEventForm = ({ event, formik }) => {
    const t = useTranslate();

    const user = useSelector( getUser );

    const {
        allDay,
        notify,
        notifyMinutes,
        recurrent,
        endDate,
        startDate,
    } = formik.values;
    const { setFieldValue } = formik;

    const prevAllDay = usePrevious( allDay );
    const prevRecurrent = usePrevious( recurrent );
    const prevNotifyMinutes = usePrevious( notifyMinutes );

    const handleChangeNotifyAt = value => {
        if ( !value ) {
            setFieldValue( "notifyAt", null );
            setFieldValue( "notifyMinutes", null );
            return;
        }

        const notifyAt = new Date( startDate );
        notifyAt.setMinutes( notifyAt.getMinutes() - value );
        setFieldValue( "notifyAt", notifyAt );
    };

    const toggleNotify = notify => {
        if ( !notify ) {
            handleChangeNotifyAt();
        } else {
            handleChangeNotifyAt( 0 );
        }
    };

    const handleChangeStartDate = value => {
        if ( dayjs( startDate ).isSame( value ) ) {
            return;
        }
        handleChangeNotifyAt();
        const endDate = new Date( value );
        endDate.setMinutes( endDate.getMinutes() + 60 );
        setFieldValue( "endDate", endDate );
        if ( recurrent.active && recurrent.until ) {
            const dateDayjs = dayjs( value );
            const nextYear = dayjs( value ).add( 1, "year" );
            const until = dayjs( recurrent.until );
            if ( dateDayjs.isAfter( until ) || nextYear.isBefore( until ) ) {
                setFieldValue( "recurrent.until", null );
            }
        }
    };

    useEffect( () => {
        if ( prevNotifyMinutes === notifyMinutes ) {
            return;
        }
        if ( !notifyMinutes ) {
            setFieldValue( "notifyAt", null );
            setFieldValue( "notifyMinutes", null );
            return;
        }

        const notifyAt = new Date( startDate );
        notifyAt.setMinutes( notifyAt.getMinutes() - notifyMinutes );
        setFieldValue( "notifyAt", notifyAt );
    }, [ setFieldValue, prevNotifyMinutes, notifyMinutes, startDate ] );


    useEffect( () => {
        if ( !prevAllDay && allDay ) {
            startDate && setFieldValue( "startDate", dayjs( startDate ).startOf( "day" ).toDate() );
            endDate && setFieldValue( "endDate", dayjs( endDate ).endOf( "day" ).toDate() );
        }
    }, [ prevAllDay, allDay, setFieldValue, startDate, endDate ] );

    useEffect( () => {
        if ( prevRecurrent?.active && !recurrent.active ) {
            if ( has( event, "recurrent.id" ) ) {
                setFieldValue( "recurrent", ({ ...event.recurrent, active: false }) );
            }
        }
    }, [ setFieldValue, event, prevRecurrent, recurrent ] );
    useEffect( () => {
        if ( !prevRecurrent?.active && recurrent.active ) {
            setFieldValue(
                "recurrent",
                ({ ...get( event, "recurrent", {} ), active: true, updateMode: "SINGLE" })
            );
        }
    }, [ setFieldValue, event, recurrent, prevRecurrent ] );
    useEffect( () => {
        if ( !isEqual( omit( prevRecurrent, [ "updateMode" ] ), omit( recurrent, [ "updateMode" ] )) &&
            has( prevRecurrent, "until" ) &&
            get( recurrent, "updateMode" ) !== "FUTURE"
        ) {
            setFieldValue( "recurrent.updateMode", "FUTURE" );
        }
    }, [ setFieldValue, prevRecurrent, recurrent ] );

    const now = new Date().toISOString();
    const showSocialFields = ( !event && user.socialCalendar ) ||
        ( event && !isEmpty( event.social ) ) ||
        ( event && !!getEventUser( event ).socialCalendar );

    const recurrentHasChanged = event && !isEqual( event.recurrent, omit( recurrent, [ "active", "updateMode" ] ) );
    const recurrentUpdateOptions = has( event, "id" ) && has( event, "recurrent.id" ) &&
        [
            {
                value: "SINGLE",
                label: t( "schedule:form-event.recurrent.update.single" ),
                disabled: recurrentHasChanged || !recurrent.active,
                className: flex.fill
            }, {
                value: "FUTURE",
                label: t( "schedule:form-event.recurrent.update.future" ),
                className: flex.fill
            }, {
                value: "ALL",
                label: t( "schedule:form-event.recurrent.update.all" ),
                disabled: recurrentHasChanged,
                className: flex.fill
            }
        ];

    return (
        <Form formik={ formik }>
            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Field
                    className={ classnames( flex.fill, cssUtils.marginRight ) }
                    name="title"
                    type="text"
                    label={ t( "schedule:form-event.title" ) }
                    component={ Input }
                    required
                />
                <Field
                    name="color"
                    type="text"
                    defaultColor="#4CAF50"
                    component={ ColorPicker }
                    required
                />
            </div>
            <div className={ flex.container }>
                <div className={ flex.fill }>
                    <Field
                        name="startDate"
                        type="text"
                        label={ t( "schedule:form-event.start-date" ) }
                        startOfDay={ allDay }
                        component={ allDay ? DatePicker : DateTimePicker }
                        onAccept={ handleChangeStartDate }
                        required
                        fullWidth
                    />
                </div>
                <div className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }>
                    <Field
                        name="endDate"
                        type="text"
                        label={ t( "schedule:form-event.end-date" ) }
                        endOfDay={ allDay }
                        component={ allDay ? DatePicker : DateTimePicker }
                        minDate={ startDate }
                        required
                    />
                </div>
            </div>
            <div className={ classnames( flex.alignItemsCenter, flex.container ) }>
                <Field
                    name="allDay"
                    type="checkbox"
                    label={ t( "schedule:form-event.all-day" ) }
                    component={ Switch }
                    fullWidth={ false }
                />
                <Field
                    label={ t( "schedule:form-event.recurrent.label" ) }
                    name="recurrent.active"
                    type="checkbox"
                    component={ Switch }
                    className={ flex.fill }
                    fullWidth={ false }
                />

                {
                    recurrent.active &&
                        <>
                            <Field
                                name="recurrent.type"
                                label={ t( "schedule:form-event.recurrent.type.label" ) }
                                source={
                                    [ "DAILY", "WEEKLY", "MONTHLY", "YEARLY" ].map( recurrentType => ({
                                        label: t( `schedule:form-event.recurrent.type.${recurrentType}` ),
                                        value: recurrentType
                                    }) )
                                }
                                component={ Dropdown }
                                className={ flex.fill }
                                fullWidth={ false }
                                required
                            />
                            <Field
                                name="recurrent.until"
                                component={ DatePicker }
                                label={ t( "schedule:form-event.recurrent.until" ) }
                                className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                minDate={ startDate }
                                maxDate={ dayjs( startDate ).add( 1, "year" ).toDate() }
                                fullWidth={ false }
                                disabled={ !startDate }
                                asDate
                                required
                            />
                        </>
                }
            </div>

            {
                ( startDate && startDate > now ) || notify ?
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            label={ t( "schedule:form-event.notify" ) }
                            name="notify"
                            type="checkbox"
                            onChange={ toggleNotify }
                            className={ flex.fill }
                            component={ Switch }
                            fullWidth={ false }
                        />
                        {
                            notify ?
                                <Field
                                    label={ t( "schedule:form-event.minutes" ) }
                                    name="notifyMinutes"
                                    type="number"
                                    className={ flex.fill }
                                    margin="none"
                                    component={ Input }
                                    fullWidth={ false }
                                    required
                                /> :
                                null
                        }
                    </div> :
                    null
            }

            <Field
                name="customer.id"
                label={ t( "schedule:form-event.customer" ) }
                customer={ event?.customer }
                component={ SelectCustomer }
            />
            <Field
                name="description"
                type="text"
                label={ t( "schedule:form-event.description" ) }
                multiline
                component={ Input }
            />

            {
                event?.status === "NOT_ACCOMPLISHED" &&
                    <Field
                        name="notAccomplishedReason"
                        type="text"
                        label={ t( "schedule:form-event.not-accomplished-reason" ) }
                        multiline
                        component={ Input }
                    />
            }

            {
                showSocialFields &&
                    <ScheduleEventSocialFields wrapperFields={ FieldArray } wrapperField={ Field } />
            }

            {
                event && event.id && event.recurrent && event.recurrent.id &&
                    <Field
                        label={ t( "common:mode" ) }
                        name="recurrent.updateMode"
                        component={ RadioButtons }
                        className={ classnames( cssUtils.marginTopSmall, cssUtils.marginBottom ) }
                        fullWidth
                        options={ recurrentUpdateOptions }
                    />
            }
        </Form>
    );
};

export default ScheduleEventForm;