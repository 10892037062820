import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteMailtopList } from "./mailtop.actions";
import { MAILTOP_DELETE_LIST_FORM } from "./mailtop.constants";

const DeleteList = ({ id, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: MAILTOP_DELETE_LIST_FORM,
        initialValues: { id },
        onSubmit: values => dispatch( deleteMailtopList( values ) ),
    });

    return (
        <DialogConfirmForm
            open={ !!id }
            handleSubmit={ formik.handleSubmit }
            message={ t( "marketplace:automation.mailtop.lists.confirm-delete" ) }
            onClose={ onClose }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteList;