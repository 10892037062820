export default {
    "title": "Marketplace",
    "integrations": {
        "title": "Integrações",
        "subtitle": "Configure suas integrações com Portais, Captção Web, Facebook ADS e muito mais.",
        "able": "Habilitado",
        "lead": {
            "title": "Captação Web",
            "help":
                "Módulo responsável por receber os e-mails dos seus portais verticais e sites, " +
                "gerando leads de forma automática. Para ativar é necessário configurar a " +
                "distribuição e as captações nas abas abaixo. O funcionamento da Captação Web é simples. " +
                "Captamos os dados do lead através do e-mail que você recebe do portal. Criamos um lead " +
                "para que você possa qualificar, podendo: - transformá-lo em cliente, criando uma " +
                "oportunidade; - descartá-lo por falta de dados no e-mail recebido. No LeadStation " +
                "você configura a distribuição dos leads recebidos, ou seja, que usuários do sistema " +
                "receberão estes leads. Além disto, você pode diferenciar os leads recebidos por origem. " +
                "Assim, será simples medir a captação por cada portal ou site. Exemplo: O portal " +
                "iCarros possui a origem iCarros e o e-mail lead.iCarros.1@leadstation.com.br." +
                "Você copia este e-mail, cola nas configurações do portal iCarros para ser o " +
                "destinatário de e-mails de novos leads e recebe os dados automaticamente no LeadStation. " +
                "Isto pode ser feito para todos os portais, visto que todos enviam e-mail de aviso de " +
                "recebimento de novo lead, com os dados do lead no corpo do e-mail.",
            "read-more": "Ler mais"
        },
        "lead-distribution": {
            "title": "Distribuição de leads",
            "call-to-action":
                "Informe aqui quais são os usuários que receberão os leads integrados. " +
                "Se for selecionado uma equipe será seguido o padrão de distribuição da equipe. Para usuários " +
                "selecionando mais de um usuário a distribuição será proporcional para eles, " +
                "ordenando de forma alfabética.",
            "distribution": "Distribuição",
            "team": "Equipe",
            "users": "Usuários",
            "last-user": "Último lead criado para { user }"
        },
        "lead-source": {
            "title": "Captação",
            "call-to-action":
                "Informe as origens que correspondem aos seus portais verticais ou sites. " +
                "Para cada uma delas é fornecido um endereço de e-mail que deve ser cadastrado " +
                "no seu portal vertical ou site para receber os leads.",
            "name": "Nome",
            "source": "Origem",
            "portal": "Portal",
            "pending": "Configuração pendente",
            "add": "Adicionar captação",
            "copy-email": "Copiar e-mail",
            "distribution": {
                "title": "Distribuição",
                "help":
                    "Informe aqui quais são os usuários que receberão os leads integrados. Esta configuração " +
                    "irá sobrescrever a configuração geral de distribuição da Captação Web."
            },
            "fields": {
                "title": "Configuração de campos de oportunidade",
                "help":
                    "Selecione os valores padrões dos campos de tipo \"Select\" para as oportunidades a serem criadas",
                "label": "Configuração",
                "field-id": "Campo",
                "default-value": "Valor padrão"
            },
            "delete": {
                "title": "Excluir configuração",
                "comment": "Você tem certeza que deseja excluir esta configuração? Esta ação é irreversível."
            }
        },
        "facebook": {
            "title": "Facebook",
            "info":
                "Aqui é feito o vínculo das páginas do Facebook para recebimento de leads no sistema. O LeadStation " +
                "possibilita a integração com páginas do Facebook para integração de leads gerados " +
                "a partir destas páginas.",
            "lead-distribution": {
                "distribution": "Distribuição",
                "team": "Equipe",
                "users": "Usuários"
            },
            "lead-pages": {
                "title": "Páginas",
                "your-pages": "Páginas integradas",
                "call-to-action": "Configure aqui as suas páginas para que o LeadStation comece a receber leads.",
                "add-help":
                        "Informe aqui qual será a origem e quais serão os usuários que receberão " +
                        "os leads integrados. Você pode definir uma equipe para receber os leads ou " +
                        "especificar diretamente alguns  usuários. Especificando mais de um usuário a distribuição " +
                        "será proporcional para eles, distribuição será proporcional para eles, " +
                        "ordenando de forma alfabética.",
                "add": "Adicionar página",
                "edit": "Editar página",
                "select-pages": "Selecione as páginas",
                "page": "Página",
                "form": {
                    "label": "Formulário",
                    "help":
                            "Você pode definir esta configuração para um formulário específico. " +
                            "Se nenhum formulário for informado, esta configuração será considera para " +
                            "todos os formulários da página."
                },
                "source": "Origem",
                "funnel": "Funil",
                "edit-description": "Altere aqui os dados da página { page }",
                "expired-token": "Chave de acesso expirada em { date }. Clique aqui para atualizar",
                "expire-token": "Chave de acesso expira { date }. Clique aqui para atualizar",
                "confirm-delete": "Você tem certeza que deseja excluir esta página?",
                "created-info": "{ user } configurou esta página em { date }",
                "empty": "Você não tem páginas para adicionar"
            },
        },
        "new-api-key": {
            "title": "Nova configuração"
        },
        "api-key": {
            "name": "Nome",
            "key": "Chave",
            "copy-key": "Copiar chave { key }",
            "source": "Origem",
            "team": "Equipe",
            "users": "Usuários"
        },
        "api-keys": {
            "title": "Integração API",
            "help": "<span>Aqui é feita a configuração das chaves a serem utilizadas para geração de clientes e oportunidades via API. Estas chaves devem ser usadas como forma de autenticação. Para maiores informações, acesse a documentação em <span><a target=\"_blank\" href=\"https://docs.leadstation.com.br/#/pt-BR/integrations/integration-api\"><b>https://docs.leadstation.com.br/#/pt-BR/integrations/integration-api</b></a></span>. Para dados técnicos de como utilizar, acesse </span><a target=\"__blank\" href=\"https://docs.leadstation.com.br/api/v1/#/ApiKey/post_apikeys__key__customers_opportunities\"> <b>https://docs.leadstation.com.br/api/v1/</b> </a> <span> sob o endpoint <b>POST https://api.leadstation.com.br/api/v1/apikeys/{key}/customers/opportunities.</b></span>\n",
            "fields-body-api": "Campos de formulário para API",
            "help-fields-body-api":
                "Campos de formulário dos tipos Texto, Data, Número e Select para serem usados na API. " +
                "Para informações dos valores aceitos nos campos diferentes de \"customer.fields\" e " +
                "\"opportunity.fields\", acesse a documentação da API.",
            "date-field-value": "Data em formato ISO",
            "customer-fields": "Campos de cliente (customer.fields):",
            "opportunity-fields": "Campo de oportunidade (opportunity.fields):",
            "observation-fields": "Observação: os nomes dos campos em parênteses devem ser ignorados",
            "empty": "Não há chaves cadastradas."
        },
        "delete-api-key": {
            "confirm": "Você tem certeza que deseja excluir esta configuração? Esta ação é irreversível",
            "yes": "Sim, excluir"
        },
        "portals": {
            "title": "Portais",
            "call-to-action":
                "Configure aqui seu portal de vendas",
            "new": {
                "title": "Novo portal",
                "help":
                    "Após preencher todas as configurações e salvar, será gerada uma URL para " +
                    "você utilizar no { integrationChannel }"
            },
            "edit": {
                "title": "Editar portal"
            },
            "empty": "Nenhuma integração configurada",
            "total": "Integrações configuradas: { total }",
            "icarros-header": "Informe abaixo os dados da conta do iCarros",
            "mobiauto-header": "Informe abaixo os dados da conta do Mobiauto",
            "usadosbr-header": "Informe abaixo os dados da conta do Usadosbr",
            "webmotors-header": "Informe abaixo os dados da conta do Webmotors",
            "expired": "Acesso expirado",
            "active": "Ativo",
            "name": {
                "label": "Nome",
                "help": "O nome que você informar aqui para esta integração, será o título das oportunidades criadas"
            },
            "source": "Origem",
            "empty-users": "Você deve selecionar uma distribuição ou equipe ou ao menos um usuário para distribuir " +
                "as oportunidades",
            "MERCADO_LIBRE": {
                "title": "Mercado Livre",
                "call-to-action":
                    "Configure este portal para receber as perguntas nos seus anúncios de veículos ou imóveis"
            },
            "OLX": {
                "title": "OLX",
                "call-to-action":
                    "Configure este portal para receber as mensagens nos seus anúncios"
            },
            "MOBIAUTO": {
                "title": "Mobiauto",
                "call-to-action":
                    "Configure este portal para receber as mensagens nos seus anúncios de veículos"
            },
            "USADOSBR": {
                "title": "Usadosbr",
                "call-to-action":
                    "Configure este portal para receber as mensagens nos seus anúncios de veículos"
            },
            "WEBMOTORS": {
                "title": "Webmotors",
                "call-to-action":
                    "Configure este portal para receber as perguntas nos seus anúncios de veículos ou imóveis"
            },
            "ICARROS": {
                "title": "iCarros",
                "call-to-action":
                    "Configure este portal para receber as perguntas nos seus anúncios de veículos ou imóveis"
            },
            "credentials-error": "Não foi possivel conectar. Verifique seu usuário e senha",
            "dealer": "Loja",
            "type": {
                "ICARROS": "iCarros",
                "MERCADO_LIBRE": "Mercado Livre",
                "MOBIAUTO": "Mobiauto",
                "OLX": "OLX",
                "USADOSBR": "Usadosbr",
                "WEBMOTORS": "Webmotors"
            }
        },
        "icarros": {
            "title": "iCarros",
        },
        "mercadolibre": {
            "title": "Mercado Livre",
        },
        "olx": {
            "title": "OLX",
        },
        "webmotors": {
            "title": "Webmotors",
        },
        "api": {
            "title": "Integrações API",
        }
    },
    "products": {
        "title": "Produtos Integrados",
        "subtitle": "Adicionar produtos para aumentar a sua agilidade nas vendas.",
        "sms": {
            "title": "SMS",
            "help":
                    "Esta integração disponibiliza a opção para o usuário enviar SMS " +
                    "(mensagem de texto para celular) para o cliente. O LeadStation recebe " +
                    "a resposta do SMS do cliente e disponibiliza no sistema. ",
            "how-to-enable": "Para habilitar entre em contato com nossa central de atendimento",
            "consumption": "Consumo do mês atual",
            "sent": "Enviado",
            "pending": "Pendente",
            "received": "Recebido",
            "rejected": "Rejeitado",
            "status": "Status",
            "quantity": "Quantidade",
            "balance": "Saldo disponível: { balance }"
        },
    },
    "automation": {
        "title": "Automações",
        "subtitle": "Integre as ferramentas de marketing.",
        "call-to-action":
        "Configure aqui a integração com ferramentas de automação de marketing. " +
        "Para maiores informações e auxílio para configurar, acesse ",
        "docs-link": {
            "label": "este link",
            "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations"
        },
        "new": {
            "title": "Nova automação",
            "exact-sales-help": "O token de acesso deve ser gerado no sistema Exact Spotter. " +
                "Após preencher todas as configurações e salvar, será feito o cadastro de uma " +
                "webhook para receber os novos leads da Exact Sales",
            "help":
                "Após preencher todas as configurações e salvar, será gerada uma URL para " +
                "você utilizar no { integrationChannel }. A requisição enviada deverá ser de tipo POST"
        },
        "edit": {
            "title": "Editar automação"
        },
        "empty": "Nenhuma integração configurada",
        "total": "Integrações configuradas: { total }",
        "name": {
            "label": "Nome",
            "help": "O nome que você informar aqui para esta integração, será o título das oportunidades criadas"
        },
        "source": "Origem",
        "access-token": "Token de acesso a plataforma",
        "portal-distribution": "Distribuição",
        "empty-users": "Você deve selecionar uma distribuição ou uma equipe " +
                "ou ao menos um usuário para distribuir as oportunidades",
        "integration-channel": {
            "EXACT_SALES": "Exact Sales",
            "MAILTOP": "Mailtop",
            "LEADLOVERS": "Leadlovers",
            "MKT2EASY": "MKT2Easy",
            "RD_STATION": "RD Station",
            "OTHER": "HTTP Rest API"
        },
        "mapping-fields": {
            "title": "Mapeamento de campos",
            "help":
            "Aqui você faz o vínculo dos campos enviados pelo sistema com os campos do LeadStation. Para " +
            "os campos de Texto, Endereço e Telefone, você pode especificar mais de um valor " +
            "para combinar os valores, por exemplo, Nome e Sobrenome combinandos se transformam no campo " +
            "Nome do LeadStation. Digite uma palavra e pressione Enter",
            "customer-fields": "Dados de cliente do LeadStation",
            "opportunity-fields": "Dados de oportunidade do LeadStation",
            "req-fields": "Dados de { integrationChannel }"
        },
        "mailtop": {
            "title": "Mailtop",
            "call-to-action":
                "Aproveite o potencial do email marketing para ganhar vendas e fortalecer " +
                "seu relacionamento com clientes através do Mailtop. Crie campanhas promocionais " +
                "com templates prontos e fáceis de modificar, segmente listas de contatos e analise " +
                "a performance do seus disparos de email marketing com a nossa solução completa. " +
                "Conte com a melhor solução de email marketing para o seu negócio. Planos pré e " +
                "pós pagos que cabem no seu bolso. Acesse agora, ",
            "access": "Acessar Mailtop",
            "tabs": {
                "auth": "Autenticação",
                "lists": "Listas"
            },
            "auth": {
                "title": "Para utilizar os recursos do Mailtop, você precisa obter uma chave de API",
                "token": "Chave de API",
                "created": "Configurado por { createdBy } em { date }",
                "confirm-delete": "Você tem certeza que deseja excluir esta chave de API?"
            },
            "lists": {
                "error-load": "Não foi possível carregar as listas do Mailtop",
                "title": "Configure aqui as listas que receberão os dados dos clientes",
                "add": "Adicionar lista",
                "edit": "Editar lista",
                "single": "Lista do Mailtop",
                "copy-webhook": "Copiar URL para webhook de automação do Mailtop",
                "event": {
                    "label": "Evento",
                    "OPPORTUNITY_CREATED": {
                        "label": "Oportunidade criada",
                    },
                    "OPPORTUNITY_UPDATED": {
                        "label": "Oportunidade alterada",
                        "helper": "Quando a oportunidade entrar no funil ou na fase"
                    },
                    "OPPORTUNITY_LOST": {
                        "label": "Oportunidade perdida",
                    },
                    "OPPORTUNITY_SOLD": {
                        "label": "Oportunidade vendida",
                    }
                },
                "create-opportunity": {
                    "label": "Criar oportunidade",
                    "help":
                        "Se nenhuma oportunidade em aberto para o funil/fase configurado for encontrada, " +
                        "este campo define se deve criar uma nova oportunidade ao receber a chamada de Webhook " +
                        "da Automação do Mailtop."
                },
                "executing": "Executando desde { date }",
                "finished-at": "Execução finalizada em { date }",
                "execute": {
                    "title": "Executar lista",
                    "confirm": "Você tem certeza que deseja executar esta lista?",
                    "description":
                        "Ao executar, os e-mails dos clientes das oportunidades desta lista que já estão " +
                        "no LeadStation, serão enviados para o Mailtop. Este processo não pode ser cancelado."
                },
                "confirm-delete": "Você tem certeza que deseja excluir a integração com esta lista?"
            },
            "source": "Origem do Mailtop",
            "email-rejected": "Este e-mail está na lista de rejeições do Mailtop",
            "email-available": "Este e-mail não está na lista de rejeições do Mailtop"
        },
        "EXACT_SALES": {
            "title": "Exact Sales",
            "call-to-action":
            "Exact Sales é uma plataforma completa de automação de marketing que oferece  " +
            "recursos como páginas de captura, sequências de e-mails, webinars e muito mais. Ao  " +
            "integrar o Exact Sales com sua aplicação Leadstation, você pode receber os leads  " +
            "capturados no Exact Sales diretamente na sua aplicação, possibilitando o gerenciamento  " +
            "centralizado dessas informações e a automação de tarefas relacionadas. " +
            "Para maiores informações e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=exact-sales-automação"
            }
        },
        "LEADLOVERS": {
            "title": "Leadlovers",
            "call-to-action":
            "Leadlovers é uma plataforma completa de automação de marketing que oferece  " +
            "recursos como páginas de captura, sequências de e-mails, webinars e muito mais. Ao  " +
            "integrar o Leadlovers com sua aplicação Leadstation, você pode receber os leads  " +
            "capturados no Leadlovers diretamente na sua aplicação, possibilitando o gerenciamento  " +
            "centralizado dessas informações e a automação de tarefas relacionadas. " +
            "Para maiores informações e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=leadlovers-automação"
            }
        },
        "MKT2EASY": {
            "title": "MKT2Easy",
            "call-to-action":
            "MKT2Easy é uma plataforma de automação de marketing digital que simplifica o " +
            "gerenciamento de campanhas de e-mail marketing, automação de fluxos de trabalho, " +
            "criação de landing pages e muito mais. Ao integrar o MKT2Easy com sua aplicação " +
            "Leadstation, você pode receber os leads capturados no MKT2Easy diretamente na sua " +
            "aplicação, permitindo uma gestão centralizada e eficiente dessas informações. " +
            "Para maiores informações acesse e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=mkt2easy-automação"
            }
        },
        "RD_STATION": {
            "title": "RD Station",
            "call-to-action":
                "Rd Station é uma plataforma de automação de marketing que auxilia na geração e gestão  " +
                "de leads, automação de e-mails, análise de resultados e muito mais. Com a integração " +
                "entre o Rd Station e sua aplicação Leadstation, você pode receber os leads capturados " +
                "no Rd Station na sua aplicação de forma automática. Isso permite a centralização dos " +
                "dados dos leads e a otimização das suas estratégias de marketing. Para maiores informações " +
                "e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=rd-station-automação"
            },
        },
        "OTHER": {
            "title": "HTTP Rest API",
            "call-to-action":
                    "A comunicação HTTP REST é um protocolo amplamente utilizado para integração entre " +
                    "sistemas. Ela permite que sua aplicação se comunique com outras plataformas e " +
                    "serviços externos por meio de chamadas HTTP. Ao utilizar a comunicação HTTP REST " +
                    "em conjunto com sua aplicação Leadstation, você pode integrar diversas outras " +
                    "ferramentas de automação e receber os leads capturados por meio dessas integrações. " +
                    "Para maiores informações e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=http-rest-api-automação"
            }
        }
    },
    "hubs": {
        "title": "Hubs de Integração",
        "subtitle": "Utilize os melhores hubs disponíveis para integrar com vários outros sistemas.",
        "zapier": {
            "title": "Zapier",
            "call-to-action": "O Zapier é uma ferramenta de automação que permite conectar o " +
            "Leadstation a outros aplicativos e serviços de forma simples. Com o " +
            "Zapier, você pode configurar gatilhos e ações para automatizar " +
            "tarefas entre o Leadstation e outras ferramentas, melhorando a " +
            "produtividade e a eficiência. Para maiores informações e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/zapier?id=integração-zapier"
            }
        }
    },
    "migrations": {
        "title": "Migrações",
        "help":
            "Migre os clientes e oportunidades do seu antigo sistema para o LeadStation. Antes de executar, " +
            "certifique-se que todos os usuários estão cadastrados pois a migração de um sistema só pode ser " +
            "executada uma única vez",
        "empty": "Nenhuma migração executada",
        "new-migration": "Nova migração",
        "system": {
            "label": "Sistema",
            "PIPEDRIVE": "Pipedrive"
        },
        "api-token": "Token de API",
        "default-source": "Origem",
        "default-source-help": "Defina qual a origem será utilizada para as oportunidades criadas",
        "default-user": "Usuário padrão",
        "default-user-help":
            "Defina qual usuário será considerado caso haja algum usuário do { system } que " +
            "não existe no LeadStation",
        "executing": "Executando",
        "executed-by": "{ user } executou esta migração em { date }",
        "finished-at": "Migração finalizada em { date }",
        "refresh": "Atualizar migrações",
    },
    "additional-resources": {
        "title": "Outros Recursos",
        "subtitle": "Configure webhooks.",
        "webhooks": {
            "title": "Webhooks",
            "subtitle": "Cadastre aqui as URLs para enviar dados de acordo com o evento à disparar.",
            "call-to-action":
            "Um webhook é um método de comunicação entre sistemas que permite que um sistema " +
            "envie automaticamente informações para outro sistema quando ocorre um evento. No " +
            "contexto do LeadStation, um webhook pode ser usado para integrá-lo a outros sistemas. " +
            "Para maiores informações e auxilio para configurar, acesse ",
            "docs-link": {
                "label": "este link",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/webhooks?id=webhooks"
            },
            "empty": "Não há webhooks cadastrados",
            "new": "Novo Webhook",
            "url": "URL",
            "event": {
                "label": "Evento",
                "CUSTOMER_CREATED": "Cliente criado",
                "OPPORTUNITY_CREATED": "Oportunidade criada",
                "OPPORTUNITY_LOST": "Oportunidade perdida",
                "OPPORTUNITY_SOLD": "Oportunidade vendida"
            },
            "oauth-client": "OAuth Client"
        }
    }
};