import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as fileActions, selectors as fileSelectors } from "../../../file";
import { fetchForm } from "../../../form/form.actions";
import { closeFileModel, openFileModel, toggleNewFileModel } from "./template.actions";
import { selectedFileModel } from "./template.selectors";
import FileModel from "./file-model.component";
import NewFileModel from "./new-file-model.component";

const FileModelList = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fileModels = useSelector( fileSelectors.getModels );
    const loading = useSelector( fileSelectors.isLoadingModels );
    const selected = useSelector( selectedFileModel );

    const handleToggleNewFile = () => dispatch( toggleNewFileModel() );
    const handleClose = () => dispatch( closeFileModel() );

    useEffect( () => {
        dispatch( fileActions.fetchModels() );
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    return (
        <>
            <Card>
                <CardHeader
                    title={ t( "config:files.title" ) }
                    subheader={ t( "config:files.call-to-action" ) }
                    action={
                        <Button color="primary" onClick={ handleToggleNewFile }>
                            { t( "common:add" ) }
                        </Button>
                    }
                />

                <List>
                    { loading && <LinearProgress/> }
                    { !loading && isEmpty( fileModels ) &&
                        <ListItem>
                            <ListItemText secondary={ t( "config:files.empty-models" ) }/>
                        </ListItem>
                    }
                    {
                        fileModels?.map( fileModel => (
                            <div key={ fileModel.id }>
                                <ListItemButton
                                    onClick={ () =>
                                        selected && selected.id === fileModel.id ?
                                            handleClose() :
                                            dispatch( openFileModel( fileModel ) )
                                    }
                                    selected={ selected && selected.id === fileModel.id }
                                >
                                    <ListItemText primary={ fileModel.name }/>
                                </ListItemButton>
                                <Collapse in={ selected && selected.id === fileModel.id } unmountOnExit>
                                    <FileModel fileModel={ selected } onClose={ handleClose }/>
                                </Collapse>
                            </div>
                        ))
                    }
                </List>
            </Card>

            <NewFileModel/>
        </>
    );
};

export default FileModelList;