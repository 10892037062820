import { forwardRef, useCallback, useMemo } from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";

import useTranslate from "../hooks/use-translate.hook";

const NumberFormatCustom = forwardRef( ( props, ref ) => {
    const { useFormattedValue, isNumericString, onChange, ...other } = props;
    const handleValueChange = useCallback( values => {
        onChange( isNumericString ? values.floatValue : useFormattedValue ? values.formattedValue : values.value );
    }, [ onChange, isNumericString, useFormattedValue ]);

    return (
        <NumberFormat
            { ...other }
            getInputRef={ ref }
            isNumericString={ isNumericString }
            onValueChange={ handleValueChange }
        />
    );
});

const MaskInput = ({
    useFormattedValue = false,
    isNumericString,
    format,
    prefix,
    suffix,
    allowNegative,
    fixedDecimalScale,
    decimalScale,
    thousandSeparator,
    decimalSeparator,
    form: { getFieldMeta, setFieldValue } = {},
    field,
    InputProps = {},
    ...props
}) => {
    const t = useTranslate();

    const name = useMemo( () => field.name, [ field ] );

    const handleChange = useCallback( value => setFieldValue( name, value ), [ name, setFieldValue ] );

    const value = useMemo( () => field.value, [ field ] );

    const validationMsg = useMemo( () => {
        const fieldMeta = getFieldMeta( name );
        return fieldMeta.touched && fieldMeta.error;
    }, [ name, getFieldMeta ] );

    return (
        <TextField
            margin="normal"
            fullWidth
            { ...props }
            value={ value }
            error={ !!validationMsg }
            helperText={ validationMsg && t( validationMsg ) }
            InputProps={{
                ...InputProps,
                inputComponent: NumberFormatCustom,
            }}
            inputProps={{
                onChange: handleChange,
                format,
                useFormattedValue,
                isNumericString,
                prefix,
                suffix,
                allowNegative,
                fixedDecimalScale,
                decimalScale,
                thousandSeparator,
                decimalSeparator,
            }}
        />
    );
};

export default MaskInput;