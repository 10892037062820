import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import { Draggable, Droppable } from "react-beautiful-dnd";

import usePrevious from "../../hooks/use-previous.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { fetchPhaseOpportunities } from "../opportunity.actions";
import { getPhaseOpportunities } from "../opportunity.selectors";
import { opportunityKanbanKeys } from "../opportunity.utils";
import OpportunityItem from "./opportunity-item.component";
import css from "./phases.scss";
import { getThemeMode } from "../../core/core.selectors";
import { cssUtils } from "../../ui";

const PhaseContainer = ({ className, phase, orderBy, orderType, saveStatus }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const themeMode = useSelector( getThemeMode );

    const phaseOpportunities = useSelector( getPhaseOpportunities( phase.id ) );

    const loading = useMemo( () => phaseOpportunities.loading || false, [ phaseOpportunities.loading ] );
    const opportunities = useMemo( () => phaseOpportunities.data || [], [ phaseOpportunities.data ] );
    const shouldReload = useMemo( () => phaseOpportunities.reload || false, [ phaseOpportunities.reload ] );
    const price = useMemo( () => phaseOpportunities.totalPrice || 0, [ phaseOpportunities.totalPrice ] );
    const recurrentPrice = useMemo(
        () => phaseOpportunities.totalRecurrentPrice || 0,
        [ phaseOpportunities.totalRecurrentPrice ]
    );
    const page = useMemo( () => phaseOpportunities.page || 1, [ phaseOpportunities.page ] );
    const pages = useMemo( () => phaseOpportunities.totalPages || 0, [ phaseOpportunities.totalPages ] );
    const total = useMemo( () => phaseOpportunities.totalElements || 0, [ phaseOpportunities.totalElements ] );

    const prevShouldReload = usePrevious( shouldReload );

    const incrementPage = () => {
        if ( loading || page === pages ) {
            return;
        }
        dispatch( fetchOpportunities( page + 1 ) );
    };

    const getStyle = useCallback( ( style, snapshot ) => {
        // adicioando getStyle para travar a animação de sortable do componente
        if ( !snapshot.isDragging ) {
            return {};
        }
        if ( !snapshot.isDropAnimating ) {
            return style;
        }

        return {
            ...style,
            transitionDuration: `0.001s`
        };
    }, [] );

    const fetchOpportunities = useCallback( ( page = 1 ) => {
        dispatch( fetchPhaseOpportunities( phase.id, {
            phaseId: phase.id,
            fields: opportunityKanbanKeys.toString(),
            status: "OPEN",
            page_size: 10,
            orderBy: orderBy,
            orderType: orderType,
            page
        }));
    }, [ dispatch, orderBy, orderType, phase.id ] );

    useEffect( () => {
        fetchOpportunities();
    }, [ fetchOpportunities ] );
    useEffect( () => {
        if ( !prevShouldReload && shouldReload ) {
            fetchOpportunities();
        }
    }, [ prevShouldReload, shouldReload, fetchOpportunities ] );

    return (
        <div className={ className }>
            <Card className={ css.container }>
                <div className={ themeMode === "dark" ? css.headerDark : css.headerLight }>
                    <div className={ css.containerTitle }>
                        <Typography
                            className={ css.title }
                            component="span"
                        >
                            { phase.name }
                        </Typography>
                        {
                            phase.description &&
                                <Tooltip title={ phase.description }>
                                    <InfoIcon className={ css.description }/>
                                </Tooltip>
                        }
                    </div>
                    <Typography
                        className={ css.subtitle }
                        component="span"
                    >
                        {
                            t( "opportunity:opportunity-list.phases-total-opportunities", {
                                total
                            })
                        }
                    </Typography>
                    <Typography
                        className={ css.subtitle }
                        component="span"
                    >
                        <Tooltip title={ t( "opportunity:price.label" ) }>
                            <span>{ priceFormat( price ) }</span>
                        </Tooltip>
                        { " / " }
                        <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                            <span>{ priceFormat( recurrentPrice ) }</span>
                        </Tooltip>
                    </Typography>
                </div>
                <Droppable
                    key={ phase.id }
                    droppableId={ `${phase.id}` }
                    index={ phase.id }
                >
                    {({ innerRef, droppableProps, placeholder }) => (
                        <div
                            className={ css.opportunities }
                            ref={ innerRef }
                            { ...droppableProps }
                        >
                            { placeholder }
                            <div className={ css.opportunitiesScroller }>
                                <InfiniteScroll
                                    hasMore={ page < pages }
                                    loadMore={ incrementPage }
                                    useWindow={ false }
                                    initialLoad={ false }
                                    threshold={ 0 }
                                >
                                    {
                                        opportunities.map( ( opportunity, index ) => (
                                            <Draggable
                                                key={ opportunity.id }
                                                draggableId={ opportunity.id }
                                                index={ index }
                                            >
                                                {({ innerRef, draggableProps, dragHandleProps }, snapshot ) => (
                                                    <div ref={ innerRef }
                                                        { ...dragHandleProps }
                                                        { ...draggableProps }
                                                        style={ getStyle( draggableProps.style, snapshot ) }>
                                                        <OpportunityItem
                                                            key={ opportunity.id }
                                                            themeMode={ themeMode }
                                                            opportunity={ opportunity }
                                                            saveStatus={ saveStatus }
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))

                                    }
                                    { loading && <CircularProgress className={ cssUtils.marginTopSmall }/> }
                                </InfiniteScroll>
                            </div>
                        </div>
                    )}
                </Droppable>
            </Card>
        </div>
    );
};

export default PhaseContainer;