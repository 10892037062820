import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useCallback, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { listAll as listAllDistributions } from "../../../config/advanced/distribution/distribution.selectors";
import { selectors as userSelectors } from "../../../user";
import { fetchLeadDistribution, saveLeadDistribution } from "./lead-integration.actions";
import { getLeadDistribution } from "./lead-integration.selectors";
import { LEAD_DISTRIBUTION_FORM } from "./lead-integration.constants";
import { Dropdown, UsersFilter, cssUtils, flex } from "../../../ui";
import { fetchDistributions } from "../../../config/advanced/distribution/distribution.actions";

const filterUsers = ( teamId, users ) => {
    if ( !teamId ) {
        return users.map( user => user.id );
    }

    return users
        .filter( user => !!user.teams.find( item => item.team.id.toString() === teamId.toString() ) )
        .map( user => user.id );
};

const LeadDistribution = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const distributions = useSelector( listAllDistributions );
    const users = useSelector( userSelectors.listAllActive );
    const leadDistribution = useSelector( getLeadDistribution );

    const lastUser = useMemo(
        () => leadDistribution?.lastUser ? users.find( user => user.id === leadDistribution.lastUser ) : null,
        [ leadDistribution, users ]
    );

    const formik = useForm({
        form: LEAD_DISTRIBUTION_FORM,
        enableReinitialize: true,
        initialValues: {
            distribution: leadDistribution?.distribution,
            teamId: leadDistribution?.teamId,
            usersId: leadDistribution?.usersId,
        },
        onSubmit: values => dispatch( saveLeadDistribution( values ) ),
    });

    const { setFieldValue, values } = formik;

    const handleChangeTeam = useCallback( teamId => {
        setFieldValue( "teamId", teamId );
        if ( !teamId ) {
            setFieldValue( "usersId", [] );
        } else {
            setFieldValue( "usersId", filterUsers( teamId, users ) );
            setFieldValue( "distribution", null );
        }
    }, [ setFieldValue, users ] );

    const handleChangeUser = useCallback( usersId => {
        setFieldValue( "usersId", usersId );
        setFieldValue( "teamId", null );
        setFieldValue( "distribution", null );
    }, [ setFieldValue ] );

    useEffect( () => {
        dispatch( fetchDistributions() );
        dispatch( fetchLeadDistribution() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( values.distribution?.id ) {
            setFieldValue( "teamId", null );
            setFieldValue( "usersId", [] );
        }
    }, [ setFieldValue, values.distribution ] );

    return (
        <div>
            <CardHeader
                title={ t( "marketplace:integrations.lead-distribution.title" ) }
                subheader={ t( "marketplace:integrations.lead-distribution.call-to-action" ) }
            />
            <CardContent>
                <Form formik={ formik }>
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            name="distribution.id"
                            label={ t( "marketplace:integrations.lead-distribution.distribution" ) }
                            className={ flex.fill }
                            component={ Dropdown }
                            source={
                                distributions?.map( distribution =>
                                    ({ value: distribution.id, label: distribution.name }) )
                            }
                            emptyValue
                        />
                    </div>
                    <UsersFilter
                        ComponentRoot={ "div" }
                        className={ classnames(
                            flex.column,
                            flex.container,
                            cssUtils.margin0,
                            cssUtils.padding0
                        )}
                        input={{
                            team: {
                                value: values.teamId,
                                onChange: handleChangeTeam
                            },
                            users: {
                                value: values.usersId,
                                onChange: handleChangeUser
                            }
                        }}
                        fullWidth={ true }
                    />
                    {
                        lastUser ?
                            <Typography className={ cssUtils.marginTop }>
                                { t( "marketplace:integrations.lead-distribution.last-user", { user: lastUser.name } ) }
                            </Typography> :
                            null
                    }
                </Form>
            </CardContent>
            <CardActions>
                <Button
                    onClick={ formik.submitForm }
                    color="primary"
                    variant="contained"
                    disabled={ formik.submitting }
                >
                    { t( "common:save" ) }
                </Button>
            </CardActions>
        </div>
    );
};

export default LeadDistribution;