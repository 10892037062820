import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import { DELETE_CUSTOMER_SAMPLE_FORM } from "../config/subscription/main/main.constants";
import { actions } from "./";

export function *fetchSubscription () {
    try {
        const response = yield call( apiv1.get, "/subscription" );
        yield put( actions.receiveSubscription( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSubscription( e.response.data ) );
    }
}

export function *fetchHoldingFunnels ({ meta: { token }}) {
    try {
        const response = yield call( apiv1.get, `/holding/${token}/funnels` );
        yield put( actions.receiveHoldingFunnels( response.data ) );
    } catch ( e ) {
        yield put( actions.errorHoldingFunnels( e.response.data ) );
    }
}

export function *watchSubscription () {
    yield all([
        takeEvery( actions.FETCH_SUBSCRIPTION, fetchSubscription ),
        takeEvery( actions.FETCH_HOLDING_FUNNELS, fetchHoldingFunnels ),
        takeEvery( actions.DELETE_CUSTOMER_SAMPLE, deleteCustomerSample ),
    ]);
}

export function *deleteCustomerSample () {
    try {
        yield put( startSubmit( DELETE_CUSTOMER_SAMPLE_FORM ) );
        yield call( apiv1.delete, `/samples` );
        yield put( stopSubmit( DELETE_CUSTOMER_SAMPLE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_CUSTOMER_SAMPLE_FORM ) );
    }
}