import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

export const isLoadingImportations = state => state.importations.all.loading;
export const getImportations = createSelector( state => state.importations.all, all => all.data );
export const getTotalPagesAll = state => state.importations.all.totalPages;
export const getTotalElementsAll = state => state.importations.all.totalElements;

const selectedBase = state => state.importations.selected;
export const getSelected = requestSelectors.getData( selectedBase );
export const isLoadingSelected = requestSelectors.isLoading( selectedBase );

const resultsBase = state => state.importations.results;
export const getResults = requestSelectors.getData( resultsBase );
export const isLoadingResults = requestSelectors.isLoading( resultsBase );