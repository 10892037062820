import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { getPasswordResetStatus } from "./login.selectors";
import { fetchPasswordResetStatus, savePasswordReset } from "./login.actions";
import { RESET_PASSWORD_FORM } from "./login.constants";
import { Link, Input, cssUtils } from "../ui";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const status = useSelector( getPasswordResetStatus );

    const formik = useForm({
        form: RESET_PASSWORD_FORM,
        initialValues: {
            id: params.id,
            password: "",
            confirmPassword: "",
        },
        onSubmit: values => dispatch( savePasswordReset( values ) ),
        validate: values => {
            const errors = {};

            if ( !values.password ) {
                errors.password = "common:validation.required";
            } else if ( values.password.length < 6 ) {
                errors.password = "common:validation.password-length";
            }

            if ( !values.confirmPassword ) {
                errors.confirmPassword = "common:validation.required";
            }

            if ( values.password && values.confirmPassword && values.password !== values.confirmPassword ) {
                errors.confirmPassword = "common:validation.passwords-match";
            }

            return errors;
        }
    });

    useEffect( () => {
        dispatch( fetchPasswordResetStatus( params.id ) );
    }, [ dispatch, params.id ] );

    if ( !status ) {
        return <CircularProgress />;
    }

    if ( status !== 204 ) {
        return (
            <div>
                <p className={ cssUtils.textCenter }>{ t( "login:reset-password.error" ) }</p>

                <Link href="/login" className={ classnames( cssUtils.fullWidth, cssUtils.marginTop ) }>
                    <Button
                        color="primary"
                        variant="contained"
                        className={ classnames( cssUtils.fullWidth, cssUtils.marginTop ) }
                    >
                        { t( "login:reset-password.go-login" ) }
                    </Button>
                </Link>
            </div>
        );
    }

    return (
        <Form formik={ formik }>
            <p className={ cssUtils.textCenter }>{ t( "login:reset-password.title" ) }</p>

            <Field
                name="password"
                type="password"
                label={ t( "login:reset-password.new-password" ) }
                component={ Input }
                required
            />

            <Field
                type="password"
                name="confirmPassword"
                label={ t( "login:reset-password.confirm-password" ) }
                component={ Input }
                required
            />

            <Button
                type="submit"
                className={ classnames( cssUtils.fullWidth, cssUtils.marginTopSmall ) }
                color="primary"
                variant="contained"
            >
                { t( "login:reset-password.reset" ) }
            </Button>

            <hr />

            <Link href="/login" className={ classnames( cssUtils.fullWidth, cssUtils.marginTopSmall ) }>
                <Button
                    color="secondary"
                    className={ cssUtils.fullWidth }
                    variant="contained"
                >
                    { t( "login:reset-password.go-login" ) }
                </Button>
            </Link>
        </Form>
    );
};

export default ResetPassword;