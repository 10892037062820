import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as userSelectors } from "../../../user";
import { saveUserActive } from "./user.actions";

export const createFormName = user => ( "userActive" + user.id );

const UserActive = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const users = useSelector( userSelectors.listAll );

    const [ blocked, setBlocked ] = useState( false );
    const [ showError, setShowError ] = useState( false );

    const toggleBlocked = () => setBlocked( !blocked );
    const closeError = () => setShowError( false );

    const formik = useForm({
        form: createFormName( user ),
        enableReinitialize: true,
        initialValues: user,
        onSubmit: values => {
            const admins = users.filter( u => u.admin && u.active );
            if ( admins.length === 1 && values.admin && values.active ) {
                toggleBlocked();
            } else {
                dispatch( saveUserActive({ ...values, active: !values.active }) );
            }
        }
    });

    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    return (
        <div type="userActive">
            <Snackbar
                open={ showError }
                action={
                    <Button color="secondary" onClick={ closeError }>
                        { "OK" }
                    </Button>
                }
                message={ formik.error }
                onClose={ closeError }
            />

            <FormControlLabel
                label={ t( "config:user.active" ) }
                control={
                    <Switch
                        checked={ user.active }
                        disabled={ formik.submitting }
                        onChange={ formik.submitForm }
                        size="small"
                    />
                }
            />

            <Dialog
                open={ blocked }
                onClose={ toggleBlocked }
            >
                <DialogContent>
                    <DialogContentText>{ t( "config:user.active-at-least" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleBlocked }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserActive;