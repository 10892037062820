import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { fetchFunnels } from "../../../funnel/funnel.actions";
import { actions as sourceActions, selectors as sourceSelectors } from "../../../source";
import { actions as teamActions, selectors as teamSelectors } from "../../../team";
import { actions as userActions, selectors as userSelectors } from "../../../user";
import { cssUtils, flex } from "../../../ui";
import { fetchHoldingFunnels } from "./holding.actions";
import { isLoadingHolding, getHoldingFunnels } from "./holding.selectors";
import AddHoldingFunnel from "./add-holding-funnel.component";
import DeleteHoldingFunnel from "./delete-holding-funnel.component";
import EditHoldingFunnel from "./edit-holding-funnel.component";

const HoldingFunnels = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loadingHolding = useSelector( isLoadingHolding );
    const loadingSources = useSelector( sourceSelectors.isLoadingSources );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const loading = loadingHolding || loadingSources || loadingTeams || loadingUsers;
    const holdingFunnels = useSelector( getHoldingFunnels );
    const sources = useSelector( sourceSelectors.getSources );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAll );

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ selected, setSelected ] = useState( null );
    const [ confirmDelete, setConfirmDelete ] = useState( false );
    const [ openEdition, setOpenEdition ] = useState( false );

    const toggleAdd = () => setOpenAdd( !openAdd );

    const select = funnel => setSelected( funnel.id === selected ? null : funnel.id );

    const toggleDelete = () => setConfirmDelete( !confirmDelete );

    const toggleEdition = () => setOpenEdition( !openEdition );

    const prevLoading = usePrevious( loading );

    useEffect( () => {
        dispatch( fetchHoldingFunnels() );
        dispatch( fetchFunnels() );
        dispatch( sourceActions.fetchSources() );
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( prevLoading && !loading && !!selected ) {
            setSelected( null );
        }
    }, [ prevLoading, loading, selected ] );

    return (
        <>
            <CardHeader
                title={ t( "config:holding.funnels.title" ) }
                subheader={ t( "config:holding.funnels.help" ) }
                avatar={
                    <Avatar>
                        <SettingsIcon/>
                    </Avatar>
                }
            />
            <List>
                <ListItemButton onClick={ toggleAdd }>
                    <ListItemIcon>
                        <AddIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "config:holding.funnels.add" ) } />
                </ListItemButton>
                {
                    isEmpty( holdingFunnels ) && !loading &&
                        <ListItem>
                            <ListItemText
                                primary={ t( "config:holding.funnels.empty" ) }
                                secondary={ t( "config:holding.funnels.empty-help" ) }
                            />
                        </ListItem>
                }
                {
                    !loading && holdingFunnels.map( ( holdingFunnel, index ) => (
                        <Fragment key={ index }>
                            <ListItemButton
                                onClick={ () => select( holdingFunnel.funnel ) }
                                selected={ selected === holdingFunnel.funnel.id }
                            >
                                <ListItemText
                                    primary={ holdingFunnel.funnel.name }
                                />
                                {
                                    selected === holdingFunnel.funnel.id ?
                                        <ExpandLessIcon/> :
                                        <ExpandMoreIcon/>
                                }
                            </ListItemButton>
                            <Collapse
                                className={ cssUtils.marginTopSmall }
                                in={ selected === holdingFunnel.funnel.id }
                            >
                                <Card>
                                    <CardContent>
                                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                            <div className={ flex.fill }>
                                                <Typography variant="subtitle1" align="center">
                                                    {
                                                        sources
                                                            .find( s => holdingFunnel.defaultSourceId === s.id )
                                                            .name
                                                    }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    { t( "config:holding.funnel.default-source.label" ) }
                                                </Typography>
                                            </div>
                                            <Divider orientation="vertical" flexItem/>
                                            <div className={ flex.fill }>
                                                <Typography variant="subtitle1" align="center">
                                                    {
                                                        holdingFunnel.teamId ?
                                                            teams.find( team => team.id === holdingFunnel.teamId )
                                                                .name :
                                                            holdingFunnel.usersId
                                                                .map( userId =>
                                                                    users.find( user => user.id === userId )
                                                                )
                                                                .map( user => user.name )
                                                                .join( ", " )
                                                    }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    {
                                                        holdingFunnel.teamId ?
                                                            t( "ui:users-filter.team" ) :
                                                            t( "ui:users-filter.users" )
                                                    }
                                                </Typography>
                                            </div>
                                            <Divider orientation="vertical" flexItem/>
                                            <div className={ flex.fill }>
                                                <Typography variant="subtitle1" align="center">
                                                    { holdingFunnel.createdBy.name }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    { t( "common:creation-user" ) }
                                                </Typography>
                                                <Divider
                                                    className={ classnames(
                                                        cssUtils.marginTopSmall,
                                                        cssUtils.marginBottomSmall
                                                    )}
                                                />
                                                <Typography variant="subtitle1" align="center">
                                                    { dateFormat( holdingFunnel.createdAt ) }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    { t( "common:creation-date" ) }
                                                </Typography>
                                            </div>
                                            <Divider orientation="vertical" flexItem/>
                                            <div className={ flex.fill }>
                                                <Typography variant="subtitle1" align="center">
                                                    { holdingFunnel.updatedBy.name }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    { t( "common:change-user" ) }
                                                </Typography>
                                                <Divider
                                                    className={ classnames(
                                                        cssUtils.marginTopSmall,
                                                        cssUtils.marginBottomSmall
                                                    )}
                                                />
                                                <Typography variant="subtitle1" align="center">
                                                    { dateFormat( holdingFunnel.updatedAt ) }
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    { t( "common:change-date" ) }
                                                </Typography>
                                            </div>
                                        </div>
                                        <Divider
                                            className={ classnames(
                                                cssUtils.marginTopSmall,
                                                cssUtils.marginBottomSmall
                                            )}
                                        />
                                        <div className={ classnames( flex.container, flex.justifyContentEnd ) }>
                                            <Button
                                                color="secondary"
                                                className={ cssUtils.marginRightSmall }
                                                onClick={ toggleDelete }
                                            >
                                                { t( "common:exclude" ) }
                                            </Button>
                                            <Button color="primary" onClick={ toggleEdition }>
                                                { t( "common:edit" ) }
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Collapse>
                        </Fragment>
                    ))
                }
            </List>

            <AddHoldingFunnel open={ openAdd } onClose={ toggleAdd }/>
            <DeleteHoldingFunnel
                open={ confirmDelete }
                onClose={ toggleDelete }
                funnelId={ selected }
            />
            <EditHoldingFunnel
                open={ openEdition }
                onClose={ toggleEdition }
                holdingFunnel={
                    openEdition && selected ?
                        holdingFunnels.find( holdingFunnel => holdingFunnel.funnel.id === selected ) :
                        {}
                }
            />
        </>
    );
};

export default HoldingFunnels;