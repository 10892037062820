import { useCallback, useMemo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/es-mx";

import useTranslate from "../hooks/use-translate.hook";

const TimePicker = ({
    disabled,
    field,
    form: { getFieldMeta, setFieldValue } = {},
    fullWidth,
    label,
    maxTime,
    minTime,
    ...props
}) => {
    const t = useTranslate();

    const value = useMemo( () => field.value, [ field.value ] );

    const handleChange = useCallback( newValue => {
        if ( newValue ) {
            if ( value ) {
                const previousValue = dayjs( value );
                newValue.set( "year", previousValue.get( "year" ) );
                newValue.set( "month", previousValue.get( "month" ) );
                newValue.set( "date", previousValue.get( "date" ) );
            }
            const isoDate = newValue._isValid ? newValue.toDate().toISOString() : "";
            setFieldValue( field.name, isoDate );
        } else {
            setFieldValue( field.name, newValue );
        }
    }, [ value, field.name, setFieldValue ] );

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }

        return null;
    }, [ field.name, getFieldMeta ] );

    return (
        <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale={ t( "common:full-locale" ).toLowerCase() }>
            <DesktopTimePicker
                ampm={ false }
                disabled={ disabled }
                label={ label }
                maxTime={ maxTime }
                minTime={ minTime }
                onChange={ handleChange }
                value={ value ? new Date( value ) : null }
                renderInput={ params => (
                    <TextField
                        error={ !!validationMsg }
                        fullWidth={ fullWidth }
                        helperText={ validationMsg && t( validationMsg ) }
                        { ... props }
                        { ...params }
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimePicker;