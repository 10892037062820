import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isObject from "lodash/isObject";

import usePrevious from "../hooks/use-previous.hook";
import { fetchSearchCustomers } from "../customer/customer.actions";
import { getSelected, getSearchCustomers, isLoadingSearch } from "../customer/customer.selectors";
import Autocomplete from "./autocomplete.component";

const SelectCustomer = ({ customer, type, ...props }) => {
    const dispatch = useDispatch();

    const selected = useSelector( getSelected );
    const searchCustomers = useSelector( getSearchCustomers );
    const loading = useSelector( isLoadingSearch );

    const prevSearchCustomers = usePrevious( searchCustomers );

    const [ searchTimeout, setSearchTimeout ] = useState();
    const [ customers, setCustomers ] = useState( customer ? [ customer ] : [] );

    const search = query => {
        clearTimeout( searchTimeout );
        if ( query ) {
            setSearchTimeout( setTimeout( () => {
                dispatch( fetchSearchCustomers({ query, type }) );
            }, 300 ) );
        }
    };

    const source = useMemo( () => customers.reduce( ( model, customer ) => ({
        ...model,
        [ customer.id ]: {
            value: customer.id,
            label: customer.company ? ( `${customer.name} (${customer.company.name})` ) : customer.name
        },
    }), {} ), [ customers ] );

    useEffect( () => {
        if ( customer && !customers.find( c => c.id === customer.id ) ) {
            setCustomers( [ ...customers, customer ] );
        }
    }, [ customer, customers ] );
    useEffect( () => {
        if ( selected && !customers.find( c => c.id === selected.id ) ) {
            setCustomers( [ ...customers, selected ] );
        }
    }, [ selected, customers ] );
    useEffect( () => {
        if ( prevSearchCustomers && prevSearchCustomers !== searchCustomers ) {
            setCustomers( searchCustomers );
        }
    }, [ prevSearchCustomers, searchCustomers ] );

    return (
        <Autocomplete
            { ...props }
            getOptionLabel={ option =>
                isObject( option ) ?
                    source[ option.value ].label :
                    option ? source[ option ].label : ""
            }
            multiple={ false }
            source={ source }
            onQueryChange={ search }
            loading={ loading }
        />
    );
};

export default SelectCustomer;