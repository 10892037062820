import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import HttpIcon from "@mui/icons-material/Http";
import LoopIcon from "@mui/icons-material/Loop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import config from "config";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { actions as funnelActions } from "../../../funnel";
import { actions as mailtopActions, selectors as mailtopSelectors } from "../../../mailtop";
import { actions as phaseActions } from "../../../phase";
import { actions as sourceActions } from "../../../source";
import { DialogConfirm } from "../../../ui";
import { executeMailtopList } from "./mailtop.actions";
import AddList from "./add-list.component";
import DeleteList from "./delete-list.component";
import EditList from "./edit-list.component";

const Lists = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const mailtopLists = useSelector( mailtopSelectors.getLists );
    const auth = useSelector( mailtopSelectors.getAuth );
    const loading = useSelector( mailtopSelectors.isLoadingLists );
    const error = useSelector( mailtopSelectors.getListsError );

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ menuListItem, setMenuListItem ] = useState();
    const [ informCopied, setInformCopied ] = useState( false );
    const [ listToDelete, setListToDelete ] = useState( "" );
    const [ listToEdit, setListToEdit ] = useState( null );
    const [ listToExecute, setListToExecute ] = useState( "" );

    const lists = useMemo( () => get( auth, "lists", [] )
        .filter( aList => mailtopLists.find( list => aList.listId === list.id ) )
        .map( aList => ({
            ...mailtopLists.find( list => aList.listId === list.id ),
            ...aList
        })), [ auth, mailtopLists ] );

    const toggleAdd = useCallback( () => {
        setOpenAdd( !openAdd );
    }, [ openAdd ] );
    const toggleCopied = useCallback( () => {
        setInformCopied( !informCopied );
    }, [ informCopied ] );

    const selectListToDelete = useCallback( id => () => {
        setListToDelete( id );
        menuListItem && closeMenuListItem();
    }, [ menuListItem, closeMenuListItem ] );

    const selectListToExecute = useCallback( id => () => {
        setListToExecute( id );
        menuListItem && closeMenuListItem();
    }, [ menuListItem, closeMenuListItem ] );

    const selectListToEdit = useCallback( integrationList => () => {
        setListToEdit( integrationList );
        menuListItem && closeMenuListItem();
    }, [ menuListItem, closeMenuListItem ] );

    const openMenuListItem = useCallback( event => {
        setMenuListItem( event.currentTarget );
    }, [] );

    const closeMenuListItem = useCallback( () => {
        setMenuListItem( null );
    }, [] );

    const executeList = useCallback( () => {
        dispatch( executeMailtopList( listToExecute ) );
        setListToExecute( "" );
    }, [ dispatch, listToExecute ] );

    const copyAutomationWebhook = id => async () => {
        await navigator.clipboard.writeText(
            `${config.get( "client.core" )}/api/v1/mailtop/integration/lists/${id}/webhook`
        );
        toggleCopied();
    };
    useEffect( () => {
        dispatch( mailtopActions.fetchLists() );
        dispatch( funnelActions.fetchFunnels() );
        dispatch( phaseActions.fetchPhases() );
        dispatch( sourceActions.fetchSources() );
    }, [ dispatch ] );

    return (
        <>
            <CardHeader
                subheader={ t( "marketplace:automation.mailtop.lists.title" ) }
            />
            { loading && <LinearProgress/> }
            {
                error &&
                    <CardContent>
                        <Typography color="error">
                            { t( "marketplace:automation.mailtop.lists.error-load" ) }
                        </Typography>
                    </CardContent>
            }
            <List>
                <ListItemButton onClick={ toggleAdd } divider={ !isEmpty( lists ) }>
                    <ListItemIcon>
                        <AddIcon/>
                    </ListItemIcon>
                    <ListItemText primary={ t( "marketplace:automation.mailtop.lists.add" ) }/>
                </ListItemButton>
                {
                    lists.map( ( list, index ) => (
                        <ListItem key={ index } dense>
                            <ListItemText
                                primary={
                                    t( "marketplace:automation.mailtop.lists.single" ) + ": " +
                                    list.nome
                                }
                                secondary={
                                    t( "opportunity:funnel" ) + ": " +
                                    list.funnel.name +
                                    " - " +
                                    ( list.phase ?
                                        t( "opportunity:phase" ) + ": " + list.phase.name + " - "
                                        : ""
                                    ) +
                                    t( "marketplace:automation.mailtop.lists.event.label" ) + ": " +
                                    t( `marketplace:automation.mailtop.lists.event.${list.event}.label` )
                                }
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title={ t( "marketplace:automation.mailtop.lists.copy-webhook" ) }>
                                    <IconButton onClick={ copyAutomationWebhook( list.id ) } size="large">
                                        <HttpIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        list.executedAt ?
                                            list.finishedAt ?
                                                t( "marketplace:automation.mailtop.lists.finished-at", {
                                                    date: dateFormat( list.finishedAt )
                                                }) :
                                                t( "marketplace:automation.mailtop.lists.executing", {
                                                    date: dateFormat( list.executedAt )
                                                }) :
                                            t( "marketplace:automation.mailtop.lists.execute.title" )
                                    }>
                                    <IconButton
                                        onClick={ selectListToExecute( list.id ) }
                                        disableRipple={ !!list.executedAt }
                                        size="large">
                                        {
                                            list.executedAt ?
                                                list.finishedAt ?
                                                    <DoneIcon/> :
                                                    <LoopIcon/> :
                                                <CloudUploadIcon/>
                                        }
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    disabled={ !!list.executedAt && !list.finishedAt }
                                    onClick={ openMenuListItem }
                                    size="large">
                                    <MoreVertIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                            <Menu
                                open={ !!menuListItem }
                                anchorEl={ menuListItem }
                                onClose={ closeMenuListItem }
                            >
                                <MenuItem onClick={ selectListToEdit( list ) }>
                                    <ListItemIcon>
                                        <EditIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "common:edit" ) }/>
                                </MenuItem>
                                <MenuItem onClick={ selectListToDelete( list.id ) }>
                                    <ListItemIcon>
                                        <DeleteIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t( "common:exclude" ) }/>
                                </MenuItem>
                            </Menu>
                        </ListItem>
                    ))
                }
            </List>

            <Snackbar
                open={ informCopied }
                onClose={ toggleCopied }
                message={ t( "common:copied" ) }
                autoHideDuration={ 5000 }
            />

            <AddList open={ openAdd } onClose={ toggleAdd }/>
            <DeleteList id={ listToDelete } onClose={ selectListToDelete( "" ) }/>
            <EditList integrationList={ listToEdit } onClose={ selectListToEdit( null ) }/>

            <DialogConfirm
                open={ !!listToExecute }
                onCancel={ selectListToExecute( "" ) }
                onConfirm={ executeList }
                messages={[
                    t( "marketplace:automation.mailtop.lists.execute.confirm" ),
                    t( "marketplace:automation.mailtop.lists.execute.description" )
                ]}
                title={ t( "marketplace:automation.mailtop.lists.execute.title" ) }
            />
        </>
    );
};

export default Lists;