import { combineReducers } from "redux";

import * as actions from "./mailtop.actions";

export function auth ( state, action ) {
    switch ( action.type ) {
        case actions.REQUEST_TOKEN:
            return Object.assign( {}, state, {
                data: undefined,
                loading: true
            });
        case actions.SET_TOKEN:
            return Object.assign( {}, state, {
                data: action.payload,
                loading: false
            });
        case actions.ERROR_TOKEN:
            return Object.assign( {}, state, {
                data: undefined,
                loading: false
            });
        default:
            return state || {};
    }
}

export function rejections ( state, action ) {
    switch ( action.type ) {
        case actions.REQUEST_REJECTIONS:
            return {
                ...state,
                [ action.meta.index ]: {
                    loading: true
                }};
        case actions.RECEIVE_REJECTIONS:
            return {
                ...state,
                [ action.meta.index ]: {
                    loading: false,
                    data: action.payload
                }};
        case actions.ERROR_REJECTIONS:
            return {
                ...state,
                [ action.meta.index ]: {
                    loading: false,
                    data: undefined
                }};
        default:
            return state || {};
    }
}

function lists ( state, action ) {
    switch ( action.type ) {
        case actions.REQUEST_LISTS:
            return {
                loading: true,
                error: null,
            };
        case actions.RECEIVE_LISTS:
            return {
                loading: false,
                data: action.payload,
            };
        case actions.ERROR_LISTS:
            return {
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return state || {};
    }
}

export const mailtop = combineReducers({ auth, lists, rejections });