import { Field, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as funnelSelectors } from "../../../funnel";
import { selectors as sourceSelectors } from "../../../source";
import { DialogForm, Dropdown, Input } from "../../../ui";
import { createWebhook } from "./webhook.actions";
import { NEW_WEBHOOK_FORM } from "./webhook.constants";

const webhookEvents = [
    "CUSTOMER_CREATED",
    "OPPORTUNITY_CREATED",
    "OPPORTUNITY_LOST",
    "OPPORTUNITY_SOLD"
];

const NewWebhook = ({ open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnels = useSelector( funnelSelectors.listAll );
    const sources = useSelector( sourceSelectors.getSources );

    const formik = useForm({
        form: NEW_WEBHOOK_FORM,
        initialValues: {
            event: null,
            url: null,
            customerType: null,
            funnelId: null,
            sourceId: null,
        },
        onSubmit: values => dispatch( createWebhook( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.event ) {
                errors.event = "common:validation.selection";
            }
            if ( !values.url ) {
                errors.url = "common:validation.required";
            }
            if ( values.event === "CUSTOMER_CREATED" && !values.customerType ) {
                errors.customerType = "common:validation.selection";
            } else if ( values.event && values.event !== "CUSTOMER_CREATED" ) {
                if ( !values.funnelId ) {
                    errors.funnelId = "common:validation.selection";
                }
            }
            return errors;
        }
    });

    const { setFieldValue, values } = formik;

    useEffect( () => {
        if ( values.event === "CUSTOMER_CREATED" ) {
            setFieldValue( "funnelId", null );
            setFieldValue( "sourceId", null );
        } else if ( !!values.event && values.customerType ) {
            setFieldValue( "customerType", null );
        }
    }, [ setFieldValue, values.event, values.customerType ] );

    return (
        <DialogForm
            open={ open }
            onClose={ onClose }
            handleSubmit={ formik.handleSubmit }
            resetForm={ formik.resetForm }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            title={ t( "marketplace:additional-resources.webhooks.new" ) }
        >
            <FormikProvider value={ formik }>
                <Field
                    name="url"
                    label={ t( "marketplace:additional-resources.webhooks.url" ) }
                    component={ Input }
                    required
                />
                <Field
                    name="event"
                    label={ t( "marketplace:additional-resources.webhooks.event.label" ) }
                    component={ Dropdown }
                    source={ webhookEvents.map( event => ({
                        value: event,
                        label: t( `marketplace:additional-resources.webhooks.event.${event}` )
                    }))}
                    required
                />
                {
                    formik.values.event === "CUSTOMER_CREATED" &&
                        <Field
                            name="customerType"
                            label={ t( "customer:type" ) }
                            component={ Dropdown }
                            source={ [ "COMPANY", "PERSON" ].map( customerType => ({
                                value: customerType,
                                label: t( `customer:${customerType.toLowerCase()}.title` )
                            })) }
                            required
                        />
                }
                {
                    formik.values.event && formik.values.event !== "CUSTOMER_CREATED" &&
                        <>
                            <Field
                                name="funnelId"
                                label={ t( "opportunity:funnel" ) }
                                component={ Dropdown }
                                source={ funnels.map( funnel => ({
                                    value: funnel.id,
                                    label: funnel.name
                                }) ) }
                                required
                            />
                            <Field
                                name="sourceId"
                                label={ t( "opportunity:source" ) }
                                component={ Dropdown }
                                source={ sources.map( source => ({
                                    value: source.id,
                                    label: source.name
                                }) ) }
                            />
                        </>
                }
            </FormikProvider>
        </DialogForm>
    );
};

export default NewWebhook;