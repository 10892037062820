import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";

import useTranslate from "../../../hooks/use-translate.hook";
import { fetchPlans } from "../../../plan/plan.actions";
import { isLoadingAll } from "../../../plan/plan.selectors";
import ConfigHeader from "../../config-header.component";
import { fetchSubscriptionBilling } from "./plan.actions";
import { isLoadingSubscriptionBilling } from "./plan.selectors";
import BillingsTable from "./billings-table.component";
import PlanSelector from "./plan-selector.component";

const Plans = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loadingAll = useSelector( isLoadingAll );
    const loadingSubscriptionBilling = useSelector( isLoadingSubscriptionBilling );
    const loading = loadingAll || loadingSubscriptionBilling;

    useEffect( () => {
        dispatch( fetchPlans() );
        dispatch( fetchSubscriptionBilling() );
    }, [ dispatch ] );

    return (
        <>
            <ConfigHeader title={ t( "config:plans.title" ) } />
            <Paper>
                {
                    loading ?
                        <LinearProgress /> :
                        <CardContent>
                            <PlanSelector />
                            <hr/>
                            <BillingsTable />
                        </CardContent>
                }
            </Paper>
        </>
    );
};

export default Plans;