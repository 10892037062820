import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Highcharts from "highcharts";
import classnames from "classnames";
import order from "lodash/orderBy";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const SalesForecastByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState();
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo( () => (
        order( ( results || [] ), [ orderBy ], [ orderType.toLowerCase() ] )
    ), [ results, orderBy, orderType ] );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: "bold",
                    color: (Highcharts.theme && Highcharts.theme.textColor) || "gray"
                },
                formatter: function () {
                    return priceFormat( this.total );
                }
            },
            labels: {
                formatter: function () {
                    return priceFormat( this.value );
                }
            }
        },
        tooltip: {
            formatter: function () {
                return this.series.name + ": " + priceFormat( this.y );
            }
        },
        plotOptions: {
            column: {
                stacking: "normal"
            },
        },
        series: [{
            name: t( "report:sales-forecast-by-user.open" ),
            data: orderedResults.map( item => item.open.price ),
            stack: "price",
            color: "#1565C0"
        }, {
            name: t( "report:sales-forecast-by-user.sold" ),
            data: orderedResults.map( item => item.sold.price ),
            stack: "price",
            color: "#2E7D32"
        }, {
            name: t( "report:sales-forecast-by-user.open-recurrent" ),
            data: orderedResults.map( item => item.open.recurrentPrice ),
            stack: "recurrentPrice",
            color: "#1565C0"
        }, {
            name: t( "report:sales-forecast-by-user.sold-recurrent" ),
            data: orderedResults.map( item => item.sold.recurrentPrice ),
            stack: "recurrentPrice",
            color: "#2E7D32"
        }]
    }), [ t, priceFormat, orderedResults ] );

    const source = useMemo( () => orderedResults.map( item => ({
        ...item,
        open: (
            item.open.total +
                " - " +
                priceFormat( item.open.price ) +
                " / " +
                priceFormat( item.open.recurrentPrice )
        ) + "",
        sold: (
            item.sold.total +
                " - " +
                priceFormat( item.sold.price ) +
                " / " +
                priceFormat( item.sold.recurrentPrice )
        ) + ""
    })), [ priceFormat, orderedResults ] );

    const totalFooter = useMemo( () => ( results || [] ).reduce( ( accumulator, item ) => {
        accumulator.open = {
            total: accumulator.open.total += item.open.total,
            price: accumulator.open.price += item.open.price,
            recurrentPrice: accumulator.open.recurrentPrice += item.open.recurrentPrice,
        };
        accumulator.sold = {
            total: accumulator.sold.total += item.sold.total,
            price: accumulator.sold.price += item.sold.price,
            recurrentPrice: accumulator.sold.recurrentPrice += item.sold.recurrentPrice,
        };
        return accumulator;
    }, {
        name: t( "report:sales-forecast-by-user.total" ),
        open: {
            total: 0,
            price: 0,
            recurrentPrice: 0
        },
        sold: {
            total: 0,
            price: 0,
            recurrentPrice: 0
        },
    }), [ t, results ] );

    const model = {
        name: t( "report:sales-forecast-by-user.user" ),
        open: t( "report:sales-forecast-by-user.open" ),
        sold: t( "report:sales-forecast-by-user.sold" )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.SALES_FORECAST_BY_USER" ) }
                subheader={ t( "report:sales-forecast-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    report={ "SALES_FORECAST_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    onChange={ handleChange }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="salesForecastByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="salesForecastByUserTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    footer={ {
                                        name: totalFooter.name,
                                        open: (
                                            totalFooter.open.total +
                                            " - " +
                                            priceFormat( totalFooter.open.price ) +
                                            " / " +
                                            priceFormat( totalFooter.open.recurrentPrice )
                                        ),
                                        sold: (
                                            totalFooter.sold.total +
                                            " - " +
                                            priceFormat( totalFooter.sold.price ) +
                                            " / " +
                                            priceFormat( totalFooter.sold.recurrentPrice )
                                        )
                                    }}
                                    title={ t( "report:reports.SALES_FORECAST_BY_USER" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default SalesForecastByUser;