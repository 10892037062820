import { useCallback, useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import omit from "lodash/omit";
import metadatas from "libphonenumber-js/metadata.min.json";
import { getCountryCallingCode } from "libphonenumber-js";

import useTranslate from "../hooks/use-translate.hook";
import { FlagIcon } from "./";
import css from "./country-flags.scss";

const CountryFlags = ({
    field,
    required,
    className,
    fullWidth = false,
    ...props
}) => {
    const t = useTranslate();

    const countries = useMemo( () => {
        return Object.keys( metadatas.countries ).map( alpha => ({
            alpha,
            code: getCountryCallingCode( alpha ),
            name: new Intl.DisplayNames( t( "common:full-locale" ), { type: "region" }).of( alpha ),
        }));
    }, [ t ] );

    const renderValue = useCallback( value => (
        <ListItem component="div" dense>
            <ListItemIcon>
                <FlagIcon code={ value.toLowerCase() } />
            </ListItemIcon>
            <ListItemText
                primary={ "(+" + ( countries.find( country => country.alpha === value ) || {} ).code + ")" }
            />
        </ListItem>
    ), [ countries ] );

    return (
        <FormControl
            required={ required }
            className={ className }
            fullWidth={ fullWidth }
        >
            <Select
                renderValue={ renderValue }
                classes={{ select: css.select }}
                { ...omit( props, "i18nLoadedAt", "t" ) }
                { ...field }
            >
                {
                    countries.map( ( country, index ) => (
                        <MenuItem key={ index } value={ country.alpha }>
                            <ListItemIcon>
                                <FlagIcon code={ country.alpha.toLowerCase() } />
                            </ListItemIcon>
                            <ListItemText primary={ "(+" + country.code + ") " + country.name } />
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default CountryFlags;