import { memo, useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDateFormat } from "../../../hooks/use-date-format.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { Table } from "../../../ui/table";
import { fetchBillings } from "./plan.actions";
import { getBillings, isLoadingBillings, getBillingsPages, getBillingsTotal } from "./plan.selectors";
import BillingPayment from "./billing-payment.component";

const BillingsTable = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );

    const billings = useSelector( getBillings );
    const loading = useSelector( isLoadingBillings );
    const totalPages = useSelector( getBillingsPages );
    const totalElements = useSelector( getBillingsTotal );

    const [ page, setPage ] = useState( 0 );

    const priceFormat = usePriceFormat( "BRL" );

    useEffect( () => {
        dispatch( fetchBillings( page === 0 ? 1 : page ) );
    }, [ dispatch, page ]);

    const handlePageChange = useCallback( ({ page }) => {
        setPage( page );
    }, [] );

    const model = useMemo( () => ({
        userLimit: t( "config:billing.hired-users" ),
        type: {
            title: t( "config:billing.type.label" ),
            format: type => t( `config:billing.type.${type}` )
        },
        status: {
            title: t( "config:billing.status.label" ),
            format: status => t( `config:billing.status.${status}` )
        },
        frequency: {
            title: t( "config:billing.frequency.label" ),
            format: type => t( `config:billing.frequency.${type}` )
        },
        dueDate: {
            title: t( "config:billing.due-date" ),
            format: dateFormat
        },
        price: {
            title: t( "config:billing.price" ),
            format: priceFormat
        },
        payment: {
            title: " ",
            size: "small"
        }
    }), [ dateFormat, priceFormat, t ] );

    const source = useMemo( () => ( billings || [] ).map( billing => ({
        ...billing,
        payment: <BillingPayment billing={ billing } />
    })), [ billings ] );

    return (
        <Table
            model={ model }
            source={ source }
            isLoading={ loading }
            onChangePage={ handlePageChange }
            pageSize={ 10 }
            selectedPage={ page }
            totalPages={ totalPages }
            totalElements={ totalElements }
            emptyMessage={ t( "config:billings.empty" ) }
            shadow={ false }
        />
    );
};

export default memo( BillingsTable );