import { useCallback } from "react";
import { Field } from "formik";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { flex, PhoneInput } from "../ui";
import css from "./phone.scss";

const PhoneFieldItem = ({ name, index, disabled, onRemove, showRemove, autoFocus }) => {
    const t = useTranslate();

    const handleRemove = useCallback( () => {
        return onRemove( index );
    }, [ onRemove, index ]);

    return (
        <li className={ classnames( flex.container, flex.alignItemsBaseline ) }>
            <Field
                name={ `${name}[${index}]` }
                label={ t( "form:phone.number" ) }
                className={ flex.fill }
                required
                disabled={ disabled }
                component={ PhoneInput }
                autoFocus= { autoFocus }
            />
            {
                showRemove &&
                    <IconButton
                        className={ css.remove }
                        onClick={ handleRemove }
                        disabled={ disabled }
                        size="large">
                        <CancelIcon/>
                    </IconButton>
            }
        </li>
    );
};

export default PhoneFieldItem;