export default {
    "user-verification": {
        "description":
            "Verifique su dirección de correo electrónico. Para confirmar su dirección de correo " +
            "electrónico, haga clic en el enlace que le enviamos.",
        "resend": "Reenviar e-mail",
        "resend-success": "¡E-mail enviado con éxito!"
    },
    "top-navbar": {
        "items": {
            "logout": "Salir",
            "open-chat": "Soporte vía chat",
            "open-docs": "Documentación",
            "profile": "Mis datos",
            "search": "Investigación",
            "notifications": "Notificaciones"
        },
        "search": "Buscar...",
        "expire-at": "Su período de uso expira el día { date }",
        "hire": "Contratar",
    },
    "select-holding": {
        "title": "Holding - cambiar suscripción",
        "logout": "Volver a la suscripción principal",
        "call-to-action": "Seleccione la suscripción a la que desea acceder.",
        "subscription": "Suscripción",
        "empty-tokens": "No hay suscripciones registradas."
    },
    "notifications": {
        "empty": "No hay notificaciones",
        "title": "Notificaciones",
        "read-all": {
            "button": "Marcar todo como leído",
            "confirmation-message": "Está seguro de que desea marcar todas las notificaciones como leídas?"
        },
        "types": {
            "CUSTOMER_CHANGE": "{ user } ha cambiado el cliente { customer }",
            "CUSTOMER_OPPORTUNITY_CREATION": "{ user } creó la oportunidad { opportunity } para { customer }",
            "CUSTOMER_OPPORTUNITY_STATUS":
                "{ user } se ha cambiado el estado de la oportunidad { opportunity } de { customer }",
            "CUSTOMER_OPPORTUNITY_CHANGE": "{ user } ha cambiado la oportunidad { opportunity } de { customer }",
            "CUSTOMERS_UPDATED": "La actualización del cliente está completa",
            "EMAIL": "Ha recibido un correo lectrónico de { customer }",
            "EMAIL_READ": "{ customer } lea el correo electrónico que envió",
            "EXPORTATION_OPPORTUNITY_CREATED":
                "La exportación de oportunidades se ha completado. Haga clic aquí para descargar",
            "EXPORTATION_COMPANY_CREATED": "Se ha completado la exportación de empresas. Haga clic aquí para descargar",
            "EXPORTATION_PERSON_CREATED": "Se ha completado la exportación de personas. Haga clic aquí para descargar",
            "FACEBOOK_PAGE_EXPIRED":
                "La integración de Facebook ADS con la página { page } ha caducado. Para continuar con la " +
                "integración es necesario activarlo de nuevo",
            "FACEBOOK_PAGE_EXPIRING": "La integración de Facebook ADS con la página { page } caducará en 10 días",
            "IMPORTATION_FINISHED": "La importación { name } se ha completado",
            "MAILTOP_OPPORTUNITY_COMMENT":
                "La oportunidad { opportunity } recibió un comentario de un proceso de automatización de Mailtop",
            "OPPORTUNITY_COMMENT": "{ user } comentó sobre la oportunidad { opportunity }",
            "OPPORTUNITY_COMMENT_MENTION":
                "{ user } te mencionó en un comentario sobre la oportunidad { opportunity }",
            "OPPORTUNITY_CREATION": "Recibiste una oportunidad llamada { opportunity }",
            "OPPORTUNITY_CHANGE_USER": "La oportunidad { opportunity } se ha transferido al usuario { user }",
            "SCHEDULING": "Tienes una programación { title } para { date } a las { hour }",
            "SCHEDULING_ALL_DAY": "Tienes una programación { title } para { date }",
            "SCHEDULING_CREATION":
                "{ user } creó una programación { title } para ti el día { date } a las { hour }",
            "SCHEDULING_REMOVE_USER": "{ user } quitó tu programación { title } ",
            "SCHEDULING_CHANGE_USER": "La programación { title } se transfirió al vendedor { user }",
            "SCHEDULING_ACCOMPLISHED":
                "{ user } marcado como realizada la programación { title } para  { date } a las { hour }",
            "SCHEDULING_NOT_ACCOMPLISHED":
                "{ user } marcado como no realizada la programación { title } para { date } a las { hour }",
            "SCHEDULING_MENTION":
                "{ user } te mecionó en la programación { title } en la oportunidad { opportunity }",
            "SMS_RECEIVED": "Has recibido un SMS de { customer }",
            "OPPORTUNITIES_GENERATED": "La generación de oportunidades está completa",
            "OPPORTUNITIES_UPDATED": "La actualización de la opotunidad se ha completado",
            "PORTAL_EXPIRED": "El acceso a la integración de { portal } ha expirado",
        }
    },
    "modules": {
        "welcome": "Bienvenido",
        "home": "Por hoy",
        "schedule": "Agenda",
        "dashboard": "Dashboard",
        "customers": "Clientes",
        "opportunities": "Oportunidades",
        "importations": "Importaciones",
        "reports": "Informes",
        "leads": "Captura Web",
        "profile": "Mis datos",
        "marketplace": "Marketplace",
        "config": "Configuraciones",
    },
    "blocked-out-of-hours": "El acceso al sistema está bloqueado fuera del horario de oficina",
    "page-not-found": "Página no encontrada",
    "mobile-instructions": "Para usar LeadStation con este dispositivo, es necesario instalar la aplicación",
    "google-play": {
        "link": "https://play.google.com/store/apps/details?id=br.com.syonet.leadstation&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
        "img-src": "https://play.google.com/intl/pt-BR/badges/images/generic/pt_badge_web_generic.png"
    },
    "app-store": {
        "link": "https://itunes.apple.com/br/app/leadstation/id1447887292?mt=8",
        "img-src": "https://linkmaker.itunes.apple.com/pt-br/badge-lrg.svg?releaseDate=2019-03-06&kind=iossoftware&bubble=ios_apps"
    },
    "leadstation-powered-by": {
        "icarros": " iCarros powered by Syonet",
    }
};