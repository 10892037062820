import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirm } from "../../ui";
import { patchOpportunity } from "../opportunity.actions";
import { DELETE_PERSON_FORM } from "../opportunity.constants";

const DeletePerson = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openConfirm, setOpenConfirm ] = useState( false );
    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const { handleSubmit, submitForm, submitting } = useForm({
        form: DELETE_PERSON_FORM,
        enableReinitialize: true,
        initialValues: {
            id: opportunity.id,
            person: null
        },
        onSubmit: values => dispatch( patchOpportunity({ ...values, formikForm: DELETE_PERSON_FORM }) ),
    });

    return (
        <>
            <IconButton onClick={ toggleConfirm } color="inherit" size="large">
                <DeleteIcon/>
            </IconButton>

            <form onSubmit={ handleSubmit }>
                <DialogConfirm
                    open={ openConfirm }
                    disabled={ submitting }
                    message={ t( "customer:person.confirm-delete-from-opportunity" ) }
                    onCancel={ toggleConfirm }
                    onConfirm={ submitForm }
                />
            </form>
        </>
    );
};

export default DeletePerson;