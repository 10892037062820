import { useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, flex } from "../../../ui";
import { deleteProductCategory } from "../../opportunity/product/product.actions";

const ProductCategoriesList = ({ form, remove, submitting }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ isOpenConfirmationDelete, setIsOpenConfirmationDelete ] = useState( false );
    const [ toDelete, setToDelete ] = useState( null );

    const toggleConfirmationDelete = ( index, category ) => {
        if ( !isOpenConfirmationDelete ) {
            if ( !category.id ) {
                remove( index );
                return;
            }
        }
        setToDelete( category );
        setIsOpenConfirmationDelete( !isOpenConfirmationDelete );
    };
    const handleCloseConfirmation = () => {
        setToDelete();
        setIsOpenConfirmationDelete( false );
    };

    const handleDelete = () => {
        dispatch( deleteProductCategory( toDelete ) );
        setIsOpenConfirmationDelete( !isOpenConfirmationDelete );
    };

    return (
        <div>
            <Dialog
                open={ isOpenConfirmationDelete }
                onClose={ handleCloseConfirmation }
            >
                <DialogTitle>{ t( "config:product-categories.delete.title" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t( "config:product-categories.delete.comment" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ handleCloseConfirmation } disabled={ submitting }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete } disabled={ submitting }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                form.values.productCategories.length === 0 &&
                    <Typography color="textSecondary" variant="subtitle1">
                        { t( "config:product-categories.empty" ) }
                    </Typography>
            }
            {
                form.values.productCategories.map( ( category, index ) => (
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) } key={ index }>
                        <Field
                            type="text"
                            label=""
                            placeholder={ t( "config:product-categories.item.placeholder" ) }
                            name={ `productCategories[${index}].name` }
                            className={ classnames( flex.fill ) }
                            component={ Input }
                        />
                        <Tooltip title={ t( "common:exclude" ) }>
                            <IconButton onClick={ () => toggleConfirmationDelete( index, category ) } size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                ))
            }
        </div>
    );
};

export default ProductCategoriesList;