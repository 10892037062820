import { useMemo, useCallback } from "react";
import { MuiTelInput } from "mui-tel-input";
import isEqual from "lodash/isEqual";

import useTranslate from "../hooks/use-translate.hook";

const PhoneInput = ({ field, form: { getFieldMeta, setFieldValue } = {}, autoFocus, ...props }) => {
    const t = useTranslate();

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, getFieldMeta ] );

    const name = useMemo( () => field.name, [ field ] );
    const value = useMemo( () => field.value || {}, [ field ] );
    const onBlur = useMemo( () => field.onBlur, [ field ]);
    const onChange = useCallback( ( number, info ) => {
        const phone = {
            number: number ? number.replace( /\D/g, "" ) : "",
            country: info.countryCode,
        };
        const newPhoneValue = phone.country ? phone : { ...value, number: phone.number };

        if ( setFieldValue && !isEqual( value, newPhoneValue ) ) {
            setFieldValue( name, newPhoneValue );
        }
    }, [ name, value, setFieldValue ] );
    const country = value.country || t( "common:country" );

    return (
        <MuiTelInput
            name={ name }
            error={ !!validationMsg }
            helperText={ validationMsg && t( validationMsg ) }
            value={ value.number ? "+" + value.number : "" }
            onBlur={ onBlur }
            onChange={ onChange }
            defaultCountry={ country }
            langOfCountryName={ t( "common:full-locale" ) }
            forceCallingCode
            { ...props }
            autoFocus={ autoFocus }
        />
    );
};

export default PhoneInput;
