export const securityTypes = [ "NONE", "TLS", "SSL", "STARTTLS" ];

export const preDefaultSettings = {
    GMAIL: {
        host: "smtp.gmail.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    OUTLOOK: {
        host: "smtp.live.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    OFFICE: {
        host: "smtp.office365.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    MS_EXCHANGE: {
        host: "smtp.live.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    AMAZON: {
        host: "",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    ZOHO: {
        host: "smtp.zoho.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    ICLOUD: {
        host: "smtp.mail.me.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    AOL: {
        host: "smtp.aol.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    YAHOO: {
        host: "smtp.yahoo.com",
        port: 587,
        securityType: "TLS",
        auth: true
    },
    KINGHOST: {
        host: "smtp.kinghost.net",
        port: 587,
        securityType: "NONE",
        auth: true
    },
};