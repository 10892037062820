import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors, validateFields } from "../../form";
import { saveCompany } from "../customer.actions";
import { SAVE_COMPANY_FORM } from "../customer.constants";
import CustomerWizard from "../customer-wizard.component";
import { Dialog, cssUtils } from "../../ui";
import css from "./company.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const validate = ( values, props ) => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    const company = get( values, "company", {} );
    if ( company.new ) {
        errors.company = validateFields( company, props );
    } else if ( !company.id ) {
        errors.company = {
            id: "common:validation.selection"
        };
    }

    if ( errors.company && isEmpty( errors.company ) ) {
        delete errors.company;
    }

    return errors;
};

const AddCompany = ({ customer }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "COMPANY" ) );

    const initialValues = useMemo( () => ({
        id: customer.id,
        company: {
            fields: fieldsToInitialValues( fields )
        }
    }), [ customer, fields ] );

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const formik = useForm({
        form: SAVE_COMPANY_FORM,
        initialValues,
        onSubmit: values => dispatch( saveCompany( values ) ),
        validate: values => validate( values, { t, fields } ),
    });

    const prevSubmitting = usePrevious( formik.submitting );

    const handlResetForm = () => formik.setFieldValue( "company.fields", initialValues.company.fields );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            setOpen( false );
        }
    }, [ prevSubmitting, formik.submitting ] );

    return (
        <>
            <IconButton color="inherit" onClick={ toggleOpen } size="large">
                <AddIcon/>
            </IconButton>

            <Dialog open={ open } fullWidth maxWidth="sm">
                <DialogTitle className={ css.titleAddCompany }>
                    { t( "customer:company.add-title" ) }
                </DialogTitle>
                <DialogContent className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRight0 )}>
                    <Form formik={ formik }>
                        <CustomerWizard
                            name="company"
                            type="COMPANY"
                            resetForm={ handlResetForm }
                            setFieldValue={ formik.setFieldValue }
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={ formik.submitting } onClick={ toggleOpen }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddCompany;