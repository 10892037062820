import { Field, FieldArray, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";

import useTranslate from "../hooks/use-translate.hook";
import { DateTimePicker, DialogForm, Dropdown, cssUtils, flex } from "../ui";
import OpportunityLostForm from "./opportunity-lost-form.component";
import { fetchProposals, patchOpportunity } from "./opportunity.actions";
import { OPPORTUNITY_STATUS_FORM } from "./opportunity.constants";
import { getOpenProposals, isLoadingProposals } from "./opportunity.selectors";

const OpportunityStatus = ({ opportunity, open, onClose, onSave, refreshStatus, status }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const proposals = useSelector( getOpenProposals );
    const loading = useSelector( isLoadingProposals );

    const initialValues = useMemo( () => ({
        id: opportunity.id,
        phase: opportunity.phase,
        proposals: status === "SOLD" ?
            ( proposals || [] ).map( proposal => ({
                ...proposal,
                status: "ACCEPTED",
            })) :
            [],
        status,
        lostAt: status === "LOST" ? new Date().toISOString() : null,
        soldAt: status === "SOLD" ? new Date().toISOString() : null,
        lossReason: status === "LOST" ? { id: "" } : null,
        lossReasonComplement: ""
    }), [ opportunity, proposals, status ] );

    const formik = useForm({
        form: OPPORTUNITY_STATUS_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            if ( onSave ) {
                onSave( values );
            } else {
                dispatch( patchOpportunity({ ...values, formikForm: OPPORTUNITY_STATUS_FORM }) );
            }
        },
        onSubmitSuccess: refreshStatus,
        validate: values => {
            const errors = {};
            if ( values.status === "LOST" ) {
                if ( !values.lostAt ) {
                    errors.lostAt = "common:validation.required";
                }
                if ( !get( values, "lossReason.id" ) ) {
                    set( errors, "lossReason.id", "common:validation.required" );
                }
            }
            if ( values.status === "SOLD" ) {
                if ( !values.soldAt ) {
                    errors.soldAt = "common:validation.required";
                }
            }

            return errors;
        }
    });


    useEffect( () => {
        if ( status === "SOLD" ) {
            dispatch( fetchProposals( opportunity.id ) );
        }
    }, [ dispatch, opportunity.id, status ] );

    const showProposals = !loading && !isEmpty( proposals );
    const statusSold = formik.values.status === "SOLD";

    return (
        <DialogForm
            open={ open }
            onClose={ onClose }
            handleSubmit={ formik.handleSubmit }
            resetForm={ formik.resetForm }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            messages={{ submit: t( "common:confirm" ) }}
            size={ "xs" }
            title={
                formik.values.status === "LOST" ?
                    t( "opportunity:sale-lost" ) :
                    formik.values.status === "SOLD" ? t( "opportunity:sale-sold" ) : t( "opportunity:reopen" )
            }
        >
            <FormikProvider value={ formik }>
                { loading && <CircularProgress/> }
                {
                    !loading && statusSold &&
                        <>
                            <Field
                                name="soldAt"
                                label={ t( "opportunity:sold-at" ) }
                                component={ DateTimePicker }
                                required
                            />

                            {
                                showProposals &&
                                    <FieldArray
                                        name="proposals"
                                        render={ () => (
                                            <div className={ cssUtils.marginTop }>
                                                <DialogContentText>
                                                    { t( "opportunity:confirm-status-proposals" ) }
                                                </DialogContentText>
                                                {
                                                    formik.values.proposals.map( ( proposal, index ) => (
                                                        <div
                                                            key={ proposal.id }
                                                            className={ classnames(
                                                                flex.fill,
                                                                flex.container,
                                                                flex.alignItemsCenter
                                                            )}
                                                        >
                                                            <Typography
                                                                className={ flex.fill }
                                                                variant="subtitle1"
                                                            >
                                                                { proposal.name }
                                                            </Typography>
                                                            <ArrowForwardIcon
                                                                className={ cssUtils.marginHorizontalSmall }
                                                            />
                                                            <Field
                                                                className={ flex.fill }
                                                                name={ `proposals[${index}].status` }
                                                                label=""
                                                                source={[ "ACCEPTED", "DECLINED" ].map( value => ({
                                                                    value,
                                                                    label: t( `opportunity:proposal.status.${value}` )
                                                                }))}
                                                                component={ Dropdown }
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    />
                            }
                        </>
                }
                {
                    formik.values.status === "OPEN" &&
                        <DialogContentText>{ t("opportunity:reopen-confirm" ) }</DialogContentText>
                }
                { formik.values.status === "LOST" && <OpportunityLostForm/> }
            </FormikProvider>
        </DialogForm>
    );
};

export default OpportunityStatus;