import { useEffect, useRef, useState } from "react";
import { useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog } from "../ui";
import { saveAvatar } from "./customer.actions";
import { getSelected } from "./customer.selectors";
import { CUSTOMER_AVATAR_FORM } from "./customer.constants";

const CustomerAvatar = () => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const customer = useSelector( getSelected );

    const fileAvatar = useRef();

    const [ openMenu, setOpenMenu ] = useState( false );
    const [ buttonMenu, setButtonMenu ] = useState( null );
    const [ confirmRemove, setConfirmRemove ] = useState( false );
    const [ showSubmitError, setShowSubmitError ] = useState( false );

    const { error, setFieldValue, submitForm } = useForm({
        form: CUSTOMER_AVATAR_FORM,
        enableReinitialize: true,
        initialValues: {
            id: customer?.id,
            avatar: null,
        },
        onSubmit: values => dispatch( saveAvatar( values ) ),
    });

    const showMenu = event => {
        setOpenMenu( true );
        setButtonMenu( event.currentTarget );
    };

    const closeMenu = () => setOpenMenu( false );

    const openFileSearch = () => {
        closeMenu();
        fileAvatar.current.click();
    };

    const toggleConfirmRemove = () => setConfirmRemove( !confirmRemove );

    const removeAvatar = () => {
        closeMenu();
        setFieldValue( "avatar", null );
        submitForm();
        toggleConfirmRemove();
    };

    const selectAvatar = event => {
        const file = event.target.files[ 0 ];

        setFieldValue( "avatar", file );
        submitForm();
    };

    const toggleSubmitError = () => setShowSubmitError( !showSubmitError );

    useEffect( () => {
        error && setShowSubmitError( true );
    }, [ error ] );

    return (
        <div>
            <Snackbar
                open={ showSubmitError }
                action={
                    <Button color="secondary" onClick={ toggleSubmitError }>
                        { t( "common:ok" ) }
                    </Button>
                }
                message={ error?.error }
                autoHideDuration={ 5000 }
                onClose={ toggleSubmitError }
            />

            <form role="form" encType="multipart/form-data">
                <input
                    type="file"
                    name="avatar"
                    style={{ display: "none" }}
                    accept="image/*"
                    ref={ fileAvatar }
                    onChange={ selectAvatar }
                />
            </form>

            <IconButton onClick={ showMenu } color="inherit" size="large">
                <MoreVertIcon/>
            </IconButton>
            <Menu
                open={ openMenu }
                onClose={ closeMenu }
                anchorEl={ buttonMenu }
            >
                <MenuItem onClick={ openFileSearch }>
                    <ListItemIcon><CloudUploadIcon/></ListItemIcon>
                    <ListItemText primary={ t( "customer:head.change-avatar" ) }/>
                </MenuItem>
                {
                    customer.avatar &&
                        <MenuItem onClick={ toggleConfirmRemove }>
                            <ListItemIcon><DeleteIcon/></ListItemIcon>
                            <ListItemText primary={ t( "customer:head.remove-avatar" ) }/>
                        </MenuItem>
                }
            </Menu>

            <Dialog open={ confirmRemove }>
                <DialogContent>
                    <DialogContentText>{ t( "customer:head.confirm-remove-avatar" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmRemove }>{ t( "common:no" ) }</Button>
                    <Button color="primary" onClick={ removeAvatar }>{ t( "common:yes" ) }</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomerAvatar;