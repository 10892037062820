import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../../../source";
import { cssUtils, Dropdown, Input, SelectFunnel, UsersFilter } from "../../../ui";
import { listAll as getDistributions } from "../../../config/advanced/distribution/distribution.selectors";

export const validateForm = ( values, props ) => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( values.type === "WEBMOTORS" ) {
        if ( !get( values, "credentials.ein" ) ) {
            set( errors, "credentials.ein", "common:validation.required" );
        } else if ( !props.isEinValid( values.credentials.ein ) ) {
            set( errors, "credentials.ein", "common:validation.ein" );
        }

        if ( !get( values, "credentials.username" ) ) {
            set( errors, "credentials.username", "common:validation.required" );
        }

        if ( !get( values, "credentials.password" ) ) {
            set( errors, "credentials.password", "common:validation.required" );
        }
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( !get( values, "funnel.id" ) ) {
        set( errors, "funnel.id", "common:validation.selection" );
    }

    if ( !get( values, "source.id" ) ) {
        set( errors, "source.id", "common:validation.selection" );
    }

    if ( !values.distribution?.id && !values.teamId && isEmpty( values.usersId ) ) {
        errors.usersId = "marketplace:automation.empty-users";
    }

    return errors;
};

const PortalForm = ({ form }) => {
    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );
    const distributions = useSelector( getDistributions );

    const handleChange = name => value => form.setFieldValue( name, value );

    const errorUsersId = form.errors?.usersId && form.touched?.usersId;

    const { setFieldValue, values } = form;

    useEffect( () => {
        if ( values.distribution?.id ) {
            setFieldValue( "teamId", null );
            setFieldValue( "usersId", [] );
        }
    }, [ setFieldValue, values.distribution ] );

    return (
        <>
            <Field
                name="name"
                label={ t( "marketplace:automation.name.label" ) }
                component={ Input }
                required
            />
            <Typography color="textSecondary" variant="caption" className={ cssUtils.marginBottomSmall }>
                { t( "marketplace:automation.name.help" ) }
            </Typography>

            <Field
                name="funnel.id"
                component={ SelectFunnel }
                required
            />

            <Field
                name="source.id"
                label={ t( "marketplace:automation.source" ) }
                component={ Dropdown }
                source={ sources?.map( source => ( { value: source.id, label: source.name } ) ) }
                required
            />

            <Field
                name="distribution.id"
                label={ t( "marketplace:automation.portal-distribution" ) }
                component={ Dropdown }
                source={
                    distributions?.map( distribution => ( { value: distribution.id, label: distribution.name } ) )
                }
                emptyValue
                error={ errorUsersId }
            />

            <UsersFilter
                ComponentRoot={ Fragment }
                error={ errorUsersId }
                input={{
                    team: {
                        value: form.values.teamId,
                        onChange: handleChange( "teamId" )
                    },
                    users: {
                        value: form.values.usersId,
                        onChange: handleChange( "usersId" )
                    }
                }}
                fullWidth
            />
            {
                errorUsersId &&
                    <Typography color="error" variant="caption">
                        { t( form.errors.usersId ) }
                    </Typography>
            }

        </>
    );
};

export default PortalForm;