import { createRequestReducer } from "redux-request-state";
import { combineReducers } from "redux";

import * as actions from "./template.actions";

const all = createRequestReducer({
    begin: actions.REQUEST_TEMPLATES,
    success: actions.RECEIVE_TEMPLATES,
    failure: actions.ERROR_TEMPLATES
});

const images = createRequestReducer({
    begin: actions.REQUEST_IMAGES,
    success: actions.RECEIVE_IMAGES,
    failure: actions.ERROR_IMAGES
});

export const templates = combineReducers({ all, images });