import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../source";
import { selectors as userSelectors } from "../user";
import { Dropdown, DropdownMultiple, SelectFunnel, Switch, cssUtils, flex } from "../ui";
import css from "./importation.scss";

const OpportunityConfig = ({ createOpportunity, setFieldValue }) => {
    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );
    const users = useSelector( userSelectors.listAll );

    const prevCreateOpportunity = usePrevious( createOpportunity );

    useEffect( () => {
        if ( prevCreateOpportunity && !createOpportunity ) {
            setFieldValue( "funnel", null );
            setFieldValue( "sourceId", null );
            setFieldValue( "usersId", [] );
            setFieldValue( "mappingOpportunity", [] );
        }
    }, [ prevCreateOpportunity, createOpportunity, setFieldValue ] );

    return (
        <>
            <hr/>
            <Field
                name="createOpportunity"
                type="checkbox"
                label="Criar oportunidade"
                component={ Switch }
            />

            {
                createOpportunity &&
                    <>
                        <Field
                            name="funnel.id"
                            component={ SelectFunnel }
                            required
                        />

                        <Field
                            name="sourceId"
                            label={ t( "opportunity:source" ) }
                            component={ Dropdown }
                            className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                            source={ sources?.map( source => ({ value: source.id, label: source.name }) ) }
                            required
                        />

                        <Field
                            name="usersId"
                            component={ DropdownMultiple }
                            label={ t( "importation:importation.users" ) }
                            theme={{
                                input: cssUtils.paddingBottom0,
                                menu: css.usersMenu
                            }}
                            source={ users?.map( user => ({
                                label: user.name,
                                value: user.id
                            })) }
                        />
                        <Typography variant="body2">
                            { t( "importation:importation.users-help" ) }
                        </Typography>
                    </>
            }
            <hr/>
        </>
    );
};

export default OpportunityConfig;