import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PrintIcon from "@mui/icons-material/Print";
import VisibiltyIcon from "@mui/icons-material/Visibility";
import has from "lodash/has";

import { useDateFormat, useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { downloadFile } from "../../../util/file.utils";
import { formatBankSlip } from "./plan.utils";
import css from "./billing-payment.scss";

const BillingPayment = ({ billing }) => {
    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const dateTimeFormat = useDateTimeFormat( t );

    const [ isOpen, setIsOpen ] = useState( false );
    const [ informCopied, setInformCopied ] = useState( false );
    const priceFormat = usePriceFormat( "BRL" );
    const handleOpen = () => setIsOpen( true );
    const handleClose = () => setIsOpen( false );

    const copyBankSlip = () => navigator.clipboard.writeText( billing.payment.digitableLine );
    const openBankSlip = () => window.open( billing.payment.bankSlipUrl, "_blank" );
    const toggleInformeCopied = () => setInformCopied( !informCopied );
    const copyToTransfer = text => async () => {
        await navigator.clipboard.writeText( text );
        toggleInformeCopied();
    };
    const openInvoice = ( fileId, filename ) => () => downloadFile(
        `/billings/invoices/${encodeURIComponent(fileId)}`,
        filename
    );

    return <>
        <Tooltip title={ t( "config:billing.payment-details" ) }>
            <IconButton onClick={ handleOpen } size="large">
                <VisibiltyIcon />
            </IconButton>
        </Tooltip>

        <Snackbar
            open={ informCopied }
            onClose={ toggleInformeCopied }
            message={ t( "common:copied") }
            autoHideDuration={ 5000 }
        />

        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                { t( "config:billing.payment-details" ) }
            </DialogTitle>
            <DialogContent>
                <List disablePadding>
                    {
                        billing.individual &&
                            <ListItem disableGutters>
                                <ListItemText
                                    primary={ t( "config:billing.individual" ) }
                                />
                            </ListItem>
                    }
                    {
                        billing.type === "BANK_SLIP" &&
                            <>
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={ t( "config:billing.type.label" ) }
                                        secondary={ t( "config:billing.type.BANK_SLIP" ) }
                                    />
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={ t( "config:billing.bank-slip-line" ) }
                                        secondary={ formatBankSlip( billing.payment.digitableLine ) }
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title={ t( "common:copy" ) }>
                                            <IconButton onClick={ copyBankSlip } size="large">
                                                <FileCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={ t( "config:billing.print-bank-slip" ) }>
                                            <IconButton onClick={ openBankSlip } size="large">
                                                <PrintIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={ t( "config:billing.due-date" ) }
                                        secondary={ dateFormat( billing.dueDate ) }
                                    />
                                </ListItem>
                            </>
                    }
                    {
                        billing.type === "CREDIT_CARD" &&
                            <ListItem disableGutters>
                                <ListItemText
                                    primary={ t( "config:billing.type.CREDIT_CARD" ) }
                                    secondary={
                                        `${t( "config:billing.credit-card.description",
                                            { number: billing.payment.creditCard.number }
                                        )} -
                                        ${billing.payment.creditCard.name} -
                                        ${billing.payment.creditCard.validity}`
                                    }
                                />
                            </ListItem>
                    }
                    {
                        ( billing.type === "PIX" && !billing.paidAt ) &&
                            <Fragment>
                                <div className={ css.qrCodeImage }>
                                    <img src={ billing.payment.qrCode }></img>
                                </div>

                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={ t( "config:billing.pix-copy" ) }
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title={ t( "common:copy" ) }>
                                            <IconButton onClick={ copyToTransfer( billing.payment.key ) } size="large">
                                                <FileCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Fragment>
                    }
                    {
                        billing.invoice?.invoiceId &&
                            <>
                                <ListItemButton
                                    disableGutters
                                    onClick={ openInvoice(
                                        billing.invoice.invoicePdfId,
                                        billing.invoice.invoicePdfName
                                    ) }
                                >
                                    <ListItemText primary={ t( "config:billing.invoice.download-pdf" ) } />
                                </ListItemButton>
                                <ListItemButton
                                    disableGutters
                                    onClick={ openInvoice(
                                        billing.invoice.invoiceXmlId,
                                        billing.invoice.invoiceXmlName
                                    ) }
                                >
                                    <ListItemText primary={ t( "config:billing.invoice.download-xml" ) } />
                                </ListItemButton>
                            </>
                    }
                    {
                        billing.paidAt &&
                            <ListItem disableGutters>
                                <ListItemText
                                    primary={ t( "config:billing.paid-at" ) }
                                    secondary={ dateTimeFormat( billing.paidAt ) }
                                />
                            </ListItem>
                    }
                    {
                        has( billing, "payment.paymentDate" ) &&
                            <ListItem disableGutters>
                                <ListItemText
                                    primary={ t( "config:billing.payment-date" ) }
                                    secondary={ dateFormat( billing.payment.paymentDate ) }
                                />
                            </ListItem>
                    }
                    {
                        has( billing, "payment.amountPaid" ) &&
                            <ListItem disableGutters>
                                <ListItemText
                                    primary={ t( "config:billing.amount-paid" ) }
                                    secondary={ priceFormat( billing.payment.amountPaid ) }
                                />
                            </ListItem>
                    }

                    <ListItem disableGutters>
                        <ListItemText
                            primary={ t( "common:creation-date" ) }
                            secondary={ dateTimeFormat( billing.createdAt ) }
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleClose }>
                    { t( "common:close" ) }
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default BillingPayment;