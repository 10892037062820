import { useDispatch } from "react-redux";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { saveNewApiKey } from "./api-key.actions";
import { NEW_API_KEY_FORM } from "./api-key.constants";
import ApiKeyForm, { validate } from "./api-key-form.component";

const NewApiKey = ({ show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_API_KEY_FORM,
        initialValues: {
            name: "",
            funnel: { id: null },
            source: { id: null },
            team: null,
            usersId: [],
        },
        onSubmit: values => dispatch( saveNewApiKey( values ) ),
        onSubmitSuccess: onClose,
        validate,
    });

    return (
        <Dialog open={ show } fullWidth>
            <DialogTitle>
                { t( "marketplace:integrations.new-api-key.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <ApiKeyForm form={ formik } />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewApiKey;