import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../ui";
import { saveProposal } from "../opportunity.actions";
import { createProposalStatusFormName } from "../opportunity.utils";

const ProposalStatus = ({ icon, onClose, proposal, status }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirm, setConfirm ] = useState( false );
    const open = () => setConfirm( true );
    const close = () => {
        onClose();
        setConfirm( false );
    };

    const formName = createProposalStatusFormName( proposal.id, status );

    const formik = useForm({
        form: formName,
        initialValues: {
            ...proposal,
            status,
        },
        onSubmit: values => dispatch( saveProposal( formName )( values ) ),
    });

    return (
        <>
            <MenuItem onClick={ open }>
                <ListItemIcon>{ icon }</ListItemIcon>
                <ListItemText primary={ t( `opportunity:proposal.change-status.${status}` ) }/>
            </MenuItem>
            <DialogConfirmForm
                open={ confirm }
                onClose={ close }
                handleSubmit={ formik.handleSubmit }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
                message={ t( "opportunity:proposal.change-status.confirm" ) }
                title={ t( `opportunity:proposal.change-status.${status}` ) }
            />
        </>
    );
};

export default ProposalStatus;