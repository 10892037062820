import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { useDateFormat } from "../hooks/use-date-format.hook";
import usePrevious from "../hooks/use-previous.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { fetchOpportunities } from "./opportunity.actions";
import { listAll, isLoadingAll, getTotalPagesAll, getTotalElementsAll } from "./opportunity.selectors";
import { getTemperatureProps } from "./opportunity.utils";
import { Dialog, cssUtils, Dropdown, flex } from "../ui";
import { Table } from "../ui/table";
import css from "./opportunities-dialog.scss";
import { selectors as funnelSelectors } from "../funnel";

const OpportunitiesDialog = ({
    onClose,
    open,
    params,
    title,
    funnelsId,
    showExportButton,
    onActionClick,
    onChangeFunnel
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const priceFormat = usePriceFormat();

    const funnels = useSelector( funnelSelectors.listAll );
    const opportunities = useSelector( listAll );
    const loading = useSelector( isLoadingAll );
    const totalPages = useSelector( getTotalPagesAll );
    const totalElements = useSelector( getTotalElementsAll );

    const [ funnelId, setFunnelId ] = useState();

    const loadOpportunities = useCallback( params => {
        dispatch( fetchOpportunities({ ...params, ignoreUserPreferences: true }) );
    }, [ dispatch ] );

    const handleChangeFunnelId = useCallback( event => {
        setFunnelId( event.target.value );
        loadOpportunities({...params, funnelId: event.target.value });
    }, [ loadOpportunities, params ] );

    const handleChangePage = useCallback( ({ page, orderBy, orderType }) => {
        loadOpportunities({ ...params, page, orderBy, orderType, funnelId });
    }, [ loadOpportunities, params, funnelId ] );

    const prevOpen = usePrevious( open );

    useEffect( () => {
        if ( open && !prevOpen ) {
            const funnelId = ( funnelsId || [] )[ 0 ];
            setFunnelId( funnelId );
            loadOpportunities({ ...params, funnelId });

            if ( onChangeFunnel ) {
                onChangeFunnel( funnelId );
            }
        }
    }, [ loadOpportunities, funnelsId, onChangeFunnel, open, params, prevOpen ] );

    const rowLink = index => {
        try {
            return `/opportunities/${opportunities[ index ].id}`;
        } catch ( e ) {
            return "/opportunities";
        }
    };

    return (
        <Dialog
            open={ open }
            onClose={ onClose }
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                {
                    <Fragment>
                        <div className={ classnames( css.containerFunnel, cssUtils.marginBottom ) }>
                            <Dropdown
                                label={ t( "opportunity:funnel" ) }
                                source={
                                    ( funnels || [] )
                                        .filter( funnel =>
                                            isEmpty( funnelsId ) || ( funnelsId || [] ).includes( funnel.id )
                                        )
                                        .map( funnel => ( { label: funnel.name, value: funnel.id } ) )
                                }
                                className={
                                    classnames(
                                        showExportButton ?
                                            ( flex.item80, css.dropdownExportButton ) :
                                            flex.fill
                                    )
                                }
                                onChange={ handleChangeFunnelId }
                                value={ funnelId }
                                variant="outlined"
                            />
                            {
                                showExportButton &&
                                    <div className={ classnames( flex.item20, flex.alignItemsCenter ) }>
                                        <Button
                                            onClick={ onActionClick }
                                            startIcon={ <CloudDownloadIcon/> }
                                            className={ css.exportationButton }
                                            fullWidth
                                            variant="outlined"
                                        >
                                            { t( "opportunity:export-file" ) }
                                        </Button>
                                    </div>
                            }
                        </div>

                        <Table
                            source={
                                ( opportunities || [] ).map( opportunity => ({
                                    title: opportunity.title,
                                    company: get( opportunity, "company.name" ),
                                    person: get( opportunity, "person.name" ),
                                    phase: opportunity.phase.name,
                                    temperature: (
                                        opportunity.temperature ?
                                            getTemperatureProps( opportunity.temperature ) :
                                            null
                                    ),
                                    price: priceFormat( opportunity.price ),
                                    recurrentPrice: priceFormat( opportunity.recurrentPrice ),
                                    closingAt: dateFormat( opportunity.closingAt ),
                                    user: opportunity.user.name,
                                    status: (
                                        <Tooltip
                                            title={ t( `opportunity:status.${opportunity.status}` ) }
                                        >
                                            <AttachMoneyIcon
                                                className={ classnames(
                                                    opportunity.status === "SOLD" ?
                                                        css.iconOpportunitySold :
                                                        opportunity.status === "LOST" ?
                                                            css.iconOpportunityLost :
                                                            null
                                                )}
                                            />
                                        </Tooltip>
                                    )
                                }))
                            }
                            model={{
                                title: t( "opportunity:title" ),
                                company: t( "opportunity:company" ),
                                person: t( "opportunity:person" ),
                                phase: t( "opportunity:phase" ),
                                price: t( "opportunity:price.label" ),
                                recurrentPrice: t( "opportunity:recurrent-price" ),
                                closingAt: t( "opportunity:closing-at" ),
                                user: {
                                    title: t( "opportunity:user" ),
                                    value: "userId",
                                },
                                temperature: {
                                    title: " ",
                                    size: "small",
                                    disableOrder: true
                                },
                                status: {
                                    title: " ",
                                    size: "small",
                                    disableOrder: true
                                }
                            }}
                            className={ css.containerOpportunities }
                            emptyMessage={ t( "opportunity:opportunity-list.empty" ) }
                            rowLink={ rowLink }
                            totalPages={ totalPages }
                            totalElements={ totalElements }
                            pageSize={ 10 }
                            onChangePage={ handleChangePage }
                            allowOrder
                            isLoading={ loading }
                        />
                    </Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose }>
                    { t( "common:close" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OpportunitiesDialog;