import { useCallback, useEffect, useMemo, useState } from "react";
import { CirclePicker } from "react-color";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { cssUtils, flex, Dialog } from "./";
import css from "./color-picker.scss";

const ColorPicker = ({ className, label, defaultColor, field, form: { setFieldValue } }) => {
    const [ showDialog, setShowDialog ] = useState( false );
    const toggleDialog = () => setShowDialog( !showDialog );

    const value = useMemo( () => field.value, [ field ] );
    const handleChange = useCallback( color => {
        setFieldValue( field.name, color.hex );
    }, [ setFieldValue, field.name ] );

    useEffect( () => {
        if ( !value ) {
            handleChange({ hex: defaultColor });
        }
    }, [ value, handleChange, defaultColor ] );

    return (
        <>
            <Dialog
                open={ showDialog }
                onClose={ toggleDialog }
            >
                <DialogContent>
                    <CirclePicker
                        className={ css.picker }
                        color={ value }
                        onChange={ handleChange }
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleDialog }>
                        { "OK" }
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={ classnames( className, flex.container, flex.alignItemsCenter )}>
                <div
                    className={ css.color }
                    style={{ backgroundColor: value }}
                    onClick={ toggleDialog }
                />
                { label && <Typography className={ cssUtils.marginLeftSmall }>{ label }</Typography> }
            </div>
        </>
    );
};

export default ColorPicker;