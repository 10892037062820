import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input } from "../../../ui";

const HoldingToken = ({ holding }) => {
    const t = useTranslate();

    const [ tokenCopied, setTokenCopied ] = useState( false );

    const copyToken = async () => {
        await navigator.clipboard.writeText( holding.token );
        setTokenCopied( true );
    };
    const closeAlertCopied = () => setTokenCopied( false );

    return (
        <>
            <Input
                name="token"
                label={ t( "config:holding.token" ) }
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <Tooltip title={ t( "common:copy" ) }>
                            <IconButton onClick={ copyToken } size="large">
                                <FileCopyIcon/>
                            </IconButton>
                        </Tooltip>
                    )
                }}
                value={ holding.token }
            />

            <Snackbar
                open={ tokenCopied }
                autoHideDuration={ 2000 }
                onClose={ closeAlertCopied }
                message={ t( "common:copied" ) }
            />
        </>
    );
};

export default HoldingToken;