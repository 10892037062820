import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as goalActions } from "../../../goal";
import { DELETE_GOAL_FORM } from "./goal.constants";
import * as actions from "./goal.actions";

export function *watchConfigGoal () {
    yield all([
        takeEvery( actions.SAVE_GOAL, saveGoal ),
        takeEvery( actions.DELETE_GOAL, deleteGoal )
    ]);
}

export function *saveGoal ({ meta: { form }, payload }) {
    yield put( startSubmit( form ) );

    try {
        if ( payload.id ) {
            yield call( apiv1.patch, `/goals/${payload.id}`, payload );
        } else {
            yield call( apiv1.post, "/goals", payload );
        }
        yield put( goalActions.fetchGoals() );
        yield put( stopSubmit( form ) );
        yield put( reset( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteGoal ({ meta: { id } }) {
    yield put( startSubmit( DELETE_GOAL_FORM ) );
    try {
        yield call( apiv1.delete, `/goals/${id}` );
        yield put( goalActions.fetchGoals() );
        yield put( stopSubmit( DELETE_GOAL_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_GOAL_FORM, e.response.data ) );
    }
}