import { useCallback } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import classnames from "classnames";

import history from "../../history";
import { cssUtils, flex } from "../ui";
import css from "./config.scss";

const ConfigHeader = ({
    actionTitle,
    onActionClick,
    actionIcon: Icon,
    title,
    disabled,
    secondaryActionIcon: SecondaryIcon,
    secondaryActionTitle,
    onSecondaryActionClick
}) => {
    const handlePreviousPage = useCallback( () => {
        history.push( "/config" );
    }, [] );

    return (
        <CardHeader
            title={ title }
            titleTypographyProps={{ variant: "h6" }}
            className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingTop0 ) }
            classes={{ action: css.configHeaderAction }}
            avatar={
                <IconButton onClick={ handlePreviousPage } color="primary">
                    <ArrowBackIcon fontSize="large"/>
                </IconButton>
            }
            action={
                <div className={ classnames( flex.columnGapSmall, flex.container ) }>
                    {
                        secondaryActionTitle &&
                            <Button onClick={ onSecondaryActionClick } startIcon={ <SecondaryIcon/> }>
                                { secondaryActionTitle }
                            </Button>
                    }
                    {
                        actionTitle &&
                            <Button
                                variant="contained"
                                onClick={ onActionClick }
                                startIcon={ <Icon /> }
                                disabled={ disabled }
                            >
                                { actionTitle }
                            </Button>
                    }
                </div>
            }
        />
    );
};

export default ConfigHeader;