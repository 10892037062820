import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { saveProposal } from "../opportunity.actions";
import { createDuplicateProposalFormName } from "../opportunity.utils";
import { ProposalForm, validate } from "./proposal-form.component";

const DuplicateProposal = ({ open, onClose, proposal }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formName = createDuplicateProposalFormName( proposal.id );

    const formik = useForm({
        form: formName,
        initialValues: {
            ...proposal,
            id: null,
            name: "",
            products: ( proposal.products || [] ).map( proposalProduct => ({
                ...proposalProduct,
                id: null,
            }))
        },
        onSubmit: values => dispatch( saveProposal( formName )( values ) ),
        validate,
    });

    return (
        <ProposalForm
            formik={ formik }
            open={ open }
            onClose={ onClose }
            title={ t( "opportunity:proposals.duplicate" ) }
        />
    );
};

export default DuplicateProposal;