import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm } from "formik-redux";
import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import get from "lodash/get";
import classnames from "classnames";

import history from "../../history";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { getLead } from "./web-capture.selectors";
import { changeLeadStatus } from "./web-capture.actions";
import { LEAD_INFO } from "./web-capture.constants";
import { Dialog, cssUtils, flex } from "../ui";

const LeadInfo = ({ className }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const lead = useSelector( getLead );

    const [ confirmDelete, setConfirmDelete ] = useState( false );
    const toggleConfirmDelete = () => setConfirmDelete( !confirmDelete );

    const formik = useForm({
        form: LEAD_INFO,
        enableReinitialize: true,
        initialValues: {
            id: lead?.id,
            status: lead?.status === "OPEN" ? "DISCARTED" : "OPEN",
        },
        onSubmit: values => dispatch( changeLeadStatus( values ) ),
        onSubmitSuccess: toggleConfirmDelete,
    });

    const openBody = () => {
        const width = 550;
        const height = 550;
        const left = ( screen.width / 2 ) - ( width / 2 );
        const top = ( screen.height / 2 ) - ( height / 2 );
        window.open(
            history.createHref({ ...location, pathname: `/leads/${lead.id}/body` }),
            "Lead",
            `width=${width}, height=${height}, top=${top}, left=${left}`
        );
    };

    const body = useMemo( () => {
        if ( !lead ) {
            return "";
        }
        if ( lead.body.indexOf( "<body" ) !== -1 ) {
            return lead.body
                .substring( lead.body.indexOf( "<body" ), lead.body.indexOf( "</body>" ) )
                .replace( "<body", "<div" )
                .concat( "</div>" );
        }
        return lead.body;
    }, [ lead ] );

    return (
        <div
            id="leadInfo"
            className={
                classnames( className, flex.container, flex.column, cssUtils.fullHeight, cssUtils.overflowAuto )
            }
        >
            <Dialog
                open={ confirmDelete }
                onClose={ toggleConfirmDelete }
            >
                <DialogContent>
                    <DialogContentText>{ t( "web-capture:lead-info.change-status.confirm" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmDelete } disabled={ formik.submitting }>
                        { t( "web-capture:lead-info.change-status.no" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "web-capture:lead-info.change-status.yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <Card className={ classnames( flex.fill, cssUtils.overflowAuto ) }>
                <CardHeader subheader={ t( "web-capture:lead-info.info" ) } />
                {
                    lead &&
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={ get( lead, "source.name" ) }
                                    secondary={ t( "web-capture:lead.source" ) }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={ lead.to }
                                    secondary={ t( "web-capture:lead.to" ) }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={ lead.from }
                                    secondary={ t( "web-capture:lead.from" ) }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={ lead.subject }
                                    secondary={ t( "web-capture:lead.subject" ) }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={ dateFormat( lead.createdAt ) }
                                    secondary={ t( "web-capture:lead.created-at" ) }
                                />
                            </ListItem>
                        </List>
                }
                <CardHeader subheader={ t( "web-capture:lead-info.body" ) } />
                <CardContent className={ cssUtils.marginTop }>
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </CardContent>
            </Card>
            <Card>
                <CardActions>
                    <div className={ flex.fill }>
                        {
                            lead.status !== "CONVERTED" &&
                                <Button
                                    onClick={ toggleConfirmDelete }
                                    color="secondary"
                                >
                                    { lead.status === "OPEN" ?
                                        t( "web-capture:lead-info.change-status.discard" ) :
                                        t( "web-capture:lead-info.change-status.open" )
                                    }
                                </Button>
                        }
                    </div>
                    <Button id="openLeadBody" onClick={ openBody }>
                        { t( "web-capture:lead-info.open-body" ) }
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default LeadInfo;