import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, matchPath, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import classnames from "classnames";
import config from "config";
import intl from "react-intl-universal";

import useTranslate from "../hooks/use-translate.hook";
import { actions as logoutActions } from "../logout";
import { setLocale } from "../core/core.actions";
import { getLocale } from "../core/core.selectors";
import { locales, supportedLanguages } from "../core/core.utils";
import { EmailVerification } from "../user";
import { Fab } from "../ui";
import { Login } from "../login";
import LoadingView from "./loading-view.component";
import SelectLocale from "./select-locale.component";
import cssUtils from "../core/css-utils.scss";
import css from "./layout.scss";

const closestLanguage = language => {
    if ( supportedLanguages.includes( language ) ) {
        return language;
    }

    return supportedLanguages
        .find( locale => language.startsWith( locale.split( "-" )[ 0 ] ) );
};

const MobileLayout = ({ mobile, userLogged }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const t = useTranslate();

    const locale = useSelector( getLocale );
    const [ ready, setReady ] = useState( false );

    const renderComponent = useCallback( Component => ( <Component location={ location } /> ), [ location ] );

    const poweredByICarros = config.has( "client.poweredBy" ) && config.get( "client.poweredBy" ) === "icarros";
    const handleLogout = useCallback( () => dispatch( logoutActions.userLogout() ), [ dispatch ] );

    useEffect( () => {
        if ( !locale ) {
            const language = navigator.language || navigator.userLanguage;
            const locale = closestLanguage( language ) || "pt-BR";
            intl.init({
                currentLocale: locale,
                fallbackLocale: "pt-BR",
                locales
            }).then( () => {
                dispatch( setLocale( locale ) );
                setReady( true );
            });
        }
    }, [ dispatch, locale ] );

    useEffect( () => {
        if ( locale ) {
            setReady( false );
            intl.init({ currentLocale: locale, locales }).then( () => setReady( true ) );
        }
    }, [ locale ] );

    if ( !ready || !locale ) {
        return <LoadingView />;
    }

    const emailVerificationRoute = matchPath( {
        path: "emails/verify/:token",
        caseSensitive: false,
        end: true
    }, location.pathname );

    return (
        <div className={ classnames( css.rootNoMenu, css.layout ) }>
            <Helmet defaultTitle="LeadStation" titleTemplate="%s - LeadStation" />

            <div className={ cssUtils.overflowAuto }>
                <Card
                    className={ classnames( cssUtils.autoHeight, css.content, css.rootNoMenu ) }
                    square
                >
                    <CardContent>
                        <div className={
                            classnames(
                                css.noMenuLogoDimension,
                                poweredByICarros && css.noMenuLogoCarros,
                                poweredByICarros !== undefined && !poweredByICarros && css.noMenuLogo
                            )
                        }
                        />
                        {
                            !userLogged ?
                                <Routes>
                                    <Route path="login/*" element={ renderComponent( Login ) }/>
                                    <Route index element={ <Navigate replace to={"/login"}/> } />
                                    <Route path="/*"
                                        element={
                                            <Navigate
                                                replace
                                                to={`/login?next=${location.pathname}&${location.search}`}
                                            />
                                        }
                                    />
                                </Routes> :
                                <>
                                    {
                                        emailVerificationRoute ?
                                            <Routes>
                                                <Route path="emails/verify/:token" element={ <EmailVerification/> } />
                                            </Routes> :
                                            <div className={ cssUtils.textCenter }>
                                                <Typography gutterBottom variant="h6">
                                                    { t( "layout:mobile-instructions" ) }
                                                </Typography>
                                                <div>
                                                    {
                                                        mobile === "android" &&
                                                            <a
                                                                className={ css.googlePlay }
                                                                href={ t( "layout:google-play.link" ) }
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={ t( "layout:google-play.img-src" ) }/>
                                                            </a>
                                                    }
                                                    {
                                                        mobile === "ios" &&
                                                            <a
                                                                className={ css.appStore }
                                                                href={ t( "layout:app-store.link" ) }
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={ t("layout:app-store.img-src") }/>
                                                            </a>
                                                    }
                                                </div>
                                            </div>
                                    }
                                    <Fab
                                        color="secondary"
                                        position="topRight"
                                        onClick={ handleLogout }
                                    >
                                        <ExitToAppIcon/>
                                    </Fab>
                                </>
                        }
                        <div className={ css.localeContainer }>
                            <SelectLocale />
                        </div>
                        {
                            poweredByICarros &&
                                <div className={ css.leadStationPoweredMessage }>
                                    <Typography align="center">
                                        { t( "layout:leadstation-powered-by.icarros" ) }
                                    </Typography>
                                </div>
                        }
                    </CardContent>
                </Card>
            </div>
        </div>
    );

};

export default MobileLayout;