import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { SelectCustomer, SelectOpportunity } from "../../../ui";
import css from "./template.scss";

const FileModelSectionPreview = ({ form, open, onClose }) => {
    const t = useTranslate();

    const [ loading, setLoading ] = useState( false );
    const [ companyId, setCompanyId ] = useState( null );
    const [ personId, setPersonId ] = useState( null );
    const [ opportunityId, setOpportunityId ] = useState( null );

    const handleClose = () => {
        onClose();
        setLoading( false );
        setCompanyId( null );
        setPersonId( null );
        setOpportunityId( null );
    };

    const preview = () => {
        setLoading( true );

        const request = new XMLHttpRequest();
        request.open( "POST", "/api/v1/filemodels/section/template" );
        request.setRequestHeader( "Content-Type", "application/json;charset=UTF-8" );
        request.setRequestHeader( "Accept", "*/*" );
        request.responseType = "blob";

        request.onload = function () {
            // Only handle status code 200
            if ( request.status === 200 ) {
            // Try to find out the filename from the content disposition `filename` value
                const filename = `${name}.pdf`;

                // The actual download
                const blob = new Blob([ request.response ], { type: "application/pdf" });
                const url = window.URL.createObjectURL( blob );
                const link = document.createElement( "a" );
                link.href = url;
                link.download = filename;

                document.body.appendChild( link );

                link.click();

                document.body.removeChild( link );
            }
            handleClose();
        };

        request
            .send( JSON.stringify({
                header: {
                    html: form.values.header,
                    height: form.values.headerHeight,
                    margin: form.values.headerMargin,
                },
                footer: {
                    html: form.values.footer,
                    height: form.values.footerHeight,
                    margin: form.values.footerMargin,
                },
                body: form.values.body,
                companyId,
                personId,
                opportunityId
            }));
    };

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:files.model.section-preview.title" ) }
            </DialogTitle>
            <DialogContent className={ css.dialogContentPreview }>
                <DialogContentText>
                    { t( "config:files.model.section-preview.description" ) }
                </DialogContentText>
                <SelectCustomer
                    label={ t( "config:files.model.section.company" ) }
                    type="COMPANY"
                    onChange={ ( event, { value } ) => setCompanyId( value ) }
                    value={ companyId }
                />
                <SelectCustomer
                    label={ t( "config:files.model.section.person" ) }
                    type="PERSON"
                    onChange={ ( event, { value } ) => setPersonId( value ) }
                    value={ personId }
                />
                <SelectOpportunity
                    label={ t( "config:files.model.section.opportunity" ) }
                    onChange={ ( event, { value } ) => setOpportunityId( value ) }
                    value={ opportunityId }
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ handleClose } disabled={ loading }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ preview } disabled={ loading }>
                    { t( "common:visualize" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileModelSectionPreview;