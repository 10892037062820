import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { fetchCategories } from "../../../product/product.actions";
import * as actions from "./product.actions";
import {
    CONFIG_PRODUCT_FORM,
    DELETE_PRODUCTS_FORM,
    IMPORTATION_PRODUCTS_FORM,
    PRODUCT_CATEGORY_FORM
} from "./product.constants";
import { createDeleteImportationForm } from "./product.utils";

export function *watchConfigOpportunityProduct () {
    yield all([
        takeEvery( actions.SAVE_PRODUCT, saveProduct ),
        takeEvery( actions.IMPORT_PRODUCTS, importProducts ),
        takeEvery( actions.FETCH_IMPORTATIONS, fetchImportations ),
        takeEvery( actions.DELETE_IMPORTATION, deleteImportation ),
        takeEvery( actions.DELETE_PRODUCT, deleteProduct ),
        takeEvery( actions.DELETE_PRODUCTS, deleteProducts ),
        takeEvery( actions.DELETE_PRODUCT_CATEGORIES, deleteProductCategory ),
        takeEvery( actions.SAVE_PRODUCT_CATEGORIES, saveProductCategories ),
    ]);
}

export function *saveProduct ({ payload: { product } }) {
    yield put( startSubmit( CONFIG_PRODUCT_FORM ) );

    try {
        if ( product.id ) {
            yield call( apiv1.patch, `/products/${ product.id }`, product );
        } else {
            yield call( apiv1.post, "/products", product );
        }
        yield put( stopSubmit( CONFIG_PRODUCT_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( CONFIG_PRODUCT_FORM, e.response.data.error ) );
    }
}

export function *importProducts ({ payload }) {
    try {
        yield put( startSubmit( IMPORTATION_PRODUCTS_FORM ) );

        const formData = new FormData();
        formData.append( "measurementUnit", payload.measurementUnit );
        formData.append( "recurrent", payload.recurrent );
        formData.append( "file", payload.file );

        yield call( apiv1.post, "/products/importations", formData );
        yield put( stopSubmit( IMPORTATION_PRODUCTS_FORM ) );
        yield put( actions.fetchImportations() );
    } catch ( e ) {
        yield put( stopSubmit( IMPORTATION_PRODUCTS_FORM, e.response.data.error ) );
    }
}

function *fetchImportations () {
    try {
        yield put( actions.requestImportations() );

        const response = yield call( apiv1.get, "/products/importations" );
        yield put( actions.receiveImportations( response.data ) );
    } catch ( e ) {
        yield put( actions.errorImportations( e.response.data.error ) );
    }
}

function *deleteImportation ({ meta: { id }}) {
    const form = createDeleteImportationForm( id );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, `/products/importations/${id}` );

        yield put( actions.fetchImportations() );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteProduct ({ meta: { id } }) {
    try {
        yield call( apiv1.delete, `/products/${id}` );
        yield put( actions.deleteProductSuccess() );
    } catch ( e ) {
        yield put( actions.deleteProductError( e.response.data ) );
    }
}

export function *deleteProducts ({ payload }) {
    yield put( startSubmit( DELETE_PRODUCTS_FORM ) );
    const errors = {};
    for ( const productId of payload.productIds ) {
        try {
            yield call( apiv1.delete, `/products/${productId}` );
        } catch ( e ) {
            errors[ productId ] = e.response.data;
        }
    }
    yield put( stopSubmit( DELETE_PRODUCTS_FORM, errors ) );
}

export function *deleteProductCategory ({ meta: { id } }) {
    yield put( startSubmit( PRODUCT_CATEGORY_FORM ) );

    try {
        yield call( apiv1.delete, "/products/categories/" + id );

        yield put( fetchCategories() );
        yield put( stopSubmit( PRODUCT_CATEGORY_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( PRODUCT_CATEGORY_FORM, e.response.data ) );
    }
}

export function *saveProductCategories ( action ) {
    const { productCategories } = action.payload;

    try {
        yield put( startSubmit( PRODUCT_CATEGORY_FORM ) );

        for ( const productCategory of productCategories ) {
            if ( productCategory.id ) {
                yield call( apiv1.patch, "/products/categories/" + productCategory.id, productCategory );
            } else {
                yield call( apiv1.post, "/products/categories", productCategory );
            }
        }

        yield put( stopSubmit( PRODUCT_CATEGORY_FORM ) );
        yield put( fetchCategories() );

    } catch ( e ) {
        yield put( stopSubmit( PRODUCT_CATEGORY_FORM, e.response.data ) );
    }
}