export default {
    "title": "Marketplace",
    "integrations": {
        "title": "Integraciones",
        "subtitle": "Configure sus integraciones con Portales, Captura Web, Facebook ADS y mucho más.",
        "able": "Habilitado",
        "lead": {
            "title": "Captura Web",
            "help":
                    "Módulo responsable de recibir correos electrónicos de sus portales verticales y sitios web, " +
                    "generando leads automáticamente. Para activar debe configurar el " +
                    "distribuición y las capturas en las pestañas siguientes. El funcionamiento de " +
                    "Web Capture es sencillo. " +
                    "Recopilamos datos de clientes potenciales a través del correo electrónico que " +
                    "recibe del portal. Creamos un lead " +
                    "para que pueda calificar, pudiendo: - convertirlo en un cliente, creando una " +
                    "oportunidad; - descartarlo por falta de datos en el correo electrónico recebido. En LeadStation " +
                    "se configura la distribución de los leads recibidos, es decir, los usuarios del sistema " +
                    "recibirá estos clientes potenciales. Además de eso, puede diferenciar los clientes " +
                    "potenciales recibidos por fuente. " +
                    "Así, será censillo medir la captura por cada portal o sitio. Exemplo: El portal " +
                    "iCarros tiene el origen iCarros y el correo electrónico lead.iCarros.1@leadstation.com.br." +
                    "Copie este correo electrónico, peguélo en la configuración del portal de " +
                    "iCarros para que sea el " +
                    "destinatario de nuevos correos electrónicos de nuevos clientes potenciales y recibe " +
                    "los datos automáticamente en LeadStation. " +
                    "Esto se puede hacer para todos los portales, ya que todos envian un " +
                    "correo electrónico de aviso desde" +
                    "recibir nuevo cliente potencial, con datos de cliente potencial en el " +
                    "cuerpo del correo electrónico.",
            "read-more": "Leer más"
        },
        "lead-distribution": {
            "title": "Distribución de leads",
            "call-to-action":
                "Ingrese aquí qué usuarios recibirán los clientes potenciales integrados. " +
                "Si se selecciona un equipo, se seguirá el patrón de distribución del equipo. Para usuarios " +
                "seleccionando más de un usuario, la distribución será proporcional a ellos, " +
                "ordenar alfabéticamente.",
            "distribution": "Distribución",
            "team": "Equipo",
            "users": "Usuarios",
            "last-user": "Último cliente potencial creado para { user }"
        },
        "lead-source": {
            "title": "Capturar",
            "call-to-action":
                "Introduzca orígenes que coincidan con sus portales verticales o sitios web. " +
                "Para cada uno de ellos se proporciona una dirección de correo electrónico que debe registrarse " +
                "en su portal vertical o sitio web para recibir los clientes potenciales.",
            "name": "Nombre",
            "source": "Origen",
            "portal": "Portal",
            "pending": "Configuración pendiente",
            "add": "Agregar captura",
            "copy-email": "Copiar correo electrónico",
            "distribution": {
                "title": "Distribución",
                "help":
                    "Díganos aquí qué usuarios recibirán los clientes potenciales integrados. Esta configuración " +
                    "sobrescribirá la configuración de distribución general de Web Capture."
            },
            "fields": {
                "title": "Configuración de campos de oportunidad",
                "help":
                    "Seleccione los valores predeterminados de los campos de tipo \"Select\" " +
                    "para las oportunidades que se crearán",
                "label": "Configuración",
                "field-id": "Campo",
                "default-value": "Valor predeterminado"
            },
            "delete": {
                "title": "Eliminar configuración",
                "comment": "Está seguro que desea eliminar esta configuración? Esta acción es irreversible."
            }
        },
        "facebook": {
            "title": "Facebook",
            "info":
                "Aquí se vinculan las páginas de Facebook para recibir clientes potenciales en el sistema. " +
                "LeadStation permite la integración con páginas de Facebook para la integración de clientes " +
                "potenciales generados a partir de estas páginas.",
            "lead-distribution": {
                "distribution": "Distribución",
                "team": "Equipo",
                "users": "Usuarios"
            },
            "lead-pages": {
                "title": "Páginas",
                "your-pages": "Páginas integradas",
                "call-to-action": "Configura tus páginas aquí para que LeadStation empiece a recibir leads.",
                "add-help":
                        "Ingrese aquí cuál será la fuente y qué usurarios recibirán los " +
                        "clientes potenciales integrados. Puede definir un equipo para recibir clientes " +
                        "potenciales o especificar directamente algunos usuarios. Especificando más de un " +
                        "usuario la distribución será proporcional a ellos, ordenar alfabéticamente.",
                "add": "Agregar página",
                "edit": "Editar página",
                "select-pages": "Seleccione las páginas",
                "page": "Página",
                "form": {
                    "label": "Formulario",
                    "help":
                            "Puede establecer esta configuración para un formulario específico. " +
                            "Si no se notifica ningún formuario, esta configuración se tendrá en cuenta para " +
                            "todos los formularios de página."
                },
                "source": "Origen",
                "funnel": "Embudo",
                "edit-description": "Cambie los datos de la página aquí { page }",
                "expired-token": "Clave de acceso caducada en { date }. Haga clic aquí para actualizar",
                "expire-token": "Caduca la clave de acceso { date }. Haga clic aquí para actualizar",
                "confirm-delete": "Está seguro que desea eliminar esta página?",
                "created-info": "{ user } establezca esta página en { date }",
                "empty": "No tiene páginas para agregar"
            }
        },
        "new-api-key": {
            "title": "Nueva configuración"
        },
        "api-keys": {
            "title": "Integración de API",
            "help": "<span>Aquí se realiza la configuración de las claves a utilizar para la generación de clientes y oportunidades vía API. Estas chaves devem ser usadas como forma de autenticação. As características da API que aceita esta autenticação está documentada em </span> <a target=\"__blank\" href=\"https://docs.leadstation.com.br/api/v1/#/ApiKey/post_apikeys__key__customers_opportunities\"> <b>https://docs.leadstation.com.br/api/v1/</b> </a> <span> sob o endpoint <b>POST https://api.leadstation.com.br/api/v1/apikeys/{key}/customers/opportunities.</b></span>\n",
            "fields-body-api": "Campos de formulario para API",
            "help-fields-body-api":
                "Campos de formulario de los tipos Texto, Data, Número y Select para usar en la API. " +
                "Para información de los valores aceptados en los campos distintos de \"customer.fields\" y " +
                "\"oportunidad.campos\", accesada a la documentación de la API.",
            "date-field-value": "Fecha en formato ISO",
            "customer-fields": "Campos de cliente (customer.fields):",
            "opportunity-fields": "Campo de oportunidad (opportunity.fields):",
            "observation-fields": "Nota: los nombres de campo entre paréntesis deben ignorarse",
            "empty": "No tiene configuraciones para agregar"
        },
        "api-key": {
            "name": "Nombre",
            "key": "Clave",
            "copy-key": "Copiar clave { key }",
            "source": "Origen",
            "team": "Equipo",
            "users": "Usuarios"
        },
        "delete-api-key": {
            "confirm": "Está seguro que desea eliminar esta configuración? Esta acción es irreversible",
            "yes": "Sí, eliminar"
        },
        "portals": {
            "title": "Portales",
            "call-to-action":
                "Configura aquí tus portales de ventas",
            "new": {
                "title": "Nuevo portal",
                "help":
                    "Después de completar todos los ajustes y guardarlos, se generará una URL para " +
                    "se utiliza en el { integraciónCanal }"
            },
            "edit": {
                "title": "Editar portal"
            },
            "empty": "No hay integración configurada",
            "total": "Integraciones configuradas: { total }",
            "icarros-header": "Introduzca a continuación los datos de la cuenta de iCarros",
            "mobiauto-header": "Introduzca a continuación los datos de la cuenta de Mobiauto",
            "usadosbr-header": "Introduzca a continuación los datos de la cuenta de Usadosbr",
            "webmotors-header": "Introduzca a continuación los datos de la cuenta de Webmotors",
            "expired": "Acceso caducado",
            "active": "Activo",
            "name": {
                "label": "Nombre",
                "help":
                    "El nombre que introduzacas aquí para esta integración, será el título de las oportunidades creadas"
            },
            "source": "Origen",
            "empty-users": "Debes seleccionar una distribución o un equipo o " +
                "al menos un usuario para distribuir oportunidades",
            "MERCADO_LIBRE": {
                "call-to-action":
                    "Configure este portal para obtener preguntas en sus anuncios de vehículos o bienes inmuebles"
            },
            "OLX": {
                "call-to-action":
                    "Configure este portal para obtener preguntas en sus anuncios de vehículos o bienes inmuebles"
            },
            "MOBIAUTO": {
                "title": "Mobiauto",
                "call-to-action": "Configura este portal para recibir mensajes en tus anuncios de vehículos"
            },
            "USADOSBR": {
                "title": "Usadosbr",
                "call-to-action": "Configura este portal para recibir mensajes en tus anuncios de vehículos"
            },
            "WEBMOTORS": {
                "call-to-action":
                    "Configure este portal para obtener preguntas en sus anuncios de vehículos o bienes inmuebles"
            },
            "ICARROS": {
                "call-to-action":
                    "Configure este portal para obtener preguntas en sus anuncios de vehículos o bienes inmuebles"
            },
            "type": {
                "ICARROS": "iCarros",
                "MERCADO_LIBRE": "Mercado Libre",
                "MOBIAUTO": "Mobiauto",
                "OLX": "OLX",
                "USADOSBR": "Usadosbr",
                "WEBMOTORS": "Motores Web"
            }
        },
        "icarros": {
            "title": "iCarros",
        },
        "olx": {
            "title": "OLX",
        },
        "webmotors": {
            "title": "Webmotors",
        },
        "api": {
            "title": "Integraciones API",
        }
    },
    "products": {
        "title": "Productos Integrados",
        "subtitle": "Agregue productos para aumentar su agilidad en las ventas.",
        "sms": {
            "title": "SMS",
            "help":
                    "Esta integración brinda la opción para que el usuario envíe SMS " +
                    "(mensaje de texto móvil) al cliente. LeadStation recibe  " +
                    "la respuesta SMS del cliente y la pone a disposición en el sistema. ",
            "consumption": "Consumo del mes en curso",
            "sent": "Enviado",
            "pending": "Pendiente",
            "received": "Recibido",
            "rejected": "Rechazado",
            "status": "Estado",
            "quantity": "Cantidad",
            "balance": "Saldo disponible: { balance }"
        },
    },
    "automation": {
        "title": "Automatizaciones",
        "subtitle": "Integre las herramientas de marketing.",
        "call-to-action":
        "Configure aquí la integración con herramientas de automatización de marketing. " +
        "Para obtener mayor información y asistencia en la configuración, vaya a ",
        "docs-link": {
            "label": "este enlace",
            "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations"
        },
        "new": {
            "title": "Nueva automatización",
            "exact-sales-help": "El token de acceso debe generarse en el sistema Exact Spotter. " +
                "Después de completar todas las configuraciones y guardar, se registrará un webhook " +
                "para recibir nuevos clientes potenciales de Exact Sales.",
            "help":
                "Después de completar todos los ajustes y guardarlos, se generará una URL para " +
                "utilizarlo no { integrationChannel }. La solicitud enviada debe ser de tipo POST"
        },
        "edit": {
            "title": "Editar automatización"
        },
        "empty": "No hay integración configurada",
        "total": "Integraciones configuradas: { total }",
        "name": {
            "label": "Nombre",
            "help": "El nombre que introduzcas aquí para esta integración, " +
        "será el título de las oportunidades creadas"
        },
        "source": "Origen",
        "access-token": "Token de acceso a la plataforma",
        "portal-distribution": "Distribución",
        "empty-users": "Debes seleccionar una distribución o un equipo o " +
                "al menos un usuario para distribuir oportunidades",
        "integration-channel": {
            "EXACT_SALES": "Exact Sales",
            "MAILTOP": "Mailtop",
            "LEADLOVERS": "Leadlovers",
            "MKT2EASY": "MKT2Easy",
            "RD_STATION": "Estación RD",
            "OTHER": "HTTP Rest API"
        },
        "mapping-fields": {
            "title": "Asignación de campos",
            "help":
            "Aquí se vinculas los campos enviados por el sistema con los campos LeadStation. Hacia " +
            "campos de Texto, Dirección y Teléfono, puede especificar más de un valor " +
            "para combinar los valores, por ejemplo, la coincidencia de nombre y apellido " +
            "se convierte en el campo Nombre de LeadStation. Escriba una palabra y pulse Enter",
            "customer-fields": "Datos de clientes de LeadStation",
            "opportunity-fields": "Datos de oportunidad de LeadStation",
            "req-fields": "Datos de { integraciónCanal }"
        },
        "mailtop": {
            "title": "Mailtop",
            "call-to-action":
                "Aprovechar el potencial del marketing por correo electrónico para ganar ventas y fortalecer " +
                "relación con los clientes a través de Mailtop. Crear campañas promocionales " +
                "con plantillas fáciles de modificar, segmentar listas de contatos y analizar " +
                "el rendimiento de sus tomas de marketing por correo electrónico con nuestra solución completa. " +
                "Cuente con la mejor solución de email marketing para su negocio. Pre - y " +
                "post pago que cabe en tu presupuesto. Acesse ahora, ",
            "access": "Acceso a Mailtop",
            "tabs": {
                "auth": "Autenticación",
                "lists": "Listas"
            },
            "auth": {
                "title": "Para utilizar las caractéristicas de Mailtop, necesitas obtener una llave de API",
                "token": "Llave de API",
                "created": "Configurado por { createdBy } en { date }",
                "confirm-delete": "Está seguro que desea eliminar esta llave de API?"
            },
            "lists": {
                "error-load": "No se pudieron cargar las listas de Mailtop",
                "title": "Configurar aquí las listas que recibirán los datos de los clientes",
                "add": "Agregar lista",
                "edit": "Editar lista",
                "single": "Lista de Mailtop",
                "copy-webhook": "Copiar dirección URL en webhook de automatización de Mailtop",
                "event": {
                    "label": "Evento",
                    "OPPORTUNITY_CREATED": {
                        "label": "Oportunidad creada",
                    },
                    "OPPORTUNITY_UPDATED": {
                        "label": "Oportunidad alterado",
                        "helper": "Cuando la oportunidad entra en el embudo o la fase"
                    },
                    "OPPORTUNITY_LOST": {
                        "label": "Oportunidad perdida",
                    },
                    "OPPORTUNITY_SOLD": {
                        "label": "Oportunidad vendida",
                    }
                },
                "create-opportunity": {
                    "label": "Crear oportunidad",
                    "help":
                        "Si no se encuentra ninguna oportunidad abierta para el embudo/fase configurada, este " +
                        "campo define si se debe crear una nueva oportunidad al recibir la llamada a Webhook " +
                        "de la Automitización de Mailtop."
                },
                "executing": "Ejecutando desde { date }",
                "finished-at": "Ejecución finalizada en { date }",
                "execute": {
                    "title": "Ejecutar lista",
                    "confirm": "Está seguro que desea ejecutar esta lista?",
                    "description":
                        "Al ejecutar, los correos electrónicos del cliente de las oportunidades " +
                        "de esta lista que ya están " +
                        "en LeadStation, se enviará a Mailtop. Este proceso no se puede cancelar."
                },
                "confirm-delete": "Está seguro que desea eliminar la integración con esta lista?"
            },
            "source": "Origen de Mailtop",
            "email-rejected": "Este correo electrónico está en la lista de rechazados de Mailtop",
            "email-available": "Este correo electrónico no está en la lista de rechazados de Mailtop"
        },
        "EXACT_SALES": {
            "title": "Exact Sales",
            "call-to-action":
            "Exact Sales es una plataforma completa de automatización de marketing que ofrece " +
            "recursos como páginas de captura, secuencias de correos electrónicos, webinars y más. " +
            "Al integrar Exact Sales con tu aplicación Leadstation, puedes recibir los leads capturados " +
            "en Exact Sales directamente en tu aplicación, lo que permite la gestión centralizada de " +
            "esta información y la automatización de tareas relacionadas. " +
            "Para obtener más información y ayuda con la configuración, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=exact-sales-automação"
            }
        },
        "LEADLOVERS": {
            "title": "Leadlovers",
            "call-to-action":
            "Leadlovers es una plataforma completa de automatización de marketing que ofrece " +
            "recursos como páginas de captura, secuencias de correos electrónicos, webinars y más. " +
            "Al integrar Leadlovers con tu aplicación Leadstation, puedes recibir los leads capturados " +
            "en Leadlovers directamente en tu aplicación, lo que permite la gestión centralizada de " +
            "esta información y la automatización de tareas relacionadas. " +
            "Para obtener más información y ayuda con la configuración, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=leadlovers-automação"
            }
        },
        "MKT2EASY": {
            "title": "MKT2Easy",
            "call-to-action":
            "MKT2Easy es una plataforma de automatización de marketing digital que simplifica la " +
            "gestión de campañas de email marketing, automatización de flujos de trabajo, creación " +
            "de páginas de destino y más. Al integrar MKT2Easy con tu aplicación Leadstation, puedes " +
            "recibir los leads capturados en MKT2Easy directamente en tu aplicación, lo que permite " +
            "una gestión centralizada y eficiente de esta información. " +
            "Para obtener más información y ayuda con la configuración, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=mkt2easy-automação"
            }
        },
        "RD_STATION": {
            "title": "RD Station",
            "call-to-action":
            "RD Station es una plataforma de automatización de marketing que ayuda en la generación y " +
            "gestión de leads, automatización de emails, análisis de resultados y más. Al integrar RD " +
            "Station con tu aplicación Leadstation, puedes recibir los leads capturados en RD Station " +
            "automáticamente en tu aplicación. Esto permite la centralización de los datos de los leads " +
            "y la optimización de tus estrategias de marketing. " +
            "Para obtener más información y ayuda con la configuración, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=rd-station-automação"
            }
        },
        "OTHER": {
            "title": "HTTP Rest API",
            "call-to-action":
            "La comunicación HTTP REST es un protocolo ampliamente utilizado para la integración entre " +
            "sistemas. Permite que tu aplicación se comunique con otras plataformas y servicios externos " +
            "a través de llamadas HTTP. Al utilizar la comunicación HTTP REST junto con tu aplicación " +
            "Leadstation, puedes integrar diversas herramientas de automatización y recibir los leads " +
            "capturados a través de estas integraciones. " +
            "Para obtener más información y ayuda con la configuración, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/automations?id=http-rest-api-automação"
            }
        }
    },
    "hubs": {
        "title": "Hubs de Integración",
        "subtitle": "Utilice los mejores hubs disponibles para integrar con varios otros sistemas.",
        "zapier": {
            "title": "Zapier",
            "help": "Configure la integración de LeadStation con su cuenta de Zapier aquí",
        }
    },
    "additional-resources": {
        "title": "Otros Recursos",
        "subtitle": "Configurar webhooks",
        "webhooks": {
            "title": "Webhooks",
            "subtitle": "Registra aquí las URL para enviar datos según el evento a disparar.",
            "call-to-action":
            "Un webhook es un método de comunicación entre sistemas que permite que un sistema " +
            "envíe automáticamente información a otro sistema cuando ocurre un evento. En " +
            "el contexto de LeadStation, se puede utilizar un webhook para integrarlo con otros sistemas. " +
            "Para obtener más información y ayuda para configurarlo, visita ",
            "docs-link": {
                "label": "este enlace",
                "value": "https://docs.leadstation.com.br/#/pt-BR/integrations/webhooks?id=webhooks"
            },
            "empty": "No hay webhooks registrados",
            "new": "Nuevo webhook",
            "url": "URL",
            "event": {
                "label": "Evento",
                "CUSTOMER_CREATED": "Cliente creado",
                "OPPORTUNITY_CREATED": "Oportunidad creada",
                "OPPORTUNITY_LOST": "Oportunidad perdida",
                "OPPORTUNITY_SOLD": "Oportunidad vendida"
            },
            "oauth-client": "OAuth Client"
        }
    }
};