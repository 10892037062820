export default {
    "field": {
        "select": "Escoger",
        "ssn": "RFC",
        "ein": "RFC"
    },
    "address": {
        "add": "Agregar dirección",
        "address": "Dirección",
        "type": "Tipo",
        "open-map": "Dirección abierta en Google Maps",
        "types": {
            "RESIDENTIAL": "Residencial",
            "COMMERCIAL": "Comercial",
            "OTHER": "Otro"
        }
    },
    "email": {
        "add": "Agregar correo electrónico"
    },
    "model": {
        "empty-brand": "Primero debe seleccionar una etiqueta"
    },
    "phone": {
        "add": "Agregar teléfono",
        "number": "Número",
    },
    "company-data": {
        "fetch": "Datos completos a través del CNPJ",
        "unavailable": "Servicio actualmente no disponible. Intente nuevamente más tarde"
    },
    "user-salesman": "Vendedor",
    "add-customer": "Agregar cliente",
    "customer": "Cliente",
    "user-filters": {
        "title": "Filtros",
        "other-fields": "Otros campos",
        "person-fields": "Campos de persona",
        "company-fields": "Campos de la empresa",
        "operator": {
            "label": "Comparador",
            "EQUAL": "Igual",
            "NOT_EQUAL": "Diferente",
            "LIKE": "Contiene",
            "NOT_LIKE": "No contiene",
            "NULL": "Sin información",
            "NOT_NULL": "Con información",
            "BETWEEN": "Entre"
        },
        "field": {
            "label": "Campo",
            "brand": "Marca"
        },
        "value": {
            "label": "Valor"
        },
        "customer": {
            "tags": "Tags"
        },
        "opportunity": {
            "empty-team": "No hay equipos configurados",
            "team": "Equipo",
            "users": "Usuarios",
            "products": "Productos",
            "temperature": "Temperatura",
            "company-tags": "Tags de empresa",
            "person-tags": "Tags de persona",
            "has-open-scheduling": "Con programación abierta",
            "has-open-scheduling-all": "Todas",
            "has-open-scheduling-no": "No",
            "has-open-scheduling-yes": "Si",
            "has-not-open-scheduling": "Sin programación abierta"
        },
        "scheduling-date": {
            "label": "Fecha de programación abierta",
            "from": " a partir de ",
            "to": " hasta ",
            "initial": "Fecha inicial",
            "final": "Fecha final"
        },
        "importation": {
            "label": "Importación",
            "help": "Importación utilizada para crear los registros"
        },
        "created-at": {
            "label": "Fecha de creación",
            "from": " a partir de ",
            "to": " hasta ",
            "initial": "Fecha incial",
            "final": "Fecha final"
        },
        "sold-at": {
            "label": "Fecha de venta ganada",
            "from": " a partir de ",
            "to": " hasta ",
            "initial": "Fecha incial",
            "final": "Fecha final"
        }
    }
};