export const FETCH_SCHEDULING_STATISTICS = "x/FETCH_SCHEDULING_STATISTICS";
export const fetchSchedulingStatistics = params => ({
    type: FETCH_SCHEDULING_STATISTICS,
    meta: { params },
});

export const REQUEST_SCHEDULING_STATISTICS = "x/REQUEST_SCHEDULING_STATISTICS";
export const requestSchedulingStatistics = () => ({
    type: REQUEST_SCHEDULING_STATISTICS,
});

export const RECEIVE_SCHEDULING_STATISTICS = "x/RECEIVE_SCHEDULING_STATISTICS";
export const receiveSchedulingStatistics = data => ({
    type: RECEIVE_SCHEDULING_STATISTICS,
    payload: data,
});

export const ERROR_SCHEDULING_STATISTICS = "x/ERROR_SCHEDULING_STATISTICS";
export const errorSchedulingStatistics = error => ({
    type: ERROR_SCHEDULING_STATISTICS,
    payload: error,
    error: true,
});

export const FETCH_SCHEDULES = "x/home/FETCH_SCHEDULES";
export const fetchSchedules = params => ({
    type: FETCH_SCHEDULES,
    meta: { params },
});

export const REQUEST_SCHEDULES = "x/home/REQUEST_SCHEDULES";
export const requestSchedules = () => ({
    type: REQUEST_SCHEDULES,
});

export const RECEIVE_SCHEDULES = "x/home/RECEIVE_SCHEDULES";
export const receiveSchedules = data => ({
    type: RECEIVE_SCHEDULES,
    payload: data,
});

export const ERROR_SCHEDULES = "x/home/ERROR_SCHEDULES";
export const errorSchedules = error => ({
    type: ERROR_SCHEDULES,
    payload: error,
    error: true,
});
