import { useMemo } from "react";
import MuiSlider from "@mui/material/Slider";

const Slider = ({
    className,
    field,
    size = "small",
    ...props
}) => {
    const value = useMemo( () => field.value || 0, [ field ] );

    return (
        <MuiSlider
            className={ className }
            size={ size }
            { ...field }
            value={ value }
            { ...props }
        />
    );

};

export default Slider;