import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import AddressItem from "./address-item.component";
import css from "./address.scss";

const AddressField = ({ currentPosition, disabled, form, label, name, push, remove, required }) => {
    const t = useTranslate();

    const [ autoFocus, setAutoFocus ] = useState( false );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    const handlePush = useCallback( () => {
        setAutoFocus( true );
        push({ name: "", type: "" });
    }, [ push ] );

    useEffect( () => {
        if ( required && values.length === 0 ) {
            handlePush();
        }
    }, [ handlePush, required, values ] );

    const rows = values.map( ( address, index ) => (
        <AddressItem
            key={ index }
            name={ `${name}[${index}]` }
            address={ address }
            onRemove={ () => {
                if ( !required || values.length > 1 ) {
                    remove( index );
                }
            }}
            currentPosition={ currentPosition }
            disabled={ disabled }
            autoFocus={ autoFocus }
        />
    ));

    return (
        <div className={ css.container }>
            <Typography variant="caption">
                { label }
                { required ? " *" : "" }
            </Typography>
            <List>{ rows }</List>
            <Button
                size="small"
                onClick={ handlePush }
                disabled={ disabled }
                startIcon={ <AddLocationIcon/> }
            >
                { t( "form:address.add" ) }
            </Button>
        </div>
    );
};

export default AddressField;