import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import useTranslate from "../../hooks/use-translate.hook";
import { Link } from "../../ui";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "./timeline.scss";

const OpportunityStatusEvent = ({ event: { user, date, payload: { opportunity } } }) => {
    const t = useTranslate();

    return (
        <TimelineItem
            classes={{
                avatar: opportunity.status === "SOLD" ? css.opportunitySoldIcon : css.opportunityLostIcon
            }}
            icon={ opportunity.status === "SOLD" ? <ThumbUpIcon/> : <ThumbDownIcon/> }
        >
            <TimelineSummary
                user={ user }
                description={
                    <span>
                        { t( "customer:timeline.opportunity." + opportunity.status ) }
                        <Link href={ "/opportunities/" + opportunity.id }>
                            <a>
                                { opportunity.title }
                            </a>
                        </Link>
                    </span>
                }
                date={ date }
            />
        </TimelineItem>
    );
};

export default OpportunityStatusEvent;