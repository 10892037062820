import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import useTranslate from "../../../hooks/use-translate.hook";
import FileModelSection from "./file-model-section.component";
import FileModelSectionItem from "./file-model-section-item.component";
import * as actions from "./template.actions";
import * as selectors from "./template.selectors";

const FileModelSections = ({ fileModel }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const selectedIndex = useSelector( selectors.selectedFileModelSection );
    const sections = useSelector( selectors.listFileModelSections );

    const [ openNewSection, setOpenNewSection ] = useState( false );

    const toggleNewSection = () => setOpenNewSection( !openNewSection );

    const handleDragEnd = result => {
        if ( !result.destination ) {
            return;
        }

        dispatch( actions.sortFileModelSection({ oldIndex: result.source.index, newIndex: result.destination.index }) );
    };

    const getItems = () => {
        return ( sections || [] ).map( ( section, index ) => (
            <FileModelSectionItem
                key={ section.id }
                index={ index }
                fileModel={ fileModel }
                section={ section }
                onSelect={ () => dispatch( actions.selectFileModelSection( index ) ) }
                onDeselect={ () => dispatch( actions.deselectFileModelSection() ) }
                selected={ selectedIndex === index }
            />
        ));
    };

    if ( !fileModel ) {
        return null;
    }

    return (
        <>
            <FileModelSection
                open={ openNewSection }
                fileModel={ fileModel }
                fileModelSection={{ fileModelId: fileModel.id }}
                onClose={ toggleNewSection }
            />

            <List
                subheader={
                    <ListSubheader>{ t( "config:files.model.section-list" ) }</ListSubheader>
                }
            >
                <ListItemButton onClick={ toggleNewSection }>
                    <ListItemIcon>
                        <AddIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={ t( "common:add" ) }
                    />
                </ListItemButton>
                <Divider/>
                <DragDropContext onDragEnd={ handleDragEnd }>
                    <Droppable droppableId="droppable">
                        { provided => (
                            <div
                                { ...provided.droppableProps }
                                ref={ provided.innerRef }
                            >
                                { getItems() }
                                { provided.placeholder }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </>
    );
};

export default FileModelSections;