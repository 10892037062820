import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as commissionActions } from "../../../commission";
import * as actions from "./commission.actions";
import { DELETE_COMMISSION_FORM, EDIT_COMMISSION_FORM, NEW_COMMISSION_FORM } from "./commission.constants";

export function *watchConfigCommission () {
    yield all([
        takeEvery( actions.CREATE_COMMISSION, createCommission ),
        takeEvery( actions.DELETE_COMMISSION, deleteCommission ),
        takeEvery( actions.EDIT_COMMISSION, editCommission ),
    ]);
}

function *createCommission ({ payload }) {
    yield put( startSubmit( NEW_COMMISSION_FORM ) );

    try {
        yield call( apiv1.post, "/commissions", payload );
        yield put( commissionActions.fetchCommissions() );
        yield put( stopSubmit( NEW_COMMISSION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_COMMISSION_FORM, e.response.data ) );
    }
}

function *deleteCommission ({ meta: { id } }) {
    yield put( startSubmit( DELETE_COMMISSION_FORM ) );

    try {
        yield call( apiv1.delete, `/commissions/${id}` );
        yield put( commissionActions.fetchCommissions() );
        yield put( stopSubmit( DELETE_COMMISSION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_COMMISSION_FORM, e.response.data ) );
    }
}

function *editCommission ({ meta: { id }, payload }) {
    yield put( startSubmit( EDIT_COMMISSION_FORM ) );

    try {
        yield call( apiv1.patch, `/commissions/${id}`, payload );
        yield put( commissionActions.fetchCommissions() );
        yield put( stopSubmit( EDIT_COMMISSION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( EDIT_COMMISSION_FORM, e.response.data ) );
    }
}