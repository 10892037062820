import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import ReplyIcon from "@mui/icons-material/Reply";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import { getUser } from "../../core/core.selectors";
import { startNewEmail } from "../customer.actions";
import { getSelected } from "../customer.selectors";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils, flex } from "../../ui";
import EmailAttachments from "./email-attachments.component";
import css from "./timeline.scss";

const EmailResponseEvent = ({ event }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const user = useSelector( getUser );
    const customer = useSelector( getSelected );

    const initialValues = useMemo( () => ({
        parentId: event.payload.id,
        subject: event.payload.subject,
        opportunityId: event.payload.opportunityId
    }), [ event ] );

    const handleReplyClick = useCallback( () => {
        const values = {
            ...initialValues,
            to: [].concat( event.payload
                .from
                .substring(
                    event.payload.from.indexOf( "<" ) + 1,
                    event.payload.from.length -
                        ( event.payload.from.indexOf( ">" ) < 0 ? 0 : 1 )
                ))
        };
        dispatch( startNewEmail( values ) );
    }, [ dispatch, event, initialValues ] );
    const handleReplyAllClick = useCallback( () => {
        const values = {
            ...initialValues,
            to: [].concat( event.payload.cc || [] )
                .concat( event.payload.from.substring(
                    event.payload.from.indexOf( "<" ) + 1,
                    event.payload.from.length -
                        ( event.payload.from.indexOf( ">" ) < 0 ? 0 : 1 )
                ) )
        };
        dispatch( startNewEmail( values ) );
    }, [ dispatch, event, initialValues ] );

    return (
        <TimelineItem icon={ <MailIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={
                    event.user ?
                        t( "customer:timeline.email-response-user" ) :
                        t( "customer:timeline.email-response-customer", {
                            customer: event.payload.customerName || customer.name
                        })
                }
            />
            <Card>
                <div className={ flex.container }>
                    <div className={ css.emailDirectionContainer }>
                        <Avatar>
                            {
                                event.payload.previous.sentBy ?
                                    <ArrowForwardIcon/> :
                                    <ArrowBackIcon/>
                            }
                        </Avatar>
                    </div>
                    <div className={ classnames( cssUtils.paddingBottomSmall, flex.fill ) }>
                        <CardHeader
                            subheader={
                                t( "customer:email.to" ) + ": " +
                                event.payload.to.toString().split( "," ).join( ", " )
                            }
                            className={ css.emailTitle }
                            titleTypographyProps={{ variant: "body2" }}
                            subheaderTypographyProps={{ variant: "body2" }}
                            title={ t( "customer:email.from" ) + ": " + event.payload.from }
                        />
                        { event.payload.cc &&
                            <CardHeader
                                className={ css.emailCc }
                                subheaderTypographyProps={{ variant: "body2" }}
                                subheader={
                                    t( "customer:email.cc" ) + ": " +
                                     event.payload.cc.toString().split( "," ).join( ", " )
                                }
                            />
                        }
                    </div>
                    {
                        user.id === event.payload.previous.sentBy &&
                            <div
                                className={
                                    classnames( cssUtils.marginRightSmall, flex.container, flex.alignItemsCenter )
                                }
                            >
                                <Tooltip title={ t( "customer:new-email.respond" ) }>
                                    <IconButton
                                        onClick={ handleReplyClick }
                                        size="large">
                                        <ReplyIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "customer:new-email.respond-all" ) }>
                                    <IconButton
                                        onClick={ handleReplyAllClick }
                                        size="large">
                                        <ReplyAllIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                    }
                </div>
                <Accordion>
                    <AccordionSummary
                        className={ classnames( flex.alignItemsCenter, flex.column ) }
                        expandIcon={ <ExpandMoreIcon/> }
                    >
                        <Typography>
                            { t( "customer:email.show-full-body" ) }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={ flex.column }>
                        <CardContent
                            className={ classnames( cssUtils.fullWidth, flex.alignItemsCenter, flex.column ) }
                        >
                            <div
                                className={ css.emailText }
                                dangerouslySetInnerHTML={{ __html: event.payload.body }}
                            />
                        </CardContent>
                        <EmailAttachments attachments={ event.payload.attachments }/>
                    </AccordionDetails>
                </Accordion>
            </Card>

            <Accordion>
                <AccordionSummary
                    className={ classnames( flex.alignItemsCenter, flex.column ) }
                    expandIcon={ <ExpandMoreIcon/> }
                >
                    <Typography>
                        { t( "customer:email.show-previous" ) }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={ flex.column }>
                    <div className={ flex.container }>
                        <div className={ css.emailDirectionContainer }>
                            <Avatar>
                                {
                                    event.payload.previous.sentBy ?
                                        <ArrowForwardIcon/> :
                                        <ArrowBackIcon/>
                                }
                            </Avatar>
                        </div>
                        <div className={ flex.fill }>
                            <CardHeader
                                className={ css.emailTitle }
                                titleTypographyProps={{ variant: "body2" }}
                                subheaderTypographyProps={{ variant: "body2" }}
                                subheader={
                                    t( "customer:email.to" ) + ": " +
                                    event.payload.previous.to.toString().split( "," ).join( ", " )
                                }
                                title={ t( "customer:email.from" ) + ": " + event.payload.previous.from }
                            />
                            { event.payload.previous.cc &&
                                <CardHeader
                                    className={ css.emailCc }
                                    subheaderTypographyProps={{ variant: "body2" }}
                                    subheader={
                                        t( "customer:email.cc" ) + ": " +
                                        event.payload.previous.cc.toString().split( "," ).join( ", " )
                                    }
                                />
                            }
                        </div>
                        <CardHeader
                            className={ css.emailTitle }
                            subheader={ dateFormat( event.payload.previous.sentAt ) }
                        />
                    </div>
                    <CardContent
                        className={ classnames( cssUtils.fullWidth, flex.alignItemsCenter, flex.column ) }
                    >
                        <p
                            className={ css.emailText }
                            dangerouslySetInnerHTML={{ __html: event.payload.previous.body }}
                        />
                    </CardContent>
                    <EmailAttachments attachments={ event.payload.attachments }/>
                </AccordionDetails>
            </Accordion>

        </TimelineItem>
    );
};

export default EmailResponseEvent;