import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { isSubmitting } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import dayjs from "dayjs";

import useTranslate from "../hooks/use-translate.hook";
import usePrevious from "../hooks/use-previous.hook";
import { EDIT_SCHEDULE_EVENT } from "../schedule";
import { actions as userActions, selectors as userSelectors } from "../user";
import { NewComment } from "../opportunity";
import { fetchSchedulingStatistics } from "./home.actions";
import { cssUtils, Dialog, flex, Link, UsersFilter } from "../ui";
import UserTodos from "./user-todos.component";
import HomeKpis from "./home-kpis.component";
import GoalProgress from "./goal-progress.component";
import PhasesFunnel from "./phases-funnel.component";
import css from "./home.scss";

const Home = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const changingEventStatus = useSelector( isSubmitting( EDIT_SCHEDULE_EVENT ) );
    const selectedEvent = useSelector( userSelectors.getEventSelected );

    const prevChangingEventStatus = usePrevious( changingEventStatus );

    const [ showConfirmationStatus, setShowConfirmationStatus ] = useState( false );
    const [ showOpportunityComment, setShowOpportunityComment ] = useState( false );
    const [ usersId, setUsersId ] = useState( null );
    const [ teamId, setTeamId ] = useState( "" );

    const toggleShowConfirmationStatus = () => setShowConfirmationStatus( !showConfirmationStatus );

    const toggleOpportunityComment = () => setShowOpportunityComment( !showOpportunityComment );

    useEffect( () => {
        return () => {
            dispatch( userActions.selectEvent( null ) );
        };
    }, [ dispatch ] );
    useEffect( () => {
        if ( prevChangingEventStatus && !changingEventStatus ) {
            if ( selectedEvent.opportunity ) {
                setShowConfirmationStatus( true );
            }
            dispatch( fetchSchedulingStatistics({ usersId: usersId.toString() }) );
        }
    }, [ dispatch, prevChangingEventStatus, changingEventStatus, selectedEvent, usersId ] );

    const endOfDay = dayjs().endOf( "day" ).toDate();

    return (
        <div className={ css.root }>
            <Helmet title={ t( "home:title" ) } />

            {
                selectedEvent && selectedEvent.opportunity &&
                    <Dialog
                        open={ showConfirmationStatus }
                        onClose={ toggleShowConfirmationStatus }
                    >
                        <DialogTitle>
                            { t( "home:opportunity-actions.title" ) }
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {
                                    t( "home:opportunity-actions.info", {
                                        opportunity: selectedEvent.opportunity.title
                                    } )
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={ flex.container }>
                            <div className={ flex.fill }>
                                <Button onClick={ toggleShowConfirmationStatus }>
                                    { t( "common:close" ) }
                                </Button>
                            </div>
                            <Link href={ "/opportunities/" + selectedEvent.opportunity.id }>
                                <Button>
                                    { t( "home:opportunity-actions.access" ) }
                                </Button>
                            </Link>
                            <Button
                                onClick={ () => {
                                    toggleShowConfirmationStatus();
                                    toggleOpportunityComment();
                                }}
                            >
                                { t( "home:opportunity-actions.comment" ) }
                            </Button>
                        </DialogActions>
                    </Dialog>
            }
            {
                showOpportunityComment && selectedEvent &&
                    <NewComment
                        opportunityId={ selectedEvent.opportunity.id }
                        onClose={ toggleOpportunityComment }
                        show
                    />
            }

            <UsersFilter
                input={{
                    team: {
                        value: teamId, onChange: setTeamId
                    },
                    users: {
                        value: usersId, onChange: setUsersId
                    }
                }}
                showInactive
                whiteSpaceNormal={ false }
                defaultActive
                className={ classnames(
                    cssUtils.marginHorizontalSmall,
                    cssUtils.marginTopSmall,
                    cssUtils.marginBottom0,
                    flex.alignItemsBaseline
                )}
            />

            <div className={ css.container }>
                {
                    loadingUsers || !usersId ?
                        <LinearProgress /> :
                        <>
                            <div className={ css.contentLeft }>
                                <UserTodos
                                    id="userTodos"
                                    className={ css.userTodos }
                                    finalDate={ endOfDay.toISOString() }
                                    selectedUsersId={ usersId }
                                />
                            </div>

                            <div className={ css.contentRight }>
                                <HomeKpis
                                    id="homeKpis"
                                    className={ css.kpis }
                                    usersId={ usersId }
                                />

                                <div className={ css.flexAndFill }>
                                    <PhasesFunnel
                                        id="phasesFunnel"
                                        className={ css.phasesFunnel }
                                        usersId={ usersId }
                                    />
                                    <GoalProgress
                                        id="goalProgress"
                                        className={ css.goalProgress }
                                        usersId={ usersId }
                                    />
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default Home;
