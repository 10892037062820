import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState, useCallback } from "react";
import { Field, FieldArray } from "formik";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../../../source";
import { deleteLeadSource } from "./lead-integration.actions";
import { Dropdown, Input, SelectFunnel, flex, cssUtils } from "../../../ui";
import SelectLeadPortal from "../../../web-capture/select-lead-portal.component";
import LeadDistribution from "./lead-source-distribution.component";
import LeadSourceField from "./lead-source-field.component";
import css from "./lead-integration.scss";

const getEmail = item => {
    const name = item.name.normalize( "NFD" ).replace( /[\u0300-\u036f]/g, "" ).replace( /[\W_]+/g, "" ).toLowerCase();
    return `leads.${name}.${item.id}@mg.leadstation.com.br`;
};

const SourceList = ({ name, form, remove }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );

    const [ showSourceFields, setShowSourceFields ] = useState();
    const [ showDistribution, setShowDistribution ] = useState();
    const [ showConfirmationDelete, setShowConfirmationDelete ] = useState( false );
    const [ index, setIndex ] = useState( null );

    const { setFieldTouched } = form;

    const toggleSourceFields = useCallback( index => setShowSourceFields( index ), [] );

    const closeSourceFields = useCallback( indexProp => {
        const errors = form.errors.leadSources && form.errors.leadSources[ indexProp ] || {};

        if ( "defaultValues" in errors && !isEmpty( errors.defaultValues.filter( value => !isEmpty( value ) ) ) ) {
            form.values.leadSources[ indexProp ].defaultValues.forEach( ( defaultValue, index ) => {
                setFieldTouched( `${name}[${indexProp}].defaultValues[${index}].defaultValue` );
                setFieldTouched( `${name}[${indexProp}].defaultValues[${index}].fieldId` );
            });
        } else {
            setShowSourceFields();
        }
    }, [ setFieldTouched, form.errors, form.values.leadSources, name ] );

    const toggleDistribution = useCallback( index => setShowDistribution( index ), [] );

    const toggleConfirmationDelete = useCallback( indexProp => {
        const newShowConfirmationDelete = !showConfirmationDelete;

        if ( newShowConfirmationDelete ) {
            if ( form.values.leadSources[ indexProp ].id ) {
                setShowConfirmationDelete( newShowConfirmationDelete );
                setIndex( indexProp );
            } else {
                remove( indexProp );
            }
        } else {
            setShowConfirmationDelete( newShowConfirmationDelete );
            setIndex( indexProp );
        }
    }, [ form.values.leadSources, showConfirmationDelete, remove ] );

    const onDelete = useCallback( () => {
        dispatch( deleteLeadSource( form.values[ name ][ index ] ) );
        toggleConfirmationDelete();
    }, [ dispatch, form.values, name, index, toggleConfirmationDelete ] );

    const copyEmail = useCallback( indexProp => {
        const email = document.getElementById( `email${indexProp}` );
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents( email );
        selection.removeAllRanges();
        selection.addRange( range );
        document.execCommand( "copy" );
        selection.removeAllRanges();
    }, [] );

    return (
        <div>
            <Dialog
                open={ showConfirmationDelete }
                onClose={ toggleConfirmationDelete }
            >
                <DialogTitle>{ t( "marketplace:integrations.lead-source.delete.title" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "marketplace:integrations.lead-source.delete.comment" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmationDelete } disabled={ form.submitting }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ onDelete } disabled={ form.submitting }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                form.values.leadSources.map( ( source, indexProp ) => {
                    const hasDistribution = !!source.distribution?.id;
                    const hasTeam = !!source.teamId;
                    const hasUsers = !isEmpty( source.usersId );

                    return (
                        <Fragment key={ indexProp }>
                            <Dialog
                                open={ indexProp === showSourceFields }
                                onClose={ () => closeSourceFields( indexProp ) }
                                fullWidth
                            >
                                <DialogTitle>
                                    { t( "marketplace:integrations.lead-source.fields.title" ) }
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        { t( "marketplace:integrations.lead-source.fields.help" ) }
                                    </DialogContentText>
                                    <FieldArray
                                        name={ `leadSources[${indexProp}].defaultValues` }
                                        component={ LeadSourceField }

                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={ () => closeSourceFields( indexProp ) }>
                                        { "OK" }
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={ indexProp === showDistribution }
                                fullWidth
                            >
                                <DialogTitle>
                                    { t( "marketplace:integrations.lead-source.distribution.title" ) }
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        { t( "marketplace:integrations.lead-source.distribution.help" ) }
                                    </DialogContentText>
                                    <LeadDistribution
                                        name={ `leadSources[${indexProp}]` }
                                        form={ form }
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={ () => toggleDistribution() }>{ "OK" }</Button>
                                </DialogActions>
                            </Dialog>
                            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                <Field
                                    type="text"
                                    label={ t( "marketplace:integrations.lead-source.name" ) }
                                    name={ `leadSources[${indexProp}].name` }
                                    className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                    component={ Input }
                                    required
                                />
                                <Field
                                    name={ `leadSources[${indexProp}].funnelId` }
                                    component={ SelectFunnel }
                                    className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                    fullWidth={ false }
                                    required
                                />
                                <Field
                                    label={ t( "marketplace:integrations.lead-source.source" ) }
                                    name={ `leadSources[${indexProp}].sourceId` }
                                    className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                    component={ Dropdown }
                                    source={
                                        sources?.map( source => ({ value: source.id, label: source.name }) )
                                    }
                                    required
                                />
                                <Field
                                    label={ t( "marketplace:integrations.lead-source.portal" ) }
                                    name={ `leadSources[${indexProp}].portal` }
                                    className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                                    component={ SelectLeadPortal }
                                    emptyValue
                                />
                                <div className={ classnames( flex.fill, css.leadSourceEmailContainer ) }>
                                    <span id={ `email${indexProp}` } className={ css.leadSourceEmail } >
                                        {
                                            source.id ?
                                                getEmail( source ) :
                                                <i>{ t( "marketplace:integrations.lead-source.pending" ) }</i>
                                        }
                                    </span>
                                    {
                                        source.id ?
                                            <Tooltip title={ t( "marketplace:integrations.lead-source.copy-email" ) }>
                                                <IconButton onClick={ () => copyEmail( indexProp ) } size="large">
                                                    <FileCopyIcon/>
                                                </IconButton>
                                            </Tooltip> :
                                            null
                                    }
                                </div>
                                <Tooltip title={ t( "marketplace:integrations.lead-source.fields.title" ) }>
                                    {
                                        !isEmpty( source.defaultValues ) ?
                                            <IconButton
                                                onClick={ () => toggleSourceFields( indexProp ) }
                                                size="large"
                                            >
                                                <Badge
                                                    badgeContent={ source.defaultValues.length }
                                                    color="primary"
                                                >
                                                    <FormatAlignJustifyIcon/>
                                                </Badge>
                                            </IconButton> :
                                            <IconButton
                                                onClick={ () => toggleSourceFields( indexProp ) }
                                                size="large"
                                            >
                                                <FormatAlignJustifyIcon/>
                                            </IconButton>
                                    }
                                </Tooltip>
                                <Tooltip title={ t( "marketplace:integrations.lead-source.distribution.title" ) }>
                                    <IconButton onClick={ () => toggleDistribution( indexProp ) } size="large">
                                        <Badge
                                            invisible={ !hasDistribution && !hasTeam && !hasUsers }
                                            color="secondary"
                                            variant="dot"
                                        >
                                            <GroupIcon/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ t( "marketplace:integrations.lead-source.delete.title" ) }>
                                    <IconButton
                                        onClick={ () => toggleConfirmationDelete( indexProp ) }
                                        size="large"
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Fragment>
                    );
                })
            }
        </div>
    );
};

export default SourceList;