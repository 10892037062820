import TextFormIcon from "@mui/icons-material/TextFormat";
import TodayIcon from "@mui/icons-material/Today";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const fieldIcons = {
    TEXT: TextFormIcon,
    DATE: TodayIcon,
    EMAIL: EmailIcon,
    PHONE: PhoneIcon,
    NUMBER: LooksOneIcon,
    ADDRESS: PlaceIcon,
    SELECT: ArrowDropDownCircleIcon,
    SELECT_MULTIPLE: ArrowDropDownCircleIcon,
    PRICE: AttachMoneyIcon
};