import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./source.actions";
import * as selectors from "./source.selectors";

export function *watchSources () {
    yield all([
        takeEvery( actions.FETCH_SOURCES, fetchSources )
    ]);
}

export function *fetchSources () {
    if ( yield select( selectors.getSources ) ) {
        return;
    }

    yield put( actions.requestSources() );

    try {
        const response = yield call( apiv1.get, "/sources" );
        yield put( actions.receiveSources( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSources( e.response.data ) );
    }
}