import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

const schedulingStatisticsBase = state => state.home.schedulingStatistics;
export const getSchedulingStatistics = requestSelectors.getData( schedulingStatisticsBase );
export const isLoadingSchedulingStatistics = requestSelectors.isLoading( schedulingStatisticsBase );

const schedulesBase = state => state.home.schedules;
export const getSchedules = createSelector( schedulesBase, scheduling => scheduling.data || [] );
export const getSchedulesTotalPages = createSelector( schedulesBase, scheduling => scheduling.totalPages );
export const isLoadingSchedules = createSelector( schedulesBase, schedules => schedules.loading );