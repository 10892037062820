import { Fragment, useEffect } from "react";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import classnames from "classnames";
import get from "lodash/get";
import set from "lodash/set";

import useTranslate from "../hooks/use-translate.hook";
import { fetchFormFilters } from "./form.actions";
import { getPreferences } from "../core/core.selectors";
import { USER_FILTERS } from "../user/user.constants";
import { actions as userActions } from "../user";
import { getTags } from "../customer/customer.selectors";
import { fetchTags } from "../customer/customer.actions";
import css from "./filters-fields.scss";
import {
    Autocomplete,
    DatePicker,
    Dropdown,
    Outlined,
    UsersFilter,
    cssUtils,
    flex
} from "../ui";
import FilterHasOpenScheduling from "./filter-has-open-scheduling.component";
import FilterField from "./filter-field.component";
import FilterImportation from "./filter-importation.component";
import FilterProductCategories from "./filter-product-categories.component";
import FilterProducts from "./filter-products.component";

const validateFilters = ( filters, formName, key, errors ) => {
    filters.forEach( ( filter, index ) => {
        const baseProp = `${formName}.${key}[${index}]`;
        if ( !get( filter, "fieldId" ) ) {
            set( errors, baseProp + ".fieldId", "common:validation.selection" );
        }
        if ( !get( filter, "operator" ) ) {
            set( errors, baseProp + ".operator", "common:validation.selection" );
        }
        if ( !( get( filter, "operator" ) || "" ).match( "NULL|NOT_NULL" ) && !get( filter, "value" ) ) {
            set( errors, baseProp + ".value", "common:validation.required" );
        }
        if ( ( get( filter, "operator" ) || "" ).match( "BETWEEN" ) ) {
            if ( !get( filter, "value.initial" ) ) {
                set( errors, baseProp + ".value.initial", "common:validation.required" );
            }
            if ( !get( filter, "value.final" ) ) {
                set( errors, baseProp + ".value.final", "common:validation.required" );
            }
        }
    });
};

const FiltersFields = ({ formName, onClose, show, showSoldAt }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const preferences = useSelector( getPreferences );
    const tags = useSelector( getTags );

    const formik = useForm({
        form: USER_FILTERS,
        enableReinitialize: true,
        initialValues: {
            ...preferences,
            opportunity: {
                ...preferences?.opportunity,
                productCategories: ( preferences?.opportunity?.productCategories || [] )
                    .map( productCategory => productCategory.id ),
            },
        },
        onSubmit: values => dispatch( userActions.saveUserFilters(
            {
                ...values,
                formName,
                opportunity: {
                    ...values.opportunity,
                    productCategories: values.opportunity.productCategories ?
                        values.opportunity.productCategories.map( categoryId => ( { id: categoryId } ) ) :
                        null,
                },
            }
        )),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            validateFilters( get( values, `${formName}.filters`, [] ), formName, "filters", errors );
            validateFilters( get( values, `${formName}.companyFilters`, [] ), formName, "companyFilters", errors );
            validateFilters( get( values, `${formName}.personFilters`, [] ), formName, "personFilters", errors );
            return errors;
        }
    });

    const searchTags = tag => {
        if ( !tag ) {
            return;
        }
        dispatch( fetchTags( tag ) );
    };

    const handleChangeTeam = teamId => {
        if ( teamId ) {
            formik.setFieldValue( "opportunity.team.id", teamId );
        } else {
            formik.setFieldValue( "opportunity.team", null );
        }
    };

    const handleChangeUsers = value => formik.setFieldValue( "opportunity.users", value );

    const cleanFields = () => {
        formik.setFieldValue( `${formName}.filters`, [] );
        formik.setFieldValue( `${formName}.createdAt`, null );
        formik.setFieldValue( `${formName}.soldAt`, null );
        formik.setFieldValue( `${formName}.team`, null );
        formik.setFieldValue( `${formName}.users`, [] );
        formik.setFieldValue( `${formName}.products`, [] );
        formik.setFieldValue( `${formName}.productCategories`, [] );
        formik.setFieldValue( `${formName}.schedulingDate`, null );
        formik.setFieldValue( `${formName}.tags`, [] );
        formik.setFieldValue( `${formName}.temperature`, null );
        formik.setFieldValue( `${formName}.hasOpenScheduling`, null );
        formik.setFieldValue( `${formName}.importation`, null );
        if ( formName === "opportunity" ) {
            formik.setFieldValue( `${formName}.companyTags`, [] );
            formik.setFieldValue( `${formName}.personTags`, [] );
            formik.setFieldValue( `${formName}.companyFilters`, [] );
            formik.setFieldValue( `${formName}.personFilters`, [] );
        }
    };

    useEffect( () => {
        formName && dispatch( fetchFormFilters( formName ) );
        if ( formName === "opportunity" ) {
            dispatch( fetchFormFilters( "person" ) );
            dispatch( fetchFormFilters( "company" ) );
        }
    }, [ dispatch, formName ] );

    const selectedTags = get( formik.values, `${formName}.tags`, [] );
    const selectedCompanyTags = get( formik.values, "opportunity.companyTags", [] );
    const selectedPersonTags = get( formik.values, "opportunity.personTags", [] );

    const sourceTags = [ ...new Set( tags, selectedTags ) ];

    return (
        <Dialog
            open={ show }
            onClose={ onClose }
            fullWidth
            maxWidth="md"
            className={ css.dialog }
        >
            <DialogTitle>{ t( "form:user-filters.title" ) }</DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    {
                        formName === "opportunity" ?
                            <div>
                                <UsersFilter
                                    input={{
                                        team: {
                                            onChange: handleChangeTeam,
                                            value: get( formik.values, "opportunity.team.id" )
                                        },
                                        users: {
                                            onChange: handleChangeUsers,
                                            value: get( formik.values, "opportunity.users" )
                                        }
                                    }}
                                    ComponentRoot={ Fragment }
                                    variant="outlined"
                                    fullWidth
                                    showInactive
                                />
                                <FilterProductCategories />
                                <FilterProducts initialValues={ formik.initialValues } />
                                <Outlined label={ t( "form:user-filters.scheduling-date.label" ) }>
                                    <div className={ flex.container }>
                                        <div className={ flex.fill }>
                                            <Field
                                                name={ `opportunity.schedulingDate.initial`}
                                                label={ t( "form:user-filters.scheduling-date.initial" ) }
                                                startOfDay
                                                component={ DatePicker }
                                            />
                                        </div>
                                        <div className={ classnames( flex.fill, cssUtils.marginLeft ) }>
                                            <Field
                                                name={ `opportunity.schedulingDate.final`}
                                                label={ t( "form:user-filters.scheduling-date.final" ) }
                                                endOfDay
                                                component={ DatePicker }
                                            />
                                        </div>
                                    </div>
                                </Outlined>
                                <Field
                                    name={ "opportunity.companyTags" }
                                    label={ t( "form:user-filters.opportunity.company-tags" ) }
                                    component={ Autocomplete }
                                    source={ [ ...new Set( tags, selectedCompanyTags ) ] }
                                    onQueryChange={ searchTags }
                                    normalize={ values => values && values.map( value => value.toLowerCase() ) }
                                    variant="outlined"
                                />
                                <Field
                                    name={ "opportunity.personTags" }
                                    label={ t( "form:user-filters.opportunity.person-tags" ) }
                                    component={ Autocomplete }
                                    source={ [ ...new Set( tags, selectedPersonTags ) ] }
                                    onQueryChange={ searchTags }
                                    normalize={ values => values && values.map( value => value.toLowerCase() ) }
                                    variant="outlined"
                                />
                            </div> :
                            <div>
                                <Field
                                    name={ `${formName}.tags` }
                                    label={ t( "form:user-filters.customer.tags" ) }
                                    component={ Autocomplete }
                                    source={ sourceTags }
                                    onQueryChange={ searchTags }
                                    normalize={ values => values && values.map( value => value.toLowerCase() ) }
                                    variant="outlined"
                                />
                            </div>
                    }

                    <FilterImportation
                        name={ `${formName}.importation` }
                        form={ formik }
                    />

                    <Outlined label={ t( "form:user-filters.created-at.label" ) }>
                        <div className={ flex.container }>
                            <div className={ flex.fill }>
                                <Field
                                    name={ `${formName}.createdAt.initial`}
                                    label={ t( "form:user-filters.created-at.initial" ) }
                                    startOfDay
                                    maxDate={
                                        get( formik.values, `${formName}.createdAt.final` ) || undefined
                                    }
                                    component={ DatePicker }
                                />
                            </div>
                            <div className={ classnames( flex.fill, cssUtils.marginLeft ) }>
                                <Field
                                    name={ `${formName}.createdAt.final`}
                                    label={ t( "form:user-filters.created-at.final" ) }
                                    endOfDay
                                    minDate={
                                        get( formik.values, `${formName}.createdAt.initial` ) || undefined
                                    }
                                    component={ DatePicker }
                                />
                            </div>
                        </div>
                    </Outlined>
                    {
                        formName === "opportunity" &&
                        <>
                            {
                                showSoldAt &&
                                    <Outlined label={ t( "form:user-filters.sold-at.label" ) }>
                                        <div className={ flex.container }>
                                            <div className={ flex.fill }>
                                                <Field
                                                    name={ `${formName}.soldAt.initial`}
                                                    label={ t( "form:user-filters.sold-at.initial" ) }
                                                    startOfDay
                                                    maxDate={
                                                        get( formik.values, `${formName}.soldAt.final` ) || undefined
                                                    }
                                                    component={ DatePicker }
                                                />
                                            </div>
                                            <div className={ classnames( flex.fill, cssUtils.marginLeft ) }>
                                                <Field
                                                    name={ `${formName}.soldAt.final`}
                                                    label={ t( "form:user-filters.sold-at.final" ) }
                                                    endOfDay
                                                    minDate={
                                                        get( formik.values, `${formName}.soldAt.initial` ) || undefined
                                                    }
                                                    component={ DatePicker }
                                                />
                                            </div>
                                        </div>
                                    </Outlined>
                            }
                            <Field
                                name="opportunity.temperature"
                                label={ t( "form:user-filters.opportunity.temperature" ) }
                                source={ [ "COLD", "WARM", "HOT" ]
                                    .map(temperature => ({
                                        value: temperature,
                                        label: t( `opportunity:temperatures.${temperature}` )
                                    }))}
                                component={ Dropdown }
                                variant="outlined"
                                emptyValue
                                sort={ false }
                            />
                            <Field
                                name="opportunity.hasOpenScheduling"
                                label={ t( "form:user-filters.opportunity.has-open-scheduling" ) }
                                component={ FilterHasOpenScheduling }
                            />
                            <FieldArray
                                name={ `${formName}.companyFilters` }
                                render={ helpers => (
                                    <FilterField
                                        { ...helpers }
                                        formType="company"
                                        label={ t( "form:user-filters.company-fields" ) }
                                    />
                                )}
                            />
                            <FieldArray
                                name={ `${formName}.personFilters` }
                                render={ helpers => (
                                    <FilterField
                                        { ...helpers }
                                        formType="person"
                                        label={ t( "form:user-filters.person-fields" ) }
                                    />
                                )}
                            />
                        </>
                    }
                    <FieldArray
                        name={ `${formName}.filters` }
                        render={ helpers => (
                            <FilterField
                                { ...helpers }
                                formType={ formName }
                                label={ t( "form:user-filters.other-fields" ) }
                            />
                        )}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <div className={ flex.fill }>
                    <Button color="secondary" onClick={ cleanFields } disabled={ formik.submitting }>
                        { t( "common:clean" ) }
                    </Button>
                </div>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FiltersFields;