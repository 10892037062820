import { useEffect, useMemo, useState } from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Checkbox, ColorPicker, Input, cssUtils, flex } from "../../../ui";
import { actions as funnelActions } from "../../../funnel";
import { actions as phaseActions } from "../../../phase";
import { deleteSchedulingType } from "./schedules-type.actions";
import PhaseSelector from "../funnel/phase-selector.component";
import css from "./schedules-type.scss";

const SchedulingTypeList = ({ form, remove }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showConfirmationDelete, setShowConfirmationDelete ] = useState( false );
    const [ showDeleteForbiddenDialog, setShowDeleteForbiddenDialog ] = useState( false );
    const [ index, setIndex ] = useState( null );

    const values = useMemo( () => form.values.schedulingTypes, [ form.values.schedulingTypes ] );

    const toggleConfirmationDelete = index => {
        if ( values.length === 1 ) {
            toggleDeleteForbiddenDialog();
            return;
        }
        setShowConfirmationDelete( !showConfirmationDelete );
        setIndex( index );
    };

    const toggleDeleteForbiddenDialog = () => setShowDeleteForbiddenDialog( !showDeleteForbiddenDialog );

    const handleDelete = () => {
        if ( !values[ index ].id ) {
            remove( index );
        } else {
            dispatch( deleteSchedulingType( values[ index ] ) );
        }
        toggleConfirmationDelete();
    };

    useEffect( () => {
        dispatch( funnelActions.fetchFunnels() );
        dispatch( phaseActions.fetchPhases() );
    }, [ dispatch ] );

    return (
        <div>
            <Dialog
                open={ showConfirmationDelete }
                onClose={ toggleConfirmationDelete }
            >
                <DialogTitle>
                    { t( "config:opportunity-scheduling-types.delete.title" ) }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "config:opportunity-scheduling-types.delete.comment" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmationDelete }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ showDeleteForbiddenDialog }
                onClose={ toggleDeleteForbiddenDialog }
            >
                <DialogContent>
                    <DialogContentText>
                        { t( "config:opportunity-scheduling-types.delete.forbidden" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleDeleteForbiddenDialog }>
                        { t( "common:ok" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                values.map( ( schedulingType, index ) => (
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) } key={ index }>
                        <Field
                            type="text"
                            label={ t( "config:opportunity-scheduling-types.item.name" ) }
                            name={ `schedulingTypes[${index}].name` }
                            className={ flex.fill }
                            component={ Input }
                            fullWidth={ false }
                            size="small"
                            required
                        />
                        <div
                            className={
                                classnames( flex.fill, flex.container, flex.alignItemsCenter, cssUtils.marginLeft )
                            }
                        >
                            <Field
                                label={ t( "config:opportunity-scheduling-types.item.phase.label" ) }
                                name={ `schedulingTypes[${index}].phaseId` }
                                className={ flex.fill }
                                component={ PhaseSelector }
                            />
                            <Tooltip title={ t( "config:opportunity-scheduling-types.item.phase.help" ) }>
                                <HelpIcon className={ css.helpIcon }/>
                            </Tooltip>
                        </div>
                        <div
                            className={
                                classnames( flex.container, flex.alignItemsCenter, cssUtils.marginLeftLarge )
                            }
                        >
                            <Field
                                type="checkbox"
                                label={ t( "config:opportunity-scheduling-types.item.dashboard.label" ) }
                                name={ `schedulingTypes[${index}].dashboard` }
                                fullWidth={ false }
                                component={ Checkbox }
                            />
                            <Tooltip title={ t( "config:opportunity-scheduling-types.item.dashboard.help" ) }>
                                <HelpIcon className={ css.helpIcon }/>
                            </Tooltip>
                        </div>
                        <Field
                            type="text"
                            label=""
                            name={ `schedulingTypes[${index}].color` }
                            className={ cssUtils.marginLeftLarge }
                            defaultColor="#2196f3"
                            component={ ColorPicker }
                        />
                        <Tooltip title={ t( "common:exclude" ) }>
                            <IconButton onClick={ () => toggleConfirmationDelete( index ) } size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                ))
            }
        </div>
    );
};


export default SchedulingTypeList;