import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Dropdown } from "../ui";
import { isLoadingLeadPortals, getLeadPortals } from "./web-capture.selectors";

const SelectLeadPortal = props => {
    const loading = useSelector( isLoadingLeadPortals );
    const leadPortals = useSelector( getLeadPortals );

    const source = useMemo(
        () => leadPortals?.map( leadPortal => ({ value: leadPortal.portal, label: leadPortal.name }) ) || [],
        [ leadPortals ]
    );

    return (
        <Dropdown
            disabled={ loading }
            source={ source }
            emptyValue
            { ...props }
        />
    );
};

export default SelectLeadPortal;