import { all, call, put, takeEvery } from "redux-saga/effects";

import api from "../../api/app";
import * as actions from "./mailtop.actions";

function *fetchToken () {
    yield put( actions.requestToken() );
    try {
        const response = yield call( createFetch( "/mailtop/integration" ) );
        yield put( actions.setToken( response ) );
    } catch ( e ) {
        yield put( actions.errorToken( e.response ) );
    }
}

function *fetchLists () {
    yield put( actions.requestLists() );
    try {
        const response = yield call( createFetch( "/mailtop/lists" ) );
        yield put( actions.receiveLists( response ) );
    } catch ( e ) {
        yield put( actions.errorLists( e ) );
    }
}

function *fetchRejections ( action ) {
    yield put( actions.requestRejections( action.meta.index, action.payload ));
    try {
        const response = yield call( createFetch( "/mailtop/rejections", { params: { email: action.payload } }) );
        yield put( actions.receiveRejections( action.meta.index, response) );
    } catch ( e ) {
        yield put( actions.errorRejections( action.meta.index, e.response ) );
    }
}

export function *watchMailtop () {
    yield all([
        takeEvery( actions.FETCH_TOKEN, fetchToken ),
        takeEvery( actions.FETCH_REJECTIONS, fetchRejections ),
        takeEvery( actions.FETCH_LISTS, fetchLists ),
    ]);
}

const createFetch = url => () => {
    return api.get( url ).then(
        response => response.data,
        error => Promise.reject( error.response.data )
    );
};