import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DeleteItem } from "../../../ui";
import { deleteFacebookLeadPage } from "./facebook-integration.actions";
import { createDeleteFacebookLeadPageForm } from "./facebook-integration.utils";

const DeleteFacebookLeadPage = ({ facebookLeadPage }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: createDeleteFacebookLeadPageForm( facebookLeadPage.id ),
        initialValues: {
            id: facebookLeadPage.id,
        },
        onSubmit: values => dispatch( deleteFacebookLeadPage( values.id ) ),
    });

    return (
        <DeleteItem
            message={ t( "marketplace:integrations.facebook.lead-pages.confirm-delete" ) }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteFacebookLeadPage;