import { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import classnames from "classnames";

import css from "./outlined.scss";

// eslint-disable-next-line no-unused-vars
const InputComponent = forwardRef(({ inputRef, ...other }, ref ) => <div ref={ ref } { ...other } /> );

const OutlinedDiv = ({
    children,
    className,
    classes = {},
    error,
    helperText,
    label,
    required,
    shrink = true,
    ...props
}) => (
    <TextField
        className={ classnames( css.root, className ) }
        variant="outlined"
        error={ error }
        helperText={ helperText }
        label={ label }
        margin="dense"
        multiline
        InputLabelProps={{ shrink }}
        InputProps={{
            inputComponent: InputComponent,
            classes: {
                multiline: css.input,
                ...classes
            }
        }}
        inputProps={{ children: children }}
        required={ required }
        { ...props }
    />
);

export default OutlinedDiv;