import * as actions from "./customer.actions";
import * as sagas from "./customer.sagas";
import * as selectors from "./customer.selectors";
import * as utils from "./customer.utils";

export * from "./customer.constants";
export { default as CustomerList } from "./customer-list.component";
export { default as NewCustomer } from "./new-customer.component";
export { default as CustomerForm } from "./customer-form.component";
export { default as Customer } from "./customer.component";
export { default as CustomerHead } from "./customer-head.component";
export { default as NewEmail } from "./new-email.component";
export { default as NewPhoneCall } from "./new-phone-call.component";
export { default as NewSms } from "./new-sms.component";
export { default as NewWhatsApp } from "./new-whatsapp.component";

export { actions, sagas, selectors, utils };