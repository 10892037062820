import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

const facebookLeadDistributionBase = state =>
    state.marketplace.integrations.facebookIntegration.facebookLeadDistribution;
export const getFacebookLeadDistribution = requestSelectors.getData( facebookLeadDistributionBase );
export const isFacebookLoadingLeadDistribution = requestSelectors.isLoading( facebookLeadDistributionBase );

const facebookLeadPagesBase = state =>
    state.marketplace.integrations.facebookIntegration.facebookLeadPages;
export const getFacebookLeadPages = createSelector(
    facebookLeadPagesBase,
    facebookLeadPages => facebookLeadPages.data || []
);
export const isFacebookLoadingLeadPages = requestSelectors.isLoading( facebookLeadPagesBase );

const facebookAuthBase = state =>
    state.marketplace.integrations.facebookIntegration.facebookAuth;
export const getFacebookAuth = requestSelectors.getData( facebookAuthBase );
export const isLoadingFacebookAuth = requestSelectors.isLoading( facebookAuthBase );

const facebookAccountPagesBase = state => state.marketplace.integrations.facebookIntegration.facebookAccountPages;
export const getFacebookAccountPages = requestSelectors.getData( facebookAccountPagesBase );
export const isLoadingFacebookAccountPages = requestSelectors.isLoading( facebookAccountPagesBase );

const facebookPageLeadgenFormsBase = state =>
    state.marketplace.integrations.facebookIntegration.facebookPageLeadgenForms;
export const getFacebookPageLeadgenForms = requestSelectors.getData( facebookPageLeadgenFormsBase );
export const isLoadingFacebookPageLeadgenForms = requestSelectors.isLoading( facebookPageLeadgenFormsBase );

const facebookPageBase = state => state.marketplace.integrations.facebookIntegration.facebookPage;
export const getFacebookPage = requestSelectors.getData( facebookPageBase );
export const isLoadingFacebookPage = requestSelectors.isLoading( facebookPageBase );