import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import config from "config";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { keycodes } from "../util";
import { formatBytes } from "../core/formatter.utils";
import { isEmail } from "../core/validator.utils";
import { getUser } from "../core/core.selectors";
import { selectors as formSelectors } from "../form";
import { selectors as opportunitySelectors } from "../opportunity";
import { fetchFiles as fecthOpportunityFiles, fetchProposals } from "../opportunity/opportunity.actions";
import {
    actions as smtpServerActions,
    selectors as smtpServerSelectors,
} from "../smtp-server";
import { getFiles, getSelected, getNewEmailValues, isLoadingSelected } from "./customer.selectors";
import { saveNewEmail, closeNewEmail, fetchFiles } from "./customer.actions";
import { NEW_EMAIL_FORM } from "./customer.constants";
import { Autocomplete, Input, Dialog, TemplateEditor, flex, Dropdown, cssUtils } from "../ui";
import history from "../../history";

const NewEmail = ({ show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );
    const opportunity = useSelector( opportunitySelectors.getSelected );
    const companyFields = useSelector( formSelectors.listEmailFields( "COMPANY" ) );
    const personFields = useSelector( formSelectors.listEmailFields( "PERSON" ) );
    const persons = useSelector( opportunitySelectors.getPersons );
    const user = useSelector( getUser );
    const customerFiles = useSelector( getFiles );
    const opportunityFiles = useSelector( opportunitySelectors.getFiles );
    const proposals = useSelector( opportunitySelectors.getNotDeclinedProposals );
    const smtpServer = useSelector( smtpServerSelectors.getSettings );
    const loadingSmptServer = useSelector( smtpServerSelectors.isLoadingSettings );
    const loadingOpportunity = useSelector( opportunitySelectors.isLoadingSelected );
    const loadingOpportunityPersons = useSelector( opportunitySelectors.isLoadingPersons );
    const loadingCustomer = useSelector( isLoadingSelected );
    const newEmailValues = useSelector( getNewEmailValues );
    const files = opportunity ? opportunityFiles : customerFiles;
    const loading = loadingSmptServer || loadingOpportunity || loadingOpportunityPersons || loadingCustomer;

    const [ openWarningEmptyFiles, setOpenWarningEmptyFiles ] = useState( false );
    const [ openWarningEmptyProposals, setOpenWarningEmptyProposals ] = useState( false );
    const [ listFilesAnchor, setListFilesAnchor ] = useState( null );
    const [ listProposalsAnchor, setListProposalsAnchor ] = useState( null );
    const [ loadingFile, setLoadingFile ] = useState( false );

    const attachmentsList = useRef();
    const inputAttachments = useRef();

    const initialValues = useMemo( () => ({
        customerId: customer?.id,
        opportunityId: opportunity?.id,
        parentId: get( newEmailValues, "parentId" ),
        to: get( newEmailValues, "to", [] ),
        cc: [],
        subject: get( newEmailValues, "subject" ),
        body: "",
        attachments: [],
    }), [ customer, newEmailValues, opportunity ] );

    const formik = useForm({
        form: NEW_EMAIL_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewEmail( values ) ),
        validate: values => {
            const errors = {};
            if ( isEmpty( values.to ) ) {
                errors.to = "common:validation.required";
            }
            values.cc && values.cc.map( cc => {
                if ( !isEmail( cc ) ) {
                    errors.cc = "common:validation.email";
                }
            });
            if ( !values.subject ) {
                errors.subject = "common:validation.required";
            }
            if ( !values.body ) {
                errors.body = "common:validation.required";
            }
            return errors;
        },
    });

    const prevShow = usePrevious( show );
    const prevCustomerId = usePrevious( formik.values.customerId );

    const customers = useMemo( () => {
        if ( !opportunity ) {
            return [ customer ];
        }
        const customers = [];
        if ( opportunity.company ) {
            customers.push( opportunity.company );
        }
        if ( opportunity.person ) {
            customers.push( opportunity.person );
        }
        if ( persons ) {
            persons.forEach( customer => customers.push( customer.person ) );
        }
        return customers;
    }, [ customer, opportunity, persons ] );

    const selectedCustomer = useMemo( () => {
        return formik.values.customerId && customers.find( customer => customer.id === formik.values.customerId );
    }, [ customers, formik.values ] );

    const handleClose = () => {
        dispatch( closeNewEmail() );
        formik.resetForm();
    };

    const openAttachmentSearch = () => inputAttachments.current.click();

    const addAttachments = event => {
        const files = event.target.files;
        if ( !files ) {
            return;
        }

        const newAttachments = ( formik.values.attachments || [] ).slice();

        const totalFiles = files.length + newAttachments.length;
        const totalPreviousFiles = newAttachments.length;

        for ( let i = newAttachments.length; i < totalFiles; i++ ) {
            const file = files[ i - totalPreviousFiles ];
            if ( file.size > 15728640 ) {
                continue;
            }

            newAttachments.push( file );
        }
        formik.setFieldValue( "attachments", newAttachments );

        setTimeout( () => {
            attachmentsList.current.scrollIntoView({ behavior: "smooth" });
        }, 200 );
    };

    const removeAttachment = index => {
        const newAttachments = ( formik.values.attachments || [] ).slice();
        newAttachments.splice( index, 1 );
        formik.setFieldValue( "attachments", newAttachments );
    };

    const openFiles = event => {
        if ( isEmpty( files ) ) {
            setOpenWarningEmptyFiles( true );
        } else {
            setListFilesAnchor( event.currentTarget );
        }
    };

    const closeWarningEmptyFiles = () => setOpenWarningEmptyFiles( false );

    const closeFiles = () => setListFilesAnchor( null );

    const attachFile = async ( name, url ) => {
        const response = await fetch( url );

        const reader = response.body.getReader();

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        const chunks = []; // array of received binary chunks (comprises the body)
        while ( true ) {
            const { done, value } = await reader.read();

            if ( done ) {
                break;
            }

            chunks.push( value );
            receivedLength += value.length;
        }

        // Step 4: concatenate chunks into single Uint8Array
        const chunksAll = new Uint8Array( receivedLength ); // (4.1)
        let position = 0;
        for ( const chunk of chunks ) {
            chunksAll.set( chunk, position ); // (4.2)
            position += chunk.length;
        }

        // Step 5: decode into a string
        //const result = new TextDecoder("utf-8").decode(chunksAll);
        const arrayBuffer = chunksAll.buffer;
        const blob = new Blob([ arrayBuffer ]);
        const files = [ new File([ blob ], name ) ];
        const event = { target: { files } };
        addAttachments( event );
    };

    const attachEntityFile = file => async () => {
        setLoadingFile( true );
        closeFiles();
        const url = opportunity && opportunity.id ?
            `/api/v1/opportunities/files/${file.id}` :
            `/api/v1/customers/files/${file.id}`;
        await attachFile( file.name, url );
        setLoadingFile( false );
    };

    const openProposals = event => {
        if ( isEmpty( proposals ) ) {
            setOpenWarningEmptyProposals( true );
        } else {
            setListProposalsAnchor( event.currentTarget );
        }
    };
    const closeProposals = () => setListProposalsAnchor( null );

    const closeWarningEmptyProposals = () => setOpenWarningEmptyProposals( false );

    const attachProposalFile = proposal => async () => {
        setLoadingFile( true );
        closeProposals();
        const url = `/api/v1/proposals/${proposal.id}/download`;
        await attachFile( `${proposal.name}.pdf`, url );
        setLoadingFile( false );
    };

    const emails = useMemo( () => {
        const customer = selectedCustomer;
        if ( !customer ) {
            return [];
        }

        const emails = [];
        if ( customer.type === "COMPANY" ) {
            companyFields.forEach( field => {
                const value = customer.fields[ field.id ];
                if ( value ) {
                    value.forEach( email => emails.push( email ) );
                }
            });
        } else {
            personFields.forEach( field => {
                const value = customer.fields[ field.id ];
                if ( value ) {
                    value.forEach( email => emails.push( email ) );
                }
            });
        }
        return emails;
    }, [ companyFields, personFields, selectedCustomer ] );

    const fromData = useMemo( () => {
        if ( !isEmpty( smtpServer ) ) {
            return smtpServer.googleAccount ?
                `${smtpServer.googleAccount.data.name} <${smtpServer.googleAccount.data.email}>` :
                `${user.name} <${smtpServer.email}>`;
        }

        const domain = config.get( "client.mail.domain" );
        return `${user.name} <${user.email.substring( 0, user.email.indexOf( "@" ) )}@${domain}>`;

    }, [ smtpServer, user.email, user.name ] );

    const handleEditMail = () => {
        handleClose();
        history.push({
            pathname: "/profile",
            state: { tabIndex: 1 },
        });
    };

    useEffect( () => {
        dispatch( smtpServerActions.fetchSmtpServer() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( show && !prevShow ) {
            if ( opportunity?.id ) {
                dispatch( fecthOpportunityFiles( opportunity.id ) );
                dispatch( fetchProposals( opportunity.id ) );
            } else {
                dispatch( fetchFiles( customer.id ) );
            }
        }
    }, [ dispatch, prevShow, show, opportunity, customer ] );
    useEffect( () => {
        if ( show ) {
            if ( formik.values.customerId !== prevCustomerId ) {
                formik.setFieldValue( "to", [] );
            }
        }
    }, [ show, formik, prevCustomerId ] );

    if ( ( !customer && !opportunity ) || loading ) {
        return null;
    }

    return (
        <Dialog open={ !!show } fullWidth maxWidth="lg">
            <DialogTitle>{ t( "customer:new-email.title" ) }</DialogTitle>
            <DialogContent>

                <Typography>
                    { t( "customer:email.from" ) + ": " + fromData }
                    <IconButton
                        className={ cssUtils.marginLeftSmall }
                        onClick={ handleEditMail }
                        size="small"
                    >
                        <EditIcon />
                    </IconButton>
                </Typography>

                <Form formik={ formik }>
                    { opportunity &&
                            <>
                                <Field
                                    name="customerId"
                                    label={ t( "customer:contacts.customer" ) }
                                    component={ Dropdown }
                                    source={ customers
                                        .map( customer => ( {
                                            value: customer.id,
                                            label: customer.name } )
                                        )
                                    }
                                />
                                { isEmpty( emails ) && !isEmpty( selectedCustomer ) &&
                                    <DialogContentText color="error">
                                        { t( "customer:contacts.empty-emails" ) }
                                    </DialogContentText>
                                }
                            </>
                    }
                    <Field
                        name="to"
                        label={ t( "customer:email.to" ) }
                        component={ Autocomplete }
                        source={ emails }
                        disabled={ !!newEmailValues || isEmpty( emails ) }
                        required
                    />
                    <Field
                        name="cc"
                        label={ t( "customer:email.cc" ) }
                        component={ Autocomplete }
                        type="email"
                        splitKeyCodes={[ keycodes.COMMA, keycodes.SEMICOLON ]}
                        allowCreate
                    />
                    <Field
                        name="subject"
                        label={ t( "customer:email.subject" ) }
                        type="text"
                        component={ Input }
                        disabled={ !!newEmailValues }
                        required
                    />

                    <Divider className={ cssUtils.marginTopSmall } />

                    <Field
                        name="body"
                        label={ t( "customer:email.body" ) }
                        type="text"
                        templateType="EMAIL"
                        customer={ selectedCustomer }
                        opportunity={ opportunity }
                        height="400"
                        showVariables={ false }
                        showTemplates
                        multiline
                        required
                        component={ TemplateEditor }
                    />

                    <input
                        type="file"
                        name="addAttachments"
                        multiple="multiple"
                        style={{ display: "none" }}
                        ref={ inputAttachments }
                        onChange={ addAttachments }
                    />

                    <List
                        dense
                        subheader={ <ListSubheader>{ t( "customer:email.attachments" ) }</ListSubheader> }
                        ref={ attachmentsList }
                    >
                        <div className={ flex.container }>
                            <ListItemButton
                                component="div"
                                className={ flex.fill }
                                onClick={ openAttachmentSearch }
                            >
                                <ListItemIcon>
                                    <AddIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={ t( "customer:new-email.attachment-from-computer" ) }
                                    secondary={ t( "customer:new-email.attachment-size" ) }
                                />
                            </ListItemButton>
                            <Divider flexItem orientation="vertical" />
                            <ListItemButton
                                component="div"
                                className={ flex.fill }
                                onClick={ openFiles }
                            >
                                <ListItemIcon>
                                    <AddIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        opportunity && opportunity.id ?
                                            t( "customer:new-email.attachment-from-opportunity" ) :
                                            t( "customer:new-email.attachment-from-customer" )
                                    }
                                />
                            </ListItemButton>
                            <Menu
                                open={ !!listFilesAnchor }
                                anchorEl={ listFilesAnchor }
                                onClose={ closeFiles }
                            >
                                {
                                    ( files || [] ).map( file => (
                                        <MenuItem key={ file.id } onClick={ attachEntityFile( file ) }>
                                            <ListItemText
                                                primary={ file.name }
                                                secondary={ formatBytes( file.size ) }
                                            />
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                            {
                                opportunity &&
                                        <Fragment>
                                            <Divider flexItem orientation="vertical"/>
                                            <ListItemButton
                                                component="div"
                                                className={ flex.fill }
                                                onClick={ openProposals }
                                            >
                                                <ListItemIcon>
                                                    <AddIcon/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ t( "customer:new-email.attachment-proposal" ) }
                                                />
                                            </ListItemButton>
                                            <Menu
                                                open={ !!listProposalsAnchor }
                                                anchorEl={ listProposalsAnchor }
                                                onClose={ closeProposals }
                                            >
                                                {
                                                    ( proposals || [] ).map( proposal => (
                                                        <MenuItem
                                                            key={ proposal.id }
                                                            onClick={ attachProposalFile( proposal ) }
                                                        >
                                                            <ListItemText
                                                                primary={ proposal.name }
                                                                secondary={ t(
                                                                    `opportunity:proposal.status.${proposal.status}`
                                                                )}
                                                            />
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Menu>
                                        </Fragment>
                            }
                        </div>
                        <Divider/>
                        { loadingFile && <LinearProgress/> }
                        {
                            formik.values.attachments.map( ( attachment, index ) => (
                                <ListItem key={ index }>
                                    <ListItemIcon>
                                        <AttachmentIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={ attachment.name }
                                        secondary={ formatBytes( attachment.size ) }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            size="small"
                                            onClick={ () => removeAttachment( index ) }>
                                            <CloseIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>

                    <Snackbar
                        open={ openWarningEmptyFiles }
                        onClose={ closeWarningEmptyFiles }
                        autoHideDuration={ 5000 }
                        message={ t( "customer:files.empty" ) }
                        action={
                            <Button color="secondary" onClick={ closeWarningEmptyFiles }>{ "OK" }</Button>
                        }
                    />
                    <Snackbar
                        open={ openWarningEmptyProposals }
                        onClose={ closeWarningEmptyProposals }
                        autoHideDuration={ 5000 }
                        message={ t( "customer:new-email.empty-proposals" ) }
                        action={
                            <Button
                                color="secondary"
                                onClick={ closeWarningEmptyProposals }>{ "OK" }
                            </Button>
                        }
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                {
                    formik.error &&
                        <Typography color="error">
                            {
                                formik.error.status === 500 ?
                                    t( "common:error.unknown" ) :
                                    formik.error.error
                            }
                        </Typography>
                }
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "customer:new-sms.send" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewEmail;