import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import AddIcon from "@mui/icons-material/Add";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { actions as lossReasonActions, selectors as lossReasonsSelectors } from "../../../loss-reason";
import { flex } from "../../../core";
import ConfigHeader from "../../config-header.component";
import { saveLossReasons } from "./loss-reason.actions";
import { LOSS_REASON_FORM } from "./loss-reason.constants";
import LossReasonList from "./loss-reason-list.component";

const LossReason = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( lossReasonsSelectors.isLoadingLossReasons );
    const lossReasons = useSelector( lossReasonsSelectors.getLossReasons );

    const [ showErrors, setShowErrors ] = useState( false );

    const toggleErrors = () => setShowErrors( !showErrors );

    const formik = useForm({
        form: LOSS_REASON_FORM,
        enableReinitialize: true,
        initialValues: {
            lossReasons: lossReasons || [],
        },
        onSubmit: values => dispatch( saveLossReasons( values ) ),
        validate: values => {
            const errors = {};
            errors.lossReasons = [];
            values.lossReasons.forEach( ( lossReason, index ) => {
                const error = {};

                if ( !lossReason.name ) {
                    error.name = "common:validation.required";
                }

                if ( !isEmpty( error ) ) {
                    errors.lossReasons[ index ] = error;
                }
            });
            if ( isEmpty( errors.lossReasons ) ) {
                delete errors.lossReasons;
            }

            return errors;
        },
    });

    const handleAdd = () => formik.setFieldValue( "lossReasons", [ ...formik.values.lossReasons, {}] );

    useEffect( () => {
        dispatch( lossReasonActions.fetchLossReasons() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowErrors( true );
        }
    }, [ formik.error ] );

    return (
        <div>
            <Snackbar
                open={ showErrors }
                action={ <Button onClick={ toggleErrors } color="secondary">{ "OK" }</Button>}
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ toggleErrors }
            />
            <ConfigHeader
                title={ t( "config:loss-reasons.title" ) }
                onActionClick={ handleAdd }
                disabled={ formik.submitting }
                actionTitle={ t( "config:loss-reasons.add" ) }
                actionIcon={ AddIcon }
            />
            <Form formik={ formik }>
                <Card id="lossReasonForm">
                    <CardHeader
                        subheader={ t( "config:loss-reasons.call-to-action" ) }
                    />
                    {
                        loading ?
                            <LinearProgress/> :
                            <Fragment>
                                <CardContent>
                                    <FieldArray name="lossReasons" component={ LossReasonList } />
                                </CardContent>
                                <CardActions className={ flex.justifyContentEnd }>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={ formik.submitting }
                                    >
                                        { t( "common:save" ) }
                                    </Button>
                                </CardActions>
                            </Fragment>
                    }
                </Card>
            </Form>
        </div>
    );
};

export default LossReason;