import { startSubmit as startSubmitFormik, stopSubmit as stopSubmitFormik, reset } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import has from "lodash/has";
import omit from "lodash/omit";
import dayjs from "dayjs";

import { apiv1 } from "../../api/sagas";
import history from "../../history";
import tracker from "../util/tracker.utils";
import { getPreferences } from "../core/core.selectors";
import { actions as customerActions } from "../customer";
import { selectors as phaseSelectors } from "../phase";
import {
    NEW_OPPORTUNITY_FORM,
    SEARCH_CUSTOMERS_FORM,
    SAVE_COMPANY_FORM,
    SAVE_PERSON_FORM,
    EDIT_OPPORTUNITY_FORM,
    PHASE_TRIGGERS_FORM,
    DELETE_OPPORTUNITY_FORM,
    NEW_OPPORTUNITY_COMMENT_FORM,
    NEW_SCHEDULING_FORM,
    OPPORTUNITY_FILE_FORM,
    NEW_SMS_FORM,
    ADD_OTHER_PERSON_FORM,
    DELETE_OTHER_PERSON_FORM,
    OPPORTUNITY_STATUS_FORM,
    OPEN_SCHEDULES_STATUS_FORM,
    OPPORTUNITY_HOLDING_FORM,
    EXPORTATION_FORM,
    UPDATE_OPPORTUNITIES_BATCH_FORM,
    actions,
    selectors
} from "./";
import {
    createDeleteProposalFormName,
    createSchedulingFormName,
    createSchedulingDeleteForm,
    opportunityKanbanKeys,
} from "./opportunity.utils";

// ---------------------------------------------------------------------------------------------------------------------
// Geral
// ---------------------------------------------------------------------------------------------------------------------
export function *watchOpportunity () {
    yield all([
        takeEvery( actions.FETCH_OPPORTUNITIES, fetchOpportunities ),
        takeEvery( actions.FETCH_OPPORTUNITIES_COUNT, countOpportunities ),
        takeEvery( actions.FETCH_PHASE_OPPORTUNITIES, fetchPhaseOpportunities ),
        takeEvery( actions.FETCH_FUNNEL_LATEST_UPDATED_AT, fetchFunnelLatestUpdatedAt ),
        takeEvery( actions.SAVE_PHASE_OPPORTUNITY, savePhaseOpportunity ),
        takeEvery( actions.CHANGE_OPEN_SCHEDULES_STATUS, changeOpenSchedulesStatus ),
        takeEvery( actions.FETCH_OPPORTUNITIES_STATISTICS, fetchOpportunitiesStatistics ),
        takeEvery( actions.FETCH_SEARCH_OPPORTUNITIES, fetchSearchOpportunities ),
        takeEvery( actions.CREATE_EXPORTATION, createExportation ),
        takeEvery( actions.FETCH_OPPORTUNITY, fetchOpportunity ),
        takeEvery( actions.FETCH_TIMELINE, fetchTimeline ),
        takeEvery( actions.SAVE_OPPORTUNITY, saveOpportunity ),
        takeEvery( actions.PATCH_OPPORTUNITY, patchOpportunity ),
        takeEvery( actions.EXECUTE_PHASE_TRIGGERS, executePhaseTriggers ),
        takeEvery( actions.FETCH_PERSONS, fetchPersons ),
        takeEvery( actions.SAVE_PERSON, savePerson ),
        takeEvery( actions.DELETE_PERSON, deletePerson ),
        takeEvery( actions.SAVE_COMMENT, saveComment ),
        takeEvery( actions.SAVE_NEW_SCHEDULING, saveNewScheduling ),
        takeEvery( actions.SAVE_SCHEDULING, saveScheduling ),
        takeEvery( actions.DELETE_SCHEDULING, deleteScheduling ),
        takeEvery( actions.FETCH_SCHEDULES, fetchSchedules ),
        takeEvery( actions.FETCH_SCHEDULING_TYPES, fetchSchedulingTypes ),
        takeEvery( actions.FETCH_PROPOSALS, fetchProposals ),
        takeEvery( actions.SAVE_PROPOSAL, saveProposal ),
        takeEvery( actions.DELETE_PROPOSAL, deleteProposal ),
        takeEvery( actions.SAVE_FILE, saveFile ),
        takeEvery( actions.FETCH_FILES, fetchFiles ),
        takeEvery( actions.DELETE_FILE, deleteFile ),
        takeEvery( actions.SAVE_HOLDING, saveHolding ),
        takeEvery( actions.SAVE_NEW_SMS, sendSms ),
        takeEvery( actions.SAVE_NEW_OPPORTUNITY, saveNewOpportunity ),
        takeEvery( actions.SAVE_NEW_OPPORTUNITY_COMPANY, saveNewOpportunityCompany ),
        takeEvery( actions.SAVE_NEW_OPPORTUNITY_PERSON, saveNewOpportunityPerson ),
        takeEvery( actions.SEARCH_CUSTOMERS, searchCustomers ),
        takeEvery( actions.DELETE_OPPORTUNITY, deleteOpportunity ),
        takeEvery( actions.TRANSFER_OPPORTUNITIES, transferOpportunities),
        takeEvery( actions.FETCH_OPPORTUNITY_HOLDING, fetchOpportunityHolding ),
    ]);
}

// ---------------------------------------------------------------------------------------------------------------------
// Opportunity list
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchOpportunities ({ payload }) {
    yield put( actions.requestOpportunities() );
    try {
        const response = yield call( apiv1.get, "/opportunities", { params: payload });
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveOpportunities({ opportunities: response.data, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorOpportunties( e.response.data ) );
    }
}

function *countOpportunities ({ payload }) {
    yield put( actions.requestOpportunitiesCount() );
    try {
        const response = yield call( apiv1.get, "/opportunities/count", { params: payload });
        yield put( actions.receiveOpportunitiesCount( response.data ) );
    } catch ( e ) {
        yield put( actions.errorOpportunitiesCount( e.response.data ) );
    }
}

export function *fetchPhaseOpportunities ({ meta: { phaseId }, payload: { params } }) {
    yield put( actions.requestPhaseOpportunities( phaseId, params.page ) );
    try {
        const response = yield call( apiv1.get, "/opportunities", { params });
        const totalPrice = response.headers && response.headers[ "total-price" ];
        const totalRecurrentPrice = response.headers && response.headers[ "total-recurrent-price" ];
        const totalPages = response.headers && parseInt( response.headers[ "total-pages" ] );
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receivePhaseOpportunities(
            phaseId,
            { opportunities: response.data, totalPrice, totalRecurrentPrice, totalPages, totalElements }
        ));
    } catch ( e ) {
        yield put( actions.errorPhaseOpportunities( e.response.data ) );
    }
}

export function *fetchFunnelLatestUpdatedAt () {
    try {
        const funnelId = yield select( selectors.getFunnelId );
        const response = yield call( apiv1.get, `/funnels/${funnelId}/latestupdatedat` );
        yield put( actions.setFunnelLatestUpdatedAt( response.data ));
    } catch ( e ) {
        // shit happens
    }
}

export function *savePhaseOpportunity ({ meta: { phaseId }, payload: { opportunity } }) {
    // Se esta alterando o status, da um start no form
    if ( opportunity.status ) {
        yield put( startSubmitFormik( OPPORTUNITY_STATUS_FORM ) );
    }
    try {
        if ( !isEmpty( opportunity.proposals ) ) {
            for ( const proposal of opportunity.proposals ) {
                yield call( apiv1.patch, `/proposals/${proposal.id}`, { ...proposal } );
            }
        }
        yield call( apiv1.patch, `/opportunities/${opportunity.id}`, opportunity );
        const userPreferences = yield select( getPreferences );
        const livePhases = get( userPreferences, "opportunity.liveKanban", false );

        if ( livePhases ) {
            const funnelId = yield select( selectors.getFunnelId );
            const phases = yield select( phaseSelectors.listWithFunnel( funnelId ) );
            for ( const phase of phases ) {
                yield put( actions.reloadPhaseOpportunities( phase.id ) );
            }
        } else {
            if ( opportunity?.phase?.id ) {
                yield put( actions.reloadPhaseOpportunities( opportunity.phase.id ) );
            }

            // Se alterou de fase, obtem o que seria a proxima oportunidade a ser buscada da proxima pagina
            const phaseOpportunities = yield select( selectors.getPhaseOpportunities( phaseId ) );
            const currentPage = phaseOpportunities.page;
            const totalPages = phaseOpportunities.totalPages;
            if ( currentPage < totalPages ) {
                const prevTotalElements = phaseOpportunities.totalElements;
                const params = {
                    status: "OPEN",
                    fields: opportunityKanbanKeys.toString(),
                    orderBy: "updatedAt",
                    orderType: "DESC",
                    page: prevTotalElements,
                    page_size: 1,
                    phaseId,
                };
                const response = yield call( apiv1.get, "/opportunities", { params });
                const totalElements = response.headers && response.headers[ "total-elements" ];
                const totalPrice = response.headers && response.headers[ "total-price" ];
                yield put( actions.receivePhaseOpportunities(
                    phaseId,
                    { opportunities: response.data, totalPrice, totalPages, totalElements }
                ));
            }
        }

        if ( opportunity.status ) {
            yield put( stopSubmitFormik( OPPORTUNITY_STATUS_FORM ) );
        }
    } catch ( e ) {
        if ( opportunity.status ) {
            yield put( stopSubmitFormik( OPPORTUNITY_STATUS_FORM ) );
        } else if ( get( opportunity, "phase.id" ) ) {
            yield put( stopSubmitFormik( OPPORTUNITY_STATUS_FORM, e.response.data ) );
        }
    }
}

export function *changeOpenSchedulesStatus ({ payload }) {
    try {
        yield put( startSubmitFormik( OPEN_SCHEDULES_STATUS_FORM ) );
        if ( payload.status !== "OPEN" ) {
            const schedules = yield select( selectors.getOpenSchedules );
            for ( const scheduling of schedules ) {
                yield call( apiv1.patch, `/schedules/${scheduling.id}`, payload );
            }
            if ( payload.updateAfter ) {
                const opportunity = yield select( selectors.getSelected );
                yield put( actions.fetchSchedules( opportunity.id ) );
            }
        }
        tracker.logAction( `Opportunity Status Change ${payload.status}` );
        yield put( stopSubmitFormik( OPEN_SCHEDULES_STATUS_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( OPEN_SCHEDULES_STATUS_FORM ) );
    }
}

export function *fetchOpportunitiesStatistics ({ payload }) {
    yield put( actions.requestOpportunitiesStatistics() );
    try {
        const response = yield call( apiv1.get, "/opportunities/statistics", { params: payload });
        yield put( actions.receiveOpportunitiesStatistics( response.data ) );
    } catch ( e ) {
        yield put( actions.errorOpportunitiesStatistics( e.response.data ) );
    }
}

export function *transferOpportunities ({ payload }) {

    yield put( startSubmitFormik( UPDATE_OPPORTUNITIES_BATCH_FORM ) );

    try {
        yield call( apiv1.patch, "/opportunities", payload );
        tracker.logAction( "Transfer Opportunity" );
        yield put( stopSubmitFormik( UPDATE_OPPORTUNITIES_BATCH_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( UPDATE_OPPORTUNITIES_BATCH_FORM, e.response.data ) );
    }
}

export function *fetchSearchOpportunities ({ payload }) {
    yield put( actions.requestSearchOpportunities() );
    try {
        const response = yield call( apiv1.get, "/opportunities/search", { params: payload } );
        yield put( actions.receiveSearchOpportunities( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSearchOpportunities( e.response.data ) );
    }
}

export function *createExportation ({ payload }) {
    yield put( startSubmitFormik( EXPORTATION_FORM ) );

    try {
        yield call( apiv1.post, "/opportunities/exports", payload );
        tracker.logAction( "Export Opportunity" );
        yield put( stopSubmitFormik( EXPORTATION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( EXPORTATION_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Single opportunity
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchOpportunity ({ meta: { id } }) {
    yield put( actions.requestOpportunity() );
    try {
        const response = yield call( apiv1.get, "/opportunities/" + id );
        const opportunity = response.data;

        yield put( actions.setOpportunity( opportunity ) );
        yield put( customerActions.setCustomer( null ) );
    } catch ( e ) {
        yield put( actions.errorOpportunity( e.response.data ) );
    }
}

export function *fetchTimeline ({ meta: { id }, payload }) {
    yield put( actions.requestTimeline() );

    try {
        const params = {};
        if ( !isEmpty( payload.types ) ) {
            params.types = payload.types.toString();
        }
        params.page = payload.page;
        const response = yield call( apiv1.get, `/opportunities/${id}/timeline`, { params } );
        const events = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveTimeline({ events, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorTimeline( e.response.data ) );
    }
}

export function *saveOpportunity ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( EDIT_OPPORTUNITY_FORM ) );

    try {
        const response = yield call( apiv1.put, "/opportunities/" + id, payload );
        const opportunity = response.data;

        yield put( actions.setOpportunity( opportunity ) );
        tracker.logAction( "Edit Opportunity Negotiation" );
        yield put( stopSubmitFormik( EDIT_OPPORTUNITY_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( EDIT_OPPORTUNITY_FORM, e.response.data ) );
    }
}

export function *patchOpportunity ({ meta: { id, formikForm }, payload }) {
    if ( formikForm ) {
        yield put( startSubmitFormik( formikForm ) );
    }
    try {
        if ( payload.company && !isEmpty( payload.company.fields ) && !payload.company.id ) {
            const response = yield call( apiv1.post, "/customers", payload.company );
            payload.company = { id: response.data.id };
        }
        if ( payload.person && !isEmpty( payload.person.fields ) && !payload.person.id ) {
            const response = yield call( apiv1.post, "/customers", payload.person );
            payload.person = { id: response.data.id };
        }
        if ( !isEmpty( payload.proposals ) ) {
            for ( const proposal of payload.proposals ) {
                yield call( apiv1.patch, `/proposals/${proposal.id}`, { ...proposal } );
            }
        }
        const response = yield call( apiv1.patch, "/opportunities/" + id, payload );
        const opportunity = response.data;

        const previousOpportunity = yield select( selectors.getSelected );
        if ( previousOpportunity && previousOpportunity.id === id ) {
            yield put( actions.setOpportunity( opportunity ) );
            yield put( actions.reloadTimeline() );
        }
        if ( formikForm ) {
            yield put( stopSubmitFormik( formikForm ) );
        }
    } catch ( e ) {
        if ( formikForm ) {
            yield put( stopSubmitFormik( formikForm, e.response.data ) );
        }
    }
}

export function *executePhaseTriggers ({ meta: { id }, payload }) {
    try {
        yield put( startSubmitFormik( PHASE_TRIGGERS_FORM ) );
        yield call(
            apiv1.post, `/opportunities/${id}/phases/triggers`,
            payload.triggers.filter( trigger => trigger.checked )
        );
        yield put( stopSubmitFormik( PHASE_TRIGGERS_FORM ) );
        yield put( actions.reloadTimeline() );
    } catch ( e ) {
        yield put( stopSubmitFormik( PHASE_TRIGGERS_FORM, e.response.data ) );
    }
}

export function *saveComment ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( NEW_OPPORTUNITY_COMMENT_FORM ) );

    try {
        yield call( apiv1.post, "/opportunities/" + id + "/comments", payload );
        yield put( stopSubmitFormik( NEW_OPPORTUNITY_COMMENT_FORM ) );
        yield put( actions.reloadTimeline() );
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_OPPORTUNITY_COMMENT_FORM, e.response.data ) );
    }
}

export function *deleteOpportunity ({ meta: { id } }) {
    yield put( startSubmitFormik( DELETE_OPPORTUNITY_FORM ) );

    try {
        yield call( apiv1.delete, `/opportunities/${ id }` );
        tracker.logAction( "Opportunity Deleted" );
        yield put( stopSubmitFormik( DELETE_OPPORTUNITY_FORM ) );
        yield call( [ history, history.push ], "/opportunities" );
    } catch ( e ) {
        yield put( stopSubmitFormik( DELETE_OPPORTUNITY_FORM, e.response.data ) );
    }
}

export function *saveFile ({ meta: { id }, payload }) {

    yield put( startSubmitFormik( OPPORTUNITY_FILE_FORM ) );
    try {
        const { fileName, file } = payload;
        const params = { fileName };
        const formData = new FormData();
        formData.append( "file", file );
        yield call( apiv1.put, `/opportunities/${id}/files`, formData, { params } );
        yield put( actions.fetchFiles( id ) );
        tracker.logAction( "Upload Opportunity File" );
        yield put( stopSubmitFormik( OPPORTUNITY_FILE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( OPPORTUNITY_FILE_FORM, e.response.data ) );
    }
}

export function *fetchFiles ({ meta: { id } }) {
    yield put( actions.requestFiles() );

    try {
        const response = yield call( apiv1.get, `/opportunities/${id}/files` );
        yield put( actions.receiveFiles( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFiles( e.response.data ) );
    }
}

export function *deleteFile ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( OPPORTUNITY_FILE_FORM ) );
    try {
        const { opportunityId } = payload;
        yield call( apiv1.delete, `/opportunities/files/${id}` );
        yield put( actions.fetchFiles( opportunityId ) );
        yield put( stopSubmitFormik( OPPORTUNITY_FILE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( OPPORTUNITY_FILE_FORM, e.response.data ) );
    }
}

export function *saveHolding ({ meta: { id }, payload: { exclude, ...data } }) {
    yield put( startSubmitFormik( OPPORTUNITY_HOLDING_FORM ) );
    try {
        yield call( apiv1.post, `/opportunities/${id}/holding`, data );
        if ( exclude ) {
            yield put( actions.deleteOpportunity({ id }) );
        }
        yield put( stopSubmitFormik( OPPORTUNITY_HOLDING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( OPPORTUNITY_HOLDING_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Persons
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchPersons ({ meta: { id } }) {
    yield put( actions.requestPersons() );
    try {
        const response = yield call( apiv1.get, `/opportunities/${id}/persons` );
        yield put( actions.receivePersons( response.data ) );
    } catch ( e ) {
        yield put( actions.errorPersons( e.response.data ) );
    }
}

export function *savePerson ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( ADD_OTHER_PERSON_FORM ) );

    try {
        if ( !payload.person.id ) {
            const response = yield call( apiv1.post, "/customers", { ...payload.person, type: "PERSON" });
            payload.person = { id: response.data.id };
        }
        yield call( apiv1.put, `/opportunities/${id}/persons`, payload );
        yield put( actions.fetchPersons( id ) );
        yield put( actions.fetchOpportunity( id ) );
        yield put( stopSubmitFormik( ADD_OTHER_PERSON_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( ADD_OTHER_PERSON_FORM, e.response.data ) );
    }
}

export function *deletePerson ({ payload: { opportunityId, personId } }) {
    yield put( startSubmitFormik( DELETE_OTHER_PERSON_FORM ) );

    try {
        yield call( apiv1.delete, `/opportunities/${opportunityId}/persons/${personId}` );
        yield put( actions.fetchPersons( opportunityId ) );
        yield put( actions.fetchOpportunity( opportunityId ) );
        yield put( stopSubmitFormik( DELETE_OTHER_PERSON_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( DELETE_OTHER_PERSON_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Scheduling
// ---------------------------------------------------------------------------------------------------------------------
export function *saveNewScheduling ({ payload }) {
    yield put( startSubmitFormik( NEW_SCHEDULING_FORM ) );

    try {
        const scheduling = { ...payload };
        if ( !scheduling.recurrent.active ) {
            scheduling.recurrent = null;
        }
        yield call( apiv1.post, "/schedules", scheduling );
        yield put( actions.fetchOpportunity( scheduling.opportunity.id ) );
        tracker.logAction( "Opportunity New Scheduling" );
        yield put( reset( NEW_SCHEDULING_FORM ) );
        yield put( stopSubmitFormik( NEW_SCHEDULING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_SCHEDULING_FORM, e.response.data ) );
    }
}

export function *saveScheduling ({ meta: { id }, payload }) {
    const formName = payload.form || createSchedulingFormName( id );
    yield put( startSubmitFormik( formName ) );

    try {
        const scheduling = { ...payload };
        const previousSchedules = yield select( selectors.getSchedules );
        const previousScheduling = previousSchedules.find( s => s.id === id );

        let response;
        if ( has( scheduling, "recurrent.updateMode" ) ) {
            if ( scheduling.recurrent.active && has( previousScheduling, "recurrent.id" ) ) {
                // se ta ativo e tinha recorrencia
                const recurrentHasChanged = !isEqual(
                    omit( scheduling.recurrent, [ "active", "updateMode" ] ),
                    previousScheduling.recurrent
                );
                if ( recurrentHasChanged ) {
                    // se alterou a recorrencia
                    if ( scheduling.recurrent.updateMode === "ALL" ) {
                        // exclui todos os agendamentos da recorrencia e cria um novo
                        yield call( apiv1.delete, `/schedules/recurrent/${previousScheduling.recurrent.id}` );
                        scheduling.recurrent.id = null;
                        response = yield call( apiv1.post, "/schedules", scheduling );
                    } else {
                        const schedulingRecurrent = {
                            ...previousScheduling.recurrent,
                            until: dayjs( scheduling.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                        };
                        // altera a recorrencia e o agendamento
                        yield call(
                            apiv1.patch,
                            `/schedules/recurrent/${schedulingRecurrent.id}`,
                            schedulingRecurrent
                        );
                        scheduling.recurrent.id = null;
                        response = yield call( apiv1.post, "/schedules", scheduling );
                    }
                } else {
                    switch ( scheduling.recurrent.updateMode ) {
                        case "ALL":
                            response = yield call(
                                apiv1.patch,
                                `/schedules/${scheduling.id}/recurrent/all`,
                                scheduling
                            );
                            break;
                        case "FUTURE":
                            response = yield call( apiv1.patch, `/schedules/${scheduling.id}/recurrent`, scheduling );
                            break;
                        default:
                            response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
                            break;
                    }
                }
            } else if ( has( previousScheduling, "recurrent.id" ) ) {
                // se nao ta ativo mas tinha recorrencia
                if ( scheduling.recurrent.updateMode === "ALL" ) {
                    yield call( apiv1.delete, `/schedules/recurrent/${previousScheduling.recurrent.id}` );

                    scheduling.recurrent = null;
                    response = yield call( apiv1.post, "/schedules", scheduling );
                } else {
                    const schedulingRecurrent = {
                        ...previousScheduling.recurrent,
                        until: dayjs( scheduling.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                    };
                    yield call( apiv1.patch, `/schedules/recurrent/${schedulingRecurrent.id}`, schedulingRecurrent );

                    scheduling.recurrent = null;
                    response = yield call( apiv1.post, "/schedules", scheduling );
                }
            } else {
                if ( !scheduling.recurrent.active ) {
                    scheduling.recurrent = null;
                }
                response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
            }
        } else {
            if ( scheduling.recurrent && !scheduling.recurrent.active ) {
                scheduling.recurrent = null;
            }
            response = yield call( apiv1.patch, `/schedules/${scheduling.id}`, scheduling );
        }

        yield put( actions.fetchOpportunity( response.data.opportunity.id ) );
        yield put( stopSubmitFormik( formName ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( formName, e.response.data ) );
    }
}

export function *deleteScheduling ({ meta: { id }, payload }) {
    const formName = createSchedulingDeleteForm( id );
    yield put( startSubmitFormik( formName ) );

    try {
        const opportunity = yield select( selectors.getSelected );

        const { mode, recurrent } = payload;
        switch ( mode ) {
            case "ALL":
                yield call( apiv1.delete, `/schedules/recurrent/${recurrent.id}` );
                break;
            case "FUTURE":
                const schedulingRecurrent = {
                    id: recurrent.id,
                    until: dayjs( payload.startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" ),
                };
                yield call(
                    apiv1.patch,
                    `/schedules/recurrent/${schedulingRecurrent.id}`,
                    schedulingRecurrent
                );
                break;
            default:
                yield call( apiv1.delete, `/schedules/${id}` );
                break;
        }

        yield put( actions.fetchOpportunity( opportunity.id ) );
        yield put( stopSubmitFormik( formName ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( formName, e.response.data ) );
    }
}


export function *fetchSchedules ({ meta: { id } }) {
    yield put( actions.requestSchedules() );

    try {
        const response = yield call( apiv1.get, `/opportunities/${id}/schedules` );
        yield put( actions.receiveSchedules( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSchedules( e.response.data ) );
    }
}

export function *fetchSchedulingTypes () {
    yield put( actions.requestSchedulingTypes() );

    try {
        const response = yield call( apiv1.get, "/opportunities/schedules/types" );
        yield put( actions.receiveSchedulingTypes( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSchedulingTypes( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Proposal
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchProposals ({ meta: { id } }) {
    yield put( actions.requestProposals() );

    try {
        const response = yield call( apiv1.get, `/opportunities/${id}/proposals` );
        yield put( actions.receiveProposals( response.data ) );
    } catch ( e ) {
        yield put( actions.errorProposals( e.response.data ) );
    }
}

export function *saveProposal ({ meta: { form, id }, payload }) {
    try {
        yield put( startSubmitFormik( form ) );
        if ( id ) {
            yield call( apiv1.patch, `/proposals/${id}`, payload );
            tracker.logAction( `Opportunity Proposal Change Status ${payload.status}` );
        } else {
            yield call( apiv1.post, `/opportunities/${payload.opportunityId}/proposals`, payload );
            tracker.logAction( "Opportunity New Proposal" );
        }
        yield put( actions.fetchOpportunity( payload.opportunityId ) );
        yield put( actions.fetchProposals( payload.opportunityId ) );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data ) );
    }
}

export function *deleteProposal ({ meta: { id, opportunityId } }) {
    const form = createDeleteProposalFormName( id );
    try {
        yield put( startSubmitFormik( form ) );
        yield call( apiv1.delete, `/proposals/${id}` );
        yield put( actions.fetchOpportunity( opportunityId ) );
        yield put( actions.fetchProposals( opportunityId ) );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Opportunity sms
// ---------------------------------------------------------------------------------------------------------------------
export function *sendSms ({ payload }) {
    yield put( startSubmitFormik( NEW_SMS_FORM ) );

    try {
        const { customerId } = payload;
        yield call( apiv1.post, `/customers/${customerId}/sms`, payload );
        yield put( stopSubmitFormik( NEW_SMS_FORM ) );
        yield put( reset( NEW_SMS_FORM ) );
        yield put( actions.reloadTimeline() );
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_SMS_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// New customer
// ---------------------------------------------------------------------------------------------------------------------
export function *searchCustomers ({ payload }) {
    yield put( startSubmitFormik( SEARCH_CUSTOMERS_FORM ) );
    try {
        const searchPersons = Object.keys( payload.person )
            .filter( key => !isEmpty( payload.person[ key ] ) )
            .length > 0;
        const searchCompanies = Object.keys( payload.company )
            .filter( key => !isEmpty( payload.company[ key ] ) )
            .length > 0;
        let persons = [];
        if ( searchPersons ) {
            const params = { ...payload.person, type: "PERSON" };
            const response = yield call( apiv1.get, "/customers/search", { params } );
            persons = response.data;
        }
        let companies = [];
        if ( searchCompanies ) {
            const params = { ...payload.company, type: "COMPANY" };
            const response = yield call( apiv1.get, "/customers/search", { params } );
            companies = response.data;
        }

        yield put( actions.setCustomersSearch({ persons, companies }) );
        yield put( actions.setCustomersSearchValues( payload ) );
        yield put( stopSubmitFormik( SEARCH_CUSTOMERS_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SEARCH_CUSTOMERS_FORM, e.response.data ) );
    }
}

export function *saveNewOpportunityPerson ({ payload }) {
    yield put( startSubmitFormik( SAVE_PERSON_FORM ) );
    try {
        let response;
        const companies = yield select( selectors.getNewOpportunityCompanies );
        const persons = yield select( selectors.getNewOpportunityPersons );
        if ( payload.id ) {
            response = yield call( apiv1.put, "/customers/" + payload.id, payload );
            const previous = persons.find( item => item.id === payload.id );
            persons.splice( persons.indexOf( previous ), 1, response.data );
        } else {
            response = yield call( apiv1.post, "/customers", payload );
            persons.push( response.data );
        }
        yield put( actions.setCustomersSearch({ companies, persons }) );
        yield put( actions.setNewOpportunityPerson( response.data ) );
        yield put( stopSubmitFormik( SAVE_PERSON_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SAVE_PERSON_FORM, e.response.data ) );
    }
}

export function *saveNewOpportunityCompany ({ payload }) {
    yield put( startSubmitFormik( SAVE_COMPANY_FORM ) );
    try {
        let response;
        const companies = yield select( selectors.getNewOpportunityCompanies );
        const persons = yield select( selectors.getNewOpportunityPersons );
        if ( payload.id ) {
            response = yield call( apiv1.put, "/customers/" + payload.id, payload );
            const previous = companies.find( item => item.id === payload.id );
            companies.splice( companies.indexOf( previous ), 1, response.data );
        } else {
            response = yield call( apiv1.post, "/customers", payload );
            companies.push( response.data );
        }
        yield put( actions.setCustomersSearch({ companies, persons }) );
        yield put( actions.setNewOpportunityCompany( response.data ) );
        yield put( stopSubmitFormik( SAVE_COMPANY_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SAVE_COMPANY_FORM, e.response.data ) );
    }
}

export function *saveNewOpportunity ({ meta, payload }) {
    const form = meta.form || NEW_OPPORTUNITY_FORM;
    yield put( startSubmitFormik( form ) );

    try {
        const response = yield call( apiv1.post, "/opportunities", payload );
        yield put( stopSubmitFormik( form ) );
        yield put( actions.pushNewOpportunity( response.data ) );
        yield put( actions.setOpportunity( response.data ) );
        tracker.logAction( "New Opportunity" );
        yield call( [ history, history.push ], `/opportunities/${response.data.id}` );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Opportunity holding
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchOpportunityHolding ({ meta: { id } }) {
    try {
        yield put( actions.requestOpportunityHolding() );
        const response = yield call( apiv1.get, `/opportunities/${id}/holding`,);
        yield put( actions.receiveOpportunityHolding(response.data) );
    } catch ( e ) {
        yield put( actions.errorOpportuntyHolding( e.response.data ) );
    }
}