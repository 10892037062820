import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { fetchModels } from "../file/file.actions";
import { cssUtils } from "../ui";
import { AddProposal, ProposalItem } from "./proposal";
import { fetchProposals } from "./opportunity.actions";
import { isLoadingProposals, getProposals, getSelected } from "./opportunity.selectors";
import css from "./opportunity.scss";

const OpportunityProposals = ({ opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingProposals );
    const opportunity = useSelector( getSelected );
    const proposals = useSelector( getProposals );

    const [ openAdd, setOpenAdd ] = useState( false );
    const toggleAdd = () => setOpenAdd( !openAdd );

    useEffect( () => {
        dispatch( fetchModels() );
    }, [ dispatch ] );
    useEffect( () => {
        dispatch( fetchProposals( opportunityId ) );
    }, [ dispatch, opportunityId, opportunity.status ] );

    return (
        <div className={ css.containerContent }>
            <AddProposal opportunityId={ opportunityId } onClose={ toggleAdd } open={ openAdd }/>

            <Card className={ css.content }>
                { loading && <LinearProgress/> }
                <List>
                    <ListItemButton onClick={ toggleAdd }>
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText primary={ t( "common:add" ) }/>
                    </ListItemButton>
                    { !loading && <hr className={ cssUtils.marginVerticalSmall }/> }
                    {
                        !loading && isEmpty( proposals ) &&
                            <ListItem>
                                <ListItemText secondary={ t( "opportunity:proposals.empty" ) }/>
                            </ListItem>
                    }
                    {
                        ( proposals || [] ).map( proposal =>
                            <ProposalItem key={ proposal.id } proposal={ proposal } />
                        )
                    }
                </List>
            </Card>
        </div>
    );
};

export default OpportunityProposals;