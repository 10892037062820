import { useEffect, useMemo } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import SignalCelularOffIcon from "@mui/icons-material/SignalCellularOff";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as subscriptionSelectors } from "../subscription";
import { selectors as opportunitySelectors } from "../opportunity";
import { Dialog, Dropdown, EmptyState, TemplateEditor } from "../ui";
import { NEW_SMS_FORM } from "./customer.constants";
import { getSelected } from "./customer.selectors";
import { saveNewSms } from "./customer.actions";
import { selectors as formSelectors } from "../form";
import { phoneFormat } from "../core/formatter.utils";
import { isCellPhone } from "../core/validator.utils";

const getPhones = ( customer, companyFields, personFields ) => {
    const phones = [];
    if ( customer.type === "COMPANY" ) {
        companyFields.forEach( field => {
            Object.keys( customer.fields )
                .filter( fieldId => fieldId === field.id.toString() )
                .forEach( fieldId => {
                    const value = ( customer.fields[ fieldId ] || [] );
                    value.filter( phone => isCellPhone( phone ) )
                        .forEach( phone => phones.push( phone ));
                });
        });
    } else {
        personFields.forEach( field => {
            Object.keys( customer.fields )
                .filter( fieldId => fieldId === field.id.toString() )
                .forEach( fieldId => {
                    const value = ( customer.fields[ fieldId ] || [] );
                    value.filter( phone => isCellPhone( phone ) )
                        .forEach( phone => phones.push( phone ));
                });
        });
    }
    return phones;
};

const NewSms = ({ show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const customer = useSelector( getSelected );
    const opportunity = useSelector( opportunitySelectors.getSelected );
    const companyFields = useSelector( formSelectors.listPhoneFields( "COMPANY" ) );
    const personFields = useSelector( formSelectors.listPhoneFields( "PERSON" ) );

    const selectedCustomer = useMemo(
        () => ( opportunity && ( opportunity.person || opportunity.company ) ) || customer,
        [ opportunity, customer ]
    );

    const initialValues = useMemo( () => ({
        customerId: selectedCustomer?.id,
        opportunityId: opportunity?.id,
        phone: null,
        message: "",
    }), [ selectedCustomer, opportunity ] );

    const formik = useForm({
        form: NEW_SMS_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewSms( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.phone ) {
                errors.phone = "common:validation.required";
            }
            if ( !values.message ) {
                errors.message = "common:validation.required";
            }
            return errors;
        },
    });

    const prevShow = usePrevious( show );
    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        if ( !prevShow && show ) {
            const phones = getPhones( selectedCustomer, companyFields, personFields );
            if ( phones.length === 1 ) {
                formik.setFieldValue( "phone", phones[ 0 ].number );
            }
        }
    }, [ prevShow, show, selectedCustomer, companyFields, personFields, formik ]);
    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onClose();
        }
    }, [ prevSubmitting, formik.submitting, onClose ] );

    if ( !subscription || !selectedCustomer ) {
        return null;
    }
    const active = subscription.integrations
        .find( item => item.integration === "SMS" )
        .active;
    const phones = getPhones( selectedCustomer, companyFields, personFields );

    return (
        <Dialog open={ show } onClose={ onClose } fullWidth>
            <DialogTitle>{ t( "customer:new-sms.title" ) }</DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    {
                        active ?
                            <>
                                <Field
                                    name="phone"
                                    component={ Dropdown }
                                    source={ phones.map( phone => ({
                                        label: phoneFormat( phone ),
                                        value: phone.number
                                    }))}
                                    label={ t( "customer:sms.phone" ) }
                                    required
                                />

                                <Field
                                    name="message"
                                    type="text"
                                    label={ t( "customer:sms.message" ) }
                                    templateType="SMS"
                                    customer={ selectedCustomer }
                                    opportunity={ opportunity }
                                    component={ TemplateEditor }
                                    showVariables={ false }
                                    showTemplates
                                    multiline
                                    required
                                />
                            </> :
                            <EmptyState
                                message={ t( "customer:new-sms.blocked" ) }
                                icon={ SignalCelularOffIcon }
                            />
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                { formik.error && <Typography color="error">{ formik.error }</Typography> }
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                {
                    active &&
                        <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                            { t( "customer:new-sms.send" ) }
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default NewSms;