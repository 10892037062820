import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { fetchGoalsProgress } from "../goal/goal.actions";
import { isLoadingGoalsProgress, getGoalsProgress } from "../goal/goal.selectors";
import { selectors as subscriptionSelectors } from "../subscription";
import { Chart, flex } from "../ui";
import { useGoalProgressFormat } from "./home.utils";
import css from "./home.scss";

const enDayjs = dayjs();
enDayjs.locale( "en" );

const percentage = ( value, total ) => roundNumber( ( 100 * value ) / total ) || 0;
const roundNumber = num => +( Math.round( num + "e+2" ) + "e-2" );

const MONTH = enDayjs.format( "MMMM" ).toUpperCase();
const YEAR = new Date().getFullYear();

const GoalProgress = ({
    title,
    usersId,
    id,
    className,
    chartId
}) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const goalProgresses = useSelector( getGoalsProgress );
    const loading = useSelector( isLoadingGoalsProgress );
    const subscription = useSelector( subscriptionSelectors.getSubscription );

    const goalProgressFormat = useGoalProgressFormat( t, priceFormat );

    const series = useMemo( () => {
        if ( !goalProgresses ) {
            return [];
        }
        return [
            {
                name: t( "home:goal-progress.target" ),
                color: "#66bb6a",
                pointPadding: 0.3,
                pointPlacement: -0.2,
                data: goalProgresses.map( progress => ({
                    y: 100,
                    value: goalProgressFormat( progress.goal, progress.goal.target, priceFormat )
                }))
            },
            {
                name: t( "home:goal-progress.reached" ),
                color: "#ff7043",
                pointPadding: 0.4,
                pointPlacement: -0.2,
                data: goalProgresses.map( progress => ({
                    y: progress.goal.type === "AVERAGE_SERVICE_TIME" ?
                        percentage( progress.goal.target, progress.current ) :
                        percentage( progress.current, progress.goal.target ),
                    value: goalProgressFormat( progress.goal, progress.current, priceFormat )
                }))
            }
        ];
    }, [ t, goalProgressFormat, goalProgresses, priceFormat ] );

    const data = useMemo( () => {
        const getType = goal => {
            switch ( goal.type ) {
                case "AVERAGE_SERVICE_TIME":
                    return t( "home:goal-progress.minutes" );
                case "SOLD_OPPORTUNITIES_PRICE":
                case "SOLD_OPPORTUNITIES_RECURRENT_PRICE":
                    return `(${getSymbolFromCurrency( subscription.currency )})`;
                case "CUSTOM_FIELD":
                    return goal.field.type === "PRICE" ?
                        `(${getSymbolFromCurrency( subscription.currency )})` :
                        t( "home:goal-progress.quantity" );
                default:
                    return t( "home:goal-progress.quantity" );
            }
        };

        return {
            title: {
                text: title || t( "home:goal-progress.title" )
            },
            xAxis: {
                categories: ( goalProgresses || [] )
                    .map( progress => progress.goal.name + " " + getType( progress.goal ) ),
                title: {
                    text: null
                }
            },
            yAxis: {
                labels: {
                    enabled: false
                },
                min: 0,
                title: {
                    text: "",
                    align: "high"
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        color: "black"
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return this.series.name + ": " + this.point.value;
                }
            },
            plotOptions: {
                bar: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.y + "%";
                        }
                    }
                }
            },
            legend: {
                enabled: true
            },
            series
        };
    }, [ t, title, goalProgresses, series, subscription.currency ] );

    useEffect( () => {
        dispatch( fetchGoalsProgress({ usersId: usersId?.toString(), month: MONTH, year: YEAR }) );
    }, [ dispatch, usersId ] );

    return (
        <Card id={ id } className={ className }>
            {
                loading ?
                    <div className={ css.goalsSkeleton }>
                        <Skeleton className={ flex.fill } sx={{ width: "30%" }} />
                        <Skeleton className={ flex.fill } />
                        <Skeleton className={ flex.fill } sx={{ width: "80%" }} />
                        <Skeleton className={ flex.fill } sx={{ width: "90%" }} />
                        <Skeleton className={ flex.fill } />
                        <Skeleton className={ flex.fill } sx={{ width: "60%" }} />
                    </div> :
                    <Chart
                        id={ chartId }
                        type="bar"
                        data={ data }
                        noDataMessage={ t( "home:goal-progress.empty" ) }
                    />
            }
        </Card>
    );
};

export default GoalProgress;