import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";

import useTranslate from "../../../hooks/use-translate.hook";
import { resendInvitation } from "./user.actions";
import { createInvitationFormName } from "./user.utils";

const ResendInvite = ({ invitation }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirm, setConfirm ] = useState( false );
    const [ showError, setShowError ] = useState( false );

    const toggleConfirm = () => setConfirm( !confirm );

    const formik = useForm({
        form: createInvitationFormName( invitation.id ),
        enableReinitialize: true,
        initialValues: {
            invitation,
        },
        onSubmit: values => dispatch( resendInvitation( values ) ),
        onSubmitSuccess: () => setConfirm( false ),
    });

    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    return (
        <div>
            <Snackbar
                open={ showError }
                action={
                    <Button color="secondary" onClick={ toggleConfirm }>
                        { "OK" }
                    </Button>
                }
                message={ formik.error }
                onClose={ toggleConfirm }
            />

            <IconButton disabled={ formik.submitting } onClick={ toggleConfirm } size="large">
                <RefreshIcon/>
            </IconButton>

            <Dialog
                open={ confirm }
                onClose={ toggleConfirm }
            >
                <DialogTitle>
                    { t( "config:resend.title" ) }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t( "config:resend.confirm" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={ formik.submitting }
                        onClick={ toggleConfirm }
                    >
                        { t( "common:no" ) }
                    </Button>
                    <Button
                        color="primary"
                        disabled={ formik.submitting }
                        onClick={ formik.submitForm }
                    >
                        { t( "config:resend.yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ResendInvite;