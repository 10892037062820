import { useDragLayer } from "react-dnd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classnames from "classnames";

import { cssUtils, flex } from "../ui";
import { fieldIcons } from "../form";

const layerStyles = width => ({
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width,
});

function getItemStyles ( props ) {
    const { initialOffset, currentOffset } = props;
    if ( !initialOffset || !currentOffset ) {
        return {
            display: "none",
        };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

const FormFieldDragLayer = ({ width }) => {
    const { isDragging, item, initialOffset, currentOffset } = useDragLayer( monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if ( !isDragging ) {
        return null;
    }
    const field = item.field;
    const Icon = fieldIcons[ field.type ];

    return (
        <div style={ layerStyles( width ) }>
            <div style={ getItemStyles({ initialOffset, currentOffset }) }>
                <Card>
                    <CardContent className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Icon className={ cssUtils.marginRight }/>
                        <span>{ field.name + ( field.options && field.options.required ? " *" : "" ) }</span>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
export default FormFieldDragLayer;
