import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { fetchSubscription } from "../../../subscription/subscription.actions";
import * as actions from "./plan.actions";
import { PLAN_FORM, DELETE_SUBSCRIPTION_BILLING_FORM } from "./plan.constants";

export function *watchConfigSubscriptionPlan () {
    yield all([
        takeEvery( actions.FETCH_BILLINGS, fetchBillings ),
        takeEvery( actions.SAVE_SUBSCRIPTION_BILLING, saveSubscriptionBilling ),
        takeEvery( actions.DELETE_SUBSCRIPTION_BILLING, deleteSubscriptionBilling ),
        takeEvery( actions.FETCH_SUBSCRIPTION_BILLING, fetchSubscriptionBilling ),
        takeEvery( actions.FETCH_CREDIT_CARDS, fetchCreditCards ),
        takeEvery( actions.FETCH_SUBSCRIPTION_STORAGE, fetchSubscriptionStorage ),
    ]);
}

function *fetchBillings ({ meta: { page }}) {
    yield put( actions.requestBillings() );

    try {
        const response = yield call( apiv1.get, "/billings", { params: { page }} );
        const billings = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveBillings({ billings, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorBillings( e.response.data ) );
    }
}

function *fetchSubscriptionBilling () {
    yield put( actions.requestSubscriptionBilling() );

    try {
        const coupon = yield call( apiv1.get, "/subscription/coupon" );
        yield put( actions.receiveSubscriptionCoupon( coupon.data ) );
        const response = yield call( apiv1.get, "/subscription/billing" );
        yield put( actions.receiveSubscriptionBilling( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSubscriptionBilling( e.response.data ) );
    }
}

function *saveSubscriptionBilling ({ payload }) {
    yield put( startSubmit( PLAN_FORM ) );
    try {
        if ( payload.billingType === "CREDIT_CARD" ) {
            if ( !payload.creditCard.id ) {
                const creditCard = yield call( apiv1.post, "/creditcards", payload.creditCard );
                payload.creditCardId = creditCard.data.id;
            } else {
                payload.creditCardId = payload.creditCard.id;
            }
        }

        const response = yield call( apiv1.put, "/subscription/billing", payload );
        yield put( actions.receiveSubscriptionBilling( response.data ) );
        yield put( stopSubmit( PLAN_FORM ) );
        yield put( actions.fetchBillings() );
        yield put( fetchSubscription() );
    } catch ( e ) {
        yield put( stopSubmit( PLAN_FORM, e.response.data ) );
    }
}

function *deleteSubscriptionBilling () {
    yield put( startSubmit( DELETE_SUBSCRIPTION_BILLING_FORM ) );
    try {
        yield call( apiv1.delete, `/subscription/billing`);
        yield put( stopSubmit( DELETE_SUBSCRIPTION_BILLING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_SUBSCRIPTION_BILLING_FORM, e.response.data ) );
    }
}

function *fetchCreditCards () {
    yield put( actions.requestCreditCards() );

    try {
        const response = yield call( apiv1.get, "/creditcards" );
        yield put( actions.receiveCreditCards( response.data ) );
    } catch ( e ) {
        yield put( actions.errorCreditCards( e.response.data ) );
    }
}

function *fetchSubscriptionStorage () {
    yield put( actions.requestSubscriptionStorage() );

    try {
        const response = yield call( apiv1.get, "/subscription/storage" );
        yield put( actions.receiveSubscriptionStorage( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSubscriptionStorage( e.response.data ) );
    }
}
