import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { listAll as listAllDistributions } from "../../../config/advanced/distribution/distribution.selectors";
import { selectors as teamSelectors } from "../../../team";
import { selectors as userSelectors } from "../../../user";
import { Dropdown, DropdownMultiple, flex } from "../../../ui";

const LeadSourceDistribution = ({ name, form }) => {
    const t = useTranslate();

    const distributions = useSelector( listAllDistributions );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAllActive );

    const values = useMemo( () => get( form.values, name ), [ form.values, name ] );

    const { setFieldValue } = form;

    useEffect( () => {
        if ( values.distribution?.id ) {
            setFieldValue( `${name}.teamId`, null );
            setFieldValue( `${name}.usersId`, [] );
        }
    }, [ name, setFieldValue, values.distribution ] );
    useEffect( () => {
        if ( values.teamId ) {
            setFieldValue( `${name}.distribution`, null );
            setFieldValue( `${name}.usersId`, [] );
        }
    }, [ name, setFieldValue, values.teamId ] );
    useEffect( () => {
        if ( values.usersId?.length ) {
            setFieldValue( `${name}.distribution`, null );
            setFieldValue( `${name}.teamId`, null );
        }
    }, [ name, setFieldValue, values.usersId ] );

    return (
        <>
            <Field
                name={ `${name}.distribution.id` }
                label={ t( "marketplace:integrations.lead-distribution.distribution" ) }
                className={ flex.fill }
                component={ Dropdown }
                source={ distributions?.map( distribution => ({ value: distribution.id, label: distribution.name }) ) }
                emptyValue
            />
            <Field
                name={ `${name}.teamId` }
                label={ t( "marketplace:integrations.lead-distribution.team" ) }
                className={ flex.fill }
                component={ Dropdown }
                source={ teams?.map( team => ({ value: team.id, label: team.name }) ) }
                emptyValue
            />
            <Field
                name={ `${name}.usersId` }
                label={ t( "marketplace:integrations.lead-distribution.users" ) }
                component={ DropdownMultiple }
                source={ users?.map( user => ({ label: user.name, value: user.id })) }
            />
        </>
    );
};

export default LeadSourceDistribution;