import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import { OAUTH_FORM } from "./oauth.constants";
import * as actions from "./oauth.actions";

export function *watchOauth () {
    yield all([
        takeEvery( actions.FETCH_CLIENT, fetchClient ),
        takeEvery( actions.CREATE_AUTHORIZATION_CODE, createAuthorizationCode ),
    ]);
}

function *fetchClient ({ meta: { id } }) {
    yield put( actions.requestClient() );
    try {
        const response = yield call( apiv1.get, `/oauth/clients/${id}` );
        yield put( actions.receiveClient( response.data ) );
    } catch ( e ) {
        yield put( actions.errorClient( e.response.data.error ) );
    }
}

function *createAuthorizationCode ({ payload }) {
    yield put( startSubmit( OAUTH_FORM ) );
    try {
        const response = yield call( apiv1.post, "/oauth/token", payload );
        yield put( actions.setAuthorizationCode( response.data.code ) );
        yield put( stopSubmit( OAUTH_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( OAUTH_FORM, e.response.data.error ) );
    }
}