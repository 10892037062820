import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { UsersFilter, cssUtils, flex, Input } from "../../../ui";

const DistributionForm = ( { distribution, form, onSubmit, onClose } ) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: form,
        enableReinitialize: true,
        initialValues: { name: "", teamId: null, usersId: [], ...distribution },
        onSubmit: values => dispatch( onSubmit( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required" ;
            }
            if ( !values.teamId && isEmpty( values.usersId ) ) {
                errors.teamId = "config:distribution.empty-users";
            }
            return errors;
        },
    });
    const { values, submitForm, submitting, setFieldValue } = formik;

    return (
        <>
            <DialogContent>
                <Form formik={ formik } >
                    <Field
                        name="name"
                        label={ t( "common:name" ) }
                        component={ Input }
                    />
                    <UsersFilter
                        ComponentRoot={ "div" }
                        className={ classnames(
                            flex.column,
                            flex.container,
                            cssUtils.margin0,
                            cssUtils.padding0
                        )}
                        error={ formik.errors.teamId && formik.touched?.teamId }
                        input={{
                            team: {
                                value: values.teamId,
                                onChange: value => setFieldValue( "teamId", value )
                            },
                            users: {
                                value: values.usersId,
                                onChange: value => setFieldValue( "usersId", value )
                            }
                        }}
                        fullWidth={ true }
                    />
                    {
                        formik.errors.teamId && formik.touched?.teamId &&
                            <Typography color="error" variant="caption">
                                { t( formik.errors.teamId ) }
                            </Typography>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={ submitting }
                    onClick={ onClose }
                >
                    { t( "common:cancel" ) }
                </Button>
                <Button
                    onClick={ submitForm }
                    disabled={ submitting }
                >
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </>
    );
};

export default DistributionForm;