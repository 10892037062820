export const SAVE_LOSS_REASONS = "x/config/opportunity/SAVE_LOSS_REASONS";
export const saveLossReasons = ({ lossReasons }) => ({
    type: SAVE_LOSS_REASONS,
    payload: { lossReasons }
});

export const DELETE_LOSS_REASON = "x/config/opportunity/DELETE_LOSS_REASON";
export const deleteLossReason = lossReason => ({
    type: DELETE_LOSS_REASON,
    payload: { lossReason }
});