import { useMemo } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FlagIcon from "@mui/icons-material/Flag";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CloudIcon from "@mui/icons-material/Cloud";
import PieChartIcon from "@mui/icons-material/PieChart";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { Link } from "../ui";
import { isMenuShown } from "./layout.selectors";
import { selectors as coreSelectors } from "../core";
import { selectors as subscriptionSelectors } from "../subscription";
import css from "./menu.scss";

// ---------------------------------------------------------------------------------------------------------------------
const Menu = () => {
    const location = useLocation();

    const active = useSelector( isMenuShown );
    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const user = useSelector( coreSelectors.getUser );

    const t = useTranslate();

    const items = useMemo( () => [{
        href: "/welcome",
        icon: FlagIcon,
        module: "welcome",
        admin: true
    }, {
        href: "/home",
        icon: HomeIcon,
        module: "home"
    }, {
        href: "/schedule",
        icon: EventIcon,
        module: "schedule"
    }, {
        href: "/dashboard",
        icon: DashboardIcon,
        module: "dashboard"
    }, {
        href: "/customers",
        icon: GroupIcon,
        module: "customers"
    }, {
        href: "/opportunities",
        icon: AttachMoneyIcon,
        module: "opportunities"
    }, {
        href: "/importations",
        icon: ImportContactsIcon,
        module: "importations"
    }, {
        href: "/leads",
        icon: CloudIcon,
        module: "leads",
        integration: "LEAD"
    }, {
        href: "/reports",
        icon: PieChartIcon,
        module: "reports"
    }, {
        href: "/marketplace",
        icon: StoreIcon,
        module: "marketplace",
        admin: true
    }], [] );

    const pathname = location.pathname.startsWith( "/" ) ?
        location.pathname :
        ( "/" + location.pathname );
    const activeIntegrations = get( subscription, "integrations", [] )
        .filter( item => item.active )
        .map( item => item.integration );
    const item = items
        .filter( item => (
            !item.admin || ( user && user.admin ) ) &&
            !item.integration || activeIntegrations.includes( item.integration ) )
        .map( ( item, index ) => {
            const Icon = item.icon;
            const itemActive = pathname.startsWith( item.href );

            return (
                <Link key={ index } href={ item.href }>
                    <Tooltip
                        title={ t( "layout:modules." + item.module ) }
                        placement="right"
                        classes={{ popper: active ? css.tooltipMenuActive : "" }}
                    >
                        <ListItemButton
                            id={ item.module + "Menu" }
                            classes={{ root: itemActive ? css.menuItemActive : css.menuItemContent }}
                        >
                            <ListItemIcon className={ css.menuItemIcon }>
                                <Icon/>
                            </ListItemIcon>
                            { active && <ListItemText primary={ t( "layout:modules." + item.module ) }/> }
                        </ListItemButton>
                    </Tooltip>
                </Link>
            );
        });

    const secondaryItems = useMemo( () => [
        {
            href: "/config",
            icon: SettingsIcon,
            module: "config",
            admin: true
        }
    ], [] );

    const secondaryItem = secondaryItems
        .filter( item => (
            !item.admin || ( user && user.admin ) ) &&
        !item.integration || activeIntegrations.includes( item.integration ) )
        .map( ( item, index ) => {
            const Icon = item.icon;
            const itemActive = pathname.startsWith( item.href );

            return (
                <Link key={ index } href={ item.href }>
                    <Tooltip
                        title={ t( "layout:modules." + item.module ) }
                        placement="right"
                        classes={{ popper: active ? css.tooltipMenuActive : "" }}
                    >
                        <ListItemButton
                            id={ item.module + "Menu" }
                            classes={{ root: itemActive ? css.menuItemActive : css.menuItemContent }}
                        >
                            <ListItemIcon className={ css.menuItemIcon }>
                                <Icon/>
                            </ListItemIcon>
                            { active && <ListItemText primary={ t( "layout:modules." + item.module ) }/> }
                        </ListItemButton>
                    </Tooltip>
                </Link>
            );
        });

    return (
        <Drawer
            id="menu"
            variant="permanent"
            classes={
                active
                    ? { paper: css.menuPinned }
                    : { paper: css.menu }
            }
        >
            <List className={ css.list }>{ item }</List>
            <div className={ css.secondaryList }>
                <Divider className={ css.dividerLists }/>
                <List className={ css.list }> { secondaryItem }</List>
            </div>
        </Drawer>
    );

};

export default Menu;