import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { Link, cssUtils } from "./";
import css from "./empty-state.scss";

export const EmptyState = ({ className, children, icon: Icon, message, href, linkMessage }) => (
    <div className={ classnames( css.emptyState, className ) }>
        <Avatar className={ css.avatar }><Icon className={ css.icon }/></Avatar>
        <Typography className={ css.message } variant="subtitle1" dangerouslySetInnerHTML={{ __html: message }} />
        {
            href ?
                <Link href={ href } className={ classnames( css.tagline, cssUtils.cursorPointer ) } >
                    { linkMessage }
                </Link>
                : null
        }
        { children }
    </div>
);

export default EmptyState;