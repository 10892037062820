import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Field, FormikProvider } from "formik";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { getSources } from "../../../source/source.selectors";
import { listAll as listAllDistributions } from "../../../config/advanced/distribution/distribution.selectors";
import { listAll as listAllTeams } from "../../../team/team.selectors";
import { listAllActive } from "../../../user/user.selectors";
import { Dropdown, DropdownMultiple, SelectFunnel } from "../../../ui";

const FacebookLeadPageForm = ({ formik }) => {
    const t = useTranslate();

    const sources = useSelector( getSources );
    const distributions = useSelector( listAllDistributions );
    const teams = useSelector( listAllTeams );
    const users = useSelector( listAllActive );

    const { setFieldValue } = formik;

    useEffect( () => {
        if ( !isEmpty( formik.values.distribution?.id ) ) {
            setFieldValue( "teamId", null );
            setFieldValue( "usersId", null );
        }
    }, [ formik.values.distribution, setFieldValue ] );
    useEffect( () => {
        if ( formik.values.teamId ) {
            setFieldValue( "distribution", null );
            setFieldValue( "usersId", null );
        }
    }, [ formik.values.teamId, setFieldValue ] );
    useEffect( () => {
        if ( !isEmpty( formik.values.usersId ) ) {
            setFieldValue( "distribution", null );
            setFieldValue( "teamId", null );
        }
    }, [ formik.values.usersId, setFieldValue ] );

    const errorDistUsers = !formik.values.distribution?.id &&
        isEmpty( formik.values.usersId ) &&
        !formik.values.teamId &&
        formik.touched?.usersId;

    return (
        <FormikProvider value={ formik }>
            <Field
                name="funnel.id"
                component={ SelectFunnel }
                required
            />
            <Field
                name="source.id"
                label={ t( "marketplace:integrations.facebook.lead-pages.source" ) }
                source={ sources.map( source => ({ value: source.id, label: source.name }) ) }
                component={ Dropdown }
                required
            />
            <Field
                name="distribution.id"
                label={ t( "marketplace:integrations.facebook.lead-distribution.distribution" ) }
                component={ Dropdown }
                source={ distributions?.map( distribution => ({ value: distribution.id, label: distribution.name }) ) }
                error={ errorDistUsers }
                emptyValue
            />
            <Field
                name="teamId"
                label={ t( "marketplace:integrations.facebook.lead-distribution.team" ) }
                component={ Dropdown }
                source={ teams.map( team => ({ value: team.id, label: team.name }) ) }
                error={ errorDistUsers }
                emptyValue
            />
            <Field
                name="usersId"
                label={ t( "marketplace:integrations.facebook.lead-distribution.users" ) }
                component={ DropdownMultiple }
                source={
                    users.map( user => ({
                        label: user.name,
                        value: user.id
                    }))
                }
                emptyValue
            />
        </FormikProvider>
    );
};

export default FacebookLeadPageForm;