import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import classNames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchOpportunityHolding } from "../opportunity.actions";
import { getOpportunityHolding } from "../opportunity.selectors";
import { cssUtils, flex } from "../../core";

const OpportunityHolding = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunityHolding = useSelector( getOpportunityHolding );

    useEffect( () => {
        dispatch( fetchOpportunityHolding( opportunity.id ) );
    }, [ dispatch, opportunity.id ] );

    return (
        opportunityHolding ?
            <div>
                <div className={ classNames( flex.container, flex.alignItemsCenter ) }>
                    <Typography color="textSecondary" variant="body2" className={ cssUtils.marginRightSmall }>
                        { t( "opportunity:timeline.opportunity-created-by-holding-source-subscription" ) }
                    </Typography>
                    <Typography variant="subtitle2">{ opportunityHolding.subscription.name }</Typography>
                </div>
                <div className={ flex.container }>
                    <Typography color="textSecondary" variant="body2" className={ cssUtils.marginRightSmall }>
                        { t( "opportunity:timeline.opportunity-created-by-holding-source-user" ) }
                    </Typography>
                    <Typography variant="subtitle2">{ opportunityHolding.user.name }</Typography>
                </div>
            </div>
            : <></>
    );
};

export default OpportunityHolding;