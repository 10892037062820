export default {
    "name": "Nombre",
    "email": {
        "label": "Correo electrónico",
        "help": "El correo electrónico no se puede cambiar."
    },
    "password": "Contraseña",
    "confirm-password": "Confirme su contraseña",
    "accept": {
        "call-to-action": "Registrese para empezar a usar LeadStation de { name }.",
        "button": "Registrar"
    },
    "not-found": "La invitación a la que se accede no existe o ya expiró.",
    "invitation": {
        "action": "Enviar",
        "succeeded": "La invitación al correo electrónico {email} se envió correctamente!"
    }
};