import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./migration.actions";

const migrations = createRequestReducer({
    begin: actions.REQUEST_MIGRATIONS,
    success: actions.RECEIVE_MIGRATIONS,
    failure: actions.ERROR_MIGRATIONS
});

export const migrationConfig = combineReducers({
    migrations
});
