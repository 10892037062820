import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as leadActions from "./lead-integration.actions";

export const leadDistribution = createRequestReducer({
    begin: leadActions.REQUEST_LEAD_DISTRIBUTION,
    success: leadActions.RECEIVE_LEAD_DISTRIBUTION,
    failure: leadActions.ERROR_LEAD_DISTRIBUTION
});

export const leadSources = createRequestReducer({
    begin: leadActions.REQUEST_LEAD_SOURCES,
    success: leadActions.RECEIVE_LEAD_SOURCES,
    failure: leadActions.ERROR_LEAD_SOURCES
});

export const leadIntegration = combineReducers({
    leadDistribution,
    leadSources
});