export function createNewCustomerValidator () {
    return values => {
        const errors = {};

        if ( !values.name || !values.name.length ) {
            errors.name = "common:validation.required";
        }

        return errors;
    };
}
