import { Field } from "formik";
import { useSelector } from "react-redux";
import classnames from "classnames";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as teamSelectors } from "../../../team";
import { flex, cssUtils, Checkbox, Dropdown } from "../../../ui";

const UserTeamsField = ({ form, push, remove }) => {
    const t = useTranslate();

    const teams = useSelector( teamSelectors.listAll );

    const chosenTeams = form.values.config.teams
        .filter( field => field && field.id )
        .map( field => field.id );
    const availableTeams = teams?.filter( team => !chosenTeams.includes( team.id ) );

    if ( isEmpty( teams ) ) {
        return null;
    }

    const rows = form.values.config.teams.map( ( teamSelected, index ) => {
        const sourceTeams = availableTeams.slice();
        // Se ha uma equipe selecionada, adiciona ela nas opcoes
        if ( teamSelected.id ) {
            sourceTeams.push( teams.find( team => team.id === teamSelected.id ) );
        }

        return (
            <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <Field
                    name={ `config.teams[${index}].id` }
                    label={ t("config:new-user.teams.team") }
                    component={ Dropdown }
                    source={ sourceTeams.map( team => ({ label: team.name, value: team.id })) }
                    className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                    required
                />
                <Field
                    type="checkbox"
                    name={ `config.teams[${index}].leader` }
                    component={ Checkbox }
                    label={ t( "config:new-user.teams.leader" ) }
                    fullWidth={ false }
                    className={ classnames( cssUtils.marginLeftSmall, cssUtils.marginBottom0 ) }
                />
                <IconButton onClick={ () => remove( index ) } size="large">
                    <CancelIcon/>
                </IconButton>
            </li>
        );
    });

    return (
        <div>
            <Typography className={ cssUtils.marginTop } variant="caption">
                { t( "config:new-user.teams.label" ) }
            </Typography>
            <List>{ rows }</List>

            { rows.length !== teams.length ?
                <Button
                    className={ cssUtils.marginBottom }
                    size="small"
                    onClick={ () => push({ id: null, leader: false }) }
                    startIcon={ <GroupAddIcon /> }
                >
                    { t( "config:new-user.teams.add" ) }
                </Button>
                : null
            }
        </div>
    );
};

export default UserTeamsField;