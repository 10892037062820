export const FETCH_PHASES = "x/FETCH_PHASES";
export const fetchPhases = forceRequest => ({
    type: FETCH_PHASES,
    meta: { forceRequest }
});

export const REQUEST_PHASES = "x/REQUEST_PHASES";
export const requestPhases = () => ({
    type: REQUEST_PHASES
});

export const RECEIVE_PHASES = "x/RECEIVE_PHASES";
export const receivePhases = () => ({
    type: RECEIVE_PHASES,
});

export const ERROR_PHASES = "x/ERROR_PHASES";
export const errorPhases = error => ({
    type: ERROR_PHASES,
    error
});

export const FETCH_FUNNEL_PHASES = "x/FETCH_FUNNEL_PHASES";
export const fetchFunnelPhases = funnelId => ({
    type: FETCH_FUNNEL_PHASES,
    meta: { funnelId }
});

export const REQUEST_FUNNEL_PHASES = "x/REQUEST_FUNNEL_PHASES";
export const requestFunnelPhases = funnelId => ({
    type: REQUEST_FUNNEL_PHASES,
    meta: { funnelId },
});

export const RECEIVE_FUNNEL_PHASES = "x/RECEIVE_FUNNEL_PHASES";
export const receiveFunnelPhases = ( funnelId, phases ) => ({
    type: RECEIVE_FUNNEL_PHASES,
    meta: { funnelId },
    payload: phases,
});

export const ERROR_FUNNEL_PHASES = "x/ERROR_FUNNEL_PHASES";
export const errorFunnelPhases = ( funnelId, error ) => ({
    type: ERROR_FUNNEL_PHASES,
    meta: { funnelId },
    payload: error,
    error: true,
});

export const RECEIVE_FUNNELS_PHASES = "x/RECEIVE_FUNNELS_PHASES";
export const receiveFunnelsPhases = phases => ({
    type: RECEIVE_FUNNELS_PHASES,
    payload: phases
});

export const FETCH_PHASES_COUNT = "x/FETCH_FUNNEL_PHASES_COUNT";
export const fetchPhasesCount = ( funnelId, params = {} ) => ({
    type: FETCH_PHASES_COUNT,
    meta: { funnelId },
    payload: { params }
});

export const REQUEST_PHASES_COUNT = "x/REQUEST_FUNNEL_PHASES_COUNT";
export const requestPhasesCount = () => ({
    type: REQUEST_PHASES_COUNT,
});

export const RECEIVE_PHASES_COUNT = "x/RECEIVE_FUNNEL_PHASES_COUNT";
export const receivePhasesCount = data => ({
    type: RECEIVE_PHASES_COUNT,
    payload: data,
});

export const ERROR_PHASES_COUNT = "x/ERROR_FUNNEL_PHASES_COUNT";
export const errorPhasesCount = error => ({
    type: ERROR_PHASES_COUNT,
    payload: error,
    error: true,
});