import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EventNoteIcon from "@mui/icons-material/EventNote";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import css from "./timeline.scss";
import { cssUtils } from "../../core";

const SchedulingStatusEvent = ({ event: { user, date, payload } }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    return (
        <TimelineItem
            classes={{
                avatar: payload.change.current === "ACCOMPLISHED" ?
                    css.actionAccomplished :
                    payload.change.current === "NOT_ACCOMPLISHED" ? css.actionNotAccomplished : ""
            }}
            icon={ <EventNoteIcon/> }
        >
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.opportunity-scheduling-status." + payload.change.current ) }
                date={ date } />

            <Card>
                <CardHeader
                    title={ payload.typeName }
                    subheader={ t( "opportunity:scheduling.date" ) + " " + dateFormat( payload.date ) }
                />
                <CardContent>
                    <span>{ payload.description }</span>
                    {
                        payload.change.notAccomplishedReason &&
                        <Typography variant="caption" className={ cssUtils.whiteSpacePreLine }>
                            <br/>
                            { payload.change.notAccomplishedReason }
                        </Typography>
                    }
                </CardContent>
            </Card>
        </TimelineItem>
    );
};

export default SchedulingStatusEvent;