import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { Dropdown, UsersFilter, cssUtils, flex } from "../../../ui";
import { deleteDistribution } from "./distribution.actions";
import { listAll } from "./distribution.selectors";
import { DELETE_DISTRIBUTION_FORM } from "./distribution.constants";

const DeleteDistribution = ( { open, onClose, distribution } ) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const distributions = useSelector( listAll );

    const distributionSource = useMemo( () => {
        return ( distributions || [] ).filter( other => other.id !== distribution.id )
            .map( other =>
                ( { value: other.id, label: other.name } )
            );
    }, [ distribution.id, distributions ] );

    const formik = useForm({
        form: DELETE_DISTRIBUTION_FORM,
        initialValues: { teamId: null, usersId: [], distributionId: null },
        onSubmit: values => dispatch( deleteDistribution( distribution, values ) ),
        validate: values => {
            const errors = {};
            if ( !values.distributionId && !values.teamId && isEmpty( values.usersId ) ) {
                errors.usersId = "marketplace:automation.empty-users";
            }
            return errors;
        }
    });

    const { values, submitting, submitForm, setFieldValue } = formik;

    const handleChange = name => value => {
        if ( !isEmpty( value ) ) {
            setFieldValue( "distributionId", null );
        }
        setFieldValue( name, value );
    };

    return (
        <Dialog open={ open } >
            <DialogTitle >
                { t( "config:distribution.delete-distribution.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } >
                    <DialogContentText>
                        { t( "config:distribution.delete-distribution.detail" ) }
                    </DialogContentText>
                    <Field
                        name="distributionId"
                        label={ t( "marketplace:automation.portal-distribution" ) }
                        component={ Dropdown }
                        error={ formik.errors.usersId && !!formik.touched?.usersId }
                        source={ distributionSource }
                        emptyValue
                    />
                    <UsersFilter
                        ComponentRoot={ "div" }
                        className={ classNames(
                            flex.column,
                            flex.container,
                            cssUtils.margin0,
                            cssUtils.padding0
                        )}
                        error={ formik.errors.usersId && !!formik.touched?.usersId }
                        input={{
                            team: {
                                value: values.teamId,
                                onChange: handleChange( "teamId" )
                            },
                            users: {
                                value: values.usersId,
                                onChange: handleChange( "usersId")
                            }
                        }}
                        fullWidth={ true }
                    />
                    {
                        formik.errors.usersId && !!formik.touched?.usersId &&
                            <Typography color="error" variant="caption">
                                { t( formik.errors.usersId ) }
                            </Typography>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={ submitting }
                    onClick={ onClose }
                >
                    { t( "common:cancel" ) }
                </Button>
                <Button
                    disabled={ submitting }
                    onClick={ submitForm }
                >
                    { t( "common:confirm" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default DeleteDistribution;