import { enqueueSnackbar } from "notistack";
import { useForm } from "formik-redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import usePrevious from "../hooks/use-previous.hook";
import { Link, EmptyState } from "../ui";
import { getUser } from "../core/core.selectors";
import { verifyEmail } from "./user.actions";
import { VERIFY_EMAIL_FORM } from "./user.constants";
import css from "./email-verification.scss";

const EmailVerification = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const t = useTranslate();

    const user = useSelector( getUser );

    const { error, submitForm, submitting } = useForm({
        form: VERIFY_EMAIL_FORM,
        initialValues: {
            token: params.token,
        },
        onSubmit: values => dispatch( verifyEmail( values.token ) ),
    });

    const prevSubmitting = usePrevious( submitting );
    useEffect( () => {
        submitForm();
    }, [ submitForm ] );
    useEffect( () => {
        if ( prevSubmitting && !submitting && !error ) {
            enqueueSnackbar(
                t( "user:email-verification.success" ),
                {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                }
            );
            history.push( user.admin ? "/welcome?first=true" : "/" );
        }
    }, [ t, error, prevSubmitting, submitting, user ] );

    return (
        <>
            {
                error &&
                    <>
                        <EmptyState
                            icon={ ErrorIcon }
                            message={ t( "user:email-verification.error" ) }
                        >
                            <Link href="/">
                                <Button>
                                    { t( "user:email-verification.back" ) }
                                </Button>
                            </Link>
                        </EmptyState>
                    </>
            }
            {
                submitting &&
                    <div className={ css.loadingContainer }>
                        <CircularProgress className={ css.loading } />
                        <Typography>
                            { t( "user:email-verification.loading" ) }
                        </Typography>
                    </div>
            }
        </>
    );
};

export default EmailVerification;