import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isObject from "lodash/isObject";

import { fetchSearchOpportunities } from "../opportunity/opportunity.actions";
import { getSearchOpportunities, isLoadingSearch } from "../opportunity/opportunity.selectors";
import Autocomplete from "./autocomplete.component";

const SelectOpportunity = props => {
    const dispatch = useDispatch();

    const opportunities = useSelector( getSearchOpportunities );
    const loading = useSelector( isLoadingSearch );

    const [ searchTimeout, setSearchTimeout ] = useState();

    const search = query => {
        clearTimeout( searchTimeout );
        if ( query ) {
            setSearchTimeout( setTimeout( () => {
                dispatch( fetchSearchOpportunities({ name: query }) );
            }, 300 ) );
        }
    };

    const source = useMemo( () => ( opportunities || [] ).reduce( ( model, opportunity ) => ({
        ...model,
        [ opportunity.id ]: {
            value: opportunity.id,
            label: opportunity.title,
        },
    }), {} ), [ opportunities ] );

    return (
        <Autocomplete
            { ...props }
            getOptionLabel={ option =>
                isObject( option ) ?
                    source[ option.value ].label :
                    option ? source[ option ].label : ""
            }
            multiple={ false }
            source={ source }
            onQueryChange={ search }
            loading={ loading }
        />
    );
};

export default SelectOpportunity;