import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import * as form from "../../../form";
import { apiv1 } from "../../../../api/sagas";
import { actions as userActions } from "../../../user";
import { CONFIG_FORM } from "./form.constants";
import * as actions from "./form.actions";
import * as selectors from "./form.selectors";
import { modifyFieldsList, createFormName } from "./form.utils";

export function *watchConfigOpportunityForm () {
    yield all([
        takeEvery( actions.INIT_FORM, fetchForm ),
        takeEvery( actions.SAVE_SORTING, saveSorting ),
        takeEvery( actions.SAVE_FIELD, saveField ),
        takeEvery( actions.DELETE_FIELD, deleteField ),
        takeEvery( actions.FETCH_USED_VALUES, fetchUsedValuesSelectField )
    ]);
}

/**
 * Obtem os campos do form selecionado pra configurar.
 */
export function *fetchForm ({ meta }) {
    yield put( form.actions.fetchForm( meta.form ) );
}

/**
 * Salva os campos reordenados.
 * Realiza um request PATCH pra cada campo.
 */
export function *saveSorting () {
    const selectedForm = yield select( selectors.selectedForm );
    const fields = yield select( selectors.listFields );
    const patchedFields = [];

    try {
        yield put( startSubmit( CONFIG_FORM ) );

        for ( const [ index, field ] of fields.entries() ) {
            const response = yield call( apiv1.patch, "/fields/" + field.id, {
                ...field,
                previousFieldId: index > 0 ? patchedFields[ index - 1 ].id : null
            });

            patchedFields.push( response.data );
        }

        yield put( form.actions.receiveForm( selectedForm, patchedFields ) );
        yield put( stopSubmit( CONFIG_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( CONFIG_FORM, e.response.data ) );
    }
}

export function *saveField ({ payload }) {
    const { field } = payload;
    const formName = createFormName( field );
    const selectedForm = yield select( selectors.selectedForm );
    yield put( startSubmit( formName ) );

    try {
        let response;
        if ( field.id ) {
            response = yield call( apiv1.patch, "/fields/" + field.id, field );
        } else {
            response = yield call( apiv1.post, `/forms/${selectedForm}/fields`, field );
        }

        const fieldsSelector = yield call( form.selectors.list, selectedForm );
        const fields = yield select( fieldsSelector );
        const updatedFields = yield call( modifyFieldsList, fields, response.data );
        yield put( form.actions.receiveForm( selectedForm, updatedFields ) );

        yield put( stopSubmit( formName ) );
    } catch ( e ) {
        yield put( stopSubmit( formName, e.response.data ) );
    }
}

export function *deleteField ({ payload }) {
    const field = payload;
    const selectedForm = yield select( selectors.selectedForm );
    yield put( startSubmit( CONFIG_FORM ) );

    try {
        yield call( apiv1.delete, "/fields/" + field.id );
        yield put( form.actions.fetchForm( selectedForm ) );
        yield put( userActions.reloadUserInfo() );
        yield put( stopSubmit( CONFIG_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( CONFIG_FORM, e.response.data ) );
    }
}

export function *fetchUsedValuesSelectField ({ payload }) {
    const { field } = payload;
    yield put( actions.requestUsedValues() );
    try {
        if ( field.id ) {
            const response = yield call( apiv1.get, `/fields/${field.id}/usedvalues` );
            yield put( actions.receiveUsedValues( response.data ) );
        }
    } catch ( e ) {
        yield put( actions.errorUsedValues( e.response.data ) );
    }
}
