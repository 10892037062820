import { useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

import css from "./floating-action.scss";

const FloatingAction = ({ id, ariaLabel, direction = "up", children, ...props }) => {
    const [ open, setOpen ] = useState( false );
    const handleClick = () => setOpen( !open );
    const handleOpen = () => setOpen( true );
    const handleClose = () => setOpen( false );

    let isTouch;
    if ( typeof document !== "undefined") {
        isTouch = "ontouchstart" in document.documentElement;
    }

    return (
        <SpeedDial
            id={ id }
            className={ css.speedDial }
            ariaLabel={ ariaLabel }
            icon={ <SpeedDialIcon/> }
            onBlur={ handleClose }
            onClick={ handleClick }
            onClose={ handleClose }
            onFocus={ isTouch ? undefined : handleOpen }
            onMouseEnter={ isTouch ? undefined : handleOpen }
            onMouseLeave={ handleClose }
            open={ open }
            direction={ direction }
            { ...props }
        >
            { children }
        </SpeedDial>
    );
};

export default FloatingAction;