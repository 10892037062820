import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { fetchUsers } from "../team/team.actions";
import { getUsers } from "../team/team.selectors";
import DeleteTeam from "../team/delete-team.component";

const TeamItem = ({ team, onSelect, showDeleteError }) => {
    const dispatch = useDispatch();

    const users = useSelector( getUsers( team.id ) );

    useEffect( () => {
        dispatch( fetchUsers( team.id ) );
    }, [ dispatch, team.id ] );

    return (
        <ListItem
            secondaryAction={
                <DeleteTeam
                    showDeleteError={ showDeleteError }
                    team={ team }
                />
            }
            disablePadding
        >
            <ListItemButton onClick={ () => onSelect( team ) } >
                <ListItemText
                    primary={ team.name }
                    secondary={ users.map( user => user.name ).join( ", " ) }
                />
            </ListItemButton>
        </ListItem>
    );
};

export default TeamItem;