import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { StepperArrow, StepArrow } from "../ui/stepper-arrow";
import { Dialog, Dropdown, flex } from "../ui";
import { actions as funnelActions, selectors as funnelSelectors } from "../funnel";
import { actions as phaseActions, selectors as phaseSelectors } from "../phase";
import * as formSelectors from "../form/form.selectors";
import { getSelected, isLoadingSelected } from "./opportunity.selectors";
import { patchOpportunity } from "./opportunity.actions";
import { OPPORTUNITY_PHASE_FORM } from "./opportunity.constants";
import PhaseTriggers from "./phase-triggers.component";
import css from "./opportunity.scss";

const OpportunityPhases = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunity = useSelector( getSelected );
    const isLoadingSelectedOpportunity = useSelector( isLoadingSelected );
    const isLoadingAll = useSelector( funnelSelectors.isLoadingAll );
    const isLoadingPhases = useSelector( phaseSelectors.isLoadingFunnelPhases( get( opportunity, "funnelId" ) ) );
    const loading = isLoadingSelectedOpportunity || isLoadingAll || isLoadingPhases;
    const funnels = useSelector( funnelSelectors.listAll );
    const phases = useSelector( phaseSelectors.listWithFunnel( get( opportunity, "funnelId" ) ) ) || [];
    const fields = useSelector( formSelectors.list( "OPPORTUNITY" ) );

    const { error, setFieldValue, submitting, submitForm, values, resetForm } = useForm({
        form: OPPORTUNITY_PHASE_FORM,
        enableReinitialize: true,
        initialValues: {
            id: opportunity.id,
            phase: { id: get( opportunity, "phase.id" ) }
        },
        onSubmit: values => dispatch( patchOpportunity({ ...values, formikForm: OPPORTUNITY_PHASE_FORM }) )
    });

    const prevOpportunity = usePrevious( opportunity );
    const [ confirmTriggers, setConfirmTriggers ] = useState( false );
    const [ showChangeFunnel, setShowChangeFunnel ] = useState( false );
    const [ showConfirmChangeFunnel, setShowConfirmChangeFunnel ] = useState( false );
    const [ lostFieldsFunnel, setLostFieldsFunnel ] = useState( [] );
    const [ showDialog, setShowDialog ] = useState( false );
    const [ showErrors, setShowErrors ] = useState( false );

    const funnel = useMemo( () => {
        return funnels?.find( funnel => funnel.id === opportunity.funnelId );
    }, [ funnels, opportunity.funnelId ] );
    const prevFunnel = usePrevious( funnel );

    const isLate = useMemo( () => {
        if ( opportunity.status === "OPEN" && opportunity.phase.days ) {
            const phaseEntryDate = new Date( opportunity.phaseEntryDate );
            const maxPhaseDate = new Date();
            maxPhaseDate.setDate( maxPhaseDate.getDate() - opportunity.phase.days );

            return phaseEntryDate < maxPhaseDate;
        }

        return false;
    }, [ opportunity.phase.days, opportunity.phaseEntryDate, opportunity.status ] );

    const toggleErrors = useCallback( () => setShowErrors( showErrors => !showErrors ), [] );

    const closeDialog = useCallback( () => {
        resetForm();
        setShowDialog( showDialog => !showDialog );
    }, [ resetForm ] );

    const toggleConfirmTriggers = useCallback( () => setConfirmTriggers( confirmTriggers => !confirmTriggers ), [] );

    const openChangeFunnel = useCallback( () => {
        setFieldValue( "funnelId", opportunity.funnelId );
        setShowChangeFunnel( true );
    }, [ opportunity.funnelId, setFieldValue ] );

    const toggleShowFunnel = useCallback( () => setShowChangeFunnel( showChangeFunnel => !showChangeFunnel ), [] );

    const setPhase = useCallback( phaseId => {
        setFieldValue( "phase.id", phaseId );
        if ( opportunity.status !== "OPEN" ) {
            setShowDialog( true );
        } else {
            submitForm();
        }
    }, [ opportunity.status, setFieldValue, submitForm ] );

    const handleChangePhase = useCallback( () => {
        submitForm();
        closeDialog();
    }, [ closeDialog, submitForm ] );

    const setFunnelId = useCallback( event => {
        setFieldValue( "funnelId", event.target.value );
    }, [ setFieldValue ] );

    const handleConfirmChangeFunnel = () => {
        const fieldFunnel = fields.filter(
            field =>
                !isEmpty( field.funnelsId ) &&
                !field.funnelsId.includes(values.funnelId )
        );

        if ( isEmpty(fieldFunnel) ) {
            handleChangeFunnel();
        } else {
            setLostFieldsFunnel( fieldFunnel );
            setShowConfirmChangeFunnel( true );
        }
    };

    const handleClearFunnel = useCallback( () => {
        setFieldValue( "funnelId", undefined );
        setShowConfirmChangeFunnel( false );
        toggleShowFunnel();
    }, [ setFieldValue, toggleShowFunnel ] );

    const handleChangeFunnel = useCallback( () => {
        setFieldValue( "phase", undefined );
        submitForm();
        setShowConfirmChangeFunnel( false );
        toggleShowFunnel();
    }, [ toggleShowFunnel, setFieldValue, submitForm ] );

    useEffect( () => {
        dispatch( funnelActions.fetchFunnels() );
    }, [ dispatch ] );

    useEffect( () => {
        dispatch( phaseActions.fetchFunnelPhases( opportunity.funnelId ) );
    }, [ dispatch, opportunity ] );

    useEffect( () => {
        if ( get( funnel, "id" ) === get( prevFunnel, "id" ) && !isEqual( funnel, prevFunnel ) ) {
            dispatch( phaseActions.fetchFunnelPhases( opportunity.funnelId ) );
        }
    }, [ opportunity, opportunity.funnelId, funnel, prevFunnel, dispatch ] );

    useEffect( () => {
        if ( !isEmpty( error ) ) {
            toggleErrors();
        }
    }, [ error, toggleErrors ] );

    useEffect( () => {
        if ( prevOpportunity && opportunity !== prevOpportunity ) {
            const triggers = opportunity.phase.triggers;
            if ( !isEmpty( triggers )
                && !isEmpty( triggers.filter( trigger => !trigger.automatic ) )
                && prevOpportunity.phase.id !== opportunity.phase.id ) {
                toggleConfirmTriggers();
            }
        }
    }, [ opportunity, prevOpportunity, toggleConfirmTriggers, dispatch ] );

    if ( loading || !opportunity ) {
        return null;
    }

    return (
        <>
            <Snackbar
                open={ showErrors }
                onClose={ toggleErrors }
                autoHideDuration={ 5000 }
            >
                <Alert severity="error">
                    { error?.error }
                </Alert>
            </Snackbar>
            <div>
                <Dialog
                    open={ showDialog }
                    onClose={ closeDialog }
                >
                    <DialogTitle>
                        { t( "opportunity:phases.dialog.title" ) }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            { t( "opportunity:phases.dialog.change-opportunity-closed" ) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ closeDialog }>
                            { t( "common:no" ) }
                        </Button>
                        <Button color="primary" onClick={ handleChangePhase } disabled={ submitting }>
                            { t( "common:yes" ) }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={ showChangeFunnel } maxWidth="xs" fullWidth>
                    <DialogTitle>
                        { t( "opportunity:change-funnel" ) }
                    </DialogTitle>
                    <DialogContent>
                        <Dropdown
                            label={ t( "opportunity:funnel" ) }
                            value={ values.funnelId }
                            onChange={ setFunnelId }
                            source={ funnels?.map( funnel => ({ value: funnel.id, label: funnel.name })) }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ handleClearFunnel }>
                            { t( "common:cancel" ) }
                        </Button>
                        <Button color="primary" onClick={ handleConfirmChangeFunnel } disabled={ submitting }>
                            { t( "common:save" ) }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={ showConfirmChangeFunnel } maxWidth="xs" fullWidth>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                {
                                    t( "opportunity:confirm-change-funnel.title",
                                        {
                                            funnel: funnels?.find( funnel => funnel.id === values.funnelId )?.name,
                                        }
                                    )
                                }
                            </Typography>
                            {
                                lostFieldsFunnel.map(
                                    field =>
                                        <Typography key={ field.id }>{` - ${field.name}`}</Typography>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            { t( "opportunity:confirm-change-funnel.description" ) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={ handleClearFunnel }>
                            { t( "common:cancel" ) }
                        </Button>
                        <Button color="primary" onClick={ handleChangeFunnel } disabled={ submitting }>
                            { t( "common:confirm" ) }
                        </Button>
                    </DialogActions>
                </Dialog>

                <PhaseTriggers
                    show={ confirmTriggers }
                    onClose={ toggleConfirmTriggers }
                    opportunity={ opportunity }
                />

                <div className={
                    classnames( flex.container, flex.justifyContentSpaceBetween, flex.alignItemsCenter ) }
                >
                    <Typography>
                        { `${t( "opportunity:funnel" )}: ` }
                        { ( funnels?.find( funnel => funnel.id === opportunity.funnelId ) || {} ).name }
                    </Typography>
                    {
                        funnels?.length > 1 &&
                            <Button color="primary" onClick={ openChangeFunnel } startIcon={ <EditIcon/> }>
                                { t( "opportunity:change-funnel" ) }
                            </Button>
                    }
                </div>

                {
                    submitting ?
                        <LinearProgress/> :
                        <StepperArrow selected={ phases.map( phase => phase.id ).indexOf( opportunity.phase.id ) }>
                            { phases.map( ( phase, index ) =>
                                <StepArrow
                                    key={ index }
                                    text={ phase.name }
                                    showTitle
                                    onClick={ () => opportunity.phase.id !== phase.id ? setPhase( phase.id ) : null }
                                />
                            )}
                        </StepperArrow>
                }

                {
                    isLate ?
                        <Typography variant="body1" className={ css.latePhase }>
                            { t(
                                "opportunity:phases.late",
                                { days: opportunity.phase.days, phase: opportunity.phase.name }
                            ) }
                        </Typography>
                        : ""
                }

            </div>
        </>
    );
};

export default OpportunityPhases;