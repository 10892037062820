import { useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Skeleton from "@mui/material/Skeleton";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex } from "../ui";
import { getPreferences } from "../core/core.selectors";
import { saveThemeMode } from "./user.actions";
import { USER_THEME_MODE_FORM } from "./user.constants";
import css from "./user.scss";

const UserThemeMode = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const preferences = useSelector( getPreferences );
    const onSubmit = values => {
        dispatch( saveThemeMode( values ) );
    };

    const formik = useForm({
        enableReinitialize: true,
        form: USER_THEME_MODE_FORM,
        initialValues: preferences,
        onSubmit,
    });

    const handleChange = value => () => formik.setFieldValue( "theme", value );

    return (
        <Card>
            <CardContent>
                <form onSubmit={ formik.handleSubmit }>
                    <FormControl>
                        <FormLabel className={ cssUtils.marginBottomSmall }>{ t( "user:theme.label" ) }</FormLabel>
                        <RadioGroup
                            row
                            name="theme"
                            onChange={ formik.handleChange }
                            value={ formik.values.theme }
                        >
                            <div className={ classnames( flex.container, flex.column, flex.alignItemsCenter ) }>
                                <Paper
                                    className={ classnames(
                                        cssUtils.cursorPointer,
                                        cssUtils.paddingSmall,
                                        flex.container,
                                        flex.column,
                                        flex.rowGapSmall,
                                        css.lightTheme,
                                    ) }
                                    onClick={ handleChange( "LIGHT" ) }
                                >
                                    <Skeleton animation={ false } variant="text" />
                                    <Skeleton animation={ false } variant="circular" width={ 40 } height={ 40 } />
                                    <Skeleton animation={ false } variant="rectangular" width={ 210 } height={ 60 } />
                                </Paper>
                                <FormControlLabel
                                    value="LIGHT"
                                    control={ <Radio /> }
                                    label={ t( "user:theme.light" ) }
                                />
                            </div>
                            <div
                                className={ classnames(
                                    flex.container,
                                    flex.column,
                                    flex.alignItemsCenter,
                                    cssUtils.marginLeft,
                                ) }>
                                <Paper
                                    className={ classnames(
                                        cssUtils.cursorPointer,
                                        cssUtils.paddingSmall,
                                        flex.container,
                                        flex.column,
                                        flex.rowGapSmall,
                                        css.darkTheme,
                                    ) }
                                    onClick={ handleChange( "DARK" ) }
                                >
                                    <Skeleton animation={ false } variant="text" />
                                    <Skeleton animation={ false } variant="circular" width={ 40 } height={ 40 } />
                                    <Skeleton animation={ false } variant="rectangular" width={ 210 } height={ 60 } />
                                </Paper>
                                <FormControlLabel
                                    value="DARK"
                                    control={ <Radio /> }
                                    label={ t( "user:theme.dark" ) }
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </form>
            </CardContent>
            <CardActions>
                <Button onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </CardActions>
        </Card>
    );
};

export default UserThemeMode;