import { useEffect, useMemo } from "react";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Fields, validateFields } from "../form";
import { fetchForm } from "../form/form.actions";
import * as formSelectors from "../form/form.selectors";
import { setOpportunity } from "../opportunity/opportunity.actions";
import { getSelected as getOpportunitySelected } from "../opportunity/opportunity.selectors";
import { saveCustomer } from "./customer.actions";
import { getSelected, isLoadingSelected } from "./customer.selectors";
import { EDIT_CUSTOMER_FORM } from "./customer.constants";
import css from "./customer.scss";

// ---------------------------------------------------------------------------------------------------------------------
// Utils
// ---------------------------------------------------------------------------------------------------------------------
const listCustomerFields = type => formSelectors.list( type );
const areCustomerFieldsLoading = type => formSelectors.isLoading( type );
const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const CustomerForm = ({ opportunityKey }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );
    const opportunity = useSelector( getOpportunitySelected );
    const fields = useSelector( listCustomerFields( customer?.type ) );
    const loadingCustomer = useSelector( isLoadingSelected );
    const loadingForm = useSelector( areCustomerFieldsLoading( customer?.type ) );
    const loading = loadingCustomer || loadingForm;

    const initialValues = useMemo( () => {
        if ( !customer ) {
            return {};
        }
        return {
            ...customer,
            fields: {
                ...fieldsToInitialValues( fields ),
                ...customer.fields
            }
        };
    }, [ customer, fields ] );

    const formik = useForm({
        form: EDIT_CUSTOMER_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveCustomer( values ) ),
        validate: values => validateFields( values, { t, fields } ),
    });

    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        dispatch( fetchForm( customer.type ) );
    }, [ dispatch, customer.type ] );
    useEffect( () => {
        if ( prevSubmitting && !formik.submitting && opportunityKey ) {
            dispatch( setOpportunity({
                ...opportunity,
                [ opportunityKey ]: customer
            }) );
        }
    }, [ dispatch, prevSubmitting, formik.submitting, customer, opportunity, opportunityKey ] );

    return (
        <div className={ css.containerContent }>
            <div className={ css.content }>
                <Card>
                    { loading ?
                        <LinearProgress/> :
                        <Form formik={ formik } noValidate>
                            <CardContent className={ css.containerForm }>
                                <Fields fields={ fields }/>
                            </CardContent>
                            <CardActions>
                                <Button
                                    id="customerFormButton"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={ formik.submitting }
                                >
                                    { t( "common:save" ) }
                                </Button>
                                {
                                    formik.error &&
                                        <Typography color="error" variant="body1">
                                            { formik.error }
                                        </Typography>
                                }
                            </CardActions>
                        </Form>
                    }
                </Card>
            </div>
        </div>
    );
};

export default CustomerForm;