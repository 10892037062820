import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { fetchLead } from "./web-capture.actions";
import { isLoadingLead, getLead } from "./web-capture.selectors";
import { cssUtils } from "../ui";

const LeadBody = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const loading = useSelector( isLoadingLead );
    const lead = useSelector( getLead );

    useEffect( () => {
        dispatch( fetchLead( params.id ) );
    }, [ dispatch, params.id ] );

    if ( loading || !lead ) {
        return <div className={ cssUtils.padding }><CircularProgress/></div>;
    }

    return (
        <div className={ cssUtils.textCenter }>
            <div dangerouslySetInnerHTML={{ __html: lead.body }}/>
        </div>
    );
};

export default LeadBody;