import { startSubmit as startSubmitFormik, stopSubmit as stopSubmitFormik, reset } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { apiv1, apiv2 } from "../../api/sagas";
import history from "../../history";
import tracker from "../util/tracker.utils";
import {
    EDIT_CUSTOMER,
    EDIT_CUSTOMER_FORM,
    NEW_CUSTOMER_FORM,
    GENERATE_OPPORTUNITIES_FORM,
    UPDATE_CUSTOMERS_FORM,
    EXPORTATION_FORM,
    SAVE_COMPANY_FORM,
    ADD_PERSON_FORM,
    NEW_EMAIL_FORM,
    NEW_PHONE_CALL_FORM,
    NEW_SMS_FORM,
    FOLLOWING_FORM,
    CUSTOMER_FILE_FORM,
    CUSTOMER_AVATAR_FORM,
    NEW_COMMENT_FORM,
    DELETE_CUSTOMER_FORM,
    actions,
    selectors
} from "./";
import { selectors as opportunitySelectors, actions as opportunityActions } from "../opportunity";
// ---------------------------------------------------------------------------------------------------------------------
// Geral
// ---------------------------------------------------------------------------------------------------------------------
export function *watchCustomer () {
    yield all([
        takeEvery( actions.FETCH_CUSTOMERS_LIST, fetchCustomersList ),
        takeEvery( actions.FETCH_SEARCH_CUSTOMERS, fetchSearchCustomers ),
        takeEvery( actions.CREATE_EXPORTATION, createExportation ),
        takeEvery( actions.GENERATE_OPPORTUNITIES, generateOpportunities ),
        takeEvery( actions.UPDATE_CUSTOMERS, updateCustomers ),
        takeEvery( actions.FETCH_STATISTICS, fetchStatistics ),
        takeEvery( actions.FETCH_TIMELINE, fetchTimeline ),
        takeEvery( actions.FETCH_OPPORTUNITIES, fetchOpportunities ),
        takeEvery( actions.FETCH_CUSTOMER, fetchCustomer ),
        takeEvery( actions.SAVE_CUSTOMER, saveCustomer ),
        takeEvery( actions.PATCH_CUSTOMER, patchCustomer ),
        takeEvery( actions.SAVE_COMPANY, saveCompany ),
        takeEvery( actions.SAVE_PERSON, savePerson ),
        takeEvery( actions.DELETE_PERSON, deletePerson ),
        takeEvery( actions.FETCH_PERSONS, fetchPersons ),
        takeEvery( actions.FETCH_FOLLOWING, fetchFollowing ),
        takeEvery( actions.SAVE_FOLLOWING, saveFollowing ),
        takeEvery( actions.SAVE_AVATAR, saveAvatar ),
        takeEvery( actions.SAVE_FILE, saveFile ),
        takeEvery( actions.FETCH_FILES, fetchFiles ),
        takeEvery( actions.DELETE_FILE, deleteFile ),
        takeEvery( actions.FETCH_TAGS, fetchTags ),
        takeEvery( actions.SAVE_NEW_EMAIL, sendEmail ),
        takeEvery( actions.SAVE_NEW_PHONE_CALL, saveNewPhoneCall ),
        takeEvery( actions.SAVE_NEW_SMS, sendSms ),
        takeEvery( actions.OPEN_WHATSAPP, openWhatsApp ),
        takeEvery( actions.SAVE_NEW_CUSTOMER, createCustomer ),
        takeEvery( actions.SAVE_COMMENT, saveComment ),
        takeEvery( actions.DELETE_CUSTOMER, deleteCustomer ),
        takeEvery( actions.FETCH_COMPANY_DATA, fetchCompanyData ),
    ]);
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer list
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchCustomersList ({ payload }) {
    yield put( actions.requestCustomersList() );
    try {
        const response = yield call( apiv1.get, "/customers", { params: payload } );
        const customers = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveCustomersList({ customers, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorCustomersList( e.response.data ) );
    }
}

export function *fetchSearchCustomers ({ payload }) {
    yield put( actions.requestSearchCustomers() );
    try {
        const response = yield call( apiv1.get, "/customers/search", { params: payload } );
        yield put( actions.receiveSearchCustomers( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSearchCustomers( e.response.data ) );
    }
}

export function *createExportation ({ payload }) {
    yield put( startSubmitFormik( EXPORTATION_FORM ) );

    try {
        yield call( apiv1.post, "/customers/exports", payload );
        tracker.logAction( "Customer Export" );
        yield put( stopSubmitFormik( EXPORTATION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( EXPORTATION_FORM, e.response.data ) );
    }
}

export function *generateOpportunities ({ payload }) {
    yield put( startSubmitFormik( GENERATE_OPPORTUNITIES_FORM ) );

    try {
        yield call( apiv1.post, "/customers/opportunities", payload );
        tracker.logAction( "Customer Generate Opportunities" );
        yield put( stopSubmitFormik( GENERATE_OPPORTUNITIES_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( GENERATE_OPPORTUNITIES_FORM, e.response.data ) );
    }
}

function *updateCustomers ({ payload }) {
    yield put( startSubmitFormik( UPDATE_CUSTOMERS_FORM ) );

    try {
        yield call( apiv1.patch, "/customers", payload );
        yield put( stopSubmitFormik( UPDATE_CUSTOMERS_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( UPDATE_CUSTOMERS_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Single customer
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchCustomer ({ meta: { id } }) {
    yield put( actions.requestCustomer() );

    try {
        const response = yield call( apiv1.get, "/customers/" + id );
        yield put( actions.setCustomer( response.data ) );
        yield put( opportunityActions.setOpportunity( null ) );
    } catch ( e ) {
        yield put( actions.errorCustomer( e.response.data ) );
    }
}

export function *fetchStatistics ({ meta: { id } }) {
    yield put( actions.requestStatistics() );

    try {
        const response = yield call( apiv2.get, `/customers/${id}/statistics` );
        yield put( actions.receiveStatistics( response.data ) );
    } catch ( e ) {
        yield put( actions.errorTimeline( e.response.data ) );
    }
}

export function *fetchTimeline ({ meta: { id }, payload }) {
    yield put( actions.requestTimeline() );

    try {
        const params = {};
        if ( !isEmpty( payload.types ) ) {
            params.types = payload.types.toString();
        }
        params.page = payload.page;
        const response = yield call( apiv1.get, `/customers/${id}/timeline`, { params } );
        const events = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        yield put( actions.receiveTimeline({ events, totalPages }) );
    } catch ( e ) {
        yield put( actions.errorTimeline( e.response.data ) );
    }
}

export function *saveComment ({ meta: { id }, payload: { comment } }) {
    yield put( startSubmitFormik( NEW_COMMENT_FORM ) );

    try {
        yield call( apiv1.post, `/customers/${id}/comments`, { comment } );
        yield put( stopSubmitFormik( NEW_COMMENT_FORM ) );
        yield put( actions.reloadTimeline() );
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_COMMENT_FORM, e.response.data ) );
    }
}

export function *saveCustomer ( action ) {
    const { meta: { id }, payload: { form = EDIT_CUSTOMER_FORM, ...payload } } = action;
    yield put( startSubmitFormik( form ) );

    try {
        const response = yield call( apiv1.put, "/customers/" + id, payload );
        yield put( actions.setCustomer( response.data ) );
        tracker.logAction( "Customer Updated" );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, get( e.response.data, "error" ) ) );
    }
}

export function *patchCustomer ({ meta: { id }, payload: { form = EDIT_CUSTOMER, ...payload } }) {
    yield put( startSubmitFormik( form ) );

    try {
        const response = yield call( apiv1.patch, `/customers/${id}`, payload );
        yield put( actions.setCustomer( response.data ) );
        yield put( actions.reloadTimeline() );
        tracker.logAction( "Customer Updated" );
        yield put( stopSubmitFormik( form ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( form, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Company
// ---------------------------------------------------------------------------------------------------------------------
export function *saveCompany ({ meta: { id }, payload: { company } }) {
    yield put( startSubmitFormik( SAVE_COMPANY_FORM ) );

    try {
        let companyId;
        if ( company.id ) {
            companyId = company.id;
        } else {
            const response = yield call( apiv1.post, "/customers", company );
            companyId = response.data.id;
        }
        const customer = yield call( apiv1.patch, `/customers/${id}`, { company: { id: companyId } } );
        yield put( actions.setCustomer( customer.data ) );
        tracker.logAction( "Customer Save Company" );
        yield put( stopSubmitFormik( SAVE_COMPANY_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( SAVE_COMPANY_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Person
// ---------------------------------------------------------------------------------------------------------------------
export function *savePerson ({ meta: { id }, payload: { person } }) {
    yield put( startSubmitFormik( ADD_PERSON_FORM ) );

    try {
        if ( person.id ) {
            yield call( apiv1.patch, `/customers/${person.id}`, { company: { id } } );
        } else {
            yield call( apiv1.post, "/customers", { ...person, company: { id } } );
        }

        yield put( actions.fetchPersons( id ) );
        tracker.logAction( "Customer Save Person" );
        yield put( stopSubmitFormik( ADD_PERSON_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( ADD_PERSON_FORM, e.response.data ) );
    }
}

export function *deletePerson ({ meta: { id }, payload: { company, opportunity } }) {
    try {
        yield put( actions.deletingPerson( true ) );

        if ( opportunity ) {
            const response = yield call( apiv1.patch, `/opportunities/${opportunity.id}`, { personId: null } );
            yield put( opportunityActions.setOpportunity( response.data ) );
        } else {
            yield call( apiv1.patch, `/customers/${id}`, { company: null } );
        }
        yield put( actions.fetchPersons( company.id ) );
        yield put( actions.deletingPerson( false ) );
    } catch ( e ) {
        yield put( actions.deletingPerson( false ) );
    }
}

export function *fetchPersons ({ meta: { id } }) {
    yield put( actions.requestPersons() );

    try {
        const response = yield call( apiv1.get, `/customers/${id}/persons` );
        yield put( actions.receivePersons( response.data ) );
    } catch ( e ) {
        yield put( actions.errorPersons( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Following
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchFollowing ({ meta: { id }}) {
    yield put( actions.requestFollowing() );
    try {
        const response = yield call( apiv1.get, `/customers/${id}/following` );
        yield put( actions.receveiFollowing( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFollowing( e.response.data ) );
    }
}

export function *saveFollowing ({ meta: { id }, payload: { following }}) {
    yield put( startSubmitFormik( FOLLOWING_FORM ) );

    try {
        if ( following ) {
            yield call( apiv1.delete, `/customers/${id}/following` );
        } else {
            yield call( apiv1.put, `/customers/${id}/following` );
        }
        yield put( actions.receveiFollowing( !following ) );
        yield put( stopSubmitFormik( FOLLOWING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( FOLLOWING_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Files
// ---------------------------------------------------------------------------------------------------------------------
export function *saveAvatar ({ meta: { id }, payload: { avatar }}) {

    yield put( startSubmitFormik( CUSTOMER_AVATAR_FORM ) );
    try {
        let response;
        if ( avatar ) {
            const formData = new FormData();
            formData.append( "avatar", avatar );
            response = yield call( apiv1.put, `/customers/${id}/avatar`, formData );
        } else {
            response = yield call( apiv1.delete, `/customers/${id}/avatar` );
        }
        yield put( actions.setCustomer( response.data ) );
        yield put( stopSubmitFormik( CUSTOMER_AVATAR_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( CUSTOMER_AVATAR_FORM, e.response.data ) );
    }
}

export function *saveFile ({ meta: { id }, payload }) {

    yield put( startSubmitFormik( CUSTOMER_FILE_FORM ) );
    try {
        const { fileName, opportunityId, file } = payload;
        const params = {
            fileName,
            opportunityId
        };
        const formData = new FormData();
        formData.append( "file", file );
        yield call( apiv1.put, `/customers/${id}/files`, formData, { params } );
        yield put( actions.fetchFiles( id ) );
        tracker.logAction( "Customer Add File" );
        yield put( stopSubmitFormik( CUSTOMER_FILE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( CUSTOMER_FILE_FORM, e.response.data ) );
    }
}

export function *fetchFiles ({ meta: { id } }) {
    yield put( actions.requestFiles() );

    try {
        const response = yield call( apiv1.get, `/customers/${id}/files` );
        yield put( actions.receiveFiles( response.data ) );
    } catch ( e ) {
        yield put( actions.errorFiles( e.response.data ) );
    }
}

export function *deleteFile ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( CUSTOMER_FILE_FORM ) );
    try {
        const { customerId } = payload;
        yield call( apiv1.delete, `/customers/files/${id}` );
        yield put( actions.fetchFiles( customerId ) );
        yield put( stopSubmitFormik( CUSTOMER_FILE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmitFormik( CUSTOMER_FILE_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Tags
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchTags ({ payload: { search } }) {
    yield put( actions.requestTags() );

    try {
        const response = yield call( apiv1.get, "/customers/tags", { params: { search } });
        yield put( actions.receiveTags( response.data ) );
    } catch ( e ) {
        yield put( actions.errorTags( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer opportunities
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchOpportunities ({ meta: { id } }) {
    yield put( actions.requestOpportunities() );

    try {
        const response = yield call( apiv1.get, `/customers/${id}/opportunities` );
        yield put( actions.receiveOpportunities( response.data ) );
    } catch ( e ) {
        yield put( actions.errorOpportunities( e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer Email
// ---------------------------------------------------------------------------------------------------------------------
export function *sendEmail ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( NEW_EMAIL_FORM ) );

    try {
        const formData = new FormData();
        Object.keys( payload )
            .filter( key => !!payload[ key ] )
            .filter( key => key !== "attachments" )
            .forEach( key => formData.append( key, payload[ key ] ) );
        if ( payload.attachments ) {
            payload.attachments.forEach( attachment => formData.append( "attachments", attachment ) );
        }
        yield call( apiv1.post, `/customers/${id}/emails`, formData );
        yield put( stopSubmitFormik( NEW_EMAIL_FORM ) );
        yield put( reset( NEW_EMAIL_FORM ) );
        tracker.logAction( "Customer E-mail Sent" );
        yield put( actions.closeNewEmail() );
        if ( yield select( opportunitySelectors.getSelected ) ) {
            yield put( opportunityActions.reloadTimeline() );
        } else {
            yield put( actions.reloadTimeline() );
        }
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_EMAIL_FORM, e.response.data ? e.response.data : e.response ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer PhoneCall
// ---------------------------------------------------------------------------------------------------------------------
export function *saveNewPhoneCall ({ payload }) {
    yield put( startSubmitFormik( NEW_PHONE_CALL_FORM ) );

    try {
        let customer = yield select( selectors.getSelected );
        const opportunity = yield select( opportunitySelectors.getSelected );
        if ( opportunity ) {
            payload.opportunityId = opportunity.id;
            customer = opportunity.person ? opportunity.person : opportunity.company;
        }

        yield call( apiv1.post, `/customers/${customer.id}/phonecalls`, payload );
        yield put( stopSubmitFormik( NEW_PHONE_CALL_FORM ) );
        yield put( reset( NEW_PHONE_CALL_FORM ) );
        tracker.logAction( "Customer SMS Sent" );

        yield put( actions.reloadTimeline() );
        if ( opportunity && opportunity.id ) {
            yield put( opportunityActions.reloadTimeline() );
        }
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_PHONE_CALL_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer Sms
// ---------------------------------------------------------------------------------------------------------------------
export function *sendSms ({ meta: { id }, payload }) {
    yield put( startSubmitFormik( NEW_SMS_FORM ) );

    try {
        yield call( apiv1.post, `/customers/${id}/sms`, payload );
        yield put( stopSubmitFormik( NEW_SMS_FORM ) );
        tracker.logAction( "Customer SMS Sent" );
        if ( yield select( opportunitySelectors.getSelected ) ) {
            yield put( opportunityActions.reloadTimeline() );
        } else {
            yield put( actions.reloadTimeline() );
        }
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_SMS_FORM, e.response.data.error ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Customer WhatsApp
// ---------------------------------------------------------------------------------------------------------------------
export function *openWhatsApp ({ payload }) {
    try {
        const customer = ( yield select( selectors.getSelected ) ) || {};
        const opportunity = yield select( opportunitySelectors.getSelected );
        if ( opportunity && opportunity.id ) {
            payload.opportunityId = opportunity.id;
            if ( !isEmpty( opportunity.person ) ) {
                customer.id = opportunity.person.id;
            } else if ( !isEmpty( opportunity.company ) ) {
                customer.id = opportunity.company.id;
            }
        }
        yield call( apiv1.post, `/customers/${customer.id}/whatsapps`, payload );
        if ( opportunity && opportunity.id ) {
            yield put( opportunityActions.reloadTimeline() );
        } else {
            yield put( actions.reloadTimeline() );
        }
        tracker.logAction( "Customer Whatsapp Opened" );
    } catch ( e ) {
        // la la land
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// New customer
// ---------------------------------------------------------------------------------------------------------------------
export function *createCustomer ( action ) {
    yield put( startSubmitFormik( NEW_CUSTOMER_FORM ) );

    try {
        const response = yield call( apiv1.post, "/customers", action.payload.customer );
        yield put( stopSubmitFormik( NEW_CUSTOMER_FORM ) );
        yield put( actions.closeNewCustomer() );
        yield put( actions.setCustomer( response.data ) );
        yield put( reset( NEW_CUSTOMER_FORM ) );
        tracker.logAction( "New Customer" );
        yield call( [ history, history.push ], `/customers/${response.data.id}` );
    } catch ( e ) {
        yield put( stopSubmitFormik( NEW_CUSTOMER_FORM, get( e.response.data, "error" ) ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Delete customer
// ---------------------------------------------------------------------------------------------------------------------
export function *deleteCustomer ({ meta: { id } }) {
    yield put( startSubmitFormik( DELETE_CUSTOMER_FORM ) );

    try {
        yield call( apiv1.delete, `/customers/${ id }` );
        yield put( stopSubmitFormik( DELETE_CUSTOMER_FORM ) );
        yield call( [ history, history.push ], "/customers" );
    } catch ( e ) {
        yield put( stopSubmitFormik( DELETE_CUSTOMER_FORM, e.response.data ) );
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// CompanyData
// ---------------------------------------------------------------------------------------------------------------------
export function *fetchCompanyData ({ meta: { ein } }) {
    yield put( actions.requestCompanyData() );
    try {
        const response = yield call( apiv1.get, `/companydata/${ein}` );
        yield put( actions.receiveCompanyData( response.data ) );
    } catch ( e ) {
        yield put( actions.errorCompanyData( e.response.data ) );
    }
}