import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

import useTranslate from "../../../hooks/use-translate.hook";
import KanbanFieldItem from "./kanban-field-item.component";

const MAX_SIZE = 3;

const KanbanField = ({ push, remove, form }) => {
    const t = useTranslate();

    const handleAdd = () => push({ formType: "OPPORTUNITY", fieldId: null });

    return (
        <>
            <Typography>
                { t( "config:kanban.other-fields", { selected: MAX_SIZE - form.values.fields.length, max: MAX_SIZE } ) }
            </Typography>
            <List>
                {
                    form.values.fields.map( ( item, index ) => (
                        <KanbanFieldItem
                            key={ index }
                            index={ index }
                            onRemove={ () => remove( index ) }
                            setFieldValue={ form.setFieldValue }
                            values={ item }
                        />
                    ) )
                }
            </List>
            <Button
                startIcon={ <AddIcon /> }
                onClick={ handleAdd }
                disabled={ MAX_SIZE === form.values.fields.length }
            >
                { t( "common:add" ) }
            </Button>
        </>
    );
};

export default KanbanField;