import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import css from "./marketplace.scss";

const MarketplaceItem = ({
    children,
    expired,
    loading,
    onClick,
    quantity,
    title,
}) => (
    <Card>
        <CardActionArea onClick={ onClick } disabled={ loading }>
            <CardContent className={ css.cardContent }>
                { children }
            </CardContent>
            <Divider/>
            {
                loading ?
                    <LinearProgress /> :
                    <ListItem component="div">
                        <ListItemText primary={ title } />
                        {
                            quantity > 0 &&
                                <Badge
                                    badgeContent={ quantity }
                                    color={ expired ? "error" : "success" }
                                    overlap="circular"
                                >
                                    <DoneAllIcon/>
                                </Badge>
                        }
                    </ListItem>
            }
        </CardActionArea>
    </Card>
);

export default MarketplaceItem;
