import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import qs from "qs";

import useTranslate from "../hooks/use-translate.hook";
import history from "../../history";
import { getUser } from "../core/core.selectors";
import { Link } from "../ui";
import WelcomeDialog from "./welcome-dialog.component";

const Welcome = ({ className }) => {
    const location = useLocation();

    const t = useTranslate();

    const queryString = qs.parse( location.search, { ignoreQueryPrefix: true });

    const user = useSelector( getUser );

    const [ firstAccess, setFirstAccess ] = useState( !!queryString.first );

    const closeFirstAccess = () => {
        history.push({ ...location, search: "" });
        setFirstAccess( false );
    };

    return (
        <div className={ className }>
            <WelcomeDialog open={ firstAccess } onClose={ closeFirstAccess } user={ user } />
            <Card>
                <CardHeader
                    title={ t( "welcome:title", { userName: user.name } ) }
                    subheader={ t( "welcome:intro" ) } />
                <List>
                    <Link href="/config/users" hrefProp="to">
                        <ListItemButton>
                            <ListItemIcon><PersonAddIcon/></ListItemIcon>
                            <ListItemText
                                primary={ t( "welcome:add-users.title" ) }
                                secondary={ t( "welcome:add-users.description" ) }
                            />
                        </ListItemButton>
                    </Link>
                    <Link href="/config/users/team" hrefProp="to">
                        <ListItemButton>
                            <ListItemIcon><PersonAddIcon/></ListItemIcon>
                            <ListItemText
                                primary={ t( "welcome:add-teams.title" ) }
                                secondary={ t( "welcome:add-teams.description" ) }
                            />
                        </ListItemButton>
                    </Link>
                </List>
            </Card>
        </div>
    );
};

export default Welcome;