import classnames from "classnames";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import omit from "lodash/omit";
import qs from "qs";

import useTranslate from "../hooks/use-translate.hook";
import { isEmail } from "../core/validator.utils";
import { cssUtils, DialogInfo, Input, Link } from "../ui";
import { actions, LOGIN_FORM } from "./";

const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const [ requestingActivate, setRequestingActivate ] = useState( false );
    const [ requestActivateSuccess, setRequestActivateSuccess ] = useState( false );

    const queryProps = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location ] );
    const nextUrl = queryProps.next ?
        `${queryProps.next}${qs.stringify( omit( queryProps, "next" ), { encode: false } )}` :
        "/";

    const formik = useForm({
        form: LOGIN_FORM,
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: values => dispatch( actions.loginSubmit( values, nextUrl ) ),
        onSubmitSuccess: () => {
            if ( requestActivateSuccess ) {
                setRequestActivateSuccess( false );
            }
        },
        validate: values => {
            const errors = {};
            if ( !values.email || !values.email.length ) {
                errors.email = "common:validation.required";
            } else if ( !isEmail( values.email ) ) {
                errors.email = "common:validation.email";
            }

            if ( !values.password || !values.password.length ) {
                errors.password = "common:validation.required";
            }
            return errors;
        }
    });

    const toggleRequestingActivate = () => setRequestingActivate( !requestingActivate );
    const toggleRequestActivateSuccess = () => setRequestActivateSuccess( !requestActivateSuccess );

    const handleRequestActivate = () => {
        toggleRequestingActivate();
        dispatch( actions.requestActivate( formik.values.email ) );
    };

    const handleBlurEmail = event => {
        if ( event.target.value ) {
            formik.setFieldValue( "email", event.target.value.toLowerCase() );
        }
    };

    return (
        <div>
            <Helmet title={ t( "login:title" ) } />
            <Form role="form" formik={ formik } noValidate>
                <Field
                    type="email"
                    name="email"
                    label={ t( "login:email" ) }
                    component={ Input }
                    onBlur={ handleBlurEmail }
                    icon={ <MailIcon/> }
                    required
                />

                <Field
                    type="password"
                    name="password"
                    label={ t( "login:password" ) }
                    component={ Input }
                    className={ cssUtils.marginBottomSmall }
                    icon={ <LockIcon/> }
                    required
                />

                <Link
                    href="/forgotpassword"
                    className={ classnames( cssUtils.textCenter, cssUtils.cursorPointer ) }
                >
                    <Typography
                        className={ cssUtils.marginBottomSmall }>
                        { t( "login:login.forgot-password" ) }
                    </Typography>
                </Link>

                {
                    formik.error &&
                        <>
                            <Alert
                                severity="error"
                                className={ cssUtils.marginBottomSmall }
                            >
                                { formik.error.key ? t( formik.error.key ) : formik.error.error }
                            </Alert>

                            {
                                formik.error.status === 410 &&
                                    <>
                                        <Typography align="center" color="textSecondary" variant="subtitle2">
                                            { t( "login:request-activate.description" ) }
                                        </Typography>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={ formik.submitting }
                                            className={ classnames(
                                                cssUtils.marginTopSmall,
                                                cssUtils.marginBottom,
                                                cssUtils.fullWidth
                                            ) }
                                            onClick={ handleRequestActivate }
                                        >
                                            { t( "login:request-activate.action" ) }
                                        </Button>
                                    </>
                            }
                        </>
                }

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={ formik.submitting }
                    className={ cssUtils.fullWidth }>
                    { t( "login:login.action" ) }
                </Button>
            </Form>

            <hr />

            <Link
                href="/signup"
                className={ classnames( cssUtils.textCenter, cssUtils.cursorPointer ) }
            >
                <Typography>
                    { t( "login:signup.call-to-action" ) }
                </Typography>
            </Link>

            <DialogInfo
                open={ requestActivateSuccess }
                onClose={ toggleRequestActivateSuccess }
                maxWidth="xs"
                message={ t( "login:request-activate.success" ) }
            />
        </div>
    );
};

export default Login;