import * as actions from "./login.actions";

export function auth ( state, action ) {
    switch ( action.type ) {
        case actions.SET_AUTH:
            return action.payload;

        default:
            return state || {};
    }
}

export function passwordReset ( state, action ) {
    switch ( action.type ) {
        case actions.SET_PASSWORD_RESET_STATUS:
            return Object.assign( {}, state, {
                status: action.payload
            });

        default:
            return state || {};
    }
}