import { useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import order from "lodash/orderBy";

import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { downloadFile } from "../util/file.utils";
import { formatBytes } from "../core/formatter.utils";
import { NewFileFromModel, selectors as fileSelectors } from "../file";
import { fetchFiles, saveFile, deleteFile } from "./opportunity.actions";
import { OPPORTUNITY_FILE_FORM } from "./opportunity.constants";
import { getSelected, getFiles, isLoadingFiles } from "./opportunity.selectors";
import PreviewFile from "../ui/preview-file.component";
import { Table } from "../ui/table";
import css from "./opportunity.scss";

const OpportunityFiles = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const opportunity = useSelector( getSelected );
    const files = useSelector( getFiles );
    const fileModels = useSelector( fileSelectors.getModels );
    const loading = useSelector( isLoadingFiles );

    const hasModels = !isEmpty( fileModels );

    const [ showError, setShowError ] = useState( false );
    const [ showFileFromModel, setShowFileFromModel ] = useState( false );
    const [ orderBy, setOrderBy ] = useState( "uploadedAt" );
    const [ orderType, setOrderType ] = useState( "ASC" );
    const [ showPreviewFile, setShowPreviewFile ] = useState( false );
    const [ fileToDownload, setFileToDownload ] = useState( {} );

    const fileInput = useRef();

    const toggleError = () => setShowError( !showError );

    const toggleFileFromModel = () => setShowFileFromModel( !showFileFromModel );

    const formik = useForm({
        form: OPPORTUNITY_FILE_FORM,
        initialValues: {
            opportunityId: opportunity.id
        },
        onSubmit: values => dispatch( saveFile( values ) ),
    });

    const selectFile = event => {
        const file = event.target.files[ 0 ];
        formik.setFieldValue( "file", file );
        formik.setFieldValue( "fileName", file.name );

        setTimeout( () => {
            formik.submitForm();
        }, 600 );
    };

    const handleDeleteFile = file => {
        dispatch( deleteFile( file ) );
    };

    const changeOrder = ( orderBy, orderType ) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const closeDialogFile = () => {
        setShowPreviewFile( false );
        setFileToDownload( {} );
    };

    const openFile = file => () => {
        let contentType;

        if ( file.name.endsWith( ".png" ) || file.name.endsWith( ".jpg" ) || file.name.endsWith( ".jpeg" ) ) {
            contentType = "image";
        } else if ( file.name.endsWith(".pdf" ) ) {
            contentType = "pdf";
        } else {
            downloadFile( `/api/v1/opportunities/files/${file.id}`, file.name );
            return;
        }

        setShowPreviewFile( true );
        setFileToDownload({ id: file.id, contentType, filename: file.name });
    };

    const model = {
        name: {
            title: t( "customer:files.name" )
        },
        size: {
            title: t( "customer:files.size" ),
            format: formatBytes
        },
        uploadedAt: {
            title: t( "customer:files.uploaded-at" ),
            format: dateFormat
        },
        uploadedBy: {
            title: t( "customer:files.uploaded-by" ),
            format: user => user.name
        },
        download: {
            title: " ",
            size: "small"
        }
    };
    const source = order(
        ( files || [] ).map( file => ({
            ...file,
            download: (
                <IconButton onClick={ openFile( file ) } size="large">
                    <CloudDownloadIcon/>
                </IconButton>
            )
        })),
        [ orderBy ],
        [ orderType.toLowerCase() ]
    );

    useEffect( () => {
        dispatch( fetchFiles( opportunity.id ) );
    }, [ dispatch, opportunity.id ] );
    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    return (
        <>
            {
                showPreviewFile &&
                    <PreviewFile
                        open={ showPreviewFile }
                        contentType={ fileToDownload?.contentType }
                        filename={ fileToDownload?.filename }
                        url={ `/api/v1/opportunities/files/${fileToDownload?.id}` }
                        onClose={ closeDialogFile }
                    />
            }
            <div className={ css.containerContent }>
                <Snackbar
                    open={ showError }
                    action={ <Button onClick={ toggleError } color="secondary">{ "OK" }</Button>}
                    message={ get( formik.error, "error" ) }
                    autoHideDuration={ 5000 }
                    onClose={ toggleError }
                />

                <form role="form" onSubmit={ formik.handleSubmit } encType="multipart/form-data">
                    <input
                        type="file"
                        name="file"
                        style={{ display: "none" }}
                        ref={ fileInput }
                        onChange={ selectFile }
                    />
                </form>

                <NewFileFromModel
                    open={ showFileFromModel }
                    onClose={ toggleFileFromModel }
                    opportunityId={ opportunity.id }
                />

                <div className={ css.content }>
                    <Table
                        isLoading={ formik.submitting || loading }
                        onAdd={ () => fileInput.current.click() }
                        headerActions={
                            hasModels && (
                                <Button color="primary" onClick={ toggleFileFromModel }>
                                    { t( "customer:files.generate" ) }
                                </Button>
                            )
                        }
                        emptyMessage={ t( "customer:files.empty" ) }
                        onDelete={ handleDeleteFile }
                        source={ source }
                        model={ model }
                        selectable
                        allowOrder
                        onChangePage={ ({ orderBy, orderType }) => changeOrder( orderBy, orderType ) }
                        orderBy={ orderBy }
                    />
                </div>
            </div>
        </>
    );
};

export default OpportunityFiles;