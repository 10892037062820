import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import * as actions from "./webhook.actions";
import { NEW_WEBHOOK_FORM } from "./webhook.constants";
import { createDeleteWebhookForm } from "./webhook.utils";

export function *watchIntegrationsWebhook () {
    yield all([
        takeEvery( actions.FETCH_WEBHOOKS, fetchWebhooks ),
        takeEvery( actions.CREATE_WEBHOOK, createWebhook ),
        takeEvery( actions.DELETE_WEBHOOK, deleteWebhook ),
    ]);
}

export function *fetchWebhooks () {
    yield put( actions.requestWebhooks() );
    try {
        const response = yield call( apiv1.get, "/webhooks" );
        yield put( actions.receiveWebhooks( response.data ) );
    } catch ( e ) {
        yield put( actions.errorWebhooks( e.response.data ) );
    }
}

export function *createWebhook ({ payload }) {
    yield put( startSubmit( NEW_WEBHOOK_FORM ) );
    try {
        yield call( apiv1.post, "/webhooks", payload );
        yield put( actions.fetchWebhooks() );
        yield put( stopSubmit( NEW_WEBHOOK_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_WEBHOOK_FORM, e.response.data.error ) );
    }
}

export function *deleteWebhook ({ meta: { id } }) {
    const form = createDeleteWebhookForm( id );
    yield put( startSubmit( form ) );
    try {
        yield call( apiv1.delete, `/webhooks/${id}` );
        yield put( actions.fetchWebhooks() );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data.error ) );
    }
}