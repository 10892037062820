import useTranslate from "../../hooks/use-translate.hook";
import WhatsAppIcon from "../../ui/icons/whatsapp.component";
import { phoneFormatInternational } from "../../core/formatter.utils";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link } from "../../ui";
import css from "./timeline.scss";

const WhatsAppEvent = ({ event: { user, date, payload } }) => {
    const t = useTranslate();

    return (
        <TimelineItem icon={ <WhatsAppIcon className={ css.whatsAppIcon }/> }>
            <TimelineSummary
                user={ user }
                description={
                    payload.opportunityId ?
                        <span>
                            { t( "customer:timeline.whatsapp-opportunity",
                                { phone: phoneFormatInternational( payload.phone ) }
                            )}
                            <Link href={ "/opportunities/" + payload.opportunityId }>
                                <a> { payload.opportunityName } </a>
                            </Link>
                        </span> :
                        <span>
                            { t( "customer:timeline.whatsapp",
                                { phone: phoneFormatInternational( payload.phone ) }
                            )}
                        </span>
                }
                date={ date }
            />
        </TimelineItem>
    );
};

export default WhatsAppEvent;