import NoteAddIcon from "@mui/icons-material/NoteAdd";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";

const ProposalCreationEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();
    return (
        <TimelineItem icon={ <NoteAddIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.proposal-created", { name: payload.name } ) }
                date={ date } />
        </TimelineItem>
    );
};

export default ProposalCreationEvent;