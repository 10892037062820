import { useCallback, useEffect, useMemo, useState } from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import CancelIcon from "@mui/icons-material/Cancel";
import MailIcon from "@mui/icons-material/Mail";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { Input, flex } from "../ui";
import css from "./email.scss";
import { getAuth } from "../mailtop/mailtop.selectors";
import EmailFieldStatus from "./email-field-status.component";

const EmailField = ({ disabled, form, label, name, push, required, remove }) => {
    const t = useTranslate();

    const auth = useSelector( getAuth );

    const [ autoFocus, setAutoFocus ] = useState( false );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    const handlePush = useCallback( () => {
        setAutoFocus( true );
        push();
    }, [ push ] );

    useEffect( () => {
        if ( required && values.length === 0 ) {
            handlePush();
        }
    }, [ handlePush, required, values ] );

    return (
        <div className={ css.container }>
            <Typography variant="caption">
                { label }
                { required ? " *" : "" }
            </Typography>
            <List>
                {
                    values.map( ( email, index ) => (
                        <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                type="email"
                                label=""
                                name={ `${name}[${index}]` }
                                className={ flex.fill }
                                required
                                disabled={ disabled }
                                component={ Input }
                                InputProps={{
                                    endAdornment: auth && <EmailFieldStatus index={ index } email={ email } />
                                }}
                                autoFocus={ autoFocus }
                            />
                            <IconButton
                                disabled={ disabled }
                                className={ css.remove }
                                onClick={ () => {
                                    if ( !required || values.length > 1 ) {
                                        remove( index );
                                    }
                                }}
                                size="large">
                                <CancelIcon/>
                            </IconButton>
                        </li>
                    ))
                }
            </List>

            <Button
                size="small"
                onClick={ handlePush }
                disabled={ disabled }
                startIcon={ <MailIcon/> }
            >
                { t( "form:email.add" ) }
            </Button>
        </div>
    );
};

export default EmailField;