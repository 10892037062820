import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import axios from "axios";
import qs from "qs";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import history from "../../../../history";
import { createEinValidation } from "../../../core/validator.utils";
import PortalForm, { validateForm } from "./portal-form.component";
import { createPortal } from "./portal-integration.actions";
import { NEW_PORTAL_FORM } from "./portal-integration.constants";

const NewPortal = ({ portalType, onClose }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const isEinValid = createEinValidation({ t });

    const queryProps = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location.search ] );

    const [ loading, setLoading ] = useState( false );

    const handleClose = useCallback( () => {
        onClose();
        history.push({ ...location, search: "" });
    }, [ onClose, location ] );

    const formik = useForm({
        enableReinitialize: true,
        form: NEW_PORTAL_FORM,
        initialValues: {
            name: "",
            funnel: null,
            source: null,
            distribution: null,
            teamId: null,
            usersid: [],
            credentials: {},
            type: portalType,
        },
        onSubmit: values => dispatch( createPortal( values ) ),
        onSubmitSuccess: handleClose,
        validate: values => validateForm( values, { isEinValid } ),
    });

    const { setFieldValue } = formik;

    const prevPortalType = usePrevious( portalType );

    useEffect( () => {
        if ( !prevPortalType && portalType ) {
            if ( !!queryProps.code ) {
                setLoading( true );

                const data = {
                    code: queryProps.code,
                };
                const headers = {
                    "content-type": "application/json"
                };
                axios.post( "/mercadolibre/token", data, headers )
                    .then( response => {
                        setLoading( false );
                        setFieldValue( "credentials.accessToken", response.data.access_token );
                        setFieldValue( "credentials.refreshToken", response.data.refresh_token );
                        setFieldValue( "credentials.userId", response.data.user_id );
                        const expireAt = new Date();
                        expireAt.setSeconds( expireAt.getSeconds() + response.data.expires_in );
                        setFieldValue( "credentials.expireAt", expireAt.toISOString() );
                    });
            }
        }
    }, [ prevPortalType, portalType, queryProps.code, queryProps.type, setFieldValue ] );

    const translateIntegrationChannel = portalType &&
        t( `marketplace:integrations.portals.type.${portalType}` );

    return (
        <Dialog open={ !!portalType } fullWidth maxWidth="md">
            <DialogTitle>
                { t( "marketplace:integrations.portals.new.title" ) }
                { portalType && ( " - " + translateIntegrationChannel ) }
            </DialogTitle>
            <DialogContent>
                { loading && <LinearProgress/> }
                {
                    portalType === "OLX" &&
                        <DialogContentText>
                            {
                                t( "marketplace:integrations.portals.new.help", {
                                    integrationChannel: translateIntegrationChannel
                                })
                            }
                        </DialogContentText>
                }
                <Form formik={ formik }>
                    <PortalForm
                        form={ formik }
                        portalType={ portalType }
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                { formik.error && <Typography color="error">{ formik.error }</Typography> }
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewPortal;