import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./file.actions";

const models = createRequestReducer({
    begin: actions.REQUEST_MODELS,
    success: actions.RECEIVE_MODELS,
    failure: actions.ERROR_MODELS
});

function modelSections ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_MODEL_SECTIONS:
            return {
                ...state,
                [ action.meta.id ]: {
                    loading: true,
                    data: state[ action.meta.id ] ? state[ action.meta.id ].data : []
                }
            };
        case actions.RECEIVE_MODEL_SECTIONS:
            return {
                ...state,
                [ action.meta.id ]: {
                    loading: false,
                    data: action.payload
                }
            };
        default:
            return state;
    }
}

export const files = combineReducers({ models, modelSections });