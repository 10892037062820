import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import InfiniteScroll from "react-infinite-scroller";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { useDateFormat, useDateTimeFormat } from "../hooks/use-date-format.hook";
import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../core";
import { EDIT_SCHEDULE_EVENT } from "../schedule";
import ScheduleEventStatus from "../schedule/schedule-event-status.component";
import { actions as userActions, selectors as userSelectors } from "../user";
import { EmptyState, Link } from "../ui";
import css from "./user-todos.scss";

const UserTodos = ({ id, className, finalDate, selectedUsersId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const dateTimeFormat = useDateTimeFormat( t );

    const loadingSchedules = useSelector( userSelectors.isLoadingSchedules );
    const schedules = useSelector( userSelectors.getSchedules ) || [];
    const pagesSchedules = useSelector( userSelectors.getPagesSchedules );
    const loadingOthersSchedules = useSelector( userSelectors.isLoadingOthersSchedules );
    const othersSchedules = useSelector( userSelectors.getOthersSchedules ) || [];
    const pagesOthersSchedules = useSelector( userSelectors.getPagesOthersSchedules );
    const changingEventStatus = useSelector( isSubmitting( EDIT_SCHEDULE_EVENT ) );
    const user = useSelector( coreSelectors.getUser );
    const permittedUsers = useSelector( userSelectors.listAllPermitted, isEqual );
    const usersId = useMemo( () => {
        return isEmpty( selectedUsersId ) ? permittedUsers.map( u => u.id ) : selectedUsersId;
    }, [ selectedUsersId, permittedUsers ] );

    const prevChangingEventStatus = usePrevious( changingEventStatus );

    const [ pageSchedules, setPageSchedules ] = useState( 1 );
    const [ pageOthersSchedules, setPageOthersSchedules ] = useState( 1 );

    const loadUserSchedules = useCallback( () => {
        dispatch( userActions.fetchSchedules({
            page: 1,
            endDate: finalDate,
            usersId: [ user.id ].toString()
        }));
    }, [ dispatch, finalDate, user ] );
    const loadOthersSchedules = useCallback( () => {
        if ( !isEmpty( usersId ) ) {
            dispatch( userActions.fetchOthersSchedules({
                page: 1,
                endDate: finalDate,
                usersId: usersId.toString()
            }));
            setPageOthersSchedules( 1 );
        }
    }, [ dispatch, finalDate, usersId ] );

    useEffect( () => {
        loadUserSchedules();
    }, [ loadUserSchedules ] );
    useEffect( () => {
        loadOthersSchedules();
    }, [ loadOthersSchedules ] );
    useEffect( () => {
        if ( prevChangingEventStatus && !changingEventStatus ) {
            setPageSchedules( 1 );
            setPageOthersSchedules( 1 );
            loadUserSchedules();
        }
    }, [ loadUserSchedules, loadOthersSchedules, prevChangingEventStatus, changingEventStatus ] );

    const getItemSecondaryActionAdmin = ( event, showStatusButtons ) => {
        if ( !showStatusButtons ) {
            const user = event.user ? event.user : event.opportunity.user;
            return (
                <ListItemAvatar>
                    <Tooltip title={ user.name }>
                        <Avatar src={ user.avatar }>{ user.avatar ? "" : user.name.charAt( 0 ).toUpperCase() }</Avatar>
                    </Tooltip>
                </ListItemAvatar>
            );
        }
        return (
            <ScheduleEventStatus
                event={ event }
                dispatch={ dispatch }
            />

        );
    };

    const getItem = ( event, index, showStatusButtons = true ) => {
        if ( event.opportunity ) {
            return (
                <ListItem
                    key={ index }
                    disablePadding
                    secondaryAction={ getItemSecondaryActionAdmin( event, showStatusButtons ) }
                >
                    <ListItemButton
                        dense
                        component={ Link }
                        href={ "/opportunities/" + event.opportunity.id }
                    >
                        <ListItemIcon>
                            <AttachMoneyIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                event.opportunitySchedulingType.name + " - " + dateTimeFormat( event.startDate )
                            }
                            secondary={
                                (
                                    event.opportunity.person ?
                                        event.opportunity.person.name :
                                        event.opportunity.company.name
                                ) +
                                " - " +
                                event.description
                            }
                        />
                    </ListItemButton>
                </ListItem>
            );
        }

        return (
            <ListItem key={ index } dense>
                <ListItemIcon>
                    <EventNoteIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={
                        event.title +
                        " - " +
                        ( event.allDay ? dateFormat( event.startDate ) : dateTimeFormat( event.startDate ) )
                    }
                    secondary={
                        ( event.customer ? event.customer.name + " - " : "" ) +
                        ( event.description ? event.description : "" )
                    }
                />
                { getItemSecondaryActionAdmin( event, showStatusButtons ) }
            </ListItem>
        );
    };

    const incrementPageSchedules = () => {
        if ( loadingSchedules ) {
            return;
        }

        const page = pageSchedules + 1;
        setPageSchedules( page );
        dispatch( userActions.fetchSchedules({ page, usersId: [ user.id ].toString(), endDate: finalDate }) );
    };

    const incrementPageOthersSchedules = () => {
        if ( loadingOthersSchedules ) {
            return;
        }

        const page = pageOthersSchedules + 1;
        setPageOthersSchedules( page );
        dispatch( userActions.fetchOthersSchedules({
            page,
            usersId: usersId.toString(),
            endDate: finalDate
        }));
    };

    const renderSchedules = ( schedules, showStatusButtons ) => {
        return schedules.map( scheduling => getItem( scheduling, scheduling.id, showStatusButtons ) );
    };

    return (
        <Fragment>
            <Card id={ id } className={ classnames( className, css.container ) } >
                <CardContent>
                    <Typography variant="h6">
                        { t( "home:user-todos.title" ) }
                    </Typography>
                </CardContent>
                {
                    !user ?
                        <CircularProgress /> :
                        <Fragment>
                            <List className={ css.list }>
                                <ListSubheader>{ t( "home:user-todos.events-user" ) }</ListSubheader>
                                {
                                    schedules.length === 0 && !loadingSchedules ?
                                        <EmptyState
                                            className={ css.emptyState }
                                            icon={ ThumbUpIcon }
                                            message={ t( "home:user-todos.empty" ) }
                                        /> :
                                        <InfiniteScroll
                                            loadMore={ incrementPageSchedules }
                                            useWindow={ false }
                                            hasMore={ pageSchedules < pagesSchedules }
                                            initialLoad={ false }
                                            threshold={ 20 }
                                        >
                                            { renderSchedules( schedules, true ) }
                                            { loadingSchedules && <CircularProgress/> }
                                        </InfiniteScroll>
                                }
                            </List>
                            {
                                !isEmpty( usersId ) &&
                                    <Fragment>
                                        <Divider className={ css.divider }/>
                                        <List className={ css.list }>
                                            <ListSubheader>
                                                { t( "home:user-todos.events-other-users" ) }
                                            </ListSubheader>
                                            {
                                                othersSchedules.length === 0 && !loadingOthersSchedules ?
                                                    <EmptyState
                                                        className={ css.emptyState }
                                                        icon={ ThumbUpIcon }
                                                        message={ t( "home:user-todos.empty" ) }
                                                    /> :
                                                    <InfiniteScroll
                                                        loadMore={ incrementPageOthersSchedules }
                                                        useWindow={ false }
                                                        hasMore={ pageOthersSchedules < pagesOthersSchedules }
                                                        initialLoad={ false }
                                                        threshold={ 20 }
                                                    >
                                                        { renderSchedules( othersSchedules, false ) }
                                                        { loadingOthersSchedules && <CircularProgress/> }
                                                    </InfiniteScroll>
                                            }
                                        </List>
                                    </Fragment>
                            }
                        </Fragment>
                }
            </Card>
        </Fragment>
    );
};

export default UserTodos;