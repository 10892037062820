import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Dialog, SelectFunnel } from "../ui";
import { getUser } from "../core/core.selectors";
import { Fields, validateFields, selectors as formSelectors } from "../form";
import { fetchForm } from "../form/form.actions";
import { saveNewOpportunity } from "../opportunity/opportunity.actions";
import { getFunnelId } from "../opportunity/opportunity.selectors";
import { closeNewOpportunity } from "./customer.actions";
import { NEW_OPPORTUNITY_FORM } from "./customer.constants";
import { isNewOpportunityOpen, getSelected } from "./customer.selectors";
import opportunityCss from "../opportunity/opportunity.scss";
import css from "./customer.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field?.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const findSystemField = ( fields, systemField ) => fields ?
    ( fields.find( field => field.systemField === systemField ) || {} ) :
    {};

const NewOpportunity = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );
    const allOpportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );
    const funnelId = useSelector( getFunnelId );
    const loading = useSelector( formSelectors.isLoading( "OPPORTUNITY" ) );
    const user = useSelector( getUser );
    const open = useSelector( isNewOpportunityOpen );
    const [ opportunityFields, setOpportunityFields ] = useState( [] );

    const initialValues = useMemo( () => {
        const userOpportunityField = findSystemField( allOpportunityFields, "USER" );

        const initialValues = {
            funnelId,
            fields: {
                ...fieldsToInitialValues( allOpportunityFields ),
                [ userOpportunityField.id ]: user.id,
            }
        };
        if ( customer ) {
            if ( customer.type === "COMPANY" ) {
                initialValues.company = customer;
            } else {
                initialValues.person = customer;
            }
        }
        return initialValues;
    }, [ customer, funnelId, allOpportunityFields, user.id ] );

    const formik = useForm({
        form: NEW_OPPORTUNITY_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewOpportunity( values, { form: NEW_OPPORTUNITY_FORM } ) ),
        validate: values => {
            const errors = validateFields( values, { t, fields: opportunityFields } );
            if ( !values.funnelId ) {
                errors.funnelId = "common:validation.selection";
            }
            return errors;
        }
    });

    const prevSubmitting = usePrevious( formik.submitting );

    const handleClose = useCallback( () => {
        dispatch( closeNewOpportunity() );
    }, [ dispatch ] );

    useEffect( () => {
        if ( !!formik?.values?.funnelId ) {
            setOpportunityFields(
                allOpportunityFields.filter( field =>
                    isEmpty( field?.funnelsId ) ||
                    field?.funnelsId.includes( formik?.values?.funnelId )
                )
            );
        }
    }, [ allOpportunityFields, formik?.values?.funnelId ] );


    useEffect( () => {
        if ( open ) {
            dispatch( fetchForm( "OPPORTUNITY" ) );
        }
    }, [ dispatch, open ] );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting && !formik.error ) {
            handleClose();
        }
    }, [ prevSubmitting, formik.error, formik.submitting, handleClose ] );

    return (
        <Dialog
            open={ open }
            maxWidth="md"
            fullWidth
        >
            <DialogTitle className={ opportunityCss.newOpportunityTitle }>
                { t( "opportunity:new-opportunity.title" ) }
            </DialogTitle>

            <DialogContent classes={{ root: css.newOpportunityForm }}>
                {
                    !loading && open ?
                        <Form formik={ formik }>
                            <>
                                <div className={ opportunityCss.funnelSelector }>
                                    <Field
                                        name="funnelId"
                                        component={ SelectFunnel }
                                        required
                                    />
                                </div>
                                <Fields
                                    fields={ opportunityFields }
                                />
                            </>
                        </Form> :
                        <CircularProgress/>
                }
            </DialogContent>
            <DialogActions>
                {
                    !isEmpty( formik.errors ) && formik.submitCount > 0 &&
                        <Typography color="error" variant="body1">
                            { t( "common:validation.required-fields" ) }
                        </Typography>
                }
                <Button color="primary" onClick={ handleClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewOpportunity;
