import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import { getCountryCallingCode } from "libphonenumber-js";

import useTranslate from "../../hooks/use-translate.hook";
import { validateFields, selectors as formSelectors } from "../../form";
import { saveNewOpportunityCompany } from "../opportunity.actions";
import { SAVE_COMPANY_FORM } from "../opportunity.constants";
import CustomerForm from "./customer-form.component";
import { getCompanySearchValues } from "../opportunity.selectors";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const getFieldBySystemField = ( systemField, fields ) => {
    return fields.find( field => field.systemField === systemField );
};
const getFieldByType = ( type, fields ) => {
    return fields.find( field => field.type === type );
};

const NewCompany = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "COMPANY" ) );
    const company = useSelector( getCompanySearchValues );

    const initialValues = useMemo( () => {
        const fieldValues = fieldsToInitialValues( fields );

        const fieldName = getFieldBySystemField( "NAME", fields );
        const fieldDoc = getFieldBySystemField( "EIN", fields );
        const fieldEmail = getFieldByType( "EMAIL", fields );
        const fieldPhone = getFieldByType( "PHONE", fields );

        fieldValues[ fieldName.id ] = company.name;
        fieldValues[ fieldDoc.id ] = company.document;

        if ( company.emails && fieldEmail ) {
            fieldValues[ fieldEmail.id ] = [ company.emails ];
        }
        if ( company.phones && fieldPhone ) {
            const code = getCountryCallingCode( t( "common:country" ) );
            const number = code + company.phones;
            fieldValues[ fieldPhone.id ] = [{ number: number, country: t( "common:country" ) }];
        }

        return {
            type: "COMPANY",
            fields: fieldValues
        };
    }, [ t, fields, company ] );

    const formik = useForm({
        form: SAVE_COMPANY_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewOpportunityCompany( values ) ),
        validate: values => {
            let errors = {};
            if ( !values ) {
                return errors;
            }
            errors = validateFields( values, { t, fields } );
            return errors;
        }
    });

    return <CustomerForm fields={ fields } formik={ formik } onClose={ onClose } />;
};

export default NewCompany;
