export const FETCH_PRODUCTS = "x/FETCH_PRODUCTS";
export const fetchProducts = ( params = {} ) => ({
    type: FETCH_PRODUCTS,
    payload: { params }
});

export const REQUEST_PRODUCTS = "x/REQUEST_PRODUCTS";
export const requestProducts = () => ({
    type: REQUEST_PRODUCTS
});

export const RECEIVE_PRODUCTS = "x/RECEIVE_PRODUCTS";
export const receiveProducts = data => ({
    type: RECEIVE_PRODUCTS,
    payload: data
});

export const ERROR_PRODUCTS = "x/ERROR_PRODUCTS";
export const errorProducts = error => ({
    type: ERROR_PRODUCTS,
    error
});

export const FETCH_SEARCH_PRODUCTS = "x/FETCH_SEARCH_PRODUCTS";
export const fetchSearchProducts = params => ({
    type: FETCH_SEARCH_PRODUCTS,
    payload: params
});

export const REQUEST_SEARCH_PRODUCTS = "x/REQUEST_SEARCH_PRODUCTS";
export const requestSearchProducts = () => ({
    type: REQUEST_SEARCH_PRODUCTS,
});

export const RECEIVE_SEARCH_PRODUCTS = "x/RECEIVE_SEARCH_PRODUCTS";
export const receiveSearchProducts = data => ({
    type: RECEIVE_SEARCH_PRODUCTS,
    payload: data,
});

export const ERROR_SEARCH_PRODUCTS = "x/ERROR_SEARCH_PRODUCTS";
export const errorSearchProducts = error => ({
    type: ERROR_SEARCH_PRODUCTS,
    payload: error,
});

export const FETCH_PRODUCT_CATEGORIES = "x/FETCH_PRODUCT_CATEGORIES";
export const fetchCategories = () => ({
    type: FETCH_PRODUCT_CATEGORIES
});

export const REQUEST_PRODUCT_CATEGORIES = "x/REQUEST_PRODUCT_CATEGORIES";
export const requestCategories = () => ({
    type: REQUEST_PRODUCT_CATEGORIES
});

export const RECEIVE_PRODUCT_CATEGORIES = "x/RECEIVE_PRODUCT_CATEGORIES";
export const receiveCategories = data => ({
    type: RECEIVE_PRODUCT_CATEGORIES,
    payload: data
});

export const ERROR_PRODUCT_CATEGORIES = "x/ERROR_PRODUCT_CATEGORIES";
export const errorCategories = error => ({
    type: ERROR_PRODUCT_CATEGORIES,
    error
});