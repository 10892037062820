import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as formSelectors } from "../form";
import { selectors as opportunitySelectors } from "../opportunity";
import { phoneFormat } from "../core/formatter.utils";
import { getSelected } from "./customer.selectors";
import { saveNewPhoneCall } from "./customer.actions";
import { NEW_PHONE_CALL_FORM } from "./customer.constants";
import { Dialog, Dropdown, EmptyState, Input } from "../ui";


const getPhones = ( customer, companyFields, personFields ) => {
    const phones = [];
    let fields;
    if ( customer.type === "COMPANY" ) {
        fields = companyFields;
    } else {
        fields = personFields;
    }
    fields.forEach( field => {
        Object.keys( customer.fields )
            .filter( fieldId => fieldId === field.id.toString() )
            .forEach( fieldId => {
                const value = ( customer.fields[ fieldId ] || [] );
                value.forEach( phone => phones.push( phone ));
            });
    });
    return phones;
};

const NewPhoneCall = ({ show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunity = useSelector( opportunitySelectors.getSelected );
    const customer = useSelector( getSelected );
    const companyFields = useSelector( formSelectors.listPhoneFields( "COMPANY" ) );
    const personFields = useSelector( formSelectors.listPhoneFields( "PERSON" ) );

    const selectedCustomer = useMemo(
        () => ( opportunity && ( opportunity.person || opportunity.company ) ) || customer,
        [ opportunity, customer ]
    );

    const prevShow = usePrevious( show );

    const initialValues = useMemo( () => ({
        customerId: selectedCustomer?.id,
        opportunityId: opportunity?.id,
        phone: null,
        description: "",
    }), [ selectedCustomer, opportunity ] );

    const formik = useForm({
        form: NEW_PHONE_CALL_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewPhoneCall( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.phone ) {
                errors.phone = "common:validation.required";
            }
            if ( !values.description ) {
                errors.description = "common:validation.required";
            }
            return errors;
        }
    });

    useEffect( () => {
        if ( !prevShow && show ) {
            const phones = getPhones( selectedCustomer, companyFields, personFields );
            if ( phones.length === 1 ) {
                formik.setFieldValue( "phone", phones[ 0 ].number );
            }
        }
    }, [ prevShow, show, selectedCustomer, companyFields, personFields, formik ]);

    if ( !selectedCustomer || ( !companyFields || !personFields ) ) {
        return null;
    }

    const phones = getPhones( selectedCustomer, companyFields, personFields );

    return (
        <Dialog open={ !!show } fullWidth>
            <DialogTitle>{ t( "customer:new-phone-call.title" ) }</DialogTitle>
            <DialogContent>
                {
                    isEmpty( phones ) ?
                        <EmptyState
                            icon={ ContactPhoneIcon }
                            message={ t( "customer:new-phone-call.empty" ) }
                        /> :
                        <Form formik={ formik }>
                            <Field
                                name="phone"
                                component={ Dropdown }
                                source={ phones.map( phone => ({
                                    label: phoneFormat( phone ),
                                    value: phone.number
                                }))}
                                label={ t( "customer:new-phone-call.phone" ) }
                                required
                            />
                            <Field
                                name="description"
                                component={ Input }
                                label={ t( "customer:new-phone-call.description" ) }
                                required
                            />
                        </Form>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                {
                    !isEmpty( phones ) &&
                        <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                            { t( "common:save" ) }
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default NewPhoneCall;