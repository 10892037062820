import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../../form";
import { Dropdown, cssUtils, flex } from "../../../ui";

const LeadSourceField = ({ name, form, push, remove }) => {
    const t = useTranslate();

    const allOpportunityFields = useSelector( formSelectors.listSelectFields( "OPPORTUNITY" ) );

    const opportunityFields = useMemo(
        () => allOpportunityFields.filter( field => !field.systemField ),
        [ allOpportunityFields ]
    );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    return (
        <div className={ cssUtils.marginTop }>
            { opportunityFields.length > 0 ?
                <Fragment>
                    <Typography variant="caption">
                        { t( "marketplace:integrations.lead-source.fields.label" ) }
                    </Typography>
                    <List>
                        {
                            values.map( ( leadSourceField, index ) => (
                                <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                    <Field
                                        label={ t( "marketplace:integrations.lead-source.fields.field-id" ) }
                                        name={ `${name}[${index}].fieldId` }
                                        className={ flex.fill }
                                        required
                                        component={ Dropdown }
                                        source={ opportunityFields
                                            .filter( field =>
                                                !values.find( value => value.fieldId === field.id ) ||
                                                ( leadSourceField.fieldId === field.id )
                                            )
                                            .map( field => ({
                                                value: field.id,
                                                label: field.name
                                            }))}
                                    />
                                    <Field
                                        label={ t( "marketplace:integrations.lead-source.fields.default-value" ) }
                                        name={ `${name}[${index}].defaultValue` }
                                        className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                        required
                                        component={ Dropdown }
                                        source={
                                            opportunityFields && get( leadSourceField, "fieldId" ) ?
                                                opportunityFields
                                                    .find( field => field.id === leadSourceField.fieldId )
                                                    .options
                                                    .values.map( value => ({ value, label: value }) )
                                                :
                                                []
                                        }
                                    />
                                    <IconButton onClick={ () => remove( index ) } size="large">
                                        <CancelIcon/>
                                    </IconButton>
                                </li>
                            ))
                        }
                    </List>

                    <Button
                        size="small"
                        disabled={ values.length >= opportunityFields.length }
                        onClick={ () => push({}) }>
                        { t( "common:add" ) }
                    </Button>
                </Fragment>
                :
                <Typography variant="caption" className={ cssUtils.textError } gutterBottom>
                    { t( "marketplace:integrations.lead-source.fields.empty" ) }
                </Typography>
            }
        </div>
    );
};

export default LeadSourceField;