export default {
    "client-not-found": "Cliente OAuth não encontrado",
    "logged-as": "Você está conectado como",
    "call-to-action": "{ client } está solicitando acesso para:",
    "scopes": {
        "read-all": "Ler dados não administrativos",
        "save-customer": "Criar e alterar clientes",
        "create-opportunity": "Criar oportunidades",
        "update-opportunity": "Alterar oportunidades",
        "save-scheduling": "Criar e alterar agendamentos",
        "webhooks": "Webhooks"
    },
    "authorize": "Autorizar",
};