import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./plan.actions";

const subscriptionBilling = createRequestReducer({
    begin: actions.REQUEST_SUBSCRIPTION_BILLING,
    success: actions.RECEIVE_SUBSCRIPTION_BILLING,
    failure: actions.ERROR_SUBSCRIPTION_BILLING
});

const subscriptionCoupon = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.RECEIVE_SUBSCRIPTION_COUPON:
            return Object.assign( {}, state, {
                data: action.payload
            });
        default:
            return state;
    }
};

const creditCards = createRequestReducer({
    begin: actions.REQUEST_CREDIT_CARDS,
    success: actions.RECEIVE_CREDIT_CARDS,
    failure: actions.ERROR_CREDIT_CARDS
});

const billings = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_BILLINGS:
            return Object.assign( {}, state, {
                loading: true
            });
        case actions.RECEIVE_BILLINGS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload.billings,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            });
        case actions.ERROR_BILLINGS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload,
            });
        default:
            return state;
    }
};

const subscriptionStorage = createRequestReducer({
    begin: actions.REQUEST_SUBSCRIPTION_STORAGE,
    success: actions.RECEIVE_SUBSCRIPTION_STORAGE,
    failure: actions.ERROR_SUBSCRIPTION_STORAGE
});

export const plan = combineReducers({
    subscriptionBilling,
    subscriptionCoupon,
    subscriptionStorage,
    creditCards,
    billings
});