import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import { actions, selectors } from "./";
import { setAuth } from "../login/login.actions";
import { actions as subscriptionActions } from "../subscription";

export function *watchCore () {
    yield all([
        takeEvery( actions.LOAD_INFO, loadInfo )
    ]);
}

export function *loadInfo () {
    // Se já temos as infos de usuário, não há necessidade de carregar novamente
    if ( yield select( selectors.getUser ) ) {
        return;
    }

    try {
        yield put( actions.requestInfo() );
        const responseUser = yield call( apiv1.get, "/users/me" );
        const user = responseUser.data;

        const responsePreferences = yield call( apiv1.get, "/users/me/preferences" );
        yield put( actions.setThemeMode( responsePreferences.data.theme.toLowerCase() ) );
        const preferences = responsePreferences.data;

        let loggedHolding;
        if ( user.admin ) {
            const responseHolding = yield call( apiv1.get, "/users/me/holdings/logged" );
            loggedHolding = responseHolding.data;
        }

        const responseSubscription = yield call( apiv1.get, "/subscription" );
        yield put( subscriptionActions.receiveSubscription( responseSubscription.data ) );
        yield put( actions.setPreferences( preferences ) );
        yield put( actions.setInfo( user, loggedHolding ) );
    } catch ( e ) {
        yield put( setAuth( false ) );
    }
}