import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import * as actions from "./distribution.actions";
import { NEW_DISTRIBUTION_FORM, DELETE_DISTRIBUTION_FORM } from "./distribution.constants";
import { createEditDistributionForm } from "./distribution.utils";

export function *watchConfigDistribution () {
    yield all([
        takeEvery( actions.FETCH_DISTRIBUTIONS, fetchDistributions ),
        takeEvery( actions.CREATE_DISTRIBUTION, createDistribution ),
        takeEvery( actions.SAVE_DISTRIBUTION, saveDistribution ),
        takeEvery( actions.DELETE_DISTRIBUTION, deleteDistribution ),
    ]);
}

function *fetchDistributions () {
    yield put( actions.requestDistributions() );
    try {
        const response = yield call( apiv1.get, "/distributions" );
        yield put( actions.receiveDistributions( response.data ) );
    } catch ( e ) {
        yield put( actions.errorDistributions( e.response.data ) );
    }
}

function *createDistribution ( { payload } ) {
    yield put( startSubmit( NEW_DISTRIBUTION_FORM ) );
    try {
        yield call( apiv1.post, `/distributions`, payload );
        yield put( stopSubmit( NEW_DISTRIBUTION_FORM ) );
        yield put( actions.fetchDistributions() );
    } catch ( e ) {
        yield put( stopSubmit( NEW_DISTRIBUTION_FORM, e.response.data ) );
    }
}

function *saveDistribution ({ meta: { id }, payload }) {
    const form = createEditDistributionForm( id );

    yield put( startSubmit( form ) );
    try {
        yield call( apiv1.patch, `/distributions/${id}`, payload );
        yield put( stopSubmit( form ) );
        yield put( actions.fetchDistributions() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

function *deleteDistribution ( { meta: { id }, payload } ) {
    yield put( startSubmit( DELETE_DISTRIBUTION_FORM ) );
    try {
        yield call( apiv1.put, `/distributions/${id}/transfer`, payload );
        yield call( apiv1.delete, `/distributions/${id}` );
        yield put( stopSubmit( DELETE_DISTRIBUTION_FORM ) );
        yield put( actions.fetchDistributions() );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_DISTRIBUTION_FORM, e.response.data ) );
    }
}