import classnames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { Field } from "formik";
import debounce from "lodash/debounce";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import isObject from "lodash/isObject";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex, Dropdown, Autocomplete } from "../ui";
import css from "./address.scss";

const AddressItem = ({ address, currentPosition, disabled, name, onRemove }) => {
    const t = useTranslate();

    const [ source, setSource ] = useState( address?.placeId ? { [ address.placeId ]: address.name } : {} );
    const [ bounds, setBounds ] = useState();

    const autocompleter = useRef( new window.google.maps.places.AutocompleteService() );

    const search = debounce( term => {
        if ( !term ) {
            setSource( {} );
            return;
        }

        autocompleter.current.getPlacePredictions({
            input: term,
            bounds,
            types: [ "establishment", "geocode" ]
        }, ( results, status ) => {
            // TODO: Tratar se acontecer algum erro
            if ( status !== "OK" && status !== "ZERO_RESULTS" ) {
                return;
            }

            const source = {};
            if ( results ) {
                results.filter( result => !!result.place_id ).forEach( result => {
                    source[ result.place_id ] = result.description;
                });
            }

            setSource( source );
        });
    }, 200 );

    useEffect( () => {
        if ( currentPosition ) {
            const { coords } = currentPosition;
            const bounds = new google.maps.LatLngBounds();
            const centerPosition = new google.maps.LatLng( coords.latitude, coords.longitude );
            bounds.extend( centerPosition );
            setBounds( bounds );
        }
    }, [ currentPosition ] );

    const addressTypes = useMemo( () => [ "RESIDENTIAL", "COMMERCIAL", "OTHER" ].map( type => ({
        value: type,
        label: t( "form:address.types." + type )
    })), [ t ] );

    return (
        <li className={ classnames( flex.container, flex.alignItemsCenter ) }>
            <Field
                name={ `${name}.name` }
                label={ t( "form:address.address" ) }
                component={ Autocomplete }
                getOptionLabel={ option => isObject( option ) ? option.label : option }
                required
                filterOptions={ options => options }
                allowCreate
                useSourceKeyValue
                className={ classnames( flex.fill, cssUtils.marginRightSmall ) }
                multiple={ false }
                onQueryChange={ search }
                source={ source }
                disabled={ disabled }
            />
            <Field
                name={ `${name}.type` }
                label={ t( "form:address.type" ) }
                component={ Dropdown }
                source={ addressTypes }
                required
                disabled={ disabled }
                className={ classnames( flex.item20, cssUtils.marginLeftSmall ) } />

            <IconButton
                className={ css.remove }
                onClick={ onRemove }
                disabled={ disabled }
                size="large"
            >
                <CancelIcon/>
            </IconButton>
        </li>
    );
};

export default AddressItem;