import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import GoogleIcon from "../ui/icons/google.component";
import { cssUtils, flex } from "../ui";
import { fetchSocialCalendar, fetchSocialAccountCalendars } from "./user.actions";
import { getSocialCalendar, isLoadingSocialCalendar } from "./user.selectors";
import GoogleCalendarAuth from "./google-calendar-auth.component";
import DeleteSocialCalendar from "./delete-social-calendar.component";
import SocialCalendarForm from "./social-calendar-form.component";

const SocialCalendar = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loadingSocialCalendar = useSelector( isLoadingSocialCalendar );
    const socialCalendar = useSelector( getSocialCalendar );

    useEffect( () => {
        dispatch( fetchSocialCalendar() );
        dispatch( fetchSocialAccountCalendars() );
    }, [ dispatch ] );

    const renderAccountIcon = socialAccount => {
        switch ( socialAccount.provider ) {
            case "GOOGLE":
                return <GoogleIcon/>;
            default:
                return <div/>;
        }
    };

    const showNewOptions = !loadingSocialCalendar && !socialCalendar;

    return (
        <Card>
            <CardHeader
                title={ t( "user:schedule.integration.title" ) }
                subheader={ t( "user:schedule.integration.call-to-action" ) }
            />
            { loadingSocialCalendar && <LinearProgress/> }

            <CardContent>
                {
                    socialCalendar &&
                        <div className={ flex.container }>
                            <Button
                                component="div"
                                className={ cssUtils.cursorUnset }
                                variant="outlined"
                                startIcon={ renderAccountIcon( socialCalendar.account ) }
                                endIcon={ <DeleteSocialCalendar/> }
                            >
                                { socialCalendar.account.data.email }
                            </Button>
                            {
                                ( socialCalendar.account && !socialCalendar.account.expired ) ?
                                    <Alert
                                        className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                        severity="success"
                                    >
                                        { t( "user:gmail.connected" ) }
                                    </Alert> : <Alert
                                        className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                        severity="error"
                                    >
                                        { t( "user:gmail.expired" ) }
                                    </Alert>
                            }
                        </div>
                }
                { showNewOptions && <GoogleCalendarAuth /> }
                { showNewOptions && <Typography color="textSecondary"></Typography> }
            </CardContent>
            { socialCalendar && <SocialCalendarForm/> }
        </Card>
    );
};

export default SocialCalendar;