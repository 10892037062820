export const FETCH_WEBHOOKS = "x/marketplace/FETCH_WEBHOOKS";
export const fetchWebhooks = () => ({
    type: FETCH_WEBHOOKS
});

export const REQUEST_WEBHOOKS = "x/marketplace/REQUEST_WEBHOOKS";
export const requestWebhooks = () => ({
    type: REQUEST_WEBHOOKS
});

export const RECEIVE_WEBHOOKS = "x/marketplace/RECEIVE_WEBHOOKS";
export const receiveWebhooks = webhooks => ({
    type: RECEIVE_WEBHOOKS,
    payload: webhooks
});

export const ERROR_WEBHOOKS = "x/marketplace/ERROR_WEBHOOKS";
export const errorWebhooks = error => ({
    type: ERROR_WEBHOOKS,
    payload: error,
    error: true,
});

export const CREATE_WEBHOOK = "x/marketplace/CREATE_WEBHOOK";
export const createWebhook = data => ({
    type: CREATE_WEBHOOK,
    payload: data
});

export const DELETE_WEBHOOK = "x/marketplace/DELETE_WEBHOOK";
export const deleteWebhook = id => ({
    type: DELETE_WEBHOOK,
    meta: { id }
});