import { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import InventoryIcon from "@mui/icons-material/Inventory";

import useTranslate from "../../../hooks/use-translate.hook";
import { OptionsButton } from "../../../ui";
import { setImportation } from "./product.actions";
import DeleteProductImportation from "./delete-product-importation.component";

const ProductImportationOptions = ({ importation, onSelectProducts }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openDelete, setOpenDelete ] = useState( false );
    const toggleDelete = () => setOpenDelete( !openDelete );

    const handleSelect = () => {
        dispatch( setImportation( importation ) );
        onSelectProducts();
    };

    return (
        <>
            <OptionsButton
                options={[
                    {
                        icon: <InventoryIcon />,
                        onClick: handleSelect,
                        text: t( "config:products.importation.created-products" ),
                    }, {
                        disabled: !importation.finishedAt,
                        icon: <DeleteIcon />,
                        onClick: toggleDelete,
                        text: t( "common:exclude" )
                    }
                ]}
            />

            <DeleteProductImportation
                importation={ importation }
                onClose={ toggleDelete }
                open={ openDelete }
            />
        </>
    );
};

export default ProductImportationOptions;