import Avatar from "@mui/material/Avatar";
import classnames from "classnames";

import css from "./timeline.scss";

const TimelineItem = ({ icon, classes = {}, children }) => (
    <li className={ css.item }>
        <Avatar className={ classnames( css.icon, classes.avatar ) }>{ icon }</Avatar>
        { children }
    </li>
);

export default TimelineItem;