import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from "@mui/icons-material/Clear";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import qs from "qs";

import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { actions as sourceActions, selectors as sourceSelectors } from "../source";
import { isLoadingLeads, getLeads, getTotalPagesLeads, getTotalElementsLeads } from "./web-capture.selectors";
import { fetchLeads } from "./web-capture.actions";
import { Table } from "../ui/table";
import { Dropdown, Input, cssUtils, flex } from "../ui";

const WebCapture = ({ className }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loadingLeads = useSelector( isLoadingLeads );
    const loadingSources = useSelector( sourceSelectors.isLoadingSources );
    const sources = useSelector( sourceSelectors.getSources );
    const leads = useSelector( getLeads );
    const totalPages = useSelector( getTotalPagesLeads );
    const totalElements = useSelector( getTotalElementsLeads );

    const queryString = useMemo( () => qs.parse( location.search ), [ location ] );
    const loading = loadingLeads || loadingSources;

    const [ sourceId, setSourceId ] = useState( "" );
    const [ to, setTo ] = useState( "" );
    const [ from, setFrom ] = useState( "" );
    const [ status, setStatus ] = useState( "OPEN" );
    const [ orderBy, setOrderBy ] = useState( queryString.orderBy || "createdAt" );
    const [ orderType, setOrderType ] = useState( queryString.orderType || "ASC" );
    const [ selectedPage, setSelectedPage ] = useState( queryString.page ? Number( queryString.page ) : 0 );

    const clearFilters = () => {
        setSourceId( "" );
        setTo( "" );
        setFrom( "" );
    };

    const handlePageChange = params => {
        setOrderBy( params.orderBy );
        setOrderType( params.orderType );
        setSelectedPage( params.page - 1 );
    };

    const loadLeads = useCallback( () => {
        dispatch( fetchLeads({
            sourceId,
            to: to ? to : null,
            from: from ? from : null,
            status,
            page: selectedPage + 1,
            orderBy,
            orderType,
        }) );
    }, [ dispatch, from, orderBy, orderType, selectedPage, sourceId, status, to ] );

    useEffect( () => {
        dispatch( sourceActions.fetchSources() );
    }, [ dispatch ] );
    useEffect( () => {
        loadLeads();
    }, [ loadLeads ] );

    const rowLink = index => leads && leads[ index ] ? `/leads/${leads[ index ].id}` : "";

    const model = {
        source: {
            title: t( "web-capture:lead.source" ),
            format: source => source.name,
            value: "source.name"
        },
        portal: t( "web-capture:lead.portal.label" ),
        to: t( "web-capture:lead.to" ),
        from: t( "web-capture:lead.from" ),
        subject: t( "web-capture:lead.subject" ),
        createdAt: {
            title: t( "web-capture:lead.created-at" ),
            format: dateFormat
        }
    };

    return (
        <div className={ className }>
            <Helmet title={ t( "web-capture:title" ) }/>
            <Card id="filters" className={ cssUtils.overflowUnset }>
                <CardContent>
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Dropdown
                            className={ flex.fill }
                            label={ t( "web-capture:lead.source" ) }
                            source={ sources?.map( source => ({ value: source.id, label: source.name }))}
                            value={ sourceId }
                            onChange={ event => setSourceId( event.target.value ) }
                            emptyValue
                        />
                        <Input
                            className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                            label={ t( "web-capture:lead.to" ) }
                            value={ to }
                            onChange={ event => setTo( event.target.value ) }
                        />
                        <Input
                            className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                            label={ t( "web-capture:lead.from" ) }
                            value={ from }
                            onChange={ event => setFrom( event.target.value ) }
                        />
                        <Tooltip title={ t( "web-capture:leads.clear-filters" ) }>
                            <IconButton
                                size="small"
                                onClick={ clearFilters }
                            >
                                <ClearIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardContent>
            </Card>

            <FormControl id="status">
                <RadioGroup
                    className={ flex.row }
                    value={ status }
                    onChange={ e => setStatus( e.target.value ) }
                >
                    <FormControlLabel
                        value="OPEN"
                        control={ <Radio /> }
                        label={
                            <Tooltip title={ t( "web-capture:lead.status.open.help" ) }>
                                <span>{ t( "web-capture:lead.status.open.label" ) }</span>
                            </Tooltip>
                        }
                    />
                    <FormControlLabel
                        className={ cssUtils.marginLeft }
                        value="DISCARTED"
                        control={ <Radio /> }
                        label={
                            <Tooltip title={ t( "web-capture:lead.status.discarted.help" ) }>
                                <span>{ t( "web-capture:lead.status.discarted.label" ) }</span>
                            </Tooltip>
                        }
                    />
                    <FormControlLabel
                        className={ cssUtils.marginLeft }
                        value="CONVERTED"
                        control={ <Radio /> }
                        label={
                            <Tooltip title={ t( "web-capture:lead.status.converted.help" ) }>
                                <span>{ t( "web-capture:lead.status.converted.label" ) }</span>
                            </Tooltip>
                        }
                    />
                </RadioGroup>
            </FormControl>

            <Table
                model={ model }
                source={ leads }
                isLoading={ loading }
                rowLink={ rowLink }
                emptyMessage={ t( "web-capture:leads.empty" ) }
                totalPages={ totalPages }
                totalElements={ totalElements }
                onChangePage={ handlePageChange }
                orderBy={ orderBy }
                pageSize={ 15 }
                orderType={ orderType }
                saveOrderHistory
                allowOrder
                selectedPage={ selectedPage }
            />
        </div>
    );
};

export default WebCapture;