import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import * as creators from "./opportunity.actions";

export function all ( state = {}, creator ) {
    switch ( creator.type ) {
        case creators.REQUEST_OPPORTUNITIES:
            return Object.assign( {}, state, {
                loading: true
            });

        case creators.RECEIVE_OPPORTUNITIES:
            return Object.assign( {}, state, {
                loading: false,
                data: creator.payload.opportunities,
                totalPages: creator.payload.totalPages,
                totalElements: creator.payload.totalElements
            });

        case creators.ERROR_OPPORTUNITIES:
            return Object.assign( {}, state, {
                loading: false,
                error: creator.error
            });

        case creators.PUSH_NEW_OPPORTUNITY:
            return Object.assign( {}, state, {
                data: [ creator.payload.opportunity ].concat( state.data || [] )
            });

        case creators.SET_FUNNEL:
            return Object.assign( {}, state, {
                funnelId: creator.payload,
                phaseId: null
            });

        case creators.SET_FUNNELS:
            return Object.assign( {}, state, {
                funnelsId: creator.payload,
            });

        case creators.SET_FUNNEL_LATEST_UPDATED_AT:
            return Object.assign( {}, state, {
                funnelLatestUpdatedAt: creator.payload
            });

        case creators.SET_PHASE:
            return Object.assign( {}, state, {
                phaseId: creator.payload
            });

        case creators.SET_LOSS_REASON:
            return Object.assign( {}, state, {
                lossReasonId: creator.payload
            });

        case creators.SET_SOURCE:
            return Object.assign( {}, state, {
                sourceId: creator.payload
            });

        default:
            return state;
    }
}

export const count = createRequestReducer({
    begin: creators.REQUEST_OPPORTUNITIES_COUNT,
    success: creators.RECEIVE_OPPORTUNITIES_COUNT,
    failure: creators.ERROR_OPPORTUNITIES_COUNT,
});

export function phases ( state = {}, creator ) {
    switch ( creator.type ) {
        case creators.REQUEST_PHASE_OPPORTUNITIES:
            if ( creator.meta.page === 1 ) {
                return {
                    ...state,
                    [ creator.meta.phaseId ]: {
                        reload: false,
                        loading: true,
                        data: [],
                        page: 1,
                        totalPrice: 0,
                        totalRecurrentPrice: 0,
                        totalPages: 0,
                        totalElements: 0
                    }
                };
            }

            return {
                ...state,
                [ creator.meta.phaseId ]: {
                    ...state[ creator.meta.phaseId ],
                    loading: true,
                    page: creator.meta.page,
                }
            };
        case creators.RECEIVE_PHASE_OPPORTUNITIES:
            const prevOpportunities = get( state, `${creator.meta.phaseId}.data`, [] );
            const prevOpportunitiesIds = prevOpportunities.map( opportunity => opportunity.id );

            return {
                ...state,
                [ creator.meta.phaseId ]: {
                    ...state[ creator.meta.phaseId ],
                    loading: false,
                    data: isEmpty( creator.payload.opportunities ) ?
                        prevOpportunities :
                        prevOpportunities.concat(
                            ( creator.payload.opportunities || [] )
                                .filter( opportunity => !prevOpportunitiesIds.includes( opportunity.id ) )
                        ),
                    totalPrice: creator.payload.totalPrice,
                    totalRecurrentPrice: creator.payload.totalRecurrentPrice,
                    totalPages: creator.payload.totalPages,
                    totalElements: creator.payload.totalElements
                }
            };
        case creators.ERROR_PHASE_OPPORTUNITIES:
            return {
                ...state,
                [ creator.meta.phaseId ]: {
                    loading: false,
                    error: creator.error
                }
            };
        case creators.SAVE_PHASE_OPPORTUNITY:
            const prevPhaseId = creator.meta.phaseId;
            const currPhaseId = creator.payload.opportunity.phase && creator.payload.opportunity.phase.id;

            const indexPrevOpportunity = state[ prevPhaseId ].data
                .map( opportunity => opportunity.id )
                .indexOf( creator.payload.opportunity.id );
            const prevPhaseOpportunities = state[ prevPhaseId ].data.slice();
            prevPhaseOpportunities.splice( indexPrevOpportunity, 1 );

            let currOpportunities;
            let opportunity;
            if ( currPhaseId ) {
                const currentPage = state[ currPhaseId ].page;
                const totalPages = state[ currPhaseId ].totalPages;
                opportunity = {
                    ...state[ prevPhaseId ].data.find( o => o.id === creator.payload.opportunity.id ),
                    phase: creator.payload.opportunity.phase,
                    phaseEntryDate: creator.payload.opportunity.phaseEntryDate
                };
                currOpportunities = state[ creator.payload.opportunity.phase.id ].data.slice();
                currOpportunities.unshift( opportunity );
                if ( currentPage.toString() < totalPages ) {
                    currOpportunities.pop();
                }

                return {
                    ...state,
                    [ prevPhaseId ]: {
                        ...state[ prevPhaseId ],
                        data: prevPhaseOpportunities,
                        totalElements: Number.parseInt( state[ prevPhaseId ].totalElements ) - 1,
                        totalPrice: Number.parseFloat( state[ prevPhaseId ].totalPrice ) - ( opportunity.price || 0 ),
                        totalRecurrentPrice: (
                            Number.parseFloat(
                                state[ prevPhaseId ].totalRecurrrentPrice ) - ( opportunity.recurrentPrice || 0
                            )
                        )
                    },
                    [ currPhaseId ]:  {
                        ...state[ currPhaseId ],
                        data: currOpportunities,
                        totalElements: Number.parseInt( state[ currPhaseId ].totalElements ) + 1,
                        totalPrice: Number.parseFloat( state[ currPhaseId ].totalPrice ) + ( opportunity.price || 0 ),
                        totalRecurrentPrice: (
                            Number.parseFloat(
                                state[ currPhaseId ].totalRecurrrentPrice ) + ( opportunity.recurrentPrice || 0
                            )
                        ),
                    }
                };
            } {
                opportunity = state[ prevPhaseId ].data[ indexPrevOpportunity ];
            }

            return {
                ...state,
                [ prevPhaseId ]: {
                    ...state[ prevPhaseId ],
                    data: prevPhaseOpportunities,
                    totalElements: Number.parseInt( state[ prevPhaseId ].totalElements ) - 1,
                    totalPrice: Number.parseFloat( state[ prevPhaseId ].totalPrice ) - ( opportunity.price || 0 ),
                    totalRecurrentPrice: (
                        Number.parseFloat(
                            state[ prevPhaseId ].totalRecurrentPrice ) - ( opportunity.recurrentPrice || 0
                        )
                    )
                }
            };
        case creators.RELOAD_PHASE_OPPORTUNITIES:
            return {
                ...state,
                [ creator.meta.phaseId ]: {
                    ...state[ creator.meta.phaseId ],
                    reload: true
                }
            };
        case creators.RESET_PHASE_OPPORTUNITIES:
            return {};
        default:
            return state;
    }
}

export const allStatistics = createRequestReducer({
    begin: creators.REQUEST_OPPORTUNITIES_STATISTICS,
    success: creators.RECEIVE_OPPORTUNITIES_STATISTICS,
    failure: creators.ERROR_OPPORTUNITIES_STATISTICS
});

export function create ( state = {}, creator ) {
    switch ( creator.type ) {
        case creators.START_NEW_OPPORTUNITY:
            return Object.assign( {}, state, {
                open: true,
            });

        case creators.CLOSE_NEW_OPPORTUNITY:
            return Object.assign( {}, state, {
                open: false,
            });

        case creators.SET_CUSTOMERS_SEARCH:
            return Object.assign( {}, state, {
                customersSearch: creator.payload,
            });

        case creators.SET_CUSTOMERS_SEARCH_VALUES:
            return Object.assign( {}, state, {
                customersSearchValues: creator.payload,
            });

        case creators.SET_NEW_OPPORTUNITY_COMPANY:
            return Object.assign( {}, state, {
                company: creator.payload,
            });

        case creators.SET_NEW_OPPORTUNITY_PERSON:
            return Object.assign( {}, state, {
                person: creator.payload,
            });

        default:
            return state;
    }
}

export const search = createRequestReducer({
    begin: creators.REQUEST_SEARCH_OPPORTUNITIES,
    success: creators.RECEIVE_SEARCH_OPPORTUNITIES,
    failure: creators.ERROR_SEARCH_OPPORTUNITIES
});

export const selected = ( state = {}, creator ) => {
    switch ( creator.type ) {
        case creators.REQUEST_OPPORTUNITY:
            return Object.assign( {}, state, {
                loading: true,
                error: null
            });

        case creators.SET_OPPORTUNITY:
            return Object.assign( {}, state, {
                loading: false,
                data: creator.payload
            });

        case creators.ERROR_OPPORTUNITY:
            return Object.assign( {}, state, {
                loading: false,
                error: creator.payload
            });

        default:
            return state || {};
    }
};

export const persons = createRequestReducer({
    begin: creators.REQUEST_PERSONS,
    success: creators.RECEIVE_PERSONS,
    failure: creators.ERROR_PERSONS
});

export const schedules = createRequestReducer({
    begin: creators.REQUEST_SCHEDULES,
    success: creators.RECEIVE_SCHEDULES,
    failure: creators.ERROR_SCHEDULES
});

export const schedulingTypes = createRequestReducer({
    begin: creators.REQUEST_SCHEDULING_TYPES,
    success: creators.RECEIVE_SCHEDULING_TYPES,
    failure: creators.ERROR_SCHEDULING_TYPES
});

export const proposals = createRequestReducer({
    begin: creators.REQUEST_PROPOSALS,
    success: creators.RECEIVE_PROPOSALS,
    failure: creators.ERROR_PROPOSALS
});

export const files = createRequestReducer({
    begin: creators.REQUEST_FILES,
    success: creators.RECEIVE_FILES,
    failure: creators.ERROR_FILES
});

export function timeline ( state = {}, creator ) {
    switch ( creator.type ) {
        case creators.FETCH_TIMELINE:
            return Object.assign( {}, state, {
                currentPage: creator.payload.page || 1
            });
        case creators.REQUEST_TIMELINE:
            return Object.assign( {}, state, {
                loading: true,
                reload: false
            });
        case creators.RECEIVE_TIMELINE:
            return Object.assign( {}, state, {
                loading: false,
                data: state.currentPage > 1 && creator.payload ?
                    state.data.concat( creator.payload.events ) :
                    get( creator.payload, "events" ),
                totalPages: get( creator.payload, "totalPages" )
            });
        case creators.ERROR_TIMELINE:
            return Object.assign( {}, state, {
                loading: false,
                error: creator.error
            });
        case creators.RELOAD_TIMELINE:
            return Object.assign( {}, state, {
                reload: true,
                data: null,
                totalPages: null
            });
        default:
            return state;
    }
}

export function opportunityHolding ( state = {}, creator ) {
    switch ( creator.type ) {
        case creators.REQUEST_OPPORTUNITY_HOLDING:
            return Object.assign( {}, state, {
                loading: true
            });
        case creators.RECEIVE_OPPORTUNITY_HOLDING:
            return Object.assign( {}, state, {
                loading: false,
                data: creator.payload
            });
        case creators.ERROR_OPPORTUNITY_HOLDING:
            return Object.assign( {}, state, {
                loading: false,
                error: creator.error
            });
        default:
            return state;
    }
}

export const opportunities = combineReducers({
    all,
    allStatistics,
    count,
    create,
    selected,
    persons,
    schedules,
    search,
    phases,
    proposals,
    files,
    schedulingTypes,
    timeline,
    opportunityHolding
});