import Dialog from "@mui/material/Dialog";

import Team from "./team.component";

const EditTeam = ({ open, onClose, team }) => (
    <Dialog
        open={ open }
        onClose={ onClose }
        fullWidth
        maxWidth="md"
    >
        <Team team={ team } onClose={ onClose } />
    </Dialog>
);

export default EditTeam;