import { Field, getIn } from "formik";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, cssUtils, flex } from "../../../ui";
import CreditCardValidity from "./credit-card-validity.component";
import css from "./plan.scss";

const NewCreditCard = () => {
    const t = useTranslate();

    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getCVCProps,
    } = usePaymentInputs({
        autoFocus: false,
        errorMessages: {
            emptyCardNumber: t( "common:validation.required" ),
            invalidCardNumber: t( "common:validation.credit-card.number" ),
            emptyCVC: t( "common:validation.required" ),
            invalidCVC: t( "common:validation.credit-card.cvc" ),
        },
    });
    const validate = name => () => get( meta, `erroredInputs.${name}` );

    return (
        <>
            <div className={ flex.container }>
                <Field name="creditCard.number" validate={ validate( "cardNumber" ) }>
                    {({
                        field,
                        form: { errors, touched },
                        helperText,
                    }) => {
                        const fieldError = getIn( errors, field.name );
                        const showError = getIn( touched, field.name ) && !!fieldError;
                        return (
                            <Input
                                label={ t( "config:billing.credit-card.number" ) }
                                error={ showError }
                                helperText={ showError ? fieldError : helperText }
                                InputProps={{
                                    startAdornment: (
                                        <svg className={ css.creditCardIcon } { ...getCardImageProps({ images }) } />
                                    ),
                                }}
                                className={ flex.fill }
                                fullWidth={ false }
                                inputProps={ getCardNumberProps({
                                    ...field,
                                    placeholder: "",
                                })}
                                required
                            />
                        );
                    }}
                </Field>
                <Field
                    name="creditCard.name"
                    label={ t( "config:billing.credit-card.name" ) }
                    component={ Input }
                    className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                    fullWidth={ false }
                    required
                />
            </div>
            <div className={ flex.container }>
                <Field
                    name="creditCard.validity"
                    className={ flex.fill }
                    fullWidth={ false }
                    label={ t( "config:billing.credit-card.validity" ) }
                    component={ CreditCardValidity }
                    required
                />
                <Field name="creditCard.cvc" required validate={ validate( "cvc" ) }>
                    {({
                        field,
                        form: { errors, touched },
                        helperText,
                    }) => {
                        const fieldError = getIn( errors, field.name );
                        const showError = getIn( touched, field.name ) && !!fieldError;
                        return (
                            <Input
                                label={ t( "config:billing.credit-card.cvc" ) }
                                error={ showError }
                                helperText={ showError ? fieldError : helperText }
                                InputProps={{ inputProps: getCVCProps({
                                    ...field,
                                    placeholder: "",
                                })}}
                                margin="dense"
                                className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                                required
                            />
                        );
                    }}
                </Field>
            </div>
        </>
    );
};

export default NewCreditCard;