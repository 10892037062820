import { useMemo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import TextField from "@mui/material/TextField";
import "dayjs/locale/pt-br";
import "dayjs/locale/es-mx";

import useTranslate from "../hooks/use-translate.hook";

const DateTimePicker = ({
    fullWidth = true,
    field,
    form,
    disabled,
    label,
    maxDate,
    minDate,
    onAccept,
    ...props
}) => {
    const t = useTranslate();

    const value = useMemo( () => field.value || null, [ field ] );
    const validationMsg = useMemo( () => {
        if ( field && form ) {
            const fieldMeta = form.getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field, form ] );

    const handleChange = newValue => {
        if ( newValue ) {
            const isoDate = newValue.isValid() ? newValue.toDate().toISOString() : "";
            form.setFieldValue( field.name, isoDate );
        } else {
            form.setFieldValue( field.name, newValue );
        }
    };

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale={ t( "common:full-locale" ).toLowerCase() }
        >
            <DesktopDateTimePicker
                renderInput={ params => (
                    <TextField
                        error={ !!validationMsg }
                        fullWidth={ fullWidth }
                        helperText={ validationMsg && t( validationMsg ) }
                        { ... props }
                        { ...params }
                    />
                )}
                disabled={ disabled }
                label={ label }
                maxDate={ maxDate }
                minDate={ minDate }
                value={ value }
                onAccept={ onAccept }
                onChange={ handleChange }
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;