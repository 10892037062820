import { useState } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import useTranslate from "../hooks/use-translate.hook";
import { flex, cssUtils, Input } from "../ui";
import { USER_PASSWORD_FORM } from "./user.constants";
import { saveUserPassword } from "./user.actions";
import { actions as logoutActions } from "../logout";

const UserChangePassword = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showSuccessMessage, setShowSuccessMessage ] = useState( false );

    const formik = useForm({
        form: USER_PASSWORD_FORM,
        initialValues: {
            current: "",
            previous: "",
            confirmCurrent: "",
        },
        onSubmit: values => dispatch( saveUserPassword( values ) ),
        onSubmitSuccess: () => setShowSuccessMessage( true ),
        validate: values => {
            const errors = {};
            if ( !values.previous ) {
                errors.previous = "common:validation.required";
            }
            if ( !values.current ) {
                errors.current = "common:validation.required";
            } else if ( values.current.length < 6 ) {
                errors.current = "common:validation.password-length";
            }
            if ( !values.confirmCurrent ) {
                errors.confirmCurrent = "common:validation.required";
            }
            if ( values.current && values.confirmCurrent ) {
                if ( values.current !== values.confirmCurrent ) {
                    errors.confirmCurrent = "common:validation.passwords-match";
                }
            }

            return errors;
        }
    });

    const handleLogout = () => dispatch( logoutActions.userLogout() );

    return (
        <>
            <Dialog open={ showSuccessMessage }>
                <DialogContent>
                    <DialogContentText>
                        { t( "user:user.change-password.success" )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleLogout }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>

            <CardHeader subheader={ t( "user:user.change-password.title" ) }/>
            <Form formik={ formik }>
                <CardContent className={ flex.container }>
                    <Typography
                        className={ cssUtils.fullWidth }
                        variant="caption"
                    >
                        { t( "user:user.change-password.help" ) }
                    </Typography>

                    { formik.error &&
                            <Typography
                                variant="caption"
                                color="error"
                            >
                                { formik.error.error }
                            </Typography>
                    }
                    <Field
                        type="password"
                        name="previous"
                        label={ t( "user:user.change-password.old-password" ) }
                        component={ Input }
                        required
                    />
                    <Field
                        type="password"
                        name="current"
                        label={ t( "user:user.change-password.new-password" ) }
                        component={ Input }
                        required
                    />
                    <Field
                        type="password"
                        name="confirmCurrent"
                        label={ t( "user:user.change-password.rewrite-new-password" ) }
                        component={ Input }
                        required
                    />
                </CardContent>
                <CardActions>
                    <Button type="submit" color="primary" disabled={ formik.submitting } >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Form>
        </>
    );
};

export default UserChangePassword;