import { useForm } from "formik-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirm } from "../../ui";
import { deletePerson } from "../opportunity.actions";
import { DELETE_OTHER_PERSON_FORM } from "../opportunity.constants";

const DelectPerson = ({ opportunityPerson }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const formik = useForm({
        form: DELETE_OTHER_PERSON_FORM,
        enableReinitialize: true,
        initialValues: {
            opportunityId: opportunityPerson.opportunityId,
            personId: opportunityPerson.person.id,
        },
        onSubmit: values => dispatch( deletePerson( values ) ),
    });

    return (
        <>
            <Tooltip title={ t( "opportunity:persons.delete-title" ) }>
                <IconButton onClick={ toggleOpen } edge="end" size="large">
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>

            <form onSubmit={ formik.handleSubmit }>
                <DialogConfirm
                    open={ open }
                    disabled={ formik.submitting }
                    message={ t( "opportunity:persons.confirm-delete", { name: opportunityPerson.person.name } ) }
                    onCancel={ toggleOpen }
                    onConfirm={ formik.submitForm }
                />
            </form>
        </>
    );
};

export default DelectPerson;