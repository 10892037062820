export default {
    "opportunity-list": {
        "title": "Oportunidades",
        "empty": "Nenhuma oportunidade encontrada.",
        "columns": "Colunas",
        "new": "Nova oportunidade",
        "filters": "Filtros",
        "select-columns": "Selecionar colunas",
        "show-cards": "Visualizar oportundiades em cards",
        "show-table": "Visualizar oportunidades em lista",
        "transfer-oportunities": "Atualizar oportunidades",
        "phases-title":
            "Total de { total } oportunidades em aberto. Valor negociado de { price } e { recurrentPrice } recorrente.",
        "phases-total-opportunities": "{ total } oportunidades",
        "warning-total":
            "Estão sendo exibidas somente 500 oportunidades. Para visualização completa utilize " +
            "a listagem ou utilize filtros para exibir somente as oportunidades que deseja",
        "open-schedules": {
            "title": "Esta oportunidade possui agendamento(s) em aberto. Veja abaixo:",
            "call-to-action": "O que deseja fazer com este(s) agendamento(s)?",
            "accomplished": "Marcar como realizado",
            "not-accomplished": "Marcar como não realizado",
            "open": "Deixar em aberto"
        },
        "live": "Ao vivo",
        "live-help":
            "Atualiza todas as fases ao alterar uma oportunidade e exibe aviso para atualizar quando houver " +
            "alterações no funil",
        "refresh-warning": "Há alterações neste funil. Clique aqui para atualizar as oportunidades",
        "click-to-refresh": "Clique aqui para atualizar as oportunidades",
    },
    "update-opportunities": {
        "transfer": "Atualizar",
        "info-title": "Atualização iniciada",
        "info-content": "Você receberá uma notificação quando a atualização estiver concluída",
        "title": "Atualização de oportunidades",
        "title-users": "Usuário",
        "title-funnel": "Fase",
        "title-status": "Status",
        "helper-users": "As oportunidades serão distribuídas proporcionalmente",
        "description": "Selecione as oportunidades que você gostaria de atualizar",
        "loss-reason-error": "Selecione ao menos um motivo de perda",
        "delete-non-admin": "Serão excluídas somente as oportunidades criadas por você",
        "delete-description": "Para confirmar digite",
        "delete-confirmation": "excluir oportunidades"
    },
    "exportation": {
        "title": "Exportação de oportunidades",
        "call-to-action":
            "Selecione abaixo os campos que você deseja exportar e o formato de arquivo desejado. " +
            "Você será notificado quando a exportação estiver concluída e o arquivo ficará disponível " +
            "por 24 horas.",
        "columns": {
            "available": "Colunas disponíveis",
            "selected": "Colunas selecionadas"
        },
        "format": "Formato do arquivo"
    },
    "new-opportunity": {
        "title": "Nova oportunidade",
        "open-opportunities": "Esse cliente possui as seguintes oportunidades em aberto",
        "new-customer": "Cadastrar novo cliente",
        "customer-error": "Você deve selecionar ao menos uma empresa ou pessoa.",
        "customer-search-error": "Preencha pelo menos um campo para pesquisar.",
        "first-select-company": "Selecione primeiro uma empresa no passo anterior",
        "first-select-person": "Selecione primeiro uma pessoa no passo anterior",
        "search": "Pesquisar",
        "search-customer": "Pesquisa empresa e/ou pessoa",
        "select-customer": "Seleção empresa e/ou pessoa",
        "selected-customer-data": "Dados selecionados",
        "company-vinculed": "Empresa vinculada a { person }"
    },
    "title": "Título",
    "search-customer": "Pesquise o cliente pelo nome, e-mail, telefone completo, CPF ou CNPJ",
    "customer": "Cliente",
    "company": "Empresa",
    "company-data": "Dados da empresa",
    "company-type-in": "Digite o nome da empresa",
    "empty-company": "Empresa não informada",
    "person": "Pessoa",
    "person-data": "Dados da pessoa",
    "person-type-in": "Digite o nome da pessoa",
    "empty-person": "Pessoa não informada",
    "opportunity": "Oportunidade",
    "avoid-duplications-person": "Evite duplicidade, busque uma pessoa",
    "avoid-duplications-company": "Evite duplicidade, busque uma empresa",
    "price": {
        "label": "Valor",
        "empty": "Não definido"
    },
    "recurrent-price": "Valor recorrente (MRR)",
    "funnel": "Funil",
    "funnels": "Funis",
    "funnels-empty": "Não há funis configurados",
    "funnel-product-categories": "Categorias de produto do funil: { productCategories }",
    "change-funnel": "Alterar funil",
    "confirm-change-funnel": {
        "title": "Ao transferir para o funil { funnel }, perderá valor no(s) seguinte(s) campo(s):",
        "description": "Tem certeza de que deseja continuar com a transferência da oportunidade? "
    },
    "phase": "Fase",
    "closing-at": "Data previsão fechamento",
    "user": "Usuário",
    "source": "Origem",
    "status": {
        "label": "Status",
        "OPEN": "Aberta",
        "SOLD": "Ganha",
        "LOST": "Perdida"
    },
    "next-scheduling": "Próximo agendamento",
    "last-interact-at": "Sem interação há",
    "date-last-interact-at": "Data ultima interação",
    "created-at": "Criada em",
    "head": {
        "edit": "Editar",
        "sold": "Ganha",
        "lost": "Perdida",
        "phase": "Em { phase }",
        "last-scheduling-user": "Usuário do último agendamento"
    },
    "reopen": "Reabrir oportunidade",
    "reopen-confirm": "Você tem certeza que deseja reabrir esta oportunidade?",
    "sale-lost": "Venda perdida",
    "sale-sold": "Venda ganha",
    "loss-reason": "Motivo venda perdida",
    "loss-reason-complement": "Complemento",
    "confirm-sold": "Confirme a venda da oportunidade",
    "confirm-status-proposals":
        "Esta oportunidade possui proposta(s) em aberto. Confirme o status da(s) proposta(s).",
    "sold-at": "Ganha em",
    "lost-at": "Perdida em",
    "holding": {
        "title": "Holding",
        "send": "Enviar para outra inscrição",
        "help":
            "Selecione para qual inscrição você deseja enviar as informações desta oportunidade",
        "token": "Inscrição",
        "no-funnels": "Esta inscrição não possui configurações de funils",
        "funnel": "Configuração do funil",
        "transfer": "Transferir",
        "clone": "Clonar",
        "success": "Oportunidade enviada com sucesso!"
    },
    "floating-actions": {
        "whatsapp": "WhatsApp",
        "sms": "SMS",
        "phone-call": "Ligação",
        "email": "E-mail"
    },
    "persons": {
        "header": "Outras pessoas",
        "add": "Adicionar pessoa",
        "role": "Papel na oportunidade",
        "select-new": "Selecione a pessoa que você deseja vincular a oportunidade",
        "delete-title": "Excluir vínculo",
        "confirm-delete": "A pessoa { name } será desvinculado da oportunidade. Você tem certeza?"
    },
    "companies": {
        "select-new": "Selecione a empresa que você deseja vincular a oportunidade",
    },
    "tabs": {
        "overview": "Visão geral",
        "form": "Negociação",
        "person": "Pessoa",
        "company": "Empresa",
        "schedules": "Agendamentos",
        "proposals": "Propostas",
        "files": "Arquivos"
    },
    "confirm-delete-opportunity": "Você tem certeza que deseja excluir essa oportunidade?",
    "delete-opportunity": "Excluir oportunidade",
    "overview": {
        "schedules": "Agendamentos",
        "phases": "Fases",
        "temperature": "Temperatura",
        "timeline": "Linha do tempo"
    },
    "timeline": {
        "types": {
            "OPPORTUNITY_CREATION": "Criação da oportunidade",
            "OPPORTUNITY_CHANGE": "Alteração da negociação",
            "OPPORTUNITY_CHECKIN": "Check-in",
            "OPPORTUNITY_PHASE": "Alteração de fase",
            "OPPORTUNITY_STATUS": "Alteração de status",
            "OPPORTUNITY_TEMPERATURE": "Alteração de temperatura",
            "OPPORTUNITY_COMMENT": "Comentário",
            "OPPORTUNITY_SCHEDULING": "Agendamentos",
            "OPPORTUNITY_PRODUCTS": "Produtos",
            "PROPOSALS": "Propostas",
            "PERSON": "Alteração de pessoa",
            "COMPANY": "Alteração de empresa",
            "EMAIL": "E-mail",
            "PHONE_CALL": "Ligações efetuadas",
            "SMS": "SMS enviado",
            "WHATSAPP": "WhatsApp"
        },
        "opportunity-changed": "Oportunidade alterada",
        "opportunity-changed-user": "alterou esta oportunidade",
        "opportunity-checkin-user": "realizou um check-in nesta oportunidade",
        "opportunity-created": "Oportunidade criada via integração API",
        "opportunity-created-facebook": "Oportunidade criada via Facebook",
        "opportunity-created-exact-sales": "Oportunidade criada via Exact Sales",
        "opportunity-created-icarros": "Oportunidade criada via iCarros",
        "opportunity-created-leadlovers": "Oportunidade criada via Leadlovers",
        "opportunity-created-mercado-libre": "Oportunidade criada via Mercado Livre",
        "opportunity-created-mobiauto": "Oportunidade criada via Mobiauto",
        "opportunity-created-mkt2easy": "Oportunidade criada via MKT2Easy",
        "opportunity-created-rd-station": "Oportunidade criada via RD Station",
        "opportunity-created-olx": "Oportunidade criada via OLX",
        "opportunity-created-usadosbr": "Oportunidade criada via Usadosbr",
        "opportunity-created-webmotors": "Oportunidade criada via Webmotors",
        "opportunity-created-user": "criou esta oportunidade",
        "opportunity-created-lead": "Oportunidade criada através de um lead",
        "opportunity-created-lead-user": "criou esta oportunidade através de um lead",
        "opportunity-created-by-importation": "criou esta oportunidade através de uma importação",
        "opportunity-created-migration": "criou esta oportunidade através de uma migração",
        "opportunity-created-funnel-automation": "Oportunidade criada através da automação do funil",
        "opportunity-created-by-holding": "Oportunidade criada pelo processo de holding",
        "opportunity-phase": "A fase desta oportunidade foi alterada",
        "opportunity-phase-user": "alterou a fase desta oportunidade",
        "opportunity-status": "alterou o status desta oportunidade",
        "opportunity-temperature": "alterou a temperatura desta oportunidade",
        "opportunity-comment": "Comentário criado",
        "opportunity-comment-user": "comentou nesta oportunidade",
        "opportunity-scheduling-creation_seller": "criou um agendamento para o vendedor { user }",
        "opportunity-scheduling-creation": "criou um agendamento",
        "opportnity-scheduling-change": "alterou o agendamento { type } com data { date }",
        "opportunity-scheduling-status": {
            "OPEN": "definiu um agendamento como aberto",
            "ACCOMPLISHED": "definiu um agendamento como realizado",
            "NOT_ACCOMPLISHED": "definiu um agendamento como não realizado"
        },
        "opportunity-created-by-holding-source-user": "Usuário de origem:",
        "opportunity-created-by-holding-source-subscription": "Inscrição de origem:",
        "customer": {
            "PERSON": {
                "removed": "removeu o vínculo da pessoa ",
                "added": "vinculou a pessoa ",
            },
            "COMPANY": {
                "removed": "removeu o vínculo da empresa ",
                "added": "vinculou a empresa ",
            },
            "at-opportunity": " nesta oportunidade",
        },
        "proposal-created": "criou a proposta de nome { name }",
        "proposal-change": "alterou a proposta de nome { name }",
        "proposal-products": {
            "message": "alterou os produtos da proposta de nome { name }",
            "previous": "Produtos anteriores",
            "empty": "Não há produtos"
        },
        "proposal-status": "alterou o status da proposta de nome { name }",
        "opportunity-product": "alterou os produtos desta oportunidade",
        "from-migration": "Oportunidade criada via migração do { system }",
    },
    "scheduling": {
        "type": "Tipo",
        "user": "Usuário",
        "date": "Data",
        "time": "Horário",
        "description": "Descrição",
        "other-user": "Agendar para outro usuário",
        "next-scheduling": "Você deseja criar um novo agendamento?",
        "notify-at": "Notificar",
        "notify": {
            "at": "No momento",
            "five-minutes": "5 minutos antes",
            "thirty-minutes": "30 minutos antes",
            "other": "Outro horário",
            "minutes": "Minutos"
        },
        "recurrent": {
            "label": "Recorrente",
            "type": {
                "label": "Recorrência",
                "DAILY": "Diário",
                "WEEKLY": "Semanal",
                "MONTHLY": "Mensal",
                "YEARLY": "Anual"
            },
            "until": "Até",
            "update": {
                "single": "Este agendamento",
                "future": "Este agendamento e os seguintes",
                "all": "Todos agendamentos"
            }
        },
        "accomplished": "Realizado",
        "not-accomplished": "Não realizado",
        "not-accomplished-reason": "Motivo",
        "change-not-accomplished-reason": "Motivo do agendamento não realizado",
        "confirm-accomplished": "Marcar o agendamento como realizado?",
        "confirm-not-accomplished": "Marcar o agendamento como não realizado?",
        "reopen": "Reabrir",
        "reopen-scheduling": "Marcar o agendamento como aberto?",
        "late": "Atrasado",
        "scheduled-at": "Agendada para { date }",
        "accomplished-at": "Realizada em { date }"
    },
    "new-scheduling": "Novo agendamento",
    "scheduling-open-type-used": "A oportunidade já possui um agendamento deste tipo em aberto",
    "schedule-of": "Agenda de { user }",
    "open-schedules": {
        "title": "Agendamentos abertos",
        "empty": "Não há agendamentos abertos",
        "follow-up": "Melhore o acompanhamento da sua oportunidade para aumentar sua conversão"
    },
    "schedules-empty": "Não há agendamentos cadastrados",
    "opportunity-product": {
        "empty": "Não há produtos nesta oportunidade",
        "product": "Produto",
        "measurement-unit": {
            "UNIT": "unidade",
            "METER": "metro",
            "KILO": "quilo",
            "LITER": "litro"
        },
        "price-by-unit":
            "Valor por { measurementUnit }: { price }",
        "unit-price": "Preço unitário",
        "quantity": "Quantidade",
        "discount": "Desconto",
        "recurrent": "Recorrente",
        "total": "Preço total",
        "subtotal": "Subtotal: { total }",
        "total-price": "Preço total: { total }",
        "inactive": "* Produto inativo",
        "category": "Categoria de produto",
        "funnel-categories": "Categorias do funil",
        "others-categories": "Outras categorias"
    },
    "opportunity-products": {
        "total": "Total: { total }",
        "empty": "Não há produtos cadastrados",
        "register": "Para cadastrar produtos acesse",
        "register-link": " Configurações > Produtos"
    },
    "proposals": {
        "add": "Adicionar proposta",
        "edit": "Editar proposta",
        "duplicate": "Duplicar proposta",
        "empty": "Não há propostas cadastradas nesta oportunidade"
    },
    "proposal": {
        "created-at": "Proposta criada em { date }",
        "change-status": {
            "OPEN": "Reabrir",
            "ACCEPTED": "Aprovar",
            "DECLINED": "Recusar",
            "confirm": "Você tem certeza que deseja alterar o status desta proposta?"
        },
        "download": "Download da proposta",
        "select-sections-for-download": "Selecione as seções do modelo de documento para realizar o download",
        "sections": "Seções",
        "status": {
            "OPEN": "Em aberto",
            "ACCEPTED": "Aprovada",
            "DECLINED": "Reprovada"
        },
        "delete": {
            "title": "Excluir proposta",
            "confirm": "Você tem certeza que deseja excluir esta proposta?"
        },
        "products": {
            "label": "Produtos",
            "empty": "Não há produtos nesta proposta",
            "total": "Preço total: { total } e { recurrent } recorrente",
            "add": "Adicionar produto"
        }
    },
    "proposal-product": {
        "product": "Produto",
        "measurement-unit": {
            "UNIT": "unidade",
            "HOUR": "hora",
            "METER": "metro",
            "KILO": "quilo",
            "LITER": "litro",
        },
        "price-by-unit":
            "Valor por { measurementUnit }: { price }",
        "unit-price": "Preço unitário",
        "quantity": "Quantidade",
        "discount": "Desconto",
        "of-discount": "{ discount } de desconto",
        "recurrent": "Recorrente",
        "total": "Preço total",
        "subtotal": "Subtotal: { total }",
        "total-price": "Preço total: { total }",
        "inactive": "* Produto inativo",
        "linked-products": "Produtos vinculados às propostas"
    },
    "phases": {
        "dialog": {
            "title": "Alterar fase",
            "change-opportunity-closed": "Você tem certeza que deseja alterar esta fase? A oportunidade será reaberta"
        },
        "open-opportunity": "Acessar oportunidade",
        "opportunity-open-scheduling": {
            "empty": "Sem agendamento",
            "late": "Agendamento atrasado",
            "scheduled": "Com agendamento"
        },
        "late": "Ultrapassou o limite de { days } dia(s) de permanência na fase { phase }",
        "triggers": "Gatilhos",
        "send": "Enviar",
        "execute-triggers": "Selecione quais templates você deseja enviar",
        "template": {
            "description": "Template: { name }. { type }",
            "type": {
                "EMAIL": "Envio por e-mail",
                "SMS": "Envio por SMS"
            },
            "send-customer": " para o cliente",
            "send-users": " para os usuários { users }"
        }
    },
    "temperatures": {
        "COLD": "Frio",
        "WARM": "Morno",
        "HOT": "Quente"
    },
    "new-comment": {
        "title": "Novo comentário",
        "comment": "Comentário"
    },
    "mercado-libre": {
        "respond": "Responder pergunta"
    },
    "lead": {
        "open": "Ir para o lead",
        "open-body": "Abrir corpo do e-mail do lead"
    },
    "not-found": "Oportunidade não encontrada",
    "export-file": "Exportar arquivo"
};