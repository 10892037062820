export default {
    "title": "Para hoje",
    "kpis": {
        "open-schedules": {
            "title": "Agendamentos para hoje",
            "help": "Agendamentos, que estão em aberto, com data para hoje"
        },
        "open-schedules-other-users": {
            "title": "Agendamentos para hoje para vendedores",
            "help": "Agendamentos, que estão em aberto e criados por você para vendedores, com data para hoje"
        },
        "late-schedules": {
            "title": "Agendamentos atrasados",
            "help": "Agendamentos, que estão em aberto, atrasados"
        },
        "late-schedules-other-users": {
            "title": "Agendamentos atrasados para vendedores",
            "help": "Agendamentos criados por você para vendedores que estão atrasados"
        },
        "opportunities-empty-open-schedules": {
            "title": "Oportunidades sem agendamento",
            "help": "Oportunidades que estão sem agendamentos abertos"
        },
        "new-opportunities": {
            "title": "Novas oportunidades",
            "help": "Oportunidades criadas hoje"
        }
    },
    "phases-funnel": {
        "opportunities": "Oportunidades",
        "filter": "Filtro",
        "empty": "Sem informação de fases",
        "title": "Funil de { funnel }"
    },
    "user-todos": {
        "title": "Agendamentos em aberto",
        "empty": "Não há o que fazer",
        "events-user": "Seus agendamentos",
        "events-other-users": "Agendamentos de outros usuários",
        "events-other-user": "Agendamentos para vendedores",
        "events-seller-user": "Agendamentos criados por você",
        "events-qualifier-to-seller": "Agendamentos criados por outros usuários"
    },
    "goal-progress": {
        "title": "Metas do mês atual",
        "target": "Objetivo",
        "reached": "Alcançado",
        "quantity": "(quantidade)",
        "minutes": "(minutos)",
        "average-service-time": "{ time } minutos",
        "opportunities-created": "{ total } oportunidades criadas",
        "empty": "Sem informação de metas"
    },
    "opportunity-actions": {
        "title": "Alterado com sucesso",
        "info": "O status do agendamento da oportunidade { opportunity } foi alterado com sucesso",
        "comment": "Inserir comentário",
        "access": "Acessar oportunidade"
    }
};