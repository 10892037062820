import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./layout.actions";

export function all ( state = { menuShown: false }, action ) {
    switch ( action.type ) {
        case actions.TOGGLE_MENU:
            return {
                ...state,
                menuShown: !state.menuShown
            };

        case actions.SET_TOP_BAR_TITLE:
            return { ...state, topBarTitle: action.payload };

        case actions.SET_ACCESS_BLOCKED:
            return Object.assign( {}, state, {
                accessBlocked: action.payload
            });

        default:
            return state;
    }
}

export const topBarItems = createRequestReducer({
    begin: actions.REQUEST_TOP_BAR_ITEMS,
    success: actions.RECEIVE_TOP_BAR_ITEMS,
    failure: actions.ERROR_TOP_BAR_ITEMS
});

export function notifications ( state = {}, action ) {
    switch ( action.type ) {
        case actions.FETCH_NOTIFICATIONS:
            const currentPage = action.payload.page;

            return Object.assign( {}, state, {
                currentPage,
                data: currentPage > 1 ? state.data : []
            });

        case actions.REQUEST_NOTIFICATIONS:
            return Object.assign( {}, state, {
                loading: true
            });

        case actions.RECEIVE_NOTIFICATIONS:
            const { totalPages, notifications } = action.payload;

            return Object.assign( {}, state, {
                loading: false,
                totalPages,
                data: state.currentPage > 1 ? state.data.concat( notifications ) : notifications,
            });

        case actions.ERROR_NOTIFICATIONS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        case actions.RECEIVE_NOTIFICATIONS_UNREAD:
            return Object.assign( {}, state, {
                unread: action.payload
            });

        default:
            return state;
    }
}


const holdings = createRequestReducer({
    begin: actions.REQUEST_HOLDINS,
    success: actions.RECEIVE_HOLDINGS,
    failure: actions.ERROR_HOLDINGS
});

const userSubscription = createRequestReducer({
    begin: actions.REQUEST_USER_SUBSCRIPTION,
    success: actions.RECEIVE_USER_SUBSCRIPTION,
    failure: actions.ERROR_USER_SUBSCRIPTION,
});

export const layout = combineReducers({ all, topBarItems, notifications, holdings, userSubscription });