import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import { actions } from "./";
import { SELECT_HOLDING_SUBSCRIPTION_FORM, SEND_USER_VERIFICATION_FORM } from "./layout.constants";

export function *watchLayout () {
    yield all([
        takeEvery( actions.FETCH_NOTIFICATIONS, fetchNotifications ),
        takeEvery( actions.FETCH_NOTIFICATIONS_UNREAD, fetchNotificationsUnread ),
        takeEvery( actions.READ_NOTIFICATION, readNotification ),
        takeEvery( actions.READ_ALL_NOTIFICATIONS, readAllNotifications ),
        takeEvery( actions.FETCH_TOP_BAR_ITEMS, fetchTopBarItems ),
        takeEvery( actions.FETCH_HOLDINGS, fetchHoldings ),
        takeEvery( actions.LOGIN_HOLDING, loginHolding ),
        takeEvery( actions.LOGOUT_HOLDING, logoutHolding ),
        takeEvery( actions.FETCH_USER_SUBSCRIPTION, fetchUserSubscription ),
        takeEvery( actions.SEND_USER_VERIFICATION, sendUserVerification ),
    ]);
}

export function *fetchTopBarItems ({ payload: { search } }) {
    yield put( actions.requestTopBarItems() );
    try {
        const customers = yield call( apiv1.get, "/customers/search", { params: { query: search } } );
        const opportunities = yield call( apiv1.get, "/opportunities/search", { params: { name: search } } );

        const items = [];
        for ( let i = 0; i < 5 && i < customers.data.length ; i++ ) {
            items.push({
                type: customers.data[ i ].type,
                id: customers.data[ i ].id,
                name: customers.data[ i ].name
            });
        }
        for ( let i = 0; i < 5 && i < opportunities.data.length ; i++ ) {
            items.push({
                type: "OPPORTUNITY",
                id: opportunities.data[ i ].id,
                name: opportunities.data[ i ].title
            });
        }

        yield put( actions.receiveTopBarItems( items ) );
    } catch ( e ) {
        console.log( e );
        yield put( actions.errorTopBarItems( e.response.data ) );
    }
}

export function *fetchNotifications ({ payload }) {
    yield put( actions.requestNotifications() );
    try {
        const response = yield call( apiv1.get, "/notifications", { params: { ...payload }} );
        const notifications = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        yield put( actions.receiveNotifications({ notifications, totalPages }) );
    } catch ( e ) {
        yield put( actions.errorNotifications( e.response.data ) );
    }
}

export function *fetchNotificationsUnread () {
    try {
        const response = yield call( apiv1.get, "/notifications/unread" );
        yield put( actions.receiveNotificationsUnread( response.data ) );
    } catch ( e ) {
        // lala
    }
}

export function *readNotification ({ payload: { notification }}) {
    try {
        yield call( apiv1.put, `/notifications/${notification.id}/read` );
        yield put( actions.fetchNotificationsUnread() );
    } catch ( e ) {
        // lala
    }
}

export function *readAllNotifications () {
    try {
        yield call( apiv1.put, "/notifications/read" );
        yield put( actions.fetchNotificationsUnread() );
    } catch ( e ) {
        // lala
    }
}

function *fetchHoldings () {
    try {
        yield put( actions.requestHoldings() );
        const response = yield call( apiv1.get, "/users/me/holdings" );
        yield put( actions.receiveHoldings( response.data ) );
    } catch ( e ) {
        yield put( actions.errorHoldings( e.response.data ) );
    }
}

function *loginHolding ({ payload }) {
    yield put( startSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM ) );
    try {
        yield call( apiv1.post, `/users/me/holdings/${payload.token}/login` );
        yield put( stopSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM, e.response.data ) );
    }
}

function *logoutHolding () {
    yield put( startSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM ) );
    try {
        yield call( apiv1.delete, "/users/me/holdings/logout" );
        yield put( stopSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SELECT_HOLDING_SUBSCRIPTION_FORM, e.response.data ) );
    }
}

function *fetchUserSubscription () {
    yield put( actions.requestUserSubscription() );
    try {
        const response = yield call( apiv1.get, "/users/me/subscription" );
        yield put( actions.receiveUserSubscription( response.data ) );
    } catch ( e ) {
        yield put( actions.errorUserSubscription( e.response.data ) );
    }
}

function *sendUserVerification () {
    yield put( startSubmit( SEND_USER_VERIFICATION_FORM ) );
    try {
        yield call( apiv1.post, "/users/me/verification" );
        yield put( stopSubmit( SEND_USER_VERIFICATION_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( SEND_USER_VERIFICATION_FORM, e.response.data ) );
    }
}