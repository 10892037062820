import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";
import dayjs from "dayjs";
import classnames from "classnames";
import sortBy from "lodash/sortBy";
import qs from "qs";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { DatePicker, Dropdown, cssUtils, flex } from "../ui";
import { selectors as formSelectors } from "../form";
import * as formActions from "../form/form.actions";
import { actions as funnelActions, selectors as funnelSelectors } from "../funnel";
import { actions as teamActions, selectors as teamSelectors } from "../team";
import { actions as userActions, selectors as userSelectors } from "../user";
import { setReport } from "./report.actions";
import { getSelected } from "./report.selectors";
import OpportunitiesBySource from "./opportunities-by-source.component";
import AccumulatedByUser from "./accumulated-by-user.component";
import AverageServiceTimeByUser from "./average-service-time-by-user.component";
import AverageOpportunityTimeSoldByUser from "./average-opportunity-time-sold-by-user.component";
import CommissionResultsByUser from "./commission-results-by-user.component";
import GoalsByUser from "./goals-by-user.component";
import SalesForecastByUser from "./sales-forecast-by-user.component";
import OpportunitySchedulesByUser from "./opportunity-schedules-by-user.component";
import OpportunityTrackingByUser from "./opportunity-tracking-by-user.component";
import OpportunitiesLostByReason from "./opportunities-lost-by-reason.component";
import OpportunitiesSoldByField from "./opportunities-sold-by-field.component";
import ConversionRateByUser from "./conversion-rate-by-user.component";
import ProductsSoldByFunnel from "./products-sold-by-funnel.component";
import OpportunitiesCreatedByUser from "./opportunities-created-by-user.component";
import ProposalsCreatedByUser from "./proposals-created-by-user.component";
import AverageTicketByUser from "./average-ticket-by-user.component";
import OpportunitiesForwardedByUser from "./opportunities-forwarded-by-user.component";
import ReportsPrint from "./reports-print.component";

const enDayjs = dayjs();
enDayjs.locale( "en" );

const getYears = () => {
    const years = [ 2018 ];
    let year = 2018;
    const yearOfToday = new Date().getFullYear();
    while ( year < yearOfToday ) {
        year++;
        years.push( year );
    }
    return years;
};

const getMonths = () => {
    return dayjs
        .months()
        .map( ( month, index ) => ({
            value: enDayjs.month( index ).format( "MMMM" ).toUpperCase(),
            label: capitalize( month )
        }));
};

const capitalize = value => value.charAt( 0 ).toUpperCase() + value.slice( 1 );

const Reports = ({ className }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const loadingOpportunityForm = useSelector( formSelectors.isLoading( "OPPORTUNITY" ) );
    const loadingFunnels = useSelector( funnelSelectors.isLoadingAll );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( userSelectors.isLoadingAll );
    const report = useSelector( getSelected );

    const loading = useMemo(
        () => loadingOpportunityForm || loadingFunnels || loadingTeams || loadingUsers,
        [ loadingFunnels, loadingOpportunityForm, loadingTeams, loadingUsers ]
    );

    const [ initialDate, setInitialDate ] = useState( dayjs().startOf( "month" ).toDate() );
    const [ finalDate, setFinalDate ] = useState( dayjs().endOf( "month" ).toDate() );
    const [ latestValidInitialDate, setLatestValidInitialDate ] = useState( initialDate );
    const [ latestValidFinalDate, setLatestValidFinalDate ] = useState( finalDate );
    const [ month, setMonth ] = useState( enDayjs.format( "MMMM" ).toUpperCase() );
    const [ year, setYear ] = useState( new Date().getFullYear() );

    const handleChangeInitialDate = value => {
        const dateValue = dayjs( value );
        const initialDate = dateValue.startOf( "day" ).toDate();
        if ( dateValue.isValid() && dateValue.isSameOrBefore( latestValidFinalDate ) ) {
            setInitialDate( initialDate );
            setLatestValidInitialDate( initialDate );
        } else {
            setInitialDate( initialDate );
        }
    };

    const handleChangeFinalDate = value => {
        const dateValue = dayjs( value );
        const finalDate = dateValue.endOf( "day" ).toDate();
        if ( dateValue.isValid() && dateValue.isSameOrAfter( latestValidInitialDate ) ) {
            setFinalDate( finalDate );
            setLatestValidFinalDate( finalDate );
        } else {
            setFinalDate( finalDate );
        }
    };

    const prevLoading = usePrevious( loading );

    useEffect( () => {
        dispatch( formActions.fetchForm( "OPPORTUNITY" ) );
        dispatch( funnelActions.fetchFunnels() );
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );

        return () => {
            dispatch( setReport() );
        };
    }, [ dispatch ] );
    useEffect( () => {
        if ( prevLoading && !loading ) {
            dayjs.locale( t( "common:full-locale" ) );

            const queryString = qs.parse( location.search, { ignoreQueryPrefix: true } );
            queryString.report && dispatch( setReport( queryString.report ) );
        }
    }, [ dispatch, prevLoading, loading, location.search, t ] );
    useEffect( () => {
        if ( report === "OPPORTUNITY_TRACKING_BY_USER" ) {
            setLatestValidInitialDate( dayjs().startOf( "month" ).toDate() );
            setLatestValidFinalDate( dayjs().endOf( "month" ).toDate() );
        }
    }, [ report ] );

    const reports = [
        { value: "OPPORTUNITIES_BY_SOURCE", icon: <InsertChartIcon/> },
        { value: "ACCUMULATED_BY_USER", icon: <InsertChartIcon/> },
        { value: "AVERAGE_SERVICE_TIME_BY_USER", icon: <InsertChartIcon/> },
        { value: "AVERAGE_OPPORTUNITY_TIME_SOLD_BY_USER", icon: <InsertChartIcon/> },
        { value: "AVERAGE_TICKET_BY_USER", icon: <InsertChartIcon/> },
        { value: "COMMISSION_RESULTS_BY_USER", icon: <InsertChartIcon/> },
        { value: "SALES_FORECAST_BY_USER", icon: <InsertChartIcon/> },
        { value: "GOALS_BY_USER", icon: <InsertChartIcon/> },
        { value: "OPPORTUNITY_SCHEDULES_BY_USER", icon: <InsertChartIcon/> },
        { value: "OPPORTUNITY_TRACKING_BY_USER", icon: <DesktopWindowsIcon/> },
        { value: "OPPORTUNITIES_LOST_BY_REASON", icon: <InsertChartIcon/> },
        { value: "OPPORTUNITIES_SOLD_BY_FIELD", icon: <InsertChartIcon/> },
        { value: "CONVERSION_RATE_BY_USER", icon: <InsertChartIcon/> },
        { value: "PRODUCTS_SOLD_BY_FUNNEL", icon: <InsertChartIcon/> },
        { value: "OPPORTUNITIES_CREATED_BY_USER", icon: <InsertChartIcon/> },
        { value: "PROPOSALS_CREATED_BY_USER", icon: <InsertChartIcon/> },
        { value: "OPPORTUNITIES_FORWARDED_BY_USER", icon: <InsertChartIcon/> },
    ];

    if ( loading ) {
        return (
            <div className={ classnames( cssUtils.textCenter, cssUtils.paddingSmall ) }>
                <CircularProgress/>
            </div>
        );
    }

    return (
        <div className={ classnames( className, cssUtils.displayBlock )}>
            <Helmet title={ t( "report:title" ) } />
            <Card>
                <CardContent className={ classnames( flex.container, flex.alignItemsCenter ) }>
                    <Dropdown
                        id="selectReport"
                        label={ t( "report:filters.report" ) }
                        fullWidth={ false }
                        className={ flex.item33 }
                        onChange={ event => dispatch( setReport( event.target.value ) ) }
                        value={ report }
                        source={ sortBy(
                            reports.map( item => ({ ...item, label: t( "report:reports." + item.value ) }) ),
                            [ "label" ]
                        )}
                    />
                    <div id="dates" className={ classnames( flex.container, flex.item66 ) }>
                        {
                            report === "GOALS_BY_USER" || report === "COMMISSION_RESULTS_BY_USER" ?
                                <>
                                    <Dropdown
                                        label={ t( "report:filters.year" ) }
                                        className={ classnames( flex.fill, cssUtils.marginLeft ) }
                                        onChange={ event => setYear( event.target.value ) }
                                        source={ getYears().map( year => ({ label: year, value: year })) }
                                        value={ year }
                                    />
                                    <Dropdown
                                        label={ t( "report:filters.month" ) }
                                        className={ classnames( flex.fill, cssUtils.marginLeft ) }
                                        onChange={ event => setMonth( event.target.value ) }
                                        source={ getMonths() }
                                        sort={ false }
                                        value={ month }
                                    />
                                </> :
                                <>
                                    <div className={ classnames( flex.fill, cssUtils.marginLeft ) }>
                                        <DatePicker
                                            label={ t( "report:filters.initial-date" ) }
                                            onChange={ handleChangeInitialDate }
                                            value={ initialDate }
                                            maxDate={ latestValidFinalDate }
                                            onBlur={ setLatestValidInitialDate }
                                            disabled={ !report }
                                        />
                                    </div>
                                    <div className={ classnames( flex.fill, cssUtils.marginLeft ) }>
                                        <DatePicker
                                            label={ t( "report:filters.final-date" ) }
                                            onChange={ handleChangeFinalDate }
                                            value={ finalDate }
                                            minDate={ latestValidInitialDate }
                                            onBlur={ setLatestValidFinalDate }
                                            disabled={ !report }
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </CardContent>
            </Card>

            {
                report === "OPPORTUNITIES_BY_SOURCE" &&
                    <OpportunitiesBySource
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "ACCUMULATED_BY_USER" &&
                    <AccumulatedByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "AVERAGE_SERVICE_TIME_BY_USER" &&
                    <AverageServiceTimeByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "AVERAGE_OPPORTUNITY_TIME_SOLD_BY_USER" &&
                    <AverageOpportunityTimeSoldByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "AVERAGE_TICKET_BY_USER" &&
                    <AverageTicketByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "COMMISSION_RESULTS_BY_USER" &&
                    <CommissionResultsByUser
                        month={ month }
                        year={ year }
                    />
            }
            {
                report === "GOALS_BY_USER" &&
                    <GoalsByUser
                        month={ month }
                        year={ year }
                    />
            }
            {
                report === "SALES_FORECAST_BY_USER" &&
                    <SalesForecastByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITY_SCHEDULES_BY_USER" &&
                    <OpportunitySchedulesByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITY_TRACKING_BY_USER" &&
                    <OpportunityTrackingByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITIES_LOST_BY_REASON" &&
                    <OpportunitiesLostByReason
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITIES_SOLD_BY_FIELD" &&
                    <OpportunitiesSoldByField
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "CONVERSION_RATE_BY_USER" &&
                    <ConversionRateByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "PRODUCTS_SOLD_BY_FUNNEL" &&
                    <ProductsSoldByFunnel
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITIES_CREATED_BY_USER" &&
                    <OpportunitiesCreatedByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "OPPORTUNITIES_FORWARDED_BY_USER" &&
                    <OpportunitiesForwardedByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report === "PROPOSALS_CREATED_BY_USER" &&
                    <ProposalsCreatedByUser
                        initialDate={ latestValidInitialDate }
                        finalDate={ latestValidFinalDate }
                    />
            }
            {
                report && <ReportsPrint report={ report }/>
            }
        </div>
    );
};

export default Reports;