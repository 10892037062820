import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { Form } from "formik-redux";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as sourceSelectors } from "../../source";
import { cssUtils, Dropdown, Input, SelectFunnel, UsersFilter } from "../../ui";
import MappingFields from "./mapping-fields.component";

export const validate = ( values, { customerFields, integrationChannel } ) => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    if ( !get( values, "funnel.id" ) ) {
        set( errors, "funnel.id", "common:validation.selection" );
    }

    if ( !get( values, "source.id" ) ) {
        set( errors, "source.id", "common:validation.selection" );
    }

    if ( !values.teamId && isEmpty( values.usersId ) ) {
        errors.usersId = "marketplace:automation.empty-users";
    }

    integrationChannel !== "RD_STATION" && ( customerFields || [] )
        .filter( field => field.systemField === "NAME" )
        .forEach( field => {
            const value = get( values, `mappingFields.${field.id}` );
            if ( !value ) {
                set( errors, `mappingFields.${field.id}`, "common:validation.required" );
            }
        });
    if ( integrationChannel === "EXACT_SALES" && !get( values, "accessToken" ) ) {
        set( errors, "accessToken", "common:validation.required" );
    }

    return errors;
};

const AutomationForm = ({ formik, integrationChannel }) => {
    const t = useTranslate();

    const sources = useSelector( sourceSelectors.getSources );

    const handleChange = name => value => formik.setFieldValue( name, value );

    const errorUsers = formik.errors?.usersId && formik.touched?.usersId;

    return (
        <Form formik={ formik }>
            {
                integrationChannel === "EXACT_SALES" &&
                    <Field
                        name="accessToken"
                        label={ t( "marketplace:automation.access-token" ) }
                        component={ Input }
                        required
                    />
            }
            <Field
                name="name"
                label={ t( "marketplace:automation.name.label" ) }
                component={ Input }
                required
            />
            <Typography color="textSecondary" variant="caption" className={ cssUtils.marginBottomSmall }>
                { t( "marketplace:automation.name.help" ) }
            </Typography>

            <Field
                name="funnel.id"
                component={ SelectFunnel }
                required
            />

            <Field
                name="source.id"
                label={ t( "marketplace:automation.source" ) }
                component={ Dropdown }
                source={ sources?.map( source => ({ value: source.id, label: source.name }))}
                required
            />

            <Field name="usersId" type="hidden" component="input"/>
            <UsersFilter
                ComponentRoot={ Fragment }
                error={ errorUsers }
                input={{
                    team: {
                        value: formik.values.teamId,
                        onChange: value => handleChange( "teamId" )( value )
                    },
                    users: {
                        value: formik.values.usersId,
                        onChange: value => handleChange( "usersId" )( value )
                    }
                }}
                fullWidth
            />
            {
                errorUsers &&
                    <Typography color="error" variant="caption">
                        { t( formik.errors.usersId ) }
                    </Typography>
            }

            <MappingFields integrationChannel={ integrationChannel }/>
        </Form>
    );
};

export default AutomationForm;