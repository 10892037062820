export default {
    "title": "Dashboard",
    "sources": "Origens",
    "select-teams-or-users": "Selecione as equipes e/ou usuários",
    "team": "Equipe",
    "empty-team": "Não há equipes configuradas",
    "users": "Usuários",
    "period": {
        "label": "Período",
        "values": {
            "TODAY": "Hoje",
            "YESTERDAY": "Ontem",
            "THIS_WEEK": "Esta semana",
            "LAST_WEEK": "Semana passada",
            "THIS_MONTH": "Este mês",
            "LAST_MONTH": "Mês passado",
            "THIS_QUARTER": "Este trimestre",
            "LAST_QUARTER": "Trimestre passado",
            "THIS_YEAR": "Este ano",
            "LAST_YEAR": "Ano passado",
            "CUSTOM": "Personalizado"
        }
    },
    "initial-date": "Período inicial",
    "final-date": "Período final",
    "help": "Ajuda",
    "opportunities-created-chart": {
        "title": "Oportunidades criadas",
        "title-dialog": "Oportunidades criadas de { initialDate } até { finalDate }",
        "empty": "Sem informação de oportunidades criadas",
        "accumulated": "Acumulado",
        "help": "Considera as oportunidades pela data de criação e usuário que está com a oportunidade.",
        "help-accumulated": "Na opção acumulado, você visualiza a soma dos dados dos usuários selecionados"
    },
    "opportunities-kpis": {
        "conversion": {
            "rate": "Taxa de conversão",
            "of": "De",
            "finished": "oportunidades finalizadas",
            "help":
                "Taxa de conversão com base nas oportunidades ganhas x oportunidades finalizadas " +
                "(ganhas + perdidas)"
        },
        "created-total": {
            "title": "Oportunidades criadas",
            "help": "Total de oportunidades criadas, no período, para o(s) usuário(s) selecionado(s)"
        },
        "created-price": {
            "title": "Valor das oportunidades criadas",
            "help": "Valor total das oportunidades criadas, no período, para o(s) usuário(s) selecionado(s)",
            "total": "Total"
        },
        "sold-total": {
            "title": "Oportunidades ganhas",
            "help": "Total de oportunidades ganhas, no período, do(s) usuário(s) selecionado(s)"
        },
        "sold-price": {
            "title": "Valor das oportunidades ganhas",
            "help": "Valor total das oportunidades ganhas, no período, do(s) usuário(s) selecionado(s)",
            "total": "Total"
        },
        "lost-total": {
            "title": "Oportunidades perdidas",
            "help": "Total de oportunidades perdidas, no período, dos usuários selecionados"
        },
        "lost-price": {
            "title": "Valor das oportunidades perdidas",
            "help": "Valor total das oportunidades perdidas, no período, do(s) usuário(s) selecionado(s)",
            "total": "Total"
        },
        "schedules-open": {
            "title": "Agendamentos em aberto",
            "help": "Agendamentos em aberto com data no período"
        },
        "schedules-accomplished": {
            "title": "Agendamentos realizados",
            "help": "Agendamentos realizados com data no período"
        },
        "schedules-not-accomplished": {
            "title": "Agendamentos não realizados",
            "help": "Agendamentos não realizados com data no período"
        },
        "first-interact": {
            "title": "Tempo primeira interação",
            "help": "Tempo médio para a primeira interação realizada na oportunidade após a criação"
        },
        "first-contact": {
            "title": "Tempo primeira tentativa de contato",
            "help": "Tempo médio entre a criação da oportunidade e a primeira tentativa de contato"
        },
        "closing-time": {
            "title": "Tempo fechamento de oportunidade",
            "help": "Tempo médio entre a criação da oportunidade e o fechamento da oportunidade"
        }
    },
    "loss-reasons-chart": {
        "title": "Motivo venda perdida",
        "title-dialog": "Oportunidades perdidas por { lossReason }",
        "empty": "Sem informação de motivo venda perdida",
        "opportunities": "Oportunidades",
        "help": "Considera as oportunidades perdidas pelo seu motivo venda perdida"
    },
    "opportunities-sales-forecast-chart": {
        "title": "Previsão de fechamento",
        "empty": "Sem informação de previsão de fechamento",
        "open": "Aberto",
        "open-recurrent": "Aberto recorrente",
        "sold": "Vendido",
        "sold-recurrent": "Vendido recorrente",
        "help-open":
            "Aberto: considera as oportunidades abertas com a data de previsão de fechamento no período selecionado",
        "help-sold": "Vendido: considera as oportunidades ganhas com a data de conclusão no período selecionado"
    },
    "opportunities-temperature-chart": {
        "title": "Temperaturas",
        "title-dialog": "Oportunidades com temperatura { temperature }",
        "empty": "Sem informação de temperatura",
        "cold": "Frio",
        "warm": "Morno",
        "hot": "Quente",
        "opportunities": "Oportunidades",
        "help":
            "Considera as oportunidades pela data de criação, que estão abertas e que " +
            "possuem uma temperatura selecionada"
    },
    "opportunity-checkin-chart": {
        "title": "Check-in nas oportunidades",
        "help": "",
        "list-title": "Check-ins realizados por { user }",
        "open": "Abrir no Google Maps"
    },
    "opportunity-scheduling-types-chart": {
        "title": "Tipos de agendamentos de oportunidade",
        "empty": "Sem informação de tipo de agendamento",
        "open": "Em aberto",
        "accomplished": "Realizados",
        "not-accomplished": "Não realizados",
        "help": "Considera os agendamentos de oportunidade pela data agendada"
    },
    "opportunities-by-source": {
        "title": "Oportunidades por Origem",
        "empty": "Sem oportunidades",
        "source": "Origem",
        "created": "Criadas",
        "sold": "Ganhas",
        "lost": "Perdidas",
        "help": "Considera as oportunidades pelas datas de criação, venda ganha e perdida de acordo com a origem",
        "title-dialog": "Oportunidades { status } de origem { source }"
    },
    "print-chart": {
        "title": "KPIS e Dashboards",
        "conversion-rate": "Taxa de conversão (KPI)",
        "opportunities-created-total": "Oportunidades (KPI)",
        "opportunities-created-price": "Valores das Oportunidades (KPI)",
        "scheduling": "Agendamentos (KPI)",
        "time-metrics": "Métricas de tempo (KPI)",
        "opportunities-by-source": "Oportunidades por origens (Dashboard)",
        "opportunities-created": "Oportunidades criadas (Dashboard)",
        "opportunities-lost-reasons": "Motivo de venda perdida (Dashboard)",
        "temperature": "Temperatura (Dashboard)",
        "opportunities-sales-forecast": "Previsão de fechamento (Dashboard)",
        "opportunity-checkin": "Check-in nas oportunidades (Dashboard)",
        "opportunity-scheduling-types": "Tipos de agendamentos de oportunidade (Dashboard)",
        "emission": "Emissão { date } às { time } por { user } pela inscrição { subscription }",
        "print-title": "Dashboard LeadStation",
        "info": "Observação: nas opções de impressão, certifique-se que a opção: " +
        "\"{ browserInfo }\" esteja marcada para visualizar corretamente os graficos.",
        "edge": "Elementos Gráficos de plano de fundo",
        "chrome": "Gráficos de segundo plano",
        "firefox": "Imprimir fundo",
        "safari": "Imprimir fundos",
        "unknown": "Gráficos de segundo plano",
        "opera": "Elementos gráficos em segundo plano"
    }
};