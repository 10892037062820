export const NEW_OPPORTUNITY_FORM = "newOpportunity";
export const SEARCH_CUSTOMERS_FORM = "searchCustomersForm";
export const SAVE_COMPANY_FORM = "saveNewOpportunityCompanyForm";
export const SAVE_PERSON_FORM = "saveNewOpportunityPersonForm";

export const UPDATE_OPPORTUNITIES_BATCH_FORM = "updateOpportunitiesBatch";
export const EDIT_OPPORTUNITY_FORM = "editOpportunity";
export const PHASE_TRIGGERS_FORM = "opportunityPhaseTriggers";
export const NEW_OPPORTUNITY_COMMENT_FORM = "newOpportunityComment";
export const NEW_SCHEDULING_FORM = "newOpportunityScheduling";
export const SAVE_SCHEDULING = "saveScheduling";
export const OPPORTUNITY_STATUS_FORM = "opportunityStatusForm";
export const OPPORTUNITY_PHASE_FORM = "opportunityPhaseForm";
export const NEW_PROPOSAL_FORM = "newOpportunityProposalForm";
export const ADD_COMPANY_FORM = "addOpportunityCompanyForm";
export const DELETE_COMPANY_FORM = "deleteOpportunityCompanyForm";
export const ADD_PERSON_FORM = "addOpportunityPersonForm";
export const DELETE_PERSON_FORM = "deleteOpportunityPersonForm";
export const ADD_OTHER_PERSON_FORM = "addOtherOpportunityPersonForm";
export const DELETE_OTHER_PERSON_FORM = "deleteOtherOpportunityPersonForm";
export const NEW_SMS_FORM = "newOpportunitySmsForm";
export const DELETE_OPPORTUNITY_FORM = "deleteOpportunityForm";
export const OPPORTUNITY_LOST_FORM = "opportunityLostForm";
export const OPPORTUNITY_FILE_FORM = "opportunityFileForm";
export const OPEN_SCHEDULES_STATUS_FORM = "opportunityOpenSchedulesStatusForm";
export const OPPORTUNITY_HOLDING_FORM = "opportunityHoldingForm";
export const EXPORTATION_FORM = "opportunityExportationForm";
export const USER_TABLE_COLUMNS_FORM = "opportunityUserTableColumnsForm";