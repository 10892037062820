import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog } from "../ui";
import { DELETE_OPPORTUNITY_FORM } from "./opportunity.constants";
import { deleteOpportunity } from "./opportunity.actions";

const OpportunityDelete = ({ className, opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ confirmDelete, setConfirmDelete ] = useState( false );
    const [ showError, setShowError ] = useState( false );
    const [ menu, setMenu ] = useState( null );

    const formik = useForm({
        form: DELETE_OPPORTUNITY_FORM,
        enableReinitialize: true,
        initialValues: {
            id: opportunityId,
        },
        onSubmit: values => dispatch( deleteOpportunity( values ) ),
    });

    const toggleConfirmDelete = () => {
        setConfirmDelete( !confirmDelete );
        setMenu( null );
    };

    const toggleError = () => setShowError( !showError );

    const openMenu = e => setMenu( e.currentTarget );
    const closeMenu = () => setMenu( null );

    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
            setConfirmDelete( false );
        }
    }, [ formik.error ] );

    return (
        <>
            <Dialog
                open={ confirmDelete }
                onClose={ toggleConfirmDelete }
            >
                <DialogContent>
                    <DialogContentText>{ t( "opportunity:confirm-delete-opportunity" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmDelete } disabled={ formik.submitting }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showError }
                onClose={ toggleError }
            >
                <DialogContent>
                    <DialogContentText>{ formik.error?.error }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleError }>
                        { t( "common:ok" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <IconButton onClick={ openMenu } size="small" className={ className }>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                open={ !!menu }
                onClose={ closeMenu }
                anchorEl={ menu }
            >
                <MenuItem onClick={ toggleConfirmDelete }>
                    <ListItemIcon><DeleteIcon/></ListItemIcon>
                    <ListItemText primary={ t( "opportunity:delete-opportunity" ) }/>
                </MenuItem>
            </Menu>
        </>
    );
};

export default OpportunityDelete;