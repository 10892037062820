import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { getSubscription } from "../../../subscription/subscription.selectors";
import { listAll } from "../../../user/user.selectors";
import { fetchHolding } from "./holding.actions";
import { isLoadingHolding, getHolding } from "./holding.selectors";
import ConfigHeader from "../../config-header.component";
import HoldingToken from "./holding-token.component";
import HoldingFunnels from "./holding-funnels.component";
import AddSubscriptionHolding from "./add-subscription-holding.component";
import DeleteSubscriptionHolding from "./delete-subscription-holding.component";

const Holding = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const subscription = useSelector( getSubscription );
    const users = useSelector( listAll );
    const loading = useSelector( isLoadingHolding );
    const holding = useSelector( getHolding );

    const [ openLinkSubscription, setOpenLinkSubscription ] = useState( false );
    const [ holdingTokenToUnlink, setHoldingTokenToUnlink ] = useState( "" );

    const toggleLinkSubscription = () => setOpenLinkSubscription( !openLinkSubscription );

    const selectHoldingTokenToUnlink = token => setHoldingTokenToUnlink( token );

    useEffect( () => {
        dispatch( fetchHolding() );
    }, [ dispatch ] );

    return (
        <div>
            <ConfigHeader title={ t( "config:holding.title" ) }/>
            <Card>
                <CardHeader
                    subheader={ t( "config:holding.help" ) }
                />
                { loading && <LinearProgress/> }

                {
                    holding && holding.token &&
                        <>
                            <CardContent><HoldingToken holding={ holding }/></CardContent>
                            <hr/>
                            <HoldingFunnels holdingToken={ holding.token }/>
                        </>
                }

                <hr/>

                <CardHeader
                    title={ t( "config:holding.linked-subscriptions" ) }
                    subheader={ t( "config:holding.link-subscription-help" ) }
                    avatar={
                        <Avatar>
                            <SendIcon/>
                        </Avatar>
                    }
                />
                <List disablePadding>
                    <ListItemButton onClick={ toggleLinkSubscription }>
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={ t( "common:add" ) }
                        />
                    </ListItemButton>
                    {
                        users && ( subscription.holdings || [] ).map( sHolding => (
                            <ListItem key={ sHolding.token }>
                                <ListItemText
                                    primary={ `${sHolding.name} - ${sHolding.token}` }
                                    secondary={
                                        t( "config:holding.linked-subscription-creation", {
                                            date: dateFormat( sHolding.createdAt ),
                                            user: sHolding.createdBy.name
                                        })
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title={ t( "config:holding.unlink-subscription.title" ) }>
                                        <IconButton
                                            onClick={ () => selectHoldingTokenToUnlink( sHolding.token )}
                                            size="large">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </Card>

            <AddSubscriptionHolding
                open={ openLinkSubscription }
                onClose={ toggleLinkSubscription }
            />
            <DeleteSubscriptionHolding
                open={ !!holdingTokenToUnlink }
                onClose={ () => selectHoldingTokenToUnlink( "" ) }
                token={ holdingTokenToUnlink }
            />
        </div>
    );
};

export default Holding;