import { call, put, takeEvery } from "redux-saga/effects";

import history from "../../history";
import { app } from "../../api/sagas";
import { actions } from "./";
import { setThemeMode } from "../core/core.actions";
import { setAuth } from "../login/login.actions";

export function *logout () {
    try {
        yield call( app.delete, "/logout" );
    } catch ( e ) {
        // pode ja ter deslogado
    }
    yield put( setAuth( false ) );
    yield put( setThemeMode( "light" ) );
    yield call( [ history, history.push ], "/login" );
}

export function *watchlogout () {
    yield takeEvery( actions.USER_LOGOUT, logout );
}