import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./product.actions";

export function *watchProduct () {
    yield all([
        takeEvery( actions.FETCH_PRODUCTS, fetchProducts ),
        takeEvery( actions.FETCH_SEARCH_PRODUCTS, searchProducts ),
        takeEvery( actions.FETCH_PRODUCT_CATEGORIES, fetchProductCategories ),
    ]);
}

export function *fetchProducts ({ payload: { params }}) {
    yield put( actions.requestProducts() );

    try {
        const response = yield call( apiv1.get, "/products", { params } );
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveProducts({ products: response.data, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorProducts( e.response.data ) );
    }
}

function *searchProducts ({ payload }) {
    yield put( actions.requestSearchProducts() );

    try {
        const response = yield call( apiv1.get, "/products", { params: payload } );
        yield put( actions.receiveSearchProducts( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSearchProducts( e.response.data ) );
    }
}


export function *fetchProductCategories () {

    yield put( actions.requestCategories() );

    try {
        const response = yield call( apiv1.get, "/products/categories" );
        yield put( actions.receiveCategories( response.data ) );
    } catch ( e ) {
        yield put( actions.errorCategories( e.response.data ) );
    }
}