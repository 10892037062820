import { useSelector } from "react-redux";

import { selectors as formSelectors, FieldsSummary } from "../form";

const CustomerSummary = ({ alignPopupLeft, customer }) => {
    const formFields = useSelector( formSelectors.listSummaryFields( customer.type ) );
    const fields = formFields.filter( field => field.systemField !== "NAME" );
    if ( !customer.fields ) {
        return null;
    }

    return (
        <FieldsSummary
            fields={ fields }
            model={ customer }
            alignPopupLeft={ alignPopupLeft }
        />
    );
};

export default CustomerSummary;