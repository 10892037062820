export default {
    "opportunity-created": "Oportunidade criada",
    "opportunity-created-user": "criou esta oportunidade",
    "change": {
        "address": "{ name } ({ type })",
        "added": "adicionado",
        "removed": "removido"
    },
    "opportunity": {
        "closing-date": "Data de fechamento",
        "price": "Valor"
    }
};