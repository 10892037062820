import { useCallback, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import PhoneFieldItem from "./phone-field-item.component";
import css from "./phone.scss";

const PhoneField = ({ disabled, form, label, name, push, remove, required }) => {
    const t = useTranslate();

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    const handlePush = useCallback( () => {
        push({ country: t( "common:country" ), number: "" });
    }, [ t, push ] );

    useEffect( () => {
        if ( required && values.length === 0 ) {
            handlePush();
        }
    }, [ handlePush, required, values ] );

    return (
        <div className={ css.container }>
            <Typography variant="caption" >
                { label }
                { required ? " *" : "" }
            </Typography>
            <List>
                { values.map( ( phone, index ) =>
                    <PhoneFieldItem
                        key={ index }
                        index={ index }
                        name={ name }
                        disabled={ disabled }
                        onRemove={ remove }
                        showRemove={ !required || values.length > 1 }
                    />
                )}
            </List>
            <Button
                size="small"
                onClick={ handlePush }
                disabled={ disabled }
                startIcon={ <PhoneIcon/> }
            >
                { t( "form:phone.add" ) }
            </Button>
        </div>
    );
};

export default PhoneField;