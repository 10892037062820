import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";

import useTranslate from "../hooks/use-translate.hook";
import { patchCustomer } from "./customer.actions";
import { CUSTOMER_TAGS_FORM } from "./customer.constants";
import { getSelected } from "./customer.selectors";
import TagsField from "./tags-field.component";
import css from "./tags.scss";

const TagsEditor = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );

    const formik = useForm({
        form: CUSTOMER_TAGS_FORM,
        enableReinitialize: true,
        initialValues: {
            id: customer.id,
            tags: customer.tags,
        },
        onSubmit: values => dispatch( patchCustomer({
            ...values,
            tags: values.tags ? values.tags.map( tag => tag.toLowerCase() ) : values.tags,
            form: CUSTOMER_TAGS_FORM
        }) ),
        onSubmitSuccess: onClose,
    });

    return (
        <Card raised className={ css.editorContainer }>
            <Form formik={ formik } className={ css.form }>
                <fieldset disabled={ formik.submitting }>
                    <CardHeader subheader={ t( "customer:tags.title" ) }/>
                    <CardContent>
                        <Field
                            name="tags"
                            component={ TagsField }
                            classes={{
                                autocomplete: css.autocomplete,
                                input: css.input,
                                values: css.autocompleteValues
                            }}
                            disabled={ formik.submitting }
                            autoFocus
                            keepFocusOnChange
                        />
                    </CardContent>
                    <CardActions className={ css.actions }>
                        <Button color="primary" type="button" onClick={ onClose } disabled={ formik.submitting }>
                            { t( "common:cancel" ) }
                        </Button>
                        <Button color="primary" type="submit" disabled={ formik.submitting }>
                            { t( "common:save" ) }
                        </Button>
                    </CardActions>
                </fieldset>
            </Form>
        </Card>
    );
};

export default TagsEditor;