import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";

import useTranslate from "../../hooks/use-translate.hook";
import {
    TimelineItem,
    TimelineSummary,
    AddressChange,
    DateChange,
    EmailChange,
    PhoneChange,
    FieldChange
} from "../../ui/timeline";

import css from "../../ui/timeline/timeline.scss";

const mapChange = change => {
    switch ( change.field.type ) {
        case "ADDRESS":
            return <AddressChange change={ change } />;

        case "DATE":
            return <DateChange change={ change } />;

        case "EMAIL":
            return <EmailChange change={ change } />;

        case "PHONE":
            return <PhoneChange change={ change } />;

        default:
            return <FieldChange change={ change } />;
    }
};

const ChangeEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();

    return (
        <TimelineItem icon={ <EditIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "customer:timeline.customer-changed" ) }
                date={ date } />

            { payload.changes.map( ( change, index ) => (
                <div className={ css.change } key={ index }>
                    <Typography>{ change.field.name }{ ": " }</Typography>
                    { mapChange( change ) }
                </div>
            ))}
        </TimelineItem>
    );
};

export default ChangeEvent;