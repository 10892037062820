import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";
import get from "lodash/get";

// ---------------------------------------------------------------------------------------------------------------------
// Opportunity list
// ---------------------------------------------------------------------------------------------------------------------
const allBase = state => state.opportunities.all;
export const isLoadingAll = state => state.opportunities.all.loading;
export const listAll = createSelector( state => state.opportunities.all, all => all.data );
export const errorAll = state => state.opportunities.all.error;
export const getTotalPagesAll = state => state.opportunities.all.totalPages;
export const getTotalElementsAll = state => state.opportunities.all.totalElements;
export const getFunnelId = createSelector( allBase, all => all.funnelId );
export const getFunnelsId = createSelector( allBase, all => all.funnelsId );
export const getFunnelLatestUpdatedAt = state => state.opportunities.all.funnelLatestUpdatedAt;
export const getPhaseId = createSelector( allBase, all => all.phaseId || null );
export const getLossReasonId = createSelector( allBase, all => all.lossReasonId );
export const getSourceId = state => state.opportunities.all.sourceId;

const allStatisticsBase = state => state.opportunities.allStatistics;
export const getAllStatistics = requestSelectors.getData( allStatisticsBase );
export const isLoadingAllStatistics = requestSelectors.isLoading( allStatisticsBase );

const opportunitiesCountBase = state => state.opportunities.count;
export const getOpportunitiesCount = requestSelectors.getData( opportunitiesCountBase );
export const isLoadingOpportunitiesCount = requestSelectors.isLoading( opportunitiesCountBase );

const phaseOpportunitiesBase = state => state.opportunities.phases;
export const getPhaseOpportunities = phaseId => createSelector(
    phaseOpportunitiesBase,
    phases => phases[ phaseId ] || {}
);
export const isReloadingPhaseOpportunities = createSelector(
    phaseOpportunitiesBase,
    phases => !!Object.keys( phases || {} ).find( phaseId => get( phases, `${phaseId}.reload`, false ) )
);
export const getPhaseOpportunitiesPrice = createSelector(
    phaseOpportunitiesBase,
    phases => Object.keys( phases || {} ).reduce(
        ( model, phaseId ) => model + new Number( get( phases, `${phaseId}.totalPrice`, 0 ) ),
        0
    )
);
export const getPhaseOpportunitiesRecurrentPrice = createSelector(
    phaseOpportunitiesBase,
    phases => Object.keys( phases || {} ).reduce(
        ( model, phaseId ) => model + new Number( get( phases, `${phaseId}.totalRecurrentPrice`, 0 ) ),
        0
    )
);
export const getPhaseOpportunitiesTotal = createSelector(
    phaseOpportunitiesBase,
    phases => Object.keys( phases || {} ).reduce(
        ( model, phaseId ) => model + new Number( get( phases, `${phaseId}.totalElements`, 0 ) ),
        0
    )
);

const searchBase = state => state.opportunities.search;
export const isLoadingSearch = requestSelectors.isLoading( searchBase );
export const getSearchOpportunities = requestSelectors.getData( searchBase );
export const getSearchError = requestSelectors.getError( searchBase );
// ---------------------------------------------------------------------------------------------------------------------
// Single opportunity
// ---------------------------------------------------------------------------------------------------------------------
const selectedOpportunityBase = state => state.opportunities.selected;
export const isLoadingSelected = requestSelectors.isLoading( selectedOpportunityBase );
export const getSelected = createSelector( selectedOpportunityBase, selected => selected.data );
export const getError = requestSelectors.getError( selectedOpportunityBase );

const timelineBase = state => state.opportunities.timeline;
export const isLoadingTimeline = requestSelectors.isLoading( timelineBase );
export const getTimelineEvents = createSelector( timelineBase, timeline => timeline.data );
export const getTimelineError = requestSelectors.getError( timelineBase );
export const getTotalPagesTimeline = state => state.opportunities.timeline.totalPages;
export const reloadTimeline = state => state.opportunities.timeline.reload;

const personsBase = state => state.opportunities.persons;
export const isLoadingPersons = requestSelectors.isLoading( personsBase );
export const getPersons = requestSelectors.getData( personsBase );

// Files
const filesBase = state => state.opportunities.files;
export const getFiles = requestSelectors.getData( filesBase );
export const isLoadingFiles = requestSelectors.isLoading( filesBase );

// ---------------------------------------------------------------------------------------------------------------------
// Scheduling
// ---------------------------------------------------------------------------------------------------------------------
const schedulesBase = state => state.opportunities.schedules;
export const isLoadingSchedules = requestSelectors.isLoading( schedulesBase );
export const getSchedules = createSelector( schedulesBase, schedules => schedules.data );
export const getOpenSchedules = createSelector(
    getSchedules,
    schedules => schedules?.filter( scheduling => scheduling.status === "OPEN" ) || []
);
const schedulingTypesBase = state => state.opportunities.schedulingTypes;
export const isLoadingSchedulingTypes = requestSelectors.isLoading( schedulingTypesBase );
export const getSchedulingTypes = createSelector( schedulingTypesBase, schedulingTypes => schedulingTypes.data );

// ---------------------------------------------------------------------------------------------------------------------
// Proposal
// ---------------------------------------------------------------------------------------------------------------------
const proposalsBase = state => state.opportunities.proposals;
export const isLoadingProposals = requestSelectors.isLoading( proposalsBase );
export const getProposals = requestSelectors.getData( proposalsBase );
export const getOpenProposals = createSelector(
    proposalsBase,
    proposals => ( proposals?.data ?? [] ).filter( proposal => proposal.status === "OPEN" )
);
export const getNotDeclinedProposals = state => ( requestSelectors.getData( proposalsBase )( state ) || [] )
    .filter( proposal => proposal.status !== "DECLINED" );
export const getProposalsError = requestSelectors.getError( proposalsBase );

// ---------------------------------------------------------------------------------------------------------------------
// New opportunity
// ---------------------------------------------------------------------------------------------------------------------
export const isNewOpportunityOpen = state => state.opportunities.create.open;
export const getNewOpportunityCompanies = createSelector(
    state => state.opportunities.create.customersSearch,
    customersSearch => get( customersSearch, "companies", [] )
);
export const getNewOpportunityPersons = createSelector(
    state => state.opportunities.create.customersSearch,
    customersSearch => get( customersSearch, "persons", [] )
);
export const getNewOpportunityCompany = createSelector(
    state => state.opportunities.create,
    create => create.company
);
export const getNewOpportunityPerson = createSelector(
    state => state.opportunities.create,
    create => create.person,
);
export const getPersonSearchValues = createSelector(
    state => state.opportunities.create,
    create => create.customersSearchValues.person
);
export const getCompanySearchValues = createSelector(
    state => state.opportunities.create,
    create => create.customersSearchValues.company
);


// ---------------------------------------------------------------------------------------------------------------------
// Opportunity holding
// ---------------------------------------------------------------------------------------------------------------------
export const getOpportunityHolding = state => state.opportunities.opportunityHolding.data;