export default {
    "title": "Entrar",
    "email": "Correo electrónico",
    "password": "Contraseña",
    "login": {
        "forgot-password": "Olvidó su contraseña?",
        "action": "Entrar"
    },
    "request-activate": {
        "description":
            "¿Quieres activar tu cuenta? Haga clic en el botón Solicitar a continuación y nos pondremos en contacto.",
        "action": "Solicitar",
        "success": "¡Solicitud enviada con éxito! Estaremos en contacto pronto"
    },
    "or": "o",
    "facebook": "Iniciar sesión con Facebook",
    "google": "Iniciar sesión con Google",
    "signup": {
        "call-to-action": "No tienen una cuenta? Regístrese ahora!"
    },
    "error": {
        "credentials": "Asegúrese de que su correo electrónico y contraseña son correctos."
    },
    "forgot-password": {
        "title":
            "Introduzca la dirección de su correo elecrónico de su suscripción registrada. " +
            "Recibirá un correo electrónico con la información para reestablecer la contraseña.",
        "email": "Correo electrónico",
        "send": "Enviar",
        "back": "Regresar",
        "email-not-found": "Correo electrónico no resgistrado",
        "email-sent": "¡Revisa tu correo!"
    },
    "reset-password": {
        "error": "La solicitud para reestablecer la contraseña ha caducado o no existe",
        "title": "Para reestablecer su contraseña rellene el campo siguiente",
        "new-password": "Nueva contraseña",
        "confirm-password": "Confirme su contraseña",
        "reset": "Reestablecer",
        "go-login": "Ir a inicio de sesión"
    },
    "two-factor": {
        "call-to-action": "Al iniciar sesión, se envía un correo electrónico al usuario con el código de verificación",
        "verification-code": "Código de verificación"
    }
};