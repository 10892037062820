import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import getSymbolFromCurrency from "currency-symbol-map";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import usePrevious from "../../../hooks/use-previous.hook.js";
import useTranslate from "../../../hooks/use-translate.hook.js";
import { createSsnValidation, createEinValidation } from "../../../core/validator.utils";
import { supportedLanguages } from "../../../core/core.utils";
import { DialogInfo, Dropdown, FlagIcon, Input, MaskInput, PhoneInput, cssUtils, flex } from "../../../ui";
import { BrandsSelector, BrandTypes } from "../../../ui/brand";
import { SUBSCRIPTION_MAIN_FORM } from "./main.constants";
import * as actions from "./main.actions";
import { errorSubscriptionAddress } from "./main.selectors.js";
import { selectors as subscriptionSelectors } from "../../../subscription";
import SubscriptionAddress from "./subscription-address.component";
import css from "./main.scss";
import { isPhoneValid } from "../../../core/validator.utils";
import SubscriptionSampleDeleteComponent from "./subscription-sample-delete.component";

const segments = [
    "AUTOMOTIVE",
    "EDUCATION",
    "AESTHETIC",
    "REALSTATE",
    "HEALTH",
    "INSURANCE",
    "NAUTICAL",
    "TELECOM",
    "RETAIL",
    "OTHER"
];

const SubscriptionMain = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const isEinValid = createEinValidation({ t });
    const isSsnValid = createSsnValidation({ t });

    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const subscriptionAddress = useSelector( errorSubscriptionAddress );

    const addressHasError = !isEmpty( subscriptionAddress );

    const [ personType, setPersonType ] = useState( subscription.ein ? "LEGAL" : "NATURAL" );
    const [ segmentWarning, setSegmentWarning ] = useState( "" );

    const formik = useForm({
        form: SUBSCRIPTION_MAIN_FORM,
        enableReinitialize: true,
        initialValues: {
            ssn: null,
            ein: null,
            address: {},
            ...subscription,
            segment: {
                options: {},
                ...subscription.segment,
            },
        },
        onSubmit: values => dispatch( actions.patchSubscription( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name || !values.name.length ) {
                errors.name = "common:validation.required";
            }

            if ( values.ssn && !isSsnValid( values.ssn ) ) {
                errors.ssn = "common:validation.ssn";
            }

            if ( values.ein && !isEinValid( values.ein ) ) {
                errors.ein = "common:validation.ein";
            }

            if ( !values.phone ) {
                errors.phone = "common:validation.required";
            } else if ( !isPhoneValid( values.phone ) ) {
                errors.phone = "common:validation.phone-number";
            }

            if ( !values.segment || !values.segment.type ) {
                errors.segment = {
                    type: "common:validation.selection"
                };
            } else if ( values.segment.type === "AUTOMOTIVE" ) {
                if ( isEmpty( get( values.segment, "options.brandTypes" ) ) ) {
                    errors.segment = {
                        options: {
                            brandTypes: "common:validation.select-option"
                        }
                    };
                } else if ( isEmpty( get( values.segment, "options.brands" ) ) ) {
                    errors.segment = {
                        options: {
                            brandTypes: "config:subscription.select-brands"
                        }
                    };
                }
            }

            if ( values.address?.zipCode ) {
                if ( addressHasError ) {
                    errors.address = {
                        zipCode: "config:subscription.address.invalid",
                    };
                }
            }
            return errors;
        }
    });

    const cancelChangeSegment = () => {
        formik.setFieldValue( "segment", subscription.segment );
    };
    const closeSegmentWarning = () => setSegmentWarning( "" );

    const selectedBrands = formik.values.segment.options?.brands || [];

    const toggleBrand = ( brand, checked ) => {
        if ( checked ) {
            formik.setFieldValue( "segment.options.brands", selectedBrands.concat( brand ) );
        } else {
            formik.setFieldValue( "segment.options.brands", selectedBrands.filter( item => brand !== item ) );
        }
    };

    const prevSegmentType = usePrevious( formik.values.segment.type );

    useEffect( () => {
        if ( prevSegmentType === "AUTOMOTIVE" &&
            prevSegmentType !== formik.values.segment.type &&
            formik.values.segment.type !== "AUTOMOTIVE"
        ) {
            setSegmentWarning( "config:subscription.segment.change.previous-automotive" );
        } else if ( formik.values.segment.type !== prevSegmentType ) {
            setSegmentWarning( "" );
        }
    }, [ prevSegmentType, formik.values.segment.type ] );

    return (
        <div className={ css.containerContent }>
            <Card>
                <Form formik={ formik } noValidate>
                    <CardHeader
                        subheader={ t( "config:subscription.call-to-action" ) }
                    />
                    <CardContent>
                        <Field
                            type="text"
                            name="name"
                            label={ t( "config:subscription.name" ) }
                            component={ Input }
                            required
                        />
                        <div className={ flex.container }>
                            <Dropdown
                                label={ t( "config:subscription.person-type.label" ) }
                                source={[
                                    {
                                        label: t( "config:subscription.person-type.natural" ),
                                        value: "NATURAL"
                                    }, {
                                        label: t( "config:subscription.person-type.legal" ),
                                        value: "LEGAL"
                                    }
                                ]}
                                value={ personType }
                                fullWidth={ false }
                                className={ flex.item30 }
                                onChange={ event => setPersonType( event.target.value ) }
                            />
                            {
                                personType === "NATURAL" ?
                                    <Field
                                        type="text"
                                        name="ssn"
                                        label={ t( "config:subscription.ssn" ) }
                                        component={ MaskInput }
                                        className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                        format="###.###.###-##"
                                    /> :
                                    <Field
                                        type="text"
                                        name="ein"
                                        label={ t( "config:subscription.ein" ) }
                                        component={ MaskInput }
                                        className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                        format="##.###.###/####-##"
                                    />
                            }
                        </div>
                        <Field
                            label={ t( "config:subscription.phone" ) }
                            name="phone"
                            component={ PhoneInput }
                            fullWidth
                            required
                        />
                        <SubscriptionAddress setFieldValue={ formik.setFieldValue } values={ formik.values } />
                        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                name="currency"
                                label={ t( "config:subscription.currency" ) }
                                source={
                                    [ "AOA", "ARS", "BRL", "CLP", "MXN", "PYG", "USD", "UYU" ].map( symbol => ({
                                        value: symbol,
                                        label: `${symbol} (${getSymbolFromCurrency( symbol )})`
                                    }))
                                }
                                className={ flex.fill }
                                component={ Dropdown }
                            />
                            <Field
                                name="locale"
                                label={ t( "common:language" ) }
                                renderValue={ option => (
                                    <>
                                        { option &&
                                            <FlagIcon
                                                code={ option.value.split( "-" )[ 1 ].toLowerCase() }
                                                className={ cssUtils.marginRightSmall }
                                            />
                                        }
                                        { option ? option.label : "" }
                                    </>
                                ) }
                                classes={{ select: flex.container }}
                                source={ supportedLanguages.map( locale => ({
                                    icon: (
                                        <FlagIcon
                                            code={ locale.split( "-" )[ 1 ].toLowerCase() }
                                            className={ cssUtils.marginRightSmall }
                                        />
                                    ),
                                    value: locale,
                                    label: t( `common:locales.${locale}` )
                                })) }
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                component={ Dropdown }
                            />
                        </div>
                        <Field
                            name="segment.type"
                            label={ t( "config:subscription.segment.label" ) }
                            component={ Dropdown }
                            sort={ false }
                            source={
                                segments
                                    .map( value => (
                                        { value, label: t( `config:subscription.segment.type.${value}` )}
                                    ))
                            }
                            required />

                        <DialogInfo
                            open={ !!segmentWarning }
                            message={ segmentWarning && t( segmentWarning ) }
                            onClose={ closeSegmentWarning }
                            onCancel={ cancelChangeSegment }
                        />

                        {
                            formik.values.segment.type === "AUTOMOTIVE" &&
                                <Field
                                    name="segment.options.brandTypes"
                                    component={ BrandTypes }
                                />
                        }

                        {
                            formik.values.segment.type === "AUTOMOTIVE" &&
                                <Accordion className={ css.brandsPanel }>
                                    <AccordionSummary
                                        className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRigth0 ) }
                                        expandIcon={ <ExpandMoreIcon/> }>
                                        <div className={ flex.fill }>
                                            <Typography>
                                                { t( "config:subscription.segment.brands.label" ) }
                                            </Typography>
                                        </div>
                                        <div className={ flex.fill }>
                                            <Typography>
                                                {
                                                    selectedBrands.length +
                                                    t( "config:subscription.segment.brands.selected" )
                                                }
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BrandsSelector
                                            onChange={ toggleBrand }
                                            selectedBrands={ selectedBrands }
                                            brandTypes={ formik.values.segment.options.brandTypes }
                                        />
                                    </AccordionDetails>
                                </Accordion>
                        }

                        <hr/>
                        <SubscriptionSampleDeleteComponent/>
                    </CardContent>
                    <CardActions className={ flex.justifyContentEnd }>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={ formik.submitting }
                        >
                            { t( "config:subscription.save" ) }
                        </Button>
                        {
                            formik.error &&
                                <Typography color="error">
                                    { formik.error.error }
                                </Typography>
                        }
                    </CardActions>
                </Form>
            </Card>
        </div>
    );
};

export default SubscriptionMain;