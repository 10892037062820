import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import {
    NEW_FILE_MODEL_FORM,
    TEMPLATE_FORM,
    TEMPLATE_TEST_FORM,
    DELETE_TEMPLATE_FORM,
    NEW_IMAGE_FORM,
    DELETE_IMAGE_FORM
} from "./template.constants";
import { createFileModelForm, createFileModelSectionForm } from "./template.utils";
import { actions as fileActions } from "../../../file";
import { actions as templateActions } from "../../../template";
import * as actions from "./template.actions";
import * as selectors from "./template.selectors";

export function *saveTemplate ({ payload }) {
    yield put( startSubmit( TEMPLATE_FORM ) );

    try {
        if ( payload.id ) {
            yield call( apiv1.patch, "/templates/" + payload.id, payload );
        } else {
            yield call( apiv1.post, `/templates`, payload );
        }

        yield put( templateActions.fetchTemplates() );
        yield put( stopSubmit( TEMPLATE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( TEMPLATE_FORM, e.response.data ) );
    }
}

export function *deleteTemplate ({ meta: { id } }) {
    yield put( startSubmit( DELETE_TEMPLATE_FORM ) );

    try {
        yield call( apiv1.delete, `/templates/${id}` );
        yield put( templateActions.fetchTemplates() );
        yield put( stopSubmit( DELETE_TEMPLATE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_TEMPLATE_FORM, e.response.data ) );
    }
}

export function *uploadImage ({ payload }) {
    yield put( startSubmit( NEW_IMAGE_FORM ) );

    try {
        const formData = new FormData();
        Object.keys( payload ).forEach( key => formData.append( key, payload[ key ] ) );
        yield call( apiv1.post, "/images", formData );
        yield put( templateActions.fetchImages() );
        yield put( stopSubmit( NEW_IMAGE_FORM ) );
        yield put( reset( NEW_IMAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_IMAGE_FORM, e.response.data ) );
    }
}

export function *deleteImage ({ meta: { id } }) {
    yield put( startSubmit( DELETE_IMAGE_FORM ) );

    try {
        yield call( apiv1.delete, `/images/${id}` );
        yield put( templateActions.fetchImages() );
        yield put( stopSubmit( DELETE_IMAGE_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_IMAGE_FORM, e.response.data ) );
    }
}

export function *testTemplate ({ payload }) {
    yield put( startSubmit( TEMPLATE_TEST_FORM ) );

    try {
        yield call( apiv1.put, "/templates/test", payload );
        yield put( stopSubmit( TEMPLATE_TEST_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( TEMPLATE_TEST_FORM, e.response.data ) );
    }
}

export function *createFileModel ({ payload }) {
    yield put( startSubmit( NEW_FILE_MODEL_FORM ) );

    try {
        yield call( apiv1.post, "/filemodels", payload );
        yield put( fileActions.fetchModels() );
        yield put( actions.toggleNewFileModel() );
        yield put( stopSubmit( NEW_FILE_MODEL_FORM ) );
        yield put( reset( NEW_FILE_MODEL_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_FILE_MODEL_FORM, e.response.data ) );
    }
}

export function *saveFileModel ({ payload }) {
    const form = createFileModelForm( payload );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.patch, `/filemodels/${payload.id}`, payload );
        const sections = yield select( selectors.listFileModelSections );
        const patchedSections = [];

        for ( const [ index, section ] of sections.entries() ) {
            const response = yield call( apiv1.patch, `/filemodelsections/${section.id}`, {
                ...section,
                prevFileModelSectionId: index > 0 ? patchedSections[ index - 1 ].id : null
            });

            patchedSections.push( response.data );
        }

        yield put( fileActions.receiveModelSections( payload.id, patchedSections ) );
        yield put( stopSubmit( form ) );
        yield put( actions.closeFileModel() );
        yield put( fileActions.fetchModels() );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteFileModel ({ meta: { id } }) {
    try {
        yield call( apiv1.delete, `/filemodels/${id}` );
        yield put( fileActions.fetchModels() );
        yield put( actions.initFileModels() );
    } catch ( e ) {
        // lala
    }
}

export function *createFileModelSection ({ payload }) {
    const form = createFileModelSectionForm( payload );
    yield put( startSubmit( form ) );

    try {
        if ( payload.id ) {
            yield call( apiv1.patch, `/filemodelsections/${payload.id}`, payload );
        } else {
            yield call( apiv1.post, `/filemodels/${payload.fileModelId}/sections`, payload );
        }
        yield put( fileActions.fetchModelSections( payload.fileModelId ) );
        yield put( stopSubmit( form ) );
        yield put( reset( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteFileModelSection ({ meta: { id, fileModelId } }) {
    try {
        yield call( apiv1.delete, `/filemodelsections/${id}` );
        yield put( fileActions.fetchModelSections( fileModelId ) );
    } catch ( e ) {
        // lala
    }
}

export function *watchConfigOpportunityTemplate () {
    yield all([
        takeEvery( actions.CREATE_FILE_MODEL, createFileModel ),
        takeEvery( actions.SAVE_FILE_MODEL, saveFileModel ),
        takeEvery( actions.DELETE_FILE_MODEL, deleteFileModel ),
        takeEvery( actions.SAVE_FILE_MODEL_SECTION, createFileModelSection ),
        takeEvery( actions.DELETE_FILE_MODEL_SECTION, deleteFileModelSection ),
        takeEvery( actions.SAVE_TEMPLATE, saveTemplate ),
        takeEvery( actions.DELETE_TEMPLATE, deleteTemplate ),
        takeEvery( actions.UPLOAD_IMAGE, uploadImage ),
        takeEvery( actions.DELETE_IMAGE, deleteImage ),
        takeEvery( actions.TEST_TEMPLATE, testTemplate )
    ]);
}