export const FETCH_SUBSCRIPTION = "x/FETCH_SUBSCRIPTION";
export const fetchSubscription = () => ({
    type: FETCH_SUBSCRIPTION
});

export const RECEIVE_SUBSCRIPTION = "x/RECEIVE_SUBSCRIPTION";
export const receiveSubscription = data => ({
    type: RECEIVE_SUBSCRIPTION,
    payload: data
});

export const ERROR_SUBSCRIPTION = "x/ERROR_SUBSCRIPTION";
export const errorSubscription = error => ({
    type: ERROR_SUBSCRIPTION,
    payload: error,
    error: true
});

export const FETCH_HOLDING_FUNNELS = "x/FETCH_SUBSCRIPTION_HOLDING_FUNNELS";
export const fetchHoldingFunnels = token => ({
    type: FETCH_HOLDING_FUNNELS,
    meta: { token }
});

export const REQUEST_HOLDING_FUNNELS = "x/REQUEST_SUBSCRIPTION_HOLDING_FUNNELS";
export const requestHoldingFunnels = () => ({
    type: REQUEST_HOLDING_FUNNELS
});

export const RECEIVE_HOLDING_FUNNELS = "x/RECEIVE_SUBSCRIPTION_HOLDING_FUNNELS";
export const receiveHoldingFunnels = holdings => ({
    type: RECEIVE_HOLDING_FUNNELS,
    payload: holdings
});

export const ERROR_HOLDING_FUNNELS = "x/ERROR_SUBSCRIPTION_HOLDING_FUNNELS";
export const errorHoldingFunnels = error => ({
    type: ERROR_HOLDING_FUNNELS,
    payload: error,
    error: true
});

// ---------------------------------------------------------------------------------------------------------------------
// Sample data
// ---------------------------------------------------------------------------------------------------------------------

export const DELETE_CUSTOMER_SAMPLE = "x/DELETE_CUSTOMER_SAMPLE";
export const deleteCustomerSample = () => ({
    type: DELETE_CUSTOMER_SAMPLE,
});