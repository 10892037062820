import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import { getCountryCallingCode } from "libphonenumber-js";

import useTranslate from "../../hooks/use-translate.hook";
import { saveNewOpportunityPerson } from "../opportunity.actions";
import { validateFields, selectors as formSelectors } from "../../form";
import { SAVE_PERSON_FORM } from "../opportunity.constants";
import CustomerForm from "./customer-form.component";
import { getPersonSearchValues } from "../opportunity.selectors";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const getFieldBySystemField = ( systemField, fields ) => {
    return fields.find( field => field.systemField === systemField );
};
const getFieldByType = ( type, fields ) => {
    return fields.find( field => field.type === type );
};

const NewPerson = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "PERSON" ) );
    const person = useSelector( getPersonSearchValues );

    const initialValues = useMemo( () => {
        const fieldValues = fieldsToInitialValues( fields );

        const fieldName = getFieldBySystemField( "NAME", fields );
        const fieldDoc = getFieldBySystemField( "SSN", fields );
        const fieldEmail = getFieldByType( "EMAIL", fields );
        const fieldPhone = getFieldByType( "PHONE", fields );
        fieldValues[ fieldName.id ] = person.name;
        fieldValues[ fieldDoc.id ] = person.document;
        if ( person.emails && fieldEmail ) {
            fieldValues[ fieldEmail.id ] = [ person.emails ];
        }
        if ( person.phones && fieldPhone ) {
            const code = getCountryCallingCode( t( "common:country" ) );
            const number = code + person.phones;
            fieldValues[ fieldPhone.id ] = [{ number: number, country: t( "common:country" ) }];
        }

        return ({
            person,
            type: "PERSON",
            fields: fieldValues
        });
    }, [ t, fields, person ] );

    const formik = useForm({
        form: SAVE_PERSON_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveNewOpportunityPerson( values ) ),
        validate: values => {
            let errors = {};
            if ( !values ) {
                return errors;
            }
            errors = validateFields( values, { t, fields } );
            return errors;
        }
    });

    return <CustomerForm fields={ fields } formik={ formik } onClose={ onClose } />;
};

export default NewPerson;
