import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import qs from "qs";

import useTranslate from "../hooks/use-translate.hook";
import history from "../../history";
import { getPreferences } from "../core/core.selectors";
import { fetchTeams } from "../team/team.actions";
import { fetchUsers } from "../user/user.actions";
import { fetchForm } from "../form/form.actions";
import { FiltersFields } from "../form";
import { actions as funnelActions } from "../funnel";
import { actions as lossReasonActions } from "../loss-reason";
import { actions as productActions } from "../product";
import { Fab } from "../ui";
import * as actions from "./opportunity.actions";
import { OpportunitiesTable, OpportunitiesPhases } from "./list";

const OpportunityList = ({ className }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const queryString = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true }), [ location ] );

    const preferences = useSelector( getPreferences );

    const [ showTable, setShowTable ] = useState( localStorage.getItem( "opportunityTableView" ) === "list" );
    const [ showFilters, setShowFilters ] = useState( false );

    const isSoldStatus = useMemo( () => queryString.status === "SOLD", [ queryString.status ] );

    const toggleFilters = () => setShowFilters( !showFilters );

    const changeContent = () => {
        if ( showTable ) {
            history.push( "/opportunities?view=cards" );
        } else {
            history.push( "/opportunities?view=list" );
        }
        setShowTable( !showTable );
    };

    const handleStartNewOpportunity = () => dispatch( actions.startNewOpportunity() );

    useEffect( () => {
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
        dispatch( fetchForm( "OPPORTUNITY" ) );
        dispatch( funnelActions.fetchFunnels() );
        dispatch( lossReasonActions.fetchLossReasons() );
        dispatch( fetchTeams() );
        dispatch( fetchUsers() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( !isEmpty( get( preferences, "opportunity.products" ) ) ) {
            dispatch( productActions.fetchProducts({ ids: get( preferences, "opportunity.products" ).toString() }) );
        }
    }, [ dispatch, preferences ] );
    useEffect( () => {
        if ( queryString.view ) {
            localStorage.setItem( "opportunityTableView", queryString.view );
        }
    }, [ queryString.view ] );

    return (
        <div className={ className }>
            <Helmet title={ t( "opportunity:opportunity-list.title" ) } />
            {
                showTable ?
                    <OpportunitiesTable
                        showOpportunitiesPhases={ changeContent }
                        onOpenFilters={ toggleFilters }
                    /> :
                    <OpportunitiesPhases
                        showOpportunitiesTable={ changeContent }
                        onOpenFilters={ toggleFilters }
                    />
            }

            <FiltersFields
                show={ showFilters }
                formName="opportunity"
                onClose={ toggleFilters }
                showSoldAt={ isSoldStatus }
            />

            <Fab
                id="addButton"
                onClick={ handleStartNewOpportunity }
                position="bottomRight"
            >
                <AddIcon/>
            </Fab>
        </div>
    );
};

export default OpportunityList;