let config;

// A condição abaixo não vai acontecer em testes Node, pois a variável é definida pelo Webpack.
/* istanbul ignore if */
if ( typeof BROWSER_ENV !== "undefined" ) {
    config = appConfig;
} else {
    // Só pra não quebrar os testes. No browser isto nunca vai ser executado.
    config = require( "config" );
}

// Implementação definida a partir de https://github.com/lorenwest/node-config/wiki/Common-Usage
function get ( key ) {
    if ( key == null ) {
        throw new Error( "key must not be null or undefined" );
    }

    return key.split( "." ).reduce( ( path, next ) => {
        if ( next in path ) {
            return path[ next ];
        }

        throw new Error( `config ${key} does not exist` );
    }, config);
}

function has ( key ) {
    try {
        return get( key ) !== undefined;
    } catch ( e ) {
        return false;
    }
}

module.exports = { get, has };