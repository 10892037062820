import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDateFormat } from "../hooks/use-date-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { formatBytes } from "../core/formatter.utils";
import { DialogConfirmForm, cssUtils } from "../ui";
import { deleteImage } from "./user.actions";
import { createDeleteImagemForm } from "./user.utils";

const DeleteImage = ({ image }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );

    const [ openConfirm, setOpenConfirm ] = useState( false );
    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const formik = useForm({
        form: createDeleteImagemForm( image.id ),
        initialValues: {
            id: image.id,
        },
        onSubmit: values => dispatch( deleteImage( values ) ),
    });

    return (
        <>
            <ImageListItemBar
                title={ `${image.name} (${formatBytes( image.size )})` }
                subtitle={ dateFormat( image.createdAt ) }
                actionIcon={
                    <Tooltip title={ t( "common:exclude" ) }>
                        <IconButton
                            className={ cssUtils.textWhite }
                            onClick={ toggleConfirm }
                            size="large">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                }
            />

            <DialogConfirmForm
                open={ openConfirm }
                handleSubmit={ formik.handleSubmit }
                message={ t( "user:images.confirm-delete" ) }
                onClose={ toggleConfirm }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
            />
        </>
    );
};

export default DeleteImage;