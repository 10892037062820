export default {
    "date-picker": {
        "clear": "Limpio",
        "invalid-date": "Fecha no álida"
    },
    "table": {
        "download-csv": "Descargar CSV",
        "download-xls": "Descargar XLS",
        "row": {
            "required": "Obligatorio"
        },
        "delete-dialog": {
            "title": "Está seguro de que desea eliminar este(s) elemento(s)?"
        },
        "select-all-tooltip": "Comprobar/Borrar todo",
        "select-all-dialog-text": "Qué tipo de selección desea realizar?",
        "select-all-dialog-type-page": "Toda esta página",
        "select-all-dialog-type-all": "Todos los registros del filtro actual",
        "select-all-alert-title": "Aviso",
        "select-all-alert-content": "Ha elegido seleccionar todos los registros de las páginas. Con esta opción" +
            " no puede borrar elementos individualmente. Desmarque la opción \"Comprobar/desmarcar todo.\"",
        "add": "Agregar",
        "of": " de ",
        "itens-per-page": "Elementos por página"
    },
    "input-file": {
        "label": "Seleccionar archivo",
        "no-file": "No hay archivos seleccionados",
        "file-error": "Extención o formato de archivo no válido"
    },
    "calendar": {
        "culture": "en-MEX",
        "date": "Fecha",
        "time": "Horario",
        "event": "Evento",
        "all-day": "Todo el día",
        "week": "Semana",
        "day": "Día",
        "month": "Mes",
        "previous": "Regresa",
        "next": "Próximo",
        "yesterday": "Ayer",
        "tomorrow": "Mañana",
        "today": "Hoy",
        "show-more": "Más { total }"
    },
    "brands": {
        "type": {
            "CAR": "Coche",
            "MOTORCYCLE": "Motocicleta",
            "TRUCK": "Camión"
        }
    },
    "social": {
        "google-auth": "Iniciar sesión con Google",
    },
    "template": {
        "body": "Cuerpo",
        "sms-size": "Caracteres: { characters }. SMS(s) para ser enviado: { quantity }",
        "add-image": "Agregar imagen",
        "variables": {
            "title": "Variables",
            "help": "Utilice estas variables para sustituir su valor respectivo al preparar el mensaje para su uso.",
        },
        "customer-name": {
            "label": "Nombre del cliente",
            "help":
                "La palabra se añadirá al cuerpo \"%%CUSTOMERNAME%%\". " +
                "Esta palabra será sustituída por el nombre del cliente al preparar el mensaje para su envío\n"
        },
        "price": {
            "label": "Precio",
            "help":
                "Se añadirá en el cuerpo la palabra \"%%PRICE%%\"." +
                "Esta palabra será sustituída por el nombre del cliente al preparar el mensaje para su envío\n"
        },
        "brand-model": {
            "label": "Marca/Modelo",
            "help":
                "Se añadirá en el cuerpo la palabra \"%%BRANDMODEL%%\"." +
                "Esta palabra será sustituída por el nombre del cliente al preparar el mensaje para su envío\n"
        },
        "general-gallery": "Galería general",
        "my-gallery": "Mi galería",
        "empty-images": "No hay imágenes registradas",
        "select": "Seleccionar plantilla",
        "system": "Plantillas generales",
        "shared": "Plantillas compartidas",
        "my": "Mis plantillas",
        "end-of-page": "Fin de la 1ra página"
    },
    "users-filter": {
        "team": "Equipo",
        "empty-teams": "No hay equipos configurados",
        "users": "Usuarios",
        "active": "Activo",
        "inactive": "Inactivo",
    },
    "autocomplete": {
        "loading": "Cargando",
        "empty": "No se encontraron resultados...",
        "warning": "Mínimo de 3 caracteres para realizar la búsqueda",
        "type-and-enter": "Introduzca un valor y presione Intro"
    },
    "phases-filter": {
        "phase": "Fase"
    },
    "input": {
        "length": "Caracteres: { size }/{ total }"
    }
};