export default {
    "user-verification": {
        "description":
            "Verifique seu endereço de e-mail. Para confirmar o seu endereço de e-mail, " +
            "clique no link que nós enviamos para você.",
        "resend": "Reenviar e-mail",
        "resend-success": "E-mail enviado com sucesso!"
    },
    "top-navbar": {
        "items": {
            "logout": "Sair",
            "open-chat": "Suporte via chat",
            "open-docs": "Documentação",
            "profile": "Meus dados",
            "search": "Pesquisa",
            "notifications": "Notificações"
        },
        "search": "Pesquisar...",
        "expire-at": "Seu período de utilização expira dia { date }",
        "hire": "Contratar",
    },
    "select-holding": {
        "title": "Holding - alterar inscrição",
        "logout": "Voltar para inscrição principal",
        "call-to-action": "Selecione qual inscrição você deseja acessar.",
        "subscription": "Inscrição",
        "empty-tokens": "Não há inscrições cadastradas."
    },
    "notifications": {
        "empty": "Não há notificações",
        "title": "Notificações",
        "read-all": {
            "button": "Marcar todas como lidas",
            "confirmation-message": "Você tem certeza que deseja marcar todas as notificações como lidas?"
        },
        "types": {
            "CUSTOMER_CHANGE": "{ user } alterou o cliente { customer }",
            "CUSTOMER_OPPORTUNITY_CREATION": "A oportunidade { opportunity } foi criada para { customer }",
            "CUSTOMER_OPPORTUNITY_CREATION_USER": "{ user } criou a oportunidade { opportunity } para { customer }",
            "CUSTOMER_OPPORTUNITY_STATUS":
                "{ user } alterou o status da oportunidade { opportunity } de { customer }",
            "CUSTOMER_OPPORTUNITY_CHANGE": "{ user } alterou a oportunidade { opportunity } de { customer }",
            "CUSTOMERS_UPDATED": "A atualização de clientes foi concluída",
            "EMAIL": "Você recebeu um e-mail de { customer }",
            "EMAIL_READ": "{ customer } leu o e-mail que você enviou",
            "EXPORTATION_OPPORTUNITY_CREATED":
                "A exportação de oportunidades foi concluída. Clique aqui para fazer o download",
            "EXPORTATION_COMPANY_CREATED": "A exportação de empresas foi concluída. Clique aqui para fazer o download",
            "EXPORTATION_PERSON_CREATED": "A exportação de pessoas foi concluída. Clique aqui para fazer o download",
            "FACEBOOK_PAGE_EXPIRED":
                "A integração de Facebook ADS com a página { page } expirou. Para continuar com a " +
                "integração é necessário ativá-la novamente",
            "FACEBOOK_PAGE_EXPIRING": "A integração de Facebook ADS com a página { page } irá expirar em 10 dias",
            "IMPORTATION_FINISHED": "A importação { name } foi finalizada",
            "MAILTOP_OPPORTUNITY_COMMENT":
                "A oportunidade { opportunity } recebeu um comentário de um processo de automação do Mailtop",
            "OPPORTUNITY_COMMENT": "{ user } comentou na oportunidade { opportunity }",
            "OPPORTUNITY_COMMENT_MENTION": "{ user } mencionou você em um comentário na oportunidade { opportunity }",
            "OPPORTUNITY_CREATION": "Você recebeu uma oportunidade chamada { opportunity }",
            "OPPORTUNITY_CHANGE_USER": "A oportunidade { opportunity } foi transferida para o usuário { user }",
            "SCHEDULING": "Você tem um agendamento { title } para { date } às { hour }",
            "SCHEDULING_ALL_DAY": "Você tem um agendamento { title } para { date }",
            "SCHEDULING_CREATION": "{ user } criou um agendamento { title } para você dia { date } às { hour }",
            "SCHEDULING_REMOVE_USER": "{ user } retirou o agendamento { title } de você",
            "SCHEDULING_CHANGE_USER": "O agendamento { title } foi transferido para o vendedor { user }",
            "SCHEDULING_ACCOMPLISHED":
                "{ user } marcou como realizado o agendamento { title } para  { date } às { hour }",
            "SCHEDULING_NOT_ACCOMPLISHED":
                "{ user } marcou como não realizado o agendamento { title } para { date } às { hour }",
            "SCHEDULING_MENTION": "{ user } mencionou você no agendamento { title } na oportunidade { opportunity }",
            "SMS_RECEIVED": "Você recebeu um SMS de { customer }",
            "OPPORTUNITIES_GENERATED": "A geração de oportunidades foi concluída",
            "OPPORTUNITIES_UPDATED": "A atualização de oportunidades foi concluída",
            "PORTAL_EXPIRED": "O acesso à integração { portal } expirou",
        }
    },
    "modules": {
        "welcome": "Bem-vindo",
        "home": "Para hoje",
        "schedule": "Agenda",
        "dashboard": "Dashboard",
        "customers": "Clientes",
        "opportunities": "Oportunidades",
        "importations": "Importações",
        "reports": "Relatórios",
        "leads": "Captação Web",
        "profile": "Meus dados",
        "marketplace": "Marketplace",
        "config": "Configurações",
    },
    "blocked-out-of-hours": "O acesso ao sistema está bloqueado fora do horário de expediente",
    "page-not-found": "Página não encontrada",
    "mobile-instructions": "Para utilizar o LeadStation com este dispositivo, é necessário instalar o aplicativo",
    "google-play": {
        "link": "https://play.google.com/store/apps/details?id=br.com.syonet.leadstation&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
        "img-src": "https://play.google.com/intl/pt-BR/badges/images/generic/pt_badge_web_generic.png"
    },
    "app-store": {
        "link": "https://itunes.apple.com/br/app/leadstation/id1447887292?mt=8",
        "img-src": "https://linkmaker.itunes.apple.com/pt-br/badge-lrg.svg?releaseDate=2019-03-06&kind=iossoftware&bubble=ios_apps"
    },
    "leadstation-powered-by": {
        "icarros": " iCarros powered by Syonet",
    }
};