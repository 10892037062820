import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import classnames from "classnames";

import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { cssUtils, flex } from "../ui";
import ChangeOpenSchedulesStatus from "../opportunity/list/change-open-schedules-status.component";
import { actions as lossReasonActions } from "../loss-reason";
import css from "./opportunity.scss";
import OpportunityStatusDialog from "./opportunity-status-dialog.component";

const OpportunityStatus = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );

    const [ newStatus, setNewStatus ] = useState( null );
    const [ showOpenSchedules, setShowOpenSchedules ] = useState( false );

    const openNextSchedules = () => {
        setNewStatus( null );

        if ( opportunity.nextScheduling ) {
            setShowOpenSchedules( true );
        }
    };
    const closeOpenSchedules = () => setShowOpenSchedules( false );

    const openStatusChange = newStatus => () => setNewStatus( newStatus );
    const closeStatusChange = () => setNewStatus( null );

    useEffect( () => {
        dispatch( lossReasonActions.fetchLossReasons() );
    }, [ dispatch ] );

    const renderComponent = () => {
        switch ( opportunity.status ) {
            case "SOLD":
                return (
                    <div className={ classnames( flex.container, flex.column ) }>
                        <div>
                            <Chip
                                avatar={
                                    <Avatar className={ classnames( css.chipAvatar, css.sold ) }><ThumbUpIcon/></Avatar>
                                }
                                label={ t( "opportunity:head.sold" ) }
                                deleteIcon={
                                    <Tooltip title={ t( "opportunity:reopen" ) }>
                                        <ReplayIcon />
                                    </Tooltip>
                                }
                                onDelete={ openStatusChange( "OPEN" ) }
                            />
                        </div>
                        <Typography variant="caption">
                            { dateTimeFormat( opportunity.soldAt ) }
                        </Typography>
                    </div>
                );
            case "LOST":
                return (
                    opportunity.lossReasonComplement ?
                        <Tooltip title={ opportunity.lossReasonComplement }>
                            <div className={ classnames( flex.container, flex.column ) }>
                                <div>
                                    <Chip
                                        avatar={
                                            <Avatar className={ classnames( css.chipAvatar, css.lost ) }>
                                                <ThumbDownIcon/>
                                            </Avatar>
                                        }
                                        label={ t( "opportunity:head.lost" ) }
                                        deleteIcon={
                                            <Tooltip title={ t( "opportunity:reopen" ) }>
                                                <ReplayIcon />
                                            </Tooltip>
                                        }
                                        onDelete={ openStatusChange( "OPEN" ) }
                                    />
                                </div>
                                {
                                    opportunity.lossReason &&
                                        <Typography variant="caption">
                                            { opportunity.lossReason.name }
                                        </Typography>
                                }
                                <Typography variant="caption">
                                    { dateTimeFormat( opportunity.lostAt ) }
                                </Typography>
                            </div>
                        </Tooltip> :
                        <div className={ classnames( flex.container, flex.column ) }>
                            <div>
                                <Chip
                                    avatar={
                                        <Avatar className={ classnames( css.chipAvatar, css.lost ) }>
                                            <ThumbDownIcon/>
                                        </Avatar>
                                    }
                                    label={ t( "opportunity:head.lost" ) }
                                    deleteIcon={
                                        <Tooltip title={ t( "opportunity:reopen" ) }>
                                            <ReplayIcon />
                                        </Tooltip>
                                    }
                                    onDelete={ openStatusChange( "OPEN" ) }
                                />
                            </div>
                            {
                                opportunity.lossReason &&
                                    <Typography variant="caption">{ opportunity.lossReason.name }</Typography>
                            }
                            <Typography variant="caption">
                                { dateTimeFormat( opportunity.lostAt ) }
                            </Typography>
                        </div>
                );
            default:
                return [
                    <Button
                        key="sold"
                        className={ css.buttonSold }
                        variant="contained"
                        onClick={ openStatusChange( "SOLD" ) }
                    >
                        { t( "opportunity:head.sold" ) }
                    </Button>,
                    <Button
                        key="lost"
                        className={ css.buttonLost }
                        variant="contained"
                        onClick={ openStatusChange( "LOST" ) }
                    >
                        { t( "opportunity:head.lost" ) }
                    </Button>
                ];
        }
    };

    return (
        <>
            <ChangeOpenSchedulesStatus
                open={ showOpenSchedules }
                opportunityId={ opportunity?.id }
                onClose={ closeOpenSchedules }
                updateAfter
            />
            <OpportunityStatusDialog
                onClose={ closeStatusChange }
                open={ !!newStatus }
                opportunity={ opportunity }
                refreshStatus={ openNextSchedules }
                status={ newStatus }
            />

            <div
                className={
                    classnames(
                        cssUtils.textCenter,
                        cssUtils.marginTopSmall,
                        opportunity.status !== "LOST" && cssUtils.marginBottomTiny
                    )
                }
            >
                { renderComponent() }
            </div>
        </>
    );
};

export default OpportunityStatus;