import { Fragment, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigationIcon from "@mui/icons-material/Navigation";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

import useTranslate from "../hooks/use-translate.hook";
import { fieldIcons } from "./";
import css from "./fields.scss";

const FieldsSummaryItem = ({ format, alignPopupLeft, field, model, toggleCopied }) => {
    const t = useTranslate();

    const [ addressToOpen, setAddressToOpen ] = useState( "" );
    const [ buttonMenuAddress, setButtonMenuAddress ] = useState();

    const openMenuAddress = ( event, address ) => {
        setButtonMenuAddress( event.currentTarget );
        setAddressToOpen( address );
    };
    const closeMenuAddress = () => setButtonMenuAddress( null );

    const openGoogleMaps = () => {
        window.open( `https://maps.google.com/?q=${addressToOpen}` );
        setButtonMenuAddress( null );
    };

    const copyToTransfer = text => async () => {
        await navigator.clipboard.writeText( text );
        toggleCopied();
    };

    const Icon = fieldIcons[ field.type ];

    if ( Array.isArray( model.fields[ field.id ] ) ) {
        return model.fields[ field.id ].map( ( value, index ) => {
            let name;
            switch ( field.type ) {
                case "ADDRESS":
                    name = field.name + " - " + t( "form:address.types." + value.type );
                    break;
                default:
                    name = field.name;
                    break;
            }

            return (
                field.type === "ADDRESS" ?
                    <Fragment key={ index }>
                        <Menu
                            open={ !!buttonMenuAddress }
                            anchorEl={ buttonMenuAddress }
                            anchorOrigin={{
                                horizontal: "center",
                                vertical: "center"
                            }}
                            transformOrigin={{
                                horizontal: alignPopupLeft ? "right" : "left",
                                vertical: "top"
                            }}
                            onClose={ closeMenuAddress }
                        >
                            <MenuItem onClick={ openGoogleMaps }>
                                <ListItemIcon><NavigationIcon/></ListItemIcon>
                                <ListItemText primary={ t( "form:address.open-map" ) }/>
                            </MenuItem>
                        </Menu>
                        <ListItemButton
                            onClick={ event => openMenuAddress( event, value.name ) }
                        >
                            <ListItemIcon>{ index === 0 ? <Icon/> : <SvgIcon/> }</ListItemIcon>
                            <ListItemText
                                primary={ format( field, value ) }
                                secondary={ name }/>
                        </ListItemButton>
                    </Fragment> :
                    field.type === "EMAIL" ?
                        <Tooltip key={ index } title={ t( "common:click-here-to-copy" ) }>
                            <ListItemButton>
                                <ListItemIcon>{ index === 0 ? <Icon/> : <SvgIcon/> }</ListItemIcon>
                                <ListItemText
                                    onClick={ copyToTransfer( format( field, value ) ) }
                                    className={ css.summaryEmail }
                                    primary={ format( field, value ) }
                                    secondary={ name }
                                />
                            </ListItemButton>
                        </Tooltip> :
                        <ListItem key={ index }>
                            <ListItemIcon>{ index === 0 ? <Icon/> : <SvgIcon/> }</ListItemIcon>
                            <ListItemText
                                primary={ format( field, value ) }
                                secondary={ name }/>
                        </ListItem>
            );
        });
    }

    if ( field.systemField === "USER" ) {
        const caption = format( field, model.fields[ field.id ] );

        if ( model.lastSchedulingUser ) {
            return [
                <ListItem key={ field.id }>
                    <ListItemIcon><Icon/></ListItemIcon>
                    <ListItemText
                        primary={ caption }
                        secondary={ field.name }
                    />
                </ListItem>,
                <ListItem key={ "userScheduling" }>
                    <ListItemIcon><SvgIcon/></ListItemIcon>
                    <ListItemText
                        primary={ model.lastSchedulingUser.name }
                        secondary={ t( "opportunity:head.last-scheduling-user" ) }
                    />
                </ListItem>
            ];
        }
        return (
            <ListItem key={ field.id }>
                <ListItemIcon><Icon/></ListItemIcon>
                <ListItemText
                    primary={ caption }
                    secondary={ field.name }
                />
            </ListItem>
        );
    }

    return (
        <ListItem>
            <ListItemIcon><Icon/></ListItemIcon>
            <ListItemText
                primary={ format( field, model.fields[ field.id ] ) }
                secondary={ field.name }
            />
        </ListItem>
    );
};

export default FieldsSummaryItem;