import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";

import useTranslate from "../hooks/use-translate.hook";
import { Dropdown, Input } from "../ui";
import { createFileFromModel, fetchModels } from "./file.actions";
import { NEW_FILE_FROM_MODEL_FORM } from "./file.constants";
import { getModels } from "./file.selectors";

const NewFileFromModel = ({ companyId, personId, opportunityId, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const models = useSelector( getModels );

    const formik = useForm({
        form: NEW_FILE_FROM_MODEL_FORM,
        initialValues: {
            companyId,
            personId,
            opportunityId,
            fileModelId: null,
            name: "",
        },
        onSubmit: values => dispatch( createFileFromModel( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.fileModelId ) {
                errors.fileModelId = "common:validation.selection";
            }
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            return errors;
        }
    });

    const { setFieldValue } = formik;

    useEffect( () => {
        dispatch( fetchModels() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( formik.values.fileModelId ) {
            const model = models.find( model => model.id === formik.values.fileModelId );
            setFieldValue( "name", model.name );
        }
    }, [ formik.values.fileModelId, models, setFieldValue ] );

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "customer:files.new-document" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="fileModelId"
                        label={ t( "customer:files.model" ) }
                        source={ models?.map( model => ({ value: model.id, label: model.name })) }
                        component={ Dropdown }
                        required
                    />
                    <Field
                        name="name"
                        label={ t( "customer:files.full-name" ) }
                        component={ Input }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    .pdf
                                </InputAdornment>
                            )
                        }}
                        required
                    />
                    {
                        formik.error &&
                            <DialogContentText color="error">
                                { formik.error.error }
                            </DialogContentText>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewFileFromModel;