import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../ui";
import { deleteProposal } from "../opportunity.actions";
import { createDeleteProposalFormName } from "../opportunity.utils";

const DeleteProposal = ({ open, onClose, proposal }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: createDeleteProposalFormName( proposal.id ),
        initialValues: proposal,
        onSubmit: values => dispatch( deleteProposal( values ) ),
    });

    return (
        <DialogConfirmForm
            open={ open }
            onClose={ onClose }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            message={ t( "opportunity:proposal.delete.confirm" ) }
            title={ t( "opportunity:proposal.delete.title" ) }
        />
    );
};

export default DeleteProposal;