import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";
import isEmpty from "lodash/isEmpty";

import { getUser as getLoggedUser } from "../core/core.selectors";

const permittedUser = ( loggedUser, user ) => {
    if ( loggedUser.admin || loggedUser.id === user.id ) {
        return true;
    }

    if ( isEmpty( loggedUser.teams ) ) {
        return false;
    }

    return !isEmpty( loggedUser.teams
        .filter( userTeam => userTeam.leader )
        .filter( userTeam => user.teams && user.teams.map( ut => ut.team.id ).includes( userTeam.team.id ) ) );
};
export const isLoadingAll = createSelector( state => state.users.all, all => all.loading );
export const listAll = createSelector( state => state.users.all, all => all.data || [] );
export const listAllActive = createSelector( listAll, users => users.filter( user => user.active ) );
export const listAllPermitted = createSelector(
    listAll,
    getLoggedUser,
    ( users, loggedUser ) => users.filter( user => permittedUser( loggedUser, user ) )
);
export const listAllPermittedActive = createSelector( listAllPermitted, users => users.filter( user => user.active ) );
export const errorAll = state => state.users.all.error;

export const isLoadingEvents = state => state.users.events.loading;
const isSameDayEvent = ( start, end ) => (
    start.getDate() === end.getDate()
    && start.getMonth() === end.getMonth()
    && start.getFullYear() === end.getFullYear()
);
export const adaptEvent = event => {
    const start = new Date( event.startDate );
    const end = new Date( event.endDate );
    const sameDay = isSameDayEvent( start, end );
    const endTime = end.getHours().toString().concat( end.getMinutes() ).concat( end.getSeconds() );
    if ( !sameDay && endTime === "000" ) {
        // Para que considere como o dia todo no calendario
        end.setHours( 23, 59, 59, 59 );
    }

    return ({
        id: event.id,
        start,
        startDate: event.startDate,
        end,
        endDate: event.endDate,
        title: event.opportunitySchedulingType ? event.description : event.title,
        description: event.description,
        status: event.status,
        color: event.opportunitySchedulingType ? event.opportunitySchedulingType.color : event.color,
        customer: event.customer,
        opportunity: event.opportunity,
        opportunitySchedulingType: event.opportunitySchedulingType,
        user: event.user,
        sameDay,
        allDay: event.allDay,
        recurrent: event.recurrent,
        notifyAt: event.notifyAt,
        social: event.social,
        notAccomplishedReason: event.notAccomplishedReason
    });
};
export const getEvents = createSelector(
    state => state.users.events,
    events => ( events.data || [] ).map( adaptEvent )
);
export const reloadEvents = state => state.users.events.reload;
export const getEventSelected = createSelector( state => state.users.events, events => events.selected );

export const getSchedules = createSelector( state => state.users.schedules.logged, logged => logged.data );
export const isLoadingSchedules = state => state.users.schedules.logged.loading;
export const getPagesSchedules = state => state.users.schedules.logged.totalPages;
export const getOthersSchedules = createSelector( state => state.users.schedules.others, others => others.data );
export const isLoadingOthersSchedules = state => state.users.schedules.others.loading;
export const getPagesOthersSchedules = state => state.users.schedules.others.totalPages;

const socialCalendarBase = state => state.users.socialCalendar;
export const getSocialCalendar = requestSelectors.getData( socialCalendarBase );
export const isLoadingSocialCalendar = requestSelectors.isLoading( socialCalendarBase );

const socialAccountCalendarsBase = state => state.users.socialAccountCalendars;
export const getSocialAccountCalendars = requestSelectors.getData( socialAccountCalendarsBase );
export const isLoadingSocialAccountCalendars = requestSelectors.isLoading( socialAccountCalendarsBase );