import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import sortBy from "lodash/sortBy";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { getUser } from "../../core/core.selectors";
import { selectors as teamSelectors } from "../../team";
import { selectors as userSelectors } from "../../user";
import { actions as phaseActions, selectors as phaseSelectors } from "../../phase";
import { selectors as lossReasonSelectors } from "../../loss-reason";
import {
    DateTimePicker,
    Dropdown,
    DropdownMultiple,
    SelectFunnel,
    Input,
    flex,
    cssUtils,
    RadioButtons
} from "../../ui";
import { transferOpportunities } from "../opportunity.actions";
import { UPDATE_OPPORTUNITIES_BATCH_FORM } from "../opportunity.constants";
import { getFunnelId, getPhaseId, getLossReasonId } from "../opportunity.selectors";
import css from "./opportunities-options.scss";
import classNames from "classnames";

const OpportunitiesBatchOptions = ({ ids, tableStatus, onClose, open, toggleTransferSuccess }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );
    const phaseId = useSelector( getPhaseId );
    const lossReasonId = useSelector( getLossReasonId );

    const formik = useForm({
        form: UPDATE_OPPORTUNITIES_BATCH_FORM,
        onSubmit: values => dispatch( transferOpportunities( values ) ),
        initialValues: {
            filters: {
                ids: ids,
                status: tableStatus,
                funnelId: funnelId,
                phaseId: phaseId,
                lossReasonId: lossReasonId
            },
            values: {
                funnelId: null,
                teamId: null,
                usersId: [],
                phaseId: null,
                status: "",
                lossReasonId: null,
                lossReasonComplement: "",
            },
            type: "UPDATE"
        },
        enableReinitialize: true,
        validate: formValues => {
            const errors = {};
            if ( formValues.type === "UPDATE" ) {
                if ( formValues.values.status === "LOST" ) {
                    if ( !formValues.values.lossReasonId ) {
                        set( errors, "values.lossReasonId", "common:validation.loss-reason" );
                    }
                    if ( !formValues.values.lostAt ) {
                        set( errors, "values.lostAt", "common:validation.required" );
                    }
                } else if ( formValues.values.status === "SOLD" ) {
                    if ( !formValues.values.soldAt ) {
                        set( errors, "values.soldAt", "common:validation.required" );
                    }
                }

                if ( !formValues.values.phaseId && formValues.values.funnelId ) {
                    errors.phaseId = "common:validation.required";
                }
            }
            return errors;
        }
    });

    const prevType = usePrevious( formik.values.type );
    const prevFunnelId = usePrevious( formik.values.values.funnelId );
    const prevTeam = usePrevious( formik.values.values.teamId );
    const prevStatus = usePrevious( formik.values.values.status );
    const prevSubmitting = usePrevious( formik.submitting );

    const teams = useSelector( teamSelectors.listAll );
    const user = useSelector( getUser );
    const users = useSelector( userSelectors.listAllPermittedActive );
    const phases = useSelector( phaseSelectors.listWithFunnel( formik.values.values.funnelId ) );
    const lossReasons = useSelector( lossReasonSelectors.getLossReasons );

    const userTeams = useMemo( () => get( user, "teams" ), [ user ] );

    const sourceTeams = useMemo( () => get( user, "admin" ) ?
        teams?.map( team => ({
            value: team.id,
            label: team.name
        })) :
        sortBy(
            userTeams.map( userTeam => ({
                value: userTeam.team.id,
                label: userTeam.team.name })
            ),
            [ "label" ])
    , [ user, teams, userTeams ] );

    const sourceUsers = useMemo( () => users.map( user => ({
        value: user.id,
        label: user.name
    })), [ users ] );

    const sourcePhases = useMemo( () => phases?.map( phase => ({
        value: phase.id,
        label: phase.name
    })), [ phases ] );

    const sourceLossReasons = useMemo( () => lossReasons?.map( lossReason => ({
        value: lossReason.id,
        label: lossReason.name
    })), [ lossReasons ] );

    useEffect( () => {
        if ( prevType !== formik.values.type ) {
            formik.setFieldValue( "values.confirmation", null );
            formik.setFieldValue( "values.funnelId", null );
            formik.setFieldValue( "values.teamId", null );
            formik.setFieldValue( "values.status", null );
        }
    }, [ prevType, formik ] );
    useEffect( () => {
        if ( prevFunnelId !== formik.values.values.funnelId ) {
            formik.setFieldValue( "values.phaseId", null );
            if ( formik.values.values.funnelId ) {
                dispatch( phaseActions.fetchFunnelPhases( formik.values.values.funnelId ) );
            }
        }
    }, [ dispatch, prevFunnelId, formik ] );
    useEffect( () => {
        if ( prevTeam !== formik.values.values.teamId ) {
            formik.setFieldValue( "values.usersId", null );
            if ( formik.values.values.teamId ) {
                const teamUsers = users
                    .filter( u => !!u.teams.find( item =>
                        item.team.id.toString() === formik.values.values.teamId.toString() )
                    )
                    .map( u => u.id );
                formik.setFieldValue( "values.usersId", teamUsers );
            }
        }
    }, [ prevTeam, formik, users ] );
    useEffect( () => {
        if ( prevStatus !== formik.values.values.status ) {
            formik.setFieldValue( "values.lossReasonId", null );
            formik.setFieldValue( "values.lossReasonComplement", null );
            if ( formik.values.values.status === "LOST" ) {
                formik.setFieldValue( "values.lostAt", new Date().toISOString() );
            } else {
                formik.setFieldValue( "values.lostAt", null );
            }
            if ( formik.values.values.status === "SOLD" ) {
                formik.setFieldValue( "values.soldAt", new Date().toISOString() );
            } else {
                formik.setFieldValue( "values.soldAt", null );
            }
        }
    }, [ prevStatus, formik ] );
    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onClose();
            toggleTransferSuccess();
        }
    }, [ prevSubmitting, formik.submitting, onClose, toggleTransferSuccess ] );

    const confirmationPhrase = t( "opportunity:update-opportunities.delete-confirmation" );
    const disabledSubmit = ( formik.values.type === "DELETE" && formik.values.confirmation !== confirmationPhrase ) ||
        ( formik.values.type === "UPDATE" &&
            !formik.values.values.funnelId &&
            !Object.keys( formik.values.values )
                .filter( key => key !== "ids")
                .filter( key => key !== "type")
                .filter( key => !isEmpty( formik.values.values[ key ] )
                ).length
        ) || formik.submitting;

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            classes={{ paper: css.drawerPaper }}
            open={ open } >

            <div className={ css.opportunitiesTransferDiv }>
                <Typography variant="subtitle1" gutterBottom>
                    { t( "opportunity:update-opportunities.title" ) }
                </Typography>

                <Typography variant="caption" color="textSecondary" gutterBottom>
                    { t( "opportunity:update-opportunities.description" ) }
                </Typography>

                <Form formik={ formik } noValidate>
                    <div className={ cssUtils.marginTop }>

                        <Field
                            label={ t( "common:mode" ) }
                            name= "type"
                            component={ RadioButtons }
                            className={ classNames( cssUtils.marginTopSmall, cssUtils.marginBottom ) }
                            fullWidth
                            options={[
                                {
                                    value: "UPDATE",
                                    label: t( "common:update" ),
                                    className: flex.fill
                                }, {
                                    value: "DELETE",
                                    label: t( "common:exclude" ),
                                    className: flex.fill
                                }
                            ]}
                        />
                    </div>
                    {
                        formik.values.type === "DELETE" &&
                            <>
                                { !user.admin &&
                                    <Typography
                                        className={ cssUtils.marginBottomSmall }
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        { t( "opportunity:update-opportunities.delete-non-admin" ) }
                                    </Typography>
                                }
                                <Typography variant="body2">
                                    { t( "opportunity:update-opportunities.delete-description" ) }
                                    { " " }
                                    <b>{ confirmationPhrase }</b>
                                </Typography>
                                <Field
                                    name="confirmation"
                                    component={ Input }
                                    label={ t( "common:confirmation" ) }
                                    fullWidth
                                    required
                                />
                            </>
                    }
                    {
                        formik.values.type === "UPDATE" &&
                            <>
                                <Typography variant="subtitle2">
                                    { t( "opportunity:update-opportunities.title-users" ) }
                                </Typography>
                                <Field
                                    name="values.teamId"
                                    label={ t( "ui:users-filter.team" ) }
                                    type={ "text" }
                                    disabled={ user.admin ? isEmpty( teams ) : isEmpty( userTeams ) }
                                    helperText={ isEmpty( teams ) && t( "ui:users-filter.empty-teams" ) }
                                    source={ sourceTeams }
                                    component={ Dropdown }
                                    emptyValue
                                />
                                <Field
                                    name="values.usersId"
                                    label={ t( "ui:users-filter.users" ) }
                                    component={ DropdownMultiple }
                                    source={ sourceUsers }
                                />
                                <Typography variant="caption" color="textSecondary">
                                    { t( "opportunity:update-opportunities.helper-users" ) }
                                </Typography>

                                <div className={ cssUtils.marginTop }>
                                    <Typography variant="subtitle2">
                                        { t( "opportunity:update-opportunities.title-funnel" ) }
                                    </Typography>
                                    <Field
                                        name="values.funnelId"
                                        component={ SelectFunnel }
                                        emptyValue
                                    />
                                    <Field
                                        name="values.phaseId"
                                        label={ t( "ui:phases-filter.phase" ) }
                                        disabled={ !formik.values.values.funnelId }
                                        source={ sourcePhases }
                                        component={ Dropdown }
                                        required={ formik.values.funnelId }
                                    />
                                </div>

                                {
                                    tableStatus === "OPEN" &&
                                        <div className={ cssUtils.marginTop }>
                                            <Typography variant="subtitle2">
                                                { t( "opportunity:update-opportunities.title-status" ) }
                                            </Typography>
                                            <Field
                                                name="values.status"
                                                label={ t( "opportunity:update-opportunities.title-status" ) }
                                                source={[
                                                    {
                                                        label: t( "opportunity:status.SOLD" ),
                                                        value: "SOLD"
                                                    }, {
                                                        label: t( "opportunity:status.LOST" ),
                                                        value: "LOST"
                                                    }
                                                ]}
                                                component={ Dropdown }
                                                emptyValue
                                            />

                                            { formik.values.values.status === "LOST" &&
                                                <div>
                                                    <Field
                                                        name="values.lostAt"
                                                        label={ t( "opportunity:lost-at" ) }
                                                        component={ DateTimePicker }
                                                        required
                                                    />
                                                    <Field
                                                        component={ Dropdown }
                                                        name="values.lossReasonId"
                                                        label={ t( "opportunity:loss-reason" ) }
                                                        source={ sourceLossReasons }
                                                        required
                                                    />
                                                    <Field
                                                        component={ Input }
                                                        name="values.lossReasonComplement"
                                                        label={ t( "opportunity:loss-reason-complement" ) }
                                                        multiline
                                                        rows="1"
                                                        rowsMax="5"
                                                    />
                                                </div>
                                            }
                                            {
                                                formik.values.values.status === "SOLD" &&
                                                    <>
                                                        <Field
                                                            name="values.soldAt"
                                                            label={ t( "opportunity:sold-at" ) }
                                                            component={ DateTimePicker }
                                                            required
                                                        />
                                                    </>
                                            }
                                        </div>
                                }
                            </>
                    }


                    <div className={ classnames( flex.container, cssUtils.marginTopSmall ) }>
                        <Button className={ flex.fill } onClick={ onClose }>
                            { t( "common:cancel" ) }
                        </Button>
                        <Button
                            disabled={ disabledSubmit }
                            className={ flex.fill }
                            type="submit"
                        >
                            { formik.values.type === "UPDATE" ? t( "common:update" ) : t( "common:exclude" ) }
                        </Button>
                    </div>

                </Form>
            </div>
        </Drawer>
    );
};

export default OpportunitiesBatchOptions;
