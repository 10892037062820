export default {
    "opportunity-list": {
        "title": "Oportunidades",
        "empty": "Ninguna oportunidad encontrada.",
        "columns": "Columnas",
        "new": "Nueva oportunidad",
        "filters": "Filtros",
        "select-columns": "Seleccionar columnas",
        "show-cards": "Ver oportunidades en tarjetas",
        "show-table": "Ver oportunidades en la lista",
        "transfer-oportunities": "Actualizar oportunidades",
        "phases-title":
            "Total de { total } oportunidades abiertas. Valor negociado de { price } y { recurrentPrice } recorrente.",
        "phases-total-opportunities": "{ total } oportunidades",
        "warning-total":
            "Sólo se muestran 500 oportunidades. Para un uso de visualización completa " +
            "enumerar o utilizar filtros para mostrar solamente las oportunidades que desee",
        "open-schedules": {
            "title": "Esta oportunidade tiene programación(es) abiertas. Ver más abajo:",
            "call-to-action": "Qué desea hacer con esta(s) programación(es)?",
            "accomplished": "Marcar como realizado",
            "not-accomplished": "Marcar como no realizado",
            "open": "Dejar abierto"
        },
        "live": "En vivo",
        "live-help":
            "Actualiza todas las fases al cambiar una oportunidad y muestra un mensaje para actualizar " +
            "cuando cambia el embudo",
        "refresh-warning": "Hay cambios en este embudo. Haga clic aquí para actualizar las oportunidades",
        "click-to-refresh": "Haga clic aquí para actualizar las oportunidades",
    },
    "update-opportunities": {
        "transfer": "Actualizar",
        "info-title": "Actualización iniciada",
        "info-content": "Recibirá una notificación cuando se complete la actualización",
        "title": "Oportunidades de actualización",
        "title-users": "Usuario",
        "title-funnel": "Fase",
        "title-status": "Estado",
        "helper-users": "Las oportunidades se distribuirán proporcionalmente",
        "description": "Seleccione las oportunidades que le gustaría actualizar",
        "loss-reason-error": "Seleccione al menos una razón para la pérdida",
        "delete-non-admin": "Solo se eliminarán las oportunidades creadas por usted",
        "delete-description": "Para confirmar tipo",
        "delete-confirmation": "eliminar oportunidades"
    },
    "exportation": {
        "title": "Exportación de oportunidades",
        "call-to-action":
            "Seleccione debajo los campos que desea exportar y el formato de archivo deseado. " +
            "Se le notificará cuando se complete la exportación y el archivo estará disponible " +
            "por 24 horas.",
        "columns": {
            "available": "Columnas disponibless",
            "selected": "Columnas seleccionadas"
        },
        "format": "Formato del archivo"
    },
    "new-opportunity": {
        "title": "Nueva oportunidad",
        "open-opportunities": "Este cliente tiene las siguientes oportunidades abiertas",
        "new-customer": "Registrar nuevo cliente",
        "customer-error": "Debe seleccionar al menos una empresa o persona.",
        "customer-search-error": "Complete al menos un campo para buscar.",
        "first-select-company": "Seleccione primero una empresa en el campo siguiente",
        "first-select-person": "Seleccione primero una persona en el campo siguiente",
        "search": "Buscar",
        "search-customer": "Buscar empresa y/o persona",
        "select-customer": "Seleção empresa y/o persona",
        "selected-customer-data": "Datos seleccionados",
        "company-vinculed": "Empresa vinculada a { person }"

    },
    "title": "Título",
    "search-customer": "Busque al cliente por nombre, correo electrónico, número de teléfono completo o RFC",
    "customer": "Cliente",
    "company": "Empresa",
    "company-data": "Datos de la empresa",
    "company-type-in": "Introduzca el nombre de la empresa",
    "empty-company": "Empresa desinformada",
    "person": "Persona",
    "person-data": "Datos de la persona",
    "person-type-in": "Introduzca el nombre de la persona",
    "empty-person": "Persona  desinformada",
    "opportunity": "Oportunidad",
    "avoid-duplications-person": "Evita la duplicidad, busca una persona",
    "avoid-duplications-company": "Evita la duplicidad, busca una empresa",
    "price": {
        "label": "Valor",
        "empty": "No definido"
    },
    "recurrent-price": "Valor recurrente (MRR)",
    "funnel": "Embudo",
    "funnels": "Embudos",
    "funnels-empty": "No hay embudos configurados",
    "funnel-product-categories": "Embudo de categorías de productos: { productCategories }",
    "change-funnel": "Embudo de cambio",
    "confirm-change-funnel": {
        "title": "Al transferir al embudo { funnel }, perderá información en el/los siguiente(s) campo(s):",
        "description": "¿Está seguro de que desea continuar con la transferencia de la oportunidad?"
    },
    "phase": "Fase",
    "closing-at": "Fecha de previsión de cierre",
    "user": "Usuario",
    "source": "Origen",
    "status": {
        "label": "Estado",
        "OPEN": "Abrir",
        "SOLD": "Ganada",
        "LOST": "Pérdida"
    },
    "next-scheduling": "Próxima cita",
    "last-interact-at": "Sem interação há",
    "date-last-interact-at": "Fecha última interacción",
    "created-at": "Creado en",
    "head": {
        "edit": "Editar",
        "sold": "Ganada",
        "lost": "Pérdida",
        "phase": "En { fase }",
        "last-scheduling-user": "Usuario de la última programación"
    },
    "reopen": "Reabrir oportunidad",
    "reopen-confirm": "¿Estás seguro de que quieres reabrir esta oportunidad?",
    "sale-lost": "Venta pérdida",
    "sale-sold": "Venta ganada",
    "loss-reason": "Razón de venta perdida",
    "loss-reason-complement": "Complemento",
    "confirm-sold": "Confirmar la venta de la oportunidad",
    "confirm-status-proposals":
        "Esta oportunidad tiene una(s) propuesta(s) abiertas. Confirmar el estado de la(s) propuesta(s).",
    "sold-at": "Ganar en",
    "lost-at": "Perdido en",
    "holding": {
        "title": "Holding",
        "send": "Enviar a otra suscripción",
        "help":
            "Seleccione a qué suscripción desea enviar la información de esta oportunidad",
        "token": "suscripción",
        "no-funnels": "Esta suscripción no tiene configuración de embudo",
        "funnel": "Configuración de embudo",
        "transfer": "Transferencia",
        "clone": "Clon",
        "success": "Oportunidad enviada correctamente!"
    },
    "floating-actions": {
        "whatsapp": "WhatsApp",
        "sms": "SMS",
        "phone-call": "Llamada teléfonica",
        "email": "Correo electrónico"
    },
    "persons": {
        "header": "Otras personas",
        "add": "Agregar persona",
        "role": "Papel en la oportunidad",
        "select-new": "Seleccione la persona a la que desea vincular a la oportunidad",
        "delete-title": "Eliminar vínculo",
        "confirm-delete": "La persona { name } se desvinculará de la oportunidad. Estás seguro?"
    },
    "companies": {
        "select-new": "Seleccione la empresa a la que desea vincular la oportunidad.",
    },
    "tabs": {
        "overview": "Visión general",
        "form": "Negociación",
        "person": "Persona",
        "company": "Empresa",
        "schedules": "Horarios",
        "proposals": "Propuestas",
        "files": "Archivos"
    },
    "confirm-delete-opportunity": "Está seguro de que desea eliminar esta oportunidad?",
    "delete-opportunity": "Eliminar oportunidad",
    "overview": {
        "schedules": "Horarios",
        "phases": "Fases",
        "temperature": "Temperatura",
        "timeline": "Línea de tiempo"
    },
    "timeline": {
        "types": {
            "OPPORTUNITY_CREATION": "Crear la oportunidad",
            "OPPORTUNITY_CHANGE": "Cambio de negociación",
            "OPPORTUNITY_CHECKIN": "Check-in",
            "OPPORTUNITY_PHASE": "Cambio de fase",
            "OPPORTUNITY_STATUS": "Cambio de estado",
            "OPPORTUNITY_TEMPERATURE": "Cambio de temperatura",
            "OPPORTUNITY_COMMENT": "Comentario",
            "OPPORTUNITY_SCHEDULING": "Horarios",
            "OPPORTUNITY_PRODUCTS": "Productos",
            "PROPOSALS": "Propuestas",
            "PERSON": "Cambio de persona",
            "COMPANY": "Cambio de empresa",
            "EMAIL": "Correo electrónico",
            "PHONE_CALL": "Llamadas realizadas",
            "SMS": "SMS enviados",
            "WHATSAPP": "WhatsApp"
        },
        "opportunity-changed": "Oportunidad cambiada",
        "opportunity-changed-user": "cambió esta oportunidad",
        "opportunity-checkin-user": "realizó un check-in en esta oportunidad",
        "opportunity-created": "Oportunidad creada a través de la integración de API",
        "opportunity-created-facebook": "Oportunidad creada a través de Facebook",
        "opportunity-created-exact-sales": "Oportunidad creada a través de Exact Sales",
        "opportunity-created-icarros": "Oportunidad creada a través de iCarros",
        "opportunity-created-leadlovers": "Oportunidad creada a través de Leadlovers",
        "opportunity-created-mercado-libre": "Oportunidad creada a través del Mercado Libre",
        "opportunity-created-mobiauto": "Oportunidad creada a través del Mobiauto",
        "opportunity-created-mkt2easy": "Oportunidad creada a través de MKT2Easy",
        "opportunity-created-rd-station": "Oportunidad creada a través de RD Station",
        "opportunity-created-olx": "Oportunidad creada a través de OLX",
        "opportunity-created-usadosbr": "Oportunidad creada a través del Usadosbr",
        "opportunity-created-webmotors": "Oportunidad creada a través de Webmotors",
        "opportunity-created-user": "creó esta oportunidad",
        "opportunity-created-lead": "Oportunidad creada a través de un lead",
        "opportunity-created-lead-user": "creó esta oportunidad a través de un lead",
        "opportunity-created-by-importation": "creó esta oportunidad a través de una importación",
        "opportunity-created-migration": "creó esta oportunidad a través de una migración",
        "opportunity-created-funnel-automation": "Oportunidad creada a través de la automatización del embudo",
        "opportunity-created-by-holding": "Oportunidad creada por el proceso de retención",
        "opportunity-phase": "La etapa de esta oportunidad ha cambiado",
        "opportunity-phase-user": "cambió la etapa de esta oportunidad",
        "opportunity-status": "ha cambiado el estado de esta oportunidad",
        "opportunity-temperature": "cambió la temperatura de esta oportunidad",
        "opportunity-comment": "Comentario creado",
        "opportunity-comment-user": "comentó sobre esta oportunidad",
        "opportunity-scheduling-creation_seller": "creó una programación para el vendedor { user }",
        "opportunity-scheduling-creation": "creó una programación",
        "opportnity-scheduling-change": "se ha cambiado la programación { type } con fecha { date }",
        "opportunity-scheduling-status": {
            "OPEN": "estableció una programación como abierta",
            "ACCOMPLISHED": "estableció una programación como realizada",
            "NOT_ACCOMPLISHED": "estableció una programación como no realizada"
        },
        "opportunity-created-by-holding-source-user": "Usuario de origen:",
        "opportunity-created-by-holding-source-subscription": "Inscripción de origen:",
        "customer": {
            "PERSON": {
                "removed": "se ha eliminado el vínculo de la persona ",
                "added": "vinculó a la persona ",
            },
            "COMPANY": {
                "removed": "se ha eliminado el vínculo de la empresa ",
                "added": "vinculó a la empresa ",
            },
            "at-opportunity": " en esta oportunidad",
        },
        "proposal-created": "creó la propuesta de nombre { name }",
        "proposal-change": "modificó la propuesta de nombre { name }",
        "proposal-products": {
            "message": "modificó los productos de la propuesta de nombre { name }",
            "previous": "Productos anteriores",
            "empty": "Sin productos"
        },
        "proposal-status": "modificó el estado de la propuesta de nombre { name }",
        "opportunity-product": "cambió los productos de esta oportunidad",
        "from-migration": "Oportinidad creada a través de la migración del { system }",
    },
    "scheduling": {
        "type": "Tipo",
        "user": "Usuario",
        "date": "Fecah",
        "time": "Horario",
        "description": "Descripción",
        "other-user": "Programar para otro usuario",
        "next-scheduling": "Desea crear una nueva programación?",
        "notify-at": "Notificar",
        "notify": {
            "at": "En el momento",
            "five-minutes": "5 minutos antes",
            "thirty-minutes": "30 minutos antes",
            "other": "Otro horario",
            "minutes": "Minutos"
        },
        "recurrent": {
            "label": "Recurrente",
            "type": {
                "label": "Reaparición",
                "DAILY": "Diariamente",
                "WEEKLY": "Semanalmente",
                "MONTHLY": "Mensual",
                "YEARLY": "Anual"
            },
            "until": "Hasta hasta",
            "update": {
                "single": "Este horario",
                "future": "Este horario y el siguiente",
                "all": "Todas las citas"
            }
        },
        "accomplished": "Realizado",
        "not-accomplished": "No realizado",
        "not-accomplished-reason": "Motivo",
        "change-not-accomplished-reason": "Motivo de la programación no realizada",
        "confirm-accomplished": "Marcar la programación como realizada?",
        "confirm-not-accomplished": "Marcar la programación como no realizada?",
        "reopen": "Reabrir",
        "reopen-scheduling": "Marcar la programación como abierta?",
        "late": "Tarde",
        "scheduled-at": "Programada para { date }",
        "accomplished-at": "Logrado en { date }"
    },
    "new-scheduling": "Nueva programación",
    "scheduling-open-type-used": "La oportunidad ya tiene una programación abierta de este tipo",
    "schedule-of": "Horario de { user }",
    "open-schedules": {
        "title": "Horarios abiertos",
        "empty": "Sin horarios abiertos",
        "follow-up": "Mejore el seguimiento de su oportunidad de aumentar su conversíon"
    },
    "schedules-empty": "No hay programaciones registradas",
    "opportunity-product": {
        "empty": "No hay productos en esta oportunidad",
        "product": "Producto",
        "measurement-unit": {
            "UNIT": "unidad",
            "METER": "metro",
            "KILO": "kilo",
            "LITER": "litro"
        },
        "price-by-unit":
            "Valor por { measurementUnit }: { price }",
        "unit-price": "Precio unitario",
        "quantity": "Cantidad",
        "discount": "Descuento",
        "recurrent": "Recurrente",
        "total": "Precio total",
        "subtotal": "Subtotal: { total }",
        "total-price": "Precio total: { total }",
        "inactive": "* Producto inactivo",
        "category": "Categoría de producto",
        "funnel-categories": "Categorías de embudo",
        "others-categories": "Otras categorías"
    },
    "opportunity-products": {
        "total": "Total: { total }",
        "empty": "Sin productos registrados",
        "register": "Para registrar productos accece",
        "register-link": " Configuraciones > Productos"
    },
    "proposals": {
        "add": "Agregar propuesta",
        "edit": "Editar propuesta",
        "duplicate": "Duplicar propuesta",
        "empty": "No hay propuestas registradas en esta oportunidad"
    },
    "proposal": {
        "created-at": "Propuesta creada en { date }",
        "change-status": {
            "OPEN": "Reabrir",
            "ACCEPTED": "Aprobar",
            "DECLINED": "Rehusar",
            "confirm": "Está seguro de que desea cambiar el estado de esta propuesta?"
        },
        "download": "Descargar la propuesta",
        "select-sections-for-download": "Seleccione las secciones de la plantilla de documento para descargar",
        "sections": "Secciones",
        "status": {
            "OPEN": "Abrir",
            "ACCEPTED": "Aprobado",
            "DECLINED": "Fracasado"
        },
        "delete": {
            "title": "Eliminar propuesta",
            "confirm": "Está seguro de que desea eliminar esta propuesta?"
        },
        "products": {
            "label": "Productos",
            "empty": "No hay productos en esta propuesta",
            "total": "Precio total: { total } y { recurrent } recurrente",
            "add": "Agregar producto"
        }
    },
    "proposal-product": {
        "product": "Producto",
        "measurement-unit": {
            "UNIT": "unidad",
            "HOUR": "hora",
            "METER": "metro",
            "KILO": "kilo",
            "LITER": "litro",
        },
        "price-by-unit":
            "Valor por { measurementUnit }: { price }",
        "unit-price": "Precio unitario",
        "quantity": "Cantidad",
        "discount": "Descuento",
        "of-discount": "{ discount } de descuento",
        "recurrent": "Recurrente",
        "total": "Precio total",
        "subtotal": "Subtotal: { total }",
        "total-price": "Precio total: { total }",
        "inactive": "* Producto inactivo",
        "linked-products": "Productos vinculados a las propuestas",
    },
    "phases": {
        "dialog": {
            "title": "Fase de cambio",
            "change-opportunity-closed": "Está seguro de que desea cambiar esta fase? Se reabrirá la oportunidad"
        },
        "open-opportunity": "Oportunidad de acceso",
        "opportunity-open-scheduling": {
            "empty": "Sin programación",
            "late": "Programación tardía",
            "scheduled": "Con programación"
        },
        "late": "Se ha superado el límite de { days } día(s) de permanencia en la fase { phase }",
        "triggers": "Disparadores",
        "send": "Enviar",
        "execute-triggers": "Seleccione las plantillas que desea enviar",
        "template": {
            "description": "Plantilla: { name }. { type }",
            "type": {
                "EMAIL": "Envío por correo electrónico",
                "SMS": "Envío por SMS"
            },
            "send-customer": " para el cliente",
            "send-users": " para usuarios { users }"
        }
    },
    "temperatures": {
        "COLD": "Frío",
        "WARM": "Tibio",
        "HOT": "Caliente"
    },
    "new-comment": {
        "title": "Nuevo comentario",
        "comment": "Comentario"
    },
    "mercado-libre": {
        "respond": "Responder la pregunta"
    },
    "lead": {
        "open": "Ir para el lead",
        "open-body": "Abrir el cuerpo del correo electrónico del cliente potencial"
    },
    "not-found": "Oportunidad no encontrada",
    "export-file": "Exportar archivo"
};