import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./welcome.actions";
import { INITIAL_DATA_FORM } from "./welcome.constants";

export function *watchWelcome () {
    yield all([
        takeEvery( actions.SET_INITIAL_DATA, setInitialData )
    ]);
}

function *setInitialData ({ payload }) {
    yield put( startSubmit( INITIAL_DATA_FORM ) );
    try {
        if ( payload.createMocks ) {
            yield call( apiv1.post, "/samples", {} );
        } else {
            // simula um delay no submit para que haja tempo para alteraçao do state do submit
            const delay = time => new Promise( resolve => setTimeout( resolve, time ) );
            yield call( delay, 200 );
        }
        yield put( stopSubmit( INITIAL_DATA_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( INITIAL_DATA_FORM, e ) );
    }
}