import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { getSources } from "../source/source.selectors";
import { Chart, cssUtils } from "../ui";
import css from "./dashboard.scss";

const OpportunitiesBySource = ({
    className,
    statistics: { sources: opportunitiesBySource },
    filterSources,
    initialDate,
    finalDate,
    onClickItem
}) => {
    const t = useTranslate();

    const sources = useSelector( getSources );

    const [ showHelp, setShowHelp ] = useState( false );
    const toggleHelp = () => setShowHelp( !showHelp );

    const selectedSources = useMemo( () => {
        return filterSources.length > 0 ? filterSources : sources?.map( source => source.id );
    }, [ filterSources, sources ] );

    const translateStatus = useCallback( status => {
        if ( !status ) {
            return t( "dashboard:opportunities-by-source.created" );
        }
        return status === "SOLD" ?
            t( "dashboard:opportunities-by-source.sold" ) :
            t( "dashboard:opportunities-by-source.lost" );
    }, [ t ] );

    const series = useMemo( () => {
        return [
            {
                name: translateStatus(),
                data: selectedSources.map( selectedSource => {
                    return {
                        id: selectedSource,
                        y: opportunitiesBySource.find( opportunities =>
                            selectedSource === opportunities.id )?.total || 0,
                    };
                } ),
                color: "#1565C0"
            },
            {
                name: translateStatus( "SOLD" ),
                data: selectedSources.map( selectedSource => {
                    return {
                        id: selectedSource,
                        status: "SOLD",
                        y: opportunitiesBySource.find( opportunities =>
                            selectedSource === opportunities.id )?.sold || 0,
                    };
                } ),
                color: "#4caf50"
            },
            {
                name: translateStatus( "LOST" ),
                data: selectedSources.map( selectedSource => {
                    return {
                        id: selectedSource,
                        status: "LOST",
                        y: opportunitiesBySource.find( opportunities =>
                            selectedSource === opportunities.id )?.lost || 0,
                    };
                } ),
                color: "#f44336"
            },
        ];
    }, [ translateStatus, selectedSources, opportunitiesBySource ] );

    const categories = useMemo( () => {
        return selectedSources.map( selectedSource =>
            sources.find( source => source.id === selectedSource ).name );
    }, [ selectedSources, sources ] );

    const chartData = useMemo( () => {
        return {
            title: {
                text: t( "dashboard:opportunities-by-source.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunities-by-source.source" );
                    }
                },
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ""
                }
            },
            tooltip: {
                headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
                pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
                "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
                footerFormat: "</table>",
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                if ( !this.y || !this.id ) {
                                    return;
                                }

                                let params = {
                                    sourcesId: this.id,
                                };
                                if ( !this.status ) {
                                    params = {
                                        ... params,
                                        initialCreatedAt: initialDate,
                                        finalCreatedAt: finalDate,
                                    };
                                } else if ( this.status === "SOLD" ) {
                                    params = {
                                        ... params,
                                        status: this.status,
                                        initialSoldAt: initialDate,
                                        finalSoldAt: finalDate
                                    };
                                } else if ( this.status === "LOST" ) {
                                    params = {
                                        ... params,
                                        status: this.status,
                                        initialLostAt: initialDate,
                                        finalLostAt: finalDate
                                    };
                                }

                                const statusLabel = translateStatus( this.status ).toLowerCase();

                                onClickItem(
                                    t(
                                        "dashboard:opportunities-by-source.title-dialog",
                                        { source: this.category, status: statusLabel }
                                    ),
                                    params
                                );
                            }
                        }
                    }
                }
            },
            series,
        };
    }, [ t, translateStatus, categories, series, onClickItem, initialDate, finalDate ]);

    return (
        <Card className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunities-by-source.help" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK "}</Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="opportunitiesBySource"
                data={ chartData }
                noDataMessage={ t( "dashboard:opportunities-by-source.empty" ) }
                type="column"
            />

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButton className={ css.iconHelpChart } onClick={ toggleHelp } size="large">
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
        </Card>
    );
};

export default OpportunitiesBySource;