import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DehazeIcon from "@mui/icons-material/Dehaze";
import DeleteIcon from "@mui/icons-material/Delete";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import valuesIn from "lodash/valuesIn";
import * as XLSX from "xlsx";
import qs from "qs";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import history from "../../../history";
import TableHead from "./table-head.component";
import TableRow from "./table-row.component";
import css from "./table.scss";
import { Dialog, DialogInfo, flex, cssUtils } from "../";

function s2ab ( s ) {
    const buf = new ArrayBuffer( s.length );

    const view = new Uint8Array( buf );

    for ( let i = 0; i !== s.length; ++i ) {
        view[ i ] = s.charCodeAt( i ) & 0xFF;
    }

    return buf;
}

const Table = ({
    isLoading,
    className,
    showTitle,
    title,
    footer,
    allowExport,
    allowOrder,
    model,
    onAdd,
    headerActions,
    onChangePage,
    onDelete,
    onRowClick,
    onSelect,
    selectable,
    isRowDisabled,
    rowLink,
    allRowsSelected,
    allRowsSelectedType,
    showSelectAllOptions,
    rowClassName,
    saveChanges,
    saveOrderHistory,
    shadow = true,
    emptyMessage,
    footnote,
    totalElements,
    totalPages,
    rowsPerPageOptions,
    id,
    ...props
}) => {
    const location = useLocation();

    const t = useTranslate();

    const [ data, setData ] = useState( props.source || [] );
    const [ showDeleteDialog, setShowDeleteDialog ] = useState( false );
    const [ orderBy, setOrderBy ] = useState( props.orderBy );
    const [ orderType, setOrderType ] = useState( props.orderType || "ASC" );
    const [ selectedPage, setSelectedPage ] = useState( props.selectedPage || 0 );
    const [ showSelectRowsDialog, setShowSelectRowsDialog ] = useState( false );
    const [ showAlertBlockUnchecked, setShowAlertBlockUnchecked ] = useState( false );
    const [ downloadButton, setDownloadButton ] = useState( null );
    const [ pageSize, setPageSize ] = useState( props.pageSize );

    const prevModel = usePrevious( model );
    const prevOrderBy = usePrevious( props.orderBy );
    const prevOrderType = usePrevious( props.orderType );
    const prevSelectedPage = usePrevious( props.selectedPage );

    const openDownload = event => setDownloadButton( event.currentTarget );
    const closeDownload = () => setDownloadButton( null );

    const toggleAlertBlockUnchecked = () => setShowAlertBlockUnchecked( !showAlertBlockUnchecked );

    const download = extension => {
        const dataDownload = [ ...data, footer ].map( item => valuesIn( pick( item, Object.keys( model ) ) ) );
        const headers = [ Object.keys( model ).map( key => model[ key ].title || model[ key ]) ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet( headers.concat( dataDownload ), { skipHeader: true } );

        wb.SheetNames.push( "" );
        wb.Sheets[ "" ] = ws;

        const wbout = XLSX.write( wb, { bookType: extension, bookSST: true, type: "binary" });
        const blob = new Blob([ s2ab( wbout ) ], { type: "application/octet-stream" });;

        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
            window.navigator.msSaveOrOpenBlob(blob, title + "." + extension );
        } else {
            const url = window.URL.createObjectURL( blob );

            const a = document.createElement( "a" );
            document.body.appendChild( a );
            a.href = url;
            a.download = title + "." + extension;
            a.click();

            window.URL.revokeObjectURL( url );
            document.body.removeChild( a );
        }
        closeDownload();
    };

    const handleChangeOrder = newOrderBy => {
        const newOrderByFormatted = newOrderBy.toString();
        const newOrderType = orderBy === newOrderByFormatted ? ( orderType === "ASC" ? "DESC" : "ASC" ) : "ASC";
        if ( saveOrderHistory ) {
            const queryString = qs.parse( location.search, { ignoreQueryPrefix: true });
            history.push({
                ...location,
                search: qs.stringify({
                    ...queryString,
                    page: 0,
                    pageSize,
                    orderBy: newOrderBy,
                    orderType: newOrderType
                })
            });
        }

        setOrderBy( newOrderByFormatted );
        setOrderType( newOrderType );
        setSelectedPage( 0 );
        setPageSize( pageSize );

        onChangePage({ orderBy: newOrderByFormatted, orderType: newOrderType, page: 0, pageSize });
    };

    const handlePageChange = ( event, page ) => {
        if ( saveOrderHistory ) {
            const queryString = qs.parse( location.search, { ignoreQueryPrefix: true });
            history.push({
                ...location,
                search: qs.stringify({ ...queryString, page, pageSize })
            });
        }

        setSelectedPage( page );

        onChangePage({ pageSize, page: page + 1, orderBy, orderType });;
    };

    const handleRowsPerPageChange = event => {
        if ( saveOrderHistory ) {
            const queryString = qs.parse( location.search, { ignoreQueryPrefix: true });
            history.push({
                ...location,
                search: qs.stringify({ ...queryString, pageSize: event.target.value, page: 0 })
            });
        }

        setSelectedPage( 0 );
        setPageSize( event.target.value );

        onChangePage({ pageSize: event.target.value, page: 1, orderBy, orderType });
    };

    const handleSelect = index => {
        const source = data.slice();

        if ( allRowsSelectedType === "all" ) {
            setShowAlertBlockUnchecked( true );
            return;
        }

        source[ index ].selected = !source[ index ].selected;

        setData( source );
        if ( onSelect ) {
            onSelect( source );
        }
    };

    const doSelectAll = ( checked, type ) => {
        const source = data.slice();

        source.forEach( item => {
            item.selected = checked;
        });

        setData( source );

        if ( onSelect ) {
            onSelect( source, type === "all" );
        }
    };

    const toggleSelectRowsDialog = type => {
        doSelectAll( true, type );
        setShowSelectRowsDialog( false );
    };

    const handleSelectAll = ( event, checked ) => {
        if ( !checked ) {
            doSelectAll( false );
        } else {
            if ( showSelectAllOptions ) {
                setShowSelectRowsDialog( true );
            } else {
                toggleSelectRowsDialog( "page" );
            }
        }
    };

    const toggleDeleteDialog = () => setShowDeleteDialog( !showDeleteDialog );

    const handleDelete = () => {
        data.filter( item => item.selected ).forEach( item => {
            onDelete( item );
        });
        toggleDeleteDialog();
    };

    const closeSelectRowsDialog = () => setShowSelectRowsDialog( false );

    useEffect( () => {
        setData( props.source || [] );
    }, [ props.source ] );
    useEffect( () => {
        if ( !prevModelKeys ) {
            return;
        }
        const prevModelKeys = Object.keys( prevModel );
        const modelKeys = Object.keys( model );
        if ( !isLoading && !isEqual( prevModelKeys, modelKeys ) && allowOrder ) {
            const orderByKey = Object.keys( model )[ 0 ];
            const orderBy = model[ orderByKey ].value.toString() || orderByKey;

            setSelectedPage( 0 );
            setOrderBy( orderBy );
            setOrderType( "ASC" );
            onChangePage({ page: selectedPage + 1, orderBy, pageSize });
        }
    }, [ allowOrder, isLoading, prevModel, model, onChangePage, selectedPage, pageSize ] );
    useEffect( () => {
        if ( props.orderBy !== prevOrderBy || props.orderType !== prevOrderType ) {
            setOrderBy( props.orderBy );
            setOrderType( props.orderType );
        }
    }, [ prevOrderBy, prevOrderType, props.orderBy, props.orderType ] );
    useEffect( () => {
        if ( props.selectedPage !== prevSelectedPage ) {
            setSelectedPage( props.selectedPage );
        }
    }, [ prevSelectedPage, props.selectedPage ] );

    const showDeleteButton = data.filter( item => item.selected ).length > 0 && onDelete;

    if ( isLoading ) {
        return <CircularProgress/>;
    }

    let quantityColumns = Object.keys( model || {} ).length;
    if ( selectable ) {
        quantityColumns++;
    }
    const pages = [];
    for ( let i = 0; i < totalPages; i++ ) {
        pages.push({ value: i + 1, label: i + 1 });
    }

    return (
        <TableContainer component={ Paper } className={ className } elevation={ shadow ? 1 : 0 } id={ id }>
            <DialogInfo
                open={ showAlertBlockUnchecked }
                message={ t( "ui:table.select-all-alert-content" ) }
                onClose={ toggleAlertBlockUnchecked }
                maxWidth="xs"
            />

            <Dialog
                open={ showSelectRowsDialog }
                onClose={ closeSelectRowsDialog }
            >
                <DialogContent>
                    <DialogContentText>{ t( "ui:table.select-all-dialog-text" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={ closeSelectRowsDialog }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ () => toggleSelectRowsDialog( "page" ) }>
                        { t( "ui:table.select-all-dialog-type-page" ) }
                    </Button>
                    <Button color="primary" onClick={ () => toggleSelectRowsDialog( "all" ) }>
                        { t( "ui:table.select-all-dialog-type-all" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showDeleteDialog }
                onClose={ toggleDeleteDialog }
            >
                <DialogContent>
                    <DialogContentText>{ t( "ui:table.delete-dialog.title" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleDeleteDialog }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            {
                onAdd || showDeleteButton ?
                    <Toolbar className={ css.title }>
                        {
                            onAdd && !showDeleteButton &&
                                <Button
                                    id="tableAddButton"
                                    color="primary"
                                    onClick={ onAdd }
                                >
                                    { t( "ui:table.add" ) }
                                </Button>
                        }
                        <div className={ flex.fill }/>
                        { !showDeleteButton && headerActions }
                        {
                            showDeleteButton ?
                                <Tooltip title={ t( "common:exclude" ) }>
                                    <IconButton
                                        onClick={ toggleDeleteDialog }
                                        size="small"
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip> :
                                null
                        }
                    </Toolbar> :
                    null
            }

            {
                ( allowExport || showTitle ) &&
                    <div
                        className={ classnames(
                            css.titleContainer,
                            showTitle ? flex.justifyContentSpaceBetween : flex.justifyContentEnd
                        )}
                    >
                        {
                            showTitle && title &&
                                <Typography
                                    variant="h6"
                                    className={ cssUtils.marginLeft }
                                >
                                    { title }
                                </Typography>
                        }
                        {
                            allowExport &&
                                <Fragment>
                                    <IconButton
                                        onClick={ openDownload }
                                        className={ cssUtils.marginRightSmall }
                                        size="large">
                                        <DehazeIcon/>
                                    </IconButton>
                                    <Menu
                                        open={ !!downloadButton }
                                        onClose={ closeDownload }
                                        anchorEl={ downloadButton }
                                    >
                                        <MenuItem onClick={ () => download( "csv" ) }>
                                            { t( "ui:table.download-csv" ) }
                                        </MenuItem>
                                        <MenuItem onClick={ () => download( "xls" ) }>
                                            { t( "ui:table.download-xls" ) }
                                        </MenuItem>
                                    </Menu>
                                </Fragment>
                        }
                    </div>
            }
            <table className={ css.table }>
                <TableHead
                    model={ model }
                    selectable={ selectable }
                    onSelectAll= { ( ( event, checked ) => handleSelectAll( event, checked ) )}
                    allRowsSelected={allRowsSelected}
                    allowOrder={ allowOrder }
                    orderBy={ orderBy }
                    orderType={ orderType }
                    onChangeOrder={ handleChangeOrder }
                />
                <tbody>
                    {
                        isEmpty( data ) && emptyMessage ?
                            <tr>
                                <td colSpan={ quantityColumns }>{ emptyMessage }</td>
                            </tr> :
                            null
                    }
                    {
                        data.map( ( data, index ) => (
                            <TableRow
                                key={ index }
                                index={ index }
                                model={ model }
                                data={ data }
                                className={ rowClassName }
                                selectable={ selectable }
                                onRowClick={ onRowClick }
                                rowLink={ rowLink }
                                onSelect={ () => handleSelect( index ) }
                                disabled={ isRowDisabled ? isRowDisabled( index ) : false }
                                saveChanges={ saveChanges }
                            />
                        ))
                    }
                </tbody>
                { footer &&
                    <TableFooter className={ css.footer }>
                        <TableRow
                            model={ model }
                            data={ footer }
                        />
                    </TableFooter>
                }
            </table>
            {
                footnote ?
                    <div>
                        {
                            Array.isArray( footnote ) ?
                                footnote.map( ( item, index ) => (
                                    <div key={ index } className={ classnames( css.foot, cssUtils.padding ) }>
                                        <div className={ flex.fill }>
                                            <span className={ css.footnote }>{ item }</span>
                                        </div>
                                    </div>
                                )) :
                                <div className={ classnames( css.foot, cssUtils.padding ) }>
                                    <div className={ flex.fill }>
                                        <span className={ css.footnote }>{ footnote }</span>
                                    </div>
                                </div>
                        }
                    </div>
                    : null
            }
            {
                parseInt( totalPages ) > 0 &&
                    <TablePagination
                        className={ css.pagination }
                        component="div"
                        count={ parseInt( totalElements ) }
                        onPageChange={ handlePageChange }
                        page={ selectedPage }
                        rowsPerPage={ pageSize }
                        rowsPerPageOptions={ rowsPerPageOptions && rowsPerPageOptions.length ?
                            rowsPerPageOptions : [] }
                        onRowsPerPageChange={ handleRowsPerPageChange }
                        labelDisplayedRows={
                            ({ from, to, count }) => from + "-" + to + t( "ui:table.of" ) + count
                        }
                        labelRowsPerPage={ rowsPerPageOptions ? t( "ui:table.itens-per-page" ) : null }
                    />
            }
        </TableContainer>
    );
};

export default Table;