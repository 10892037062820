export default {
    "title": "Importaciones",
    "importations": {
        "title": "Importaciones",
        "empty": "No hay importaciones registradas",
        "empty-file": "Importación no realizada",
        "download": "Descargar el archivo que utiliza",
        "copy": "Crear nueva importación a partir de esta plantilla de configuración desde un nuevo archivo"
    },
    "importation": {
        "name": "Nombre",
        "tags": "Tags",
        "users": "Vendedores",
        "users-help":
            "Vendedores que recibirán proporcionalmente las oportunidades creadas. " +
            "Si no se seleccionado, al menos, un vendedor, se crearán oportunidades para usted.",
        "merge-data": "Combinar datos",
        "clear-data": "Borrar datos",
        "clear-data-help":
            "Si el valor de un campo viene en blanco y en el cliente hay valor, será el mismo \"limpio\".",
        "warning-validation-errors": "Debe llenar todos los campos obligatorios para continuar",
        "first-row-header": "Considere la primera línea de archivo como encabezado",
        "files-accepted": "Solamente se aceptan archivos .csv, .xls y .xlsx ",
        "created-at": "Creado en",
        "created-by": "Creado por",
        "file": "Archivo",
        "finished-at": "Finalizado en",
    },
    "new-importation": {
        "init-success":
            "La importación se puso en cola correctamente para ejecutarse. Se le totificará cuando se complete.",
        "save-and-execute": "Guardar y ejecutar",
        "different-file":
            "El archivo seleccionado no tiene el mismo número de columnas que el archivo utilizado " +
            "en la importación seleccionada para copiar.",
        "file-exceed-size": "El archivo no puede superar los 2 MB",
        "file-exceed-lines": "La hoja de cálculo no puede tener más de 1000 filas",
        "mapping-invalid": "Este campo necesita algunos ajustes. Haga clic en el botón en el lateral para editarlo",
        "select-date-format": "Seleccione el formato de las fechas",
        "select-address-type": "Seleccione el tipo de dirección",
        "select-phone-country": "Seleccione el país del teléfono",
        "select-phone-type": "Seleccione el tipo de teléfono",
        "file-required": "Es necesario seleccionar un archivo",
        "file-columns": "Columnas de archivo",
        "form-fields": "Campos de formulario. Arrastre estos campos para las columnas del archivo al lado",
        "customers": "Clientes",
        "customer-person": "Persona",
        "customer-company": "Empresa",
        "customer-help": "Las personas estarán vinculadas a las empresas en el archivo.",
        "select-customer": "Seleccione al menos un tipo de cliente",
        "opportunities": "Oportunidades",
        "empty-value": "(Sin valor)",
        "drag-here-field": "Arrastre el campo aquí",
        "confirm": {
            "title": "Confirma la importación del archivo { file } con { lines } línea(s)?",
            "opportunity-users":
                "Las oportunidades creadas se distribuirán proporcionalmente a lo(s) usuario(s) { users }.",
            "used-columns": "Columnas que se importarán",
            "not-used-columns": "Columnas que no se importarán"
        }
    },
    "importation-results": {
        "executing": "Ejecución de la importación",
        "success-message": "Importación finalizada en { date }",
        "title": "Resultados",
        "customers-created": "Clientes creados",
        "customers-updated": "Clientes actualizados",
        "customers-skipped": "Clientes ignorados",
        "customers-skipped-download": "Haga clic aquí para descargar el archivo con las líneas omitidas",
        "opportunities-created": "Oportunidades creadas",
        "opportunities-skipped": "Oportunidades ignoradas",
        "opportunities-skipped-download": "Haga clic aquí para descargar el archivo con las líneas omitidas",
        "delete-confirm":
            "Está seguro que desea eliminar esta imprtación? Todos los clientes y oportunidades creadas " +
            "por esta importación, será excluído tambiém. Los datos creados se eliminarán de forma asincrónica."
    }
};