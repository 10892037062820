import { Field } from "formik";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { cssUtils, Input } from "../../../ui";
import CommonForm from "./common-form.component";
import useTranslate from "../../../hooks/use-translate.hook";

const UsadosbrForm = props => {
    const t = useTranslate();
    return (
        <>
            <Box border={ 1 } borderRadius={ 1 } className={ cssUtils.padding }>
                <Typography variant="subtitle2">
                    { t( "marketplace:integrations.portals.usadosbr-header" ) }
                </Typography>
                <Field
                    name="credentials.username"
                    label={ t( "common:username" ) }
                    component={ Input }
                    required
                />
                <Field
                    type="password"
                    name="credentials.password"
                    label={ t( "common:password" ) }
                    component={ Input }
                    required
                />
            </Box>
            <CommonForm { ...props } />
        </>
    );
};

export default UsadosbrForm;