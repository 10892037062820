export default {
    "name": "Nome",
    "email": {
        "label": "E-mail",
        "help": "O e-mail não pode ser alterado."
    },
    "password": "Senha",
    "confirm-password": "Confirme sua senha",
    "accept": {
        "call-to-action": "Registre-se para começar a usar o LeadStation de { name }.",
        "button": "Registrar"
    },
    "not-found": "O convite acessado não existe ou já expirou.",
    "invitation": {
        "action": "Enviar",
        "succeeded": "O convite para o e-mail {email} foi enviado com sucesso!"
    }
};