import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../../../hooks/use-translate.hook";
import { getSchedulingTypes } from "../../../opportunity/opportunity.selectors";
import { cssUtils, flex, Dropdown, Input, Outlined, MaskInput } from "../../../ui";

const TIME_TYPES = [
    "AT_THE_TIME",
    "AMOUNT_HOURS",
    "AMOUNT_DAYS",
    "EXACT",
];

const PhaseEntrySchedules = ({ form, name, push, remove }) => {
    const t = useTranslate();

    const schedulingTypes = useSelector( getSchedulingTypes );

    const onAdd = () => push({ timeType: "" });
    const onRemove = index => () => remove( index );

    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    return (
        <>
            {
                values.map( ( entry, index ) => (
                    <div key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Outlined className={ flex.fill }>
                            <Field
                                name={ `${name}[${index}].opportunitySchedulingTypeId` }
                                label={ t( "config:phases.entry-schedules.opportunity-scheduling-type" ) }
                                source={ schedulingTypes.map( schedulingType => ({
                                    value: schedulingType.id,
                                    label: schedulingType.name,
                                })) }
                                component={ Dropdown }
                                required
                            />
                            <Field
                                name={ `${name}[${index}].description` }
                                label={ t( "config:phases.entry-schedules.description" ) }
                                component={ Input }
                                required
                            />
                            <div className={ classnames( flex.container, flex.columnGapSmall ) }>
                                <Field
                                    name={ `${name}[${index}].timeType` }
                                    label={ t( "config:phases.entry-schedules.time-type.label" ) }
                                    source={ TIME_TYPES.map( timeType => ({
                                        label: t( `config:phases.entry-schedules.time-type.${timeType}` ),
                                        value: timeType,
                                    })) }
                                    component={ Dropdown }
                                    className={ flex.fill }
                                    fullWidth={ false }
                                    sort={ false }
                                    required
                                />
                                {
                                    entry.timeType === "AMOUNT_HOURS" &&
                                        <Field
                                            name={ `${name}[${index}].amountTime` }
                                            label={ t( "config:phases.entry-schedules.amount-time" ) }
                                            placeholder="hh:mm"
                                            component={ MaskInput }
                                            format={ "##:##" }
                                            isNumericString={ false }
                                            useFormattedValue
                                            className={ flex.fill }
                                            fullWidth={ false }
                                            required
                                        />
                                }
                                {
                                    entry.timeType === "AMOUNT_DAYS" &&
                                        <Field
                                            name={ `${name}[${index}].amountTime` }
                                            label={ t( "config:phases.entry-schedules.amount-days" ) }
                                            type="number"
                                            component={ Input }
                                            className={ flex.fill }
                                            fullWidth={ false }
                                            required
                                        />
                                }
                                {
                                    entry.timeType === "EXACT" &&
                                        <Field
                                            name={ `${name}[${index}].exactTime` }
                                            label={ t( "config:phases.entry-schedules.exact-time" ) }
                                            placeholder="hh:mm"
                                            component={ MaskInput }
                                            format={ "##:##" }
                                            isNumericString={ false }
                                            useFormattedValue
                                            className={ flex.fill }
                                            fullWidth={ false }
                                            required
                                        />
                                }
                            </div>
                        </Outlined>
                        <IconButton onClick={ onRemove( index ) } size="large">
                            <CancelIcon />
                        </IconButton>
                    </div>
                ))
            }
            <Button size="small" onClick={ onAdd } className={ cssUtils.marginTopSmall }>
                { t( "common:add" ) }
            </Button>
        </>
    );
};

export default PhaseEntrySchedules;