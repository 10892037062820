import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchForm } from "../../form/form.actions";
import { list } from "../../form/form.selectors";
import { generateOpportunities } from "../customer.actions";
import { GENERATE_OPPORTUNITIES_FORM } from "../customer.constants";
import { Fields, validateFields } from "../../form/fields.component";
import { DialogForm, SelectFunnel, UsersFilter, cssUtils } from "../../ui";
import css from "./generate-opportunities.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const GenerateOpportunities = ({ customerType, totalCustomers }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunityFields = useSelector( list( "OPPORTUNITY" ) ).filter( field => field.systemField !== "USER" );

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const formik = useForm({
        form: GENERATE_OPPORTUNITIES_FORM,
        enableReinitialize: true,
        initialValues: {
            customerType,
            funnelId: null,
            teamId: null,
            usersId: [],
            fields: fieldsToInitialValues( opportunityFields )
        },
        onSubmit: values => dispatch( generateOpportunities( values ) ),
        validate: values => {
            const errors = validateFields( values, { fields: opportunityFields } );

            if ( !values.funnelId ) {
                errors.funnelId = "common:validation.selection";
            }

            if ( isEmpty( values.usersId ) ) {
                errors.usersId = "common:validation.selection";
            }

            return errors;
        },
    });

    useEffect( () => {
        dispatch( fetchForm( "OPPORTUNITY" ) );
    }, [ dispatch ] );

    const inputUsers = {
        team: {
            value: formik.values.teamId,
            onChange: value => formik.setFieldValue( "teamId", value )
        },
        users: {
            value: formik.values.usersId,
            onChange: value => formik.setFieldValue( "usersId", value )
        }
    };
    const errorUsers = !!formik.errors.usersId && formik.touched.teamId;

    return (
        <>
            <Tooltip title={ t( "customer:generate-opportunities.action" ) }>
                <IconButton onClick={ toggleOpen } size="large">
                    <AttachMoneyIcon />
                </IconButton>
            </Tooltip>
            <DialogForm
                open={ open }
                fullWidth
                size="md"
                handleSubmit={ formik.handleSubmit }
                onClose={ toggleOpen }
                resetForm={ formik.resetForm }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
                title={ t( "customer:generate-opportunities.title" ) }
            >
                <FormikProvider value={ formik }>
                    <Alert severity="warning">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t( "customer:generate-opportunities.description", { total: totalCustomers } )
                            }}
                        />
                    </Alert>

                    <Field
                        name="funnelId"
                        component={ SelectFunnel }
                        required
                    />
                    <UsersFilter
                        input={ inputUsers }
                        ComponentRoot="div"
                        className={ cssUtils.padding0 }
                        error={ errorUsers }
                        required
                    />
                    <hr />
                    <div className={ css.fields }>
                        <Fields fields={ opportunityFields } />
                    </div>
                </FormikProvider>
            </DialogForm>
        </>
    );
};

export default GenerateOpportunities;