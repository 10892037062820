import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";

import useTranslate from "../../../hooks/use-translate.hook";
import { CONFIG_PRODUCT_FORM } from "./product.constants";
import { saveProduct } from "./product.actions";
import { Input, Dropdown, Checkbox, PriceInput } from "../../../ui";
import { listCategories } from "../../../product/product.selectors";

const Products = ({ product, onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const categories = useSelector( listCategories );

    const [ showError, setShowError ] = useState( false );
    const closeError = () => setShowError( false );

    const formik = useForm({
        enableReinitialize: true,
        form: CONFIG_PRODUCT_FORM,
        initialValues: {
            description: "",
            code: "",
            active: true,
            recurrent: false,
            ...product,
            measurementUnit: product?.measurementUnit || "UNIT"
        },
        onSubmit: values => dispatch( saveProduct( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.description ) {
                errors.description = "common:validation.required";
            } else if ( values.description.length > 255 ) {
                errors.description = "common:validation.exceeded";
            }
            if ( !values.measurementUnit ) {
                errors.measurementUnit = "common:validation.selection";
            }
            if ( values.code && values.code.length > 255 ) {
                errors.code = "common:validation.exceeded";
            }
            return errors;
        }
    });

    useEffect( () => {
        if ( formik.error ) {
            setShowError( true );
        }
    }, [ formik.error ] );

    if ( !show ) {
        return null;
    }

    return (
        <>
            <Snackbar
                open={ showError }
                action={ <Button onClick={ closeError } color="secondary">{ "OK" }</Button>}
                message={ formik.error }
                autoHideDuration={ 5000 }
                onClose={ closeError }
            />
            <Dialog
                open
                onClose={ onClose }
            >
                <DialogTitle>{ t( "config:products.add.single" ) }</DialogTitle>
                <DialogContent>
                    <Form formik={ formik } noValidate>
                        <fieldset disabled={ formik.submitting }>
                            <Field
                                type="text"
                                name="code"
                                label={ t( "config:product.code" ) }
                                component={ Input }
                                autoComplete="off"
                                maxLength={ 255 } />
                            <Field
                                type="text"
                                name="description"
                                label={ t( "config:product.description" ) }
                                component={ Input }
                                autoComplete="off"
                                maxLength={ 255 }
                                required />
                            <Field
                                name="price"
                                label={ t( "config:product.price" ) }
                                component={ PriceInput }
                                autoComplete="off" />
                            <Field
                                name="measurementUnit"
                                label={ t( "config:product.measurementUnit.label" ) }
                                component={ Dropdown }
                                source={
                                    [ "UNIT", "METER", "HOUR", "KILO", "LITER" ].map( value => (
                                        { value, label: t( `config:product.measurementUnit.${value}` )}
                                    ))
                                }
                                required />
                            <Field
                                name="category.id"
                                label={ t( "config:product.category.label" ) }
                                component={ Dropdown }
                                source={ categories.map( category =>
                                    ({ value: category.id, label: category.name }) ) }
                            />
                            <Field
                                name="recurrent"
                                type="checkbox"
                                component={ Checkbox }
                            >
                                { t( "config:product.recurrent" ) }
                            </Field>
                            {
                                product.id ?
                                    <Field name="active"
                                        type="checkbox"
                                        component={ Checkbox }
                                    >
                                        { t( "config:product.active" ) }
                                    </Field>
                                    : ""
                            }
                        </fieldset>
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ onClose } disabled={ formik.submitting }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Products;