import { useMemo } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import get from "lodash/get";

import { Outlined, cssUtils } from "../ui";
import FilterFieldItem from "./filter-field-item.component";

const FilterField = ({ formType, label, form, name, push, remove }) => {
    const values = useMemo( () => get( form.values, name ) || [], [ form.values, name ] );

    const rows = values.map( ( filter, index ) => (
        <Outlined key={ index }>
            <FilterFieldItem
                name={ `${name}[${index}]` }
                formType={ formType }
                onDelete={ () => remove( index ) }
                setFieldValue={ form.setFieldValue }
                filter={ filter }
            />
        </Outlined>
    ));

    return (
        <List disablePadding>
            { rows }
            <ListItemButton className={ cssUtils.marginTopSmall } onClick={ () => push({}) }>
                <ListItemIcon><AddIcon/></ListItemIcon>
                <ListItemText primary={ label }/>
            </ListItemButton>
        </List>
    );
};

export default FilterField;