export default {
    "locales": {
        "pt-BR": "Português",
        "es-MX": "Espanhol"
    },
    "language": "Idioma",
    "change-locale": "Alterar idioma",
    "full-locale": "pt-BR",
    "country": "BR",
    "locale": "pt",
    "currency": "BRL",
    "currency-symbol": "R$",
    "date": "DD/MM/YYYY",
    "full-date": "DD/MM/YYYY HH:mm",
    "format-hour": "HH:mm",
    "day-month": "DD/MM",
    "yes": "Sim",
    "no": "Não",
    "access": "Acessar",
    "add": "Adicionar",
    "remove": "Remover",
    "ok": "Ok",
    "duplicate": "Duplicar",
    "edit": "Editar",
    "enter": "Entrar",
    "confirm": "Confirmar",
    "confirmation": "Confirmação",
    "execute": "Executar",
    "save": "Salvar",
    "submit": "Enviar",
    "cancel": "Cancelar",
    "clean": "Limpar",
    "close": "Fechar",
    "exclude": "Excluir",
    "expand": "Expandir",
    "export": "Exportar",
    "copy": "Copiar",
    "copied": "Copiado",
    "click-here-to-copy": "Clique aqui para copiar",
    "print": "Imprimir",
    "help": "Ajuda",
    "file": "Arquivo",
    "optional": "Opcional",
    "definitions": "Definições",
    "previous": "Anterior",
    "next": "Próximo",
    "back": "Voltar",
    "skip": "Pular",
    "refresh": "Atualizar",
    "search": "Pesquisar",
    "select": "Selecionar",
    "table": "Tabela",
    "visualize": "Visualizar",
    "minimize": "Minimizar",
    "order": "Alterar ordem",
    "download": "Fazer o download",
    "delete-confirm": "Você tem certeza que deseja excluir? Esta ação é irreversível!",
    "click-here-access": "Clique aqui para acessar",
    "empty-email": "Nenhum e-mail cadastrado",
    "empty-phone": "Nenhum telefone cadastrado",
    "open-this-window": "Abrir nesta janela",
    "open-new-window": "Abrir em uma nova janela",
    "creation-user": "Usuário de criação",
    "creation-date": "Data de criação",
    "change-user": "Usuário de alteração",
    "change-date": "Data de alteração",
    "name": "Nome",
    "username": "Usuário",
    "email": "E-mail",
    "password": "Senha",
    "ein": "CNPJ",
    "ssn": "CPF",
    "phone": "Telefone",
    "address": "Endereço",
    "customers": "Clientes",
    "customer-empty": "Sem seleção de dados",
    "customer-person-empty": "Nenhuma pessoa encontrada",
    "customer-company-empty": "Nenhuma empresa encontrada",
    "none": "Nenhum",
    "mode": "Modo",
    "update": "Alterar",
    "from": "De",
    "until": "Até",
    "price": "Valor",
    "total": "Total",
    "type": "Tipo",
    "error": {
        "unknown": "Ops, um erro desconhecido aconteceu aqui! Que tal tentar novamente mais tarde?",
        "refresh": "Ops, um erro desconhecido aconteceu aqui! Atualize a página e tente novamente"
    },
    "validation": {
        "accept-terms": "Você deve concordar com os termos de uso.",
        "required-fields": "Você deve preencher todos os campos obrigatórios",
        "required": "Preencha este campo.",
        "required-file": "Selecione um arquivo.",
        "selection": "Selecione um valor.",
        "select-option": "Selecione ao menos um valor.",
        "add-at-least-one-user": "Adicione ao menos um usuário.",
        "address": "Selecione um endereço válido.",
        "email": "Informe um e-mail válido.",
        "email-taken": "Este e-mail já está em uso por outro usuário.",
        "dist-empty-users": "Você deve selecionar uma equipe ou ao menos um usuário para distribuir as oportunidades.",
        "phone-number": "Informe um número de telefone válido com DDD.",
        "currency": "Informe uma quantia de dinheiro válida.",
        "exceeded": "Número de caracteres excedido",
        "date": "Informe uma data válida.",
        "password-length": "A senha deve conter no mínimo 6 caracteres",
        "passwords-match": "Essas senhas não coincidem.",
        "lower-start": "Horário inferior ao de início.",
        "higher-end": "Horário superior ao de fim.",
        "lower-start-period": "Horário inferior ao da manhã.",
        "higher-end-period": "Horário superior ao da tarde.",
        "ssn": "Informe um CPF válido.",
        "ein": "Informe um CNPJ válido.",
        "user-limit": "Quantidade de usuários desejados inválidos",
        "credit-card": {
            "number": "Número de cartão inválido.",
            "month-out-range": "O mês deve estar entre 01 e 12.",
            "year-out-range": "O ano não deve estar no passado.",
            "date-out-range": "A data não pode estar no passado.",
            "cvc": "Código de segurança inválido."
        },
        "plan-limit-out-of-range": "A quantidade de usuários está fora do limite do plano.",
        "loss-reason": "Informe um motivo para perda."
    },
    "highcharts": {
        "print-chart": "Imprimir gráfico",
        "menu-chart": "Opções do gráfico",
        "download-jpeg": "Download imagem JPEG",
        "download-png": "Download imagem PNG",
        "download-pdf": "Download documento PDF",
        "download-csv": "Download CSV",
        "download-xls": "Download XLS",
        "view-full-screen": "Ver em tela cheia",
    }
};