export function modifyFieldsList ( fields, newField ) {
    const prevIndex = fields.findIndex( field => field.id === newField.id );

    if ( prevIndex > -1 ) {
        return fields
            .slice( 0, prevIndex )
            .concat( newField )
            .concat( fields.slice( prevIndex + 1 ) );
    }

    return fields.concat( newField );
}

export function createFormName ( field ) {
    return "fieldConfig" + ( field && field.id || 0 ) + ( field && field.type || "" );
}