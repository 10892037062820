import { createSelector } from "reselect";

const kanbanBase = state => state.kanban;

export const getKanban = createSelector(
    kanbanBase,
    kanban => kanban.data
);
export const isLoadingKanban = createSelector(
    kanbanBase,
    kanban => kanban.loading
);