import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./subscription.actions";

export function logged ( state = {}, action) {
    switch ( action.type ) {
        case actions.RECEIVE_SUBSCRIPTION:
            const subscription = action.payload;
            return { subscription };

        case actions.ERROR_SUBSCRIPTION:
            return {
                error: action.payload
            };

        default:
            return state;
    }
}

const holdingFunnels = createRequestReducer({
    begin: actions.REQUEST_HOLDING_FUNNELS,
    success: actions.RECEIVE_HOLDING_FUNNELS,
    failure: actions.ERROR_HOLDING_FUNNELS
});

export const subscription = combineReducers({ logged, holdingFunnels });