import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import order from "lodash/orderBy";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { GoalProgress, useGoalProgressFormat } from "../home";
import { Dropdown, DropdownMultiple, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { selectors as teamSelectors } from "../team";
import { selectors as userSelectors } from "../user";
import { getUser } from "../core/core.selectors";
import { fetchResults } from "./report.actions";
import { isLoadingResults, getResults } from "./report.selectors";

const filterGoalsByTeam = ( results, teamId ) => {
    let filterGoals;
    if ( !teamId ) {
        filterGoals = progress => !progress.goal.team && isEmpty( progress.goal.users );
    } else {
        filterGoals = progress => ( progress.goal.team && progress.goal.team.id === teamId ) ||
            ( !progress.goal.team && isEmpty( progress.goal.users ) );
    }

    const filteredGoalProgresses = [];
    results.forEach(
        item => {
            item.goalProgresses
                .filter( filterGoals )
                .forEach( goalProgresses => filteredGoalProgresses.push({ ...goalProgresses, userId: item.id }) );
        }
    );

    const newGoalProgresses = [];
    filteredGoalProgresses.forEach( goalProgress => {
        const previous = newGoalProgresses
            .find( g => g.goal.id === goalProgress.goal.id && g.userId !== goalProgress.userId );
        if ( previous ) {
            previous[ "current" ] = previous.current + goalProgress.current;
            previous[ "goal" ] = {
                ...previous.goal,
                target: previous.goal.target + goalProgress.goal.target
            };
        } else {
            newGoalProgresses.push( goalProgress );
        }
    });

    return newGoalProgresses;
};

const percentage = ({ goal, current }) => {
    let value;
    if ( goal.type === "AVERAGE_SERVICE_TIME" ) {
        value = Number.parseFloat( roundNumber( ( 100 * goal.target ) / current ) || 0 ).toLocaleString();
    } else {
        value = Number.parseFloat( roundNumber( ( 100 * current ) / goal.target ) || 0 ).toLocaleString();
    }

    return `(${value}%)`;
};
const roundNumber = num => +( Math.round( num + "e+2" ) + "e-2" );

const GoalsByUser = ({ month, year }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();
    const goalProgressFormat = useGoalProgressFormat( t, priceFormat );

    const users = useSelector( userSelectors.listAllPermittedActive );
    const user = useSelector( getUser );
    const teams = useSelector( teamSelectors.listAll );
    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState( [] );
    const [ showError, setShowError ] = useState( false );
    const [ orderBy, setOrderBy ] = useState( "goal" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChangeTeam = event => {
        const value = event.target.value;
        if ( value ) {
            const teamUsers = users
                .filter( u => !!u.teams.find( item => !item.leader && item.team.id.toString() === value.toString() ) )
                .map( u => u.id );
            setUsersId( teamUsers );
        }
        setTeamId( value );
    };

    const handleChangeUsers = event => {
        setUsersId( event.target.value );
        setTeamId( "" );
    };

    const sortedResults = useMemo( () => {
        const orderResults = order(
            ( results ),
            [ orderBy ],
            [ orderType.toLowerCase() ]
        );

        return orderResults;
    }, [ results, orderBy, orderType ] );

    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const source = useMemo( () => {
        return filterGoalsByTeam( sortedResults, teamId )
            .map( progress => ({
                goal: progress.goal.name,
                current: (
                    goalProgressFormat(
                        progress.goal,
                        progress.current,
                        priceFormat
                    )
                        + " " +
                        percentage( progress )
                ),
                target: goalProgressFormat(
                    progress.goal,
                    progress.goal.target,
                    priceFormat
                )
            }));
    }, [ goalProgressFormat, priceFormat, sortedResults, teamId ] );

    const handleLoad = useCallback( () => {
        if ( isEmpty( usersId ) ) {
            setShowError( true );
            return;
        }
        dispatch( fetchResults({
            report: "GOALS_BY_USER",
            month,
            year,
            usersId: usersId.toString()
        }));
    }, [ dispatch, month, year, usersId ] );

    useEffect( () => {
        handleLoad();
    }, [ handleLoad ] );

    const model = {
        goal: t( "report:goals-by-user.goal" ),
        target: t( "report:goals-by-user.target" ),
        current: t( "report:goals-by-user.current" )
    };

    const userTeams = get( user, "teams" );
    const sourceTeams = get( user, "admin" ) ?
        teams.map( team => ({ value: team.id, label: team.name }) ) :
        sortBy(
            userTeams.map( userTeam =>
                ({ value: userTeam.team.id, label: userTeam.team.name })
            ),
            [ "label" ] );

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.GOALS_BY_USER" ) }
                subheader={ t( "report:goals-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.padding, cssUtils.marginBottomSmall
                )}
            >
                <Dropdown
                    label={ isEmpty( sourceTeams ) ?
                        t( "report:filters.empty-team" ) :
                        t( "report:filters.team" )
                    }
                    fullWidth={ false }
                    className={ flex.fill }
                    onChange={ handleChangeTeam }
                    value={ teamId }
                    source={ sourceTeams }
                    disabled={ isEmpty( sourceTeams ) }
                    required
                    error={ showError && isEmpty( usersId ) }
                />
                <div className={ classnames( cssUtils.marginLeft, flex.fill ) }>
                    <DropdownMultiple
                        label={ t( "report:filters.users" ) }
                        source={
                            users.map( user => ({
                                value: user.id,
                                label: user.name
                            }))
                        }
                        input={{ onChange: handleChangeUsers, value: usersId }}
                        required
                        error={ showError && isEmpty( usersId ) }
                    />
                </div>
                <Button className={ cssUtils.marginLeftSmall } onClick={ handleLoad }>
                    { t( "report:filters.apply-filter" ) }
                </Button>
            </Paper>
            {
                loading ?
                    <LinearProgress className={ flex.flexGrow }/> :
                    <div className={ classnames( flex.container, flex.column, flex.alignItemsStart ) }>
                        {
                            results && results.map( user => (
                                <div
                                    key={ user.id }
                                    className={ classnames( flex.container, flex.fill, cssUtils.marginBottom ) }
                                >
                                    <div className={ classnames( flex.fill, cssUtils.marginRightSmall ) }>
                                        <GoalProgress
                                            chartId="goalsByUserChart"
                                            statistics={{ goalProgresses: user.goalProgresses }}
                                            title={ user.name }
                                            usersId={ usersId }
                                        />
                                    </div>
                                    <div
                                        className={ flex.fill }
                                    >
                                        <Table
                                            id="goalsByUserTable"
                                            allowOrder
                                            onChangePage={ changeOrder }
                                            orderBy={ orderBy }
                                            orderType={ orderType }
                                            allowExport
                                            model={ model }
                                            source={ order( user.goalProgresses.map( progress => ({
                                                goal: progress.goal.name,
                                                current: (
                                                    goalProgressFormat(
                                                        progress.goal,
                                                        progress.current,
                                                        priceFormat
                                                    )
                                                    + " " +
                                                    percentage( progress )
                                                ),
                                                target: goalProgressFormat(
                                                    progress.goal,
                                                    progress.goal.target,
                                                    priceFormat
                                                )
                                            })), [ orderBy ], [ orderType.toLowerCase() ])}
                                            showTitle
                                            title={ user.name }
                                        />
                                    </div>
                                </div>
                            ))
                        }
                        {
                            results &&
                                <div
                                    className={ classnames( flex.container, flex.fill, cssUtils.marginBottom ) }
                                >
                                    <div className={ classnames( flex.fill, cssUtils.marginRightSmall ) }>
                                        <GoalProgress
                                            chartId="goalsByUserAllChart"
                                            statistics={{
                                                goalProgresses: filterGoalsByTeam( results, teamId )
                                            }}
                                            title={ t( "report:goals-by-user.all" ) }
                                        />
                                    </div>
                                    <div
                                        className={ flex.fill }
                                    >
                                        <Table
                                            id="goalsByUserAllTable"
                                            allowExport
                                            model={ model }
                                            source={ source }
                                            showTitle
                                            title={ t( "report:goals-by-user.all" ) }
                                        />
                                    </div>
                                </div>
                        }
                    </div>
            }
        </div>
    );
};

export default GoalsByUser;