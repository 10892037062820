import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Link, cssUtils, flex } from "../ui";
import css from "./customer.scss";
import { CUSTOMER_AVATAR_FORM } from "./customer.constants";
import { isLoadingSelected, getSelected } from "./customer.selectors";
import CustomerAvatar from "./customer-avatar.component";
import CustomerTags from "./customer-tags.component";
import CustomerFollowing from "./customer-following.component";
import CustomerSummary from "./customer-summary.component";
import { CustomerCompany } from "./company";
import { CustomerPersons } from "./person";
import { fetchForm } from "../form/form.actions";

const CustomerHead = ({ alignPopupLeft, opportunity, onEdit }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );
    const loading = useSelector( isLoadingSelected );
    const submittingAvatar = useSelector( isSubmitting( CUSTOMER_AVATAR_FORM ) );

    useEffect( () => {
        dispatch( fetchForm( "COMPANY" ) );
        dispatch( fetchForm( "PERSON" ) );
    }, [ dispatch ] );

    if ( loading || !customer ) {
        return <div className={ cssUtils.textCenter }><CircularProgress/></div>;
    }

    return (
        <>
            <Card id="customerHead">
                <CardHeader
                    className={ customer.type === "PERSON" ? css.headerPerson : css.headerCompany }
                    classes={{
                        content: css.headerContent
                    }}
                    avatar={
                        submittingAvatar ?
                            <CircularProgress className={ css.loadingAvatar }/> :
                            <Link to={ "/customers/" + customer.id } className={ cssUtils.cursorPointer }>
                                <Badge
                                    classes={{ badge: css.avatarTypeContainer }}
                                    badgeContent={
                                        <Tooltip
                                            title={
                                                customer.type === "PERSON" ?
                                                    t( "customer:person.title" ) :
                                                    t( "customer:company.title" )
                                            }
                                        >
                                            <Avatar className={ css.avatarType }>
                                                {
                                                    customer.type === "PERSON" ?
                                                        <PersonIcon/> :
                                                        <BusinessIcon/>
                                                }
                                            </Avatar>
                                        </Tooltip>
                                    }
                                >
                                    <Avatar src={ customer.avatar }>
                                        {
                                            customer.avatar ? "" : customer.name.charAt( 0 ).toUpperCase()
                                        }
                                    </Avatar>
                                </Badge>
                            </Link>
                    }
                    action={ <CustomerAvatar/> }
                    title={ customer.name }
                />

                <div id="customerTags" className={ classnames( flex.container, flex.column ) }>
                    <CustomerTags className={ css.tags } />
                </div>

                <List dense>
                    <Divider />
                    <CustomerSummary customer={ customer } alignPopupLeft={ alignPopupLeft }/>
                </List>

                <CardActions>
                    <div className={ flex.fill }>
                        <Button size="small" onClick={ onEdit }
                            startIcon={ <EditIcon/> }
                        >
                            { t( "customer:head.edit" ) }
                        </Button>
                    </div>
                    <CustomerFollowing customerId={ customer.id } />
                </CardActions>
            </Card>

            <hr/>
            {
                customer.type === "PERSON" ?
                    <CustomerCompany customer={ customer } opportunity={ opportunity }/> :
                    <CustomerPersons customer={ customer } opportunity={ opportunity }/>
            }
        </>
    );
};

export default CustomerHead;