
import * as actions from "./form.actions";
import { actions as formActions } from "../../../form";


export const reorderFields = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
};

export function formConfig ( state = {}, action ) {
    switch ( action.type ) {
        case actions.INIT_FORM:
            return {
                form: action.meta.form
            };

        case formActions.RECEIVE_FORM:
            return !state.form ? state : {
                ...state,
                selectedIndex: null,
                sorting: false,
                unsortedFields: undefined,
                fields: action.payload
            };

        case actions.NEW_FIELD:
            let selectedIndex = state.fields.length;
            const field = action.payload;

            if ( selectedIndex > 0 ) {
                const lastField = state.fields[ selectedIndex - 1 ];

                field.previousFieldId = lastField.id ? lastField.id : lastField.previousFieldId;

                if ( !lastField.id ) {
                    state.fields.pop();
                    selectedIndex = selectedIndex - 1;
                }

            } else {
                field.previousFieldId = null;
            }

            return {
                ...state,
                selectedIndex: selectedIndex,
                fields: state.fields.concat( field )
            };

        case actions.SELECT_FIELD:
            const sameIndex = action.payload.index === state.selectedIndex;
            const deselectedState = formConfig( state, { type: actions.DESELECT_FIELD });
            return Object.assign( {}, sameIndex ? state : deselectedState, {
                selectedIndex: action.payload.index,
                usedValues: null
            });

        case actions.DESELECT_FIELD:
            const current = state.fields[ state.selectedIndex ];
            return {
                ...state,
                selectedIndex: null,
                fields: !current || current.id ? state.fields : state.fields.slice( 0, -1 )
            };

        case actions.SORT_FIELD:
            const { oldIndex, newIndex } = action.payload;
            const unsortedFields = state.unsortedFields || state.fields;
            return oldIndex === newIndex ? state : {
                ...state,
                unsortedFields,
                sorting: true,
                fields: reorderFields( state.fields, oldIndex, newIndex )
            };

        case actions.CANCEL_SORTING:
            return {
                ...state,
                unsortedFields: undefined,
                fields: state.unsortedFields,
                sorting: false
            };

        case actions.REQUEST_USED_VALUES:
            return {
                ...state,
                loading: true
            };

        case actions.RECEIVE_USED_VALUES:
            const { usedValues } = action.payload;
            return {
                ...state,
                loading: false,
                usedValues
            };

        case actions.ERROR_USED_VALUES:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}