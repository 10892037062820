import { Field, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import { useEffect, memo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import DialogContentText from "@mui/material/DialogContentText";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import isEmpty from "lodash/isEmpty";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { getLoggedHolding } from "../core/core.selectors";
import { fetchHolding } from "../config/advanced/holding/holding.actions";
import { DialogForm, Dropdown } from "../ui";
import { fetchHoldings, fetchUserSubscription, loginHolding, logoutHolding } from "./layout.actions";
import { SELECT_HOLDING_SUBSCRIPTION_FORM } from "./layout.constants";
import { getHoldings, getUserSubscription, isLoadingHoldings, isLoadingUserSubscription } from "./layout.selectors";
import { getSubscription } from "../subscription/subscription.selectors";
import css from "./top-navbar.scss";

const SelectHoldingSubscription = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ open, setOpen ] = useState( false );

    const logged = useSelector( getLoggedHolding );
    const loadingHoldings = useSelector( isLoadingHoldings );
    const loadingUserSubscription = useSelector( isLoadingUserSubscription );
    const holdings = useSelector( getHoldings );
    const subscription = useSelector( getSubscription );
    const userSubscription = useSelector( getUserSubscription );

    const loading = loadingHoldings || loadingUserSubscription;

    const formik = useForm({
        enableReinitialize: true,
        form: SELECT_HOLDING_SUBSCRIPTION_FORM,
        initialValues: {
            token: logged ? logged.token : "",
        },
        onSubmit: values => dispatch( loginHolding( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.token ) {
                errors.token = "common:validation.selection";
            }
            return errors;
        }
    });

    const { resetForm, submitting } = formik;

    const handleLogout = () => dispatch( logoutHolding() );

    const handleOpen = () => {
        dispatch( fetchHolding() );
        dispatch( fetchUserSubscription() );
        setOpen( true );
    };

    const handleClose = useCallback( () => {
        setOpen( false );
        resetForm();
    }, [ resetForm ] );

    useEffect( () => {
        dispatch( fetchHoldings() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( submitting ) {
            window.location.href = history.createHref({});
        }
    }, [ submitting ] );

    return (
        <>
            {
                !user.admin || loading || isEmpty( holdings ) ?
                    <Chip
                        className={ css.subscriptionChip }
                        label={ subscription.name }
                        icon={ <LocationCityIcon/> }
                    /> :
                    <Tooltip title={ t( "layout:select-holding.title" ) }>
                        <Chip
                            className={ css.subscriptionChip }
                            disabled={ loading }
                            label={ subscription.name }
                            onClick={ handleOpen }
                            icon={ <LocationCityIcon/> }
                        />
                    </Tooltip>
            }
            <DialogForm
                open={ open }
                onClose={ handleClose }
                handleSubmit={ formik.handleSubmit }
                submitForm={ formik.submitForm }
                submitting={ submitting }
                messages={{
                    submit: t( "common:enter" )
                }}
                title={ t( "layout:select-holding.title" ) }
            >
                <DialogContentText>
                    { t( "layout:select-holding.call-to-action" ) }
                </DialogContentText>
                { loading && <LinearProgress/> }
                {
                    !isEmpty( holdings ) && !isEmpty( logged ) && !isEmpty( userSubscription ) &&
                        <>
                            <ListItemButton component="div" dense onClick={ handleLogout } disabled={ submitting }>
                                <ListItemIcon>
                                    <ArrowBackIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={ t( "layout:select-holding.logout" ) }
                                    secondary={ userSubscription.name }
                                />
                            </ListItemButton>
                            <hr />
                        </>
                }
                <FormikProvider value={ formik }>
                    <Field
                        name="token"
                        label={ t( "layout:select-holding.subscription" ) }
                        component={ Dropdown }
                        source={ holdings?.map( option => ({ value: option.token, label: option.name })) }
                        disabled={ isEmpty( holdings ) }
                        helperText={ !loading && isEmpty( holdings ) && t( "layout:select-holding.empty-tokens" ) }
                        required
                    />
                </FormikProvider>
            </DialogForm>
        </>
    );

};

export default memo( SelectHoldingSubscription );