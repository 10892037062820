import { memo, useCallback, useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CancelIcon from "@mui/icons-material/Cancel";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils } from "./";
import DropdownMultipleSubheader from "./dropdown-multiple-subheader.component";
import css from "./dropdown-multiple.scss";

const DropdownMultiple = ({
    className,
    error,
    id,
    field,
    label,
    margin,
    required,
    form: { getFieldMeta, setFieldValue } = {},
    fullWidth = true,
    source = [],
    sort = true,
    emptyValue = false,
    helperText,
    variant,
    whiteSpaceNormal = true,
    onChange,
    ...props
}) => {
    const t = useTranslate();

    const name = useMemo( () => field?.name, [ field ] );

    const handleClearValue = useCallback( () => {
        if ( setFieldValue ) {
            setFieldValue( name, [] );
        } else if ( onChange ) {
            onChange({ target: { value: [] } });
        }
    }, [ name, setFieldValue, onChange ] );

    const validationMsg = useMemo( () => {
        if ( name && getFieldMeta ) {
            const fieldMeta = getFieldMeta( name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ name, getFieldMeta ] );

    const value = useMemo( () => {
        return field ? ( field.value || [] ) : props.value ? props.value : [];
    }, [ field, props.value ] );

    let endAdornment;
    const hasValue = !isEmpty( value );
    if ( emptyValue && hasValue ) {
        endAdornment = (
            <InputAdornment position="end" className={ cssUtils.marginRight }>
                <IconButton onClick={ handleClearValue } size="small">
                    <CancelIcon/>
                </IconButton>
            </InputAdornment>
        );
    }

    return (
        <FormControl
            className={ className }
            id={ id }
            margin={ margin }
            error={ error || !!validationMsg }
            fullWidth={ fullWidth }
            required={ required }
            variant={ variant }
        >
            { label && <InputLabel>{ label }</InputLabel> }
            <Select
                onChange={ onChange }
                { ...props }
                { ...field }
                classes={{ root: whiteSpaceNormal ? css.select : null }}
                endAdornment={ endAdornment }
                label={ label }
                value={ value }
                multiple
                renderValue={ selected => {
                    if ( !selected || isEmpty( source ) ) {
                        return "";
                    }

                    return selected
                        .map( value => source.find( option => option.value === value ) )
                        .filter( option => !!option )
                        .map( option => option.label )
                        .join( ", " );
                }}
                MenuProps={{ variant: "menu" }}
            >
                {
                    ( sort ? source.sort( ( a, b ) => a.label.localeCompare( b.label ) ) : source )
                        .map( ( option, index ) =>
                            option.header ?
                                <DropdownMultipleSubheader key={ index } option={ option } /> :
                                <MenuItem
                                    key={ index }
                                    value={ option.value }
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            className={ css.menuCheckbox }
                                            checked={ value.includes( option.value ) }
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={ option.label }/>
                                </MenuItem>
                        )
                }
            </Select>
            { helperText && <FormHelperText>{ helperText }</FormHelperText> }
            { validationMsg && <FormHelperText>{ t( validationMsg ) }</FormHelperText> }
        </FormControl>
    );
};

export default memo( DropdownMultiple );