import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import * as actions from "./sms.actions";

export function *watchIntegrationsSms () {
    yield all([
        takeEvery( actions.FETCH_SMS_STATISTICS, fetchSmsStatistics ),
    ]);
}

export function *fetchSmsStatistics () {
    yield put( actions.requestSmsStatistics() );

    try {
        const response = yield call( apiv1.get, "/sms/statistics" );
        yield put( actions.receiveSmsStatistics( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSmsStatistics( e.response.data ) );
    }
}