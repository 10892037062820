import Fab from "@mui/material/Fab";
import css from "./fab.scss";

export default ( { children, position, ...props } ) => (
    <Fab
        { ...props }
        className={ position && css[ position ] }
    >
        { children }
    </Fab>
);
