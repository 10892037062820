import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./template.actions";

export function *fetchTemplates () {
    yield put( actions.requestTemplates() );
    try {
        const response = yield call( apiv1.get, "/templates" );
        yield put( actions.receiveTemplates( response.data ) );
    } catch ( e ) {
        yield put( actions.errorTemplates( e.response.data ) );
    }
}

export function *fetchImages () {
    yield put( actions.requestImages() );
    try {
        const response = yield call( apiv1.get, "/images" );
        yield put( actions.receiveImages( response.data ) );
    } catch ( e ) {
        yield put( actions.errorImages( e.response.data ) );
    }
}

export function *watchTemplates () {
    yield all([
        takeEvery( actions.FETCH_TEMPLATES, fetchTemplates ),
        takeEvery( actions.FETCH_IMAGES, fetchImages )
    ]);
}