import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const OpportunitiesBySource = ({ initialDate, finalDate, }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const changeOrder = ( orderBy, orderType ) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const allChartData = useMemo( () => {
        if ( !results ) {
            return null;
        }
        const series = [{
            name: t( "report:opportunities-by-source.created" ),
            data: results.map( item => item.created ),
            color: "#1565C0",
        }, {
            name: t( "report:opportunities-by-source.sold" ),
            data: results.map( item => item.sold ),
            color: "#4caf50",
        }, {
            name: t( "report:opportunities-by-source.lost" ),
            data: results.map( item => item.lost ),
            color: "#f44336",
        }];

        return {
            title: {
                text: t( "report:reports.OPPORTUNITIES_BY_SOURCE" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "report:reports.OPPORTUNITIES_BY_SOURCE" );
                    }
                },
            },
            xAxis: {
                categories: results.map( item => item.name ),
                crosshair: true
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: ""
                }
            },
            tooltip: {
                headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
                pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
                "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
                footerFormat: "</table>",
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series,
        };
    }, [ t, results ] );

    const conversionRateChart = useMemo( () => {
        if ( !results ) {
            return null;
        }
        return {
            title: {
                text: t( "report:opportunities-by-source.rate" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "report:opportunities-by-source.rate" );
                    }
                },
            },
            tooltip: {
                formatter: function () {
                    return this.x + ": " + ( +( Math.round( this.y + "e+2" ) + "e-2" ) ) + "%";
                }
            },
            xAxis: {
                categories: results.map( item => item.name ),
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ""
                }
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    name: t( "report:opportunities-by-source.rate" ),
                    data: results.map( item => item.conversionRate ),
                    color: "#1565C0",
                }
            ]
        };
    }, [ t, results ] );
    const priceChart = useMemo( () => {
        if ( !results ) {
            return null;
        }
        const series = [{
            name: t( "report:opportunities-by-source.value" ),
            data: results.map( item => item.soldPrice ),
            color: "#4caf50",
        }, {
            name: t( "report:opportunities-by-source.recurrent-value" ),
            data: results.map( item => item.soldRecurrentPrice ),
            color: "#2E7D32",
        }];

        return {
            title: {
                text: t( "report:opportunities-by-source.sold-value" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "report:opportunities-by-source.sold-value" );
                    }
                },
            },
            xAxis: {
                categories: results.map( item => item.name ),
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ""
                },
                labels: {
                    formatter: function () {
                        return priceFormat( this.value );
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return this.series.name + ": " + priceFormat( this.y );
                }
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return priceFormat( this.y );
                        }
                    },
                }
            },
            series,
        };
    }, [ t, priceFormat, results ] );

    const sourceCharts = useMemo( () => {
        if ( !results ) {
            return null;
        }

        return results.map( item => ({
            title: {
                text: item.name
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "report:reports.OPPORTUNITIES_BY_SOURCE" );
                    }
                },
            },
            xAxis: {
                categories: results.map( item => item.name ),
                crosshair: true
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: ""
                }
            },
            series: [
                {
                    name: t( "report:opportunities-by-source.created" ),
                    data: [ item.created ],
                    color: "#1565C0",
                }, {
                    name: t( "report:opportunities-by-source.sold" ),
                    data: [ item.sold ],
                    color: "#4caf50",
                }, {
                    name: t( "report:opportunities-by-source.lost" ),
                    data: [ item.lost ],
                    color: "#f44336",
                }
            ]
        }));
    }, [ t, results ] );

    const model = {
        name: t( "report:opportunities-by-source.source" ),
        created: t( "report:opportunities-by-source.created" ),
        lostSold: `${t( "report:opportunities-by-source.lost" )} / ${t( "report:opportunities-by-source.sold-rate" )}`,
        soldPrice: {
            title: t( "report:opportunities-by-source.value" ),
            format: priceFormat,
        },
        soldRecurrentPrice: {
            title: t( "report:opportunities-by-source.recurrent-value" ),
            format: priceFormat,
        }
    };

    const source = useMemo( () => {
        if ( !results ) {
            return [];
        }
        const items = results.map( item => ( {
            ...item,
            lostSold: `${item.lost} / ${item.sold} (${item.conversionRate})%`,
        }));
        let orderByKey;
        switch ( orderBy ) {
            case "lostSold":
                orderByKey = "conversionRate";
                break;
            default:
                orderByKey = orderBy;
                break;
        }

        return order( items, [ orderByKey ], [ orderType.toLowerCase() ] );
    }, [ orderBy, orderType, results ]);

    const footer = useMemo( () => {
        if ( !results ) {
            return {};
        }
        const lost = results.reduce( ( acc, item ) => acc + item.lost, 0 );
        const sold = results.reduce( ( acc, item ) => acc + item.sold, 0 );
        const totalFinished = lost + sold;
        let conversionRate = 0;
        if ( totalFinished > 0 ) {
            conversionRate = +( Math.round( ( ( 100 * sold ) / ( lost + sold ) ) + "e+2" ) + "e-2" );
        }
        return {
            name: t( "report:opportunities-by-source.total" ),
            created: results.reduce( ( acc, item ) => acc + item.created, 0 ),
            lostSold: `${lost} / ${sold} (${conversionRate}%)`,
            soldPrice: results.reduce( ( acc, item ) => acc + item.soldPrice, 0 ),
            soldRecurrentPrice: results.reduce( ( acc, item ) => acc + item.soldRecurrentPrice, 0 ),
        };
    }, [ t, results ] );

    return (
        <div className={ classnames( cssUtils.marginTopSmall, cssUtils.overflowHidden ) }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITIES_BY_SOURCE" ) }
                subheader={ t( "report:opportunities-by-source.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels" ]}
                    required={[ "funnels" ]}
                    report={ "OPPORTUNITIES_BY_SOURCE" }
                    initialDate= { initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                />
            </Paper>
            { loading && <LinearProgress /> }

            <div className={ classnames( flex.container ) }>
                <div className={ classnames( flex.fill, flex.container, flex.column, flex.rowGapSmall ) }>
                    {
                        allChartData &&
                            <Chart
                                id="opportunitiesBySourceTotalsChart"
                                data={ allChartData }
                                type="column"
                            />
                    }
                    {
                        conversionRateChart &&
                            <Chart
                                id="opportunitiesBySourceRateChart"
                                data={ conversionRateChart }
                                type="column"
                            />
                    }
                    {
                        priceChart &&
                            <Chart
                                id="opportunitiesBySourcePricesChart"
                                data={ priceChart }
                                type="column"
                            />
                    }
                    {
                        sourceCharts?.map( ( sourceChart, index ) => (
                            <Chart
                                key={ index }
                                id={ `opportunitiesBySource${index}` }
                                data={ sourceChart }
                                type="column"
                            />
                        ))
                    }
                </div>
                <div className={ flex.fill }>
                    {
                        !isEmpty( results ) &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="opportunitiesBySourceOriginTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    footer={ footer }
                                    title={ t( "report:reports.OPPORTUNITIES_BY_SOURCE" ) }
                                    allowOrder
                                    onChangePage={ ({ orderBy, orderType }) => changeOrder( orderBy, orderType ) }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OpportunitiesBySource;