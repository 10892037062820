import { useSelector } from "react-redux";
import CalendarEvents from "react-calendar-events";
import DayJsUtils from "@date-io/dayjs";

import useTranslate from "../../hooks/use-translate.hook";
import { getThemeMode } from "../../core/core.selectors";
import Toolbar from "./calendar-toolbar.component";
import css from "./calendar.scss";

const Calendar = ({ components, ...props }) => {
    const t = useTranslate();

    const theme = useSelector( getThemeMode );

    const culture = t( "ui:calendar.culture" );
    const timeRangeFormat = ( adapter, { start, end } ) =>
        `${adapter.format( start, "hh:mm")} — ${adapter.format( end, "hh:mm" )}`;
    const timeRangeStartFormat = ( adapter, { start } ) =>
        `${adapter.format( start, "hh:mm" )}  — `;
    const timeRangeEndFormat = ( adapter, { end } ) =>
        ` —  ${adapter.format( end, "hh:mm" )}`;
    const dayRangeHeaderFormat = ( adapter, { start, end } ) => (
        adapter.format( start, "MMMM D" ) +
        " - " +
        (
            start.getMonth() === end.getMonth() ?
                adapter.format( end, "D" ) :
                adapter.format( end, "MMMM D" )
        )
    );

    return (
        <CalendarEvents
            adapter={ DayJsUtils }
            className={ css.calendar }
            selectable
            popup
            culture={ culture }
            messages={{
                allDay: t( "ui:calendar.all-day" ),
                showMore: total => t( "ui:calendar.show-more", { total } )
            }}
            components={{
                toolbar: Toolbar,
                ...components
            }}
            views={[ "month", "week", "day" ]}
            formats={{
                dateFormat: "DD",
                agendaDateFormat: "ddd MMM DD",
                agendaTimeFormat: "hh:mm",
                timeGutterFormat: "hh:mm",
                dayFormat: "ddd DD/MM",
                dayHeaderFormat: "dddd, MMMM D",
                dayRangeHeaderFormat,
                selectRangeFormat: timeRangeFormat,
                eventTimeRangeFormat: timeRangeFormat,
                eventTimeRangeStartFormat: timeRangeStartFormat,
                eventTimeRangeEndFormat: timeRangeEndFormat,
            }}
            theme={ theme }
            { ...props }
        />
    );
};

export default Calendar;