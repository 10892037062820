// ---------------------------------------------------------------------------------------------------------------------
// Customer list
// ---------------------------------------------------------------------------------------------------------------------
export const SET_CUSTOMER_LIST_TYPE = "x/SET_CUSTOMER_LIST_TYPE";
export function setCustomerListType ( type ) {
    return {
        type: SET_CUSTOMER_LIST_TYPE,
        payload: type
    };
}
export const FETCH_CUSTOMERS_LIST = "x/FETCH_CUSTOMERS_LIST";
export function fetchCustomersList ( data ) {
    return {
        type: FETCH_CUSTOMERS_LIST,
        payload: data
    };
}

export const REQUEST_CUSTOMERS_LIST = "x/REQUEST_CUSTOMERS_LIST";
export function requestCustomersList () {
    return {
        type: REQUEST_CUSTOMERS_LIST
    };
}

export const RECEIVE_CUSTOMERS_LIST = "x/RECEIVE_CUSTOMERS_LIST";
export function receiveCustomersList ( data ) {
    return {
        type: RECEIVE_CUSTOMERS_LIST,
        payload: data
    };
}

export const ERROR_CUSTOMERS_LIST = "x/ERROR_CUSTOMERS_LIST";
export function errorCustomersList ( error ) {
    return {
        type: ERROR_CUSTOMERS_LIST,
        error
    };
}

export const FETCH_SEARCH_CUSTOMERS = "x/FETCH_SEARCH_CUSTOMERS";
export function fetchSearchCustomers ( data ) {
    return {
        type: FETCH_SEARCH_CUSTOMERS,
        payload: data
    };
}

export const REQUEST_SEARCH_CUSTOMERS = "x/REQUEST_SEARCH_CUSTOMERS";
export function requestSearchCustomers () {
    return {
        type: REQUEST_SEARCH_CUSTOMERS
    };
}

export const RECEIVE_SEARCH_CUSTOMERS = "x/RECEIVE_SEARCH_CUSTOMERS";
export function receiveSearchCustomers ( data ) {
    return {
        type: RECEIVE_SEARCH_CUSTOMERS,
        payload: data
    };
}

export const ERROR_SEARCH_CUSTOMERS = "x/ERROR_SEARCH_CUSTOMERS";
export function errorSearchCustomers ( error ) {
    return {
        type: ERROR_SEARCH_CUSTOMERS,
        error
    };
}

export const CREATE_EXPORTATION = "x/CREATE_CUSTOMER_EXPORTATION";
export const createExportation = data => ({
    type: CREATE_EXPORTATION,
    payload: data
});

export const GENERATE_OPPORTUNITIES = "x/GENERATE_OPPORTUNITIES";
export const generateOpportunities = data => ({
    type: GENERATE_OPPORTUNITIES,
    payload: data
});

export const UPDATE_CUSTOMERS = "x/UPDATE_CUSTOMERS";
export const updateCustomers = data => ({
    type: UPDATE_CUSTOMERS,
    payload: data
});

// ---------------------------------------------------------------------------------------------------------------------
// Single customer
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_CUSTOMER = "x/FETCH_CUSTOMER";
export const fetchCustomer = id => ({
    type: FETCH_CUSTOMER,
    meta: { id }
});

export const REQUEST_CUSTOMER = "x/REQUEST_CUSTOMER";
export const requestCustomer = () => ({
    type: REQUEST_CUSTOMER
});

export const SET_CUSTOMER = "x/SET_CUSTOMER";
export const setCustomer = data => ({
    type: SET_CUSTOMER,
    payload: data
});

export const ERROR_CUSTOMER = "x/ERROR_CUSTOMER";
export const errorCustomer = error => ({
    type: ERROR_CUSTOMER,
    error: true,
    payload: error
});

export const SAVE_CUSTOMER = "x/SAVE_CUSTOMER";
export const saveCustomer = customer => ({
    type: SAVE_CUSTOMER,
    meta: { id: customer.id },
    payload: customer
});

export const PATCH_CUSTOMER = "x/PATCH_CUSTOMER";
export const patchCustomer = customer => ({
    type: PATCH_CUSTOMER,
    meta: { id: customer.id },
    payload: customer
});

export const FETCH_STATISTICS = "x/FETCH_CUSTOMER_STATISTICS";
export const fetchStatistics = id => ({
    type: FETCH_STATISTICS,
    meta: { id }
});

export const REQUEST_STATISTICS = "x/REQUEST_CUSTOMER_STATISTICS";
export const requestStatistics = () => ({
    type: REQUEST_STATISTICS
});

export const RECEIVE_STATISTICS = "x/RECEIVE_CUSTOMER_STATISTICS";
export const receiveStatistics = data => ({
    type: RECEIVE_STATISTICS,
    payload: data
});

export const ERROR_STATISTICS = "x/ERROR_CUSTOMER_STATISTICS";
export const errorStatistics = error => ({
    type: ERROR_STATISTICS,
    error: true,
    payload: error
});

export const FETCH_TIMELINE = "x/FETCH_CUSTOMER_TIMELINE";
export const fetchTimeline = ({ id, ...params }) => ({
    type: FETCH_TIMELINE,
    meta: { id },
    payload: params
});

export const REQUEST_TIMELINE = "x/REQUEST_CUSTOMER_TIMELINE";
export const requestTimeline = () => ({
    type: REQUEST_TIMELINE
});

export const RECEIVE_TIMELINE = "x/RECEIVE_CUSTOMER_TIMELINE";
export const receiveTimeline = data => ({
    type: RECEIVE_TIMELINE,
    payload: data
});

export const ERROR_TIMELINE = "x/ERROR_CUSTOMER_TIMELINE";
export const errorTimeline = error => ({
    type: ERROR_TIMELINE,
    error: true,
    payload: error
});

export const RELOAD_TIMELINE = "x/RELOAD_CUSTOMER_TIMELINE";
export const reloadTimeline = () => ({
    type: RELOAD_TIMELINE
});

export const SAVE_COMMENT = "x/SAVE_CUSTUMER_TIMELINE_COMENT";
export const saveComment = comment => ({
    type: SAVE_COMMENT,
    meta: { id: comment.customerId },
    payload: comment
});

export const SAVE_AVATAR = "x/SAVE_CUSTOMER_AVATAR";
export const saveAvatar = ({ id, avatar }) => ({
    type: SAVE_AVATAR,
    meta: { id },
    payload: { avatar }
});

export const SAVE_FILE = "x/SAVE_CUSTOMER_FILE";
export const saveFile = ({ customerId, ...data }) => ({
    type: SAVE_FILE,
    meta: { id: customerId },
    payload: data
});

export const FETCH_FILES = "x/FETCH_CUSTOMER_FILES";
export const fetchFiles = id => ({
    type: FETCH_FILES,
    meta: { id }
});

export const REQUEST_FILES = "x/REQUEST_CUSTOMER_FILES";
export const requestFiles = () => ({
    type: REQUEST_FILES
});

export const RECEIVE_FILES = "x/RECEIVE_CUSTOMER_FILES";
export const receiveFiles = data => ({
    type: RECEIVE_FILES,
    payload: data
});

export const ERROR_FILES = "x/ERROR_CUSTOMER_FILES";
export const errorFiles = error => ({
    type: ERROR_FILES,
    error: true,
    payload: error
});

export const DELETE_FILE = "x/DELETE_CUSTOMER_FILE";
export const deleteFile = ({ id, ...file }) => ({
    type: DELETE_FILE,
    meta: { id },
    payload: file
});

export const DELETE_CUSTOMER = "x/DELETE_CUSTOMER";
export const deleteCustomer = ({ id }) => ({
    type: DELETE_CUSTOMER,
    meta: { id }
});

// ---------------------------------------------------------------------------------------------------------------------
// Person
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_PERSONS = "x/FETCH_CUSTOMER_PERSONS";
export const fetchPersons = customerId => ({
    type: FETCH_PERSONS,
    meta: { id: customerId }
});

export const REQUEST_PERSONS = "x/REQUEST_CUSTOMER_PERSONS";
export const requestPersons = () => ({
    type: REQUEST_PERSONS
});

export const RECEIVE_PERSONS = "x/RECEIVE_CUSTOMER_PERSONS";
export const receivePersons = persons => ({
    type: RECEIVE_PERSONS,
    payload: persons
});

export const ERROR_PERSONS = "x/ERROR_CUSTOMER_PERSONS";
export const errorPersons = error => ({
    type: ERROR_PERSONS,
    error: true,
    payload: error
});

export const DELETE_PERSON = "x/DELETE_CUSTOMER_PERSON";
export const deletePerson = ({ id, company, opportunity }) => ({
    type: DELETE_PERSON,
    meta: { id },
    payload: { company, opportunity }
});

export const DELETING_PERSON = "x/DELETING_CUSTOMER_PERSON";
export const deletingPerson = deleting => ({
    type: DELETING_PERSON,
    payload: deleting
});

export const SAVE_PERSON = "x/SAVE_CUSTOMER_PERSON";
export const savePerson = ({ id, person }) => ({
    type: SAVE_PERSON,
    meta: { id },
    payload: { person }
});

export const SAVE_COMPANY = "x/SAVE_CUSTOMER_COMPANY";
export const saveCompany = ({ id, company }) => ({
    type: SAVE_COMPANY,
    meta: { id },
    payload: { company }
});

// ---------------------------------------------------------------------------------------------------------------------
// Following
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_FOLLOWING = "x/FETCH_CUSTOMER_FOLLOWING";
export const fetchFollowing = id => ({
    type: FETCH_FOLLOWING,
    meta: { id }
});

export const REQUEST_FOLLOWING = "x/REQUEST_CUSTOMER_FOLLOWING";
export const requestFollowing = () => ({
    type: REQUEST_FOLLOWING
});

export const RECEIVE_FOLLOWING = "x/RECEIVE_CUSTOMER_FOLLOWING";
export const receveiFollowing = following => ({
    type: RECEIVE_FOLLOWING,
    payload: following
});

export const ERROR_FOLLOWING = "x/ERROR_CUSTOMER_FOLLOWING";
export const errorFollowing = error => ({
    type: ERROR_FOLLOWING,
    error: true,
    payload: error
});

export const SAVE_FOLLOWING = "x/SAVE_CUSTOMER_FOLLOWING";
export const saveFollowing = data => ({
    type: SAVE_FOLLOWING,
    meta: { id: data.id },
    payload: { following: data.following }
});

// ---------------------------------------------------------------------------------------------------------------------
// Tags
// ---------------------------------------------------------------------------------------------------------------------
export const REQUEST_TAGS = "x/REQUEST_CUSTOMER_TAGS";
export const requestTags = () => ({
    type: REQUEST_TAGS
});

export const FETCH_TAGS = "x/FETCH_CUSTOMER_TAGS";
export const fetchTags = search => ({
    type: FETCH_TAGS,
    payload: { search }
});

export const RECEIVE_TAGS = "x/RECEIVE_CUSTOMER_TAGS";
export const receiveTags = tags => ({
    type: RECEIVE_TAGS,
    payload: tags
});

export const ERROR_TAGS = "x/ERROR_CUSTOMER_TAGS";
export const errorTags = error => ({
    type: ERROR_TAGS,
    error: true,
    payload: error
});

// ---------------------------------------------------------------------------------------------------------------------
// Customer opportunities
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_OPPORTUNITIES = "x/FETCH_CUSTOMER_OPPORTUNITIES";
export const fetchOpportunities = customerId => ({
    type: FETCH_OPPORTUNITIES,
    meta: { id: customerId }
});

export const REQUEST_OPPORTUNITIES = "x/REQUEST_CUSTOMER_OPPORTUNITIES";
export const requestOpportunities = () => ({
    type: REQUEST_OPPORTUNITIES
});

export const RECEIVE_OPPORTUNITIES = "x/RECEIVE_CUSTOMER_OPPORTUNITIES";
export const receiveOpportunities = opportunities => ({
    type: RECEIVE_OPPORTUNITIES,
    payload: opportunities
});

export const ERROR_OPPORTUNITIES = "x/ERROR_CUSTOMER_OPPORTUNITIES";
export const errorOpportunities = error => ({
    type: ERROR_OPPORTUNITIES,
    error: true,
    payload: error
});

export const OPEN_NEW_OPPORTUNITY = "x/OPEN_NEW_CUSTOMER_OPPORTUNITY";
export const openNewOpportunity = () => ({
    type: OPEN_NEW_OPPORTUNITY
});

export const CLOSE_NEW_OPPORTUNITY = "x/CLOSE_NEW_CUSTOMER_OPPORTUNITY";
export const closeNewOpportunity = () => ({
    type: CLOSE_NEW_OPPORTUNITY
});

// ---------------------------------------------------------------------------------------------------------------------
// Customer Email
// ---------------------------------------------------------------------------------------------------------------------
export const START_NEW_EMAIL = "x/START_NEW_CUSTOMER_EMAIL";
export const startNewEmail = initialValues => ({
    type: START_NEW_EMAIL,
    payload: initialValues
});

export const CLOSE_NEW_EMAIL = "x/CLOSE_NEW_CUSTOMER_EMAIL";
export const closeNewEmail = () => ({
    type: CLOSE_NEW_EMAIL
});

export const SAVE_NEW_EMAIL = "x/SAVE_NEW_CUSTOMER_EMAIL";
export const saveNewEmail = email => ({
    type: SAVE_NEW_EMAIL,
    meta: { id: email.customerId },
    payload: email
});

// ---------------------------------------------------------------------------------------------------------------------
// Customer PhoneCall
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_NEW_PHONE_CALL = "x/SAVE_NEW_CUSTOMER_PHONE_CALL";
export const saveNewPhoneCall = phoneCall => ({
    type: SAVE_NEW_PHONE_CALL,
    payload: phoneCall
});

// ---------------------------------------------------------------------------------------------------------------------
// Customer Sms
// ---------------------------------------------------------------------------------------------------------------------
export const SAVE_NEW_SMS = "x/SAVE_NEW_CUSTOMER_SMS";
export const saveNewSms = sms => ({
    type: SAVE_NEW_SMS,
    meta: { id: sms.customerId },
    payload: sms
});

// ---------------------------------------------------------------------------------------------------------------------
// Customer WhatsApp
// ---------------------------------------------------------------------------------------------------------------------
export const OPEN_WHATSAPP = "x/OPEN_CUSTOMER_WHATSAPP";
export const openWhatsApp = phone => ({
    type: OPEN_WHATSAPP,
    payload: { phone }
});

// ---------------------------------------------------------------------------------------------------------------------
// New customer
// ---------------------------------------------------------------------------------------------------------------------
export const START_NEW_CUSTOMER = "x/START_NEW_CUSTOMER";
export function startNewCustomer () {
    return {
        type: START_NEW_CUSTOMER
    };
}

export const CLOSE_NEW_CUSTOMER = "x/CLOSE_NEW_CUSTOMER";
export function closeNewCustomer () {
    return {
        type: CLOSE_NEW_CUSTOMER
    };
}

export const SAVE_NEW_CUSTOMER = "x/SAVE_NEW_CUSTOMER";
export function saveNewCustomer ( customer ) {
    return {
        type: SAVE_NEW_CUSTOMER,
        payload: { customer }
    };
}

// ---------------------------------------------------------------------------------------------------------------------
// CompanyData
// ---------------------------------------------------------------------------------------------------------------------
export const FETCH_COMPANY_DATA = "x/FETCH_COMPANY_DATA";
export const fetchCompanyData = ein => {
    return {
        type: FETCH_COMPANY_DATA,
        meta: { ein }
    };
};

export const REQUEST_COMPANY_DATA = "x/REQUEST_COMPANY_DATA";
export const requestCompanyData = () => {
    return {
        type: REQUEST_COMPANY_DATA
    };
};

export const RECEIVE_COMPANY_DATA = "x/RECEIVE_COMPANY_DATA";
export const receiveCompanyData = data => {
    return {
        type: RECEIVE_COMPANY_DATA,
        payload: data
    };
};

export const ERROR_COMPANY_DATA = "x/ERROR_COMPANY_DATA";
export const errorCompanyData = error => {
    return {
        type: ERROR_COMPANY_DATA,
        error: true,
        payload: error
    };
};