import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input, flex } from "../../../ui";
import { deleteSource } from "./source.actions";

const SourceList = ({ form, remove }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showConfirmationDelete, setShowConfirmationDelete ] = useState( false );
    const [ showDeleteForbiddenDialog, setShowDeleteForbiddenDialog ] = useState( false );
    const [ index, setIndex ] = useState( null );

    const values = useMemo( () => form.values.sources, [ form.values.sources ] );

    const toggleDeleteForbiddenDialog = () => setShowDeleteForbiddenDialog( !showDeleteForbiddenDialog );

    const toggleConfirmationDelete = index => {
        if ( !showConfirmationDelete ) {
            if ( values.filter( source => source.id ).length === 1 ) {
                toggleDeleteForbiddenDialog();
                return;
            }

            if ( !values[ index ].id ) {
                remove( index );
                return;
            }
        }
        setShowConfirmationDelete( !showConfirmationDelete );
        setIndex( index );
    };

    const handleDelete = () => {
        dispatch( deleteSource( values[ index ] ) );
        toggleConfirmationDelete();
    };

    return (
        <div>
            <Dialog
                open={ showConfirmationDelete }
                onClose={ toggleConfirmationDelete }
            >
                <DialogTitle>{ t( "config:sources.delete.title" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t( "config:sources.delete.comment" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleConfirmationDelete }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ showDeleteForbiddenDialog }
                onClose={ toggleDeleteForbiddenDialog }
            >
                <DialogTitle>{ t( "config:sources.delete.title" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t( "config:sources.delete.forbidden" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleDeleteForbiddenDialog }>
                        { t( "common:ok" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                values.map( ( source, index ) => (
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) } key={ index }>
                        <Field
                            type="text"
                            label=""
                            placeholder={ t( "config:sources.item.placeholder" ) }
                            name={ `sources[${index}].name` }
                            className={ flex.fill }
                            component={ Input }
                        />
                        <Tooltip title={ t( "common:exclude" ) }>
                            <IconButton onClick={ () => toggleConfirmationDelete( index ) } size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                ))
            }
        </div>
    );
};

export default SourceList;