import { useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import WarningIcon from "@mui/icons-material/Warning";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";
import SchedulingItem from "./scheduling-item.component";
import { getSelected, getOpenSchedules } from "../opportunity.selectors";
import { fetchSchedules } from "../opportunity.actions";
import css from "./scheduling.scss";

const OpenSchedules = ({ opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const schedules = useSelector( getOpenSchedules );
    const opportunity = useSelector( getSelected );

    const prevOpportunity = usePrevious( opportunity );

    useEffect( () => {
        dispatch( fetchSchedules( opportunityId ) );
    }, [ dispatch, opportunityId ] );
    useEffect( () => {
        const newPhase = isEqual( opportunity.id, prevOpportunity?.id ) &&
            !isEqual( opportunity.phase, prevOpportunity?.phase ) &&
            !isEmpty( opportunity.phase.entrySchedules );
        if ( newPhase ) {
            dispatch( fetchSchedules( opportunity.id ) );
        }
    }, [ dispatch, prevOpportunity, opportunity ] );

    return (
        <Card className={ cssUtils.marginBottomSmall }>
            {
                schedules.length === 0 ?
                    <CardHeader
                        avatar={ <WarningIcon className={ css.avatarEmptyOpenSchedules } /> }
                        title={ t( "opportunity:open-schedules.empty" ) }
                        subheader={ t( "opportunity:open-schedules.follow-up" ) }>
                    </CardHeader> :
                    <>
                        <CardHeader subheader={ t( "opportunity:open-schedules.title" ) } />
                        <List disablePadding>
                            {
                                schedules.map( ( scheduling, index ) => (
                                    <SchedulingItem
                                        key={ scheduling.id }
                                        last={ ( index + 1 ) === schedules.length }
                                        scheduling={ scheduling }
                                    />
                                ))
                            }
                        </List>
                    </>
            }
        </Card>
    );
};

export default OpenSchedules;