import { useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { listAllActive } from "../user/user.selectors";
import { Autocomplete, Checkbox, Outlined, cssUtils, flex } from "../ui";

const SocialCalendarAttendees = ({ disabled, fields, form, name, push, remove, required, wrapperField }) => {
    const t = useTranslate();
    const users = useSelector( listAllActive );

    const values = useMemo( () => {
        return fields ? fields : get( form.values, name ) || [];
    }, [ fields, form, name ]);
    const fieldName = useMemo( () => {
        return fields ? fields.name : name;
    }, [ fields, name ]);

    const handleAdd = () => {
        const handle = fields ? fields.push : push;
        handle({ email: "", optional: false });
    };

    const handleRemove = index => {
        const handle = fields ? fields.remove : remove;
        handle( index );
    };

    const Field = wrapperField;

    return (
        <Outlined label={ t( "schedule:form-event.social.attendees" ) }>
            <List>
                {
                    values.map( ( socialAttendees, index ) => (
                        <li key={ index } className={ classnames( flex.container, flex.alignItemsCenter ) }>
                            <Field
                                name={ `${fieldName}[${index}].email` }
                                label=""
                                placeholder={ t( "common:email" ) }
                                className={ flex.fill }
                                fullWidth={ false }
                                multiple={ false }
                                disabled={ disabled }
                                getOptionLabel={ option => option }
                                options={ users.map( user => ({
                                    value: user.email,
                                    label: `${user.name} <${user.email}>`
                                }))}
                                component={ Autocomplete }
                                allowCreate
                                required
                            />
                            <Field
                                name={ `${fieldName}[${index}].optional` }
                                type="checkbox"
                                label={ t( "common:optional" ) }
                                className={ cssUtils.marginLeftSmall }
                                fullWidth={ false }
                                component={ Checkbox }
                            />
                            <IconButton
                                disabled={ disabled }
                                onClick={ () => {
                                    if ( !required || fields.length > 1 ) {
                                        handleRemove( index );
                                    }
                                }}
                                size="large">
                                <CancelIcon/>
                            </IconButton>
                        </li>
                    ))
                }
            </List>

            <Button
                size="small"
                onClick={ handleAdd }
                disabled={ disabled }
            >
                { t( "schedule:form-event.social.add-attendee" ) }
            </Button>
        </Outlined>
    );
};

export default SocialCalendarAttendees;