import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Draggable } from "react-beautiful-dnd";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirm } from "../../../ui";
import { deleteFileModelSection } from "./template.actions";
import { isDeletingFileModelSection } from "./template.selectors";
import FileModelSection from "./file-model-section.component";

const FileModelSectionItem = ({ index, fileModel, onSelect, onDeselect, section, selected }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const deleting = useSelector( isDeletingFileModelSection );

    const [ confirmDelete, setConfirmDelete ] = useState( false );
    const toggleDelete = () => setConfirmDelete( !confirmDelete );

    const handleDelete = () => dispatch( deleteFileModelSection( section.id, section.fileModelId ) );

    return (
        <>
            <DialogConfirm
                open={ confirmDelete }
                message={ t( "config:files.model.section.confirm-delete" ) }
                onConfirm={ handleDelete }
                onCancel={ toggleDelete }
                disabled={ deleting }
            />

            <Draggable key={ section.id } draggableId={ section.id.toString() } index={ index }>
                { provided => (
                    <div
                        ref={ provided.innerRef }
                        { ...provided.draggableProps }
                    >
                        <div>
                            <ListItem
                                disablePadding
                                divider
                                secondaryAction={
                                    <>
                                        <Tooltip title={ t( "common:exclude" ) }>
                                            <IconButton onClick={ toggleDelete } size="large">
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={ t( "common:order" ) } >
                                            <IconButton { ...provided.dragHandleProps } size="large"><ReorderIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            >
                                <ListItemButton
                                    onClick={ selected ? null : onSelect }
                                >
                                    <ListItemText
                                        primary={ section.name }
                                    />
                                </ListItemButton>
                            </ListItem>
                        </div>

                    </div>
                )}
            </Draggable>

            <FileModelSection
                open={ selected }
                fileModel={ fileModel }
                fileModelSection={ section }
                onClose={ onDeselect }
            />
        </>
    );
};

export default FileModelSectionItem;