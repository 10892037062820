import { useCallback } from "react";
import intl from "react-intl-universal";

const useTranslate = () => {
    const translate = useCallback( ( key, options ) => {
        return intl.get( key.replace( ":", "." ), options );
    }, [] );

    return translate;
};

export default useTranslate;