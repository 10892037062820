export default {
    "title": "Informes",
    "empty-results": "Sin resultados",
    "filters": {
        "initial-date": "Fecha de inicio",
        "final-date": "Fecha final",
        "month": "Mes",
        "year": "Año",
        "report": "Informe",
        "team": "Equipo",
        "empty-team": "No hay equipos configurados",
        "users": "Usuarios",
        "columns": "Columnas",
        "field": "Agrupación",
        "source": "Origen",
        "apply-filter": "Aplicar filtros"
    },
    "reports": {
        "OPPORTUNITIES_BY_SOURCE": "Oportunidades por origen",
        "ACCUMULATED_BY_USER": "Acumulado por usuario",
        "AVERAGE_SERVICE_TIME_BY_USER": "Tiempo promedio de servicio por usuario",
        "AVERAGE_OPPORTUNITY_TIME_SOLD_BY_USER": "Tiempo promedio de oportunidad ganado por usuario",
        "AVERAGE_TICKET_BY_USER": "Ticket promedio por usuario",
        "COMMISSION_RESULTS_BY_USER": "Resultados de comisiones por usuario",
        "GOALS_BY_USER": "Objetivos por usuario",
        "SALES_FORECAST_BY_USER": "Previsión de faturación por usuario",
        "OPPORTUNITY_SCHEDULES_BY_USER": "Programaciones de oportunidades por usuario",
        "OPPORTUNITY_TRACKING_BY_USER": "Clasificación de conversiones por usuario",
        "OPPORTUNITIES_LOST_BY_REASON": "Oportunidades perdidas por razones",
        "OPPORTUNITIES_SOLD_BY_FIELD": "Oportunidades ganadas",
        "CONVERSION_RATE_BY_USER": "Tasa de conversión por usuario",
        "PRODUCTS_SOLD_BY_FUNNEL": "Productos vendidos por embudo",
        "OPPORTUNITIES_CREATED_BY_USER": "Oportunidades creadas por usuario",
        "PROPOSALS_CREATED_BY_USER": "Propuestas creadas por usuario",
        "OPPORTUNITIES_FORWARDED_BY_USER": "Oportunidades de reenvío por usuario"
    },
    "opportunities-by-source": {
        "source": "Origen",
        "created": "Creadas",
        "sold": "Ganado",
        "sold-rate": "Ganado (Tasa de conversión)",
        "lost": "Pérdidas",
        "total": "Total",
        "rate": "Tasa de conversión",
        "sold-value": "valor ganado",
        "value": "Valor",
        "recurrent-value": "Valor recurrente",
        "help": "Seleccione oportunidades por fechas de creación y gane"
    },
    "accumulated-by-user": {
        "user": "Usuario",
        "open": "Abrir",
        "sold": "Ganado",
        "lost": "Pérdidas",
        "total": "Total",
        "help": "Muestra estadísticas de estado de oportunidad"
    },
    "average-service-time-by-user": {
        "user": "Usuario",
        "total-opportunities": "Oportunidades",
        "total-opportunities-worked": "Oportunidades trabajadas",
        "average-minutes": "Tiempo promedio de servicio",
        "minutes": "{ minutes } minutos",
        "help":
            "Muestra el tiempo promedio de la primera interacción en las oportunidades " +
            "incluidas en el período seleccionado"
    },
    "average-opportunity-time-sold-by-user": {
        "user": "Usuario",
        "total": "Oportunidades",
        "average-minutes": "Tiempo promedio de venta",
        "minutes": "{ minutes } minutos",
        "help": "Muestra el tiempo promedio para vender las oportunidades ganadas en el período seleccionado"
    },
    "average-ticket-by-user": {
        "user": "Usuario",
        "average-ticket": "Ticket promedio",
        "sold-opportunities": "Oportunidades ganadas",
        "total": "Total",
        "help": "Muestra el ticket medio por usuario. " +
            "Para calcular el ticket medio, se realiza el cálculo: valor / cantidad de oportunidades ganadas"
    },
    "commission-results-by-user": {
        "help": "Muestra los resultados de los usuarios de la comisión.",
        "commission": "Comisión",
        "user": "Usuario",
        "price": "Valor",
        "goal-reached": "Alcanzar la meta",
        "goal-not-reached": "Objetivo no alcanzado",
        "no-goal": "Sin meta",
    },
    "goals-by-user": {
        "help": "Muestra el progreso de los usuarios en sus respectivos objetivos",
        "user": "Usuario",
        "goal": "Meta",
        "target": "Objetivo",
        "current": "Alcanzado",
        "all": "General"
    },
    "sales-forecast-by-user": {
        "user": "Usuario",
        "open": "Abrir",
        "open-recurrent": "Solicitante abierto",
        "sold": "Vendido",
        "sold-recurrent": "Vendido recurrente",
        "total": "Total",
        "help":
            "Considera los datos de las oportunidades obtenidas por la fecha de finalización y las " +
            "oportunidades abiertas por la fecha de previsión de cierre"
    },
    "opportunity-schedules-by-user": {
        "type": "Tipo de horario",
        "user": "Usuario",
        "total": "Total",
        "open": "Abiertos",
        "accomplished": "Realizados",
        "not-accomplished": "No realizados",
        "help": "Muestra las estadísticas de estado de las programaciones de oportunidades"
    },
    "opportunity-tracking-by-user": {
        "help": "Muestra la clasificación de la conversión de oportunidades por usuario",
        "help-exit": "Presione la tecla ESC para salir",
        "total": "Total",
        "open": "Abrir",
        "not-worked": "No trabajado",
        "schedules": "Programado",
        "done": "Finalizado",
        "sold": "Ganado",
        "price": "Valor",
        "recurrent-price": "Valor recurrente",
        "conversion-rate": "Tasa de conversión"
    },
    "opportunities-lost-by-reason": {
        "name": "Razón de venta perdida",
        "total": "Total",
        "percentage": "Porcentaje",
        "help": "Considera las oportunidades perdidas en el período"
    },
    "opportunities-sold-by-field": {
        "value": "Valor",
        "total": "Total",
        "percentage": "Porcentaje",
        "help": "Agrupa los valores de las oportunidades ganadas de acuerdo al campo de tipo Select"
    },
    "conversion-rate-by-user": {
        "user": "Usuario",
        "total": "Total",
        "total-sold": "Ganado",
        "rate": "Tasa de conversión",
        "help": "Tasa de conversión de oportunidades ganadas"
    },
    "products-sold-by-funnel": {
        "total": "Total",
        "product": "Producto",
        "quantity": "Cantidad",
        "unit-price": "Valor unitario",
        "total-price": "Valor total",
        "help": "Cantidad de productos vendidos"
    },
    "opportunities-created-by-user": {
        "total": "Total",
        "user": "Usuario",
        "quantity": "Cantidad",
        "help": "Número de oportunidades creadas por usuario"
    },
    "proposals-created-by-user": {
        "title": "Propuestas creadas por usuario",
        "help": "Número de propuestas creadas por usuario",
        "empty": "No hay información sobre las propuestas creadas",
        "open": "Abrir",
        "accepted": "Aceptado",
        "declined": "Negado",
        "total": "Total",
        "user": "Usuario",
        "quantity": "Cantidad",
    },
    "opportunities-forwarded-by-user": {
        "total": "Total",
        "forward": "Adelante",
        "opportunities": "Oportunidades",
        "user": "Usuario",
        "help": "Muestra el número de oportunidades que los usuarios han reenviado a otros usuarios"
    },
    "print-chart": {
        "emission": "Emisión { date } a las { time } por { user } para la Inscripción { subscription }",
    }
};