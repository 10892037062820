import { useMemo } from "react";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as funnelSelectors } from "../../../funnel";
import { selectors as phaseSelectors } from "../../../phase";
import { cssUtils } from "../../../ui";

const PhaseSelector = ({
    exclude,
    funnel,
    field,
    form: { getFieldMeta } = {},
    id,
    label,
    required,
    className,
    fullWidth = false,
    ...props
}) => {
    const t = useTranslate();

    const allFunnels = useSelector( funnelSelectors.listAll );
    const allPhases = useSelector( phaseSelectors.list );

    const funnels = useMemo( () => allFunnels?.filter( f => !funnel || f.id !== funnel.id ), [ allFunnels, funnel ] );
    const phases = useMemo(
        () => allPhases
            .filter( phase => !funnel || phase.funnelId !== funnel.id )
            .filter( phase => !exclude || !exclude.includes( phase.id ) ),
        [ allPhases, funnel, exclude ]
    );

    const handleClearValue = () => {
        field.onChange({ target: { name: field.name, value: null } });
    };

    const value = useMemo( () => field.value || "", [ field ] );

    const hasValue = useMemo( () => !!value, [ value ] );
    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, getFieldMeta ] );

    let endAdornment;
    if ( hasValue ) {
        endAdornment = (
            <InputAdornment position="end" className={ cssUtils.marginRight }>
                <IconButton onClick={ handleClearValue } size="small">
                    <CancelIcon/>
                </IconButton>
            </InputAdornment>
        );
    }

    return (
        <FormControl
            id={ id }
            margin="dense"
            error={ !!validationMsg }
            required={ required }
            className={ className }
            fullWidth={ fullWidth }
            size="small"
        >
            { label && <InputLabel>{ label }</InputLabel> }
            <Select
                { ...props }
                { ...field }
                value={ value }
                label={ label }
                MenuProps={{ variant: "menu" }}
                endAdornment={ endAdornment }
            >
                {
                    funnels?.map( funnel => [
                        <ListSubheader key={ funnel.id + funnel.name }>{ funnel.name }</ListSubheader>,
                        phases.filter( phase => phase.funnelId === funnel.id ).map( phase => (
                            <MenuItem key={ phase.id } value={ phase.id }>
                                <ListItemText
                                    primary={ phase.name }
                                />
                            </MenuItem>
                        ))
                    ])
                }
            </Select>
            { validationMsg && <FormHelperText>{ t( validationMsg ) }</FormHelperText> }
        </FormControl>
    );
};

export default PhaseSelector;