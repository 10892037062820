import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FormikProvider } from "formik";
import { useForm } from "formik-redux";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../../hooks/use-previous.hook";
import { flex, SelectFunnel } from "../../ui";
import { getUser } from "../../core/core.selectors";
import { selectors as formSelectors, Fields, validateFields } from "../../form";
import { closeNewOpportunity, saveNewOpportunity } from "../opportunity.actions";
import { NEW_OPPORTUNITY_FORM } from "../opportunity.constants";
import { getFunnelId } from "../opportunity.selectors";
import css from "../opportunity.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const findSystemField = ( fields, systemField ) => fields ?
    ( fields.find( field => field.systemField === systemField ) || {} ) :
    {};

const OpportunityForm = ({ company, person, setRef }) => {
    const dispatch = useDispatch();

    const funnelId = useSelector( getFunnelId );
    const allOpportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );
    const user = useSelector( getUser );
    const [ opportunityFields, setOpportunityFields ] = useState( allOpportunityFields );

    const initialValues = useMemo( () => {
        const userOpportunityField = findSystemField( allOpportunityFields, "USER" );
        const userId = userOpportunityField.options.values
            .map( option => option.value )
            .find( option => option === user.id );

        return ({
            company,
            person,
            funnelId,
            fields: {
                ...fieldsToInitialValues( allOpportunityFields ),
                [ userOpportunityField.id ]: userId,
            }
        });
    }, [ funnelId, company, person, allOpportunityFields, user.id ] );

    const formik = useForm({
        enableReinitialize: true,
        form: NEW_OPPORTUNITY_FORM,
        initialValues,
        onSubmit: values => dispatch( saveNewOpportunity( values ) ),
        validate: values => {
            let errors = {};
            if ( !values ) {
                return errors;
            }
            errors = validateFields( values, { fields: opportunityFields } );

            if ( !values.funnelId ) {
                errors.funnelId = "common:validation.selection";
            }

            return errors;
        }
    });

    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        if ( !!formik?.values?.funnelId ) {
            setOpportunityFields(
                allOpportunityFields.filter( field =>
                    isEmpty( field?.funnelsId ) ||
                    field?.funnelsId.includes( formik?.values?.funnelId )
                )
            );
        }
    }, [ allOpportunityFields, formik?.values?.funnelId ] );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting && isEmpty( formik.error ) ) {
            dispatch( closeNewOpportunity() );
        }
    }, [ dispatch, prevSubmitting, formik.submitting, formik.error ] );

    return (
        <FormikProvider value={ formik }>
            <form ref={ setRef } onSubmit={ formik.handleSubmit } noValidate>
                <div className={ flex.container }>
                    <div className={ flex.fill }>
                        <div className={ css.funnelSelector }>
                            <Field
                                name="funnelId"
                                component={ SelectFunnel }
                                required
                            />
                        </div>
                        <Fields
                            fields={ opportunityFields }
                        />
                    </div>
                </div>
            </form>
        </FormikProvider>
    );
};

export default OpportunityForm;