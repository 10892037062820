import { createSelector } from "reselect";

export const isLoading = state => state.phases.all?.loading;
export const error = state => state.phases.all?.error;
export const isLoadedAll = state => state.phases.all?.all;
export const list = state => Object
    .keys( state.phases.funnel )
    .flatMap( funnelId => state.phases.funnel[ funnelId ]?.data || [] );

export const isLoadingFunnelPhases = funnelId => state => state.phases.funnel[ funnelId ]?.loading;
export const listWithFunnel = funnelId =>
    createSelector( state => state.phases, phases => phases.funnel[ funnelId ]?.data );

export const getPhasesCount = state => state.phases.count.data;
export const isLoadingPhasesCount = state => state.phases.count.loading;