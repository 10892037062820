import { all } from "redux-saga/effects";

import * as configUsersSagas from "./users/users.sagas";
import * as configSubscriptionSagas from "./subscription/subscription.sagas";
import * as configOpportunitySagas from "./opportunity/opportunity.sagas";
import * as configAdvancedSagas from "./advanced/advanced.sagas";

export function *watchConfig () {
    yield all([
        configUsersSagas.watchConfigUsers(),
        configSubscriptionSagas.watchConfigSubscription(),
        configOpportunitySagas.watchConfigOpportunity(),
        configAdvancedSagas.watchConfigAdvanced()
    ]);
}