import { combineReducers } from "redux";

import * as actions from "./brand.actions";

export function all ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_BRANDS:
            return {
                ...state,
                [ action.meta.brandType ]: {
                    loading: true,
                    data: []
                }
            };

        case actions.RECEIVE_BRANDS:
            return {
                ...state,
                [ action.meta.brandType ]: {
                    loading: false,
                    data: action.payload
                }
            };

        case actions.ERROR_BRANDS:
            return {
                ...state,
                [ action.meta.brandType ]: {
                    loading: false,
                    error: action.payload
                }
            };

        default:
            return state;
    }
}

export const brands = combineReducers({ all });