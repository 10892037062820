import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import qs from "qs";

import useTranslate from "../../hooks/use-translate.hook";
import { actions as funnelActions, selectors as funnelSelectors } from "../../funnel";
import { selectors as formSelectors } from "../../form";
import { Dropdown } from "../../ui";
import { setFunnel } from "../opportunity.actions";
import { getFunnelId } from "../opportunity.selectors";
import { selectors as coreSelectors } from "../../core";
import { actions as userActions } from "../../user";

const FunnelFilter = ( { className, fullWidth, size } ) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const t = useTranslate();

    const loading = useSelector( funnelSelectors.isLoadingAll );
    const funnels = useSelector( funnelSelectors.listAll );
    const selected = useSelector( getFunnelId ) || "";
    const fields = useSelector( formSelectors.list( "opportunity" ) );
    const preferences = useSelector( coreSelectors.getPreferences );

    const handleChange = useCallback( event => {
        dispatch( setFunnel( event.target.value ) );
    }, [ dispatch ] );
    const source = useMemo( () => {
        return funnels?.map( funnel => ({ value: funnel.id, label: funnel.name })) || [];
    }, [ funnels ] );

    useEffect( () => {
        if ( !loading && !funnels ) {
            dispatch( funnelActions.fetchFunnels() );
        }
    }, [ dispatch, loading, funnels ] );

    useEffect( () => {
        if ( !selected && !isEmpty( funnels ) && !loading ) {
            const queryString = qs.parse( location.search, { ignoreQueryPrefix: true });
            dispatch( setFunnel( queryString.funnelId || funnels[ 0 ].id ) );
        }
    }, [ dispatch, loading, funnels, location, selected ] );

    useEffect( () => {
        if ( selected !== "" ) {
            const fieldsOpportunity = fields.filter(
                ( { funnelsId } ) => isEmpty( funnelsId ) || funnelsId.includes( selected )
            );

            if (fieldsOpportunity.length !== fields.length) {
                const filters = get( preferences, "opportunity.filters" ) || [];

                if ( !isEmpty(filters) ) {
                    const filtersToRemove = filters.filter(
                        filter => !fieldsOpportunity.some( ( { id } ) => id === filter.fieldId )
                    );

                    if ( !isEmpty( filtersToRemove ) ) {
                        const newFilters = filters.filter(
                            filter => !filtersToRemove.some( ( { fieldId } ) => fieldId === filter.fieldId )
                        );

                        set( preferences, "opportunity.filters", newFilters );
                        dispatch(userActions.saveUserPreferences(preferences));
                    }
                }
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selected ] );


    return (
        <Dropdown
            label={ t( "opportunity:funnel" ) }
            onChange={ handleChange }
            value={ selected }
            disabled={ loading || isEmpty( funnels ) }
            source={ source }
            helperText={ !loading && isEmpty( funnels ) && t( "opportunity:funnels-empty" ) }
            className={ className }
            fullWidth={ fullWidth }
            size={ size }
        />
    );
};

export default FunnelFilter;