import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { saveHoldingFunnel } from "./holding.actions";
import { EDIT_HOLDING_FUNNEL_FORM } from "./holding.constants";
import HoldingFunnelForm from "./holding-funnel-form.component";

const EditHoldingFunnel = ({ holdingFunnel, onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: EDIT_HOLDING_FUNNEL_FORM,
        initialValues: holdingFunnel,
        onSubmit: values => dispatch( saveHoldingFunnel( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.defaultSourceId ) {
                errors.defaultSourceId = "common:validation.selection";
            }
            if ( !values.teamId && isEmpty( values.usersId ) ) {
                errors.teamId = "common:validation.selection";
                errors.usersId = "common:validation.selection";
            }
            return errors;
        }
    });

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:holding.funnel.edit.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <HoldingFunnelForm
                        errors={ formik.errors }
                        touched={ formik.touched }
                        setFieldValue={ formik.setFieldValue }
                        initialValues={ formik.initialValues }
                        values={ formik.values }
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditHoldingFunnel;