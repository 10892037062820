export default {
    "date-picker": {
        "clear": "Limpar",
        "invalid-date": "Data inválida"
    },
    "table": {
        "download-csv": "Download CSV",
        "download-xls": "Download XLS",
        "row": {
            "required": "Obrigatório"
        },
        "delete-dialog": {
            "title": "Você tem certeza que deseja deletar este(s) item(ns)?"
        },
        "select-all-tooltip": "Marcar/Desmarcar todos",
        "select-all-dialog-text": "Qual tipo de seleção deseja fazer?",
        "select-all-dialog-type-page": "Todos desta página",
        "select-all-dialog-type-all": "Todos os registros do filtro atual",
        "select-all-alert-title": "Aviso",
        "select-all-alert-content": "Você escolheu selecionar todos os registros das páginas. Com esta opção" +
            " você não pode desmarcar itens individualmente. Desmarque a opção \"Marcar/desmarcar todos.\"",
        "add": "Adicionar",
        "of": " de ",
        "itens-per-page": "Itens por página"
    },
    "input-file": {
        "label": "Selecionar arquivo",
        "no-file": "Nenhum arquivo selecionado",
        "file-error": "Formato ou extensão de arquivo inválido"
    },
    "calendar": {
        "culture": "pt-BR",
        "date": "Data",
        "time": "Horário",
        "event": "Evento",
        "all-day": "Dia todo",
        "week": "Semana",
        "day": "Dia",
        "month": "Mês",
        "previous": "Voltar",
        "next": "Próximo",
        "yesterday": "Ontem",
        "tomorrow": "Amanhã",
        "today": "Hoje",
        "show-more": "Mais { total }"
    },
    "brands": {
        "type": {
            "CAR": "Carro",
            "MOTORCYCLE": "Moto",
            "TRUCK": "Caminhão"
        }
    },
    "social": {
        "google-auth": "Fazer login com o Google",
    },
    "template": {
        "body": "Corpo",
        "sms-size": "Caracteres: { characters }. SMS(s) a ser(em) enviado(s): { quantity }",
        "add-image": "Adicionar imagem",
        "variables": {
            "title": "Variáveis",
            "help": "Utilize estas variáveis para substituir pelo seu respectivo valor ao preparar a mensagem para uso",
        },
        "customer-name": {
            "label": "Nome do cliente",
            "help":
                "Será adicionado no corpo a palavra \"%%CUSTOMERNAME%%\". " +
                "Esta palavra será substituída pelo nome do cliente ao preparar a mensagem para envio\n"
        },
        "price": {
            "label": "Valor",
            "help":
                "Será adicionado no corpo a palavra \"%%PRICE%%\". " +
                "Esta palavra será substituída pelo valor da oportunidade ao preparar a mensagem para envio\n"
        },
        "brand-model": {
            "label": "Marca/Modelo",
            "help":
                "Será adicionado no corpo a palavra \"%%BRANDMODEL%%\". " +
                "Esta palavra será substituída pela Marca/Modelo da oportunidade ao preparar a mensagem para envio\n"
        },
        "general-gallery": "Galeria geral",
        "my-gallery": "Minha galeria",
        "empty-images": "Não há imagens cadastradas",
        "select": "Selecionar template",
        "system": "Templates gerais",
        "shared": "Templates compartilhados",
        "my": "Meus templates",
        "end-of-page": "Fim da 1º página"
    },
    "users-filter": {
        "team": "Equipe",
        "empty-teams": "Não há equipes configuradas",
        "users": "Usuários",
        "active": "Ativos",
        "inactive": "Inativos",
    },
    "autocomplete": {
        "loading": "Carregando",
        "empty": "Nenhum resultado encontrado...",
        "warning": "Mínimo de 3 caracteres para realizar a pesquisa",
        "type-and-enter": "Digite um valor e pressione Enter"
    },
    "phases-filter": {
        "phase": "Fase"
    },
    "input": {
        "length": "Caracteres: { size }/{ total }"
    }
};