export const NEW_CUSTOMER_FORM = "newCustomerForm";
export const EDIT_CUSTOMER_FORM = "editCustomerForm";
export const EDIT_CUSTOMER = "editCustomer";
export const EXPORTATION_FORM = "customerExportationForm";
export const GENERATE_OPPORTUNITIES_FORM = "generateOpportunitiesForm";
export const UPDATE_CUSTOMERS_FORM = "updateCustomersForm";
export const USER_TABLE_COLUMNS_FORM = "userCustomerTableColumnsForm";

export const CUSTOMER_TAGS_FORM = "customerTagsForm";
export const FOLLOWING_FORM = "customerFollowingForm";
export const CUSTOMER_AVATAR_FORM = "customerAvatarForm";
export const CUSTOMER_FILE_FORM = "customerFileForm";

export const SAVE_COMPANY_FORM = "customerCompanyForm";
export const DELETE_COMPANY_FORM = "deleteCompanyForm";
export const ADD_PERSON_FORM = "customerPersonForm";

export const NEW_EMAIL_FORM = "newCustomerEmailForm";
export const NEW_PHONE_CALL_FORM = "newCustomerPhoneCallForm";
export const NEW_SMS_FORM = "newCustomerSmsForm";
export const NEW_WHATSAPP_FORM = "newWhatsAppForm";
export const NEW_COMMENT_FORM = "newCustomerCommentForm";
export const NEW_OPPORTUNITY_FORM = "newCustomerOpportunityForm";

export const DELETE_CUSTOMER_FORM = "deleteCustomerForm";