import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const AverageTicketByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "user" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo(
        () => order( results || [], [ orderBy ], [ orderType.toLowerCase() ] ),
        [ results, orderBy, orderType ]
    );

    const chartData = useMemo( () => ({
        chart: {
            type: "column",
            style: {
                fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
            }
        },
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.user ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: t( "report:average-ticket-by-user.average-ticket" ),
            data: orderedResults.map( item => item.averageTicket ) || 0,
            color: "#4CAF50"
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => orderedResults.map( item => ({
        user: item.user + "",
        soldOpportunities: item.soldOpportunities + "",
        averageTicket: priceFormat( item.averageTicket ) + ""
    })), [ priceFormat, orderedResults ] );

    const model = {
        user: t( "report:average-ticket-by-user.user" ),
        soldOpportunities: t( "report:average-ticket-by-user.sold-opportunities" ),
        averageTicket: t( "report:average-ticket-by-user.average-ticket" )
    };

    const footer = useMemo( () => {
        const data = orderedResults.reduce(( acc, item ) => {
            acc.user = t( "report:products-sold-by-funnel.total" );
            acc.soldOpportunities += item.soldOpportunities;
            acc.averageTicket += item.averageTicket;
            return acc;
        }, {
            user: t( "report:products-sold-by-funnel.total" ),
            soldOpportunities: 0,
            averageTicket: 0
        });
        data.averageTicket = priceFormat( data.averageTicket );
    }, [ t, priceFormat, orderedResults ] );

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.AVERAGE_TICKET_BY_USER" ) }
                subheader={ t( "report:average-ticket-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    onChange={ handleChange }
                    report={ "AVERAGE_TICKET_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="averageTicketByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="averageTicketByUserTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    footer={ footer }
                                    title={ t( "report:reports.AVERAGE_TICKET_BY_USER" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default AverageTicketByUser;