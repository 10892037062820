import DialogContentText from "@mui/material/DialogContentText";

import useTranslate from "../hooks/use-translate.hook";
import { DialogForm } from "./";

const DialogConfirmForm = ({ handleSubmit, open, onClose, message, submitForm, submitting, title }) => {
    const t = useTranslate();

    return (
        <DialogForm
            open={ open }
            onClose={ onClose }
            size="xs"
            handleSubmit={ handleSubmit }
            messages={{
                cancel: t( "common:no" ),
                submit: t( "common:yes" )
            }}
            submitForm={ submitForm }
            submitting={ submitting }
            title={ title }
        >
            <DialogContentText>{ message }</DialogContentText>
        </DialogForm>
    );
};

export default DialogConfirmForm;