import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../../core";
import { selectors as formSelectors } from "../../form";
import { actions as userActions } from "../../user";
import { Checkbox, flex } from "../../ui";
import { USER_TABLE_COLUMNS_FORM } from "../customer.constants";

const UserTableColumns = ({ customerType, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const mainScreenFields = useSelector( formSelectors.list( customerType ) );
    const preferences = useSelector( coreSelectors.getPreferences );

    const mainScreenColumns = useMemo( () => {
        if ( isEmpty( mainScreenFields ) ) {
            return [];
        }

        const userColumns = ( get( preferences, `${customerType.toLowerCase()}.mainColumns` ) || [] );

        const mainScreenColumns = mainScreenFields
            .filter( field => field.options && field.options.mainScreen )
            .map( field => ({
                value: field.id.toString(),
                label: field.name,
                checked: userColumns.includes( field.id.toString() ) || isEmpty( userColumns )
            }));

        return mainScreenColumns;
    }, [ customerType, mainScreenFields, preferences ] );

    const initialValues = useMemo( () => ({
        columns: mainScreenColumns.reduce( ( model, column ) => ({ ...model, [ column.value ]: column.checked }), {} ),
    }), [ mainScreenColumns ] );

    const formik = useForm({
        form: USER_TABLE_COLUMNS_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            const mainColumns = Object.keys( values.columns ).filter( key => !!values.columns[ key ] );
            dispatch( userActions.saveUserPreferences(
                set({ ...preferences }, `${customerType.toLowerCase()}.mainColumns`, mainColumns ),
                USER_TABLE_COLUMNS_FORM
            ));
        }
    });

    const prevSubmitting = usePrevious( formik.submitting );

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onClose();
        }
    }, [ prevSubmitting, formik.submitting, onClose ] );

    return (
        <Drawer
            open={ open }
            onClose={ handleClose }
            anchor="right"
        >
            <Form formik={ formik }>
                <List>
                    <ListSubheader>{ t( "customer:customers-list.columns" ) }</ListSubheader>
                    {
                        mainScreenColumns.map( column => (
                            <ListItem key={ column.value }>
                                <Field
                                    name={ `columns[${column.value}]` }
                                    type="checkbox"
                                    component={ Checkbox }
                                    label={ column.label }
                                />
                            </ListItem>
                        ))
                    }
                </List>
                <div className={ flex.container }>
                    <Button className={ flex.fill } disabled={ formik.submitting } onClick={ handleClose }>
                        { t( "common:close" ) }
                    </Button>
                    <Button className={ flex.fill } disabled={ formik.submitting } onClick={ formik.submitForm }>
                        { t( "common:save" ) }
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default UserTableColumns;
