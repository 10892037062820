import Highcharts from "highcharts";
import addChart from "highcharts/modules/funnel";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import merge from "lodash/merge";

import { getThemeMode } from "../core/core.selectors";

const DEFAULT_DATA = type => ({
    chart: {
        type,
        style: {
            fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
        }
    }
});

const DARK_OPTIONS = {
    colors: [
        "#2b908f", "#90ee7e", "#f45b5b", "#7798BF", "#aaeeee", "#ff0066",
        "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"
    ],
    chart: {
        backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0 },
            stops: [
                [ 0, "#1E1E1E" ]
            ]
        },
        plotBorderColor: "#606063"
    },
    title: {
        style: {
            color: "#fff",
        }
    },
    subtitle: {
        style: {
            color: "#fff",
        }
    },
    xAxis: {
        gridLineColor: "#707073",
        labels: {
            style: {
                color: "#fff"
            }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        title: {
            style: {
                color: "#A0A0A3"

            }
        }
    },
    yAxis: {
        gridLineColor: "#707073",
        labels: {
            style: {
                color: "#fff"
            }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        tickWidth: 1,
        title: {
            style: {
                color: "#A0A0A3"
            }
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: "bold",
                color: "#fff"
            },
        },
    },
    tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        style: {
            color: "#fff"
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: "#fff",
            },
            marker: {
                lineColor: "#333"
            }
        },
        boxplot: {
            fillColor: "#505053"
        },
        candlestick: {
            lineColor: "white"
        },
        errorbar: {
            color: "white"
        }
    },
    legend: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        itemStyle: {
            color: "#fff"
        },
        itemHoverStyle: {
            color: "#fff"
        },
        itemHiddenStyle: {
            color: "#606063"
        },
        title: {
            style: {
                color: "#C0C0C0"
            }
        }
    },
    credits: {
        style: {
            color: "#666"
        }
    },
    labels: {
        style: {
            color: "#707073"
        }
    },

    drilldown: {
        activeAxisLabelStyle: {
            color: "#F0F0F3"
        },
        activeDataLabelStyle: {
            color: "#F0F0F3"
        }
    },

    navigation: {
        buttonOptions: {
            symbolStroke: "#DDDDDD",
            theme: {
                fill: "#1E1E1E"
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: "#505053",
            stroke: "#000000",
            style: {
                color: "#CCC"
            },
            states: {
                hover: {
                    fill: "#707073",
                    stroke: "#000000",
                    style: {
                        color: "white"
                    }
                },
                select: {
                    fill: "#000003",
                    stroke: "#000000",
                    style: {
                        color: "white"
                    }
                }
            }
        },
        inputBoxBorderColor: "#505053",
        inputStyle: {
            backgroundColor: "#333",
            color: "silver"
        },
        labelStyle: {
            color: "silver"
        }
    },

    navigator: {
        handles: {
            backgroundColor: "#666",
            borderColor: "#AAA"
        },
        outlineColor: "#CCC",
        maskFill: "rgba(255,255,255,0.1)",
        series: {
            color: "#7798BF",
            lineColor: "#A6C7ED"
        },
        xAxis: {
            gridLineColor: "#505053"
        }
    },

    scrollbar: {
        barBackgroundColor: "#808083",
        barBorderColor: "#808083",
        buttonArrowColor: "#CCC",
        buttonBackgroundColor: "#606063",
        buttonBorderColor: "#606063",
        rifleColor: "#FFF",
        trackBackgroundColor: "#404043",
        trackBorderColor: "#404043"
    },
};

const Chart = ({ noDataMessage, data, type = "line", id }) => {
    const container = useRef();

    const themeMode = useSelector( getThemeMode );

    useEffect( () => {
        noDataMessage && Highcharts.setOptions({ lang: { noData: noDataMessage }});
    }, [ noDataMessage ] );
    useEffect( () => {
        themeMode === "dark" && Highcharts.setOptions( DARK_OPTIONS );
    }, [ themeMode ] );
    useEffect( () => {
        const chart = Highcharts.chart( container.current, merge( DEFAULT_DATA( type ), data ) );

        // Hack necessario para que o grafico fique com a largura correta
        window.dispatchEvent( new Event( "resize" ) );

        return () => chart.destroy();
    }, [ type, data ] );
    useEffect( () => {
        if ( type === "funnel" ) {
            addChart( Highcharts );
        }
    }, [ type ] );

    return <div ref={ container } id={ id }/>;
};

export default Chart;