import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils } from "../ui";
import css from "./dashboard.scss";

const coldColor = "#1E88E5";
const warmColor = "#FBC02D";
const hotColor = "#F4511E";

const OpportunitiesTemperatureChart = ({
    className,
    statistics: { temperatures },
    initialDate,
    finalDate,
    onClickItem,
    users,
}) => {
    const t = useTranslate();

    const [ showHelp, setShowHelp ] = useState( false );
    const toggleHelp = () => setShowHelp( !showHelp );

    const series = useMemo( () => {
        let data = [];
        if ( temperatures.COLD > 0 || temperatures.WARM > 0 || temperatures.HOT > 0 ) {
            data = [{
                name: t( "dashboard:opportunities-temperature-chart.cold" ),
                temperature: "COLD",
                y: temperatures.COLD,
                color: coldColor
            }, {
                name: t( "dashboard:opportunities-temperature-chart.warm" ),
                temperature: "WARM",
                y: temperatures.WARM,
                color: warmColor
            }, {
                name: t( "dashboard:opportunities-temperature-chart.hot" ),
                temperature: "HOT",
                y: temperatures.HOT,
                color: hotColor
            }];
        }

        return [{
            name: t( "dashboard:opportunities-temperature-chart.opportunities" ),
            colorByPoint: true,
            data
        }];
    }, [ t, temperatures ]);

    const data = useMemo( () => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
                style: {
                    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
                }
            },
            title: {
                text: t( "dashboard:opportunities-temperature-chart.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunities-temperature-chart.title" );
                    }
                },
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                format: "<b>{point.name}</b>: {point.percentage:.1f} %"
                            }
                        }
                    }
                }
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y} - {point.percentage:.1f}%</b>"
            },
            plotOptions: {
                pie: {
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                },
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                if ( !this.temperature ) {
                                    return;
                                }

                                onClickItem(
                                    t(
                                        "dashboard:opportunities-temperature-chart.title-dialog",
                                        { temperature: this.name.toLowerCase() }
                                    ),
                                    {
                                        initialCreatedAt: initialDate,
                                        finalCreatedAt: finalDate,
                                        temperature: this.temperature,
                                        status: "OPEN",
                                        users: users.toString()
                                    }
                                );
                            }
                        }
                    }
                }
            },
            series,
        };
    }, [ t, finalDate, initialDate, onClickItem, series, users ] );

    return (
        <Card className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunities-temperature-chart.help" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK "}</Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="temperatureChartChart"
                type="pie"
                data={ data }
                noDataMessage={ t( "dashboard:opportunities-temperature-chart.empty" ) }
            />

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButton className={ css.iconHelpChart } onClick={ toggleHelp } size="large">
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
        </Card>
    );
};

export default OpportunitiesTemperatureChart;