import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirm, Input, cssUtils, flex } from "../../../ui";
import { fetchModelSections } from "../../../file/file.actions";
import { saveFileModel, deleteFileModel } from "./template.actions";
import { isDeletingFileModel } from "./template.selectors";
import { createFileModelForm } from "./template.utils";
import FileModelHeader from "./file-model-header.component";
import FileModelFooter from "./file-model-footer.component";
import FileModelSections from "./file-model-sections.component";
import css from "./template.scss";

const FileModel = ({ fileModel, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ openHeader, setOpenHeader ] = useState( false );
    const [ openFooter, setOpenFooter ] = useState( false );
    const [ confirmDelete, setConfirmDelete ] = useState( false );

    const deleting = useSelector( isDeletingFileModel );

    const toggleHeader = () => setOpenHeader( !openHeader );

    const toggleFooter = () => setOpenFooter( !openFooter );

    const toggleDelete = () => setConfirmDelete( !confirmDelete );

    const formik = useForm({
        form: createFileModelForm( fileModel ),
        enableReinitialize: true,
        initialValues: {
            name: "",
            ...fileModel,
        },
        onSubmit: values => dispatch( saveFileModel( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            return errors;
        },
    });

    useEffect( () => {
        fileModel && dispatch( fetchModelSections( fileModel.id ) );
    }, [ dispatch, fileModel ] );

    return (
        <>
            <Card className={ cssUtils.marginBottom }>
                <CardContent>
                    <Form formik={ formik }>
                        <Field
                            name="name"
                            label={ t( "config:files.model.name" ) }
                            component={ Input }
                            required
                        />

                        <ButtonGroup fullWidth className={ cssUtils.marginTop }>
                            <Button color="primary" onClick={ toggleHeader }>
                                <Badge
                                    invisible={ !formik.values.header }
                                    badgeContent={
                                        <Avatar className={ css.checkIcon }>
                                            <CheckIcon/>
                                        </Avatar>
                                    }
                                >
                                    { t( "config:files.model.config-header" ) }
                                </Badge>
                            </Button>
                            <Button color="primary" onClick={ toggleFooter }>
                                <Badge
                                    invisible={ !formik.values.footer }
                                    badgeContent={
                                        <Avatar className={ css.checkIcon }>
                                            <CheckIcon/>
                                        </Avatar>
                                    }
                                >
                                    { t( "config:files.model.config-footer" ) }
                                </Badge>
                            </Button>
                        </ButtonGroup>

                        <FileModelHeader
                            open={ openHeader }
                            onClose={ toggleHeader }
                            form={ formik }
                        />

                        <FileModelFooter
                            open={ openFooter }
                            onClose={ toggleFooter }
                            form={ formik }
                        />

                        <Divider className={ classnames( cssUtils.marginTop, cssUtils.marginBottomSmall ) }/>
                    </Form>

                    <FileModelSections fileModel={ formik.values } />
                </CardContent>
                <CardActions>
                    <div className={ flex.fill }>
                        <Button color="secondary" disabled={ formik.submitting } onClick={ toggleDelete }>
                            { t( "common:exclude" ) }
                        </Button>
                    </div>
                    <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Card>

            <DialogConfirm
                open={ confirmDelete }
                disabled={ deleting }
                message={ t( "config:files.model.confirm-delete" ) }
                onCancel={ toggleDelete }
                onConfirm={ deleteFileModel }
            />
        </>
    );
};

export default FileModel;