import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";

import useTranslate from "../hooks/use-translate.hook";
import FacebookIntegration from "./integration/facebook/facebook-integration.component";
import WebCaptureIntegration from "./integration/web-capture/web-capture.component";
import PortalsIntegration from "./integration/portal/portals-integration.component";
import ApiKeyIntegration from "./integration/api-key/api-keys.component";
import SmsIntegration from "./product/sms/sms.component";
import MailtopAutomation from "./automation/mailtop/mailtop.component";
import Automations from "./automation/automations.component";
import ZapierHub from "./hub/zapier/zapier.component";
import Webhooks from "./additional-resources/webhook/webhook.component";
import MarketplaceList from "./marketplace-list.component";

const Marketplace = ({ className }) => {
    const t = useTranslate();

    return (
        <div className={ className }>
            <Helmet title={ t( "marketplace:title" ) } />
            <Routes>
                <Route index element={ <MarketplaceList /> } />
                <Route path="integrations">
                    <Route path="facebook" element={ <FacebookIntegration /> } />
                    <Route
                        path="web-capture"
                        element={ <WebCaptureIntegration /> }
                    />
                    <Route
                        path={ "portals/*" }
                        element={ <PortalsIntegration /> }
                    />
                    <Route
                        path={ "apikeys" }
                        element={ <ApiKeyIntegration /> }
                    />
                    <Route path="products/*">
                        <Route path="sms" element={ <SmsIntegration /> } />
                    </Route>
                </Route>
                <Route path="automations">
                    <Route path="*" element={ <Automations /> } />
                    <Route
                        path="mailtop"
                        element={ <MailtopAutomation /> }
                    />
                </Route>
                <Route path="hubs/*">
                    <Route path="zapier" element={ <ZapierHub /> }/>
                </Route>
                <Route path="additional-resources/*">
                    <Route path="webhooks" element={ <Webhooks /> }/>
                </Route>
            </Routes>
        </div>
    );
};

export default Marketplace;