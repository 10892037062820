import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import InfiniteScroll from "react-infinite-scroller";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { useDateFormat, useDateTimeFormat } from "../hooks/use-date-format.hook";
import { fetchSchedules, receiveSchedules } from "./home.actions";
import ScheduleEventStatus from "../schedule/schedule-event-status.component";
import { getSchedules, isLoadingSchedules, getSchedulesTotalPages } from "./home.selectors";
import { EmptyState, Link } from "../ui";
import css from "./user-todos.scss";

const SchedulesDialog = ({
    onClose,
    open,
    usersId,
    isOtherUsers,
    refreshSchedulingStatistics,
    title,
    startDate,
    endDate,
    started = false
}) => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const dateFormat = useDateFormat( t );
    const dateTimeFormat = useDateTimeFormat( t );

    const schedules = useSelector( getSchedules );
    const pages = useSelector( getSchedulesTotalPages );
    const isLoading = useSelector( isLoadingSchedules );

    const [ page, setPage ] = useState( 1 );

    const loadSchedules = useCallback( () => {
        dispatch( fetchSchedules({
            status: "OPEN",
            page,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            usersId: usersId.toString(),
            started
        }) );
    }, [ usersId, dispatch, page, startDate, endDate, started ] );

    const incrementPageSchedules = useCallback( () => {
        if ( isLoading ) {
            return;
        }

        setPage( page => page + 1 );
    }, [ isLoading ] );

    const refreshSchedules = useCallback( () => {
        refreshSchedulingStatistics();
        loadSchedules();
    }, [ loadSchedules, refreshSchedulingStatistics ] );

    const renderSchedules = useCallback( () => {
        return schedules.map( scheduling => getItem( scheduling, scheduling.id ) );
    }, [ getItem, schedules ] );

    const getItemSecondaryActionAdmin = useCallback( event => {
        const user = event.user ? event.user : event.opportunity.user;
        if ( isOtherUsers ) {
            return (
                <ListItemAvatar>
                    <Tooltip title={ user.name }>
                        <Avatar src={ user.avatar }>{ user.avatar ? "" : user.name.charAt( 0 ).toUpperCase() }</Avatar>
                    </Tooltip>
                </ListItemAvatar>
            );
        }
        return (
            <ScheduleEventStatus
                event={ event }
                dispatch={ dispatch }
                refreshSchedules={ refreshSchedules }
            />

        );
    }, [ dispatch, isOtherUsers, refreshSchedules ] );

    const getItem = useCallback( ( event, index ) => {
        if ( event.opportunity ) {
            return (
                <ListItem
                    key={ index }
                    disablePadding
                    secondaryAction={ getItemSecondaryActionAdmin( event ) }
                >
                    <ListItemButton
                        dense
                        component={ Link }
                        href={ "/opportunities/" + event.opportunity.id }
                    >
                        <ListItemIcon>
                            <AttachMoneyIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                event.opportunitySchedulingType.name + " - " + dateTimeFormat( event.startDate )
                            }
                            secondary={
                                (
                                    event.opportunity.person ?
                                        event.opportunity.person.name :
                                        event.opportunity.company.name
                                ) +
                                " - " +
                                event.description
                            }
                        />
                    </ListItemButton>
                </ListItem>
            );
        }

        return (
            <ListItem key={ index } dense>
                <ListItemIcon>
                    <EventNoteIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={
                        event.title +
                        " - " +
                        ( event.allDay ? dateFormat( event.startDate ) : dateTimeFormat( event.startDate ) )
                    }
                    secondary={
                        ( event.customer ? event.customer.name + " - " : "" ) +
                        ( event.description ? event.description : "" )
                    }
                />
                { getItemSecondaryActionAdmin( event ) }
            </ListItem>
        );
    }, [ dateFormat, dateTimeFormat, getItemSecondaryActionAdmin ] );

    useEffect( () => {
        loadSchedules();
    }, [ loadSchedules, dispatch ] );

    useEffect( () => {
        return () => {
            dispatch( receiveSchedules( null ) );
        };
    }, [ dispatch ] );

    return (
        <Dialog
            open={ open }
            fullWidth
            maxWidth="md"
            disableScrollLock
        >
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent
                id="schedulesDialogCardContent"
                className={ classnames( css.container, css.maxHeightDialog ) }
            >
                <List className={ css.list }>
                    {
                        !isLoading && isEmpty( schedules ) ?
                            <EmptyState
                                className={ css.emptyState }
                                icon={ ThumbUpIcon }
                                message={ t( "home:user-todos.empty" ) }
                            /> :
                            <InfiniteScroll
                                loadMore={ incrementPageSchedules }
                                useWindow={ false }
                                hasMore={ page < pages }
                                initialLoad={ false }
                                threshold={ 10 }
                                target="schedulesDialogCardContent"
                            >
                                { renderSchedules() }
                                { isLoading && <CircularProgress/> }
                            </InfiniteScroll>
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose }>{ t( "common:close" ) }</Button>
            </DialogActions>
        </Dialog>
    );

};

export default SchedulesDialog;