import { IdentificationValidation } from "locale-validations";
import libphonenumber from "libphonenumber-js/max";

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createSsnValidation = ({ t }) => value => {
    const validation = new IdentificationValidation( t( "common:full-locale" ) );
    return validation.isSSNValid( value );
};

export const createEinValidation = ({ t }) => value => {
    const validation = new IdentificationValidation( t( "common:full-locale" ) );
    return validation.isEINValid( value );
};

export const isPhoneValid = ({ number, country }) => {
    try {
        const phone = libphonenumber( number, country );
        return phone.isValid();
    } catch ( e ) {
        return false;
    }
};

export const isCellPhone = phone => {
    try {
        return libphonenumber(phone.number, phone.country ).getType() === "MOBILE";
    } catch ( e ) {
        return false;
    }
};

export const isEmail = email => {
    if ( !email ) {
        return false;
    }

    return email.match( EMAIL_REGEX );
};
