import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import CircularProgress from "@mui/material/CircularProgress";
import LocationOffIcon from "@mui/icons-material/LocationOff";

import useTranslate from "../hooks/use-translate.hook";
import { getSelected } from "./customer.selectors";
import { selectors as formSelectors } from "../form";
import { EmptyState } from "../ui";
import css from "./customer-map.scss";
import { useEffect } from "react";

const infoWindow = ({ placeId, name }) => (
    `<div><a href='https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}' target="_blank">` +
    `${name}</a></div>`
);

const CustomerMap = () => {
    const t = useTranslate();

    const container = useRef();

    const [ loading, setLoading ] = useState( true );
    const [ empty, setEmpty ] = useState( false );
    const [ notValid, setNotValid ] = useState( false );

    const customer = useSelector( getSelected );
    const fields = useSelector( formSelectors.listAddressFields( customer.type ) );

    const loadMap = useCallback( () => {
        try {
            const names = [];
            fields.forEach( field => {
                const value = customer.fields[ field.id ];
                if ( value ) {
                    value.forEach( address => names.push( address.name ) );
                }
            });

            if ( isEmpty( names ) ) {
                setLoading( false );
                setEmpty( true );
                return;
            }

            const geocoder = new google.maps.Geocoder();
            const addressConverter = names
                .map( name => new Promise( resolve =>
                    geocoder.geocode({ address: name }, function ( results, status ) {
                        if ( status === google.maps.GeocoderStatus.OK ) {
                            resolve({ ...results[ 0 ].geometry, name, placeId: results[ 0 ].place_id });
                        } else {
                            resolve();
                        }
                    })
                ));

            Promise.all( addressConverter ).then( addresses => {

                const validAddresses = addresses.filter(Boolean);

                if ( isEmpty( validAddresses ) ) {
                    setLoading( false );
                    setNotValid( true );
                    return;
                }

                const bound = new google.maps.LatLngBounds();
                for ( let i = 0; i < validAddresses.length; i++ ) {
                    const lat = validAddresses[ i ].location.lat();
                    const lng = validAddresses[ i ].location.lng();
                    bound.extend(
                        new google.maps.LatLng( lat, lng)
                    );
                }

                const map = new google.maps.Map( container.current, {
                    zoom: 15,
                    center: bound.getCenter()
                });

                setLoading( false );

                validAddresses.forEach( address => {
                    const marker = new google.maps.Marker({
                        position: { lat: address.location.lat(), lng: address.location.lng() },
                        map: map,
                        title: address.name
                    });
                    marker.addListener( "click", function () {
                        const window = new google.maps.InfoWindow({
                            content: infoWindow( address )
                        });
                        window.open( map, marker );
                    });
                });

                // Se ha mais de um endereço, deixa pro maps fazer o calculo de zoom e centralizaçao
                if ( validAddresses.length > 1 ) {
                    map.fitBounds( bound );
                }
            });
        } catch ( e ) {
            // Se deu erro eh pq nao baixou a lib do google ainda, uma hora vai
        }
    }, [ customer, fields ] );

    useEffect( () => {
        loadMap();
    }, [ loadMap ] );

    return (
        <div className={ css.container } >
            { loading && <CircularProgress/> }

            {
                empty &&
                    <EmptyState icon={ LocationOffIcon } message={ t( "customer:map.empty" ) } />
            }

            {
                notValid &&
                    <EmptyState icon={ LocationOffIcon } message={ t( "customer:map.not-valid" ) } />
            }

            <div
                className={ css.map }
                ref={ container }
            >
            </div>
        </div>
    );
};

export default CustomerMap;