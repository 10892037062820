import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonIcon from "@mui/icons-material/Person";
import throttle from "lodash/throttle";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { Autocomplete, flex } from "../ui";
import { getThemeMode } from "../core/core.selectors";
import { fetchTopBarItems } from "./layout.actions";
import { topBarItems, isLoadingTopBarItems } from "./layout.selectors";
import css from "./top-navbar.scss";

const SearchAppBar = ({ onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const themeMode = useSelector( getThemeMode );
    const items = useSelector( topBarItems );
    const loading = useSelector( isLoadingTopBarItems );

    const searchItems = useCallback( name => {
        if ( !name || name.length < 3 ) {
            return;
        }
        dispatch( fetchTopBarItems( name ) );
    }, [ dispatch ]);
    const search = throttle( searchItems, 200, { leading: false } );

    const selectItem = event => {
        const value = items.find( item => item.id === event.target.value );

        switch ( value?.type ) {
            case "COMPANY":
            case "PERSON":
                history.push( "/customers/" + value.id );
                break;
            case "OPPORTUNITY":
                history.push( "/opportunities/" + value.id );
                break;
            default:
                break;
        }
        onClose();
    };

    const source = useMemo( () => {
        return ( items || [] ).reduce( ( model, item ) => {
            model[ item.id ] = {
                text: item.name, icon: item.type === "OPPORTUNITY" ?
                    <AttachMoneyIcon/> :
                    item.type === "COMPANY" ? <BusinessIcon/> : <PersonIcon/>
            };
            return model;
        }, {} );
    }, [ items ] );
    const field = {
        onChange: selectItem
    };

    return (
        <AppBar
            className={ css.appBar }
            classes={{ root: themeMode === "dark" ? css.searchAppBarDark : css.searchAppBar }}
        >
            <Toolbar>
                <IconButton onClick={ onClose } size="large">
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Autocomplete
                    className={ flex.fill }
                    autoFocus
                    placeholder={ t( "layout:top-navbar.search" ) }
                    multiple={ false }
                    onQueryChange={ search }
                    source={ source }
                    field={ field }
                    loading={ loading }
                    filterOptions={ options => options }
                />
            </Toolbar>
        </AppBar>
    );
};

export default SearchAppBar;