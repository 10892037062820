export const FETCH_SOURCES = "x/FETCH_SOURCES";
export const fetchSources = () => ({
    type: FETCH_SOURCES
});

export const REQUEST_SOURCES = "x/REQUEST_SOURCES";
export const requestSources = () => ({
    type: REQUEST_SOURCES
});

export const RECEIVE_SOURCES = "x/RECEIVE_SOURCES";
export const receiveSources = sources => ({
    type: RECEIVE_SOURCES,
    payload: sources
});

export const ERROR_SOURCES = "x/ERROR_SOURCES";
export const errorSources = error => ({
    type: ERROR_SOURCES,
    error
});