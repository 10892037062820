import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteSubscriptionHolding } from "./holding.actions";
import { DELETE_SUBSCRIPTION_HOLDING_FORM } from "./holding.constants";

const DeleteSubscriptionHolding = ({ onClose, open, token }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        enableReinitialize: true,
        form: DELETE_SUBSCRIPTION_HOLDING_FORM,
        initialValues: { token },
        onSubmit: values => dispatch( deleteSubscriptionHolding( values.token ) ),
    });

    return (
        <DialogConfirmForm
            open={ open }
            onClose={ onClose }
            title={ t( "config:holding.unlink-subscription.title" ) }
            message={ t( "config:holding.unlink-subscription.confirm" ) }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteSubscriptionHolding;