import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import MuiImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils } from "../ui";
import { actions as templateActions, selectors as templateSelectors } from "../template";
import AddImage from "./add-image.component";
import DeleteImage from "./delete-image.component";

const ImageList = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( templateSelectors.isLoadingImages );
    const images = useSelector( templateSelectors.getMyImages );

    const openImage = image => () => window.open( image.url );

    useEffect( () => {
        dispatch( templateActions.fetchImages() );
    }, [ dispatch ] );

    return (
        <Card>
            <CardHeader
                title={ t( "user:images.title" ) }
                subheader={ t( "user:images.call-to-action" ) }
            />

            { loading && <div className={ cssUtils.textCenter }><CircularProgress/></div> }

            <CardContent>
                <AddImage/>

                <MuiImageList cols={ 4 } className={ cssUtils.marginTop }>
                    { images.map( image => (
                        <ImageListItem key={ image.id }>
                            <img
                                onClick={ openImage( image ) }
                                src={ image.url }
                                alt={ image.name }
                                className={ cssUtils.cursorPointer }
                            />
                            <DeleteImage image={ image }/>
                        </ImageListItem>
                    ))}
                </MuiImageList>
            </CardContent>
        </Card>
    );
};

export default ImageList;