import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";

import useTranslate from "../hooks/use-translate.hook";
import { Input } from "../ui";
import { patchCustomer } from "./customer.actions";
import { getSelected } from "./customer.selectors";
import { EDIT_CUSTOMER } from "./customer.constants";
import css from "./customer.scss";

const CustomerNotes = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customer = useSelector( getSelected );

    const formik = useForm({
        form: EDIT_CUSTOMER,
        initialValues: {
            id: customer.id,
            notes: customer.notes,
        },
        onSubmit: values => dispatch( patchCustomer({ ...values, form: EDIT_CUSTOMER }) ),
    });

    return (
        <div className={ css.containerContent }>
            <div className={ css.content }>
                <Card>
                    <Form formik={ formik }>
                        <CardContent>
                            <Field
                                name="notes"
                                label={ t( "customer:notes.title" ) }
                                type="text"
                                multiline
                                rows="7"
                                rowsMax="30"
                                component={ Input }
                            />
                            <Button
                                type="submit"
                                disabled={ formik.submitting }
                                color="primary"
                            >
                                { t( "common:save" ) }
                            </Button>
                        </CardContent>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default CustomerNotes;