import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const OpportunitiesLostByReason = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo(
        () => order( results || [], [ orderBy ], [ orderType.toLowerCase() ] ),
        [ results, orderBy, orderType ]
    );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
            "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: t( "report:opportunities-lost-by-reason.total" ),
            data: orderedResults.map( item => item.total ),
            color: "#F44336"
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => {
        const totalOpportunities = orderedResults.reduce( ( model, item ) => model + item.total, 0 );

        return orderedResults.map( item => ({
            ...item,
            total: item.total + "",
            percentage: item.total > 0 ?
                ( +( Math.round( ( ( 100 * item.total ) / totalOpportunities ) + "e+2" ) + "e-2" ) ) + "%" :
                "0%"
        }));
    }, [ orderedResults ] );

    const model = {
        name: t( "report:opportunities-lost-by-reason.name" ),
        total: t( "report:opportunities-lost-by-reason.total" ),
        percentage: t( "report:opportunities-lost-by-reason.percentage" )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITIES_LOST_BY_REASON" ) }
                subheader={ t( "report:opportunities-lost-by-reason.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source" ]}
                    required={[ "funnels" ]}
                    report={ "OPPORTUNITIES_LOST_BY_REASON" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        chartData &&
                            <Chart
                                id="opportunitiesLostByReasonChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="opportunitiesLostByReasonTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    title={ t( "report:reports.OPPORTUNITIES_LOST_BY_REASON" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default OpportunitiesLostByReason;