export const SAVE_PRODUCT = "x/config/opportunity/product/SAVE_PRODUCT";
export const saveProduct = product => ({
    type: SAVE_PRODUCT,
    payload: { product }
});

export const DELETE_PRODUCT = "x/config/opportunity/product/DELETE_PRODUCT";
export const deleteProduct = product => ({
    type: DELETE_PRODUCT,
    meta: { id: product.id }
});

export const DELETE_PRODUCTS = "x/config/opportunity/product/DELETE_PRODUCTS";
export const deleteProducts = data => ({
    type: DELETE_PRODUCTS,
    payload: data
});

export const DELETE_PRODUCT_SUCCESS = "x/config/opportunity/product/DELETE_PRODUCT_SUCCESS";
export const deleteProductSuccess = () => ({
    type: DELETE_PRODUCT_SUCCESS
});

export const DELETE_PRODUCT_ERROR = "x/config/opportunity/product/DELETE_PRODUCT_ERROR";
export const deleteProductError = error => ({
    type: DELETE_PRODUCT_ERROR,
    error: error
});

export const IMPORT_PRODUCTS = "x/config/opportunity/product/IMPORT_PRODUCTS";
export const importProducts = data => ({
    type: IMPORT_PRODUCTS,
    payload: data
});

export const DELETE_PRODUCT_CATEGORIES = "x/DELETE_PRODUCT_CATEGORIES";
export const deleteProductCategory = productCategory => ({
    type: DELETE_PRODUCT_CATEGORIES,
    meta: { id: productCategory.id }
});


export const SAVE_PRODUCT_CATEGORIES = "x/config/opportunity/opportunity/SAVE_PRODUCT_CATEGORIE";
export const saveProductCategories = ({ productCategories }) => ({
    type: SAVE_PRODUCT_CATEGORIES,
    payload: { productCategories }
});

export const FETCH_IMPORTATIONS = "x/config/opportunity/product/FETCH_PRODUCT_IMPORTATIONS";
export const fetchImportations = () => ({
    type: FETCH_IMPORTATIONS
});

export const REQUEST_IMPORTATIONS = "x/config/opportunity/REQUEST_PRODUCT_IMPORTATIONS";
export const requestImportations = () => ({
    type: REQUEST_IMPORTATIONS,
});

export const RECEIVE_IMPORTATIONS = "x/config/opportunity/RECEIVE_PRODUCT_IMPORTATIONS";
export const receiveImportations = data => ({
    type: RECEIVE_IMPORTATIONS,
    payload: data,
});

export const ERROR_IMPORTATIONS = "x/config/opportunity/ERROR_PRODUCT_IMPORTATIONS";
export const errorImportations = error => ({
    type: ERROR_IMPORTATIONS,
    payload: error
});

export const DELETE_IMPORTATION = "x/config/opportunity/DELETE_PRODUCT_IMPORTATION";
export const deleteImportation = ({ id }) => ({
    type: DELETE_IMPORTATION,
    meta: { id }
});

export const SET_IMPORTATION = "x/config/opportunity/SET_PRODUCT_IMPORTATION";
export const setImportation = importation => ({
    type: SET_IMPORTATION,
    payload: importation,
});