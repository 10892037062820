// ---------------------------------------------------------------------------------------------------------------------
// Invitation in acceptance
// ---------------------------------------------------------------------------------------------------------------------
export const errorInAcceptance = state => state.invitations.inAcceptance.error;
export const getInAcceptance = state => state.invitations.inAcceptance.data;

// ---------------------------------------------------------------------------------------------------------------------
// Invitations list
// ---------------------------------------------------------------------------------------------------------------------
export const listAll = state => state.invitations.all.data;
export const errorAll = state => state.invitations.all.error;
export const isLoadingAll = state => state.invitations.all.loading;