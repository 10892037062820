export const FETCH_KANBAN = "x/FETCH_KANBAN";
export const fetchKanban = () => ({
    type: FETCH_KANBAN
});

export const REQUEST_KANBAN = "x/REQUEST_KANBAN";
export const requestKanban = () => ({
    type: REQUEST_KANBAN
});

export const RECEIVE_KANBAN = "x/RECEIVE_KANBAN";
export const receiveKanban = kanban => ({
    type: RECEIVE_KANBAN,
    payload: kanban
});

export const ERROR_KANBAN = "x/ERROR_KANBAN";
export const errorKanban = error => ({
    type: ERROR_KANBAN,
    error
});