export default {
    "email-verification": {
        "success": "E-mail verificado com sucesso!",
        "error": "Não foi possível verificar o e-mail utilizando este token",
        "back": "Voltar para a página inicial",
        "loading": "Verificando seu e-mail, aguarde por favor"
    },
    "user": {
        "name": "Nome",
        "email": "E-mail",
        "profile": "Meus dados",
        "change-email-help":
            "Após salvar a alteração de e-mail, você será redirecionado para a página de login e " +
            "será necessária a verificação do novo endereço",
        "index-route": {
            "label": "Página inicial",
            "help": "Neste campo você define qual módulo que será exibido ao realizar o login ou ao acessar o sistema",
            "home": "Para hoje",
            "opportunities": "Oportunidades"
        },
        "avatar": "Avatar",
        "confirm-remove-avatar": "Você tem certeza que deseja remover o avatar?",
        "signature": "Assinatura de e-mail",
        "change-password": {
            "title": "Altere sua senha",
            "old-password": "Senha atual",
            "new-password": "Nova senha",
            "rewrite-new-password": "Confirme a nova senha",
            "changed-password": "Senha alterada",
            "success": "Senha alterada com sucesso",
            "help":
                "Após a alteração será redirecionado para a página de login, " +
                "onde poderá efetuar login com sua nova senha."
        },
        "confirm-remove-signature": "Você tem certeza que deseja remover a assinatura?",
        "help-signature": "Apenas arquivos de imagem são aceitos."
    },
    "smtp-server": {
        "call-to-action": "Configure aqui sua forma de envio de e-mails",
        "type": {
            "label": "Modo de envio",
            "LS": "Servidor do LeadStation",
            "CUSTOM": "Outro servidor"
        },
        "pre-settings": {
            "label": "Utilizar pré configuração",
            "subscription": "Configuração da inscrição"
        },
        "host": "Host",
        "security-type": "Tipo de segurança",
        "port": "Porta",
        "auth": "Com autenticação",
        "no-auth": "Sem autenticação",
        "username": {
            "label": "Usuário",
            "help": "Geralmente é igual ao e-mail"
        },
        "amazon-help": "Para utilizar este SMTP, é necessária a criação de credenciais de acesso em sua conta Amazon. ",
        "amazon-help-docs": "Clique aqui para acessar a documentação.",
        "gmail-help":
            "O Gmail irá solicitar que você \"Revise tentativa de login bloqueada\" através de um e-mail.",
        "submit": {
            "success": "Configurações salvas com sucesso!",
            "auth-error":
                "Não foi possível autenticar. Verifique se os dados estão corretos, sua caixa de entrada e " +
                "tente novamente.",
            "unknown-error": "Não foi possível salvar as configurações. Verifique os dados e tente novamente."
        }
    },
    "theme": {
        "label": "Tema",
        "light": "Claro",
        "dark": "Escuro"
    },
    "gmail": {
        "auth": "Autenticar com o Google",
        "connected": "Conta conectada",
        "expired": "Conta expirada"
    },
    "schedule": {
        "title":  "Agenda",
        "integration": {
            "title": "Integração de agenda",
            "call-to-action":
                "Configure aqui uma conta para integrar a agenda com o LeadStation. A partir desta configuração, " +
                "você poderá visualizar no LeadStation os eventos da sua agenda pessoal e os agendamentos criados " +
                "pela nossa plataforma serão vinculados na sua agenda."
        },
        "color": "Cor para exibir os agendamentos na Agenda do LeadStation"
    },
    "register": "Cadastro",
    "system": "Sistema",
    "save": "Salvar",
    "remove": "Remover",
    "yes": "Sim",
    "no": "Não",
    "tabs": {
        "person": "Informações pessoais",
        "email": "Envio de e-mail",
        "templates": "Templates",
        "schedule": "Agenda"
    },
    "images": {
        "title": "Minha galeria",
        "call-to-action": "Aqui estão listadas as imagens cadastradas que podem ser utilizadas nos seus templates",
        "confirm-delete": "Você tem certeza que deseja excluir esta imagem da sua galeria?"
    },
    "templates": {
        "title": "Meus templates",
        "call-to-action": "Você pode configurar aqui os seus templates e compartilha-los com demais usuários"
    }
};