import Typography from "@mui/material/Typography";
import difference from "lodash/difference";

import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils } from "../../ui";
import css from "./timeline.scss";

const mapChangedItem = ( from, to, { label, className, t }) => {
    const items = difference( from, to );

    return !items.length ? null : items.map( ( email, index ) => (
        <li key={ index }>
            <Typography>
                <span className={ css.emailChangeLabel }>{ t( label ) + " " }</span>
                <mark className={ className }>
                    { email }
                </mark>
            </Typography>
        </li>
    ));
};

const EmailChangeList = ({ change }) => {
    const t = useTranslate();

    const previous = mapChangedItem( change.previous, change.current, {
        t,
        label: "timeline:change.removed",
        className: cssUtils.highlightOld
    });
    const current = mapChangedItem( change.current, change.previous, {
        t,
        label: "timeline:change.added",
        className: cssUtils.highlightNew
    });

    return <ul className={ css.emailChangeList }>{ previous }{ current }</ul>;
};

export default EmailChangeList;