import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../../../hooks/use-translate.hook";
import { createCommission } from "./commission.actions";
import { NEW_COMMISSION_FORM } from "./commission.constants";
import CommissionForm, { validate } from "./commission-form.component";

const NewCommission = ({ onClose, open }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_COMMISSION_FORM,
        initialValues: {
            name: "",
            type: "",
            products: [],
            funnel: {
                id: "",
            },
            priceType: "",
            price: null,
            closingDay: null,
            goalId: null,
            teamId: null,
            usersId: [],
        },
        onSubmit: values => dispatch( createCommission( values ) ),
        onSubmitSuccess: onClose,
        validate,
    });

    return (
        <Dialog open={ open } fullWidth maxWidth="sm">
            <DialogTitle>
                { t( "config:commission.new" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik } noValidate>
                    <CommissionForm setFieldValue={ formik.setFieldValue } values={ formik.values } />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewCommission;