import { FieldArray } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import get from "lodash/get";

import { cssUtils } from "../../../ui";
import useTranslate from "../../../hooks/use-translate.hook";
import PhaseAutomationActivityRegistered from "./phase-automation-activity-registered.component";
import PhaseAutomationSchedulesRegisteredList from "./phase-automation-schedules-registered-list.component";
import css from "./funnel.scss";

const PhaseAutomation = ({ name, onChange, onClose, onSave, open, values }) => {
    const t = useTranslate();

    const showPhaseEmailSent = get( values, "showPhaseEmailSent", false );
    const showPhaseWhatsappSent = get( values, "showPhaseWhatsappSent", false );
    const showPhasePhoneCallCreated = get( values, "showPhasePhoneCallCreated", false );
    const showPhaseSmsSent = get( values, "showPhaseSmsSent", false );
    const showPhaseCommentCreated = get( values, "showPhaseCommentCreated", false );

    const toggleShowEmail = () => {
        onChange( `${name}.showPhaseEmailSent`, !showPhaseEmailSent );
        onChange( `${name}.phaseEmailSent`, null );
    };

    const toggleShowWhatsapp = () => {
        onChange( `${name}.showPhaseWhatsappSent`, !showPhaseWhatsappSent );
        onChange( `${name}.phaseWhatsappSent`, null );
    };

    const toggleShowCall = () => {
        onChange( `${name}.showPhasePhoneCallCreated`, !showPhasePhoneCallCreated );
        onChange( `${name}.phasePhoneCallCreated`, null );
    };

    const toggleShowSms = () => {
        onChange( `${name}.showPhaseSmsSent`, !showPhaseSmsSent );
        onChange( `${name}.phaseSmsSent`, null );
    };

    const toggleShowComment = () => {
        onChange( `${name}.showPhaseCommentCreated`, !showPhaseCommentCreated );
        onChange( `${name}.phaseCommentCreated`, null );
    };

    return (
        <Dialog
            open={ open }
            onClose={ onClose }
            maxWidth="md"
            fullWidth
        >
            <DialogTitle className={ cssUtils.paddingBottomSmall }>
                { t("config:phases.automation-activity.title" ) }
            </DialogTitle>
            <DialogContent>
                <div className={ css.phaseAutomationContainer }>
                    <DialogContentText>
                        { t("config:phases.automation-activity.info" ) }
                    </DialogContentText>
                    <Divider/>
                    <div>
                        <Typography component="h6" className={ css.phaseAutomationTitle }>
                            { t("config:phases.automation-activity.registered-activity-opportunity") }
                        </Typography>
                        <div
                            className={
                                classnames(
                                    cssUtils.paddingLeft,
                                    cssUtils.marginTopSmall,
                                    css.phaseAutomationItemContainer
                                )
                            }
                        >
                            <PhaseAutomationActivityRegistered
                                onChange={ toggleShowEmail }
                                checked={ showPhaseEmailSent }
                                formControlLabel={ t( "common:email" ) }
                                formLabel={ t( "config:phases.automation-activity.quantity" ) }
                                formName={ `${name}.phaseEmailSent` }
                            />
                            <PhaseAutomationActivityRegistered
                                onChange={ toggleShowWhatsapp }
                                checked={ showPhaseWhatsappSent }
                                formControlLabel={ t( "config:phases.automation-activity.whatsapp" ) }
                                formLabel={ t( "config:phases.automation-activity.quantity" ) }
                                formName={ `${name}.phaseWhatsappSent` }
                            />
                            <PhaseAutomationActivityRegistered
                                onChange={ toggleShowCall }
                                checked={ showPhasePhoneCallCreated }
                                formControlLabel={ t( "config:phases.automation-activity.call" ) }
                                formLabel={ t( "config:phases.automation-activity.quantity" ) }
                                formName={ `${name}.phasePhoneCallCreated` }
                            />
                            <PhaseAutomationActivityRegistered
                                onChange={ toggleShowSms }
                                checked={ showPhaseSmsSent }
                                formControlLabel={ t( "config:phases.automation-activity.sms" ) }
                                formLabel={ t( "config:phases.automation-activity.quantity" ) }
                                formName={ `${name}.phaseSmsSent` }
                            />
                            <PhaseAutomationActivityRegistered
                                checked={ showPhaseCommentCreated }
                                onChange={ toggleShowComment }
                                formControlLabel={ t( "config:phases.automation-activity.comment" ) }
                                formLabel={ t( "config:phases.automation-activity.quantity" ) }
                                formName={ `${name}.phaseCommentCreated` }
                            />
                        </div>
                    </div>
                    <Divider/>
                    <div>
                        <Typography component="h6" className={ css.phaseAutomationTitle }>
                            { t("config:phases.automation-activity.registered-schedules-opportunity" ) }
                        </Typography>
                        <FieldArray
                            name={ `${name}.phaseSchedulingStatus` }
                            component={ PhaseAutomationSchedulesRegisteredList }
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" variant="contained" onClick={ onSave }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default PhaseAutomation;