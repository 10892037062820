import { useDispatch } from "react-redux";
import { FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { executePhaseTriggers } from "./opportunity.actions";
import { PHASE_TRIGGERS_FORM } from "./opportunity.constants";
import { Dialog, cssUtils, flex } from "../ui";
import PhaseTrigger from "./phase-trigger-field.component";

const PhaseTriggers = ({ opportunity, show, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: PHASE_TRIGGERS_FORM,
        enableReinitialize: true,
        initialValues: {
            opportunityId: opportunity && opportunity.id,
            triggers: opportunity && opportunity.phase.triggers ?
                opportunity.phase.triggers
                    .filter( trigger => !trigger.automatic )
                    .map( trigger => ({ ...trigger, checked: false, body: trigger.template.body }) ) :
                []
        },
        onSubmit: values => dispatch( executePhaseTriggers( values ) ),
        onSubmitSuccess: onClose,
    });

    return (
        <Dialog
            open={ show }
            fullScreen
            onClose={ onClose }
            fullWidth
            TransitionComponent={ Slide }
        >
            <AppBar position="relative">
                <Toolbar>
                    <Tooltip title={ t( "common:close" ) }>
                        <IconButton color="inherit" onClick={ onClose } disabled={ formik.submitting } size="large">
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6" color="inherit" className={ flex.fill }>
                        { t( "opportunity:phases.triggers" ) }
                    </Typography>
                    <Button color="inherit" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "opportunity:phases.send" ) }
                    </Button>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <DialogContentText className={ classnames( cssUtils.marginTop, cssUtils.marginBottom ) }>
                    { t( "opportunity:phases.execute-triggers" ) }
                </DialogContentText>
                <Form formik={ formik } noValidate>
                    <FieldArray name="triggers" component={ PhaseTrigger }/>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default PhaseTriggers;