import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import OpacityIcon from "@mui/icons-material/Opacity";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import classnames from "classnames";
import get from "lodash/get";

import { cssUtils, flex } from "../ui";
import { getSelected } from "./opportunity.selectors";
import { patchOpportunity } from "./opportunity.actions";
import OpportunityTemperatureItem from "./opportunity-temperature-item.component";

const OpportunityTemperature = () => {
    const dispatch = useDispatch();

    const opportunity = useSelector( getSelected );

    const handleChange = useCallback( temperature => {
        dispatch( patchOpportunity({
            id: opportunity.id,
            temperature: temperature === opportunity.temperature ? null : temperature
        }));
    }, [ dispatch, opportunity.id, opportunity.temperature ] );

    const temperature = get( opportunity, "temperature" );

    return (
        <div className={ classnames( flex.container, cssUtils.margin ) }>
            <OpportunityTemperatureItem
                value="COLD"
                icon={ <AcUnitIcon/> }
                selected={ temperature }
                onClick={ handleChange }
            />
            <OpportunityTemperatureItem
                value="WARM"
                icon={ <OpacityIcon/> }
                selected={ temperature }
                onClick={ handleChange }
            />
            <OpportunityTemperatureItem
                value="HOT"
                icon={ <WhatshotIcon/> }
                selected={ temperature }
                onClick={ handleChange }
            />
        </div>
    );
};

export default OpportunityTemperature;