import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import qs from "qs";

import history from "../../history";
import usePriceFormat from "../hooks/use-price-format.hook";
import useTranslate from "../hooks/use-translate.hook";
import { getUser } from "../core/core.selectors";
import { setSource } from "../opportunity/opportunity.actions";
import { FunnelFilter, selectors as opportunitySelectors } from "../opportunity";
import { selectors as sourceSelectors } from "../source";
import { selectors as teamSelectors } from "../team";
import { selectors as userSelectors } from "../user";
import { Dialog, Dropdown, DropdownMultiple, cssUtils, flex } from "../ui";
import { fetchSources } from "../source/source.actions";
import { fetchResults } from "./report.actions";
import { isLoadingResults, getResults } from "./report.selectors";
import css from "./opportunity-tracking-by-user.scss";
import { useDateFormat } from "../hooks/use-date-format.hook";

function roundNumber ( num ) {
    return +( Math.round( num + "e+2" ) + "e-2" );
}

const percentage = item => {
    if ( !item.soldOpportunities || !item.doneOpportunities ) {
        return 0;
    }
    return roundNumber( item.soldOpportunities * 100 / item.doneOpportunities );
};

const percentageAll = results => {
    const soldOpportunities = results.map( item => item.soldOpportunities ).reduce( sum );
    const doneOpportunities = results.map( item => item.doneOpportunities ).reduce( sum );

    return percentage({ soldOpportunities, doneOpportunities });
};

const sum = ( i1, i2 ) => i1 + i2;

const OpportunityTrackingByUser = ({ initialDate, finalDate }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();
    const priceFormat = usePriceFormat();
    const dateFormat = useDateFormat( t );

    const queryString = useMemo( () => qs.parse( location.search, { ignoreQueryPrefix: true } ), [ location ] );

    const funnelId = useSelector( opportunitySelectors.getFunnelId );
    const sourceId = useSelector( opportunitySelectors.getSourceId );
    const sources = useSelector( sourceSelectors.getSources );
    const teams = useSelector( teamSelectors.listAll );
    const user = useSelector( getUser );
    const users = useSelector( userSelectors.listAllPermitted );
    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ columns, setColumns ] = useState( queryString.columns ?
        queryString.columns.split( "," ) :
        [ "open", "notWorked", "schedules", "done", "sold" ]
    );
    const [ open, setOpen ] = useState( queryString.open ? Boolean( queryString.open ) : false );
    const [ openHelp, setOpenHelp ] = useState( true );
    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState(
        queryString.usersId ?
            queryString.usersId.split( "," ).map( id => Number( id ) ) :
            []
    );
    const startDate = useMemo( () => queryString.initialDate || initialDate, [ initialDate, queryString.initialDate ] );
    const endDate = useMemo( () => queryString.finalDate || finalDate, [ finalDate, queryString.finalDate ] );

    const handleClose = () => setOpen( false );
    const handleCloseHelp = () => setOpenHelp( false );

    const openFullscreen = () => {
        const elem = document.documentElement;
        if ( !document.fullscreenElement ) {
            if ( elem.requestFullscreen ) {
                elem.requestFullscreen();
            } else if ( elem.mozRequestFullScreen ) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if ( elem.webkitRequestFullscreen ) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if ( elem.msRequestFullscreen ) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        } else {
            if ( document.exitFullscreen ) {
                document.exitFullscreen();
            } else if ( document.mozCancelFullScreen ) { /* Firefox */
                document.mozCancelFullScreen();
            } else if ( document.webkitExitFullscreen ) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if ( document.msExitFullscreen ) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    };

    const openRankingNewTab = () => {
        window.open(
            history.createHref({
                pathname: "/reports",
                search: qs.stringify({
                    report: "OPPORTUNITY_TRACKING_BY_USER",
                    open: true,
                    columns: columns.toString(),
                    funnelId,
                    sourceId,
                    usersId: usersId.toString(),
                    initialDate: initialDate.toISOString(),
                    finalDate: finalDate.toISOString()
                })
            })
        );
    };

    const openRanking = () => setOpen( true );

    const handleChangeColumns = column => {
        const previousValue = columns;
        const newValue = previousValue.includes( column ) ?
            previousValue.filter( item => item !== column ) :
            previousValue.concat( column );
        setColumns( newValue );
    };

    const handleChangeTeam = teamId => {
        if ( teamId ) {
            const teamUsers = users
                .filter( u => !!u.teams.find( item => item.team.id.toString() === teamId.toString() ) )
                .map( u => u.id );
            setUsersId( teamUsers );
        }
        setTeamId( teamId );
    };

    const handleChangeUsers = usersId => {
        setUsersId( usersId );
        setTeamId( null );
    };

    const loadResults = useCallback( () => {
        if ( !funnelId ) {
            return;
        }
        dispatch( fetchResults({
            report: "OPPORTUNITY_TRACKING_BY_USER",
            funnelsId: funnelId,
            sourceId,
            usersId: usersId.toString(),
            initialDate: startDate,
            finalDate: endDate,
        }) );
    }, [ dispatch, endDate, funnelId, sourceId, startDate, usersId ] );

    const source = useMemo( () => {
        if ( isEmpty( results ) ) {
            return [];
        }

        return orderBy( results.map( item => ({
            ...item,
            openOpportunities: item.openOpportunities + "",
            opportunitiesWithScheduling: item.opportunitiesWithScheduling + "",
            doneOpportunities: item.doneOpportunities + "",
            soldOpportunities: item.soldOpportunities + "",
            conversionRate: percentage( item )
        })), [ "conversionRate" ], [ "desc" ] );

    }, [ results ] );

    useEffect( () => {
        dispatch( fetchSources() );
    }, [ dispatch ] );
    useEffect( () => {
        let interval;
        if ( open ) {
            loadResults();
            interval = setInterval( loadResults, 60000 );
        }
        return () => {
            clearInterval( interval );
        };
    }, [ loadResults, open ] );

    const userTeams = get( user, "teams" );

    const sourceTeams = get( user, "admin" ) ?
        teams.map( team => ({ value: team.id, label: team.name }) ) :
        sortBy(
            userTeams.map( userTeam =>
                ({ value: userTeam.team.id, label: userTeam.team.name })
            ),
            [ "label" ] );

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.OPPORTUNITY_TRACKING_BY_USER" ) }
                subheader={ t( "report:opportunity-tracking-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.column, cssUtils.padding, cssUtils.marginBottomSmall
                )}
            >
                <div className={ classnames( flex.container, flex.alignItemsCenter, flex.fill ) }>
                    <FunnelFilter
                        fullWidth={ false }
                        className={ flex.fill }
                    />
                    <Dropdown
                        margin="none"
                        label={ t( "report:filters.source" ) }
                        fullWidth={ false }
                        className={ classnames( cssUtils.marginLeft, flex.fill )}
                        emptyValue={ true }
                        onChange={ event => dispatch( setSource( event.target.value ) ) }
                        value={ sourceId }
                        source={ sources?.map( source => ({ value: source.id, label: source.name }) ) }
                        disabled={ isEmpty( sources ) }
                    />
                    <Dropdown
                        label={ isEmpty( userTeams ) ?
                            t( "report:filters.empty-team" ) :
                            t( "report:filters.team" )
                        }
                        fullWidth={ false }
                        className={ classnames( cssUtils.marginLeft, flex.fill ) }
                        onChange={ event => handleChangeTeam( event.target.value ) }
                        value={ teamId }
                        source={ sourceTeams }
                        disabled={ isEmpty( userTeams ) }
                    />
                    <DropdownMultiple
                        className={ classnames( cssUtils.marginLeftSmall, flex.fill ) }
                        label={ t( "report:filters.users" ) }
                        source={ users.map( user => ({ label: user.name, value: user.id })) }
                        onChange={ event => handleChangeUsers( event.target.value ) }
                        value={ usersId }
                    />
                </div>
                <FormControl className={ classnames( flex.fill, cssUtils.marginTop ) }>
                    <FormLabel
                        className={ cssUtils.marginBottomSmall }
                        component="legend"
                    >
                        { t( "report:filters.columns" ) }
                    </FormLabel>
                    <FormGroup row>
                        {
                            [
                                { value: "open", label: t( "report:opportunity-tracking-by-user.open" ) },
                                { value: "notWorked",
                                    label: t( "report:opportunity-tracking-by-user.not-worked" ) },
                                { value: "schedules", label: t( "report:opportunity-tracking-by-user.schedules" ) },
                                { value: "done", label: t( "report:opportunity-tracking-by-user.done" ) },
                                { value: "sold", label: t( "report:opportunity-tracking-by-user.sold" ) },
                                { value: "price", label: t( "report:opportunity-tracking-by-user.price" ) },
                            ].map( item => (
                                <FormControlLabel
                                    key={ item.value }
                                    className={ flex.fill }
                                    control={
                                        <Checkbox
                                            className={
                                                classnames( cssUtils.paddingBottom0, cssUtils.paddingTop0 )
                                            }
                                            checked={ columns.includes( item.value ) }
                                            onChange={ () => handleChangeColumns( item.value ) }
                                        />
                                    }
                                    label={ item.label }
                                />
                            ))
                        }
                    </FormGroup>
                </FormControl>

                <div className={ classnames( flex.container, cssUtils.marginTopSmall ) }>
                    <div className={ flex.fill }/>
                    <Button
                        onClick={ openRanking }
                        className={ cssUtils.marginRight }
                        endIcon={ <OpenInBrowserIcon/> }
                        disabled={ !funnelId }
                    >
                        { t( "common:open-this-window" ) }
                    </Button>

                    <Button
                        onClick={ openRankingNewTab }
                        endIcon={ <OpenInNewIcon/> }
                        disabled={ !funnelId }
                    >
                        { t( "common:open-new-window" ) }
                    </Button>
                </div>
            </Paper>

            <Dialog
                fullScreen
                fullWidth
                open={ open }
                onClose={ handleClose }
                classes={{ paper: css.dialog }}
            >
                <AppBar position="relative" color="secondary">
                    <Toolbar>
                        <div className={ css.logo }/>
                        <div className={ classnames( flex.fill, cssUtils.textCenter ) }>
                            <Typography variant="h6" className={ flex.fill }>
                                { dateFormat( startDate ) + " - " + dateFormat( endDate ) }
                            </Typography>
                        </div>
                        <IconButton
                            className={ cssUtils.colorBlack }
                            onClick={ openFullscreen }
                            size="large">
                            <FullscreenIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                { loading && <LinearProgress/> }

                <Table>
                    <TableHead className={ css.tableHead }>
                        <TableRow>
                            <TableCell className={ css.tableCellAvatar }>
                                { "" }
                            </TableCell>
                            <TableCell className={ css.tableHeadCell }>
                                { "" }
                            </TableCell>
                            {
                                columns.includes( "open" ) &&
                                    <TableCell className={ css.tableHeadCellSmall } align="center">
                                        { t( "report:opportunity-tracking-by-user.open" ) }
                                    </TableCell>
                            }
                            {
                                columns.includes( "notWorked" ) &&
                                    <TableCell
                                        className={ classnames( css.tableCellNotWorked, css.tableHeadCellSmall ) }
                                        align="center"
                                    >
                                        { t( "report:opportunity-tracking-by-user.not-worked" ) }
                                    </TableCell>
                            }
                            {
                                columns.includes( "schedules" ) &&
                                    <TableCell className={ css.tableHeadCellSmall } align="center">
                                        { t( "report:opportunity-tracking-by-user.schedules" ) }
                                    </TableCell>
                            }
                            {
                                columns.includes( "done" ) &&
                                    <TableCell className={ css.tableHeadCellSmall } align="center">
                                        { t( "report:opportunity-tracking-by-user.done" ) }
                                    </TableCell>
                            }
                            {
                                columns.includes( "sold" ) &&
                                    <TableCell
                                        className={ classnames( css.tableHeadCellSmall, css.tableCellSold ) }
                                        align="center"
                                    >
                                        { t( "report:opportunity-tracking-by-user.sold" ) }
                                    </TableCell>
                            }
                            {
                                columns.includes( "price" ) &&
                                    <TableCell
                                        className={ classnames( css.tableBodyCell, css.tableCellSold ) }
                                        align="center"
                                    >
                                        <h5 className={ cssUtils.margin0 }>
                                            { t( "report:opportunity-tracking-by-user.price" ) }
                                        </h5>
                                        <h5 className={ cssUtils.margin0 }>
                                            { t( "report:opportunity-tracking-by-user.recurrent-price" ) }
                                        </h5>
                                    </TableCell>
                            }
                            <TableCell className={ css.tableHeadCellSmall } align="center">
                                { t( "report:opportunity-tracking-by-user.conversion-rate" ) }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={ css.tableBody }>
                        {
                            source.map( ( item, index ) => (
                                <TableRow key={ index }>
                                    <TableCell className={ css.tableCellAvatar }>
                                        <Avatar src={ item.avatar }>
                                            { !item.avatar && item.name.charAt( 0 ).toUpperCase() }
                                        </Avatar>
                                    </TableCell>
                                    <TableCell className={ css.tableBodyCell }>
                                        { item.name }
                                    </TableCell>
                                    {
                                        columns.includes( "open" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                { item.openOpportunities }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "notWorked" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableCellNotWorked, css.tableBodyCellSmall )
                                                }
                                                align="center"
                                            >
                                                { item.openOpportunitiesNotWorked }
                                            </TableCell>

                                    }
                                    {
                                        columns.includes( "schedules" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                { item.opportunitiesWithScheduling }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "done" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                { item.doneOpportunities }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "sold" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableBodyCellSmall, css.tableCellSold )
                                                }
                                                align="center"
                                            >
                                                { item.soldOpportunities }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "price" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableBodyCell, css.tableCellSold )
                                                }
                                                align="center"
                                            >
                                                <Typography variant="h5" className={ cssUtils.margin0 }>
                                                    { priceFormat( item.soldOpportunitiesPrice ) }
                                                </Typography>
                                                <Typography variant="h5" className={ cssUtils.margin0 }>
                                                    { priceFormat( item.soldOpportunitiesRecurrentPrice ) }
                                                </Typography>
                                            </TableCell>
                                    }
                                    <TableCell className={ css.tableBodyCellSmall } align="center">
                                        { `${item.conversionRate}%` }
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                        {
                            !isEmpty( results ) &&
                                <TableRow className={ css.tableFooter }>
                                    <TableCell className={ css.tableCellAvatar }>
                                        { "" }
                                    </TableCell>
                                    <TableCell className={ css.tableBodyCell }>
                                        { t( "report:opportunity-tracking-by-user.total" ) }
                                    </TableCell>
                                    {
                                        columns.includes( "open" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                { results.map( item => item.openOpportunities ).reduce( sum ) }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "notWorked" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableCellNotWorked, css.tableBodyCellSmall )
                                                }
                                                align="center"
                                            >
                                                {
                                                    results
                                                        .map( item => item.openOpportunitiesNotWorked )
                                                        .reduce( sum )
                                                }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "schedules" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                {
                                                    results
                                                        .map( item => item.opportunitiesWithScheduling )
                                                        .reduce( sum )
                                                }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "done" ) &&
                                            <TableCell className={ css.tableBodyCellSmall } align="center">
                                                { results.map( item => item.doneOpportunities ).reduce( sum ) }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "sold" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableBodyCellSmall, css.tableCellSold )
                                                }
                                                align="center"
                                            >
                                                { results.map( item => item.soldOpportunities ).reduce( sum ) }
                                            </TableCell>
                                    }
                                    {
                                        columns.includes( "price" ) &&
                                            <TableCell
                                                className={
                                                    classnames( css.tableBodyCell, css.tableCellSold )
                                                }
                                                align="center"
                                            >
                                                <Typography variant="h5" className={ cssUtils.margin0 }>
                                                    {
                                                        priceFormat(
                                                            results
                                                                .map( item => item.soldOpportunitiesPrice )
                                                                .reduce( sum )
                                                        )
                                                    }
                                                </Typography>
                                                <Typography variant="h5" className={ cssUtils.margin0 }>
                                                    {
                                                        priceFormat(
                                                            results
                                                                .map( item => item.soldOpportunitiesRecurrentPrice )
                                                                .reduce( sum )
                                                        )
                                                    }
                                                </Typography>
                                            </TableCell>
                                    }
                                    <TableCell className={ css.tableBodyCellSmall } align="center">
                                        { `${percentageAll( results )}%` }
                                    </TableCell>
                                </TableRow>
                        }

                    </TableBody>

                </Table>

                <Snackbar
                    open={ openHelp }
                    autoHideDuration={ 5000 }
                    onClose={ handleCloseHelp }
                    message={ t( "report:opportunity-tracking-by-user.help-exit" ) }
                />
            </Dialog>
        </div>
    );
};

export default OpportunityTrackingByUser;