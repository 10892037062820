import Avatar from "@mui/material/Avatar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import dayjs from "dayjs";

import useTranslate from "../../../hooks/use-translate.hook";
import ResendInvite from "./resend-invite.component";
import DeleteInvite from "./delete-invite.component";

export const DATE_FORMAT = "DD/MM/YYYY HH:mm";

const InvitationItem = ({ data }) => {
    const t = useTranslate();
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <PersonOutlineIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={ data.name + " - " + data.email }
                secondary={
                    <span dangerouslySetInnerHTML={{
                        __html: t( "config:users.pending-invite" ) + " - " + t( "config:invitations.sent-at", {
                            date: dayjs( data.createdAt ).format( DATE_FORMAT )
                        })
                    }} />
                }
            />
            <ResendInvite invitation={ data } />
            <DeleteInvite invitation={ data }/>
        </ListItem>
    );
};

export default InvitationItem;