import { useCallback, useMemo } from "react";
import dayjs from "dayjs";

export const useDateFormat = t => {
    const format = useMemo( () => t( "common:date" ), [ t ] );

    const dateFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return dayjs( value ).format( format );
    }, [ format ] );

    return dateFormat;
};

export const useDateTimeFormat = t => {
    const format = useMemo( () => t( "common:full-date" ), [ t ] );

    const dateTimeFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return dayjs( value ).format( format );
    }, [ format ] );

    return dateTimeFormat;
};

export const useTimeFormat = t => {
    const format = useMemo( () => t( "common:format-hour" ), [ t ] );

    const timeFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return dayjs( value ).format( format );
    }, [ format ] );

    return timeFormat;
};

export const useDayMonthFormat = t => {
    const format = useMemo( () => t( "common:day-month" ), [ t ] );

    const dayMonthFormat = useCallback( value => {
        if ( !value ) {
            return "";
        }
        return dayjs( value ).format( format );
    }, [ format ] );

    return dayMonthFormat;
};
