import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { timeDuration } from "../core/formatter.utils";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const AverageServiceTimeByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState( null );
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo(
        () => order( results || [], [ orderBy ], [ orderType.toLowerCase() ] ),
        [ results, orderBy, orderType ]
    );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true,
            text: null
        },
        yAxis: {
            min: 0,
            allowDecimals: false,
            title: {
                text: t( "report:average-service-time-by-user.average-minutes" )
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        tooltip: {
            formatter: function () {
                return this.series.name +
                    ": " +
                    timeDuration( orderedResults.find( item => item.id === this.point.id ).averageMinutes );
            }
        },
        series: [{
            name: t( "report:average-service-time-by-user.average-minutes" ),
            data: orderedResults.map(
                item => ({
                    id: item.id,
                    y: +( Math.round( ( item.averageMinutes ? item.averageMinutes / 60 : 0 ) + "e+2" ) + "e-2" )
                })
            )
        }]
    }), [ t, orderedResults ] );

    const source = useMemo( () => orderedResults.map( item => ({
        ...item,
        totalOpportunities: item.totalOpportunities + "",
        totalOpportunitiesWorked: item.totalOpportunitiesWorked + "",
        averageMinutes: timeDuration( item.averageMinutes )
    })), [ orderedResults ] );

    const model = {
        name: t( "report:average-service-time-by-user.user" ),
        totalOpportunities: t( "report:average-service-time-by-user.total-opportunities" ),
        totalOpportunitiesWorked: t( "report:average-service-time-by-user.total-opportunities-worked" ),
        averageMinutes: t( "report:average-service-time-by-user.average-minutes" )
    };

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.AVERAGE_SERVICE_TIME_BY_USER" ) }
                subheader={ t( "report:average-service-time-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    onChange={ handleChange }
                    report={ "AVERAGE_SERVICE_TIME_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="averageServiceTimeByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="averageServiceTimeByUserTable"
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    title={ t( "report:reports.OPPORTUNITIES_BY_SOURCE" ) }
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default AverageServiceTimeByUser;