import { Fragment, useEffect, useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReorderIcon from "@mui/icons-material/Reorder";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import reject from "lodash/reject";

import usePrevious from "../../hooks/use-previous.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { listAll as listFunnels } from "../../funnel/funnel.selectors";
import { getSelected } from "../opportunity.selectors";
import { actions as productActions, selectors as productSelectors } from "../../product";
import { Autocomplete, Input, Outlined, PriceInput, cssUtils, flex } from "../../ui";

const getTotalPrice = ( product = {}, quantity = 0, discount = 0 ) => {
    const totalPrice = ( quantity * ( product.price || 0 ) );

    return totalPrice ? totalPrice - ( totalPrice * discount / 100 ) : 0;
};

const ProposalProductsItem = ({ index, form, onRemove }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const loading = useSelector( productSelectors.isLoadingProducts );
    const funnels = useSelector( listFunnels );
    const opportunity = useSelector( getSelected );
    const funnel = funnels.find( funnel => funnel.id === opportunity.funnelId );
    const products = useSelector( productSelectors.getActiveProducts );

    const formValues = useMemo( () => form.values.products[ index ], [ index, form.values.products ] );

    const [ open, setOpen ] = useState( !formValues.id );
    const [ timeoutSearch, setTimeoutSearch ] = useState();

    const handleOpen = () => setOpen( true );
    const handleClose = () => {
        if ( isEmpty( form.errors ) ) {
            setOpen( false );
            return;
        }
        if ( isEmpty( reject( form.errors.products[ index ], isEmpty ) ) ) {
            setOpen( false );
        } else {
            form.setFieldTouched( `products[${ index }].product` );
            form.setFieldTouched( `products[${ index }].quantity` );
        }
    };

    const handleCancel = () => {
        setOpen( false );
        const initialValues = form.initialValues.products[ index ];
        if ( !isEmpty( initialValues) ) {
            form.setFieldValue( `products[${index}]`, initialValues );
        } else {
            onRemove();
        }
    };

    const searchProduct = description => {
        const categoriesId = funnel.productCategories ?
            funnel.productCategories.map( productCategory => productCategory.id ) :
            [];
        clearTimeout( timeoutSearch );
        if ( description ) {
            setTimeoutSearch( setTimeout( () => {
                dispatch( productActions.fetchProducts({ description, categoriesId: categoriesId.toString() }) );
            }, 300 ) );
        }
    };

    const product = useMemo( () => formValues.product || {}, [ formValues.product ] );
    const subtotal = useMemo( () => getTotalPrice( product, formValues.quantity ), [ product, formValues.quantity ] );
    const setFieldValue = useMemo( () => form.setFieldValue, [ form.setFieldValue ] );

    const prevFormValues = usePrevious( formValues );

    useEffect( () => {
        const totalPrice = getTotalPrice( formValues.product || {}, formValues.quantity, formValues.discount );
        setFieldValue( `products[${index}].totalPrice`, totalPrice );
        if ( !isEqual( formValues.discount, prevFormValues?.discount ) && formValues.discount === "" ) {
            setFieldValue( `products[${index}].discount`, null );
            setFieldValue( `products[${index}].discountUnit`, null );
        }
    }, [ prevFormValues, formValues, index, setFieldValue ] );

    const renderDiscount = subtotal => {
        if ( !formValues.discount ) {
            return "";
        }
        const discount = priceFormat( subtotal - formValues.totalPrice );

        return ` (${t( "opportunity:proposal-product.of-discount", { discount } )})`;
    };

    const productsSource = products.map( product => ({
        value: product,
        label: ( product.code ? product.code + " - " : "" ) + product.description,
        helper: product.recurrent ?
            t( "config:product.with-recurrence" ) :
            t( "config:product.without-recurrence" )
    }));

    return (
        <Draggable draggableId={ index.toString() } index={ index }>
            {
                provided => (
                    <div
                        ref={ provided.innerRef }
                        { ...provided.draggableProps }
                    >
                        <Outlined
                            className={ cssUtils.marginTopTiny }
                            classes={{ root: !open && cssUtils.padding0 }}
                        >
                            <Fragment>
                                {
                                    !open &&
                                        <ListItem>
                                            <ListItemText
                                                primary={ `${product.description} (${formValues.quantity})` }
                                                secondary={
                                                    priceFormat( formValues.totalPrice ) +
                                                    renderDiscount( subtotal )
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={ handleOpen } size="large">
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton onClick={ onRemove } size="large">
                                                    <DeleteIcon/>
                                                </IconButton>
                                                <IconButton { ...provided.dragHandleProps } size="large">
                                                    <ReorderIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                }
                                <Collapse in={ open }>
                                    {
                                        !isEmpty( funnel.productCategories ) &&
                                            <Typography color="textSecondary" variant="caption">
                                                { t( "opportunity:funnel-product-categories", {
                                                    productCategories: funnel.productCategories
                                                        .map( productCategory => productCategory.name )
                                                        .join( ", " )
                                                } ) }
                                            </Typography>
                                    }
                                    <Field
                                        name={ `products[${index}].product` }
                                        source={ productsSource }
                                        label={ t( "opportunity:proposal-product.product" ) }
                                        component={ Autocomplete }
                                        multiple={ false }
                                        loading={ loading }
                                        onQueryChange={ searchProduct }
                                        getOptionLabel={ option => option ?
                                            ( option.value ? option.value.description : option.description || "" ) :
                                            ""
                                        }
                                        filterOptions={ options => options }
                                        required
                                    />
                                    <Typography variant="caption" className={ cssUtils.marginBottomSmall }>
                                        { t(
                                            "opportunity:proposal-product.price-by-unit",
                                            {
                                                measurementUnit: t(
                                                    "opportunity:proposal-product.measurement-unit." +
                                                    get( product, "measurementUnit", "UNIT" )
                                                ),
                                                price: priceFormat( get( product, "price" ) )
                                            }
                                        )}
                                    </Typography>
                                    <Field
                                        name={ `products[${index}].quantity` }
                                        type="number"
                                        min="0"
                                        label={ t( "opportunity:proposal-product.quantity" ) }
                                        component={ Input }
                                        required
                                    />
                                    <Typography variant="caption" className={ cssUtils.marginBottomSmall }>
                                        { t(
                                            "opportunity:proposal-product.subtotal",
                                            {
                                                total: priceFormat( subtotal )
                                            }
                                        )}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        display="block"
                                        className={ cssUtils.marginTopSmall }
                                    >
                                        { t( "opportunity:proposal-product.discount" ) }
                                    </Typography>
                                    <div className={ flex.container }>
                                        <Field
                                            name={ `products[${index}].discount` }
                                            render={ ({ field, form }) => {
                                                const handleChange = event => {
                                                    form.setFieldValue( field.name, event.target.value );
                                                    form.setFieldValue(
                                                        `products[${index}].discountUnit`,
                                                        event.target.value > 0 ? subtotal * event.target.value / 100 : 0
                                                    );
                                                };
                                                return (
                                                    <Input
                                                        step="0.5"
                                                        label={ "%" }
                                                        className={ classnames(
                                                            flex.fill,
                                                            cssUtils.marginTop0,
                                                            cssUtils.marginRightSmall
                                                        ) }
                                                        disabled={ !subtotal }
                                                        form={ form }
                                                        field={{
                                                            ...field,
                                                            onChange: handleChange,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Field
                                            name={ `products[${index}].discountUnit` }
                                            render={ ({ field, form }) => {
                                                const handleChange = ({ target: { value }}) => {
                                                    form.setFieldValue( field.name, value );
                                                    form.setFieldValue(
                                                        `products[${index}].discount`,
                                                        value ?
                                                            Math.round( ( 100 * value / subtotal ) * 100 ) / 100 :
                                                            0
                                                    );
                                                };

                                                return (
                                                    <PriceInput
                                                        label={ "" }
                                                        defaultLabel
                                                        hidePrefix
                                                        className={ classnames(
                                                            flex.fill,
                                                            cssUtils.marginTop0,
                                                            cssUtils.marginLeftSmall
                                                        ) }
                                                        disabled={ !subtotal }
                                                        form={ form }
                                                        field={{
                                                            ...field,
                                                            onChange: handleChange,
                                                        }}
                                                    />
                                                );
                                            } }
                                        />
                                    </div>
                                    <Typography variant="caption" className={ cssUtils.marginBottomSmall }>
                                        { t(
                                            "opportunity:proposal-product.total-price",
                                            {
                                                total: priceFormat( formValues.totalPrice )
                                            }
                                        )}
                                    </Typography>
                                    <div className={ classnames( flex.container, flex.justifyContentEnd ) }>
                                        <Button color="secondary" onClick={ handleCancel }>
                                            { t( "common:cancel" ) }
                                        </Button>
                                        <Button color="primary" onClick={ handleClose }>
                                            { "OK" }
                                        </Button>
                                    </div>
                                </Collapse>
                            </Fragment>
                        </Outlined>
                    </div>
                )
            }
        </Draggable>
    );
};

export default ProposalProductsItem;