import { memo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { getUser } from "../core/core.selectors";
import "./chat.css";

const Chat = () => {
    const user = useSelector( getUser );

    useEffect( () => {
        const otherScript = document.createElement( "script" );
        otherScript.innerHTML = `var mdChatClient="34CEEEAF3C2C44B4BE3556F0C5336E2C";`;
        otherScript.type = "text/javascript";

        document.body.appendChild( otherScript );

        const script = document.createElement( "script" );
        script.src = "https://chat.movidesk.com/Scripts/chat-widget.min.js";

        document.body.appendChild( script );
    }, [] );

    const initChat = useCallback(() => {
        try {
            window.movideskLogin({
                name: user.name,
                email: user.email,
            });
        } catch ( e ) {
            setTimeout( initChat, 400 );
        }
    }, [ user.name, user.email ] );

    useEffect( () => {
        initChat();
    }, [ initChat ] );

    return <div/>;

};

export default memo( Chat );