import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { setLossReason } from "../opportunity.actions";
import { getLossReasonId } from "../opportunity.selectors";
import { selectors as lossReasonSelectors } from "../../loss-reason";
import { Dropdown } from "../../ui";

const LossReasonFilter = ({ className, }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const lossReasons = useSelector( lossReasonSelectors.getLossReasons );
    const loading = useSelector( lossReasonSelectors.isLoadingLossReasons );
    const selected = useSelector( getLossReasonId );

    const handleChange = useCallback( event => {
        dispatch( setLossReason( event.target.value ) );
    }, [ dispatch ] );
    const source = useMemo( () => {
        return lossReasons?.map( lossReason => ({ value: lossReason.id, label: lossReason.name }) ) || [];
    }, [ lossReasons ] );

    return (
        <Dropdown
            className={ className }
            margin="none"
            label={ t( "opportunity:loss-reason" ) }
            disabled={ loading }
            value={ selected }
            onChange={ handleChange }
            source={ source }
            emptyValue
        />
    );
};

export default LossReasonFilter;