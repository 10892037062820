import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";

import useTranslate from "../../hooks/use-translate.hook";
import { saveProposal } from "../opportunity.actions";
import { createEditProposalFormName } from "../opportunity.utils";
import { ProposalForm, validate } from "./proposal-form.component";

const adaptProducts = proposalProducts => proposalProducts &&
    proposalProducts.map( ( item, index ) => ({
        ...item,
        discountUnit: item.discount > 0 ? item.totalPrice * item.discount / 100 : 0,
        order: index + 1,
    }));

const EditProposal = ({ open, onClose, proposal }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formName = createEditProposalFormName( proposal.id );

    const formik = useForm({
        form: formName,
        initialValues: {
            ...proposal,
            products: adaptProducts( proposal.products ),
        },
        onSubmit: values => dispatch( saveProposal( formName )({
            ...values,
            products: adaptProducts( values.products )
        })),
        validate,
    });

    return (
        <ProposalForm
            formik={ formik }
            open={ open }
            onClose={ onClose }
            title={ t( "opportunity:proposals.edit" ) }
        />
    );
};

export default EditProposal;