import { startSubmit, stopSubmit, reset } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import * as actions from "./api-key.actions";
import { NEW_API_KEY_FORM } from "./api-key.constants";
import { createDeleteApiKeyForm, createEditApiKeyForm } from "./api-key.utils";

export function *watchIntegrationsApiKey () {
    yield all([
        takeEvery( actions.FETCH_API_KEYS, fetchApiKeys ),
        takeEvery( actions.SAVE_NEW_API_KEY, saveNewApiKey ),
        takeEvery( actions.SAVE_API_KEY, saveApiKey ),
        takeEvery( actions.DELETE_API_KEY, deleteApiKey ),
    ]);
}

export function *fetchApiKeys () {
    yield put( actions.requestApiKeys() );

    try {
        const response = yield call( apiv1.get, "/apikeys" );
        yield put( actions.receiveApiKeys( response.data ) );
    } catch ( e ) {
        yield put( actions.errorApiKeys( e.response.data ) );
    }
}

export function *saveNewApiKey ({ payload }) {
    yield put( startSubmit( NEW_API_KEY_FORM ) );

    try {
        yield call( apiv1.post, "/apikeys", payload );
        yield put( actions.fetchApiKeys() );
        yield put( reset( NEW_API_KEY_FORM ) );
        yield put( stopSubmit( NEW_API_KEY_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_API_KEY_FORM, e.response.data ) );
    }
}

export function *saveApiKey ({ meta: { id }, payload }) {
    const form = createEditApiKeyForm( id );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.patch, "/apikeys/" + id, payload );
        yield put( actions.fetchApiKeys() );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}

export function *deleteApiKey ({ meta: { id } }) {
    const form = createDeleteApiKeyForm( id );
    yield put( startSubmit( form ) );

    try {
        yield call( apiv1.delete, "/apikeys/" + id );
        yield put( actions.fetchApiKeys() );
        yield put( stopSubmit( form ) );
    } catch ( e ) {
        yield put( stopSubmit( form, e.response.data ) );
    }
}