import { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { Form, useForm } from "formik-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Dropdown, Input, Switch, UsersFilter, cssUtils, flex, DropdownMultiple } from "../../../ui";
import { selectors as funnelSelectors } from "../../../funnel";
import { selectors as phaseSelectors } from "../../../phase";
import { saveFunnel } from "./funnel.actions";
import { createFormFunnel } from "./funnel.utils";
import DeleteFunnel from "./delete-funnel.component";
import { listCategories } from "../../../product/product.selectors";
import PhaseList from "./phases-list.component";

const validate = values => {
    const errors = {};
    if ( !values ) {
        return errors;
    }

    if ( !values.name ) {
        errors.name = "common:validation.required";
    }

    values.phases?.forEach( ( phase, index ) => {
        const phaseError = {};

        if ( !phase.name ) {
            phaseError.name = "common:validation.required";
        }

        if ( !phase.cadencePhaseId ^ !phase.cadenceTime ) {
            if ( !phase.cadenceTime ) {
                phaseError.cadenceTime = "common:validation.required";
            } else {
                phaseError.cadencePhaseId = "common:validation.required";
            }

        }

        if ( !isEmpty( phase.triggers ) ) {
            phase.triggers.forEach( ( trigger, index ) => {
                const triggerError = {};

                if ( !trigger.template ) {
                    set( triggerError, "template.id", "common:validation.selection" );
                }

                if ( !isEmpty( triggerError ) ) {
                    set( phaseError, `triggers[${index}]`, triggerError );
                }
            });
        }

        if ( !isEmpty( phase.entrySchedules ) ) {
            phase.entrySchedules.forEach( ( entryScheduling, index ) => {
                const entrySchedulingError = {};

                if ( !entryScheduling.opportunitySchedulingTypeId ) {
                    entrySchedulingError.opportunitySchedulingTypeId = "common:validation.selection";
                }
                if ( !entryScheduling.description ) {
                    entrySchedulingError.description = "common:validation.required";
                }
                if ( !entryScheduling.timeType ) {
                    entrySchedulingError.timeType = "common:validation.selection";
                } else if ( entryScheduling.timeType === "AMOUNT" ) {
                    if ( !entryScheduling.amountTime ||
                        !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test( entryScheduling.amountTime )
                    ) {
                        entrySchedulingError.amountTime = "common:validation.required";
                    }
                } else if ( entryScheduling.timeType === "EXACT" ) {
                    if ( !entryScheduling.exactTime ||
                        !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test( entryScheduling.exactTime )
                    ) {
                        entrySchedulingError.exactTime = "common:validation.required";
                    }
                }

                if ( !isEmpty( entrySchedulingError ) ) {
                    set( phaseError, `entrySchedules[${index}]`, entrySchedulingError );
                }
            } );
        }

        if ( !isEmpty( phase.phaseSchedulingStatus ) ) {
            phase.phaseSchedulingStatus.forEach( ( phaseSchedulingStatus, index ) => {
                const phaseSchedulingStatusError = {};

                if ( !phaseSchedulingStatus.opportunitySchedulingTypeId ) {
                    phaseSchedulingStatusError.opportunitySchedulingTypeId = "common:validation.selection";
                }

                if ( !phaseSchedulingStatus.schedulingStatus ) {
                    phaseSchedulingStatusError.schedulingStatus = "common:validation.selection";
                }

                if ( !isEmpty( phaseSchedulingStatusError ) ) {
                    set( phaseError, `phaseSchedulingStatus[${index}]`, phaseSchedulingStatusError );
                }
            } );
        }

        if ( !!phase.forwardAfter ) {
            if ( !phase.forwardAfterType ) {
                phaseError.forwardAfterType = "common:validation.selection";
            }
        }

        if ( phase.showPhaseCommentCreated && !phase.phaseCommentCreated ) {
            phaseError.phaseCommentCreated = "common:validation.required";
        }

        if ( phase.showPhaseEmailSent && !phase.phaseEmailSent ) {
            phaseError.phaseEmailSent = "common:validation.required";
        }

        if ( phase.showPhasePhoneCallCreated && !phase.phasePhoneCallCreated ) {
            phaseError.phasePhoneCallCreated = "common:validation.required";
        }

        if ( phase.showPhaseSmsSent && !phase.phaseSmsSent ) {
            phaseError.phaseSmsSent = "common:validation.required";
        }

        if ( phase.showPhaseWhatsappSent && !phase.phaseWhatsappSent ) {
            phaseError.phaseWhatsappSent = "common:validation.required";
        }
        if ( !isEmpty( phaseError ) ) {
            set( errors, `phases[${index}]`, phaseError );
        }
    });

    if ( values.showAutomation ) {
        if ( !values.nextFunnelId ) {
            errors.nextFunnelId = "common:validation.selection";
        }

        if ( !values.nextOpportunityStatus ) {
            errors.nextOpportunityStatus = "common:validation.selection";
        }
    }

    if ( !values.opportunitiesOrderBy ) {
        errors.opportunitiesOrderBy = "common:validation.selection";
    }

    if ( !values.opportunitiesOrderType ) {
        errors.opportunitiesOrderType = "common:validation.selection";
    }

    return errors;
};

const FunnelForm = ({ funnel }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const allFunnels = useSelector( funnelSelectors.listAll );
    const productCategories = useSelector( listCategories );
    const phases = useSelector( phaseSelectors.listWithFunnel( funnel.id ) );

    const [ showDelete, setShowDelete ] = useState( false );

    const toggleDelete = () => setShowDelete( !showDelete );

    const formik = useForm({
        enableReinitialize: true,
        form: createFormFunnel( funnel.id ),
        initialValues: {
            ...funnel,
            productCategories: ( funnel.productCategories || [] ).map( productCategory => productCategory.id ),
            showAutomation: !!funnel.nextFunnelId,
            phases: phases
                .map( phase => ({
                    ...phase,
                    showPhaseEmailSent: !!phase.phaseEmailSent,
                    showPhaseWhatsappSent: !!phase.phaseWhatsappSent,
                    showPhasePhoneCallCreated: !!phase.phasePhoneCallCreated,
                    showPhaseSmsSent: !!phase.phaseSmsSent,
                    showPhaseCommentCreated: !!phase.phaseCommentCreated,
                    triggers: phase.triggers &&
                        phase.triggers
                            .map( trigger => ({
                                automatic: false,
                                sendCustomer: false,
                                ...trigger,
                                usersId: trigger.users && trigger.users.map( user => user.id )
                            }))

                }))
        },
        onSubmit: values => dispatch( saveFunnel({
            ...values,
            productCategories: values.productCategories ?
                values.productCategories.map( productCategoryId => ({ id: productCategoryId })) :
                null,
            phases: values.phases.map( phase => ({
                ...phase,
                triggers: phase.triggers?.map( trigger => ({
                    ...trigger,
                    users: trigger.usersId?.map( userId => ({ id: userId }))
                }))
            }))
        }) ),
        validate,
    });

    const nextPhases = useSelector( phaseSelectors.listWithFunnel( formik.values.nextFunnelId ) );

    const toggleAutomation = () => {
        const showAutomation = !formik.values.showAutomation;
        if ( !showAutomation ) {
            formik.setFieldValue( "nextFunnelId", null );
            formik.setFieldValue( "nextTeamId", null );
            formik.setFieldValue( "nextUsersId", null );
            formik.setFieldValue( "nextPhaseId", null );
            formik.setFieldValue( "nextOpportunityStatus", null );
        } else {
            formik.setFieldValue( "nextOpportunityStatus", "SOLD" );
        }

        formik.setFieldValue( "showAutomation", showAutomation );
    };

    const handleNextTeamChange = value => formik.setFieldValue( "nextTeamId", value );

    const handleNextUsersIdChange = value => {
        formik.setFieldValue( "nextTeamId", null );
        formik.setFieldValue( "nextUsersId", value );
    };

    const handleTeamChange = value => formik.setFieldValue( "teamId", value );

    const handleUserChange = value => {
        formik.setFieldValue( "teamId", null );
        formik.setFieldValue( "usersId", value );
    };

    const opportunityOrders = useMemo( () => {
        return [
            { value: "createdAt", label: t( "config:funnels.opportunitiesOrderBy.createdAt" ) },
            { value: "updatedAt", label: t( "config:funnels.opportunitiesOrderBy.updatedAt" ) }
        ];
    }, [ t ] );

    const nextOpportunityStatus = useMemo( () => {
        return [
            { value: "SOLD", label: t( "config:funnels.nextOpportunityStatus.sold" ) },
            { value: "LOST", label: t( "config:funnels.nextOpportunityStatus.lost" ) },
        ];
    }, [ t ] );

    const opportunityOrderTypes = useMemo( () => {
        return [
            { value: "ASC", label: t( "config:funnels.opportunitiesOrderType.asc" ) },
            { value: "DESC", label: t( "config:funnels.opportunitiesOrderType.desc" ) }
        ];
    }, [ t ] );

    return (
        <Fragment>
            <Form formik={ formik } noValidate>
                <CardContent>
                    <Field
                        name="name"
                        label={ t( "config:funnels.name" ) }
                        component={ Input }
                        variant="outlined"
                        required
                    />
                    <div className={ classnames( flex.container, flex.columnGap ) }>
                        <Field
                            name="opportunitiesOrderBy"
                            label={ t( "config:funnels.opportunitiesOrderBy.label" ) }
                            component={ Dropdown }
                            source={ opportunityOrders }
                            className={ flex.fill }
                            fullWidth={ false }
                            required
                        />
                        <Field
                            name="opportunitiesOrderType"
                            label={ t( "config:funnels.opportunitiesOrderType.label" ) }
                            component={ Dropdown }
                            source={ opportunityOrderTypes }
                            className={ flex.fill }
                            fullWidth={ false }
                            required
                        />
                    </div>
                    <Typography className={ cssUtils.marginTopSmall }>
                        { t( "config:phases.title" ) }
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                        { t( "config:phases.call-to-action" ) }
                    </Typography>
                    <FieldArray
                        component={ PhaseList }
                        funnelId={ funnel.id }
                        name="phases"
                    />
                </CardContent>
                <Accordion expanded={ formik.values.showAutomation } elevation={ 0 }>
                    <AccordionSummary classes={{ content: flex.alignItemsCenter, root: cssUtils.cursorUnset }}>
                        <Typography className={ flex.fill }>
                            { t( "config:funnels.automation.title" ) }
                        </Typography>
                        <Switch
                            checked={ formik.values.showAutomation }
                            onClick={ toggleAutomation }
                            fullWidth={ false }
                        />
                    </AccordionSummary>
                    <AccordionDetails className={ flex.column }>
                        <Typography color="textSecondary" className={ flex.fill }>
                            { t( "config:funnels.automation.call-to-action" ) }
                        </Typography>
                        <div className={ classnames( flex.container, cssUtils.marginTop )}>
                            <Field
                                name="nextFunnelId"
                                label={ t( "config:funnels.name" ) }
                                component={ Dropdown }
                                source={ allFunnels.map( funnel => ({
                                    value: funnel.id,
                                    label: funnel.name
                                }))}
                                fullWidth={ false }
                                className={ flex.fill }
                                required
                            />
                            <Field
                                name="nextPhaseId"
                                label={ t( "config:funnels.nextPhaseId.label" ) }
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                component={ Dropdown }
                                source={ nextPhases?.map( phase => ({
                                    value: phase.id,
                                    label: phase.name
                                }))}
                            />
                            <Field
                                name="nextOpportunityStatus"
                                label={ t( "config:funnels.nextOpportunityStatus.label" ) }
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                component={ Dropdown }
                                source={ nextOpportunityStatus }
                                required
                            />
                        </div>
                        <UsersFilter
                            ComponentRoot={ "div" }
                            className={ classnames(
                                flex.fill,
                                flex.container,
                                cssUtils.margin0,
                                cssUtils.padding0
                            )}
                            input={{
                                team: {
                                    value: formik.values.nextTeamId,
                                    onChange: handleNextTeamChange
                                },
                                users: {
                                    value: formik.values.nextUsersId,
                                    onChange: handleNextUsersIdChange
                                }
                            }}
                            fullWidth={ false }
                        />
                    </AccordionDetails>
                </Accordion>

                <CardContent>
                    <Divider />

                    <Field
                        name="productCategories"
                        label={ t( "config:funnels.product-categories.label" ) }
                        component={ DropdownMultiple }
                        helperText={ t("config:funnels.product-categories.help" ) }
                        source={ productCategories.map( category => ({
                            value: category.id,
                            label: category.name
                        })) }
                        className={ classnames( cssUtils.marginTop, cssUtils.marginBottom ) }
                        emptyValue
                    />
                    <Divider />

                </CardContent>

                <div className={ flex.container }>

                    <div
                        className={ classnames(
                            cssUtils.marginVerticalSmall,
                            cssUtils.paddingLeftSmall,
                            cssUtils.fullWidth
                        )}
                    >
                        <Typography
                            color="textSecondary" variant="body2">
                            { t( "config:funnels.config-team-user.help" ) }
                        </Typography>
                    </div>

                    <UsersFilter
                        ComponentRoot={ "div" }
                        className={ classnames(
                            flex.fill,
                            flex.container,
                            cssUtils.padding0,
                            cssUtils.marginHorizontalSmall
                        )}
                        input={{
                            team: {
                                value: formik.values.teamId,
                                onChange: handleTeamChange
                            },
                            users: {
                                value: formik.values.usersId,
                                onChange: handleUserChange
                            }
                        }}
                        fullWidth={ false }
                    />
                </div>
                <CardActions className={ flex.justifyContentEnd }>
                    {
                        allFunnels.length > 1 &&
                            <div className={ flex.fill }>
                                <Button color="secondary" onClick={ toggleDelete }>
                                    { t( "config:funnels.delete.title" ) }
                                </Button>
                            </div>
                    }
                    <Button
                        id="saveFunnelButton"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={ formik.submitting }
                    >
                        { t( "common:save" ) }
                    </Button>
                </CardActions>
            </Form>

            {
                allFunnels.length > 1 &&
                    <DeleteFunnel show={ showDelete } funnel={ funnel } onClose={ toggleDelete }/>
            }
        </Fragment>
    );
};

export default FunnelForm;