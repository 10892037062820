import { createTheme } from "@mui/material/styles";

const theme = mode => createTheme({
    palette: {
        mode,
        primary: { main: mode === "dark" ? "#EF7D36" : "#424242" },
        secondary: { main: mode === "dark" ? "#424242" : "#EF7D36" },
    },
    typography: {
        fontSize: 13,
        allVariants: {
            color: mode === "dark" && "#fff",
        },
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    left: "50%",
                    position: "relative",
                    display: "block",
                },
            },
        },
        MuiFab: {
            defaultProps: {
                color: mode === "dark" ? "primary" : "secondary",
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                margin: "normal",
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                componentsProps: {
                    typography: {
                        color: mode === "dark" && "#fff",
                    }
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                container: {
                    listStyle: "none",
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    color: mode !== "dark" && "#757575 !important",
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                indicatorColor: mode === "dark" ? "primary" : "secondary",
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                margin: "normal",
            }
        },
        MuiSnackbar: {
            defaultProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            }
        }
    },
});

export default theme;