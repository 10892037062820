import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as facebookActions from "./facebook-integration.actions";

const facebookLeadDistribution = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_LEAD_DISTRIBUTION,
    success: facebookActions.RECEIVE_FACEBOOK_LEAD_DISTRIBUTION,
    failure: facebookActions.ERROR_FACEBOOK_LEAD_DISTRIBUTION
});

const facebookLeadPages = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_LEAD_PAGES,
    success: facebookActions.RECEIVE_FACEBOOK_LEAD_PAGES,
    failure: facebookActions.ERROR_FACEBOOK_LEAD_PAGES
});

const facebookAuth = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_TOKEN,
    success: facebookActions.SET_FACEBOOK_TOKEN,
    failure: facebookActions.ERROR_FACEBOOK_TOKEN
});

const facebookAccountPages = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_ACCOUNT_PAGES,
    success: facebookActions.RECEIVE_FACEBOOK_ACCOUNT_PAGES,
    failure: facebookActions.ERROR_FACEBOOK_ACCOUNT_PAGES
});

const facebookPageLeadgenForms = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_PAGE_LEADGEN_FORMS,
    success: facebookActions.RECEIVE_FACEBOOK_PAGE_LEADGEN_FORMS,
    failure: facebookActions.ERROR_FACEBOOK_PAGE_LEADGEN_FORMS
});

const facebookPage = createRequestReducer({
    begin: facebookActions.REQUEST_FACEBOOK_PAGE,
    success: facebookActions.RECEIVE_FACEBOOK_PAGE,
    failure: facebookActions.ERROR_FACEBOOK_PAGE
});

export const facebookIntegration = combineReducers({
    facebookLeadDistribution,
    facebookLeadPages,
    facebookAuth,
    facebookAccountPages,
    facebookPageLeadgenForms,
    facebookPage
});