import { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { useVariablesFormat } from "./template.utils";

const TemplateSelector = ({ customer, opportunity, templates, onChange }) => {
    const t = useTranslate();
    const variablesFormat = useVariablesFormat();

    const handleChange = event => {
        const body = variablesFormat( customer, opportunity, event.target.value );
        onChange( body );
    };

    const systemTemplates = useMemo( () => templates.filter( template => template.system ), [ templates ] );
    const myTemplates = useMemo( () => templates.filter( template => template.my ), [ templates ] );
    const sharedTemplates = useMemo( () => templates.filter( template => template.shared ), [ templates ] );

    const label = t( "ui:template.select" );

    return (
        <FormControl size="small" fullWidth>
            <InputLabel>{ label }</InputLabel>
            <Select label={ label } onChange={ handleChange } value="">
                <MenuItem dense value=""><em>{ label }</em></MenuItem>
                { !isEmpty( systemTemplates ) && <ListSubheader>{ t( "ui:template.system" ) }</ListSubheader> }
                {
                    systemTemplates.map( template => (
                        <MenuItem key={ template.id } dense value={ template.body }>{ template.name }</MenuItem>
                    ))
                }
                { !isEmpty( myTemplates ) && <ListSubheader>{ t( "ui:template.my" ) }</ListSubheader> }
                {
                    myTemplates.map( template => (
                        <MenuItem key={ template.id } dense value={ template.body }>{ template.name }</MenuItem>
                    ))
                }
                { !isEmpty( sharedTemplates ) && <ListSubheader>{ t( "ui:template.shared" ) }</ListSubheader>}
                {
                    sharedTemplates.map( template => (
                        <MenuItem key={ template.id } dense value={ template.body }>{ template.name }</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default TemplateSelector;