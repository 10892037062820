export const FETCH_TOKEN = "x/FETCH_MAILTOP_TOKEN";
export const fetchToken = () => ({
    type: FETCH_TOKEN
});

export const REQUEST_TOKEN = "x/REQUEST_MAILTOP_TOKEN";
export const requestToken = () => ({
    type: REQUEST_TOKEN
});

export const SET_TOKEN = "x/SET_MAILTOP_TOKEN";
export const setToken = data => ({
    type: SET_TOKEN,
    payload: data
});

export const ERROR_TOKEN = "x/ERROR_MAILTOP_TOKEN";
export const errorToken = error => ({
    type: ERROR_TOKEN,
    payload: { error },
    error: true
});

export const FETCH_LISTS = "x/FETCH_MAILTOP_LISTS";
export const fetchLists = () => ({
    type: FETCH_LISTS
});

export const REQUEST_LISTS = "x/REQUEST_MAILTOP_LISTS";
export const requestLists = () => ({
    type: REQUEST_LISTS
});

export const RECEIVE_LISTS = "x/RECEIVE_MAILTOP_LISTS";
export const receiveLists = lists => ({
    type: RECEIVE_LISTS,
    payload: lists
});

export const ERROR_LISTS = "x/ERROR_MAILTOP_LISTS";
export const errorLists = error => ({
    type: ERROR_LISTS,
    payload: error,
    error: true
});

export const FETCH_REJECTIONS = "x/FETCH_MAILTOP_REJECTIONS";
export const fetchRejections = ( index, email ) => ({
    type: FETCH_REJECTIONS,
    meta: { index },
    payload: email
});

export const REQUEST_REJECTIONS = "x/REQUEST_MAILTOP_REJECTIONS";
export const requestRejections = ( index, email ) => ({
    type: REQUEST_REJECTIONS,
    meta: {index },
    payload: email
});

export const RECEIVE_REJECTIONS = "x/RECEIVE_MAILTOP_REJECTIONS";
export const receiveRejections = ( index, lists ) => ({
    type: RECEIVE_REJECTIONS,
    meta: { index },
    payload: lists

});

export const ERROR_REJECTIONS = "x/ERROR_MAILTOP_REJECTIONS";
export const errorRejections = ( index, error ) => ({
    type: ERROR_REJECTIONS,
    payload: error,
    error: true,
    meta: {index }
});