export default {
    "title": "Agenda",
    "users": "Usuários",
    "new-event": {
        "title": "Novo agendamento"
    },
    "form-event": {
        "start-date": "Data de início",
        "start-time": "Horário de início",
        "end-date": "Data de fim",
        "end-time": "Horário de fim",
        "all-day": "Dia inteiro",
        "title": "Título",
        "recurrent": {
            "label": "Recorrente",
            "type": {
                "label": "Tipo de recorrência",
                "DAILY": "Diário",
                "WEEKLY": "Semanal",
                "MONTHLY": "Mensal",
                "YEARLY": "Anual"
            },
            "until": "Até",
            "update": {
                "single": "Este agendamento",
                "future": "Este agendamento e os seguintes",
                "all": "Todos agendamentos"
            }
        },
        "notify": "Notificar",
        "minutes": "Minutos",
        "description": "Descrição",
        "color": "Cor",
        "customer": "Cliente",
        "social": {
            "attendees": "Convidados",
            "add-attendee": "Adicionar convidado",
            "google-data": "Dados da Agenda do Google",
        },
        "status": {
            "ACCOMPLISHED": "Realizado",
            "NOT_ACCOMPLISHED": "Não realizado",
            "OPEN": "Aberto"
        },
        "confirm-accomplished": "Marcar o agendamento como realizado?",
        "confirm-not-accomplished": "Marcar o agendamento como não realizado?",
        "reopen-scheduling": "Marcar o agendamento como aberto?",
        "not-accomplished-reason": "Motivo"
    },
    "event": {
        "delete": {
            "confirm": "Você tem certeza que deseja excluir este evento?"
        },
        "edit": "Editar",
        "user": "Usuário: ",
        "salesman": "Vendedor: ",
        "social": {
            "linked": "Agendamento vinculado com a Agenda do Google",
            "open": "Abrir na Agenda do Google",
            "google-meet": {
                "enter": "Entrar com o Google Meet",
                "copy": "Copiar link da conferência"
            }
        },
        "not-accomplished-reason": "Motivo não realizado"
    },
    "filters": {
        "social": {
            "label": "Agenda Google",
            "help": "Agendamentos criados diretamente na Agenda do Google"
        },
        "opportunity-scheduling-types": "Com oportunidade",
        "with-user-schedules": "Sem oportunidade"
    }
};