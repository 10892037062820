import Typography from "@mui/material/Typography";
import classnames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import css from "./timeline.scss";

dayjs.extend( relativeTime );

const TimelineSummary = ({ className, user, description, date }) => (
    <div className={ classnames( className, css.summary ) }>
        <Typography className={ css.summaryItem } variant="subtitle2">
            { user && user.name }
        </Typography>
        <Typography className={ css.summaryItem } variant="caption">
            { description || "" }
        </Typography>
        <Typography className={ css.summaryItem } variant="subtitle2">
            <Tooltip title={ dayjs(date).format("DD/MM/YYYY HH:mm") }>
                <time dateTime={ date }>
                    { dayjs( date ).fromNow( false ) }
                </time>
            </Tooltip>
        </Typography>
    </div>
);

export default TimelineSummary;