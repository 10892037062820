import { useDispatch, useSelector } from "react-redux";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import dayjs from "dayjs";

import useTranslate from "../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../core";
import { actions as userActions, selectors as userSelectors } from "../user";
import { actions as opportunityActions, selectors as opportunitySelectors } from "../opportunity";
import { cssUtils, flex, Dialog, Fab, UsersFilter } from "../ui";
import { Calendar, CalendarToolbar, DatePicker } from "../ui/calendar";
import CalendarEvent from "./calendar-event.component";
import Event from "./schedule-event.component";
import NewEvent from "./new-schedule-event.component";
import css from "./schedule.scss";

// Increase date by x amount of days
const increaseDays = ( date, amount ) => new Date( date.setDate( date.getDate() + amount ) );

// Get all weeks in given period
const buildWeeks = ( start, end ) => {
    const weeks = [];
    let current = new Date( start );

    while ( current < end ) {
        // Get start of the week
        const beginOfWeek = dayjs( new Date( current ) ).startOf( "day" ).toISOString();
        // Get end of the week
        let endOfWeek = increaseDays( current, 6 );
        // If there are less then 7 days left before the end, use the end.
        endOfWeek = dayjs( endOfWeek > end ? end : endOfWeek ).endOf( "day" ).toISOString();

        // Add week to our collection
        weeks.push({ startDate: beginOfWeek, endDate: endOfWeek });

        current = increaseDays( current, 1 );
    }

    return weeks;
};

const Schedule = ({ className }) => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const loading = useSelector( userSelectors.isLoadingEvents );
    const events = useSelector( userSelectors.getEvents );
    const reload = useSelector( userSelectors.reloadEvents );
    const selectedEvent = useSelector( userSelectors.getEventSelected );
    const user = useSelector( coreSelectors.getUser );
    const allOpportunitySchedulingTypes = useSelector( opportunitySelectors.getSchedulingTypes );

    const [ view, setView ] = useState( "month" );
    const [ date, setDate ] = useState( new Date() );
    const [ selectedDate, setSelectedDate ] = useState( new Date() );
    const [ showNewEvent, setShowNewEvent ] = useState( false );
    const [ newEventStartDate, setNewEventStartDate ] = useState( null );
    const [ newEventEndDate, setNewEventEndDate ] = useState( null );
    const [ opportunitySchedulingTypes, setOpportunitySchedulingTypes ] = useState( [] );
    const [ userScheduling, setUserScheduling ] = useState( true );
    const [ socialSchedules, setSocialSchedules ] = useState( !user.admin );
    const [ teamId, setTeamId ] = useState( "" );
    const [ usersId, setUsersId ] = useState( [] );
    const [ showUsers, setShowUsers ] = useState( false );

    const loadEvents = useCallback( base => {
        const baseDate = base || date;

        switch ( view ) {
            case "month":
                const startMonth = dayjs( baseDate.getTime() )
                    .startOf( "month" )
                    .startOf( "week" )
                    .startOf( "day" )
                    .toDate();
                const endMonth = dayjs( baseDate.getTime() )
                    .endOf( "month" )
                    .endOf( "week" )
                    .endOf( "day" )
                    .toDate();

                const weeks = buildWeeks( startMonth, endMonth );

                dispatch( userActions.fetchEvents({
                    weeks,
                    usersId: usersId.toString(),
                    social: socialSchedules
                }));
                break;
            case "week":
                const startWeek = dayjs( baseDate.getTime() )
                    .startOf( "week" )
                    .startOf( "day" )
                    .toDate();
                const endWeek = dayjs( baseDate.getTime() )
                    .endOf( "week" )
                    .endOf( "day" )
                    .toDate();

                dispatch( userActions.fetchEvents({
                    startDate: startWeek,
                    endDate: endWeek,
                    usersId: usersId.toString(),
                    social: socialSchedules
                }));
                break;
            case "day":
                const startDay = dayjs( baseDate.getTime() ).startOf( "day" ).toDate();
                const endDay = dayjs( baseDate.getTime() ).endOf( "day" ).toDate();

                dispatch( userActions.fetchEvents({
                    startDate: startDay,
                    endDate: endDay,
                    usersId: usersId.toString(),
                    social: socialSchedules
                }));
                break;
            default:
                break;
        }
    }, [ dispatch, date, socialSchedules, usersId, view ] );

    const handleNavigate = ( newDate, view, type ) => {
        const newMonth = newDate.getMonth() !== date.getMonth() ||
            newDate.getFullYear() !== date.getFullYear();
        const isNewDate = view && view !== "month" && newDate.getTime() !== date.getTime();
        if ( newMonth || isNewDate ) {
            loadEvents( newDate );
        }
        setDate( new Date( newDate.getTime() ) );
        if ( type === "TODAY" || type === "DATE" || view === "day" ) {
            setSelectedDate( newDate );
        }
    };

    const selectEvent = useCallback( event => {
        dispatch( userActions.selectEvent( event ) );
        setShowNewEvent( false );
    }, [ dispatch ] );

    const handleCloseEvent = useCallback( () => selectEvent(), [ selectEvent ] );

    const toggleNewEvent = () => {
        setShowNewEvent( !showNewEvent );
        setNewEventStartDate( null );
        setNewEventEndDate( null );
    };

    const setNewEventDates = ({ start, end }) => {
        setShowNewEvent( true );
        setNewEventStartDate( start.toISOString() );
        setNewEventEndDate( end.toISOString() );
    };

    const selectSocialSchedules = useCallback( event => {
        setSocialSchedules( event.target.checked );
        dispatch( userActions.reloadEvents() );
    }, [ dispatch ] );
    const selectOpportunitySchedulingType = id => () => {
        const selected = opportunitySchedulingTypes.map( opportunitySchedulingType => ({
            ...opportunitySchedulingType,
            checked: id === opportunitySchedulingType.id ?
                !opportunitySchedulingType.checked :
                opportunitySchedulingType.checked
        }));
        setOpportunitySchedulingTypes( selected );
    };
    const selectAllOpportunitySchedulingType = value => {
        const selected = opportunitySchedulingTypes.map( opportunitySchedulingType => ({
            ...opportunitySchedulingType,
            checked: !!value
        }));
        setOpportunitySchedulingTypes( selected );
    };

    const toggleUsers = () => setShowUsers( !showUsers );
    const handleCloseUsers = useCallback( () => {
        setShowUsers( false );
        dispatch( userActions.reloadEvents() );
    }, [ dispatch ] );

    const filteredEvents = useMemo( () => events.filter( event =>
        (
            event.opportunity
                && !!opportunitySchedulingTypes
                    .filter( type => type.checked )
                    .find( type => type.id === event.opportunitySchedulingType.id )
        ) ||
        ( !event.opportunity && userScheduling )
    ), [ events, opportunitySchedulingTypes, userScheduling ] );

    useEffect( () => {
        dispatch( opportunityActions.fetchSchedulingTypes() );
        dispatch( userActions.fetchUsers() );
        dispatch( userActions.reloadEvents() );
    }, [ dispatch ] );
    useEffect( () => {
        return () => {
            handleCloseEvent();
        };
    }, [ handleCloseEvent ] );
    useEffect( () => {
        if ( allOpportunitySchedulingTypes ) {
            const opportunitySchedulingTypes = allOpportunitySchedulingTypes.map( schedulingType => ({
                ...schedulingType,
                checked: true
            }));
            setOpportunitySchedulingTypes( opportunitySchedulingTypes );
        }
    }, [ allOpportunitySchedulingTypes ] );
    useEffect( () => {
        if ( reload ) {
            loadEvents();
            setShowNewEvent( false );
            selectEvent();
        }
    }, [ reload, loadEvents, selectEvent ] );

    const renderToolbar = props => {
        return (
            <CalendarToolbar
                rightButtons={[
                    (
                        usersId.length > 0 ?
                            <Badge
                                key="users"
                                badgeContent={ usersId.length }
                                color="secondary"
                            >
                                <Button
                                    className={ cssUtils.marginLeft }
                                    onClick={ toggleUsers }
                                    startIcon={ <SupervisorAccountIcon/> }
                                >
                                    { t( "dashboard:users" ) }
                                </Button>
                            </Badge> :
                            <Button
                                key="users"
                                className={ cssUtils.marginLeft }
                                onClick={ toggleUsers }
                                startIcon={ <SupervisorAccountIcon/> }
                            >
                                { t( "dashboard:users" ) }
                            </Button>
                    )
                ]}
                { ...props }
            />
        );
    };

    return (
        <div className={ classnames( className, css.container ) }>
            <Helmet title={ t( "schedule:title" ) } />
            <Event
                dispatch={ dispatch }
                event={ selectedEvent }
                onClose={ handleCloseEvent }
                user={ user }
            />

            <NewEvent
                dispatch={ dispatch }
                show={ showNewEvent }
                onClose={ toggleNewEvent }
                startDate={ newEventStartDate }
                endDate={ newEventEndDate }
            />

            <Paper component="aside" className={ css.aside } square>
                <DatePicker
                    showHeader={ false }
                    month={ date.getMonth() }
                    onNavigate={ date => handleNavigate( date ) }
                    onDatePicked={ date => handleNavigate( date ) }
                    selectedDate={ selectedDate }
                />
                <div id="filters" className={ css.filters }>
                    <Divider/>
                    <FormGroup>
                        <div
                            className={ classnames(
                                cssUtils.paddingRightSmall,
                                flex.alignItemsCenter,
                                flex.container
                            ) }
                        >
                            <FormControlLabel
                                className={ classnames( flex.fill, cssUtils.marginLeft0 ) }
                                label={ t( "schedule:filters.social.label" ) }
                                control={
                                    <Checkbox
                                        checked={ socialSchedules }
                                        color="secondary"
                                        onChange={ selectSocialSchedules }
                                    />
                                }
                            />
                            <Tooltip title={ t( "schedule:filters.social.help" ) }>
                                <HelpIcon fontSize="small"/>
                            </Tooltip>
                        </div>
                        <Divider/>
                        <FormControlLabel
                            className={ cssUtils.marginLeft0 }
                            label={ t( "schedule:filters.with-user-schedules" ) }
                            control={
                                <Checkbox
                                    checked={ userScheduling }
                                    color="secondary"
                                    onChange={ e => setUserScheduling( e.target.checked ) }
                                />
                            }
                        />
                        <Divider/>
                        <FormControlLabel
                            className={ cssUtils.marginLeft0 }
                            label={ t( "schedule:filters.opportunity-scheduling-types" ) }
                            control={
                                <Checkbox
                                    checked={
                                        opportunitySchedulingTypes
                                            .filter(
                                                opportunitySchedulingType => opportunitySchedulingType.checked
                                            )
                                            .length === opportunitySchedulingTypes.length
                                    }
                                    color="secondary"
                                    onChange={
                                        event => selectAllOpportunitySchedulingType( event.target.checked )
                                    }
                                />
                            }
                        />

                        {
                            opportunitySchedulingTypes.map( ( opportunitySchedulingType, index ) => (
                                <FormControlLabel
                                    key={ index }
                                    className={ cssUtils.marginLeftSmall }
                                    label={ opportunitySchedulingType.name }
                                    control={
                                        <Checkbox
                                            checked={ opportunitySchedulingType.checked }
                                            onChange={ selectOpportunitySchedulingType( opportunitySchedulingType.id ) }
                                            style={{
                                                color: opportunitySchedulingType.checked ?
                                                    opportunitySchedulingType.color :
                                                    "",
                                                borderColor: opportunitySchedulingType.color
                                            }}
                                        />
                                    }
                                />
                            ))
                        }
                    </FormGroup>
                </div>
            </Paper>
            <div className={ classnames( cssUtils.overflowHidden, flex.fill ) }>
                { loading && <LinearProgress/> }

                <Calendar
                    date={ date }
                    events={ filteredEvents }
                    step={ 30 }
                    eventPropGetter={ event => ({
                        className: css.calendarEvent,
                        style: { backgroundColor: event.color }
                    }) }
                    onNavigate={ handleNavigate }
                    onView={ setView }
                    allDayAccessor={ event => event.allDay }
                    onSelectEvent={ selectEvent }
                    onSelectSlot={ setNewEventDates }
                    components={{ event: CalendarEvent, toolbar: renderToolbar }}
                />
            </div>

            <Fab
                id="addButton"
                position="bottomRight"
                onClick={ toggleNewEvent }
            >
                <AddIcon/>
            </Fab>

            <Dialog
                open={ showUsers }
                classes={{ paper: css.dialogUsers }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    { t( "schedule:users" ) }
                </DialogTitle>
                <DialogContent>
                    <UsersFilter
                        ComponentRoot={ Fragment }
                        fullWidth
                        input={{
                            team: {
                                value: teamId,
                                onChange: setTeamId
                            },
                            users: {
                                value: usersId,
                                onChange: setUsersId
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ handleCloseUsers }>
                        { "OK" }
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );

};

export default Schedule;