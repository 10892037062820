export default {
    "title": "Por hoy",
    "kpis": {
        "open-schedules": {
            "title": "Citas para hoy",
            "help": "Horarios, que están abiertos, con fecha para hoy"
        },
        "open-schedules-other-users": {
            "title": "Horarios para hoy para vendedores",
            "help": "Horarios, que están abiertos y creados por usted para los vendedores, con fecha para hoy"
        },
        "late-schedules": {
            "title": "Horarios atrasados",
            "help": "Horarios, que están abiertos, retrasados"
        },
        "late-schedules-other-users": {
            "title": "Horarios atrasados para los vendedores",
            "help": "Programaciones que crea para los vendedores que llegan tarde"
        },
        "opportunities-empty-open-schedules": {
            "title": "Oportunidades sin programación",
            "help": "Oportunidades que están sin programaciones abertas"
        },
        "new-opportunities": {
            "title": "Nuevas oportunidades",
            "help": "Oportunidades creadas hoy"
        }
    },
    "phases-funnel": {
        "opportunities": "Oportunidades",
        "filter": "Filtro",
        "empty": "Sin información de fases",
        "title": "Embudo de { funnel }"
    },
    "user-todos": {
        "title": "Horarios abiertos",
        "empty": "No hay nada que hacer",
        "events-user": "Sus citas",
        "events-other-users": "Programación de otros usuarios",
        "events-other-user": "Programación para vendedores",
        "events-seller-user": "Programaciones creadas por usted",
        "events-qualifier-to-seller": "Programaciones creadas por otros usuarios"
    },
    "goal-progress": {
        "title": "Objetivos del mes actual",
        "target": "Objetivo",
        "reached": "Alcanzado",
        "quantity": "(cantidad)",
        "minutes": "(minutos)",
        "average-service-time": "{ time } minutos",
        "opportunities-created": "{ total } oportunidades creadas",
        "empty": "Sin información de objetivos"
    },
    "opportunity-actions": {
        "title": "Cambiado con éxito",
        "info": "El estado de programación de la oportunidad { opportunity } se cambió correctamente",
        "comment": "Insertar comentario",
        "access": "Oportunidad de acceso"
    }
};