import { useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Snackbar from "@mui/material/Snackbar";
import isEmpty from "lodash/isEmpty";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import { downloadFile } from "../util/file.utils";
import { formatBytes } from "../core/formatter.utils";
import { NewFileFromModel, selectors as fileSelectors } from "../file";
import { getSelected, getFiles, isLoadingFiles } from "./customer.selectors";
import { fetchFiles, saveFile, deleteFile } from "./customer.actions";
import { CUSTOMER_FILE_FORM } from "./customer.constants";
import PreviewFile from "../ui/preview-file.component";
import { Table } from "../ui/table";
import css from "./customer.scss";

const CustomerFiles = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const customer = useSelector( getSelected );
    const files = useSelector( getFiles );
    const models = useSelector( fileSelectors.getModels );
    const hasModels = !isEmpty( models );
    const loading = useSelector( isLoadingFiles );

    const [ showError, setShowError ] = useState( false );
    const [ showFileFromModel, setShowFileFromModel ] = useState( false );
    const [ orderBy, setOrderBy ] = useState( "uploadedAt" );
    const [ orderType, setOrderType ] = useState( "ASC" );
    const [ showPreviewFile, setShowPreviewFile ] = useState( false );
    const [ fileToDownload, setFileToDownload ] = useState({});

    const { error, setFieldValue, submitting, submitForm, } = useForm({
        form: CUSTOMER_FILE_FORM,
        initialValues: {
            customerId: customer.id,
        },
        onSubmit: values => dispatch( saveFile( values ) ),
    });

    const fileRef = useRef();

    const toggleError = () => setShowError( !showError );
    const toggleFileFromModel = () => setShowFileFromModel( !showFileFromModel );

    const selectFile = event => {
        const file = event.target.files[ 0 ];

        setFieldValue( "file", file );
        setFieldValue( "fileName", file.name );
        submitForm();
    };

    const handleDeleteFile = file => dispatch( deleteFile( file ) );

    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const closeDialogFile = () => {
        setShowPreviewFile( false );
        setFileToDownload({});
    };

    const openFile = file => () => {
        let contentType = "";

        if ( file.name.endsWith(".png") || file.name.endsWith(".jpg") || file.name.endsWith(".jpeg")) {
            contentType = "image";
        } else if ( file.name.endsWith(".pdf") ) {
            contentType = "pdf";
        } else {
            downloadFile( `/api/v1/customers/files/${file.id}`, file.name );
            return;
        }

        setShowPreviewFile( true );
        setFileToDownload({ id: file.id, contentType, filename: file.name });
    };

    const source = useMemo( () => {
        if ( !files ) {
            return [];
        }
        return order(
            files.map( file => ({
                ...file,
                download:
                    <IconButton onClick={ openFile( file ) } size="large">
                        <CloudDownloadIcon/>
                    </IconButton>
            })),
            [ orderBy ],
            [ orderType.toLowerCase() ]
        );
    }, [ files, orderBy, orderType ] );

    useEffect( () => {
        dispatch( fetchFiles( customer.id ) );
    }, [ dispatch, customer.id ] );
    useEffect( () => {
        error && setShowError( true );
    }, [ error ] );

    const model = {
        name: {
            title: t( "customer:files.name" )
        },
        size: {
            title: t( "customer:files.size" ),
            format: formatBytes
        },
        uploadedAt: {
            title: t( "customer:files.uploaded-at" ),
            format: dateFormat
        },
        uploadedBy: {
            title: t( "customer:files.uploaded-by" ),
            format: user => user.name
        },
        download: {
            title: " ",
            size: "small"
        }
    };

    const fileModelProps = {
        [ customer.type === "COMPANY" ? "companyId" : "personId" ]: customer.id,
        open: showFileFromModel,
        onClose: toggleFileFromModel,
    };

    return (
        <Fragment>
            <PreviewFile
                open={ showPreviewFile }
                contentType={ fileToDownload?.contentType }
                filename={ fileToDownload?.filename }
                url={ `/api/v1/customers/files/${fileToDownload?.id}` }
                onClose={ closeDialogFile }
            />

            <div className={ css.containerContent }>
                <Snackbar
                    open={ showError }
                    action={ <Button onClick={ toggleError } color="secondary">{ "OK" }</Button>}
                    message={ error?.error }
                    autoHideDuration={ 5000 }
                    onClose={ toggleError }
                />

                <form role="form" encType="multipart/form-data">
                    <input
                        type="file"
                        name="file"
                        style={{ display: "none" }}
                        ref={ fileRef }
                        onChange={ selectFile }
                    />
                </form>

                <NewFileFromModel { ...fileModelProps } />

                <div className={ css.content }>
                    <Table
                        isLoading={ submitting || loading }
                        onAdd={ () => fileRef.current.click() }
                        headerActions={
                            hasModels && (
                                <Button color="primary" onClick={ toggleFileFromModel }>
                                    { t( "customer:files.generate" ) }
                                </Button>
                            )
                        }
                        emptyMessage={ t( "customer:files.empty" ) }
                        onDelete={ handleDeleteFile }
                        source={ source }
                        model={ model }
                        selectable
                        allowOrder
                        onChangePage={ changeOrder }
                        orderBy={ orderBy }
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default CustomerFiles;