import { all } from "redux-saga/effects";

import { sagas as integrationSagas } from "./integration";
import { sagas as productSagas } from "./product";
import { sagas as automationSagas } from "./automation";
import { sagas as additionalResourcesSagas } from "./additional-resources";

export function *watchMarketplace () {
    yield all([
        integrationSagas.watchIntegrations(),
        productSagas.watchProducts(),
        automationSagas.watchIntegrationsAutomation(),
        additionalResourcesSagas.watchAdditionalResources()
    ]);
}