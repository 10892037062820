import { combineReducers } from "redux";

import * as actions from "./main.actions";

const subscriptionAddress = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_SUBSCRIPTION_ADDRESS:
            return Object.assign( {}, state, {
                loading: true,
            });
        case actions.RECEIVE_SUBSCRIPTION_ADDRESS:
            return Object.assign( {}, state, {
                data: action.payload,
                loading: false,
                error: null,
            });
        case actions.ERROR_SUBSCRIPTION_ADDRESS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload,
            });
        default:
            return state;
    }
};


export const main = combineReducers({
    subscriptionAddress
});