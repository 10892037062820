import AcUnitIcon from "@mui/icons-material/AcUnit";
import OpacityIcon from "@mui/icons-material/Opacity";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import css from "./opportunity.scss";

export const createDeleteProposalFormName = id => `deleteOpportunityProposalForm${id}`;
export const createDuplicateProposalFormName = id => `duplicateOpportunityProposalForm${id}`;
export const createEditProposalFormName = id => `editOpportunityProposalForm${id}`;
export const createProposalStatusFormName = ( id, status ) => `proposal${status}Form${id}`;

export function createSchedulingFormName ( id ) {
    return "opportunitySchedulingForm" + ( id || 0 );
}
export const createSchedulingDeleteForm = id => `opportunitySchedulingDeleteForm${id}`;

export const getTemperatureProps = value => {
    switch ( value ) {
        case "COLD":
            return <AcUnitIcon className={ css.labelColdTemperature }/>;
        case "WARM":
            return <OpacityIcon className={ css.labelWarmTemperature }/>;
        case "HOT":
            return <WhatshotIcon className={ css.labelHotTemperature }/>;
    }
};

export const opportunityKanbanKeys = [
    "fields",
    "funnelId",
    "phase.id",
    "phase.name",
    "phase.days",
    "phase.triggers",
    "phaseEntryDate",
    "sourceId",
    "id",
    "title",
    "temperature",
    "price",
    "recurrentPrice",
    "nextScheduling",
    "company.id",
    "company.name",
    "company.fields",
    "person.id",
    "person.name",
    "person.fields",
    "user.id",
    "user.name",
    "user.avatar",
];