import { useEffect } from "react";
import { FormikProvider } from "formik";
import { useForm } from "formik-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import usePrevious from "../../hooks/use-previous.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../ui";
import { SEARCH_CUSTOMERS_FORM } from "../opportunity.constants";
import css from "../opportunity.scss";
import SearchCustomerFields from "./search-customer-fields.component";

const SearchCustomers = ({ setRef, onSubmitSuccess, onSubmit }) => {
    const t = useTranslate();

    const formik = useForm({
        form: SEARCH_CUSTOMERS_FORM,
        initialValues: {
            person: {},
            company: {}
        },
        onSubmit,
    });

    const prevSubmitting = usePrevious( formik.submitting );

    useEffect( () => {
        if ( prevSubmitting && !formik.submitting ) {
            onSubmitSuccess();
        }
    }, [ prevSubmitting, formik.submitting, onSubmitSuccess ] );

    return (
        <FormikProvider value={ formik }>
            <form ref={ setRef } onSubmit={ formik.handleSubmit } className={ classnames( flex.container ) }>
                { formik.submitting && <LinearProgress /> }
                <div className={ flex.fill }>
                    <div className={ css.searchCustomerContainer }>
                        <span className={ cssUtils.fontBold }>
                            { t( "customer:person.title" ) }
                        </span>

                        <Typography variant="body2">
                            { t( "opportunity:avoid-duplications-person" ) }
                        </Typography>

                        <div className={ cssUtils.marginTopSmall }>
                            <SearchCustomerFields customerType="PERSON" name="person"/>
                        </div>

                    </div>
                </div>
                <div className={ flex.fill }>
                    <div className={ classnames( css.searchCustomerContainer ) }>
                        <span className={ cssUtils.fontBold }>
                            { t( "customer:company.title" ) }
                        </span>
                        <Typography variant="body2">
                            { t( "opportunity:avoid-duplications-company" ) }
                        </Typography>
                        <div className={ cssUtils.marginTopSmall }>
                            <SearchCustomerFields customerType="COMPANY" name="company"/>
                        </div>
                    </div>
                </div>
            </form>
        </FormikProvider>
    );
};

export default SearchCustomers;