import { useMemo, useCallback, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import history from "../../../../history";
import { flex } from "../../../ui";
import ConfigHeader from "../../config-header.component";
import User from "./user.component";
import ConfigTeam from "./team.component";
import NewUserDialog from "./new-user.component";
import NewTeamDialog from "../team/new-team.component";

const Users = () => {
    const location = useLocation();

    const t = useTranslate();
    const [ showNewUser, setShowNewUser ] = useState( false );
    const [ showNewTeam, setShowNewTeam ] = useState( false );

    const items = useMemo( () => [
        { id: "users", href: "/config/users" },
        { id: "teams", href: "/config/users/team" }
    ], [] );

    const activeTab = useMemo( () => {
        const tab = items.find( item => location.pathname === item.href ).id;
        return tab;
    }, [ items, location.pathname ] );

    const toggleNew = useCallback( () => {
        if ( activeTab === "users" ) {
            setShowNewUser( showNewUser => !showNewUser );
        } else if ( activeTab === "teams" ) {
            setShowNewTeam( showNewTeam => !showNewTeam );
        }
    }, [ activeTab ] );

    const isSelected = useCallback( href => location.pathname === href, [ location ] );

    const handleChangeTab = useCallback( href => () => {
        history.push( href );
    }, [] );

    const renderNewDialog = useCallback( () => {
        if ( activeTab === "users" ) {
            return (
                <NewUserDialog
                    open={ showNewUser }
                    onClose={ toggleNew }
                />
            );
        } else if ( activeTab === "teams" ) {
            return (
                <NewTeamDialog
                    open={ showNewTeam }
                    onClose={ toggleNew }
                />
            );
        }
    }, [ activeTab, showNewTeam, showNewUser, toggleNew ] );

    return (
        <>
            <ConfigHeader
                title={ t( "config:main.yourTeam.invite" ) }
                actionTitle={ t( `config:${activeTab}.new` ) }
                actionIcon={ activeTab === "users" ? AddIcon : GroupAddIcon }
                onActionClick={ toggleNew }
            />
            {
                renderNewDialog()
            }
            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <div className={ flex.item25 }>
                    <List>
                        {
                            items.map( item => (
                                <ListItemButton
                                    key={ item.id }
                                    onClick={ handleChangeTab( item.href ) }
                                    selected={ isSelected( item.href ) }
                                >
                                    <ListItemText
                                        primary={ t( "config:" + item.id.toLowerCase() + ".title" ) }
                                    />
                                </ListItemButton>
                            ))
                        }
                    </List>
                </div>
                <div className={ flex.fill }>
                    <Routes>
                        <Route index element={ <User/> }/>
                        <Route path={ "team" } element={ <ConfigTeam/> }/>
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Users;