import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import set from "lodash/set";

import { useDateFormat } from "../../hooks/use-date-format.hook";
import { useFieldFilterFormat } from "../../hooks/use-field-value-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../../core";
import { selectors as formSelectors } from "../../form";
import { selectors as productSelectors } from "../../product";
import { actions as userActions, selectors as userSelectors } from "../../user";

const OpportunitiesFilters = ({ status }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const fieldFilterFormat = useFieldFilterFormat( t );

    const fields = useSelector( formSelectors.list( "opportunity" ) );
    const companyFields = useSelector( formSelectors.list( "company" ) );
    const personFields = useSelector( formSelectors.list( "person" ) );
    const preferences = useSelector( coreSelectors.getPreferences );
    const products = useSelector( productSelectors.getProducts );
    const users = useSelector( userSelectors.listAllPermitted );

    const deleteFilter = ({ index, users, company, person, products }) => {
        let newPreferences;
        if ( index !== undefined ) {
            const filters = preferences.opportunity.filters.slice();
            filters.splice( index, 1 );
            newPreferences = {
                ...preferences,
                opportunity: {
                    ...preferences.opportunity,
                    filters
                }
            };
        } else if ( users ) {
            newPreferences = ( set({ ...preferences }, "opportunity.team", null ) );
            newPreferences = ( set({ ...preferences }, "opportunity.users", [] ) );
        } else if ( products ) {
            newPreferences = ( set({ ...preferences }, "opportunity.products", [] ) );
        } else if ( company ) {
            newPreferences = ( set({ ...preferences }, "opportunity.company", null ) );
        } else if ( person ) {
            newPreferences = ( set({ ...preferences }, "opportunity.person", null ) );
        } else {
            return;
        }
        dispatch( userActions.saveUserPreferences( newPreferences ) );
    };

    const deleteCompanyFilters = index => () => {
        const companyFilters = preferences.opportunity.companyFilters.slice();
        companyFilters.splice( index, 1 );
        const newPreferences = {
            ...preferences,
            opportunity: {
                ...preferences.opportunity,
                companyFilters: companyFilters
            }
        };
        dispatch( userActions.saveUserPreferences( newPreferences ) );
    };

    const deletePersonFilters = index => () => {
        const personFilters = preferences.opportunity.personFilters.slice();
        personFilters.splice( index, 1 );
        const newPreferences = {
            ...preferences,
            opportunity: {
                ...preferences.opportunity,
                personFilters: personFilters
            }
        };
        dispatch( userActions.saveUserPreferences( newPreferences ) );
    };

    const deleteSchedulingDate = () => {
        dispatch( userActions.saveUserPreferences({ ...omit( preferences, "opportunity.schedulingDate" ) }) );
    };

    const deleteImportation = () => {
        dispatch( userActions.saveUserPreferences( { ...omit( preferences, "opportunity.importation" ) } ) );
    };

    const deleteCreatedAt = () => {
        dispatch( userActions.saveUserPreferences({ ...omit( preferences, "opportunity.createdAt" ) }) );
    };

    const deleteSoldAt = useCallback( () => {
        dispatch( userActions.saveUserPreferences({ ...omit( preferences, "opportunity.soldAt" ) }) );
    }, [ dispatch, preferences ] );

    const deleteTemperature = () => {
        dispatch( userActions.saveUserPreferences({ ...omit( preferences, "opportunity.temperature" ) }) );
    };

    const deleteHasOpenScheduling = () => {
        dispatch( userActions.saveUserPreferences({ ...omit( preferences, "opportunity.hasOpenScheduling" ) }) );
    };

    const deleteCompanyTags = () => {
        dispatch( userActions.saveUserPreferences({ ...set( preferences, "opportunity.companyTags", [] ) }) );
    };

    const deletePersonTags = () => {
        dispatch( userActions.saveUserPreferences({ ...set( preferences, "opportunity.personTags", [] ) }) );
    };

    const deleteProductCategories = () => {
        dispatch( userActions.saveUserPreferences( { ...omit( preferences, "opportunity.productCategories" ) } ) );
    };

    const filters = get( preferences, "opportunity.filters" ) || [];
    const companyFilters = get( preferences, "opportunity.companyFilters" ) || [];
    const personFilters = get( preferences, "opportunity.personFilters" ) || [];
    const schedulingDate = get( preferences, "opportunity.schedulingDate" );
    const importation = get( preferences, "opportunity.importation.name" );
    const createdAt = get( preferences, "opportunity.createdAt" );
    const soldAt = get( preferences, "opportunity.soldAt" );
    const companyTags = get( preferences, "opportunity.companyTags" ) || [];
    const personTags = get( preferences, "opportunity.personTags" ) || [];
    const productCategories = get( preferences, "opportunity.productCategories" );
    const getUsers = () => (
        preferences.opportunity.users.map( userId => ( users.find( user => user.id === userId ) || {} ).name )
    );
    const getProducts = () => (
        preferences.opportunity.products.map(
            productId => ( products?.find( product => product.id === productId ) || {} ).description
        )
    );

    useEffect( () => {
        if ( status !== "SOLD" && !!soldAt ) {
            deleteSoldAt();
        }
    }, [ status, soldAt, deleteSoldAt ] );

    return (
        <>
            {
                !isEmpty( get( preferences, "opportunity.users" ) ) ?
                    <Chip
                        onDelete={ () => deleteFilter({ users: [] }) }
                        label={
                            t( "form:user-filters.opportunity.users" ) +
                                    ": " +
                                    getUsers().join( ", " )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( companyTags ) &&
                    <Chip
                        onDelete={ deleteCompanyTags }
                        label={
                            t( "form:user-filters.opportunity.company-tags" ) +
                            ": " +
                            companyTags.join( ", " )
                        }
                    />
            }
            {
                !isEmpty( personTags ) &&
                    <Chip
                        onDelete={ deletePersonTags }
                        label={
                            t( "form:user-filters.opportunity.person-tags" ) +
                            ": " +
                            personTags.join( ", " )
                        }
                    />
            }
            {
                !isEmpty( get( preferences, "opportunity.company.id" ) ) &&
                    <Chip
                        onDelete={ () => deleteFilter({ company: true }) }
                        label={
                            t( "opportunity:company" ) +
                                    ": " +
                                    preferences.opportunity.company.name
                        }
                    />
            }
            {
                !isEmpty( get( preferences, "opportunity.person.id" ) ) &&
                    <Chip
                        onDelete={ () => deleteFilter({ person: true }) }
                        label={
                            t( "opportunity:person" ) +
                                    ": " +
                                    preferences.opportunity.person.name
                        }
                    />
            }
            {
                !isEmpty( get( preferences, "opportunity.products" ) ) ?
                    <Chip
                        onDelete={ () => deleteFilter({ products: [] }) }
                        label={
                            t( "form:user-filters.opportunity.products" ) +
                                    ": " +
                                    getProducts().join( ", " )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( schedulingDate ) ?
                    <Chip
                        onDelete={ deleteSchedulingDate }
                        label={
                            t( "form:user-filters.scheduling-date.label" ) +
                                    ": " +
                                    ( schedulingDate.initial && schedulingDate.final ?
                                        dateFormat( schedulingDate.initial ) +
                                        " - " +
                                        dateFormat( schedulingDate.final )
                                        :
                                        schedulingDate.initial ?
                                            t( "form:user-filters.scheduling-date.from" ) +
                                            dateFormat( schedulingDate.initial ) :
                                            t( "form:user-filters.scheduling-date.to" ) +
                                            dateFormat( schedulingDate.final )
                                    )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( importation ) &&
                    <Chip
                        onDelete={ deleteImportation }
                        label={
                            t( "form:user-filters.importation.label" ) +
                            ": " +
                            importation
                        }
                    />
            }
            {
                !isEmpty( createdAt ) ?
                    <Chip
                        onDelete={ deleteCreatedAt }
                        label={
                            t( "form:user-filters.created-at.label" ) +
                                    ": " +
                                    ( createdAt.initial && createdAt.final ?
                                        dateFormat( createdAt.initial ) +
                                        " - " +
                                        dateFormat( createdAt.final )
                                        :
                                        createdAt.initial ?
                                            t( "form:user-filters.created-at.from" ) +
                                            dateFormat( createdAt.initial ) :
                                            t( "form:user-filters.created-at.to" ) +
                                            dateFormat( createdAt.final )
                                    )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( soldAt ) ?
                    <Chip
                        onDelete={ deleteSoldAt }
                        label={
                            t( "form:user-filters.sold-at.label" ) +
                                    ": " +
                                    ( soldAt.initial && soldAt.final ?
                                        dateFormat( soldAt.initial ) +
                                        " - " +
                                        dateFormat( soldAt.final )
                                        :
                                        soldAt.initial ?
                                            t( "form:user-filters.sold-at.from" ) +
                                            dateFormat( soldAt.initial ) :
                                            t( "form:user-filters.sold-at.to" ) +
                                            dateFormat( soldAt.final )
                                    )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( get( preferences, "opportunity.temperature" ) ) ?
                    <Chip
                        onDelete={ deleteTemperature }
                        label={
                            t( "form:user-filters.opportunity.temperature" ) +
                                    ": " +
                                    t( `opportunity:temperatures.${preferences
                                        .opportunity.temperature}`)
                        }
                    /> :
                    null
            }
            {
                get( preferences, "opportunity.hasOpenScheduling" ) !== undefined ?
                    <Chip
                        onDelete={ deleteHasOpenScheduling }
                        label={ get( preferences, "opportunity.hasOpenScheduling" ) ?
                            t( "form:user-filters.opportunity.has-open-scheduling" ) :
                            t( "form:user-filters.opportunity.has-not-open-scheduling" )
                        }
                    /> :
                    null
            }
            {
                !isEmpty( productCategories ) ?
                    <Chip
                        onDelete={ deleteProductCategories }
                        label={
                            t( "opportunity:opportunity-product.category" ) +
                                    ": " +
                                    productCategories.map( productCategory => productCategory.name )
                                        .join( ", " )
                        }
                    /> :
                    null
            }
            {
                companyFilters.map( ( filter, index ) => {
                    const field = companyFields.find( field => field.id === filter.fieldId ) || {};

                    return (
                        <Chip
                            key={ index }
                            onDelete={ deleteCompanyFilters( index ) }
                            label={
                                fieldFilterFormat( field, filter.operator, filter.value )
                            }
                        />
                    );
                })
            }
            {
                personFilters.map( ( filter, index ) => {
                    const field = personFields.find( field => field.id === filter.fieldId ) || {};

                    return (
                        <Chip
                            key={ index }
                            onDelete={ deletePersonFilters( index ) }
                            label={
                                fieldFilterFormat( field, filter.operator, filter.value )
                            }
                        />
                    );
                })
            }
            {
                filters.map( ( filter, index ) => {
                    const field = fields.find( field => field.id === filter.fieldId ) || {};

                    return (
                        <Chip
                            key={ index }
                            onDelete={ () => deleteFilter({ index }) }
                            label={
                                fieldFilterFormat( field, filter.operator, filter.value ) }
                        />
                    );
                })
            }
        </>
    );
};

export default OpportunitiesFilters;