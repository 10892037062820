import { createSelector } from "reselect";
import { selectors as requestSelectors } from "redux-request-state";

const portalsBase = state => state.marketplace.integrations.portalIntegration.portals;
export const getPortals = createSelector( portalsBase, portals => portals.data || [] );
export const isLoadingPortals = requestSelectors.isLoading( portalsBase );

const icarrosDealersBase = state => state.marketplace.integrations.portalIntegration.icarrosDealers;
export const getICarrosDealers = requestSelectors.getData( icarrosDealersBase );
export const isLoadingICarrosDealers = requestSelectors.isLoading( icarrosDealersBase );
export const getICarrosDealersError = requestSelectors.getError( icarrosDealersBase );

const mobiautoDealersBase = state => state.marketplace.integrations.portalIntegration.mobiautoDealers;
export const getMobiautoDealers = requestSelectors.getData( mobiautoDealersBase );
export const isLoadingMobiautoDealers = requestSelectors.isLoading( mobiautoDealersBase );
export const getMobiautoDealersError = requestSelectors.getError( mobiautoDealersBase );