export const FETCH_FUNNELS = "x/FETCH_FUNNELS";
export const fetchFunnels = forceRequest => ({
    type: FETCH_FUNNELS,
    meta: { forceRequest }
});

export const REQUEST_FUNNELS = "x/REQUEST_FUNNELS";
export const requestFunnels = () => ({
    type: REQUEST_FUNNELS
});

export const RECEIVE_FUNNELS = "x/RECEIVE_FUNNELS";
export const receiveFunnels = data => ({
    type: RECEIVE_FUNNELS,
    payload: data
});

export const ERROR_FUNNELS = "x/ERROR_FUNNELS";
export const errorFunnels = error => ({
    type: ERROR_FUNNELS,
    payload: error,
    error: true
});