import { useCallback } from "react";
import get from "lodash/get";

import { useDateFormat, useDayMonthFormat, useTimeFormat } from "../hooks/use-date-format.hook";

export const getNotificationHref = notification => {
    switch ( notification.type ) {
        case "CUSTOMERS_UPDATED":
            return "/customers";
        case "CUSTOMER_CHANGE":
            return "/customers/" + notification.customer.id;
        case "CUSTOMER_OPPORTUNITY_CREATION":
        case "CUSTOMER_OPPORTUNITY_STATUS":
        case "CUSTOMER_OPPORTUNITY_CHANGE":
        case "MAILTOP_OPPORTUNITY_COMMENT":
        case "OPPORTUNITY_CREATION":
        case "OPPORTUNITY_COMMENT":
        case "OPPORTUNITY_COMMENT_MENTION":
        case "OPPORTUNITY_CHANGE_USER":
            return "/opportunities/" + notification.opportunity.id;
        case "SCHEDULING":
        case "SCHEDULING_CREATION":
        case "SCHEDULING_ACCOMPLISHED":
        case "SCHEDULING_NOT_ACCOMPLISHED":
        case "SCHEDULING_CHANGE_USER":
        case "SCHEDULING_MENTION":
            return notification.scheduling.opportunity ?
                "/opportunities/" + notification.scheduling.opportunity.id :
                "/schedule";
        case "EMAIL":
        case "EMAIL_READ":
            return notification.opportunity
                ? "/opportunities/" + notification.opportunity.id
                : "/customers/" + notification.customer.id;
        case "EXPORTATION_CREATED":
            return "/api/v1/exports/" + notification.exportation.id;
        case "FACEBOOK_PAGE_EXPIRED":
        case "FACEBOOK_PAGE_EXPIRING":
            return "/marketplace/integrations/facebook";
        case "IMPORTATION_FINISHED":
            return `/importations/${notification.importation.id}/results`;
        case "SMS_RECEIVED":
            return notification.smsResponse.sms.opportunityId
                ? "/opportunities/" + notification.smsResponse.sms.opportunityId
                : "/customers/" + notification.smsResponse.sms.customer.id;
        case "OPPORTUNITIES_GENERATED":
            return "/opportunities";
        case "OPPORTUNITIES_UPDATED":
            return "/opportunities?view=list";
        case "PORTAL_EXPIRED":
            return "/marketplace/integrations/portals/" +
                notification.portal.type.toLowerCase().replace(/[^a-z0-9]/g, "");
        default:
            return "";
    }
};

export const useNotificationDescription = t => {
    const dateFormat = useDateFormat( t );
    const timeFormat = useTimeFormat( t );
    const dayMonthFormat = useDayMonthFormat( t );

    const description = useCallback( notification => {
        switch ( notification.type ) {
            case "CUSTOMER_CHANGE":
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        user: notification.createdBy.name,
                        customer: notification.customer.name
                    }
                );
            case "CUSTOMER_OPPORTUNITY_CREATION":
                if ( notification.createdBy ) {
                    return t(
                        "layout:notifications.types.CUSTOMER_OPPORTUNITY_CREATION_USER",
                        {
                            user: notification.createdBy.name,
                            opportunity: notification.opportunity.title,
                            customer: notification.customer.name
                        }
                    );
                }
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        opportunity: notification.opportunity.title,
                        customer: notification.customer.name
                    }
                );
            case "CUSTOMER_OPPORTUNITY_STATUS":
            case "CUSTOMER_OPPORTUNITY_CHANGE":
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        user: notification.createdBy.name,
                        opportunity: notification.opportunity.title,
                        customer: notification.customer.name
                    }
                );
            case "EMAIL":
            case "EMAIL_READ":
                return t(
                    "layout:notifications.types." + notification.type,
                    { customer: notification.customer.name }
                );
            case "EXPORTATION_CREATED":
                if ( notification.exportation.formType === "COMPANY" ) {
                    return t( "layout:notifications.types.EXPORTATION_COMPANY_CREATED" );
                } else if ( notification.exportation.formType === "PERSON" ) {
                    return t( "layout:notifications.types.EXPORTATION_PERSON_CREATED" );
                }
                return t( "layout:notifications.types.EXPORTATION_OPPORTUNITY_CREATED" );
            case "FACEBOOK_PAGE_EXPIRED":
            case "FACEBOOK_PAGE_EXPIRING":
                return t(
                    "layout:notifications.types." + notification.type,
                    { page: notification.facebookLeadPage.pageName }
                );
            case "IMPORTATION_FINISHED":
                return t(
                    "layout:notifications.types." + notification.type,
                    { name: notification.importation.name }
                );
            case "MAILTOP_OPPORTUNITY_COMMENT":
            case "OPPORTUNITY_CREATION":
                return t(
                    "layout:notifications.types." + notification.type,
                    { opportunity: notification.opportunity.title }
                );
            case "OPPORTUNITY_CHANGE_USER":
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        opportunity: notification.opportunity.title,
                        user: notification.opportunity.user.name,
                    }
                );
            case "OPPORTUNITY_COMMENT":
            case "OPPORTUNITY_COMMENT_MENTION":
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        user: notification.createdBy.name,
                        opportunity: notification.opportunity.title
                    }
                );
            case "SCHEDULING":
                if ( notification.scheduling.allDay ) {
                    return t(
                        "layout:notifications.types." + "SCHEDULING_ALL_DAY",
                        {
                            title: get( notification, "scheduling.opportunitySchedulingType.name",
                                notification.scheduling.title ),
                            date: dayMonthFormat( notification.scheduling.startDate ),
                            interpolation: {
                                escapeValue: false
                            }
                        }
                    );
                }
                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        title: get( notification, "scheduling.opportunitySchedulingType.name",
                            notification.scheduling.title ),
                        hour: timeFormat( notification.scheduling.startDate ),
                        date: dayMonthFormat( notification.scheduling.startDate ),
                        interpolation: {
                            escapeValue: false
                        }
                    }
                );
            case "SCHEDULING_CREATION":
            case "SCHEDULING_ACCOMPLISHED":
            case "SCHEDULING_NOT_ACCOMPLISHED":
                const now = new Date();
                const notificationYear = new Date( notification.scheduling.startDate );

                return t(
                    "layout:notifications.types." + notification.type,
                    {
                        user: notification.createdBy.name,
                        title: get( notification, "scheduling.opportunitySchedulingType.name",
                            notification.scheduling.title ),
                        date: now.getFullYear() === notificationYear.getFullYear() ?
                            dayMonthFormat( notification.scheduling.startDate ) :
                            dateFormat( notification.scheduling.startDate ),
                        hour: timeFormat( notification.scheduling.startDate ),
                        interpolation: {
                            escapeValue: false
                        }
                    }
                );
            case "SCHEDULING_CHANGE_USER":
                if ( notification.scheduling.user ) {
                    return t(
                        "layout:notifications.types." + notification.type,
                        {
                            user: notification.scheduling.user.name,
                            title: get( notification, "scheduling.opportunitySchedulingType.name",
                                notification.scheduling.title )
                        }
                    );
                }
                return t(
                    "layout:notifications.types." + "SCHEDULING_REMOVE_USER",
                    {
                        user: notification.createdBy.name,
                        title: get( notification, "scheduling.opportunitySchedulingType.name",
                            notification.scheduling.title )
                    }
                );
            case "SCHEDULING_MENTION":
                return t(
                    "layout:notifications.types.SCHEDULING_MENTION",
                    {
                        user: notification.createdBy.name,
                        title: notification.scheduling.opportunitySchedulingType.name,
                        opportunity: notification.scheduling.opportunity.title
                    }
                );
            case "SMS_RECEIVED":
                return t(
                    "layout:notifications.types." + notification.type,
                    { customer: notification.smsResponse.sms.customer.name }
                );
            case "CUSTOMERS_UPDATED":
            case "OPPORTUNITIES_GENERATED":
            case "OPPORTUNITIES_UPDATED":
                return t( "layout:notifications.types." + notification.type );
            case "PORTAL_EXPIRED":
                return t(
                    "layout:notifications.types." + notification.type,
                    { portal: notification.portal.name }
                );
            default:
                return "";
        }
    }, [ t, dateFormat, timeFormat, dayMonthFormat ]);

    return description;
};
