import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FlagIcon from "@mui/icons-material/Flag";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import EditFacebookLeadPage from "./edit-facebook-lead-page.component";
import DeleteFacebookLeadPage from "./delete-facebook-lead-page.component";
import RefreshFacebookLeadPage from "./refresh-facebook-lead-page.component";

const FacebookLeadPageItem = ({ facebookLeadPage }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const [ openEdit, setOpenEdit ] = useState( false );

    const toggleEdit = () => setOpenEdit( openEdit => !openEdit );

    return (
        <>
            <ListItem
                disablePadding
                secondaryAction={
                    <>
                        <RefreshFacebookLeadPage facebookLeadPage={ facebookLeadPage}/>
                        <DeleteFacebookLeadPage facebookLeadPage={ facebookLeadPage }/>
                    </>
                }
            >
                <ListItemButton onClick={ toggleEdit }>
                    <ListItemIcon>
                        <FlagIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            facebookLeadPage.pageName +
                                ( facebookLeadPage.formName ? ` - ${facebookLeadPage.formName}` : "" )
                        }
                        secondary={
                            t(
                                "marketplace:integrations.facebook.lead-pages.created-info",
                                {
                                    date: dateFormat( facebookLeadPage.createdAt ),
                                    user: facebookLeadPage.createdBy.name,
                                    interpolation: { escapeValue: false }
                                }
                            )
                        }
                    />
                </ListItemButton>
            </ListItem>

            <EditFacebookLeadPage
                open={ openEdit }
                onClose={ toggleEdit }
                facebookLeadPage={ facebookLeadPage }
            />
        </>
    );
};

export default FacebookLeadPageItem;