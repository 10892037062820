import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogConfirm } from "../../ui";
import { isDeletingPerson } from "../customer.selectors";
import { deletePerson } from "../customer.actions";

const CustomerPersonItem = ({ customer, opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const deleting = useSelector( isDeletingPerson );

    const [ openConfirm, setOpenConfirm ] = useState( false );
    const toggleConfirm = () => setOpenConfirm( !openConfirm );

    const handleDelete = () => dispatch( deletePerson({ id: customer.id, company: customer.company, opportunity }));

    return (
        <>
            <IconButton onClick={ toggleConfirm } edge="end" size="large">
                <DeleteIcon/>
            </IconButton>

            <DialogConfirm
                open={ openConfirm }
                disabled={ deleting }
                message={
                    opportunity ?
                        t( "customer:person.confirm-delete-from-opportunity" ) :
                        t( "customer:person.confirm-delete" )
                }
                onCancel={ toggleConfirm }
                onConfirm={ handleDelete }
            />
        </>
    );
};

export default CustomerPersonItem;