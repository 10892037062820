import FieldConfig from "./field-config.component";
import GroupingConfig from "./grouping-config.component";

const FieldDemo = ({ className, field, onDeselect }) => (
    <div className={ className }>
        {
            field.type === "GROUPING" ?
                <GroupingConfig
                    field={ field }
                    onCancel={ onDeselect }
                /> :
                <FieldConfig
                    field={ field }
                    onCancel={ onDeselect }
                />
        }
    </div>
);

export default FieldDemo;