import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as templateSelectors } from "../../../template";
import { selectors as userSelectors } from "../../../user";
import { Dropdown, DropdownMultiple, Input, Outlined, Switch, cssUtils, flex } from "../../../ui";
import css from "./funnel.scss";

const PhaseTriggersItem = ({
    handleChange,
    handleRemove,
    name,
    values,
}) => {
    const t = useTranslate();

    const allTemplates = useSelector( templateSelectors.getTemplates );
    const users = useSelector( userSelectors.listAllActive );

    const templates = useMemo(
        () => ( allTemplates || [] ).filter( template => template.type.match( "EMAIL|SMS" ) ),
        [ allTemplates ]
    );

    const automatic = useMemo( () => values.automatic, [ values.automatic ] );
    const sendCustomer = useMemo( () => values.sendCustomer, [ values.sendCustomer ] );

    useEffect( () => {
        if ( !automatic ) {
            handleChange( `${name}.minutes`, null );
        }
    }, [ automatic, handleChange, name ] );
    useEffect( () => {
        if ( sendCustomer ) {
            handleChange( `${name}.usersId`, null );
        }
    }, [ sendCustomer, handleChange, name ] );

    return (
        <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
            <Outlined
                className={ classnames( flex.fill, flex.container, flex.alignItemsCenter )}
                fullWidth
            >
                <div className={ flex.fill }>
                    <Field
                        label={ t( "config:phases.trigger.template" ) }
                        name={ `${name}.template.id`}
                        className={ flex.fill }
                        required
                        component={ Dropdown }
                        source={ templates.map( template => ({
                            value: template.id,
                            label: template.name
                        }))}
                        fullWidth
                    />
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            label={ t( "config:phases.trigger.automatic.label" ) }
                            type="checkbox"
                            name={ `${name}.automatic` }
                            component={ Switch }
                            fullWidth={ false }
                        />
                        <Tooltip title={ t( "config:phases.trigger.automatic.help" ) }>
                            <HelpIcon className={ css.helpIcon } />
                        </Tooltip>
                        <Field
                            label={ t( "config:phases.trigger.minutes.label" ) }
                            placeholder={ t( "config:phases.trigger.minutes.placeholder" ) }
                            name={ `${name}.minutes` }
                            className={ classnames( cssUtils.marginLeft, flex.fill ) }
                            type="number"
                            component={ Input }
                            fullWidth={ false }
                            disabled={ !values.automatic }
                        />
                    </div>
                    <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                        <Field
                            label={ t( "config:phases.trigger.send-customer.label" ) }
                            type="checkbox"
                            name={ `${name}.sendCustomer` }
                            className={ css.phaseTriggerCheckbox }
                            component={ Switch }
                            fullWidth={ false }
                        />
                        <Tooltip title={ t( "config:phases.trigger.send-customer.help" ) }>
                            <HelpIcon className={ css.helpIcon } />
                        </Tooltip>
                        <div className={ classnames( cssUtils.marginLeft, flex.fill ) }>
                            <Field
                                label={ t( "config:phases.trigger.users-id" ) }
                                name={ `${name}.usersId` }
                                className={ flex.fill }
                                component={ DropdownMultiple }
                                source={
                                    users.map( user => ({
                                        value: user.id,
                                        label: user.name
                                    }))
                                }
                                disabled={ values.sendCustomer }
                            />
                        </div>
                    </div>
                </div>
            </Outlined>
            <div>
                <IconButton onClick={ handleRemove } size="large">
                    <CancelIcon/>
                </IconButton>
            </div>
        </div>
    );
};

export default PhaseTriggersItem;