import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

import css from "./layout.scss";

const LoadingView = () => (
    <div className={ css.loadingContainer }>
        <Helmet defaultTitle="LeadStation" />

        <Box position="relative" display="inline-flex">
            <CircularProgress color="secondary" size={ 64 } />
            <Box
                top={ 0 }
                left={ 0 }
                bottom={ 0 }
                right={ 0 }
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <img src="https://img.leadstation.com.br/logo/48x48.png"/>
            </Box>
        </Box>
    </div>
);

export default LoadingView;