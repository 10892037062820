export const getSubscriptionBilling = state => state.config.subscription.plan.subscriptionBilling.data;
export const getSubscriptionCoupon = state => state.config.subscription.plan.subscriptionCoupon.data;
export const isLoadingSubscriptionBilling = state => state.config.subscription.plan.subscriptionBilling.loading;

export const getBillings = state => state.config.subscription.plan.billings.data;
export const isLoadingBillings = state => state.config.subscription.plan.billings.loading;
export const getBillingsPages = state => state.config.subscription.plan.totalPages;
export const getBillingsTotal = state => state.config.subscription.plan.totalElements;

export const getCreditCards = state => state.config.subscription.plan.creditCards.data;
export const isLoadingCreditCards = state => state.config.subscription.plan.creditCards.loading;

export const getSubscriptionStorage = state => state.config.subscription.plan.subscriptionStorage.data;
export const isLoadingSubscriptionStorage = state => state.config.subscription.plan.subscriptionStorage.loading;
