import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./phase.actions";
import * as selectors from "./phase.selectors";

export function *watchPhase () {
    yield all([
        takeEvery( actions.FETCH_PHASES, fetchPhases ),
        takeEvery( actions.FETCH_FUNNEL_PHASES, fetchFunnelPhases ),
        takeEvery( actions.FETCH_PHASES_COUNT, fetchPhasesCount ),
    ]);
}

export function *fetchPhases ({ meta: { forceRequest } }) {
    const previousPhases = yield select( selectors.isLoadedAll );
    if ( previousPhases && !forceRequest ) {
        return;
    }

    yield put( actions.requestPhases() );

    try {
        const response = yield call( apiv1.get, "/phases" );
        const phases = response.data;
        yield put( actions.receiveFunnelsPhases( phases ) );
        yield put( actions.receivePhases() );
    } catch ( e ) {
        yield put( actions.errorPhases( e.response.data ) );
    }
}

function *fetchFunnelPhases ({ meta: { funnelId } }) {
    const previousPhases = yield select( selectors.listWithFunnel( funnelId ) );
    if ( previousPhases ) {
        return;
    }

    yield put( actions.requestFunnelPhases( funnelId ) );

    try {
        const response = yield call( apiv1.get, `/funnels/${funnelId}/phases` );
        yield put( actions.receiveFunnelPhases( funnelId, response.data ) );
    } catch ( e ) {
        yield put( actions.errorFunnelPhases( funnelId, e.response.data ) );
    }
}

function *fetchPhasesCount ({ meta: { funnelId }, payload: { params } }) {
    yield put( actions.requestPhasesCount() );

    try {
        const response = yield call( apiv1.get, `/funnels/${funnelId}/phases/count`, { params } );
        yield put( actions.receivePhasesCount( response.data ) );
    } catch ( e ) {
        yield put( actions.errorPhasesCount( e.response.data ) );
    }
}