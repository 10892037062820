import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import useTranslate from "../../../hooks/use-translate.hook";
import { deleteCustomerSample } from "../../../subscription/subscription.actions";
import { DialogConfirmForm } from "../../../ui";
import { DELETE_CUSTOMER_SAMPLE_FORM } from "./main.constants";

const SubscriptionSampleDelete = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const onSubmit = () => {
        dispatch( deleteCustomerSample() );
    };

    const formik = useForm({
        initialValues: {},
        enableReinitialize: true,
        form: DELETE_CUSTOMER_SAMPLE_FORM,
        onSubmit
    });

    const [ open, setOpen ] = useState( false );
    const toggleOppen = () => setOpen( !open );

    return (
        <>
            <Typography color="textSecondary" variant="subtitle1" paragraph>
                { t("config:subscription.sample.sample-description") }
            </Typography>
            <Button
                color={ "error" }
                variant={ "contained" }
                onClick={ () => toggleOppen() }
            >
                { t("config:subscription.sample.sample-delete") }
            </Button>
            <DialogConfirmForm
                open={ open }
                onClose={ () => toggleOppen() }
                message={ t("config:subscription.sample.confirm-delete") }
                handleSubmit={ formik.handleSubmit }
                submitForm={ formik.submitForm }
                submitting={ formik.submitting }
            />
        </>
    );
};

export default SubscriptionSampleDelete;
