import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import get from "lodash/get";
import set from "lodash/set";

import useTranslate from "../../../hooks/use-translate.hook";
import { Input } from "../../../ui";
import { createFunnel } from "./funnel.actions";
import { NEW_FUNNEL_FORM } from "./funnel.constants";

const NewFunnel = ({ onClose, show }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: NEW_FUNNEL_FORM,
        initialValues: {
            name: "",
            phase: {
                name: "",
            },
            opportunitiesOrderBy: "updatedAt",
            opportunitiesOrderType: "DESC"
        },
        onSubmit: values => dispatch( createFunnel( values ) ),
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !get( values, "phase.name" ) ) {
                set( errors, "phase.name", "common:validation.required" );
            }
            return errors;
        }
    });

    return (
        <Dialog open={ show } maxWidth="sm" fullWidth>
            <DialogTitle>
                { t( "config:funnels.new.title" ) }
            </DialogTitle>
            <DialogContent>
                <Form formik={ formik }>
                    <Field
                        name="name"
                        label={ t( "config:funnels.new.name" ) }
                        component={ Input }
                        required
                    />

                    <Field
                        name="phase.name"
                        label={ t( "config:funnels.new.phase-name" ) }
                        component={ Input }
                        required
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={ formik.submitting } onClick={ onClose }>
                    { t( "common:cancel" ) }
                </Button>
                <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewFunnel;