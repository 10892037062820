import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import SmsIcon from "@mui/icons-material/Sms";
import isEmpty from "lodash/isEmpty";

import { deleteTemplate } from "../config/opportunity/template/template.actions";
import { DELETE_TEMPLATE_FORM } from "../config/opportunity/template/template.constants";
import { actions as templateActions, selectors as templateSelectors } from "../template";
import useTranslate from "../hooks/use-translate.hook";
import WhatsAppIcon from "../ui/icons/whatsapp.component";
import Template from "./template.component";

const UserTemplates = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( templateSelectors.isLoadingTemplates );
    const templates = useSelector( templateSelectors.getMyTemplates );
    const deleting = useSelector( isSubmitting( DELETE_TEMPLATE_FORM ) );

    const [ openTemplateForm, setOpenTemplateForm ] = useState( false );
    const [ selectedTemplate, setSelectedTemplate ] = useState( "" );
    const [ confirmDelete, setConfirmDelete ] = useState( "" );

    const toggleTemplateForm = () => {
        setOpenTemplateForm( !openTemplateForm );
        setSelectedTemplate( null );
    };

    const selectTemplate = template => {
        setOpenTemplateForm( true );
        setSelectedTemplate( template );
    };

    const toDelete = template => setConfirmDelete( template );

    const handleDelete = () => dispatch( deleteTemplate( confirmDelete.id ) );

    const renderTemplateIcon = template => {
        switch ( template.type ) {
            case "EMAIL":
                return <MailIcon/>;
            case "SMS":
                return <SmsIcon/>;
            case "WHATSAPP":
                return <WhatsAppIcon/>;
            default:
                return "";
        }
    };

    useEffect( () => {
        dispatch( templateActions.fetchTemplates() );
    }, [ dispatch ] );
    useEffect( () => {
        if ( !deleting ) {
            setConfirmDelete( "" );
        }
    }, [ deleting ] );

    return (
        <>
            <Template
                open={ openTemplateForm }
                template={ selectedTemplate }
                onClose={ toggleTemplateForm }
            />

            <Card>
                <CardHeader
                    title={ t( "user:templates.title" ) }
                    subheader={ t( "user:templates.call-to-action" ) }
                />
                { loading && <LinearProgress /> }
                {
                    !loading &&
                        <List disablePadding>
                            <ListItemButton onClick={ toggleTemplateForm }>
                                <ListItemIcon><AddIcon/></ListItemIcon>
                                <ListItemText primary={ t( "common:add" ) }/>
                            </ListItemButton>
                            <Divider/>
                            {
                                isEmpty( templates ) &&
                                    <ListItem>
                                        <ListItemText secondary={t( "config:templates.no-templates" ) } />
                                    </ListItem>
                            }
                            {
                                templates.map( template => (
                                    <ListItem
                                        key={ template.id }
                                        onClick={ () => selectTemplate( template ) }
                                    >
                                        <ListItemIcon>
                                            { renderTemplateIcon( template ) }
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={ template.name }
                                            secondary={ t( `config:template.type.${template.type}` ) }
                                        />
                                        <ListItemSecondaryAction>
                                            <Tooltip title={ t( "common:exclude" ) }>
                                                <IconButton
                                                    onClick={ () => toDelete( template ) }
                                                    size="large"
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                }
            </Card>

            <Dialog open={ !!confirmDelete }>
                <DialogContent>
                    <DialogContentText>
                        { t( "config:templates.delete.confirm" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ () => toDelete() } disabled={ deleting }>
                        { t( "common:no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete } disabled={ deleting }>
                        { t( "common:yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserTemplates;