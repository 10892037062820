import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./commission.actions";

export function *watchCommission () {
    yield all([
        takeEvery( actions.FETCH_COMMISSIONS, fetchCommissions )
    ]);
}

function *fetchCommissions () {
    yield put( actions.requestCommissions() );
    try {
        const response = yield call( apiv1.get, "/commissions" );
        yield put( actions.receiveCommissions( response.data ) );
    } catch ( e ) {
        yield put( actions.errorCommissions( e.response.data ) );
    }
}