import { all, call, put, takeEvery } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "formik-redux";

import { apiv1 } from "../../api/sagas";
import { fetchFiles as fetchCustomerFiles } from "../customer/customer.actions";
import { fetchFiles as fetchOpportunityFiles } from "../opportunity/opportunity.actions";
import * as actions from "./file.actions";
import { NEW_FILE_FROM_MODEL_FORM } from "./file.constants";

export function *watchFiles () {
    yield all([
        takeEvery( actions.FETCH_MODELS, fetchModels ),
        takeEvery( actions.FETCH_MODEL_SECTIONS, fetchModelSections ),
        takeEvery( actions.CREATE_FILE_FROM_MODEL, createFileFromModel ),
    ]);
}

export function *fetchModels () {
    yield put( actions.requestModels() );
    try {
        const response = yield call( apiv1.get, "/filemodels" );
        yield put( actions.receiveModels( response.data ) );
    } catch ( e ) {
        yield put( actions.errorModels( e.response.data ) );
    }
}

export function *fetchModelSections ({ meta: { id } }) {
    yield put( actions.requestModelSections( id ) );
    try {
        const response = yield call( apiv1.get, `/filemodels/${id}/sections` );
        yield put( actions.receiveModelSections( id, response.data ) );
    } catch ( e ) {
        // lala
    }
}

export function *createFileFromModel ({ payload: { fileModelId, companyId, personId, opportunityId, name } }) {
    try {
        yield put( startSubmit( NEW_FILE_FROM_MODEL_FORM ) );
        const params = { fileModelId, companyId, personId, opportunityId };
        const fileBuffer = yield call( apiv1.get, "/filemodels/download", { params, responseType: "blob" } );
        const blob = new Blob([ fileBuffer.data ], { type: "application/pdf" });
        const file = new File([ blob ], `${name}.pdf` );
        const formData = new FormData();
        formData.append( "file", file );

        if ( companyId || personId ) {
            yield call( apiv1.put, `/customers/${companyId || personId}/files`, formData );
            yield put( fetchCustomerFiles( companyId || personId ) );
        } else {
            yield call( apiv1.put, `/opportunities/${opportunityId}/files`, formData );
            yield put( fetchOpportunityFiles( opportunityId ) );
        }
        yield put( stopSubmit( NEW_FILE_FROM_MODEL_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( NEW_FILE_FROM_MODEL_FORM, e.response.data ) );
    }
}