import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";

import useTranslate from "../../../hooks/use-translate.hook";
import { DialogConfirmForm } from "../../../ui";
import { deleteImportation } from "./product.actions";
import { createDeleteImportationForm } from "./product.utils";

const DeleteProductImportation = ({ importation, open, onClose }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: createDeleteImportationForm( importation.id ),
        initialValues: {
            id: importation.id
        },
        onSubmit: values => dispatch( deleteImportation( values ) ),
        onSubmitSuccess: onClose,
    });

    return (
        <DialogConfirmForm
            open={ open }
            onClose={ onClose }
            message={ t( "config:products.importation.delete" ) }
            handleSubmit={ formik.handleSubmit }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
        />
    );
};

export default DeleteProductImportation;