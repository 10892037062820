import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import omit from "lodash/omit";

import { Dropdown } from "../ui";
import { setBrand } from "./form.actions";

const BrandField = ({ formField: { options }, field, input, ...props }) => {
    const dispatch = useDispatch();

    const name = useMemo( () => field.name, [ field ] );
    const handleChange = useMemo( () => field.onChange, [ field.onChange ] );
    const value = useMemo( () => field.value, [ field.value ] );

    useEffect( () => {
        if ( value ) {
            if ( !options.values.includes( value ) ) {
                dispatch( setBrand() );
                handleChange({ target: { name, value: null } });
                return;
            }
        }

        dispatch( setBrand( value ) );
    }, [ dispatch, handleChange, name, options, value ] );

    useEffect( () => {
        return () => {
            dispatch( setBrand() );
        };
    }, [ dispatch ] );

    return (
        <Dropdown
            { ...omit( props, "currentPosition" ) }
            field={ field }
            input={ input }
            source={ options.values.map( option => ({ value: option, label: option }))}
            emptyValue
        />
    );
};

export default BrandField;