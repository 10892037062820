import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors, validateFields } from "../../form";
import { Dialog, cssUtils } from "../../ui";
import { fetchPersons } from "../../customer/customer.actions";
import { getPersons } from "../../customer/customer.selectors";
import CustomerWizard from "../../customer/customer-wizard.component";
import customerCss from "../../customer/person/person.scss";
import { patchOpportunity } from "../opportunity.actions";
import { ADD_PERSON_FORM } from "../opportunity.constants";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const AddPerson = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "PERSON" ) );
    const persons = useSelector( getPersons );
    const opportunityPersons = useMemo(
        () => ( persons || [] )
            .filter( person => !opportunity || !opportunity.person || person.id !== opportunity.person.id ),
        [ persons, opportunity ]
    );

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const initialValues = useMemo( () => ({
        id: opportunity.id,
        person: {
            type: "PERSON",
            fields: fieldsToInitialValues( fields )
        }
    }), [ fields, opportunity ] );

    const formik = useForm({
        form: ADD_PERSON_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( patchOpportunity({ ...values, formikForm: ADD_PERSON_FORM }) ),
        onSubmitSuccess: () => setOpen( false ),
        validate: values => {
            const errors = {};
            if ( !values ) {
                return errors;
            }

            const person = get( values, "person", {} );
            if ( person.new ) {
                errors.person = validateFields( person, { t, fields } );
            } else if ( !person.id ) {
                errors.person = {
                    id: "common:validation.selection"
                };
            }

            if ( errors.person && isEmpty( errors.person ) ) {
                delete errors.person;
            }

            return errors;
        }
    });

    const handleResetForm = () => formik.setFieldValue( "person.fields", initialValues.person.fields );

    useEffect( () => {
        if ( opportunity.company ) {
            dispatch( fetchPersons( opportunity.company.id ) );
        }
    }, [ dispatch, opportunity.company ] );

    return (
        <>
            <Tooltip title={ t( "customer:person.add-title" ) }>
                <IconButton onClick={ toggleOpen } color="inherit" size="large">
                    <AddIcon/>
                </IconButton>
            </Tooltip>

            <Dialog open={ open } fullWidth maxWidth="sm">
                <DialogTitle className={ customerCss.titleAddPerson }>
                    { t( "customer:person.add-title" ) }
                </DialogTitle>
                <DialogContent className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRight0 )}>
                    <Form formik={ formik }>
                        <CustomerWizard
                            name="person"
                            type="PERSON"
                            resetForm={ handleResetForm }
                            setFieldValue={ formik.setFieldValue }
                            suggestions={ opportunity && opportunityPersons }
                            suggestionsTitle={ t( "customer:person.company-persons" ) }
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={ formik.submitting } onClick={ toggleOpen }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddPerson;