import { createSelector } from "reselect";
import get from "lodash/get";
import { selectors as requestSelectors } from "redux-request-state";

// ---------------------------------------------------------------------------------------------------------------------
// Customer list
// ---------------------------------------------------------------------------------------------------------------------
const allBase = state => state.customers.all;
export const getAllType = createSelector( allBase, all => all.type || "COMPANY" );
export const listAll = createSelector( allBase, all => all.data );
export const getTotalPagesAll = state => state.customers.all.totalPages;
export const getTotalElementsAll = state => state.customers.all.totalElements;
export const isLoadingAll = state => state.customers.all.loading;
export const getErrorAll = state => state.customers.all.error;

const searchBase = state => state.customers.search;
export const isLoadingSearch = requestSelectors.isLoading( searchBase );
export const getSearchCustomers = requestSelectors.getData( searchBase );
export const getSearchError = requestSelectors.getError( searchBase );

// ---------------------------------------------------------------------------------------------------------------------
// Single customer
// ---------------------------------------------------------------------------------------------------------------------
const selectedCustomerBase = state => state.customers.selected;
export const isLoadingSelected = requestSelectors.isLoading( selectedCustomerBase );
export const getSelected = createSelector( selectedCustomerBase, selected => selected.data );
export const getError = requestSelectors.getError( selectedCustomerBase );

// Person
export const isLoadingPersons = state => state.customers.persons.loading;
export const getPersons = createSelector( state => state.customers.persons, persons => persons.all );
export const isDeletingPerson = state => state.customers.persons.deleting;

// Contacts
const contactsBase = state => state.customers.contacts;
export const isLoadingContacts = requestSelectors.isLoading( contactsBase );
export const getContacts = state => get( requestSelectors.getData( contactsBase )( state ), "contacts" );
export const getContactOf = state => get( requestSelectors.getData( contactsBase )( state ), "contactOf" );
export const getContactsError = requestSelectors.getError( contactsBase );

const contactTypesBase = state => state.customers.contactTypes;
export const getContactTypes = requestSelectors.getData( contactTypesBase );
export const isLoadingContactTypes = requestSelectors.isLoading( contactTypesBase );

// Statistics
const statisticsBase = state => state.customers.statistics;
export const isLoadingStatistics = requestSelectors.isLoading( statisticsBase );
export const getStatistics = requestSelectors.getData( statisticsBase );

// Timeline
const timelineBase = state => state.customers.timeline;
export const isLoadingTimeline = createSelector( timelineBase, timeline => timeline.loading );
export const getTimelineEvents = createSelector( timelineBase, timeline => timeline.data );
export const getTimelineError = requestSelectors.getError( timelineBase );
export const getTotalPagesTimeline = createSelector( timelineBase, timeline => timeline.totalPages );
export const reloadTimeline = createSelector( timelineBase, timeline => timeline.reload );

// Following
const followingBase = state => state.customers.following;
export const isFollowing = requestSelectors.getData( followingBase );

// Files
const filesBase = state => state.customers.files;
export const getFiles = requestSelectors.getData( filesBase );
export const isLoadingFiles = requestSelectors.isLoading( filesBase );

// Tags
const tagsBase = state => state.customers.tags;
export const isLoadingTags = requestSelectors.isLoading( tagsBase );
export const getTags = createSelector( tagsBase, tags => tags.data || [] );

// Email
export const isNewEmailOpen = state => state.customers.newEmail.open;
export const getNewEmailValues = state => state.customers.newEmail.initialValues;

// New Opportunity
export const isNewOpportunityOpen = state => state.customers.newOpportunity.open || false;

// ---------------------------------------------------------------------------------------------------------------------
// Customer opportunities
// ---------------------------------------------------------------------------------------------------------------------
const opportunitiesBase = state => state.customers.opportunities;
export const isLoadingOpportunities = requestSelectors.isLoading( opportunitiesBase );
export const listOpportunities = createSelector( opportunitiesBase, opportunities => opportunities.data );
export const getOpportunititesError = requestSelectors.getError( opportunitiesBase );
export const listOpenOpportunities = createSelector(
    listOpportunities,
    opportunities => opportunities?.filter( opportunity => opportunity.status === "OPEN" ) || []
);

// ---------------------------------------------------------------------------------------------------------------------
// New customer
// ---------------------------------------------------------------------------------------------------------------------
export const isNewCustomerOpen = state => state.customers.create.open;

// ---------------------------------------------------------------------------------------------------------------------
// Company Data
// ---------------------------------------------------------------------------------------------------------------------
const companyDataBase = state => state.customers.companyData;
export const isLoadingCompanyData = requestSelectors.isLoading( companyDataBase );
export const getCompanyData = createSelector( companyDataBase, companyData => companyData.data );
export const getCompanyDataError = createSelector( companyDataBase, companyData => companyData.error );