import { Field } from "formik";
import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { listWithoutGroupings } from "../../../form/form.selectors";
import { Dropdown, flex } from "../../../ui";

const FORM_TYPES = [ "COMPANY", "PERSON", "OPPORTUNITY" ];

const KanbanFieldItem = ({ index, onRemove, setFieldValue, values }) => {
    const t = useTranslate();

    const formType = useMemo( () => values.formType, [ values.formType ] );
    const fieldId = useMemo( () => values.fieldId, [ values.fieldId ] );

    const fields = useSelector( listWithoutGroupings( formType ) );

    const sourceFields = useMemo( () => {
        return fields
            .filter( field => !field.systemField || field.systemField === "MODEL" || field.systemField === "BRAND" )
            .filter( field => field.type.match( "TEXT|NUMBER|SELECT" ) )
            .map( field => ({
                value: field.id,
                label: field.name
            }));
    }, [ fields ] );

    useEffect( () => {
        if ( fields.length > 0 && fieldId && !fields.some( field => field.id === fieldId ) ) {
            setFieldValue( `fields[${index}].fieldId`, null );
        }
    }, [ fieldId, fields, index, setFieldValue ] );

    return (
        <div className={ classnames( flex.container, flex.columnGapSmall ) }>
            <Field
                name={ `fields[${index}].formType` }
                label={ t( "common:type" ) }
                source={ FORM_TYPES.map( formType => ({
                    value: formType,
                    label: t( `config:form.modules.${formType}` )
                }) ) }
                component={ Dropdown }
                fullWidth={ false }
                className={ flex.fill }
                required
            />
            <Field
                name={ `fields[${index}].fieldId` }
                label={ t( "config:kanban.field" ) }
                source={ sourceFields }
                component={ Dropdown }
                className={ flex.fill }
                fullWidth={ false }
                required
            />
            <IconButton className={ flex.alignSelfCenter } onClick={ onRemove }>
                <CancelIcon />
            </IconButton>
        </div>
    );
};

export default KanbanFieldItem;