import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import classnames from "classnames";
import throttle from "lodash/throttle";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import history from "../../history";
import useTranslate from "../hooks/use-translate.hook";
import { selectors as formSelectors, validateFields, Fields } from "../form";
import { actions as customerActions, selectors as customerSelectors } from "../customer";
import { saveLeadForm } from "./web-capture.actions";
import { getLead } from "./web-capture.selectors";
import { LEAD_FORM } from "./web-capture.constants";
import { Autocomplete, SelectFunnel, cssUtils, flex } from "../ui";
import css from "./lead-form.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const getOpportunityValues = ( lead, opportunityFields ) => {
    if ( !lead || isEmpty( opportunityFields ) ) {
        return {};
    }

    const sourceField = opportunityFields.find( field => field.systemField === "SOURCE" );
    return {
        ...fieldsToInitialValues( opportunityFields ),
        [ sourceField.id ]: lead.source.id
    };
};

const LeadForm = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const customerFields = useSelector( formSelectors.list( "PERSON" ) );
    const opportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );
    const loadingCustomers = useSelector( customerSelectors.isLoadingSearch );
    const customers = useSelector( customerSelectors.getSearchCustomers );
    const customer = useSelector( customerSelectors.getSelected );
    const lead = useSelector( getLead );

    const initialValues = useMemo( () => ({
        lead,
        opportunity: {
            fields: getOpportunityValues( lead, opportunityFields ),
            funnelId: lead.funnelId
        },
        customer: {
            type: "PERSON",
            fields: fieldsToInitialValues( customerFields )
        }
    }), [ lead, customerFields, opportunityFields ] );

    const formik = useForm({
        form: LEAD_FORM,
        enableReinitialize: true,
        initialValues,
        onSubmit: values => dispatch( saveLeadForm( values ) ),
        validate: values => {
            const errors = {};

            errors.customer = validateFields( values.customer, { t, fields: customerFields } );

            errors.opportunity = validateFields( values.opportunity, { t, fields: opportunityFields } );

            if ( errors.customer && isEmpty( errors.customer ) ) {
                delete errors.customer;
            }

            if ( !values.opportunity.funnelId ) {
                errors.opportunity.funnelId = "common:validation.selection" ;
            }

            if ( errors.opportunity && isEmpty( errors.opportunity ) ) {
                delete errors.opportunity;
            }


            return errors;
        }
    });
    const setFieldValue = formik.setFieldValue;

    const searchCustomer = query => {
        if ( !query || loadingCustomers ) {
            return;
        }
        dispatch( customerActions.fetchSearchCustomers({ query, type: "PERSON" }) );
    };

    const selectCustomer = throttle( event => {
        const id = event?.target.value;
        if ( id ) {
            const customer = customers.find( customer => customer.id === id );
            dispatch( customerActions.setCustomer( customer ) );
        } else {
            dispatch( customerActions.setCustomer( null ) );
        }
    }, 200, { leading: false } );

    const customersSource = useMemo( () => customers?.reduce(
        ( model, customer ) => {
            model[ customer.id ] = customer.name;
            return model;
        },
        {}
    ), [ customers ] );

    useEffect( () => {
        if ( customer && customer.id ) {
            setFieldValue( "customer", customer );
        } else {
            setFieldValue( "customer", { type: "PERSON", fields: {} });
        }
    }, [ customer, setFieldValue ] );

    if ( lead.status === "DISCARTED" ) {
        return (
            <Card
                id="leadForm"
                className={ classnames( className, flex.container, flex.column, cssUtils.fullHeight ) }
            >
                <CardContent>
                    { t( "web-capture:lead-form.discarted" ) }
                </CardContent>
            </Card>
        );
    } else if ( lead.status === "CONVERTED" ) {
        const customer = lead.opportunity.person || lead.opportunity.company;

        return (
            <Card
                id="leadForm"
                className={ classnames( className, flex.container, flex.column, cssUtils.fullHeight ) }
            >
                <CardHeader subheader={ t( "web-capture:lead-form.converted" ) }/>

                <div
                    className={ cssUtils.cursorPointer }
                    onClick={ () => history.push( `/customers/${customer.id}` ) }
                >
                    <CardHeader
                        avatar={
                            <Avatar src={ customer.avatar }>
                                {
                                    !customer.avatar
                                        && customer.name.charAt( 0 ).toUpperCase()
                                }
                            </Avatar>
                        }
                        title={ customer.name }
                        subheader={ t( "web-capture:lead-form.customer" ) }
                    />
                </div>

                <div
                    className={ cssUtils.cursorPointer }
                    onClick={ () => history.push( `/opportunities/${lead.opportunity.id}` ) }
                >
                    <CardHeader
                        avatar={ <Avatar><AttachMoneyIcon/></Avatar> }
                        title={ lead.opportunity.title }
                        subheader={ t( "web-capture:lead-form.opportunity" ) }
                    />
                </div>
            </Card>
        );
    }

    return (
        <Form
            id="leadForm"
            className={ classnames( className, flex.container, flex.column, cssUtils.fullHeight ) }
            formik={ formik }
            noValidate
        >
            <Card>
                <CardHeader subheader={ t( "web-capture:lead-form.title" ) }/>
            </Card>
            <Card className={ classnames( flex.fill, cssUtils.overflowAuto ) }>
                <CardContent>
                    <Autocomplete
                        multiple={ false }
                        placeholder={ t( "web-capture:lead-form.search-customer" ) }
                        source={ customersSource }
                        onQueryChange={ searchCustomer }
                        getOptionLabel={ option =>
                            isObject( option ) ?
                                customersSource[ option.value ] :
                                option ? customersSource[ option ] : ""
                        }
                        filterOptions={ options => options }
                        field={{
                            onChange: selectCustomer,
                            value: customer && customer.id
                        }}
                    />
                    {
                        customer &&
                            <Button
                                onClick={ () => selectCustomer() }
                                size="small"
                                startIcon={ <ClearAllIcon/> }
                            >
                                { t( "web-capture:lead-form.clear-customer" ) }
                            </Button>
                    }
                </CardContent>

                <CardHeader subheader={ t( "web-capture:lead-form.customer" ) } />

                <Fields name="customer" fields={ customerFields }/>

                <CardHeader subheader={ t( "web-capture:lead-form.opportunity" ) } />
                <div className={ css.funnelSelector }>
                    <Field
                        name="opportunity.funnelId"
                        component={ SelectFunnel }
                        disabled={ lead.funnelId }
                        required
                    />
                </div>
                <Fields
                    name="opportunity"
                    fields={ opportunityFields }
                    disabled={
                        opportunityFields
                            .filter( field =>
                                field.systemField && field.systemField.match( "SOURCE" )
                            )
                            .map( field => field.id )
                    }
                />
                <br/>
            </Card>
            <Card>
                <CardActions>
                    <Button
                        type="submit"
                        disabled={ formik.submitting }
                        color="primary"
                        variant="contained"
                    >
                        { t( "web-capture:lead-form.save" ) }
                    </Button>
                </CardActions>
            </Card>
        </Form>
    );
};

export default LeadForm;