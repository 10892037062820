import { useCallback, useState } from "react";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { isEmail } from "../core/validator.utils";
import { FORGOT_PASSWORD_FORM } from "./login.constants";
import { sendResetPassword } from "./login.actions";
import { Dialog, Input, Link, cssUtils } from "../ui";

const ForgotPassword = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: FORGOT_PASSWORD_FORM,
        initialValues: {
            email: "",
        },
        onSubmit: values => dispatch( sendResetPassword( values ) ),
        onSubmitSuccess: () => setSubmitSuccess( true ),
        validate: values => {
            const errors = {};
            if ( !values.email || !isEmail( values.email ) ) {
                errors.email = "common:validation.email";
            }
            return errors;
        }
    });

    const [ submitSuccess, setSubmitSuccess ] = useState( false );
    const toggleSubmitSuccess = useCallback( () => setSubmitSuccess( !submitSuccess ), [ submitSuccess ] );

    return (
        <Form role="form" formik={ formik }>
            <Dialog
                open={ submitSuccess }
                onClose={ toggleSubmitSuccess }
            >
                <DialogContent>
                    <DialogContentText>
                        { t( "login:forgot-password.email-sent" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleSubmitSuccess }>
                        { t( "common:ok" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography>
                { t( "login:forgot-password.title" ) }
            </Typography>

            <Field
                name="email"
                type="email"
                label={ t( "login:forgot-password.email" ) }
                component={ Input }
                required
            />

            <Button
                type="submit"
                className={ classnames( cssUtils.fullWidth, cssUtils.marginTop, cssUtils.marginBottom ) }
                color="primary"
                variant="contained"
                disabled={ formik.submitting }
            >
                { t( "login:forgot-password.send" ) }
            </Button>

            <Link href="/login" disabled={ formik.submitting }>
                <Button color="secondary" variant="contained" className={ cssUtils.fullWidth }>
                    { t( "login:forgot-password.back" ) }
                </Button>
            </Link>
        </Form>
    );
};

export default ForgotPassword;