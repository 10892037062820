import { useEffect } from "react";

import { RadioButtons } from "../ui";
import useTranslate from "../hooks/use-translate.hook";

const FilterHasOpenScheduling = ( { name, field, form, label } ) => {
    const t = useTranslate();

    const { setFieldValue } = form;

    // o RadioButtons não reconhecer o null e undefined como valor então ele passa como ""
    useEffect(() => {
        if ( field.value === "" ) {
            // como não reconhece o null então tem que fazer o dispatch para mudar o valor para null
            setFieldValue( name, null );
        }
    }, [ field.value, setFieldValue, name ]);
    return (
        <RadioButtons
            field={ field }
            label={ label }
            options={
                [
                    { value: "", label: t( "form:user-filters.opportunity.has-open-scheduling-all" ) },
                    { value: false, label: t( "form:user-filters.opportunity.has-open-scheduling-no" ) },
                    { value: true, label: t( "form:user-filters.opportunity.has-open-scheduling-yes" ) }
                ]
            }
        />
    );
};

export default FilterHasOpenScheduling;