import { reducer as formik } from "formik-redux";
import { routerReducer } from "react-router-redux";

import { actions } from "./";

export const routing = routerReducer;
export { formik };

export function info ( state, action ) {
    switch ( action.type ) {
        case actions.REQUEST_INFO:
            return Object.assign( {}, state, {
                loading: true
            });
        case actions.SET_INFO:
            return Object.assign( {}, state, {
                loading: false,
                ...action.payload
            });
        case actions.SET_PREFERENCES:
            return Object.assign( {}, state, {
                preferences: action.payload
            });
        case actions.SET_LOCALE:
            return Object.assign( {}, state, {
                locale: action.payload
            });
        default:
            return state || null;
    }
}

export function theme ( state, action ) {
    switch ( action.type ) {
        case actions.SET_THEME_MODE:
            return Object.assign( {}, state, {
                mode: action.payload
            });
        default:
            return state || {};
    }
}