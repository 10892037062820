import { Helmet } from "react-helmet";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";

import useTranslate from "../hooks/use-translate.hook";
import { Fab } from "../ui";
import CustomersTable from "./customers-table.component";
import { startNewCustomer } from "./customer.actions";

const CustomerList = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const handleAddClick = () => dispatch( startNewCustomer() );

    return (
        <div className={ className }>
            <Helmet title={ t( "customer:customers-list.title" ) } />
            <CustomersTable />

            <Fab
                id="addButton"
                onClick={ handleAddClick }
                position="bottomRight"
            >
                <AddIcon/>
            </Fab>
        </div>
    );
};

export default CustomerList;