import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./home.actions";

export function *watchHome () {
    yield all([
        takeEvery( actions.FETCH_SCHEDULING_STATISTICS, fetchSchedulingStatistics ),
        takeEvery( actions.FETCH_SCHEDULES, fetchSchedules )
    ]);
}

function *fetchSchedulingStatistics ({ meta: { params }}) {
    yield put( actions.requestSchedulingStatistics() );
    try {
        const response = yield call( apiv1.get, "/schedules/statistics", { params } );
        yield put( actions.receiveSchedulingStatistics( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSchedulingStatistics( e.response.data ) );
    }
}

function *fetchSchedules ({ meta: { params } }) {
    yield put( actions.requestSchedules() );
    try {
        const response = yield call(
            apiv1.get,
            "/schedules",
            { params }
        );

        const schedules = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];

        yield put( actions.receiveSchedules({ schedules, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorSchedules( e.response.data ) );
    }
}