import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Field } from "formik";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import isEqual from "lodash/isEqual";
import classnames from "classnames";

import { USER_FORM } from "./user.constants";
import * as userActions from "./user.actions";
import useTranslate from "../hooks/use-translate.hook";
import { Input, Dropdown, FlagIcon, Helper, PhoneInput, cssUtils, flex } from "../ui";
import { getPreferences, getUser } from "../core/core.selectors";
import { isEmail, isPhoneValid } from "../core/validator.utils";
import { supportedLanguages } from "../core/core.utils";
import ImageList from "./image-list.component";
import UserAvatar from "./user-avatar.component";
import UserSignature from "./user-signature.component";
import UserTemplates from "./user-templates.component";
import UserThemeMode from "./user-theme-mode.component";
import UserChangePassword from "./user-change-password.component";
import SmtpServer from "./smtp-server.component";
import SocialCalendar from "./social-calendar.component";

const UserForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const t = useTranslate();

    const user = useSelector( getUser );
    const preferences = useSelector( getPreferences );

    const [ tabIndex, setTabIndex ] = useState( location.state?.tabIndex || 0 );

    const formik = useForm({
        form: USER_FORM,
        enableReinitialize: true,
        initialValues: {
            phone: {
                number: "",
                country: null,
            },
            ...user,
            preferences: preferences
        },
        onSubmit: values => dispatch( userActions.saveUser( values ) ),
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.email ) {
                errors.email = "common:validation.required";
            } else if ( !isEmail( values.email ) ) {
                errors.email = "common:validation.email";
            }
            if ( values.phone?.number ) {
                if ( !isPhoneValid( values.phone ) ) {
                    errors.phone = "common:validation.phone-number";
                }
            }
            return errors;
        },
    });

    const handleBlurEmail = event => {
        if ( event.target.value ) {
            formik.setFieldValue( "email", event.target.value.toLowerCase() );
        }
    };

    const isNewEmail = useMemo(
        () => !isEqual( user.email, formik.values.email ),
        [ user.email, formik.values.email ]
    );

    return (
        <>
            <Helmet title={ t( "user:user.profile" ) } />

            <Paper>
                <Tabs
                    variant="fullWidth"
                    value={ tabIndex }
                    onChange={ ( event, value ) => setTabIndex( value ) }
                >
                    <Tab label={ t( "user:tabs.person" ) }/>
                    <Tab label={ t( "user:tabs.email" ) }/>
                    <Tab label={ t( "user:tabs.schedule" ) }/>
                    <Tab label={ t( "user:tabs.templates" ) }/>
                </Tabs>
            </Paper>

            <div className={ classnames( cssUtils.overflowAuto, cssUtils.paddingSmall, flex.fill ) }>
                {
                    tabIndex === 0 &&
                        <>
                            <div className={
                                classnames(
                                    flex.justifyContentSpaceBetween,
                                    flex.container,
                                    flex.fill )
                            }>
                                <Card className={ classnames( flex.fill, flex.column ) }>
                                    <CardHeader subheader={ t( "user:user.avatar" ) }/>
                                    <UserAvatar user={ user }/>
                                </Card>

                                <Card className={ classnames( flex.fill, cssUtils.marginLeftSmall, flex.column ) }>
                                    <Form formik={ formik }>
                                        <CardHeader subheader={ t( "user:register" ) }/>
                                        <CardContent>
                                            {
                                                formik.error &&
                                                    <p className={ cssUtils.textError }>
                                                        { formik.error.error }
                                                    </p>
                                            }
                                            <Field
                                                type="text"
                                                name="name"
                                                label={ t( "user:user.name" ) }
                                                component={ Input }
                                                autoComplete="off"
                                                required
                                            />

                                            <Field
                                                type="email"
                                                name="email"
                                                label={ t( "user:user.email" ) }
                                                onBlur={ handleBlurEmail }
                                                component={ Input }
                                                autoComplete="off"
                                                required
                                            />
                                            {
                                                isNewEmail &&
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="caption"
                                                        gutterBottom
                                                    >
                                                        { t( "user:user.change-email-help" ) }
                                                    </Typography>
                                            }
                                            <Field
                                                name="phone"
                                                label={ t( "config:subscription.phone" ) }
                                                component={ PhoneInput }
                                                fullWidth
                                            />
                                            <Field
                                                name="locale"
                                                label={ t( "common:language" ) }
                                                renderValue={ option => (
                                                    <>
                                                        { option &&
                                                            <FlagIcon
                                                                code={
                                                                    option.value.split( "-" )[ 1 ].toLowerCase()
                                                                }
                                                                className={ cssUtils.marginRightSmall }
                                                            />
                                                        }
                                                        { option ? option.label : "" }
                                                    </>
                                                ) }
                                                classes={{ select: flex.container }}
                                                source={ supportedLanguages.map( locale => ({
                                                    icon: (
                                                        <FlagIcon
                                                            code={ locale.split( "-" )[ 1 ].toLowerCase() }
                                                            className={ cssUtils.marginRightSmall }
                                                        />
                                                    ),
                                                    value: locale,
                                                    label: t( `common:locales.${locale}` )
                                                })) }
                                                component={ Dropdown }
                                            />
                                        </CardContent>
                                        <CardHeader subheader={ t( "user:system" ) }/>
                                        <CardContent>
                                            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                                                <Field
                                                    name="preferences.indexRoute"
                                                    label={ t( "user:user.index-route.label" ) }
                                                    component={ Dropdown }
                                                    className={ flex.fill }
                                                    source={
                                                        [ "home", "opportunities" ].map( value => ({
                                                            value, label: t( `user:user.index-route.${value}` )
                                                        }))
                                                    }
                                                    required
                                                />
                                                <Helper message={ t( "user:user.index-route.help" ) }/>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                disabled={ formik.submitting }
                                            >
                                                { t( "user:save" ) }
                                            </Button>
                                        </CardActions>
                                    </Form>
                                </Card>

                                <Card className={ classnames( flex.fill, flex.column, cssUtils.marginLeftSmall ) }>
                                    <UserChangePassword/>
                                </Card>
                            </div>

                            <UserThemeMode />
                        </>
                }
                {
                    tabIndex === 1 &&
                        <>
                            <SmtpServer/>
                            <hr />

                            <Card>
                                <CardHeader subheader={ t( "user:user.signature" ) }/>
                                <UserSignature user={ user }/>
                            </Card>
                        </>
                }
                { tabIndex === 2 && <SocialCalendar/> }
                {
                    tabIndex === 3 &&
                        <>
                            <UserTemplates user={ user }/>
                            <hr/>
                            <ImageList/>
                        </>
                }
            </div>
        </>
    );
};

export default UserForm;