import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, useForm } from "formik-redux";
import { Field } from "formik";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import BusinessIcon from "@mui/icons-material/Business";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import RedeemIcon from "@mui/icons-material/Redeem";
import GroupIcon from "@mui/icons-material/Group";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import qs from "qs";
import config from "config";
import GoogleTagManager from "react-gtm-module";

import usePrevious from "../hooks/use-previous.hook";
import { createSsnFormatter, createEinFormatter } from "../core/formatter.utils";
import { Checkbox, Dropdown, Input, Link, PhoneInput, flex, cssUtils, MaskInput } from "../ui";
import { BrandTypes } from "../ui/brand";
import { SIGNUP_FORM, actions } from "./";
import { createEinValidation, createSsnValidation } from "../core/validator.utils";
import { isPhoneValid, isEmail } from "../core/validator.utils";
import useTranslate from "../hooks/use-translate.hook";
import app from "../../api/app";

const adaptMedia = ( params = {} ) => {
    if ( params.md ) {
        return params.md;
    } else if ( params.utm_source && params.utm_campaign === "remarketing" ) {
        return "37";
    } else if ( params.utm_source && params.utm_campaign === "display" ) {
        return "38";
    } else if ( params.utm_source && params.utm_campaign === "gmail" ) {
        return "57";
    } else if ( params.gclid || params.utm_source ) {
        return "3";
    }

    return null;
};

const validateEmail = async email => {
    if ( email ) {
        return await app.get( "/signup/users/" + email ).then( () => {}, err => {
            return err.response.data.error;
        });
    }

    return Promise.resolve();
};

const Signup = () => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const location = useLocation();

    const queryParams = qs.parse( location.search, { ignoreQueryPrefix: true });
    const isSsnValid = useMemo( () => createSsnValidation({ t }), [ t ] );
    const isEinValid = useMemo( () => createEinValidation({ t }), [ t ] );
    const poweredByICarros = config.has( "client.poweredBy" ) && config.get( "client.poweredBy" ) === "icarros";

    const ssnFormat = useMemo( () => createSsnFormatter({ t }), [ t ] );
    const einFormat = useMemo( () => createEinFormatter({ t }), [ t ] );

    const validate = async values => {
        const errors = {
            user: {}
        };

        if ( !values ) {
            return errors;
        }

        if ( !values.name || !values.name.length ) {
            errors.name = "common:validation.required";
        }

        if ( poweredByICarros ) {
            if ( values.personType === "NATURAL" && !values.ssn ) {
                errors.ssn = "common:validation.required";
            }

            if ( values.personType === "LEGAL" && !values.ein ) {
                errors.ein = "common:validation.required";
            }
        }

        if ( values.ssn && !isSsnValid( values.ssn ) ) {
            errors.ssn = "common:validation.ssn";
        }

        if ( values.ein && !isEinValid( values.ein ) ) {
            errors.ein = "common:validation.ein";
        }

        if ( poweredByICarros && !values.userLimit ) {
            errors.userLimit = "common:validation.required";
        } else if ( values.userLimit && ( values.userLimit < 1 || values.userLimit % 1 !== 0 ) ) {
            errors.userLimit = "common:validation.user-limit";
        }

        if ( !values.phone ) {
            errors.phone = "common:validation.required";
        } else if ( !isPhoneValid( values.phone ) ) {
            errors.phone = "common:validation.phone-number";
        }

        const user = values.user || {};
        if ( !user.name || !user.name.length ) {
            errors.user.name = "common:validation.required";
        }

        if ( !user.email || !user.email.length ) {
            errors.user.email = "common:validation.required";
        } else if ( !isEmail( user.email ) ) {
            errors.user.email = "common:validation.email";
        } else {
            const errorEmail = await validateEmail( user.email );
            if ( errorEmail ) {
                errors.user.email = errorEmail;
            }
        }

        if ( !user.password || !user.password.length ) {
            errors.user.password = "common:validation.required";
        } else if ( user.password.length < 6 ) {
            errors.user.password = "common:validation.password-length";
        }

        if ( !user.confirmPassword || !user.confirmPassword.length ) {
            errors.user.confirmPassword = "common:validation.required";
        }

        if ( user.password && user.confirmPassword && user.password !== user.confirmPassword ) {
            errors.user.confirmPassword = "common:validation.passwords-match";
        }

        errors.user && isEmpty( errors.user ) && delete errors.user;
        errors.phone && isEmpty( errors.phone ) && delete errors.phone;

        if ( !values.segment || !values.segment.type ) {
            errors.segment = {
                type: "common:validation.selection"
            };
        } else if ( values.segment.type === "AUTOMOTIVE" && isEmpty( get( values.segment, "options.brandTypes" ) ) ) {
            errors.segment = {
                options: {
                    brandTypes: "common:validation.select-option"
                }
            };
        }

        if ( !values.acceptedTerms ) {
            errors.acceptedTerms = "common:validation.accept-terms";
        }
        return errors;
    };

    const formik = useForm({
        form: SIGNUP_FORM,
        enableReinitialize: true,
        initialValues: {
            personType: "LEGAL",
            name: null,
            ssn: null,
            ein: null,
            userLimit: null,
            officeHours: {
                zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            phone: {},
            user: {
                name: null,
                email: null,
                password: null,
                confirmPassword: null,
            },
            segment: {
                type: null,
                options: {
                    brandTypes: []
                }
            },
            acceptedTerms: false,
            media: adaptMedia( queryParams ),
            partner: get( queryParams, "partner" ),
            vendor: get( queryParams, "vendor" )
        },
        onSubmit: values => dispatch( actions.signupSubmit( values ) ),
        validate: values => validate( values ),
    });
    const { error, submitting, setFieldValue, values: { personType, segment } } = formik;

    const segments = useMemo( () => [
        "AUTOMOTIVE",
        "EDUCATION",
        "AESTHETIC",
        "REALSTATE",
        "HEALTH",
        "INSURANCE",
        "NAUTICAL",
        "TELECOM",
        "RETAIL",
        "OTHER"
    ], [] );

    const prevPersonType = usePrevious( personType );

    useEffect( () => {
        if ( config.has( "client.googleTagManagerId" ) ) {
            const googleTagManagerArgs = {
                gtmId: config.get( "client.googleTagManagerId" )
            };
            GoogleTagManager.initialize( googleTagManagerArgs );
        }
    }, [] );
    useEffect( () => {
        if ( prevPersonType !== personType ) {
            setFieldValue( personType === "NATURAL" ? "ein" : "ssn", null ) ;
        }
    }, [ setFieldValue, prevPersonType, personType ] );

    return (
        <div>
            <Helmet title={ t( "signup:title" ) } />
            <Form formik={ formik } noValidate>
                <Field
                    type="text"
                    name="name"
                    label={ t( "signup:company" ) }
                    component={ Input }
                    icon={ <BusinessIcon/> }
                    required
                />

                <div className={ flex.container }>
                    <Field
                        name="personType"
                        label={ t( "signup:person-type.label" ) }
                        source={[
                            {
                                label: t( "signup:person-type.natural" ),
                                value: "NATURAL"
                            }, {
                                label: t( "signup:person-type.legal" ),
                                value: "LEGAL"
                            }
                        ]}
                        fullWidth={ false }
                        className={ flex.item40 }
                        component={ Dropdown }
                    />
                    {
                        personType === "NATURAL" ?
                            <Field
                                type="text"
                                name="ssn"
                                label={ t( "signup:ssn" ) }
                                component={ MaskInput }
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                format={ ssnFormat }
                                required={ poweredByICarros }
                            /> :
                            <Field
                                type="text"
                                name="ein"
                                label={ t( "signup:ein" ) }
                                component={ MaskInput }
                                className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }
                                format={ einFormat }
                                required={ poweredByICarros }
                            />
                    }
                </div>

                <Field
                    type="number"
                    name="userLimit"
                    label={ t( "signup:user-limit" ) }
                    component={ Input }
                    icon={ <GroupIcon/> }
                    required={ poweredByICarros }
                />

                <Field
                    label={ t( "signup:phone" ) }
                    name="phone"
                    fullWidth
                    component={ PhoneInput }
                    required
                />

                <Field
                    type="text"
                    name="user.name"
                    label={ t( "signup:name" ) }
                    component={ Input }
                    autoComplete="off"
                    icon={ <PersonIcon/> }
                    required
                />

                <Field
                    type="email"
                    name="user.email"
                    label={ t( "signup:email" ) }
                    component={ Input }
                    autoComplete="off"
                    icon={ <MailIcon/> }
                    required />

                <Field
                    type="password"
                    name="user.password"
                    label={ t( "signup:password" ) }
                    component={ Input }
                    icon={ <LockIcon/> }
                    required
                />

                <Field
                    type="password"
                    name="user.confirmPassword"
                    label={ t( "signup:confirm-password" ) }
                    component={ Input }
                    icon={ <LockIcon/> }
                    required
                />

                <Field
                    name="segment.type"
                    label={ t( "signup:segment.label" ) }
                    component={ Dropdown }
                    sort={ false }
                    source={
                        segments
                            .map( value => ({ value, label: t( `signup:segment.type.${value}.label` )}))
                    }
                    required
                />

                {
                    segment?.type === "AUTOMOTIVE" &&
                        <Field
                            name="segment.options.brandTypes"
                            component={ BrandTypes }
                            helperText={ t( "signup:segment.type.AUTOMOTIVE.help" ) }
                        />
                }

                <Field
                    type="text"
                    name="coupon"
                    label={ t( "signup:coupon" ) }
                    component={ Input }
                    icon={ <RedeemIcon/> }
                />

                {
                    error &&
                        <Alert
                            severity="error"
                            className={ cssUtils.marginTopSmall }
                        >
                            { error.error || t( "common:error.unknown" ) }
                        </Alert>
                }

                <Field
                    name="acceptedTerms"
                    type="checkbox"
                    label={
                        <Typography variant="body2">
                            <span>{ t( "signup:accept-terms.1" ) }</span>
                            <a href="https://www.leadstation.com.br/Termo_de_Uso" target="_blank">{ t( "signup:accept-terms.2" ) }</a>
                            <span>{ t( "signup:accept-terms.3" ) }</span>
                            <a href="https://www.leadstation.com.br/Politica_Privacidade" target="_blank">{ t( "signup:accept-terms.4" ) }</a>
                        </Typography>
                    }
                    className={ cssUtils.marginTopSmall }
                    component={ Checkbox }
                    required
                />

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={ submitting }
                    className={ classnames( cssUtils.marginTopSmall, cssUtils.fullWidth ) }
                >
                    { t( "signup:signup.action" ) }
                </Button>
            </Form>

            <hr />

            <div className={ cssUtils.marginBottomSmall }>
                <Typography className={ cssUtils.textCenter }>{ t( "signup:login.call-to-action" ) }</Typography>
            </div>
            <Link href="/login" className={ cssUtils.fullWidth }>
                <Button color="secondary" variant="contained" className={ cssUtils.fullWidth }>
                    { t( "signup:login.action" ) }
                </Button>
            </Link>
        </div>
    );
};

export default Signup;