import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as actions from "./user.actions";

export function all ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_USERS:
            return {
                ...state,
                loading: true
            };

        case actions.RECEIVE_USERS:
            return {
                ...state,
                data: action.payload.users,
                loading: false
            };

        case actions.ERROR_USERS:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        default:
            return state;
    }
}

export function events ( state = {}, action ) {
    switch ( action.type ) {
        case actions.REQUEST_EVENTS:
            return Object.assign( {}, state, {
                reload: false,
                data: [],
                loading: true
            });

        case actions.RECEIVE_EVENTS:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload
            });

        case actions.ERROR_EVENTS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        case actions.RELOAD_EVENTS:
            return Object.assign( {}, state, {
                reload: true
            });

        case actions.SELECT_EVENT:
            return Object.assign( {}, state, {
                selected: action.payload
            });

        default:
            return state;
    }
}

const loggedSchedules = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.FETCH_SCHEDULES:
            return Object.assign( {}, state, {
                currentPage: action.meta.params.page || 1
            });
        case actions.REQUEST_SCHEDULES:
            return Object.assign( {}, state, {
                loading: true,
                reload: false
            });
        case actions.RECEIVE_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                data: state.currentPage > 1 && action.payload ?
                    state.data.concat( action.payload.schedules ) :
                    action.payload.schedules,
                totalPages: action.payload.totalPages
            });
        case actions.ERROR_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });
        default:
            return state;
    }
};
const othersSchedules = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.FETCH_OTHERS_SCHEDULES:
            return Object.assign( {}, state, {
                currentPage: action.meta.params.page || 1
            });
        case actions.REQUEST_OTHERS_SCHEDULES:
            return Object.assign( {}, state, {
                loading: true,
                reload: false
            });
        case actions.RECEIVE_OTHERS_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                data: state.currentPage > 1 && action.payload ?
                    state.data.concat( action.payload.schedules ) :
                    action.payload.schedules,
                totalPages: action.payload.totalPages
            });
        case actions.ERROR_OTHERS_SCHEDULES:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });
        default:
            return state;
    }
};
const schedules = combineReducers({ logged: loggedSchedules, others: othersSchedules });

const socialCalendar = createRequestReducer({
    begin: actions.REQUEST_SOCIAL_CALENDAR,
    success: actions.RECEIVE_SOCIAL_CALENDAR,
    failure: actions.ERROR_SOCIAL_CALENDAR
});

const socialAccountCalendars = createRequestReducer({
    begin: actions.REQUEST_SOCIAL_ACCOUNT_CALENDARS,
    success: actions.RECEIVE_SOCIAL_ACCOUNT_CALENDARS,
    failure: actions.ERROR_SOCIAL_ACCOUNT_CALENDARS
});

export const users = combineReducers({
    all,
    events,
    schedules,
    socialCalendar,
    socialAccountCalendars
});