import { useMemo } from "react";
import { Form, useForm } from "formik-redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { Fields, validateFields } from "../form";
import * as formSelectors from "../form/form.selectors";
import { saveOpportunity } from "./opportunity.actions";
import { getSelected, isLoadingSelected } from "./opportunity.selectors";
import { EDIT_OPPORTUNITY_FORM } from "./opportunity.constants";
import css from "./opportunity.scss";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const OpportunityForm = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const opportunity = useSelector( getSelected );
    const fields = useSelector( formSelectors.list( "OPPORTUNITY" ) );
    const loadingSelected = useSelector( isLoadingSelected );
    const loadingForm = useSelector( formSelectors.isLoading( "OPPORTUNITY" ) );
    const loading = loadingSelected || loadingForm;

    const fieldsOpportunity = useMemo( () => {
        return fields.filter( field =>
            isEmpty( field?.funnelsId ) ||
            field?.funnelsId.includes( opportunity.funnelId )
        );
    }, [ fields, opportunity.funnelId ]);

    const formik = useForm({
        form: EDIT_OPPORTUNITY_FORM,
        enableReinitialize: true,
        initialValues: {
            ...opportunity,
            fields: {
                ...fieldsToInitialValues( fieldsOpportunity ),
                ...opportunity.fields
            }
        },
        onSubmit: values => dispatch( saveOpportunity( values ) ),
        validate: values => validateFields( values, { fields: fieldsOpportunity, t } ),
    });

    return (
        <div className={ css.containerContent }>
            <Card className={ css.content }>
                { loading ?
                    <LinearProgress/> :
                    <Form formik={ formik }>
                        <CardContent className={ css.containerForm }>
                            <Fields fields={ fieldsOpportunity } />
                        </CardContent>
                        <CardActions>
                            <Button
                                id="opportunityFormButton"
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={ formik.submitting }
                            >
                                { t( "common:save" ) }
                            </Button>
                        </CardActions>
                    </Form>
                }
            </Card>
        </div>
    );
};

export default OpportunityForm;