import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import { Chart, cssUtils } from "../ui";
import css from "./dashboard.scss";

const OpportunitiesSalesForecastChart = ({
    className,
    initialDate,
    finalDate,
    statistics: { salesForecast },
    users,
    onClickItem,
}) => {
    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const [ showHelp, setShowHelp ] = useState( false );
    const toggleHelp = () => setShowHelp( !showHelp );

    const format = useMemo( () => {
        const differentMonths = dayjs( finalDate ).diff( dayjs( initialDate ), "month" ) > 0;
        if ( differentMonths ) {
            return "MMM/YY";
        }
        return "D/MMM";
    }, [ finalDate, initialDate ] );
    const categories = useMemo( () => {
        const maxDate = dayjs( finalDate );
        let minDate = dayjs( initialDate );

        const differentMonths = maxDate.diff( minDate, "month" ) > 0;
        const categories = [];
        if ( differentMonths ) {
            while ( maxDate > minDate || minDate.format( "M" ) === maxDate.format( "M" ) ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "month" );
            }
        } else {
            while ( maxDate.diff( minDate, "days", true ) >= 0 ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "day" );
            }
        }
        return categories;
    }, [ finalDate, format, initialDate ] );

    const series = useMemo( () => {
        const dataOpen = categories
            .map( category =>
                salesForecast
                    .filter( item => !!item.open && dayjs( item.date ).format( format ) === category )
                    .reduce( ( model, item ) => model + item.open, 0 )
            );
        const dataOpenRecurrent = categories
            .map( category =>
                salesForecast
                    .filter( item => !!item.openRecurrent && dayjs( item.date ).format( format ) === category )
                    .reduce( ( model, item ) => model + item.openRecurrent, 0 )
            );
        const dataSold = categories
            .map( category =>
                salesForecast
                    .filter( item => !!item.sold && dayjs( item.date ).format( format ) === category )
                    .reduce( ( model, item ) => model + item.sold, 0 )
            );
        const dataSoldRecurrent = categories
            .map( category =>
                salesForecast
                    .filter( item => !!item.soldRecurrent && dayjs( item.date ).format( format ) === category )
                    .reduce( ( model, item ) => model + item.soldRecurrent, 0 )
            );

        return [{
            name: t( "dashboard:opportunities-sales-forecast-chart.open" ),
            status: "OPEN",
            stack: "price",
            data: dataOpen,
            color: "#1565C0"
        }, {
            name: t( "dashboard:opportunities-sales-forecast-chart.open-recurrent" ),
            status: "OPEN",
            stack: "recurrentPrice",
            data: dataOpenRecurrent,
            color: "#1976d2"
        }, {
            name: t( "dashboard:opportunities-sales-forecast-chart.sold" ),
            status: "SOLD",
            data: dataSold,
            stack: "price",
            color: "#2E7D32"
        }, {
            name: t( "dashboard:opportunities-sales-forecast-chart.sold-recurrent" ),
            status: "SOLD",
            stack: "recurrentPrice",
            data: dataSoldRecurrent,
            color: "#2E7D32"
        }];
    }, [ t, categories, format, salesForecast ] );

    const data = useMemo( () => {
        const differentMonths = dayjs( finalDate ).diff( dayjs( initialDate ), "month" ) > 0;

        return {
            title: {
                text: t( "dashboard:opportunities-sales-forecast-chart.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunities-sales-forecast-chart.title" );
                    }
                },
            },
            xAxis: {
                categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: ""
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: "bold",
                    },
                    formatter: function () {
                        return priceFormat( this.total );
                    }
                },
                labels: {
                    formatter: function () {
                        return priceFormat( this.value );
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return this.series.name + ": " + priceFormat( this.y );
                }
            },
            plotOptions: {
                column: {
                    stacking: "normal"
                },
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                if ( !this.y ) {
                                    return;
                                }

                                const iDate = dayjs( this.category, format )
                                    .year( dayjs( initialDate ).year() )
                                    .startOf( "day" )
                                    .toDate();
                                let fDate = dayjs( iDate ).endOf( "day" ).toDate();
                                if ( differentMonths ) {
                                    fDate = dayjs( fDate ).endOf( "month" ).toDate();
                                }

                                const status = this.series.userOptions.status;
                                let params;
                                if ( status === "OPEN" ) {
                                    params = {
                                        initialClosingAt: iDate.toISOString(),
                                        finalClosingAt: fDate.toISOString()
                                    };
                                } else {
                                    params = {
                                        initialSoldAt: iDate.toISOString(),
                                        finalSoldAt: fDate.toISOString()
                                    };
                                }

                                onClickItem(
                                    t( "dashboard:opportunities-sales-forecast-chart.title" ),
                                    {
                                        ...params,
                                        status,
                                        users: users.toString()
                                    }
                                );
                            }
                        }
                    }
                }
            },
            series,
        };
    }, [ finalDate, initialDate, t, categories, series, priceFormat, format, onClickItem, users ] );

    return (
        <Card className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunities-sales-forecast-chart.help-open" ) }
                        <br/>
                        { t( "dashboard:opportunities-sales-forecast-chart.help-sold" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="opportunitiesSalesForecastChart"
                type="column"
                data={ data }
                noDataMessage={ t( "dashboard:opportunities-sales-forecast-chart.empty" ) }
            />

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButton onClick={ toggleHelp } className={ css.iconHelpChart } size="large">
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
        </Card>
    );
};

export default OpportunitiesSalesForecastChart;