import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import Button from "@mui/material/Button";

import useTranslate from "../hooks/use-translate.hook";
import tracker from "../util/tracker.utils";
import { fetchSources } from "../source/source.actions";
import { fetchResults } from "./report.actions";
import { setSource } from "../opportunity/opportunity.actions";
import { selectors as opportunitySelectors } from "../opportunity";
import { selectors as formSelectors } from "../form";
import { selectors as sourceSelectors } from "../source";
import { getSelected, isLoadingResults } from "./report.selectors";
import { Dropdown, UsersFilter, cssUtils, flex } from "../ui";
import css from "./filters.scss";
import FunnelsFilter from "../opportunity/list/funnels-filter.component";

const Filters = ({ onChange, initialDate, finalDate, items, required = [], fieldId, teamId, usersId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.listSelectFields( "OPPORTUNITY" ) );
    const loading = useSelector( isLoadingResults );
    const funnelsId = useSelector( opportunitySelectors.getFunnelsId );
    const sourceId = useSelector( opportunitySelectors.getSourceId );
    const sources = useSelector( sourceSelectors.getSources );
    const report = useSelector( getSelected );

    const [ showError, setShowError ] = useState( false );

    const loadResults = useCallback( () => {
        if ( ( required.includes( "users" ) && isEmpty( usersId ) ) ||
            ( required.includes( "funnels" ) && isEmpty(funnelsId) ) ||
            ( required.includes( "source" ) && !sourceId ) ||
            ( required.includes( "field" ) && !fieldId )
        ) {
            setShowError( true );
            return;
        }

        const filters = { report, initialDate, finalDate };

        if ( items.includes( "funnels" ) && !isEmpty( funnelsId ) ) {
            Object.assign( filters, { funnelsId: funnelsId.toString() } );
        }

        if ( items.includes( "source" ) && !!sourceId ) {
            Object.assign( filters, { sourceId });
        }

        if ( items.includes( "users" ) && !isEmpty( usersId ) ) {
            Object.assign( filters, { usersId: usersId.toString() });
        }

        if ( items.includes( "field" ) && !!fieldId ) {
            Object.assign( filters, { fieldId });
        }

        const words = report.split( "_" ).map( word => word.toLowerCase() );
        const formattedWords = words.map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) );

        tracker.pageChange( "/report", formattedWords.join( " " ) );

        dispatch( fetchResults( filters ) );
    }, [ dispatch, fieldId, finalDate, initialDate, items, required, report, funnelsId, sourceId, usersId ] );

    useEffect( () => {
        dispatch( fetchSources() );
    }, [ dispatch ] );

    return (
        <>
            {
                items.includes( "funnels" ) &&
                    <FunnelsFilter
                        fullWidth={ false }
                        className={ flex.fill }
                        required={ required.includes( "funnels" ) }
                        error={ required.includes( "funnels" ) && showError && ( !funnelsId || isEmpty( funnelsId ) ) }
                    />
            }
            {
                items.includes( "source" ) &&
                    <Dropdown
                        margin="none"
                        label={ t( "report:filters.source" ) }
                        fullWidth={ false }
                        className={ classnames(
                            items.includes( "funnels" ) && cssUtils.marginLeft,
                            flex.fill
                        )}
                        emptyValue={ true }
                        onChange={ event => dispatch( setSource( event.target.value ) ) }
                        value={ sourceId }
                        source={ sources?.map( source => ({ value: source.id, label: source.name }) ) }
                        disabled={ isEmpty( sources ) }
                        required={ required.includes( "source" ) }
                        error={ required.includes( "source" ) && showError && !sourceId }
                    />
            }
            {
                items.includes( "users" ) &&
                    <UsersFilter
                        margin="none"
                        fullWidth={ false }
                        ComponentRoot={ "div" }
                        className={ css.usersFilter }
                        input={{
                            team: {
                                value: teamId,
                                onChange: value => onChange( "teamId", value )
                            },
                            users: {
                                value: usersId,
                                onChange: value => onChange( "usersId", value )
                            }
                        }}
                        error={ required.includes( "users" ) && showError && isEmpty( usersId ) }
                        required={ required.includes( "users" ) }
                        showInactive
                    />
            }
            {
                items.includes( "field" ) &&
                    <Dropdown
                        label={ t( "report:filters.field" ) }
                        onChange={ event => onChange( "fieldId", event.target.value ) }
                        value={ fieldId }
                        source={ fields.map( field => ({
                            value: field.id,
                            label: field.name
                        }))}
                        fullWidth={ false }
                        className={ classnames( cssUtils.marginLeft, flex.fill ) }
                        required={ required.includes( "field" ) }
                        error={ required.includes( "field" ) && showError && !fieldId }
                    />
            }
            <Button className={ cssUtils.marginLeft } onClick={ loadResults } disabled={ loading }>
                { t( "report:filters.apply-filter" ) }
            </Button>
        </>
    );
};

export default Filters;