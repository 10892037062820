import { useMemo, useRef, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { useSelector } from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import merge from "lodash/merge";

import useTranslate from "../hooks/use-translate.hook";
import { getThemeMode } from "../core/core.selectors";
import Outlined from "./outlined.component";
import defaultStyle from "./template-mentions.style";

const TemplateMentions = ({
    label,
    field,
    form: { getFieldMeta, setFieldValue } = {},
    required,
    style,
    data,
    ...props
}) => {
    const t = useTranslate();

    const themeMode = useSelector( getThemeMode );

    const [ focused, setFocused ] = useState( false );

    const container = useRef();

    const validationMsg = useMemo( () => {
        if ( getFieldMeta ) {
            const fieldMeta = getFieldMeta( field.name );
            return fieldMeta.touched && fieldMeta.error;
        }
        return null;
    }, [ field.name, getFieldMeta ] );

    const value = useMemo( () => field.value, [ field ] );

    const handleChange = ( event, value ) => setFieldValue( field.name, value );

    const mentionsStyle = useMemo( () => {
        const defaultStyleCopy = cloneDeep( defaultStyle );

        return merge( omit( defaultStyleCopy, "dark" ), style, themeMode === "dark" && defaultStyleCopy.dark );
    }, [ style, themeMode ] );


    const handleBlur = () => setFocused( false );
    const handleFocus = () => setFocused( true );

    return (
        <div
            ref={ container }
            style={ defaultStyle.root }
        >
            <Outlined
                label={ label }
                error={ !!validationMsg }
                required={ required }
                shrink={ focused || !!value }
                helperText={ validationMsg && t( validationMsg ) }
            >
                <MentionsInput
                    { ...field }
                    autoComplete="off"
                    onBlur={ handleBlur }
                    onFocus={ handleFocus }
                    onChange={ handleChange }
                    style={ mentionsStyle }
                    { ...props }
                    suggestionsPortalHost={ container.current }
                >
                    <Mention
                        trigger="#"
                        markup="#(__id__)"
                        data={ data }
                        displayTransform={ id => `#${data.find( item => item.id === id ).display }` }
                        style={{ backgroundColor: themeMode === "dark" ? "#424242" : "#EF7D36" }}
                        renderSuggestion={ ( entry, search, highlightedDisplay, index, focused ) => (
                            <ListItemButton component="div" selected={ focused } dense>
                                <ListItemText
                                    primary={ entry.display }
                                />
                            </ListItemButton>
                        ) }
                    />
                </MentionsInput>
            </Outlined>
        </div>

    );
};

export default TemplateMentions;