export const SAVE_NEW_EVENT = "x/SAVE_NEW_SCHEDULE_EVENT";
export const saveNewEvent = data => ({
    type: SAVE_NEW_EVENT,
    payload: data
});

export const DELETE_EVENT = "x/DELETE_SCHEDULE_EVENT";
export const deleteEvent = data => ({
    type: DELETE_EVENT,
    payload: data
});

export const EDIT_EVENT = "x/EDIT_SCHEDULE_EVENT";
export const editEvent = data => ({
    type: EDIT_EVENT,
    payload: data
});