import { selectors as requestSelectors } from "redux-request-state";

export const getLeads = state => state.webCapture.leads.data;
export const getTotalPagesLeads = state => state.webCapture.leads.totalPages;
export const getTotalElementsLeads = state => state.webCapture.leads.totalElements;
export const isLoadingLeads = state => state.webCapture.leads.loading;
export const getErrorLeads = state => state.webCapture.leads.error;

const leadBase = state => state.webCapture.lead;
export const getLead = requestSelectors.getData( leadBase );
export const isLoadingLead = requestSelectors.isLoading( leadBase );

const leadPortalsBase = state => state.webCapture.leadPortals;
export const getLeadPortals = requestSelectors.getData( leadPortalsBase );
export const isLoadingLeadPortals = requestSelectors.isLoading( leadPortalsBase );