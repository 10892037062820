import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";

const ContactsEvent = ({ event: { user, date, payload } }) => {
    const t = useTranslate();

    return (
        <TimelineItem icon={ <PersonIcon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "customer:timeline.contacts-changed" ) }
                date={ date } />

            <Card>
                {
                    isEmpty( payload ) ?
                        <CardHeader subheader={ t( "customer:contacts.empty" ) } /> :
                        <List>
                            {
                                payload.map( ( contact, index ) => (
                                    <ListItem key={ index } >
                                        <ListItemText primary={ contact.name } secondary={ contact.type }/>
                                    </ListItem>
                                ))
                            }
                        </List>
                }
            </Card>
        </TimelineItem>
    );
};

export default ContactsEvent;