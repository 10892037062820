import { useMemo } from "react";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors } from "../../form";
import { cssUtils, flex } from "../../ui";
import css from "./automation.scss";
import MappingFieldItem from "./mapping-field-item.component";

const ignoreField = ( field, integrationChannel ) => {
    if ( integrationChannel !== "RD_STATION" && integrationChannel !== "EXACT_SALES" ) {
        return false;
    }

    return field.systemField === "NAME" || field.type.match( "ADDRESS|EMAIL|PHONE" );
};

const MappingFields = ({ integrationChannel }) => {
    const t = useTranslate();

    const personFields = useSelector( formSelectors.list( "PERSON" ) );
    const companyFields = useSelector( formSelectors.list( "COMPANY" ) );
    const opportunityFields = useSelector( formSelectors.list( "OPPORTUNITY" ) );

    const customerFields = useMemo( () => {
        return integrationChannel === "EXACT_SALES" ? companyFields : personFields;
    }, [ companyFields, integrationChannel, personFields ] );

    const mappingCustomerFields = useMemo(
        () => ( customerFields || [] ).filter( field => !ignoreField( field, integrationChannel ) ),
        [ customerFields, integrationChannel ]
    );
    const mappingOpportunityFields = useMemo(
        () => ( opportunityFields || [] ).filter( field => !"TITLE|SOURCE|USER".match( field.systemField ) ),
        [ opportunityFields ]
    );

    const reqFieldsLabel = t( "marketplace:automation.mapping-fields.req-fields", {
        integrationChannel: t(
            `marketplace:automation.integration-channel.${integrationChannel}`
        )
    });

    return (
        <Accordion className={ cssUtils.marginTop } defaultExpanded>
            <AccordionSummary expandIcon={ <EditIcon/> }>
                <Typography>
                    { t( "marketplace:automation.mapping-fields.title" ) }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={ classnames( flex.fill, flex.container, flex.column ) }>
                    <Typography color="textSecondary">
                        { t( "marketplace:automation.mapping-fields.help" ) }
                    </Typography>
                    <div className={ classnames( flex.container, cssUtils.marginTop ) }>
                        <Typography className={ flex.fill }>
                            { t( "marketplace:automation.mapping-fields.customer-fields" ) }
                        </Typography>
                        <Typography className={ css.reqFieldsLabel }>{ reqFieldsLabel }</Typography>
                    </div>
                    {
                        mappingCustomerFields.map( ( field, index ) => (
                            <MappingFieldItem field={ field } key={ index }/>
                        ))
                    }

                    <div className={ classnames( flex.container, cssUtils.marginTop ) }>
                        <Typography className={ flex.fill }>
                            { t( "marketplace:automation.mapping-fields.opportunity-fields" ) }
                        </Typography>
                        <Typography className={ css.reqFieldsLabel }>{ reqFieldsLabel }</Typography>
                    </div>
                    {
                        mappingOpportunityFields.map( ( field, index ) => (
                            <MappingFieldItem field={ field } key={ index }/>
                        ))
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default MappingFields;