import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as userSelectors } from "../../../user";
import { saveUser } from "./user.actions";

export const createFormName = user => ( "userAdmin" + user.id );

const UserAdmin = ({ user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const users = useSelector( userSelectors.listAll );

    const [ blocked, setBlocked ] = useState( false );

    const toggleBlocked = () => setBlocked( !blocked );

    const formik = useForm({
        form: createFormName( user ),
        enableReinitialize: true,
        initialValues: user,
        onSubmit: values => {
            const admins = users.filter( u => u.admin );
            if ( admins.length === 1 && values.admin ) {
                toggleBlocked();
            } else {
                dispatch( saveUser({ ...values, admin: !values.admin }) );
            }
        },
    });

    return (
        <div type="userAdmin">
            <FormControlLabel
                label={ t( "config:user.admin" ) }
                control={
                    <Switch
                        checked={ user.admin }
                        disabled={ formik.submitting }
                        onChange={ formik.submitForm }
                        size="small"
                    />
                }
            />

            <Dialog
                open={ blocked }
                onClose={ toggleBlocked }
            >
                <DialogContent>
                    <DialogContentText>{ t( "config:user.admin-at-least" ) }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleBlocked }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserAdmin;