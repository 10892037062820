import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchPersons } from "../customer.actions";
import { isLoadingPersons, getPersons } from "../customer.selectors";
import customerCss from "../customer.scss";
import AddPerson from "./add-person.component";
import CustomerPersonItem from "./customer-person-item.component";
import css from "./person.scss";

const CustomerPersons = ({ alignPopupLeft, customer, opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingPersons );
    const persons = useSelector( getPersons );

    useEffect( () => {
        dispatch( fetchPersons( customer.id ) );
    }, [ dispatch, customer.id ] );

    return (
        <Card>
            <CardHeader
                className={ classnames( css.personsHeader, customerCss.headerPerson )}
                classes={{
                    content: customerCss.headerContent
                }}
                subheader={ t( "customer:person.list" ) }
                action={
                    !get( opportunity, "person" ) &&
                        <AddPerson customer={ customer } opportunity={ opportunity }/>
                }
            />
            {
                loading || !persons ?
                    <CardContent><CircularProgress/></CardContent> :
                    get( opportunity, "person" ) ?
                        <List>
                            <CustomerPersonItem
                                customer={ opportunity.person }
                                opportunity={ opportunity }
                                alignPopupLeft={ alignPopupLeft }
                            />
                        </List> :
                        !isEmpty( persons ) &&
                            <List>
                                {
                                    persons.map( person => (
                                        <CustomerPersonItem
                                            key={ person.id }
                                            customer={ person }
                                            opportunity={ opportunity }
                                            alignPopupLeft={ alignPopupLeft }
                                        />
                                    ))
                                }
                            </List>
            }
        </Card>
    );
};

export default CustomerPersons;