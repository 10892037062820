export const SET_REPORT = "x/SET_REPORT";
export const setReport = report => ({
    type: SET_REPORT,
    payload: report
});

export const FETCH_RESULTS = "x/FETCH_REPORT_RESULTS";
export const fetchResults = params => ({
    type: FETCH_RESULTS,
    payload: params
});

export const REQUEST_RESULTS = "x/REQUEST_REPORT_RESULTS";
export const requestResults = () => ({
    type: REQUEST_RESULTS
});

export const RECEIVE_RESULTS = "x/RECEIVE_REPORT_RESULTS";
export const receiveResults = data => ({
    type: RECEIVE_RESULTS,
    payload: data
});

export const ERROR_RESULTS = "x/ERROR_REPORT_RESULTS";
export const errorResults = error => ({
    type: ERROR_RESULTS,
    error: true,
    payload: error
});