import { useCallback, useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import MUITableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";

import css from "./table.scss";
import { Link } from "../";

const TableRow = ({
    index,
    className,
    disabled,
    model,
    data,
    onRowClick,
    onSelect,
    rowLink,
    selectable,
}) => {
    const row = useMemo( () => {
        const item = { ...data };
        Object.keys( model )
            .filter( key => item[ key ] === undefined )
            .forEach( key => item[ key ] = "" );
        return item;
    }, [ model, data ] );

    const handleClick = useCallback( () => {
        if ( disabled ) {
            return;
        }
        if ( selectable ) {
            onSelect && onSelect();
        } else {
            onRowClick && onRowClick( index );
        }
    }, [ disabled, index, onRowClick, selectable, onSelect ] );

    const renderSelectableCell = () => {
        if ( selectable ) {
            return (
                <td className={ css.checkbox }>
                    <Checkbox checked={ data.selected || false } />
                </td>
            );
        }
    };

    const renderCells = () => {
        const cells = Object.keys( model ).map( key => {
            const format = model[ key ].format;
            const info = ( info =>
                typeof info === "function" ?
                    info( data[ key ] ) :
                    info
            )( model[ key ].info );

            return ({
                key,
                info,
                component:
                    <TableCell
                        key={ key }
                        className={
                            classnames(
                                ( rowLink || onRowClick ) && !disabled ? css.selectable : "",
                                {[ css.small ]: model[ key ].size === "small" }
                            )
                        }
                    >
                        { format ? format( row[ key ], model[ key ] ) : row[ key ] }
                    </TableCell>,
            });
        });

        return cells.map( ({ component, info, key }) =>
            !( info && info.title ) ? component :
                <Tooltip key={ key } { ...info }>
                    { component }
                </Tooltip>
        );
    };

    return (
        <MUITableRow
            disabled={ disabled }
            hover
            onClick={ handleClick }
            className={ classnames( css.row, className && className( data ) ) }
            component={ rowLink && !selectable ? Link : "tr" }
            to={ !disabled && rowLink && rowLink( index ) }
        >
            { renderSelectableCell() }
            { renderCells() }
        </MUITableRow>
    );
};

export default TableRow;