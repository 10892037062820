export default {
    "title": "Registro",
    "company": "Nombre de la empresa",
    "phone": "Teléfono",
    "name": "Su nombre",
    "email": "Correo electrónico",
    "password": "Contraseña",
    "confirm-password": "Confirme su contraseña",
    "coupon": "Cupón",
    "segment": {
        "label": "Segmento",
        "type": {
            "AESTHETIC": {
                "label": "Estética y Procedimientos"
            },
            "AUTOMOTIVE": {
                "label": "Automotor",
                "help": "Seleccione el tipo de vehículo que se comercializará con su matrícula."
            },
            "EDUCATION": {
                "label": "Educación"
            },
            "HEALTH": {
                "label": "Salud"
            },
            "INSURANCE": {
                "label": "Seguros"
            },
            "NAUTICAL": {
                "label": "Náutico"
            },
            "OTHER": {
                "label": "Otro"
            },
            "REALSTATE": {
                "label": "Bien inmueble"
            },
            "RETAIL": {
                "label": "Venta al por menor"
            },
            "TELECOM": {
                "label": "Telecomunicaciones"
            }
        }
    },
    "accept-terms": {
        "1": "Acepto los ",
        "2": "Términos de uso",
        "3": " y la ",
        "4": "Política de privacidad",
    },
    "signup": {
        "action": "Registro"
    },
    "login": {
        "call-to-action": "Ya tiene una cuenta por aquí?",
        "action": "Entrar"
    },
    "person-type": {
        "label": "Tipo de persona",
        "legal": "Jurídica",
        "natural": "Física"
    },
    "ssn": "CPF",
    "ein": "CNPJ",
    "user-limit": "¿Cuantos usuarios quieres?",
};