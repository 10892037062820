import { useMemo } from "react";
import List from "@mui/material/List";

import InvitationItem from "./invitation-item.component";
import UserItem from "./user-item.component";

export const UsersList = ({ className, users, invitations }) => {
    const data = useMemo( () => {
        return ( users || [] )
            .map( user => ({
                type: "user",
                data: user
            }))
            .concat( ( invitations || [] ).map( invitation => ({
                type: "invitation",
                data: invitation
            })))
            .sort( ( a, b ) => {
                return a.data.name.localeCompare( b.data.name );
            });
    }, [ invitations, users ] );

    return (
        <List id="usersList" className={ className }>
            { data.map( ( item, index ) => {
                const Component = item.type === "user" ? UserItem : InvitationItem;
                return <Component key={ index } data={ item.data } />;
            })}
        </List>
    );
};

export default UsersList;