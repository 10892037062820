import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import { FormikProvider, Field } from "formik";
import DialogContentText from "@mui/material/DialogContentText";

import useTranslate from "../../hooks/use-translate.hook";
import { DialogForm, RadioButtons, cssUtils, flex } from "../../ui";
import { createExportation } from "../opportunity.actions";
import { EXPORTATION_FORM } from "../opportunity.constants";
import { getFunnelId, getLossReasonId, getPhaseId } from "../opportunity.selectors";
import ExportationColumns from "./exportation-columns.component";

const ExportationForm = ({ opportunityStatus, filters = {}, initialColumns = [], ...props }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const funnelId = useSelector( getFunnelId );
    const phaseId = useSelector( getPhaseId );
    const lossReasonId = useSelector( getLossReasonId );

    const formik = useForm({
        enableReinitialize: true,
        form: EXPORTATION_FORM,
        initialValues: ({
            funnelId,
            phaseId,
            lossReasonId,
            status: opportunityStatus || "OPEN",
            columns: initialColumns,
            fileExtension: "csv",
            ...filters,
        }),
        onSubmit: values => dispatch( createExportation( values ) ),
    });

    return (
        <DialogForm
            messages={{ submit: t( "common:execute" ) }}
            title={ t( "opportunity:exportation.title" ) }
            handleSubmit={ formik.handleSubmit }
            resetForm={ formik.resetForm }
            submitForm={ formik.submitForm }
            submitting={ formik.submitting }
            { ...props }
        >
            <DialogContentText>
                { t( "opportunity:exportation.call-to-action" ) }
            </DialogContentText>
            <FormikProvider value={ formik }>
                <ExportationColumns opportunityStatus={ opportunityStatus } />
                <Field
                    label={ t( "opportunity:exportation.format" ) }
                    name= "fileExtension"
                    component={ RadioButtons }
                    className={ cssUtils.marginTopSmall }
                    fullWidth
                    options={ [ "csv", "xls", "xlsx" ]
                        .map( extension => ( { value: extension, label: extension, className: flex.fill } ) ) }
                />
            </FormikProvider>
        </DialogForm>
    );
};

export default ExportationForm;