export default {
    "section-title-format": "$t(config:index.title) > { sublevel }",
    "title": "Configurações",
    "main": {
        "suggestions": {
            "title": "Sugestões para você",
            "inviteUsers": {
                "info": "Convide as pessoas da sua equipe para usar o CRM",
                "buttonText": " Convidar usuários"
            },
            "createCustomFields": {
                "info": "Crie campos personalizados de acordo com o seu processo de vendas",
                "buttonText": "Criar campos personalizados"
            }
        },
        "yourTeam": {
            "title": "Seu time",
            "invite": "Convite, usuários e equipes",
            "goals": "Metas",
            "commission": "Comissão",
        },
        "subscription": {
            "title": "Inscrição",
            "registrationData": "Dados Cadastrais",
            "main": {
                "title": "Dados Cadastrais",
            },
            "plan": "Plano, pagamentos e faturas",
        },
        "opportunities": {
            "title": "Ajuste de oportunidades",
            "funnel": "Funis",
            "sources": "Origens",
            "lossReasons": "Motivos de venda perdida",
            "scheduleType": "Tipos de agendamento",
            "customFields": "Campos personalizados",
            "templates": "Templates, documentos e galeria de imagens",
            "products": "Produtos e categorias de produtos",
        },
        "advanced": {
            "title": "Avançado",
            "holding": "Holding",
            "email": "E-mail",
            "rules": "Regras e horários",
            "rule": "Regras",
        },
    },
    "items": {
        "main": {
            "label": "Geral",
            "description": "Seu plano"
        },
        "commission": {
            "label": "Comissões"
        },
        "opportunity": {
            "label": "Oportunidades",
            "description": "Fases"
        },
        "form": {
            "label": "Formulários",
            "description": "Campos customizados por módulo"
        },
        "product": {
            "label": "Produtos",
            "description": "Seus produtos e serviços"
        },
        "user": {
            "label": "Usuários",
            "description": "Gerencie e convide usuários"
        },
        "team": {
            "label": "Equipes",
            "description": "Suas equipes"
        },
        "goal": {
            "label": "Metas",
            "description": "Gerencie as metas mensais que os usuários devem alcançar"
        },
        "template": {
            "label": "Templates",
            "description": "Gerencie seus modelos de envio de E-mail, SMS e WhatsApp"
        }
    },
    "subscription": {
        "title": "Inscrição",
        "call-to-action": "Altere os dados da sua inscrição",
        "name": "Nome",
        "person-type": {
            "label": "Tipo de pessoa",
            "legal": "Jurídica",
            "natural": "Física"
        },
        "ssn": "CPF",
        "ein": "CNPJ",
        "save": "Salvar",
        "phone": "Telefone",
        "address": {
            "invalid": "CEP inválido",
            "zip-code": "CEP",
            "street": "Rua",
            "number": "Número",
            "complement": "Complemento",
            "district": "Bairro",
            "city": "Cidade",
            "state": "Estado",
            "country": "País"
        },
        "currency": "Moeda",
        "customer-portfolio": {
            "label": "Regra de carteira de cliente",
            "help":
                "Com esta opção habilitada, os vendedores só poderão visualizar os dados de seus clientes e " +
                "suas oportunidades"
        },
        "block-non-unique-customer": {
            "label": "Bloquear cadastro de clientes existentes",
            "help": "Com esta opção habilitada, não é possível cadastrar clientes de mesmo CPF ou CNPJ"
        },
        "segment": {
            "label": "Segmento",
            "type": {
                "AESTHETIC": "Estética e Procedimentos",
                "AUTOMOTIVE": "Automotivo",
                "EDUCATION": "Educação",
                "HEALTH": "Saúde",
                "INSURANCE": "Seguros",
                "NAUTICAL": "Náutico",
                "OTHER": "Outro",
                "REALSTATE": "Imobiliário",
                "RETAIL": "Varejo",
                "TELECOM": "Telecom"
            },
            "brands": {
                "label": "Marcas",
                "selected": " selecionada(s)"
            },
            "change": {
                "previous-automotive":
                    "Se você retirar sua inscrição do segmento automotivo. Os campos de " +
                    "marca e modelo das oportunidades serão removidos"
            }
        },
        "select-brands": "Selecione ao menos uma marca abaixo",
        "office-hours": {
            "title": "Horário de expediente",
            "weekdays": "Segunda à sexta",
            "morning": "Manhã",
            "afternoon": "Tarde",
            "start-time": "Horário de início",
            "end-time": "Horário de fim",
            "work-on-saturday": "Expediente em sábado",
            "work-on-sunday": "Expediente em domingo",
            "zone-id": "Fuso horário",
            "my-location": "Usar seu fuso horário"
        },
        "block-out-of-hours": {
            "label": "Bloquear acesso fora do horário de expediente",
            "help":
                "Bloqueia o uso do sistema para usuários que não são administradores, fora do horário de " +
                "início ou fim do expediente. São considerados 10 minutos de tolerância."
        },
        "sample": {
            "sample-description": "Dados fictícios adicionados automaticamene para teste",
            "sample-delete": "Remover dados fictícios",
            "confirm-delete": "Tem certeza que deseja remover dados fictícios?"
        }
    },
    "plans": {
        "title": "Plano, pagamentos e faturas",
        "quantity-with-end": "De {start} a {end} usuários",
        "quantity-without-end": "Acima de {start} usuários",
        "coupon-expire": "Expira em {date}",
        "discount-unit": "Desconto de {discount} por usuário",
        "discount-percentage": "Desconto de {discount}%",
        "discount-total": "Desconto de {discount}",
        "price": "por usuário por mês",
        "min-user-limit": "a partir de {minUserLimit} usuários",
        "max-user-limit": "até {maxUserLimit} usuários",
        "min-and-max-user-limit": "de {minUserLimit} até {maxUserLimit} usuários",
        "empty-address":
            "O endereço da sua inscrição não está completo. Você deve preencher o endereço",
        "empty-document": "O CPF/CNPJ da sua inscrição não foi informado. Você deve informar um documento válido",
        "access-register": "Acessar cadastro",
        "frequency": {
            "label": "Frequência de cobrança",
            "MONTHLY": "Mensal",
            "SEMIANNUAL": "Semestral (10% de desconto)",
            "ANNUAL": "Anual (20% de desconto)"
        },
        "hire": "Contratar",
        "cancel": {
            "title": "Cancelar assinatura",
            "confirm":
                "Será enviado um e-mail para o nosso atendimento solicitando o cancelamento. " +
                "Você deseja prosseguir com o cancelamento?",
        },
        "storage": {
            "title": "Armazenamento",
            "available": "Disponível",
            "customer-usage": "Clientes",
            "email-usage": "E-mails",
            "image-usage": "Imagens",
            "opportunity-usage": "Oportunidades",
            "usage": "Uso de armazenamento",
        },
        "success-updated": "Plano atualizado com sucesso."
    },
    "billing": {
        "plan": "Plano",
        "user-limit": {
            "label": "Limite de usuários",
            "help":
                "Defina aqui a quantidade de usuários ativos que a sua inscrição irá utilizar. {price} por usuário.",
            "incresead":
                "Ao aumentar o seu limite de usuários, será cobrado proporcionalmente os dias de uso destes " +
                "usuários até a data da próxima fatura",
            "warning":
                "Se houver mais usuários ativos que o limite definido, os usuários mais recentes serão " +
                "inativados no pagamento da próxima fatura. O valor destes usuários serão reduzidos na próxima fatura"
        },
        "individual": "Pagamento proporcional de novos usuários",
        "hired-users": "Usuários contratados",
        "email-help": "Este e-mail será usado para avisos sobre os pagamentos e envio de faturas",
        "frequency": {
            "label": "Frequência",
            "MONTHLY": "Mensal",
            "SEMIANNUAL": "Semestral",
            "ANNUAL": "Anual"
        },
        "total": "Total: {price}",
        "total-frequency": "Total: {price} {frequency}",
        "type": {
            "label": "Forma de pagamento",
            "BANK_SLIP": "Boleto",
            "CREDIT_CARD": "Cartão de crédito",
            "PIX": "PIX",
        },
        "warning-new": "Ao alterar a frequência de cobrança, será gerada uma nova fatura",
        "status": {
            "label": "Status",
            "PENDING": "Pagamento pendente",
            "AUTHORIZED": "Pagamento autorizado",
            "PROCESSING": "Em processamento",
            "IN_DISPUTE": "Em disputa",
            "RETURNED": "Valor devolvido",
            "DOWNED": "Boleto baixado",
            "REFUSED": "Cartão de crédito recusado",
            "RELEASED": "Liberado",
            "IN_CANCELLATION": "Em cancelamento",
            "CHARGEBACK": "Transação não foi reconhecida pelo titular do cartão",
            "PRE_AUTHORIZED": "Pagamento pré-autorizado"
        },
        "print-bank-slip": "Imprimir boleto",
        "bank-slip-line": "Linha digitável",
        "credit-card": {
            "description": "Terminado em {number}",
            "new": "Novo cartão de crédito",
            "number": "Número do cartão",
            "name": "Nome do titular",
            "validity": "Validade",
            "cvc": "Código de segurança"
        },
        "due-date": "Data de vencimento",
        "price": "Valor",
        "paid-at": "Pagamento recebido em",
        "payment-date": "Data de pagamento",
        "amount-paid": "Valor pago",
        "payment-details": "Detalhes do pagamento",
        "pix-copy": "Código Copia e Cola",
        "pix-info": "Será gerado um QR Code para pagamento",
        "invoice": {
            "download-pdf": "Download NF-e em PDF",
            "download-xml": "Download NF-e em XML",
        },
    },
    "billings": {
        "next": "Próxima fatura em {date}",
        "empty": "Não há faturas registradas"
    },
    "holding": {
        "title": "Holding",
        "help":
            "Utilizando o conceito de holding, você pode configurar aqui a comunicação entre inscrições. " +
            "Esta comunicação permite que os usuários possam enviar as informações das oportunidades para as " +
            "inscrições que forem vinculadas aqui.",
        "token": "Token",
        "validate-token": "Validar token",
        "invalid-token": "Token inválido",
        "funnels": {
            "title": "Funis",
            "help": "Configure aqui a distribuição das oportunidades que você receber de outras inscrições",
            "add": "Criar configuração",
            "empty": "Não há configurações criadas",
            "empty-help":
                "Para que você consiga receber as oportunidades de outras inscrições, " +
                "você precisa criar ao menos uma configuração"
        },
        "funnel": {
            "default-source": {
                "label": "Origem padrão",
                "help": "Se a origem da oportunidade enviada não for localizada nesta inscrição, utiliza esta origem"
            },
            "delete": {
                "title": "Deletar configuração",
                "confirm": "Você tem certeza que deseja excluir esta configuração?"
            },
            "edit": {
                "title": "Editar configuração"
            }
        },
        "linked-subscriptions": "Inscrições vinculadas",
        "link-subscription-help": "Vincule aqui as inscrições que você deseja ter acesso e enviar oportunidades",
        "link-subscription": "Vincular inscrição",
        "subscription-name": "Nome da inscrição",
        "linked-subscription-creation": "Inscrição vinculada em { date } por { user }",
        "unlink-subscription": {
            "title": "Remover vínculo",
            "confirm": "Você tem certeza que deseja remover o vínculo com esta inscrição?"
        }
    },
    "migrations": {
        "title": "Migrações",
        "help":
            "Migre os clientes e oportunidades do seu antigo sistema para o LeadStation. Antes de executar, " +
            "certifique-se que todos os usuários estão cadastrados pois a migração de um sistema só pode ser " +
            "executada uma única vez",
        "empty": "Nenhuma migração executada",
        "new-migration": "Nova migração",
        "system": {
            "label": "Sistema",
            "PIPEDRIVE": "Pipedrive"
        },
        "api-token": "Token de API",
        "default-source": "Origem",
        "default-source-help": "Defina qual a origem será utilizada para as oportunidades criadas",
        "default-user": "Usuário padrão",
        "default-user-help":
            "Defina qual usuário será considerado caso haja algum usuário do { system } que " +
            "não existe no LeadStation",
        "executing": "Executando",
        "executed-by": "{ user } executou esta migração em { date }",
        "finished-at": "Migração finalizada em { date }",
        "refresh": "Atualizar migrações",
        "add": "Nova migration"
    },
    "smtp-server-default": {
        "email": "E-mail",
        "call-to-action": "Crie aqui uma pré configuração para facilitar a configuração dos usuários",
        "title": "Servidor SMTP",
        "create": "Criar pré configuração",
        "confirm-delete": "Você tem certeza que deseja excluir esta pré configuração?",
    },
    "funnels": {
        "title": "Funis",
        "call-to-action": "Adicione, remova e edite seus funis",
        "add": "Adicionar funil",
        "new": {
            "title": "Novo funil",
            "name": "Nome do funil",
            "phase-name": "Nome da fase inicial"
        },
        "config-team-user": {
            "help": "Você pode definir a equipe ou usuários que podem visualizar os dados deste funil." +
            "Esta regra é ignorada para usuários administradores"
        },
        "name": "Funil",
        "automation": {
            "title": "Automação do funil",
            "call-to-action":
                "Ao marcar como ganha ou perdida uma oportunidade deste funil, você pode definir que o " +
                "processo continue, gerando uma nova oportunidade de forma automática em um novo funil. " +
                "Você também pode definir qual equipe/usuários que irão receber estas novas oportunidades, " +
                "se nenhum for definido, a oportunidade será criada para o mesmo usuário da oportunidade ganha."
        },
        "delete": {
            "title": "Excluir funil",
            "confirm":
                "Relacione as fases deste funil, com fases de outros funis para que as oportunidades que estiverem " +
                "neste funil, sejam transferidas",
            "new-phase": "Nova fase"
        },
        "product-categories": {
            "label": "Categorias de produtos",
            "help": "Você pode definir quais categorias de produtos podem ser adicionadas nas propostas " +
                " das oportunidades do funil"
        },
        "opportunitiesOrderBy": {
            "label": "Ordenar oportunidades por",
            "createdAt": "Data de criação",
            "updatedAt": "Data de atualização",
        },
        "opportunitiesOrderType": {
            "label": "Tipo de ordenação",
            "asc": "Crescente",
            "desc": "Decrescente",
        },
        "nextOpportunityStatus": {
            "label": "Status",
            "sold": "Ganha",
            "lost": "Perdida",
        },
        "nextPhaseId": {
            "label": "Fase"
        }
    },
    "phases": {
        "title": "Fases",
        "call-to-action": "Adicione, remova e reorganize as fases de negociação do seu funil",
        "add": "Adicionar fase",
        "delete": {
            "title": "Excluir fase",
            "comment": "Você tem certeza que deseja excluir esta fase? Esta ação é irreversível.",
            "forbidden": "Você não pode excluir esta fase. É necessário haver, pelo menos, uma fase no funil."
        },
        "item": {
            "name": "Nome",
            "days": "Dias de permanência",
            "description": "Descrição"
        },
        "forward": {
            "title": "Tempo limite sem interação",
            "call-to-action":
                "Ao efetuar esta configuração, você exigirá que o usuário efetue qualquer interação, " +
                "em determinado tempo, na oportunidade, quando estiver nesta fase . " +
                "Se ele não efetuar, a oportunidade será encaminhada para outro usuário.",
            "time": "Tempo",
            "time-unit": {
                "label": "Unidade de tempo",
                "DAY": "Dia",
                "HOUR": "Hora",
                "MINUTE": "Minuto"
            },
            "clear-config": "Limpar configuração",
            "empty-users": "Você deve selecionar uma equipe ou ao menos um usuário para encaminhar as oportunidades"
        },
        "cadence": {
            "title": "Tempo de cadência",
            "call-to-action":
            "Ao efetuar esta configuração, você delimitara o tempo em que oportunidade irá ficar " +
            "nesta fase. Ao término do tempo, a oportunidade será encaminhada para outra fase.",
            "phaseId": "Próxima fase",
            "time": "Tempo em dias",
            "clear-config": "Limpar configuração"
        },
        "entry-schedules": {
            "title": "Automação de agendamentos",
            "call-to-action": "Configure aqui a criação de agendamentos na oportunidade ao entrar na fase",
            "opportunity-scheduling-type": "Tipo de agendamento da oportunidade",
            "description": "Descrição do agendamento",
            "time-type": {
                "label": "Tipo de definição de horário",
                "AT_THE_TIME": "No momento",
                "AMOUNT_HOURS": "Hora(s)/minuto(s) à frente",
                "AMOUNT_DAYS": "Dia(s) à frente",
                "EXACT": "Exatamente"
            },
            "amount-time": "Tempo",
            "amount-days": "Dia(s)",
            "exact-time": "Horário",
        },
        "trigger": {
            "title": "Gatilhos",
            "help": "Gatilhos que serão executados ao trocar a fase",
            "template": "Template",
            "automatic": {
                "label": "Automático",
                "help":
                    "O envio será feito automaticamente após a fase ser trocada. " +
                    "Você pode configurar ao lado quantos minutos deve ser aguardado para o envio ser realizado\n"
            },
            "send-customer": {
                "label": "Enviar para cliente",
                "help":
                    "Utilize esta opção para enviar o template para o cliente. " +
                    "Se for um template de e-mail, será enviado para todos os e-mails do cliente, " +
                    "agora se for um template de SMS, será enviado para todos os celulares do cliente. " +
                    "Esta opção não pode ser utilizada em conjunto com os usuários para enviar\n"
            },
            "users-id": "Usuários para enviar",
            "minutes": {
                "label": "Enviar após",
                "placeholder": "minutos"
            },
            "add": "Adicionar gatilho"
        },
        "automation-activity": {
            "title": "Automação de atividades",
            "info": "Automação de atividades irá bloquear a troca de fase do funil caso não seja realizada. " +
                "As atividades serão consideradas como realizadas quando um E-mail, WhatsApp, SMS ou Ligação " +
                "for registrada da Oportunidade ou quando um agendamento for concluído como realizado. Também " +
                "serão considerados agendamentos agendados como atividade a ser cumprida.",
            "registered-activity-opportunity": "Atividade registrada na oportunidade",
            "registered-schedules-opportunity": "Agendamentos registrados na oportunidade",
            "registered-schedules-opportunity-empty": "Não há agendamentos registrados na oportunidade",
            "schedule": {
                "label": "Agendamento",
                "add": "Adicionar agendamento",
                "status": {
                    "label": "Situação",
                    "OPEN": "Agendada",
                    "ACCOMPLISHED": "Realizada"
                }
            },
            "quantity": "Quantidade",
            "comment": "Comentário",
            "whatsapp": "Whatsapp",
            "call": "Ligação",
            "sms": "SMS",
        }
    },
    "opportunity-scheduling-types": {
        "title": "Tipos de agendamento",
        "call-to-action": "Adicione e edite os tipos de agendamento de oportunidade que sua inscrição utiliza.",
        "add": "Adicionar tipo",
        "item": {
            "name": "Nome",
            "phase": {
                "label": "Fase",
                "help":
                    "Quando um agendamento deste tipo for marcado como realizado, a oportunidade " +
                    "em questão terá sua fase alterada para a selecionada aqui. " +
                    "Essa alteração de fase só ocorre se a oportunidade estiver em uma fase anterior a selecionada"
            },
            "dashboard": {
                "label": "Exibir no dashboard",
                "help": "Define se o tipo de agendamento deve ser exibido no gráfico no módulo de dashboard"
            }
        },
        "delete": {
            "title": "Excluir tipo de agendamento",
            "comment": "Você tem certeza que deseja excluir este tipo de agendamento?",
            "forbidden":
                "Você não pode excluir este tipo de agendamento. É necessário haver, pelo menos, " +
                "um tipo de agendamento no sistema."
        },
    },
    "sources": {
        "title": "Origens",
        "call-to-action": "Adicione, edite e exclua as origens de oportunidade que sua inscrição utiliza.",
        "add": "Adicionar origem",
        "item": {
            "placeholder": "Nome da origem"
        },
        "delete": {
            "title": "Excluir origem",
            "comment": "Você tem certeza que deseja excluir esta origem?",
            "forbidden": "Você não pode excluir esta origem. É necessário haver, pelo menos, uma origem no sistema."
        }
    },
    "loss-reasons": {
        "title": "Motivos venda perdida",
        "call-to-action":
            "Adicione, edite e exclua os motivos venda perdida que serão usados nas oportunidades " +
            "que sua inscrição utiliza.",
        "add": "Novo Motivo",
        "item": {
            "placeholder": "Nome do motivo venda perdida"
        },
        "delete": {
            "title": "Excluir",
            "comment": "Você tem certeza que deseja excluir este motivo venda perdida?",
            "forbidden":
                "Você não pode excluir este motivo venda perdida. É necessário haver, pelo menos, " +
                "um motivo venda perdida no sistema."
        }
    },
    "product-categories": {
        "header": "Produtos e categorias de produtos",
        "title": "Categorias",
        "call-to-action": "Adicione, edite e exclua as categorias para utilizar nos produtos",
        "empty": "Não há categorias cadastradas",
        "add": "Nova Categoria",
        "item": {
            "placeholder": "Nome da categoria de produto"
        },
        "delete": {
            "title": "Excluir",
            "comment": "Você tem certeza que deseja excluir esta categoria de produto?"
        }
    },
    "form": {
        "add-grouping": "Adicionar agrupamento...",
        "add-field": "Adicionar campo...",
        "layout": {
            "title": "Layout do formulário de { form }",
            "comment":
                "Clique nos campos pra editá-los, ou clique no botão \"Adicionar agrupamento...\" " +
                "para criar um novo agrupamento, ou clique no botão \"Adicionar campo...\" pra criar um novo."
        },
        "default-grouping-name": "Agrupamento",
        "default-field-name": "Campo",
        "sorting": {
            "undo": "Desfazer",
            "save": "Salvar ordem"
        },
        "field-config": {
            "name": {
                "label": "Nome do campo"
            },
            "funnel": {
                "label": "Funis"
            },
            "type": {
                "label": "Tipo do campo"
            },
            "required": {
                "label": "Campo obrigatório"
            },
            "mainScreen": {
                "label": "Campo disponível na lista principal de { module }",
                "help": "Apresenta esse campo na listagem de oportunidades ao acessar o módulo de { module }"
            },
            "summary": {
                "label": "Campo disponível no resumo de { module }",
                "help-customer":
                    "Apresenta esse campo no quadro resumo, esse resumo fica posicionado na lateral " +
                    "esquerda da tela ao acessar um cliente.",
                "help-opportunity":
                    "Apresenta esse campo no quadro resumo, esse resumo fica posicionado na lateral " +
                    "esquerda da tela ao acessar uma oportunidade."
            },
            "options": {
                "values": {
                    "label": "Opções",
                    "placeholder": "Adicione uma nova opção"
                }
            },
            "system-field": "Campo fixo do sistema",
            "half-row": {
                "label": "Considerar 50% da linha",
                "help":
                    "Este campo terá a metade da largura da linha. Caso o campo acima ou " +
                    "abaixo deste também considere 50% da linha, ambos ficarão na mesma linha"
            },
            "depends-on": {
                "label": "Condicionar a outro campo",
                "help": "Condicionar a exibição deste campo a seleção de um valor do outro campo do tipo Select",
                "field": "Campo",
            },
            "confirm-update-message": "O(s) valor(es) \"{ values }\" está(ão) em uso. Deseja continuar?"
        },
        "header-config": {
            "name": {
                "label": "Nome do agrupamento"
            }
        },
        "modules": {
            "COMPANY": "Empresas",
            "PERSON": "Pessoas",
            "OPPORTUNITY": "Oportunidades"
        },
        "field-types": {
            "text": "Texto",
            "date": "Data",
            "email": "E-mail",
            "phone": "Telefone",
            "number": "Número",
            "address": "Endereço",
            "select": "Select",
            "select-multiple": "Select Múltiplo",
            "price": "Valor",
        },
        "delete-field": {
            "comment-field": "Você tem certeza que deseja excluir este campo?",
            "comment-grouping": "Você tem certeza que deseja excluir este agrupamento?"
        }
    },
    "products": {
        "header": "Produtos e categorias de produtos",
        "title": "Produtos",
        "subtitle": "Seus produtos e serviços",
        "search": "Pesquise aqui pela descrição ou código do produto",
        "selected-importation": "Visualizando produtos criados via: { name }",
        "empty": "Não há produtos cadastrados",
        "delete": {
            "title": "Excluir produtos",
            "success": "Produto excluído",
            "comment": "Você tem certeza que deseja excluir este produto?"
        },
        "add": {
            "single": "Novo produto",
            "import": "Importar produtos"
        },
        "importation": {
            "title": "Importações",
            "subheader":
                "Importe produtos utilizando uma planilha. " +
                "Se processar produtos com código já cadastrados, os mesmos serão atualizados",
            "call-to-action":
                "A planilha pode conter quatro colunas: " +
                "descrição, valor, código e nome da categoria (nesta ordem). Somente descrição é obrigatório",
            "empty": "Não há importações criadas",
            "helper-file": "Somente arquivos .csv, .xls e .xlsx são aceitos",
            "file-exceed-lines": "A planilha não pode possuir mais de 300 linhas",
            "finished-at": "Finalizada em",
            "processing": "Processando",
            "delete":
                "Você tem certeza que deseja excluir esta importação? Todos os produtos criados também serão excluídos",
            "created-products": "Ver produtos criados",
        },
        "download": "Exportar todos os produtos para uma planilha (.csv)"
    },
    "product": {
        "code": "Código",
        "description": "Descrição",
        "price": "Preço",
        "measurementUnit": {
            "label": "Medida de unidade",
            "UNIT": "Unidade",
            "METER": "Metro",
            "HOUR": "Hora",
            "KILO": "Quilo",
            "LITER": "Litro"
        },
        "recurrent": "Recorrente",
        "with-recurrence": "Com recorrência",
        "without-recurrence": "Sem recorrência",
        "active": "Ativo",
        "export-products": {
            "info-title": "Exportar Produtos",
            "info-content": "Não há produtos para exportar"
        },
        "category": {
            "label": "Categoria",
            "placeholder": "Nome da origem"
        }
    },
    "users": {
        "title": "Usuários",
        "active": "Ativos",
        "invitations": "Convites",
        "new-user": "Novo usuário",
        "pending-invite": "CONVITE PENDENTE",
        "search": {
            "placeholder": "Pesquise usuários pelo nome"
        },
        "new": "Novo usuário",
        "limit": "Sua inscrição possui um limite de { users } usuário(s) ativo(s)"
    },
    "new-user": {
        "title": "Novo usuário",
        "name": "Nome",
        "email": "E-mail",
        "admin": "Administrador",
        "teams": {
            "label": "Equipes",
            "add": "Adicionar equipe",
            "leader": "Responsável",
            "team": "Equipe"
        },
        "invitation-sent": "O convite para { email } foi enviado!"
    },
    "user": {
        "admin": "Administrador",
        "admin-at-least": "Não é possível realizar esta alteração. Deve haver, pelo menos, um usuário administrador.",
        "active": "Ativo",
        "active-at-least":
            "Não é possível realizar esta alteração. Deve haver, pelo menos, um usuário administrador ativo.",
        "two-factor-auth": {
            "title": "Autenticação de dois fatores",
            "help": "Ao realizar o login, é enviado um e-mail para o usuário com o código de verificação"
        },
    },
    "invitations": {
        "sent-at": "Enviado em { date }",
        "no-invites": "Não há convites pendentes."
    },
    "resend": {
        "title": "Reenviar convite",
        "confirm": "Tem certeza que deseja reenviar este convite?",
        "yes": "Sim, reenviar"
    },
    "delete-invite": {
        "title": "Excluir convite",
        "confirm": "Tem certeza que deseja excluir este convite?",
        "yes": "Excluir"
    },
    "team": {
        "name": "Nome",
        "users": {
            "label": "Usuários",
            "single": "Usuário",
            "leader": "Responsável",
            "add": "Adicionar usuário",
            "weight": "Peso",
        },
        "new": "Nova equipe",
        "opportunity-distribution-types": {
            "label": "Tipo de distribuição de oportunidades",
            "weighted": "Ponderada",
            "proportional": "Proporcional"
        },
        "weight": {
            "label": "Pesos",
            "validation": "A soma dos pesos deve ser igual a 10",
            "info":
                "Para uma distribuição ponderada de oportunidade, é necessário atribuir a " +
                "equipe os pesos individuais de cada usuário, numa escala de 1 a 10, indicando o grau " +
                "de relevância de cada usuário no recebimento das oportunidades.",
        }
    },
    "teams": {
        "title": "Equipes",
        "new": "Nova equipe",
        "no-teams": "Não há equipes cadastradas."
    },
    "new-team": {
        "title": "Nova equipe"
    },
    "goal": {
        "name": "Nome",
        "type": {
            "label": "Tipo",
            "OPPORTUNITIES_CREATED": "Ooportunidades criadas (quantidade)",
            "OPPORTUNITIES_SOLD": "Oportunidades ganhas (quantidade)",
            "OPPORTUNITY_SCHEDULING_ACCOMPLISHED": "Agendamentos de oportunidade realizados",
            "PRODUCTS_SOLD": "Produtos vendidos",
            "SOLD_OPPORTUNITIES_PRICE": "Valor de oportunidades ganhas",
            "SOLD_OPPORTUNITIES_RECURRENT_PRICE": "Valor recorrente de oportunidades ganhas",
            "AVERAGE_SERVICE_TIME": "Tempo médio de atendimento (minutos)",
            "CUSTOM_FIELD": "Campo personalizado"
        },
        "opportunity-scheduling-type": "Tipo de agendamento de oportunidade",
        "products": "Produtos de oportunidades",
        "product-categories": "Categorias de produto",
        "target": "Objetivo",
        "help-target":
            "O objetivo deve representar o número que os usuários devem alcançar de acordo " +
            "com o tipo da meta dentro do mês",
        "help-users":
            "Você pode definir esta meta para uma equipe ou usuários específicos. " +
            "Se não for definido uma equipe ou usuários, esta meta será considerada para todos os usuários. " +
            "Os responsáveis pela equipe selecionada, não serão considerados.",
        "help-type": "Soma os valores de campos numéricos e de valor das oportunidades vendidas no período",
        "team": "Equipe",
        "users": "Usuários",
        "all-users": "Todos usuários",
        "duration": {
            "label": "Duração",
            "help": "Se nenhuma data for informada, a meta será sempre considerada"
        },
        "initial-date": "Data de início",
        "final-date": "Data de fim",
        "interval": {
            "label": "Intervalo",
            "help": "Intervalo de tempo para considerar no cálculo do progresso da meta",
            "MONTHLY": "Mensal",
            "QUARTERLY": "Trimestral",
            "SEMIANNUAL": "Semestral",
            "ANNUAL": "Anual"
        },
        "future": {
            "label": "Meta futura",
            "help": "Metas que possuem a data de início superior à data atual"
        },
        "past": {
            "label": "Meta passada",
            "help": "Metas que possuem a data de fim inferior à data atual"
        },
        "confirm-delete": "Você tem certeza que deseja excluir esta meta?",
        "collective": "Meta coletiva",
        "collective-helper": "A meta coletiva quando ativa irá considerar " +
        "o valor do objetivo para todos os membros da equipe ou usuários selecionadas"
    },
    "new-goal": "Nova meta",
    "edit-goal": "Editar meta",
    "empty-goals": "Não há metas cadastradas",
    "template": {
        "name": "Nome",
        "type": {
            "label": "Tipo",
            "EMAIL": "E-mail",
            "SMS": "SMS",
            "WHATSAPP": "WhatsApp"
        },
        "share": "Compartilhar com demais usuários",
        "share-help": "Os demais usuários podem usar o template para enviar ao cliente",
        "close": "Fechar",
        "save": "Salvar"
    },
    "commissions": {
        "title": "Comissões",
        "call-to-action": "Defina as comissões dos usuários baseadas nas oportunidades ganhas",
        "empty": "Não há comissões cadastradas"
    },
    "commission": {
        "new": "Nova comissão",
        "delete": {
            "title": "Excluir comissão",
            "confirm": "Você tem certeza que deseja excluir esta comissão?",
        },
        "edit": "Editar comissão",
        "type": {
            "OPPORTUNITY": "Oportunidade",
            "PRODUCT": "Produto"
        },
        "opportunity-price": "Considerar valor da oportunidade",
        "opportunity-recurrent-price": "Considerar valor recorrente da oportunidade",
        "products": {
            "at-least": "Selecione ao menos uma categoria ou um produto"
        },
        "price-type": {
            "label": "Tipo de valor",
            "UNIT": "Unitário",
            "PERCENTAGE": "Porcentagem"
        },
        "closing-day": {
            "label": "Dia de fechamento",
            "helper": "Dia do mês que será fechada a comissão",
            "bigger": "Não pode ser maior que 30",
            "lower": "Não pode ser menor que 1",
        },
        "goal": {
            "label": "Meta",
            "helper": "Meta a ser atingida para condicionar a aprovação da comissão",
        },
        "users-helper":
            "Se nenhuma equipe ou usuário for selecionado, a comissão será considerada para todos os usuários",
    },
    "templates": {
        "title": "E-mail/SMS/WhatsApp",
        "call-to-action": "Gerencie seus modelos de envio de E-mail, SMS e WhatsApp",
        "add": "Adicionar template",
        "no-templates": "Não há templates cadastrados",
        "created-by": "Criado por { user } em { date } ",
        "updated-by": "- Alterado por { user } em { date } ",
        "type": {
            "EMAIL": "E-mail",
            "SMS": "SMS",
            "WHATSAPP": "WhatsApp",
            "empty": "Não há templates deste tipo cadastrados"
        },
        "delete": {
            "confirm": "Você tem certeza que deseja excluir este template?",
            "yes": "Sim",
            "no": "Não"
        },
        "new": "Novo template",
        "edit": "Editar template",
        "test": "Enviar teste",
        "test-submitted": "E-mail enviado com sucesso",
        "email": "E-mail",
        "email-test-description": "Informe abaixo o e-mail que receberá este teste"
    },
    "files": {
        "title": "Documentos",
        "call-to-action": "Cadastre aqui seus modelos de documentos",
        "empty-models": "Não há modelos de documentos cadastrados",
        "model": {
            "name": "Nome",
            "new": "Novo modelo de arquivo",
            "config-header": "Configurar cabeçalho",
            "config-footer": "Configurar rodapé",
            "confirm-delete": "Você tem certeza que deseja excluir este modelo?",
            "header": "Cabeçalho",
            "header-height": "Altura do cabeçalho",
            "header-margin": "Alinhar o cabeçalho de acordo com a margem do documento",
            "footer": "Rodapé",
            "footer-height": "Altura do rodapé",
            "footer-margin": "Alinhar o rodapé de acordo com a margem do documento",
            "section-list": "Seções",
            "section": {
                "confirm-delete": "Você tem certeza que deseja excluir esta seção?",
                "edit": "Editar seção",
                "new": "Nova seção",
                "name": "Nome da seção",
                "fields": "Campos",
                "form": "Formulário",
                "overview": "Geral",
                "date-time": "Data e horário",
                "date": "Data",
                "time": "Horário",
                "company": "Empresa",
                "person": "Pessoa",
                "opportunity": "Oportunidade",
                "products-all": "Produtos (todos)",
                "products-with-recurrence": "Produtos com recorrência",
                "products-without-recurrence": "Produtos sem recorrência",
                "proposal": "Proposta",
                "proposal-sequence": "Sequência",
                "proposal-sequence-help": "Número sequencial da proposta criada por oportunidade",
                "body": "Corpo",
                "in-words": "por extenso",
                "show-fields": "Exibir campos",
                "hide-fields": "Esconder campos"
            },
            "section-preview": {
                "title": "Visualizar seção",
                "description": "Para substituir os campos, você pode selecionar uma empresa, pessoa ou oportunidade"
            }
        }
    },
    "images": {
        "title": "Galeria de imagens",
        "subheader": "Aqui estão listadas as imagens cadastradas que podem ser utilizadas nos templates",
        "created-by": "Criada por { user } em { date }",
        "delete": {
            "confirm": "Você tem certeza que deseja excluir esta imagem?",
            "yes": "Sim",
            "no": "Não"
        }
    },
    "kanban": {
        "title": "Kanban",
        "help": "Configure aqui os campos para exibir no kanban das suas oportunidades",
        "company-name": "Nome da empresa",
        "person-name": "Nome da pessoa",
        "phone-help":
            "Se houver pessoa vinculada, tenta obter o primeiro telefone da pessoa, caso não houver, " +
            "tenta obter o primeiro telefone da empresa",
        "email-help":
            "Se houver pessoa vinculada, tenta obter o primeiro e-mail da pessoa, caso não houver, " +
            "tenta obter o primeiro e-mail da empresa",
        "other-fields": "Outros campos de formulário. Disponíveis para seleção: {selected} de {max}",
        "field": "Campo",
    },
    "distribution": {
        "title": "Distribuições",
        "detail": "Adicione, remova e edite as configurações de distribuição para integrar uma abordagem mais " +
            "colaborativa. Estas configurações substituem as regras de distribuição das integrações vinculadas, " +
            "permitindo distribuições por equipe, seguindo as regras existentes, ou por usuários, utilizando um " +
            "modelo proporcional. A distribuição atua de forma independente das integrações, buscando uma " +
            "distribuição mais equitativa das oportunidades. Em outras palavras, se uma oportunidade chegar às " +
            "integrações X e Y, ela será distribuída considerando o conjunto de integrações. Por exemplo, se a " +
            "oportunidade chegar primeiro à integração X e depois à Y, a distribuição assegurará que as " +
            "oportunidades sejam encaminhadas para vendedores diferentes, caso a regra de distribuição seja " +
            "proporcional. Isso resulta em uma alocação mais justa e eficiente das oportunidades.",
        "empty-distributions": "Não há regras de distribuição cadastradas",
        "empty-users": "Você deve selecionar uma equipe ou ao menos um usuário para distribuir as oportunidades",
        "add": "Adicionar distribuição",
        "new": "Nova distribuição",
        "delete-distribution": {
            "title": "Excluir distribuição",
            "detail": "Escolha a regra de distribuição a ser aplicada nas integrações atualmente associados à " +
                "distribuição que está sendo removida."
        }
    },
};