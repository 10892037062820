import { createSelector } from "reselect";

export function adaptServerState ( state ) {
    return Object.assign( {}, state, {
        auth: {
            logged: state.auth.logged
        },
        theme: {
            mode: state?.theme?.mode || "light",
        }
    });
}

export const isLogged = state => state.auth.logged;
export const isLoadingInfo = state => state.info && state.info.loading;
export const getUser = createSelector( state => state.info, info => info?.user );
export const getLoggedHolding = state => state.info && state.info.loggedHolding;
export const getLocale = state => state.info && state.info.locale;
export const getPreferences = createSelector( state => state.info, info => info?.preferences );
export const getThemeMode = state => state?.theme.mode;