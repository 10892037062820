import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils, flex } from "../../../ui";
import { actions as mailtopActions, selectors as mailtopSelectors } from "../../../mailtop";
import css from "../../marketplace.scss";
import { AuthToken, Lists } from ".";

const MailtopAutomation = ({ className }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const navigate = useNavigate();

    const auth = useSelector( mailtopSelectors.getAuth );
    const loading = useSelector( mailtopSelectors.isLoadingAuth );

    const [ tab, setTab ] = useState( 0 );

    const handleChangeTab = useCallback( ( e, newTab ) => setTab( newTab ), [] );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    useEffect( () => {
        dispatch( mailtopActions.fetchToken() );
    }, [ dispatch ] );

    const hasToken = !!auth?.token;

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, css.cardInfoContainer ) }>
                    <div className={ css.cardInfo }>
                        <div className={ css.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ css.cardLogo }>
                            <div className={ css.mailtopIcon }/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            <span>
                                { t( "marketplace:automation.mailtop.call-to-action" ) }
                                <a href="https://mailtop.com.br/?md=88" className={ cssUtils.textLink } target="_blank">
                                    <b>{ "mailtop.com.br!" }</b>
                                </a>
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader
                        title={ t( "marketplace:automation.mailtop.title" ) }
                        titleTypographyProps={{ variant: "h5" }}
                    />
                    <Paper>
                        <Tabs value={ tab } onChange={ handleChangeTab }>
                            <Tab label={ t( "marketplace:automation.mailtop.tabs.auth" ) }/>
                            <Tab label={ t( "marketplace:automation.mailtop.tabs.lists" ) } disabled={ !hasToken }/>
                        </Tabs>
                        { loading && <LinearProgress/> }
                    </Paper>
                    {
                        tab === 0 ? <AuthToken/> : <Lists/>
                    }
                </Card>
            </div>
        </div>
    );
};

export default MailtopAutomation;