import useTranslate from "../../../hooks/use-translate.hook";
import ConfigHeader from "../../config-header.component";
import SubscriptionMain from "./subscription-main.component";

const Main = () => {
    const t = useTranslate();

    return (
        <div>
            <ConfigHeader title={ t( "config:main.subscription.main.title" ) }/>
            <SubscriptionMain/>
        </div>
    );
};

export default Main;