import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import get from "lodash/get";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { flex } from "./";
import css from "./dialog-form.scss";

const DialogForm = ({
    closeAfterSubmit = true,
    actions,
    children,
    handleSubmit,
    onClose,
    open,
    size = "sm",
    messages,
    resetForm,
    submitForm,
    submitting,
    title,
}) => {
    const t = useTranslate();

    const prevSubmitting = usePrevious( submitting );
    const prevOpen = usePrevious( open );

    useEffect( () => {
        if ( prevSubmitting && !submitting && closeAfterSubmit ) {
            onClose();
        }
    }, [ closeAfterSubmit, onClose, prevSubmitting, submitting ] );
    useEffect( () => {
        if ( prevOpen && !open && resetForm ) {
            resetForm();
        }
    }, [ prevOpen, open, resetForm ] );

    return (
        <Dialog open={ open } maxWidth={ size } fullWidth>
            { title && <DialogTitle>{ title }</DialogTitle> }
            <DialogContent>
                <form className={ css.form } onSubmit={ handleSubmit } noValidate>
                    { children }
                </form>
            </DialogContent>
            <DialogActions>
                <div className={ flex.fill }>
                    { actions }
                </div>
                <Button color="primary" disabled={ submitting } onClick={ onClose }>
                    { get( messages, "cancel", t( "common:cancel" ) ) }
                </Button>
                <Button color="primary" disabled={ submitting } onClick={ submitForm }>
                    { get( messages, "submit", t( "common:save" ) ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogForm;