import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";
import get from "lodash/get";

import * as actions from "./customer.actions";

export function all ( state = {}, action ) {

    switch ( action.type ) {
        case actions.SET_CUSTOMER_LIST_TYPE:
            return Object.assign( {}, state, {
                type: action.payload
            });
        case actions.REQUEST_CUSTOMERS_LIST:
            return Object.assign( {}, state, {
                loading: true
            });

        case actions.RECEIVE_CUSTOMERS_LIST:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload.customers,
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements
            });

        case actions.ERROR_CUSTOMERS_LIST:
            return Object.assign( {}, state, {
                loading: false,
                error: action.error
            });

        default:
            return state;
    }
}

export const search = createRequestReducer({
    begin: actions.REQUEST_SEARCH_CUSTOMERS,
    success: actions.RECEIVE_SEARCH_CUSTOMERS,
    failure: actions.ERROR_SEARCH_CUSTOMERS
});

export const selected = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_CUSTOMER:
            return Object.assign( {}, state, {
                loading: true,
                error: null
            });

        case actions.SET_CUSTOMER:
            return Object.assign( {}, state, {
                loading: false,
                data: action.payload
            });

        case actions.ERROR_CUSTOMER:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload
            });

        default:
            return state || {};
    }
};

export const contacts = createRequestReducer({
    begin: actions.REQUEST_CONTACTS,
    success: actions.RECEIVE_CONTACTS,
    failure: actions.ERROR_CONTACTS
});

export const contactTypes = createRequestReducer({
    begin: actions.REQUEST_CONTACT_TYPES,
    success: actions.RECEIVE_CONTACT_TYPES,
    failure: actions.ERROR_CONTACT_TYPES
});

export const files = createRequestReducer({
    begin: actions.REQUEST_FILES,
    success: actions.RECEIVE_FILES,
    failure: actions.ERROR_FILES
});

export const statistics = createRequestReducer({
    begin: actions.REQUEST_STATISTICS,
    success: actions.RECEIVE_STATISTICS,
    failure: actions.ERROR_STATISTICS
});

export const persons = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.REQUEST_PERSONS:
            return Object.assign( {}, state, {
                loading: true
            });
        case actions.RECEIVE_PERSONS:
            return Object.assign( {}, state, {
                loading: false,
                all: action.payload
            });
        case actions.ERROR_PERSONS:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload
            });
        case actions.DELETING_PERSON:
            return Object.assign( {}, state, {
                deleting: action.payload
            });
        default:
            return state;
    }
};

export function timeline ( state = {}, action ) {
    switch ( action.type ) {
        case actions.FETCH_TIMELINE:
            return Object.assign( {}, state, {
                currentPage: action.payload.page || 1
            });
        case actions.REQUEST_TIMELINE:
            return Object.assign( {}, state, {
                loading: true,
                reload: false
            });

        case actions.RECEIVE_TIMELINE:
            return Object.assign( {}, state, {
                loading: false,
                data: state.currentPage > 1 && action.payload ?
                    state.data.concat( action.payload.events ) :
                    get( action.payload, "events" ),
                totalPages: get( action.payload, "totalPages" )
            });

        case actions.ERROR_TIMELINE:
            return Object.assign( {}, state, {
                loading: false,
                error: action.payload
            });

        case actions.RELOAD_TIMELINE:
            return Object.assign( {}, state, {
                reload: true,
                data: null,
                totalPages: null
            });

        default:
            return state;
    }
}

export const opportunities = createRequestReducer({
    begin: actions.REQUEST_OPPORTUNITIES,
    success: actions.RECEIVE_OPPORTUNITIES,
    failure: actions.ERROR_OPPORTUNITIES
});

export const following = createRequestReducer({
    begin: actions.REQUEST_FOLLOWING,
    success: actions.RECEIVE_FOLLOWING,
    error: actions.ERROR_FOLLOWING
});

export const tags = createRequestReducer({
    begin: actions.REQUEST_TAGS,
    success: actions.RECEIVE_TAGS,
    error: actions.ERROR_TAGS
});

export const companyData = createRequestReducer({
    begin: actions.REQUEST_COMPANY_DATA,
    success: actions.RECEIVE_COMPANY_DATA,
    failure: actions.ERROR_COMPANY_DATA
});

export function create ( state = {}, action ) {
    switch ( action.type ) {
        case actions.START_NEW_CUSTOMER:
            return Object.assign( {}, state, {
                open: true
            });

        case actions.CLOSE_NEW_CUSTOMER:
            return Object.assign( {}, state, {
                open: false
            });

        default:
            return state;
    }
}

export function newEmail ( state = {}, action ) {
    switch ( action.type ) {
        case actions.START_NEW_EMAIL:
            return Object.assign( {}, state, {
                open: true,
                initialValues: action.payload
            });

        case actions.CLOSE_NEW_EMAIL:
            return Object.assign( {}, state, {
                open: false,
                initialValues: null
            });

        default:
            return state;
    }
}

const newOpportunity = ( state = {}, action ) => {
    switch ( action.type ) {
        case actions.OPEN_NEW_OPPORTUNITY:
            return Object.assign( {}, state, {
                open: true,
            });
        case actions.CLOSE_NEW_OPPORTUNITY:
            return Object.assign( {}, state, {
                open: false,
            });
        default:
            return state;
    }
};

export const customers = combineReducers({
    all,
    search,
    create,
    selected,
    contacts,
    contactTypes,
    files,
    statistics,
    persons,
    timeline,
    opportunities,
    following,
    tags,
    companyData,
    newEmail,
    newOpportunity,
});