import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, useForm } from "formik-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../hooks/use-translate.hook";
import { selectors as formSelectors, validateFields } from "../../form";
import CustomerWizard from "../../customer/customer-wizard.component";
import companyCss from "../../customer/company/company.scss";
import { Dialog, cssUtils } from "../../ui";
import { patchOpportunity } from "../opportunity.actions";
import { ADD_COMPANY_FORM } from "../opportunity.constants";

const fieldsToInitialValues = fields => fields
    .map( field => field.id )
    .reduce( ( model, id ) => ({ ...model, [ id ]: null }), {} );

const AddCompany = ({ opportunity }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const fields = useSelector( formSelectors.list( "COMPANY" ) );

    const [ open, setOpen ] = useState( false );

    const toggleOpen = () => setOpen( !open );

    const initialValues = useMemo( () => ({
        id: opportunity.id,
        company: {
            new: false,
            id: null,
            type: "COMPANY",
            fields: fieldsToInitialValues( fields )
        }
    }), [ fields, opportunity ] );

    const formik = useForm({
        form: ADD_COMPANY_FORM,
        enableReinitialize: true,
        onSubmit: values => dispatch( patchOpportunity({ ...values, formikForm: ADD_COMPANY_FORM }) ),
        onSubmitSuccess: () => setOpen( false ),
        initialValues,
        validate: values => {
            const errors = {};

            const company = get( values, "company", {} );
            if ( company.new ) {
                errors.company = validateFields( company, { t, fields } );
            } else if ( !company.id ) {
                errors.company = {
                    id: "common:validation.selection"
                };
            }

            if ( errors.company && isEmpty( errors.company ) ) {
                delete errors.company;
            }

            return errors;
        },
    });

    return (
        <>
            <Tooltip title={ t( "customer:company.add-title" ) }>
                <IconButton color="inherit" onClick={ toggleOpen } size="large">
                    <AddIcon/>
                </IconButton>
            </Tooltip>

            <Dialog open={ open } fullWidth maxWidth="sm">
                <DialogTitle className={ companyCss.titleAddCompany }>
                    { t( "customer:company.add-title" ) }
                </DialogTitle>
                <DialogContent className={ classnames( cssUtils.paddingLeft0, cssUtils.paddingRight0 )}>
                    <Form formik={ formik }>
                        <CustomerWizard
                            name="company"
                            type="COMPANY"
                            resetForm={ formik.resetForm }
                            setFieldValue={ formik.setFieldValue }
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={ formik.submitting } onClick={ toggleOpen }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button color="primary" disabled={ formik.submitting } onClick={ formik.submitForm }>
                        { t( "common:save" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddCompany;