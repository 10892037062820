import {useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as subscriptionSelectors } from "../../../subscription";
import { fetchSmsStatistics } from "./sms.actions";
import { getSmsStatistics, isLoadingSmsStatistics } from "./sms.selectors";
import { cssUtils, flex } from "../../../ui";
import { Table } from "../../../ui/table";
import cssMarketplace from "../../marketplace.scss";

const SmsIntegration = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const t = useTranslate();

    const loading = useSelector( isLoadingSmsStatistics );
    const subscription = useSelector( subscriptionSelectors.getSubscription );
    const smsStatistics = useSelector( getSmsStatistics );

    const integration = useMemo(
        () => get( subscription, "integrations", [] ).find( item => item.integration === "SMS" ),
        [ subscription ]
    );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "../..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const source = useMemo( () => smsStatistics && ([
        {
            name: t( "marketplace:products.sms.sent" ),
            value:  smsStatistics.sent
        },
        {
            name: t( "marketplace:products.sms.pending" ),
            value:  smsStatistics.pending
        },
        {
            name: t( "marketplace:products.sms.received" ),
            value:  smsStatistics.sent
        },
        {
            name: t( "marketplace:products.sms.rejected" ),
            value:  smsStatistics.rejected
        }
    ]), [ smsStatistics, t ] );

    const model = useMemo( () => ({
        name: t( "marketplace:products.sms.status" ),
        value: t( "marketplace:products.sms.quantity" )
    }), [ t ] );

    useEffect( () => {
        dispatch( fetchSmsStatistics() );
    }, [ dispatch ] );

    if ( loading ) {
        return <CircularProgress/>;
    }

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, cssMarketplace.cardInfoContainer ) }>
                    <div className={ cssMarketplace.cardInfo }>
                        <div className={ cssMarketplace.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ cssMarketplace.cardLogo }>
                            <SmsIcon style={{ fontSize: 50 }}/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            { t( "marketplace:products.sms.help" ) }
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={ flex.fill }>
                    <CardHeader
                        title={ t( "marketplace:products.sms.title" ) }
                        action={
                            <Switch
                                checked={ integration.active }
                                onChange={ () => {} }
                            />
                        }
                    />
                    {
                        !integration.active &&
                        <CardContent>
                            <Typography>
                                { t( "marketplace:products.sms.how-to-enable" ) }
                            </Typography>
                        </CardContent>
                    }

                    {
                        integration.active &&
                        <>
                            <CardContent className={ cssUtils.paddingBottom0 }>
                                <Typography variant="subtitle1" color="textSecondary">
                                    { t( "marketplace:products.sms.consumption" ) }
                                </Typography>
                            </CardContent>
                            <Table
                                model={ model }
                                source={ source }
                                shadow={ false }
                            />
                        </>
                    }
                </Card>
            </div>
        </div>
    );
};

export default SmsIntegration;