import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "formik-redux";
import Button from "@mui/material/Button";
import classnames from "classnames";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as subscriptionSelectors } from "../../../subscription";
import { saveIntegration } from "../integration.actions";
import { flex, cssUtils } from "../../../ui";
import { fetchUsers } from "../../../user/user.actions";
import { fetchFunnels } from "../../../funnel/funnel.actions";
import { fetchSources } from "../../../source/source.actions";
import { fetchTeams } from "../../../team/team.actions";
import LeadDistribution from "../lead/lead-distribution.component";
import LeadSourceForm from "../lead/lead-source-form.component";
import css from "../../marketplace.scss";

const FORM_NAME = "INTEGRATION_LEAD";

const WebCapture = ({ className}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const t = useTranslate();

    const integrations = useSelector( subscriptionSelectors.getSubscription );
    const integration = get( integrations, "integrations", [] ).find( item => item.integration === "LEAD" );

    const { error, setFieldValue, submitting, submitForm } = useForm({
        form: FORM_NAME,
        enableReinitialize: true,
        initialValues: integration,
        onSubmit: values => dispatch( saveIntegration( values ) ),
    });

    const [ tab, setTab ] = useState( 0 );
    const [ showErrors, setShowErrors ] = useState( false );

    const handleMarketplacePage = useCallback( () => {
        const parentPath = new URL( "..", window.location.href ).pathname.replace( /\/$/, "" );
        navigate( parentPath );
    }, [ navigate ] );

    const toggleErrors = useCallback( () => setShowErrors( showErrors => !showErrors ), [] );

    const toggleActive = useCallback( ( event, value ) => {
        setFieldValue( "active", value );
        submitForm();
    }, [ setFieldValue, submitForm ] );

    const changeTab = useCallback( ( event, value ) => setTab( value ), [] );

    useEffect( () => {
        dispatch( fetchFunnels() );
        dispatch( fetchSources() );
        dispatch( fetchTeams() );
        dispatch( fetchUsers() );
    }, [ dispatch ] );

    useEffect( () => {
        if ( !isEmpty( error ) ) {
            toggleErrors();
        }
    }, [ error, toggleErrors ] );

    return (
        <div className={ classnames( className, cssUtils.displayBlock ) }>
            <div className={ classnames( flex.container, flex.row, flex.alignItemsStart ) }>
                <Card className={ classnames( flex.item30, cssUtils.marginRight, css.cardInfoContainer ) }>
                    <div className={ css.cardInfo }>
                        <div className={ css.cardButton }>
                            <IconButton size="large" onClick={ handleMarketplacePage }>
                                <ArrowBackIcon />
                            </IconButton >
                        </div>
                        <div className={ css.cardLogo }>
                            <DynamicFeedIcon style={{ fontSize: 50 }}/>
                        </div>
                    </div>
                    <Divider/>
                    <CardContent>
                        <Typography variant="subtitle1" color="textSecondary">
                            { t( "marketplace:integrations.lead.help" ) }
                        </Typography>
                    </CardContent>
                </Card>
                <>
                    <Snackbar
                        open={ showErrors }
                        action={ <Button onClick={ toggleErrors } color="secondary">{ "OK" }</Button>}
                        message={ error?.error }
                        autoHideDuration={ 5000 }
                        onClose={ toggleErrors }
                    />
                    <Card className={ flex.fill }>
                        <CardHeader
                            title={ t( "marketplace:integrations.lead.title" ) }
                            action={
                                <FormControlLabel
                                    label={ t( "marketplace:integrations.able" ) }
                                    control={
                                        <Switch
                                            checked={ integration.active }
                                            onChange={ toggleActive }
                                            disabled={ submitting }
                                        />
                                    }
                                />
                            }
                        />
                        <CardContent>
                            <Tabs
                                variant="fullWidth"
                                value={ tab }
                                onChange={ changeTab }
                            >
                                <Tab label="Distribuição"/>
                                <Tab label="Captação"/>
                            </Tabs>
                            { tab === 0 && <LeadDistribution/> }
                            { tab === 1 && <LeadSourceForm/> }
                        </CardContent>
                    </Card>

                </>
            </div>
        </div>
    );
};


export default WebCapture;