import { useMemo, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";
import ConfigHeader from "../../config-header.component";
import ProductsTable from "./products-table.component";
import ProductCategoryForm from "./product-category-form.component";
import ProductImportations from "./product-importations.component";

const Product = () => {
    const t = useTranslate();

    const [ selectedId, setSelectedId ] = useState( "products" );
    const [ showNewProduct, setShowNewProduct ] = useState( false );
    const [ showImportProduct, setShowImportProduct ] = useState( false );
    const [ newCategory, setNewCategory ] = useState( false );

    const toggleNewProduct = () => setShowNewProduct( !showNewProduct );

    const toggleImportProduct = () => setShowImportProduct( !showImportProduct );

    const toggleNewCategory = () => setNewCategory( !newCategory );

    const menus = useMemo( () => [
        {
            id: "products",
            actionTitle: t( "config:products.add.single" ),
            actionIcon: AddIcon,
            onActionClick: () => setShowNewProduct( true ),
            title: t( "config:products.title" ),
        }, {
            id: "categories",
            actionTitle: t( "config:product-categories.add" ),
            actionIcon: AddIcon,
            onActionClick: () => setNewCategory( true ),
            title: t( "config:product-categories.title" ),
        }, {
            id: "importations",
            actionTitle: t( "config:products.add.import" ),
            actionIcon: UploadFileIcon,
            onActionClick: () => setShowImportProduct( true ),
            title: t( "config:products.importation.title" ),
        }
    ], [ t ] );

    const selectProductsMenu = () => setSelectedId( "products" );
    const selectedMenu = useMemo( () => menus.find( menu => menu.id === selectedId ), [ menus, selectedId ] );

    return (
        <>
            <ConfigHeader
                actionIcon={ selectedMenu.actionIcon }
                actionTitle={ selectedMenu.actionTitle }
                onActionClick={ selectedMenu.onActionClick }
                title={ t( "config:products.header" ) }
            />
            <div className={ classnames( flex.alignItemsStart, flex.container ) }>
                <div className={ flex.item25 }>
                    <List>
                        {
                            menus.map( item => (
                                <ListItemButton
                                    key={ item.id }
                                    onClick={ () => setSelectedId( item.id ) }
                                    selected={ selectedId === item.id }
                                >
                                    <ListItemText primary={ item.title } />
                                </ListItemButton>
                            ))
                        }
                    </List>
                </div>
                <div className={ flex.item75 }>
                    {
                        selectedId === "products" &&
                            <ProductsTable
                                showNewProduct={ showNewProduct }
                                onChangeNewProduct={ toggleNewProduct }
                            />
                    }
                    {
                        selectedId === "categories" &&
                            <ProductCategoryForm
                                newCategory={ newCategory }
                                onChangeNewCategory={ toggleNewCategory }
                            />
                    }
                    {
                        selectedId === "importations" &&
                            <ProductImportations
                                onSelectProducts={ selectProductsMenu }
                                onClose={ toggleImportProduct }
                                show={ showImportProduct }
                            />
                    }
                </div>
            </div>
        </>
    );
};

export default Product;