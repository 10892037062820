import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils } from "../ui";
import css from "./dashboard.scss";

const schedulingOpenColor = "#757575";
const schedulingAccomplishedColor = "#4CAF50";
const schedulingNotAccomplishedColor = "#F44336";

const OpportunitySchedulingTypesChart = ({ className, statistics: { schedules } }) => {
    const t = useTranslate();

    const [ showHelp, setShowHelp ] = useState( false );

    const toggleHelp = () => setShowHelp( !showHelp );

    const series = useMemo( () => {
        return [{
            name: t( "dashboard:opportunity-scheduling-types-chart.open" ),
            data: schedules.map( scheduling => scheduling.open ),
            color: schedulingOpenColor
        }, {
            name: t( "dashboard:opportunity-scheduling-types-chart.accomplished" ),
            data: schedules.map( scheduling => scheduling.accomplished ),
            color: schedulingAccomplishedColor
        }, {
            name: t( "dashboard:opportunity-scheduling-types-chart.not-accomplished" ),
            data: schedules.map( scheduling => scheduling.notAccomplished ),
            color: schedulingNotAccomplishedColor
        }];
    }, [ t, schedules ]);

    const chartData = useMemo( () => {
        return {
            title: {
                text: t( "dashboard:opportunity-scheduling-types-chart.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunity-scheduling-types-chart.title" );
                    }
                },
            },
            xAxis: {
                categories: schedules.map( scheduling => scheduling.name ),
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ""
                }
            },
            tooltip: {
                headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
                pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
                "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
                footerFormat: "</table>",
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series,
        };
    }, [ t, series, schedules ]);

    return (
        <Card className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunity-scheduling-types-chart.help" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK "}</Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="schedulingTypeChart"
                data={ chartData }
                noDataMessage={ t( "dashboard:opportunity-scheduling-types-chart.empty" ) }
                type="column"
            />

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButton className={ css.iconHelpChart } onClick={ toggleHelp } size="large">
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
        </Card>
    );
};

export default OpportunitySchedulingTypesChart;