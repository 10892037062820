import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./smtp-server.actions";

export function *watchSmtpServer () {
    yield all([
        takeEvery( actions.FETCH_SMTP_SERVER_DEFAULT, fetchSmtpServerDefault ),
        takeEvery( actions.FETCH_SMTP_SERVER, fetchSmtpServer ),
    ]);
}

function *fetchSmtpServerDefault () {
    yield put( actions.requestSmtpServerDefault() );

    try {
        const response = yield call( apiv1.get, "/smtpserver/default" );
        yield put( actions.receiveSmtpServerDefault( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSmtpServerDefault( e.response.data ) );
    }
}

function *fetchSmtpServer () {
    yield put( actions.requestSmtpServer() );

    try {
        const response = yield call( apiv1.get, "/smtpserver" );
        yield put( actions.receiveSmtpServer( response.data ) );
    } catch ( e ) {
        yield put( actions.errorSmtpServer( e.response.data ) );
    }
}
