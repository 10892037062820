import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { EmptyState, flex } from "../../../ui";
import { actions as teamActions, selectors as teamSelectors } from "../../../team";
import { actions as userActions } from "../../../user";
import EditTeam from "../team/edit-team.component";
import TeamItem from "./team-item.component";

const ConfigTeam = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ selectedTeam, setSelectedTeam ] = useState( null );
    const [ showDialogTeam, setShowDialogTeam ] = useState( false );
    const [ showError, setShowError ] = useState( false );
    const [ deleteError, setDeleteError ] = useState( "" );

    const teams = useSelector( teamSelectors.listAll );
    const loadingTeams = useSelector( teamSelectors.isLoadingAll );
    const loadingUsers = useSelector( teamSelectors.isLoadingAll );

    const loading = loadingTeams || loadingUsers;

    const toggleDialogTeam = () => setShowDialogTeam( !showDialogTeam );
    const showDeleteError = useCallback( error => {
        setShowError( true );
        setDeleteError( error?.error );
    }, [] );
    const closeDeleteError = () => setShowError( false );
    const selectTeam = team => {
        setSelectedTeam( team );
        setShowDialogTeam( true );
    };

    useEffect( () => {
        dispatch( teamActions.fetchTeams() );
        dispatch( userActions.fetchUsers() );
    }, [ dispatch ] );

    return (
        <div className={ flex.alignItemsStart }>
            <Card>
                <CardHeader
                    title={ t( "config:items.team.label" ) }
                    subheader={ t( "config:items.team.description" ) }
                />
                { loading && <LinearProgress /> }
                {
                    !loading && isEmpty( teams ) ?
                        <CardContent>
                            <EmptyState icon={ PersonOutlineIcon } message={ t( "config:teams.no-teams" ) } />
                        </CardContent> :
                        <List>
                            { ( teams || [] ).map( ( team, index ) => (
                                <TeamItem
                                    key={ index }
                                    team={ team }
                                    onSelect={ selectTeam }
                                    showDeleteError={ showDeleteError }
                                />
                            ))}
                            <Snackbar
                                open={ showError }
                                autoHideDuration={ 2000 }
                                onClose={ closeDeleteError }
                                message={ deleteError }
                                action={
                                    <Button
                                        onClick={ closeDeleteError }
                                        color="secondary"
                                        size="small"
                                    >
                                        { "OK" }
                                    </Button>
                                }
                            />
                        </List>

                }

            </Card>
            <EditTeam
                team={ selectedTeam }
                open={ showDialogTeam }
                onClose={ toggleDialogTeam }
            />
        </div>
    );
};

export default ConfigTeam;