import { Fragment } from "react";
import { useDrop } from "react-dnd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import WarningIcon from "@mui/icons-material/Warning";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { DRAG_SOURCE_TYPE } from "./form-field.component";
import { cssUtils, flex } from "../ui";
import css from "./importation.scss";

const MappingField = ({
    order,
    header,
    line,
    field,
    format,
    country,
    type,
    onClick,
    onDelete
}) => {
    const t = useTranslate();

    const isValid = () => {
        if ( !field ) {
            return true;
        }
        switch ( field.type ) {
            case "ADDRESS":
                return !!type;
            case "DATE":
                return !!format;
            case "PHONE":
                return !!country;
            default:
                return true;
        }
    };

    const legend = () => {
        if ( !field ) {
            return "";
        }
        switch ( field.type ) {
            case "ADDRESS":
                return type && t( "form:address.types." + type );
            case "DATE":
                return format;
            case "PHONE":
                return country;
            default:
                return "";
        }
    };
    const [ , drop ] = useDrop(() => ({
        accept: DRAG_SOURCE_TYPE,
        drop: () => ({ order })
    }));


    return (
        <div ref={ drop } className={ classnames( cssUtils.marginSmall, flex.container ) }>
            <Card className={ flex.fill }>
                <CardHeader
                    title={ header }
                    subheader={
                        line ?
                            line :
                            <i>{ t( "importation:new-importation.empty-value" ) }</i>
                    }
                />
            </Card>
            <Card className={ flex.fill }>
                <CardContent
                    className={ classnames(
                        !field ? css.mappingField : "",
                        flex.container,
                        flex.alignItemsCenter
                    )}
                >
                    { field?.form === "PERSON" && <PersonIcon className={ cssUtils.marginRight }/> }
                    { field?.form === "COMPANY" && <BusinessIcon className={ cssUtils.marginRight }/> }
                    { field?.form === "OPPORTUNITY"
                    && <AttachMoneyIcon className={ cssUtils.marginRight }/> }

                    <div className={ flex.fill }>
                        {
                            field ?
                                ( field.name + ( field.options && field.options.required ? " *" : "" ) ) :
                                t( "importation:new-importation.drag-here-field" )
                        }

                        {
                            legend() &&
                                    <Fragment>
                                        <br/>
                                        <span className={ css.mappingField }> { legend() } </span>
                                    </Fragment>
                        }
                    </div>
                    {
                        !isValid() &&
                                <Tooltip title={ t( "importation:new-importation.mapping-invalid" ) }>
                                    <WarningIcon className={ css.mappingFieldInvalid }/>
                                </Tooltip>
                    }
                    {
                        !!field && field?.type.match( "DATE|PHONE|ADDRESS" ) ?
                            <IconButton onClick={ () => onClick( order ) } size="large">
                                <EditIcon/>
                            </IconButton> :
                            null
                    }
                    {
                        <Tooltip title={ t( "common:exclude" ) }>
                            <IconButton
                                onClick={ () => onDelete( order ) }
                                disabled={ !field }
                                size="large">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </CardContent>
            </Card>
        </div>
    );

};

export default MappingField;