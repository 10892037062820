export const FETCH_PLANS = "x/FETCH_PLANS";
export const fetchPlans = () => ({
    type: FETCH_PLANS
});

export const REQUEST_PLANS = "x/REQUEST_PLANS";
export const requestPlans = () => ({
    type: REQUEST_PLANS
});

export const RECEIVE_PLANS = "x/RECEIVE_PLANS";
export const receivePlans = plans => ({
    type: RECEIVE_PLANS,
    payload: plans
});

export const ERROR_PLANS = "x/ERROR_PLANS";
export const errorPlans = error => ({
    type: ERROR_PLANS,
    payload: error,
    error: true
});