import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import usePriceFormat from "../hooks/use-price-format.hook";
import { getTemperatureProps } from "../opportunity/opportunity.utils";
import { Table } from "../ui/table";
import { fetchOpportunities, openNewOpportunity } from "./customer.actions";
import { fetchFunnels } from "../funnel/funnel.actions";
import { isLoadingOpportunities, listOpenOpportunities } from "./customer.selectors";
import { listAll } from "../funnel/funnel.selectors";
import NewOpportunity from "./new-opportunity.component";

const OpportunitiesPanel = ({ customerId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    const priceFormat = usePriceFormat();

    const loading = useSelector( isLoadingOpportunities );
    const opportunities = useSelector( listOpenOpportunities );
    const funnels = useSelector( listAll );

    const tableModel = useMemo( () => ({
        title: t( "opportunity:title" ),
        funnel: t( "opportunity:funnel" ),
        phase: t( "opportunity:phase" ),
        price: {
            title: t( "opportunity:price.label" ),
            format: priceFormat
        },
        recurrentPrice: {
            title: t( "opportunity:recurrent-price" ),
            format: priceFormat
        },
        nextScheduling: {
            title: t( "opportunity:next-scheduling" ),
            format: dateFormat
        },
        temperature: {
            title: " ",
            size: "small"
        },
    }), [ t, dateFormat, priceFormat ] );
    const tableSource = useMemo( () => {
        if ( isEmpty( funnels ) ) {
            return [];
        }
        return opportunities.map( opportunity => ({
            title: opportunity.title,
            funnel: funnels.find( funnel => funnel.id === opportunity.funnelId ).name,
            phase: opportunity.phase.name,
            temperature: getTemperatureProps( opportunity.temperature ),
            price: opportunity.price,
            recurrentPrice: opportunity.recurrentPrice,
            nextScheduling: opportunity.nextScheduling
        }));
    }, [ funnels, opportunities ] );

    const handleOpenNewOpportunity = () => dispatch( openNewOpportunity() );

    useEffect( () => {
        dispatch( fetchFunnels() );
    }, [ dispatch ] );
    useEffect( () => {
        dispatch( fetchOpportunities( customerId ) );
    }, [ dispatch, customerId ] );

    const rowLink = index => opportunities && opportunities[ index ] && `/opportunities/${opportunities[ index ].id}`;

    return (
        <>
            <Table
                model={ tableModel }
                source={ tableSource }
                onAdd={ handleOpenNewOpportunity }
                emptyMessage={ t( "customer:opportunities-panel.empty" ) }
                rowLink={ rowLink }
                isLoading={ loading }
            />

            <NewOpportunity/>
        </>
    );
};

export default OpportunitiesPanel;
