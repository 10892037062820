import FormControlLabel from "@mui/material/FormControlLabel";
import { Field } from "formik";
import classnames from "classnames";

import { cssUtils, flex, Switch, Input } from "../../../ui";
import css from "./funnel.scss";

const PhaseAutomationActivityRegistered = ({ onChange, checked, formControlLabel, formLabel, formName, className }) => {
    return (
        <div className={ classnames( css.phaseAutomationActivityItem, className ) }>
            <div className={ flex.fill }>
                <FormControlLabel
                    label={ formControlLabel }
                    control={
                        <Switch
                            checked={ checked }
                            onChange={ onChange }
                            size="small"
                            fullWidth={ false }
                        />
                    }
                />
            </div>
            <div className={ flex.item50 }>
                {
                    checked &&
                        <Field
                            name={ formName }
                            component={ Input }
                            type="number"
                            label={ formLabel }
                            className={ cssUtils.margin0 }
                            fullWidth={ false }
                            required
                        />
                }
            </div>
        </div>
    );
};

export default PhaseAutomationActivityRegistered;