import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils } from "../../ui";

const icons = {
    OPEN: ReplayIcon,
    SOLD: ThumbUpIcon,
    LOST: ThumbDownIcon
};

const StatusEvent = ({ event: { user, payload: { current, previous }, date } }) => {
    const t = useTranslate();
    const dateTimeFormat = useDateTimeFormat( t );

    const Icon = icons[ current.status ];
    return (
        <TimelineItem icon={ <Icon/> }>
            <TimelineSummary
                user={ user }
                description={ t( "opportunity:timeline.opportunity-status" ) }
                date={ date } />

            <div>
                <Typography>
                    <mark className={ cssUtils.highlightOld }>
                        { t( "opportunity:status." + previous.status ) }
                    </mark>
                    { "→ " }
                    <mark className={ cssUtils.highlightNew }>
                        { t( "opportunity:status." + current.status ) }
                    </mark>
                </Typography>
                {
                    current.soldAt &&
                        <Typography variant="caption">
                            { `${t( "opportunity:sold-at" )}: ${dateTimeFormat( current.soldAt )}` }
                        </Typography>
                }
                {
                    current.lossReasonName &&
                        <Typography variant="caption">
                            {
                                t( "opportunity:loss-reason" ) +
                                ": " +
                                current.lossReasonName
                            }
                            { current.lostAt &&
                                <>
                                    <br />
                                    { `${t( "opportunity:lost-at" )}: ${dateTimeFormat( current.lostAt )}` }
                                </>
                            }
                        </Typography>
                }
                {
                    current.lossReasonComplement &&
                        <Typography variant="caption" className={ cssUtils.whiteSpacePreLine }>
                            { current.lossReasonComplement }
                        </Typography>
                }
            </div>
        </TimelineItem>
    );
};

export default StatusEvent;