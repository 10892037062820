export default {
    "dialog": {
        "greeting": "Hola, { user }",
        "text":
            "Bienvenido a LeadStation.<br />" +
            "Para facilitar su viaje a través de nuestro CRM, tenemos una opción para " +
            "agregar automáticamente datos ficticios " +
            "en los registros de Clientes (Empresa/Persona), Productos, Oportunidades, Citas y Flujo/Fases de " +
            "embudo.<br />" +
            "Cuando haya terminado con sus pruebas, puede eliminar todos los datos a la vez. Para eso, solo acceda " +
            "Configuración en la pestaña General en Inscripciones y haga clic en Eliminar datos simulados.",
        "add-samples": "Agregue datos ficticios a LeadStation para realizar pruebas"
    },
    "title": "Hola, {userName}!",
    "intro":
        "Bienvenido a LeadStation!\n lo guiaremos en los primeros pasos con el sistema.\n" +
        "Haga clic en una de las funciones que se indican a continuación y comencemos.",
    "add-users": {
        "title": "Invitar a usuarios",
        "description": "Envíe invitaciones a sus compañeros de trabajo."
    },
    "add-teams": {
        "title": "Registre a sus equipos",
        "description": "Configure sus equipos."
    }
};