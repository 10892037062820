import { Field } from "formik";
import { useEffect, useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";

import usePrevious from "../hooks/use-previous.hook";
import useTranslate from "../hooks/use-translate.hook";
import { Checkbox, InputFile, cssUtils } from "../ui";

const FileConfig = ({
    form,
    onChange,
    personFields,
    companyFields,
    opportunityFields,
}) => {
    const t = useTranslate();

    const [ fileExceedLines, setFileExceedLines ] = useState( false );
    const [ lines, setLines ] = useState( [] );

    const closeWarningExceedLines = () => setFileExceedLines( false );

    const refreshHeaders = useCallback( firstRowHeader => {
        if ( isEmpty( lines ) ) {
            return;
        }
        let headers;
        let index = 0;
        if ( firstRowHeader ) {
            headers = lines[ 0 ];
            index = 1;
        }
        const values = lines[ index ];
        onChange( data => values.map( ( value, index ) => ({
            ...data[ index ],
            value,
            header: headers && headers[ index ],
        })));
    }, [ lines, onChange ] );

    const readFile = file => {
        if ( !file ) {
            return;
        }

        const extension = file.name.substring( file.name.lastIndexOf( "." ) + 1 );

        const reader = new FileReader();
        reader.onload = event => {
            if ( !event.target.result ) {
                return;
            }

            const result = event.target.result;
            const wb = XLSX.read( result, { type: "binary" } );
            const wsname = wb.SheetNames[ 0 ];
            const ws = wb.Sheets[ wsname ];
            const lines = XLSX.utils.sheet_to_row_object_array(
                ws,
                { header: 1, defval: "", raw: false, blankrows: false }
            );

            if ( lines.length > 1000 ) {
                setFileExceedLines( true );
                form.setFieldValue( "file", null );
                return;
            }

            let headers;
            let lineValues = [];
            if ( form.values.firstRowHeader && lines.length > 0 ) {
                headers = lines[ 0 ];
                lineValues = lines[ 1 ];
            } else {
                lineValues = lines[ 0 ];
            }

            const mapping = lineValues.map( ( line, index ) => {
                if ( !headers ) {
                    return { line };
                }

                const header = headers[ index ] || "";

                const commonProps = { line, header };

                let personField = personFields.find( f => f.name.toLowerCase() === header.toLowerCase() );
                if ( personField ) {
                    return ({ ...commonProps, field: personField });
                }

                let companyField = companyFields.find( f => f.name.toLowerCase() === header.toLowerCase() );
                if ( companyField ) {
                    return ({ ...commonProps, field: companyField });
                }

                let opportunityField;
                if ( form.values.createOpportunity ) {
                    opportunityField = opportunityFields.find( f => f.name.toLowerCase() === header.toLowerCase() );
                    if ( opportunityField ) {
                        return ({ ...commonProps, field: opportunityField });
                    }
                }

                const names = header.split( " " );
                for ( let i = 0; i < names.length; i++ ) {
                    const name = names[ i ].toLowerCase();

                    personField = personFields.find( f => f.name.toLowerCase().split( " " ).includes( name ) );
                    if ( personField ) {
                        return ({ ...commonProps, field: personField });
                    }

                    companyField = companyFields.find( f => f.name.toLowerCase().split( " " ).includes( name ) );
                    if ( companyField ) {
                        return ({ ...commonProps, field: companyField });
                    }

                    if ( form.values.createOpportunity ) {
                        opportunityField = opportunityFields
                            .find( f => f.name.toLowerCase().split( " " ).includes( name ) );
                        if ( opportunityField ) {
                            return ({ ...commonProps, field: opportunityField });
                        }
                    }
                }

                return commonProps;
            });
            form.setFieldValue( "mapping", mapping );

            setLines( lines.slice( 0, 2 ) );
            form.setFieldValue( "totalColumns", lineValues.length );
            form.setFieldValue( "fileLength", lines.length );
        };

        if ( extension === "csv" ) {
            reader.readAsText( file );
        } else {
            reader.readAsBinaryString( file );
        }

        form.setFieldValue( "fileName", file.name );
    };

    const prevFirstRowHeader = usePrevious( form.values.firstRowHeader );

    useEffect( () => {
        if ( prevFirstRowHeader !== form.values.firstRowHeader ) {
            refreshHeaders( form.values.firstRowHeader );
        }
    }, [ prevFirstRowHeader, form.values.firstRowHeader, refreshHeaders ] );

    return (
        <>
            <Field
                name="firstRowHeader"
                type="checkbox"
                label={ t( "importation:importation.first-row-header" ) }
                component={ Checkbox }
                className={ cssUtils.marginBottomSmall }
            />

            <Field
                name="file"
                onChange={ readFile }
                accept=".csv,.xls,.xlsx"
                component={ InputFile }
            />
            <Typography>
                { t( "importation:importation.files-accepted" ) }
            </Typography>

            <Snackbar
                open={ fileExceedLines }
                action={ <Button onClick={ closeWarningExceedLines } color="secondary">{ "OK" }</Button>}
                message={ t( "importation:new-importation.file-exceed-lines" ) }
                autoHideDuration={ 5000 }
                onClose={ closeWarningExceedLines }
            />
        </>
    );
};

export default FileConfig;