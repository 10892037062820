import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../api/sagas";
import * as actions from "./kanban.actions";
import * as selectors from "./kanban.selectors";

export function *watchKanban () {
    yield all([
        takeEvery( actions.FETCH_KANBAN, fetchKanban )
    ]);
}

function *fetchKanban () {
    const previousKanban = yield select( selectors.getKanban );
    if ( previousKanban ) {
        return;
    }

    yield put( actions.requestKanban() );
    try {
        const response = yield call( apiv1.get, "/kanban" );
        yield put( actions.receiveKanban( response.data ) );
    } catch ( e ) {
        yield put( actions.errorKanban( e.response.data ) );
    }
}