export const FETCH_DISTRIBUTIONS = "x/config/advanced/distribution/FETCH_DISTRIBUTION";
export const fetchDistributions = () => ({
    type: FETCH_DISTRIBUTIONS
});

export const REQUEST_DISTRIBUTIONS = "x/config/opportunity/distribution/REQUEST_DISTRIBUTION";
export const requestDistributions = () => ({
    type: REQUEST_DISTRIBUTIONS
});

export const RECEIVE_DISTRIBUTIONS = "x/config/opportunity/distribution/RECEIVE_DISTRIBUTION";
export const receiveDistributions = distributions => ({
    type: RECEIVE_DISTRIBUTIONS,
    payload: distributions
});

export const ERROR_DISTRIBUTIONS = "x/config/opportunity/distribution/ERROR_DISTRIBUTION";
export const errorDistributions = error => ({
    type: ERROR_DISTRIBUTIONS,
    error
});

export const CREATE_DISTRIBUTION = "x/config/advanced/distribution/CREATE_DISTRIBUTION";
export const createDistribution = data => ({
    type: CREATE_DISTRIBUTION,
    payload: data,
});

export const SAVE_DISTRIBUTION = "x/config/advanced/distribution/SAVE_DISTRIBUTION";
export const saveDistribution = ({ id, ...data }) => ({
    type: SAVE_DISTRIBUTION,
    meta: { id },
    payload: data,
});

export const DELETE_DISTRIBUTION = "x/config/opportunity/distribution/DELETE_DISTRIBUTION";
export const deleteDistribution = ({ id }, payload) => ({
    type: DELETE_DISTRIBUTION,
    meta: { id },
    payload,
});