import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { cssUtils } from "../../ui";
import css from "../../ui/timeline/timeline.scss";
import { getSchedulingTypes } from "../opportunity.selectors";

const getFieldTranslate = ( t, key ) => {
    switch ( key ) {
        case "typeId":
            return t( "opportunity:scheduling.type" );
        case "notAccomplishedReason":
            return t( "opportunity:scheduling.change-not-accomplished-reason" );

        case "userName":
            return t( "opportunity:scheduling.user" );
        default:
            return t( "opportunity:scheduling." + key );
    }
};


const getValue = ( t, dateFormat, types, key, value ) => {
    switch ( key ) {
        case "date":
            return dateFormat( value );
        case "typeId":
            return ( types.find( type => type.id === value ) || {} ).name;
        default:
            return value;
    }
};

const SchedulingChangeEvent = ({ event: { user, payload, date } }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const types = useSelector( getSchedulingTypes );

    return (
        <TimelineItem icon={ <EditIcon/> }>
            <TimelineSummary
                user={ user }
                description={
                    t(
                        "opportunity:timeline.opportnity-scheduling-change",
                        {
                            type: payload.typeName,
                            date: dateFormat( payload.date ),
                            interpolation: { escapeValue: false }
                        }
                    )
                }
                date={ date } />

            {
                Object.keys( payload.change.previous ).map( key => (
                    <div key={ key } className={ css.change } >
                        <Typography>
                            { getFieldTranslate( t, key ) }{ ": " }
                            <Typography component="span" className={ cssUtils.marginLeftSmall }>
                                <mark className={ cssUtils.highlightOld }>
                                    { getValue( t, dateFormat, types, key, payload.change.previous[ key ] ) }
                                </mark>
                                {"→ "}
                                <mark className={ cssUtils.highlightNew }>
                                    { getValue( t, dateFormat, types, key, payload.change.current[ key ] ) }
                                </mark>
                            </Typography>
                        </Typography>
                    </div>
                ))
            }
            {
                Object.keys( payload.change.current ).filter( key => !payload.change.previous[ key ] ).map( key => (
                    <div key={ key } className={ css.change } >
                        <Typography>
                            { getFieldTranslate( t, key ) + ": " }
                            <Typography component="span" className={ cssUtils.marginLeftSmall }>
                                <mark className={ cssUtils.highlightNew }>
                                    { getValue( t, dateFormat, types, key, payload.change.current[ key ] ) }
                                </mark>
                            </Typography>
                        </Typography>
                    </div>
                ))
            }

        </TimelineItem>
    );
};

export default SchedulingChangeEvent;