import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import ApiIcon from "@mui/icons-material/Api";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { useNavigate } from "react-router";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchApiKeys } from "./api-key/api-key.actions";
import { fetchLeadSources } from "./lead/lead-integration.actions";
import { fetchFacebookLeadPages } from "./facebook/facebook-integration.actions";
import { fetchPortals } from "./portal/portal-integration.actions";
import { getApiKeys, isLoadingApiKeys } from "./api-key/api-key.selectors";
import { getFacebookLeadPages, isFacebookLoadingLeadPages } from "./facebook/facebook-integration.selectors";
import { getPortals, isLoadingPortals } from "./portal/portal-integration.selectors";
import { getLeadSources, isLoadingLeadSources } from "./lead/lead-integration.selectors";
import MarketplaceItem from "../marketplace-item.component";
import css from "../marketplace.scss";

const Integration = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const t = useTranslate();

    const loadingLeadSources = useSelector( isLoadingLeadSources );
    const loadingFacebookLeadPages = useSelector( isFacebookLoadingLeadPages );
    const loadingPortals = useSelector( isLoadingPortals );
    const loadingApiKeys = useSelector( isLoadingApiKeys );
    const leadSources = useSelector( getLeadSources );
    const facebookLeadPages = useSelector( getFacebookLeadPages );
    const portals = useSelector( getPortals );
    const apiKeys = useSelector( getApiKeys );

    const loading = loadingLeadSources || loadingFacebookLeadPages || loadingPortals || loadingApiKeys;

    const filterPortal = useCallback( type => portals.filter( portal => portal.type === type ), [ portals ] );

    const integrations = useMemo( () => [
        {
            id: "webCapture",
            title: t( "marketplace:integrations.lead.title" ),
            expired: false,
            quantity: leadSources.length,
        },
        {
            id: "facebook",
            title: t( "marketplace:integrations.facebook.title" ),
            quantity: facebookLeadPages.length,
            expired: facebookLeadPages.some( page => page.expireAt < new Date() ),
            className: css.facebookIcon
        },
        {
            id: "icarros",
            title: t( "marketplace:integrations.icarros.title" ),
            quantity: filterPortal( "ICARROS" ).length,
            expired: filterPortal( "ICARROS" ).some( portal => portal.credentials.expired ),
            className: css.icarrosIcon
        },
        {
            id: "mercadolibre",
            title: t( "marketplace:integrations.mercadolibre.title" ),
            quantity: filterPortal( "MERCADO_LIBRE" ).length,
            expired: filterPortal( "MERCADO_LIBRE" ).some( portal => portal.credentials.expired ),
            className: css.mercadoLibreIcon
        },
        {
            id: "mobiauto",
            title: t( "marketplace:integrations.portals.MOBIAUTO.title" ),
            quantity: filterPortal( "MOBIAUTO" ).length,
            expired: filterPortal( "MOBIAUTO" ).some( portal => portal.credentials.expired ),
            className: css.mobiautoIcon
        },
        {
            id: "olx",
            title: t( "marketplace:integrations.olx.title" ),
            quantity: filterPortal( "OLX" ).length,
            expired: filterPortal( "OLX" ).some( portal => portal.credentials.expired ),
            className: css.olxIcon
        },
        {
            id: "usadosbr",
            title: t( "marketplace:integrations.portals.USADOSBR.title" ),
            quantity: filterPortal( "USADOSBR" ).length,
            expired: filterPortal( "USADOSBR" ).some( portal => portal.credentials.expired ),
            className: css.usadosbrIcon
        },
        {
            id: "webmotors",
            title: t( "marketplace:integrations.webmotors.title" ),
            quantity: filterPortal( "WEBMOTORS" ).length,
            expired: filterPortal( "WEBMOTORS" ).some( portal => portal.credentials.expired ),
            className: css.webmotorsIcon
        },
        {
            id: "apiKeys",
            title: t( "marketplace:integrations.api.title" ),
            quantity: apiKeys.length,
            expired: apiKeys.some( api => !api.key ),
            className: css.apiIcon
        }
    ], [ t, leadSources, facebookLeadPages, filterPortal, apiKeys ] );

    const handleClickIntegration = useCallback( id => () => {
        const isPortal = [ "icarros", "mercadolibre", "mobiauto", "olx", "usadosbr", "webmotors" ].includes( id );
        let route = "integrations/";

        if ( id === "webCapture" ) {
            route += "web-capture";
        } else if ( id === "apiKeys" ) {
            route += "apikeys";
        } else if ( isPortal ) {
            route += "portals/" + id;
        } else {
            route += id;
        }
        navigate( route );
    }, [ navigate ] );

    const renderIntegrationIcon = useCallback( integration => {
        switch ( integration.id ) {
            case "webCapture":
                return <DynamicFeedIcon style={{ fontSize: 50 }}/>;
            case "apiKeys":
                return <ApiIcon style={{ fontSize: 50 }}/>;
            default:
                return <div className={ integration.className }/>;
        }
    }, [] );

    useEffect( () => {
        dispatch( fetchLeadSources() );
        dispatch( fetchFacebookLeadPages() );
        dispatch( fetchPortals() );
        dispatch( fetchApiKeys() );
    }, [ dispatch ] );

    return (
        <div className={ className }>
            <CardHeader
                className={ css.sectionTitle }
                title={ t( "marketplace:integrations.title" ) }
                subheader={ t( "marketplace:integrations.subtitle" ) }
            />
            <Grid container spacing={ 2 } id="cardContainerIntegration">
                {
                    integrations.map( integration => (
                        <Grid key={ integration.id } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                            <MarketplaceItem
                                onClick={ handleClickIntegration( integration.id ) }
                                loading={ loading }
                                expired={ integration.expired }
                                quantity={ integration.quantity }
                                title={ integration.title }
                            >
                                { renderIntegrationIcon( integration ) }
                            </MarketplaceItem>
                        </Grid>
                    ) )
                }
            </Grid>
        </div>
    );
};

export default Integration;