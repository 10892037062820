export const FETCH_SMS_STATISTICS = "x/marketplace/FETCH_SMS_STATISTICS";
export const fetchSmsStatistics = () => ({
    type: FETCH_SMS_STATISTICS
});

export const REQUEST_SMS_STATISTICS = "x/marketplace/REQUEST_SMS_STATISTICS";
export const requestSmsStatistics = () => ({
    type: REQUEST_SMS_STATISTICS
});

export const RECEIVE_SMS_STATISTICS = "x/marketplace/RECEIVE_SMS_STATISTICS";
export const receiveSmsStatistics = data => ({
    type: RECEIVE_SMS_STATISTICS,
    payload: data
});

export const ERROR_SMS_STATISTICS = "x/marketplace/ERROR_SMS_STATISTICS";
export const errorSmsStatistics = error => ({
    type: ERROR_SMS_STATISTICS,
    payload: error,
    error: true
});