import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "formik-redux";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";

import { cssUtils, flex } from "../ui";
import useTranslate from "../hooks/use-translate.hook";
import { saveTemplate } from "./user.actions";
import { USER_TEMPLATE } from "./user.constants";
import TemplateBodyTester from "../config/opportunity/template/template-body-tester.component";
import { variables } from "../ui/template.utils";
import TemplateForm from "./template-form.component";
import css from "./user.scss";

const Template = ({ open, onClose, template }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ showBodyTester, setShowBodyTester ] = useState( false );
    const toggleTestSubmission = () => setShowBodyTester( !showBodyTester );

    const initialValues = useMemo( () => {
        if ( !template ) {
            return {
                name: "",
                type: "EMAIL",
                body: "",
                share: false,
            };
        }
        let body = template.body;
        if ( template.type !== "EMAIL" ) {
            variables.forEach( variable => {
                body = body.replace( variable.value, `#(${variable.value})` );
            });
        }

        return {
            ...template,
            body,
        };
    }, [ template ] );

    const formik = useForm({
        enableReinitialize: true,
        form: USER_TEMPLATE,
        initialValues,
        onSubmit: values => {
            let body = values.body;
            if ( values.type !== "EMAIL" ) {
                variables.forEach( variable => {
                    body = body.replace( `#(${variable.value})`, variable.value );
                });
            }

            dispatch( saveTemplate({ ...values, body }) );
        },
        onSubmitSuccess: onClose,
        validate: values => {
            const errors = {};
            if ( !values.name ) {
                errors.name = "common:validation.required";
            }
            if ( !values.type ) {
                errors.type = "common:validation.selection";
            }
            return errors;
        },
    });

    return (
        <Dialog open={ open } disableEnforceFocus fullWidth fullScreen>
            <AppBar position="absolute" classes={{ root: css.newTemplateBar }}>
                <Toolbar>
                    <Tooltip title={ t( "config:template.close" ) }>
                        <IconButton color="inherit" onClick={ onClose } disabled={ formik.submitting } size="large">
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={ classnames( flex.fill, cssUtils.marginLeft ) }
                    >
                        {
                            template && template.id ?
                                t( "config:templates.edit" ) :
                                t( "config:templates.new" )
                        }
                    </Typography>
                    { formik.values.type === "EMAIL" &&
                        formik.values.body &&
                        formik.values.body !== "<p></p>" &&
                            <Button
                                color="inherit"
                                disabled={ showBodyTester }
                                onClick={ toggleTestSubmission }
                            >
                                { t( "config:templates.test" ) }
                            </Button>
                    }
                    <Button color="inherit" onClick={ formik.submitForm } disabled={ formik.submitting }>
                        { t( "config:template.save" ) }
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <TemplateForm formik={ formik } />
            </DialogContent>

            <TemplateBodyTester
                show={ showBodyTester }
                body={ formik.values.body }
                onClose={ toggleTestSubmission }
            />

        </Dialog>
    );
};

export default Template;