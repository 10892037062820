import Avatar from "@mui/material/Avatar";

import useTranslate from "../hooks/use-translate.hook";
import { cssUtils } from "../ui";
import css from "./opportunity.scss";

const temperaturesClassName = {
    COLD: {
        label: css.labelColdTemperature,
        icon: css.iconColdTemperature
    },
    WARM: {
        label: css.labelWarmTemperature,
        icon: css.iconWarmTemperature
    },
    HOT: {
        label: css.labelHotTemperature,
        icon: css.iconHotTemperature
    }
};

const OpportunityTemperatureItem = ({ selected, icon, value, onClick }) => {
    const t = useTranslate();

    const handleClick = () => onClick( value );

    return (
        <div className={ css.temperatureContainer } onClick={ handleClick } >
            <Avatar
                className={
                    selected ?
                        selected === value ? temperaturesClassName[ value ].icon : css.iconTemperatureDisabled
                        : ""
                }
            >
                { icon }
            </Avatar>
            <div className={ cssUtils.marginTopSmall }>
                <span
                    className={
                        selected ?
                            selected === value ? temperaturesClassName[ value ].label : css.labelTemperatureDisabled
                            : css.temperatureLabel
                    }
                >
                    { t( "opportunity:temperatures." + value ) }
                </span>
            </div>
        </div>
    );
};

export default OpportunityTemperatureItem;