import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, select, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { actions as lossReasonActions, selectors as lossReasonSelectors } from "../../../loss-reason";
import * as actions from "./loss-reason.actions";
import { LOSS_REASON_FORM } from "./loss-reason.constants";

export function *watchConfigOpportunityLossReason () {
    yield all([
        takeEvery( actions.SAVE_LOSS_REASONS, saveLossReasons ),
        takeEvery( actions.DELETE_LOSS_REASON, deleteLossReason )
    ]);
}

export function *saveLossReasons ( action ) {
    const { lossReasons } = action.payload;
    const newLossReasons = [];

    try {
        yield put( startSubmit( LOSS_REASON_FORM ) );

        for ( const lossReason of lossReasons ) {
            let response;
            if ( lossReason.id ) {
                response = yield call( apiv1.patch, "/lossreasons/" + lossReason.id, lossReason );
            } else {
                response = yield call( apiv1.post, "/lossreasons", lossReason );
            }

            newLossReasons.push( response.data );
        }

        newLossReasons.sort( ( o1, o2 ) => o1.name < o2.name ? -1 : o1.name > o2.name ? 1 : 0 );
        yield put( stopSubmit( LOSS_REASON_FORM ) );
        yield put( lossReasonActions.receiveLossReasons( newLossReasons ) );
    } catch ( e ) {
        yield put( stopSubmit( LOSS_REASON_FORM, e.response.data ) );
    }
}

export function *deleteLossReason ({ payload }) {
    yield put( startSubmit( LOSS_REASON_FORM ) );
    const { lossReason } = payload;

    const lossReasons = yield select( lossReasonSelectors.getLossReasons );

    try {
        yield call( apiv1.delete, "/lossReasons/" + lossReason.id );

        const filteredLossReasons = lossReasons.filter( item => item.id !== lossReason.id );
        yield put( lossReasonActions.receiveLossReasons( filteredLossReasons ) );
        yield put( stopSubmit( LOSS_REASON_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( LOSS_REASON_FORM, e.response.data ) );
    }
}