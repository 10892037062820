import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import usePrevious from "../../hooks/use-previous.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { actions as fileActions, selectors as fileSelectors } from "../../file";
import { Outlined, PreviewFile, cssUtils } from "../../ui";
import { Table } from "../../ui/table";

const ProposalDetails = ({ proposal }) => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const priceFormat = usePriceFormat();

    const fileModelId = useMemo( () => proposal.fileModelId, [ proposal.fileModelId ] );

    const loading = useSelector( fileSelectors.isLoadingSections( fileModelId ) );
    const sections = useSelector( fileSelectors.getModelSections( fileModelId ) );

    const [ showPreviewFile, setShowPreviewFile ] = useState( false );
    const [ showSelectSections, setShowSelectSections ] = useState( false );
    const [ sectionsId, setSectionsId ] = useState( [] );

    const handleChangeSections = sectionId => () => {
        setSectionsId( sectionsId.includes( sectionId ) ?
            sectionsId.filter( id => id !== sectionId ) :
            [ ...sectionsId, sectionId ]
        );
    };

    const togglePreviewFile = () => setShowPreviewFile( !showPreviewFile );

    const toggleSelectSections = () => setShowSelectSections( !showSelectSections );

    const isSelected = sectionId => sectionsId.includes( sectionId );

    const tableModel = useMemo( () => ({
        product: t( "opportunity:proposal-product.product" ),
        quantity: t( "opportunity:proposal-product.quantity" ),
        discount: t( "opportunity:proposal-product.discount" ),
        recurrent: t( "opportunity:proposal-product.recurrent" ),
        totalPrice: {
            title: t( "opportunity:proposal-product.total" ),
            format: priceFormat
        }
    }), [ t, priceFormat ] );
    const tableSource = useMemo( () => ( proposal.products || [] ).map( proposalProduct => ({
        ...proposalProduct,
        discount: new Intl
            .NumberFormat( t( "common:full-locale" ), { maximumFractionDigits: 2 } )
            .format( proposalProduct.discount || 0 ) + "%",
        recurrent: proposalProduct.product.recurrent ? t( "common:yes" ) : t( "common:no" ),
        product: proposalProduct.product.description
    })), [ t, proposal ] );
    const footnote = useMemo( () => [
        t( "opportunity:proposal.products.total",
            {
                total: priceFormat( proposal.products
                    .filter( proposalProduct => !proposalProduct.product.recurrent )
                    .reduce( ( model, proposalProduct ) => model + ( proposalProduct.totalPrice || 0 ), 0 )
                ),
                recurrent: priceFormat( proposal.products
                    .filter( proposalProduct => proposalProduct.product.recurrent )
                    .reduce( ( model, proposalProduct ) => model + ( proposalProduct.totalPrice || 0 ), 0 )
                )
            }
        )
    ], [ t, priceFormat, proposal ] );

    const prevLoading = usePrevious( loading );
    const prevShowSelectSections = usePrevious( showSelectSections );

    useEffect( () => {
        fileModelId && dispatch( fileActions.fetchModelSections( fileModelId ) );
    }, [ dispatch, fileModelId ] );
    useEffect( () => {
        if ( ( prevLoading && !loading ) || ( prevShowSelectSections && !showSelectSections ) ) {
            setSectionsId( sections.map( section => section.id ) );
        }
    }, [ prevLoading, loading, sections, prevShowSelectSections, showSelectSections ] );

    return (
        <>
            <PreviewFile
                open={ showPreviewFile }
                contentType={ "pdf" }
                filename={ proposal.name.concat( ".pdf" ) }
                url={
                    `/api/v1/proposals/${proposal.id}/download` +
                    ( sections?.length > 1 ? `?sectionsId=${sectionsId}` : "" )
                }
                onClose={ togglePreviewFile }
            />
            <Outlined label={ t( "opportunity:proposal.products.label" ) }>
                <Table
                    model={ tableModel }
                    source={ tableSource }
                    emptyMessage={ t( "opportunity:proposal.products.empty" ) }
                    footnote={ footnote }
                    shadow={ false }
                />
            </Outlined>
            <ListItemButton
                component={ "a" }
                onClick={ sections?.length > 1 ? toggleSelectSections : togglePreviewFile }
                disabled={ loading }
                className={ cssUtils.marginTopSmall }
            >
                { loading && <LinearProgress/> }
                <ListItemIcon>
                    <CloudDownloadIcon/>
                </ListItemIcon>
                <ListItemText primary={ t( "opportunity:proposal.download" ) }/>
            </ListItemButton>
            <Dialog
                open={ showSelectSections }
                onClose={ toggleSelectSections }
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{ t( "opportunity:proposal.download" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "opportunity:proposal.select-sections-for-download" ) }
                    </DialogContentText>
                    <List disablePadding>
                        {
                            sections?.map( section => (
                                <ListItemButton
                                    key={ section.id }
                                    onClick={ handleChangeSections( section.id ) }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={ isSelected( section.id ) } />
                                    </ListItemIcon>
                                    <ListItemText primary={ section.name }/>
                                </ListItemButton>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleSelectSections }>
                        { t( "common:cancel" ) }
                    </Button>
                    <Button
                        color="primary"
                        onClick={ togglePreviewFile }
                        disabled={ isEmpty( sectionsId ) }
                    >
                        { t( "common:download" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProposalDetails;