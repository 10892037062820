import { useEffect } from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { fetchSchedules } from "./opportunity.actions";
import { getSchedules, isLoadingSchedules } from "./opportunity.selectors";
import { SchedulingItem } from "./scheduling";
import { EmptyState } from "../ui";
import css from "./opportunity.scss";

const OpportunitySchedules = ({ opportunityId }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const schedules = useSelector( getSchedules );
    const loading = useSelector( isLoadingSchedules );

    useEffect( () => {
        dispatch( fetchSchedules( opportunityId ) );
    }, [ dispatch, opportunityId ] );

    return (
        <div className={ css.containerContent }>
            <div className={ css.content }>
                {
                    loading ?
                        <CircularProgress/> :
                        isEmpty( schedules ) && !loading ?
                            <EmptyState icon={ EventBusyIcon } message={ t( "opportunity:schedules-empty" ) } /> :
                            <Card>
                                <List>
                                    {
                                        schedules.map( ( scheduling, index ) => (
                                            <SchedulingItem
                                                key={ index }
                                                last={ ( index + 1 ) === schedules.length }
                                                scheduling={ scheduling }
                                            />
                                        ))
                                    }
                                </List>
                            </Card>
                }
            </div>
        </div>
    );
};

export default OpportunitySchedules;