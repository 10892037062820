import { all, call, put, takeEvery, select } from "redux-saga/effects";

import { apiv1, apiv2 } from "../../api/sagas";
import { selectors as subscriptionSelectors } from "../subscription";
import * as actions from "./form.actions";

export function *watchForm () {
    yield all([
        takeEvery( actions.FETCH_FORM, fetchForm ),
        takeEvery( actions.SET_BRAND, setBrand ),
        takeEvery( actions.FETCH_FORM_FILTERS, fetchFormFilters )
    ]);
}

export function *fetchForm ({ meta: { form } }) {
    yield put( actions.requestForm( form ) );

    try {
        const response = yield call( apiv1.get, `/forms/${form}/fields` );
        yield put( actions.receiveForm( form, response.data ) );
    } catch ( e ) {
        yield put( actions.errorForm( form, e.response.data ) );
    }
}

export function *fetchFormFilters ({ meta: { form } }) {
    yield put( actions.requestFormFilters( form ) );

    try {
        const response = yield call( apiv1.get, `/forms/${form}/fields/filters` );
        yield put( actions.receiveFormFilters( form, response.data ) );
    } catch ( e ) {
        yield put( actions.errorFormFilters( form, e.response.data ) );
    }
}

export function *setBrand ({ payload }) {
    if ( !payload ) {
        return;
    }
    try {
        yield put( actions.requestBrandModels() );
        const subscription = yield select( subscriptionSelectors.getSubscription );
        const params = {
            brandTypes: subscription.segment.options.brandTypes.toString(),
            brandName: payload
        };
        const response = yield call( apiv2.get, "/models", { params } );
        yield put( actions.setBrandModels( response.data ) );
    } catch ( e ) {
        yield put( actions.setBrandModels( [] ) );
    }
}