import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";
import ConfigHeader from "../../config-header.component";
import FileModelList from "./file-model-list.component";
import ImageList from "./image-list.component";
import TemplateList from "./template-list.component";
import { initFileModels } from "./template.actions";

const menus = [
    {
        value: "TEMPLATE",
        title: "config:templates.title"
    }, {
        value: "FILE",
        title: "config:files.title"
    }, {
        value: "IMAGE",
        title: "config:images.title"
    }
];

const ConfigTemplate = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const [ selectedMenu, setSelectedMenu ] = useState( "TEMPLATE" );

    useEffect( () => {
        dispatch( initFileModels() );
    }, [ dispatch ] );

    return (
        <>
            <ConfigHeader title={ t( "config:main.opportunities.templates" ) } />
            <div className={ classnames( flex.alignItemsStart, flex.container ) }>
                <div className={ flex.item25 }>
                    <List>
                        {
                            menus.map( item => (
                                <ListItemButton
                                    key={ item.value }
                                    id={ item.value }
                                    onClick={ () => setSelectedMenu( item.value ) }
                                    button
                                    selected={ selectedMenu === item.value }
                                >
                                    <ListItemText primary={ t( item.title ) } />
                                </ListItemButton>
                            ))
                        }
                    </List>
                </div>

                <div className={ flex.item75 }>
                    { selectedMenu === "TEMPLATE" && <TemplateList/> }
                    { selectedMenu === "FILE" && <FileModelList/> }
                    { selectedMenu === "IMAGE" && <ImageList/> }
                </div>

            </div>
        </>
    );
};

export default ConfigTemplate;