import { Component } from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import MessageIcon from "@mui/icons-material/Message";
import ReplyIcon from "@mui/icons-material/Reply";
import SmsIcon from "@mui/icons-material/Sms";

import useTranslate from "../../hooks/use-translate.hook";
import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import { phoneFormatInternational } from "../../core/formatter.utils";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link } from "../../ui";
import css from "../../ui/timeline/timeline.scss";

const SmsEvent = ({ event }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const phone = phoneFormatInternational( event.payload.phone );

    return (
        <TimelineItem icon={ <SmsIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={
                    event.payload.opportunityId ?
                        <span>
                            { t( "customer:timeline.sms-sent-opportunity", { phone } ) }
                            <Link href={ "/opportunities/" + event.payload.opportunityId }>
                                <a>
                                    { event.payload.opportunityTitle }
                                </a>
                            </Link>
                        </span> :
                        t( "customer:timeline.sms-sent", { phone } )
                }
            />
            <div className={ css.smsContainer }>
                <Chip
                    className={ css.smsSentContainer }
                    avatar={
                        <Tooltip
                            title={
                                t(
                                    "customer:sms.status-info",
                                    { status: t( "customer:sms.status", { status: event.payload.status })}
                                )
                            }
                        >
                            <Avatar classes={{ root: css.smsSentIcon }}><MessageIcon/></Avatar>
                        </Tooltip>
                    }
                    label={ event.payload.message }
                />
                {
                    event.payload.responses.map( ( response, index ) => (
                        <Chip
                            key={ index }
                            className={ css.smsResponseContainer }
                            avatar={
                                <Tooltip
                                    title={
                                        t(
                                            "customer:sms.received-at",
                                            {
                                                date: dateFormat( response.receivedAt ),
                                                interpolation: { escapeValue: false }
                                            }
                                        )
                                    }
                                >
                                    <Avatar classes={{ root: css.smsSentIcon }}><ReplyIcon/></Avatar>
                                </Tooltip>
                            }
                            label={ response.message }
                        />
                    ))
                }
            </div>
        </TimelineItem>
    );
};

export class _SmsEvent extends Component {

    shouldComponentUpdate () {
        return false;
    }

    render () {
        const { t, event, dateFormat } = this.props;
        const phone = phoneFormatInternational( event.payload.phone );

        return (
            <TimelineItem icon={ <SmsIcon/> }>
                <TimelineSummary
                    user={ event.user }
                    date={ event.date }
                    description={
                        event.payload.opportunityId ?
                            <span>
                                { t( "customer:timeline.sms-sent-opportunity", { phone } ) }
                                <Link href={ "/opportunities/" + event.payload.opportunityId }>
                                    <a>
                                        { event.payload.opportunityTitle }
                                    </a>
                                </Link>
                            </span> :
                            t( "customer:timeline.sms-sent", { phone } )
                    }
                />
                <div className={ css.smsContainer }>
                    <Chip
                        className={ css.smsSentContainer }
                        avatar={
                            <Tooltip
                                title={
                                    t(
                                        "customer:sms.status-info",
                                        { status: t( "customer:sms.status", { status: event.payload.status })}
                                    )
                                }
                            >
                                <Avatar classes={{ root: css.smsSentIcon }}><MessageIcon/></Avatar>
                            </Tooltip>
                        }
                        label={ event.payload.message }
                    />
                    {
                        event.payload.responses.map( ( response, index ) => (
                            <Chip
                                key={ index }
                                className={ css.smsResponseContainer }
                                avatar={
                                    <Tooltip
                                        title={
                                            t(
                                                "customer:sms.received-at",
                                                {
                                                    date: dateFormat( response.receivedAt ),
                                                    interpolation: { escapeValue: false }
                                                }
                                            )
                                        }
                                    >
                                        <Avatar classes={{ root: css.smsSentIcon }}><ReplyIcon/></Avatar>
                                    </Tooltip>
                                }
                                label={ response.message }
                            />
                        ))
                    }
                </div>
            </TimelineItem>
        );
    }

}

export default SmsEvent;