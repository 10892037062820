import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import ReplyIcon from "@mui/icons-material/Reply";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { getUser } from "../../core/core.selectors";
import { startNewEmail } from "../customer.actions";
import { TimelineItem, TimelineSummary } from "../../ui/timeline";
import { Link, cssUtils, flex } from "../../ui";
import EmailAttachments from "./email-attachments.component";
import css from "./timeline.scss";

export const mapStateToProps = state => ({
    user: getUser( state )
});

export const createProps = props => {
    const email = props.event.payload;

    return ({
        initialValues: {
            parentId: email.id,
            to: email.to,
            subject: "Re: " + email.subject,
            opportunityId: email.opportunityId
        }
    });
};

const EmailSentEvent = ({ event }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const user = useSelector( getUser );

    const initialValues = useMemo( () => ({
        parentId: event.payload.id,
        to: event.payload.to,
        subject: `Re: ${event.payload.subject}`,
        opportunityId: event.payload.opportunityId
    }), [ event ] );

    const handleReply = useCallback( () => {
        dispatch( startNewEmail( initialValues ) );
    }, [ dispatch, initialValues ] );
    const handleReplyAll = useCallback( () => {
        const values = {
            ...initialValues,
            to: [].concat( event.payload.cc || [] ).concat( event.payload.to )
        };
        dispatch( startNewEmail( values ) );
    }, [ dispatch, initialValues, event ] );

    return (
        <TimelineItem icon={ <MailIcon/> }>
            <TimelineSummary
                user={ event.user }
                date={ event.date }
                description={
                    event.payload.opportunityId ?
                        <span>
                            { t( "customer:timeline.email-sent-opportunity" ) }
                            <Link href={ "/opportunities/" + event.payload.opportunityId }>
                                <a>{ event.payload.opportunityTitle }</a>
                            </Link>
                        </span> :
                        t( "customer:timeline.email-sent" )
                }
            />
            <Card>
                <div className={ flex.container }>
                    <div className={ css.emailReadContainer }>
                        <Tooltip
                            title={
                                event.payload.read ?
                                    t( "customer:timeline.email-read" ) :
                                    t( "customer:timeline.email-not-read" )
                            }
                        >
                            <Avatar>
                                {
                                    event.payload.read ?
                                        <DoneAllIcon className={ css.emailReadIcon }/> :
                                        <DoneIcon/>
                                }
                            </Avatar>
                        </Tooltip>
                    </div>
                    <div className={ flex.fill }>
                        <CardHeader
                            className={ css.emailTitle }
                            subheader={
                                t( "customer:email.to" ) + ": " +
                                event.payload.to.toString().split( "," ).join( ", " )
                            }
                            title={ t( "customer:email.subject" ) + ": " + event.payload.subject }
                        />
                        { event.payload.cc &&
                            <CardHeader
                                className={ css.emailCc }
                                subheader={
                                    t( "customer:email.cc" ) + ": " +
                                    event.payload.cc.toString().split( "," ).join( ", " )
                                }
                            />
                        }
                        <CardHeader
                            className={ css.emailSubject }
                            subheader={ t( "customer:email.from" ) + ": " + event.payload.from }
                        />
                    </div>
                    {
                        user.id === event.user.id &&
                            <div className={ classnames( flex.container, flex.column ) }>
                                {
                                    event.payload.status &&
                                        <Typography
                                            className={ classnames(
                                                cssUtils.textCenter,
                                                cssUtils.marginTopSmall,
                                                cssUtils.marginRightSmall
                                            )}
                                            color="textSecondary"
                                            variant="subtitle2"
                                        >
                                            { t( `customer:email.status.${event.payload.status}` ) }
                                        </Typography>
                                }
                                <div
                                    className={ classnames(
                                        cssUtils.marginRightSmall,
                                        flex.container,
                                        flex.alignItemsCenter,
                                        flex.fill
                                    )}
                                >
                                    <Tooltip title={ t( "customer:new-email.respond" ) }>
                                        <IconButton onClick={ handleReply } size="large">
                                            <ReplyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={ t( "customer:new-email.respond-all" ) }>
                                        <IconButton
                                            onClick={ handleReplyAll }
                                            size="large">
                                            <ReplyAllIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                    }
                </div>
                <Accordion>
                    <AccordionSummary
                        className={ classnames( flex.alignItemsCenter, flex.column ) }
                        expandIcon={ <ExpandMoreIcon/> }
                    >
                        <Typography>
                            { t( "customer:email.show-full-body" ) }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={ classnames( flex.alignItemsCenter, flex.column ) }>
                        <CardContent
                            className={ classnames( cssUtils.fullWidth, flex.alignItemsCenter, flex.column ) }
                        >
                            <div
                                className={ css.emailText }
                                dangerouslySetInnerHTML={{ __html: event.payload.body }}
                            />
                        </CardContent>
                        <EmailAttachments attachments={ event.payload.attachments }/>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </TimelineItem>
    );
};

export default EmailSentEvent;