import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../hooks/use-translate.hook";
import { Link, flex } from "../ui";
import { AddCompany, DeleteCompany } from "./company";
import { AddPerson, DeletePerson } from "./person";
import CustomerSummary from "../customer/customer-summary.component";
import customerCss from "../customer/customer.scss";
import OpportunityPersons from "./opportunity-persons.component";

const OpportunityCustomerHead = ({ opportunity = {}, onEditCompany, onEditPerson }) => {
    const t = useTranslate();

    return (
        <>
            <Card>
                <CardHeader
                    className={ customerCss.headerCompany }
                    classes={{ content: customerCss.headerContent }}
                    avatar={
                        opportunity.company &&
                            <Link to={ "/customers/" + opportunity.company.id }>
                                <Badge
                                    classes={{ badge: customerCss.avatarTypeContainer }}
                                    badgeContent={
                                        <Tooltip title={ t( "customer:company.title" ) }>
                                            <Avatar className={ customerCss.avatarType }>
                                                <BusinessIcon/>
                                            </Avatar>
                                        </Tooltip>
                                    }
                                >
                                    <Avatar src={ opportunity.company.avatar }>
                                        {
                                            opportunity.company.avatar ?
                                                "" :
                                                opportunity.company.name.charAt( 0 ).toUpperCase()
                                        }
                                    </Avatar>
                                </Badge>
                            </Link>
                    }
                    action={
                        opportunity.company ?
                            opportunity.person && <DeleteCompany opportunity={ opportunity }/> :
                            <AddCompany opportunity={ opportunity }/>
                    }
                    title={ opportunity.company ? opportunity.company.name : t( "customer:company.title" ) }
                    titleTypographyProps={{ variant: opportunity.company ? "body2" : "subtitle1" }}
                />

                {
                    opportunity.company && !isEmpty( opportunity.company.tags ) &&
                        <>
                            <div className={ classnames( flex.container, flex.column ) }>
                                <div className={ customerCss.tags }>
                                    {
                                        opportunity.company.tags.map( ( tag, index ) =>
                                            <Chip key={ index } label={ tag }/>
                                        )
                                    }
                                </div>
                            </div>
                            <Divider/>
                        </>
                }

                {
                    opportunity.company &&
                        <>
                            <List dense disablePadding>
                                <CustomerSummary customer={ opportunity.company } alignPopupLeft/>
                            </List>
                            <CardActions>
                                <Button size="small" onClick={ onEditCompany } startIcon={ <EditIcon /> }>
                                    { t( "customer:head.edit" ) }
                                </Button>
                            </CardActions>
                        </>
                }
            </Card>

            <hr/>

            <Card>
                <CardHeader
                    className={ customerCss.headerPerson }
                    classes={{ content: customerCss.headerContent }}
                    avatar={
                        opportunity.person &&
                            <Link to={ "/customers/" + opportunity.person.id }>
                                <Badge
                                    classes={{ badge: customerCss.avatarTypeContainer }}
                                    badgeContent={
                                        <Tooltip title={ t( "customer:person.title" ) }>
                                            <Avatar className={ customerCss.avatarType }>
                                                <PersonIcon/>
                                            </Avatar>
                                        </Tooltip>
                                    }
                                >
                                    <Avatar src={ opportunity.person.avatar }>
                                        {
                                            opportunity.person.avatar ?
                                                "" :
                                                opportunity.person.name.charAt( 0 ).toUpperCase()
                                        }
                                    </Avatar>
                                </Badge>
                            </Link>
                    }
                    action={
                        opportunity.person ?
                            opportunity.company && <DeletePerson opportunity={ opportunity }/> :
                            <AddPerson opportunity={ opportunity }/>
                    }
                    title={ opportunity.person ? opportunity.person.name : t( "customer:person.title" ) }
                    titleTypographyProps={{ variant: opportunity.person ? "body2" : "subtitle1" }}
                />

                {
                    opportunity.person && !isEmpty( opportunity.person.tags ) &&
                        <>
                            <div className={ classnames( flex.container, flex.column ) }>
                                <div className={ customerCss.tags }>
                                    {
                                        opportunity.person.tags.map( ( tag, index ) =>
                                            <Chip key={ index } label={ tag }/>
                                        )
                                    }
                                </div>
                            </div>
                            <Divider/>
                        </>
                }

                {
                    opportunity.person &&
                        <>
                            <List dense disablePadding>
                                <CustomerSummary customer={ opportunity.person } alignPopupLeft/>
                            </List>
                            <CardActions>
                                <Button size="small" onClick={ onEditPerson } startIcon={ <EditIcon /> }>
                                    { t( "customer:head.edit" ) }
                                </Button>
                            </CardActions>
                        </>
                }
            </Card>

            <br />

            { opportunity.id && <OpportunityPersons opportunityId={ opportunity.id }/> }

        </>
    );
};

export default OpportunityCustomerHead;