export const FETCH_SMTP_SERVER_DEFAULT = "x/FETCH_SMTP_SERVER_DEFAULT";
export const fetchSmtpServerDefault = () => ({
    type: FETCH_SMTP_SERVER_DEFAULT
});

export const REQUEST_SMTP_SERVER_DEFAULT = "x/REQUEST_SMTP_SERVER_DEFAULT";
export const requestSmtpServerDefault = () => ({
    type: REQUEST_SMTP_SERVER_DEFAULT
});

export const RECEIVE_SMTP_SERVER_DEFAULT = "x/RECEIVE_SMTP_SERVER_DEFAULT";
export const receiveSmtpServerDefault = data => ({
    type: RECEIVE_SMTP_SERVER_DEFAULT,
    payload: data
});

export const ERROR_SMTP_SERVER_DEFAULT = "x/ERROR_SMTP_SERVER_DEFAULT";
export const errorSmtpServerDefault = error => ({
    type: ERROR_SMTP_SERVER_DEFAULT,
    payload: error,
    error: true
});

export const FETCH_SMTP_SERVER = "x/FETCH_SMTP_SERVER";
export const fetchSmtpServer = () => ({
    type: FETCH_SMTP_SERVER
});

export const REQUEST_SMTP_SERVER = "x/REQUEST_SMTP_SERVER";
export const requestSmtpServer = () => ({
    type: REQUEST_SMTP_SERVER
});

export const RECEIVE_SMTP_SERVER = "x/RECEIVE_SMTP_SERVER";
export const receiveSmtpServer = data => ({
    type: RECEIVE_SMTP_SERVER,
    payload: data
});

export const ERROR_SMTP_SERVER = "x/ERROR_SMTP_SERVER";
export const errorSmtpServer = data => ({
    type: ERROR_SMTP_SERVER,
    payload: data,
    error: true
});