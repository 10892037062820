import { all } from "redux-saga/effects";

import * as planSagas from "./plan/plan.sagas";
import * as mainSagas from "./main/main.sagas";

export function *watchConfigSubscription () {
    yield all([
        planSagas.watchConfigSubscriptionPlan(),
        mainSagas.watchConfigSubscriptionMain()
    ]);
}