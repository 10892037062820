import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import get from "lodash/get";
import set from "lodash/set";

import useTranslate from "../../hooks/use-translate.hook";
import { getPreferences } from "../../core/core.selectors";
import { saveUserPreferences } from "../../user/user.actions";
import { Switch, cssUtils } from "../../ui";

const LivePhases = () => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const preferences = useSelector( getPreferences );

    const checked = useMemo( () => get( preferences, "opportunity.liveKanban", false ), [ preferences ] );
    const handleChange = useCallback( value => {
        const newPreferences = ( set({ ...preferences }, "opportunity.liveKanban", value ) );
        dispatch( saveUserPreferences( newPreferences ) );
    }, [ dispatch, preferences ]);

    return (
        <Tooltip title={ t( "opportunity:opportunity-list.live-help" ) }>
            <div>
                <Switch
                    label={ t( "opportunity:opportunity-list.live" ) }
                    onChange={ handleChange }
                    checked={ checked }
                    className={ cssUtils.cursorPointer }
                    fullWidth={ false }
                />
            </div>
        </Tooltip>
    );
};

export default LivePhases;