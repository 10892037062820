import { useSelector } from "react-redux";
import { Field } from "formik";
import classnames from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import useTranslate from "../../../hooks/use-translate.hook";
import { selectors as coreSelectors } from "../../../core";
import { selectors as teamSelectors } from "../../../team";
import { selectors as userSelectors } from "../../../user";
import { Dropdown, DropdownMultiple, cssUtils, flex } from "../../../ui";

const UserDistribution = ({
    fullWidth,
    teamName,
    usersName,
    error
}) => {
    const t = useTranslate();

    const user = useSelector( coreSelectors.getUser );
    const teams = useSelector( teamSelectors.listAll );
    const users = useSelector( userSelectors.listAllActive );

    if ( !users || !teams ) {
        return null;
    }

    const userTeams = get( user, "teams" );
    const sourceTeams = get( user, "admin" ) ?
        teams.map( team => ({ value: team.id, label: team.name }) ) :
        userTeams.map( userTeam =>
            ({ value: userTeam.team.id, label: userTeam.team.name })
        );

    return (
        <>
            <Field
                name={ teamName }
                label={ t( "ui:users-filter.team" ) }
                fullWidth={ fullWidth }
                className={ flex.fill }
                source={ sourceTeams }
                disabled={ user.admin ? isEmpty( teams ) : isEmpty( userTeams ) }
                helperText={ isEmpty( teams ) && t( "ui:users-filter.empty-teams" ) }
                error={ error }
                emptyValue
                component={ Dropdown }
            />
            <Field
                name={ usersName }
                className={ classnames( !fullWidth && cssUtils.marginLeftSmall, flex.fill ) }
                label={ t( "ui:users-filter.users" ) }
                source={
                    users.map( user => ({
                        label: user.name,
                        value: user.id
                    }))
                }
                error={ error }
                emptyValue
                component={ DropdownMultiple }
            />
        </>
    );
};

export default UserDistribution;