import { combineReducers } from "redux";
import { createRequestReducer } from "redux-request-state";

import * as webhooksActions from "./webhook.actions";

const webhooks = createRequestReducer({
    begin: webhooksActions.REQUEST_WEBHOOKS,
    success: webhooksActions.RECEIVE_WEBHOOKS,
    failure: webhooksActions.ERROR_WEBHOOKS
});

export const webhookResource = combineReducers({
    webhooks
});