import AdditionalResources from "./additional-resources/additional-resources.component";
import Automation from "./automation/automation.component";
import Hub from "./hub/hub.component";
import Integration from "./integration/integration.component";
import Product from "./product/product.component";
import css from "./marketplace.scss";

const MarketplaceList = () => (
    <div className={ css.marketplace }>
        <Integration/>
        <Product/>
        <Automation/>
        <Hub/>
        <AdditionalResources/>
    </div>
);

export default MarketplaceList;