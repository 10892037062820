export const SAVE_SCHEDULING_TYPES = "x/config/opportunity/SAVE_SCHEDULING_TYPES";
export const saveSchedulingTypes = ({ schedulingTypes }) => ({
    type: SAVE_SCHEDULING_TYPES,
    payload: { schedulingTypes }
});

export const DELETE_SCHEDULING_TYPE = "x/config/opportunity/DELETE_SCHEDULING_TYPE";
export const deleteSchedulingType = schedulingType => ({
    type: DELETE_SCHEDULING_TYPE,
    payload: { schedulingType }
});