import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";

import useTranslate from "../../hooks/use-translate.hook";
import { phoneFormatNational } from "../../core/formatter.utils";
import { flex } from "../../ui";
import { getTemperatureProps } from "../opportunity.utils";
import css from "./phases.scss";

const OpportunityItemPhone = ({ opportunity, companyField, personField }) => {
    const t = useTranslate();

    const value = useMemo( () => {
        if ( opportunity.person && personField && !isEmpty( opportunity.person.fields[ personField.id ] ) ) {
            const phone = opportunity.person.fields[ personField.id ][ 0 ];
            return phoneFormatNational( phone.number, phone.country );
        }
        if ( !companyField || isEmpty( opportunity.company?.fields[ companyField.id ] ) ) {
            return t( "common:empty-email" );
        }
        const phone = opportunity.company.fields[ companyField.id ][ 0 ];
        return phoneFormatNational( phone.number, phone.country );
    }, [ t, opportunity, companyField, personField ] );

    return (
        <div
            className={
                classnames(
                    flex.container,
                    flex.alignItemsCenter,
                    css.opportunityInfo,
                    css.opportunityPriceTemperatureRow
                )
            }
        >
            <Typography
                variant="caption"
                className={ flex.fill }
                noWrap
            >
                { value }
            </Typography>
            {
                opportunity.temperature ?
                    getTemperatureProps( opportunity.temperature ) :
                    ""
            }
        </div>
    );
};

export default OpportunityItemPhone;