export const FETCH_AUTOMATIONS = "x/marketplace/FETCH_AUTOMATIONS";
export const fetchAutomations = () => ({
    type: FETCH_AUTOMATIONS
});

export const REQUEST_AUTOMATIONS = "x/marketplace/REQUEST_AUTOMATIONS";
export const requestAutomations = () => ({
    type: REQUEST_AUTOMATIONS
});

export const RECEIVE_AUTOMATIONS = "x/marketplace/RECEIVE_AUTOMATIONS";
export const receiveAutomations = data => ({
    type: RECEIVE_AUTOMATIONS,
    payload: data
});

export const ERROR_AUTOMATIONS = "x/marketplace/ERROR_AUTOMATIONS";
export const errorAutomations = error => ({
    type: ERROR_AUTOMATIONS,
    payload: error,
    error: true
});

export const CREATE_AUTOMATION = "x/marketplace/CREATE_AUTOMATION";
export const createAutomation = data => ({
    type: CREATE_AUTOMATION,
    payload: data
});

export const SAVE_AUTOMATION = "x/marketplace/SAVE_AUTOMATION";
export const saveAutomation = automation => ({
    type: SAVE_AUTOMATION,
    meta: { token: automation.token },
    payload: automation
});

export const DELETE_AUTOMATION = "x/marketplace/DELETE_AUTOMATION";
export const deleteAutomation = ({ token }) => ({
    type: DELETE_AUTOMATION,
    meta: { token }
});