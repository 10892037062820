import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";

import usePrevious from "../../../hooks/use-previous.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { Input, flex } from "../../../ui";
import { fetchSubscriptionAddress, receiveSubscriptionAddress } from "./main.actions";
import { getSubscriptionAddress, isLoadingSubscriptionAddress } from "./main.selectors";

const REGEX_BRAZIL = new RegExp( "(brasil)", "i" );

const SubscriptionAddress = ({ setFieldValue, values }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const loading = useSelector( isLoadingSubscriptionAddress );
    const address = useSelector( getSubscriptionAddress );

    const prevZipCode = usePrevious( values.address.zipCode );

    const handleBlurNumber = event => {
        const number = event.target.value ? event.target.value.replace( /[^\d]/g, "" ) : event.target.value;
        setFieldValue( "address.number", number );
    };

    const handleBlurZipCode = useCallback( event => {
        const zipCode = event.target.value ? event.target.value.replace( /[^\d]/g, "" ) : event.target.value;
        setFieldValue( "address.zipCode", zipCode );
        if ( !values.address.country || !REGEX_BRAZIL.test( values.address.country ) ) {
            return;
        }

        if ( !!zipCode !== prevZipCode ) {
            dispatch( fetchSubscriptionAddress( zipCode ) );
            setFieldValue( "address.street", null );
            setFieldValue( "address.district", null );
            setFieldValue( "address.city", null );
            setFieldValue( "address.state", null );
        }
    }, [ dispatch, setFieldValue, values.address?.country, prevZipCode ] );

    useEffect( () => {
        if ( address ) {
            if ( address.error ) {
                return;
            }
            setFieldValue( "address.street", address.logradouro );
            setFieldValue( "address.district", address.bairro );
            setFieldValue( "address.city", address.localidade );
            setFieldValue( "address.state", address.uf );
        }
    }, [ setFieldValue, address ] );
    useEffect( () => {
        return () => {
            dispatch( receiveSubscriptionAddress() );
        };
    }, [ dispatch ] );

    return (
        <>
            { loading && <LinearProgress /> }
            <div className={ classnames( flex.container, flex.columnGapSmall ) }>
                <Field
                    name="address.country"
                    label={ t( "config:subscription.address.country" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.fill }
                />
                <Field
                    name="address.zipCode"
                    label={ t( "config:subscription.address.zip-code" ) }
                    onBlur={ handleBlurZipCode }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.item20 }
                />
                <Field
                    name="address.street"
                    label={ t( "config:subscription.address.street" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.fill }
                    disabled={ !values.address?.zipCode || loading }
                />
                <Field
                    name="address.number"
                    label={ t( "config:subscription.address.number" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.item20 }
                    onBlur={ handleBlurNumber }
                />
            </div>
            <div className={ classnames( flex.container, flex.columnGapSmall ) }>
                <Field
                    name="address.district"
                    label={ t( "config:subscription.address.district" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.fill }
                    disabled={ !values.address?.zipCode || loading }
                />
                <Field
                    name="address.city"
                    label={ t( "config:subscription.address.city" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.fill }
                    disabled={ !values.address?.zipCode || loading }
                />
                <Field
                    name="address.state"
                    label={ t( "config:subscription.address.state" ) }
                    component={ Input }
                    fullWidth={ false }
                    maxLength={ 2 }
                    className={ flex.item15 }
                    disabled={ !values.address?.zipCode || loading }
                />
                <Field
                    name="address.complement"
                    label={ t( "config:subscription.address.complement" ) }
                    component={ Input }
                    fullWidth={ false }
                    className={ flex.item20 }
                />
            </div>
        </>
    );
};

export default SubscriptionAddress;