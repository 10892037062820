import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { receiveKanban } from "../../../kanban/kanban.actions";
import * as actions from "./kanban.actions";
import { KANBAN_FORM } from "./kanban.constants";

export function *watchConfigKanban () {
    yield all([
        takeEvery( actions.SAVE_KANBAN, saveKanban ),
    ]);
}

function *saveKanban ({ payload }) {
    yield put( startSubmit( KANBAN_FORM ) );
    try {
        const response = yield call( apiv1.put, "/kanban", payload );
        yield put( receiveKanban( response.data ) );
        yield put( stopSubmit( KANBAN_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( KANBAN_FORM, e.response.data ) );
    }
}