import { decryptText } from "../core/formatter.utils";

export const getDefaultSettings = state => state.smtpServer.defaultSettings.data;
export const isLoadingDefaultSettings = state => state.smtpServer.defaultSettings.loading;

export const getSettings = state => state.smtpServer.settings.data ?
    {
        ...state.smtpServer.settings.data,
        password: decryptText( state.smtpServer.settings.data.password )
    } :
    null;
export const isLoadingSettings = state => state.smtpServer.settings.loading;