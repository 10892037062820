import { createSelector, createSelectorCreator, defaultMemoize } from "reselect";
import flow from "lodash/flow";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import * as coreSelectors from "../core/core.selectors";

const createDeepEqualSelector = createSelectorCreator( defaultMemoize, isEqual );

const getFormBase = form => state => state.fields[ ( form || "" ).toUpperCase() ] || {};

export const isLoading = form => flow(
    getFormBase( form ),
    state => state.loading
);
export const error = form => flow(
    getFormBase( form ),
    state => state.error
);
export const list = form => createDeepEqualSelector(
    getFormBase( form ),
    state => state.data || []
);
export const listWithoutGroupings = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type !== "GROUPING" )
);
export const listMainScreenFields = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.options && field.options.mainScreen )
);

export const listUserMainScreenFields = form => createSelector(
    listMainScreenFields( form ),
    coreSelectors.getPreferences,
    ( fields, preferences ) => fields.map( field => {
        const userCols = get( preferences, `${form.toLowerCase()}.mainColumns` );
        return ({
            ...field,
            checked: userCols.includes( field.id.toString() ) || isEmpty( userCols )
        });
    })
);

export const listSummaryFields = form => createSelector(
    state => state.fields,
    fields => ( fields[ form ]?.data || [] ).filter( field => field.options && field.options.summary )
);

export const listNumberAndPrice = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type === "NUMBER" || field.type === "PRICE" )
);

export const listPhoneFields = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type === "PHONE" )
);

export const listAddressFields = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type === "ADDRESS" )
);

export const listEmailFields = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type === "EMAIL" )
);

export const listSelectFields = form => createSelector(
    list( form ),
    fields => fields.filter( field => field.type === "SELECT" )
);

export const getSelectedBrand = state => state.fields.brand;
export const getSelectedBrandModels = state => get( state.fields, "brandModels.data" );
export const isLoadingBrandModels = state => get( state.fields, "brandModels.loading", false );

const getFormFiltersBase = form => state => state.filters[ ( form || "" ).toUpperCase() ] || {};

export const listFilters = form => createSelector(
    getFormFiltersBase( form ),
    state => state.data || []
);
export const isLoadingFilters = form => createSelector(
    getFormFiltersBase( form ),
    state => state.loading
);
export const errorFilters = form => createSelector(
    getFormFiltersBase( form ),
    state => state.error
);
