import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import MyLocalationIcon from "@mui/icons-material/MyLocation";
import classnames from "classnames";
import timezonesList from "timezones-list";

import useTranslate from "../../../hooks/use-translate.hook";
import { flex } from "../../../ui";

const timezones = timezonesList.map( timezone => ({
    value: timezone.tzCode,
    label: timezone.label
}));

const Timezone = ({ field, form }) => {
    const t = useTranslate();

    const [ open, setOpen ] = useState( false );
    const toggleOpen = () => setOpen( !open );

    const handleSelect = zoneId => () => {
        form.setFieldValue( field.name, zoneId );
        toggleOpen();
    };
    const myLocation = () => form.setFieldValue( field.name, Intl.DateTimeFormat().resolvedOptions().timeZone );

    return (
        <>
            <div className={ classnames( flex.container, flex.alignItemsCenter ) }>
                <div>
                    <Typography color="textSecondary" variant="caption">
                        { t( "config:subscription.office-hours.zone-id" ) }
                    </Typography>
                    <Typography>{ field.value }</Typography>
                </div>
                <Tooltip title={ t( "common:edit" ) }>
                    <IconButton onClick={ toggleOpen } size="large">
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={ t( "config:subscription.office-hours.my-location" ) }>
                    <IconButton onClick={ myLocation } size="large">
                        <MyLocalationIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <Dialog open={ open }>
                <DialogContent>
                    { timezones.map( ( timezone, index ) => (
                        <ListItemButton
                            key={ index }
                            dense
                            onClick={ handleSelect( timezone.value ) }
                            selected={ field.value === timezone.value }
                        >
                            <ListItemText primary={ timezone.label }/>
                        </ListItemButton>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={ toggleOpen } color="primary">
                        { t( "common:cancel" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Timezone;