import { useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import WebhookIcon from "@mui/icons-material/Webhook";
import { useNavigate } from "react-router";

import useTranslate from "../../hooks/use-translate.hook";
import { fetchWebhooks } from "./webhook/webhook.actions";
import { getWebhooks, isLoadingWebhooks } from "./webhook/webhook.selectors";
import MarketplaceItem from "../marketplace-item.component";
import css from "../marketplace.scss";

const AdditionalResources = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const t = useTranslate();

    const webhooks = useSelector( getWebhooks );
    const loading = useSelector( isLoadingWebhooks );

    const additionalResources = useMemo( () => [
        {
            id: "webhooks",
            title: t( "marketplace:additional-resources.webhooks.title" ),
            quantity: webhooks?.length || 0
        }
    ], [ t, webhooks ] );

    const handleClickResource = useCallback( id => () => {
        const route = `additional-resources/${id}`;
        navigate( route );
    }, [ navigate ] );

    const renderAdditionalResourceIcon = useCallback( resource => {
        switch ( resource.id ) {
            case "webhooks":
                return <WebhookIcon style={{ fontSize: 50 }}/>;
            default:
                return <div className={ resource.className }/>;
        }
    }, [] );

    useEffect( () => {
        dispatch( fetchWebhooks() );
    }, [ dispatch ] );

    return (
        <div className={ className }>
            <CardHeader
                className={ css.sectionTitle }
                title={ t( "marketplace:additional-resources.title" ) }
                subheader={ t( "marketplace:additional-resources.subtitle" ) }
            />
            <Grid container spacing={ 2 }>
                {
                    additionalResources.map( resource => (
                        <Grid key={ resource.id } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
                            <MarketplaceItem
                                onClick={ handleClickResource( resource.id ) }
                                loading={ loading }
                                expired={ resource.expired }
                                quantity={ resource.quantity }
                                title={ resource.title }
                            >
                                { renderAdditionalResourceIcon( resource ) }
                            </MarketplaceItem>
                        </Grid>
                    ) )
                }
            </Grid>
        </div>
    );
};

export default AdditionalResources;