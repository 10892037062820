import axios from "axios";
import config from "config";
import get from "lodash/get";
import { call, select } from "redux-saga/effects";

import apiv1Api from "./api-v1";
import apiv2Api from "./api-v2";
import appApi from "./app";
import mailtopApi from "./mailtop-api";

export const app = makeApi( appApi );
export const apiv1 = makeApi( apiv1Api );
export const apiv2 = makeApi( apiv2Api );
export const mailtop = makeApi( mailtopApi );

function makeApi ( api ) {
    const privateApi = axios.create( api.defaults );
    const mock = {
        _api: privateApi
    };

    [ "request", "get", "head", "post", "put", "patch", "delete" ].forEach( method => {
        mock[ method ] = function *() {
            const state = yield select();
            privateApi.defaults.headers.common[ "Accept-Language" ] = get( state, "info.locale" );

            // Este if vai ser removido em um ambiente browser de produção, portanto nada de problemas de segurança aqui
            if ( typeof BROWSER_ENV === "undefined" ) {

                privateApi.defaults.headers.common.cookie = get( state, "auth.cookieString", "" );

                if ( config.get( "server.forceHTTPS" ) ) {
                    privateApi.defaults.headers.common[ "X-Forwarded-Proto" ] = "https";
                }
            }

            return yield call( privateApi[ method ], ...arguments );
        };
    });

    return mock;
}