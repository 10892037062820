import { useSelector } from "react-redux";
import { Field } from "formik";

import useTranslate from "../hooks/use-translate.hook";
import { DateTimePicker, Dropdown, Input } from "../ui";
import { selectors as lossReasonSelectors } from "../loss-reason";

const OpportunityLostForm = () => {
    const t = useTranslate();

    const lossReasons = useSelector( lossReasonSelectors.getLossReasons );

    return (
        <>
            <Field
                name="lostAt"
                label={ t( "opportunity:lost-at" ) }
                component={ DateTimePicker }
                required
            />
            <Field
                component={ Dropdown }
                name="lossReason.id"
                label={ t( "opportunity:loss-reason" ) }
                source={ ( lossReasons || [] ).map( lossReason => ({ value: lossReason.id, label: lossReason.name }) ) }
                required
            />
            <Field
                component={ Input }
                name="lossReasonComplement"
                label={ t( "opportunity:loss-reason-complement" ) }
                multiline
                rows="2"
                rowsMax="5"
            />
        </>
    );
};

export default OpportunityLostForm;