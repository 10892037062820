import { useCallback } from "react";

export const useGoalProgressFormat = ( t, priceFormat ) => {
    const goalProgressFormat = useCallback( ( goal, value ) => {
        switch ( goal.type ) {
            case "AVERAGE_SERVICE_TIME":
                return t( "home:goal-progress.average-service-time", { time: value } );
            case "CUSTOM_FIELD":
                return goal.field.type === "PRICE" ?
                    priceFormat( value ) :
                    value;
            case "OPPORTUNITIES_CREATED":
                return t( "home:goal-progress.opportunities-created", { total: value } );
            case "SOLD_OPPORTUNITIES_PRICE":
            case "SOLD_OPPORTUNITIES_RECURRENT_PRICE":
                return priceFormat( value );
            default:
                return value;
        }
    }, [ t, priceFormat ] );

    return goalProgressFormat;
};