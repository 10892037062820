import isEmpty from "lodash/isEmpty";

const SUBSCRIPTION_ADDRESS_KEYS = [ "street", "zipCode", "number", "district", "city", "state", "country" ];

export const subscriptionHasAddress = subscription => {
    if ( isEmpty( subscription.address ) ) {
        return false;
    }

    return !SUBSCRIPTION_ADDRESS_KEYS.find( key => !subscription.address[ key ] );
};

export const formatBankSlip = number => number.replace(
    /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/,
    "$1.$2 $3.$4 $5.$6 $7 $8"
);