import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useTranslate from "../hooks/use-translate.hook";
import { Dialog, cssUtils } from "./";

const DialogInfo = ({ open, message, onClose, onCancel, title, whiteSpacePre, ...props }) => {
    const t = useTranslate();

    return (
        <Dialog
            open={ open }
            maxWidth="sm"
            fullWidth
            onClose={ onClose }
            { ...props }
        >
            {
                title && <DialogTitle>{ title }</DialogTitle>
            }
            <DialogContent>
                <DialogContentText
                    className={ whiteSpacePre && cssUtils.whiteSpacePre }
                >
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    onCancel &&
                        <Button color="primary" onClick={ onCancel }>
                            { t( "common:cancel" ) }
                        </Button>
                }
                <Button color="primary" onClick={ onClose }>
                    { t( "common:ok" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogInfo;