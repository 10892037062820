import Dialog from "@mui/material/Dialog";

import Team from "./team.component";

const NewTeam = ({ open, onClose }) => (
    <Dialog
        open={ open }
        onClose={ onClose }
        fullWidth
        maxWidth="md"
    >
        <Team onClose={ onClose }/>
    </Dialog>
);

export default NewTeam;