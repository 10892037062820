import common from "./common";
import config from "./config";
import customer from "./customer";
import dashboard from "./dashboard";
import form from "./form";
import home from "./home";
import importation from "./importation";
import invitation from "./invitation";
import layout from "./layout";
import login from "./login";
import oauth from "./oauth";
import opportunity from "./opportunity";
import report from "./report";
import schedule from "./schedule";
import signup from "./signup";
import timeline from "./timeline";
import ui from "./ui";
import user from "./user";
import webCapture from "./web-capture";
import welcome from "./welcome";
import marketplace from "./marketplace";

export default {
    common,
    config,
    customer,
    dashboard,
    form,
    home,
    importation,
    invitation,
    layout,
    login,
    oauth,
    opportunity,
    report,
    schedule,
    signup,
    timeline,
    ui,
    user,
    "web-capture": webCapture,
    welcome,
    marketplace
};