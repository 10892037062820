export const USER_FORM = "userForm";
export const USER_AVATAR_FORM = "userAvatarForm";
export const USER_SIGNATURE_FORM = "userSignatureForm";
export const USER_PASSWORD_FORM = "userPasswordForm";
export const USER_FILTERS = "userFilters";
export const USER_TEMPLATE = "userTemplateForm";
export const USER_THEME_MODE_FORM = "userThemeModeForm";
export const ADD_IMAGE_FORM = "addImageForm";
export const SMTP_SERVER_FORM = "smtpServerForm";
export const SOCIAL_CALENDAR_FORM = "socialCalendarForm";
export const DELETE_SOCIAL_CALENDAR_FORM = "deleteSocialCalendarForm";
export const VERIFY_EMAIL_FORM = "verifyEmailForm";