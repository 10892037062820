import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import classnames from "classnames";
import get from "lodash/get";

import { useDateTimeFormat } from "../../hooks/use-date-format.hook";
import usePriceFormat from "../../hooks/use-price-format.hook";
import useTranslate from "../../hooks/use-translate.hook";
import { isLate } from "../../core/date.utils";
import { list as listForm } from "../../form/form.selectors";
import { getKanban } from "../../kanban/kanban.selectors";
import OpenSchedulesIcon from "./open-schedules-icon.component";
import css from "./phases.scss";
import { Link, flex, cssUtils } from "../../ui";
import OpportunityItemCustomer from "./opportunity-item-customer.component";
import OpportunityItemEmail from "./opportunity-item-email.component";
import OpportunityItemPhone from "./opportunity-item-phone.component";
import opportunityCss from "../opportunity.scss";

const OpportunityItem = ({ opportunity, saveStatus, themeMode }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    const priceFormat = usePriceFormat();

    const companyFields = useSelector( listForm( "COMPANY" ) );
    const personFields = useSelector( listForm( "PERSON" ) );
    const opportunityFields = useSelector( listForm( "OPPORTUNITY" ) );
    const kanban = useSelector( getKanban );

    const [ buttonMenuOptions, setButtonMenuOptions ] = useState();

    const openMenuOptions = event => setButtonMenuOptions( event.currentTarget );
    const closeMenuOptions = () => setButtonMenuOptions( null );

    const setStatusLost = useCallback( () => {
        saveStatus({ ...opportunity, status: "LOST" });
        closeMenuOptions();
    }, [ opportunity, saveStatus ] );

    const setStatusSold = useCallback( () => {
        saveStatus({ ...opportunity, status: "SOLD" });
        closeMenuOptions();
    }, [ opportunity, saveStatus ] );

    const isPhaseLate = useMemo( () => {
        let isPhaseLate = false;
        if ( opportunity.phase.days ) {
            const phaseEntryDate = new Date( opportunity.phaseEntryDate );
            const maxPhaseDate = new Date();
            maxPhaseDate.setDate( maxPhaseDate.getDate() - opportunity.phase.days );

            isPhaseLate = phaseEntryDate < maxPhaseDate;
        }
        return isPhaseLate;
    }, [ opportunity.phase.days, opportunity.phaseEntryDate ] );

    const companyEmailField = useMemo(
        () => companyFields.find( field => field.type === "EMAIL" ),
        [ companyFields ]
    );
    const companyPhoneField = useMemo(
        () => companyFields.find( field => field.type === "PHONE" ),
        [ companyFields ]
    );

    const personEmailField = useMemo(
        () => personFields.find( field => field.type === "EMAIL" ),
        [ personFields ]
    );
    const personPhoneField = useMemo(
        () => personFields.find( field => field.type === "PHONE" ),
        [ personFields ]
    );

    const source = useMemo( () => {
        const field = opportunityFields.find( field => field.systemField === "SOURCE" );
        return field.options.values.find( item => item.value === opportunity.sourceId ).label;
    }, [ opportunity.sourceId, opportunityFields ] );
    const nextSchedulingClassNames = useMemo( () => {
        if ( !opportunity.nextScheduling ) {
            return [ flex.fill, opportunityCss.iconEmptySchedules ];
        }
        if ( isLate( opportunity.nextScheduling ) ) {
            return [ flex.fill, opportunityCss.iconLateScheduling ];
        }
        return [ flex.fill, opportunityCss.iconScheduling ];
    }, [ opportunity.nextScheduling ] );
    const nextScheduling = useMemo( () => {
        return opportunity.nextScheduling ?
            dateFormat( opportunity.nextScheduling ) :
            t( "opportunity:phases.opportunity-open-scheduling.empty" );
    }, [ t, dateFormat, opportunity.nextScheduling ] );
    const otherFields = useMemo( () => {
        if ( !kanban || !kanban.fields?.length ) {
            return [];
        }
        return kanban.fields
            .map( item => {
                switch ( item.formType ) {
                    case "COMPANY":
                        return {
                            name: companyFields.find( field => field.id === item.fieldId ).name,
                            value: opportunity.company?.fields[ item.fieldId ]
                        };
                    case "PERSON":
                        return {
                            name: personFields.find( field => field.id === item.fieldId ).name,
                            value: opportunity.person?.fields[ item.fieldId ]
                        };
                    default:
                        return {
                            name: opportunityFields.find( field => field.id === item.fieldId )?.name,
                            value: opportunity.fields[ item.fieldId ]
                        };
                }
            })
            .filter( field => !!field.value );
    }, [ kanban, opportunity, companyFields, personFields, opportunityFields ] );

    if ( !kanban ) {
        return null;
    }

    return (
        <div className={ css.item }>
            <Card
                className={ classnames(
                    isPhaseLate ? css.latePhaseItem : css.normalItem,
                    themeMode === "light" && css.lightItem
                ) }
                variant={ themeMode === "dark" ? "outlined" : "elevation" }
            >
                <div
                    className={ classnames(
                        flex.container,
                        flex.alignItemsCenter,
                        css.opportunityInfo,
                        css.opportunityTitle
                    ) }
                >
                    <Link
                        href={ "/opportunities/" + opportunity.id }
                        className={ classnames(
                            flex.fill,
                            cssUtils.cursorPointer,
                            css.itemTitle
                        )}
                    >
                        <Tooltip title={ opportunity.title } placement="top" >
                            <Typography variant="subtitle2" noWrap>{ opportunity.title }</Typography>
                        </Tooltip>
                    </Link>
                    <div>
                        <IconButton
                            size="small"
                            onClick={ openMenuOptions }
                            className={ cssUtils.padding0 }
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            open={ !!buttonMenuOptions }
                            anchorEl={ buttonMenuOptions }
                            onClose={ closeMenuOptions }
                        >
                            <MenuItem onClick={ setStatusSold } dense>
                                <ListItemIcon>
                                    <ThumbUpIcon className={ css.menuItemSold }/>
                                </ListItemIcon>
                                <ListItemText
                                    className={ css.menuItemSold }
                                    primary={ t( "opportunity:status.SOLD" ) }
                                />
                            </MenuItem>
                            <MenuItem onClick={ setStatusLost } dense>
                                <ListItemIcon>
                                    <ThumbDownIcon className={ css.menuItemLost }/>
                                </ListItemIcon>
                                <ListItemText
                                    className={ css.menuItemLost }
                                    primary={ t( "opportunity:status.LOST" ) }
                                />
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                {
                    kanban.company &&
                        <OpportunityItemCustomer
                            icon={ <BusinessIcon className={ css.customerTypeIcon }/> }
                            name={ get( opportunity, "company.name", t( "opportunity:empty-company" ) ) }
                        />
                }
                {
                    kanban.person &&
                        <OpportunityItemCustomer
                            icon={ <PersonIcon className={ css.customerTypeIcon }/> }
                            name={ get( opportunity, "person.name", t( "opportunity:empty-person" ) ) }
                        />
                }
                {
                    kanban.phone &&
                        <OpportunityItemPhone
                            opportunity={ opportunity }
                            companyField={ companyPhoneField }
                            personField={ personPhoneField }
                        />
                }
                {
                    kanban.email &&
                        <OpportunityItemEmail
                            opportunity={ opportunity }
                            companyField={ companyEmailField }
                            personField={ personEmailField }
                        />
                }
                {
                    ( kanban.price || kanban.recurrentPrice || kanban.source || kanban.user ) &&
                        <div className={ classnames( css.opportunityInfo, css.opportunityPriceTemperatureRow ) }>
                            <Typography noWrap variant="caption" className={ flex.fill }>
                                {
                                    kanban.price &&
                                        <Tooltip title={ t( "opportunity:price.label" ) }>
                                            <span>{ priceFormat( opportunity.price ) }</span>
                                        </Tooltip>
                                }
                                {
                                    kanban.recurrentPrice &&
                                        <>
                                            { kanban.price && " / " }
                                            <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                                <span>{ priceFormat( opportunity.recurrentPrice ) }</span>
                                            </Tooltip>
                                        </>
                                }
                                {
                                    kanban.source &&
                                        <>
                                            { ( kanban.price || kanban.recurrentPrice ) && " - " }
                                            { source }
                                        </>
                                }
                            </Typography>

                            {
                                kanban.user &&
                                    <Tooltip title={ opportunity.user.name }>
                                        <Avatar
                                            src={ opportunity.user.avatar }
                                            className={ css.avatarUser }
                                        >
                                            {
                                                opportunity.user.avatar ?
                                                    "" :
                                                    opportunity.user.name.toUpperCase().charAt( 0 )
                                            }
                                        </Avatar>
                                    </Tooltip>
                            }
                        </div>
                }
                {
                    kanban.nextScheduling &&
                        <>
                            <hr className={ css.divider }/>
                            <div className={ classnames( css.opportunityInfo, css.opportunitySchedulingRow ) }>
                                <Typography
                                    className={ classnames( nextSchedulingClassNames ) }
                                    variant="caption"
                                >
                                    { nextScheduling }
                                </Typography>
                                <OpenSchedulesIcon opportunity={ opportunity } />
                            </div>
                        </>
                }
                {
                    otherFields.length > 0 &&
                        <>
                            <hr className={ css.divider }/>
                            {
                                otherFields.map( ( field, index ) => (
                                    <div
                                        key={ index }
                                        className={ classnames( flex.container, css.opportunityInfo ) }
                                    >
                                        <Tooltip title={ field.name }>
                                            <Typography
                                                variant="caption"
                                                className={ flex.fill }
                                                noWrap
                                            >
                                                { field.value }
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                ))
                            }
                        </>
                }
            </Card>
        </div>
    );
};

export default OpportunityItem;