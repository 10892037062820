import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import get from "lodash/get";

import useTranslate from "../hooks/use-translate.hook";
import { useDateFormat } from "../hooks/use-date-format.hook";
import { Link, cssUtils } from "../ui";
import GoogleIcon from "../ui/icons/google.component";
import Delete from "./schedule-event-delete.component";
import Edit from "./schedule-event-edit.component";
import Status from "./schedule-event-status.component";
import css from "./schedule.scss";

const formatDate = date => (
    date.toLocaleDateString( navigator.language, { weekday: "long", month: "long", day: "numeric" } )
);

const formatHour = date => (
    new Date( date ).toLocaleString( navigator.language, { hour: "2-digit", minute: "2-digit" } )
);

const ScheduleEvent = ({ event, onClose, user }) => {
    const t = useTranslate();
    const dateFormat = useDateFormat( t );

    const [ open, setOpen ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openSocialDetails, setOpenSocialDetails ] = useState( false );

    const opportunityUser = useMemo( () => {
        if ( !event?.opportunity ) {
            return "";
        }
        if ( event.user && event.user.id !== user.id ) {
            return t( "schedule:event.user" ) + event.user.name;
        }
        if ( event.opportunity.user.id !== user.id || event.opportunity.user.id !== user.id ) {
            return t( "schedule:event.user" ) + event.opportunity.user.name;
        }
        return "";
    }, [ t, event, user ] );

    const handleClose = () => {
        setOpen( false );
        onClose();
    };

    const handleOpenDelete = () => {
        setOpen( false );
        setOpenDelete( true );
    };
    const handleCloseDelete = () => {
        setOpen( true );
        setOpenDelete( false );
    };

    const handleOpenEdit = () => {
        setOpen( false );
        setOpenEdit( true );
    };
    const handleCloseEdit = () => {
        setOpen( true );
        setOpenEdit( false );
    };

    const toggleSocialDetails = () => setOpenSocialDetails( !openSocialDetails );
    const openSocialLink = event => () => window.open( event.social.data.htmlLink, "_blank" );
    const openSocialConference = event => () => window.open(
        `https://meet.google.com/${event.social.data.conferenceId}`,
        "_blank"
    );
    const copySocialConferenceLink = event => async () => (
        await navigator.clipboard.writeText( `https://meet.google.com/${event.social.data.conferenceId}` )
    );

    useEffect( () => {
        if ( event && !open ) {
            setOpen( true );
        }
    }, [ event, open ] );
    useEffect( () => {
        if ( !event && openDelete ) {
            setOpenDelete( false );
        }
    }, [ event, openDelete ]);
    useEffect( () => {
        if ( !event && openEdit ) {
            setOpenEdit( false );
        }
    }, [ event, openEdit ]);
    useEffect( () => {
        if ( !event && openSocialDetails ) {
            setOpenSocialDetails( false );
        }
    }, [ event, openSocialDetails ]);

    if ( !event ) {
        return null;
    }
    const company = get(
        event,
        "opportunity.company",
        event.customer && event.customer.type === "COMPANY" && event.customer
    );
    const person = get(
        event,
        "opportunity.person",
        event.customer && event.customer.type === "PERSON" && event.customer
    );
    const recurrentDescription = event.recurrent ?
        ( event.allDay ? "" : " " ) +
        t( `schedule:form-event.recurrent.type.${event.recurrent.type}`) +
        " - " +
        t( "schedule:form-event.recurrent.until" ) +
        " " +
        dateFormat( event.recurrent.until ) :
        "";

    return (
        <>
            <Dialog
                open={ open }
                fullWidth
            >
                <DialogTitle
                    style={{
                        backgroundColor: event.color
                    }}
                    className={ css.eventContainerTitle }
                >
                    { event.opportunitySchedulingType ? event.opportunitySchedulingType.name : event.title }
                    {
                        event.id &&
                            <Tooltip title={ t( "common:exclude" ) }>
                                <IconButton
                                    className={ css.eventDeleteButton }
                                    style={{ backgroundColor: event.color }}
                                    onClick={ handleOpenDelete }
                                    size="large">
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                    }
                </DialogTitle>
                <List>
                    <ListItem>
                        <ListItemIcon><ScheduleIcon/></ListItemIcon>
                        <ListItemText
                            primary={
                                formatDate( event.start ) +
                                ( !event.sameDay ? " - " + formatDate( event.end ) : "" )
                            }
                            secondary={
                                ( event.allDay ?
                                    "" :
                                    formatHour( event.startDate ) + " - " + formatHour( event.endDate )
                                ) +
                                ( recurrentDescription )
                            }
                        />
                    </ListItem>
                    {
                        company &&
                            <ListItemButton
                                component={ Link }
                                to={ `/customers/${company.id}` }
                            >
                                <ListItemIcon><BusinessIcon/></ListItemIcon>
                                <ListItemText primary={ company.name }/>
                            </ListItemButton>
                    }
                    {
                        person &&
                            <ListItemButton
                                component={ Link }
                                to={ `/customers/${person.id}` }
                            >
                                <ListItemIcon><PersonIcon/></ListItemIcon>
                                <ListItemText primary={ person.name }/>
                            </ListItemButton>
                    }
                    {
                        event.opportunity ?
                            <ListItemButton
                                component={ Link }
                                href={ `/opportunities/${event.opportunity.id}` }
                            >
                                <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                                <ListItemText
                                    primary={ event.opportunity.title }
                                    secondary={ opportunityUser }
                                />
                            </ListItemButton> :
                            event.user.id !== user.id &&
                                <ListItem>
                                    <ListItemIcon>
                                        <SupervisorAccountIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={ event.user.name }
                                    />
                                </ListItem>
                    }
                    {
                        event.social &&
                            <>
                                <Divider className={ css.socialDivider }/>
                                <ListItemButton
                                    onClick={ toggleSocialDetails }
                                    selected={ openSocialDetails }
                                >
                                    <ListItemIcon><GoogleIcon/></ListItemIcon>
                                    <ListItemText primary={ t( "schedule:event.social.linked" ) }/>
                                    { openSocialDetails ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                                </ListItemButton>
                                <Collapse in={ openSocialDetails }>
                                    <ListItemButton
                                        className={ cssUtils.paddingLeft }
                                        onClick={ openSocialLink( event ) }
                                    >
                                        <ListItemIcon>
                                            <OpenInNewIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={ t( "schedule:event.social.open" ) }/>
                                    </ListItemButton>
                                    {
                                        event.social.calendar.account.provider === "GOOGLE" &&
                                        event.social.data.conferenceId &&
                                            <ListItem
                                                disablePadding
                                                secondaryAction={
                                                    event.social.data.conferenceId &&
                                                        <Tooltip
                                                            title={
                                                                t( "schedule:event.social.google-meet.copy" )
                                                            }
                                                        >
                                                            <IconButton
                                                                onClick={ copySocialConferenceLink( event ) }
                                                                size="large">
                                                                <FileCopyIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                            >
                                                <ListItemButton
                                                    className={ cssUtils.paddingLeft }
                                                    onClick={ openSocialConference( event ) }
                                                >
                                                    <ListItemIcon>
                                                        <VoiceChatIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={ t( "schedule:event.social.google-meet.enter" ) }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                    }
                                </Collapse>
                            </>
                    }
                    {
                        event?.notAccomplishedReason &&
                            <ListItem>
                                <ListItemText
                                    primary={ t( "schedule:event.not-accomplished-reason" ) }
                                    secondary={ event.notAccomplishedReason }
                                />
                            </ListItem>
                    }
                </List>
                {
                    event.description &&
                        <CardContent className={ css.eventDescription }>{ event.description }</CardContent>
                }
                <DialogContent>
                    <div className={ cssUtils.fullWidth }>
                        {
                            event.id && !event.opportunity &&
                                <Button
                                    size="small"
                                    onClick={ handleOpenEdit }
                                    startIcon={ <EditIcon/> }
                                >

                                    { t( "schedule:event.edit" ) }
                                </Button>
                        }
                        <div className={ cssUtils.floatRight }>
                            { event.id && <Status event={ event }/> }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={ handleClose }
                        style={{ color: event.color }}
                    >
                        { t( "common:close" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            {
                event.id &&
                    <Delete
                        open={ openDelete }
                        onClose={ handleCloseDelete }
                        event={ event }
                    />
            }

            <Edit
                show={ openEdit }
                event={ event }
                onClose={ handleCloseEdit }
            />
        </>
    );
};

export default ScheduleEvent;