import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "formik-redux";

import history from "../../history";
import { apiv1 } from "../../api/sagas";
import * as actions from "./web-capture.actions";
import * as selectors from "./web-capture.selectors";
import { actions as customerActions } from "../customer";
import { LEAD_FORM, LEAD_INFO } from "./web-capture.constants";

export function *watchLeads () {
    yield all([
        takeEvery( actions.FETCH_LEADS, fetchLeads ),
        takeEvery( actions.FETCH_LEAD, fetchLead ),
        takeEvery( actions.SAVE_LEAD_FORM, saveLeadForm ),
        takeEvery( actions.CHANGE_LEAD_STATUS, changeLeadStatus ),
        takeEvery( actions.FETCH_LEAD_PORTALS, fetchLeadPortals ),
    ]);
}

export function *fetchLeads ({ payload }) {
    yield put( actions.requestLeads() );
    try {
        const response = yield call( apiv1.get, "/leads", { params: payload } );
        const leads = response.data;
        const totalPages = response.headers && response.headers[ "total-pages" ];
        const totalElements = response.headers && response.headers[ "total-elements" ];
        yield put( actions.receiveLeads({ leads, totalPages, totalElements }) );
    } catch ( e ) {
        yield put( actions.errorLeads( e.response.data ) );
    }
}

export function *fetchLead ({ meta: { id } }) {
    yield put( actions.requestLead() );
    try {
        const response = yield call( apiv1.get, `/leads/${id}` );
        yield put( actions.setLead( response.data ) );
    } catch ( e ) {
        yield put( actions.errorLead( e.response.data ) );
    }
}

export function *saveLeadForm ({ payload }) {
    yield put( startSubmit( LEAD_FORM ) );
    let customer;
    try {
        const newCustomer = !payload.customer.id;
        if ( !newCustomer ) {
            customer = yield call( apiv1.put, `/customers/${payload.customer.id}`, payload.customer );
        } else {
            customer = yield call( apiv1.post, "/customers", payload.customer );
        }

        const opportunity = yield call(
            apiv1.post, `/opportunities`,
            { ...payload.opportunity, person: { id: customer.data.id }}
        );

        const { lead } = payload;
        const leadRequestBody = {
            opportunity: {
                id: opportunity.data.id
            }
        };
        if ( newCustomer ) {
            leadRequestBody.customerId = customer.data.id;
        }
        yield call( apiv1.patch, `/leads/${lead.id}`, leadRequestBody );

        yield call( [ history, history.push ], `/opportunities/${opportunity.data.id}` );
        yield put( stopSubmit( LEAD_FORM ) );
    } catch ( e ) {
        if ( customer && customer.data && customer.data.id ) {
            yield put( customerActions.setCustomer( customer.data ) );
        }
        yield put( stopSubmit( LEAD_FORM, e.response.data ) );
    }
}

export function *changeLeadStatus ({ meta: { id }, payload }) {
    yield put( startSubmit( LEAD_INFO ) );
    try {
        const response = yield call( apiv1.patch, `/leads/${id}`, { status: payload } );
        if ( yield select( selectors.getLead ) ) {
            if ( payload !== "OPEN" ) {
                yield call( [ history, history.push ], "/leads" );
            } else {
                yield put( actions.setLead( response.data ) );
            }
        }
        yield put( stopSubmit( LEAD_INFO ) );
    } catch ( e ) {
        yield put( stopSubmit( LEAD_INFO, e.response.data ) );
    }
}

export function *fetchLeadPortals () {
    yield put( actions.requestLeadPortals() );
    try {
        const response = yield call( apiv1.get, "/leads/portals/templates" );
        yield put( actions.receiveLeadPortals( response.data ) );
    } catch ( e ) {
        yield put( actions.errorLeadPortals( e.response.data ) );
    }
}
