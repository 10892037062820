import { useEffect, useMemo } from "react";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import classnames from "classnames";

import useTranslate from "../../../hooks/use-translate.hook";
import { Switch, cssUtils, flex } from "../../../ui";
import TimeInterval from "./time-interval.component";
import Timezone from "./timezone.component";

const OfficeHours = ({ values, setFieldValue }) => {
    const t = useTranslate();

    const workOnSaturday = useMemo(
        () => values.officeHours.workOnSaturday,
        [ values.officeHours.workOnSaturday ]
    );
    const workOnSunday = useMemo(
        () => values.officeHours.workOnSunday,
        [ values.officeHours.workOnSunday ]
    );

    useEffect( () => {
        if ( !workOnSaturday ) {
            setFieldValue( "officeHours.saturdayStartAt", null );
            setFieldValue( "officeHours.saturdayEndAt", null );
        }
    }, [ workOnSaturday, setFieldValue ] );
    useEffect( () => {
        if ( !workOnSunday ) {
            setFieldValue( "officeHours.sundayStartAt", null );
            setFieldValue( "officeHours.sundayEndAt", null );
        }
    }, [ workOnSunday, setFieldValue ] );

    return (
        <>
            <Typography gutterBottom>{ t( "config:subscription.office-hours.weekdays" ) }</Typography>
            <div className={ flex.container }>
                <div className={ classnames( flex.fill, cssUtils.marginRight ) }>
                    <TimeInterval
                        title={ t( "config:subscription.office-hours.morning" ) }
                        name="officeHours.startAt"
                    />
                </div>
                <div className={ flex.fill }>
                    <TimeInterval
                        title={ t( "config:subscription.office-hours.afternoon" ) }
                        name="officeHours.endAt"
                    />
                </div>
            </div>
            <Field
                name="officeHours.workOnSaturday"
                type="checkbox"
                label={ t( "config:subscription.office-hours.work-on-saturday" ) }
                component={ Switch }
                checked={ workOnSaturday }
            />
            {
                workOnSaturday &&
                    <div className={ flex.container }>
                        <div className={ classnames( flex.fill, cssUtils.marginRight ) }>
                            <TimeInterval
                                title={ t( "config:subscription.office-hours.morning" ) }
                                name="officeHours.saturdayStartAt"
                            />
                        </div>
                        <div className={ flex.fill }>
                            <TimeInterval
                                title={ t( "config:subscription.office-hours.afternoon" ) }
                                name="officeHours.saturdayEndAt"
                            />
                        </div>
                    </div>
            }
            <Field
                name="officeHours.workOnSunday"
                type="checkbox"
                label={ t( "config:subscription.office-hours.work-on-sunday" ) }
                component={ Switch }
                checked={ workOnSunday }
            />
            {
                workOnSunday &&
                    <div className={ flex.container }>
                        <div className={ classnames( flex.fill, cssUtils.marginRight ) }>
                            <TimeInterval
                                title={ t( "config:subscription.office-hours.morning" ) }
                                name="officeHours.sundayStartAt"
                            />
                        </div>
                        <div className={ flex.fill }>
                            <TimeInterval
                                title={ t( "config:subscription.office-hours.afternoon" ) }
                                name="officeHours.sundayEndAt"
                            />
                        </div>
                    </div>
            }
            <Field name="officeHours.zoneId" component={ Timezone }/>
        </>
    );
};

export default OfficeHours;