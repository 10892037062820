import { useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

import { useDateFormat } from "../../../hooks/use-date-format.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { cssUtils } from "../../../ui";
import { Table } from "../../../ui/table";
import GoalItemActions from "./goal-item-actions.component";

const GoalsList = ({ goals = [], onSelect, bottomShadow, showInterval, subheader, title }) => {
    const t = useTranslate();
    const dateFormat = useDateFormat( t );
    const priceFormat = usePriceFormat();

    const [ open, setOpen ] = useState( false );

    const toggleOpen = () => setOpen( !open );

    const formatGoalTarget = goal => {
        if ( goal.type.match( "SOLD_OPPORTUNITIES_PRICE|SOLD_OPPORTUNITIES_RECURRENT_PRICE" ) ) {
            return priceFormat( goal.target );
        }
        return goal.target;
    };

    const usersDescription = goal => {
        return !isEmpty( goal.users ) ?
            t( "config:goal.users" ) + ": " + goal.users.map( user => user.name ).join( ", " ) :
            !!goal.team ?
                t( "config:goal.team" ) + ": " + goal.team.name :
                t( "config:goal.all-users" );
    };

    const model = useMemo( () => {
        const items = {
            name: t( "common:name" ),
            type: t( "config:goal.type.label" ),
            products: `${t( "config:product.category.label" )} / ${t( "config:products.title" )}`,
            target: t( "config:goal.target" ),
            users: t( "config:goal.users" )
        };
        if ( showInterval ) {
            items.interval = t( "config:goal.interval.label" );
        }
        items.funnel = t( "opportunity:funnel" );
        items.period = `${t( "config:goal.initial-date" )} / ${t( "config:goal.final-date" )}`;
        items.actions = {
            title: " ",
            size: "small",
        };
        return items;
    }, [ t, showInterval ] );

    return (
        <List disablePadding>
            <ListItemButton selected={ open } onClick={ toggleOpen }>
                <ListItemText primary={ `${title} (${goals.length})` } secondary={ subheader }/>
                { open ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
            </ListItemButton>
            <Collapse in={ open }>
                <Table
                    model={ model }
                    source={ goals.map( goal => ({
                        ...goal,
                        type: t( `config:goal.type.${goal.type}` ),
                        products: ( goal.productCategory ? goal.productCategory.name?.concat("/") : "-" )
                            .concat( goal.products && !isEmpty( goal.products ) ?
                                goal.products.map( product => product.description ).join( ", " ) : ""),
                        target: formatGoalTarget( goal ),
                        users: usersDescription( goal ),
                        interval: t( `config:goal.interval.${goal.interval}` ),
                        period: ( goal.initialDate ? t( "common:from" ).concat( " " )
                            .concat( dateFormat( goal.initialDate ) ) : "" )
                            .concat( ( goal.finalDate ? " ".concat( t( "common:until" ) ).concat(" ")
                                .concat( dateFormat( goal.finalDate ) ) : "") ),
                        funnel: goal.funnel?.name || "",
                        actions: <GoalItemActions goal={ goal } onSelect={ onSelect }/>
                    })) }
                    emptyMessage={ t( "config:empty-goals" ) }
                    className={ classnames(
                        bottomShadow ? cssUtils.marginBottomSmall : "",
                        bottomShadow ? "" : cssUtils.boxShadowUnset
                    ) }
                />
            </Collapse>
        </List>
    );
};

export default GoalsList;