import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import classnames from "classnames";
import order from "lodash/orderBy";

import useTranslate from "../hooks/use-translate.hook";
import { Chart, cssUtils, flex } from "../ui";
import { Table } from "../ui/table";
import { isLoadingResults, getResults } from "./report.selectors";
import Filters from "./filters.component";

const AccumulatedByUser = ({ initialDate, finalDate }) => {
    const t = useTranslate();

    const loading = useSelector( isLoadingResults );
    const results = useSelector( getResults );

    const [ teamId, setTeamId ] = useState();
    const [ usersId, setUsersId ] = useState( [] );
    const [ orderBy, setOrderBy ] = useState( "name" );
    const [ orderType, setOrderType ] = useState( "ASC" );

    const handleChange = ( name, value ) => {
        switch ( name ) {
            case "teamId":
                setTeamId( value );
                break;
            case "usersId":
                setUsersId( value );
                setTeamId( null );
                break;
            default:
                break;
        }
    };
    const changeOrder = ({ orderBy, orderType }) => {
        setOrderBy( orderBy );
        setOrderType( orderType );
    };

    const orderedResults = useMemo( () => (
        order( ( results || [] ), [ orderBy ], [ orderType.toLowerCase() ] )
    ), [ results, orderBy, orderType ] );

    const source = useMemo( () => orderedResults.map( item => ({
        ...item,
        open: item.open + "",
        sold: item.sold + "",
        lost: item.lost + ""
    })), [ orderedResults ] );

    const chartData = useMemo( () => ({
        title: {
            text: " "
        },
        xAxis: {
            categories: orderedResults.map( item => item.name ),
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ""
            }
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:10px\">{point.key}</span><table>",
            pointFormat: "<tr><td style=\"color:{series.color};padding:0\">{series.name}: </td>" +
                "<td style=\"padding:0\"><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: t( "report:accumulated-by-user.open" ),
            data: orderedResults.map( item => item.open )
        }, {
            name: t( "report:accumulated-by-user.sold" ),
            data: orderedResults.map( item => item.sold ),
            color: "#4CAF50"
        }, {
            name: t( "report:accumulated-by-user.lost" ),
            data: orderedResults.map( item => item.lost ),
            color: "#F44336"
        }]
    }), [ t, orderedResults ]);

    const model = {
        name: t( "report:accumulated-by-user.user" ),
        open: t( "report:accumulated-by-user.open" ),
        sold: t( "report:accumulated-by-user.sold" ),
        lost: t( "report:accumulated-by-user.lost" )
    };

    const totalFooter = orderedResults.reduce( ( accumulator, item ) => {
        accumulator.open += item.open;
        accumulator.sold += item.sold;
        accumulator.lost += item.lost;
        return accumulator;
    }, {
        name: t( "report:accumulated-by-user.total" ),
        open: 0,
        sold: 0,
        lost: 0
    });

    return (
        <div className={ cssUtils.marginTopSmall }>
            <CardHeader
                title={ t( "report:reports.ACCUMULATED_BY_USER" ) }
                subheader={ t( "report:accumulated-by-user.help" ) }
            />

            <Paper
                className={ classnames(
                    flex.container, flex.alignItemsCenter, cssUtils.paddingSmall, cssUtils.marginBottomSmall
                )}
            >
                <Filters
                    items={[ "funnels", "source", "users" ]}
                    required={[ "users", "funnels" ]}
                    onChange={ handleChange }
                    report={ "ACCUMULATED_BY_USER" }
                    initialDate={ initialDate.toISOString() }
                    finalDate={ finalDate.toISOString() }
                    teamId={ teamId }
                    usersId={ usersId }
                />
            </Paper>

            <div className={ classnames( flex.container, flex.alignItemsStart ) }>
                <Paper>
                    {
                        results &&
                            <Chart
                                id="accumulatedByUserChart"
                                data={ chartData }
                                noDataMessage={ t( "report:empty-results" ) }
                                type="column"
                            />
                    }
                </Paper>
                {
                    loading ?
                        <LinearProgress className={ flex.flexGrow }/> :
                        results &&
                            <div className={ classnames( flex.fill, cssUtils.marginLeftSmall ) }>
                                <Table
                                    id="accumulatedByUserTable"
                                    allowOrder
                                    onChangePage={ changeOrder }
                                    orderBy={ orderBy }
                                    orderType={ orderType }
                                    allowExport
                                    model={ model }
                                    source={ source }
                                    footer={{
                                        ...totalFooter,
                                        open: totalFooter.open + "",
                                        sold: totalFooter.sold + "",
                                        lost: totalFooter.lost + ""
                                    }}
                                    title={ t( "report:reports.ACCUMULATED_BY_USER" ) }
                                />
                            </div>
                }
            </div>
        </div>
    );
};

export default AccumulatedByUser;