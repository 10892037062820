import { useForm } from "formik-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";

import useTranslate from "../hooks/use-translate.hook";
import { setInitialData } from "./welcome.actions";
import { INITIAL_DATA_FORM } from "./welcome.constants";

const WelcomeDialog = ({ open, onClose, user }) => {
    const dispatch = useDispatch();

    const t = useTranslate();

    const formik = useForm({
        form: INITIAL_DATA_FORM,
        initialValues: {
            createMocks: false
        },
        onSubmit: values => dispatch( setInitialData( values ) ),
        onSubmitSuccess: onClose,
    });

    return (
        <Dialog open={ open }>
            <DialogTitle>{ t( "welcome:dialog.greeting", { user: user.name } ) }</DialogTitle>
            <DialogContent>
                <form>
                    <DialogContentText>
                        <span dangerouslySetInnerHTML={{ __html: t( "welcome:dialog.text" ) }} />
                    </DialogContentText>
                    <FormControlLabel
                        name="createMocks"
                        label={ t( "welcome:dialog.add-samples" ) }
                        control={
                            <Checkbox
                                checked={ formik.values.createMocks }
                                onChange={ formik.handleChange }
                            />
                        }
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button disabled={ formik.submitting } onClick={ formik.submitForm } variant="contained">
                    { t( "common:save" ) }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WelcomeDialog;