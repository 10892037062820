import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSubmitting } from "formik-redux";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import MuiImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import classnames from "classnames";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { formatBytes } from "../../../core/formatter.utils";
import { cssUtils } from "../../../ui";
import { selectors as templateSelectors } from "../../../template";
import { DELETE_IMAGE_FORM } from "./template.constants";
import { deleteImage } from "./template.actions";
import NewImage from "./new-image.component";
import css from "./template.scss";

const ImageList = () => {
    const dispatch = useDispatch();

    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const loading = useSelector( templateSelectors.isLoadingImages );
    const images = useSelector( templateSelectors.getSystemImages );
    const deleting = useSelector( isSubmitting( DELETE_IMAGE_FORM ) );

    const [ selected, setSelected ] = useState();
    const [ confirmDelete, setConfirmDelete ] = useState();

    const handleSelect = image => setSelected( image );

    const toDelete = image => setConfirmDelete( image );

    const handleDelete = () => dispatch( deleteImage( confirmDelete.id ) );

    useEffect( () => {
        if ( !deleting ) {
            setConfirmDelete();
        }
    }, [ deleting ] );

    return (
        <Card>
            <CardHeader
                title={ t( "config:images.title" ) }
                subheader={ t( "config:images.subheader" ) }
            />

            { loading && <div className={ cssUtils.textCenter }><CircularProgress/></div> }

            <CardContent>
                <NewImage/>

                <MuiImageList cols={ 4 } className={ cssUtils.marginTop }>
                    { images.map( image => (
                        <ImageListItem key={ image.id }>
                            <img
                                onClick={ () => handleSelect( image ) }
                                src={ image.url }
                                alt={ image.name }
                                className={ cssUtils.cursorPointer }
                            />
                            <ImageListItemBar
                                title={ `${image.name} (${formatBytes( image.size )})` }
                                subtitle={ t( "config:images.created-by",
                                    {
                                        user: image.createdBy.name,
                                        date: dateFormat( image.createdAt ),
                                        interpolation: { escapeValue: false }
                                    }
                                )}
                                actionIcon={
                                    <Tooltip title={ t( "common:exclude" ) }>
                                        <IconButton
                                            className={ css.deleteImageIcon }
                                            onClick={ () => toDelete( image ) }
                                            size="large">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        </ImageListItem>
                    ))}
                </MuiImageList>
            </CardContent>

            <div className={ classnames( !selected && cssUtils.displayNone, css.containerImageSelected ) }>
                <IconButton
                    className={ css.closeSelectedImage }
                    onClick={ () => handleSelect() }
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
                { selected && <img className={ css.imageSelected } src={ selected.url } alt={ selected.name }/> }
            </div>

            <Dialog open={ !!confirmDelete }>
                <DialogContent>
                    <DialogContentText>
                        { t( "config:images.delete.confirm" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ () => toDelete() } disabled={ deleting }>
                        { t( "config:images.delete.no" ) }
                    </Button>
                    <Button color="primary" onClick={ handleDelete } disabled={ deleting }>
                        { t( "config:images.delete.yes" ) }
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default ImageList;