import { useCallback, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dayjs from "dayjs";
import classnames from "classnames";

import usePrevious from "../../hooks/use-previous.hook";
import css from "./date-picker.scss";

const DatePicker = ({ className, month, onDatePicked, onNavigate, selectedDate, showHeader = true }) => {
    const [ current, setCurrent ] = useState( selectedDate );
    const [ selected, setSelected ] = useState( selectedDate );

    const prevMonth = usePrevious( month );

    const updateMonth = useCallback( add => {
        const date = new Date( selectedDate );
        date.setMonth( month + add );
        onNavigate( date );
    }, [ month, onNavigate, selectedDate ] );

    const next = useCallback( () => {
        updateMonth( 1 );
    }, [ updateMonth ] );
    const prev = useCallback( () => {
        updateMonth( -1 );
    }, [ updateMonth ] );

    useEffect( () => {
        if ( prevMonth !== month ) {
            const date = new Date( current );
            date.setMonth( month );
            setCurrent( date );
        }
    }, [ current, prevMonth, month ] );
    useEffect( () => {
        setSelected( selectedDate );
    }, [ selectedDate ] );

    const handleSelectDay = date => {
        setSelected( date );
        onDatePicked( date );
    };

    const renderDay = ( opts = {} ) => {
        let containerStyle = {};
        if ( opts.selected ) {
            containerStyle = {
                color: "#ffffff"
            };
        }

        const today = new Date();
        const isToday = today.getFullYear() === copy.getFullYear()
            && today.getDate() === copy.getDate()
            && today.getMonth() === copy.getMonth();
        return (
            <div
                key={ opts.index }
                className={ classnames( css.dayContainer, css.noselect, opts.current ? "" : css.nonCurrent ) }
                style={ containerStyle }
                onClick={ () => handleSelectDay( opts.date ) }
            >
                <div className={ isToday ? css.current : "" } />
                <div className={ opts.selected ? css.selected : "" } />
                <Typography className={ css.day }>
                    { opts.date.getDate() }
                </Typography>
            </div>
        );
    };

    const renderDays = copy => {
        const days = [];

        // set to beginning of month
        copy.setDate( 1 );

        // if we are missing no offset, include the previous week
        const offset = copy.getDay() === 0 ? 7 : copy.getDay();

        copy.setDate( -offset );

        let inMonth = false;
        let lastMonth = true;
        for ( let i = 0; i < 42; i++ ) {
            // increase date
            copy.setDate( copy.getDate() + 1 );

            // make sure we pass any previous month values
            if ( i < 30 && copy.getDate() === 1 ) {
                inMonth = true;
                lastMonth = false;
            } else if ( i > 30 && copy.getDate() === 1 ) {
                // if we are seeing the "1" again, we have iterated over
                // the current month
                inMonth = false;
            }

            const isSelected = selected.getFullYear() === copy.getFullYear()
                && selected.getDate() === copy.getDate()
                && selected.getMonth() === copy.getMonth();


            days.push( renderDay( {
                index: i,
                selected: isSelected,
                current: inMonth,
                month: ( inMonth ? 0 : ( lastMonth ? -1 : 1 ) ),
                day: copy.getDate(),
                date: new Date( copy.getTime() ),
            } ) );
        }

        return days;
    };

    const renderHeaders = () => {
        const header = [];
        const weekdays = dayjs.weekdaysShort();
        for ( let i = 0; i < weekdays; i++ ) {
            header.push(
                <Typography key={ i } className={ classnames( css.dayHeaders, css.noselect ) }>
                    { weekdays[ i ] }
                </Typography>
            );
        }

        return header;
    };

    // get su-sat header
    const header = renderHeaders();

    // copy our current time state
    const copy = new Date( current.getTime() );

    // get the month days
    const days = renderDays( copy );

    const months = dayjs.months();
    const tMonth = months[ selectedDate.getMonth() ];
    const tDate = selectedDate.getDate();
    const year = selectedDate.getFullYear();

    let upperDate = null;
    if ( showHeader ) {
        upperDate = (
            <div
                className={ classnames( css.header, css.center ) }
            >
                <Typography>{ tMonth.toUpperCase() }</Typography>
                <Typography variant="caption">{ tDate }</Typography>
            </div>
        );
    }
    return (
        <div id="datePickerCalendar" className={ classnames( css.flexCol, className ) }>
            { upperDate }
            <div className={ css.padding }>
                <div className={ css.month }>
                    <IconButton onClick={ prev } size="large">
                        <KeyboardArrowLeftIcon/>
                    </IconButton>
                    <div className={ css.monthTitle }>
                        <Typography>{ months[ month ] }</Typography>
                        <Typography variant="caption">{ year }</Typography>
                    </div>
                    <IconButton onClick={ next } size="large">
                        <KeyboardArrowRightIcon/>
                    </IconButton>
                </div>
                <div className={ css.footer }>
                    { header }
                    { days }
                </div>
            </div>
        </div>
    );
};

export default DatePicker;