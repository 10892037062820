import { Fragment, useCallback, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButtton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import BusinessIcon from "@mui/icons-material/Business";
import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import classnames from "classnames";

import useTranslate from "../hooks/use-translate.hook";
import { useDateTimeFormat } from "../hooks/use-date-format.hook";
import { Chart, Link, cssUtils } from "../ui";
import css from "./dashboard.scss";

dayjs.extend( isBetween );

const OpportunityCheckinChart = ({
    className,
    initialDate,
    finalDate,
    statistics: { checkins },
    users
}) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );

    const [ showHelp, setShowHelp ] = useState( false );
    const [ showData, setShowData ] = useState( false );
    const [ data, setData ] = useState( [] );
    const [ user, setUser ] = useState( "" );

    const toggleHelp = () => setShowHelp( !showHelp );
    const toggleData = useCallback( ( data, user ) => {
        setShowData( !showData );
        setData( data );
        setUser( user );
    }, [ showData ] );

    const differentMonths = useMemo( () => {
        return dayjs( finalDate ).diff( dayjs( initialDate ), "month" ) > 0;
    }, [ initialDate, finalDate ] );

    const format = useMemo( () => {
        return differentMonths ? "MMM/YY" : "D/MMM";
    }, [ differentMonths ] );

    const categories = useMemo( () => {
        const maxDate = dayjs( finalDate );
        let minDate = dayjs( initialDate );

        const categories = [];
        if ( differentMonths ) {
            while ( maxDate > minDate || minDate.format( "M" ) === maxDate.format( "M" ) ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "month" );
            }
        } else {
            while ( maxDate.diff( minDate, "days", true ) >= 0 ) {
                categories.push( minDate.format( format ) );
                minDate = minDate.add( 1, "day" );
            }
        }
        return categories;
    }, [ differentMonths, finalDate, format, initialDate ] );

    const series = useMemo( () => {
        return checkins.map( item => ({
            userId: item.userId,
            name: item.userName,
            data: categories.map(
                category => item.data.filter( item => dayjs( item.createdAt ).format( format ) === category ).length
            )
        }));
    }, [ categories, checkins, format ] );

    const chartData = useMemo( () => {
        return {
            title: {
                text: t( "dashboard:opportunity-checkin-chart.title" )
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function ( item ) {
                        if ( item?.name ) {
                            return item.name;
                        }
                        return t( "dashboard:opportunity-checkin-chart.title" );
                    }
                },
            },
            yAxis: {
                title: {
                    text: ""
                }
            },
            xAxis: {
                categories
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                if ( !this.y ) {
                                    return;
                                }

                                const iDate = dayjs( this.category, format )
                                    .year( dayjs( initialDate ).year() )
                                    .startOf( "day" );
                                let fDate = dayjs( iDate ).endOf( "day" );
                                if ( differentMonths ) {
                                    fDate = dayjs( fDate ).endOf( "month" );
                                }
                                const userId = this.series.userOptions.userId;
                                const user = users.find( u => u.id === userId );

                                const userCheckins = checkins
                                    .filter( item => item.userId === userId )
                                    .map( item => item.data )[ 0 ]
                                    .map( checkin => ({ ...checkin, date: dayjs( checkin.createdAt ) }))
                                    .filter( checkin => checkin.date.isBetween( iDate, fDate ) );

                                toggleData( userCheckins, user );
                            }
                        }
                    }
                }
            },
            series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom"
                        }
                    }
                }]
            }
        };
    }, [ t, categories, series, format, initialDate, differentMonths, users, checkins, toggleData ] );

    const openMap = ( latitude, longitude ) => () => {
        window.open( `https://www.google.com/maps?q=${latitude},${longitude}`, "_blank" );
    };

    return (
        <Card id="opportunityCheckinChart" className={ classnames( className, cssUtils.positionRelative ) }>
            <Dialog
                open={ showHelp }
                onClose={ toggleHelp }
            >
                <DialogTitle>{ t( "dashboard:help" ) }</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { t( "dashboard:opportunities-created-chart.help" ) }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ toggleHelp }>{ "OK" }</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ showData }
                onClose={ () => toggleData( [] ) }
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    { t( "opportunity:opportunity-list.title" ) }
                </DialogTitle>
                <DialogContent className={ css.opportunityCheckDialog }>
                    <List
                        dense
                        subheader={
                            <ListSubheader disableGutters disableSticky>
                                {
                                    user &&
                                        t( "dashboard:opportunity-checkin-chart.list-title", {
                                            user: user.name
                                        } )
                                }
                            </ListSubheader>
                        }
                    >
                        {
                            data.map( ( checkin, index ) => (
                                <ListItem
                                    key={ index }
                                    disablePadding
                                    secondaryAction={
                                        <Tooltip title={ t( "dashboard:opportunity-checkin-chart.open" ) }>
                                            <IconButtton
                                                onClick={ openMap( checkin.latitude, checkin.longitude ) }
                                            >
                                                <PlaceIcon/>
                                            </IconButtton>
                                        </Tooltip>
                                    }
                                >
                                    <ListItemButton
                                        component={ Link }
                                        href={ `/opportunities/${checkin.opportunityId}` }
                                        disableGutters
                                        divider
                                    >
                                        <ListItemText
                                            primary={
                                                checkin.opportunityTitle +
                                                " - " +
                                                dateFormat( checkin.createdAt )
                                            }
                                            classes={{
                                                primary: css.opportunityCheckinTitle
                                            }}
                                            secondaryTypographyProps={{
                                                component: "div"
                                            }}
                                            secondary={
                                                <Fragment>
                                                    { checkin.companyName &&
                                                        <Chip
                                                            avatar={ <Avatar><BusinessIcon/></Avatar> }
                                                            label={ checkin.companyName }
                                                            size="small"
                                                        />
                                                    }
                                                    { checkin.companyName && checkin.personName && "  " }
                                                    { checkin.personName &&
                                                        <Chip
                                                            avatar={ <Avatar><PersonIcon/></Avatar> }
                                                            label={ checkin.personName }
                                                            size="small"
                                                        />
                                                    }
                                                </Fragment>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={ () => toggleData( [] ) }>
                        { t( "common:close" ) }
                    </Button>
                </DialogActions>
            </Dialog>

            <Chart
                id="opportunityCheckinChart"
                data={ chartData }
                noDataMessage={ t( "dashboard:opportunities-created-chart.empty" ) }
            />

            <Tooltip title={ t( "dashboard:help" ) }>
                <IconButtton id="helpButton" onClick={ toggleHelp } className={ css.iconHelpChart } size="large">
                    <InfoIcon/>
                </IconButtton>
            </Tooltip>
        </Card>
    );
};

export default OpportunityCheckinChart;