import { useMemo } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import BusinessIcon from "@mui/icons-material/Business";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import classnames from "classnames";
import phoneExamples from "libphonenumber-js/mobile/examples";
import { getExampleNumber } from "libphonenumber-js";

import { useDateTimeFormat } from "../../../hooks/use-date-format.hook";
import usePriceFormat from "../../../hooks/use-price-format.hook";
import useTranslate from "../../../hooks/use-translate.hook";
import { getThemeMode, getUser } from "../../../core/core.selectors";
import { listWithoutGroupings } from "../../../form/form.selectors";
import { getTemperatureProps } from "../../../opportunity/opportunity.utils";
import OpenSchedulesIcon from "../../../opportunity/list/open-schedules-icon.component";
import { cssUtils, flex } from "../../../ui";
import css from "./kanban.scss";

const OpportunityPreview = ({ kanban }) => {
    const t = useTranslate();
    const dateFormat = useDateTimeFormat( t );
    const priceFormat = usePriceFormat();

    const companyFields = useSelector( listWithoutGroupings( "COMPANY" ) );
    const personFields = useSelector( listWithoutGroupings( "PERSON" ) );
    const opportunityFields = useSelector( listWithoutGroupings( "OPPORTUNITY" ) );
    const themeMode = useSelector( getThemeMode );
    const user = useSelector( getUser );

    const date = useMemo( () => {
        const date = new Date();
        date.setDate( date.getDate() + 1 );
        return date;
    }, [] );

    return (
        <div className={ css.item }>
            <Card
                className={ classnames(
                    css.normalItem,
                    themeMode === "light" && css.lightItem
                ) }
                variant={ themeMode === "dark" ? "outlined" : "elevation" }
            >
                <div
                    className={ classnames(
                        flex.container,
                        flex.alignItemsCenter,
                        css.opportunityInfo,
                        css.opportunityTitle,
                    ) }
                >
                    <div
                        className={ classnames(
                            flex.fill,
                            cssUtils.cursorPointer,
                            css.itemTitle
                        )}
                    >
                        <Tooltip title={ t( "opportunity:title" ) } placement="top" >
                            <Typography variant="subtitle2" noWrap>{ t( "opportunity:title" ) }</Typography>
                        </Tooltip>
                    </div>
                    <div>
                        <IconButton size="small" className={ cssUtils.padding0 }>
                            <MoreVertIcon/>
                        </IconButton>
                    </div>
                </div>
                {
                    kanban.company &&
                        <div
                            className={
                                classnames(
                                    flex.container,
                                    flex.alignItemsCenter,
                                    css.opportunityInfo,
                                )
                            }
                        >
                            <BusinessIcon className={ css.customerTypeIcon }/>
                            <Typography
                                variant="caption"
                                className={ flex.fill }
                            >
                                { t( "opportunity:company" ) }
                            </Typography>
                        </div>
                }
                {
                    kanban.person &&
                        <div
                            className={
                                classnames(
                                    flex.container,
                                    flex.alignItemsCenter,
                                    css.opportunityInfo,
                                )
                            }
                        >
                            <PersonIcon className={ css.customerTypeIcon }/>
                            <Typography
                                variant="caption"
                                className={ flex.fill }
                            >
                                { t( "opportunity:person" ) }
                            </Typography>
                        </div>
                }
                {
                    kanban.phone &&
                        <div
                            className={
                                classnames(
                                    flex.container,
                                    flex.alignItemsCenter,
                                    css.opportunityInfo,
                                    css.opportunityPriceTemperatureRow
                                )
                            }
                        >
                            <Typography
                                variant="caption"
                                className={ flex.fill }
                                noWrap
                            >
                                { getExampleNumber( t( "common:country" ), phoneExamples ).formatNational() }
                            </Typography>
                            { getTemperatureProps( "HOT" ) }
                        </div>
                }
                {
                    kanban.email &&
                        <div
                            className={
                                classnames(
                                    flex.container,
                                    flex.alignItemsCenter,
                                    css.opportunityInfo,
                                    css.opportunityPriceTemperatureRow
                                )
                            }
                        >
                            <Typography
                                variant="caption"
                                className={ flex.fill }
                                noWrap
                            >
                                { "email@leadstation.com.br" }
                            </Typography>
                        </div>
                }
                {
                    ( kanban.price || kanban.recurrentPrice || kanban.source || kanban.user ) &&
                        <div className={ classnames( css.opportunityInfo, css.opportunityPriceTemperatureRow ) }>
                            <Typography noWrap variant="caption" className={ flex.fill }>
                                {
                                    kanban.price &&
                                        <Tooltip title={ t( "opportunity:price.label" ) }>
                                            <span>{ priceFormat( 10 ) }</span>
                                        </Tooltip>
                                }
                                {
                                    kanban.recurrentPrice &&
                                        <>
                                            { kanban.price && " / " }
                                            <Tooltip title={ t( "opportunity:recurrent-price" ) }>
                                                <span>{ priceFormat( 10 ) }</span>
                                            </Tooltip>
                                        </>
                                }
                                {
                                    kanban.source &&
                                        <>
                                            { ( kanban.price || kanban.recurrentPrice ) && " - " }
                                            { t( "opportunity:source" ) }
                                        </>
                                }
                            </Typography>
                            {
                                kanban.user &&
                                    <Tooltip title={ user.name }>
                                        <Avatar
                                            src={ user.avatar }
                                            className={ css.avatarUser }
                                        >
                                            { user.avatar ? "" : user.name.toUpperCase().charAt( 0 ) }
                                        </Avatar>
                                    </Tooltip>
                            }
                        </div>
                }
                {
                    kanban.nextScheduling &&
                        <>
                            <hr className={ css.divider }/>
                            <div className={ classnames( css.opportunityInfo, css.opportunitySchedulingRow ) }>
                                <Typography variant="caption" className={ classnames( flex.fill, css.iconScheduling ) }>
                                    { dateFormat( date ) }
                                </Typography>
                                <OpenSchedulesIcon opportunity={{ nextScheduling: date }} />
                            </div>
                        </>
                }
                {
                    kanban.fields?.length > 0 &&
                        <>
                            <hr className={ css.divider } />
                            {
                                kanban.fields.filter( field => !!field.fieldId ).map( ( field, index ) => {
                                    let fieldName;
                                    switch ( field.formType ) {
                                        case "COMPANY":
                                            fieldName = companyFields.find( f => field.fieldId === f.id )?.name;
                                            break;
                                        case "PERSON":
                                            fieldName = personFields.find( f => field.fieldId === f.id )?.name;
                                            break;
                                        default:
                                            fieldName = opportunityFields.find( f => field.fieldId === f.id )?.name;
                                            break;
                                    }

                                    return (
                                        <div
                                            key={ index }
                                            className={ classnames( flex.container, css.opportunityInfo ) }
                                        >
                                            <Tooltip title={ fieldName }>
                                                <Typography
                                                    variant="caption"
                                                    className={ flex.fill }
                                                    noWrap
                                                >
                                                    { fieldName }
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    );
                                })
                            }
                        </>
                }
            </Card>
        </div>
    );
};

export default OpportunityPreview;