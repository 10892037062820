import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreicon from "@mui/icons-material/ExpandMore";

import useTranslate from "../hooks/use-translate.hook";
import SocialCalendarAttendees from "./social-calendar-attendees.component";

const ScheduleEventSocialFields = ({ wrapperFields: WrapperFields, wrapperField }) => {
    const t = useTranslate();
    const [ open, setOpen ] = useState( false );
    const toggle = () => setOpen( !open );

    return (
        <>
            <hr/>
            <ListItemButton component="div" onClick={ toggle } selected={ open }>
                <ListItemText primary={ t( "schedule:form-event.social.google-data" ) }/>
                { open ? <ExpandLessIcon/> : <ExpandMoreicon/> }
            </ListItemButton>
            <Collapse in={ open }>
                <WrapperFields
                    name="social.data.attendees"
                    wrapperField={ wrapperField }
                    component={ props => <SocialCalendarAttendees { ...props } wrapperField={ wrapperField } /> }
                />
            </Collapse>
        </>
    );
};

export default ScheduleEventSocialFields;