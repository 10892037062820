import { startSubmit, stopSubmit } from "formik-redux";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { apiv1 } from "../../../../api/sagas";
import { fetchSubscription } from "../../../subscription/subscription.actions";
import * as actions from "./holding.actions";
import {
    ADD_HOLDING_FUNNEL_FORM,
    DELETE_HOLDING_FUNNEL_FORM,
    EDIT_HOLDING_FUNNEL_FORM,
    ADD_SUBSCRIPTION_HOLDING_FORM,
    DELETE_SUBSCRIPTION_HOLDING_FORM
} from "./holding.constants";

export function *watchConfigAdvancedHolding () {
    yield all([
        takeEvery( actions.FETCH_HOLDING, fetchHolding ),
        takeEvery( actions.FETCH_HOLDING_FUNNELS, fetchHoldingFunnels ),
        takeEvery( actions.CREATE_HOLDING_FUNNEL, createHoldingFunnel ),
        takeEvery( actions.DELETE_HOLDING_FUNNEL, deleteHoldingFunnel ),
        takeEvery( actions.SAVE_HOLDING_FUNNEL, saveHoldingFunnel ),
        takeEvery( actions.ADD_SUBSCRIPTION_HOLDING, addSubscriptionHolding ),
        takeEvery( actions.DELETE_SUBSCRIPTION_HOLDING, deleteSubscriptionHolding )
    ]);
}

export function *fetchHolding () {
    yield put( actions.requestHolding() );

    try {
        const response = yield call( apiv1.get, "/holding" );
        yield put( actions.receiveHolding( response.data ) );
    } catch ( e ) {
        yield put( actions.errorHolding( e.response.data ) );
    }
}

export function *fetchHoldingFunnels () {
    yield put( actions.requestHoldingFunnels() );

    try {
        const response = yield call( apiv1.get, "/holding/funnels" );
        yield put( actions.receiveHoldingFunnels( response.data ) );
    } catch ( e ) {
        yield put( actions.errorHoldingFunnels( e.response.data ) );
    }
}

export function *createHoldingFunnel ({ payload: { funnel, ...data } }) {
    yield put( startSubmit( ADD_HOLDING_FUNNEL_FORM ) );
    try {
        yield call( apiv1.put, `/holding/funnels/${funnel.id}`, data );
        yield put( stopSubmit( ADD_HOLDING_FUNNEL_FORM ) );
        yield put( actions.fetchHoldingFunnels() );
    } catch ( e ) {
        yield put( stopSubmit( ADD_HOLDING_FUNNEL_FORM, e.response.data ) );
    }
}

export function *deleteHoldingFunnel ({ meta: { funnelId } }) {
    yield put( startSubmit( DELETE_HOLDING_FUNNEL_FORM ) );
    try {
        yield call( apiv1.delete, `/holding/funnels/${funnelId}` );
        yield put( stopSubmit( DELETE_HOLDING_FUNNEL_FORM ) );
        yield put( actions.fetchHoldingFunnels() );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_HOLDING_FUNNEL_FORM, e.response.data ) );
    }
}

export function *saveHoldingFunnel ({ meta: { funnelId }, payload }) {
    yield put( startSubmit( EDIT_HOLDING_FUNNEL_FORM ) );
    try {
        yield call( apiv1.put, `/holding/funnels/${funnelId}`, payload );
        yield put( stopSubmit( EDIT_HOLDING_FUNNEL_FORM ) );
        yield put( actions.fetchHoldingFunnels() );
    } catch ( e ) {
        yield put( stopSubmit( EDIT_HOLDING_FUNNEL_FORM, e.response.data ) );
    }
}


export function *addSubscriptionHolding ({ payload }) {
    yield put( startSubmit( ADD_SUBSCRIPTION_HOLDING_FORM ) );
    try {
        yield call( apiv1.post, "/subscription/holdings", payload );
        yield put( fetchSubscription() );
        yield put( stopSubmit( ADD_SUBSCRIPTION_HOLDING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( ADD_SUBSCRIPTION_HOLDING_FORM, e.response.data ) );
    }
}

export function *deleteSubscriptionHolding ({ meta: { token } }) {
    yield put( startSubmit( DELETE_SUBSCRIPTION_HOLDING_FORM ) );
    try {
        yield call( apiv1.delete, `/subscription/holdings/${token}` );
        yield put( fetchSubscription() );
        yield put( stopSubmit( DELETE_SUBSCRIPTION_HOLDING_FORM ) );
    } catch ( e ) {
        yield put( stopSubmit( DELETE_SUBSCRIPTION_HOLDING_FORM, e.response.data ) );
    }
}